import i18next from "i18n";
import React from "react";
import { BootstrapTable, TableHeaderColumn } from "react-bootstrap-table";
import styled from "styled-components";
import { CostAllocation } from "../types/transactionTable";

const Path = styled.div`
  color: #888888;
`;

const Allocation = styled.h4`
  color: #888888;
`;

interface Props {
  costAllocations: Array<CostAllocation>;
}

const payerPathFormatter = (cell: string | undefined): JSX.Element => {
  if (!cell) return <></>;

  const path: string = cell.split("/").slice(0, -1).join("/");
  const departmentName: string = cell.split("/").slice(-1).join("");
  return (
    <div style={{ whiteSpace: "pre-wrap" }}>
      <Path>{path}</Path>
      <h4>{departmentName}</h4>
    </div>
  );
};

const CostAllocationDepartmentTable: React.FC<Props> = (props) => {
  const style = { maxHeight: "700px", overflowY: "scroll" };
  const header = {
    top: "0",
    position: "sticky",
    zIndex: "1",
    backgroundColor: "white",
  };
  return (
    <BootstrapTable
      tableStyle={style}
      headerStyle={header}
      data={props.costAllocations}
      keyField="payerId"
    >
      <TableHeaderColumn
        dataField="payerAbsolutePath"
        dataAlign="light"
        width="200"
        dataFormat={(cell: string): JSX.Element => payerPathFormatter(cell)}
      >
        {i18next.t("groups.properties.name")}
      </TableHeaderColumn>
      <TableHeaderColumn
        dataField="numerator"
        dataAlign="center"
        width="60"
        dataFormat={(cell: string): JSX.Element => (
          <Allocation>{cell}％</Allocation>
        )}
        hidden={props.costAllocations.length === 1} // 費用負担の割合が入力されていない(費用負担部署が１つ)の場合は費用負担割合を表示しない
      >
        {i18next.t("transactions.costAllocationModal.costAllocation")}
      </TableHeaderColumn>
    </BootstrapTable>
  );
};

export default CostAllocationDepartmentTable;
