import { displayMessage } from "actions/ActionCreators";
import { ThunkDispatch } from "tdispatch";
import Api from "utilities/api";
import { getMessageFromResponse } from "utilities/Utils";
import { fetchPreReport } from "../preReportActions";
import {
  closePreReportRequestJob,
  startPreReportRequest,
} from "../preReportRequestActions";
import * as ActionCreators from "./ActionCreators";

/**
 * 仮払の返金を取り消します。
 * @param id 事前申請ID
 * @param comment コメント
 */
export function cancelTemporaryPaymentRefund(id: string, comment: string) {
  return async (dispatch: ThunkDispatch): Promise<void> => {
    try {
      dispatch(startPreReportRequest("cancelTemporaryPaymentRefund"));
      const { message } = await Api.preReports.cancelTemporaryPaymentRefund({
        id,
        comment,
      });

      await dispatch(fetchPreReport(id, true));
      dispatch(ActionCreators.closeModal());
      dispatch(displayMessage("success", message));
    } catch (e) {
      dispatch(displayMessage("error", getMessageFromResponse(e)));
    } finally {
      dispatch(closePreReportRequestJob());
    }
  };
}
