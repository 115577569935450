import ConditionForm from './ConditionForm';
import React, { FC, useCallback, useMemo } from 'react';
import i18next from 'i18n';
import styled from 'styled-components';
import {
  Condition, ConditionOption, Department, TaxCategory,
} from 'utilities/api/models/JournalEntries';

interface Props {
  className: string;
  conditionOptions: ConditionOption[];
  conditions: Condition[][];
  departments?: Department[];
  taxCategories?: TaxCategory[];
  onChangeConditions: (nextConditions: Condition[][]) => void;
}

const ConditionsConnectedWithAndConjunctionView = styled.div`
  background-color: whitesmoke;
  border-radius: 10px;
`;

const AddAndConjunctionView = styled.div`
  cursor: pointer
  padding-left: 20px;
  padding-top: 5px;
  padding-bottom: 10px;
`;

const AddOrConjunctionView = styled.div`
  cursor: pointer;
  padding-left: 20px;
  padding-top: 10px;
  padding-bottom: 10px;
`;

const AndConjunctionView = styled.span`
  display: block;
  text-align: center;
`;

const OrConjunctionView = styled.span`
  display: block;
  text-align: center;
  padding-top: 10px;
  padding-bottom: 10px;
`;

const ConditionsForm: FC<Props> = (props) => {
  const noOptions = useMemo(() => props.conditionOptions.length === 0, [props.conditionOptions.length]);
  const defaultCondition: Condition | null = noOptions ? null : {
    left: { expression: '', type: '' },
    operator: props.conditionOptions[0].operators[0],
    right: { type: 'JournalEntries::CellValueConditions::RightHands::String' },
  };
  const conditions = useMemo(() => {
    return props.conditions.length > 0 || defaultCondition === null ? props.conditions : [[defaultCondition]];
  }, [props.conditions, defaultCondition]);

  const onClickAddAndConjunction = useCallback((orIndex: number): void => {
    if (defaultCondition === null) { return; }

    const nextConditions = JSON.parse(JSON.stringify(conditions)) as Condition[][];
    nextConditions[orIndex] = nextConditions[orIndex].concat([defaultCondition]);
    props.onChangeConditions(nextConditions);
  }, [conditions, defaultCondition, props.onChangeConditions]);

  const onClickAddOrConjunction = useCallback((): void => {
    if (defaultCondition === null) { return; }

    const nextConditions = conditions.concat([[defaultCondition]]);
    props.onChangeConditions(nextConditions);
  }, [conditions, defaultCondition, props.onChangeConditions]);

  const onChangeCondition = useCallback((condition: Condition, orIndex: number, conditionIndex: number): void => {
    const nextConditions = JSON.parse(JSON.stringify(conditions)) as Condition[][];
    nextConditions[orIndex][conditionIndex] = condition;
    props.onChangeConditions(nextConditions);
  }, [conditions, props.onChangeConditions]);

  const onRemoveCondition = useCallback((orIndex: number, conditionIndex: number): void => {
    const operatorRemovedConditions = props.conditions.map((forms, currentOrIndex) => {
      return forms.filter((_, currentOperatorIndex) => { return !(currentOrIndex === orIndex && currentOperatorIndex === conditionIndex); });
    });
    const compactedConditions = operatorRemovedConditions.filter((forms) => { return forms.length > 0; });
    props.onChangeConditions(compactedConditions === [] && defaultCondition !== null ? [[defaultCondition]] : compactedConditions);
  }, [props.conditions, defaultCondition, props.onChangeConditions]);

  const conditionRemovable = useMemo(() => !(conditions.length === 1 && conditions[0].length === 1), [conditions]);

  return (
    <div className={ props.className }>
      {
        conditions.map((operatorForms, orIndex) => {
          return (
            <div key={ `conditions-and-conjunction-${orIndex}` } style={ { height: '100%' } }>
              <ConditionsConnectedWithAndConjunctionView key={ `conditions-${orIndex}` }>
                {
                  operatorForms.map((condition, conditionIndex) => {
                    return (
                      <div key={ `condition-form-and-conjunction-${orIndex}-${conditionIndex}` }>
                        <ConditionForm key={ `condition-form-${orIndex}-${conditionIndex}` }
                          conditionOptions={ props.conditionOptions }
                          conditionIndex={ conditionIndex }
                          departments={ props.departments }
                          orIndex={ orIndex }
                          condition={ condition }
                          removable={ conditionRemovable }
                          onChangeCondition={ (c: Condition): void => onChangeCondition(c, orIndex, conditionIndex) }
                          onRemoveCondition={ (): void => onRemoveCondition(orIndex, conditionIndex) }
                          taxCategories={ props.taxCategories }
                        />
                        { operatorForms.length - 1 !== conditionIndex ? <AndConjunctionView>AND</AndConjunctionView> : null }
                      </div>
                    );
                  })
                }
                { noOptions ? null
                  : <AddAndConjunctionView className="txt txt-primary"
                    onClick={ (): void => onClickAddAndConjunction(orIndex) }
                  >
                    { i18next.t('journalEntries.buttons.addAndCondition') }
                  </AddAndConjunctionView>
                }
              </ConditionsConnectedWithAndConjunctionView>
              { conditions.length - 1 !== orIndex ? <OrConjunctionView>OR</OrConjunctionView> : null }
            </div>
          );
        })
      }
      { noOptions ? null
        : <AddOrConjunctionView className="txt txt-accent"
          onClick={ onClickAddOrConjunction }
        >
          { i18next.t('journalEntries.buttons.addOrCondition') }
        </AddOrConjunctionView>
      }
    </div>
  );
};

export default ConditionsForm;
