import { useCallback, useEffect, useRef, useState } from "react";
import { TaxCategorySelection } from "utilities/api/models";
import { OperatorEntry } from "utilities/api_expenses/models/expenses/OperatorEntry";
import {
  assignOperatorEntry,
  changeByAmount,
  changeBySelectedTaxCategories,
  operatorEntryInclucesTaxCategories,
  zeroPaddingValues,
} from "../factory";
import { fetchTaxCategories } from "../fetch";
import { AmountPerTaxCategory, TaxCategory } from "../interface";
import { isAssignedOperatorEntry } from "../specification";
import {
  filterDefaultSelected,
  filterSelectables,
  isEqualDates,
} from "../utils";
import { usePrevious } from "./usePrevious";

interface Props {
  /** 経費ID */
  readonly expenseId: string | null;
  /** 税区分別金額 */
  readonly values: AmountPerTaxCategory[];
  /** 金額 */
  readonly amount: number | null;
  /** 利用日一覧 */
  readonly dates: string[];
  /** 経費科目ID */
  readonly categoryId: string | null;
  /** 適格請求書として扱うか */
  readonly asEligibleInvoice: boolean;
  /** 税区分入力が有効か */
  readonly isTaxCategoryEditable: boolean;
  /**  オペレータ入力 */
  readonly operatorEntry: OperatorEntry | null;
  /** 税区分別金額変更時のコールバック */
  readonly onChange: (
    newAmountPerTaxCategories: AmountPerTaxCategory[],
  ) => void;
}

interface HooksReturnType {
  /** 選択可能な税区分 */
  readonly selectableTaxCategories: TaxCategory[];
  /** 選択中の税区分 */
  readonly selectedTaxCategories: TaxCategory[];
  /** 税区分を選択した時 */
  readonly handleSelectTaxCategories: (
    newSelectedTaxCategories: TaxCategory[],
  ) => void;
  /** オペレータ入力を表示するか */
  readonly showOperatorEntry: boolean;
}

export const useEditor = ({
  expenseId,
  values,
  amount,
  dates,
  categoryId,
  asEligibleInvoice,
  isTaxCategoryEditable,
  operatorEntry,
  onChange,
}: Props): HooksReturnType => {
  const renderedComponent = useRef(false);

  const prevAmount = usePrevious<number | null>(amount);
  const prevDates = usePrevious<string[]>(dates);
  const prevCategoryId = usePrevious<string | null>(categoryId);
  const prevAsEligibleInvoice = usePrevious<boolean>(asEligibleInvoice);
  // 適格請求書フラグは異なるタイミングで変更されるため、現在の値を保持しておく。
  const currentAsEligibleInvoice = useRef(asEligibleInvoice);

  // 適格非適格を含む、利用日、経費科目において選択可能な税区分一覧
  const [allTaxCategories, setAllTaxCategories] = useState<
    TaxCategorySelection[]
  >([]);
  // 適格請求書フラグにおける選択可能な税区分一覧
  const [selectableTaxCategories, setSelectablesTaxCategories] = useState<
    TaxCategory[]
  >([]);
  // 選択中の税区分一覧
  const [selectedTaxCategories, setSelectedTaxCategories] = useState<
    TaxCategory[]
  >([]);
  // オペレータ入力を表示するか
  const [showOperatorEntry, setShowOperatorEntry] = useState<boolean>(false);

  /**
   * 税区分別金額を更新します。
   * @param newValues 新しい税区分別金額
   */
  const updateValues = useCallback(
    (newValues: AmountPerTaxCategory[]) => {
      if (operatorEntry) {
        setShowOperatorEntry(
          !isAssignedOperatorEntry(operatorEntry, newValues),
        );
      }
      onChange(newValues);
    },
    [operatorEntry, onChange],
  );

  /**
   * 経費の金額が変更されたときに税区分別金額を変更します。
   * @param newAmount 新しい金額
   */
  const handleChangedAmount = useCallback(
    (newAmount: number) => {
      const newValues = changeByAmount(values, newAmount);
      updateValues(newValues);
    },
    [values, updateValues],
  );

  /**
   * 経費科目未設定から設定した場合に税区分別金額を変更します。
   * @param newDates 新しい利用日
   * @param newCategoryId 新しい経費科目ID
   */
  const handleNewCategoryId = useCallback(
    async (newDates: string[], newCategoryId: string) => {
      const newAll = await fetchTaxCategories(newDates, newCategoryId);
      setAllTaxCategories(newAll);

      const selectables = filterSelectables(
        newAll,
        currentAsEligibleInvoice.current,
      );
      setSelectablesTaxCategories(selectables);

      const selects = filterDefaultSelected(
        newAll,
        currentAsEligibleInvoice.current,
      );
      setSelectedTaxCategories(selects);

      if (
        operatorEntry &&
        operatorEntryInclucesTaxCategories(operatorEntry, selects) &&
        operatorEntry.total === amount
      ) {
        const newValues = assignOperatorEntry(
          expenseId,
          operatorEntry,
          selects,
        );
        updateValues(newValues);
      } else {
        const newValues = changeBySelectedTaxCategories(
          expenseId,
          values,
          selects,
          amount || 0,
        );
        updateValues(newValues);
      }
    },
    [
      currentAsEligibleInvoice,
      values,
      amount,
      expenseId,
      operatorEntry,
      updateValues,
    ],
  );

  /**
   * 経費科目IDが変更されたときに税区分別金額を変更します。
   * @param newDates 新しい利用日
   * @param newCategoryId 新しい経費科目ID
   */
  const handleChangeDatesOrCategoryId = useCallback(
    async (newDates: string[], newCategoryId: string) => {
      const newAll = await fetchTaxCategories(newDates, newCategoryId);
      setAllTaxCategories(newAll);

      const selectables = filterSelectables(
        newAll,
        currentAsEligibleInvoice.current,
      );
      setSelectablesTaxCategories(selectables);

      const selects = filterDefaultSelected(
        newAll,
        currentAsEligibleInvoice.current,
      );
      setSelectedTaxCategories(selects);

      const newValues = changeBySelectedTaxCategories(
        expenseId,
        values,
        selects,
        amount || 0,
      );
      updateValues(newValues);
    },
    [currentAsEligibleInvoice, values, amount, expenseId, updateValues],
  );

  /**
   * 適格請求書として扱うかが変更されたときに税区分別金額を変更します。
   * @param newAsEligibleInvoice 新しい適格請求書として扱うか
   */
  const handleChangeAsEligibleInvoice = useCallback(
    async (newAsEligibleInvoice: boolean) => {
      const selectables = filterSelectables(
        allTaxCategories,
        newAsEligibleInvoice,
      );
      setSelectablesTaxCategories(selectables);

      const selects = filterDefaultSelected(
        allTaxCategories,
        newAsEligibleInvoice,
      );
      setSelectedTaxCategories(selects);

      const newValues = changeBySelectedTaxCategories(
        expenseId,
        values,
        selects,
        amount || 0,
      );
      updateValues(newValues);
    },
    [allTaxCategories, expenseId, values, amount, updateValues],
  );

  /**
   * 税区分を変更したときに税区分別金額を変更します。
   * @param newSelected 新しい税区分
   */
  const handleSelectTaxCategories = useCallback(
    async (newSelects: TaxCategory[]) => {
      const defaults = filterDefaultSelected(
        allTaxCategories,
        currentAsEligibleInvoice.current,
      );
      const selects = values.length === 0 ? defaults : newSelects;
      setSelectedTaxCategories(selects);

      const newValues = changeBySelectedTaxCategories(
        expenseId,
        values,
        selects,
        amount || 0,
      );
      updateValues(newValues);
    },
    [
      allTaxCategories,
      currentAsEligibleInvoice,
      expenseId,
      values,
      amount,
      updateValues,
    ],
  );

  // 選択可能な税区分を初期化します。
  const handleInitialize = useCallback(async () => {
    const newAll = await fetchTaxCategories(dates, categoryId);
    setAllTaxCategories(newAll);
    const selectables = filterSelectables(
      newAll,
      currentAsEligibleInvoice.current,
    );
    setSelectablesTaxCategories(selectables);

    if (isTaxCategoryEditable && values.length > 0) {
      const selects = values.map((value) => value.taxCategory);
      setSelectedTaxCategories(selects);
      updateValues(values);
      return;
    }

    const selects = filterDefaultSelected(
      newAll,
      currentAsEligibleInvoice.current,
    );
    setSelectedTaxCategories(selects);
    const paddings = zeroPaddingValues(
      expenseId,
      newAll,
      values,
      currentAsEligibleInvoice.current,
    );
    // 事前申請項目や領収書原本から経費を新規作成した場合、金額を割り当てる。
    if (!expenseId && amount && paddings.every((v) => v.amount === 0)) {
      updateValues(changeByAmount(paddings, amount));
    } else {
      updateValues(paddings);
    }
  }, [
    dates,
    categoryId,
    amount,
    values,
    isTaxCategoryEditable,
    expenseId,
    updateValues,
    currentAsEligibleInvoice,
  ]);

  useEffect(() => {
    if (!renderedComponent.current) {
      renderedComponent.current = true;

      if (categoryId && dates.length > 0) {
        handleInitialize();
      }
      return;
    }
    if (operatorEntry) {
      setShowOperatorEntry(!isAssignedOperatorEntry(operatorEntry, values));
    }
    if (amount !== prevAmount && values.length > 0) {
      handleChangedAmount(amount || 0);
    }
    if (categoryId && dates.length > 0) {
      const equalDates = isEqualDates(dates, prevDates || []);
      const equalCategory = categoryId === prevCategoryId;

      if (!prevCategoryId && equalDates && values.length === 0) {
        handleNewCategoryId(dates, categoryId);
      } else if (!equalCategory || !equalDates) {
        handleChangeDatesOrCategoryId(dates, categoryId);
      }
    }
    if (asEligibleInvoice !== prevAsEligibleInvoice) {
      currentAsEligibleInvoice.current = asEligibleInvoice;
      handleChangeAsEligibleInvoice(asEligibleInvoice);
    }
  }, [
    expenseId,
    amount,
    prevAmount,
    dates,
    prevDates,
    categoryId,
    prevCategoryId,
    asEligibleInvoice,
    prevAsEligibleInvoice,
    values,
    handleInitialize,
    handleChangedAmount,
    handleNewCategoryId,
    handleChangeDatesOrCategoryId,
    handleChangeAsEligibleInvoice,
    operatorEntry,
    currentAsEligibleInvoice,
  ]);

  return {
    selectableTaxCategories,
    selectedTaxCategories,
    handleSelectTaxCategories,
    showOperatorEntry,
  };
};
