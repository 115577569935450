import i18next from "i18n";
import React from "react";

interface Props {
  disabled: boolean;
  onClick: () => void;
}

/** 汎用申請の提出ボタン */
export const SubmitButton: React.FC<Props> = React.memo(
  ({ disabled, onClick }) => {
    return (
      <button
        className="btn btn-block btn-accent"
        onClick={(): void => {
          onClick();
        }}
        disabled={disabled}
      >
        <i className="fa fa-left fa-paper-plane" />
        {i18next.t("commons.actions.apply")}
      </button>
    );
  },
);
