import Api from 'utilities/api';
import React, { useCallback } from 'react';
import SimpleModal from '../SimpleModal';
import flash from 'utilities/flash';
import i18next from 'i18n';
import { getMessageFromResponse, snakecaseKeys } from 'utilities/Utils';

/** 選択候補となる参加者の削除を行おうとした際に、最終確認のために表示されるモーダル */
const CompanionDeleteConfirmModal = (props) => {
  const {
    /** 削除対象となっている参加者 */
    companion,
    /** このモーダルを表示するか否か */
    show,
    /** このモーダルを閉じるためのメソッド */
    onClose,
    /** 削除後に実行したいメソッド */
    afterDelete,
  } = props;

  const onDelete = useCallback(
    async () => {
      try {
        await Api.companions.destroy(snakecaseKeys({
          id: companion?.id,
          isUser: false, // Userはここから削除しないので必ずfalse
        }));
        flash.success(i18next.t('companions.messages.deleted'));

        await afterDelete();
        onClose();
      } catch (e) {
        flash.error(getMessageFromResponse(e));
      }
    },
    [afterDelete, companion, onClose],
  );

  return (
    <SimpleModal
      title={ '参加者を削除しますか？' }
      show={ show }
      close={ onClose }
      buttons={ [{ content: '削除', color: 'danger', onClick: onDelete }] }
    />
  );
};

export default CompanionDeleteConfirmModal;
