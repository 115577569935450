import Api from 'utilities/api';
import React, { useCallback, useEffect, useState } from 'react';
import flash from 'utilities/flash';
import { CancelButton, SaveButton } from '../components/buttons';
import { DetailToolbar } from './components/DetailToolbar';
import { FormLayout } from './components/FormLayout';
import { FormProvider, useForm } from 'react-hook-form';
import { ReadOnlyForm } from './components/forms/ReadOnlyForm';
import { RightDrawer, RightDrawerFooter, RightDrawerHeader } from 'components/renewaled_ui/single_views';
import { SystemSetting } from './types';
import { Task } from 'utilities/api/models/accountingDataScheduledExports';
import { UpdateRequest } from 'utilities/api/requests/accountingDataScheduledExports/tasks';
import { getMessageFromResponse, snakecaseKeys } from 'utilities/Utils';
import { useMountedRef } from 'hooks';

interface Props {
  id: string;
  show: boolean;
  isEditable: boolean;
  systemSetting: SystemSetting;
  onClose: () => void;
  onChange: (show: boolean) => void;
}

export const DetailDrawer: React.FC<Props> = ({
  id,
  show,
  isEditable,
  onClose,
  onChange,
  systemSetting,
}) => {
  const mountedRef = useMountedRef();
  const methods = useForm({ mode: 'onChange' });
  const [task, setTask] = useState<Task | null>(null);
  const [processing, setProcessing] = useState(false);
  const [showEditForm, setShowEditForm] = useState(false);

  useEffect(() => {
    if (!id || !show || !mountedRef.current) return;

    const fetchTask = async (): Promise<void> => {
      setShowEditForm(false);
      setProcessing(true);
      flash.clear();

      try {
        const data = await Api.accountingDataScheduledExports.tasks.show({ id });
        if (Array.isArray(data.companyExpenseAccountAssignees) && data.companyExpenseAccountAssignees.length === 0 && data.enableBankDataCreation === true) {
          const initData = {
            ...data,
            enableBankDataCreation: false,
          };

          setTask(initData);
        } else {
          setTask(data);
        }
      } catch (error) {
        flash.error(getMessageFromResponse(error as Record<string, unknown>));
        onClose();
      } finally {
        if (mountedRef.current) {
          setProcessing(false);
        }
      }
    };
    fetchTask();
  }, [show, id, setProcessing, setTask, onClose, mountedRef]);

  const handleUpdate = useCallback((data: Task) => {
    const updateTask = async (): Promise<void> => {
      setProcessing(true);
      flash.clear();

      try {
        const { message } = await Api.accountingDataScheduledExports.tasks.update(snakecaseKeys(data) as UpdateRequest);
        flash.success(message);
        const newTask = await Api.accountingDataScheduledExports.tasks.show({ id });
        setTask(newTask);
        onChange(true);
        setShowEditForm(false);
      } catch (error) {
        flash.error(getMessageFromResponse(error as Record<string, unknown>));
      } finally {
        if (mountedRef.current) {
          setProcessing(false);
        }
      }
    };
    updateTask();
  }, [id, setProcessing, setTask, onChange, mountedRef]);

  const handleEnable = useCallback(() => {
    const enableTask = async (): Promise<void> => {
      setProcessing(true);
      flash.clear();

      try {
        const { message } = await Api.accountingDataScheduledExports.tasks.enable({ id });
        flash.success(message);
        const data = await Api.accountingDataScheduledExports.tasks.show({ id });
        setTask(data);
        onChange(true);
      } catch (error) {
        flash.error(getMessageFromResponse(error as Record<string, unknown>));
      } finally {
        if (mountedRef.current) {
          setProcessing(false);
        }
      }
    };
    enableTask();
  }, [id, setProcessing, setTask, onChange, mountedRef]);

  const handleDisable = useCallback(() => {
    const disableTask = async (): Promise<void> => {
      setProcessing(true);
      flash.clear();

      try {
        const { message } = await Api.accountingDataScheduledExports.tasks.disable({ id });
        flash.success(message);
        const data = await Api.accountingDataScheduledExports.tasks.show({ id });
        setTask(data);
        onChange(true);
      } catch (error) {
        flash.error(getMessageFromResponse(error as Record<string, unknown>));
      } finally {
        if (mountedRef.current) {
          setProcessing(false);
        }
      }
    };
    disableTask();
  }, [id, setProcessing, setTask, onChange, mountedRef]);

  const handleDelete = useCallback(() => {
    setProcessing(true);
    flash.clear();

    const destroyApi = async (): Promise<void> => {
      try {
        const { message } = await Api.accountingDataScheduledExports.tasks.destroy({ id });
        flash.success(message);
        onChange(false);
      } catch (error) {
        flash.error(getMessageFromResponse(error as Record<string, unknown>));
      } finally {
        if (mountedRef.current) {
          setProcessing(false);
        }
      }
    };
    destroyApi();
  }, [id, setProcessing, onChange, mountedRef]);

  const handleTest = useCallback(() => {
    setProcessing(true);
    flash.clear();

    const testApi = async (): Promise<void> => {
      try {
        const { message } = await Api.accountingDataScheduledExports.tasks.test({ id });
        flash.success(message);
        onChange(true);
      } catch (error) {
        flash.error(getMessageFromResponse(error as Record<string, unknown>));
      } finally {
        if (mountedRef.current) {
          setProcessing(false);
        }
      }
    };
    testApi();
  }, [id, setProcessing, mountedRef, onChange]);

  const handleExecute = useCallback(() => {
    setProcessing(true);
    flash.clear();

    const executeApi = async (): Promise<void> => {
      try {
        const { message } = await Api.accountingDataScheduledExports.tasks.execute({ id });
        flash.success(message);
        onChange(true);
      } catch (error) {
        flash.error(getMessageFromResponse(error as Record<string, unknown>));
      } finally {
        if (mountedRef.current) {
          setProcessing(false);
        }
      }
    };
    executeApi();
  }, [id, setProcessing, mountedRef, onChange]);

  const handleClose = useCallback(() => {
    if (showEditForm) {
      setShowEditForm(false);
    } else {
      onClose();
    }
  }, [showEditForm, setShowEditForm, onClose]);

  const handleEdit = useCallback(() => {
    setShowEditForm(true);
  }, [setShowEditForm]);

  const handleCancelEdit = useCallback(() => {
    setShowEditForm(false);
  }, [setShowEditForm]);

  useEffect(() => {
    if (mountedRef.current && showEditForm && task) {
      methods.reset({ ...task });
    }
  }, [showEditForm, methods, task, mountedRef]);

  return task && (
    <RightDrawer
      show={ show }
      onClose={ handleClose }
      width={ 600 }
      isLoading={ processing }
      onShow={ (): void => { /** nothing to do */ } }
      preventCloseSelector='#task_table_expense > .tbody,div[role=tooltip]'
      overflowY='hidden'
    >
      <RightDrawerHeader title={ task.name } onClose={ handleClose } />
      <div style={ { display: !showEditForm ? 'inherit' : 'none' } }>
        <ReadOnlyForm task={ task } />
      </div>
      <div style={ { display: showEditForm ? 'inherit' : 'none' } }>
        <FormProvider { ...methods }>
          <FormLayout systemSetting={ systemSetting } isShowingForm={ showEditForm } />
        </FormProvider>
      </div>
      {
        isEditable && <RightDrawerFooter>
          <div style={ { display: !showEditForm ? 'inherit' : 'none' } }>
            <DetailToolbar
              task={ task }
              processing={ processing }
              onEdit={ handleEdit }
              onDelete={ handleDelete }
              onEnable={ handleEnable }
              onDisable={ handleDisable }
              onTest={ handleTest }
              onExecute={ handleExecute }
            />
          </div>
          <div style={ { display: showEditForm ? 'flex' : 'none', gap: '6px' } }>
            <SaveButton onClick={ methods.handleSubmit(handleUpdate) } processing={ processing } valid={ methods.formState.isValid } />
            <CancelButton onClick={ handleCancelEdit } disabled={ processing } />
          </div>
        </RightDrawerFooter>
      }
    </RightDrawer>
  );
};
