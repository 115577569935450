import flatten from "lodash/flatten";
import get from "lodash/get";
import isNil from "lodash/isNil";

/**
 * 手当表のセル情報を保存するオブジェクトを初期化する
 *
 * @param {Array} cells 表のセルのリスト。e.g. [{ id: 'id', factors: [{ id: 'factor-id' }] }]
 * @return {object}
 *   表の(x, y, z)の位置の値を取る時、[x.id][y.id][z.id]を指定する
 */
export function buildCellMap(cells) {
  return cells.reduce((acc, x) => {
    let factorMap = acc;

    // factorTypesの並び順と、factorsの並び順は一致する
    // cell[行のkey][列のkey] = { id, value, factors } となるように、セルのObjectを構成する
    x.factors.forEach((factor, i) => {
      if (i === x.factors.length - 1) {
        // 最後のkeyまで指定できた時は、cellの値を入れる
        // valueがnullの時は、ユーザの入力値をそのまま手当とするため、フラグを立てる（editable）
        factorMap[factor.id] = {
          id: x.id,
          value: isNil(x.value) ? "" : x.value, // nullの時は空文字にする。ReactのWarning対策
          factors: x.factors,
          editable: isNil(x.value),
        };
      } else if (factorMap[factor.id]) {
        factorMap = factorMap[factor.id];
      } else {
        factorMap[factor.id] = {};
        factorMap = factorMap[factor.id];
      }
    });

    return acc;
  }, {});
}

/**
 * セルの情報を保存したObjectを、配列に変換する
 * buildCellMapと逆の処理を行う
 *
 * @return {Array}
 */
export function cellsToArray(factorTypes, cells) {
  const cellKeys = factorTypes.reduce(
    (acc, factorType) => {
      return flatten(
        acc.map((key) =>
          factorType.options.map((option) => `${key}[${option.id}]`),
        ),
      );
    },
    [""],
  );

  return cellKeys.map((key) => get(cells, key));
}

/**
 * 手当の計算式の変数入力フォームの値を初期化する
 *
 * @param {object} table
 * @param {object} cells 手当表のセルのキャッシュデータ。buildCellMapの戻り値
 * @param {Array} inputs ユーザの入力情報（経費編集時は、入力済みのデータでフォームを初期化する。新規の時は空の配列）
 *   e.g. [{ id: [InputId], variableId: [VariableId], value: 1.0 }]
 *   valueの値は、iniputTypeにより異なる（select, tableの場合は、それぞれoptionのID、cellのIDになる）
 */
export function buildInput(
  table,
  cells,
  inputs = [],
  defaultPeriod = { from: null, to: null },
) {
  // 表のセルを指定するキーを入れる
  let factorIds = {};

  // 計算式の中で、手当表のセルを参照する変数が一つだけ存在する
  // 計算時間短縮のため、その値も保存する
  let tableVariableId = null;

  if (
    isNil(table.calculationFormula) ||
    isNil(table.calculationFormula.variables)
  ) {
    return {
      factorIds,
      tableVariableId,
      variableInputs: {},
    };
  }

  // 変数の入力情報を初期化
  const variableInputs = table.calculationFormula.variables.reduce((acc, x) => {
    const input = inputs.find((v) => v.variableId === x.id);
    switch (x.inputType) {
      case "select": {
        // valueに入っているのは、optionのID
        // IDを使って対応するoptionの情報を探す
        // 見つからない場合は、先頭のoptionの値をセットする
        const optionId = get(input, "input.id");
        const optionValue = get(input, "input.value");
        const selectedOption =
          x.options.find((option) => option.id === optionId) ||
          get(x.options, "[0]");
        const value = isNil(get(selectedOption, "value"))
          ? optionValue
          : get(selectedOption, "value");

        return { ...acc, [x.id]: { id: get(selectedOption, "id"), value } };
      }
      case "number": {
        // 未入力時は初期値を0とする
        return { ...acc, [x.id]: +get(input, "input", 0) };
      }
      case "period": {
        const today = new Date();
        return {
          ...acc,
          [x.id]: get(input, "input", {
            from: get(defaultPeriod, "from", today.toISOString()),
            to: get(defaultPeriod, "to", today.toISOString()),
          }),
        };
      }
      case "table": {
        tableVariableId = x.id;

        const cellId = get(input, "input.id");
        const cellValue = get(input, "input.value", "");
        let selectedCell = {};

        // 手当表の値が入力済みの時は、セルの値を探し、factorIdsも、そのセルのキーで初期化する
        if (cellId) {
          const cellKeys = searchFactorIds(cells, cellId);

          if (cellKeys) {
            // factorIdsの構築処理でエラーにならないように、factorsに空配列をセット
            selectedCell = get(
              cells,
              cellKeys.map((key) => `[${key}]`).join(""),
              { factors: [] },
            );
            factorIds = selectedCell.factors.reduce((factorIdMap, factor) => {
              return {
                ...factorIdMap,
                [factor.type.id]: factor.id,
              };
            }, {});
          }
        } else {
          // 手当表の値が未入力の時は、利用可能な中で最初にサジェストされた値で初期化を試みる
          // デフォルト値の指定がない場合, factorIdsを先頭のセルのキーで初期化し、セルの値も対応する値で初期化する
          // 初期選択肢設定で指定されたデフォルト値が閲覧制限されていた場合、利用可能な中で最初の選択肢で初期化を試みる。
          factorIds = table.factorTypes.reduce((factorIdMap, type) => {
            const readableOptionIds = type.options.map((option) => option.id);
            const defaultOptionId = get(type.defaultOption, "id");
            const firstOptionId = readableOptionIds[0];
            const defaultValue = readableOptionIds.includes(defaultOptionId)
              ? defaultOptionId
              : firstOptionId;

            return { ...factorIdMap, [type.id]: defaultValue };
          }, {});

          const cellKey = table.factorTypes
            .map((factorType) => `[${factorIds[factorType.id]}]`)
            .join("");
          selectedCell = get(cells, cellKey, {});
        }

        return {
          ...acc,
          [x.id]: {
            id: selectedCell.id,
            value: +(selectedCell.value || cellValue || 0), // フォーム上、小数点不要の場合に小数点が消えるように
            editable: selectedCell.editable,
          },
        };
      }
      default: {
        // 実行されない
        return { ...acc, [x.id]: "" };
      }
    }
  }, {});

  return {
    factorIds,
    tableVariableId,
    variableInputs,
  };
}

/**
 * セルのIDに対応する、セルのkeyの情報を探す
 *
 * @param {object} cellMap
 *   セルの情報が入ったオブジェクト。buildCellMapの戻り値を参照
 *   再帰的に処理するため、全てのセルの情報を持っているとは限らないことに注意
 * @param {string} cellId セルのID
 * @return {Array<string>} セルのkeyのリスト（順番固定）
 */
export function searchFactorIds(cellMap, cellId) {
  // keyを全て指定すると、セルの情報が入ったオブジェクトにたどり着く
  if (cellMap.id) {
    if (cellMap.id === cellId) {
      // 見つかった時はからの配列を返し、再帰的にkeyの値を詰める
      return [];
    }
    // 見つからなかった時は、nullを返す
    return null;
  }

  let result = null;

  Object.keys(cellMap).some((key) => {
    // 途中までkeyを指定した状態で、残りのkeyを全探索する。
    // 見つからなければnullになる
    result = searchFactorIds(cellMap[key], cellId);
    if (result) {
      result.unshift(key);
      return true;
    }
    return false;
  });

  return result;
}
