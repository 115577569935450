import React, { FC } from "react";

const AccountRegistrationWarning: FC = () => (
  <div className="msg warning">
    <div className="msg-header">
      <p className="txt txt-lg">アカウントご登録前の注意</p>
    </div>
    <div className="msg-content">
      <ul>
        <li>
          <p>
            連携後に「ログインに失敗しました」というメッセージが表示された場合、一度本サイトにログインできるかのご確認をお願いします。
          </p>
        </li>
        <li>
          <p>
            連携後に「重要なお知らせがあります」というメッセージが表示された場合、一度本サイトにログインしてお知らせ画面を閉じた上で再取得を行ってください。
          </p>
        </li>
      </ul>
    </div>
  </div>
);

export default AccountRegistrationWarning;
