import { ListForm } from "components/renewaled_ui/form_views";
import i18next from "i18n";
import React, { FC } from "react";
import styled from "styled-components";
import { InvoicingOrganization } from "utilities/api_managements/models/InvoicingOrganization";
import RegistratedNumberAndInvoicingOrganizationForm from "./RegistratedNumberAndInvoicingOrganizationForm";

const EligibleInvoiceConfirmationFormWrapper = styled.div`
  padding-top: 10px;
  padding-left: 10px;
  padding-right: 0px;
  padding-bottom: 0px;
`;

interface Props {
  asEligibleInvoice: boolean;
  registratedNumber: string; // nullな時もあるが、EntryFormsの中で''を代入
  invoicingOrganization: InvoicingOrganization | null;
  paidAddress: string; // nullな時もあるが、EntryFormsの中で''を代入
  isRegistratedNumberShow: boolean;
  isPaidAddressShow: boolean;
}

const EligibleInvoiceConfirmationReadOnlyForm: FC<Props> = (props) => {
  const {
    asEligibleInvoice,
    registratedNumber,
    invoicingOrganization,
    paidAddress,
    isRegistratedNumberShow,
    isPaidAddressShow,
  } = props;

  return (
    <EligibleInvoiceConfirmationFormWrapper className="eligible-invoice-confirmation-view">
      {/* 適格請求書として扱う */}
      <ListForm
        key={"as_eligible_invoice_input"}
        label={i18next.t("transactions.properties.asEligibleInvoice")}
        isViewMode={true}
      >
        {asEligibleInvoice
          ? i18next.t("transactions.properties.eligibleInvoice.regardAs")
          : i18next.t("transactions.properties.eligibleInvoice.notRegardAs")}
      </ListForm>

      {/* 登録番号および適格請求書発行事業者 詳細  */}
      <RegistratedNumberAndInvoicingOrganizationForm
        readOnly={true}
        registratedNumber={registratedNumber}
        invoicingOrganization={invoicingOrganization}
        isRegistratedNumberShow={isRegistratedNumberShow}
      />

      {/* 住所又は所在地 */}
      {isPaidAddressShow && (
        <ListForm
          key={"as_paid_address_input"}
          label={i18next.t("transactions.properties.paidAddress")}
          isViewMode={true}
        >
          {paidAddress}
        </ListForm>
      )}
    </EligibleInvoiceConfirmationFormWrapper>
  );
};

EligibleInvoiceConfirmationReadOnlyForm.defaultProps = {
  asEligibleInvoice: false,
  registratedNumber: "",
  invoicingOrganization: null,
  paidAddress: "",
  isRegistratedNumberShow: true,
  isPaidAddressShow: false,
};

export default EligibleInvoiceConfirmationReadOnlyForm;
