import { displayMessage } from "actions/ActionCreators";
import TransactionModalWrapper from "applications/transactions/containers/TransactionModalWrapper";
import BootstrapCard from "components/BootstrapCard";
import i18next from "i18n";
import get from "lodash/get";
import isNil from "lodash/isNil";
import PropTypes from "prop-types";
import React, { Component } from "react";
import { connect } from "react-redux";
import { initialPreTransaction } from "values/initial_pre_transaction";
import * as formStateActions from "../actions/formStateActions";
import * as preReportActions from "../actions/preReportActions";
import * as preReportRequestActions from "../actions/preReportRequestActions";
import PreTransactionTable from "../components/PreTransactionTable";
import makePreTransactionTableStateMapper from "../selectors/preTransactionTable";

export class PreTransactionTableCard extends Component {
  constructor(props) {
    super(props);

    this.handleClickAddButton = this.handleClickAddButton.bind(this);
    this.handleConvertBtnClick = this.handleConvertBtnClick.bind(this);
    this.handleCreateSuccess = this.handleCreateSuccess.bind(this);
    this.handleOpenPreTransactionModal =
      this.handleOpenPreTransactionModal.bind(this);
  }

  handleClickAddButton() {
    const initParameter = {
      ...initialPreTransaction(),
      project: this.props.preReport.project,
    };
    this.props.setIsNew();
    this.handleOpenPreTransactionModal(initParameter);
  }

  handleConvertBtnClick(row, formId) {
    const { entryForms } = this.props;
    const entryForm = entryForms.find((form) => form.id === formId);
    const preReport = {
      ...this.props.preReport,
      fields: get(
        entryForms.find((form) => form.id === "pre_report"),
        "formFields",
        [],
      ),
    };

    this.props.openTransactionModal(preReport, row, entryForm);
  }

  handleCreateSuccess(expenses, reuseInput) {
    if (!reuseInput) {
      this.props.closeTransactionModal();
    }
    this.props.onCreateSuccess(expenses, this.props.resetTransactions);
  }

  handleOpenPreTransactionModal(initParameter) {
    this.props.openPreTransactionModal(initParameter);
  }

  render() {
    const {
      editable,
      transactionAddable,
      hasApproved,
      preTransactions,
      entryForms,
    } = this.props;
    const buttons = editable
      ? [
          {
            color: "primary",
            icons: [{ position: "right", className: "fa-angle-right" }],
            content: i18next.t("preReports.items.addItems"),
            onClick: this.handleClickAddButton,
          },
        ]
      : [];
    const entryForm = entryForms.find((form) => {
      if (form.id === "allowance") {
        return (
          form.directProductTableId ===
          this.props.modalTransaction.directProductTableId
        );
      }

      return form.id === this.props.modalTransaction.formId;
    });

    return (
      <BootstrapCard
        title={i18next.t("preReports.items.menu")}
        buttons={buttons}
      >
        <PreTransactionTable
          preTransactions={preTransactions}
          editable={editable}
          transactionAddable={transactionAddable}
          hasApproved={hasApproved}
          onRowClick={this.handleOpenPreTransactionModal}
          onConvertBtnClick={this.handleConvertBtnClick}
          onDeleteBtnClick={this.props.deletePreTransaction}
        />
        <TransactionModalWrapper
          show={this.props.isTransactionModalOpen}
          formFields={get(entryForm, "formFields", [])}
          transaction={this.props.modalTransaction}
          onCreateSuccess={this.handleCreateSuccess}
          closeModal={this.props.closeTransactionModal}
          shouldSelectSelfAsCompanion={this.props.shouldSelectSelfAsCompanion}
          onSelectCompanionsCategory={this.props.onSelectCompanionsCategory}
          fromPreTransaction={true}
        />
      </BootstrapCard>
    );
  }
}

PreTransactionTableCard.propTypes = {
  preReport: PropTypes.object,
  preTransactions: PropTypes.arrayOf(PropTypes.object),
  editable: PropTypes.bool.isRequired,
  isTransactionModalOpen: PropTypes.bool,
  modalTransaction: PropTypes.object,
  resetTransactions: PropTypes.func,
};

function mapDispatchToProps(dispatch, ownProps) {
  return {
    deletePreTransaction(key) {
      dispatch(preReportActions.deletePreTransaction(key));
      dispatch(preReportRequestActions.setShouldSave(true));
    },
    openPreTransactionModal(preTransaction) {
      const preTransactionCategory = preTransaction.category;
      if (
        !isNil(preTransactionCategory) &&
        preTransactionCategory.requiresCompanion
      ) {
        dispatch(formStateActions.setShouldSelectSelfAsCompanion(false));
      }
      dispatch(formStateActions.openPreTransaction(preTransaction));
    },
    openTransactionModal(preReport, preTransaction, entryForm) {
      const preTransactionCategory = preTransaction.category;
      if (
        !isNil(preTransactionCategory) &&
        preTransactionCategory.requiresCompanion
      ) {
        dispatch(formStateActions.setShouldSelectSelfAsCompanion(false));
      }
      dispatch(
        formStateActions.openTransactionModal(
          preReport,
          preTransaction,
          entryForm,
        ),
      );
    },
    closeTransactionModal() {
      dispatch(formStateActions.setShouldSelectSelfAsCompanion(true));
      dispatch(formStateActions.hideTransactionModal());
    },
    onSelectCompanionsCategory() {
      dispatch(formStateActions.setShouldSelectSelfAsCompanion(false));
    },
    setIsNew() {
      dispatch(formStateActions.setIsNew(true));
    },
    onCreateSuccess(_expenses, callback) {
      if (typeof callback === "function") {
        dispatch(
          displayMessage("success", i18next.t("transactions.messages.create")),
        );
        callback();
      }
    },
  };
}

export default connect(
  makePreTransactionTableStateMapper,
  mapDispatchToProps,
)(PreTransactionTableCard);
