import * as ActionTypes from "../actions/preReportRequestActions";

const initialState = {
  title: null,
  preTransactions: [],
  approvalFlow: null,
  showModal: false,
  showReapplyModal: false,
  showPreReportReapplyModal: false,
  shouldShowPaymentModal: false,
  paymentComment: "",
  paymentDate: "",
  shouldDisablePaymentButton: false,
  updateFlow: false,
  comment: null,
  requestState: {
    isJobRunning: false,
    jobType: "",
  },
  isRejectModalOpen: false,
  rejectReason: "",
  shouldSave: false,
};

const preReportRequest = (state = initialState, action) => {
  switch (action.type) {
    case ActionTypes.SET_PRE_REPORT_REQUEST: {
      return {
        ...state,
        ...action.preReportRequest,
      };
    }
    case ActionTypes.SHOW_APPROVAL_FLOWS_MODAL: {
      return {
        ...state,
        showModal: true,
      };
    }
    case ActionTypes.SET_UPDATE_FLOW: {
      return {
        ...state,
        updateFlow: action.payload.isUpdatingFlow,
      };
    }
    case ActionTypes.CLOSE_APPROVAL_FLOWS_MODAL: {
      return {
        ...state,
        showModal: false,
      };
    }
    case ActionTypes.TOGGLE_SHOW_REAPPLY_MODAL: {
      return {
        ...state,
        showReapplyModal: !state.showReapplyModal,
      };
    }
    case ActionTypes.SHOW_PRE_REPORT_REAPPLY_MODAL: {
      return {
        ...state,
        showPreReportReapplyModal: true,
      };
    }
    case ActionTypes.CLOSE_PRE_REPORT_REAPPLY_MODAL: {
      return {
        ...state,
        showPreReportReapplyModal: false,
      };
    }
    case ActionTypes.SHOW_PAYMENT_MODAL: {
      return {
        ...state,
        shouldShowPaymentModal: action.payload.shouldShow,
      };
    }
    case ActionTypes.SET_PAYMENT_COMMENT: {
      return {
        ...state,
        paymentComment: action.payload.comment,
      };
    }
    case ActionTypes.SET_PAYMENT_DATE: {
      return {
        ...state,
        paymentDate: action.payload.date,
      };
    }
    case ActionTypes.DISABLE_PAYMENT_BUTTON: {
      return {
        ...state,
        shouldDisablePaymentButton: action.payload.shouldDisable,
      };
    }
    case ActionTypes.SET_COMMENT: {
      return {
        ...state,
        comment: action.comment,
      };
    }
    case ActionTypes.START_PRE_REPORT_REQUEST_JOB: {
      return {
        ...state,
        requestState: {
          ...state.requestState,
          jobType: action.jobType,
          isJobRunning: true,
        },
      };
    }
    case ActionTypes.CLOSE_PRE_REPORT_REQUEST_JOB: {
      return {
        ...state,
        requestState: {
          ...state.requestState,
          jobType: "",
          isJobRunning: false,
        },
      };
    }
    case ActionTypes.SHOW_REJECT_MODAL: {
      return {
        ...state,
        isRejectModalOpen: true,
      };
    }
    case ActionTypes.CLOSE_REJECT_MODAL: {
      return {
        ...state,
        isRejectModalOpen: false,
      };
    }
    case ActionTypes.INPUT_REJECT_REASON: {
      return {
        ...state,
        rejectReason: action.value,
      };
    }
    case ActionTypes.SET_SHOULD_SAVE: {
      return {
        ...state,
        shouldSave: action.shouldSave,
      };
    }
    default:
      return state;
  }
};

export default preReportRequest;
