import * as ActionTypes from '../actions';
import * as MonthlyUsageActionTypes from '../actions/monthlyUsage';
import isEmpty from 'lodash/isEmpty';
import isNil from 'lodash/isNil';

const MONTHLY_USAGE_YEAR_CURRENT = new Date().getFullYear();
const MONTHLY_USAGE_YEAR_MIN = 2015;

export const monthlyUsageInitialState = {
  year: MONTHLY_USAGE_YEAR_CURRENT,
  yearRange:
    [...Array(MONTHLY_USAGE_YEAR_CURRENT - MONTHLY_USAGE_YEAR_MIN + 1).keys()]
      .map((x) => x + MONTHLY_USAGE_YEAR_MIN)
      .reverse(),
  tableData: [],
};

export const initialState = {
  expiry: null,
  isAdmin: userPreferences.isAdmin,
  activePlan: 'corporate',
  planId: 'corporate_yearly',
  instructionType: null,
  cancelled: false,
  isPaymentFormModalOpen: false,
  inProcess: false,
  errorMessage: null,
  planInfo: null,
  payment: null,
  formData: {
    cardNum: null,
    securityCode: null,
    expiryMonth: null,
    expiryYear: new Date().getFullYear(),
    stripeToken: null,
  },
  csrfToken: null, // Railsがhtmlに出力しているトークンを入れておくところ
  monthlyUsage: {
    ...monthlyUsageInitialState,
  },
};

function convertToPerMonthArray(year, data) {
  return isEmpty(data)
    ? []
    : [...Array(12).keys()]
      .map((v) => [year, `0${v + 1}`.slice(-2)].join('-'))
      .map((ym) => ({
        yearMonth: ym,
        count: isNil(data[ym]) ? null : data[ym].count,
      }));
}

const monthlyUsageReducer = (state = monthlyUsageInitialState, action) => {
  switch (action.type) {
    case MonthlyUsageActionTypes.SET_YEAR: {
      return {
        ...state,
        year: parseInt(action.value, 10),
      };
    }
    case MonthlyUsageActionTypes.SET_TABLE_DATA: {
      return {
        ...state,
        tableData: convertToPerMonthArray(state.year, action.data),
      };
    }
    default: return state;
  }
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case ActionTypes.SWITCH_PLAN_TAB: {
      return {
        ...state,
        activePlan: action.plan,
      };
    }
    case ActionTypes.SELECT_PLAN: {
      return {
        ...state,
        planId: action.id,
      };
    }
    case ActionTypes.SET_ERROR_MESSAGE: {
      return {
        ...state,
        errorMessage: action.message,
      };
    }
    case ActionTypes.SET_FORM_DATA: {
      return {
        ...state,
        formData: {
          ...state.formData,
          [action.prop]: action.value,
        },
      };
    }
    case ActionTypes.TOGGLE_PAYMENT_FORM_MODAL: {
      return {
        ...state,
        isPaymentFormModalOpen: action.show,
      };
    }
    case ActionTypes.TOGGLE_FORM_BUTTON: {
      return {
        ...state,
        inProcess: action.lock,
      };
    }
    case ActionTypes.SET_CSRF_TOKEN: {
      return {
        ...state,
        csrfToken: action.csrfToken,
      };
    }
    case MonthlyUsageActionTypes.SET_YEAR:
    case MonthlyUsageActionTypes.SET_TABLE_DATA: {
      return {
        ...state,
        monthlyUsage: monthlyUsageReducer(state.monthlyUsage, action),
      };
    }
    default: return state;
  }
};

export default reducer;
