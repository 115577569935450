// Types
export interface FormValues {
  email: string;
}

export interface FormErrors {
  email?: string;
  submit?: string;
}

export const validateEmailInput = (values: FormValues): FormErrors => {
  const errors: FormErrors = {};
  if (!values.email) {
    errors.email = 'メールアドレスを入力してください。';
  }
  return errors;
};
