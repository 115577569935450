import React from "react";
import styled from "styled-components";
import colors from "values/colors";
import LogoGoogle from "../assets/google.svg";
import LogoOffice from "../assets/microsoft.svg";

const OauthGroup = styled.div`
  max-width: 300px;
  margin: 0 auto 28px;
  .or-text {
    text-align: center;
    font-size: 1.2rem;
    font-weight: bold;
    margin-bottom: 12px;
  }
  .form-group {
    margin-bottom: 10px;
  }
  button {
    color: #616161;
    height: 39px;
    margin: 0 auto;
    max-width: 240px;
    border-radius: 10px;
    font-weight: bold;
    background-color: #fff;
    border: ${colors.gray} 1px solid;
    font-size: 1.4rem;
    transition: 0.3s;
    &:hover {
      background-color: #eceff1;
    }
    &.sub-domain-btn {
      color: #616161;
    }
  }
`;

/**
 * OAuthのログイン項目一覧
 */
export const OAuthGroup: React.FC = () => {
  const csrfTokenNode = document.querySelector(
    'head meta[name="csrf-token"]',
  ) as HTMLMetaElement;
  const csrfParamNode = document.querySelector(
    'head meta[name="csrf-param"]',
  ) as HTMLMetaElement;
  // タグが見つからない場合はテストで警告が出ないように空文字を使う
  const csrfParamName = csrfParamNode ? csrfParamNode.content : "";
  const csrfTokenValue = csrfTokenNode ? csrfTokenNode.content : "";

  return (
    <OauthGroup className="oauth-group">
      <p className="or-text">または</p>
      <div className="form-group">
        <form action="/omniauth/google_oauth2" method="post">
          <input name={csrfParamName} type="hidden" value={csrfTokenValue} />
          <button type="submit" className="btn-block google-btn">
            <img
              className=""
              src={LogoGoogle}
              alt="Google"
              width="20"
              style={{ marginRight: "6px" }}
            />
            Googleでログイン
          </button>
        </form>
      </div>
      <div className="form-group">
        <form action="/omniauth/microsoft_office365" method="post">
          <input name={csrfParamName} type="hidden" value={csrfTokenValue} />
          <button type="submit" className="btn-block">
            <img
              className=""
              src={LogoOffice}
              alt="Microsoft 365"
              width="18"
              style={{ marginRight: "6px" }}
            />
            Microsoft 365でログイン
          </button>
        </form>
      </div>
      <div className="form-group">
        <input name={csrfParamName} type="hidden" value={csrfTokenValue} />
        <button
          type="submit"
          onClick={(): void => {
            window.location.href = "/subdomains/input";
          }}
          className="btn-block sub-domain-btn"
        >
          サブドメインを入力
        </button>
      </div>
    </OauthGroup>
  );
};
