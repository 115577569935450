import InfiniteSelector from 'components/fields/InfiniteSelector';
import LazyElement from 'components/LazyElement';
import React, { useMemo } from 'react';
import colors from 'values/colors';
import i18next from 'i18next';

interface Props<AssignableReport> {
  options: AssignableReport[];
  onSelect: (assignableReport: AssignableReport) => void;
  value: AssignableReport;
  isLoading: boolean;
}

interface RequiredSelectorProps { id: string; title: string; }

/** 経費に割り当て可能な申請書（事前申請・経費申請）セレクタ */
export const AssignableReportSelector = <AssignableReport extends RequiredSelectorProps, >(
  {
    options, onSelect, value, isLoading,
  }: Props<AssignableReport>,
): JSX.Element => {
  const formatOption = (item): JSX.Element => {
    return (
      <div className='list-selector-item-content'>
        <LazyElement height={ 19 } margin='4px 0'>
          { item && (
            <div style={ {
              whiteSpace: 'nowrap',
              overflow: 'hidden',
              textOverflow: 'ellipsis',
              maxWidth: '490px',
            } }
            >{ item.title }</div>
          ) }
        </LazyElement>
      </div>
    );
  };

  const maxCount = useMemo(() => 100, []);

  const selectableCount = useMemo(() => Math.min(maxCount, options.length), [maxCount, options.length]);

  if (isLoading) {
    return (
      <div style={ { textAlign: 'center', color: colors.renewaled.textGrey } }>
        <i className="fas fa-lg fa-spin fa-spinner" />
      </div>
    );
  }

  if (selectableCount === 0) {
    return (
      <div style={ { color: colors.renewaled.textGrey } }>
        { i18next.t('reports.messages.noSelectableReports') }
      </div>);
  }

  return (
    <InfiniteSelector
      responseKey='pre-report'
      isMultiSelectable={ false }
      options={ options }
      selection={ value }
      batchSize={ maxCount }
      totalCount={ selectableCount }
      rowHeight={ 40 }
      formatOption={ formatOption }
      onSelect={ onSelect }
    >
      { (renderSelector): JSX.Element => renderSelector() }
    </InfiniteSelector>
  );
};
