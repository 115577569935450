import i18next from "i18n";
import moment from "moment";
import PropTypes from "prop-types";
import React, { Component } from "react";
import { Alert, Button, ButtonToolbar, Modal } from "react-bootstrap";
import { BootstrapTable, TableHeaderColumn } from "react-bootstrap-table";

class ManualMergeModal extends Component {
  renderPearTable(pairIndex, pair, suggestTransactions, options) {
    return (
      <div key={pairIndex}>
        <div className="col-md-1">
          <input
            style={{
              zIndex: 1,
              position: "relative",
              top: "12px",
              marginLeft: "30px",
            }}
            type="checkbox"
            checked={pair.selected || false}
            onChange={() => this.props.selectMergeTarget(pairIndex)}
          />
        </div>
        <div className="col-md-11">
          <BootstrapTable
            data={suggestTransactions}
            options={options}
            tableBodyClass="multiline"
          >
            <TableHeaderColumn dataField="id" hidden={true} isKey={true} />
            <TableHeaderColumn
              dataField="inputBy"
              dataFormat={(cell, row) => {
                if (!row.id) {
                  return <div>統合結果</div>;
                }
                switch (cell) {
                  case "worker":
                    return <div>自動入力</div>;
                  case "aggregation":
                    return <div>カード連携</div>;
                  case "user":
                    return <div>ユーザ入力</div>;
                  default:
                    return <div></div>;
                }
              }}
              width="120"
            >
              元データ
            </TableHeaderColumn>
            <TableHeaderColumn
              dataField="transactedAt"
              dataFormat={(cell) => moment(cell).format("YYYY/MM/DD")}
              width="100"
            >
              利用日
            </TableHeaderColumn>
            <TableHeaderColumn dataField="shopName" width="280">
              支払先
            </TableHeaderColumn>
            <TableHeaderColumn dataField="amount" width="100">
              金額
            </TableHeaderColumn>
            <TableHeaderColumn dataField="categoryName" width="120">
              経費科目
            </TableHeaderColumn>
          </BootstrapTable>
        </div>
      </div>
    );
  }

  renderBody() {
    const { mergeTargetList } = this.props;

    if (mergeTargetList.length < 1) {
      return (
        <Alert bsStyle="warning">
          統合可能な経費の組み合わせは見つかりませんでした。
        </Alert>
      );
    }

    const options = {
      noDataText: i18next.t("commons.messages.noDataFound"),
    };

    const pairTableList = mergeTargetList.map((pair, pairIndex) => {
      const suggestTransactions = [
        pair.forWorker,
        ...pair.forAggregation,
        pair.mergedSample,
      ];
      return this.renderPearTable(
        pairIndex,
        pair,
        suggestTransactions,
        options,
      );
    });

    return (
      <div>
        <div
          className="col-sm-offset-1 col-sm-11"
          style={{ marginBottom: "20px" }}
          key="title"
        >
          統合可能な経費の組み合わせは以下のとおりです。
        </div>
        {pairTableList}
      </div>
    );
  }

  renderFooter() {
    const { mergeTargetList } = this.props;

    if (mergeTargetList.length < 1) {
      return (
        <Button bsStyle="default" onClick={this.props.onHide}>
          キャンセル
        </Button>
      );
    }

    return (
      <div>
        <Button
          bsStyle="primary"
          onClick={() => this.props.mergeTransactions("selected")}
        >
          選択した組合せを統合する
        </Button>
        <Button bsStyle="default" onClick={this.props.onHide}>
          キャンセル
        </Button>
      </div>
    );
  }

  render() {
    const { show, onHide } = this.props;
    return (
      <Modal
        show={show}
        onHide={onHide}
        dialogClassName="transaction-manual-merge-modal"
      >
        <Modal.Header>経費の手動統合</Modal.Header>
        <Modal.Body>
          <ButtonToolbar>{this.renderBody()}</ButtonToolbar>
        </Modal.Body>
        <Modal.Footer>{this.renderFooter()}</Modal.Footer>
      </Modal>
    );
  }
}

ManualMergeModal.propTypes = {
  show: PropTypes.bool.isRequired,
  onHide: PropTypes.func.isRequired,
  mergeTargetList: PropTypes.array.isRequired,
  mergeTransactions: PropTypes.func.isRequired,
  selectMergeTarget: PropTypes.func.isRequired,
};

export default ManualMergeModal;
