import { useCallback } from 'react';

interface UseReturn {
  readonly items: () => number[];
  readonly canNextPage: () => boolean;
  readonly canPreviousPage: () => boolean;
  readonly canPreviousTopPage: () => boolean;
  readonly canLastPage: () => boolean;
  readonly onClickNext: () => void;
  readonly onClickPrev: () => void;
  readonly onClickTopPage: () => void;
  readonly onClickLastPage: () => void;
  readonly onClickPageItem: (page: number) => void;
}

/**
 * ロジック: テーブルのページネーション
 */
export const useHooks = (onPageChange: (page: number, sizePerPage: number) => void, currentPage: number, paginationSize: number, sizePerPage: number): UseReturn => {
  // ページングの最大数
  const PAGEING_SIZE = 5;
  // 中間の数
  const PAGEING_CENTER = 3;

  /**
   * 指定範囲の数字配列を返す
   */
  const range = (start: number, end: number): number[] => {
    const size = end - start + 1;
    return Array.from(Array(size).keys()).map((i) => i + start);
  };

  /**
   * ページネーション先頭の番号を返す
   */
  const getStartNum = (): number => {
    // 現在のページが中央値以下ならば、1を返す
    if (currentPage <= PAGEING_CENTER) return 1;
    // 最大サイズを超えてなければ、1を返す
    if (paginationSize <= PAGEING_SIZE) return 1;
    return currentPage - 2;
  };

  /**
   * ページネーション最後の番号を返す
   */
  const getEndNum = (): number => {
    // ページ最大サイズを超えてなければ、データ最大サイズを返す
    if (paginationSize < PAGEING_SIZE) return paginationSize;
    // 現在のページが中央値以下ならば、データ最大サイズを返す
    if (currentPage <= PAGEING_CENTER) return PAGEING_SIZE;
    // データ最大サイズが上限
    if (currentPage + 2 > paginationSize) return paginationSize;
    return currentPage + 2;
  };

  /**
   * ページネーションの番号
   */
  const items = (): number[] => {
    const start = getStartNum();
    const end = getEndNum();
    return range(start, end);
  };

  /**
   * 次のページに移動できるか
   */
  const canNextPage = useCallback((): boolean => {
    return currentPage !== paginationSize;
  }, [currentPage, paginationSize]);

  /**
   * 前のページに移動できるか
   */
  const canPreviousPage = useCallback((): boolean => {
    return currentPage !== 1;
  }, [currentPage]);

  /**
   * 最後のページに移動できるか
   */
  const canLastPage = useCallback((): boolean => {
    return currentPage < paginationSize - 2;
  }, [currentPage, paginationSize]);

  /**
   * トップのページに移動できるか
   */
  const canPreviousTopPage = useCallback((): boolean => {
    return currentPage > 3;
  }, [currentPage]);

  /**
   * 次のページへ移動
   */
  const onClickNext = (): void => {
    if (currentPage >= paginationSize) return;
    onPageChange(currentPage + 1, sizePerPage);
  };

  /**
   * 先頭のページへ移動
   */
  const onClickTopPage = (): void => {
    onPageChange(1, sizePerPage);
  };

  /**
   * 前のページへ移動
   */
  const onClickPrev = (): void => {
    if (currentPage <= 1) return;
    onPageChange(currentPage - 1, sizePerPage);
  };

  /**
   * 最後のページへ移動
   */
  const onClickLastPage = (): void => {
    onPageChange(paginationSize, sizePerPage);
  };

  /**
   * 指定のページへ移動
   */
  const onClickPageItem = (pageNum: number): void => {
    onPageChange(pageNum, sizePerPage);
  };

  return {
    items,
    canNextPage,
    canPreviousPage,
    canPreviousTopPage,
    canLastPage,
    onClickNext,
    onClickPrev,
    onClickTopPage,
    onClickLastPage,
    onClickPageItem,
  };
};
