import ClearableTextFormControl from 'applications/payment_requests/components/ClearableTextFormControl';
import React from 'react';
import i18next from 'i18next';
import { AssignableReportSelector } from './AssignableReportSelector';
import {
  Modal, ModalBody, ModalHeader,
} from 'components/renewaled_ui/single_views';
import { PreReport, Report } from 'utilities/api/models';
import { TabPanel, Tabs } from 'components/renewaled_ui/Tab';
import { useAssignableReportInputModal } from 'hooks/useAssignableReportInputModal';

interface Props {
  show: boolean;
  value: PreReport | Report | null;
  onClose: () => void;
  onSelectPreReport: (preReport: PreReport) => void;
  onSelectReport: (report: Report) => void;
}

/** 経費にアタッチする事前申請・経費申請選択モーダル */
export const AssignableReportInputModal: React.FC<Props> = ({
  show, value, onClose, onSelectPreReport, onSelectReport,
}) => {
  const {
    selectedTabNum,
    preReportOptions,
    reportOptions,
    searchedAssignableReportTitle,
    modalTitle,
    tabs,
    isLoadingPreReport,
    isLoadingReport,
    usePreReportRequest,
    setSelectedTabNum,
    handleChangeText,
  } = useAssignableReportInputModal();

  return (
    <Modal
      width='sm'
      show={ show }
      backdrop
      onClose={ onClose }
      fillContent
      className='assignable-report-input-modal'
    >
      <ModalHeader title={ modalTitle } onClose={ onClose } />
      <ModalBody>
        <div style={ { padding: '16px' } }>
          <div style={ { marginBottom: '8px' } }>
            { usePreReportRequest ? (
              i18next.t('transactions.messages.expenseCanBeAttachedToAlreadyCreatedReportsOrPreReports')
            ) : (
              i18next.t('transactions.messages.expenseCanBeAttachedToAlreadyCreatedReports')
            )}
          </div>
          <ClearableTextFormControl
            value={ searchedAssignableReportTitle }
            onChange={ handleChangeText }
            placeholder={ i18next.t('reports.searchConditions.searchWithReportTitle') }
          />
        </div>
        { usePreReportRequest ? (
          <>
            <Tabs
              value={ selectedTabNum }
              tabs={ tabs }
              onSelect={ (v: number): void => setSelectedTabNum(v) }
            />
            <div style={ { padding: '16px' } }>
              <TabPanel
                value={ selectedTabNum }
                selectedIndex={ 1 }
              >
                <AssignableReportSelector
                  value={ value as Report }
                  options={ reportOptions }
                  onSelect={ onSelectReport }
                  isLoading={ isLoadingReport }
                />
              </TabPanel>
              <TabPanel
                value={ selectedTabNum }
                selectedIndex={ 2 }
              >
                <AssignableReportSelector
                  value={ value as PreReport }
                  options={ preReportOptions }
                  onSelect={ onSelectPreReport }
                  isLoading={ isLoadingPreReport }
                />
              </TabPanel>
            </div>
          </>
        ) : (
          <div style={ { padding: '16px' } }>
            <AssignableReportSelector
              value={ value as Report }
              options={ reportOptions }
              onSelect={ onSelectReport }
              isLoading={ isLoadingReport }
            />
          </div>
        ) }
      </ModalBody>
    </Modal>
  );
};
