import React from 'react';
import i18next from 'i18n';

interface Props {
  disabled: boolean;
  value: string;
  type: 'text' | 'number';
  onChange: (value: string) => void;
}

/**
 * 汎用フォームのテキスト入力
 */
export const FormItemTextInput: React.FC<Props> = React.memo(({
  disabled, value, type, onChange,
}) => {
  return (
    <input
      disabled={ disabled }
      value={ value }
      type={ type }
      className="form-control toggle"
      placeholder={ i18next.t('commons.status.notEntered') }
      onChange={ (e: React.ChangeEvent<HTMLInputElement>): void => { onChange(e.target.value); }  }/>
  );
});
