import ActionTypes from 'actions/ActionTypes';
import _get from 'lodash/get';
import _set from 'lodash/set';
import flash from 'utilities/flash';
import isNil from 'lodash/isNil';

export function configureReducers(appReducers) {
  return (state, action) => {
    switch (action.type) {
      case ActionTypes.DISPLAY_MESSAGE: {
        const messenger = action.messenger || flash;
        messenger.clear();

        let message = action.message;
        if (action.level === 'error' && isNil(action.message)) {
          message = 'システムエラーが発生しました。お手数ですが、サポートにお問い合わせください。';
        }
        if (action.message && typeof messenger[action.level] === 'function') {
          if (action.options) {
            messenger.callWithOptions(action.level, message, action.options);
          } else {
            messenger[action.level](message);
          }
        }
        return state;
      }
      case ActionTypes.CLEAR_MESSAGE: {
        const messenger = action.messenger || flash;
        setTimeout(messenger.clear, action.time);
        return state;
      }
      case ActionTypes.REDIRECT_TO: {
        window.location.href = action.url;
        return state;
      }
      case ActionTypes.OPEN_TAB: {
        window.open(action.url, '_blank');
        return state;
      }
      case ActionTypes.CHANGE_URL: {
        window.history.pushState(null, action.title || $('title').text(), action.url);
        return state;
      }
      case ActionTypes.RESET_STATE: {
        const nextState = appReducers(void 0, action);
        if (action.exclude.length > 0) {
          action.exclude.forEach((prop) => {
            _set(nextState, prop, _get(state, prop));
          });
        }
        return nextState;
      }
      default:
        return appReducers(state, action);
    }
  };
}

export default {
  configureReducers,
};
