import { useEffect, useState } from "react";

type MinMaxArgs = {
  readonly minWidth: number;
  readonly maxWidth: number;
  readonly minHeight: number;
  readonly maxHeight: number;
};

/**
 * 画像の縦横のサイズを取得する
 * 最大値と最小値以内に収まるように調整する
 */
const useMinMaxSize = (args: MinMaxArgs, url?: string): [number, number] => {
  const { minWidth, maxWidth, minHeight, maxHeight } = args;

  const [width, setWidth] = useState<number>(minWidth);
  const [height, setHeight] = useState<number>(minHeight);

  useEffect(() => {
    if (!url) {
      return;
    }

    const image = new Image();

    // 画像が読み込まれた
    image.onload = (): void => {
      const nextWidth = Math.min(Math.max(minWidth, image.width), maxWidth);
      const ratio = nextWidth / image.width;
      const scaledHeight = image.height * ratio;

      setWidth(nextWidth);
      setHeight(Math.min(Math.max(minHeight, scaledHeight), maxHeight));
    };

    image.src = url;
  }, [url, minWidth, maxWidth, minHeight, maxHeight]);

  return [width, height];
};

export default useMinMaxSize;
