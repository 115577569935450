import { getExpenseApi } from "api_builders/ExpenseApi";
import TransactionModalWrapper from "applications/transactions/containers/TransactionModalWrapper"; // disable-eslint-lint import/no-cycle
import { FormField } from "applications/transactions/types/ExpenseEntryFormRequest";
import { EntryForm } from "components/types";
import React, { useEffect, useState } from "react";
import Api from "utilities/api";
import { Expense } from "utilities/api/models";

interface Props {
  readonly closeModal: () => void;
  readonly expenseId: string;
  readonly show: boolean;
}

const selectFormFields = (
  expense: Expense,
  entryForms: EntryForm[],
): FormField[] => {
  const entryForm = entryForms.find((form) => {
    if (form.id === "allowance") {
      return form.directProductTableId === expense.formId;
    }

    return form.id === expense.formId;
  });

  return entryForm?.formFields || [];
};

/**
 * 経費IDのみ指定すれば読み取り専用でモーダル表示
 */
export const ExpenseReadOnlyModal: React.FC<Props> = ({
  closeModal,
  expenseId,
  show,
}) => {
  const [entryForms, setEntryForms] = useState<EntryForm[] | null>(null);
  const [expense, setExpense] = useState<Expense | null>(null);

  useEffect(() => {
    const fetch = async (): Promise<void> => {
      const data = await Api.entryForms.index();
      setEntryForms(data.summaries);
    };
    fetch();
  }, [setEntryForms]);

  useEffect(() => {
    if (show && entryForms) {
      const fetch = async (id: string): Promise<void> => {
        const data = await getExpenseApi().show({ id });
        setExpense(data);
      };
      fetch(expenseId);
    }
    return (): void => {
      setExpense(null);
    };
  }, [show, expenseId, entryForms, setExpense]);

  return (
    expense &&
    entryForms && (
      <TransactionModalWrapper
        show={show}
        closeModal={closeModal}
        formFields={selectFormFields(expense, entryForms)}
        transaction={expense}
        shouldSelectSelfAsCompanion={false}
      />
    )
  );
};
