import React from 'react';

/** ツールバー(右)
 *  styleは./index.tsxで指定
 */
export const ToolBarRight: React.FC = ({ children }) => {
  // childrenが個数によって型が変わるので出し分ける。配列の場合は固定マージンを設け横に並べる。
  const renderToolBarContents = (): JSX.Element => {
    if (!children) return <></>;

    if (!Array.isArray(children)) {
      return <>{ children }</>;
    }

    return (
      <>
        {children.map((child, index) => {
          if (!child) return null;

          return (
            <div key={ `toolbar-right-item-${index}` } style={ { marginLeft: '8px' } }>{ child }</div>
          );
        })}
      </>
    );
  };

  return (
    <div className="toolbar-right">
      {renderToolBarContents()}
    </div>
  );
};
