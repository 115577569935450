import Api from 'utilities/api';
import isNil from 'lodash/isNil';
import { snakecaseKeys } from 'utilities/Utils';

// S3のPresigned URLを取得する
export function getPresignedUrlGeneratorPath(imageId) {
  return `/api/v1/s3_images/url/${imageId}`;
}

/**
 * @params {UUID} imageId - 取得する画像に関して DK が保存している画像 ID
 * @params {func} onFetch - 取得した S3 URL を取得したあとの処理
 */
export function fetchPresignedUrl(imageId, onFetch, toImage = false) {
  if (isNil(imageId)) {
    return Promise.reject('image id is not specified');
  }

  const params = { id: imageId };
  if (toImage) {
    params.toImage = true;
  }

  return Promise.resolve(Api.s3Files.presignedUrl(snakecaseKeys(params)))
    .then((data) => (onFetch(data.url, data.stampedPdfUrl)));
}

export function handleS3Error(imageId, onFetch, toImage = false) {
  if (!isNil(imageId)) {
    return fetchPresignedUrl(imageId, onFetch, toImage);
  }
  return Promise.reject('image id is not specified');
}

export function createImageFromId(imageId) {
  if (!isNil(imageId)) {
    return fetchPresignedUrl(imageId, (url, pdfUrl) => (createImageFromURL(url, pdfUrl)), true);
  }
  return Promise.reject('image id is not specified');
}

export function createImageFromURL(url, pdfUrl) {
  if (!isNil(url)) {
    return new Promise((resolve, reject) => {
      const image = new Image();
      image.crossOrigin = 'anonymous';
      image.onload = () => {
        resolve([image, pdfUrl]);
      };
      image.onerror = (e) => {
        reject(e);
      };
      image.src = url;
    });
  }
  return Promise.reject('image url is not specified');
}

/**
 * @deprecated
 */
export function createBlobUrlFromId(imageId, rotation = 0, scale = 1.0, mimeType = 'image/png') {
  if (!isNil(imageId)) {
    return createImageFromId(imageId).then(async (images) => {
      return images[0].src;
    });
  }
  return Promise.reject('image id is not specified');
}
