import React from 'react';

interface Props {
  selections: string[];
  disabled: boolean;
  value: string[];
  onChange: (value: string) => void;
}

/**
 * 汎用フォームのラジオボタン
 */
export const FormItemRadio: React.FC<Props> = React.memo(({
  selections, disabled, value, onChange,
}) => {
  return (
    <div>
      {
        selections.map((selection, i) => (
          // 同じ選択肢を二回入れられるとkeyが重複するのでkeyにselectionが使えない
          <div className="radio" key={ `radio-${i}` }>
            <label>
              <input type="radio"
                disabled={ disabled }
                checked={ value.includes(selection) }
                onChange={ (): void => onChange(selection) }
              />
              { selection }
            </label>
          </div>
        ))
      }
    </div>
  );
});
