import renderAmountPerPersonWrapper from "applications/transactions/utilities/renderer";
import PeriodInput from "components/PeriodInput";
import { ListForm } from "components/renewaled_ui/form_views";
import Decimal from "decimal.js";
import i18next from "i18n";
import moment from "moment";
import PropTypes from "prop-types";
import React, { Component } from "react";
import formatter from "utilities/formatter";
import { renderInputWithIcon } from "utilities/renderer";
import { initIntlCurrencyObj } from "utilities/Utils";
import { FactorTypeSelector } from "./FactorTypeSelector";

/**
 * 日当・手当の計算に必要な値の入力フォーム用のクラス
 * @extends React.Component
 * */
export default class AllowanceInput extends Component {
  constructor(props) {
    super(props);

    this.renderFactorTypeSelectors = this.renderFactorTypeSelectors.bind(this);
    this.renderVariableInputs = this.renderVariableInputs.bind(this);

    this.handleFactorTypeChange = this.handleFactorTypeChange.bind(this);
    this.handleVariableInputChange = this.handleVariableInputChange.bind(this);
    this.handleVariableSelectChange =
      this.handleVariableSelectChange.bind(this);
    this.handleVariablePeriodChange =
      this.handleVariablePeriodChange.bind(this);
    this.handleVariableCellValueChange =
      this.handleVariableCellValueChange.bind(this);
    this.calcAmountFromAst = this.calcAmountFromAst.bind(this);
  }

  /**
   * ASTを使って、手当総額を計算する
   *
   * @param {Object} ast
   * @param {Object} variables 計算式中の変数と、ユーザの入力値をマッピングしたObject
   * @return {number}
   */
  calcAmountFromAst(ast, variables) {
    const add = (node) => {
      return new Decimal(this.calcAmountFromAst(node.left, variables)).plus(
        this.calcAmountFromAst(node.right, variables),
      );
    };

    const subtract = (node) => {
      return new Decimal(this.calcAmountFromAst(node.left, variables)).minus(
        this.calcAmountFromAst(node.right, variables),
      );
    };

    const multiply = (node) => {
      return new Decimal(this.calcAmountFromAst(node.left, variables)).times(
        this.calcAmountFromAst(node.right, variables),
      );
    };

    const getVariableValue = (node) => {
      const variable = variables[node.id];

      switch (node.inputType) {
        case "number": {
          return variable ? new Decimal(variable) : new Decimal(0);
        }
        case "table":
        case "select": {
          // { id, value } という形のObject
          // 必ず数字が入るように
          return variable.value ? new Decimal(variable.value) : new Decimal(0);
        }
        case "period": {
          const { from, to } = variable;
          const fromDate = from ? new Date(from) : new Date();
          const toDate = to ? new Date(to) : new Date();
          toDate.setHours(0, 0, 0, 0);
          fromDate.setHours(0, 0, 0, 0);
          const dayDiff = (toDate - fromDate) / 1000 / 60 / 60 / 24;
          const period = new Decimal(dayDiff).plus(1); // from, toの日付も含めるため、+1する
          const zero = new Decimal(0);
          if (period.lessThanOrEqualTo(zero)) {
            return zero;
          }

          return period;
        }
        default: {
          // 実行されない
          // 値不明のため、NaNにする
          return new Decimal(NaN);
        }
      }
    };

    const minimum = (node) => {
      const left = this.calcAmountFromAst(node.left, variables);
      const right = this.calcAmountFromAst(node.right, variables);
      if (new Decimal(left).lessThan(right)) {
        return left;
      }

      return right;
    };

    const maximum = (node) => {
      const left = this.calcAmountFromAst(node.left, variables);
      const right = this.calcAmountFromAst(node.right, variables);
      if (new Decimal(left).lessThan(right)) {
        return right;
      }

      return left;
    };

    switch (ast.type) {
      case "add": {
        return add(ast);
      }
      case "subtract": {
        return subtract(ast);
      }
      case "multiply": {
        return multiply(ast);
      }
      case "variable": {
        return getVariableValue(ast);
      }
      case "minimum": {
        return minimum(ast);
      }
      case "maximum": {
        return maximum(ast);
      }
      case "constant": {
        return new Decimal(ast.value);
      }
      default: {
        // 実行されない
        // 計算方法不明のため、計算を打ち切る
        return new Decimal(NaN);
      }
    }
  }

  /* 手当表のセルを選択する際に使用するパラメータのchangeイベントを処理する
   * @param {Event} e
   * @param {string} factorTypeId
   */
  handleFactorTypeChange(e, factorTypeId) {
    this.props.onChange("factorIds", { [factorTypeId]: e.target.value });
  }

  /**
   * 手当の総額を計算する際に使用するパラメータのchangeイベントを処理する（inputType = number）
   *
   * @param {Event} e
   * @param {string} variableId
   */
  handleVariableInputChange(e, variableId) {
    this.props.onChange("variableInputs", { [variableId]: e.target.value });
  }

  /**
   * 手当の総額を計算する際に使用するパラメータのchangeイベントを処理する（inputType = select）
   *
   * @param {Event} e
   * @param {string} variableId
   */
  handleVariableSelectChange(e, variableId) {
    const {
      calculationFormula: { variables },
    } = this.props;
    const optionId = e.target.value;
    const selectedOption = variables
      .find((x) => x.id === variableId)
      .options.find((x) => x.id === optionId);

    this.props.onChange("variableInputs", {
      [variableId]: { id: optionId, value: selectedOption.value },
    });
  }

  /**
   * 手当の総額を計算する際に使用するパラメータのchangeイベントを処理する（inputType = period）
   *
   * @param {string} variableId
   * @param {string} type  'from' or 'to'
   * @param {string} value 'YYYY/MM/DD' や 'YYYY-MM-DDTHH:MM:SS.000+09:00' など
   */
  handleVariablePeriodChange(variableId, type, value) {
    this.props.onChange("variableInputs", {
      [variableId]: { [type]: moment(value).format("YYYY-MM-DD") },
    });
  }

  /**
   * 手当の総額を計算する際に使用するパラメータのchangeイベントを処理する（inputType = table）
   * 手当表のセルの値がnullの時、ユーザが実費入力をするが、その際に使用する
   *
   * @param {Event} e
   * @param {string} variableId
   */
  handleVariableCellValueChange(e, variableId) {
    this.props.onChange("variableInputs", {
      [variableId]: { value: e.target.value },
    });
  }

  /**
   * 日当・手当の総額を計算し、表示する
   * 表示は利用通貨に合わせる
   */
  renderAmountInput() {
    // Decimalの有効桁数を100桁とする(デフォルト値: 20)
    Decimal.set({ precision: 100 });

    const {
      calculationFormula,
      variableInputs,
      defaultCurrencyId,
      companions,
      fields,
    } = this.props;
    const amount = this.calcAmountFromAst(
      calculationFormula.ast,
      variableInputs,
    );
    const currencyOption =
      initIntlCurrencyObj(defaultCurrencyId).resolvedOptions();
    const power = 10 ** (currencyOption.maximumFractionDigits || 0);
    const totalAmount = amount.times(new Decimal(power)).floor() / power;
    const input = (
      <input
        type="number"
        className="form-control validate[required,custom[number],maxSize[15]] disabled"
        value={totalAmount}
        readOnly
      />
    );
    return renderAmountPerPersonWrapper(
      companions,
      totalAmount,
      fields,
      renderInputWithIcon(input, <span>{defaultCurrencyId}</span>),
      true,
      "amount_input",
    );
  }

  /**
   * 日当・手当表のセルを特定する際に使用する、行・列などの値のセレクトボックスを全て表示する
   *
   * @param {Object[]} factorTypes
   * @param {string} factorTypes[].id
   * @param {Object} labelProps
   * @param {Object} contentProps
   *
   * @see renderFactorTypeSelector
   */
  renderFactorTypeSelectors(factorTypes) {
    return factorTypes.map((factorType) => {
      return (
        <FactorTypeSelector
          key={factorType.id}
          factorType={factorType}
          selectedFactorId={this.props.factorIds[factorType.id]}
          onChange={this.handleFactorTypeChange}
        />
      );
    });
  }

  /**
   * 日当・手当の総額計算に使用する変数で、とりうる値が決まっている時、値を選択するセレクトボックスを表示する
   * 海外/国外で、それぞれ日当の額を1倍、3倍する場合など
   *
   * @param {Object} variable
   * @param {string} variable.id
   * @param {Object} labelProps
   * @param {Object} contentProps
   */
  renderVariableSelector(variable) {
    const { variableInputs } = this.props;
    const variableInput = variableInputs[variable.id];

    const input = (
      <select
        className="form-control"
        onChange={(e) => {
          this.handleVariableSelectChange(e, variable.id);
        }}
        value={variableInput.id}
      >
        {variable.options.map((option, index) => (
          <option key={`variable-option-${index}`} value={option.id}>
            {option.name}
          </option>
        ))}
      </select>
    );

    return (
      <ListForm label={variable.name} required>
        {input}
      </ListForm>
    );
  }

  /**
   * 日当・手当の総額計算に使用する変数で、ユーザの数値入力が必要な時、数値の入力フォームを表示する
   * 日数など(常に必須)
   *
   * @param {Object} variable
   * @param {string} variable.id
   * @param {Object} labelProps
   * @param {Object} contentProps
   */
  renderNumberInput(variable) {
    const { variableInputs } = this.props;
    const value = variableInputs[variable.id];

    const input = (
      <input
        type="number"
        step="any"
        className="form-control validate[required,custom[number],maxSize[15]]"
        value={value}
        onChange={(e) => {
          this.handleVariableInputChange(e, variable.id);
        }}
      />
    );

    return (
      <ListForm label={variable.name} required>
        {input}
      </ListForm>
    );
  }

  /**
   * 日当・手当の総額計算に使用する変数で、期間の入力が必要な時、期間の入力フォームを表示する
   * 出張期間など
   *
   * @param {Object} variable
   * @param {string} variable.id
   * @param {Object} labelProps
   * @param {Object} contentProps
   */
  renderPeriodInput(variable) {
    const { variableInputs } = this.props;
    const value = variableInputs[variable.id] || {};

    const input = (
      // PeriodInputがbootstrapでスタイルしていて高さ無し要素のため、AllowanceInputの他の要素と並べる場合のみ、高さを持ったdivで囲って高さを持たせる。
      <div style={{ height: "80px" }}>
        <PeriodInput
          key={`period-${variable.id}`}
          oneline={false}
          dateFrom={value.from}
          dateTo={value.to}
          isDateFromRequired={true}
          isDateToRequired={true}
          onChangeDateFrom={this.handleVariablePeriodChange.bind(
            null,
            variable.id,
            "from",
          )}
          onChangeDateTo={this.handleVariablePeriodChange.bind(
            null,
            variable.id,
            "to",
          )}
        />
      </div>
    );

    return (
      <ListForm label={variable.name} required>
        {input}
      </ListForm>
    );
  }

  /**
   * 日当・手当表の選択状態を元に、日当・手当の額を表示する
   * 実費入力が必要な時は、入力フォームを表示する
   *
   * @param {Object} variable
   * @param {string} variable.id
   * @param {Object} labelProps
   * @param {Object} contentProps
   */
  renderTableInput(variable) {
    const { variableInputs, defaultCurrencyId } = this.props;
    const { value, editable } = variableInputs[variable.id];

    // 実費入力時
    if (editable) {
      const input = (
        <input
          type="number"
          className="form-control validate[required,custom[number],maxSize[15]]"
          value={value}
          onChange={(e) => {
            this.handleVariableCellValueChange(e, variable.id);
          }}
        />
      );
      return (
        <ListForm label={variable.name} required>
          {input}
        </ListForm>
      );
    }

    const amountFormat = formatter.amount(
      value,
      i18next.t("requestTypes.modalBody.notEntered"),
      null,
      defaultCurrencyId,
    );

    return <ListForm label={variable.name}>{amountFormat}</ListForm>;
  }

  /**
   * 日当・手当の総額計算に使用する変数の入力フォームを表示する
   *
   * @param {Object} variable
   * @param {string} variable.id
   * @param {Object} labelProps
   * @param {Object} contentProps
   */
  renderVariableInput(variable, labelProps, contentProps) {
    switch (variable.inputType) {
      case "number": {
        return this.renderNumberInput(variable, labelProps, contentProps);
      }
      case "table": {
        return this.renderTableInput(variable, labelProps, contentProps);
      }
      case "select": {
        return this.renderVariableSelector(variable, labelProps, contentProps);
      }
      case "period": {
        return this.renderPeriodInput(variable, labelProps, contentProps);
      }
      default: {
        // 実行されない
        return null;
      }
    }
  }

  /**
   * 日当・手当の総額計算に使用する変数の入力フォームを全て表示する
   *
   * @param {Object[]} variables
   * @param {string} variables[].id
   * @param {Object} labelProps
   * @param {Object} contentProps
   *
   * @see renderVariableInput
   */
  renderVariableInputs(variables, labelProps, contentProps) {
    return variables.map((variable, index) => {
      return (
        <div key={`variable-${index}`}>
          {this.renderVariableInput(variable, labelProps, contentProps)}
        </div>
      );
    });
  }

  render() {
    const {
      factorTypes,
      calculationFormula: { variables },
    } = this.props;
    const labelProps = { className: "col-sm-3 col-md-3 control-label" };
    const contentProps = { className: "col-sm-6 col-md-6" };

    return (
      <div>
        {this.renderFactorTypeSelectors(factorTypes, labelProps, contentProps)}
        {this.renderVariableInputs(variables, labelProps, contentProps)}
        {/* 常にdisabledなので、必須マークは不要 */}
        <ListForm label={i18next.t("transactions.properties.amount")}>
          {this.renderAmountInput()}
        </ListForm>
      </div>
    );
  }
}

AllowanceInput.defaultProps = {
  companions: [],
  fields: [],
};

AllowanceInput.propTypes = {
  defaultCurrencyId: PropTypes.string.isRequired,
  factorTypes: PropTypes.arrayOf(PropTypes.object).isRequired,
  tableCells: PropTypes.object.isRequired,
  calculationFormula: PropTypes.shape({
    id: PropTypes.string.isRequired,
    ast: PropTypes.object.isRequired,
    variables: PropTypes.array.isRequired,
  }).isRequired,
  variableInputs: PropTypes.object.isRequired,
  tableCellId: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  companions: PropTypes.array.isRequired,
  fields: PropTypes.array.isRequired,
};
