/* eslint-disable @typescript-eslint/explicit-module-boundary-types, @typescript-eslint/explicit-function-return-type */

import {
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react';

type OnSubmit = (form: HTMLFormElement) => void;

/**
 * formタグを使ってフォーム送信を管理するためのフック
 * @param validate 入力値を検証する関数
 * @param intialValues フォームの初期値
 * @param onSubmit フォーム送信可能なときに実行されるイベントハンドラ
 * @returns
 *   - errors 入力値のエラーメッセージ
 *   - formElm formタグへの参照
 *   - handleValuesChange 入力値が変更されたときに使うイベントハンドラ
 *   - handleClick 送信ボタンがクリックされたときに使うイベントハンドラ
 *   - isSubmitting フォーム送信中はtrue
 *   - touched 送信ボタンクリック後はtrue
 *   - valid 入力エラーがない場合はtrue
 *   - values 入力値
 */
export const useHtmlForm = <FormValues, FormErrors>(validate: (values: FormValues) => FormErrors, intialValues: FormValues, onSubmit: OnSubmit) => {
  const [values, setValues] = useState(intialValues);
  const [errors, setErrors] = useState({} as FormErrors);
  const [touched, setTouched] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const formElm = useRef<HTMLFormElement>(null);
  const valid = Object.keys(errors).length === 0;

  const handleValuesChange  = useCallback((changes: Partial<FormValues>) => {
    setValues({ ...values, ...changes });
    // なぜかFormValuesがdepsに登録されてしまうので無効にする
  }, [values]); // eslint-disable-line react-hooks/exhaustive-deps

  const handleClick = useCallback((e: React.MouseEvent<HTMLButtonElement>): void => {
    e.preventDefault();
    setTouched(true);
    if (formElm.current && valid) {
      setIsSubmitting(true);
      onSubmit(formElm.current);
    }
  }, [valid, onSubmit]);

  useEffect(() => {
    setErrors(validate(values));
  }, [validate, values]);

  return {
    errors,
    formElm,
    handleValuesChange,
    handleClick,
    isSubmitting,
    touched,
    valid,
    values,
  } as const;
};
