import i18next from "i18n";
import cloneDeep from "lodash/cloneDeep";
import findIndex from "lodash/findIndex";
import get from "lodash/get";
import isEmpty from "lodash/isEmpty";
import isNil from "lodash/isNil";
import set from "lodash/set";
import uniqueId from "lodash/uniqueId";
import { fetchDirectProductTables } from "./fetch";
import { buildCellMap, cellsToArray } from "./utils";

const prefix = "allowances/settings";

/**
 * 表のフォームを初期化する
 */
export function initTable(table = {}) {
  // デフォルトでは二次元表
  const factorTypes = table.factorTypes || [initFactorType(), initFactorType()];
  const cells = resetCells(table.cells || {}, factorTypes);
  const variables = isEmpty(table.variables)
    ? [
        {
          id: uniqueId(),
          inputType: "table",
          name: i18next.t("allowance.messageBox.title.allowance"),
        },
      ]
    : table.variables;

  return {
    id: table.id || null,
    name: table.name || "",
    icon: table.icon || null,
    factorTypes,
    cells,
    variables,
    formula: table.formula || "",
    categories: table.categories || [],
    defaultCategory: table.defaultCategory || null,
    tableAccessPolicy: table.tableAccessPolicy || null,
    optionAccessPolicies: table.optionAccessPolicies || [],
  };
}

/**
 * 表の項目（次元）の初期値を生成する
 */
export function initFactorType() {
  const id = uniqueId();
  return {
    id,
    name: "",
    options: [initFactorOption(id, "")], // デフォルトで1要素
    defaultOptionPolicy: null,
  };
}

/**
 * 表の行（列）を構成する要素の初期値を生成する
 */
export function initFactorOption(typeId, typeName) {
  return {
    id: uniqueId(),
    name: "",
    type: { id: typeId, name: typeName },
  };
}

/**
 * 表のセルの初期値を生成する
 */
export function initCell(factors = []) {
  return {
    id: uniqueId(),
    value: "",
    factors,
  };
}

/**
 * 計算式の変数の初期値を生成する
 */
export function initVariable(inputType = "number") {
  const variable = {
    id: uniqueId(),
    inputType,
    name: "",
  };

  if (inputType === "select") {
    return { ...variable, options: [initVariableOption()] };
  }

  return variable;
}

export function initVariableOption() {
  return {
    id: uniqueId(),
    name: "",
    value: "",
  };
}

/**
 * 手当表の一覧を初期化する
 */
export function resetDirectProductTables(name) {
  return (dispatch, getState) => {
    return dispatch(fetchDirectProductTables(false, false)).then((data) => {
      const tables = data.map((table) => {
        const { calculationFormula } = table;

        return {
          ...table,
          variables: calculationFormula.variables,
          formula: calculationFormula.ast.expression,
        };
      });
      const table = tables.find((x) => x.name === name) || tables[0];

      if (table) {
        dispatch(setTables(tables));
        dispatch(resetTableForm(table));
        dispatch(selectTab(table.id));
      }
    });
  };
}

export const SET_TABLES = `${prefix}/SET_TABLES`;
export function setTables(tables) {
  return {
    type: SET_TABLES,
    data: tables,
  };
}

export const RESET_TABLE_FORM = `${prefix}/RESET_TABLE_FORM`;
/**
 * 編集対象のテーブルの情報を初期化する
 * 引数が渡された時は、その内容を元にして、フォームを初期化する（e.g. 既存設定の編集時）
 *
 * @param {Object} table
 */
export function resetTableForm(table) {
  return {
    type: RESET_TABLE_FORM,
    data: initTable(table),
  };
}

export const SELECT_TAB = `${prefix}/SELECT_TAB`;
export function selectTab(tabId) {
  return {
    type: SELECT_TAB,
    id: tabId,
  };
}

export const APPEND_TAB = `${prefix}/APPEND_TAB`;
export function appendTab(table) {
  return {
    type: APPEND_TAB,
    data: initTable(table),
  };
}

export const SET_TABLE_FORM_VALUE = `${prefix}/SET_TABLE_FORM_VALUE`;
/**
 * 表のフォームの項目を更新する
 *
 * @param {string} key 更新するプロパティ名
 *   'name', 'factorTypes'
 * @param {string} value
 */
export function setTableFormValue(key, value) {
  return {
    type: SET_TABLE_FORM_VALUE,
    key,
    value,
  };
}

/**
 * オブジェクトの配列の順番を入れ替える
 *
 * @param {Object[]} array
 * @param {string} id 入れ替え対象のID
 * @param {number} diff 順番をいくつ繰り上げ（下げ）るかを示す。正の数の時は、後ろにずらし、負の数の時はまえにずらす
 * @return {Object[]}
 */
export function changeArrayOrder(array, id, diff) {
  const idx = findIndex(array, (x) => x.id === id);
  let nextIdx = idx + diff;

  if (nextIdx < 0) {
    nextIdx = 0;
  } else if (nextIdx >= array.length) {
    nextIdx = array.length - 1;
  }

  const nextArray =
    nextIdx < idx
      ? [
          ...array.slice(0, nextIdx), // 影響のない部分
          array[idx], // 移動
          ...array.slice(nextIdx, idx), // 移動対象の手前の要素をコピー
          ...array.slice(idx + 1), // 影響のない部分
        ]
      : [
          ...array.slice(0, idx), // 影響のない部分
          ...array.slice(idx + 1, nextIdx + 1), // 移動対象はスキップしてコピー
          array[idx],
          ...array.slice(nextIdx + 1), // 影響のない部分
        ];

  return nextArray;
}

/**
 * 表の項目設定の順序を入れ替える
 *
 * @param {Object[]} factors
 * @param {string} factorId
 * @param {number} diff
 */
export function changeFactorTypeOrder(cells, factors, factorId, diff) {
  return (dispatch, getState) => {
    const nextFactors = changeArrayOrder(factors, factorId, diff);

    dispatch(setTableFormValue("factorTypes", nextFactors));
    dispatch(
      setTableFormValue(
        "cells",
        changeCellMapOrder(cells, factors, nextFactors),
      ),
    );
  };
}

/**
 * 表の項目設定で、項目の名称を変更する
 *
 * @param {Object[]} factors 現在の次元のデータ
 * @param {string} factorId 編集対象の次元のID
 * @param {string} name
 */
export function setFactorTypeName(
  factors,
  factorId,
  name,
  optionAccessPolicies,
) {
  return (dispatch, getState) => {
    const nextFactors = factors.map((factor) => {
      if (factor.id === factorId) {
        const options = factor.options.map((o) => ({
          ...o,
          type: { id: factor.id, name: factor.name },
        }));
        return { ...factor, name, options };
      }
      return factor;
    });

    dispatch(setTableFormValue("factorTypes", nextFactors));
    dispatch(
      setTableFormValue(
        "optionAccessPolicies",
        updateOptionAccessPolicies(optionAccessPolicies, factors, nextFactors),
      ),
    );
  };
}

/**
 * 表の項目（次元）を追加する
 *
 * @param {Object} cells セルの入力情報が入ったオブジェクト
 * @param {Object[]} factors 現在の次元のデータ
 */
export function addFactorType(cells, factors) {
  return (dispatch, getState) => {
    const nextFactors = factors.concat([initFactorType()]);

    dispatch(setTableFormValue("factorTypes", nextFactors));
    dispatch(setTableFormValue("cells", resetCells(cells, nextFactors)));
  };
}

/**
 * 表の項目（次元）を削除する
 *
 * @param {Object} cells セルの入力情報が入ったオブジェクト
 * @param {Object[]} factors 現在の次元のデータ
 * @param {string} factorId 削除対象の次元のID
 */
export function removeFactorType(
  cells,
  factors,
  factorId,
  optionAccessPolicies,
) {
  return (dispatch, getState) => {
    const nextFactors = factors.filter((factor) => factor.id !== factorId);

    dispatch(setTableFormValue("factorTypes", nextFactors));
    dispatch(setTableFormValue("cells", resetCells(cells, nextFactors)));
    dispatch(
      setTableFormValue(
        "optionAccessPolicies",
        updateOptionAccessPolicies(optionAccessPolicies, factors, nextFactors),
      ),
    );
  };
}

/**
 * 表の項目（次元）のサイズを変更する（表の行、列などの数を変更する）
 *
 * @param {Object} cells セルの入力情報が入ったオブジェクト
 * @param {Object[]} factors 現在の次元のデータ
 * @param {string} factorId 要素数の変更対象の次元のID
 */
export function changeFactorOptionSize(
  cells,
  factors,
  factorId,
  size,
  optionAccessPolicies,
) {
  return (dispatch, getState) => {
    const nextFactors = factors.map((x) => {
      if (x.id === factorId) {
        const addingNumber = Math.max(0, size - x.options.length);
        const nextOptions = [
          // 新しいサイズであふれる部分は削る（size > options.length の時は、コピー）
          ...x.options.slice(0, size),
          // 拡張された時は、初期値で追加する
          ...new Array(addingNumber)
            .fill(null)
            .map(() => initFactorOption(x.id, x.name)),
        ];
        const nextDefaultOptionPolicy = x.defaultOptionPolicy &&
          x.defaultOptionPolicy.rules.some((r) =>
            nextOptions.map((o) => o.id).includes(r.defaultOption.id),
          ) && {
            ...x.defaultOptionPolicy,
            rules: x.defaultOptionPolicy.rules.map((r) => {
              return {
                ...r,
                defaultOption: (({ id, name }) => ({ id, name }))(
                  nextOptions.find((o) => o.id === r.defaultOption.id) ||
                    nextOptions[0],
                ),
              };
            }),
          };

        return {
          ...x,
          options: nextOptions,
          defaultOptionPolicy: nextDefaultOptionPolicy,
        };
      }

      return x;
    });

    dispatch(setTableFormValue("factorTypes", nextFactors));
    dispatch(setTableFormValue("cells", resetCells(cells, nextFactors)));
    dispatch(
      setTableFormValue(
        "optionAccessPolicies",
        updateOptionAccessPolicies(optionAccessPolicies, factors, nextFactors),
      ),
    );
  };
}

/**
 * 表の項目（次元）の要素名を変更する
 * @param {Object[]} factors
 * @param {string} factorId 変更対象の要素が属する次元のID
 * @param {string} optionId 変更対象のID。factorTypes.optionsのID
 * @param {string} name
 */
export function setFactorName(
  factors,
  factorId,
  optionId,
  name,
  optionAccessPolicies,
) {
  return (dispatch, getState) => {
    const nextFactors = factors.map((x) => {
      if (x.id === factorId) {
        return {
          ...x,
          options: x.options.map((y) => {
            if (y.id === optionId) {
              return { ...y, name, type: { id: x.id, name: x.name } };
            }
            return y;
          }),
          defaultOptionPolicy: x.defaultOptionPolicy && {
            ...x.defaultOptionPolicy,
            rules: x.defaultOptionPolicy.rules.map((r) => {
              return {
                ...r,
                defaultOption: {
                  ...r.defaultOption,
                  name:
                    r.defaultOption.id === optionId
                      ? name
                      : r.defaultOption.name,
                },
              };
            }),
          },
        };
      }
      return x;
    });

    dispatch(setTableFormValue("factorTypes", nextFactors));
    dispatch(
      setTableFormValue(
        "optionAccessPolicies",
        updateOptionAccessPolicies(optionAccessPolicies, factors, nextFactors),
      ),
    );
  };
}

export const SET_DEFAULT_OPTION_POLICY = `${prefix}/SET_DEFAULT_OPTION_POLICY`;
export function setDefaultOptionPolicy(selection, defaultOptionPolicy) {
  return {
    type: SET_DEFAULT_OPTION_POLICY,
    payload: {
      selection,
      defaultOptionPolicy,
    },
  };
}

/**
 * 表のセルの値を変更する（ヘッダは除く）
 *
 * @param {Object} cells セルの入力情報が入ったオブジェクト
 * @param {string} cellId 変更対象のセルのID
 * @param {(string|number)} value
 */
export function setCellValue(cells, factors, cellId, value) {
  return setTableFormValue(
    "cells",
    resetCells(cells, factors, { [cellId]: value }),
  );
}

/**
 * 表のセルの状態を更新し、新しいセルのデータを返す
 * 次元数が削減されたり、表のサイズが変更された時は、不要なデータが残るため、それらを削除する
 * 個別のセルの値を更新する時には使用しない
 *
 * @param {Object} cells セルの入力情報が入ったオブジェクト
 * @param {Object[]} factors 次元の情報。順番は表の表示順にソートされている必要がある
 * @param {Object} overwrite セルのIDをkeyに持ち、セルの値をvalueに持つオブジェクト
 */
export function resetCells(cells, factors, overwrite = {}) {
  return buildCellCache(factors, []);

  /**
   * factorsの先頭、factor.optionsの先頭から順番に、cellsに値が保存されているかどうかを確認する
   * 保存されていたら、その値をオブジェクトにコピーし、保存されていない場合は、デフォルト値をオブジェクトに保存する
   * 全セルを走査するので、セル数が多くなると遅くなる
   *
   * @param {Object[]} subFactors factorsの部分配列。先頭から順番に消化される
   * @param {Object[]} options たどってきたoptionの配列
   * @return {Object} cellsと同じ構造で、セルの値を保存する
   */
  function buildCellCache(subFactors, options) {
    // 次元を先頭から走査する
    // 完了した場合は、現在のセルの値があれば、それを維持する
    // 値が見つからなければ、空文字で初期化する（セル上は、実費入力の項目を表す）
    if (subFactors.length === 0) {
      const cellKey = options.map((x) => `[${x.id}]`).join("");
      let cell = get(cells, cellKey, initCell([...options]));

      if (isNil(cell.id)) {
        // 最後の次元が削減された場合、キャッシュからセルの値までたどることができないので、初期値で置き換える
        // （id, valueが入ったオブジェクトまでたどり着かない）
        cell = initCell([...options]);
      } else if (!isNil(overwrite[cell.id])) {
        cell = { ...cell, value: overwrite[cell.id] };
      }

      return cell;
    }

    // 全ての次元の値が指定し終わっていない時は、順番に値を指定する
    const cache = subFactors[0].options.reduce((acc, option) => {
      set(
        acc,
        option.id,
        buildCellCache(subFactors.slice(1), [...options, { ...option }]),
      );
      return acc;
    }, {});

    return cache;
  }
}

/**
 * 表の次元の順番が変更された時に、セルのキャッシュデータのkeyの順番を入れ替える
 * セルのキャッシュデータを、配列に変換してから、再度オブジェクトとして構成し直す
 *
 * @param {Object} cells
 * @param {Array} oldFactors
 * @param {Array} nextFactors
 */
export function changeCellMapOrder(cells, oldFactors, nextFactors) {
  // 更新後の次元の順番を見て、もともとの次元をどのように並べ替えればよいかを調べる
  // 現在の配列におけるindexが分かれば、簡単に並べ替えられる
  const nextIndexOrders = nextFactors.map((factor) =>
    findIndex(oldFactors, (x) => x.id === factor.id),
  );

  // セルをObjectから配列に戻した上で、次元の情報の配列を並べ替える
  const cellArray = cellsToArray(oldFactors, cells).map((cell) => {
    return {
      ...cell,
      factors: nextIndexOrders.map((i) => cell.factors[i]),
    };
  });

  return buildCellMap(cellArray);
}

/**
 * 選択肢の閲覧制限ポリシーを更新します。
 */
function updateOptionAccessPolicies(
  optionAccessPolicies,
  oldFactors,
  nextFactors,
) {
  const oldTypeIds = oldFactors.map((t) => t.id);
  const nextTypeNamesById = nextFactors
    .filter((t) => {
      return oldTypeIds.includes(t.id);
    })
    .reduce((acc, t) => {
      acc[t.id] = t.name;
      return acc;
    }, {});

  const oldOptionIds = oldFactors.flatMap((type) =>
    type.options.map((o) => o.id),
  );
  const nextOptionNamesById = nextFactors
    .flatMap((t) => t.options)
    .filter((o) => {
      return oldOptionIds.includes(o.id);
    })
    .reduce((acc, o) => {
      acc[o.id] = o.name;
      return acc;
    }, {});

  const getResources = (policy) =>
    policy.target.conditions.rightNode.rightNode.directProductFactorOptions;

  return optionAccessPolicies
    .filter((policy) => {
      // 新しい FactorType がリソースに1つも存在しない場合、ポリシーを削除する。
      const factorOptions = getResources(policy);

      return factorOptions.some((o) => {
        return !!nextOptionNamesById[o.id];
      });
    })
    .map((policy) => {
      const newPolicy = cloneDeep(policy);
      const factorOptions = getResources(newPolicy);
      const newFactorOptions = factorOptions
        .filter((o) => {
          // FactorOption が存在しない場合にはリソースから削除する。
          return !!nextOptionNamesById[o.id];
        })
        .map((o) => {
          // FactorOption/FactorType の名称が変更された場合には更新する。
          return {
            ...o,
            name: nextOptionNamesById[o.id],
            type: {
              ...o.type,
              name: nextTypeNamesById[o.type.id],
            },
          };
        });
      newPolicy.target.conditions.rightNode.rightNode.directProductFactorOptions =
        newFactorOptions;
      return newPolicy;
    });
}

/**
 * 変数を追加する
 *
 * @param {Object[]} variables 現在の変数のリスト
 */
export function addVariable(variables) {
  const nextVariables = variables.concat([initVariable()]);

  return setTableFormValue("variables", nextVariables);
}

/**
 * 変数を削除する
 *
 * @param {Object[]} variables 現在の変数のリスト
 * @param {string} variableId
 */
export function removeVariable(variables, variableId) {
  const nextVariables = variables.filter(
    (variable) => variable.id !== variableId,
  );

  return setTableFormValue("variables", nextVariables);
}

/**
 * 変数の順番を入れ替える
 *
 * @param {Object[]} variables
 * @param {string} variableId
 * @param {number} diff
 */
export function changeVariableOrder(variables, variableId, diff) {
  return setTableFormValue(
    "variables",
    changeArrayOrder(variables, variableId, diff),
  );
}

/**
 * 変数の設定を更新する
 *
 * @param {Object[]} variables 現在の変数のリスト
 * @param {string} variableId 編集対象の変数のID
 * @param {string} key 編集対象の項目
 * @param {(string|Object[])} value 変更後の値
 */
export function setVariableValue(variables, variableId, key, value) {
  const nextVariables = variables.map((variable) => {
    if (variable.id === variableId) {
      // inputTypeがselectの時とそれ以外のときで、データの構造が異なるため、作り直す
      if (key === "inputType") {
        return { ...initVariable(value), name: variable.name };
      }
      return { ...variable, [key]: value };
    }
    return variable;
  });

  return setTableFormValue("variables", nextVariables);
}

/**
 * 変数のオプションを追加する
 */
export function addVariableOption(variables, variableId) {
  const nextVariables = variables.map((variable) => {
    if (variable.id === variableId) {
      return {
        ...variable,
        options: variable.options.concat([initVariableOption()]),
      };
    }
    return variable;
  });

  return setTableFormValue("variables", nextVariables);
}

/**
 * 変数のオプションを削除する（inputTypeがselectの時）
 */
export function removeVariableOption(variables, variableId, optionId) {
  const nextVariables = variables.map((variable) => {
    if (variable.id === variableId) {
      return {
        ...variable,
        options: variable.options.filter((option) => option.id !== optionId),
      };
    }
    return variable;
  }, []);

  return setTableFormValue("variables", nextVariables);
}

/**
 * 変数のオプションの値（名前、値）を変更する
 */
export function setVariableOptionValue(
  variables,
  variableId,
  optionId,
  key,
  value,
) {
  const nextVariables = variables.map((variable) => {
    if (variable.id === variableId) {
      return {
        ...variable,
        options: variable.options.map((option) => {
          if (option.id === optionId) {
            return { ...option, [key]: value };
          }
          return option;
        }),
      };
    }
    return variable;
  });

  return setTableFormValue("variables", nextVariables);
}

export function setFormula(formula) {
  return setTableFormValue("formula", formula);
}

export const TOGGLE_DELETE_CONFIRMATION_MODAL = `${prefix}/TOGGLE_DELETE_CONFIRMATION_MODAL`;
export function toggleDeleteConfirmationModal(show) {
  return {
    type: TOGGLE_DELETE_CONFIRMATION_MODAL,
    show,
  };
}

export const SET_CATEGORIES = `${prefix}/SET_CATEGORIES`;
export function setCategories(categories) {
  return {
    type: SET_CATEGORIES,
    categories,
  };
}
