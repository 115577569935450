import axios from "axios";
import $ from "jquery";
import _ from "lodash";
import qs from "qs";
import { camelizeKeys } from "utilities/Utils";
import ApiAuth from "./api_auth";

export default class ApiBaseV2 {
  constructor(
    version,
    {
      defaultHeaders = {},
      relativePath = "",
      authPath = "",
      afterSignOutPath = "/users/sign_in",
      timeout = undefined,
    } = {},
  ) {
    const baseURL = `/api/${version}/${relativePath}`;

    const configParams = {
      baseURL,
      headers: {
        "Content-Type": "application/json",
        "X-Requested-With": "XMLHttpRequest",
        ...defaultHeaders,
      },
    };

    // feature/IP-5444/set-timeout: 請求書のUI/UX改善完了時にrevertする
    if (timeout !== undefined) configParams.timeout = timeout;

    this.axios = axios.create(configParams);

    this.axios.interceptors.response.use(
      (response) => response,
      (error) => {
        this.notifyBugsnag(error);
        return Promise.reject(error);
      },
    );
    this.apiAuth = new ApiAuth({ rootPath: authPath, afterSignOutPath });
    this.endpoints = [];
  }

  static getRailsPath(isPlural, basePath, actionType) {
    if (isPlural) {
      switch (actionType) {
        case "show":
        case "update":
        case "destroy":
          return `${basePath}/{id}`;
        default:
          return basePath; // index, create
      }
    }

    return basePath;
  }

  static endpointActions(isPlural, { only = null, except = null } = {}) {
    let action = {
      show: { method: "GET" },
      create: { method: "POST" },
      update: { method: "PUT" },
      destroy: { method: "DELETE" },
    };

    if (isPlural) {
      action.index = { method: "GET" };
    }
    if (only) {
      action = _.pick(action, only);
    }
    if (except) {
      action = _.omit(action, except);
    }

    return action;
  }

  static getMethodPath(path, action = "") {
    return path
      .replace(/\{[^}]*\}/g, "")
      .split("/")
      .concat([action])
      .filter((p) => !!p)
      .map((key) => _.camelCase(key))
      .join(".");
  }

  /**
   * URLの設定された位置に、パラメータを挿入する
   * @param {string} urlOption - '/api/v1/controller/{id}/{action}
   * @param {object} params - { id: 1, action: 'create' }
   * @return {object[]} - ['/api/v1/controller/1/create', {}]
   */
  static buildUrlAndBody(urlOption, params = {}) {
    let url = urlOption;
    const body = { ...params };
    // NOTE: 実行時に、パラメータの指定が漏れた時、スラッシュが連続しないように、スラッシュも含める
    // e.g. ['/{id}', '/{action}']
    const matched = _.uniq(url.match(/\/\{[^{}]*\}/g));
    const missingKeys = [];

    matched.forEach((param) => {
      // 中括弧を外す
      const key = param.slice(2, -1);

      if (!(key in params)) {
        missingKeys.push(key);
      }

      url = url.replace(
        new RegExp(param, "g"),
        key in params ? `/${params[key]}` : "",
      );

      // URLに使用したパラメータは、bodyから削除する
      _.unset(body, key);
    });

    if (missingKeys.length > 0 && process.env.NODE_ENV !== "production") {
      // TODO: production以外は実行時エラーにする
      const message = `URL(${urlOption})のパラメータに、指定漏れがあります: ${missingKeys.join(
        ", ",
      )}`;

      if (process.env.NODE_ENV === "test") {
        throw new Error(message);
      }

      console.error(message); // eslint-disable-line no-console
    }

    return [url, body];
  }

  static convertToGetParams(options) {
    return {
      ..._.omit(options, ["data"]),
      params: options.data,
      paramsSerializer(params) {
        return qs.stringify(params, { arrayFormat: "brackets" });
      },
    };
  }

  get isAuthenticated() {
    return this._isAuthenticated || false;
  }

  get(...args) {
    return this.appendEndpoint("GET", ...args);
  }

  post(...args) {
    return this.appendEndpoint("POST", ...args);
  }

  put(...args) {
    return this.appendEndpoint("PUT", ...args);
  }

  destroy(...args) {
    return this.appendEndpoint("DELETE", ...args);
  }

  addActions(path, isPlural, options = {}) {
    const actions = ApiBaseV2.endpointActions(isPlural, options);
    const commonOptions = options.commons || {};

    Object.keys(actions).forEach((actionType) => {
      const actionOptions = options[actionType] || {};
      const url = ApiBaseV2.getRailsPath(isPlural, path, actionType);
      const to = ApiBaseV2.getMethodPath(options.to || path, actionType);
      const endpointOptions = _.merge(
        {},
        actions[actionType],
        commonOptions,
        actionOptions,
      );

      this.appendEndpoint(endpointOptions.method, url, {
        to,
        options: endpointOptions,
      });
    });

    return this;
  }

  /**
   * Railsのresourceに対応するAPI用のメソッドを生成する
   * @param {string} path - URL
   * @param {object} options.to - Apiクライアント使用時の、アクセスパス (e.g. 'users' とすると、 `Api.users.index` が生成される)
   * @param {string[]} options.only - 指定したactionに対応するメソッドのみ生成される
   * @param {string[]} options.except - 指定したactionに対応するメソッドは、生成しない
   *
   * @param {object} options.commons - axiosのオプション
   * @param {object} options.show - show用の、axiosのオプション。options.commonsと同様
   * @param {object} options.create - create用の、axiosのオプション。options.commonsと同様
   * @param {object} options.update - update用の、axiosのオプション。options.commonsと同様
   * @param {object} options.destroy - destroy用の、axiosのオプション。options.commonsと同様
   */
  resource(path, options = {}) {
    return this.addActions(path, false, options);
  }

  /**
   * Railsのresourcesに対応するAPI用のメソッドを生成する。resourceを参照
   * @param {string} path
   * @param {object} options.to
   * @param {string[]} options.only
   * @param {string[]} options.except
   *
   * @param {object} options.commons
   * @param {object} options.index
   * @param {object} options.show
   * @param {object} options.create
   * @param {object} options.update
   * @param {object} options.destroy
   */
  resources(path, options = {}) {
    return this.addActions(path, true, options);
  }

  /**
   * @param {string} method - HTTPのメソッド
   * @param {string} url - URL。パラメータがある場合は、{}で囲む。 e.g. '/transactions/{id}'
   * @param {object} options - axiosのオプション
   * @param {string} to - Apiクライアント使用時の、アクセスパス
   */
  appendEndpoint(method, url, { options = {}, to = null } = {}) {
    this.endpoints.push({
      methodPath: to || ApiBaseV2.getMethodPath(url),
      url,
      options: { ...options, method },
    });

    return this;
  }

  build() {
    const Api = this.endpoints.reduce((api, { methodPath, url, options }) => {
      return _.set(api, methodPath, this.request({ ...options, url }));
    }, {});

    this.apiAuth.bindEndpoint(Api, "auth");
    return _.set(Api, "fetch", this.fetch.bind(this));
  }

  /**
   * @param {Object} defaultOptions - axiosに渡すオプション
   * @return {function} - リクエストを送信する関数
   */
  request(defaultOptions) {
    /**
     * @param {object} data - 送信パラメータ
     * @param {object} options - リクエスト送信時のオプション
     * @return {Deferred} - TODO: Promiseを返すように
     */
    return (data, options) => {
      const config = _.merge({}, defaultOptions, options);
      const [url, body] = ApiBaseV2.buildUrlAndBody(config.url, data);

      const deferred = new $.Deferred();

      this.fetch(url, { ...config, url, data: body })
        .then((result) => {
          deferred.resolve(result);
        })
        .catch((error) => {
          if (error.request) {
            // jqXHRに似せるために、responseJSONをセット
            _.set(
              error.request,
              "responseJSON",
              _.get(error, "response.data", null),
            );
            deferred.reject(
              error.request,
              _.get(error, "request.responseJSON.message", ""),
            );
          } else {
            // 通信エラー以外のエラー
            deferred.reject(error);
          }
        });

      return deferred.promise();
    };
  }

  /**
   * @param {Object} options - axiosに渡すオプション
   * @return {Promise}
   */
  async fetch(url, options) {
    const config =
      options.method === "GET"
        ? ApiBaseV2.convertToGetParams(options)
        : { ...options };
    // authHeadersの値が、動的に変更されるため、都度読み込み
    const headers = { ...config.headers, ...this.apiAuth.authHeaders };

    await this.authenticate();

    return this.axios(url, { ...config, headers })
      .then((response) => camelizeKeys(response.data))
      .catch((error) => {
        // axiosのエラーではない時は、そのままthrowする
        if (!error.request) {
          return Promise.reject(error);
        }

        switch (error.request.status) {
          case 401: {
            // 認証エラーの時、ログイン画面に遷移
            // TODO: 認証情報が無効である（tokenの有効期限切れなど）ことを示すように
            return this.apiAuth.signOut().then(() => Promise.reject(error));
          }
          default:
            break;
        }

        return Promise.reject(error);
      });
  }

  /**
   * @see https://docs.bugsnag.com/platforms/javascript/reporting-handled-errors/#sending-errors
   */
  notifyBugsnag(error) {
    // Bugsnagライブラリがロードされていない場合、スキップ
    if (!Bugsnag) {
      return;
    }

    const { config, request, response } = error;

    if (!config) {
      // axios自体のエラーなど、通信エラー以外のエラー
      Bugsnag.notify(error);
      return;
    }

    // 400系のエラーは予定されているので、Bugsnagには通知しない
    if (response && response.status < 500) {
      return;
    }

    const options = {
      request: {
        apiUrl: config.url,
        apiType: config.method,
        params: config.data,
        readyState: request.readyState,
        status: request.status,
        statusText: request.statusText,
      },
      // https://docs.bugsnag.com/platforms/javascript/reporting-handled-errors/#customizing-diagnostic-data
      metaData: {
        response: {
          data: _.get(response, "data"),
          headers: _.get(response, "headers"),
        },
      },
    };

    Bugsnag.notify(error, options);
  }

  async authenticate() {
    if (!this.isAuthenticated) {
      // scriptタグが複数あり（applications.js, entries/**/*.js）、ファイル間では、ApiAuthが共有されない。
      // tokenのバリデーションを行うと、ApiAuthを使っているタグの数だけ、 /validate_token への通信が発生する。
      // このため、tokenのバリデーションはスキップする。
      //
      // TODO: validateTokenを呼び出すように

      // return this.apiAuth.validateToken()...;
      this._isAuthenticated = true;
    }

    return Promise.resolve();
  }
}
