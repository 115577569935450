import React, { useMemo } from 'react';
import i18next from 'i18n';
import { Approver } from 'utilities/api/models';
import { BootstrapTable, TableHeaderColumn } from 'react-bootstrap-table';
import { Button } from 'react-bootstrap';

const typeNameBy: { [key: string]: string } = {
  'company_post': i18next.t('approvals.flows.userDepartment'),
  'payer_department_post': i18next.t('approvals.flows.payerDepartment'),
  'group': i18next.t('approvals.flows.department'),
  'department_post_relationship': i18next.t('approvals.flows.departmentPost'),
  'project_post': i18next.t('approvals.flows.projectPost'),
  'user': i18next.t('approvals.flows.approver'),
};
const sortBy = Object.keys(typeNameBy);

const options = {
  noDataText: i18next.t('commons.messages.noDataFound'),
};

interface Props {
  selectedApprovers: Approver[];
  onRemoveClick: (approver: Approver) => void;
}

const formatTypeName = (type: string): string => {
  return typeNameBy[type];
};

const formatName = (name: string): JSX.Element => {
  return (
    <div style={ { whiteSpace: 'normal', wordWrap: 'break-word' } }>
      { name }
    </div>
  );
};

const formatRemoveButton = (_, row, handleRemoveClick): JSX.Element => {
  return (
    <Button bsStyle="danger" onClick={ (): void => { handleRemoveClick(row); } }>
      { i18next.t('commons.actions.remove') }
    </Button>
  );
};

const sortByType = (selectedApprovers: Approver[]): Approver[] => {
  return selectedApprovers.sort((a, b) => {
    return sortBy.indexOf(a.type) - sortBy.indexOf(b.type);
  });
};

const ApprovalFormSelectedApprovarsList: React.FC<Props> = ({
  selectedApprovers,
  onRemoveClick,
}) => {
  const sortedSelectedApprovers = useMemo(() => {
    return sortByType(selectedApprovers);
  }, [selectedApprovers]);

  return (
    <BootstrapTable data={ sortedSelectedApprovers } options={ options }>
      <TableHeaderColumn dataField="id" hidden={ true } isKey={ true } />
      <TableHeaderColumn width="150" dataField="type" dataFormat={ formatTypeName }>
        { i18next.t('commons.words.type') }
      </TableHeaderColumn>
      <TableHeaderColumn width="150" dataField="name" dataFormat={ formatName }>
        { i18next.t('commons.words.name') }
      </TableHeaderColumn>
      <TableHeaderColumn width="100" dataAlign='center' dataFormat={ formatRemoveButton } formatExtraData={ onRemoveClick } />
    </BootstrapTable>
  );
};

export default ApprovalFormSelectedApprovarsList;
