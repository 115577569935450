import FormItem from './FormItem';
import PropTypes from 'prop-types';
import React from 'react';
import _isNil from 'lodash/isNil';
import formatter from 'utilities/formatter';

const Report = (props) => {
  const report = props.value;
  const titleElement = formatter.text(report.title, '-');
  const url = _isNil(report.preReportId) ? `/approving_reports/${report.id}` : `/approving_pre_reports/${report.preReportId}`;

  return (
    <>
      <FormItem label='reportTitle'>
        { _isNil(report.id)
          ? titleElement
          : (
            <a href={ url }>{ titleElement }</a>
          )
        }
      </FormItem>
    </>
  );
};

Report.defaultProps = {
  value: {},
};

Report.propTypes = {
  value: PropTypes.shape({
    id: PropTypes.string,     // 経費精算の ID
    title: PropTypes.string,  // 経費精算の申請名
    status: PropTypes.string, // 経費精算のステータス
  }),
};

export default React.memo(Report);
