import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';
import _isNil from 'lodash/isNil';

export default class NumberField extends PureComponent {
  constructor(props) {
    super(props);

    this.handleChange = this.handleChange.bind(this);
  }

  get pattern() {
    if (this.props.pattern) {
      return this.props.pattern;
    }

    const { allowMinus, allowZeroPadding, allowDecimal } = this.props;

    return `${allowMinus ? '-?' : ''}${allowZeroPadding ? '\\d*' : '([1-9]\\d*|0)?'}${allowDecimal ? '(\\.\\d*)?' : ''}`;
  }

  get value() {
    return _isNil(this.props.value) ? '' : this.props.value;
  }

  handleChange(e) {
    if (e.target.checkValidity()) {
      this.props.onChange(e.target.value);
    }
  }

  render() {
    // type='number'だと数字以外の入力が可能な上にonchangeが発火しないため、Reactで制御できない
    // type='tel'を使うとスマホのブラウザで見る時に数字キーボードが表示される
    // @see https://github.com/BearTail/keihi/pull/5505

    return (
      <input className={ this.props.className || '' }
        type='tel'
        autoFocus={ this.props.autoFocus }
        pattern={ this.pattern }
        value={ this.value }
        onChange={ this.handleChange }
        onBlur={ this.props.onBlur }
        onKeyDown={ this.props.onKeyDown }
        disabled={ this.props.disabled }
        { ...this.props.options }
      />
    );
  }
}

NumberField.defaultProps = {
  className: 'form-control',
  value: '',
  options: {},
};

NumberField.propTypes = {
  className: PropTypes.string,
  autoFocus: PropTypes.bool,
  pattern: PropTypes.string,
  allowMinus: PropTypes.bool,
  allowZeroPadding: PropTypes.bool,
  allowDecimal: PropTypes.bool,
  value: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  onChange: PropTypes.func.isRequired,
  onBlur: PropTypes.func,
  onKeyDown: PropTypes.func,
  options: PropTypes.object,
  disabled: PropTypes.bool,
};
