import { useCallback, useState } from 'react';

interface UseReturn {
  readonly page: number | "";
  readonly canNextPage: () => boolean;
  readonly canPreviousPage: () => boolean;
  readonly handleInputPage: (e: React.ChangeEvent<HTMLInputElement>) => void;
  readonly handlePageChange: (e: React.FocusEvent<HTMLInputElement> | React.FormEvent<HTMLFormElement>) => void;
}

/**
 * ロジック: テーブルのページネーション
 */
export const useHooks = (onPageChange: (page: number, sizePerPage: number) => void, currentPage: number, paginationSize: number, sizePerPage: number): UseReturn => {
  // 現在のページと移動先ページの表示を分けるためにstateに渡す
  const [page, setPage] = useState<number | ''>(currentPage);

  /**
   * 次のページに移動できるか
   */
  const canNextPage = useCallback((): boolean => {
    return currentPage !== paginationSize;
  }, [currentPage, paginationSize]);

  /**
   * 前のページに移動できるか
   */
  const canPreviousPage = useCallback((): boolean => {
    return currentPage !== 1;
  }, [currentPage]);

  /**
   * 移動先ページ入力
   * 送信はしない
   */
  const handleInputPage = (e: React.ChangeEvent<HTMLInputElement>): void => {
    const value = Number(e.target.value);

    if (Number.isNaN(value) || value < 1) {
      setPage('');
      return;
    }

    setPage(value);
  };

  /**
   * 移動先ページ手入力フォーム送信
   */
  const handlePageChange = (e: React.FocusEvent<HTMLInputElement> | React.FormEvent<HTMLFormElement>): void => {
    e.preventDefault();

    // 未入力または0以下が入力されたら1ページに戻す
    if (!page || page < 1) {
      setPage(1);
      onPageChange(1, sizePerPage);
      return;
    }

    // ページ数よりも大きい値が入力されたら最終ページに戻す
    if (page > paginationSize) {
      setPage(paginationSize);
      onPageChange(paginationSize, sizePerPage);
      return;
    }

    onPageChange(page, sizePerPage);
  };

  return {
    page,
    canNextPage,
    canPreviousPage,
    handleInputPage,
    handlePageChange,
  };
};
