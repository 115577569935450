import { CompositeSpecification } from './RequestSearchSpecification/';

/**
 * TOKIUM 共通部 において表示が可能か否か判断する仕様
 */
export class CommonRequestSearchSpecification extends CompositeSpecification {
  forApprover: boolean;

  inputProject: boolean;

  constructor(
    forApprover: boolean,
  ) {
    super();

    const { preference: { inputProject } } = userPreferences;
    this.forApprover = forApprover;
    this.inputProject = inputProject;
  }

  isSatisfiedBy(candidate: string): boolean {
    switch (candidate) {
      case 'sequenceNum':
      case 'requestName':
      case 'requestedAt':
      case 'lastApprovedAt':
      case 'approvalStatus':
      case 'requestType': return true;
      case 'requesterName':
      case 'belongingDepartment':
      case 'approvalFlowName':
      case 'includingApprover':
      case 'approvedApprover': return this.forApprover;
      case 'projectID':
      case 'projectName': return this.forApprover && this.inputProject;
      default: return false;
    }
  }
}
