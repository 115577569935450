import RadioGroup from "components/fields/RadioGroup";
import LabeledComponent from "components/LabeledComponent";
import i18next from "i18n";
import React from "react";
import { SearchRequest } from "utilities/api/requests/expenseRequests/SearchRequest";

type Value = Required<SearchRequest>["receiptExpenseMatching"] | null;

interface Props {
  onChange: (value: Value) => void;
  value: Value;
}

export function getRadios(): {
  description?: string;
  label: string;
  value: NonNullable<Value>;
}[] {
  return [
    {
      label: i18next.t(
        "transactions.index.searchbox.receiptExpenseMatching.alreadyMatched",
      ),
      value: "already_matched",
    },
    {
      description: i18next.t(
        "transactions.index.searchbox.receiptExpenseMatching.descriptions.markedAsMatched",
      ),
      label: i18next.t(
        "transactions.index.searchbox.receiptExpenseMatching.markedAsMatched",
      ),
      value: "marked_as_matched",
    },
    {
      label: i18next.t(
        "transactions.index.searchbox.receiptExpenseMatching.notMatched",
      ),
      value: "not_matched",
    },
    {
      label: i18next.t(
        "transactions.index.searchbox.receiptExpenseMatching.electronicOrNotAttachedReceiptImage",
      ),
      value: "electronic_or_not_attached_receipt_image",
    },
  ];
}

const ReceiptExpenseMatchingCondition: React.FC<Props> = (props) => {
  const { value, onChange } = props;
  const radios = getRadios();

  return (
    <LabeledComponent
      label={i18next.t(
        "transactions.index.searchbox.receiptExpenseMatching.label",
      )}
    >
      <RadioGroup isClearable={true} {...{ onChange, radios, value }} />
    </LabeledComponent>
  );
};

export default ReceiptExpenseMatchingCondition;
