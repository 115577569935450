import PropTypes from 'prop-types';
import React, { Component } from 'react';
import i18next from 'i18n';
import { Button } from 'react-bootstrap';

export default class ApproverSearchBox extends Component {
  render() {
    const { searchText, reportIndex } = this.props;
    return (
      <div>
        <div
          className="col-sm-offset-6 col-md-offset-6 col-sm-6 col-md-6 text-right"
          style={ { marginBottom: '5px', paddingRight: '0px' } }>
          <span className='fa fa-fw fa-save' />
          <a onClick={ () => this.props.resetSearchText() }>
            { i18next.t('transactions.index.searchbox.resetCondition') }
          </a>
        </div>
        <div className="col-sm-10 col-md-10" style={ { paddingLeft: '0px', marginBottom: '15px' } }>
          <input
            type='text'
            className='form-control'
            placeholder={ this.props.placeholder }
            value={ this.props.searchText }
            onChange={ (e) => this.props.setSearchText(e.target.value) }
            onKeyDown={ (e) => {
              const ENTER = 13;
              if (e.keyCode === ENTER) { this.props.search(searchText, reportIndex); }
            } }
          />
        </div>
        <Button
          className='col-sm-2 col-md-2 btn btn-accent'
          type='button'
          onClick={ () => this.props.search(searchText, reportIndex) }
        > { i18next.t('commons.actions.search') } </Button>
      </div>
    );
  }
}

ApproverSearchBox.propTypes = {
  resetSearchText: PropTypes.func.isRequired,
  setSearchText: PropTypes.func.isRequired,
  searchText: PropTypes.string.isRequired,
  search: PropTypes.func.isRequired,
  placeholder: PropTypes.string.isRequired,
};
