
import React from 'react';
import { Item } from './Item';

interface Props {
  label: string;
  value: { name: string; } | null;
}

export const SelectedItem: React.FC<Props> = ({
  label,
  value,
}) => {
  if (value === null) { return <></>; }

  return (
    <Item label={ label } value={ value.name }></Item>
  );
};
