import Api from 'utilities/api';
import React, { useEffect, useState } from 'react';
import Select from 'react-select';
import { ControlLabel, FormGroup } from 'react-bootstrap';
import { Controller, useFormContext } from 'react-hook-form';
import { User } from 'utilities/api/models';

interface Props {
  name: string;
  label?: string | JSX.Element;
}

export const ApproverSelectField: React.FC<Props> = ({
  name,
  label,
}) => {
  const { control } = useFormContext();

  const [processing, setProcessing] = useState<boolean>(false);
  const [approvers, setApprovers] = useState<User[]>([]);

  useEffect(() => {
    const fetchApprovers = async (): Promise<void> => {
      setProcessing(true);

      try {
        const res = await Api.approvers.index();
        setApprovers(res.users);
      } catch (e) {
        // nothing to do
      } finally {
        setProcessing(false);
      }
    };
    fetchApprovers();
  }, [setProcessing, setApprovers]);

  const formatOptionLabel = ({ name: userName, email }): JSX.Element => (
    <div>
      <div>{ userName }</div>
      <div style={ { color: '#666', fontSize: '12px' } }>{ email }</div>
    </div>
  );

  if (processing) {
    return (<div>承認者を読込中...</div>);
  }

  return approvers.length > 0 ? (
    <Controller
      name={ name }
      control={ control }
      render={ ({ field: { onChange, value, ref : inputRef } }): React.ReactElement => (
        <FormGroup>
          { label && <ControlLabel>{ label }</ControlLabel> }
          <Select
            inputRef={ inputRef }
            options={ approvers }
            value={ approvers.find((v) => v.id === (value as User)?.id) }
            formatOptionLabel={ formatOptionLabel }
            getOptionValue={ (v: User): string => v.id }
            onChange={ onChange }
            isClearable
          />
        </FormGroup>
      ) }
    />
  ) : (<></>);
};
