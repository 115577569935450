import AttachmentDroparea from 'components/AttachmentDroparea';
import React, { useCallback } from 'react';
import SelectField from 'components/fields/SelectField';
import TextArea from 'components/TextArea';
import { DropZone } from './DropZone';
import { FileValue, FormItemModel } from '../Types';
import { FormElement } from 'components/FormElement';
import {
  FormItemCheckbox, FormItemDateInput, FormItemMultiCheck, FormItemRadio, FormItemTextInput,
} from './Fields';

interface Props {
  editable: boolean;
  formItem: FormItemModel;
  onChange: (value: string[]) => void;
}

const propsAreEqual = (prevProps: Readonly<Props>, nextProps: Readonly<Props>): boolean => (
  prevProps.editable === nextProps.editable
  && JSON.stringify(prevProps.formItem) === JSON.stringify(nextProps.formItem)
  && prevProps.onChange === nextProps.onChange
);
/**
 * 汎用フォームの入力フィールド
 */
export const FormItem: React.FC<Props> = React.memo(({
  editable,
  formItem,
  onChange,
}) => {
  /**
   * 入力欄の変更: テキストと数字
   */
  const handleSingleValueChange = useCallback((value: string) => {
    onChange([value]);
  }, [onChange]);

  /**
   * 入力欄の変更: 複数行テキスト
   */
  const handleValueChange = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
    onChange([e.target.value]);
  }, [onChange]);

  /**
   * 入力欄の変更: チェックボックス
   */
  const handleCheckChange = useCallback((currentValue: string[], selection: string, e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.checked) {
      onChange(currentValue.concat(selection));
    } else {
      onChange(currentValue.filter((it) => it !== selection));
    }
  }, [onChange]);

  /**
   * 入力欄の変更: ファイル削除
   */
  const handleFileDelete = useCallback((e: React.MouseEvent) => {
    e.stopPropagation();
    onChange([]);
  }, [onChange]);

  /**
   * 入力欄
   */
  function renderInput(): JSX.Element | null {
    const { type, selections } = formItem;
    // RequestTypeの時はvalueが渡ってこない
    const fileValue = (formItem.value || []) as FileValue[];
    const stringValue = (formItem.value || []) as string[];

    const params = {
      disabled: !editable,
      value: stringValue[0] || '',
    };

    switch (type) {
      case 'text':
      case 'number':
        return (
          <FormItemTextInput
            { ...params }
            type={ type }
            onChange={ handleSingleValueChange }
          />
        );
      case 'multiline_text':
        return (
          <TextArea
            { ...params }
            className="form-control toggle"
            onChange={ handleValueChange }
          />
        );
      case 'date':
        return (
          <FormItemDateInput
            { ...params }
            onChange={ handleSingleValueChange }
          />
        );
      case 'check':
        return (
          <FormItemCheckbox
            disabled={ !editable }
            label={ selections[0] }
            onChange={ handleCheckChange }
            value={ stringValue }
          />
        );
      case 'multi_check':
        return (
          <FormItemMultiCheck
            disabled={ !editable }
            onChange={ handleCheckChange }
            selections={ selections }
            value={ stringValue }
          />
        );
      case 'radio':
        return (
          <FormItemRadio
            disabled={ !editable }
            onChange={ handleSingleValueChange }
            selections={ selections }
            value={ stringValue }
          />
        );
      case 'select':
        return (
          <SelectField className="toggle"
            isDisabled={ params.disabled }
            value={ params.value ? { value: params.value } : null }
            options={ selections.map((it) => ({  value: it })) }
            getOptionLabel={ (x): string => x.value }
            onChange={ ((option): void => handleSingleValueChange(option?.value)) }
          />
        );
      case 'attachment':
        return (
          <AttachmentDroparea
            className="form-control toggle expand"
            disabled={ !editable }
            onChange={ onChange }
          >
            <DropZone
              editable={ editable }
              onFileDelete={ handleFileDelete }
              value={ fileValue[0] }
            />
          </AttachmentDroparea>
        );
      default:
        return null;
    }
  }

  return (
    <FormElement label={ formItem.label }
      contentProps={ { className:"col-sm-8 col-md-8" } }
      labelProps={ { className:"col-sm-3" } }>
      { renderInput() }
    </FormElement>
  );
}, propsAreEqual);
