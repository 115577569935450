import { AccordionListForm } from "components/renewaled_ui/form_views";
import i18next from "i18next";
import React from "react";
import EligibleInvoiceConfirmationForm from "../../EligibleInvoiceConfirmationForm";
import { useHooks } from "./hooks";
import { Category, EligibleInvoiceFormFieldValue } from "./interfaces";

interface Props {
  /** 必須項目かどうか */
  isRequired: boolean;
  /** 適格請求書の項目値 */
  value: EligibleInvoiceFormFieldValue;
  /** 日当かどうか */
  isAllowance: boolean;
  /** 経費科目 */
  category: Category;
  /** 適格請求書として扱うチェックボックスの変更時の処理 */
  onChangeAsEligibleInvoice: (value: boolean) => void;
  /** 登録番号の変更時の処理 */
  onChangeRegistratedNumber: (value: string | null) => void;
  /** 登録番号の検証時の処理 */
  onVerifyRegistratedNumber: () => void;
  /** 住所又は所在地の変更時の処理 */
  onChangePaidAddress: (value: string) => void;
}

/**
 * 適格請求書項目欄
 */
export const EligibleInvoiceFormField: React.FC<Props> = ({
  isRequired,
  value,
  isAllowance,
  category,
  onChangeAsEligibleInvoice,
  onChangeRegistratedNumber,
  onVerifyRegistratedNumber,
  onChangePaidAddress,
}) => {
  const {
    isShowValidation,
    isValid,
    isShowRegistratedNumberField,
    canChangeAsEligibleInvoice,
    isShowPaidAddressField,
  } = useHooks(value, isAllowance, category);

  const showIcon = (): JSX.Element => {
    if (!isShowValidation) {
      return <></>;
    }
    if (isValid) {
      return <i className={"fa fa-check color-success"} />;
    }

    return <i className={"fa fa-times color-danger"} />;
  };

  return (
    <AccordionListForm
      label={i18next.t("transactions.properties.eligibleInvoiceConfirmation")}
      showIcon={showIcon()}
      required={isRequired}
    >
      <EligibleInvoiceConfirmationForm
        asEligibleInvoice={value.asEligibleInvoice}
        registratedNumber={value.registratedNumber || ""}
        invoicingOrganization={value.invoicingOrganization}
        paidAddress={value.paidAddress || ""}
        notActiveAsEligibleInvoice={!canChangeAsEligibleInvoice}
        isRegistratedNumberShow={isShowRegistratedNumberField}
        isPaidAddressShow={isShowPaidAddressField}
        onChangeAsEligibleInvoice={onChangeAsEligibleInvoice}
        onChangeRegistratedNumber={onChangeRegistratedNumber}
        onVerifyRegistratedNumber={onVerifyRegistratedNumber}
        onChangePaidAddress={onChangePaidAddress}
      />
    </AccordionListForm>
  );
};
