import { passwordErrorMessage } from './validatePassword';

// Types
export interface FormValues {
  password: string;
}

export interface FormErrors {
  password?: string;
}

export const validateInvitationForm = (values: FormValues): FormErrors => {
  const errors: FormErrors = {};

  const passwordError = passwordErrorMessage(values.password);
  if (passwordError) {
    errors.password = passwordError;
  }

  return errors;
};
