import React from 'react';
import i18next from 'i18n';
import styled from 'styled-components';
import { Dropdown, MenuItem } from 'components/renewaled_ui/Dropdown';
import { Dropdown as RBDropdown } from 'react-bootstrap';

const Title = styled.div`
  font-weight: 500;
`;
const DropdownWrapper = styled.div`
  margin-top: 8px;
`;

interface Props {
  /** 1ページ当たりの表示件数の選択肢 */
  readonly sizePerPageList: number[];
  /** 現在のページの表示件数 */
  readonly currentPageDataLength: number;
  /** 1ページ当たりの表示件数 */
  readonly sizePerPage: number;
  /** 1ページ当たりの表示件数を変更した際のハンドラ */
  readonly onSizePerPageList: (sizePerPage: number) => void;
}

/**
 * 表示件数の変更セレクトボタン
 */
export const SizePerPageSelector: React.FC<Props> = (props) => {
  // 表示しない
  if (!props.currentPageDataLength) return <React.Fragment />;

  return (
    <div>
      <Title>
        { i18next.t('commons.words.sizePerPage') }
      </Title>

      <DropdownWrapper>
        <Dropdown
          id='size-per-page-drop-down'
          direction='up'
          align='left'
          toggleButton={ (
            <RBDropdown.Toggle>
              { props.sizePerPage }
            </RBDropdown.Toggle>
          ) }
        >
          { props.sizePerPageList.map((size) => (
            <MenuItem
              key={ `size-per-page-${size}` }
              onClick={ (): void => props.onSizePerPageList(size) }
            >
              {size}
            </MenuItem>
          )) }
        </Dropdown>
      </DropdownWrapper>
    </div>
  );
};
