import FormItem from './FormItem';
import PropTypes from 'prop-types';
import React from 'react';
import formatter from 'utilities/formatter';

const MatchingStatus = (props) => {
  return (
    <FormItem label='matchingStatus'>
      { formatter.text(props.value, '-') }
    </FormItem>
  );
};

MatchingStatus.propTypes = {
  value: PropTypes.string,
};

export default React.memo(MatchingStatus);
