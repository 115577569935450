import * as ActionTypes from "../actions/applicationRequestActions";

const initialState = {
  approvalFlow: null,
  title: null, // サーバーで重複除去した後のタイトルなのでapplication.titleとは違う可能性がある
  showModal: false,
  comment: null,
  showCancelApprovalModal: false,
  cancelApprovalComment: null,
  isRejectModalOpen: false,
  rejectReason: "",
  rejectingInProgress: false,
};

const applicationRequest = (state = initialState, action) => {
  switch (action.type) {
    case ActionTypes.SET_COMMENT:
      return {
        ...state,
        comment: action.comment,
      };
    case ActionTypes.SET_APPROVAL_FLOW:
      return {
        ...state,
        approvalFlow: action.approvalFlow,
      };
    case ActionTypes.TOGGLE_MODAL:
      return {
        ...state,
        showModal: action.showModal,
      };
    case ActionTypes.TOGGLE_CANCEL_APPROVAL_MODAL:
      return {
        ...state,
        showCancelApprovalModal: action.payload.showCancelApprovalModal,
      };
    case ActionTypes.SET_CANCEL_APPROVAL_COMMENT:
      return {
        ...state,
        cancelApprovalComment: action.payload.comment,
      };
    case ActionTypes.SET_TITLE:
      return {
        ...state,
        title: action.title,
      };
    case ActionTypes.SHOW_REJECT_MODAL: {
      return {
        ...state,
        isRejectModalOpen: true,
      };
    }
    case ActionTypes.CLOSE_REJECT_MODAL: {
      return {
        ...state,
        isRejectModalOpen: false,
      };
    }
    case ActionTypes.INPUT_REJECT_REASON: {
      return {
        ...state,
        rejectReason: action.value,
      };
    }
    case ActionTypes.START_REJECTING: {
      return {
        ...state,
        rejectingInProgress: true,
      };
    }
    case ActionTypes.FINISH_REJECTING: {
      return {
        ...state,
        rejectingInProgress: false,
      };
    }
    default:
      return state;
  }
};

export default applicationRequest;
