import CategorySelector from './CategorySelector';
import Clearable from 'components/fields/Clearable';
import FieldWithInputModal from '../FieldWithInputModal';
import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';
import i18next from 'i18n';
import { CreditInvoiceCategorySelector } from './CreditInvoiceCategorySelector.tsx';
import { DebitInvoiceCategorySelector } from './DebitInvoiceCategorySelector.tsx';
import { text as formatText } from 'utilities/formatter';

/** 科目入力モーダル。
 *  経費科目・借方請求明細科目対応。
 *  借方請求明細科目対応は後から行い他の使用場所に影響を出さない為、
 *  - props.categoryTypeに'invoice-debit'が指定された場合は借方請求明細科目セレクタ
 *  - props.categoryTypeに'invoice-credit'が指定された場合は貸方請求明細科目セレクタ
 *  それ以外は経費科目セレクタとする。
 */
export default class CategoryFieldWithInputModal extends PureComponent {
  constructor(props) {
    super(props);

    this.handleSelect = this.handleSelect.bind(this);
    this.handleClear = this.handleClear.bind(this);
    this.renderField = this.renderField.bind(this);
    this.renderModalBody = this.renderModalBody.bind(this);
  }

  handleSelect(_category, _isSelected, selection) {
    this.props.onSelect(selection);
  }

  handleClear() {
    // 複数選択可の場合は、全てのチェックを外す.
    this.props.onSelect(this.props.isMultiSelectable ? [] : null);
  }

  formatValue(value) {
    const emptyText = this.props.placeholder || null;

    if (this.props.isMultiSelectable) {
      if (value && value.length > 0) {
        return formatText(value.map((v) => v.name).join(', '), emptyText);
      }

      return formatText(null, emptyText);
    }

    return formatText(value?.name, emptyText);
  }

  renderField({ className, onClick }) {
    if (this.props.isStatic) {
      return (
        <span className={ `${className} form-control-static`  }>
          { this.formatValue(this.props.value) }
        </span>);
    }

    return (
      <Clearable className={ `${className} form-control` }
        onClick={ onClick }
        onClear={ this.handleClear }
      >
        { this.formatValue(this.props.value) }
        <i className='caret' />
      </Clearable>
    );
  }

  renderModalBody() {
    switch (this.props.categoryType) {
      case 'invoice-credit':
        return (
          <CreditInvoiceCategorySelector
            isMultiSelectable={ this.props.isMultiSelectable }
            onSelect={ this.handleSelect }
            onlyChildCategories={ this.props.onlyChildCategories }
            value={ this.props.value }
          />
        );
      case 'invoice-debit':
        return (
          <DebitInvoiceCategorySelector
            isMultiSelectable={ this.props.isMultiSelectable }
            onSelect={ this.handleSelect }
            onlyChildCategories={ this.props.onlyChildCategories }
            value={ this.props.value }
          />
        );
      default:
        return (
          <CategorySelector
            isMultiSelectable={ this.props.isMultiSelectable }
            onSelect={ this.handleSelect }
            onlyChildCategories={ this.props.onlyChildCategories }
            value={ this.props.value }
          />
        );
    }
  }

  /** 経費科目or借方請求明細科目でモーダルタイトルを出し分ける */
  modalTitle() {
    if (this.props.categoryType === 'invoice-credit') return i18next.t('paymentRequests.creditInvoiceCategory.actions.select');
    if (this.props.categoryType === 'invoice-debit') return i18next.t('paymentRequests.debitInvoiceCategory.actions.select');
    return i18next.t('categories.actions.selectCategory');
  }

  render() {
    return (
      <FieldWithInputModal className={ this.props.className }
        isStatic={ this.props.isStatic }
        renderField={ this.renderField }
        renderModalBody={ this.renderModalBody }
        modalTitle={ this.modalTitle() }
        modalSize='md'
      />
    );
  }
}

CategoryFieldWithInputModal.defaultProps = {
  className: 'category-field',
  isStatic: false,
  isMultiSelectable: false,
  onlyChildCategories: false,
};

CategoryFieldWithInputModal.propTypes = {
  className: PropTypes.string,
  isStatic: PropTypes.bool.isRequired,
  value: PropTypes.oneOfType([
    PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.string.isRequired,
        name: PropTypes.string,
      }),
    ),
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      name: PropTypes.string,
    }),
  ]),
  isMultiSelectable: PropTypes.bool,
  placeholder: PropTypes.string,
  onSelect: PropTypes.func,
  onlyChildCategories: PropTypes.bool,
};
