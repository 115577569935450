import React from 'react';

interface Props {
  disabled: boolean;
  selections: string[];
  value: string[];
  onChange: (currentValue: string[], selection: string, e: React.ChangeEvent<HTMLInputElement>) => void;
}

/**
 * 汎用フォームの複数チェック
 */
export const FormItemMultiCheck: React.FC<Props> = React.memo(({
  disabled, selections, value, onChange,
}) => {
  return (
    <div>
      {
        selections.map((selection, i) => (
          // 同じ選択肢を二回入れられるとkeyが重複するのでkeyにselectionが使えない
          <div className="checkbox" key={ i }>
            <label>
              <input
                disabled={ disabled }
                type="checkbox"
                checked={ value.includes(selection) }
                onChange={ onChange.bind(null, value, selection) }
              />
              { selection }
            </label>
          </div>
        ))
      }
    </div>
  );
});
