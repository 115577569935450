import * as Types from "../types";

/**
 * 分割可能な経費かどうか
 * @param expense 分割元経費
 */
export const isSplittableExpense = (expense: Types.Expense): boolean => {
  const {
    directProductTableId,
    formId,
    editable,
    id,
    originalAmountCurrencyId,
    route,
    reportId,
  } = expense;

  if (!id) return false;
  if (!editable) return false;
  if (formId !== "normal") return false;
  if (originalAmountCurrencyId !== "JPY") return false;
  if (directProductTableId) return false;
  if (route) return false;
  // reportIdが存在することで精算フローに乗っているとみなせる
  if (reportId) return false;

  return true;
};
