import i18next from "i18n";
import React from "react";
import { Popover } from "react-bootstrap";
import { Expense } from "utilities/api/models";
import { IconWithOverlay } from "./IconWithOverlay";

type Props = Pick<Expense, "isElectronicReceiptImage">;

/** 電子領収書フラグアイコン */
export const ElectronicReceiptImageIconWithOverlay: React.FC<Props> = ({
  isElectronicReceiptImage,
}) => {
  if (
    !userPreferences.preference.enableExpenseElectronicReceiptImageFlag ||
    !isElectronicReceiptImage
  ) {
    return null;
  }

  const isElectronicReceiptImageOverlay = (): JSX.Element => {
    return (
      <Popover id="corporate-popup">
        {i18next.t("transactions.messages.isElectronicReceiptImage")}
      </Popover>
    );
  };

  return (
    <IconWithOverlay
      overlay={isElectronicReceiptImageOverlay()}
      icon={"fas fa-bolt"}
    />
  );
};
