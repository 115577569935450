import PropTypes from 'prop-types';
import React, { Component } from 'react';
import i18next from 'i18n';
import { Button, Modal } from 'react-bootstrap';

/*
 * SimpleModalというコンポーネントを作ったので
 * 今後はそちらを使ってください
 * this.props = {
 *   show     : モーダルの表示非表示
 *   onDelete : 削除ボタンが押された時のコールバック
 *   onCancel : 削除がキャンセルされた時のコールバック
 * }
 */
export default class DeleteConfirmationModal extends Component {
  constructor(props) {
    super(props);
    this.state = { show: props.show };
  }

  // eslint-disable-next-line camelcase
  UNSAFE_componentWillReceiveProps(props) {
    this.setState({ show: props.show });
  }

  onDelete(e) {
    if (this.props.onDelete) {
      this.props.onDelete();
    }

    if (this.props.autoClose) {
      this.close();
    }
  }

  render() {
    return (
      <Modal
        show = { this.state.show }
        onHide = { this.close.bind(this) } >

        <Modal.Header>
          <Modal.Title id="contained-modal-title">{ i18next.t('commons.messages.confirmDelete') }</Modal.Title>
        </Modal.Header>

        {
          this.props.content !== '' && (
            <Modal.Body>
              <div>
                { this.props.content }
              </div>
            </Modal.Body>
          )
        }

        <Modal.Footer>
          <Button onClick={ this.close.bind(this) } bsStyle='default'>{ i18next.t('commons.actions.cancel') }</Button>
          <Button onClick={ this.onDelete.bind(this) } bsStyle='danger'>{ i18next.t('commons.actions.delete') }</Button>
        </Modal.Footer>

      </Modal>
    );
  }

  show() {
    this.setState({ show: true });
  }

  close() {
    this.setState({ show: false });
    if (this.props.onCancel) {
      this.props.onCancel();
    }
  }
}

DeleteConfirmationModal.defaultProps = {
  autoClose: true,
  content: '',
};

DeleteConfirmationModal.propTypes = {
  show:     PropTypes.bool,
  onDelete: PropTypes.func,
  onCancel: PropTypes.func,
  autoClose: PropTypes.bool,
  content: PropTypes.string,
};
