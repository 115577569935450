import ListSelector from 'components/ListSelector';
import React, { FC } from 'react';
import SimpleModal, { ButtonProps } from 'components/SimpleModal';

interface List {
  id: string;
  isChecked: boolean;
  name: string;
  popoverText?: string;
}

interface Props {
  buttons?: ButtonProps[];
  close: () => void;
  list: List[];
  listClicked: (id: string, isChecked: boolean) => void;
  show: boolean;
  title: string;
}

const ListModal: FC<Props> = (props) => {
  const {
    buttons, close, list, listClicked, show, title,
  } = props;

  return (
    <SimpleModal
      bodyClassName='approver-modal'
      buttons={ buttons }
      close={ close }
      dialogClassName='approver-dialog'
      show={ show }
      title={ title }
    >
      <ListSelector list={ list } listClicked={ listClicked }/>
    </SimpleModal>
  );
};

export default ListModal;
