import * as actions from '../actions/teikiRoutes';
import * as searchBoxActions from '../../fare_transactions/actions';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import SimpleModal from 'components/SimpleModal';
import TeikiRouteForm from './TeikiRouteForm';
import i18next from 'i18n';
import isEmpty from 'lodash/isEmpty';
import { connect } from 'react-redux';
import { displayMessage } from 'actions/ActionCreators';
import { getMessageFromResponse } from 'utilities/Utils';

export class TeikiRoutesModal extends Component {
  render() {
    return (
      <SimpleModal
        dialogClassName='root-category-modal'
        title={ i18next.t(`preferences.teiki.titles.${this.props.isEdit ? 'edit' : 'add'}`) }
        show={ this.props.show }
        close={ this.props.onHide }
        buttons={ [{
          color: 'success',
          disabled: isEmpty(this.props.route) || this.props.isProcessing || this.props.isSearching,
          onClick: (e) => (
            this.props.isEdit
              ? this.props.onUpdate()
              : this.props.onCreate(this.props.user.id)),
          content: i18next.t(`commons.actions.${this.props.isEdit ? 'save' : 'create'}`),
        }] }>
        { this.renderForm() }
        { this.renderMessage() }
      </SimpleModal>
    );
  }

  renderMessage() {
    return (
      <div style={ { textAlign: 'center' } }>
        { '※バスを含む定期券については、定期控除が適切に行われない場合があります' }
      </div>
    );
  }

  renderForm() {
    return (
      <form className='form-horizontal transaction-form'>
        <div className='fare-search-box'>
          <TeikiRouteForm userId={ this.props.user.id }
            teikiRoute={ this.props.teikiRoute }
            isSearching={ this.props.isSearching }
          />
        </div>
      </form>
    );
  }
}

function mapStateToProps(state, ownProps) {
  return {
    isEdit: !!ownProps.teikiRoute.id,
    route: state.searchBox.route,
  };
}

function mapDispatchToProps(dispatch, ownProps) {
  return {
    onHide() {
      dispatch(actions.closeTeikiRouteModal());
      dispatch(searchBoxActions.resetSearchBox());
    },
    onCreate(userId) {
      dispatch(actions.toggleProcessingStatus(true));
      dispatch(actions.onCreate(userId))
        .then(() => {
          dispatch(searchBoxActions.resetSearchBox());
        })
        .catch((e) => {
          // TODO: errorsが返ってきているので、入力ミスした箇所にエラーメッセージを表示すること
          dispatch(displayMessage('error', getMessageFromResponse(e)));
        })
        .finally(() => {
          dispatch(actions.toggleProcessingStatus(false));
        });
    },
    onUpdate() {
      dispatch(actions.toggleProcessingStatus(true));
      dispatch(actions.onUpdate())
        .then(() => {
          dispatch(searchBoxActions.resetSearchBox());
        })
        .catch((e) => {
          // TODO: errorsが返ってきているので、入力ミスした箇所にエラーメッセージを表示すること
          dispatch(displayMessage('error', getMessageFromResponse(e)));
        })
        .finally(() => {
          dispatch(actions.toggleProcessingStatus(false));
        });
    },
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(TeikiRoutesModal);

TeikiRoutesModal.defaultProps = {
  user: {},
  onCreate: () => null,
  onUpdate: () => null,
  isProcessing: false,
  isSearching: false,
};

TeikiRoutesModal.propTypes = {
  show:                PropTypes.bool.isRequired,
  teikiRoute:          PropTypes.object.isRequired,
  onHide:              PropTypes.func.isRequired,
  onCreate:            PropTypes.func.isRequired,
  onUpdate:            PropTypes.func.isRequired,
  user: PropTypes.shape({
    id: PropTypes.string,
    name: PropTypes.string,
    email: PropTypes.string,
  }).isRequired,
  isProcessing:         PropTypes.bool.isRequired,
  isSearching:         PropTypes.bool.isRequired,
};
