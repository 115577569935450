import Api from 'utilities/api';
import { fetchAsync } from 'actions/AsyncAction';

const prefix = `approvers_search`;

export const CONFIG_APPROVERS_USER_SEARCH_CONDITION = `${prefix}/CONFIG_APPROVERS_USER_SEARCH_CONDITION`;
export function configureApproversUserSearchConditions(key, value) {
  return {
    type: CONFIG_APPROVERS_USER_SEARCH_CONDITION,
    key,
    value,
  };
}

export const REQUEST_APPROVERS_SEARCH = `${prefix}/REQUEST_APPROVERS_SEARCH`;
export function requestApproversUserSearch() {
  return { type: REQUEST_APPROVERS_SEARCH };
}

export const RECEIVE_APPROVERS_SEARCH = `${prefix}/RECEIVE_APPROVERS_SEARCH`;
export function receiveApproversSearch(value) {
  return { type: RECEIVE_APPROVERS_SEARCH, value };
}

export function searchApprovers(value) {
  return (dispatch, getState) => {
    dispatch(requestApproversUserSearch());
    return dispatch(fetchAsync(
      Api.approvers.index, { searchText: value },
    )).then(
      (data) => dispatch(receiveApproversSearch(data)),
    );
  };
}
