import AnalysesPreference from './AnalysesPreference';
import ExportPreference from './ExportPreference';
import IcCardOption from './IcCardOption';
import { combineReducers } from 'redux';

const preferenceApp = combineReducers({
  ExportPreference,
  IcCardOption,
  AnalysesPreference,
});

export default preferenceApp;
