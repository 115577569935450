import ViewerSettingStorage from "components/renewaled_ui/FileViewer/hooks/ViewerSettingStorage";
import { useState } from "react";
import { ImageMIMETypes, MIMEType } from "utilities/api/types/MIMEType";

interface UseReturn {
  /** 削除可能か */
  readonly isDeletable: boolean;
  /** ダウンロード可能か */
  readonly isDownloadable: boolean;
  /** ファイルドロップ可能か */
  readonly isDroppable: boolean;
  /** 変形可能か */
  readonly isTransformable: boolean;
  /** クリック可能か */
  readonly isClickable: boolean;
  /** ツールバーを表示するか */
  readonly isShowToolBar: boolean;
  /** サブバーを表示するか */
  readonly isShowSubBar: boolean;
  /** PDFか */
  readonly isPDF: boolean;
  /** ブラウザ既存ビューアーか */
  readonly isBrowserViewer: boolean;
  /** 切り替えるを押した */
  readonly onClickChangeViewer: (
    e: React.MouseEvent<HTMLElement, MouseEvent>,
  ) => void;
}

/**
 * ビューアーロジック
 */
export const useHooks = (
  showToolbar = true,
  isDeletable = false,
  isDownloadable = true,
  isDroppable = false,
  isTransformable = true,
  isShowToolBar = true,
  isShowSubBar = true,
  mimeType?: MIMEType,
): UseReturn => {
  const [isBrowserViewer, setIsBrowserViewer] = useState<boolean>(
    ViewerSettingStorage.data.isBrowserViewer,
  );

  /**
   * 画像ファイルか
   */
  const isImage = Boolean(
    mimeType &&
      (ImageMIMETypes as readonly string[]).includes(mimeType) &&
      mimeType !== "application/pdf",
  );

  /**
   * PDFファイルか
   */
  const isPDF = Boolean(mimeType && mimeType === "application/pdf");

  /**
   * ビューアーの切り替えを押した
   */
  const onClickChangeViewer = (
    e: React.MouseEvent<HTMLElement, MouseEvent>,
  ): void => {
    e.stopPropagation();
    const isBrowser = ViewerSettingStorage.data.isBrowserViewer;
    setIsBrowserViewer(!isBrowser);
    ViewerSettingStorage.set({
      ...ViewerSettingStorage.data,
      isBrowserViewer: !isBrowser,
    });
  };

  return {
    isDeletable: showToolbar && isDeletable,
    isDownloadable: showToolbar && isDownloadable,
    isDroppable: showToolbar && isDroppable,
    isTransformable: (isImage || isPDF) && showToolbar && isTransformable,
    isClickable: isImage || mimeType == null,
    isShowToolBar,
    isShowSubBar,
    isPDF,
    isBrowserViewer,
    onClickChangeViewer,
  };
};
