import WebStorage from 'utilities/web_storage';

/**
 * ローカルストレージを扱う処理をまとめたクラス
 */
export class LocalStorageUtil<Data> {
  private storage = new WebStorage();

  private _data: null | Data = null;

  constructor(
    /** バージョン マイグレーションに使う */
    private version: string,
    /** LocalstrageのKEY */
    private key: string[],
    /** 初期値 */
    private defaultData: Data,
    /**
     * マイグレーションを実行する
     * バージョンが違えばパラメーターを追加する処理を書く
     */
    private migrateSchema: ((params: Data) => Data) | null = null,
  ) {
    this.migrate();
  }

  /**
   * キャッシュデータを返す
   * なければ初期値を返す
   */
  public get data(): Data {
    return this._data || this.storage.getItem(this.key) || this.defaultData;
  }

  /**
   * データを保存する
   */
  public set(params: Data): void {
    this.storage.setItem(this.key, {
      ...params,
      /** 保存時にバージョンも保存する */
      _version: this.version,
    });
  }

  /**
   * キャッシュデータをクリアする
   */
  public clear(): void {
    this.set(this.defaultData);
  }

  /**
   * マイグレーションをする
   */
  private migrate(): void {
    if (!this.migrateSchema) return;
    this.set(this.migrateSchema(this.data));
  }
}
