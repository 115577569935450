import Clearable from 'components/fields/Clearable';
import FieldWithInputModal from './FieldWithInputModal';
import ProjectSelector from './projects/ProjectSelector';
import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';
import i18next from 'i18n';

export default class ProjectField extends PureComponent {
  constructor(props) {
    super(props);

    /** @note defaultProps で定義するとレンダリング前に window 変数にアクセスする都合、テストが落ちるので移動 */
    this.isCreatable = (this.props.isCreatable !== void 0)
      ? this.props.isCreatable
      : userPreferences.preference.allowMembersToCreateProjects;
    this.handleSelect = this.handleSelect.bind(this);
    this.handleClear = this.handleClear.bind(this);
    this.renderField = this.renderField.bind(this);
    this.renderModalBody = this.renderModalBody.bind(this);
  }

  handleSelect(_project, _isSelected, selection) {
    this.props.onSelect(selection);
  }

  handleClear() {
    this.handleSelect(this.props.value, false, this.props.isMultiSelectable ? [] : null);
  }

  projectText(projectDict, index) {
    const canma = index === 0 ? '' : ',';
    if (!projectDict || (!projectDict.displayId && !projectDict.id && !projectDict.name)) {
      return (
        <span className='txt txt-disabled'>{i18next.t('commons.status.notEntered')}</span>
      );
    }

    if (!projectDict.name) {
      return (
        <span>
          { canma }
          <span className='txt txt-disabled'>{i18next.t('projects.none')}</span>
          ({projectDict.displayId})
        </span>
      );
    }

    return (
      <span>
        { canma }
        {projectDict.name}({projectDict.displayId})
      </span>
    );
  }

  formatProjects(projectDict) {
    if (projectDict.length === 0) {
      return (
        <span className='txt txt-disabled'>{i18next.t('commons.status.notEntered')}</span>
      );
    }

    return (
      <span>
        { projectDict.map((p, i) => this.projectText(p, i)) }
      </span>
    );
  }

  formatValue(value) {
    return this.props.isMultiSelectable ? this.formatProjects(value) : this.projectText(value, 0);
  }

  renderField({ className, onClick }) {
    if (this.props.isStatic) {
      return (
        <span className={ `${className} form-control-static`  }>
          { this.formatValue(this.props.value) }
        </span>);
    }

    return (
      <Clearable className={ `${className} form-control` }
        onClick={ this.props.disabled ? null : onClick }
        onClear={ this.handleClear }
        disabled={ this.props.disabled }
        style={ this.props.disabled ? { cursor: 'not-allowed' } : {} }
      >
        { this.formatValue(this.props.value) }
        <i className='caret' />
      </Clearable>
    );
  }

  renderModalBody() {
    return (
      <ProjectSelector
        isMultiSelectable={ this.props.isMultiSelectable }
        isCreatable={ this.isCreatable }
        selection={ this.props.value }
        ownerId={ this.props.ownerId }
        onSelect={ this.handleSelect }
        scope={ this.props.scope }
        showDeletedCheckBox={ this.props.showDeletedCheckBox }
        showRecentlyUsedMessage={ this.props.showRecentlyUsedMessage }
        skipAllValidation={ this.props.skipAllValidation }
      />
    );
  }

  render() {
    return (
      <FieldWithInputModal className={ this.props.className }
        isStatic={ this.props.isStatic }
        renderField={ this.renderField }
        renderModalBody={ this.renderModalBody }
        modalTitle={ i18next.t('projects.actions.selectProject') }
        modalSize='md'
        disabled={ this.props.disabled }
      />
    );
  }
}

ProjectField.defaultProps = {
  className: 'project-field',
  isStatic: false,
  isMultiSelectable: false,
  scope: null,
  disabled: false,
  showDeletedCheckBox: false,
  showRecentlyUsedMessage: true,
  skipAllValidation: false,
};

ProjectField.propTypes = {
  className: PropTypes.string,
  isStatic: PropTypes.bool.isRequired,
  isCreatable: PropTypes.bool,
  value: PropTypes.oneOfType([
    PropTypes.arrayOf(
      PropTypes.shape({
        displayId: PropTypes.string,
        name: PropTypes.string,
      }),
    ),
    PropTypes.shape({
      displayId: PropTypes.string,
      name: PropTypes.string,
    }),
  ]),
  ownerId: PropTypes.string,
  isMultiSelectable: PropTypes.bool,
  onSelect: PropTypes.func,
  onFieldStateChange: PropTypes.func,
  scope: PropTypes.oneOf(['all']),
  disabled: PropTypes.bool,
  showDeletedCheckBox: PropTypes.bool,
  showRecentlyUsedMessage: PropTypes.bool,
  skipAllValidation: PropTypes.bool,
};
