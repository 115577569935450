import CompanyExpenseAccountAssigneeComponent from './CompanyExpenseAccountAssigneeComponent';
import React from 'react';
import SmartCheckBox from 'components/SmartCheckBox';
import i18next from 'i18n';
import { Controller, useFormContext } from 'react-hook-form';
import { TaskCompanyExpenseAccountAssignee } from 'utilities/api/models/accountingDataScheduledExports/Task';

export const BankExportForm = React.forwardRef((_, ref?: React.Ref<HTMLDivElement>) => {
  const {
    control,
    watch,
    setValue,
  } = useFormContext();

  const enableBankDataCreation = watch('enableBankDataCreation');

  const initValueCompanyExpenseAccountAssignee: TaskCompanyExpenseAccountAssignee = {
    companyExpenseAccount: null,
    exportFileName: '',
    paymentSpecificationType: null,
    paymentRelativeSpecificationType: null,
    paymentRelativeSpecificationDays: null,
    paymentSpecifiedDate: null,
  };

  const onChangeEnableBankDataCreation = (value: boolean, onChange: (value: boolean) => void): void => {
    onChange(value);

    if (value) setValue('companyExpenseAccountAssignees', [initValueCompanyExpenseAccountAssignee]);
    else setValue('companyExpenseAccountAssignees', []);
  };


  return (
    <>
      <div ref={ ref } />
      <h4 className='title'>
        { i18next.t('accountingDataScheduledExports.tasks.forms.export.companyExpenseAccount.title') }
      </h4>
      <Controller
        name='enableBankDataCreation'
        control={ control }
        render={ ({ field: { onChange, value } }): React.ReactElement => (
          <SmartCheckBox
            onChange={ (e):void =>  onChangeEnableBankDataCreation(e, onChange) }
            checked={ value || false }
            label={ i18next.t('accountingDataScheduledExports.tasks.forms.export.enableBankDataCreation.label') }
          />
        ) }
      />
      {
        enableBankDataCreation && <>
          <CompanyExpenseAccountAssigneeComponent
            enableBankDataCreation={ enableBankDataCreation }
            initValue={ initValueCompanyExpenseAccountAssignee }
          />
        </>
      }
    </>
  );
});
