import ConditionLeftHandSideForm from './ConditionLeftHandSideForm';
import ConditionOperatorForm from './ConditionOperatorForm';
import ConditionRightHandSideForm, { DataSetWithItems } from './ConditionRightHandSideForm';
import React, { FC, useCallback, useMemo } from 'react';
import last from 'lodash/last';
import styled from 'styled-components';
import {
  Bank, Category, Condition, ConditionOption, ConditionRightHandSide, CreditInvoiceCategory, DebitInvoiceCategory,
  Department, Project, SuperCategory, Supplier as SupplierOption, TaxCategory,
} from 'utilities/api/models/JournalEntries';
import { Item } from 'utilities/api/models/genericFields';
import { Operator } from 'utilities/api/types/JournalEntries';
import { PaymentMethodType } from 'utilities/api_payment_requests/models/PaymentMethod';
import { ReportLabel } from 'utilities/api_payment_requests/models';

interface Props {
  conditionOptions: ConditionOption[];
  condition: Condition;
  departments?: Department[];
  removable: boolean;
  conditionIndex: number;
  orIndex: number;
  taxCategories?: TaxCategory[];
  onChangeCondition: (condition: Condition) => void;
  onRemoveCondition: () => void;
}

const ConditionFormView = styled.div`
  display: flex;
  padding: 15px;
`;

const RemoveIconView = styled.div`
  cursor: pointer;
  padding-left: 8px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

function multipleSelectable(operator: Operator): boolean {
  return operator === 'in' || operator === 'not_in' || operator === 'all_equal' || operator === 'contains_any' || operator === 'contains_all' || operator === 'not_contain_any';
}

function operatorOptions(conditionOptions: ConditionOption[], condition: Condition): Operator[] {
  const selectedOption = conditionOptions.find((option) => { return option.left.expression === condition.left.expression; });

  if (selectedOption) {
    return selectedOption.operators;
  }

  return [condition.operator];
}

const ConditionForm: FC<Props> = (props) => {
  const onSelectLeftHandSide = (option: ConditionOption): void => {
    const { left, right } = props.condition;

    if (left.expression === option.left.expression) return;

    // 左辺を汎用マスタから別の汎用マスタに変更した際に、右辺の選択肢をリセットする
    if ([left.type, option.left.type].every((t) => t === 'JournalEntries::CellValueConditions::LeftHands::Expenses::GenericFields::DataSet')) {
      const rightCondition = {
        genericFieldItems: [],
        type: option.right.type,
      };
      const condition = { ...props.condition, left: option.left, right: rightCondition };
      props.onChangeCondition(condition);
      return;
    }

    /** 真偽値の場合には true / false に対応する選択肢の表現も合わせて初期化する必要があるため引き継がない */
    if (right.type === option.right.type && right.booleanValue === undefined) {
      const condition = { ...props.condition, left: option.left };
      props.onChangeCondition(condition);
      return;
    }

    const condition = { ...option, operator: option.operators[0] };
    props.onChangeCondition(condition);
  };

  const dataSet = useMemo(() => {
    if (last(props.condition.left.type.split('::')) === 'DataSet') {
      const selectedOption = props.condition.left;
      const dataSetData = {} as DataSetWithItems;

      if (selectedOption.dataSetId) {
        dataSetData.dataSetId = selectedOption.dataSetId;
        dataSetData.name = selectedOption.expression;
        dataSetData.items = props.condition.right.genericFieldItems || [];
      }

      return dataSetData;
    }

    return undefined;
  }, [props.condition]);

  const onSelectOperator = (operator: Operator): void => {
    if (props.condition.operator === operator) { return; }

    // 含む、含まない ⇄ =、≠の変更の場合、右辺の値をクリアする
    const right = (): ConditionRightHandSide => {
      const toMultipleSelectable = !multipleSelectable(props.condition.operator) && multipleSelectable(operator);
      const toSingleSelectable = multipleSelectable(props.condition.operator) && !multipleSelectable(operator);

      if (toMultipleSelectable || toSingleSelectable) {
        return { type: props.condition.right.type };
      }

      return props.condition.right;
    };

    const condition = { ...props.condition, operator, right: right() };
    props.onChangeCondition(condition);
  };

  const onChangeRightHandSideBooleanValue = (booleanValue: boolean): void => {
    const condition = {
      ...props.condition,
      right: {
        type: props.condition.right.type,
        falseExpression: props.condition.right.falseExpression,
        trueExpression: props.condition.right.trueExpression,
        booleanValue,
      },
    };
    props.onChangeCondition(condition);
  };

  const onChangeRightHandSidePayFee = (payFee: 'our' | 'their' | null): void => {
    const condition = {
      ...props.condition,
      right: {
        type: props.condition.right.type,
        payFee: payFee || null,
      },
    };
    props.onChangeCondition(condition);
  };

  const onChangeRightHandSideBank = (bank: Bank | null): void => {
    const condition = {
      ...props.condition,
      right: {
        type: props.condition.right.type,
        bank: bank || { id: '', name: '' },
      },
    };
    props.onChangeCondition(condition);
  };

  const onChangeRightHandSideCategory = (value: Category | Category[] | null): void => {
    if (multipleSelectable(props.condition.operator)) {
      const categories = value as Category[]; /* 含む、含まないの場合は必ず配列となる */
      const condition = {
        ...props.condition,
        right: {
          type: props.condition.right.type,
          categories,
        },
      };
      props.onChangeCondition(condition);
      return;
    }

    const category = value as Category | null; /* =、≠の場合は必ずObjectかnullとなる */
    const condition = {
      ...props.condition,
      right: {
        type: props.condition.right.type,
        category: category || { id: '', name: '' },
      },
    };
    props.onChangeCondition(condition);
  };

  const onChangeRightHandSideCreditInvoiceCategory = (value: CreditInvoiceCategory | CreditInvoiceCategory[] | null): void => {
    if (multipleSelectable(props.condition.operator)) {
      const creditInvoiceCategories = value as CreditInvoiceCategory[]; /* 含む、含まないの場合は必ず配列となる */
      const condition = {
        ...props.condition,
        right: {
          type: props.condition.right.type,
          creditInvoiceCategories,
        },
      };
      props.onChangeCondition(condition);
      return;
    }

    const creditInvoiceCategory = value as CreditInvoiceCategory | null; /* =、≠の場合は必ずObjectかnullとなる */
    const condition = {
      ...props.condition,
      right: {
        type: props.condition.right.type,
        creditInvoiceCategory: creditInvoiceCategory || { id: '', name: '' },
      },
    };
    props.onChangeCondition(condition);
  };

  const onChangeRightHandSideInvoiceCategory = (value: DebitInvoiceCategory | DebitInvoiceCategory[] | null): void => {
    if (multipleSelectable(props.condition.operator)) {
      const debitInvoiceCategories = value as DebitInvoiceCategory[]; /* 含む、含まないの場合は必ず配列となる */
      const condition = {
        ...props.condition,
        right: {
          type: props.condition.right.type,
          debitInvoiceCategories,
        },
      };
      props.onChangeCondition(condition);
      return;
    }

    const debitInvoiceCategory = value as DebitInvoiceCategory | null; /* =、≠の場合は必ずObjectかnullとなる */
    const condition = {
      ...props.condition,
      right: {
        type: props.condition.right.type,
        debitInvoiceCategory: debitInvoiceCategory || { id: '', name: '' },
      },
    };
    props.onChangeCondition(condition);
  };

  const onChangeRightHandSideDepartment = (value: Department | Department[] | null): void => {
    if (multipleSelectable(props.condition.operator)) {
      const departments = value as Department[]; /* 含む、含まないの場合は必ず配列となる */
      const condition = {
        ...props.condition,
        right: {
          type: props.condition.right.type,
          departments,
        },
      };
      props.onChangeCondition(condition);
      return;
    }

    const department = value as Department | null; /* =、≠の場合は必ずObjectかnullとなる */
    const condition = {
      ...props.condition,
      right: {
        type: props.condition.right.type,
        department: department || { id: '', name: '' },
      },
    };
    props.onChangeCondition(condition);
  };

  const onChangeRightHandSideNumber = (number: number): void => {
    const condition = {
      ...props.condition,
      right: {
        type: props.condition.right.type,
        number,
      },
    };
    props.onChangeCondition(condition);
  };

  const onChangeRightHandSideProject = (value: Project | Project[] | null): void => {
    if (multipleSelectable(props.condition.operator)) {
      const projects = value as Project[]; /* 含む、含まないの場合は必ず配列となる */
      const condition = {
        ...props.condition,
        right: {
          type: props.condition.right.type,
          projects,
        },
      };
      props.onChangeCondition(condition);
      return;
    }

    const project = value as Project | null; /* =、≠の場合は必ずObjectかnullとなる */
    const condition = {
      ...props.condition,
      right: {
        type: props.condition.right.type,
        project: project || { id: '', displayId: '', name: '' },
      },
    };
    props.onChangeCondition(condition);
  };

  const onChangeRightHandSideSuperCategory = (value: SuperCategory | SuperCategory[] | null): void => {
    if (multipleSelectable(props.condition.operator)) {
      const superCategories = value as SuperCategory[]; /* 含む、含まないの場合は必ず配列となる */
      const condition = {
        ...props.condition,
        right: {
          type: props.condition.right.type,
          superCategories,
        },
      };
      props.onChangeCondition(condition);
      return;
    }

    const superCategory = value as SuperCategory | null; /* =、≠の場合は必ずObjectかnullとなる */
    const condition = {
      ...props.condition,
      right: {
        type: props.condition.right.type,
        superCategory: superCategory || { id: '', name: '' },
      },
    };
    props.onChangeCondition(condition);
  };

  const onChangeRightHandSideCreditSuperCategory = (value: SuperCategory | SuperCategory[] | null): void => {
    if (multipleSelectable(props.condition.operator)) {
      const creditSuperCategories = value as SuperCategory[]; /* 含む、含まないの場合は必ず配列となる */
      const condition = {
        ...props.condition,
        right: {
          type: props.condition.right.type,
          creditSuperCategories,
        },
      };
      props.onChangeCondition(condition);
      return;
    }

    const creditSuperCategory = value as SuperCategory | null; /* =、≠の場合は必ずObjectかnullとなる */
    const condition = {
      ...props.condition,
      right: {
        type: props.condition.right.type,
        creditSuperCategory: creditSuperCategory || { id: '', name: '' },
      },
    };
    props.onChangeCondition(condition);
  };

  const onChangeRightHandSideTaxCategory = (taxCategory: TaxCategory): void => {
    const condition = {
      ...props.condition,
      right: {
        type: props.condition.right.type,
        taxCategory,
      },
    };
    props.onChangeCondition(condition);
  };

  const onChangeRightHandSideValue = (value: string): void => {
    const condition = {
      ...props.condition,
      right: {
        type: props.condition.right.type,
        value,
      },
    };
    props.onChangeCondition(condition);
  };

  const onChangeRightHandSideSupplier = (value: SupplierOption | SupplierOption[]): void => {
    if (multipleSelectable(props.condition.operator)) {
      const condition = {
        ...props.condition,
        right: {
          type: props.condition.right.type,
          suppliers: value as SupplierOption[],
        },
      };
      props.onChangeCondition(condition);
      return;
    }

    const condition = {
      ...props.condition,
      right: {
        type: props.condition.right.type,
        supplier: value as SupplierOption,
      },
    };
    props.onChangeCondition(condition);
  };

  const onSelectReportLabel = useCallback((value: ReportLabel[]): void => {
    const condition: Condition = {
      ...props.condition,
      right: {
        type: props.condition.right.type,
        reportLabels: value,
      },
    };
    props.onChangeCondition(condition);
  }, [props.condition, props.onChangeCondition]);

  const onSelectPaymentMethodType = useCallback((value: PaymentMethodType | undefined): void => {
    if (multipleSelectable(props.condition.operator)) return;

    props.onChangeCondition({
      ...props.condition,
      right: {
        type: props.condition.right.type,
        paymentMethodType: value,
      },
    });
  }, [props]);

  const onSelectPaymentMethodTypes = useCallback((value: PaymentMethodType[]): void => {
    if (!multipleSelectable(props.condition.operator)) return;

    props.onChangeCondition({
      ...props.condition,
      right: {
        type: props.condition.right.type,
        paymentMethodTypes: value,
      },
    });
  }, [props]);

  const onSelectGenericFieldItems = useCallback((items: Item[]): void => {
    if (!multipleSelectable(props.condition.operator)) return;

    const genericFieldItems = items as Item[];
    const condition = {
      ...props.condition,
      right: {
        type: props.condition.right.type,
        genericFieldItems,
      },
    };

    props.onChangeCondition(condition);
  }, [props]);

  return (
    <ConditionFormView>
      <ConditionLeftHandSideForm
        conditionOptions={ props.conditionOptions }
        condition={ props.condition }
        conditionIndex={ props.conditionIndex }
        orIndex={ props.orIndex }
        onSelect={ onSelectLeftHandSide }
      />
      <ConditionOperatorForm
        options={ operatorOptions(props.conditionOptions, props.condition) }
        operator={ props.condition.operator }
        conditionIndex={ props.conditionIndex }
        orIndex={ props.orIndex }
        onSelect={ onSelectOperator }
      />
      <ConditionRightHandSideForm
        departments={ props.departments }
        dataSet={ dataSet }
        onChangeBooleanValue={ onChangeRightHandSideBooleanValue }
        onChangeValue={ onChangeRightHandSideValue }
        onChangeNumber={ onChangeRightHandSideNumber }
        onSelectBank={ onChangeRightHandSideBank }
        onSelectCategory={ onChangeRightHandSideCategory }
        onSelectDepartment={ onChangeRightHandSideDepartment }
        onSelectPayFee={ onChangeRightHandSidePayFee }
        onSelectProject={ onChangeRightHandSideProject }
        onSelectSuperCategory={ onChangeRightHandSideSuperCategory }
        onSelectCreditSuperCategory={ onChangeRightHandSideCreditSuperCategory }
        onSelectTaxCategory={ onChangeRightHandSideTaxCategory }
        onSelectCreditInvoiceCategory={ onChangeRightHandSideCreditInvoiceCategory }
        onSelectInvoiceCategory={ onChangeRightHandSideInvoiceCategory }
        onSelectSupplier={ onChangeRightHandSideSupplier }
        onSelectReportLabel={ onSelectReportLabel }
        onSelectPaymentMethodType={ onSelectPaymentMethodType }
        onSelectPaymentMethodTypes={ onSelectPaymentMethodTypes }
        onSelectGenericFieldItems={ onSelectGenericFieldItems }
        operator={ props.condition.operator }
        left={ props.condition.left }
        right={ props.condition.right }
        taxCategories={ props.taxCategories }
      />
      {
        props.removable
          ? <RemoveIconView onClick={ props.onRemoveCondition }>
            <i className='fa fa-times txt-danger' />
          </RemoveIconView>
          : null
      }
    </ConditionFormView>
  );
};

export default ConditionForm;
