import React from 'react';
import { ApprovalStep, ApprovalStepsForReject, RequestStep } from 'utilities/api/models/ApprovalStepsForReject';
import { Modal, ModalBody, ModalHeader } from 'components/renewaled_ui/single_views';
import { RejectToSelector } from './RejectToSelector';

interface Props {
  show: boolean;
  onClose: () => void;
  steps: ApprovalStepsForReject;
  selectedStep: RequestStep | ApprovalStep;
  onSelectStep: (newStep: RequestStep | ApprovalStep) => void;
}

/**
 * 差戻先選択モーダル
 */
export const RejectToSelectorModal: React.FC<Props> = ({
  show,
  onClose,
  steps,
  selectedStep,
  onSelectStep,
}) => {
  return (
    <Modal show={ show } onClose={ onClose }>
      <ModalHeader title='差し戻し先を指定する' onClose={ onClose } />
      <ModalBody>
        <RejectToSelector selectedStep={ selectedStep } onSelectStep={ onSelectStep } steps={ steps }/>
      </ModalBody>
    </Modal>
  );
};
