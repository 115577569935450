import Amount from '../form_items/Amount';
import CompanyName from '../form_items/CompanyName';
import MatchingStatus from '../form_items/MatchingStatus';
import OriginalFilename from '../form_items/OriginalFilename.tsx';
import OriginalReceiptView from 'components/attached_files/OriginalReceiptView';
import PayedShop from '../form_items/PayedShop';
import Post from '../form_items/Post';
import PropTypes from 'prop-types';
import React from 'react';
import Report from '../form_items/Report';
import StorageCode from '../form_items/StorageCode';
import TransactedDate from '../form_items/TransactedDate';

// 原本データの詳細表示コンポーネント
class OriginalReceipt extends React.Component {
  render() {
    const props = this.props;
    const originalReceipt = props.originalReceipt;
    const isHidden = {
      // 各要素を隠す (レンダリングする) かどうか
      image: props.hiddenElements.includes('image'),
      companyName: props.hiddenElements.includes('companyName'),
      storageCode: props.hiddenElements.includes('storageCode'),
      transactedAt: props.hiddenElements.includes('transactedAt'),
      amount: props.hiddenElements.includes('amount'),
      payedShop: props.hiddenElements.includes('payedShop'),
      report: props.hiddenElements.includes('report'),
      matchingStatus: props.hiddenElements.includes('matchingStatus'),
      post: props.hiddenElements.includes('post'),
      originalFilename: props.hiddenElements.includes('originalFilename'),
    };

    return (
      <form className='col-sm-12'>
        {
          !isHidden.image && (
            <div className='col-sm-7 original-receipt-card__image-frame'>
              <OriginalReceiptView
                id={ originalReceipt.id }
                mimeType={ originalReceipt.mimetype }
                height={ 400 }
              />
            </div>
          )
        }
        <div className={ isHidden.image ? 'col-sm-12' : 'col-sm-5' }>
          { isHidden.companyName ? null : <CompanyName value={ originalReceipt.rootGroupName } /> }
          { isHidden.storageCode ? null : <StorageCode value={ originalReceipt.storageCode } /> }
          { isHidden.transactedAt ? null : <TransactedDate value={ originalReceipt.transactedAt } /> }
          { isHidden.amount ? null : <Amount value={ originalReceipt.amount } /> }
          { isHidden.payedShop ? null : <PayedShop value={ originalReceipt.shopName } /> }
          { isHidden.report ? null : <Report value={ originalReceipt.report }/> }
          { isHidden.matchingStatus ? null : <MatchingStatus value={ originalReceipt.matchingStatus }/> }
          { isHidden.post ? null : <Post value={ { postName: originalReceipt.boxName, postAddress: originalReceipt.boxAddress } }/> }
          { isHidden.originalFilename ? null : <OriginalFilename value={ originalReceipt.originalFilename }/> }
        </div>
      </form>
    );
  }
}

// 隠せる要素
const HIDEABLE_ITEMS = [
  'image', 'companyName', 'storageCode', 'transactedAt', 'amount', 'payedShop',
  'report', 'matchingStatus', 'post',
];

OriginalReceipt.defaultProps = {
  originalReceipt: {},
  hiddenElements: [],
};

OriginalReceipt.propTypes = {
  originalReceipt: PropTypes.shape({
    id: PropTypes.string,             // 原本の ID
    mimetype: PropTypes.string,       // 原本画像の memetype
    url: PropTypes.string,            // 原本画像の URL
    rootGroupName: PropTypes.string,  // 事業所名
    storageCode: PropTypes.string,    // レターパックに割り当てたコード
    transactedAt: PropTypes.string,   // 原本に記載された利用日
    amount:  PropTypes.number,        // 原本に記載された金額
    shopName: PropTypes.string,       // 原本に記載された利用店舗名
    matchingStatus: PropTypes.string, // 突合に関するステータス
    boxName: PropTypes.string,        // ポスト名
    boxAddress: PropTypes.string,     // ポストの住所
    report: PropTypes.shape({
      id: PropTypes.string,     // 経費精算の ID
      title: PropTypes.string,  // 経費精算の申請名
      status: PropTypes.string, // 経費精算のステータス
    }),
  }).isRequired,
  // 隠したい要素がある場合, hiddenableItems の要素を指定する
  hiddenElements: PropTypes.arrayOf(
    (propValue, key, componentName, location, propFullName) => {
      if (!HIDEABLE_ITEMS.includes(propValue[key])) {
        return new Error(
          `Invalid prop ${propFullName} supplied to`
          + `${componentName}. Validation failed.`,
        );
      }
      return null;
    },
  ),
};

export default OriginalReceipt;
