import { DropzoneRootProps } from "react-dropzone";

interface UseReturn {
  readonly isClickable: boolean | undefined;
  readonly onClickUploadTool: (
    e: React.MouseEvent<HTMLElement, MouseEvent>,
  ) => void;
}

/**
 * PDFビューアーのツールのロジック
 */
export const useHooks = (
  rootProps: DropzoneRootProps,
  isDroppable: boolean,
  isClickable?: boolean,
): UseReturn => {
  /**
   * ツールバー: アップロードを押した
   */
  const onClickUploadTool = (
    e: React.MouseEvent<HTMLElement, MouseEvent>,
  ): void => {
    if (!isClickable) return;
    if (!rootProps.onClick) return;
    rootProps.onClick(e);
  };

  return {
    isClickable: isDroppable && isClickable,
    onClickUploadTool,
  };
};
