import React, { useCallback, useState } from "react";
import REPORT_STATUS from "values/report_status";
import { RecallButton } from "./RecallButton";
import { ResetFlowButton } from "./ResetFlowButton";
import { ResubmitButton } from "./ResubmitButton";
import { SubmitButton } from "./SubmitButton";

/** 修正時 */
interface Application {
  id: string;
  status: string;
}

/** 新規作成時 */
interface RequestTypeFormData {
  id?: undefined;
  status?: undefined;
}

interface AsyncActions {
  fetchRequest: () => Promise<void>;
  reapply: () => Promise<void>;
  recall: () => Promise<void>;
}

interface Props {
  application: RequestTypeFormData | Application;
  actions: AsyncActions;
}

/** render結果が変わるものだけ比較しているので、actionsの変更は無視されます */
const propsAreEqual = (
  prevProps: Readonly<Props>,
  nextProps: Readonly<Props>,
): boolean =>
  prevProps.application.id === nextProps.application.id &&
  prevProps.application.status === nextProps.application.status;

/** 申請者用フォームのボタン */
export const RequesterButtons: React.FC<Props> = React.memo(
  ({ application, actions }) => {
    const [disabled, setDisabled] = useState(false);

    const handleFetchRequest = useCallback(() => {
      setDisabled(true);
      actions.fetchRequest().finally(() => {
        setDisabled(false);
      });
    }, [actions]);

    const handleReapply = useCallback(() => {
      setDisabled(true);
      actions.reapply().finally(() => {
        setDisabled(false);
      });
    }, [actions]);

    const handleRecall = useCallback(() => {
      setDisabled(true);
      actions.recall().finally(() => {
        setDisabled(false);
      });
    }, [actions]);

    // 作成前
    if (!application.id) {
      return <SubmitButton disabled={disabled} onClick={handleFetchRequest} />;
    }

    const STATE = REPORT_STATUS[userPreferences.locale];

    switch (application.status) {
      case STATE.recalled:
      case STATE.rejected:
        return <ResubmitButton disabled={disabled} onClick={handleReapply} />;
      case STATE.aborted:
        return (
          <ResetFlowButton disabled={disabled} onClick={handleFetchRequest} />
        );
      case STATE.applying:
        return <RecallButton disabled={disabled} onClick={handleRecall} />;
      default:
        return null;
    }
  },
  propsAreEqual,
);
