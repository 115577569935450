import MultipleDateField from  'applications/transactions/components/MultipleDateField';
import React, { FC } from 'react';
import SimpleModal, { ButtonProps } from 'components/SimpleModal';
import TextArea from 'components/TextArea';

interface Props {
  readonly buttons: ButtonProps[];
  readonly close: () => void;
  readonly onPaymentCommentChange: (comment: string) => void;
  readonly onPaymentDateChange: (date: string) => void;
  readonly paymentComment: string;
  readonly paymentCommentLabel: string;
  readonly paymentDate: string;
  readonly paymentDateLabel: string;
  readonly show: boolean;
  readonly title: string;
  readonly usePaymentDate: boolean;
}

const PaymentDateModal: FC<Props> = (props) => {
  return (
    <SimpleModal
      title={ props.title }
      show={ props.show }
      close={ props.close }
      buttons={ props.buttons }>
      {
        props.usePaymentDate ? (
          <div className='form-group'>
            <div className='row'>
              <div className='col-sm-6'>
                <label>{ props.paymentDateLabel }</label>
                <MultipleDateField
                  className={ `form-control transacted-at` }
                  isMultiSelectable={ false }
                  value={ props.paymentDate }
                  onValueChanged={ (value): void => props.onPaymentDateChange(value) }
                />
              </div>
            </div>
            <div className='row'>
              <div className='col-sm-12'>
                <label>{ props.paymentCommentLabel }</label>
                <TextArea
                  className='form-control'
                  value={ props.paymentComment }
                  onChange={ (e): void => props.onPaymentCommentChange(e.target.value) }
                />
              </div>
            </div>
          </div>
        ) : null
      }
    </SimpleModal>
  );
};

export default PaymentDateModal;
