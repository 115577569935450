import PropTypes from 'prop-types';
import React from 'react';
import i18next from 'i18n';
import { Col, ControlLabel, FormGroup } from 'react-bootstrap';

const FormItem = (props) => {
  return (
    <FormGroup className='row'>
      <Col sm={ 4 }>
        <ControlLabel>
          { i18next.t(`paperless.originalReceipts.properties.${props.label}`) }
        </ControlLabel>
      </Col>
      <Col sm={ 8 }>
        { props.children }
      </Col>
    </FormGroup>
  );
};

FormItem.defaultProps = {
  label: '',
  children: null,
};

FormItem.propTypes = {
  label: PropTypes.string.isRequired,
  children: PropTypes.element.isRequired,
};

export default React.memo(FormItem);
