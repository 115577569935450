import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { OverlayTrigger, Popover } from 'react-bootstrap';

export default class SimpleBalloon extends Component {
  render() {
    const {
      popoverId, message, icon, color, overlay, ...other
    } = this.props;

    return (
      <OverlayTrigger
        overlay = { overlay || this.renderOverlay() }
        { ...other }>

        <span>
          <i className={ `fa ${this.props.icon} ${this.props.color}` }></i>
          { this.props.children }
        </span>
      </OverlayTrigger>
    );
  }

  renderOverlay() {
    return (
      <Popover id={ this.props.popoverId } title={ this.props.popoverTitle }>
        { this.props.message }
      </Popover>
    );
  }
}

SimpleBalloon.defaultProps = {};

SimpleBalloon.propTypes = {
  popoverId: PropTypes.string.isRequired,
  popoverTitle: PropTypes.string,
  message:   PropTypes.any,
  icon:      PropTypes.string,
  color:     PropTypes.string,
  overlay:   PropTypes.element,
  children:  PropTypes.element,
};
