import LabelWithExecutionDayPopover from '../components/LabelWithExecutionDayPopover';
import React, { useEffect } from 'react';
import Select from 'react-select';
import SmartCheckBox from 'components/SmartCheckBox';
import i18next from 'i18n';
import moment from 'moment';
import styled from 'styled-components';
import { ControlLabel, FormControl, FormGroup } from 'react-bootstrap';
import { Controller, useFormContext } from 'react-hook-form';
import { RequiredIcon } from '../../../../components/icons/RequiredIcon';

const FormGroupView = styled(FormGroup)`
  .form-control:focus {
    outline: none;
    box-shadow: none;
    border: 2px solid #004acc;
    background-color: #ffffff;
  }
  &.has-success div[class$="-control"] {
    border-color: #399d46;
    box-shadow: none;
  }
  &.has-success .control-label {
    color: #399d46;
  }
  &.has-error div[class$="-control"] {
    border-color: #d9534f;
    box-shadow: none;
  }
  &.has-error .control-label {
    color: #d9534f;
  }
  &.has-error .help-block {
    color: #d9534f;
  }
`;

interface Option {
  value: string | number;
  label: string;
}

const executionIntervals: Option[] = [
  { value: 'by_month', label: i18next.t('accountingDataScheduledExports.tasks.forms.base.executionInterval.intervals.byMonth') },
  { value: 'by_weekday', label: i18next.t('accountingDataScheduledExports.tasks.forms.base.executionInterval.intervals.byWeekday') },
  { value: 'by_day', label: i18next.t('accountingDataScheduledExports.tasks.forms.base.executionInterval.intervals.byDay') },
  { value: 'by_once_specified_date', label: i18next.t('accountingDataScheduledExports.tasks.forms.base.executionInterval.intervals.byOnceSpecifiedDate') },
];

const executionDayOfMonths: Option[] = [...Array(31)].map((_, i) => ({ value: i + 1, label: i18next.t('accountingDataScheduledExports.tasks.schedule.day', { day: i + 1 }) }));
const executionDayOfWeeks: Option[] = i18next.t<string[]>('accountingDataScheduledExports.tasks.schedule.dayOfWeek', { returnObjects: true }).map((label, i) => ({ value: i, label }));
const currentDate = new Date();

export const ScheduleSelectField: React.FC = () => {
  const {
    control,
    watch,
    setValue,
    formState: {
      dirtyFields,
    },
  } = useFormContext();

  const executionInterval = watch('executionInterval');
  const executionDayLastDayOfMonth = watch('executionDayLastDayOfMonth');
  const executionDayOfMonth = watch('executionDayOfMonth');
  const executionDayOfWeek = watch('executionDayOfWeek');
  const executionOnceSpecifiedDate = watch('executionOnceSpecifiedDate');

  useEffect(() => {
    switch (executionInterval) {
      case 'by_month':
        setValue('executionDayOfMonth', executionDayOfMonth || 1);
        setValue('executionDayLastDayOfMonth', executionDayLastDayOfMonth || false);
        setValue('executionDayOfWeek', null);
        setValue('executionOnceSpecifiedDate', null);
        break;
      case 'by_weekday':
        setValue('executionDayOfMonth', null);
        setValue('executionDayLastDayOfMonth', null);
        setValue('executionDayOfWeek', executionDayOfWeek || 0);
        setValue('executionOnceSpecifiedDate', null);
        break;
      case 'by_day':
        setValue('executionDayOfMonth', null);
        setValue('executionDayLastDayOfMonth', false);
        setValue('executionDayOfWeek', null);
        setValue('executionOnceSpecifiedDate', null);
        break;
      case 'by_once_specified_date':
        setValue('executionDayOfMonth', null);
        setValue('executionDayLastDayOfMonth', false);
        setValue('executionDayOfWeek', null);
        setValue('executionOnceSpecifiedDate', executionOnceSpecifiedDate || moment(currentDate).format('YYYY-MM-DD'));
        break;
      default:
        break;
    }
  }, [executionDayOfMonth, executionDayLastDayOfMonth, executionDayOfWeek, executionOnceSpecifiedDate, dirtyFields, executionInterval, setValue]);

  useEffect(() => {
    if (executionInterval !== 'by_month') return;

    setValue('executionDayOfMonth', executionDayLastDayOfMonth ? null : executionDayOfMonth || 1);
  }, [executionInterval, executionDayLastDayOfMonth, executionDayOfMonth, setValue]);

  return (
    <FormGroupView>
      <ControlLabel><LabelWithExecutionDayPopover label={ <>{ i18next.t('accountingDataScheduledExports.tasks.forms.base.executionInterval.label') } <RequiredIcon /></> } /></ControlLabel>
      <div style={ { display: 'flex', gap: '12px', alignItems: 'center' } }>
        <Controller
          name='executionInterval'
          rules={ { required: i18next.t<string>('accountingDataScheduledExports.tasks.forms.base.executionInterval.required') } }
          control={ control }
          render={ ({ field: { onChange, value, ref: inputRef } }): React.ReactElement => (
            <div style={ { width: '120px' } }>
              <Select
                inputRef={ inputRef }
                options={ executionIntervals }
                value={ executionIntervals?.find((c) => c.value === value) }
                getOptionLabel={ (v: Option): string => v.label }
                getOptionValue={ (v: Option): string | number => v.value }
                onChange={ (v: Option): void => onChange(v?.value) }
                isSearchable={ false }
              />
            </div>
          ) }
        />
        {
          executionInterval === 'by_month' && !executionDayLastDayOfMonth && (
            <Controller
              name='executionDayOfMonth'
              control={ control }
              render={ ({ field: { onChange, value, ref: inputRef } }): React.ReactElement => (
                <div style={ { width: '100px' } }>
                  <Select
                    inputRef={ inputRef }
                    options={ executionDayOfMonths }
                    value={ executionDayOfMonths?.find((c) => c.value === value) }
                    getOptionLabel={ (v: Option): string => v.label }
                    getOptionValue={ (v: Option): string | number => v.value }
                    onChange={ (v: Option): void => onChange(v?.value) }
                    isSearchable={ false }
                  />
                </div>
              ) }
            />
          )
        }
        {
          executionInterval === 'by_month' && (
            <Controller
              name='executionDayLastDayOfMonth'
              control={ control }
              render={ ({ field: { onChange, value } }): React.ReactElement => (
                <SmartCheckBox onChange={ onChange } checked={ value || false } label={ i18next.t('accountingDataScheduledExports.tasks.forms.base.executionInterval.lastDayOfMonth') } />
              ) }
            />
          )
        }
        {
          executionInterval === 'by_weekday' && (
            <Controller
              name='executionDayOfWeek'
              control={ control }
              render={ ({ field: { onChange, value, ref: inputRef } }): React.ReactElement => (
                <div style={ { width: '100px' } }>
                  <Select
                    inputRef={ inputRef }
                    options={ executionDayOfWeeks }
                    value={ executionDayOfWeeks?.find((c) => c.value === value) }
                    getOptionLabel={ (v: Option): string => v.label }
                    getOptionValue={ (v: Option): string | number => v.value }
                    onChange={ (v: Option): void => onChange(v?.value) }
                    isSearchable={ false }
                  />
                </div>
              ) }
            />
          )
        }
        {
          executionInterval === 'by_once_specified_date' && (
            <Controller
              name='executionOnceSpecifiedDate'
              control={ control }
              render={ ({ field: { onChange, value } }): React.ReactElement => (
                <div style={ { width: '150px' } }>
                  <FormControl
                    type='date'
                    min={ moment(currentDate).format('YYYY-MM-DD') }
                    value={ value || moment(currentDate).format('YYYY-MM-DD') }
                    onChange={ onChange }
                  />
                </div>
              ) }
            />
          )
        }
      </div>
    </FormGroupView>
  );
};
