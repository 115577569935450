import * as actions from '../../exports/actions/index';
import BootstrapCard from 'components/BootstrapCard';
import ExportJobModal from '../../exports/components/ExportJobModal';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import UserAnalysisTable from './UserAnalysisTable';
import i18next from 'i18n';
import { connect } from 'react-redux';

/**
 * 従業員毎集計
 */
class UserAnalysisCard extends Component {
  constructor(props) {
    super(props);

    this.onDownloadClick = this.onDownloadClick.bind(this);
  }

  /**
   * 集計出力ボタンを押した
   */
  onDownloadClick() {
    const { openExportModal } = this.props;

    openExportModal();
  }

  render() {
    const { closeExportModal, isShowModal } = this.props;
    const buttons = [
      {
        color: 'accent',
        icons: [
          {
            position: 'right',
            className: 'fa-angle-right',
          },
        ],
        content: i18next.t('exports.titles.aggregationByUserExport'),
        onClick: this.onDownloadClick,
      },
    ];

    return (
      <BootstrapCard
        title='従業員毎集計'
        titleIcon={ { className: 'fa-users' } }
        buttons={ this.props.isDownloadable ? buttons : null }
      >
        <UserAnalysisTable
          analysisId={ this.props.analysisId }
          onRowClick={ this.props.onRowClick }
          summariesByUser={ this.props.summariesByUser }
          isDownloadable={ this.props.isDownloadable }
        />
        <ExportJobModal
          title={ i18next.t('exports.titles.fileExport') }
          show={ isShowModal }
          closeModal={ closeExportModal }
          formName='UserAnalysisExportForm'
        />
      </BootstrapCard>
    );
  }
}

UserAnalysisCard.propTypes = {
  analysisId: PropTypes.string.isRequired,
  closeExportModal: PropTypes.func.isRequired,
  isDownloadable: PropTypes.bool,
  isShowModal: PropTypes.bool.isRequired,
  onRowClick: PropTypes.func.isRequired,
  openExportModal: PropTypes.func.isRequired,
  summariesByUser: PropTypes.array.isRequired,
};

UserAnalysisCard.defaultProps = {
  isDownloadable: true,
  isShowModal: false,
};

function mapStateToProps(state, ownProps) {
  const { exportData } = state;
  return {
    isShowModal: exportData.showAggregationByUserModal,
  };
}

function mapDispatchToProps(dispatch, ownProps) {
  return {
    /**
     * ファイル出力モーダルを開く
     */
    openExportModal() {
      dispatch(actions.openAggregatonByUserModal());
    },
    /**
     * ファイル出力モーダルを閉じる
     */
    closeExportModal() {
      dispatch(actions.closeAggregatonByUserModal());
    },
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(UserAnalysisCard);
