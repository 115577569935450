import ExtendedFileView from '../renewaled_ui/FileViewer';
import React from 'react';
import useMinMaxSize from './useMinMaxSize';
import { LoadingStatus } from 'components/types/attachedFiles.types';
import { MIMEType } from "utilities/api/types/MIMEType";
import { useThumbnail } from "./useRemoteFile";

interface Props {
  /** AttachedS3FileのID */
  readonly id?: string;
  readonly mimeType?: MIMEType;
  readonly width?: number;
  readonly rotation?: 0 | 90 | 180 | 270;
  readonly minHeight?: number;
  readonly maxHeight?: number;
  readonly quality?: number;
  readonly style?: React.CSSProperties;
  readonly className?: string;
  readonly onClick: (e: React.SyntheticEvent, state: LoadingStatus) => void;
}

/**
 * 画像・PDFファイル（AttachedS3File）のサムネイル用ビューアー
 *
 * 幅は固定で、画像の縦の長さに合わせて伸縮可能なようになっている
 * 高さを固定したい場合は、 `maxHeight` と `minHeight` の指定を合わせる
 */
const Thumbnail: React.FC<Props> = (props: Props) => {
  const {
    id,
    quality,
    width: originalWidth = 200,
    rotation,
    minHeight = 200,
    maxHeight = 200,
    ...viewProps
  } = props;
  const mimeType =
    props.mimeType === "application/pdf" ? "image/jpeg" : props.mimeType;

  const [objectUrl, loadStatus] = useThumbnail(
    id,
    mimeType,
    Math.max(originalWidth, minHeight),
    quality,
  );
  const [width, height] = useMinMaxSize(
    {
      minWidth: originalWidth,
      maxWidth: originalWidth,
      maxHeight,
      minHeight,
    },
    objectUrl,
  );

  return (
    <ExtendedFileView
      {...viewProps}
      width={width}
      height={height}
      defaultRotation={rotation}
      url={objectUrl}
      mimeType={mimeType}
      loadStatus={loadStatus}
      showToolbar={false}
      className={props.className}
    />
  );
};

export default Thumbnail;
