import React, {
  useCallback, useEffect, useMemo, useState,
} from 'react';
import isNil from 'lodash/isNil';
import { FixedSizeList } from 'react-window';
import { Subject, from } from 'rxjs';
import {
  distinctUntilChanged, filter, map, mergeMap, throttleTime,
} from 'rxjs/operators';

type SparseArray<T> = Array<T | undefined>;
type Response<T> = {
  count: number;
  options: SparseArray<T>;
};

export type FetchItems<T> = (offset: number, limit: number) => Promise<Response<T>>;
export type ItemsChangeCallback<T> = (items: SparseArray<T>, count: number) => void;

interface RenderItemOptions {
  style: React.CSSProperties;
  index: number;
}

interface Props<T> {
  className?: string;
  innerRef?: React.Ref<HTMLDivElement>;
  outerRef?: React.Ref<HTMLDivElement>;
  totalCount?: number;
  batchSize?: number; // batchSize は表示されている件数 + overscanされる件数以上の数を指定すること
  throttleMs?: number;
  overscanCount?: number;
  items: SparseArray<T>;
  height: number;
  width?: number | string;
  itemHeight: number;
  style?: React.CSSProperties;
  fetchItems: FetchItems<T>;
  onItemsChange: ItemsChangeCallback<T>;
  renderItem: (item: T | undefined, options: RenderItemOptions) => React.ReactElement;
}

type Direction = 'forward' | 'backward';

interface FetchEvent {
  startIndex: number;
  stopIndex: number;
  scrollDirection: Direction;
}

/**
 * NOTE: fromIndex < toIndex
 * @returns fromIndexからtoIndexの範囲の値を抽出した配列を返す。長さは toIndex - fromIndex（toIndexは含まない）
 */
export function buildSparseArray<T>(items: SparseArray<T>, fromIndex: number, toIndex: number): SparseArray<T> {
  return Array.prototype.concat.call(
    items.slice(fromIndex, toIndex),
    new Array(Math.max(0, (toIndex - items.length) - Math.max(0, fromIndex - items.length))),
  );
}

/**
 * 未取得の範囲を算出する
 * 指定された範囲のデータを全て取得済みの場合は、[-1, -1] を返す
 * その他の場合は、スクロール方向に向かって、batchSize以下の長さで最大の未取得の範囲を計算して返す
 *
 * @param items 取得済みのデータ
 * @param batchSize データの最大取得件数
 * @param scrollDirection
 * @param fetchEvent
 */
export function getUnfetchedRange<T>(items: SparseArray<T>, batchSize: number, fetchEvent: FetchEvent): [number, number] {
  const { startIndex, stopIndex, scrollDirection } = fetchEvent;
  const itemsInRange = buildSparseArray(items, startIndex, stopIndex + 1);
  const firstRelativeIndex = scrollDirection === 'forward' ? itemsInRange.findIndex((x) => !x) : itemsInRange.reverse().findIndex((x) => !x);

  if (firstRelativeIndex < 0) {
    return [-1, -1];
  }

  if (scrollDirection === 'forward') {
    const firstIndex = startIndex + firstRelativeIndex;
    let lastIndex = firstIndex + batchSize - 1;
    lastIndex -= buildSparseArray(items, firstIndex, lastIndex + 1).reverse().findIndex((x) => !x); // 未取得の範囲に絞る

    return [firstIndex, lastIndex];
  }

  const lastIndex = stopIndex - firstRelativeIndex; // スクロール方向から見ると先頭
  let firstIndex = Math.max(0, lastIndex - batchSize + 1);
  firstIndex += buildSparseArray(items, firstIndex, lastIndex + 1).findIndex((x) => !x); // 未取得の範囲に絞る

  return [firstIndex, lastIndex];
}

function useLazyLoad<T>(items: SparseArray<T>, fetchItems: FetchItems<T>, onItemsChange: ItemsChangeCallback<T>, batchSize: number, throttleMs: number): Subject<FetchEvent> {
  const subject = useMemo(() => (new Subject<FetchEvent>()), []);
  const fetch$ = useMemo(() => {
    // スクロール時のイベントストリームにより、未取得データを取得する
    return subject.pipe(
      throttleTime(throttleMs, void 0, { leading: true, trailing: true }),
      map((fetchEvent) => (getUnfetchedRange(items, batchSize, fetchEvent))),
      filter(([startIndex, stopIndex]) => (startIndex >= 0 && stopIndex >= 0)),
      // 2回連続でリクエストが飛んでしまう現象を緩和 https://github.com/ReactiveX/rxjs/issues/2727
      distinctUntilChanged((x, y) => { return x[0] === y[0] && x[1] === y[1]; }),
      mergeMap(([startIndex, stopIndex]) => from(fetchItems(startIndex, stopIndex - startIndex + 1))),
    );
  }, [subject, items, fetchItems, batchSize, throttleMs]);

  useEffect(() => {
    return (): void => { subject.complete(); };
  }, [subject]);

  useEffect(() => {
    const subscription = fetch$.subscribe((response) => {
      const { options, count } = response;
      onItemsChange(options, count);
    });

    return (): void => {
      subscription.unsubscribe();
    };
  }, [fetch$, onItemsChange]);

  return subject;
}

/**
 * スクロールによってリストのLazy Loadingを行うComponent
 * パフォーマンスの観点から、画面に表示されている要素 + overscan分の要素を除いて描画されない
 *
 * ATTENTION!: 検索条件が変更される時などリストの更新が必要な場合は、totalCountをnullに、itemsを[]に変更してスクロール位置を先頭に戻すこと
 */
const InfiniteScroller = <T extends { id: string }>(props: Props<T>): React.ReactElement<Props<T>> | null => {
  const {
    items, totalCount, batchSize = 20, throttleMs = 500, overscanCount = 8,
    innerRef, outerRef, style: listStyle, height, width = 'auto', itemHeight,
    fetchItems, onItemsChange, renderItem,
  } = props;

  const [scrollDirection, setScrollDirection] = useState<Direction>('forward');
  const getItemKey = useCallback((index) => {
    return items[index]?.id || index;
  }, [items]);
  const renderRow = useCallback(({ index, style }) => {
    return renderItem(items[index], { style, index });
  }, [renderItem, items]);
  const fetch$ = useLazyLoad(items, fetchItems, onItemsChange, batchSize, throttleMs);

  useEffect(() => {
    if (isNil(totalCount)) {
      fetch$.next({ startIndex: 0, stopIndex: batchSize - 1, scrollDirection });
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fetch$, totalCount]); // 一回に取得する量（batchSize）やスクロール方向（scrollDirection）の変更はどうでも良い

  const handleItemsRendered = useCallback(({ overscanStartIndex, overscanStopIndex }) => {
    fetch$.next({ startIndex: overscanStartIndex, stopIndex: overscanStopIndex, scrollDirection });
  }, [fetch$, scrollDirection]);
  const handleScroll = useCallback((x) => {
    setScrollDirection(x.scrollDirection);
  }, []);

  return (
    <FixedSizeList
      innerRef={ innerRef }
      outerRef={ outerRef }
      style={ listStyle }
      itemKey={ getItemKey }
      itemCount={ totalCount }
      overscanCount={ overscanCount }
      height={ height }
      width={ width }
      itemSize={ itemHeight }
      onItemsRendered={ handleItemsRendered }
      onScroll={ handleScroll }
    >
      { renderRow }
    </FixedSizeList>
  );
};

export default InfiniteScroller;
