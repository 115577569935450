import CheckBoxGroup, { CheckBoxColor } from 'components/CheckBoxGroup';
import LabeledComponent from 'components/LabeledComponent';
import React, { FC } from 'react';
import i18next from 'i18n';

type Statuses = {
  [status in StatusForReport]: boolean;
};

/** 申請書が取りうる状態 */
type StatusForReport =
  'applying' | 'created' |
  'recalled' | 'rejected' | 'unsettled' |
  'settled' | 'approved' | 'paid' |
  'pendingTemporaryPayment' | 'pendingTemporaryPaymentRefund';

/** あなたの承認状況が取りうる状態 */
type StatusForApprover = 'applying' | 'approved' | 'potentially' | 'none';

interface Props {
  /**
   * 承認状況に関するステータス。
   * ここにある Report は経費精算のことではなく、単に申請書のこと。
   * @todo ユビキタス言語と衝突しているので、命名変更を考えたほうが良さそう
   */
  statusForReport: Statuses;

  /** あなたの承認状況を表すステータス */
  statusForApprover: string;

  /**
   * あなたの承認状況を表すステータスを有効にするかどうか
   * 検索範囲が会社全体の場合に意味をなさないので、そういった場合に true になる。
   */
  disabledStatusForApprover: boolean;

  /**
   * 承認状況に関するステータスを更新するための処理
   * @param status チェックの状態を変更するステータス
   * @param target 何に対するステータスを更新するのかを指定する (申請書の場合は forReport)
   *   target に渡す引数について、現状では forReport 以外ありえないため、設計次第では不要になるはず
   * @param checked 更新後のチェック状態
   */
  onChangeStatus: (status: StatusForReport, target: string, checked: boolean) => void;

  /**
   * 渡された statuses のハッシュ内の全ての value を true へ更新する
   * @param statuses 変更対象のステータス
   * @param target 何に対するステータスを更新するのかを指定する (申請書の場合は forReport)
   *   target に渡す引数について、現状では forReport 以外ありえないため、設計次第では不要になるはず
   */
  onChangeAllStatus: (statuses: Statuses, target: string) => void;

  /**
   * あなたの承認状況に関するステータスを更新するための処理
   * @param status 変更後のステータスの状態
   */
  onChangeStatusForApprover: (status: StatusForApprover) => void;

  /**
   * 誰に向けた表示なのかを示す
   * true の場合は [承認一覧] 画面向けの表示になり、 false の場合は [申請一覧] 画面向けの表示になる
   */
  forApprover: boolean;

  usedStatusesForReport?: StatusForReport[];
}

/**
 * 申請一覧 / 承認一覧 における "承認状況" と "あなたの承認状況" の検索条件を表示する
 */
const RequestStatusCheckBoxes: FC<Props> = ({
  statusForReport,
  statusForApprover,
  disabledStatusForApprover,
  onChangeStatus,
  onChangeAllStatus,
  onChangeStatusForApprover,
  forApprover,
  usedStatusesForReport = [
    'applying', 'created', 'recalled', 'rejected',
    'unsettled', 'settled', 'approved', 'paid',
  ],
}) => {
  const preReportRequest = userPreferences.preference.requestTypes.find((x) => x.type === 'pre_report_request');
  const useTemporaryPayment = preReportRequest?.preReportPreference?.useTemporaryPayment;
  const approverChecks: Array<StatusForApprover> = ['applying', 'approved', 'potentially', 'none'];

  if (useTemporaryPayment) {
    // 仮払機能が有効な場合、「差し戻し」の後ろに、仮払系のステータスを検索条件に追加する
    usedStatusesForReport.splice(5, 0, 'pendingTemporaryPayment', 'pendingTemporaryPaymentRefund');
  }

  const statusForReports = usedStatusesForReport.map((status) => ({
    color: 'accent' as CheckBoxColor,
    description: status === 'rejected'
      ? i18next.t('reports.searchConditions.includesAborted')
      : undefined,
    label: i18next.t(`commons.status.${status}`),
    checked: statusForReport[status],
    onChange(): void { onChangeStatus(status, 'forReport', !statusForReport[status]); },
  }));

  if (forApprover) {
    return (
      <>
        <CheckBoxGroup
          className='col-sm-12'
          checkBoxesProps={ statusForReports }
          label={ i18next.t('reports.searchConditions.approvalStatus') }
          isAllSelectable={ true }
          onChangeAllStatus={ (): void => { onChangeAllStatus(statusForReport, 'forReport'); } }
        />
        <LabeledComponent
          className='col-sm-12'
          label={ i18next.t('reports.searchConditions.approvalStatusForApprover') }
        >
          <div className='checkbox-group'>
            { approverChecks.map((status, index) => (
              <div key={ index }>
                <input
                  type='radio'
                  disabled={ disabledStatusForApprover }
                  checked={ statusForApprover === status }
                  onChange={ (): void => { onChangeStatusForApprover(status); } }/>
                <span style={ { marginLeft: '8px' } }>{ i18next.t(`commons.status.${status}`) }</span>
              </div>
            )) }
          </div>
        </LabeledComponent>
      </>
    );
  }

  return (
    <CheckBoxGroup
      className='col-sm-12'
      checkBoxesProps={ statusForReports }
      label={ i18next.t('reports.searchConditions.approvalStatus') }
      isAllSelectable={ true }
      onChangeAllStatus={ (): void => { onChangeAllStatus(statusForReport, 'forReport'); } }
    />
  );
};

export default RequestStatusCheckBoxes;
