import en from 'locales/keihi.en';
import i18next from 'i18next';
import intervalPlural from 'i18next-intervalplural-postprocessor';
import ja from 'locales/keihi.ja';
import { getUserLocale } from 'utilities/locale';

i18next
  .use(intervalPlural)
  .init({
    lng: getUserLocale(),
    ns: 'keihi',
    defaultNS: 'keihi',
    resources: {
      en: {
        keihi: en,
      },
      ja: {
        keihi: ja,
      },
    },
    /*
      * 対応言語が増加するなどした場合は、非同期で該当言語を取得するよう、
      * 以下の様なコードに変更する
      *
      * resGetPath: '/assets/locales/{{lng}}/{{ns}}.json',
      * backend: {
      *   loadPath: '/assets/locales/{{lng}}/{{ns}}.json',
      * },
      */
    fallbackLng: 'ja',
  });

// TODO: 既存コードで使用している箇所はないことを確認して削除
(global as any).i18next = i18next; // eslint-disable-line @typescript-eslint/no-explicit-any

export default i18next;
