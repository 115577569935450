import AccordionButton from "components/accordion_button";
import JrReservationField from "components/fields/jrReservationField";
import i18next from "i18n";
import chunk from "lodash/chunk";
import omit from "lodash/omit";
import PropTypes from "prop-types";
import React, { Component } from "react";
import colors from "values/colors";

export default class FareDetailOptions extends Component {
  constructor(props) {
    super(props);

    this.handleCheckBox = this.handleCheckBox.bind(this);
    this.handleRadio = this.handleRadio.bind(this);
    this.handleSelector = this.handleSelector.bind(this);
  }

  handleCheckBox(e, key) {
    e.stopPropagation();
    this.props.onSearchConditionChange(key, e.target.checked);

    // 新幹線でチェックが外れている場合は割引を未選択に戻す
    if (key === "shinkansen" && !e.target.checked) {
      this.props.onSearchConditionChange("jrReservation", undefined);
    }
  }

  handleRadio(e, value) {
    e.stopPropagation();
    this.props.onSearchConditionChange("surcharge", value);
  }

  handleSelector(option) {
    this.props.onSearchConditionChange("jrReservation", option?.value);
  }

  render() {
    const { searchCondition } = this.props;

    // todo detailCode -> codeに変えたい
    const transports = chunk(
      Object.keys(
        omit(searchCondition, [
          "withIc",
          "surcharge",
          "detailCode",
          "date",
          "jrReservation",
        ]),
      ),
      3,
    ).map((keys, index) => (
      <div key={`detail-idx-${index}`}>
        {keys.map((key) => (
          <label className="checkbox-inline" key={`${key}-option`}>
            <input
              type="checkbox"
              id={key}
              checked={searchCondition[key]}
              onChange={(e) => this.handleCheckBox(e, key)}
            />
            {i18next.t(`transactions.inputs.details.${key}`)}
          </label>
        ))}
      </div>
    ));

    const surchargeKind = (
      <div className="form-inline" key={"surcharge-kind-option"}>
        {["free", "reserved", "green"].map((type) => (
          <div className="radio-inline" key={`surcharge-${type}-option`}>
            <input
              type="radio"
              name="type"
              value={i18next.t(`transactions.inputs.details.${type}`)}
              checked={searchCondition.surcharge === type}
              onChange={(e) => this.handleRadio(e, type)}
            />
            {i18next.t(`transactions.inputs.details.${type}`)}
          </div>
        ))}
      </div>
    );

    return (
      <AccordionButton
        className="fare-transaction-appendix"
        defaultOpen={false}
        inline={true}
        renderButton={() => (
          <span
            style={{
              color: colors.renewaled.main,
              fontSize: "12px",
              userSelect: "none",
            }}
          >
            <i className="fa fa-fw fa-search" />
            <span>{i18next.t("transactions.inputs.detailSettings")}</span>
          </span>
        )}
      >
        <div className="fare-transaction-searchbox row">
          <div className="row">
            <label className="col-sm-3 control-label transports">
              {i18next.t("transactions.inputs.facilities")}
            </label>
            <div className="col-sm-9 option-checkbox-group transports">
              {transports}
            </div>
          </div>
          {this.props.searchCondition.shinkansen ? (
            <>
              <div className="row">
                <label className="col-sm-3 control-label">
                  {i18next.t("transactions.inputs.jrDiscount")}
                </label>
                <div className="col-sm-9 option-checkbox-group jr-reservation-option">
                  <JrReservationField
                    className={"jr-reservation-select"}
                    discountType={searchCondition.jrReservation}
                    selectedItem={searchCondition.jrReservation}
                    onSelectItem={this.handleSelector}
                  />
                </div>
              </div>
            </>
          ) : (
            <></>
          )}
          <div className="row">
            <label className="col-sm-3 control-label">
              {i18next.t("transactions.inputs.expressOptions")}
            </label>
            <div className="col-sm-9 option-checkbox-group">
              {surchargeKind}
            </div>
          </div>
        </div>
      </AccordionButton>
    );
  }
}

FareDetailOptions.defaultProps = {
  searchCondition: {},
};

FareDetailOptions.propTypes = {
  searchCondition: PropTypes.shape({
    shinkansen: PropTypes.bool.isRequired,
    limitedExpress: PropTypes.bool.isRequired,
    liner: PropTypes.bool.isRequired,
    localBus: PropTypes.bool.isRequired,
    connectionBus: PropTypes.bool.isRequired,
    highwayBus: PropTypes.bool.isRequired,
    midnightBus: PropTypes.bool.isRequired,
    plane: PropTypes.bool.isRequired,
    ship: PropTypes.bool.isRequired,
    surcharge: PropTypes.string.isRequired,
    jrReservation: PropTypes.string,
  }),
  onSearchConditionChange: PropTypes.func.isRequired,
};
