import _get from "lodash/get";
import _merge from "lodash/merge";
import * as ActionTypes from "../actions/transaction";

/**
 * tablesは以下の構造のObjectのArray
 * {
 *   id: '', // 手当表のID
 *   name: '', // 手当表の名前
 *   factorTypes: [
 *     // 手当表を構成する行・列の情報
 *     // 1次元であったり、3次元以上であることもあるので注意
 *     {
 *       id: '',
 *       name: '', // 行の情報の表示名
 *       sort: 0,
 *       options: [], // 表の行のリスト
 *     },
 *     {
 *       id: '',
 *       name: '', // 列の情報の表示名
 *       sort: 1,
 *       options: [], // 表の列のリスト
 *     },
 *   ],
 *   cells: {
 *     // 手当表のセルの情報を持つ
 *     // 値を取るには、factorTypeのoptionのIDを順番にたどっていく（2次元表の場合は、行、列の順にたどる）
 *     [UUID]: {
 *       [UUID]: {
 *         ...
 *            [UUID]: { id: 'cell id', value: 100 },
 *       },
 *     },
 *   ],
 *   calculationFormula: {
 *     id: '', // 計算式のID
 *     ast: {},
 *     variables: [], // 計算実行上必要な入力情報
 *   },
 *   input: {
 *     factorIds: {}, // factorTypeのIDをkeyとし、optionのIDをvalueとするObject
 *     tableVariableId: '', // 計算式に使用する変数の内、手当表の結果を代入するもののID
 *     cellId: '', // factorIdsの情報により特定されたcellのID
 *     variableInputs: {}, // variableのIDをkeyとし、入力値をvalueとするObject
 *     amount: 0, // variableInputsとcellIdにより計算される総額
 *   },
 * }
 *
 */
const initialState = {
  tables: [],
  tabs: [],
};

function selectCell(factorTypes, factorIds, cells) {
  const key = factorTypes.map((x) => `[${factorIds[x.id]}]`).join("");
  return _get(cells, key, {});
}

const tableReducer = (state = {}, action) => {
  switch (action.type) {
    case ActionTypes.RESET_INPUT: {
      return {
        ...action.table,
        input: {
          ...action.inputData,
        },
      };
    }
    case ActionTypes.SET_INPUT_VALUE: {
      if (action.key === "factorIds") {
        // 手当表のセルも合わせて変更される
        const nextFactorIds = {
          ...state.input.factorIds,
          ...action.value,
        };

        const cell = selectCell(state.factorTypes, nextFactorIds, state.cells);
        return {
          ...state,
          input: {
            ...state.input,
            factorIds: nextFactorIds,
            variableInputs: {
              ...state.input.variableInputs,
              [state.input.tableVariableId]: {
                id: cell.id,
                value: cell.value,
                editable: cell.editable,
              },
            },
          },
        };
      }

      let nextValue = null;

      if (action.key === "variableInputs") {
        nextValue = _merge({ ...state.input[action.key] }, { ...action.value });
      } else {
        nextValue = action.value;
      }

      return {
        ...state,
        input: {
          ...state.input,
          [action.key]: nextValue,
        },
      };
    }
    default: {
      return state;
    }
  }
};

const allowance = (state = initialState, action) => {
  switch (action.type) {
    case ActionTypes.SET_DIRECT_PRODUCT_TABLES: {
      return {
        ...state,
        tables: action.data,
      };
    }
    case ActionTypes.SET_INPUT_VALUE:
    case ActionTypes.RESET_INPUT: {
      const tables = state.tables.map((table) => {
        if (table.id === action.table.id) {
          return tableReducer(table, action);
        }
        return table;
      });

      return {
        ...state,
        tables,
      };
    }
    default:
      return state;
  }
};

export default allowance;
