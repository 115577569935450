import {
  DropEventHandler,
  DropType,
  FileType,
  Rect,
  RotateEventHandler,
  ScaleEventHandler,
} from "components/types/attachedFiles.types";
import React, { useCallback, useMemo, useState } from "react";
import { DropzoneState, useDropzone } from "react-dropzone";

export type DropOption<T extends DropType = DropType> = {
  readonly dropType: T;
  readonly isDroppable: boolean;
  readonly selectableTypes?: FileType[];
  readonly onDrop?: DropEventHandler<T>;
};
interface RotateOption {
  readonly defaultValue?: number;
  readonly onChange?: RotateEventHandler;
}
interface ScaleOption {
  readonly defaultValue?: number;
  readonly viewerSize?: Rect;
  readonly rawImageSize?: Rect;
  readonly diff?: number;
  readonly min?: number;
  readonly max?: number;
  readonly onChange?: ScaleEventHandler;
}

type Callback = (e: React.SyntheticEvent) => void;
interface RotationCallbacks {
  readonly onRotate: Callback;
}
interface ScaleCallbacks {
  readonly onScaleUp: Callback;
  readonly onScaleDown: Callback;
  readonly onFit: Callback;
}

const TYPE_TO_MIMETYPE = {
  jpeg: "image/jpeg",
  png: "image/png",
  gif: "image/gif",
  pdf: "application/pdf",
  xlsx: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
  xls: "application/vnd.ms-excel",
  csv: "text/csv",
  txt: "text/plain",
  doc: "application/msword",
  docx: "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
} as const;

/**
 * ドラック&ドロップのロジック
 */
export const useDrop = (option: DropOption): DropzoneState => {
  const { dropType, isDroppable, onDrop, selectableTypes = [] } = option;

  const accept = useMemo(
    () => selectableTypes.map((type) => TYPE_TO_MIMETYPE[type]),
    [selectableTypes],
  );

  /**
   * ファイルがドロップした
   */
  const handleDrop = useCallback(
    (acceptedFiles: File[], rejectedFiles: File[]) => {
      if (onDrop) {
        const isAccepted = acceptedFiles.length > 0;
        if (dropType === "single") {
          const file = acceptedFiles[0] || rejectedFiles[0];
          onDrop(file, isAccepted);
        } else {
          const files = acceptedFiles || rejectedFiles;
          onDrop(files, isAccepted);
        }
      }
    },
    [dropType, onDrop],
  );

  return useDropzone({
    disabled: !isDroppable,
    accept,
    onDrop: handleDrop,
  });
};

/**
 * 回転・拡大・縮小のロジック
 */
export const useRotation = (
  option: RotateOption = {},
): [number, RotationCallbacks] => {
  const { defaultValue = 0, onChange } = option;
  const [rotation, setRotation] = useState<number>(defaultValue);

  const onRotate = useCallback(
    (e: React.SyntheticEvent) => {
      e.stopPropagation();

      const nextValue = (rotation + 90) % 360;
      setRotation(nextValue);

      if (onChange) {
        onChange(e, nextValue);
      }
    },
    [rotation, onChange],
  );

  return [rotation, { onRotate }];
};

/**
 * サイズ調整のロジック
 * @param viewerSize - 画像の表示領域のサイズ
 * @param imageSize - 倍率1の時の画像サイズ。現在の表示を元にする（回転中は、高さ・横幅は元画像と逆転していることがある）
 * @param option
 */
export const useScale = (
  option: ScaleOption = {},
): [number, ScaleCallbacks] => {
  const {
    viewerSize = [0, 0],
    rawImageSize = [0, 0],
    diff = 0.25,
    min = 1 / 3,
    max = 15.0,
    defaultValue = 1,
    onChange,
  } = option;

  const [scale, setScale] = useState<number>(defaultValue);

  /**
   * 拡大をした
   */
  const onScaleUp = useCallback(
    (e: React.SyntheticEvent) => {
      e.stopPropagation();

      const nextValue = Math.min(max, scale + diff);
      setScale(nextValue);

      if (onChange) {
        onChange(e, nextValue);
      }
    },
    [scale, diff, max, onChange],
  );

  /**
   * 縮小をした
   */
  const onScaleDown = useCallback(
    (e: React.SyntheticEvent) => {
      e.stopPropagation();

      const nextValue = Math.max(min, scale - diff);
      setScale(nextValue);

      if (onChange) {
        onChange(e, nextValue);
      }
    },
    [scale, diff, min, onChange],
  );

  /**
   * サイズを初期化した
   */
  const onFit = useCallback(
    (e: React.SyntheticEvent) => {
      e.stopPropagation();

      const [imageHeight, imageWidth] = rawImageSize;
      const [viewerHeight, viewerWidth] = viewerSize;

      if (imageWidth === 0 || imageHeight === 0) {
        // 実行されない
        return;
      }

      const newScale = Math.min(
        viewerHeight / imageHeight,
        viewerWidth / imageWidth,
      );
      const nextValue = Math.floor(100 * newScale) / 100;

      setScale(nextValue);

      if (onChange) {
        onChange(e, nextValue);
      }
    },
    [rawImageSize, viewerSize, onChange],
  );

  return [scale, { onScaleUp, onScaleDown, onFit }];
};
