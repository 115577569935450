import React from 'react';
import i18next from 'i18next';
import { AnalysisAggregationConditions } from 'utilities/api/models/accountingDataScheduledExports/AnalysisAggregationConditions';
import { Popover as BsPopover, OverlayTrigger } from 'react-bootstrap';
import { List } from './List';

interface Props {
  analysisAggregationConditions: AnalysisAggregationConditions;
}

export const Popover: React.FC<Props> = ({
  analysisAggregationConditions,
}) => {
  return (
    <OverlayTrigger
      trigger={ ['click', 'hover'] }
      placement={ 'bottom' }
      overlay={
        <BsPopover id='description' style={ { minWidth: '400px' } }>
          <List analysisAggregationConditions={ analysisAggregationConditions } />
        </BsPopover>
      }>
      <div style={ { color: '#AAA' } }>{ i18next.t('accountingDataScheduledExports.tasks.analysisAggregationConditions.details') }</div>
    </OverlayTrigger>
  );
};
