import Api from 'utilities/api';
import escapeRegExp from 'lodash/escapeRegExp';
import get from 'lodash/get';
import isNil from 'lodash/isNil';
import { fetchAsync } from 'actions/AsyncAction';

const prefix = 'customInput';

export function fetchCustomInputList(field, params, customInput = null) {
  return (dispatch, getState) => {
    const fetchApi = null;

    if (isNil(fetchApi)) {
      return null;
    }

    return dispatch(fetchAsync(fetchApi, params ? { ...params, fieldId: field.id } : { fieldId: field.id }))
      .then((data) => {
        dispatch(setCustomInputList(field, data));

        return data;
      })
      .then((data) => {
        if (customInput) {
          dispatch(updateCustomInputSuggestions(field, customInput, data));
        }
      })
      .catch(() => { /* エラー表示後なので何もしない */ });
  };
}

export const SET_CUSTOM_INPUT_LIST = `${prefix}/SET_CUSTOM_INPUT_LIST`;
export function setCustomInputList(field, histories) {
  return {
    type: SET_CUSTOM_INPUT_LIST,
    field,
    histories,
  };
}

export const SET_CUSTOM_INPUT_TEXT = `${prefix}/SET_CUSTOM_INPUT_TEXT`;
export function setCustomInputText(field, text) {
  return {
    type: SET_CUSTOM_INPUT_TEXT,
    field,
    text,
  };
}

export const SET_CUSTOM_INPUT = `${prefix}/SET_CUSTOM_INPUT`;
export function setCustomInput(field, value) {
  return {
    type: SET_CUSTOM_INPUT,
    field,
    value,
  };
}

// Suggestions
/*
 * 入力の補完候補を更新する
 * @param {string} type - actionのtypeを指定。更新対象を区別する
 * @param {array} suggestions - 補完候補
 */

export const UPDATE_CUSTOM_INPUT_SUGGESTIONS = `${prefix}/UPDATE_CUSTOM_INPUT_SUGGESTIONS`;
export function updateCustomInputSuggestions(field, value, total = []) {
  return {
    type: UPDATE_CUSTOM_INPUT_SUGGESTIONS,
    field,
    suggestions: filterSuggestions(total, value, (x) => x.displayName),
  };
}

export const RESET_CUSTOM_INPUT_SUGGESTIONS = `${prefix}/RESET_CUSTOM_INPUT_SUGGESTIONS`;
export function resetCustomInputSuggestions(field) {
  return { type: RESET_CUSTOM_INPUT_SUGGESTIONS, field };
}

const getCurrentSuggestions = (state, field) => getSuggestions.bind(null, state, field, 'current');
const getTotalSuggestions = (state, field) => getSuggestions.bind(null, state, field, 'total');
function getSuggestions(state, field, key) {
  return get(state.customSuggest, `${field.id}.${key}`, []);
}

function filterSuggestions(total, value, getter = (x) => x) {
  const reg = new RegExp(`.*${escapeRegExp((value || '').trim())}.*`, 'g');
  return total.filter((x) => getter(x).match(reg));
}
