import i18next from "i18n";
import React from "react";
import { OverlayTrigger, Popover } from "react-bootstrap";
import { Expense } from "utilities/api/models";

type Props = Pick<Expense, "inputBy">;

/** オペレータ入力アイコン */
export const FromWorkerIconWithOverlay: React.FC<Props> = ({ inputBy }) => {
  if (inputBy !== "worker") {
    return null;
  }

  const fromWorkerOverlay = (): JSX.Element => {
    return (
      <Popover id="from-worker-popup">
        {i18next.t("transactions.messages.fromWorker")}
      </Popover>
    );
  };

  return (
    <OverlayTrigger rootClose placement="left" overlay={fromWorkerOverlay()}>
      <i className="material-icons">cloud_done</i>
    </OverlayTrigger>
  );
};
