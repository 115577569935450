import InputChangeLog from './InputChangeLog';
import InputDiffLog from './InputDiffLog';
import LabeledComponent from 'components/LabeledComponent';
import PropTypes from 'prop-types';
import React from 'react';
import _camelCase from 'lodash/camelCase';
import i18next from 'i18n';
import styled from "styled-components";

const PropertyLabel = styled.span`
  &:hover {
    cursor: pointer;
  }
`;

function getHistoryPropertyLabel(fieldType, propertyName) {
  if (fieldType === 'allowance' || fieldType === 'generic_fields') {
    return (
      <PropertyLabel
        title={ propertyName }
      >
        { propertyName }
      </PropertyLabel>
    );
  }

  const knownFields = [
    'amount',
    'companion',
    'mark_as_matched',
    'original_receipt',
    'project',
    'receipt_file',
    'route',
    'withholding',
  ];

  // サーバーで新たにフィールドが追加された時の対策（ラベルなし）
  if (!knownFields.includes(fieldType)) { return null; }

  const field = _camelCase(fieldType);
  const property = _camelCase(propertyName);

  return i18next.t(`transactions.histories.properties.${field}.${property}`);
}

const getLogComponent = (change) => {
  if (Object.prototype.hasOwnProperty.call(change, 'increment')) {
    return InputDiffLog;
  }

  return InputChangeLog;
};

const renderLog = (change, props) => {
  const LogComponent = getLogComponent(change);

  return <LogComponent { ...change } { ...props } />;
};

const FieldInputLog = (props) => {
  const { fieldType, label, changes } = props;
  const baseClassName = 'field-input-log';
  const className = props.className ? `${baseClassName} ${props.className}` : baseClassName;
  const hasProperties = changes.some((x) => x.propertyName);

  return (
    <LabeledComponent className={ className } label={ label }>
      {
        hasProperties ? (
          <div className='field-input-log__properties'>
            {
              changes.map((change) => {
                const logProps = {
                  className: 'field-input-log__value',
                  fieldType,
                };

                return (
                  <LabeledComponent key={ change.propertyName }
                    className='field-input-log__property'
                    label={ getHistoryPropertyLabel(fieldType, change.propertyName) }
                  >
                    { renderLog(change, logProps) }
                  </LabeledComponent>
                );
              })
            }
          </div>
        ) : renderLog(changes[0], { className: 'field-input-log__value', fieldType })
      }
    </LabeledComponent>
  );
};

FieldInputLog.defaultProps = {
  changes: [],
};

FieldInputLog.propTypes = {
  clasName: PropTypes.string,
  label: PropTypes.string.isRequired,
  fieldType: PropTypes.string.isRequired,
  changes: PropTypes.array.isRequired,
};

export default FieldInputLog;
