import React from "react";
import REPORT_STATUS from "../../../values/report_status";
import * as Buttons from "./buttons";
import { RequestState } from "./buttons/Types";

interface Props {
  locale: string;
  preReport: {
    id?: string;
    status?: string;
  };
  requestState: RequestState;
  onSaveAndApplyButtonClick: () => void;
  onApplyButtonClick: () => void;
  onReapplyButtonClick: () => void;
  onChangeFlowButtonClick: () => void;
  onRecallButtonClick: () => void;
}

/** 承認前の事前申請（精算申請なし）の申請者用ボタン */
const ButtonsForRequesterBeforeApproved: React.FC<Props> = ({
  locale,
  preReport,
  requestState,
  onSaveAndApplyButtonClick,
  onApplyButtonClick,
  onReapplyButtonClick,
  onChangeFlowButtonClick,
  onRecallButtonClick,
}) => {
  if (preReport.id == null) {
    return (
      <Buttons.SaveAndApplyButton
        requestState={requestState}
        onClick={onSaveAndApplyButtonClick}
      />
    );
  }
  switch (preReport.status) {
    case REPORT_STATUS[locale].created:
      return (
        <Buttons.ApplyButton
          requestState={requestState}
          onClick={onApplyButtonClick}
        />
      );
    case REPORT_STATUS[locale].recalled:
    case REPORT_STATUS[locale].rejected:
      return (
        <Buttons.ReapplyButton
          requestState={requestState}
          onClick={onReapplyButtonClick}
        />
      );
    case REPORT_STATUS[locale].aborted:
      return (
        <Buttons.ChangeFlowButton
          requestState={requestState}
          onClick={onChangeFlowButtonClick}
        />
      );
    case REPORT_STATUS[locale].applying:
      return (
        <Buttons.RecallButton
          requestState={requestState}
          onClick={onRecallButtonClick}
        />
      );
    default:
      return null;
  }
};

export default ButtonsForRequesterBeforeApproved;
