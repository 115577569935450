import { TabelColumnParams } from "applications/expenses/types";
import cloneDeep from "lodash/cloneDeep";
import _omit from "lodash/omit";
import WebStorage from "utilities/web_storage";
import SearchParamsStorage from "./searchParamsStorage";

interface UpdateWidthPayload {
  id: string;
  width: number;
}

/**
 * 経費一覧のカラム表示設定を保存するために使用する、LocalStorageのモデル
 */
export class ColumnParamsStorage {
  private readonly storage: WebStorage;

  private readonly version: string;

  private _data: TabelColumnParams;

  constructor() {
    this.storage = new WebStorage();

    this.version = "1.0";

    this._data = { ...this.data };

    this.migrate();
  }

  get data(): TabelColumnParams {
    return (
      this._data ||
      this.storage.getItem(this.key) ||
      this.defaultDataWithInitialWidthAdjust
    );
  }

  set data(params: TabelColumnParams) {
    const newData = _omit(params, ["_version"]);

    this._data = newData;
    this.storage.setItem(this.key, { ...this._data, _version: this.version });
  }

  update(params: TabelColumnParams): void {
    this.data = { ...this.data, ...(params || {}) };
  }

  updateWidth({ id, width }: UpdateWidthPayload): void {
    if (!this.data[id]) return;
    const data = cloneDeep(this.data);
    data[id].width = width;
    this.data = data;
  }

  private get defaultData(): TabelColumnParams {
    const tableColumnParams = {
      error: {
        show: true,
        index: 0,
        width: 50,
      },
      read: {
        show: false,
        index: 1,
        width: 70,
      },
      transactedAt: {
        show: true,
        index: 2,
        width: 120,
      },
      label: {
        show: true,
        index: 3,
        width: 130,
      },
      hasValidatedReceiptMatching: {
        show: userPreferences.isPaperlessPlan, // 選択可能条件に合わせる
        index: 4,
        width: 100,
      },
      isElectronicReceiptImage: {
        show: userPreferences.preference
          .enableExpenseElectronicReceiptImageFlag,
        index: 5,
        width: 100,
      },
      shopName: {
        show: true,
        index: 6,
        width: 120,
      },
      transitPayee: {
        show: false,
        index: 7,
        width: 150,
      },
      amount: {
        show: true,
        index: 8,
        width: 100,
      },
      withholding: {
        show: false,
        index: 9,
        width: 120,
      },
      ownerName: {
        // リリース時で'事業所全体で検索'を選択中であれば表示
        show: SearchParamsStorage.data.scope,
        index: 10,
        width: 120,
      },
      categoryName: {
        show: true,
        index: 11,
        width: 120,
      },
      expenseAmountPerTaxCategories: {
        show: true,
        index: 12,
        width: 100,
      },
      comment: {
        show: false,
        index: 13,
        width: 150,
      },
      companions: {
        show: false,
        index: 14,
        width: 100,
      },
      originAndDestinationByCategory: {
        show: false,
        index: 15,
        width: 100,
      },
      visitByCategory: {
        show: false,
        index: 16,
        width: 100,
      },
      purposeByCategory: {
        show: false,
        index: 17,
        width: 100,
      },
      project: {
        show: !userPreferences.preference.inputProject, // 選択可能条件に合わせる
        index: 18,
        width: 120,
      },
      department: {
        show: true,
        index: 19,
        width: 100,
      },
      costAllocations: {
        show: userPreferences.preference.inputGroup, // 選択可能条件に合わせる
        index: 20,
        width: 130,
      },
      creditCategoryName: {
        show: false,
        index: 21,
        width: 120,
      },
      reportTitle: {
        show: true,
        index: 22,
        width: 150,
      },
      isCorporate: {
        show: false,
        index: 23,
        width: 100,
      },
      status: {
        show: true,
        index: 24,
        width: 100,
      },
      createdAt: {
        show: true,
        index: 25,
        width: 100,
      },
      reportSequenceNum: {
        show: false,
        index: 26,
        width: 100,
      },
      preReportSequenceNum: {
        show: false,
        index: 27,
        width: 100,
      },
      registratedNumber: {
        show: true,
        index: 27,
        width: 100,
      },
      asEligibleInvoice: {
        show: true,
        index: 28,
        width: 100,
      },
    };

    const countTableColumnParams = Object.keys(tableColumnParams).length;

    if (Array.isArray(userPreferences.preference.genericFields)) {
      userPreferences.preference.genericFields.forEach((item, index) => {
        tableColumnParams[item.id] = {
          show: true,
          index: countTableColumnParams + index + 1,
          width: 100,
        };
      });
    }

    return tableColumnParams;
  }

  /** 初期化時、画面幅に併せて各列の幅を調整 */
  get defaultDataWithInitialWidthAdjust(): TabelColumnParams {
    let totalFlexColumnWidth = 0;
    let totalFixedColumnWidth = 0;
    const fixWidthKeys = ["error", "read", "label"];

    // 幅調整する列としない列の合計幅をそれぞれ算出
    Object.keys(this.defaultData).forEach((key) => {
      if (this.defaultData[key].show) {
        if (fixWidthKeys.includes(key)) {
          totalFixedColumnWidth += this.defaultData[key].width;
        } else {
          totalFlexColumnWidth += this.defaultData[key].width;
        }
      }
    });

    // 画面幅 - サイドバー幅 - 業務領域のpadding - 画面幅に合わせて列幅を変えない列の合計幅 - Tableに追加されるチェックボックス列の幅 - スクロールバー幅
    const tableWidth =
      window.innerWidth - 170 - 32 - totalFixedColumnWidth - 40 - 20;

    // 画面幅 =< テーブル幅の場合：そのまま表示
    if (tableWidth < totalFlexColumnWidth) return this.defaultData;

    // 画面幅 > テーブル幅の場合：余白を埋めるように列幅を拡張
    const data = cloneDeep(this.defaultData);
    Object.keys(this.defaultData).forEach((key) => {
      if (this.defaultData[key].show && !fixWidthKeys.includes(key)) {
        data[key].width =
          (this.defaultData[key].width / totalFlexColumnWidth) * tableWidth;
      }
    });

    return data;
  }

  private get key(): ["expenses", "reactTableColumnParams"] {
    return ["expenses", "reactTableColumnParams"];
  }

  private migrate(): void {
    this.data = this.migrateSchema(
      this.storage.getItem(["transactions", "reactTableColumnParams"]),
    );

    // 使用されない値を削除する
    this.storage.removeItem(["transactions", "reactTableColumnParams"]);
  }

  private migrateSchema(params: TabelColumnParams): TabelColumnParams {
    return {
      ...this.defaultDataWithInitialWidthAdjust,
      ...(params || this.data),
    };
  }
}

export default new ColumnParamsStorage();
