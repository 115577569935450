import React from "react";
import styled from "styled-components";

const Wrapper = styled.div`
  background: #6b6b6b;
  border-radius: 50px;
  width: 18px;
  height: 18px;
  box-shadow: 0 0 10px #00000052;
  cursor: pointer;
`;
const Innner = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
`;

interface Props {
  /** 上向きか。閉じてる状態。 */
  readonly isUp: boolean;
}

/**
 * PDFビューアーツールバー: トグルボタン
 */
const PDFToggleButton: React.FC<Props> = (p) => {
  return (
    <Wrapper className="viewer-browser-pdf-toolbar-toggle-wrapper">
      <Innner className="viewer-browser-pdf-toolbar-toggle-inner">
        {p.isUp && <i className="fas fa-chevron-up" />}
        {!p.isUp && <i className="fas fa-chevron-down" />}
      </Innner>
    </Wrapper>
  );
};

export default PDFToggleButton;
