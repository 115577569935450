import React from 'react';
import ReactDOM from 'react-dom';
import i18n from '../i18n';
import logger from 'redux-logger';
import merge from 'lodash/merge';
import thunk from 'redux-thunk';
import { Provider } from 'react-redux';
import { applyMiddleware, compose, createStore } from 'redux';

/**
 * ページのReactPropsデータを取得する
 */
function getReactPropData(dom) {
  if (dom) {
    const propData = dom.getAttribute("data-react-props");
    try {
      const props = (propData && JSON.parse(propData)) || void 0;
      return props;
    } catch (err) {
      if (err instanceof SyntaxError) {
        return void 0;
      }
      throw err;
    }
  }
  return void 0;
}

/**
 * Reduxコンポーネントでのエンハンサーの定義
 * @param {function} funcs The functions to compose
 */
export function createEnhancer(...funcs) {
  // https://github.com/zalmoxisus/redux-devtools-extension を利用する
  const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose; // eslint-disable-line no-underscore-dangle

  // test, production の場合は開発ツールを導入しない
  if (!['test', 'production'].includes(process.env.NODE_ENV)) {
    return composeEnhancers(
      // Reduxのログをコンソールに出力する
      applyMiddleware(thunk, logger),
      ...funcs,
    );
  }
  return compose(
    applyMiddleware(thunk),
    ...funcs,
  );
}

/**
 * コンポーネントを描画する
 */
export function render(componentName, component, processor) {
  const rootDom = document.getElementById(componentName);

  // railsのview内の場合分けなどで、render_react_componentが呼ばれないコンポーネントがある
  if (rootDom) {
    let props = getReactPropData(rootDom);

    if (processor && typeof processor === 'function') {
      props = processor(props);
    }

    ReactDOM.render(
      React.createElement(component, { ...props, i18n }),
      document.getElementById(componentName),
    );
  }
}

/**
 * Reduxを使用するコンポーネントを描画する
 */
export function renderRedux(name, component, reducers, processor, debug = process.env.NODE_ENV !== 'production') {
  const rootDom = document.getElementById(name);

  if (rootDom) {
    let props = getReactPropData(rootDom);

    if (processor && typeof processor === 'function') {
      props = processor(props);
    }
    const initialState = merge(reducers(void 0, {}), props);
    const store = createStore(reducers, initialState, createEnhancer());

    ReactDOM.render(
      <Provider store={ store }>
        { React.createElement(component, { i18n }) }
      </Provider>,
      rootDom,
    );
  }
}

export default {
  render,
  renderRedux,
};
