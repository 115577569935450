import { IndeterminateCheckbox } from "components/renewaled_ui/Table/atoms/IndeterminateCheckbox";
import { RequiredTableDataProps } from "components/renewaled_ui/Table/interface/table";
import React from "react";
import { Row } from "react-table";

interface Props<Data> {
  /** checkboxのための重複しないID */
  readonly id: string;
  /** 行データ */
  readonly row: Row;
  /** テーブルデータ */
  readonly data: Data[];
  /** 行を選択した */
  readonly onSelect: (data: Data, isSelected: boolean) => void;
}

/**
 * テーブルの先端のセル部分のチェックボックス
 */
export const TableFirstCell = <Data extends RequiredTableDataProps>(
  props: Props<Data>,
): JSX.Element => {
  /**
   * 選択中のデータ
   */
  const selectedData = props.data[props.row.id];

  /**
   * 選択を押した
   */
  const onChange = (): void => {
    props.onSelect(selectedData, !selectedData.isSelected);
  };

  /**
   * 非活性の場合は薄くする
   */
  const opacity = selectedData?.isUnapprovable ? 0.4 : 1;

  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        height: 24,
        marginLeft: "-3px",
        opacity,
      }}
    >
      <IndeterminateCheckbox
        {...props.row.getToggleRowSelectedProps()}
        checked={selectedData?.isSelected}
        onChange={onChange}
        isDisabled={selectedData?.isUnapprovable}
        id={`${props.id}-tbody-select-row-${props.row.id}`}
      />
    </div>
  );
};
