import Clearable from 'components/fields/Clearable';
import FieldWithInputModal from '../FieldWithInputModal';
import React, { Dispatch, FC, SetStateAction, useCallback } from 'react';
import SuperCategorySelector from './SuperCategorySelector';
import i18next from 'i18n';
import { SuperCategoryChoice } from './types/SuperCategoryChoice';
import { text as formatText } from 'utilities/formatter';

interface Props {
  className: string;
  isMultiSelectable: boolean;
  isStatic: boolean;
  placeholder?: string;
  value: null | SuperCategoryChoice | SuperCategoryChoice[];
  onSelect: (value: SuperCategoryChoice | SuperCategoryChoice[]) => void;
  show?: boolean;
  setShowModal?: Dispatch<SetStateAction<boolean>>
}

function formatMultiSelectableText(value: SuperCategoryChoice[], emptyText: string | null): JSX.Element | null | string {
  if (value.length > 0) {
    return formatText(value.map((v) => v.name).join(', '), emptyText);
  }

  return formatText(null, emptyText);
}

function formatValue(
  value: null | SuperCategoryChoice | SuperCategoryChoice[],
  placeholder?: string,
): JSX.Element | null | string {
  const emptyText = placeholder || null;

  if (value == null) {
    return formatText(null, emptyText);
  }

  if (value instanceof Array) {
    return formatMultiSelectableText(value, emptyText);
  }

  return formatText(value.name, emptyText);
}

/** 勘定科目入力フィールド
 * モーダルを用いて経費科目を選択する
 */
const SuperCategoryFieldWithInputModal: FC<Props> = (props) => {
  const {
    isMultiSelectable, isStatic, placeholder, value, onSelect, show, setShowModal,
  } = props;

  const handleSelect = useCallback((_superCategory, _isSelected, selection): void => { onSelect(selection); }, [onSelect]);

  const handleClear = useCallback((): void => { onSelect([]); }, [onSelect]);

  const renderField = useCallback(({ className, onClick }): JSX.Element => {
    if (isStatic) {
      return (
        <span className={ `${className} form-control-static`  }>
          { formatValue(value, placeholder) }
        </span>);
    }

    return (
      <Clearable className={ `${className} form-control` }
        onClick={ onClick }
        onClear={ handleClear }
      >
        { formatValue(value, placeholder) }
        <i className='caret' />
      </Clearable>
    );
  }, [handleClear, isStatic, placeholder, value]);

  const renderModalBody = useCallback((): JSX.Element => {
    return (
      <SuperCategorySelector
        isMultiSelectable={ isMultiSelectable }
        onSelect={ handleSelect }
        value={ value }
      />
    );
  }, [handleSelect, isMultiSelectable, value]);

  return (
    <FieldWithInputModal className={ props.className }
      isStatic={ props.isStatic }
      renderField={ renderField }
      renderModalBody={ renderModalBody }
      modalTitle={ i18next.t('superCategories.actions.selectSuperCategory') }
      modalSize='md'
      show={ show }
      setShowModal={ setShowModal }
    />
  );
};

export default SuperCategoryFieldWithInputModal;
