import Api from 'utilities/api';
import InfiniteSelector from 'components/fields/InfiniteSelector';
import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';
import SearchBox from 'components/SearchBox';
import SearchResult from 'components/SearchResult';
import escapeRegExp from "lodash/escapeRegExp";
import i18next from 'i18n';
import isNil from "lodash/isNil";
import sortBy from 'lodash/sortBy';
import uniqBy from 'lodash/uniqBy';
import { SelectAllCheckBox }  from 'components/SelectAllCheckBox';
import { fetchAsyncAll } from 'actions/AsyncAction';
import { snakecaseKeys } from 'utilities/Utils';

// 経費科目選択用コンポーネント
export default class CategorySelector extends PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      searchText: '',
      allOptions: [],

      // 検索結果をリセットするためのダミーデータ。totalCountがnullの時にfilteredOptionsが更新される
      totalCount: null,
      filteredOptions: [],
    };

    this.handleSearchTextChange = this.handleSearchTextChange.bind(this);
    this.handleOptionsChange = this.handleOptionsChange.bind(this);
  }

  componentDidMount() {
    this.fetchCategories().then((categories) => {
      // BUG: レスポンス取得前に検索条件が入力された場合、表示される検索結果が正しくない可能性がある
      this.setState({ allOptions: categories, filteredOptions: [...categories], totalCount: categories.length });
    });
  }

  /**
   * @return {null|number} データを未取得の時、nullを返す。その他の場合は、件数を返す。
   */
  get totalCount() {
    const { filteredOptions, totalCount } = this.state;

    return isNil(totalCount) ? null : filteredOptions.length;
  }

  get options() {
    return this.state.filteredOptions || [];
  }

  buildCategories(categories) {
    const options = this.props.onlyChildCategories ? categories.filter((c) => c.selectable) : categories;

    /* 複数選択と、単一選択の場合で value の型が異なる */
    const mergedCategories = this.props.isMultiSelectable ? [...options, ...this.props.value] : [...options, this.props.value];
    const adjustedCategories = sortBy(uniqBy(mergedCategories, 'id'), 'sort').filter(Boolean);

    return adjustedCategories.map((c) => ({ ...c, name: this.getCategoryAbsolutePath(c, categories) }));
  }

  getCategoryAbsolutePath(category, categories, nestedCategories = []) {
    const nextNestedCategories = [category.name].concat(nestedCategories);
    const parentCategory = categories.find((c) => c.id === category.parentId);

    if (isNil(parentCategory)) {
      return nextNestedCategories.join(' / ');
    }

    return this.getCategoryAbsolutePath(parentCategory, categories, nextNestedCategories);
  }

  // 利用元で取得する必要がないようにここで取得する
  fetchCategories() {
    return fetchAsyncAll(
      (params) => Api.rootCategories.index(snakecaseKeys({
        enable: true,
        ...params,
      })).then((result) => {
        return { data: result.categories, count: result.count };
      }),
      { /* APIを投げる際の追加パラメータは特になし */ },
      { limit: 200 },
    ).then(({ data }) => {
      return this.buildCategories(data);
    });
  }

  search(searchText, _offset, _limit) {
    // offset, limitは非対応
    const categories = this.state.allOptions.filter((c) => this.isMatchedForSearchText(c, searchText));
    return Promise.resolve({ categories, count: categories.length });
  }

  // 検索条件に引っかかるか
  isMatchedForSearchText(category, searchText) {
    const text = escapeRegExp(searchText);

    if (isNil(text)) {
      return true;
    }

    return new RegExp(text).test(category.name);
  }

  formatOption(option) {
    return (
      <div className='list-selector-item-content'>
        <div>{ option.name }</div>
      </div>
    );
  }

  handleSearchTextChange(text) {
    this.setState({ searchText: text });
  }

  handleOptionsChange(categories, totalCount) {
    this.setState({ filteredOptions: categories, totalCount });
  }

  render() {
    return (
      <InfiniteSelector
        responseKey='categories'
        style={ this.props.style || {} }
        isMultiSelectable={ this.props.isMultiSelectable }
        options={ this.options }
        selection={ this.props.value }
        totalCount={ this.totalCount }
        rowHeight={ 42 }
        formatOption={ this.formatOption }
        fetchOptions={ this.search.bind(this, this.state.searchText) }
        onSelect={ this.props.onSelect }
        onOptionsChange={ this.handleOptionsChange }
        onSelectAll={ this.props.onSelectAll }
        isAllSelectable={ this.props.isAllSelectable }
      >
        {(renderSelector, { onOptionsReset, options, handleAllOptionSelect }) => {
          return (
            <div className='category-selector'>
              <SearchBox value={ this.state.searchText }
                placeholder={ i18next.t('categories.actions.searchPlaceholder') }
                onChange={ this.handleSearchTextChange }
                onClick={ onOptionsReset }
              />
              <SearchResult count={ this.totalCount }>
                {
                  this.props.isAllSelectable && (
                    <SelectAllCheckBox
                      options={ options }
                      onSelectAll={ handleAllOptionSelect }
                    />
                  )
                }
                { renderSelector() }
              </SearchResult>
            </div>
          );
        }}
      </InfiniteSelector>

    );
  }
}

CategorySelector.defaultProps = {
  isMultiSelectable: false,
  onSelect(_category, _isSelected, _selection) {
    window.console.warn('onSelect is not implemented: CategorySelector');
  },
  onlyChildCategories: false,
  isAllSelectable: false,
};

CategorySelector.propTypes = {
  isMultiSelectable: PropTypes.bool,
  onSelect: PropTypes.func.isRequired,
  onSelectAll: PropTypes.func,
  isAllSelectable: PropTypes.bool,
  value: PropTypes.oneOfType([
    PropTypes.arrayOf(
      PropTypes.shape({
        name: PropTypes.string,
      }),
    ),
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      name: PropTypes.string,
    }),
  ]),
  onlyChildCategories: PropTypes.bool,
};
