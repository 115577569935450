import React from "react";
import styled from "styled-components";
import FileIcon from "./FileIcon";
import useIcon from "./useIcon";

const Wrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
`;

interface Props {
  readonly type?: "default" | "notExist" | "notFound" | "error";
  readonly showDescription?: boolean;
}

/**
 * ファイルのplaceholder
 * ファイルが存在しない時、またはエラー発生時に表示するダミー画像
 */
const Placeholder: React.FunctionComponent<Props> = (p: Props) => {
  const type = p.type || "default";
  const [ref, iconSize] = useIcon();
  const opacity = type === "default" || type === "notExist" ? 0.7 : void 0;

  return (
    <Wrapper
      ref={ref}
      className="image-placeholder-view"
      style={{
        opacity: typeof opacity === "number" ? opacity : "",
        fontSize: `${iconSize}px`,
      }}
    >
      <FileIcon type={type} showDescription={p.showDescription} />
    </Wrapper>
  );
};

export default Placeholder;
