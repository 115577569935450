import i18next from "i18n";
import React from "react";
import TextSearchForm, { TextSearchFormProps } from "./TextSearchForm";

interface Props extends Omit<TextSearchFormProps, "label"> {
  textFormValue: string | undefined;
  checkboxValue: boolean | false;
  onChangeTextForm: (registratedNumber: string) => void;
  onChangeCheckbox?: (emptyRegistratedNumber: boolean) => void;
}

const RegistratedNumberSearchField: React.FC<Props> = ({ ...props }) => (
  <>
    <TextSearchForm
      label={i18next.t("transactions.index.searchbox.registratedNumber")}
      disabled={props.checkboxValue}
      {...props}
    />
  </>
);

export default RegistratedNumberSearchField;
