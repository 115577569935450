import Api from 'utilities/api';
import React, { useCallback, useEffect, useState } from 'react';
import flash from 'utilities/flash';
import i18next from 'i18n';
import styled from 'styled-components';
import { CancelButton, SaveButton } from '../components/buttons';
import { FormLayout } from './components/FormLayout';
import { FormProvider, useForm } from 'react-hook-form';
import { Modal } from 'react-bootstrap';
import { SystemSetting } from './types';
import { Task } from 'utilities/api/models/accountingDataScheduledExports';
import { getMessageFromResponse, snakecaseKeys } from 'utilities/Utils';

interface Props {
  show: boolean;
  onClose: () => void;
  onCreated: () => void;
  systemSetting: SystemSetting;
}

const FixedModalBody = styled(Modal.Body)`
  height: calc(100vh - 180px);
  position: relative;
`;

const ModalFooterView = styled(Modal.Footer)`
  display: flex;
  padding: 15px 6px;
  flex-direction: row;
  column-gap: 8px;
  align-items: center;
  justify-content: center;

  button:last-child {
    margin-left: auto;
  }
`;

export const CreateModal: React.FC<Props> = ({
  show,
  onClose,
  onCreated,
  systemSetting,
}) => {
  const methods = useForm<Omit<Task, 'periodStartedAt' | 'periodEndedAt'> & { periodStartedAt: string; periodEndedAt: string; }>({
    mode: 'onChange',
    defaultValues: {
      name: '',
      executionInterval: 'by_month',
      executionDayOfMonth: null,
      executionDayLastDayOfMonth: true,
      executionDayOfWeek: null,
      executionTimetable: undefined,
      periodStartedAt: '1970-01-01',
      periodEndedAt: '2100-01-01',
      analysisAggregationName: '',
      analysisAggregationConditions: {
        submittedPeriod: null,
        startPeriod: null,
        temporaryPaymentDuePeriod: null,
        transactedPeriod: null,
        lastApprovedPeriod: null,
        destination: '',
        applicantGroup: null,
        applicantPost: null,
        approvedApprover: null,
        paymentGroup: null,
        projectId: '',
        projectName: '',
        tags: [],
        includeChildDepartment: true,
        includeNotAggregated: true,
        includeAggregated: false,
        includeNotExported: false,
        includeExported: false,
        includeNotPaid: false,
        includePaid: false,
        includeNotTemporaryPaid: false,
        includeTemporaryPaid: false,
        includeUnsettled: false,
        includeSettled: false,
        matchedOriginalReceipt: false,
        notMatchedOriginalReceipt: false,
      },
      targetReportType: 'is_report',
      enableAccountingDataCreation: true,
      specifiedDateOfMonthRange: null,
      specifiedDateOfDay: null,
      specifiedDateOfLastDayOfMonth: false,
      executor: undefined,
      enableFileTransfer: true,
      fileTransferLocation: null,
      exportFormatAssignees: [
        {
          exportFormat: null,
          exportFileNameFormat: '',
        },
      ],
      enableBankDataCreation: true,
      companyExpenseAccountAssignees: [
        {
          companyExpenseAccount: null,
          exportFileName: '',
          paymentSpecificationType: null,
          paymentRelativeSpecificationType: null,
          paymentRelativeSpecificationDays: null,
          paymentSpecifiedDate: null,
        },
      ],
    },
  });
  const {
    formState: { isDirty, isValid },
  } = methods;

  const [processing, setProcessing] = useState(false);

  const handleClose = useCallback(() => {
    onClose();
  }, [onClose]);

  useEffect(() => {
    if (!show) {
      methods.reset();
    }
  }, [methods, show]);

  const handleCreate = useCallback((data) => {
    if (processing) return;

    const createApi = async (): Promise<void> => {
      try {
        setProcessing(true);
        const { message } = await Api.accountingDataScheduledExports.tasks.create(snakecaseKeys(data));
        flash.success(message);
        onCreated();
      } catch (error) {
        flash.error(getMessageFromResponse(error as Record<string, unknown>));
      } finally {
        setProcessing(false);
      }
    };

    createApi();
  }, [processing, setProcessing, onCreated]);

  return (
    <Modal show={ show } onHide={ handleClose } animation={ false } backdrop='static'>
      <Modal.Header closeButton>
        { i18next.t('accountingDataScheduledExports.tasks.createModal.title') }
      </Modal.Header>
      <FixedModalBody>
        <FormProvider { ...methods }>
          <FormLayout systemSetting={ systemSetting } offsetHeight={ 200 } />
        </FormProvider>
      </FixedModalBody>
      <ModalFooterView>
        <SaveButton onClick={ methods.handleSubmit(handleCreate) } processing={ processing } valid={ isDirty && isValid } />
        <CancelButton onClick={ handleClose } disabled={ processing } />
      </ModalFooterView>
    </Modal>
  );
};
