import { approvalFlowRuleFeature as prApprovalFlowRuleFeature } from "applications/payment_requests/specifications/useFeature";
import { ApprovalFlowRuleFeature, ApproverFeature } from "../types";

const useDkRequests = userPreferences.preference.useDkRequests;
const usePaymentRequest = userPreferences.preference.usePaymentRequest;
const inputGroup = userPreferences.preference.inputGroup;
const inputProject = userPreferences.preference.inputProject;

// 承認フロー条件設定機能のうち、ログインユーザが利用できる条件かどうかを返す
export function canUseApprovalFlowRuleFeature(
  feature: ApprovalFlowRuleFeature,
): boolean {
  if (!useDkRequests && usePaymentRequest) {
    return prApprovalFlowRuleFeature.includes(feature); // TOKIUMインボイスのみ利用する事業所おいて利用できる機能かどうかを返す
  }

  return true; // TOKIUMインボイスのみ利用する事業所以外は全ての機能を利用できる
}

// 承認フロー承認者追加機能のうち、ログインユーザが利用できる承認者種別かどうかを返す
export function canUseApproverFeature(feature: ApproverFeature): boolean {
  switch (feature) {
    case "payerDepartmentPosts":
      // TKが有効であれば費用負担部署の設定によって出し分ける
      // TKが無効の場合、TIの利用があれば表示する(費用負担部署の設定に関わらず請求書に費用負担部署を設定できるため)
      return (
        (useDkRequests && inputGroup) || (!useDkRequests && usePaymentRequest)
      );
    case "projectPosts":
      // プロジェクト役職に関して、プロジェクト利用設定によって出し分ける
      return (useDkRequests || usePaymentRequest) && inputProject;
    default:
      return true;
  }
}
