import { Button } from "components/renewaled_ui/buttons";
import i18next from "i18n";
import React from "react";

interface Props {
  onClick: () => void;
}

/** キャンセルボタン */
export const CancelButton: React.FC<Props> = ({ onClick }) => {
  return (
    <Button onClick={onClick} styleType="link-secondary">
      {i18next.t("commons.actions.cancel")}
    </Button>
  );
};
