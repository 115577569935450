import ApprovalFormSelectedApprovarsList from './ApprovalFormSelectedApprovarsList';
import ApproverSearchBox from './ApproverSearchBox';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import SimpleModal from 'components/SimpleModal';
import _get from 'lodash/get';
import i18next from 'i18n';
import {
  Alert,
  OverlayTrigger,
  Popover,
  Tab,
  Tabs,
} from 'react-bootstrap';
import { DepartmentPostRelationshipSelector } from './DepartmentPostRelationshipSelector';
import { canUseApproverFeature as canUseFeature } from '../specifications/useFeatures';
import { departmentWithAbsolutePath } from 'utilities/formatter';

export default class AddApproverModal extends Component {
  static TAB_USER_DEPARTMENT = 1;

  static TAB_PAYER_DEPARTMENT = 2;

  static TAB_DEPARTMENT = 3;

  constructor(props) {
    super(props);

    this.handleApproverCheck = this.handleApproverCheck.bind(this);
    this.handleLevelCheck = this.handleLevelCheck.bind(this);
    this.handleNearestPostsApproverCheck = this.handleNearestPostsApproverCheck.bind(this);
    this.handleChangeLevel = this.handleChangeLevel.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
  }

  handleApproverCheck(type, approvers, approver, e) {
    e.stopPropagation();
    this.props.checkApprover(type, approvers, approver.id, !approver.isChecked);
  }

  handleLevelCheck(approverType, e) {
    e.stopPropagation();
    this.props.checkUpperLevelSelector(approverType, e.target.checked);
  }

  handleNearestPostsApproverCheck(approverType, e) {
    e.stopPropagation();
    this.props.checkNearestPostsApprover(approverType, e.target.checked);
  }

  handleChangeLevel(approverType, e) {
    e.stopPropagation();
    this.props.onChangeLevel(approverType, e.target.value);
  }

  onSubmit() {
    const approvers = [];
    const {
      users, groups, userDepartmentPosts, projectPosts, payerDepartmentPosts,
    } = this.props.approvers;
    const postOption = {
      relativeLevel: this.props.relativeLevel,
    };
    const { selectedDepartmentPostRelationships } = this.props;

    users.forEach((user) => {
      if (user.isChecked) {
        approvers.push({ ...user, type: 'user' });
      }
    });

    groups.forEach((group) => {
      if (group.isChecked) {
        approvers.push({ ...group, type: 'group' });
      }
    });

    userDepartmentPosts.forEach((post) => {
      if (post.isChecked) {
        approvers.push({ ...post, ...postOption, type: 'company_post' });
      }
    });

    projectPosts.forEach((projectPost) => {
      if (projectPost.isChecked) {
        approvers.push({ ...projectPost, type: 'project_post' });
      }
    });

    selectedDepartmentPostRelationships.forEach((relationship) => {
      approvers.push({ ...relationship });
    });

    payerDepartmentPosts.forEach((payerPost) => {
      if (payerPost.isChecked) {
        approvers.push({ ...payerPost, ...postOption, type: 'payer_department_post' });
      }
    });

    if (!this.isUserDepartmentPostSelected && !this.isPayerDepartmentPostSelected) {
      this.props.onSubmit(approvers, {});
      return;
    }

    const optionType = this.isUserDepartmentPostSelected ? 'userDepartmentPosts' : 'payerDepartmentPosts';

    const options = {
      isUpperLevelPostSelecting: _get(this.props.options[optionType], 'isUpperLevelPostSelecting') || false,
      relativeLevel: _get(this.props.options[optionType], 'relativeLevel') || 0,
      nearestPostsApprover: _get(this.props.options[optionType], 'nearestPostsApprover') || false,
    };

    this.props.onSubmit(approvers, options);
  }

  get isUserDepartmentPostSelected() {
    return this.props.approvers.userDepartmentPosts.some((x) => x.isChecked);
  }

  get isPayerDepartmentPostSelected() {
    return this.props.approvers.payerDepartmentPosts.some((x) => x.isChecked);
  }

  renderTabs() {
    const { displayPost, approvers: { userDepartmentPosts, payerDepartmentPosts } } = this.props;

    if (!displayPost) {
      return (
        <Tabs id='approver-tab' defaultActiveKey={ 1 } animation={ false }>
          <Tab eventKey={ 1 } title={ i18next.t('approvals.flows.approver') }>{ this.renderApprovers('users') }</Tab>
          <Tab eventKey={ 2 } title={ i18next.t('approvals.flows.department') }>{ this.renderApprovers('groups') }</Tab>
        </Tabs>
      );
    }

    const payerDepSelected = this.isPayerDepartmentPostSelected;
    const userDepSelected = this.isUserDepartmentPostSelected;

    return (
      <Tabs id='approver-tab' defaultActiveKey={ this.defaultActiveKey(payerDepSelected, userDepSelected) } animation={ false }>
        { canUseFeature('userDepartmentPosts') && <Tab eventKey={ 1 } title={ i18next.t('approvals.flows.userDepartment') } disabled={ payerDepSelected }>{ this.renderApprovers('userDepartmentPosts') }</Tab> }
        { canUseFeature('payerDepartmentPosts') && <Tab eventKey={ 2 } title={ i18next.t('approvals.flows.payerDepartment') } disabled={ userDepSelected }>{ this.renderApprovers('payerDepartmentPosts') }</Tab> }
        { canUseFeature('groups') && <Tab eventKey={ 3 } title={ i18next.t('approvals.flows.department') }>{ this.renderApprovers('groups') }</Tab> }
        { canUseFeature('departmentPostRelationships') && <Tab eventKey={ 4 } title={ i18next.t('approvals.flows.departmentPost') }>{ this.renderApprovers('departmentPostRelationships') }</Tab> }
        { canUseFeature('projectPosts') && <Tab eventKey={ 5 } title={ i18next.t('approvals.flows.projectPost') }>{ this.renderApprovers('projectPosts') }</Tab> }
        { canUseFeature('users') && <Tab eventKey={ 6 } title={ i18next.t('approvals.flows.approver') }>{ this.renderApprovers('users') }</Tab> }
      </Tabs>
    );
  }

  defaultActiveKey(isPayerDepSelected, isUserDepSelected) {
    if (!isPayerDepSelected && !isUserDepSelected) {
      return AddApproverModal.TAB_DEPARTMENT;
    }
    if (isPayerDepSelected) {
      return AddApproverModal.TAB_PAYER_DEPARTMENT;
    }

    return AddApproverModal.TAB_USER_DEPARTMENT;
  }

  renderNearestPostsApproverInput(approverType) {
    const { options } = this.props;
    const isChecked = _get(options, `[${approverType}].nearestPostsApprover`, false);

    return (
      <div style={ { marginBottom: '8px' } }>
        <div style= { { marginBottom: '4px' } }>
          <label>
            <input type='checkbox'
              style={ { marginRight: '4px' } }
              checked={ isChecked }
              onChange={ this.handleNearestPostsApproverCheck.bind(null, approverType) }
            />
            <span style={ { fontWeight: 'normal' } }>
              { i18next.t(`approvals.flows.options.${approverType}.nearestPostsApproverSelecting`) }
            </span>
            <OverlayTrigger
              trigger={ ['hover', 'focus', 'click'] }
              placement='top'
              overlay={
                <Popover
                  id="approvalFlowNearestPostsApproverSelecting"
                  title= { i18next.t(`approvals.flows.description`) }
                >
                  { i18next.t(`approvals.flows.descriptions.nearestPostsApproverSelecting`) }
                </Popover>
              }>
              <i className='far fa-question-circle' style={ { color: '#AAA', marginLeft: '6px', cursor: 'pointer' } } />
            </OverlayTrigger>
          </label>
        </div>
      </div>
    );
  }

  renderLevelInput(approverType) {
    const { options } = this.props;
    const isChecked = options[approverType].isUpperLevelPostSelecting;
    const level = options[approverType].relativeLevel;

    // TODO CSSを整理
    //   暫定でstyleタグに直接書く
    return (
      <div style={ { marginBottom: '8px' } }>
        <div style= { { marginBottom: '4px' } }>
          <label>
            <input type='checkbox'
              style={ { marginRight: '4px' } }
              checked={ isChecked }
              onChange={ this.handleLevelCheck.bind(null, approverType) }
            />
            <span style={ { fontWeight: 'normal' } }>
              { i18next.t(`approvals.flows.options.${approverType}.upperLevelPostSelecting`) }
              <OverlayTrigger
                trigger={ ['hover', 'focus', 'click'] }
                placement='top'
                overlay={
                  <Popover
                    id="approvalFlowUpperLabalPostSelecting"
                    title={ i18next.t(`approvals.flows.description`) }
                  >
                    { i18next.t(`approvals.flows.descriptions.upperLevelPostSelecting`) }
                  </Popover>
                }>
                <i className='far fa-question-circle' style={ { color: '#AAA', marginLeft: '6px', cursor: 'pointer' } } />
              </OverlayTrigger>
            </span>
          </label>
        </div>
        {
          isChecked ? (
            <div>
              <input className='form-control'
                type='number'
                style={ { display: 'inline-block', width: '50px', marginRight: '4px' } }
                value={ level }
                min='1'
                max='5'
                onChange={ this.handleChangeLevel.bind(null, approverType) }
              />
              <span>{ i18next.t(`approvals.flows.specifiesDepartmentPosition`) }</span>
            </div>
          ) : null
        }
      </div>
    );
  }

  renderHeaderOption(type) {
    switch (type) {
      case 'userDepartmentPosts':
      case 'payerDepartmentPosts':
        return (
          <div>
            { this.renderLevelInput(type) }
            { this.renderNearestPostsApproverInput(type) }
          </div>
        );
      case 'users':
        return (
          <ApproverSearchBox
            reportIndex= { this.props.reportIndex }
            resetSearchText={ this.props.resetApproversUserSearchText }
            setSearchText={ this.props.setApproversUserSearchText }
            searchText={ this.props.approversSearchCondition.user.searchText }
            search={ this.props.searchApprovers.bind(null) }
            placeholder={ `${i18next.t('approvals.flows.approver')} または ${i18next.t('preferences.users.inputs.email')}` }/>
        );
      default:
        return null;
    }
  }

  renderApproveSelector(approver) {
    if (approver.email) {
      return (
        <div className='approve-selector__wrapper'>
          <div className='approve-selector__name'>{ approver.name }</div>
          <div className='approve-selector__mail' >{ approver.email }</div>
        </div>
      );
    }
    if (approver.absolutePath) {
      return (
        <div className='approve-selector__wrapper'>
          { departmentWithAbsolutePath(approver.absolutePath) }
        </div>
      );
    }
    return (
      <div className='approve-selector__name'>{ approver.name }</div>
    );
  }

  renderApprovers(type) {
    const approvers = this.props.approvers[type];

    switch (type) {
      case 'departmentPostRelationships':
        return (
          <div>
            <DepartmentPostRelationshipSelector
              updateButton={ this.renderSaveButton() }
              defaultPosts={ this.props.approvers.userDepartmentPosts }
              selectedDepartmentPostRelationships={ this.props.selectedDepartmentPostRelationships }
              onSelectDepartmentPostRelationship={ this.props.onSelectDepartmentPostRelationship }
              onDeselectDepartmentPostRelationship={ this.props.onDeselectDepartmentPostRelationship }
            />
          </div>
        );
      default:
        return (
          <div>
            { this.renderHeaderOption(type) }
            { this.renderSaveButton() }
            <div>
              {
                (approvers.length > 0) ? approvers.map((approver) => (
                  <div key={ approver.id }
                    className='approve-selector txt-pointer col-md-12'
                    onClick={ this.handleApproverCheck.bind(null, type, approvers, approver) }
                  >
                    { this.renderApproveSelector(approver) }
                    <input className='approve-selector__input' type='checkbox' id={ `check-${approver.id}` } checked={ approver.isChecked } readOnly/>
                  </div>
                )) : (
                  <Alert bsStyle='warning' className='col-md-12'>
                    { i18next.t('approvals.flows.noResults') }
                  </Alert>
                )
              }
            </div>
          </div>
        );
    }
  }

  renderSaveButton() {
    const button = {
      className: 'col-sm-offset-10 col-sm-2',
      color: 'primary',
      onClick: this.onSubmit,
      content: i18next.t('commons.actions.update'),
    };

    return (
      <button key={ `add-approver-update-button` }
        className={ `btn btn-${button.color} ${button.className}` }
        onClick={ button.onClick }
        disabled={ !!button.disabled }
        style={ { marginBottom: '10px' } }
      >
        { button.content }
      </button>
    );
  }

  render() {
    const buttons = [
      {
        className: 'col-sm-offset-10 col-sm-2',
        color: 'primary',
        onClick: this.onSubmit,
        content: i18next.t('commons.actions.update'),
      },
    ];

    return (
      <SimpleModal
        title={ i18next.t('approvals.flows.addApprover') }
        show={ this.props.show }
        close={ this.props.close }
        buttons={ buttons }
        dialogClassName='approver-dialog'
        bodyClassName='approver-modal col-md-12'
      >
        <ApprovalFormSelectedApprovarsList
          selectedApprovers={ this.props.selectedApprovers }
          onRemoveClick={ this.props.onRemoveSelectedApproverClick }
        />
        { this.renderTabs() }
      </SimpleModal>
    );
  }
}

AddApproverModal.defaultProps = {
  relativeLevel: 0,
  checkUpperLevelSelector() {},
  checkNearestPostsApprover() {},
  onChangeLevel() {},
  selectedDepartmentPostRelationships: [],
  onSelectDepartmentPostRelationship() {},
  onDeselectDepartmentPostRelationship() {},
};

AddApproverModal.propTypes = {
  show: PropTypes.bool.isRequired,
  close: PropTypes.func.isRequired,
  approvers: PropTypes.shape({
    users: PropTypes.array.isRequired,
    groups: PropTypes.array.isRequired,
    userDepartmentPosts: PropTypes.array.isRequired,
    projectPosts: PropTypes.array.isRequired,
    payerDepartmentPosts: PropTypes.array.isRequired,
  }).isRequired,
  options: PropTypes.shape({
    userDepartmentPosts: PropTypes.object.isRequired,
    payerDepartmentPosts: PropTypes.object.isRequired,
  }).isRequired,
  displayPost: PropTypes.bool.isRequired,
  isUpperLevelPostSelecting: PropTypes.bool,
  relativeLevel: PropTypes.number,
  checkApprover: PropTypes.func.isRequired,
  checkUpperLevelSelector: PropTypes.func,
  onChangeLevel: PropTypes.func,
  checkNearestPostsApprover: PropTypes.func,
  onSubmit: PropTypes.func.isRequired,
  selectedApprovers: PropTypes.array,
  onRemoveSelectedApproverClick: PropTypes.func,
  /** 選択中の部署役職一覧 */
  selectedDepartmentPostRelationships: PropTypes.array,
  /** 部署役職選択欄で部署役職選択した場合 */
  onSelectDepartmentPostRelationship: PropTypes.func,
  /** 部署役職選択欄で部署役職選択解除した場合 */
  onDeselectDepartmentPostRelationship: PropTypes.func,
};
