import i18next from "i18n";
import React, { FC } from "react";
import { BootstrapTable, TableHeaderColumn } from "react-bootstrap-table";
import { PersonalCategory } from "utilities/api/models";

interface Props {
  /** 表示するカテゴリ */
  categories: Array<PersonalCategory>;
  /** カテゴリの有効無効ボタンを押されたときのアクション */
  onCheckboxClicked: (caterogy: PersonalCategory) => void;
  /** 何かしらの処理を行っているカテゴリIDのリスト */
  inProcesses?: Array<string>;
}

const PersonalCategoriesTable: FC<Props> = ({
  categories = [],
  onCheckboxClicked,
  inProcesses = [],
}) => {
  const options = {
    noDataText: i18next.t("preferences.personalCategories.noData"),
  };

  return (
    <BootstrapTable data={categories} options={options} striped={true}>
      <TableHeaderColumn
        isKey={true}
        hidden={true}
        dataField="id"
      ></TableHeaderColumn>
      <TableHeaderColumn hidden={true} dataField="sort"></TableHeaderColumn>
      <TableHeaderColumn
        dataField="enable"
        width="50"
        dataFormat={(
          enable: boolean,
          row: PersonalCategory,
          _data: Array<PersonalCategory>,
          index: number,
        ): React.ReactElement => {
          let parentId = row.parentId;
          const pearentIds: Array<string> = categories
            .slice(0, index)
            .reverse()
            .map((category): string => {
              if (parentId === null) {
                return "";
              }
              if (category.id === parentId) {
                parentId = category.parentId;
                return category.id;
              }
              return "";
            })
            .filter((v) => v !== "");

          return (
            <input
              type="checkbox"
              checked={enable}
              onChange={onCheckboxClicked.bind(null, row)}
              disabled={
                inProcesses.includes(row.id) ||
                pearentIds.map((v) => inProcesses.includes(v)).includes(true)
              }
            />
          );
        }}
        dataAlign="center"
      >
        {i18next.t("preferences.personalCategories.valid")}
      </TableHeaderColumn>
      <TableHeaderColumn
        dataField="name"
        dataFormat={(
          name: string,
          row: PersonalCategory,
        ): React.ReactElement => (
          <div style={{ textIndent: row.level * 15 }}>{name}</div>
        )}
      >
        {i18next.t("preferences.personalCategories.accountTitle")}
      </TableHeaderColumn>
    </BootstrapTable>
  );
};

export default PersonalCategoriesTable;
