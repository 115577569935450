import qs from "qs";
import { useEffect, useState } from "react";
import Api from "utilities/api";
import * as RequestsRequests from "utilities/api/requests/requests";
import * as ResponsesRequests from "utilities/api/responses/requests";
import { snakecaseKeys } from "utilities/Utils";

interface UseReturn {
  /** 検索結果件数 */
  readonly count: number;
  /** 現在の申請書が何番目か */
  readonly current: number;
  /** 前の申請書へのリンク */
  readonly prevLink: string | null;
  /** 次の申請書へのリンク */
  readonly nextLink: string | null;
  /** ローディング中かどうか */
  readonly processing: boolean;
  /** 隣接する申請書があるかどうか */
  readonly hasNeighbors: boolean;
}

type ReportType = "Report" | "PreReport" | "Application";
type RequestType = "report" | "pre_report" | "application";

/**
 * 隣接する申請書へのリンクを生成する
 * @param id 申請書ID
 * @param type 申請書の種類
 * @param forAttr 申請者か承認者か
 * @param searchQuery 検索条件
 * @returns
 */
const generateNeighborLink = (
  id: string | null,
  type: ReportType | null,
  forAttr: "list" | "approver" | "admin",
  searchQuery: string,
): string | null => {
  if (!id) {
    return null;
  }

  // 承認画面の場合は approving_ が付与される
  const approving =
    forAttr === "approver" || forAttr === "admin" ? "approving_" : "";

  switch (type) {
    case "Report":
      return `/${approving}reports/${id}${searchQuery}`;
    case "PreReport":
      return `/${approving}pre_reports/${id}${searchQuery}`;
    case "Application":
      return `/${approving}applications/${id}${searchQuery}`;
    default:
      return "";
  }
};

/**
 * 隣接する申請書を検索するためのAPIを選択する
 * @param requestType 申請フォーム
 * @returns
 */
const selectNeighborsApi = (
  requestType: RequestType,
): ((
  params: RequestsRequests.NeighborsRequest,
) => Promise<ResponsesRequests.NeighborsResponse>) => {
  switch (requestType) {
    case "report":
      return Api.requests.reports.neighbors;
    case "pre_report":
      return Api.requests.preReports.neighbors;
    case "application":
      return Api.requests.applications.neighbors;
    default:
      throw new Error("申請フォーム種別を判別できません");
  }
};

/**
 * 隣接する申請書を検索するためのフック
 * @param currentId 現在の申請書ID
 */
export const useHooks = (currentId: string): UseReturn => {
  const [processing, setProcessing] = useState(false);
  const [current, setCurrent] = useState(0);
  const [count, setCount] = useState(0);
  const [nextLink, setNextLink] = useState<string | null>(null);
  const [prevLink, setPrevLink] = useState<string | null>(null);
  const [hasNeighbors, setHasNeighbors] = useState(false);

  const searchQuery = window.location.search;

  const fetchNeighbors = async (id: string): Promise<void> => {
    setProcessing(true);

    try {
      // ライブラリの更新によってqsに型情報が含まれるようになったが実装の変更を行うことが難しいため、一時的にanyで対応
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      const searchCondition: any = qs.parse(searchQuery, {
        ignoreQueryPrefix: true,
      });
      const requestType = searchCondition.request_type as RequestType;
      delete searchCondition.request_type;

      const neighborsApi = selectNeighborsApi(requestType);
      const data = await neighborsApi(
        snakecaseKeys({ id, ...searchCondition }),
      );
      setCount(data.count);
      setCurrent(data.current);
      setNextLink(
        generateNeighborLink(
          data.nextId,
          data.nextType,
          searchCondition.for,
          searchQuery,
        ),
      );
      setPrevLink(
        generateNeighborLink(
          data.prevId,
          data.prevType,
          searchCondition.for,
          searchQuery,
        ),
      );
      setHasNeighbors(true);
    } catch (e) {
      // 何もしない
    } finally {
      setProcessing(false);
    }
  };

  useEffect(() => {
    if (searchQuery.length > 0) {
      fetchNeighbors(currentId);
    } else {
      setHasNeighbors(false);
    }
  }, [currentId]);

  return {
    count,
    current,
    nextLink,
    prevLink,
    processing,
    hasNeighbors,
  };
};
