import * as ActionTypes from '../actions/expenseAccount';
import isNil from 'lodash/isNil';

const initialForm = {
  id: null,
  bank: {
    name: null,
  },
  bankBranch: {
    name: null,
  },
  type: '普通',
  number: null,
  holderKana: null,
  customerCode: null,
  editable: true,
};

const initialTransferFee = {
  id: null,
  feeThreshold: 0,
  eqOrHeigherFee: 0,
  lowerFee: 0,
};

const initialTransferFees = () => {
  return [
    {
      index: 0,
      ...initialTransferFee,
      type: 'TransferFees::SameBank',
    },
    {
      index: 1,
      ...initialTransferFee,
      type: 'TransferFees::SameBankBranch',
    },
    {
      index: 2,
      ...initialTransferFee,
      type: 'TransferFees::OtherBank',
    },
  ];
};


const initialState = {
  expenseAccounts: [],
  selectedAccount: initialForm,
  selectedAccountTransferFees: initialTransferFees(),
  banks: {
    total: [],
    current: [],
  },
  branches: {
    total: [],
    current: [],
  },
  inProcess: false,
  toggle: {
    expenseAccountModal: false,
    deleteConfirmationModal: false,
    isExpenseAccountDeleteConfirmationModalOpen: false,
  },
  userId: null,
  tabKey: 'account',
};

const expenseAccount = (state = initialState, action) => {
  switch (action.type) {
    case ActionTypes.SET_EXPENSE_ACCOUNTS:
      return {
        ...state,
        expenseAccounts: action.data,
      };
    case ActionTypes.SET_ACCOUNT_EDITABLE:
      return {
        ...state,
        selectedAccount: {
          ...state.selectedAccount,
          editable: action.editable,
        },
      };
    case ActionTypes.SELECT_ACCOUNT:
      return {
        ...state,
        selectedAccount: isNil(action.data) ? initialForm : { ...action.data },
        selectedAccountTransferFees: (isNil(action.data?.transferFees) || action.data.transferFees.length === 0) ? initialTransferFees() : action.data.transferFees.map((d, index) => ({ ...d, index })),
      };
    case ActionTypes.SET_USER_ID:
      return {
        ...state,
        userId: action.value,
      };
    case ActionTypes.SET_FORM_DATA:
      return {
        ...state,
        selectedAccount: {
          ...state.selectedAccount,
          [action.key]: action.value,
        },
      };
    case ActionTypes.SET_TRANSFER_FEES:
      return {
        ...state,
        selectedAccountTransferFees: action.value,
      };
    // AutoSuggest
    case ActionTypes.INIT_SUGGESTIONS:
      return {
        ...state,
        [action.key]: {
          total: action.data,
          current: [...action.data],
        },
      };
    case ActionTypes.UPDATE_SUGGESTIONS:
      return {
        ...state,
        [action.key]: {
          total: state[action.key].total,
          current: [...action.data],
        },
      };
    case ActionTypes.RESET_SUGGESTIONS:
      return {
        ...state,
        [action.key]: {
          total: state[action.key].total,
          current: [...state[action.key].total],
        },
      };
    case ActionTypes.TOGGLE_FORM_BUTTON:
      return {
        ...state,
        inProcess: action.disabled,
      };
    case ActionTypes.TOGGLE_EXPENSE_ACCOUNT_MODAL:
      return {
        ...state,
        toggle: {
          ...state.toggle,
          expenseAccountModal: action.value,
        },
      };
    case ActionTypes.TOGGLE_DELETE_CONFIRMATION_MODAL:
      return {
        ...state,
        toggle: {
          ...state.toggle,
          deleteConfirmationModal: action.value,
        },
      };
    case ActionTypes.TOGGLE_EXPENSE_ACCOUNT_DELETE_CONFIRMATION_MODAL:
      return {
        ...state,
        toggle: {
          ...state.toggle,
          isExpenseAccountDeleteConfirmationModalOpen: action.show,
        },
      };
    case ActionTypes.SET_TAB_KEY:
      return {
        ...state,
        tabKey: action.value,
      };
    default:
      return state;
  }
};

export default expenseAccount;
