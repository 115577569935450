import i18next from "i18n";
import isNil from "lodash/isNil";
import PropTypes from "prop-types";
import React, { Component } from "react";
import formatter from "utilities/formatter";
import { textToLink } from "utilities/Utils";

export default class Timeline extends Component {
  constructor(props) {
    super(props);

    this.setContentRef = this.setContentRef.bind(this);
  }

  setContentRef(ref) {
    this.contentRef = ref;
  }

  componentDidMount() {
    this.scrollToBottom();
  }

  componentDidUpdate() {
    this.scrollToBottom();
  }

  scrollToBottom() {
    const content = this.contentRef;
    if (!isNil(content)) {
      content.scrollTop = content.scrollHeight;
    }
  }

  renderApproval(approval) {
    return (
      <div key={approval.id} className="timeline-card-row pending disabled">
        <div className="icon">
          <i className="fa" />
        </div>
        <div className="content">
          <p className="title">{approval.label}</p>
        </div>
      </div>
    );
  }

  renderEvent(evt) {
    return (
      <div className={`timeline-card-row ${evt.type}`} key={evt.id}>
        <div className="icon">
          <i className="fa" />
        </div>
        <div className="content">
          <p className="title">{evt.title}</p>
          <p className="body">
            {evt.attachments.length > 0 ? (
              <li>
                <a
                  href={`/attachments/${evt.attachments[0].id}?request_id=${evt.requestId}`}
                >
                  {evt.attachments[0].fileName}
                </a>
              </li>
            ) : (
              textToLink(evt.body)
                .split(/\r|\n|\r\n/g)
                .map((x, i) => (
                  <span key={i}>
                    {
                      // eslint-disable-next-line react/no-danger
                      <span dangerouslySetInnerHTML={{ __html: x }} />
                    }
                    <br />
                  </span>
                ))
            )}
          </p>
        </div>
        <div className="created-at">
          <p className="txt txt-sm">{formatter.datetime(evt.createdAt, "")}</p>
        </div>
      </div>
    );
  }

  render() {
    const { style, requestEvents, remainingApprovals } = this.props;
    // 英語表記の場合にレポートの種類によって this.props.reportStatus が日本語か英語かが異なるため、両方を考慮する
    const ja = i18next.getFixedT("ja");
    const en = i18next.getFixedT("en");
    const toShowMarkerStatus = [
      ja("commons.status.applying"),
      en("commons.status.applying"),
      ja("commons.status.reapplying"),
      en("commons.status.reapplying"),
    ];
    return (
      <div ref={this.setContentRef} style={style}>
        <div className="request-event-list">
          {requestEvents.map(this.renderEvent)}
        </div>
        {remainingApprovals &&
        remainingApprovals.length > 0 &&
        toShowMarkerStatus.find(
          (status) => status === this.props.reportStatus,
        ) !== undefined
          ? [
              <div key="current-marker" className="current-marker">
                <hr className="line" />
                <p className="message">
                  {i18next.t("reports.timeline.markerMessage")}
                </p>
              </div>,
              <div key="approvals" className="remaining-approval-list">
                {remainingApprovals.map(this.renderApproval)}
              </div>,
            ]
          : null}
      </div>
    );
  }
}

Timeline.defaultProps = {
  requestEvents: [],
  remainingApprovals: [],
};

Timeline.propTypes = {
  style: PropTypes.object,
  reportStatus: PropTypes.string,
  requestEvents: PropTypes.array,
  remainingApprovals: PropTypes.array,
};
