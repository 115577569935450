import ApproverForm from "applications/approval_flows/approver_form";
import ToggleButton from "components/toggle_button";
import i18next from "i18n";
import PropTypes from "prop-types";
import React, { Component } from "react";
import { Modal } from "react-bootstrap";
import flash from "utilities/flash";
import * as formatter from "utilities/formatter";

export default class PreReportRequestModal extends Component {
  onSubmit() {
    this.props.onSubmit(
      this.refs.approverForm.getApprovals(),
      this.onError.bind(this),
    );
  }

  onError(error) {
    flash.error(error);
    this.refs.button.activate();
  }

  renderRow(label, contents) {
    return (
      <tr className="row">
        <td className="col-sm-3">{label}</td>
        <td className="col-sm-10">{contents}</td>
      </tr>
    );
  }

  renderAmount(preReport) {
    const { amount, editable } = preReport;

    if (!editable) {
      return this.renderRow("総額", formatter.amount(amount));
    }

    return null;
  }

  renderPreAmount(preReport) {
    const { preAmount } = preReport;

    return this.renderRow("事前申請総額", formatter.amount(preAmount));
  }

  render() {
    const { approvalFlow, title, comment, preReport, requestState } =
      this.props;
    return (
      <Modal show={this.props.showModal} onHide={this.props.closeModal}>
        <Modal.Header closeButton>
          <Modal.Title>事前申請</Modal.Title>
        </Modal.Header>
        <Modal.Body bsClass="request-modal">
          <div className="card request-page">
            <table className="table table-bordered border-side">
              <tbody>
                {this.renderRow("申請名", title)}
                {this.renderRow(
                  "コメント",
                  <div className="form-group">
                    <input
                      type="text"
                      placeholder={i18next.t("reports.requests.enterComment")}
                      value={comment}
                      onChange={(e) =>
                        this.props.onCommentChange(e.target.value)
                      }
                      className="form-control"
                    />
                  </div>,
                )}
                {this.renderAmount(preReport)}
                {this.renderPreAmount(preReport)}
                {this.renderRow(
                  "承認フロー",
                  approvalFlow ? (
                    <ApproverForm
                      ref="approverForm"
                      approvalFlow={approvalFlow}
                      isEdit={false}
                    />
                  ) : null,
                )}
              </tbody>
            </table>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <div className="text-left">
            <ToggleButton
              ref="button"
              className="btn btn-accent col-sm-6 col-sm-offset-3"
              onClick={this.onSubmit.bind(this)}
            >
              {requestState.isJobRunning ? (
                <i className="fa fa-spinner fa-spin"></i>
              ) : (
                ""
              )}
              {i18next.t("commons.actions.sendReport")}
            </ToggleButton>
          </div>
        </Modal.Footer>
      </Modal>
    );
  }
}

PreReportRequestModal.propTypes = {
  showModal: PropTypes.bool.isRequired,
  approvalFlow: PropTypes.object,
  comment: PropTypes.string,
  title: PropTypes.string,
  preReport: PropTypes.shape({
    amount: PropTypes.number,
    preAmount: PropTypes.number,
    editable: PropTypes.bool,
  }),
  onCommentChange: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  closeModal: PropTypes.func.isRequired,
};
