import S3UploadError from './S3UploadError';

// S3Uploaderが投げる例外クラス
// 複数枚を一括送信した際に、どのファイルで失敗したかどうかを保持しておくために使用する
export default class S3UploadBatchError extends Error {
  public name: string;

  constructor(public message: string, public stage: string, public uploadErrors: S3UploadError[]) {
    super(message);

    this.name = this.constructor.name;
    this.stage = stage;
    this.uploadErrors = uploadErrors;

    if (typeof Error.captureStackTrace === 'function') {
      Error.captureStackTrace(this, this.constructor);
    } else {
      this.stack = (new Error(message)).stack;
    }
  }
}
