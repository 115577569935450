import LabeledComponent from 'components/LabeledComponent';
import React, { FC } from 'react';

interface Props {
  className: string;
  label: string;
  labelClassName: string;
}

const SettingLabelForm: FC<Props> = (props) => {
  return (
    <LabeledComponent className={ 'form-group' }
      labelClass={ props.labelClassName }
      label={ props.label }
    >
      <hr className={ props.className }/>
    </LabeledComponent>
  );
};

export default SettingLabelForm;
