import React from "react";
import { Popover } from "react-bootstrap";
import { Expense } from "utilities/api/models";
import { IconWithOverlay } from "./IconWithOverlay";

type Props = Pick<Expense, "comment">;

/** メモアイコン */
export const CommentIconWithOverlay: React.FC<Props> = ({ comment }) => {
  if (!comment) return null;

  const commentOverlay = (): JSX.Element => {
    return <Popover id="comment-popup">{comment}</Popover>;
  };

  const formatComment = (): JSX.Element => {
    return (
      <IconWithOverlay
        overlay={commentOverlay()}
        icon={"far fa-comment-dots"}
      />
    );
  };

  return <div>{formatComment()}</div>;
};
