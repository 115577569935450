import Placeholder from "components/renewaled_ui/FileViewer/Viewer/Other/FileView/parts/Placeholder";
import { FileLoadStatus } from "components/types/attachedFiles.types";
import React from "react";

interface Props {
  readonly loadStatus: FileLoadStatus;
  readonly showPlaceholderDescription?: boolean;
}

/**
 * ビューアーのエラー表示
 */
const ViewerMessage: React.FC<Props> = (p) => {
  switch (p.loadStatus.state) {
    // ファイルが見つからない
    case "notFound": {
      return (
        <Placeholder
          type="notFound"
          showDescription={p.showPlaceholderDescription}
        />
      );
    }
    case "loaded":
    case "loading": {
      return <React.Fragment />;
    }
    // ファイルの読み込みエラー
    case "failedToLoad":
    case "failedToShow": {
      return (
        <Placeholder
          type="error"
          showDescription={p.showPlaceholderDescription}
        />
      );
    }
    // ファイルが存在しない
    case "notExist": {
      return (
        <Placeholder
          type="notExist"
          showDescription={p.showPlaceholderDescription}
        />
      );
    }
    default: {
      return (
        <Placeholder
          type="default"
          showDescription={p.showPlaceholderDescription}
        />
      );
    }
  }
};

export default ViewerMessage;
