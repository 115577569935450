import React from 'react';
import i18next from 'i18n';
import { Button } from 'components/renewaled_ui/buttons/Button';

interface Props {
  onClick: () => void;
  disabled: boolean;
  show?: boolean;
}

export const EditButton: React.FC<Props> = ({
  onClick,
  disabled,
  show = true,
}) => {
  return (
    <Button onClick={ onClick } disabled={ disabled } show={ show }>
      { i18next.t('commons.actions.edit') }
    </Button>
  );
};
