import { RemoteReceiptFile } from "components/types/attachedFiles.types";
import i18next from "i18n";
import React, { useMemo } from "react";

interface Props {
  file: RemoteReceiptFile;
  index: number;
  count: number;
}

const RESULT = {
  OK: "OK",
  NG: "NG",
  PROCESSING: "PROCESSING",
} as const;

const OK = RESULT.OK;
const NG = RESULT.NG;
const PROCESSING = RESULT.PROCESSING;

const RESOLUTION_CHECK_DPI_LIMIT = 200;
const RESOLUTION_CHECK_PIXELS_WIDTH_LIMIT = 1654;
const RESOLUTION_CHECK_PIXELS_HEIGHT_LIMIT = 2338;

const ResolutionInformationItem: React.FC<Props> = (props) => {
  const { file, index, count } = props;

  const displayVersion = (count || 0) - index;

  // 階調は、2 * bpsの階乗となる
  const gradationNum = (bps): number => 2 ** bps;

  const checkSatisfied = useMemo(() => {
    // 画像自体はアップロードされたが、まだ解像度検証が完了していない時
    if (file.resolutionInformations?.length === 0) return PROCESSING;

    return file.resolutionInformations.every((info) => info.satisfied)
      ? OK
      : NG;
  }, [file]);

  const isAllResultDigital = useMemo(() => {
    return file.resolutionInformations.every((info) => !info.colorspace);
  }, [file]);

  // UI上に表示するのは1件という仕様のため、「解像度」「DPI」それぞれの最小値を抽出している。もし今後、全件表示する事となった場合は、UIから見直し
  const resolutionDetail = useMemo(() => {
    const titleWhenAreaDimension = i18next.t(
      "transactions.properties.resolutionInformation.areaDimension",
    );
    const titleWhenDPI = i18next.t(
      "transactions.properties.resolutionInformation.dpi",
    );

    // 画像自体はアップロードされたが、まだ解像度検証が完了していない時
    if (file.resolutionInformations?.length === 0) return "";

    // 1つの領収書に紐づくresolutionInformationsのうち、最もDPIが小さいものを抽出している
    const minDPIInfo = file.resolutionInformations.reduce((a, b) => {
      if (a.dpi === null) {
        return b;
      }
      if (b.dpi === null) {
        return a;
      }

      const Awidth = a.dpi.width;
      const Ahegiht = a.dpi.height;
      const AareaDimension = Awidth * Ahegiht;

      const Bwidth = b.dpi.width;
      const Bhegiht = b.dpi.height;
      const BareaDimension = Bwidth * Bhegiht;

      if (AareaDimension < BareaDimension) {
        return a;
      }

      return b;
    });

    // 1つの領収書に紐づくresolutionInformationsのうち、最も面積が小さいものを抽出している
    const minPixelsInfo = file.resolutionInformations.reduce((a, b) => {
      if (a.pixels === null) {
        return b;
      }
      if (b.pixels === null) {
        return a;
      }

      const Awidth = a.pixels.width;
      const Ahegiht = a.pixels.height;
      const AareaDimension = Awidth * Ahegiht;

      const Bwidth = b.pixels.width;
      const Bhegiht = b.pixels.height;
      const BareaDimension = Bwidth * Bhegiht;

      if (AareaDimension < BareaDimension) {
        return a;
      }

      return b;
    });

    // アップされたPDFの「すべて」が電子領収書と判定されるとき
    if (minPixelsInfo.pixels === null || minDPIInfo === null) return null;

    const pixelsSatisfied =
      (minPixelsInfo.pixels.width >= RESOLUTION_CHECK_PIXELS_WIDTH_LIMIT &&
        minPixelsInfo.pixels.height >= RESOLUTION_CHECK_PIXELS_HEIGHT_LIMIT) ||
      (minPixelsInfo.pixels.height >= RESOLUTION_CHECK_PIXELS_WIDTH_LIMIT &&
        minPixelsInfo.pixels.width >= RESOLUTION_CHECK_PIXELS_HEIGHT_LIMIT); // 縦と横を反転させて画素数を満たしている場合はtrueとする
    const dpiSatisfied =
      minDPIInfo.dpi.width >= RESOLUTION_CHECK_DPI_LIMIT &&
      minDPIInfo.dpi.height >= RESOLUTION_CHECK_DPI_LIMIT;

    // 解像度要件のうち、「DPIは満たしていないが画素数は満たしている時」には画素数の情報を返す
    if (pixelsSatisfied && !dpiSatisfied) {
      const width = minPixelsInfo.pixels.width;
      const height = minPixelsInfo.pixels.height;
      const areaDimension = width * height;

      return `${titleWhenAreaDimension}: ${width.toLocaleString()} × ${height.toLocaleString()} (${areaDimension.toLocaleString()})`;
    }

    const roundedDPIWidth = minDPIInfo.dpi.width.toFixed(2);
    const roundedDPIiHeight = minDPIInfo.dpi.height.toFixed(2);

    // DPIの情報を返す。縦と横のDPI値が同一の時
    if (roundedDPIWidth === roundedDPIiHeight) {
      return `${titleWhenDPI}: DPI ${roundedDPIWidth}`;
    }

    // DPIの情報を返す。縦と横のDPI値が異なる時
    return `${titleWhenDPI}: DPI ${roundedDPIWidth} × ${roundedDPIiHeight}`;
  }, [file]);

  // UI上に表示するのは1件という仕様のため、「色階調I」は最小値を抽出している。もし今後、全件表示する事となった場合は、UIから見直し
  const formatColorspace = useMemo(() => {
    // 画像自体はアップロードされたが、まだ解像度検証が完了していない時
    if (file.resolutionInformations.length === 0) return "";

    // アップされたPDFの「すべて」が電子領収書と判定されるとき
    if (file.resolutionInformations.every((info) => !info.colorspace))
      return null;

    // 1つの領収書に紐づく resolutionInformationsのうち、最もbitPerSampleが小さいものを抽出している
    const bps = file.resolutionInformations.reduce((a, b) => {
      if (a.colorspace === null) {
        return b;
      }
      if (b.colorspace === null) {
        return a;
      }

      return a.colorspace?.bitPerSample < b.colorspace?.bitPerSample ? a : b;
    }).colorspace?.bitPerSample;

    if (bps)
      return (
        <span>
          {`${gradationNum(bps).toString()}${i18next.t(
            "transactions.properties.resolutionInformation.colorSpaceUnit",
          )}`}{" "}
        </span>
      );

    return i18next.t("transactions.properties.resolutionInformation.noData");
  }, [file]);

  return (
    <li className="side-detail-item">
      <p className="ver-text">
        Ver. {displayVersion}：
        {checkSatisfied === "OK" && (
          <span>
            OK <i className="fas fa-check text-success" />
          </span>
        )}
        {checkSatisfied === "NG" && (
          <span>
            NG <i className="fas fa-times text-danger" />
          </span>
        )}
      </p>
      <div className="info-area">
        {!isAllResultDigital ? (
          <>
            <p>
              {checkSatisfied === "OK" && (
                <span>
                  {resolutionDetail}
                  <i className="fas fa-check text-success" />
                </span>
              )}
              {checkSatisfied === "NG" && (
                <span>
                  {resolutionDetail}
                  <i className="fas fa-times text-danger" />
                </span>
              )}
              {checkSatisfied === "PROCESSING" && <></>}
            </p>
            <p>
              {i18next.t(
                "transactions.properties.resolutionInformation.colorSpaceLabel",
              )}
              ：{formatColorspace}
            </p>
          </>
        ) : (
          <>
            <p>
              {i18next.t(
                "transactions.properties.resolutionInformation.digital",
              )}
            </p>
          </>
        )}
      </div>
    </li>
  );
};

export default ResolutionInformationItem;
