import React, { useCallback, useState } from 'react';
import styled from 'styled-components';
import {
  ControlLabel,
  FormControl,
  FormGroup,
  HelpBlock,
} from 'react-bootstrap';
import { Controller, FieldError, FieldErrors } from 'react-hook-form';

interface FormProps {
  inlineForm: boolean;
}

const FormGroupView = styled(FormGroup)<FormProps>`
  display: ${(props): string => (props.inlineForm ? 'flex' : 'block')};
  align-items: ${(props): string => (props.inlineForm ? 'center' : 'normal')};
  .control-label {
    ${(props): string => (props.inlineForm ? 'width: 140px' : '')};
  }

  .form-control:focus {
    outline: none;
    box-shadow: none;
    border: 2px solid #004acc;
    background-color: #ffffff;
  }
  &.has-error .form-control {
    border-color: #d9534f;
  }
  &.has-error .form-control:focus {
    border: 2px solid #d9534f;
  }
  &.has-error .control-label {
    color: #d9534f;
  }
  &.has-error .form-control-feedback {
    color: #d9534f;
  }
  &.has-error .help-block {
    color: #d9534f;
  }
`;

interface Props {
  type?: string;
  name: string;
  label?: JSX.Element;
  feedback?: boolean,
  rules: Record<string, unknown>;
  error?: FieldError;
  control,
  autoComplete?: string;
  disabled?: boolean;
  readOnly?: boolean;
  placeholder?: string;
  defaultValue?: string | null;
  componentClass?: 'select' | 'textarea';
  max?: string;
  inlineForm?: boolean; // inlineFormがtrueの場合、ラベルとフォームが横並びになります。
}

type ValidationState = 'error' | 'warning' | null | undefined;

const validationState = (error: FieldErrors | undefined): ValidationState => {
  return error ? 'error' : null;
};

export const InputField: React.FC<Props> = ({
  type = 'text',
  name,
  label,
  rules,
  feedback = false,
  control,
  autoComplete = 'off',
  disabled = false,
  readOnly = false,
  placeholder = '',
  children,
  defaultValue = '',
  componentClass,
  max = '',
  inlineForm = false,
}) => {
  const [ref, setRef] = useState<HTMLInputElement>();

  // Chrome の authcomplete='new-password' が機能しない問題に対応
  const preventAutoComplete = useCallback((e: React.MouseEvent<FormControl & HTMLInputElement>): void => {
    if (type !== 'password') {
      return;
    }
    setTimeout(() => ref && ref.focus(), 10);
    e.preventDefault();
  }, [ref, type]);

  return (
    <Controller
      name={ name }
      control={ control }
      rules={ rules }
      defaultValue={ defaultValue || '' }
      render={ ({ field, fieldState: { error } }): React.ReactElement => (
        <FormGroupView validationState={ validationState(error) } inlineForm={ inlineForm }>
          { label && <ControlLabel>{ label }</ControlLabel> }
          <FormControl
            { ...field }
            value={ field.value?.toString() || '' }
            type={ type }
            autoComplete={ autoComplete }
            disabled={ disabled }
            readOnly={ readOnly }
            placeholder={ placeholder }
            componentClass={ componentClass }
            inputRef={ (r): void => setRef(r) }
            onMouseDown={ preventAutoComplete }
            max={ max }
          >
            { children }
          </FormControl>
          { feedback && <FormControl.Feedback /> }
          { error && <HelpBlock>{ error.message }</HelpBlock> }
        </FormGroupView>
      ) }
    />
  );
};
