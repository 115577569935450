import * as actions from '../actions/analysisReportExportData';
import ExportForm from '../components/ExportForm';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import Timer from 'utilities/timer';
import i18next from 'i18n';
import isNil from 'lodash/isNil';
import { connect } from 'react-redux';

export class AccountingExportForm extends Component {
  constructor(props) {
    super(props);

    this.pollingAccountingJobs();

    this.onRequestDownload = this.onRequestDownload.bind(this);
    this.pollingAccountingJobs = this.pollingAccountingJobs.bind(this);
    this.stopPollingAccountingJobs = this.stopPollingAccountingJobs.bind(this);
  }

  componentWillUnmount() {
    this.stopPollingAccountingJobs();
  }

  pollingAccountingJobs() {
    const { loadAccountingJobs } = this.props;

    this.timer = new Timer(3000, [loadAccountingJobs]);
    this.timer.start();
  }

  stopPollingAccountingJobs() {
    if (!isNil(this.timer)) {
      this.timer.stop();
    }
  }

  onRequestDownload() {
    const {
      requestAccountingJobs, loadAccountingJobs, formatKey, jobName, targetKey, targetId, transactedAt,
    } = this.props;
    requestAccountingJobs(formatKey, jobName, targetKey, targetId, transactedAt);
    loadAccountingJobs();
  }

  onDownloadClick(e, url) {
    e.stopPropagation();
    window.location.href = url;
  }

  render() {
    const { analysisReportExportData } = this.props;

    return (
      <ExportForm
        data={ analysisReportExportData.accountingJobs }
        onRequestExport={ this.onRequestDownload }
        requestButtonTitle={ i18next.t('exports.inputs.requestAccountingCsv') }
        setAnalysisFileExportFlag={ this.props.setAnalysisFileExportFlag }
      />
    );
  }
}

AccountingExportForm.propTypes = {
  analysisReportExportData: PropTypes.shape({
    AccountingJobs: PropTypes.array.isRequired,
  }),
  formatKey: PropTypes.string,
  jobName: PropTypes.string,
  loadAccountingJobs: PropTypes.func.isRequired,
  requestAccountingJobs: PropTypes.func.isRequired,
  targetKey: PropTypes.string,
  targetId: PropTypes.string,
  transactedAt: PropTypes.string,
};

function mapStateToProps(state, ownProps) {
  const { analysisReportExportData } = state;
  return {
    analysisReportExportData,
  };
}

function mapDispatchToProps(dispatch, ownProps) {
  return {
    loadAccountingJobs() {
      dispatch(actions.fetchAccountingJobs());
    },
    requestAccountingJobs(formatKey, jobName, targetKey, targetId, transactedAt) {
      dispatch(actions.requestAccountingJobs(formatKey, jobName, targetKey, targetId, transactedAt));
    },
    setAnalysisFileExportFlag() {
      return dispatch(actions.setAnalysisFileExportFlag());
    },
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(AccountingExportForm);
