import AttachmentDroparea from "components/AttachmentDroparea";
import BootstrapCard from "components/BootstrapCard";
import TextArea from "components/TextArea";
import i18next from "i18n";
import PropTypes from "prop-types";
import React, { Component } from "react";
import Api from "utilities/api";
import flash from "utilities/flash";
import { snakecaseKeys } from "utilities/Utils";
import UPLOAD_STATUS from "values/upload_status";
import Timeline from "./components/Timeline";

// @Deprecated
// 新規のComponentでは、containers/TimelineCardを使用する
export default class TimelineCard extends Component {
  constructor(props) {
    super(props);
    this.state = {
      reportStatus: props.reportStatus,
      requestEvents: props.requestEvents || [],
      remainingApprovals: props.remainingApprovals || [],
      comment: "",
      uploadStatus: {},
      isUploadingComment: false,
    };

    this.setHeightContentTimeCard = this.setHeightContentTimeCard.bind(this);
  }

  // eslint-disable-next-line camelcase
  UNSAFE_componentWillReceiveProps(props) {
    this.setState({
      requestEvents: props.requestEvents || [],
      remainingApprovals: props.remainingApprovals || [],
    });
  }

  onSubmitComment() {
    this.submit(this.state.comment);
  }

  onUploadStatusChange(statuses) {
    const status = statuses[0];
    switch (status.status) {
      case UPLOAD_STATUS.uploading:
        break;
      case UPLOAD_STATUS.uploaded:
        this.submit(null, status.attachmentId);
        break;
      case UPLOAD_STATUS.failed:
        flash.error(status.error);
        break;
      default:
        break;
    }

    this.setState({
      uploadStatus: status,
    });
  }

  onPushControllAndEnter(e) {
    if (e.ctrlKey && e.keyCode === 13) {
      // onChangeを呼ばないようにイベントをキャンセル
      e.preventDefault();
      this.onSubmitComment();
    }
  }

  onChange(e) {
    this.setState({ comment: e.target.value });
  }

  submit(comment, attachmentId) {
    if (!this.state.isUploadingComment && (!!comment || attachmentId)) {
      this.setState({ isUploadingComment: true });
      Api.requests
        .comment(
          snakecaseKeys({
            id: this.props.requestId,
            comment,
            attachmentId,
          }),
        )
        .fail(this.onError.bind(this))
        .done((data) => {
          // 添付ファイルを送っときはリセットしない
          if (comment) {
            this.setState({ comment: "" });
          }

          this.setState({ isUploadingComment: false });
          this.props.onRefresh();
        });
    }
  }

  onError(xhr, message) {
    this.setState({ isUploadingComment: false });
    flash.error(message || i18next.t("commons.errors.communicationError"));
  }

  renderFooter() {
    return (
      <div className="input-group" style={{ height: "100%" }}>
        <AttachmentDroparea
          className="input-group-addon"
          disabled={this.state.isUploadingComment}
          onChange={this.onUploadStatusChange.bind(this)}
        >
          <span className="fa fa-fw fa-paperclip" />
        </AttachmentDroparea>
        {this.state.uploadStatus.status === UPLOAD_STATUS.uploading ? (
          <span className="form-control txt-info">アップロード中です</span>
        ) : (
          <TextArea
            type="text"
            className="form-control custom-control"
            maxRows={5}
            value={this.state.comment}
            placeholder={i18next.t("reports.messages.enterComment")}
            onChange={this.onChange.bind(this)}
            onKeyDown={this.onPushControllAndEnter.bind(this)}
          />
        )}
        <span
          type="submit"
          className="input-group-addon btn btn-success"
          onClick={this.onSubmitComment.bind(this)}
          disabled={this.state.isUploadingComment}
        >
          {i18next.t("commons.actions.send")}
        </span>
      </div>
    );
  }

  setHeightContentTimeCard() {
    const { isSetHeight = false, classNameOfComponentCompare = "" } =
      this.props;
    if (isSetHeight) {
      const elementCompare = document.querySelector(
        `.${classNameOfComponentCompare}`,
      );
      const elementTimeCard = document.querySelector(".timeline-card");
      const elementTimeCardBody =
        elementTimeCard?.querySelector(".card-content");
      const height = elementCompare?.clientHeight;
      if (elementTimeCard && height && elementTimeCardBody) {
        elementTimeCardBody.style.height = `${height - 53 - 45}px`;
      }
    }
  }

  componentDidMount() {
    this.setHeightContentTimeCard();
  }

  componentDidUpdate() {
    this.setHeightContentTimeCard();
  }

  render() {
    const events = this.state.requestEvents || [];
    if (events.length <= 0) {
      return <span />;
    }

    return (
      <BootstrapCard
        className="card timeline-card"
        title={i18next.t("reports.titles.timeline")}
        footer={this.renderFooter()}
      >
        <Timeline
          reportStatus={this.props.reportStatus}
          requestEvents={this.state.requestEvents}
          remainingApprovals={this.state.remainingApprovals}
        />
      </BootstrapCard>
    );
  }
}

TimelineCard.propTypes = {
  onRefresh: PropTypes.func.isRequired,
  requestId: PropTypes.string,
  reportStatus: PropTypes.string,
  requestEvents: PropTypes.array,
  remainingApprovals: PropTypes.array,
};
