import DeleteConfirmationModal from 'components/delete_confirmation_modal';
import ExtendedFileView from 'components/renewaled_ui/FileViewer';
import React, { useCallback } from 'react';
import i18next from 'i18n';
import styled from 'styled-components';
import { FileSide, FileType, ReceiptFile } from 'components/types/attachedFiles.types';
import { useDeleteCallback, useRotationCallback } from './useSingleSideView';
import { useReceiptFile } from 'components/attached_files/useRemoteFile';

type DeleteCallback =  (file: ReceiptFile, error?: Error) => void;
type RotationCallback = (file: ReceiptFile, rotation: number) => void;
type DropCallback = (side: FileSide, file: File, isAccepted: boolean) => void;

interface Props {
  side: FileSide;
  downloadLink?: string;

  receiptFile: ReceiptFile | null;
  width?: number;
  height?: number;
  isEditable: boolean;
  isOriginalReceiptMatched?: boolean;
  isVisible: boolean;

  onDelete?: DeleteCallback;
  onRotate?: RotationCallback;
  onDrop?: DropCallback;
}

interface FileViewContainerProps {
  side: FileSide;
}
const FileViewContainer = styled.div<FileViewContainerProps>`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  height: 100%;
  overflow: hidden;
  backface-visibility: hidden;
  transform: ${(props): string => `rotateY(${props.side === 'foreside' ? '0deg' : '180deg'})`};
`;

const selectableTypes: FileType[] = ['jpeg', 'png', 'pdf'];

const SingleSideView: React.FunctionComponent<Props> = (props) => {
  const {
    side, receiptFile, onDelete, onDrop, onRotate,
    width, height, downloadLink, isEditable, isOriginalReceiptMatched, isVisible,
  } = props;

  const {
    isConfirming,
    onCancel: handleCancel, onConfirm: handleConfirm, onDelete: handleDelete,
  } = useDeleteCallback(receiptFile, onDelete);
  const handleRotate = useRotationCallback(receiptFile, onRotate);
  const handleDrop = useCallback((file, isAccepted) => {
    if (onDrop) {
      onDrop(side, file, isAccepted);
    }
  }, [side, onDrop]);

  const [objectUrl, loadStatus] = useReceiptFile(receiptFile);

  return (
    <FileViewContainer
      side={ side }
      className={ `file-view-container ${props.side === 'foreside' ? 'foreside' : 'backside'}` }
      style={{
        opacity: isVisible ? 1 : 0,
        pointerEvents: isVisible ? 'auto' : 'none',
      }}
    >
      <ExtendedFileView key={ receiptFile && 'id' in receiptFile ? receiptFile.id : receiptFile?.file?.name }
        url={ objectUrl }
        mimeType={ receiptFile?.contentType }
        loadStatus={ loadStatus }
        width={ width }
        height={ height }
        defaultRotation={ receiptFile?.rotation }
        downloadLink={ downloadLink || objectUrl }
        isDeletable={ isEditable }
        // ローカルのファイルは意味がないのでダウンロードリンクを表示しない
        isDownloadable={ !!receiptFile && 'id' in receiptFile }
        isDroppable={ isEditable }
        dropType='single'
        selectableTypes={ selectableTypes }
        onDelete={ handleDelete }
        onRotate={ handleRotate }
        onDrop={ handleDrop }
      />
      {
        isEditable && (
          <DeleteConfirmationModal
            show={ isConfirming }
            onDelete={ handleConfirm }
            onCancel={ handleCancel }
            content={ isOriginalReceiptMatched ? i18next.t('transactions.messages.confirmCancelReceiptMatching') : '' }
          />
        )
      }
    </FileViewContainer>
  );
};

export default SingleSideView;
