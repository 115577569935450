import i18next from "i18n";
import React, { FC } from "react";
import { TableHeaderColumn } from "react-bootstrap-table";
import Formatter from "utilities/formatter";
import { ColumnProps } from "./types";
import { buildTableHeaderColumnOption } from "./utils";

const formatMemo = (cell: string): JSX.Element | null | string => {
  return Formatter.text(cell);
};

const renderMemoColumn: FC<ColumnProps> = (props: ColumnProps) => {
  return (
    <TableHeaderColumn
      {...buildTableHeaderColumnOption(props)}
      dataField={"comment"}
      dataFormat={formatMemo}
    >
      {i18next.t("transactions.properties.comment")}
    </TableHeaderColumn>
  );
};

export default renderMemoColumn;
