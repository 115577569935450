import LabeledComponent from 'components/LabeledComponent';
import PeriodInput from 'components/PeriodInput';
import React from 'react';
import i18next from 'i18n';

interface Props {
  className?: string;
  dueFrom: string | undefined;
  dueTo: string | undefined;
  onDueFromChange: (date: string) => void;
  onDueToChange: (date: string) => void;
}

/**
 * Invoice における支払期日を入力する
 *
 * @note
 *   本来は payment_requests/components/reports/fields/DueAtRangeField.tsx を使用したいが、
 *   UI が浮いてしまわないように TOKIUM 経費精算 の UI で作成する。
 *   後から置き換えられるように props の互換性はある程度あわせている。
 */
export const DueAtRangeField = ({
  className,
  dueFrom,
  dueTo,
  onDueFromChange,
  onDueToChange,
}: Props): React.ReactElement => {
  return (
    <LabeledComponent
      className={ className }
      label={ `${i18next.t('paymentRequests.properties.dueAt')}（${i18next.t('menus.ip.invoices')}）` }
    >
      <PeriodInput
        className='lastapproved-at-period'
        dateFrom={ dueFrom }
        dateTo={ dueTo }
        onChangeDateFrom={ (date: string): void => onDueFromChange(date ? new Date(date).toISOString() : date) }
        onChangeDateTo={ (date: string): void => onDueToChange(date ? new Date(date).toISOString() : date) }
      />
    </LabeledComponent>
  );
};
