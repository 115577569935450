import i18next from "i18n";
import React from "react";

interface Props {
  disabled?: boolean;
  onChange: (value: string) => void;
  title: string;
}

/** 汎用申請の申請名 */
export const TitleField: React.FC<Props> = React.memo(
  ({ disabled = false, onChange, title }) => {
    return (
      <input
        value={title}
        className="form-control toggle"
        placeholder={i18next.t("commons.status.notEntered")}
        disabled={disabled}
        onChange={(e: React.ChangeEvent<HTMLInputElement>): void =>
          onChange(e.target.value)
        }
      />
    );
  },
);
