import React, { useEffect, useMemo } from 'react';
import Select from 'react-select';
import SmartCheckBox from 'components/SmartCheckBox';
import includes from 'lodash/includes';
import styled from 'styled-components';
import { Controller, useFormContext } from 'react-hook-form';
import { FormControl } from 'react-bootstrap';

interface Props {
  basePropertyName: string;
}

const HorizontalFieldGroupView = styled.div`
  display: flex;
  gap: 6px;
  align-items: center;
`;

interface Option {
  label: string;
  value: string | number | null;
}

const units: Option[] = [
  { label: '月', value: 'month' },
  { label: '週', value: 'weekday' },
  { label: '日', value: 'day' },
];

const directions: Option[] = [
  { label: '前', value: 'before' },
  { label: '後', value: 'after' },
];

const days: Option[] = [...Array(31)].map((_, i) => ({ value: i + 1, label: `${i + 1}日` }));
const weekdays: Option[] = ['日', '月', '火', '水', '木', '金', '土'].map((wday, i) => ({ value: i, label: `${wday}曜日` }));

export const PeriodPointField: React.FC<Props> = ({
  basePropertyName,
}) => {
  const {
    control,
    watch,
    setValue,
    formState: {
      dirtyFields,
    },
  } = useFormContext();

  const unit = watch(`${basePropertyName}.unit`);
  const dayOfMonth = watch(`${basePropertyName}.dayOfMonth`);
  const lastDayOfMonth = watch(`${basePropertyName}.lastDayOfMonth`);
  const weekday = watch(`${basePropertyName}.weekday`);

  const hasOnlyBeforeDirection = useMemo(() => {
    return includes(['submittedPeriod', 'lastApprovedPeriod'], basePropertyName.split('.')[1]);
  }, [basePropertyName]);

  useEffect(() => {
    switch (unit) {
      case 'month':
        setValue(`${basePropertyName}.weekday`, null);
        setValue(`${basePropertyName}.dayOfMonth`, dayOfMonth || 1);
        setValue(`${basePropertyName}.lastDayOfMonth`, lastDayOfMonth || false);
        break;
      case 'weekday':
        setValue(`${basePropertyName}.weekday`, weekday || 0);
        setValue(`${basePropertyName}.dayOfMonth`, null);
        setValue(`${basePropertyName}.lastDayOfMonth`, false);
        break;
      case 'day':
        setValue(`${basePropertyName}.weekday`, null);
        setValue(`${basePropertyName}.dayOfMonth`, null);
        setValue(`${basePropertyName}.lastDayOfMonth`, false);
        break;
      default:
        break;
    }
  }, [unit, dirtyFields, basePropertyName, setValue, dayOfMonth, lastDayOfMonth, weekday]);

  useEffect(() => {
    if (unit !== 'month') return;

    setValue(`${basePropertyName}.dayOfMonth`, lastDayOfMonth ? null : dayOfMonth || 1);
  }, [lastDayOfMonth, basePropertyName, setValue, unit, dayOfMonth]);

  return (
    <HorizontalFieldGroupView>
      <Controller
        name={ `${basePropertyName}.value` }
        control={ control }
        defaultValue={ '' }
        render={ ({ field: { onChange, value, ref: inputRef } }): React.ReactElement => (
          <div style={ { width: '50px' } }>
            <FormControl
              inputRef={ inputRef }
              value={ value || 0 }
              type='number'
              onChange={ onChange }
              min={ 0 }
            />
          </div>
        ) }
      />
      <Controller
        name={ `${basePropertyName}.unit` }
        control={ control }
        render={ ({ field: { onChange, value, ref: inputRef } }): React.ReactElement => (
          <div style={ { width: '50px' } }>
            <Select
              inputRef={ inputRef }
              options={ units }
              value={ units?.find((c) => c.value === value) }
              getOptionLabel={ (v: Option): string => v.label }
              getOptionValue={ (v: Option): string | number | null => v.value }
              onChange={ (v: Option): void => onChange(v?.value) }
              isSearchable={ false }
              components={ { DropdownIndicator:(): null => null, IndicatorSeparator:(): null => null } }
            />
          </div>
        ) }
      />
      { hasOnlyBeforeDirection ? (
        <span>前</span>
      ) : (
        <Controller
          name={ `${basePropertyName}.direction` }
          control={ control }
          render={ ({ field: { onChange, value, ref: inputRef } }): React.ReactElement => (
            <>
              <div style={ { width: '50px' } }>
                <Select
                  inputRef={ inputRef }
                  options={ directions }
                  value={ directions?.find((c) => c.value === value) }
                  getOptionLabel={ (v: Option): string => v.label }
                  getOptionValue={ (v: Option): string | number | null => v.value }
                  onChange={ (v: Option): void => onChange(v?.value) }
                  isSearchable={ false }
                  components={ { DropdownIndicator: (): null => null, IndicatorSeparator: (): null => null } }
                />
              </div>
            </>
          ) }
        />
      ) }
      { unit !== 'day' && <span>の</span> }
      {
        unit === 'month' && !lastDayOfMonth && (
          <Controller
            name={ `${basePropertyName}.dayOfMonth` }
            control={ control }
            render={ ({ field: { onChange, value, ref : inputRef } }): React.ReactElement => (
              <div style={ { width: '75px' } }>
                <Select
                  inputRef={ inputRef }
                  options={ days }
                  value={ days?.find((c) => c.value === value) }
                  getOptionLabel={ (v: Option): string => v.label }
                  getOptionValue={ (v: Option): string | number | null => v.value }
                  onChange={ (v: Option): void => onChange(v?.value) }
                  isSearchable={ false }
                  components={ { DropdownIndicator:(): null => null, IndicatorSeparator:(): null => null } }
                />
              </div>
            ) }
          />
        )
      }
      {
        unit === 'month' && (
          <Controller
            name={ `${basePropertyName}.lastDayOfMonth` }
            control={ control }
            render={ ({ field: { onChange, value } }): React.ReactElement => (
              <SmartCheckBox onChange={ onChange } checked={ value || false } label='月末日' />
            ) }
          />
        )
      }
      {
        unit === 'weekday' && (
          <Controller
            name={ `${basePropertyName}.weekday` }
            control={ control }
            render={ ({ field: { onChange, value, ref : inputRef } }): React.ReactElement => (
              <div style={ { width: '100px' } }>
                <Select
                  inputRef={ inputRef }
                  options={ weekdays }
                  value={ weekdays?.find((c) => c.value === value) }
                  getOptionLabel={ (v: Option): string => v.label }
                  getOptionValue={ (v: Option): string | number | null => v.value }
                  onChange={ (v: Option): void => onChange(v?.value) }
                  isSearchable={ false }
                />
              </div>
            ) }
          />
        )
      }
    </HorizontalFieldGroupView>
  );
};
