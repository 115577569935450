import FlipSwitch from 'components/FlipSwitch';
import React, { useCallback } from 'react';
import colors from 'values/colors';
import i18next from 'i18n';
import { FileSide } from 'components/types/attachedFiles.types';

interface Props {
  className?: string;
  side: FileSide;
  onChange: (side: FileSide) => void;
}

const SideSwitch: React.FunctionComponent<Props> = (props: Props) => {
  const { className, onChange } = props;
  const handleChange = useCallback((_e, flag) => {
    onChange(flag ? 'foreside' : 'backside');
  }, [onChange]);
  const activeConfig = {
    label: i18next.t('timeStamps.foreside'),
    backgroundColor: colors.status.primary,
    fontColor: 'white',
  };
  const inactiveConfig = {
    label: i18next.t('timeStamps.backside'),
    backgroundColor: '#eee',
    fontColor: colors.status.primary,
  };

  return (
    <FlipSwitch className={ className }
      checked={ props.side === 'foreside' }
      onChange={ handleChange }
      active={ activeConfig }
      inactive={ inactiveConfig }
    />
  );
};

export default SideSwitch;
