import i18next from "i18n";
import React, { HTMLAttributes } from "react";
import { OverlayTrigger, Popover } from "react-bootstrap";

type IconType = "default" | "notExist" | "notFound" | "error";

interface Props {
  readonly type?: IconType;
  readonly description?: string;
  readonly showDescription?: boolean;
}

function getDescription(type: IconType, description?: string): string | null {
  if (description) {
    return description;
  }
  if (type === "default") {
    return null;
  }

  return i18next.t(`attachedFiles.icons.description.${type}`);
}

interface IconProps extends HTMLAttributes<HTMLLIElement> {
  readonly type: IconType;
}
const IconBody: React.FunctionComponent<IconProps> = (props: IconProps) => {
  const { type, ...iconProps } = props;

  switch (type) {
    case "notFound": {
      return <i className="far fa-image text-danger" {...iconProps} />;
    }
    case "error": {
      return (
        <i className="fa fa-exclamation-circle text-danger" {...iconProps} />
      );
    }
    case "notExist": {
      return <i className="fas fa-upload text-muted" {...iconProps} />;
    }
    default: {
      return <i className="fas fa-upload" {...iconProps} />;
    }
  }
};

/**
 * Placeholderで使用するアイコン
 */
const FileIcon: React.FunctionComponent<Props> = (p: Props) => {
  const type = p.type || "default";
  const description = getDescription(type, p.description);

  if (!p.showDescription || !description) {
    return <IconBody type={type} />;
  }

  return (
    <OverlayTrigger
      trigger={["click", "hover"]}
      placement="top"
      overlay={<Popover id="description">{description}</Popover>}
    >
      <IconBody type={type} />
    </OverlayTrigger>
  );
};

export default FileIcon;
