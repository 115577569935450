import NumberField from "components/fields/NumberField";
import i18next from "i18n";
import React from "react";

interface Props {
  /** 金額 */
  amount?: number | null;
  /** 入力時のハンドラ */
  onChange: (amount: number) => void;
  /** フォーカスアウト時のハンドラ */
  onBlurAmountInput: () => void;
}

/** 金額入力欄 */
export const AmountInput: React.FC<Props> = ({
  amount,
  onChange,
  onBlurAmountInput,
}) => {
  return (
    <NumberField
      value={amount}
      className="form-control text-right"
      allowMinus={false}
      allowDecimal={false}
      allowZeroPadding={true}
      onChange={(value: string): void =>
        onChange(value.length ? parseInt(value, 10) : 0)
      }
      onBlur={onBlurAmountInput}
      options={{
        title: i18next.t(
          "expenses.split.amountBySeparatorsEditor.amountInputTitle",
        ),
      }}
    />
  );
};
