import { createSelector } from "reselect";

const getEntryForms = (state) => state.transactionTable?.entryForms ?? [];
const getReportHistories = (state) => state.histories?.reportHistories ?? [];
const getExpenseHistories = (state) => state.histories?.expenseHistories ?? [];
const getExpense = (state) => state.histories?.expenseForReference ?? null;

const stateMapper = () =>
  createSelector(
    [getEntryForms, getReportHistories, getExpenseHistories, getExpense],
    (entryForms, reportHistories, expenseHistories, expense) => {
      return {
        entryForms,
        histories: {
          reportHistories,
          transactionHistories: expenseHistories,
        },
        transaction: expense,
      };
    },
  );

export default stateMapper;
