import EditableDate from 'components/EditableDate';
import EditableSelect from 'components/EditableSelect';
import EditableText from 'components/EditableText';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import i18next from 'i18n';

// インライン編集に使用されるComponent
// inputTypeに指定された文字列に応じて、異なる形式での編集が可能になる
//
// text -> type=textのinputタグでの編集
// number -> type=numberのinputタグでの編集
// date -> カレンダーによる日付編集
// select -> ドロップダウンリストからの選択による編集
export default class EditableInput extends Component {
  render() {
    switch (this.props.inputType) {
      case 'text':
      case 'number': {
        const props = {
          ...this.props,
          input: {
            ...this.props.input,
            type: this.props.inputType,
          },
        };
        return (
          <EditableText { ...props }/>
        );
      }
      case 'date': {
        return (
          <EditableDate { ...this.props } />
        );
      }
      case 'select': {
        return (
          <EditableSelect { ...this.props } />
        );
      }
      default: return null;
    }
  }
}

EditableInput.defaultProps = {
  type: 'text',
  text:   '',
  className: 'editable-input',
  input: {
    className: 'col-sm-12 form-control',
    placeholder: '',
  },
  button: {
    className: 'btn btn-success',
    text: i18next.t('commons.actions.save'),
    show: false,
  },
  icon: {
    className: 'fas fa-pencil-alt txt-pointer',
    style: {
      marginLeft: '5px',
    },
  },
  updateOnBlur: true,
  formatter(v) { return v; },
  onUpdate() {},
  onChange() {},
  onCancel() {},
  switchMode() {},
};

EditableInput.propTypes = {
  type: PropTypes.string.isRequired,
  sync: PropTypes.bool,
  text: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
  ]),
  editingText: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
  ]),
  className: PropTypes.string,
  input: PropTypes.shape({
    className: PropTypes.string,
    type: PropTypes.string,
    placeholder: PropTypes.string,
  }),
  button: PropTypes.shape({
    className: PropTypes.string,
    text: PropTypes.string,
  }),
  updateOnBlur: PropTypes.bool,
  icon: PropTypes.shape({
    className: PropTypes.string,
    style: PropTypes.object,
  }),
  isEditing: PropTypes.bool.isRequired,
  formatter: PropTypes.func,
  onUpdate: PropTypes.func,
  onChange: PropTypes.func,
  onCancel: PropTypes.func,
  switchMode: PropTypes.func,

  // EditableSelect用
  style: PropTypes.object,
  suggestions: PropTypes.array,
  renderSuggestion: PropTypes.func,
  onSuggestionsUpdateRequested: PropTypes.func,
  onSelect: PropTypes.func,
};
