import Api from "utilities/api";
import React, { useCallback, useState } from 'react';
import flash from 'utilities/flash';
import i18next from 'i18next';
import styled from 'styled-components';
import { CancelButton, SaveButton } from '../components/buttons';
import { Form } from './Form';
import { FormProvider, useForm } from 'react-hook-form';
import { Modal } from 'react-bootstrap';
import { defaultValues } from "./types";
import { getMessageFromResponse, snakecaseKeys } from 'utilities/Utils';
import { useMountedRef } from 'hooks';

const FixedModalBody = styled(Modal.Body)`
  overflow: scroll;
  height: calc(100vh - 180px);
  position: relative;
`;

const ModalFooterView = styled(Modal.Footer)`
  display: flex;
  padding: 15px 6px;
  flex-direction: row;
  column-gap: 8px;
  align-items: center;
  justify-content: center;

  button:last-child {
    margin-left: auto;
  }
`;

interface Props {
  show: boolean;
  onClose: () => void;
  onCreated: () => void;
}

export const CreateModal: React.FC<Props> = ({
  show,
  onClose,
  onCreated,
}) => {
  const mountedRef = useMountedRef();
  const methods = useForm({ mode: 'onChange', defaultValues });
  const { isDirty, isValid } = methods.formState;

  const [processing, setProcessing] = useState(false);

  const handleSubmit = useCallback((data) => {
    setProcessing(true);
    flash.clear();

    const createApi = async (): Promise<void> => {
      try {
        const { message } = await Api.accountingDataScheduledExports.fileTransferLocations.create(snakecaseKeys(data));
        flash.success(message);
        methods.reset();
        onClose();
        onCreated();
      } catch (error) {
        flash.error(getMessageFromResponse(error as Record<string, unknown>));
      } finally {
        if (mountedRef.current) {
          setProcessing(false);
        }
      }
    };
    createApi();
  }, [setProcessing, methods, onClose, onCreated, mountedRef]);

  return (
    <Modal show={ show } onHide={ onClose } animation={ false } backdrop='static'>
      <Modal.Header closeButton={ true }>{ i18next.t('accountingDataScheduledExports.fileTransferLocations.createModal.title') }</Modal.Header>
      <FixedModalBody>
        <FormProvider { ...methods }>
          <Form action='create' />
        </FormProvider>
      </FixedModalBody>
      <ModalFooterView>
        <SaveButton onClick={ methods.handleSubmit(handleSubmit) } processing={ processing } valid={ isDirty && isValid } />
        <CancelButton onClick={ onClose } disabled={ processing } />
      </ModalFooterView>
    </Modal>
  );
};
