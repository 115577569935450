import React from 'react';
import colors from 'values/colors';
import styled from 'styled-components';
import { TableRowProps } from 'react-table';

const lightGreen = "#d4eee8";
const lightGreenThin = "#dbf2ed";

interface WrapperProps {
  /** onRowClickがあるか(行をクリック可能か) */
  readonly hasOnRowClick?: boolean;
  /** 枠線がないシンプルなデザイン */
  readonly isSimpleDesignMode?: boolean;
  /** 選択状態である */
  readonly isSelected: boolean;
  /** ハイライト状態 */
  readonly isHighLight: boolean;
}
/**
 * 背景色
 */
const getBackgroundColor = (p: WrapperProps): string => {
  // ハイライト状態なら背景つける
  if (p.isHighLight) return lightGreen;
  // 選択状態でなければ背景付けない
  if (!p.isSelected) return 'inherit';
  // クリック不可なら背景付けない
  if (!p.hasOnRowClick) return 'inherit';
  // 背景の設定
  if (p.isSimpleDesignMode) return colors.renewaled.whiteHoverActive;
  return lightGreen;
};
/**
 * ホバーした時の背景色
 */
const getHoverBackgroundColor = (p: WrapperProps): string => {
  if (!p.hasOnRowClick) return 'inherit';
  if (p.isSimpleDesignMode) {
    if (p.isSelected) return colors.renewaled.whiteHoverActive;
    return colors.renewaled.whiteHover;
  }
  return lightGreenThin;
};
const Wrapper = styled.tr<WrapperProps>`
  border-bottom: 1px solid ${colors.renewaled.borderGrey};
  cursor: ${(p): string => (p.hasOnRowClick ? 'pointer' : 'auto')};
  transition: 50ms;
  text-decoration: none;
  color: inherit;
  background: ${(p): string => getBackgroundColor(p)};

  &:hover {
    background: ${(p): string => getHoverBackgroundColor(p)};
  }
  [data-sticky-td] {
    position: sticky;
    background-color: white;
  }
`;

interface Props {
  readonly isSimpleDesignMode: boolean;
  readonly children: JSX.Element | JSX.Element[];
  /** react-tableのdomのprops */
  readonly tableRowProps: TableRowProps;
  /** 選択状態にする */
  readonly isSelected: boolean;
  /** ハイライト状態にする */
  readonly isHighLight: boolean;
  /** onRowClickがあるか(行をクリック可能か) */
  readonly hasOnRowClick?: boolean;
}

/**
 * table tr
 */
export const Tr: React.FC<Props> = (props: Props) => {
  const className = `tr${props.isSelected ? ' isSelected' : ''}${props.tableRowProps.className ? ` ${props.tableRowProps.className}` : ''}`;
  return (
    <Wrapper
      { ...props.tableRowProps }
      className={ className }
      isSimpleDesignMode={ props.isSimpleDesignMode }
      isSelected={ props.isSelected }
      isHighLight={ props.isHighLight }
      hasOnRowClick={ props.hasOnRowClick }
      key={ props.tableRowProps.key }
      role={ props.tableRowProps.role }
      style={ {
        ...props.tableRowProps.style,
        display: 'table-row',
      } }
    >
      { props.children }
    </Wrapper>
  );
};
