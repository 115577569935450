import PropTypes from 'prop-types';
import React, { Component } from 'react';
import get from 'lodash/get';
import i18next from 'i18n';
import { Button, Modal } from 'react-bootstrap';

export default class ConfirmationModal extends Component {
  async handleConfirm() {
    const { onConfirm, close, onError } = this.props;
    try {
      await Promise.resolve(onConfirm());
      close();
    } catch (error) {
      onError(error, get(error, 'responseJSON.message'));
    }
  }

  render() {
    const {
      show, title, content, buttonStyle, buttonText, close, onDenialConfirm,
      inProcess, denialButtonText, children,
    } = this.props;

    return (
      <Modal
        show={ show }
        onHide={ close }
      >
        <Modal.Header>
          <Modal.Title>{ title }</Modal.Title>
        </Modal.Header>
        {
          (children || content) && (
            <Modal.Body>
              <div className='modal-message'>
                { children || content }
              </div>
            </Modal.Body>
          )
        }
        <Modal.Footer>
          <Button
            onClick={ onDenialConfirm || close }
            bsStyle='default'
          >
            { denialButtonText }
          </Button>
          <Button
            className={ inProcess ? 'disabled' : '' }
            onClick={ this.handleConfirm.bind(this) }
            bsStyle={ buttonStyle }
          >
            { buttonText }
          </Button>
        </Modal.Footer>
      </Modal>
    );
  }
}

ConfirmationModal.defaultProps = {
  show: false,
  content: null,
  buttonStyle: 'danger',
  inProcess: false,
  denialButtonText: i18next.t('commons.actions.cancel'),
};

ConfirmationModal.propTypes = {
  /** 表示するか否か */
  show: PropTypes.bool.isRequired,
  /** 確認モーダルのタイトル */
  title: PropTypes.string.isRequired,
  /**
   * モーダルに表示する内容
   * @deprecated children を使用してください
   * @see https://reactjs.org/docs/composition-vs-inheritance.html#containment
   */
  content: PropTypes.oneOfType([
    PropTypes.object,
    PropTypes.string,
  ]),
  /** 確認ボタンのスタイル */
  buttonStyle: PropTypes.string,
  /** 確認ボタンに記載するテキスト */
  buttonText: PropTypes.string.isRequired,
  /** モーダルを閉じるアクション */
  close: PropTypes.func.isRequired,
  /**
   * close のエイリアスに当たる prop
   * @deprecated close を使用してください
   * @note close と排他的
   */
  onDenialConfirm: PropTypes.func,
  /** 関連する処理が実行か否か (default: false) */
  inProcess: PropTypes.bool,
  /** 確認ボタンが押下されたときに発火するアクション */
  onConfirm: PropTypes.func.isRequired,
  /** 確認ボタン押下によって発火したアクションが失敗したときのアクション */
  onError: PropTypes.func,
  /** キャンセルに該当するアクション (default: i18next.t('commons.actions.cancel')) */
  denialButtonText: PropTypes.string,
};
