import React, { useMemo } from 'react';
import i18next from 'i18next';
import moment from 'moment';
import { List } from './components/table/latest_job_execution/List';
import { NoNextExecution } from './components/NoNextExecution';
import { Popover } from './components/table/analysis_aggregation_conditions/Popover';
import { ScheduleItem } from './components/table/schedule/ScheduleItem';
import { Table } from 'components/renewaled_ui/Table';
import { Task } from 'utilities/api/models/accountingDataScheduledExports';

interface Props {
  tasks: Task[];
  isLoading: boolean;
  onRowClick: (task: Task) => void;
}

export const TaskTable: React.FC<Props> = ({
  tasks,
  isLoading,
  onRowClick,
}) => {
  const getEnabledText = (enabled): string => {
    return i18next.t(`accountingDataScheduledExports.tasks.enable.${enabled}`);
  };

  const getSchedule = (task: Task): JSX.Element => {
    return (
      <>
        <ScheduleItem task={ task }></ScheduleItem>
      </>
    );
  };

  const getStatusCompanyExpenseAccountText = (task: Task): JSX.Element => {
    return !task.enableBankDataCreation || task.companyExpenseAccountAssignees?.length === 0
      ? <>{ i18next.t('accountingDataScheduledExports.tasks.table.accountingDataCreationDisabled') }</>
      : <>{ i18next.t('accountingDataScheduledExports.tasks.table.enableBankDataCreationEnabled') }</>;
  };

  const getAnalysisAggregationConditions = (task: Task): JSX.Element => {
    if (task.analysisAggregationConditions === null) {
      return <>{ i18next.t('accountingDataScheduledExports.tasks.analysisAggregationConditions.noData') }</>;
    }

    return (<Popover analysisAggregationConditions={ task.analysisAggregationConditions } />);
  };

  const getListExportFormatName = (task: Task): JSX.Element => {
    return (<>
      {
        task?.exportFormatAssignees?.map((exportFormatAssignee) => exportFormatAssignee.exportFormat && (
          <div key={ exportFormatAssignee?.exportFormat.id }>
            {exportFormatAssignee?.exportFormat.name}
          </div>
        ))
      }
    </>);
  };

  const getLatestJobExecution = (task: Task): JSX.Element => {
    return (
      <>
        <List jobRequest={ task.latestJobRequest }></List>
      </>
    );
  };

  const getNextExecutionTimetable = (task: Task): string | JSX.Element => {
    if (task.executionInterval === 'by_once_specified_date') {
      if (task.notScheduleCompleted) {
        return `${task.executionOnceSpecifiedDate} ${task.executionTimetable.name}`;
      }
      return <NoNextExecution id={ task.id } />;
    }
    if (!task.nextExecutionTimetable.startAt || !task.nextExecutionTimetable.endAt) return i18next.t('accountingDataScheduledExports.tasks.table.notValidTimetable'); // 例: nextExecutionTimetable.startAtとnextExecutionTimetable.endAtの間の範囲が、task.periodStartedAtとtask.periodEndedAtの範囲に包含されていないとき

    const startAt = task.nextExecutionTimetable.startAt;
    const endAt = task.nextExecutionTimetable.endAt;
    const nextExecutionDate = moment(startAt).format('YYYY-MM-DD');
    const rangeText = `${moment(startAt).format('HH:mm')}~${moment(endAt).subtract(1, 'minute').format('HH:mm')}`;

    return `${nextExecutionDate} ${rangeText}`;
  };

  const columns = useMemo(() => [
    {
      accessor: 'name',
      Header: i18next.t('accountingDataScheduledExports.tasks.table.name'),
      width: 100,
    },
    {
      Header: i18next.t('accountingDataScheduledExports.tasks.table.schedule'),
      Cell: ({ row }): JSX.Element => getSchedule(row.original),
      width: 180,
    },
    {
      accessor: 'enabled',
      Header: i18next.t('accountingDataScheduledExports.tasks.table.enabled'),
      Cell: ({ value }): string => getEnabledText(value),
      width: 100,
    },
    {
      accessor: 'enableAccountingDataCreation',
      Header: i18next.t('accountingDataScheduledExports.tasks.table.enableAccountingDataCreation'),
      Cell: ({ value }): string => (value ? i18next.t('accountingDataScheduledExports.tasks.table.accountingDataCreationEnabled') : i18next.t('accountingDataScheduledExports.tasks.table.accountingDataCreationDisabled')),
      width: 120,
    },
    {
      Header: i18next.t('accountingDataScheduledExports.tasks.table.enableBankDataCreation'),
      Cell: ({ row }): JSX.Element => getStatusCompanyExpenseAccountText(row.original),
      width: 120,
    },
    {
      accessor: 'enableFileTransfer',
      Header: i18next.t('accountingDataScheduledExports.tasks.table.enableFileTransfer'),
      Cell: ({ value }): string => (value ? i18next.t('accountingDataScheduledExports.tasks.table.fileTransferEnabled') : i18next.t('accountingDataScheduledExports.tasks.table.fileTransferDisabled')),
      width: 100,
    },
    {
      Header: i18next.t('accountingDataScheduledExports.tasks.table.exportFormatName'),
      Cell: ({ row }): JSX.Element => getListExportFormatName(row.original),
      width: 160,
    },
    {
      Header: i18next.t('accountingDataScheduledExports.tasks.table.analysisAggregationConditions'),
      Cell: ({ row }): JSX.Element => getAnalysisAggregationConditions(row.original),
      width: 140,
    },
    {
      Header: i18next.t('accountingDataScheduledExports.tasks.table.latestJobExecution'),
      Cell: ({ row }): JSX.Element => getLatestJobExecution(row.original),
      width: 200,
    },
    {
      Header: i18next.t('accountingDataScheduledExports.tasks.table.nextExecutionTimetable'),
      Cell: ({ row }): string | JSX.Element => getNextExecutionTimetable(row.original),
      width: 200,
    },
  ], []);

  return (
    <>
      <Table<Task>
        data={ tasks }
        showCheckBoxes={ false }
        columns={ columns }
        columnResizable={ true }
        isLoading={ isLoading }
        onRowClick={ onRowClick }
      />
    </>
  );
};
