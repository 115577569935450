import LabelWithDateFormatPopover from './components/LabelWithDateFormatPopover';
import React, { useEffect, useMemo } from 'react';
import Select from 'react-select';
import SmartCheckBox from 'components/SmartCheckBox';
import i18next from 'i18n';
import { ApproverSelectField } from './fields/ApproverSelectField';
import {
  ControlLabel,
  FormGroup,
  OverlayTrigger,
  Popover,
} from 'react-bootstrap';
import { Controller, useFormContext } from 'react-hook-form';
import { DepartmentSelectField } from './fields/DepartmentSelectField';
import { GenericFieldDataSetFields } from './fields/GenericFieldDataSetFields';
import { InputField } from 'components/renewaled_ui/forms/inputs';
import { PeriodField } from './fields/PeriodField';
import { PostSelectField } from './fields/PostSelectField';
import { RegistratedNumberField } from './fields/RegistratedNumberField';
import { RequiredIcon } from '../../../components/icons/RequiredIcon';
import { SystemSetting } from '../../types';
import { TagsSelectField } from './fields/TagsSelectField';

interface Props {
  systemSetting: SystemSetting;
  isShowingForm: boolean;
}

interface Option {
  label: string;
  value: string;
}

const getTargetReportTypes = (useTemporaryPayment: boolean, usePreReport: boolean): Option[] => {
  const options = [{ label: '経費精算', value: 'is_report' }];
  if (useTemporaryPayment) {
    options.push({ label: '事前申請(仮払)', value: 'is_temporary_payment' });
  }
  if (usePreReport) {
    options.push({ label: '旅費申請', value: 'is_travel' });
  }
  return options;
};

export const AggregationForm = React.forwardRef((props: Props, ref?: React.Ref<HTMLDivElement>) => {
  const {
    control,
    watch,
    setValue,
    getValues,
  } = useFormContext();

  const {
    usePaymentDepartment = true,
    useProject = true,
    usePreReport = true,
    useTemporaryPayment = true,
    usePLP = true,
    useGenericFields = true,
    genericFields,
  } = props.systemSetting;
  const isShowingForm = props.isShowingForm;

  const defaultTargetReportType = getValues('targetReportType');
  const targetReportType = watch('targetReportType');

  // 申請種別が変更されたら集計条件をリセットする。
  useEffect(() => {
    if (defaultTargetReportType === targetReportType) return;

    [
      'submittedPeriod',
      'startPeriod',
      'temporaryPaymentDuePeriod',
      'transactedPeriod',
      'lastApprovedPeriod',
    ].forEach((key) => {
      setValue(`analysisAggregationConditions.${key}`, null);
    });
    setValue('analysisAggregationConditions.genericFields', []);
    setValue('analysisAggregationConditions.destination', '');
    setValue('analysisAggregationConditions.applicantGroup', null);
    setValue('analysisAggregationConditions.applicantPost', null);
    setValue('analysisAggregationConditions.approvedApprover', null);
    setValue('analysisAggregationConditions.paymentGroup', null);
    setValue('analysisAggregationConditions.projectId', '');
    setValue('analysisAggregationConditions.projectName', '');
    setValue('analysisAggregationConditions.tags', []);
    setValue('analysisAggregationConditions.includeChildDepartment', true);
    setValue('analysisAggregationConditions.includeNotAggregated', true);
    setValue('analysisAggregationConditions.includeAggregated', false);
    setValue('analysisAggregationConditions.includeNotExported', false);
    setValue('analysisAggregationConditions.includeExported', false);
    setValue('analysisAggregationConditions.includeNotPaid', false);
    setValue('analysisAggregationConditions.includePaid', false);
    setValue('analysisAggregationConditions.includeNotTemporaryPaid', false);
    setValue('analysisAggregationConditions.includeTemporaryPaid', false);
    setValue('analysisAggregationConditions.includeUnsettled', false);
    setValue('analysisAggregationConditions.includeSettled', false);
    setValue('analysisAggregationConditions.matchedOriginalReceipt', false);
    setValue('analysisAggregationConditions.notMatchedOriginalReceipt', false);
    setValue('analysisAggregationConditions.registratedNumber', '');
    setValue('analysisAggregationConditions.emptyRegistratedNumber', false);
    setValue('analysisAggregationConditions.includeAsInvoice', false);
    setValue('analysisAggregationConditions.includeNotAsInvoice', false);
  }, [setValue, targetReportType, defaultTargetReportType, genericFields]);

  const targetReportTypes = useMemo(() => getTargetReportTypes(useTemporaryPayment, usePreReport), [useTemporaryPayment, usePreReport]);

  const isShowTemporaryPaidStatus = useMemo(() => usePaymentDepartment && ['is_temporary_payment'].includes(targetReportType) && userPreferences.preference.inputGroup, [targetReportType, usePaymentDepartment]);

  const description = i18next.t('accountingDataScheduledExports.tasks.descriptions.aggregationDate').split(/(\n)/).map(
    (t, i) => (
      (t === '\n')
        ? <React.Fragment key={ i }><br /></React.Fragment>
        : t
    ),
  );

  return (
    <>
      <div ref={ ref } />
      <h4 className='title'>集計</h4>
      <InputField
        name='analysisAggregationName'
        label={ <LabelWithDateFormatPopover label={ <>集計名フォーマット <RequiredIcon /></> } /> }
        rules={ { required: '集計名フォーマットを入力してください' } }
        control={ control }
        placeholder='{yyyymmdd}_集計'
        defaultValue=''
      />
      <Controller
        name={ 'targetReportType' }
        control={ control }
        render={ ({ field: { onChange, value, ref: inputRef } }): React.ReactElement => (
          <FormGroup>
            <ControlLabel>申請種別</ControlLabel>
            <Select
              inputRef={ inputRef }
              options={ targetReportTypes }
              value={ targetReportTypes?.find((v) => v.value === value) }
              getOptionLabel={ (v: Option): string => v.label }
              getOptionValue={ (v: Option): string => v.value }
              onChange={ (v: Option): void => onChange(v.value) }
              isSeachable={ false }
            />
          </FormGroup>
        ) }
      />
      <label>
        <h5>
          <span>集計条件</span>
          <OverlayTrigger
            trigger={ ['click'] }
            placement='left'
            rootClose={ true }
            overlay={
              <Popover
                id="description"
                style={ { maxWidth: '500px' } }
              >
                { description }
              </Popover>
            }>
            <i className='far fa-question-circle' style={ { color: '#AAA', marginLeft: '6px', cursor: 'pointer' } } />
          </OverlayTrigger>
        </h5>
      </label>
      {/* 編集時にフォームを非表示とした時には、isShowingFormを切り替える事で PeriodField(中のchecked state) をリセットする */ }
      { isShowingForm && <>
        {
          targetReportType === 'is_travel' && <PeriodField
            label='期間'
            basePropertyName='analysisAggregationConditions.startPeriod'
          />
        }
        <PeriodField
          label='申請日'
          basePropertyName='analysisAggregationConditions.submittedPeriod'
        />
        {
          targetReportType === 'is_temporary_payment' && <PeriodField
            label='仮払希望日'
            basePropertyName='analysisAggregationConditions.temporaryPaymentDuePeriod'
          />
        }
        {
          ['is_report', 'is_travel'].includes(targetReportType) && <>
            <PeriodField
              label='利用日(経費)'
              basePropertyName='analysisAggregationConditions.transactedPeriod'
            />
          </>
        }
        <PeriodField
          label='最終承認日'
          basePropertyName='analysisAggregationConditions.lastApprovedPeriod'
        />
      </>
      }
      {
        targetReportType === 'is_travel' && <>
          <InputField
            name='analysisAggregationConditions.destination'
            label={ <>目的地</> }
            rules={ {} }
            control={ control }
            defaultValue=''
          />
        </>
      }
      <DepartmentSelectField
        name='analysisAggregationConditions.applicantGroup'
        label={ <>申請者の所属部署</> }
      />
      <PostSelectField
        name='analysisAggregationConditions.applicantPost'
        label={ <>申請者の役職</> }
      />
      <ApproverSelectField
        name='analysisAggregationConditions.approvedApprover'
        label={ <>承認済みの承認者</> }
      />
      {
        usePaymentDepartment && targetReportType === 'is_report' && <>
          <DepartmentSelectField
            name='analysisAggregationConditions.paymentGroup'
            label={ <>費用負担部署</> }
          />
        </>
      }
      {
        useProject && ['is_report', 'is_travel'].includes(targetReportType) && <>
          <InputField
            name='analysisAggregationConditions.projectId'
            label={ <>プロジェクトID</> }
            rules={ {} }
            control={ control }
            defaultValue=''
          />
        </>
      }
      {
        useProject && ['is_report', 'is_travel'].includes(targetReportType) && <>
          <InputField
            name='analysisAggregationConditions.projectName'
            label={ <>プロジェクト名</> }
            rules={ {} }
            control={ control }
            defaultValue=''
          />
        </>
      }
      <RegistratedNumberField
        styleTextForm={ { 'zIndex': 'auto' } }
        name='analysisAggregationConditions.registratedNumber'
        checkboxName='analysisAggregationConditions.emptyRegistratedNumber'
      />
      <FormGroup>
        <ControlLabel>{ i18next.t('accountingDataScheduledExports.tasks.analysisAggregationConditions.includeLabel.asInvoice') }</ControlLabel>
        <div style={ { display: 'flex', gap: '12px' } }>
          <Controller
            name='analysisAggregationConditions.includeAsInvoice'
            control={ control }
            render={ ({ field: { value, onChange } }): React.ReactElement => (
              <SmartCheckBox onChange={ onChange } checked={ value || false } label={ i18next.t('accountingDataScheduledExports.tasks.analysisAggregationConditions.includeAsInvoice') } />
            ) }
          />
          <Controller
            name='analysisAggregationConditions.includeNotAsInvoice'
            control={ control }
            render={ ({ field: { value, onChange } }): React.ReactElement => (
              <SmartCheckBox onChange={ onChange } checked={ value || false } label={ i18next.t('accountingDataScheduledExports.tasks.analysisAggregationConditions.includeNotAsInvoice') } />
            ) }
          />
        </div>
      </FormGroup>
      {
        useGenericFields && genericFields && ['is_report', 'is_travel'].includes(targetReportType) && <>
          <Controller
            name='analysisAggregationConditions.genericFields'
            control={ control }
            render={ ({ field: { value, onChange } }): React.ReactElement => (
              <GenericFieldDataSetFields onChange={ onChange } value={ value } dataSets={ genericFields } />
            ) }
          />
        </>
      }
      <TagsSelectField />
      <FormGroup>
        <ControlLabel>部署検索範囲</ControlLabel>
        <div style={ { display: 'flex', gap: '12px' } }>
          <Controller
            name='analysisAggregationConditions.includeChildDepartment'
            control={ control }
            render={ ({ field: { value, onChange } }): React.ReactElement => (
              <SmartCheckBox onChange={ onChange } checked={ value || false } label='下位部署を含む' />
            ) }
          />
        </div>
      </FormGroup>
      <FormGroup>
        <ControlLabel>集計状況</ControlLabel>
        <div style={ { display: 'flex', gap: '12px' } }>
          <Controller
            name='analysisAggregationConditions.includeNotAggregated'
            control={ control }
            render={ ({ field: { value, onChange } }): React.ReactElement => (
              <SmartCheckBox onChange={ onChange } checked={ value || false } label='未集計' />
            ) }
          />
          <Controller
            name='analysisAggregationConditions.includeAggregated'
            control={ control }
            render={ ({ field: { value, onChange } }): React.ReactElement => (
              <SmartCheckBox onChange={ onChange } checked={ value || false } label='集計済' />
            ) }
          />
        </div>
      </FormGroup>
      {
        ['is_report', 'is_temporary_payment'].includes(targetReportType) && <>
          <FormGroup>
            <ControlLabel>会計データ出力状況</ControlLabel>
            <div style={ { display: 'flex', gap: '12px' } }>
              <Controller
                name='analysisAggregationConditions.includeNotExported'
                control={ control }
                render={ ({ field: { value, onChange } }): React.ReactElement => (
                  <SmartCheckBox onChange={ onChange } checked={ value || false } label='会計データ未出力' />
                ) }
              />
              <Controller
                name='analysisAggregationConditions.includeExported'
                control={ control }
                render={ ({ field: { value, onChange } }): React.ReactElement => (
                  <SmartCheckBox onChange={ onChange } checked={ value || false } label='会計データ出力済み' />
                ) }
              />
            </div>
          </FormGroup>
        </>
      }
      {
        ['is_report'].includes(targetReportType) && <>
          <FormGroup>
            <ControlLabel>支払い状況</ControlLabel>
            <div style={ { display: 'flex', gap: '12px' } }>
              <Controller
                name='analysisAggregationConditions.includeNotPaid'
                control={ control }
                render={ ({ field: { value, onChange } }): React.ReactElement => (
                  <SmartCheckBox onChange={ onChange } checked={ value || false } label='未払い' />
                ) }
              />
              <Controller
                name='analysisAggregationConditions.includePaid'
                control={ control }
                render={ ({ field: { value, onChange } }): React.ReactElement => (
                  <SmartCheckBox onChange={ onChange } checked={ value || false } label='支払処理済み' />
                ) }
              />
            </div>
          </FormGroup>
        </>
      }
      {
        isShowTemporaryPaidStatus && <>
          <FormGroup>
            <ControlLabel>仮払い状況</ControlLabel>
            <div style={ { display: 'flex', gap: '12px' } }>
              <Controller
                name='analysisAggregationConditions.includeNotTemporaryPaid'
                control={ control }
                render={ ({ field: { value, onChange } }): React.ReactElement => (
                  <SmartCheckBox onChange={ onChange } checked={ value || false } label='未払い' />
                ) }
              />
              <Controller
                name='analysisAggregationConditions.includeTemporaryPaid'
                control={ control }
                render={ ({ field: { value, onChange } }): React.ReactElement => (
                  <SmartCheckBox onChange={ onChange } checked={ value || false } label='支払処理済み' />
                ) }
              />
            </div>
          </FormGroup>
        </>
      }
      {
        usePreReport && ['is_travel'].includes(targetReportType) && <>
          <FormGroup>
            <ControlLabel>精算状況</ControlLabel>
            <div style={ { display: 'flex', gap: '12px' } }>
              <Controller
                name='analysisAggregationConditions.includeUnsettled'
                control={ control }
                render={ ({ field: { value, onChange } }): React.ReactElement => (
                  <SmartCheckBox onChange={ onChange } checked={ value || false } label='未精算' />
                ) }
              />
              <Controller
                name='analysisAggregationConditions.includeSettled'
                control={ control }
                render={ ({ field: { value, onChange } }): React.ReactElement => (
                  <SmartCheckBox onChange={ onChange } checked={ value || false } label='精算済み' />
                ) }
              />
            </div>
          </FormGroup>
        </>
      }
      {
        usePLP && ['is_report'].includes(targetReportType) && <>
          <FormGroup>
            <ControlLabel>原本確認状況</ControlLabel>
            <div style={ { display: 'flex', gap: '12px' } }>
              <Controller
                name='analysisAggregationConditions.matchedOriginalReceipt'
                control={ control }
                render={ ({ field: { value, onChange } }): React.ReactElement => (
                  <SmartCheckBox onChange={ onChange } checked={ value || false } label='完了' />
                ) }
              />
              <Controller
                name='analysisAggregationConditions.notMatchedOriginalReceipt'
                control={ control }
                render={ ({ field: { value, onChange } }): React.ReactElement => (
                  <SmartCheckBox onChange={ onChange } checked={ value || false } label='未完了' />
                ) }
              />
            </div>
          </FormGroup>
        </>
      }
    </>
  );
});
