import React, { useState } from 'react';
import i18next from 'i18n';
import { Dropdown, MenuItem } from 'react-bootstrap';
import { ReportTypes } from 'applications/requests/components/organisms/RequestReportTable/interface';

interface Props {
  readonly reportTypes: ReportTypes;
  readonly onChangeReportOfReportTypes: () => void;
  readonly onChangeNoTemporaryPaymentOfReportTypes: () => void;
  readonly onChangeTemporaryPaymentOfReportTypes: () => void;
}

/**
 * 経費精算テーブル: 申請/承認ページのフィルタードロップダウン
 */
const ReportRequestTypeFilter: React.FC<Props> = (props) => {
  const [isShow, setIsShow] = useState<boolean>(false);

  const renderCheck = (): JSX.Element => (
    <i className='fa fa-check' style={ { marginRight: '4px' } } />
  );

  return (
    <Dropdown
      id='report-type-filter'
      open={ isShow }
      style={ { cursor: 'pointer', width: '100%' } }
      onToggle={ (): void => { setIsShow(!isShow); } }
    >
      <Dropdown.Toggle
        id="example-toggle"
        style={ {
          border: 'none', boxShadow: 'none', background: 'none', padding: 0, textAlign: 'left', width: '100%', overflow: 'hidden',
        } }
      >
        { i18next.t('reports.properties.requestType') }
      </Dropdown.Toggle>
      <Dropdown.Menu>
        <MenuItem
          key='report'
          role='menu'
          className='dropdown-item'
          onClick={ props.onChangeReportOfReportTypes }
        >
          { props.reportTypes.report ? renderCheck() : null }
          { i18next.t('reports.properties.reportTypes.report') }
        </MenuItem>
        <MenuItem
          key='noTemporaryPayment'
          className='dropdown-item'
          role='menu'
          onClick={ props.onChangeNoTemporaryPaymentOfReportTypes }
        >
          { props.reportTypes.noTemporaryPayment ? renderCheck() : null }
          { i18next.t('reports.properties.reportTypes.noTemporaryPayment') }
        </MenuItem>
        <MenuItem
          key='temporaryPayment'
          className='dropdown-item'
          role='menu'
          onClick={ props.onChangeTemporaryPaymentOfReportTypes }
        >
          { props.reportTypes.temporaryPayment ? renderCheck() : null }
          { i18next.t('reports.properties.reportTypes.temporaryPayment') }
        </MenuItem>
      </Dropdown.Menu>
    </Dropdown>
  );
};

export default ReportRequestTypeFilter;
