import PropTypes from 'prop-types';
import React, { Component } from 'react';
import i18next from 'i18n';

export default class GenericFieldsSelectedItemsList extends Component {
  render() {
    const { selectedItems, onSelectItems, disabled } = this.props;

    // 選択された選択肢を解除するボタン。編集画面でのみ表示する
    const renderClearButton = (itemId) => {
      return (
        <i role='button' className='fa fa-times-circle' onClick={ () => clearItem(itemId) }/>
      );
    };

    // 選択された選択肢を解除する
    const clearItem = (id) => {
      const nextItems = selectedItems.filter((item) => item.id !== id);
      onSelectItems(nextItems);
    };

    const displayName = (item) => {
      if (item.isDeleted) return `${item.name} (${i18next.t('genericFields.items.labels.isDeleted')})`;

      return `${item.name}`;
    };

    return (
      <ul>
        { selectedItems.length > 0 ? selectedItems.map((item, i) => (
          <li key={ i }>{ displayName(item) } { !disabled && renderClearButton(item.id) } </li>
        )) : null }
      </ul>
    );
  }
}

GenericFieldsSelectedItemsList.propTypes = {
  selectedItems: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.string.isRequired,
    code: PropTypes.string,
    name: PropTypes.string.isRequired,
    sort: PropTypes.number,
    isDeleted: PropTypes.bool,
  })),
};
