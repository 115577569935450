import { AccordionListForm } from "components/renewaled_ui/form_views";
import i18next from "i18next";
import React from "react";
import { InvoicingOrganization } from "utilities/api_managements/models/InvoicingOrganization";
import EligibleInvoiceConfirmationReadOnlyForm from "../../EligibleInvoiceConfirmationReadOnlyForm";
import { useHooks } from "./hooks";
import { Category, EligibleInvoiceFormFieldValue } from "./interfaces";

interface Props {
  /** 適格請求書の項目値 */
  value: EligibleInvoiceFormFieldValue;
  /** 日当かどうか */
  isAllowance: boolean;
  /** 経費科目 */
  category: Category;
}

/**
 * 適格請求書項目欄(読取専用)
 */
export const EligibleInvoiceFormReadOnlyField: React.FC<Props> = ({
  value,
  isAllowance,
  category,
}) => {
  const {
    isShowValidation,
    isValid,
    isShowRegistratedNumberField,
    isShowPaidAddressField,
  } = useHooks(value, isAllowance, category);

  const showIcon = (): JSX.Element => {
    if (!isShowValidation) {
      return <></>;
    }
    if (isValid) {
      return <i className={"fa fa-check color-success"} />;
    }
    return <i className={"fa fa-times color-danger"} />;
  };

  return (
    <AccordionListForm
      label={i18next.t("transactions.properties.eligibleInvoiceConfirmation")}
      showIcon={showIcon()}
      isViewMode
    >
      <EligibleInvoiceConfirmationReadOnlyForm
        asEligibleInvoice={value.asEligibleInvoice}
        registratedNumber={value.registratedNumber || ""}
        invoicingOrganization={
          value.invoicingOrganization as InvoicingOrganization | null
        }
        paidAddress={value.paidAddress || ""}
        isRegistratedNumberShow={isShowRegistratedNumberField}
        isPaidAddressShow={isShowPaidAddressField}
      />
    </AccordionListForm>
  );
};
