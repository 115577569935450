import Api from 'utilities/api';
import escapeRegExp from 'lodash/escapeRegExp';
import get from 'lodash/get';
import getExpenseAccountForm from '../selectors/expenseAccount';
import isNil from 'lodash/isNil';
import omit from 'lodash/omit';
import { displayMessage } from 'actions/ActionCreators';
import { getMessageFromResponse, snakecaseKeys } from 'utilities/Utils';

const prefix = 'settings/expenseAccount';

export function fetchExpenseAccounts(company = false) {
  return async (dispatch, getState) => {
    try {
      const data = await (company ? Api.companyExpenseAccounts.index() : Api.expenseAccounts.index());

      if (company) {
        dispatch(setExpenseAccounts(data));
      } else {
        dispatch(selectAccount(data));

        // 口座が未登録の場合にも口座ロック設定を反映する
        // FIXME: 会社の口座と従業員の口座の違いを表す言葉が曖昧に使われている
        const preference = await Api.userPreference.show();
        dispatch(setAccountEditable(preference.expenseAccountEditable));

        if (get(data, 'bank.name', null)) {
          dispatch(fetchBranches(data.bank.name));
        }
      }
    } catch (e) {
      dispatch(displayMessage('error', getMessageFromResponse(e)));
    }
  };
}

export function fetchBanks() {
  return async (dispatch, getState) => {
    try {
      const data = await Api.banks.index();
      dispatch(initSuggestions('banks', data.banks));
    } catch (e) {
      dispatch(displayMessage('error', getMessageFromResponse(e)));
    }
  };
}

export function fetchBranches(bank) {
  return async (dispatch, getState) => {
    try {
      const data = await Api.banks.branches(snakecaseKeys({ bankName: bank }));
      dispatch(initSuggestions('branches', data.branches));
    } catch (e) {
      dispatch(displayMessage('error', getMessageFromResponse(e)));
    }
  };
}

export function createExpenseAccount(company = false) {
  return async (dispatch, getState) => {
    try {
      dispatch(lockFormButton());

      const { selectedAccount, selectedAccountTransferFees } = getExpenseAccountForm()(getState());
      if (company) {
        await Api.companyExpenseAccounts.create(snakecaseKeys({
          account: selectedAccount,
          transferFees: selectedAccountTransferFees,
        }));
      } else {
        await Api.expenseAccounts.create(snakecaseKeys({
          bankId: selectedAccount.bank.id,
          bankBranchId: selectedAccount.bankBranch.id,
          holderKana: selectedAccount.holderKana,
          number: selectedAccount.number,
          type: selectedAccount.type,
        }));
      }

      dispatch(fetchExpenseAccounts(company));
      dispatch(displayMessage('success', '保存しました'));
    } catch (e) {
      dispatch(displayMessage('error', getMessageFromResponse(e)));
    } finally {
      dispatch(unlockFormButton());
    }
  };
}

export function updateExpenseAccount(company = false) {
  return async (dispatch, getState) => {
    try {
      dispatch(lockFormButton());

      const { selectedAccount, selectedAccountTransferFees, userId } = getExpenseAccountForm()(getState());
      if (company) {
        await Api.companyExpenseAccounts.update(snakecaseKeys({ ...selectedAccount, transferFees: selectedAccountTransferFees }));
      } else {
        await Api.expenseAccounts.create(snakecaseKeys({
          userId,
          bankId: selectedAccount.bank.id,
          bankBranchId: selectedAccount.bankBranch.id,
          holderKana: selectedAccount.holderKana,
          number: selectedAccount.number,
          type: selectedAccount.type,
        }));
      }

      if (isNil(userId)) {
        dispatch(fetchExpenseAccounts(company));
      }
      dispatch(displayMessage('success', '更新しました'));
    } catch (e) {
      dispatch(displayMessage('error', getMessageFromResponse(e)));
    } finally {
      dispatch(unlockFormButton());
    }
  };
}

export function deleteExpenseAccount(company = false) {
  return async (dispatch, getState) => {
    try {
      dispatch(lockFormButton());

      const account = getExpenseAccountForm()(getState()).selectedAccount;
      if (company) {
        await Api.companyExpenseAccounts.destroy({ id: account.id });
      } else {
        await Api.expenseAccounts.destroy({ id: account.id });
      }

      dispatch(fetchExpenseAccounts(company));
      dispatch(displayMessage('success', '削除しました'));
    } catch (e) {
      dispatch(displayMessage('error', getMessageFromResponse(e)));
    } finally {
      dispatch(unlockFormButton());
    }
  };
}

export const SET_EXPENSE_ACCOUNTS = `${prefix}/SET_EXPENSE_ACCOUNTS`;
export function setExpenseAccounts(data) {
  return {
    type: SET_EXPENSE_ACCOUNTS,
    data: data.map((datum) => parseAccount(datum)),
  };
}

export const SET_ACCOUNT_EDITABLE = `${prefix}/SET_ACCOUNT_EDITABLE`;
export function setAccountEditable(editable) {
  return {
    type: SET_ACCOUNT_EDITABLE,
    editable,
  };
}

export const SELECT_ACCOUNT = `${prefix}/SELECT_ACCOUNT`;
export function selectAccount(data = null) {
  return {
    type: SELECT_ACCOUNT,
    data: parseAccount(data),
  };
}

function parseAccount(datum) {
  if (!isNil(datum)) {
    return {
      editable: !datum.locked,
      ...omit(datum, 'locked'),
    };
  }

  return null;
}

export const SET_USER_ID = `${prefix}/SET_USER_ID`;
export function setUserId(userId = null) {
  return {
    type: SET_USER_ID,
    value: userId,
  };
}

export const SET_FORM_DATA = `${prefix}/SET_FORM_DATA`;
export function setFormData(key, value) {
  return {
    type: SET_FORM_DATA,
    key,
    value,
  };
}

export const SET_TRANSFER_FEES = `${prefix}/SET_TRANSFERFEES`;
export function setTransferFees(value) {
  return {
    type: SET_TRANSFER_FEES,
    value,
  };
}

export function setBankName(name) {
  return setFormData('bank', { name });
}

export function setBank(value) {
  return setFormData('bank', value);
}

export function setBranchName(name) {
  return setFormData('bankBranch', { name });
}

export function setBranch(value) {
  return setFormData('bankBranch', value);
}

export function setAccountType(value) {
  return setFormData('type', value);
}

export function setHolderKana(value) {
  return setFormData('holderKana', value);
}

export function setAccountNumber(value) {
  return setFormData('number', value);
}

export function setCustomerCode(value) {
  return setFormData('customerCode', value);
}

// AutoSuggest
export const INIT_SUGGESTIONS = `${prefix}/INIT_SUGGESTIONS`;
export function initSuggestions(key, data) {
  return {
    type: INIT_SUGGESTIONS,
    key,
    data,
  };
}

export function requestSuggestionsUpdate(key, { value, reason }) {
  return (dispatch, getState) => {
    if (reason === 'click' || reason === 'enter' || isNil(value) || value.length === 0) {
      dispatch(resetSuggestions(key));
      return;
    }

    const suggestions = getExpenseAccountForm()(getState())[key];
    dispatch(updateSuggestions(key, filterSuggestions(suggestions.total, value, (it) => it.name)));
  };
}

export function filterSuggestions(total, value, getter = (x) => x) {
  const reg = new RegExp(`.*${escapeRegExp(value.trim())}.*`, 'g');
  return total.filter((x) => getter(x).match(reg));
}

export const UPDATE_SUGGESTIONS = `${prefix}/UPDATE_SUGGESTIONS`;
export function updateSuggestions(key, data) {
  return {
    type: UPDATE_SUGGESTIONS,
    key,
    data,
  };
}

export const RESET_SUGGESTIONS = `${prefix}/RESET_SUGGESTIONS`;
export function resetSuggestions(key) {
  return {
    type: RESET_SUGGESTIONS,
    key,
  };
}

export function selectBank(bank) {
  return async (dispatch, getState) => {
    try {
      dispatch(setBank(bank));
      dispatch(fetchBranches(bank.name));
    } catch (e) {
      dispatch(displayMessage('error', getMessageFromResponse(e)));
    }
  };
}

export const TOGGLE_FORM_BUTTON = `${prefix}/TOGGLE_FORM_BUTTON`;
export function lockFormButton() {
  return {
    type: TOGGLE_FORM_BUTTON,
    disabled: true,
  };
}

export function unlockFormButton() {
  return {
    type: TOGGLE_FORM_BUTTON,
    disabled: false,
  };
}

export const TOGGLE_EXPENSE_ACCOUNT_MODAL = `${prefix}/TOGGLE_EXPENSE_ACCOUNT_MODAL`;
export function openExpenseAccountModal() {
  return {
    type: TOGGLE_EXPENSE_ACCOUNT_MODAL,
    value: true,
  };
}

export function closeExpenseAccountModal() {
  return {
    type: TOGGLE_EXPENSE_ACCOUNT_MODAL,
    value: false,
  };
}

export const SET_TAB_KEY = `${prefix}/SET_TAB_KEY`;
export function  setTabKey(value) {
  return {
    type: SET_TAB_KEY,
    value,
  };
}

export const TOGGLE_DELETE_CONFIRMATION_MODAL = `${prefix}/TOGGLE_DELETE_CONFIRMATION_MODAL`;
export function openDeleteConfirmationModal() {
  return {
    type: TOGGLE_DELETE_CONFIRMATION_MODAL,
    value: true,
  };
}

export function closeDeleteConfirmationModal() {
  return {
    type: TOGGLE_DELETE_CONFIRMATION_MODAL,
    value: false,
  };
}

export const TOGGLE_EXPENSE_ACCOUNT_DELETE_CONFIRMATION_MODAL = `${prefix}/TOGGLE_EXPENSE_ACCOUNT_DELETE_CONFIRMATION_MODAL`;
export function openExpenseAccountDeleteConfirmationModal() {
  return {
    type: TOGGLE_EXPENSE_ACCOUNT_DELETE_CONFIRMATION_MODAL,
    show: true,
  };
}

export function closeExpenseAccountDeleteConfirmationModal() {
  return {
    type: TOGGLE_EXPENSE_ACCOUNT_DELETE_CONFIRMATION_MODAL,
    show: false,
  };
}
