import BootstrapCard from 'components/BootstrapCard';
import Icon from 'applications/payment_requests/components/Icon';
import React, {
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';
import i18next from 'i18next';
import { CreateModal } from './CreateModal';
import { DetailDrawer } from './DetailDrawer';
import { NoLicenseForRPPage } from '../no_license/NoLicenseForRPPage';
import { Panel } from 'react-bootstrap';
import { SystemSetting } from './types';
import { Task } from 'utilities/api/models/accountingDataScheduledExports';
import { TaskTable } from './TaskTable';
import { useFetchTasks } from './hooks/useFetchTasks';
import { useLicense } from '../no_license/hooks/useLicense';

interface Props {
  id?: string;
}

const TASKS_URL = '/accounting_data_scheduled_exports/tasks';

export const IndexComponent: React.FC<Props> = ({
  id,
}) => {
  const [selectedId, setSelectedId] = useState(id || null);
  const showDetail = useMemo(() => selectedId !== null, [selectedId]);
  const [showCreateModal, setShowCreateModal] = useState<boolean>(false);
  const [tasks, setTasks] = useState<Task[]>([]);
  const [expanded, setExpanded] = useState(true);

  const { hasLicense } = useLicense();
  const { fetchTasks, processing } = useFetchTasks({ setTasks });

  const handleCloseDetail = useCallback(() => {
    setSelectedId(null);
  }, [setSelectedId]);

  const handleChange = useCallback((show: boolean) => {
    if (!show) setSelectedId(null);
    fetchTasks();
  }, [fetchTasks, setSelectedId]);

  const handleCreated = useCallback(() => {
    setShowCreateModal(false);
    fetchTasks();
  }, [fetchTasks, setShowCreateModal]);

  const handleRowClick = useCallback((task: Task) => {
    setSelectedId(task.id);
  }, [setSelectedId]);

  useEffect(() => {
    setSelectedId(id || null);
  }, [id, setSelectedId]);

  useEffect(() => {
    if (selectedId) {
      window.history.replaceState(null, '', `${TASKS_URL}/${selectedId}?resource=expense`);
    } else {
      window.history.replaceState(null, '', TASKS_URL);
    }
  }, [selectedId]);

  const systemSetting = useMemo((): SystemSetting => {
    const {
      inputGroup,
      inputProject,
      requestTypes,
      useGenericFields,
      genericFields,
    } = userPreferences.preference;

    const preReportRequest = requestTypes.find((v) => v.enable && !!v.preReportPreference);

    return {
      useProject: inputProject,
      usePaymentDepartment: inputGroup,
      usePreReport: !!preReportRequest,
      useTemporaryPayment: preReportRequest?.preReportPreference?.useTemporaryPayment || false,
      usePLP: !!userPreferences.paperlessPlanSubscriptions,
      useGenericFields,
      genericFields,
    };
  }, []);

  const isEditable = userPreferences.isAdmin || userPreferences.isAccountant;

  useEffect(() => {
    if (hasLicense) fetchTasks();
  }, [fetchTasks, hasLicense]);

  const buttons = [
    {
      color: 'primary',
      content: i18next.t('commons.actions.newEntry'),
      onClick: (ev: React.MouseEvent<HTMLButtonElement>): void => { ev.stopPropagation(); setShowCreateModal(true); },
    },
  ];

  if (!hasLicense) return <NoLicenseForRPPage />;

  return (
    <div id='task_table_expense'>
      <BootstrapCard
        hasNoMargin
        title={ i18next.t('accountingDataScheduledExports.title') }
        buttons={ isEditable ? buttons : null }
        headerElement={
          <div
            className='card-right'
            style={ { marginLeft: '10px' } }
            onClick={ (): void => setExpanded(!expanded) }
          >
            <Icon type='solid' variant={ expanded ? 'angle-up' : 'angle-down' } />
          </div>
        }
        onClickHeader={ (): void => setExpanded(!expanded) }
      >
        <Panel
          style={ { marginBottom: '0px' } }
          expanded={ expanded }
          onToggle={ (): void => setExpanded(!expanded) }
        >
          <Panel.Collapse>
            <div className='card-content'>
              <TaskTable
                isLoading={ processing }
                tasks={ tasks }
                onRowClick={ handleRowClick }
              />
            </div>
          </Panel.Collapse>
        </Panel>
        {
          selectedId && showDetail && (
            <DetailDrawer
              id={ selectedId }
              show={ showDetail }
              isEditable={ isEditable }
              systemSetting={ systemSetting }
              onClose={ handleCloseDetail }
              onChange={ handleChange }
            />
          )
        }
        <CreateModal show={ showCreateModal } onClose={ (): void => setShowCreateModal(false) } onCreated={ handleCreated } systemSetting={ systemSetting } />
      </BootstrapCard>
    </div>
  );
};
