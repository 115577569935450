import renderAmountPerPersonWrapper from "applications/transactions/utilities/renderer";
import i18next from "i18n";
import isNil from "lodash/isNil";
import PropTypes from "prop-types";
import React, { Component } from "react";
import { connect } from "react-redux";
import Formatter from "utilities/formatter";
import { calcDistance } from "utilities/Utils";
import colors from "values/colors";
import * as actions from "../actions";
import RouteSelector from "../components/RouteSelector";
import formatter from "../fare_formatter";

export class RouteDisplay extends Component {
  constructor(props) {
    super(props);

    this.state = { isModalOpen: false };

    this.handleSelect = this.handleSelect.bind(this);
    this.handleOpenModal = this.handleOpenModal.bind(this);
    this.handleCloseModal = this.handleCloseModal.bind(this);
  }

  get isRouteSelectable() {
    const { routes } = this.props;

    return !isNil(routes) && routes.length !== 0;
  }

  handleSelect(route) {
    this.props.onRouteSelect(route, this.props.isRoundTrip);
    this.handleCloseModal();
  }

  handleOpenModal() {
    if (!this.isRouteSelectable) {
      return;
    }

    this.setState({ isModalOpen: true });
  }

  handleCloseModal() {
    this.setState({ isModalOpen: false });
  }

  render() {
    const {
      className,
      amount,
      routes,
      selected,
      isRoundTrip,
      fields,
      companions,
    } = this.props;

    const formattedDistance =
      userPreferences.preference.showDistance &&
      selected &&
      calcDistance(selected, isRoundTrip)
        ? `（${Formatter.distance(calcDistance(selected, isRoundTrip))}）`
        : "";

    return (
      <div className={className} style={{ position: "relative" }}>
        <div className="fare-display">
          {renderAmountPerPersonWrapper(
            companions,
            amount,
            fields,
            "",
            true,
            "route_display",
            { selected },
          )}
          <p className="txt txt-lg">{formattedDistance}</p>
        </div>
        <div className={isNil(selected) ? "hidden" : ""}>
          {formatter.renderLabels(selected && selected.labels)}
        </div>

        <div className={`route-display${isNil(selected) ? " hidden" : ""}`}>
          {formatter.renderViaList(
            selected && (selected.via || selected.lines),
            "right",
          )}

          <div className="modal-link-wrapper">
            <a
              className={`modal-link${
                this.isRouteSelectable ? "" : " disabled"
              }`}
              onClick={this.handleOpenModal}
              style={{
                color: colors.renewaled.main,
                fontSize: "12px",
                marginTop: "8px",
              }}
            >
              {i18next.t("transactions.inputs.showOtherRoutes")}
            </a>
          </div>
        </div>
        <div>
          <RouteSelector
            routes={routes}
            closeModal={this.handleCloseModal}
            show={this.state.isModalOpen}
            onRouteSelect={this.handleSelect}
          />
        </div>
      </div>
    );
  }
}

RouteDisplay.defaultProps = {
  companions: [],
  fields: [],
};

RouteDisplay.propTypes = {
  className: PropTypes.string,
  routes: PropTypes.array,
  selected: PropTypes.object,
  isRoundTrip: PropTypes.bool,
  onRouteSelect: PropTypes.func.isRequired,
  companions: PropTypes.array,
  fields: PropTypes.array,
};

function mapStateToProps(state, ownProps) {
  const { searchBox } = state;
  return {
    amount: actions.calcAmount(searchBox.route, searchBox.checked.roundTrip),
  };
}

function mapDispatchToProps(dispatch) {
  return {
    onRouteSelect: (route, isRoundTrip) =>
      dispatch(actions.onRouteSelect(route, isRoundTrip)),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(RouteDisplay);
