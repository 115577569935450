import React from 'react';
import SendBackConfirmationModal from '../modals/SendBackConfirmationModal';
import i18next from 'i18n';
import { Button } from 'react-bootstrap';

interface Props {
  className?: string;
  isProcess?: boolean;
  disabled?: boolean;
  onSendBack: () => void;
}

const SendBackButton: React.FC<Props> = (props) => {
  const className = `${props.className}${(props.isProcess || props.disabled) ? ' disabled' : ''}`;

  const [shown, setShown] = React.useState(false);

  const handleOpen = (): void => setShown(true);
  const handleClose = (): void => setShown(false);

  return (
    <>
      <SendBackConfirmationModal
        show={ shown }
        close={ handleClose }
        onSendBack={ props.onSendBack }/>
      <Button
        className={ className }
        onClick={ handleOpen }
        bsStyle='danger'>
        { i18next.t('paperless.sendBackings.actions.sendBack') }
      </Button>
    </>
  );
};

SendBackButton.defaultProps = {
  className: '',
  isProcess: false,
  disabled: false,
};

export default React.memo(SendBackButton);
