import {
  ParentCategory,
  PersonalCategory,
} from "utilities/api_v3/models/PersonalCategory";
import * as Types from "../types";

/** 経費科目の親科目に対して再帰的にアクセスし、親からの絶対パスをビルドする */
const buildAbsolutePathName = (
  v3Category: PersonalCategory | ParentCategory | undefined,
  currentName = "",
): string => {
  // まったく親が存在しない場合は、そのままの値を返す
  if (v3Category?.parent === void 0 && currentName.length === 0) {
    return (v3Category as PersonalCategory).name;
  }

  // 再帰処理の中で親が存在しなくなった場合は、ここまでの経費科目を返す
  if (v3Category?.parent === void 0) {
    return (v3Category as PersonalCategory).name.concat("/", currentName);
  }

  // 親が存在している場合は、繋ぎこむ親のパスを再帰させる
  return buildAbsolutePathName(
    v3Category.parent,
    currentName.length === 0
      ? (v3Category as PersonalCategory).name
      : (v3Category as PersonalCategory).name.concat("/", currentName),
  );
};

/**
 * 経費科目取得 API V3 で取得する経費科目の形式を, 経費科目取得 API V1 で取得する経費科目の形式に変換する
 *
 * 背景
 *   基本的には (経費一覧などの古いコンポーネント) では 経費科目に V1 を用いている.
 *   しかし、経費科目の取得については V3 を用いるほうが高速であり、少ない取得で済むように再設計されている.
 *   このため、部分的に経費科目取得に V3 を用いており、そのレスポンスを v1 形式に変換する必要がある.
 *   本メソッドは、この互換性維持のために作成している.
 */
export const convertV3ResponseToV1Response = (
  v3Category: PersonalCategory,
): Types.Category => ({
  id: v3Category.id,
  name: v3Category.name,
  parentId: v3Category.parent?.id || null,
  absolutePathName: buildAbsolutePathName(v3Category),
});
