import CheckBoxGroup from 'components/CheckBoxGroup';
import React, { FC } from 'react';
import i18next from 'i18n';

interface Props {
  checkedMatchedOriginalReceipt: boolean;
  checkedNotMatchedOriginalReceipt: boolean;
  onChangeMatchedOriginalReceipt: (checked: boolean) => void;
  onChangeNotMatchedOriginalReceipt: (checked: boolean) => void;
}

/**
 * ペーパーレスに関する検索条件を決定するチェックボタンのグループ
 */
const PaperlessStatusCheckBoxes: FC<Props> = ({
  checkedMatchedOriginalReceipt,
  checkedNotMatchedOriginalReceipt,
  onChangeMatchedOriginalReceipt,
  onChangeNotMatchedOriginalReceipt,
}) => {
  return (
    <CheckBoxGroup
      className='col-sm-6'
      checkBoxesProps={ [{
        color: 'accent',
        label: i18next.t('reports.index.matchedOriginalReceipt.yes'),
        checked: checkedMatchedOriginalReceipt,
        onChange: (): void => { onChangeMatchedOriginalReceipt(!checkedMatchedOriginalReceipt); },
      },
      {
        color: 'accent',
        label: i18next.t('reports.index.matchedOriginalReceipt.no'),
        checked: checkedNotMatchedOriginalReceipt,
        onChange: (): void => { onChangeNotMatchedOriginalReceipt(!checkedNotMatchedOriginalReceipt); },
      }] }
      label={ i18next.t('reports.index.matchedOriginalReceipt.label') }
      labelProps={ { description: i18next.t('reports.index.matchedOriginalReceipt.description') } }
      isAllSelectable={ false }
    />
  );
};

export default PaperlessStatusCheckBoxes;
