import { isSameType } from "utilities/Utils";
import { useState } from "react";

type Response<T> = [T, (value: T) => void]

type WebStorageType = 'localStorage' | 'sessionStorage'

/** localStorageのヘルスチェック */
const isLocalStorageAvailable = (): boolean => {
  try {
    window.localStorage.setItem('test', 'test');
    window.localStorage.removeItem('test');

    return true;
  } catch (e) {
    return false;
  }
};

/**
 * Web Storage(localStorage, sessionStorage)を管理するためのhook
 * @param key storageのkey名。現状のlocalStorageに保存されているデータの命名に沿って"tk:expenses:expenseTableColumnParams" といった形でname spaceを切ってください。
 * @param initialValue 初期値
 * @param type 'localStorage' or 'sessionStorage'
 * @returns
 *  - value: storageのデータ
 *  - setValue: storageにデータをセットする
 */
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const useWebStorage = <T extends { [key: string]: any }>(
  key: string,
  initialValue: T,
  type: WebStorageType = 'localStorage',
): Response<T> => {
  const storage = type === 'localStorage' && isLocalStorageAvailable() ? window.localStorage : window.sessionStorage;

  /** storageからデータを取得します。
   *  storageにデータが無ければ初期値を返します。
  */
  const getValue = (): T => {
    try {
      const item = storage.getItem(key);
      return (item && isSameType(JSON.parse(item), initialValue)) ? JSON.parse(item) : initialValue;
    } catch (error) {
      return initialValue;
    }
  };

  /** storageに値を保存します。
   *  @param argValue 保存するデータ
   */
  const setValue = (argValue: T): void => {
    try {
      setStoredValue(argValue);

      storage.setItem(key, JSON.stringify(argValue));
    // eslint-disable-next-line no-empty
    } catch (error) {}
  };

  const [value, setStoredValue] = useState<T>(getValue);

  return [value, setValue];
};
