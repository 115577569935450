import Api from 'utilities/api';
import React, { useCallback } from 'react';
import flash from 'utilities/flash';
import i18next from 'i18n';
import styled from 'styled-components';
import { Button } from 'components/renewaled_ui/buttons';
import { FormProvider, useForm } from 'react-hook-form';
import { InputField } from 'components/renewaled_ui/forms/inputs';
import { ToolTip } from 'components/renewaled_ui/ToolTip/ToolTip';
import { getMessageFromResponse, snakecaseKeys } from 'utilities/Utils';

const FormContents = styled.div`
  display: flex;
  justify-content: space-between;

  .form-field-wrap {
    width: 38%;
  }

  .form-button-wrap {
    width: 20%;
    display:flex;
    align-items:flex-end;
    margin-bottom: 15px;
  }
`;

interface Props {
  dataSetId: string;
  onSelectNewlyCreatedItem?: (items) => void;
  onCreateComplete?:  () => void;
  isInvoice?: boolean;
}

export const GenericFieldsItemAppendForm: React.FC<Props> = ({
  dataSetId,
  onSelectNewlyCreatedItem,
  onCreateComplete,
  isInvoice = false,
}) => {
  const methods = useForm({
    mode: 'all',
    defaultValues: {
      dataSetId,
      code: '',
      name: '',
      sort: null,
      createdMethod: isInvoice ? 'users_invoice' : 'users_expense',
    },
  });

  const {
    formState,
    handleSubmit,
    reset,
    control,
  } = methods;

  const createNewItem = useCallback(async (data): Promise<void> => {
    flash.clear();

    try {
      const res = await Api.genericFields.dataSets.itemsForUsers.create(snakecaseKeys(data));
      if (onSelectNewlyCreatedItem) onSelectNewlyCreatedItem(res.item);
      if (onCreateComplete) onCreateComplete();
      reset();
      flash.success(res.message);
    } catch (error) {
      flash.error(getMessageFromResponse(error as Record<string, unknown>));
    } finally {
      // do nothing
    }
  }, [onCreateComplete, onSelectNewlyCreatedItem, reset]);

  const PopUpDisabledAddButtonMessage = (): JSX.Element | null => {
    if (!formState.isValid) {
      return (
        <div>
          { i18next.t('genericFields.items.button.disabledAddButton') }
        </div>
      );
    }

    return null;
  };

  return (
    <FormProvider { ...methods }>
      <FormContents className={ 'generic-fields-item-append-form' }>
        <div className={ 'form-field-wrap' } >
          <InputField
            name='code'
            label={  i18next.t('genericFields.items.labels.code') }
            control={ control }
            rules= { { required: true } }
            autoComplete='false'
          />
        </div>
        <div className={ 'form-field-wrap' } >
          <InputField
            name='name'
            label={ i18next.t('genericFields.items.labels.name') }
            control={ control }
            rules= { { required: true } }
            autoComplete='false'
          />
        </div>
        <div className={ 'form-button-wrap' } >
          <ToolTip
            overlay={ PopUpDisabledAddButtonMessage() }
            align='left'
          >
            <Button
              styleType='success'
              onClick={ handleSubmit(createNewItem) }
              loading={ formState.isSubmitting }
              alternativeDisabled={ !formState.isValid }
            >
              { i18next.t('genericFields.items.button.addButton') }
            </Button>
          </ToolTip>
        </div>
      </FormContents>
    </FormProvider>
  );
};
