import ExportOptionButtons from "applications/exports/components/ExportOptionButtons";
import EmbeddedFlash from "components/embedded_flash";
import ExportFormatSelect from "components/export_format_select";
import SelectContactsField from "components/fields/SelectContactsField";
import LabeledComponent from "components/LabeledComponent";
import i18next from "i18n";
import isNil from "lodash/isNil";
import moment from "moment";
import PropTypes from "prop-types";
import React, { Component } from "react";
import Api from "utilities/api";
import { fetchAsync } from "utilities/async";
import flash from "utilities/flash";
import { camelizeKeys, getMessageFromResponse, getDatePickerDayConfig } from "utilities/Utils";
import { handleExportCsv } from "./CsvExportHandler";

export default class CsvExportForm extends Component {
  constructor(props) {
    super(props);
    this.datePickerConfig = {
      language: userPreferences.locale,
      format: "yyyy/m/d",
      clearBtn: true,
      todayBtn: "linked",
      autoclose: true,
      beforeShowDay: getDatePickerDayConfig,
    };
    this.state = {
      modal: { transaction: {} },
      formatKey: "",
      jobName: "",
      transactedAt: "",
      isShowModal: false,
      contacts: [],
    };

    this.handleChangeContacts = this.handleChangeContacts.bind(this);
    this.handleFormatSelect = this.handleFormatSelect.bind(this);
    this.handleExportEmail = this.handleExportEmail.bind(this);
    this.handleExportCsv = () => {
      handleExportCsv(this.state, this.reportExportRequestPrams);
    };
  }

  componentDidMount() {
    this.loadPreferences();

    /* jQueryからDOMにアクセスするために、ModalのDOMがrenderされるまで待つ必要あり */
    setTimeout(this.handleDomRendered.bind(this), 1000);
  }

  handleDomRendered() {
    $(".transacted-at")
      .datepicker(this.datePickerConfig)
      .on("changeDate", (e) => {
        this.handleDateChange(e);
      });
  }

  handleDateChange(jQueryEvent) {
    const isoDate = jQueryEvent.date?.toISOString();
    if (isoDate === void 0) {
      this.setState({ transactedAt: "" });
    } else {
      this.setState({ transactedAt: moment(isoDate).format("YYYY/MM/DD") });
    }
  }

  async loadPreferences() {
    const { type } = this.props;

    // 仕訳フォーマット設定は経費に対してしか出力できないため
    if (
      type === "analysis" ||
      type === "report" ||
      type === "approvingRequest"
    ) {
      try {
        const data = await Api.journalEntries.exportFormats.index({
          isPublished: true,
        });
        const defaultFormat = data.find((format) => format.isDefault);
        if (defaultFormat) {
          this.setState({
            formatKey: defaultFormat.id,
            jobName: "journalEntriesForExpenses",
          });
        }
      } catch (error) {
        flash.error(getMessageFromResponse(error));
      }
    }

    // 仕訳フォーマット設定を優先
    if (this.state.formatKey) return;

    try {
      const preference = await Api.preferences.index();
      const settingParams = camelizeKeys(preference);
      const formatKey = (() => {
        switch (type) {
          case "preTemporaryPaymentAnalysis": {
            return "normal";
          }
          case "preAnalysis": {
            return "normal";
          }
          case "preReport": {
            return "normal";
          }
          default: {
            return settingParams.defaultExportFormat;
          }
        }
      })();

      const jobNames = {
        analysis: "accounting",
        aggregation: "", // typeはあるがjobはなさそう
        report: "accounting",
        approvingRequest: "accounting",
        preAnalysis: "preAccounting",
        preReport: "preAccounting",
        preTemporaryPaymentAnalysis: "preAccounting",
      };
      const jobName = jobNames[type];

      this.setState({
        formatKey,
        jobName,
        exportAggregationColumns: settingParams.exportAggregationColumns,
      });
    } catch (error) {
      flash.error(getMessageFromResponse(error));
    }
  }

  handleCloseExportModal(show) {
    this.setState({ isShowModal: false });
  }

  handleFormatSelect(converter) {
    const { formatKey, jobName } = converter;
    this.setState({ formatKey, jobName });
  }

  getTargetKey(type) {
    switch (type) {
      case "report": {
        return "reportId";
      }
      case "approvingRequest": {
        return "reportId";
      }
      case "preReport": {
        return "preReportId";
      }
      case "analysis": {
        return "analysisId";
      }
      case "preAnalysis": {
        return "preAnalysisId";
      }
      case "preTemporaryPaymentAnalysis": {
        return "preAnalysisId";
      }
      default: {
        return "aggregationId";
      }
    }
  }

  get reportApiPermission() {
    if (userPreferences.isAdmin) {
      return "admin";
    }
    if (userPreferences.isApprover) {
      return "approver";
    }
    return "list";
  }

  /**
   * 集計のエクスポートはまだ API が作られていないので, 既存のAPIを選択する
   * また, 送信先にどこを選択するかは API ごとに違うので, API 選択時にパラメータへ差し込む
   */
  buildExportMailApi(params) {
    // @todo ExportModal と強い依存関係にあるので, コンポーネント機能を分離すること
    if (!isNil(params.reportId)) {
      return {
        api: Api.expenses.reports.export,
        params: {
          ...params,
          userIds: this.state.contacts.map((x) => x.id),
          exportCondition: {
            id: params[this.getTargetKey(this.props.type)],
            for: this.reportApiPermission,
          },
        },
      };
    }

    if (isNil(this.props.targetId)) {
      return {
        api: Api.expenses.reports.export,
        params: {
          ...params,
          userIds: this.state.contacts.map((x) => x.id),
        },
      };
    }

    return {
      api: Api.exports.csv,
      params: { ...params, email: this.state.contacts.map((x) => x.email) },
    };
  }

  handleExportEmail() {
    if (this.state.contacts.length === 0) {
      flash.error(i18next.t("exports.errors.emailMissing"));
      return Promise.resolve();
    }

    if (this.state.jobName === "journalEntriesForExpenses") {
      const params = this.reportExportRequestPrams;
      params.userIds = this.state.contacts.map((x) => x.id);

      return fetchAsync(
        Api.accounting.journalEntries.export.emailForExpenses,
        params,
      ).then(() => {
        flash.success(i18next.t("exports.messages.sendEmailSuccess"));
      });
    }

    const exportApi = this.buildExportMailApi(this.reportExportRequestPrams);
    return fetchAsync(exportApi.api, exportApi.params).then(() => {
      flash.success(i18next.t("exports.messages.sendEmailSuccess"));
    });
  }

  handleChangeContacts(contacts) {
    this.setState({ contacts });
  }

  get reportExportRequestPrams() {
    const params = {
      formatKey: this.state.formatKey,
      transactedAt: this.state.transactedAt,
    };

    // 明確に出力対象が渡されていない場合, 出力対象と対象の絞り込み条件を送信する.
    if (isNil(this.props.targetId)) {
      params.exportTargetType = this.props.type;
      params.exportCondition = this.props.exportCondition;
    } else {
      const targetKey = this.getTargetKey(this.props.type);
      params[targetKey] = this.props.targetId;
    }

    return params;
  }

  render() {
    return (
      <div className="row">
        <EmbeddedFlash
          ref="embeddedFlash"
          style={{ padding: "0px 15px 15px" }}
        />
        {this.renderContent()}
      </div>
    );
  }

  renderContent() {
    return (
      <div className="form-horizontal">
        <div className="form-group">
          <ExportFormatSelect
            defaultExportFormat={this.state.formatKey}
            onFormatSelect={this.handleFormatSelect}
            type={this.props.type}
          />
        </div>

        <LabeledComponent
          className="form-group"
          labelClass="col-sm-3"
          label={i18next.t("exports.inputs.recipient")}
        >
          <div className="col-sm-6">
            <SelectContactsField onChange={this.handleChangeContacts} />
          </div>
        </LabeledComponent>

        <LabeledComponent
          className="form-group"
          labelClass="col-sm-3"
          label={i18next.t("exports.inputs.transactionDate")}
        >
          <div className="col-sm-6">
            <input
              readOnly
              style={{ background: "white" }}
              ref="transactedAt"
              type="text"
              className="form-control transacted-at"
              placeholder={i18next.t("exports.messages.enterTransactionDate")}
            />
          </div>
        </LabeledComponent>

        <div className="form-group">
          <ExportOptionButtons
            type={this.props.type}
            className="col-sm-6 col-sm-offset-3"
            targetId={this.props.targetId}
            targetKey={this.getTargetKey(this.props.type)}
            onExportEmail={this.handleExportEmail}
            onExportCsv={this.handleExportCsv}
            exportType={this.props.exportType}
            formatKey={this.state.formatKey}
            jobName={this.state.jobName}
            transactedAt={this.state.transactedAt}
          />
        </div>
      </div>
    );
  }
}

CsvExportForm.propTypes = {
  type: PropTypes.oneOf([
    "report",
    "preReport",
    "analysis",
    "preAnalysis",
    "preTemporaryPaymentAnalysis",
    "aggregation",
  ]), // 経費申請、経費集計
  reportId: PropTypes.string,
  analysisId: PropTypes.string,
  exportType: PropTypes.string,
  targetId: PropTypes.string,
  exportCondition: PropTypes.object,
  /**
   * 出力する対象の絞り込み条件
   * 明確に出力対象が渡されていない場合は必須であるため例外を投げる.
   * 絞り込みの条件は出力対象によって異なるため, PropTypes.object とする.
   */
  exportConditionIsRequired(props, propName, componentName) {
    if (props.targetId === null && props.exportCondition !== PropTypes.object) {
      return new TypeError(
        `Invalid prop '${propName}' supplied to ${componentName}: exportCondition is required when 'targetId' is null`,
      );
    }
    return null;
  },
};
