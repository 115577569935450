import i18next from "i18n";
import React from "react";
import { empty } from "utilities/formatter";
import { Editor, ExpenseHistory } from "../types";

/** 編集タイプのテキスト取得 */
export const historyTypeText = (
  historyType: ExpenseHistory["type"],
): string => {
  switch (historyType) {
    case "add":
      return i18next.t("transactions.histories.types.add");
    case "edit":
      return i18next.t("transactions.histories.types.edit");
    case "delete":
      return i18next.t("transactions.histories.types.delete");
    case "split":
      return i18next.t("transactions.histories.types.split");
    default:
      return "";
  }
};

/** 編集日時取得 */
export const formatChangedAt = (date: string): string => {
  return new Date(date).toLocaleString();
};

/** 編集者名取得 */
export const formatEditor = (editor: Editor): JSX.Element => {
  if (editor.type === "system")
    return empty(i18next.t("transactions.histories.editorTypes.system"));

  return (
    <>
      <span>{editor.name}</span>
      {editor.agent && (
        <>
          <br />
          <span>({editor.agent.name})</span>
        </>
      )}
    </>
  );
};
