import ViewerChangeButton from "components/renewaled_ui/FileViewer/Viewer/BrowserPDF/parts/ViewerChangeButton";
import IconToggle from "components/renewaled_ui/FileViewer/Viewer/PDF/parts/IconToggle";
import React from "react";
import { DropzoneInputProps, DropzoneRootProps } from "react-dropzone";
import styled from "styled-components";
import { useHooks } from "./hooks";

const Spacer = styled.div`
  width: 10px;
`;
const SpacerS = styled.div`
  width: 5px;
`;
const Wrapper = styled.div`
  color: #ffffffe0;
`;
const MainBar = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 50px;
  padding: 0 10px;
  background: #2e2e38;
`;
const Left = styled.div`
  display: flex;
  align-items: center;
`;
const Center = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 12px;
`;
const Right = styled.div`
  display: flex;
  justify-content: end;
  align-items: center;
  height: 100%;
`;
const PageSize = styled.div`
  margin-left: 4px;
  color: #ffffff9c;
`;
const ScaleArea = styled.div`
  display: flex;
  align-items: center;
  height: 100%;
  background: #0000003b;
`;
const ScaleButton = styled.div`
  display: flex;
  align-items: center;
  height: 100%;
  padding: 0 8px;
  transition: 0.2s;
  cursor: pointer;

  &:hover {
    cursor: pointer;
    opacity: 0.8;
  }
`;
const Scale = styled.div`
  font-size: 12px;
`;
const Rotate = styled.div`
  display: flex;
  align-items: center;
  background: #0000003b;
  padding: 6px;
  border-radius: 50px;
  transition: 0.2s;
  cursor: pointer;

  &:hover {
    opacity: 0.8;
  }
`;
const RotateNumber = styled.div`
  margin-left: 5px;
  font-size: 12px;
`;
const UploadButton = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  background: #294690;
  padding: 8px 10px;
  border-radius: 4px;
  transition: 0.2s;
  cursor: pointer;

  &:hover {
    opacity: 0.8;
  }
`;
const DownloadButton = styled.a`
  display: flex;
  justify-content: center;
  align-items: center;
  background: #294690;
  color: #ffffffe0;
  padding: 8px 10px;
  border-radius: 4px;
  transition: 0.2s;
  cursor: pointer;

  &:hover {
    color: #ffffffe0;
    opacity: 0.8;
  }
  &:focus {
    color: #ffffffe0;
    opacity: 0.8;
  }
`;
const DeleteButton = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  background: #b63939;
  padding: 8px 10px;
  border-radius: 4px;
  transition: 0.2s;
  cursor: pointer;

  &:hover {
    opacity: 0.8;
  }
`;
const ToolBarButton = styled.div`
  display: flex;
  align-items: center;
  height: 100%;
  padding: 0 2px;
  font-size: 18px;
  transition: 0.2s;
  cursor: pointer;

  &:hover {
    opacity: 0.8;
  }
`;
const ExpandButton = styled.div`
  display: flex;
  align-items: center;
  width: 25px;
  justify-content: center;
  height: 100%;
  font-size: 14px;
  transition: 0.2s;
  cursor: pointer;
  background: #0000003b;

  &:hover {
    opacity: 0.8;
  }
`;
const FileName = styled.div`
  font-size: 12px;
  width: 120px;
  opacity: 0.5;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;
const SubBar = styled.div`
  background: #2b2b2f;
  height: 30px;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;
const SubLeft = styled.div`
  padding-left: 10px;
`;
const SubRight = styled.div`
  display: flex;
  justify-content: flex-end;
  height: 100%;
`;

interface Props {
  /** 現在のページ */
  readonly rootProps: DropzoneRootProps;
  /** 現在のページ */
  readonly currentPage: number | "";
  /** PDFの最大ページ数 */
  readonly maxPage: number;
  /** サイズのパーセント 0 ~ 1 */
  readonly scaleSize: number;
  /** ファイルinput */
  readonly getInputProps: (
    props?: DropzoneInputProps | undefined,
  ) => DropzoneInputProps;
  /** ダウンロードリンク */
  readonly downloadLink: string;
  /** ダウンロードファイル名 */
  readonly fileName: string;
  /** 手のひらツールか */
  readonly isHand: boolean;
  /** 縦幅100%か横幅100%か */
  readonly isExpandHeight: boolean;
  /** 回転の度数 */
  readonly rotate: number;
  /** サブバーを表示するか */
  readonly isShowSubBar: boolean;
  /** アップロード可能か */
  readonly canUpload: boolean;
  /** ダウンロード可能か */
  readonly canDownload: boolean;
  /** 回転が可能か */
  readonly canRotate: boolean;
  /** 手のひらツールが可能か */
  readonly canHand: boolean;
  /** 拡大・縮小が可能か */
  readonly canTransform: boolean;
  /** 削除可能か */
  readonly canDelete: boolean;
  /** 回転を押した */
  readonly onClickRotate: () => void;
  /** 削除を押した */
  readonly onClickDelete: (
    e: React.MouseEvent<HTMLDivElement, MouseEvent>,
  ) => void;
  /** 手のひらツール/矢印を押した */
  readonly onClickHand: (e: React.MouseEvent<HTMLElement, MouseEvent>) => void;
  /** サイズを元に戻す押した */
  readonly onClickExpand: () => void;
  /** 拡大を押した */
  readonly onClickScaleUp: (
    e: React.MouseEvent<HTMLElement, MouseEvent>,
  ) => void;
  /** 縮小を押した */
  readonly onClickScaleDown: (
    e: React.MouseEvent<HTMLElement, MouseEvent>,
  ) => void;
  /** 切り替えを押した */
  readonly onClickChangeViewer: (
    e: React.MouseEvent<HTMLDivElement, MouseEvent>,
  ) => void;
}

/**
 * PDFビューアーのツールバー
 */
const PDFToolBar: React.FC<Props> = (p) => {
  const { wrapperRef, isShowSubBar, scalePercent } = useHooks(p.scaleSize);

  return (
    <Wrapper className="viewer-pdf-view-area-wrapper-toolbar-wrapper">
      <MainBar
        className="viewer-pdf-view-area-wrapper-toolbar-mainbar"
        ref={wrapperRef}
        onClick={(e: React.MouseEvent<HTMLElement, MouseEvent>): void =>
          e.stopPropagation()
        }
      >
        <Left
          className="viewer-pdf-view-area-wrapper-toolbar-left"
          style={{
            width: isShowSubBar ? "38%" : "44%",
          }}
        >
          {p.canUpload && (
            <>
              <UploadButton
                className="viewer-pdf-view-area-wrapper-toolbar-upload-button"
                onClick={p.rootProps.onClick}
              >
                <i className="fas fa-exchange-alt" />
                <span role="button">
                  <input {...p.getInputProps()} />
                </span>
              </UploadButton>
              <Spacer className="viewer-pdf-view-area-wrapper-toolbar-spacer" />
            </>
          )}
          {p.canDownload && (
            <>
              <DownloadButton
                className="viewer-pdf-view-area-wrapper-toolbar-download-button"
                href={p.downloadLink}
                download={p.fileName}
              >
                <i className="fa fa-download" />
              </DownloadButton>
              <Spacer className="viewer-pdf-view-area-wrapper-toolbar-spacer" />
            </>
          )}
          {!isShowSubBar && (
            <FileName className="viewer-pdf-view-area-wrapper-toolbar-filename">
              {p.fileName}
            </FileName>
          )}
        </Left>
        <Center
          className="viewer-pdf-view-area-wrapper-toolbar-center"
          style={{
            width: isShowSubBar ? "24%" : "12%",
          }}
        >
          <div>{p.currentPage}</div>
          <PageSize className="viewer-pdf-view-area-wrapper-toolbar-pagesize">
            / {p.maxPage}
          </PageSize>
        </Center>
        <Right
          className="viewer-pdf-view-area-wrapper-toolbar-right"
          style={{
            width: isShowSubBar ? "38%" : "44%",
          }}
        >
          {p.canRotate && !isShowSubBar && (
            <Rotate
              className="viewer-pdf-view-area-wrapper-toolbar-rotate"
              onClick={p.onClickRotate}
            >
              <i className="fa fa-redo-alt" />
              <RotateNumber className="viewer-pdf-view-area-wrapper-toolbar-rotatenumber">
                {p.rotate}°
              </RotateNumber>
            </Rotate>
          )}
          {p.canHand && !isShowSubBar && (
            <>
              <Spacer className="viewer-pdf-view-area-wrapper-toolbar-spacer" />
              <ToolBarButton
                className="viewer-pdf-view-area-wrapper-toolbar-toolbarbutton"
                onClick={p.onClickHand}
              >
                <IconToggle
                  isOn={p.isHand}
                  iconClassOn="fa fa-mouse-pointer"
                  iconClassOff="fa fa-hand-paper"
                />
              </ToolBarButton>
            </>
          )}
          {p.canTransform && !isShowSubBar && (
            <>
              <Spacer className="viewer-pdf-view-area-wrapper-toolbar-spacer" />
              <ExpandButton
                className="viewer-pdf-view-area-wrapper-toolbar-expand-button"
                onClick={p.onClickExpand}
              >
                <IconToggle
                  isOn={p.isExpandHeight}
                  iconClassOn="fas fa-arrows-alt-h"
                  iconClassOff="fas fa-arrows-alt-v"
                />
              </ExpandButton>
              <SpacerS className="viewer-pdf-view-area-wrapper-toolbar-spacer-s" />
              <ScaleArea className="viewer-pdf-view-area-wrapper-toolbar-scalearea">
                <ScaleButton
                  className="viewer-pdf-view-area-wrapper-toolbar-scalebutton"
                  onClick={p.onClickScaleDown}
                >
                  <i className="fa fa-minus" />
                </ScaleButton>
                <Scale className="viewer-pdf-view-area-wrapper-toolbar-scale">
                  {scalePercent}%
                </Scale>
                <ScaleButton
                  className="viewer-pdf-view-area-wrapper-toolbar-scalebutton"
                  onClick={p.onClickScaleUp}
                >
                  <i className="fa fa-plus" />
                </ScaleButton>
              </ScaleArea>
            </>
          )}
          <SpacerS className="viewer-pdf-view-area-wrapper-toolbar-spacer-s" />
          <ViewerChangeButton onClick={p.onClickChangeViewer} />
          {p.canDelete && (
            <>
              <Spacer className="viewer-pdf-view-area-wrapper-toolbar-spacer" />
              <DeleteButton
                className="viewer-pdf-view-area-wrapper-toolbar-deletebutton"
                onClick={p.onClickDelete}
              >
                <i className="fas fa-trash-alt" />
              </DeleteButton>
            </>
          )}
        </Right>
      </MainBar>

      {/* 小さい画面の場合に表示するサブバー */}
      {isShowSubBar && p.isShowSubBar && (
        <SubBar className="viewer-pdf-view-area-wrapper-toolbar-subbar">
          <SubLeft className="viewer-pdf-view-area-wrapper-toolbar-subleft">
            {
              <FileName className="viewer-pdf-view-area-wrapper-toolbar-filename">
                {p.fileName}
              </FileName>
            }
          </SubLeft>
          <SubRight className="viewer-pdf-view-area-wrapper-toolbar-subright">
            {p.canRotate && (
              <Rotate
                className="viewer-pdf-view-area-wrapper-toolbar-rotate"
                onClick={p.onClickRotate}
              >
                <i className="fa fa-redo-alt" />
                <RotateNumber className="viewer-pdf-view-area-wrapper-toolbar-rotatenumber">
                  {p.rotate}°
                </RotateNumber>
              </Rotate>
            )}
            {p.canHand && (
              <>
                <Spacer className="viewer-pdf-view-area-wrapper-toolbar-spacer" />
                <ToolBarButton
                  className="viewer-pdf-view-area-wrapper-toolbar-toolbarbutton"
                  onClick={p.onClickHand}
                >
                  <IconToggle
                    isOn={p.isHand}
                    iconClassOn="fa fa-mouse-pointer"
                    iconClassOff="fa fa-hand-paper"
                  />
                </ToolBarButton>
              </>
            )}
            {p.canTransform && (
              <>
                <Spacer className="viewer-pdf-view-area-wrapper-toolbar-spacer" />
                <ExpandButton
                  className="viewer-pdf-view-area-wrapper-toolbar-expand-button"
                  onClick={p.onClickExpand}
                >
                  <IconToggle
                    isOn={p.isExpandHeight}
                    iconClassOn="fas fa-arrows-alt-h"
                    iconClassOff="fas fa-arrows-alt-v"
                  />
                </ExpandButton>
                <Spacer className="viewer-pdf-view-area-wrapper-toolbar-spacer" />
                <ScaleArea>
                  <ScaleButton
                    className="viewer-pdf-view-area-wrapper-toolbar-scalebutton"
                    onClick={p.onClickScaleDown}
                  >
                    <i className="fa fa-minus" />
                  </ScaleButton>
                  <Scale className="viewer-pdf-view-area-wrapper-toolbar-scale">
                    {scalePercent}%
                  </Scale>
                  <ScaleButton
                    className="viewer-pdf-view-area-wrapper-toolbar-scalebutton"
                    onClick={p.onClickScaleUp}
                  >
                    <i className="fa fa-plus" />
                  </ScaleButton>
                </ScaleArea>
              </>
            )}
          </SubRight>
        </SubBar>
      )}
    </Wrapper>
  );
};

export default PDFToolBar;
