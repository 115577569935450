import { TableColumnControlCacheParams, TableColumnControlParam } from 'components/renewaled_ui/ModalColumnControl/interface';
import { useState, useEffect } from 'react';

interface UseReturn {
  readonly tableColumns: TableColumnControlParam[];
  readonly canUpColumn: (accessor: string) => boolean;
  readonly canDownColumn: (accessor: string) => boolean;
  readonly setColumns: (columns: TableColumnControlParam[]) => void;
  readonly toggleVisible: (accessor: string) => void;
  readonly upTopColumn: (accessor: string) => void;
  readonly upColumn: (accessor: string) => void;
  readonly downColumn: (accessor: string) => void;
  readonly reset: () => void;
}

/**
 * カラムの制御をする
 * defaultColumns: 全ての列情報
 * cacheColumns: LocalStorageに保存されている列情報
 */
export const useColumnControl = (selectableColumns: TableColumnControlParam[], cacheColumns: TableColumnControlCacheParams): UseReturn => {
  /**
   * indexの最大値を取得する
   */
  const maxIndex = (): number => {
    const columnsIndexs = selectableColumns.map((c) => c.index || 0);
    const cacheIndexs = Object.values(cacheColumns).map((c) => c.index || 0);
    return Math.max(...columnsIndexs, ...cacheIndexs);
  };

  const lastIndex = maxIndex() + 1;

  /**
   * ソートする
   */
  const sortColumns = (columns: TableColumnControlParam[]): TableColumnControlParam[] => {
    const columnsLength = columns.length;
    return columns.sort((a, b) => {
      const aIndex = a.index ?? columnsLength;
      const bIndex = b.index ?? columnsLength;
      if (aIndex > bIndex) return 1;
      if (aIndex < bIndex) return -1;
      return 0;
    });
  };

  /**
   * 順番をindexの再割り当て
   */
  const asignIndex = (columns: TableColumnControlParam[]): TableColumnControlParam[] => {
    return columns.map((c, index) => ({
      ...c,
      index,
    }));
  };

  /**
   * カラムの初期値
   */
  const getDefaultColumn = (): TableColumnControlParam[] => {
    // キャッシュに存在しないカラムがあれば追加する
    const margedColumns: TableColumnControlParam[] = selectableColumns.map((c) => {
      // キャッシュに存在する場合
      const hasColumn = Object.prototype.hasOwnProperty.call(cacheColumns, c.accessor);
      if (hasColumn) {
        return {
          accessor: c.accessor,
          show: cacheColumns[c.accessor].show,
          // 非表示なら末尾に追加
          index: cacheColumns[c.accessor].show ? cacheColumns[c.accessor].index : lastIndex,
          Header: c.Header,
        };
      }

      // キャッシュはすでにあり、accessorがキャッシュに存在しない場合
      // 新規カラムの場合は先頭に表示する
      if (Object.keys(cacheColumns).length !== 0) {
        return {
          ...c,
          index: -1,
        };
      }

      // キャッシュに存在しない場合はデフォルト値を返す
      return {
        ...c,
        // 非表示なら末尾に追加
        index: c.show ? c.index : lastIndex,
      };
    });

    // ソートする
    const sortedColumns = sortColumns(margedColumns);

    // indexを再割り当て
    return asignIndex(sortedColumns);
  };

  const [tableColumns, setTableColumns] = useState<TableColumnControlParam[]>(getDefaultColumn());

  /**
   * カラム情報もしくはキャッシュ値が変更されたら再代入する
   */
  useEffect(() => {
    setTableColumns(getDefaultColumn());
  }, [selectableColumns, cacheColumns]);

  /**
   * 上に移動できるか
   */
  const canUpColumn = (accessor: string): boolean => {
    const t = tableColumns.find((column) => column.accessor === accessor);
    if (!t) return false;
    return t.index !== 0;
  };

  /**
   * 下に移動できるか
   */
  const canDownColumn = (accessor: string): boolean => {
    const t = tableColumns.find((column) => column.accessor === accessor);
    if (!t) return false;
    if (!t.show) return false;
    return t.index !== (tableColumns.filter((tc) => tc.show).length - 1);
  };

  /**
   * 表示に切り替え
   */
  const showColumn = (accessor: string): void => {
    const arr = tableColumns.map((c) => {
      // 表示し、indexを最後尾にする
      if (c.accessor === accessor) {
        return {
          ...c,
          show: true,
          index: tableColumns.filter((t) => t.show).length,
        };
      }
      // 非表示のものはindexを1つずらす
      if (!c.show) {
        return {
          ...c,
          index: (c.index ?? tableColumns.length) + 1,
        };
      }
      return c;
    });
    // ソートする
    const sortedColumns = sortColumns(arr);
    // indexを再割り当て
    const asignedColumns = asignIndex(sortedColumns);
    // 反映する
    setTableColumns(asignedColumns);
  };

  /**
   * 非表示に切り替え
   */
  const disableColumn = (accessor: string): void => {
    const arr = tableColumns.map((c) => {
      if (c.accessor !== accessor) return c;
      return {
        ...c,
        show: false,
        index: tableColumns.length,
      };
    });
    // ソートする
    const sortedColumns = sortColumns(arr);
    // indexを再割り当て
    const asignedColumns = asignIndex(sortedColumns);
    // 反映する
    setTableColumns(asignedColumns);
  };

  /**
   * 表示/非表示の切り替え
   */
  const toggleVisible = (accessor: string): void => {
    const column = tableColumns.find((c) => c.accessor === accessor);
    if (!column) return;

    // 非表示にする場合
    if (column.show) {
      disableColumn(accessor);
      return;
    }

    // 表示にする場合
    showColumn(accessor);
  };

  /**
   * 一番上に移動
   */
  const upTopColumn = (accessor: string): void => {
    // 一番上に設定
    const arr = tableColumns.map((c) => {
      if (c.accessor !== accessor) return c;
      return {
        ...c,
        index: -1,
      };
    });
    // ソートする
    const sortedColumns = sortColumns(arr);
    // indexを再割り当て
    const asignedColumns = asignIndex(sortedColumns);
    // 反映する
    setTableColumns(asignedColumns);
  };

  /**
   * カラムの入れ替え
   */
  const swapIndexColumn = (firstIndex: number, nextIndex: number): TableColumnControlParam[] => {
    return tableColumns.map((c, index) => {
      if (index === firstIndex) {
        return {
          ...c,
          index: nextIndex,
        };
      }
      if (index === nextIndex) {
        return {
          ...c,
          index: firstIndex,
        };
      }
      return c;
    });
  };

  /**
   * 上に移動
   * 上のカラムの入れ替えをする
   */
  const upColumn = (accessor: string): void => {
    const targetIndex = tableColumns.findIndex((c) => c.accessor === accessor);

    // 先頭の場合は実行しない
    if (targetIndex <= 0) return;
    const prevIndex = targetIndex - 1;

    // indexの入れ替えをする
    const swapIndexColumns = swapIndexColumn(targetIndex, prevIndex);
    // ソートする
    const sortedColumns = sortColumns(swapIndexColumns);
    // 反映する
    setTableColumns(sortedColumns);
  };

  /**
   * 下に移動
   * 下のカラムの入れ替えをする
   */
  const downColumn = (accessor: string): void => {
    const lastShowIndex = tableColumns.filter((t) => t.show).length - 1;
    const targetIndex = tableColumns.findIndex((c) => c.accessor === accessor);

    // 最後の場合は実行しない
    if (targetIndex >= lastShowIndex) return;
    const nextIndex = targetIndex + 1;

    // indexの入れ替えをする
    const swapIndexColumns = swapIndexColumn(targetIndex, nextIndex);
    // ソートする
    const sortedColumns = sortColumns(swapIndexColumns);
    // 反映する
    setTableColumns(sortedColumns);
  };

  /**
   * モーダルを開いた時の状態にする
   */
  const reset = (): void => {
    setTableColumns(getDefaultColumn());
  };

  /**
   * columsをセットする
   */
  const setColumns = (columns: TableColumnControlParam[]): void => {
    const margedColumns: TableColumnControlParam[] = columns.map((c) => ({
      ...c,
      // 非表示なら末尾に追加
      index: c.show ? c.index : lastIndex,
    }));
    // ソートする
    const sortedColumns = sortColumns(margedColumns);
    const asignedColumns = asignIndex(sortedColumns);
    setTableColumns(asignedColumns);
  };

  return {
    reset,
    tableColumns,
    canUpColumn,
    canDownColumn,
    setColumns,
    toggleVisible,
    upTopColumn,
    upColumn,
    downColumn,
  };
};
