import compact from "lodash/compact";
import { ReportForIndex } from "utilities/api_payment_requests/responses/reports/IndexResponse";

// 含まれる外貨通貨が全てXXX＝通貨不明のReportを判定するSpecification
export class UnknownForeignCurrencyReportSpecification {
  private report: ReportForIndex;

  constructor(report: ReportForIndex) {
    this.report = report;
  }

  public isSatisfied(): boolean {
    return this.report.hasForeignCurrency && this.hasOnlyUnknownForeignCurrency();
  }

  private hasOnlyUnknownForeignCurrency(): boolean {
    if (this.report.foreignCurrencyTotalAmounts.length === 0) return false;

    // XXX が含まれている項目を抽出
    const result = compact(this.report.foreignCurrencyTotalAmounts.map((amount: string) => {
      const sliced = amount.split(':');

      if (sliced.length === 2 && sliced[0] === 'XXX') {
        return sliced[0];
      }

      return undefined;
    }));

    return result.length === this.report.foreignCurrencyTotalAmounts.length;
  }
}
