export interface State {
  saml: boolean;
  id?: string;
  hasSubdomain: boolean;
}
const initialState: State = {
  saml: false,
  hasSubdomain: false,
};

const licence = (state: State = initialState): State => {
  return state;
};

export default licence;
