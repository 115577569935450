import SimpleModal from "components/SimpleModal";
import i18next from "i18n";
import React from "react";
import MarkingAsMatchedForm from "../forms/MarkingAsMatchedForm";

interface Props {
  close: () => void;
  comment: string;
  disabled?: boolean;
  onCommentChange: (comment: string) => void;
  onSubmitButtonClick: () => void;
  show: boolean;
}

const MarkingAsMatchedModal: React.FC<Props> = (props) => {
  const {
    close,
    comment,
    disabled = false,
    onCommentChange,
    onSubmitButtonClick,
    show,
  } = props;

  return (
    <SimpleModal
      buttons={[
        {
          className: disabled ? "disabled" : "",
          color: "primary",
          content: i18next.t(
            "paperless.expense.modals.markingAsMatchedModal.footer.button",
          ),
          disabled,
          onClick: onSubmitButtonClick,
        },
      ]}
      close={close}
      show={show}
      title={i18next.t("paperless.expense.modals.markingAsMatchedModal.title")}
    >
      <MarkingAsMatchedForm
        comment={comment}
        disabled={disabled}
        onCommentChange={onCommentChange}
      />
    </SimpleModal>
  );
};

export default MarkingAsMatchedModal;
