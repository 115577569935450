import { CommonRequestSearchSpecification, KeihiReportRequestSearchSpecification } from './index';
import { CompositeSpecification } from './RequestSearchSpecification/index';
import { ReportRequestSearchSpecification as PaymentRequestReportRequestSearchSpecification } from 'applications/payment_requests/requests/specifications/ReportRequestSearchSpecification';

export class RequestSearchSpecification {
  compositeSpecification: CompositeSpecification;

  useDkRequests: boolean;

  usePaymentRequest: boolean;

  constructor(
    forApprover: boolean,
  ) {
    const { preference: { useDkRequests, usePaymentRequest } } = userPreferences;
    const commonRequestSearchSpecification = new CommonRequestSearchSpecification(forApprover);
    const keihiReportRequestSearchSpecification = new KeihiReportRequestSearchSpecification(forApprover);
    const paymentRequestReportRequestSearchSpecification = new PaymentRequestReportRequestSearchSpecification(forApprover);

    this.useDkRequests = useDkRequests;
    this.usePaymentRequest = usePaymentRequest;
    this.compositeSpecification = commonRequestSearchSpecification
      .or(keihiReportRequestSearchSpecification)
      .or(paymentRequestReportRequestSearchSpecification);
  }

  isSearchable(form: string): boolean {
    return this.compositeSpecification.isSatisfiedBy(form);
  }

  /**
   * 表示するステータス一覧
   * @todo サービスごとに分けることを検討すること
   */
  searchableApprovalStatuses(): string[] {
    if (!this.useDkRequests && this.usePaymentRequest) {
      return [
        'applying',
        'created',
        'recalled',
        'rejected',
        'approved',
        'paid',
      ];
    }

    return [
      'applying',
      'created',
      'recalled',
      'rejected',
      'unsettled',
      'settled',
      'approved',
      'paid',
    ];
  }
}
