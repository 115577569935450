import React from 'react';
import i18next from 'i18n';
import { OverlayTrigger, Popover } from 'react-bootstrap';

type Placement = 'top' | 'right' | 'bottom' | 'left';

export interface Props {
  className?: string;
  description?: string;
  placement?: Placement;
  popoverTitle?: string;
  trigger?: string[];
  rootClose?: boolean;
  style?: React.CSSProperties;
}

/**
 * フォームのラベルを描画するクラス
 * Bootstrapのcontrol-labelに対応
 * 入力項目の説明をポップオーバーで表示できる
 *
 * @note ポップオーバーが必要なければ import { ControlLabel, ControlLabelProps } from 'react-bootstrap';
 * @todo 必須入力項目かどうかを表現できるようにする
 */
export const ControlLabel: React.FC<Props> = React.memo(({
  children, className, description, placement, popoverTitle, trigger, rootClose, style,
}) => {
  const labelClassName = ['control-label', className].filter((x) => x).join(' ');

  const descriptionPopover = (
    <Popover id='description' title={ popoverTitle || i18next.t('approvals.flows.description') }>
      { description }
    </Popover>
  );

  return (
    <label className={ labelClassName } style={ style }>
      { children }
      {
        description && (
          <OverlayTrigger
            overlay={ descriptionPopover }
            placement={ placement || 'top' }
            trigger={ trigger || ['click', 'hover'] }
            rootClose={ rootClose || false }
          >
            <i className='far fa-question-circle' style={ { color: '#AAA', marginLeft: '6px', cursor: 'pointer' } } />
          </OverlayTrigger>
        )
      }
    </label>
  );
});
