import React from "react";

export interface Props {
  /** 右ドロワータイトル */
  readonly title: string | JSX.Element;
  /** 閉じるボタンを表示するか */
  readonly showCloseButton?: boolean;
  /** 「次」「前」ボタンを表示するか */
  readonly showNextPrevButton?: boolean;
  /** 「次」ボタンを押した */
  readonly onGoNext?: () => void;
  /** 「前」ボタンを押した */
  readonly onGoPrev?: () => void;
  /** 閉じるを押した */
  readonly onClose: () => void;
}

/**
 * 右ドロワーのヘッダー
 */
export const RightDrawerHeader: React.FC<Props> = ({
  title,
  showCloseButton = true,
  showNextPrevButton = false,
  onGoNext,
  onGoPrev,
  onClose,
}) => {
  return (
    <header className="right-drawer-header">
      <div className="right-drawer-title">{title}</div>

      <div className="right-drawer-header-toolbar">
        {showNextPrevButton && (
          <div className="prev-next-icons">
            <div
              className={`prev-icon ${!onGoPrev && "disabled"}`}
              onClick={onGoPrev}
              title="prev-icon"
            >
              <i className="fa fa-arrow-up" />
            </div>
            <div
              className={`next-icon ${!onGoNext && "disabled"}`}
              onClick={onGoNext}
              title="next-icon"
            >
              <i className="fa fa-arrow-down" />
            </div>
          </div>
        )}

        {showCloseButton && (
          <div
            className="close-button"
            onClick={onClose}
            hidden={!showCloseButton}
            title="close-button"
          >
            <i
              className="fa fa-times"
              style={{
                fontSize: "18px",
              }}
            />
          </div>
        )}
      </div>
    </header>
  );
};
