import React from "react";
import { OverlayTrigger, Popover } from "react-bootstrap";
import { RouteLabel, RouteVia } from "utilities/api/models/Route";
import { RoutePath } from "./RoutePath";

const ROUTE_ARROW = {
  roundTrip: "⇄",
  oneway: "→",
};

interface Props {
  /** 経路のIDなどの一意な値 */
  id: string;
  /** 支払先名 */
  payeeName?: string;
  /** 乗車駅名 */
  originStation: string;
  /** 降車駅名 */
  destinationStation: string;
  /** 片道当たりの距離 */
  onewayDistance?: number;
  /** 往復経路か */
  isRoundTrip: boolean;
  /**
   * 距離を表示するか
   * @note デフォルトでは userPreferences.preference.showDistanc の値を参照
   */
  showDistance?: boolean;
  /** 経由ポイント */
  vias: Array<RouteVia>;
  /** 経路に対してあてられたメタ的なラベル */
  labels: Array<RouteLabel>;
}

/** 移動経路情報を持つ支払先/訪問先 */
export const RoutePayee = ({
  id,
  payeeName = "",
  originStation,
  destinationStation,
  onewayDistance = 0,
  isRoundTrip,
  showDistance = userPreferences.preference.showDistance,
  vias,
  labels,
}: Props): React.ReactElement => {
  /** 距離を表示するか否か */
  const isShowDistance = showDistance && onewayDistance;
  /** 乗車駅と降車駅の関係を示す矢印 */
  const routeArrow = isRoundTrip ? ROUTE_ARROW.roundTrip : ROUTE_ARROW.oneway;
  /** 総移動距離 */
  const distance = isRoundTrip ? onewayDistance * 2 : onewayDistance;

  return (
    <OverlayTrigger
      rootClose
      placement="left"
      overlay={
        <Popover id={`route-${id}`} placement="left">
          <RoutePath vias={vias} labels={labels} />
        </Popover>
      }
    >
      <span>
        {isShowDistance
          ? `${payeeName} (${originStation} ${routeArrow} ${destinationStation} ${
              distance / 10
            }km)`
          : `${payeeName} (${originStation} ${routeArrow} ${destinationStation})`}
      </span>
    </OverlayTrigger>
  );
};
