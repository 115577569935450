import ApiV3 from 'utilities/api_v3';
import React, { useCallback } from 'react';
import SuggestField from './SuggestField';
import formatter from 'utilities/formatter';
import { PersonalCategory } from 'utilities/api_v3/models/PersonalCategory';
import { fetchAsync } from 'utilities/async';

interface Props {
  disable?: boolean;
  formId?: string;
  includeDisabled?: boolean;
  includeTaxCategory?: boolean;
  onlyUsePreReportItem?: boolean;
  ownerId?: string;
  parentId?: string | null;
  placeholder?: string;
  requestType?: string;
  selectable?: boolean;
  text: string; // Categoryを扱いたいが、任意の科目名を入力させるケースが多いため
  value: PersonalCategory | null;
  onTextChange: (text: string) => void;
  onSelect: (value: PersonalCategory | null) => void;
}

function getOptionText(option: PersonalCategory): string {
  return option.name;
}

function renderOption(option: PersonalCategory | null): JSX.Element {
  return formatter.text(option?.name);
}

/**
 * 個人の選択可能な経費科目をサジェストする
 * 経費科目のツリー構造を考慮せず、「利用する」設定になっている経費科目の一覧から検索する
 *
 * 事業所全体、あるいはログインユーザ以外の特定ユーザの個人設定を反映した検索を行うことも可能
 */
const PersonalCategorySuggestField: React.FunctionComponent<Props> = (props) => {
  const {
    disable, text, value, placeholder, onSelect, onTextChange,
    formId, includeDisabled = false, includeTaxCategory = false,
    onlyUsePreReportItem, ownerId, parentId, requestType, selectable,
  } = props;

  const fetchOptions = useCallback((name: string, offset: number, limit: number) => {
    const params = {
      name,
      formId,
      includeDisabled,
      includeTaxCategory,
      onlyUsePreReportItem,
      ownerId,
      parentId,
      requestType,
      selectable,
      offset,
      limit,
    };

    return fetchAsync(ApiV3.personalCategories.index, params)
      .then(({ pagination, data }) => {
        // NOTE: SuggestFieldの実装上、先頭（offset = 0）から順番にデータを取得できる前提
        return { count: pagination.count, data };
      });
  }, [formId, includeDisabled, includeTaxCategory, onlyUsePreReportItem, ownerId, parentId, requestType, selectable]);

  return (
    <SuggestField async={ true }
      disabled = { disable }
      text={ text }
      value={ value }
      placeholder={ placeholder }
      getOptionText={ getOptionText }
      renderOption={ renderOption }
      onTextChange={ onTextChange }
      onSelect={ onSelect }
      fetchOptions={ fetchOptions }
    />
  );
};

export default PersonalCategorySuggestField;
