import React from 'react';
import styled from 'styled-components';

interface Props {
  exportFileName: string;
  downloadUrl: string;
}

const Link = styled.a`
  &:hover {
    cursor: pointer;
  }
`;

const Icon = styled.i`
  margin-right: 0.5rem;
`;

const fileDownload = async (e, url): Promise<void> => {
  e.stopPropagation();

  window.location.href = url;
};

export const DownloadItem: React.FC<Props> = ({
  exportFileName, downloadUrl,
}) => {
  return (
    <div>
      <Link onClick={ (e): Promise<void> => fileDownload(e, downloadUrl) }><Icon className='text-success fas fa-check'></Icon>{ exportFileName }</Link>
    </div>
  );
};
