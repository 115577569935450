import * as ActionTypes from "./ActionTypes";
import * as Types from "./Types";

export function closeModal(): Types.ModalAction {
  return {
    type: ActionTypes.TOGGLE_MARKING_AS_MATCHED_MODAL,
    payload: {
      show: false,
    },
  };
}

export function openModal(): Types.ModalAction {
  return {
    type: ActionTypes.TOGGLE_MARKING_AS_MATCHED_MODAL,
    payload: {
      show: true,
    },
  };
}

export function setFetchingStatus(
  isLoading: boolean,
): Types.FetchingStatusAction {
  return {
    type: ActionTypes.SET_MARKING_AS_MATCHED_FETCHING_STATUS,
    payload: {
      isLoading,
    },
  };
}
