import { fetchAsync } from "actions/AsyncAction";
import { initialTransaction } from "applications/transactions/reducers/formData";
import get from "lodash/get";
import Api from "utilities/api";
import { foldFormValues } from "../../transactions/utilities/transactionFormBuilder";
import * as memoBuilder from "../../transactions/utilities/transactionMemoBuilder";

const prefix = `pre_form_data`;

export function fetchEntryForm() {
  return (dispatch, getState) => {
    return dispatch(fetchAsync(Api.entryForms.show, { id: "pre_report" })).then(
      (data) => dispatch(setFields(get(data, "formFields", []))),
    );
  };
}

export const SET_FIELDS = `${prefix}/SET_FIELDS`;
export function setFields(fields) {
  return {
    type: SET_FIELDS,
    fields,
  };
}

export const TOGGLE_PRE_REPORT_REQUEST_MODAL = `${prefix}/TOGGLE_PRE_REPORT_REQUEST_MODAL`;
export function showPreReportRequestModal() {
  return {
    type: TOGGLE_PRE_REPORT_REQUEST_MODAL,
    show: true,
  };
}

export function hidePreReportRequestModal() {
  return {
    type: TOGGLE_PRE_REPORT_REQUEST_MODAL,
    show: false,
  };
}

export function openPreTransaction(preTransaction) {
  return function openPreTransactionThunk(dispatch, getState) {
    dispatch(setPreTransaction(preTransaction));
    return dispatch(showPreTransactionModal());
  };
}

export const SET_PRE_TRANSACTION = `${prefix}/SET_PRE_TRANSACTION`;
export function setPreTransaction(preTransaction) {
  return {
    type: SET_PRE_TRANSACTION,
    data: preTransaction,
  };
}

export const TOGGLE_PRE_TRANSACTION_MODAL = `${prefix}/TOGGLE_PRE_TRANSACTION_MODAL`;
export function showPreTransactionModal() {
  return {
    type: TOGGLE_PRE_TRANSACTION_MODAL,
    show: true,
  };
}

export function hidePreTransactionModal() {
  return {
    type: TOGGLE_PRE_TRANSACTION_MODAL,
    show: false,
  };
}

export const SET_PRE_TRANSACTION_PROPERTY = `${prefix}/SET_PRE_TRANSACTION_PROPERTY`;
export function setPreTransactionProperty(key, value) {
  return {
    type: SET_PRE_TRANSACTION_PROPERTY,
    key,
    value,
  };
}

export const CLEAR_PRE_TRANSACTION = `${prefix}/CLEAR_PRE_TRANSACTION`;
export function clearPreTransaction() {
  return {
    type: CLEAR_PRE_TRANSACTION,
  };
}

export function openTransactionModal(preReport, preTransaction, entryForm) {
  return (dispatch, getState) => {
    const transaction = convertPreTransactionIntoTransaction(
      preReport,
      preTransaction,
      entryForm,
    );

    dispatch(setTransaction(transaction));
    dispatch(showTransactionModal());
  };
}

function convertPreTransactionIntoTransaction(
  preReport,
  preTransaction,
  entryForm,
) {
  const category = preTransaction.category;
  const comment = get(preTransaction, "comment", null);
  const commentFormat = get(userPreferences.preference, "commentFormat", null);
  const memoTemplate = get(category, "memoTemplate", null);

  return {
    ...initialTransaction,
    transactedAt: preTransaction.transactedAt,
    shopName: void 0,
    amount: preTransaction.amount,
    categoryName: preTransaction.categoryName,
    originalAmount: preTransaction.amount,
    project: preTransaction.project,
    companions: preTransaction.companions || [],
    preReportId: preReport.id,
    preReportTitle: preReport.title,
    preReportDepartment: preReport.department,
    comment: memoBuilder.mergeMemo(comment, commentFormat, memoTemplate),
    skipCompanions: preTransaction.skipCompanions || false,
    formId: entryForm.id,
    formValues: [
      ...foldFormValues(
        entryForm.formFields,
        { default: ["department_input"], additional: [] },
        { formValues: preReport.formValues, fields: preReport.fields },
      ),
      // 事前申請項目は汎用フォームに対応していないので、サーバーから受けた値をそのまま使う
      // todo 事前申請項目の汎用フォーム対応時に書き直す
      ...preTransaction.formValues,
    ],
    editable: true,
  };
}

export function initializeStateForCompanionSelector() {
  return (dispatch, getState) => {
    dispatch(setIsNew(false));
    dispatch(setShouldSelectSelfAsCompanion(true));
    dispatch(setPreTransactionProperty("companions", []));
  };
}

export const SET_TRANSACTION = `${prefix}/SET_TRANSACTION`;
export function setTransaction(transaction) {
  return {
    type: SET_TRANSACTION,
    data: transaction,
  };
}

export const TOGGLE_EDITING_PRE_REPORT_CONTENT = `${prefix}/TOGGLE_EDITING_PRE_REPORT_CONTENT`;
export function toggleEditingPreReportContent(isEditing) {
  return {
    type: TOGGLE_EDITING_PRE_REPORT_CONTENT,
    editing: isEditing,
  };
}

export const TOGGLE_TRANSACTION_MODAL = `${prefix}/TOGGLE_TRANSACTION_MODAL`;
export function showTransactionModal() {
  return {
    type: TOGGLE_TRANSACTION_MODAL,
    show: true,
  };
}

export function hideTransactionModal() {
  return {
    type: TOGGLE_TRANSACTION_MODAL,
    show: false,
  };
}

export const CLEAR_TRANSACTION = `${prefix}/CLEAR_TRANSACTION`;
export function clearTransaction() {
  return {
    type: CLEAR_TRANSACTION,
  };
}

export const SET_IS_NEW = `${prefix}/SET_IS_NEW`;
export function setIsNew(state) {
  return {
    type: SET_IS_NEW,
    state,
  };
}

export const SET_SHOULD_SELECT_SELF_AS_COMPANION = `${prefix}/SET_SHOULD_SELECT_SELF_AS_COMPANION`;
export function setShouldSelectSelfAsCompanion(state) {
  return {
    type: SET_SHOULD_SELECT_SELF_AS_COMPANION,
    state,
  };
}
