import React from 'react';
import i18next from 'i18n';
import styled from 'styled-components';

interface Props {
  originAndDestinationByCategory: {
    originByCategory: string,
    destinationByCategory: string,
  },
  handleOriginByCategoryChange: (e: React.ChangeEvent<HTMLInputElement>) => void
  handledestinationByCategoryChange: (e: React.ChangeEvent<HTMLInputElement>) => void
  onExchangeButtonClick: () => void
}

const OriginAndDestinationView = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  i {
    text-align: center;
    color: #757575;
    width: 10%
    padding: 5px 16px;
    &:hover {
      color: #f39c12;
      cursor: pointer;
    }
  }
  .list-form{
    width:  45%;
    margin-bottom: 0px;
  }
`;


export const OriginAndDestinationInput: React.FC<Props> = ({
  originAndDestinationByCategory,
  handleOriginByCategoryChange,
  handledestinationByCategoryChange,
  onExchangeButtonClick,
}) => {
  return (
    <OriginAndDestinationView>
      <input type='text'
        name='originAndDestinationByCategory.originByCategory'
        className='form-control'
        placeholder={ i18next.t('transactions.properties.originByCategory') }
        value={ originAndDestinationByCategory.originByCategory || '' }
        onChange={ handleOriginByCategoryChange }
      />
      <div className='exchange-btn' onClick={ onExchangeButtonClick }>
        <i className='fas fa-exchange-alt fa-x' />
      </div>
      <input type='text'
        name='originAndDestinationByCategory.destinationByCategory'
        className='form-control'
        placeholder={ i18next.t('transactions.properties.destinationByCategory') }
        value={ originAndDestinationByCategory.destinationByCategory || '' }
        onChange={ handledestinationByCategoryChange }
      />
    </OriginAndDestinationView>
  );
};
