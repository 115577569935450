import SelectField from "components/fields/SelectField";
import React, { FC } from "react";
import { CurrencyType } from "../types/currencyType";

interface Currency {
  value: CurrencyType;
  label: string;
}

interface Props {
  currencies: CurrencyType[];
  onChange: (currerncy: CurrencyType | null) => void;
  disabled: boolean;
}

const CurrencyInput: FC<Props> = (props) => {
  const currencyToText = ({
    currencyId,
    currencyName,
  }: CurrencyType): string => {
    if (currencyName) {
      return `${currencyId}(${currencyName})`;
    }

    return currencyId;
  };

  const handleChangeCurrency = (currency: Currency | null): void => {
    props.onChange(currency?.value || null);
  };

  const options = props.currencies.map(
    (c: CurrencyType): Currency => ({ value: c, label: currencyToText(c) }),
  );

  return (
    <SelectField
      options={options}
      onChange={handleChangeCurrency}
      isDisabled={props.disabled}
    />
  );
};

export default CurrencyInput;
