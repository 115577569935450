import { CsvViewer } from "components/renewaled_ui/FileViewer/Viewer/Other/FileView/CsvViewer";
import { ExcelViewer } from "components/renewaled_ui/FileViewer/Viewer/Other/FileView/ExcelViewer";
import { OtherViewer } from "components/renewaled_ui/FileViewer/Viewer/Other/FileView/OtherViewer";
import Loading from "components/renewaled_ui/FileViewer/Viewer/Other/FileView/parts/Loading";
import Placeholder from "components/renewaled_ui/FileViewer/Viewer/Other/FileView/parts/Placeholder";
import { WordViewer } from "components/renewaled_ui/FileViewer/Viewer/Other/FileView/WordViewer";
import {
  FileLoadStatus,
  LoadingStatus,
} from "components/types/attachedFiles.types";
import React, { CSSProperties, FC, forwardRef, useCallback } from "react";
import styled from "styled-components";
import { MIMEType, ViewableMIMETypes } from "utilities/api/types/MIMEType";
import { isSafari } from "utilities/Utils";

/**
 * ファイルビューアー: 画像
 * IE11, EdgeでobjectタグにData URIが指定できないことがある問題の対策で、画像とPDFで別のタグを使う
 */
const ImageView = styled.img`
  // 中央揃え
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  margin: auto;
`;

/**
 * 縦横サイズのCSSの値を整形し返す
 */
function normalizeSize(size?: number | string): string {
  if (typeof size === "number") return `${size}px`;
  if (size) return size;
  return "auto";
}

const isObjectFitRequired = !isSafari();

interface FrameViewProps {
  readonly backgroundColor: string;
  readonly width?: number | string;
  readonly height?: number | string;
  readonly isScrollable?: boolean;
}
/**
 * ファイルビューアーの外枠
 */
const FrameView = styled.div<FrameViewProps>`
  position: relative;
  background-color: ${(p): string => p.backgroundColor};
  overflow: ${(p): string => (p.isScrollable ? "auto" : "hidden")};
  width: ${(p): string => normalizeSize(p.width)};
  height: ${(p): string => normalizeSize(p.height)};

  & > *:first-child {
    max-height: 100%;
    max-width: 100%;
    ${isObjectFitRequired ? "object-fit: contain;" : ""};
  }

  // img は、回転時に正しいサイズを取る必要があるため、大きさを維持
  // PDFとplaceholderは外枠いっぱいに広げる
  & > div,
  object {
    width: 100%;
    height: 100%;
  }
`;

interface InnerProps {
  readonly url?: string;
  readonly mimeType?: MIMEType;
  readonly filename?: string;
  readonly imageStyle?: CSSProperties;
  readonly loadStatus: FileLoadStatus;
  readonly showPlaceholderDescription?: boolean;
}

export interface Props extends InnerProps {
  readonly className?: string;
  readonly imageStyle?: CSSProperties;
  readonly width?: number | string;
  readonly height?: number | string;
  readonly onClick?: (e: React.SyntheticEvent, state: LoadingStatus) => void;
  readonly showOverlay?: boolean;
}

/**
 * 背景色の取得
 */
function getBackgroundColor(type: LoadingStatus): string {
  switch (type) {
    case "loaded":
    case "notFound":
    case "failedToLoad":
    case "failedToShow": {
      return "rgba(37, 48, 68, 0.7)";
    }
    default: {
      return "rgba(0, 0, 0, 0.1)";
    }
  }
}

function getClassName(
  type: LoadingStatus,
  className?: string,
): string | undefined {
  if (!className) {
    return void 0;
  }

  return `${className} ${className}--${type}`;
}

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const exhaustiveCheck = (_args: never): never => {
  throw new Error("This function is unreachable");
};

interface LoadingFileViewerProps {
  readonly url?: string;
  readonly mimeType?: MIMEType;
  readonly filename?: string;
  readonly loadStatus: FileLoadStatus;
}

/**
 * ビューアーのローディングと表示
 */
const LoadingFileViewer: FC<LoadingFileViewerProps> = ({
  url,
  mimeType,
  filename,
  loadStatus,
}) => {
  if (ViewableMIMETypes.some((v) => v === mimeType)) {
    return <Loading progress={loadStatus.summary?.progress} />;
  }

  return <LoadedFileViewer url={url} mimeType={mimeType} filename={filename} />;
};

interface LoadedFileViewerProps {
  readonly url?: string;
  readonly mimeType?: MIMEType;
  readonly filename?: string;
  readonly imageStyle?: CSSProperties;
}

/**
 * ビューアーの分岐と表示
 */
const LoadedFileViewer: FC<LoadedFileViewerProps> = ({
  url,
  mimeType,
  filename,
  imageStyle,
}) => {
  switch (mimeType) {
    case "image/gif":
    case "image/png":
    case "image/jpeg": {
      return (
        <ImageView
          style={imageStyle}
          src={url}
          className="image-image-view"
          draggable={false}
        />
      );
    }
    case "application/msword":
    case "application/vnd.openxmlformats-officedocument.wordprocessingml.document": {
      return <WordViewer url={url} filename={filename} />;
    }
    case "application/vnd.ms-excel":
    case "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet": {
      return <ExcelViewer url={url} filename={filename} />;
    }
    case "text/csv": {
      return <CsvViewer url={url} filename={filename} />;
    }
    case "application/pdf":
    case "text/plain":
    case undefined: {
      return <OtherViewer url={url} filename={filename} />;
    }
    default: {
      return exhaustiveCheck(mimeType);
    }
  }
};

/**
 * 画像・ファイル・ダウンロードリンクのビューアー
 *
 * ダウンロード完了前は、ローディング状況に応じて表示を変更する
 * 同じインスタンスで別のファイルを表示する場合は、keyを切り替えること
 */
const FileViewInner: React.FC<InnerProps> = ({
  url,
  mimeType,
  loadStatus,
  imageStyle,
  filename,
  showPlaceholderDescription,
}) => {
  switch (loadStatus.state) {
    // TODO: プレビューの必要が無いデータはローディングステップ自体をスキップするようにする
    // ローディング中
    case "loading": {
      return (
        <LoadingFileViewer
          url={url}
          mimeType={mimeType}
          filename={filename}
          loadStatus={loadStatus}
        />
      );
    }
    // ファイルが見つからない
    case "notFound": {
      return (
        <Placeholder
          type="notFound"
          showDescription={showPlaceholderDescription}
        />
      );
    }
    // ファイルの読み込みエラー
    case "failedToLoad":
    case "failedToShow": {
      return (
        <Placeholder
          type="error"
          showDescription={showPlaceholderDescription}
        />
      );
    }
    // ファイル読み込み完了
    case "loaded": {
      return (
        <LoadedFileViewer
          url={url}
          mimeType={mimeType}
          filename={filename}
          imageStyle={imageStyle}
        />
      );
    }
    // ファイルが存在しない
    case "notExist": {
      return (
        <Placeholder
          type="notExist"
          showDescription={showPlaceholderDescription}
        />
      );
    }
    default: {
      return (
        <Placeholder
          type="default"
          showDescription={showPlaceholderDescription}
        />
      );
    }
  }
};

/**
 * ファイルのビューアー
 */
const FileView = forwardRef<HTMLDivElement, Props>(
  (
    {
      className,
      width,
      height,
      imageStyle,
      loadStatus,
      onClick,
      url,
      mimeType,
      filename,
      showPlaceholderDescription,
    },
    ref,
  ) => {
    const frameClassName = getClassName(loadStatus.state, className);
    const handleClick = useCallback(
      (e: React.SyntheticEvent) => {
        if (onClick) {
          onClick(e, loadStatus.state);
        }
      },
      [loadStatus, onClick],
    );

    return (
      <FrameView
        ref={ref}
        className={`${frameClassName} image-frame-view`}
        backgroundColor={getBackgroundColor(loadStatus.state)}
        width={width}
        height={height}
        isScrollable={
          mimeType !== "application/pdf" && loadStatus.state === "loaded"
        }
        onClick={handleClick}
      >
        <FileViewInner
          url={url}
          mimeType={mimeType}
          filename={filename}
          imageStyle={imageStyle}
          showPlaceholderDescription={showPlaceholderDescription}
          loadStatus={loadStatus}
        />
      </FrameView>
    );
  },
);

export default FileView;
