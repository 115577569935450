import * as ActionTypes from '../actions/formActions';

const form = (state = [], action) => {
  switch (action.type) {
    case ActionTypes.SET_VALUE: {
      const formItems = [...state];
      formItems[action.index] = { ...formItems[action.index], value: action.value };

      return formItems;
    }
    case ActionTypes.SET_FORM: {
      return action.formItems;
    }
    default:
      return state;
  }
};

export default form;
