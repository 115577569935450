import React from "react";

interface Props {
  /** 描画するにあたってユニークになるキー情報 */
  key: string | number;
  /** 経由ポイントのタイプ */
  type: string;
  /** 経由ポイント名 */
  name: string;
  /** 経由ポイントの色 */
  color?: string;
}

/** 経由ポイントの情報を描画する */
export const ViaPoint = ({
  key,
  type,
  name,
  color,
}: Props): React.ReactElement => {
  return (
    <div key={`line-${key}`}>
      {type === "station" ? (
        <span>{name}</span>
      ) : (
        <>
          <span>
            <i
              className="fa fa-arrow-circle-down prefix-icon"
              style={{ color }}
            />
          </span>
          <span className="txt-sm">{name}</span>
        </>
      )}
    </div>
  );
};
