import CheckBoxGroupAddon from "components/CheckBoxGroupAddon";
import DateInput from "components/date_input";
import i18next from "i18n";
import moment from "moment";
import React, { FC } from "react";
import styled from "styled-components";

const RangerTextWrap = styled.div`
  border-top: 1px solid #ccc;
  border-bottom: 1px solid #ccc;
  span {
    background-color: white;
    font-size: 20px;
    font-weight: bold;
    display: flex;
    height: 32px;
    justify-content: center;
  }

  @media (max-width: 767px) {
    border: none;
    span {
      background-color: unset;
      height: 100%;
    }
  }
`;

const DatePickerFrom = styled.div`
  .ic-wrap {
    background-color: white;
    border-right: none;
  }

  .react-datepicker-wrapper,
  .react-datepicker__input-container {
    display: flex;
  }

  .react-datepicker__input-container {
    .form-control {
      border-right: unset;
      border-left: unset;
    }
  }

  @media (max-width: 767px) {
    .react-datepicker__input-container {
      .form-control {
        border: 1px solid #ccc;
        border-left: unset;
        border-radius: 0px 4px 4px 0px;
      }
    }
  }
`;

const DatePickerTo = styled.div`
  .ic-wrap {
    background-color: white;
    border-left: none;
    border-right: none;
    border-radius: unset;
  }

  .react-datepicker-wrapper,
  .react-datepicker__input-container {
    display: flex;
  }

  .react-datepicker__input-container {
    .form-control {
      border-left: unset;
    }
  }

  @media (max-width: 767px) {
    .ic-wrap {
      border: 1px solid #ccc;
      border-right: none;
      border-radius: 4px 0px 0px 4px;
    }
  }
`;

interface Props {
  searchEmpty: boolean;
  dateFrom: string;
  dateTo: string;
  onDateChange: (type: string, value: string) => void;
  onSearchEmptyChange: () => void;
}

export const DateRange: FC<Props> = (props) => {
  const handleOnDateChange = (ref, value): void => {
    // check valid and change format from ISO date
    const date = moment(value);
    const newFormatValue = date.isValid() ? date.format("YYYY/M/D") : "";
    props.onDateChange(ref, newFormatValue);
  };

  const renderDatePicker = (ref): JSX.Element => {
    return (
      <div className="input-group">
        <div className="input-group-addon ic-wrap">
          <i className="fas fa-calendar-alt" />
        </div>
        <DateInput
          inputClassName="form-control"
          disabled={props.searchEmpty}
          value={props[ref]}
          onChange={(value): void => {
            handleOnDateChange(ref, value);
          }}
        />
        {ref === "dateTo" && (
          <CheckBoxGroupAddon
            label={i18next.t("transactions.index.searchbox.blankSearch")}
            defaultChecked={props.searchEmpty}
            onChange={props.onSearchEmptyChange}
          />
        )}
      </div>
    );
  };

  return (
    <div className="transaction-date-input">
      <DatePickerFrom className="col-sm-4 col-md-4">
        {renderDatePicker("dateFrom")}
      </DatePickerFrom>
      <RangerTextWrap className="col-sm-1 col-md-1">
        <span>~</span>
      </RangerTextWrap>
      <DatePickerTo className="col-sm-7 col-md-7">
        {renderDatePicker("dateTo")}
      </DatePickerTo>
    </div>
  );
};

DateRange.defaultProps = {
  searchEmpty: false,
  dateFrom: "",
  dateTo: "",
};

export default DateRange;
