import i18next from 'i18n';
import { RequestsSearchApplicationResponse } from 'utilities/api/responses/requests/searchResults/application';
import { RequestsSearchPreReportResponse } from 'utilities/api/responses/requests/searchResults/preReport';
import { RequestsSearchReportResponse } from 'utilities/api/responses/requests/searchResults/report';

type ReportsForStatus = RequestsSearchApplicationResponse | RequestsSearchReportResponse | RequestsSearchPreReportResponse;

interface UseReturn {
  readonly unapprovableReports: (reports: ReportsForStatus[]) => string[];
}

/**
 * レポートステータスのロジック
 */
export const useReportStatus = (): UseReturn => {
  /**
   * 承認不可なレポート一覧を返す
   */
  const unapprovableReports = (reports: ReportsForStatus[]): string[] => {
    if (Array.isArray(reports)) {
      return reports.filter((report) => (
        report && (report.status === i18next.t('commons.status.created') || report.status === i18next.t('commons.status.unsubmitted') || report?.statusForApprover !== i18next.t('commons.status.applying'))
      )).map((report) => report.id);
    }

    return [];
  };

  return {
    unapprovableReports,
  };
};
