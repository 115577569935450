import assign from "lodash/assign";
import isNil from "lodash/isNil";
import * as ActionTypes from "../actions";

const preference = userPreferences.preference;

const initialAutoCategorizeRuleForm = {
  id: null,
  accountId: null,
  category: {
    id: null,
    name: null,
  },
  regex: null,
  enable: true,
};

const initialState = {
  id: null,
  inputs: [],
  loginUrl: null,
  oauthUrl: null,
  oauthRequestUrl: null,
  name: null,
  accountType: null,
  form: {},
  isDisabled: false,
  userId: userPreferences.id,
  inputProject: preference.inputProject || false,
  inputGroup: preference.inputGroup || false,
  isCard: false,
  isEWallet: false,
  isExternalService: false,
  groupName: "",
  dwAccountFormId: null,
  delegations: {},
  preferences: {
    defaultCategory: null,
    defaultGroupName: null,
    defaultProject: null,
    defaultComment: null,
    isCorporate: false,
    mergeable: false,
    isAutoRegister: false,
    isExcludingProductPurchase: false,
  },
  autoCategorizeRule: {
    autoCategorizeRules: [],
    selectedAutoCategorizeRule: initialAutoCategorizeRuleForm,
    toggleSettingModal: false,
    toggleDeleteModal: false,
  },
};

const accountForms = (state = initialState, action) => {
  switch (action.type) {
    case ActionTypes.SET_ACCOUNT_FORM_DATA:
      return {
        ...state,
        inputs: action.accountFormInputs,
        loginUrl: action.loginUrl,
        oauthRequestUrl: action.oauthRequestUrl,
        name: action.name,
        form: {},
        isDisabled: false,
        groupName: action.groupName,
        isCard: action.groupName === "card",
        isEWallet: action.groupName === "eWallet",
        isExternalService: action.groupName === "externalService",
        dwAccountFormId: action.dwAccountFormId,
        delegations: action.delegations,
      };
    case ActionTypes.SET_ACCOUNT_NAME:
      return {
        ...state,
        name: action.value,
      };
    case ActionTypes.SET_ACCOUNT_TYPE:
      return {
        ...state,
        accountType: action.value,
      };
    case ActionTypes.SET_OAUTH_URL:
      return {
        ...state,
        oauthUrl: action.value,
      };
    case ActionTypes.SET_FORM_INPUT:
      return {
        ...state,
        form: assign(state.form, action.form),
      };
    case ActionTypes.SET_ACCOUNT_PREFERENCES:
    case ActionTypes.TOGGLE_IS_CORPORATE:
    case ActionTypes.TOGGLE_MERGEABLE:
    case ActionTypes.TOGGLE_IS_AUTO_REGISTER:
    case ActionTypes.TOGGLE_IS_EXCLUDING_PRODUCT_PURCHASE:
    case ActionTypes.SET_CATEGORY:
    case ActionTypes.SET_GROUP_NAME:
    case ActionTypes.SET_PROJECT:
    case ActionTypes.SET_COMMENT:
      return {
        ...state,
        preferences: accountPrefs(state.preferences, action),
      };
    case ActionTypes.LOCK_INPUT_FORM:
    case ActionTypes.UNLOCK_INPUT_FORM:
      return {
        ...state,
        isDisabled: action.value,
      };
    case ActionTypes.TOGGLE_AUTO_CATEGORIZE_RULE_MODAL:
    case ActionTypes.SET_FORM_CONDITION_DATA:
    case ActionTypes.SET_FORM_SUBJECT_DATA:
    case ActionTypes.TOGGLE_DELETE_AUTO_CATEGORIZE_RULE_MODAL:
    case ActionTypes.SELECT_AUTO_CATEGORIZE_RULE:
    case ActionTypes.SET_AUTO_CATEGORIZE_RULE:
      return {
        ...state,
        autoCategorizeRule: autoCategorizeRule(
          state.autoCategorizeRule,
          action,
        ),
      };
    default:
      return state;
  }
};

const accountPrefs = (prefs, action) => {
  switch (action.type) {
    case ActionTypes.SET_ACCOUNT_PREFERENCES: {
      return {
        defaultCategory: {
          id: action.prefs.defaultCategory?.id,
          name: action.prefs.defaultCategory?.name,
        },
        defaultGroupName: action.prefs.defaultGroupName,
        defaultProject: action.prefs.defaultProject,
        defaultComment: action.prefs.defaultComment,
        isCorporate: action.prefs.isCorporate,
        mergeable: action.prefs.mergeable,
        isAutoRegister: action.prefs.isAutoRegister,
        isExcludingProductPurchase: action.prefs.isExcludingProductPurchase,
      };
    }
    case ActionTypes.TOGGLE_IS_CORPORATE:
      return {
        ...prefs,
        isCorporate: action.value,
      };
    case ActionTypes.TOGGLE_MERGEABLE:
      return {
        ...prefs,
        mergeable: action.value,
      };
    case ActionTypes.TOGGLE_IS_AUTO_REGISTER:
      return {
        ...prefs,
        isAutoRegister: action.value,
      };
    case ActionTypes.TOGGLE_IS_EXCLUDING_PRODUCT_PURCHASE:
      return {
        ...prefs,
        isExcludingProductPurchase: action.value,
      };
    case ActionTypes.SET_CATEGORY:
      return {
        ...prefs,
        defaultCategory: {
          id: action.id,
          name: action.name,
        },
      };
    case ActionTypes.SET_GROUP_NAME:
      return {
        ...prefs,
        defaultGroupName: action.value,
      };
    case ActionTypes.SET_PROJECT:
      return {
        ...prefs,
        defaultProject: action.value,
      };
    case ActionTypes.SET_COMMENT:
      return {
        ...prefs,
        defaultComment: action.value,
      };
    default:
      return prefs;
  }
};

const autoCategorizeRule = (autoCategorizeRuleState, action) => {
  switch (action.type) {
    case ActionTypes.TOGGLE_AUTO_CATEGORIZE_RULE_MODAL: {
      return {
        ...autoCategorizeRuleState,
        toggleSettingModal: action.show,
      };
    }
    case ActionTypes.TOGGLE_DELETE_AUTO_CATEGORIZE_RULE_MODAL: {
      return {
        ...autoCategorizeRuleState,
        toggleDeleteModal: action.show,
      };
    }
    case ActionTypes.SET_FORM_CONDITION_DATA:
      return {
        ...autoCategorizeRuleState,
        selectedAutoCategorizeRule: {
          ...autoCategorizeRuleState.selectedAutoCategorizeRule,
          regex: action.value,
        },
      };
    case ActionTypes.SET_FORM_SUBJECT_DATA:
      return {
        ...autoCategorizeRuleState,
        selectedAutoCategorizeRule: {
          ...autoCategorizeRuleState.selectedAutoCategorizeRule,
          category: action.value,
        },
      };
    case ActionTypes.SELECT_AUTO_CATEGORIZE_RULE:
      return {
        ...autoCategorizeRuleState,
        selectedAutoCategorizeRule: isNil(action.value)
          ? { ...initialAutoCategorizeRuleForm, accountId: initialState.id }
          : { ...action.value },
      };
    case ActionTypes.SET_AUTO_CATEGORIZE_RULE:
      return {
        ...autoCategorizeRuleState,
        autoCategorizeRules: action.autoCategorizeRules,
      };
    default:
      return autoCategorizeRuleState;
  }
};

export default accountForms;
