import { initialTransaction } from "applications/transactions/reducers/formData";
import presetEntryFormType from "applications/transactions/utilities/keyMap";
import {
  buildFormFields,
  buildFormValues,
} from "applications/transactions/utilities/transactionFormBuilder";
import { canRead } from "applications/transactions/utilities/transactionFormPolicy";
import { initialPreTransaction } from "values/initial_pre_transaction";
import * as ActionTypes from "../actions/formStateActions";
import * as PreReportActionTypes from "../actions/preReportActions";

const initialState = {
  fields: [],
  preTransaction: initialPreTransaction(), // 現在編集・作成中のPreTransaction。ここに持つべきじゃない気もするけど・・
  isEditing: true,
  showPreReportRequestModal: false,
  showPreTransactionModal: false,
  showTranactionModal: false,
  isNew: false,
  shouldSelectSelfAsCompanion: true,
  transaction: {},
};

const formState = (state = initialState, action) => {
  switch (action.type) {
    case PreReportActionTypes.SET_PRE_REPORT: {
      const formValues = buildFormValues(action.fields, action.preReport);
      return {
        ...state,
        fields: buildFormFields(action.fields, {}, formValues),
        isEditing: !action.forApprover && action.preReport.editable,
      };
    }
    case PreReportActionTypes.SET_PROPERTY:
    case PreReportActionTypes.SET_METADATA: {
      return {
        ...state,
        fields: formFields(state.fields, action),
      };
    }
    case ActionTypes.SET_FIELDS: {
      const formValues = buildFormValues(action.fields);
      return {
        ...state,
        fields: buildFormFields(action.fields, {}, formValues),
      };
    }
    case ActionTypes.TOGGLE_EDITING_PRE_REPORT_CONTENT: {
      return {
        ...state,
        isEditing: action.editing,
      };
    }
    case ActionTypes.TOGGLE_PRE_REPORT_REQUEST_MODAL: {
      return {
        ...state,
        showPreReportRequestModal: action.show,
      };
    }
    case ActionTypes.TOGGLE_PRE_TRANSACTION_MODAL: {
      return {
        ...state,
        showPreTransactionModal: action.show,
      };
    }
    case ActionTypes.SET_PRE_TRANSACTION: {
      return {
        ...state,
        preTransaction: action.data,
      };
    }
    case ActionTypes.SET_PRE_TRANSACTION_PROPERTY: {
      return {
        ...state,
        preTransaction: {
          ...state.preTransaction,
          [action.key]: action.value,
        },
      };
    }
    case ActionTypes.CLEAR_PRE_TRANSACTION: {
      return {
        ...state,
        preTransaction: initialPreTransaction(),
      };
    }
    case ActionTypes.TOGGLE_TRANSACTION_MODAL: {
      return {
        ...state,
        showTransactionModal: action.show,
      };
    }
    case ActionTypes.SET_TRANSACTION: {
      return {
        ...state,
        transaction: action.data,
      };
    }
    case ActionTypes.CLEAR_TRANSACTION: {
      return {
        ...state,
        transaction: initialTransaction,
      };
    }
    case ActionTypes.SET_IS_NEW: {
      return {
        ...state,
        isNew: action.state,
      };
    }
    case ActionTypes.SET_SHOULD_SELECT_SELF_AS_COMPANION: {
      return {
        ...state,
        shouldSelectSelfAsCompanion: action.state,
      };
    }
    default:
      return state;
  }
};

const formFields = (state = [], action) => {
  switch (action.type) {
    case PreReportActionTypes.SET_PROPERTY:
      return state.map((field) => {
        const key = presetEntryFormType[action.key];
        return {
          ...field,
          show: canRead(field, { [key]: action.value }),
          edited: field.type === key || field.edited,
        };
      });
    case PreReportActionTypes.SET_METADATA:
      return state.map((field) => ({
        ...field,
        show: canRead(field, { [action.key]: action.value }),
        edited: field.id === action.prop || field.edited,
      }));
    default:
      return state;
  }
};

export default formState;
