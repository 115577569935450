import isNil from "lodash/isNil";
import { createSelector } from "reselect";

// EditableTransactionTableで使用するselector
const getIsEditable = (state, props) => props.isEditable;
const getIsDetachable = (state, props) => props.isDetachable;
const getFormData = (state, props) => state.formData;

const makeEditableTransactionTableStateMapper = () =>
  createSelector(
    [getIsEditable, getIsDetachable, getFormData],
    (isEditable, isDetachable, formData) => ({
      isEditable: isNil(isEditable) || isEditable,
      isDetachable: isNil(isDetachable) || isDetachable,
      formData,
    }),
  );

export default makeEditableTransactionTableStateMapper;
