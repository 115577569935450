import PropTypes from 'prop-types';
import React, { Component } from 'react';
import i18next from 'i18n';

export default class ApprovalStep extends Component {
  render() {
    const idx = this.props.approvalStepIndex;
    const step = this.props.approverStep;
    const approvalStep = i18next.t('commons.ordinals.no_interval', { postProcess: 'interval', count: idx + 1 });

    return (
      <div className="form-group clear" key={ `approve-${idx}` }>
        <label className='col-sm-3 control-label approval-label' htmlFor={ `approver-${idx}` }>
          { i18next.t('approvals.flows.approvalStep', { order: approvalStep }) }
        </label>
        <div className="col-sm-9 input-group" id={ `approver-${idx}` }>
          <div className="approve-num input-group-addon">
            { step.condition === "all"
              ? (<span>{ i18next.t('approvals.flows.all') }</span>)
              : (<span>{ i18next.t('approvals.flows.someOne') }</span>)
            }
          </div>
          <div className="form-control scroll-form" style={ { height: '100%' } }>
            { step.approvers.length > 0
              ? (step.approvers.map((it) => it.name).join(", "))
              : (<span className="txt-disabled">{ i18next.t('approvals.flows.empty') }</span>)
            }
          </div>
        </div>
      </div>
    );
  }
}

ApprovalStep.defaultProps = {
  approvalStepIndex: 0,
};

ApprovalStep.propTypes = {
  approvalStepIndex: PropTypes.number,
  approverStep: PropTypes.object.isRequired,
};
