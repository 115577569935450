import ExpenseSummary from "applications/transactions/components/history/ExpenseSummary";
import FieldInputLog from "components/histories/FieldInputLog";
import React from "react";
import { Expense } from "utilities/api/models";
import { Change, ExpenseHistory } from "../types";

interface Props {
  /** 経費 */
  readonly expense: Expense;
  /** 編集タイプ */
  readonly expenseHistoryType: ExpenseHistory["type"];
  /** 変更点リスト */
  readonly changes: Change[];
}

/**
 * 経費編集履歴テーブル: 編集内容
 */
export const ExpenseLog: React.FC<Props> = ({
  expense,
  expenseHistoryType,
  changes,
}) => {
  if (["add", "delete"].includes(expenseHistoryType)) {
    return <ExpenseSummary expense={expense} />;
  }

  const propsForChangeSummary = changes.map((changeLog, i) => {
    const {
      formField: { type, label },
    } = changeLog;

    return {
      label: label || "",
      key: type,
      fieldType: type,
      changes: [changes[i]],
    };
  });

  return (
    <div>
      {propsForChangeSummary.map((x, i) => {
        return <FieldInputLog {...x} key={`field-input-log-${i}`} />;
      })}
    </div>
  );
};
