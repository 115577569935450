import PropTypes from "prop-types";
import React, { Component } from "react";
import Api from "utilities/api";
import PaidByCorporateCardCondition from "./PaidByCorporateCardCondition";
import ToBePaidCondition from "./ToBePaidCondition";

export default class SettlementCondition extends Component {
  constructor(props) {
    super(props);

    this.state = { isCorporateCardAvailable: false };

    this.handleToBePaidChange = this.handleToBePaidChange.bind(this);
    this.handlePaidByCorporateCardChange =
      this.handlePaidByCorporateCardChange.bind(this);
  }

  get className() {
    const baseClass = "settlement-condition";
    return this.props.className
      ? `${baseClass} ${this.props.className}`
      : baseClass;
  }

  componentDidMount() {
    this.checkCorporateCardAvailability();
  }

  async checkCorporateCardAvailability() {
    try {
      const data = await Api.corporateCards.status();

      this.setState({ isCorporateCardAvailable: data.enabled });
    } catch (e) {
      // 検索条件の表示有無のみの問題なので、特に何もしない
    }
  }

  handleToBePaidChange(value) {
    this.props.onChange("toBePaid", value);
  }

  handlePaidByCorporateCardChange(value) {
    this.props.onChange("paidByCorporateCard", value);
  }

  render() {
    return (
      <div className={this.className}>
        <ToBePaidCondition
          value={this.props.isToBePaid}
          onChange={this.handleToBePaidChange}
        />

        {this.state.isCorporateCardAvailable && (
          <PaidByCorporateCardCondition
            value={this.props.isPaidByCorporateCard}
            onChange={this.handlePaidByCorporateCardChange}
          />
        )}
      </div>
    );
  }
}

SettlementCondition.defaultProps = {
  isToByPaid: null,
  isPaidByCorporateCard: null,
};

SettlementCondition.propTypes = {
  className: PropTypes.string,
  isToBePaid: PropTypes.bool,
  isPaidByCorporateCard: PropTypes.bool,
  onChange: PropTypes.func.isRequired,
};
