import RadioGroup from "components/fields/RadioGroup";
import LabeledComponent from "components/LabeledComponent";
import i18next from "i18n";
import PropTypes from "prop-types";
import React, { Component } from "react";

export default class ElectronicReceiptImageCondition extends Component {
  constructor(props) {
    super(props);

    this.handleIsElectronicReceiptImageChange =
      this.handleIsElectronicReceiptImageChange.bind(this);
  }

  handleIsElectronicReceiptImageChange(value) {
    this.props.onChange("isElectronicReceiptImage", value);
  }

  render() {
    return (
      <div className="electronicReceiptImage-condition col-sm-6 col-md-6">
        <LabeledComponent
          label={i18next.t(
            "transactions.index.searchbox.electronicReceiptImage.label",
          )}
        >
          <RadioGroup
            isClearable={true}
            value={this.props.isElectronicReceiptImage}
            radios={[
              {
                value: true,
                label: i18next.t(
                  "transactions.index.searchbox.electronicReceiptImage.yes",
                ),
              },
              {
                value: false,
                label: i18next.t(
                  "transactions.index.searchbox.electronicReceiptImage.no",
                ),
              },
            ]}
            onChange={this.handleIsElectronicReceiptImageChange}
          />
        </LabeledComponent>
      </div>
    );
  }
}

ElectronicReceiptImageCondition.defaultProps = {
  isElectronicReceiptImage: null,
};

ElectronicReceiptImageCondition.propTypes = {
  isElectronicReceiptImage: PropTypes.bool,
  onChange: PropTypes.func.isRequired,
};
