import React, { FC } from 'react';
import i18next from 'i18n';
import styled from 'styled-components';

interface Props {
  inEditing: boolean;
  isFirstPage?: boolean;
  onClickBackButton: () => void;
  onClickDiscardButton: () => void;
  onClickForwardButton: () => void;
  onClickSaveButton: () => void;
  persisted: boolean;
}

const ForwardAndBackButtonView = styled.div`
  .disabled {
    cursor: not-allowed;
  }

  .enabled {
    cursor: pointer;
  }

  button {
    width: 100px;
  }

  .left-half,
  .right-half {
    justify-content: space-between;
    display: flex;
  }
`;

const ForwardAndBackButton: FC<Props> = (props) => {
  const arrowDisabled = props.inEditing || !props.persisted;

  const renderBackButton = (): JSX.Element => {
    if (props.isFirstPage) {
      return (
        <button
          className={ `btn btn-outline btn-success ${arrowDisabled ? 'disabled' : 'enabled'}` }
          onClick={ props.onClickBackButton }
        >
          { i18next.t('journalEntries.buttons.backToList') }
        </button>
      );
    }

    return (
      <button className={ `btn btn-outline btn-secondary ${arrowDisabled ? 'disabled' : 'enabled'}` }
        onClick={ arrowDisabled ? undefined : props.onClickBackButton }
      >
        <i className='fas fa-chevron-left'></i> { i18next.t('journalEntries.buttons.backward') }
      </button>
    );
  };

  return (
    <ForwardAndBackButtonView className='col-sm-offset-3 col-sm-6'>
      <div className='col-sm-6 left-half'>
        { renderBackButton() }
        <button
          className={ `btn btn-outline btn-danger ${props.inEditing ? 'enabled' : 'disabled'}` }
          onClick={ props.onClickDiscardButton }
        >
          { i18next.t('journalEntries.buttons.discardChanges') }
        </button>
      </div>
      <div className='col-sm-6 right-half'>
        <button
          className={ `btn btn-outline btn-success ${props.inEditing ? 'enabled' : 'disabled'}` }
          onClick={ props.onClickSaveButton }
        >
          { i18next.t('journalEntries.buttons.save') }
        </button>
        <button className={ `btn btn-outline btn-secondary ${arrowDisabled ? 'disabled' : 'enabled'}` }
          onClick={ arrowDisabled ? undefined : props.onClickForwardButton }
        >
          { i18next.t('journalEntries.buttons.forward') } <i className='fas fa-chevron-right'></i>
        </button>
      </div>
    </ForwardAndBackButtonView>
  );
};

export default ForwardAndBackButton;
