import { displayMessage } from "actions/ActionCreators";
import _get from "lodash/get";
import { connect } from "react-redux";
import * as actions from "../actions/transactionTable";
import * as AsyncActions from "../actions/TransactionTableAsyncActions";
import TransactionTable from "../components/TransactionTable";
import makeEditableTransactionTableStateMapper from "../selectors/editableTransactionTable";

function mapDispatchToProps(dispatch, ownProps) {
  return {
    onSelect(transaction, isSelected) {
      dispatch(
        actions.selectTransaction(
          ownProps.transactionsObj,
          transaction,
          isSelected,
        ),
      );
    },
    onSelectAll(isSelected) {
      const { currentPage, sizePerPage, transactionsObj } = ownProps;
      dispatch(
        actions.selectAllTransactions(
          currentPage,
          sizePerPage,
          transactionsObj,
          isSelected,
        ),
      );
    },
    /**
     * 編集モードで経費を更新する処理です。
     * transactions/components/TransactionTableから呼ばれます。
     * @param {string} id 経費ID
     * @param {string} key 更新した経費の属性名
     */
    async onUpdateTransaction(id, key) {
      if (ownProps.onUpdateTransaction) {
        // NOTE: 経費申請・精算申請の場合は経費一覧取得APIが違うので上書きしない
        ownProps.onUpdateTransaction(
          id,
          key,
          ownProps.transactions,
          ownProps.transactionsObj,
        );
      } else {
        // 経費一覧の場合
        const transactions = ownProps.transactions.filter(
          (t) => _get(t, "id") === id,
        );
        const data = await dispatch(
          actions.updateTransactions(
            transactions,
            key,
            ownProps.transactionsObj,
          ),
        );

        if (data && data.message) {
          dispatch(displayMessage("success", data.message));
        }
      }
    },
    onChangeTransaction(id, key, value) {
      if (
        key === "categoryName" ||
        key === "creditCategoryName" ||
        key === "project" ||
        key === "groupName"
      ) {
        // 補完候補付きのプロパティは別処理
        dispatch(actions.selectSuggestion(id, key, value));
      } else {
        dispatch(actions.setFormData(id, key, value));
      }
    },
    onChangeSelectInput(id, key, value) {
      dispatch(actions.setSelectText(id, key, value));
    },
    onClearSelectInput(id, key) {
      dispatch(actions.setSelectText(id, key, ""));
      dispatch(actions.selectSuggestion(id, key, null));

      // 経費科目をクリアした場合、経費を更新しない。updateTransactionsでAPIがコールされてしまうため。
      if (key === "categoryName") return;
      const transactions = ownProps.transactions
        .filter((t) => _get(t, "id") === id)
        .map((t) => ({ ...t, [key]: null }));
      dispatch(
        actions.updateTransactions(transactions, key, ownProps.transactionsObj),
      );
    },
    fetchSuggestions(ownerId = null) {
      dispatch(AsyncActions.fetchCategories(ownerId));
      dispatch(AsyncActions.fetchSuperCategories());
      dispatch(AsyncActions.fetchDepartments());
    },
    initSuggestions(id, key) {
      dispatch(actions.initCurrentSuggestions(id, key));
    },
    clearSuggestions(id, key) {
      dispatch(actions.clearCurrentSuggestions(id, key));
    },
    onSuggestionsUpdateRequested(id, key, { value, reason }) {
      dispatch(actions.requestSuggestionsUpdate(id, key, reason, value));
    },
    onPageChange(page) {
      dispatch(actions.setCurrentPage(page));
    },
    onSizePerPageList(sizePerPage) {
      dispatch(actions.setSizePerPage(sizePerPage));
    },
    onColumnSelectionChange(columnVisibilities) {
      dispatch(actions.setColumnVisibilities(columnVisibilities));
    },
    onRestoreDefaultColumnSelectionClick() {
      dispatch(actions.resetColumnVisibilities());
    },
  };
}

export default connect(
  makeEditableTransactionTableStateMapper,
  mapDispatchToProps,
)(TransactionTable);
