import Api from 'utilities/api';
import flash from 'utilities/flash';
import { FileTransferLocation } from 'utilities/api/models/accountingDataScheduledExports';
import { useCallback, useEffect } from 'react';
import { useFetcher } from 'hooks/useFetcher';

export const useFileTransferLocations = (): {
  locations: FileTransferLocation[];
  fetchLocations: () => Promise<void>;
  isLoading: boolean;
} => {
  const {
    fetch: index, isLoading, response, errorMessage,
  } = useFetcher(Api.accountingDataScheduledExports.fileTransferLocations.index);

  const fetchLocations = useCallback(async (): Promise<void> => {
    await index();
  }, [index]);

  useEffect(() => { if (errorMessage) flash.error(errorMessage); }, [errorMessage]);

  return {
    locations: response?.data ?? [],
    fetchLocations,
    isLoading,
  };
};
