import FileHistoryView from './FileHistoryView';
import React from 'react';
import i18next from 'i18n';
import { MIMEType } from "utilities/api/types/MIMEType";
import { useOriginalReceipt } from 'components/attached_files/useRemoteFile';

interface Props {
  id?: string;
  mimeType?: MIMEType;
  fileName?: string;
}

const OriginalReceipt: React.FunctionComponent<Props> = (props) => {
  const { id, mimeType, fileName } = props;
  const [objectUrl, loadStatus] = useOriginalReceipt(id, mimeType);

  return (
    <FileHistoryView url={ objectUrl }
      mimeType={ mimeType }
      loadStatus={ loadStatus }
      fileName={ fileName }
      title={ i18next.t('transactions.histories.titles.originalReceiptDetails') }
    />
  );
};

export default OriginalReceipt;
