import i18next from "i18n";
import React from "react";
import RequestStateButton from "./RequestStateButton";
import { Props } from "./Types";

/** 「仮払を取り消す」ボタン */
const CancelTemporaryPaymentButton: React.FC<Props> = ({
  requestState,
  onClick,
}) => {
  return (
    <RequestStateButton
      buttonJobType="cancelTemporaryPayment"
      buttonTitle={i18next.t("commons.actions.cancelTemporaryPayment")}
      buttonClassName="btn btn-block btn-danger"
      iconClassName="fa fa-left fa-undo"
      requestState={requestState}
      onClick={onClick}
    />
  );
};

export default CancelTemporaryPaymentButton;
