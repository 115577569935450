import PropTypes from 'prop-types';
import React, { Component } from 'react';
import isNil from 'lodash/isNil';
import { createBlobUrlFromId, handleS3Error } from 'utilities/s3';

export default class S3Image extends Component {
  componentDidMount() {
    if (isNil(this.props.src) && !isNil(this.props.imageId) && !this.failed) {
      // TODO: エラーハンドリング漏れ？
      createBlobUrlFromId(this.props.imageId)
        .then((image) => this.onCreateBlobUrl(image));
    }
  }

  // eslint-disable-next-line camelcase
  UNSAFE_componentWillReceiveProps(nextProps) {
    if (isNil(nextProps.src) && !isNil(nextProps.imageId) && nextProps.src !== this.props.src && !this.failed) {
      // TODO: エラーハンドリング漏れ？
      createBlobUrlFromId(this.props.imageId)
        .then((image) => this.onCreateBlobUrl(image));
    }
  }

  getImage() {
    return this._image;
  }

  render() {
    return (
      <img ref={ (ref) => { this._image = ref; } } className={ this.props.className }
        src={ this.props.src }
        style={ this.props.style }
        onLoad={ this.props.onLoad }
        onClick={ this.props.onClick }
        onMouseOver={ this.props.onMouseOver }
        onMouseOut={ this.props.onMouseOut }
        onMouseMove={ this.props.onMouseMove }
        onError={ handleS3Error.bind(null, this.props.imageId, this.props.onFetchPresignedUrl, true) }
        crossOrigin='anonymous'
      />
    );
  }

  onCreateBlobUrl(url) {
    this.props.onFetchPresignedUrl(url);
  }
}

S3Image.defaultProps = {
  onFetchPresignedUrl() {},
};

S3Image.propTypes = {
  src: PropTypes.string,
  className: PropTypes.string,
  style: PropTypes.object,
  imageId: PropTypes.string.isRequired,
  onLoad: PropTypes.func,
  onMouseOver: PropTypes.func,
  onMouseOut: PropTypes.func,
  onMouseMove: PropTypes.func,
  onFetchPresignedUrl: PropTypes.func.isRequired,
};
