import React from 'react';
import fonts from 'values/fonts';
import i18next from 'i18n';
import styled from 'styled-components';
import { Button } from 'components/renewaled_ui/buttons';
import { Dropdown, MenuItem } from 'components/renewaled_ui/Dropdown';

const Title = styled.div`
  padding: 8px 16px;
  font-weight: ${Number(fonts.weight.bold)};
  font-size: 12px;
  color: #616161;
  user-select: none;
`;

interface Props {
  /** 1ページ当たりの表示件数 */
  readonly sizePerPage: number;
  /** 現在のページ */
  readonly currentPage: number;
  /**
   * テーブル左下の１ページあたりのデータ取得結果を表示するか
   * 一部APIで、クエリで指定する検索結果の数と実際に返ってくる数に差がある場合があり、
   * 合わない箇所については暫定的に結果の数を隠せる(falseにする)ためのprops
   */
  readonly showItemsCount?: boolean;
  /** 1ページ当たりの表示件数の選択肢 */
  readonly sizePerPageList: number[];
  /** 現在のページの表示件数 */
  readonly currentPageDataLength: number;
  /** 表示件数 */
  readonly dataLength: number;
  /** 1ページ当たりの表示件数を変更した際のハンドラ */
  readonly onSizePerPageList: (sizePerPage: number) => void;
}

/**
 * 表示件数の変更セレクトボタン
 */
export const SizePerPageSelectorNew: React.FC<Props> = (props) => {
  const from = ((props.currentPage - 1) * props.sizePerPage) + 1;
  const to = from + props.currentPageDataLength - 1;

  // 表示しない
  if (!props.currentPageDataLength) return <React.Fragment />;

  return (
    <Dropdown
      id='size-per-page-drop-down'
      direction='up'
      align='left'
      toggleButton={ (
        <Button bsRole='toggle' styleType='link-secondary' small linkText className='size-per-page-drop-down-button'>
          { props.showItemsCount ? (
            <span style={ { fontWeight: 500 } }>
              { from }〜{ to }{' '}
              { i18next.t('commons.dataFetchTable.itemsAreDisplayed') }
              {" / "}
              { i18next.t('commons.dataFetchTable.total') }{' '}
              { props.dataLength }{' '}
              { i18next.t('commons.dataFetchTable.items') }
            </span>
          ) : (
            <span style={ { fontWeight: 500 } }>
              { i18next.t('commons.words.sizePerPage') }{': '}{ props.sizePerPage }{' '}{ i18next.t('commons.dataFetchTable.items') }
            </span>
          ) }
        </Button>
        ) }
      >

      <Title>
        { i18next.t('commons.dataFetchTable.sizePerPage') }
      </Title>

      { props.sizePerPageList.map((size) => (
        <MenuItem
          key={ `size-per-page-${size}` }
          onClick={ (): void => props.onSizePerPageList(size) }>
          {size}
        </MenuItem>
      )) }
    </Dropdown>
  );
};
