import Api from 'utilities/api';
import React, { useCallback, useEffect, useState } from 'react';
import i18next from 'i18next';
import { ApprovalStep, ApprovalStepsForReject, RequestStep } from 'utilities/api/models/ApprovalStepsForReject';
import { Modal } from 'react-bootstrap';
import { RejectForm } from './RejectForm';
import { snakecaseKeys } from 'utilities/Utils';

interface Props {
  id: string;
  showModal: boolean;
  processing: boolean;
  closeModal: () => void;
  rejectReport: (id: string, comment: string, step: RequestStep | ApprovalStep | null) => void;
}

export const RejectModal: React.FC<Props> = ({
  id,
  showModal,
  processing,
  closeModal,
  rejectReport,
}) => {
  // コメント
  const [comment, setComment] = useState('');
  // 差戻先
  const [selectedStep, setSelectedStep] = useState<RequestStep | ApprovalStep | null>(null);
  // 差戻先の選択肢
  const [steps, setSteps] = useState<ApprovalStepsForReject | null>(null);
  // コメント設定ハンドラ
  const handleSetCommend = useCallback((newComment: string): void => setComment(newComment), []);
  // 差戻し選択ハンドラ
  const handleSelectStep = useCallback((newStep: RequestStep | ApprovalStep) => setSelectedStep(newStep), []);
  // 申請書差戻し
  const handleReject = useCallback(() => {
    try {
      rejectReport(id, comment, selectedStep);
    } catch {
      // 何もしない
    }
  }, [id, comment, selectedStep]);

  // 初期化
  useEffect(() => {
    if (id) {
      const fetchApprovalSteps = async (approvalId: string): Promise<void> => {
        try {
          // 差戻先一覧を取得
          const data = await Api.approvals.approvalSteps(snakecaseKeys({ id: approvalId }));
          setSteps(data);
          // 差戻先の初期値は申請者とする
          setSelectedStep(data.requestStep);
        } catch (e) {
          // 何もしない
        }
      };
      fetchApprovalSteps(id);
    }

    return (): void => {
      setComment('');
      setSelectedStep(null);
    };
  }, [id]);

  return (
    <>
      <Modal
        show={ showModal }
        onHide={ closeModal }>
        <Modal.Header closeButton>
          <Modal.Title>{ i18next.t('commons.actions.reject') }</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {
            <RejectForm
              steps={ steps }
              selectedStep={ selectedStep }
              comment={ comment }
              onChangeComment={ handleSetCommend }
              onSelectStep={ handleSelectStep }
            />
          }
        </Modal.Body>
        <Modal.Footer>
          <div className='col-sm-6 col-sm-offset-3'>
            {
              (steps && selectedStep) ? (
                <button
                  className={ `btn btn-block btn-danger ${processing ? ' disabled' : ''}` }
                  onClick={ handleReject }>
                  { processing ? <i className='fa fa-spinner fa-spin'></i> : '' }
                  { i18next.t('commons.actions.reject') }
                </button>
              ) : (
                <button className='btn btn-block btn-danger disabled' disabled>{ i18next.t('commons.actions.reject') }</button>
              )
            }
          </div>
        </Modal.Footer>
      </Modal>
    </>
  );
};
