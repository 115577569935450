import React from 'react';
import { ControlLabel, ControlLabelProps } from 'react-bootstrap';

interface Props {
  children: React.ReactNode;
  contentProps?: React.HTMLAttributes<HTMLDivElement>;
  label: string;
  labelProps?: ControlLabelProps;
}

export const FormElement: React.FC<Props> = React.memo(({
  children,
  contentProps = { className: 'col-sm-6 col-md-6' },
  label,
  labelProps = { className: 'col-sm-3' },
}) => {
  return (
    <div className='form-group'>
      <ControlLabel { ...labelProps }>{label}</ControlLabel>
      <div { ...contentProps }>{ children }</div>
    </div>
  );
});
