import React from 'react';
import colors from 'values/colors';
import fonts from 'values/fonts';
import i18next from 'i18n';
import styled from 'styled-components';
import { Button, IconButton } from 'components/renewaled_ui/buttons';
import { TableColumnControlParam } from 'components/renewaled_ui/ModalColumnControl/interface';

const Wrapper = styled.div`
  width: 58%;
`;
const Title = styled.div`
  font-weight: ${fonts.weight.bold};
  margin-bottom: 8px;
  user-select: none;
`;
const Items = styled.ul`
  height: 400px;
  overflow-y: auto;
  padding: 0;
  margin: 0;
`;
const Item = styled.li`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;
const Card = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 8px;
  background: ${colors.renewaled.whiteHover};
  margin-bottom: 4px;
  border-radius: 4px;

  &:hover {
    .top-button {
      opacity: 1;
    }
  }
`;
const Name = styled.div`
  font-weight: 600;
`;
const Buttons = styled.div`
  display: flex;
  align-items: center;

  .top-button {
    opacity: 0;
    transition: 300ms;
  }
`;
const RemoveButton = styled.div`
  margin-left: 8px;
`;

interface Props {
  /** 表示/非表示列データ */
  readonly tableColumns: TableColumnControlParam[];
  /** 一番上にするボタンを押した */
  readonly onClickTop: (accessor: string) => void;
  /** 上ボタンを押した */
  readonly onClickUp: (accessor: string) => void;
  /** 下ボタンを押した */
  readonly onClickDown: (accessor: string) => void;
  /** 削除ボタンを押した */
  readonly onClickRemove: (accessor: string) => void;
  /** 上に行けるか */
  readonly canUpColumn: (accessor: string) => boolean;
  /** 下に行けるか */
  readonly canDownColumn: (accessor: string) => boolean;
}

/**
 * 右の欄: 順序と表示/非表示の設定
 */
const DisplayIndexController: React.FC<Props> = (p) => {
  const showColumns = p.tableColumns.filter((c) => c.show);

  return (
    <Wrapper>
      <Title>
        { i18next.t('transactions.index.selectedColumns') }{' '}
        ({ showColumns.length }{ i18next.t('commons.units.result') })
      </Title>
      <Items>
        { showColumns.map((c, index) => {
          return (
            <Item key={ `column-panel-${c.accessor}` }>
              <Card>
                <Name>
                  <span>
                    { c.accessor === 'error' ? i18next.t('commons.status.alert') : c.Header }
                  </span>
                </Name>
                <Buttons>
                  {index !== 0 && (
                    <div className="top-button">
                      <Button
                        small
                        styleType='link-primary'
                        onClick={ (): void => p.onClickTop(c.accessor) }
                      >
                        { i18next.t('transactions.index.moveToTop') }
                      </Button>
                    </div>
                  )}
                  <div>
                    <IconButton
                      onClick={ (): void => p.onClickUp(c.accessor) }
                      disabled={ !p.canUpColumn(c.accessor) }
                      buttonSize={ 20 }
                      fontSize={ 16 }
                      className='fas fa-angle-up'
                    />
                    <IconButton
                      onClick={ (): void => p.onClickDown(c.accessor) }
                      disabled={ !p.canDownColumn(c.accessor) }
                      buttonSize={ 20 }
                      fontSize={ 16 }
                      className='fas fa-angle-down'
                    />
                  </div>
                </Buttons>
              </Card>
              <RemoveButton>
                <IconButton
                  onClick={ (): void => p.onClickRemove(c.accessor) }
                  buttonSize={ 24 }
                  fontSize={ 16 }
                  className='fas fa-times'
                />
              </RemoveButton>
            </Item>
          );
        }) }
      </Items>
    </Wrapper>
  );
};

export default DisplayIndexController;
