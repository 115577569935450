import DateInput from "components/date_input";
import FormValidation from "components/FormValidation";
import LabeledComponent from "components/LabeledComponent";
import i18next from "i18n";
import React, { FC } from "react";

interface Props {
  date: string; // "2020-01-15T00:00:00.000+09:00"
  disabled: boolean;
  errors: string[];
  onChangeDate: (date: string) => void;
}

const PreExpenseDateForm: FC<Props> = (props) => {
  return (
    <LabeledComponent
      className="form-group"
      labelClass="col-sm-3"
      label={i18next.t("transactions.properties.date")}
    >
      <div className="col-sm-6">
        <FormValidation messages={props.errors}>
          {props.disabled ? (
            <div
              className="form-control-static"
              style={{ paddingLeft: "12px" }}
            >
              <DateInput
                disabled={true}
                value={props.date}
                withDayOfTheWeek={true}
              />
            </div>
          ) : (
            <DateInput
              inputClassName={`form-control toggle`}
              value={props.date}
              placeholder={i18next.t("commons.status.notEntered")}
              onChange={props.onChangeDate}
              withDayOfTheWeek={true}
            />
          )}
        </FormValidation>
      </div>
    </LabeledComponent>
  );
};

export default PreExpenseDateForm;
