import FlipSwitch from 'components/FlipSwitch';
import React, { FC } from 'react';
import { OverlayTrigger, Popover } from 'react-bootstrap';
import { uniqueId } from 'lodash';

interface Props {
  description?: string;
  label: string | JSX.Element;
  onToggle: () => void;
  popoverDescription?: string | JSX.Element;
  popoverId?: string;
  toggleState: boolean;
}


const ToggleSettingComponent: FC<Props> = ({
  description = '',
  popoverId = uniqueId('popover-'),
  ...props
}) => {
  return (
    <div className='use-payment-date'>
      <div className='attention'>
        <p className='txt'>{ description }</p>
      </div>
      <div>
        <form className='form-horizontal'>
          <div style={ { display: 'flex', alignItems: 'center' } }>
            <label className='control-label col-sm-5 col-md-5'>
              { props.label }
              {
                props.popoverDescription ? (
                  <OverlayTrigger
                    trigger={ ['click', 'focus', 'hover'] }
                    placement='top'
                    overlay={ <Popover id={ popoverId }>{ props.popoverDescription }</Popover> }>
                    <i className='far fa-question-circle' style={ { color: '#AAA', marginLeft: '6px', cursor: 'pointer' } } />
                  </OverlayTrigger>
                ) : <></>
              }
            </label>
            <FlipSwitch
              className="col-md-offset-1 col-sm-6 col-md-6"
              checked={ props.toggleState }
              onChange={ props.onToggle }
            />
          </div>
        </form>
      </div>
    </div>
  );
};

export default ToggleSettingComponent;
