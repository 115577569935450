import i18next from "i18n";
import _ from "lodash";
import PropTypes from "prop-types";
import React from "react";
import { Button, Modal, Tab, Tabs } from "react-bootstrap";
import {
  validateCategory,
  validateFare,
  validateSuperCategory,
  validateTaxCategory,
} from "utilities/validator";
import ExpenseSelector from "../../containers/ExpenseSelectorContainer";
import TransactionForm from "../../containers/TransactionForm";

/**
 * 経費と原本の突合をサポートするモーダル
 */
class ExpenseMatchingModal extends React.Component {
  constructor(props) {
    super(props);
    this.state = { activeTab: "ExpenseSelector", inProcess: false };
    this.handleSelectTab = this.handleSelectTab.bind(this);
    this.initSuggestionExpense = this.initSuggestionExpense.bind(this);
    this.validExpenseCreateForm = this.validExpenseCreateForm.bind(this);
    this.handleMatchingButton = this.handleMatchingButton.bind(this);
  }

  // 突合対象となる可能性の高い経費一覧を取得する
  initSuggestionExpense() {
    this.props.fetchSuggestionExpense(
      (this.props.expenseCurrentPage - 1) * this.props.expenseSizePerPage,
      this.props.expenseSizePerPage,
      this.props.originalReceipt.transactedAt,
      this.props.originalReceipt.amount,
    );
  }

  componentDidUpdate(prevProps) {
    const isOpenModal = prevProps.show === false && this.props.show === true;

    if (isOpenModal) {
      // モーダルのオープン時にフォームをクリアする
      if (this.props.isCreatable) {
        this.props.fetchEntryForms().then(() => {
          this.props.resetExpenseForm();
        });
      }
      this.initSuggestionExpense();
    }
  }

  handleSelectTab(key) {
    this.setState({ activeTab: key });
  }

  validExpenseCreateForm() {
    return $(".transaction-form").validationEngine("validate", {
      customFunctions: {
        validateFare,
        validateTaxCategory: validateTaxCategory.bind(
          null,
          this.props.suggestions.taxCategories.map((it) => it.name),
        ),
        validateCategory: validateCategory.bind(
          null,
          this.props.suggestions.categories.map((it) => it.name),
        ),
        validateSuperCategory: validateSuperCategory.bind(
          null,
          this.props.suggestions.superCategories.map((it) => it.name),
        ),
        validateProject: () =>
          _.isNil(this.props.formValues.project_input)
            ? "必須項目です"
            : void 0,
        validateSelect: (field) => {
          const foundClassName = Array.prototype.find.call(
            field[0].classList,
            (x) => x.startsWith("select-"),
          );

          if (!foundClassName) {
            return void 0;
          }

          return _.isNil(
            this.props.formValues[foundClassName.replace("select-", "")],
          )
            ? "必須項目です"
            : void 0;
        },
        validateCustomValue: (field) => {
          const foundClassName = Array.prototype.find.call(
            field[0].classList,
            (x) => x.startsWith("custom-"),
          );

          if (!foundClassName) {
            return void 0;
          }

          return _.isNil(
            this.props.formValues[foundClassName.replace("custom-", "")],
          )
            ? "必須項目です"
            : void 0;
        },
      },
    });
  }

  async handleMatchingButton() {
    const {
      originalReceipt,
      selectedExpense,
      // 以下の関数内で、処理中に変更される可能性のあるpropsの値を参照しているため、呼び出される関数の実体は処理開始時点のものである必要がある
      onClickMatchingButton,
    } = this.props;

    if (this.state.activeTab === "ExpenseSelector") {
      this.setState({ inProcess: true });

      try {
        await onClickMatchingButton(originalReceipt.id, selectedExpense.id);
        this.props.onHide();
      } catch (e) {
        // 何もしない
      } finally {
        this.setState({ inProcess: false });
      }
    } else if (this.state.activeTab === "ExpenseBuilder") {
      if (!this.validExpenseCreateForm()) {
        return;
      }

      this.setState({ inProcess: true });
      let expense = null;

      try {
        expense = await this.props.createExpense();
        await onClickMatchingButton(originalReceipt.id, expense.id);
        this.props.onHide();
      } catch (e) {
        if (!_.isNil(expense) && !_.isNil(expense.id)) {
          // 突合に失敗した場合、作成した経費も削除してロールバックする
          await this.props.deleteExpense(expense.id);
        }
      } finally {
        this.setState({ inProcess: false });
      }
    } else {
      /* TODO: 日当など, 異なる処理を行う場合に実装すること */
    }
  }

  render() {
    return (
      <Modal
        ref="modal"
        show={this.props.show}
        onHide={this.props.onHide}
        bsSize="lg"
      >
        <Modal.Header closeButton>
          <Modal.Title>
            {i18next.t(
              "paperless.originalReceipts.titles.selectMatchedExpense",
            )}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body bsClass="expense-matching-modal">
          <Tabs
            id="ExpenseMatchingMenu"
            defaultActiveKey={"ExpenseSelector"}
            onSelect={(key) => this.handleSelectTab(key)}
          >
            <Tab
              eventKey="ExpenseSelector"
              title={i18next.t("transactions.index.selectTransaction")}
            >
              <ExpenseSelector forAdmin={this.props.forAdmin} />
            </Tab>
            {this.props.isCreatable && (
              <Tab
                eventKey="ExpenseBuilder"
                title={i18next.t("transactions.index.createTransaction")}
                unmountOnExit={true}
                mountOnEnter={true}
              >
                <div style={{ margin: "16px" }}>
                  <TransactionForm
                    isNew
                    inModal={true}
                    fields={this.props.expenseFields}
                    suggestions={this.props.suggestions}
                    comment={this.props.expenseComment}
                    /**
                     * NOTE: フォームの表示判定に使用している必須値
                     * 現状の仕様だと, 初期の作成時には null を入れておく必要がある
                     */
                    reportId={null}
                    preReportId={null}
                    isTaxAmountShow={true}
                  />
                </div>
              </Tab>
            )}
          </Tabs>
        </Modal.Body>
        <Modal.Footer>
          <Button
            bsStyle="success"
            className={`col-sm-offset-9 col-sm-3 ${
              this.state.inProcess ? " disabled" : ""
            }`}
            onClick={this.handleMatchingButton}
          >
            {this.state.inProcess ? (
              <i className="fa fa-spinner fa-spin"></i>
            ) : (
              i18next.t(
                "paperless.originalReceipts.matchingActions.selectMatchedExpense",
              )
            )}
          </Button>
        </Modal.Footer>
      </Modal>
    );
  }
}

ExpenseMatchingModal.defaultProps = {
  isCreatable: true,
};

ExpenseMatchingModal.propTypes = {
  isCreatable: PropTypes.bool,
  forAdmin: PropTypes.bool,
  fetchEntryForms: PropTypes.func, // 経費作成に使用するフォームを取得
  expenseFields: PropTypes.array, // 経費作成時に適用するフォーム
  suggestions: PropTypes.object, // 経費作成時にサジェストするリスト
  expenseComment: PropTypes.string,
  selectedExpense: PropTypes.object, // 選択した経費
  originalReceipt: PropTypes.object, // 突合対象の原本
  fetchSuggestionExpense: PropTypes.func, // 突合対象の原本と紐づく可能性の高い経費をフェッチするAPI
  createExpense: PropTypes.func, // 経費を作成するAPI
  onClickMatchingButton: PropTypes.func, // 原本と経費の突合API
  formValues: PropTypes.object, // フォームに入力された値
  resetExpenseForm: PropTypes.func, // フォーム情報をリセットするための関数
  deleteExpense: PropTypes.func, // 経費を削除するための関数
};

export default ExpenseMatchingModal;
