import React from "react";
import stationType from "types/station";

const StationListElement = ({ datum }) => {
  let classNameStr = "station-type";
  switch (datum.type) {
    case "train":
      classNameStr += "-train";
      break;
    case "bus":
      classNameStr += "-bus";
      break;
    case "plane":
      classNameStr += "-plane";
      break;
    default:
      break;
  }
  return (
    <span className={`station-suggestion ${classNameStr}`}>
      <a>{datum.name}</a>
    </span>
  );
};

StationListElement.propTypes = {
  datum: stationType,
};

export default StationListElement;
