import get from "lodash/get";
import PropTypes from "prop-types";
import React, { Component } from "react";
import { Provider } from "react-redux";
import { configureReducers } from "reducers";
import { createStore } from "redux";
import { createEnhancer } from "utilities/react_helper";
import { resetTransaction } from "../actions";
import reducers from "../reducers";
import { initialTransaction } from "../reducers/formData";
import {
  buildFormFields,
  buildFormValues,
} from "../utilities/transactionFormBuilder";
import TransactionModal from "./TransactionModal";

export class TransactionModalWrapper extends Component {
  constructor(props) {
    super(props);

    this.store = createStore(
      configureReducers(reducers),
      this.processProps(props),
      createEnhancer(),
    );
  }

  componentDidMount() {
    if (this.shouldResetExpense()) {
      this.store.dispatch(resetTransaction(this.processProps(this.props)));
    }
  }

  componentDidUpdate(prevProps) {
    if (this.shouldResetExpense(prevProps)) {
      this.store.dispatch(resetTransaction(this.processProps(this.props)));
    }
  }

  shouldResetExpense(prevProps = {}) {
    if (this.props.show !== prevProps.show && this.props.show === true) {
      return true;
    }

    if (
      this.props.transaction?.id !== prevProps.transaction?.id &&
      this.props.transaction?.id
    ) {
      return true;
    }

    if (this.willUpdateFormFields(prevProps)) {
      return true;
    }

    return false;
  }

  /**
   * フォームのフィールドが更新されるかどうかを返します。
   */
  willUpdateFormFields(prevProps) {
    return this.props.formFields?.length !== prevProps.formFields?.length;
  }

  // todo Modalを開く時のformFieldsの初期化処理
  processProps(props) {
    const formValues = buildFormValues(props.formFields, props.transaction);

    // 汎用マスタのフィールドは動的であるため、ここでformFieldsから組み立ててformDataに渡している
    const genericFields = props.formFields
      .filter((f) => f.type === "generic_fields_input")
      .map((f) => ({
        dataSetId: f.dataSetId,
        items: [],
      }));

    return {
      formData: {
        ...initialTransaction,
        ...props.transaction,
        formValues,
        genericFields,
        authority: props.authority,
      },
      formState: {
        fields: buildFormFields(
          props.formFields,
          {
            authority: props.authority,
            status: get(props.transaction, "status", null),
          },
          formValues,
        ),
      },
    };
  }

  render() {
    const {
      show,
      closeModal,
      authority,
      onDestroySuccess,
      onUpdateSuccess,
      onCreateSuccess,
      onDetachSuccess,
      onDeleteImage,
      onRotateImage,
      onUnread,
      ownerId,
      shouldSelectSelfAsCompanion,
      onGoToNextExpense,
      onGoToPreviousExpense,
      onSplitSuccess,
    } = this.props;

    return (
      <Provider store={this.store}>
        <TransactionModal
          authority={authority}
          closeModal={closeModal}
          fromPreTransaction={this.props.fromPreTransaction}
          matchedOriginalReceipt={this.props.transaction.matchedOriginalReceipt}
          onCreateSuccess={onCreateSuccess}
          onDeleteImage={onDeleteImage}
          onDestroySuccess={onDestroySuccess}
          onDetachSuccess={onDetachSuccess}
          onSplitSuccess={onSplitSuccess}
          onGoToNextExpense={onGoToNextExpense}
          onGoToPreviousExpense={onGoToPreviousExpense}
          onRotateImage={onRotateImage}
          onSelectCompanionsCategory={this.props.onSelectCompanionsCategory}
          onUnread={onUnread}
          onUpdateSuccess={onUpdateSuccess}
          ownerId={ownerId}
          preReportDepartment={this.props.transaction.preReportDepartment}
          preReportId={this.props.transaction.preReportId}
          preReportSequenceNum={this.props.transaction.preReportSequenceNum}
          preReportTitle={this.props.transaction.preReportTitle}
          receiptExpenseMatching={this.props.transaction.receiptExpenseMatching}
          reportId={this.props.transaction.reportId}
          reportSequenceNum={this.props.transaction.reportSequenceNum}
          reportTitle={this.props.transaction.reportTitle}
          shouldSelectSelfAsCompanion={shouldSelectSelfAsCompanion}
          show={show}
          isCreatedByAggregation={this.props.transaction.isCreatedByAggregation}
          isElectronicReceiptImage={
            this.props.transaction.isElectronicReceiptImage
          }
          disableAssignableRportInput={this.props.disableAssignableRportInput}
          // genericFields={ this.props.transaction.genericFields } # 消したけど大丈夫だろうか？
        />
      </Provider>
    );
  }
}

TransactionModalWrapper.defaultProps = {
  fromPreTransaction: false,
  onCreateSuccess() {},
  onDeleteImage() {},
  onDestroySuccess() {},
  onDetachSuccess() {},
  onSplitSuccess() {},
  onUnread() {},
  onUpdateSuccess() {},
};

TransactionModalWrapper.propTypes = {
  authority: PropTypes.string,
  closeModal: PropTypes.func.isRequired,
  fromPreTransaction: PropTypes.bool.isRequired,
  onCreateSuccess: PropTypes.func.isRequired,
  onDestroySuccess: PropTypes.func.isRequired,
  onDetachSuccess: PropTypes.func.isRequired,
  onSplitSuccess: PropTypes.func,
  onGoToNextExpense: PropTypes.func,
  onGoToPreviousExpense: PropTypes.func,
  onRotateImage: PropTypes.func,
  onUnread: PropTypes.func,
  onUpdateSuccess: PropTypes.func.isRequired,
  shouldSelectSelfAsCompanion: PropTypes.bool.isRequired,
  show: PropTypes.bool.isRequired,
  transaction: PropTypes.object,
};

export default TransactionModalWrapper;
