import * as actions from '../actions';
import CsvExportForm from './CsvExportForm';
import PreXlsExportForm from './PreXlsExportForm';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import TemporaryPaymentXlsExportForm from './TemporaryPaymentXlsExportForm';
import XlsExportForm from './XlsExportForm';
import ZenginExportForm from './ZenginExportForm';
import assign from 'lodash/assign';
import get from 'lodash/get';
import i18next from 'i18n';
import isNil from 'lodash/isNil';
import pick from 'lodash/pick';
import { Modal } from 'react-bootstrap';
import { connect } from 'react-redux';
import { renderComponentWithTab } from 'utilities/tab_renderer';

export class ExportModal extends Component {
  constructor(props) {
    super(props);
    this.close = this.close.bind(this);
    this.getTabGroups = this.getTabGroups.bind(this);
    this.tabContentRenderer = this.tabContentRenderer.bind(this);
    this.setupTabs();
  }

  // eslint-disable-next-line camelcase
  UNSAFE_componentWillReceiveProps(nextProps) {
    if ((!isNil(nextProps.reportId) && this.propsWillChanged(nextProps, 'reportId'))
        || (!isNil(nextProps.preReportId) && this.propsWillChanged(nextProps, 'preReportId'))
        || (!isNil(nextProps.analysisId) && this.propsWillChanged(nextProps, 'analysisId'))
        || (!isNil(nextProps.preAnalysisId) && this.propsWillChanged(nextProps, 'preAnalysisId'))) {
      this.setupTabs(nextProps);
    }
  }

  propsWillChanged(nextProps, propName) {
    return this.props[propName] !== nextProps[propName] && !isNil(nextProps[propName]);
  }

  setupTabs(props = this.props) {
    const tabStates = {
      csv: {
        name: i18next.t('exports.titles.accountingData'),
        renderer: this.renderCsvExportForm.bind(this),
      },
      xls: {
        name: i18next.t('exports.titles.applicationForm'),
        renderer: this.renderXlsExportForm.bind(this),
      },
      preXls: {
        name: i18next.t('exports.titles.preReport'),
        renderer: this.renderPreXlsExportForm.bind(this),
      },
      temporaryPaymentXls: {
        name: i18next.t('exports.titles.temporaryPayment'),
        renderer: this.renderTemporaryPaymentXlsExportForm.bind(this),
      },
      zengin: {
        name: i18next.t('exports.titles.zengin'),
        renderer: this.renderZenginExportForm.bind(this),
      },
    };

    const tabs = props.exportFormats.map(
      (format, index) => {
        let tabState;

        if (index === 0) {
          tabState = assign(tabStates[format], { isActive: true });
        } else {
          tabState = assign(tabStates[format], { isActive: false });
        }

        return tabState;
      },
    );

    this.props.setupTabs(tabs);
  }

  getTabGroups(tabs) {
    return tabs.map((tab) => (pick(tab, ['name', 'isActive'])));
  }

  tabContentRenderer(group, idx) {
    return get(this.props.tabs, idx).renderer();
  }

  renderCsvExportForm() {
    const {
      reportId, preReportId, analysisId, preAnalysisId, preTemporaryPaymentAnalysisId, aggregationId,
    } = this.props;

    const targetId = reportId || preReportId || analysisId || preAnalysisId || preTemporaryPaymentAnalysisId || aggregationId;
    let type = null;

    if (!isNil(reportId)) {
      type = 'report';
    } else if (!isNil(preReportId)) {
      type = 'preReport';
    } else if (!isNil(analysisId)) {
      type = 'analysis';
    } else if (!isNil(preAnalysisId)) {
      type = 'preAnalysis';
    } else if (!isNil(preTemporaryPaymentAnalysisId)) {
      type = 'preTemporaryPaymentAnalysis';
    } else {
      type = 'aggregation';
    }

    return (
      <CsvExportForm
        type={ type }
        targetId={ targetId }
        onExport={ this.close }
      />
    );
  }

  renderXlsExportForm() {
    return (
      <XlsExportForm
        reportId={ this.props.reportId }
        preReportId={ this.props.preReportId }
        onExport={ this.close }
      />
    );
  }

  renderPreXlsExportForm() {
    return (
      <PreXlsExportForm
        preReportId={ this.props.preReportId }
        onExport={ this.close }/>
    );
  }

  renderTemporaryPaymentXlsExportForm() {
    return (
      <TemporaryPaymentXlsExportForm
        preReportId={ this.props.preReportId }
        onExport={ this.close }/>
    );
  }

  renderModalBody(show, tabs) {
    if (!show) {
      return null;
    }

    return renderComponentWithTab(this.getTabGroups(tabs),
      this.tabContentRenderer,
      this.props.onClickTab);
  }

  renderZenginExportForm() {
    const {
      analysisId, preAnalysisId, preTemporaryPaymentAnalysisId, aggregationId,
    } = this.props;

    const targetId = analysisId || preAnalysisId || preTemporaryPaymentAnalysisId || aggregationId;
    let type = null;

    if (!isNil(analysisId)) {
      type = 'analysis';
    } else if (!isNil(preAnalysisId) || !isNil(preTemporaryPaymentAnalysisId)) {
      type = 'preAnalysis';
    } else {
      type = 'aggregation';
    }

    return (
      <ZenginExportForm
        type={ type }
        targetId={ targetId }
      />
    );
  }

  render() {
    return (
      <Modal show={ this.props.show } onHide={ this.close } bsSize='lg'>
        <Modal.Header closeButton>
          <Modal.Title>{ i18next.t('exports.titles.fileExport') }</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          { this.renderModalBody(this.props.show, this.props.tabs) }
        </Modal.Body>
      </Modal>
    );
  }

  close() {
    this.props.closeModal();
  }
}

/* TODO: 支払依頼など、出力が必要な処理が増えた時に、スケールするように
 *
 * Modalはtab名とpropの情報だけを受け取って、個別の処理は~Formに任せたほうが良さそう
 *   tabs: [
 *    { type: 'csv', props: { ... } },
 *    { type: 'zengin', props: { ... } },
 *   ]
 * のようなイメージ
 */
ExportModal.propTypes = {
  aggregationId: PropTypes.string,
  analysisId: PropTypes.string,
  closeModal: PropTypes.func.isRequired,
  exportFormats: PropTypes.array.isRequired,
  onClickTab: PropTypes.func.isRequired,
  preAnalysisId: PropTypes.string,
  preReportId: PropTypes.string,
  preTemporaryPaymentAnalysisId: PropTypes.string,
  reportId: PropTypes.string,
  setupTabs: PropTypes.func.isRequired,
  show: PropTypes.bool.isRequired,
  tabs: PropTypes.array.isRequired,
};

function mapStateToProps(state, ownProps) {
  const { exportData } = state;
  return {
    tabs: exportData.tabs,
  };
}

function mapDispatchToProps(dispatch, ownProps) {
  return {
    setupTabs(tabs) {
      dispatch(actions.setupTabs(tabs));
    },
    onClickTab(e, group) {
      dispatch(actions.onClickTab(e, group));
    },
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(ExportModal);
