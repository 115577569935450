import ExportFormatAssigneesComponent from './ExportFormatAssigneesComponent';
import React from 'react';
import SmartCheckBox from 'components/SmartCheckBox';
import i18next from 'i18n';
import { Controller, useFormContext } from 'react-hook-form';
import { SpecifiedDateSelectField } from './fields/SpecifiedDateSelectField';
import { TaskExportFormatAssignee } from 'utilities/api/models/accountingDataScheduledExports';

export const ExportForm = React.forwardRef((_, ref?: React.Ref<HTMLDivElement>) => {
  const {
    control,
    watch,
    setValue,
  } = useFormContext();

  const enableAccountingDataCreation = watch('enableAccountingDataCreation');

  const initValueExportFormatAssignees: TaskExportFormatAssignee = {
    exportFormat: null,
    exportFileNameFormat: '',
  };

  const onChangeEnableAccountingDataCreation = (value: boolean, onChange: (value: boolean) => void): void => {
    onChange(value);

    if (value) setValue('exportFormatAssignees', [initValueExportFormatAssignees]);
    else setValue('exportFormatAssignees', []);
  };


  return (
    <>
      <div ref={ ref } />
      <h4 className='title'>
        { i18next.t('accountingDataScheduledExports.tasks.forms.export.title') }
      </h4>
      <Controller
        name='enableAccountingDataCreation'
        control={ control }
        render={ ({ field: { onChange, value } }): React.ReactElement => (
          <SmartCheckBox
            onChange={ (e):void =>  onChangeEnableAccountingDataCreation(e, onChange) }
            checked={ value || false }
            label={ i18next.t('accountingDataScheduledExports.tasks.forms.export.enableAccountingDataCreation.label') }
          />
        ) }
      />
      {
        enableAccountingDataCreation && <>
          <ExportFormatAssigneesComponent
            enableAccountingDataCreation={ enableAccountingDataCreation }
            initValue={ initValueExportFormatAssignees }
          />
          <SpecifiedDateSelectField />
        </>
      }
    </>
  );
});
