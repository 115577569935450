import * as ActionTypes from '../../actions/search/conditions';
import { initialSearchConditionState as initialState } from '../../types';

const conditions = (state = initialState, action) => {
  switch (action.type) {
    case ActionTypes.CHANGE_SEARCH_CONDITION: {
      return {
        ...state,
        [action.payload.key]: action.payload.value,
      };
    }
    case ActionTypes.CHANGE_ORIGINAL_RECEIPT_ID: {
      return {
        ...state,
        originalReceiptId: action.payload.value,
      };
    }
    case ActionTypes.CLEAR_ORIGINAL_RECEIPT_ID: {
      return {
        ...state,
        originalReceiptId: '',
      };
    }
    default: {
      return state;
    }
  }
};

export default conditions;
