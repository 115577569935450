import Ellipsis from './Ellipsis';
import React from 'react';
import Truncate from 'react-truncate';
import { ApprovalStep } from 'utilities/api/models/ApprovalStepsForReject';

interface Props {
  label: string,
  totalApprover: string | number,
  selectedStep: ApprovalStep
}

const Approvers = ({ label, totalApprover, selectedStep }: Props): JSX.Element => {
  return (
    <div style={ { position: 'relative' } }>
      <Truncate
        lines={ 2 }
        ellipsis={
          <Ellipsis
            ellipsis={ `他${totalApprover}` }
            style={ { color:'#47BBA6', cursor: 'pointer' } }
            selectedStep={ selectedStep }
          />
        }
      >
        {label}
      </Truncate>
    </div>
  );
};

export default Approvers;
