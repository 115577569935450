import SimpleModal from "components/SimpleModal";
import i18next from "i18n";
import PropTypes from "prop-types";
import React from "react";
import TransactionTable from "./TransactionTable";

const TransactionsModal = ({
  show,
  close,
  title,
  transactions,
  fetchTransactions,
  onPageChange,
  onSizePerPageList,
  currentPage,
  sizePerPage,
  openCostAllocationModal,
  costAllocationHovorRowIdx,
  onMouseEnterCostAlocationDiv,
  onMouseLeaveCostAlocationDiv,
}) => {
  const buttons = [
    {
      onClick: close,
      color: "default",
      content: i18next.t("commons.actions.close"),
    },
  ];

  return (
    <SimpleModal
      title={title}
      size="lg"
      show={show}
      close={close}
      buttons={buttons}
    >
      <TransactionTable
        transactions={transactions}
        fetchTransactions={fetchTransactions}
        onPageChange={onPageChange}
        onSizePerPageList={onSizePerPageList}
        currentPage={currentPage}
        sizePerPage={sizePerPage}
        columnSelectable={false}
        openCostAllocationModal={openCostAllocationModal}
        costAllocationHovorRowIdx={costAllocationHovorRowIdx}
        onMouseEnterCostAlocationDiv={onMouseEnterCostAlocationDiv}
        onMouseLeaveCostAlocationDiv={onMouseLeaveCostAlocationDiv}
      />
    </SimpleModal>
  );
};

TransactionsModal.defaultProps = {
  title: i18next.t("transactions.titles.list"),
  transactions: [],
  currentPage: 1,
  sizePerPage: 10,
};

TransactionsModal.propTypes = {
  show: PropTypes.bool.isRequired,
  close: PropTypes.func.isRequired,
  fetchTransactions: PropTypes.func.isRequired,
  onPageChange: PropTypes.func.isRequired,
  onSizePerPageList: PropTypes.func,
  transactions: PropTypes.array.isRequired,
  currentPage: PropTypes.number.isRequired,
  sizePerPage: PropTypes.number.isRequired,
  title: PropTypes.string,
  openCostAllocationModal: PropTypes.func,
  costAllocationHovorRowIdx: PropTypes.number,
  onMouseEnterCostAlocationDiv: PropTypes.func,
  onMouseLeaveCostAlocationDiv: PropTypes.func,
};

export default TransactionsModal;
