import React from "react";
import styled from "styled-components";
import colors from "values/colors";

interface Props {
  read: boolean;
}

const ReadMarkIconView = styled.span`
  color: ${colors.renewaled.main};
  font-size: 20px;
`;

/** 未読アイコン */
export const UnreadIcon: React.FC<Props> = ({ read }) => {
  return <ReadMarkIconView>{read ? "" : "●"}</ReadMarkIconView>;
};
