import SuggestField from "components/fields/SuggestField";
import isEmpty from "lodash/isEmpty";
import isNil from "lodash/isNil";
import PropTypes from "prop-types";
import React, { PureComponent } from "react";
import { from } from "rxjs";
import Api from "utilities/api";
import { fetchAsync } from "utilities/async";
import formatter from "utilities/formatter";

export default class DestinationField extends PureComponent {
  constructor(props) {
    super(props);

    this.state = { totalOptions: [] };

    this.getInputProps = this.getInputProps.bind(this);
  }

  componentDidMount() {
    this.fetchSubscription = from(this.initializeOptions()).subscribe(
      ({ data }) => {
        this.setState({ totalOptions: data });
      },
    );
  }

  componentWillUnmount() {
    if (this.fetchSubscription) {
      this.fetchSubscription.unsubscribe();
    }
  }

  initializeOptions() {
    return fetchAsync(Api.transits.recentShops, {
      userId: this.props.ownerId,
    }).then((response) => ({ data: response.shops }));
  }

  getOptionText(option) {
    return option.name;
  }

  renderOption(option) {
    return formatter.text(option.name);
  }

  getInputProps(props) {
    const colorClass =
      !isNil(this.props.text) && !isEmpty(this.props.text)
        ? "input-success"
        : "input-warning";

    return {
      ...props,
      className: `form-control validate[maxSize[255],required] ${colorClass} destination-input-field`,
    };
  }

  render() {
    return (
      <SuggestField
        async={false}
        text={this.props.text}
        initialOptions={this.state.totalOptions}
        getOptionText={this.getOptionText}
        getInputProps={this.getInputProps}
        renderOption={this.renderOption}
        onSelect={this.props.onSelect}
        onTextChange={this.props.onTextChange}
      />
    );
  }
}

DestinationField.propTypes = {
  ownerId: PropTypes.string,
  text: PropTypes.string,
  onTextChange: PropTypes.func,
  onSelect: PropTypes.func,
};
