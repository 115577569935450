import React, { FC } from 'react';
import styled from 'styled-components';
import uniqueId from 'lodash/uniqueId';
import { OverlayTrigger, Popover } from "react-bootstrap";

/**
 * チェックボックスに対して選択できる色の種類
 * @todo bootstrap で共通のタイプなので、どこかにまとめておくこと
 */
export type CheckBoxColor =
  'default' | 'primary' | 'accent' | 'success' | 'danger' | 'info' | 'disabled';

interface Props {
  className?: string;

  /** 入力のエレメントに対する ID */
  id?: string;

  /** ボックスがチェックされているか否か */
  checked: boolean;

  /** チェックされた際に発火するアクション */
  onChange: (checked: boolean) => void;

  /** チェックボックスを無効化するかどうか */
  disabled?: boolean;

  /** ラベルとして表示する内容 */
  label?: string;

  /**
   * チェックボックスとラベルの全体にかけるスタイル
   * @todo object型は使わないように
   */
  // eslint-disable-next-line @typescript-eslint/ban-types
  style?: object;

  /**
   * チェックボックスの className に追加される
   * @note 使われていなさそうであるので、不要かもしれない
   */
  type?: string;

  /** チェックボックスの色 */
  color?: CheckBoxColor;

  /**
   * ラベルの説明文がある場合, Popover に割り当てる ID
   */
  popoverId?: string;

  /** ラベルの説明文 */
  description?: string;
}

const DisabledLabel = styled.label`
  cursor: not-allowed;
  borderColor: #ccc;
  backgroundColor: transparent;
`;

/**
 * CheckboxのStatelessバージョン
 * 新規利用時は基本的にこちらを使う
 */
const SmartCheckBox: FC<Props> = ({
  className = '',
  id = uniqueId('checkbox-'),
  checked,
  onChange,
  disabled = false,
  label,
  style = {},
  type = 'squared',
  color = 'primary',
  popoverId = uniqueId('popover-'),
  description,
}) => (
  <div className={ className } style={ style }>
    <div className={ `checkbox checkbox-${color} ${type}` } style={ { display: 'inline-block' } }>
      <input type='checkbox' value='None'
        id={ id } checked={ checked }
        onChange={ (e): void => { onChange(e.target.checked); } }
        disabled={ disabled }
      />
      {
        disabled
          ? <label htmlFor={ id } />
          : <DisabledLabel  htmlFor={ id } />
      }
    </div>
    <span>
      {
        description
          ? (
            <span>
              { label }
              <OverlayTrigger
                trigger={ ['click', 'focus', 'hover'] }
                placement='top'
                overlay={ <Popover id={ popoverId }>{ description }</Popover> }>
                <i className='far fa-question-circle' style={ { color: '#AAA', marginLeft: '6px', cursor: 'pointer' } } />
              </OverlayTrigger>
            </span>
          ) : (<span>{ label }</span>)
      }
    </span>
  </div>
);

export default SmartCheckBox;
