import transactionTable from "applications/transactions/reducers/transactionTable";
import * as ActionTypes from "../actions/corporateReport";
import * as HistoryActionTypes from "../actions/reportHistories";

const initialState = {
  isEditing: false,
  report: {}, // ReportViewData
  user: null,
  request: {},
  transactions: {
    master: [],
    inEdit: [],
  },
  entryForms: [],
  histories: {
    reportHistories: [],
    transactionHistories: [],
    cache: {}, // transactionのsummaryをcacheする
    selectedTransactionId: null, // 編集履歴の一覧を参照中の経費のID
  },
  transactionForReference: null,
  suggestions: {
    categoryName: [],
    project: [],
    groupName: [],
  },
  modal: {
    transaction: null,
    isTransactionModalOpen: false,
  },
  requestEvents: [],
  remainingApprovals: [],
  approvalFlows: [],
  // フロー変更時に使用。// POST /api/v1/report_requests/reportsの戻り値
  // フロー付きで申請する際のモーダルがReduxを使っていないため、暫定的に使用
  reportsWithFlow: [],
  requestState: {
    isJobRunning: false,
    jobType: "",
  },
  reapply: {
    isReapplyModalOpen: false,
  },
  reject: {
    isRejectModalOpen: false,
    rejectReason: "",
  },
};

const corporateReport = (state = initialState, action) => {
  switch (action.type) {
    case ActionTypes.SET_REPORT: {
      return {
        ...state,
        report: action.data,
      };
    }
    case ActionTypes.SET_USER: {
      return {
        ...state,
        user: action.data,
      };
    }
    case ActionTypes.SET_REQUEST: {
      return {
        ...state,
        request: action.data,
      };
    }
    case ActionTypes.SET_REQUEST_EVENTS: {
      return {
        ...state,
        requestEvents: action.data,
      };
    }
    case ActionTypes.SET_REMAINING_APPROVALS: {
      return {
        ...state,
        remainingApprovals: action.data,
      };
    }
    case ActionTypes.SET_REPORTS_WITH_FLOW: {
      return {
        ...state,
        reportsWithFlow: action.data,
      };
    }
    case HistoryActionTypes.INIT_REPORT_HISTORIES: {
      const { reportHistories, transactionHistories } = action.payload;

      return {
        ...state,
        histories: {
          ...state.histories,
          reportHistories: [...(reportHistories || [])],
          transactionHistories: [...(transactionHistories || [])],
        },
      };
    }
    case HistoryActionTypes.WRITE_HISTORY_CACHE: {
      return {
        ...state,
        histories: {
          ...state.histories,
          cache: {
            ...state.histories.cache,
            [action.payload.id]: { ...action.payload },
          },
        },
      };
    }
    case HistoryActionTypes.SET_TRANSACTION_FOR_REFERENCE: {
      return {
        ...state,
        transactionForReference: action.payload,
      };
    }
    case HistoryActionTypes.TOGGLE_HISTORY_MODAL: {
      return {
        ...state,
        histories: {
          ...state.histories,
          selectedTransactionId: action.payload.show
            ? action.payload.transactionId
            : null,
        },
      };
    }
    case ActionTypes.OPEN_RE_APPLY_REPORT_MODAL: {
      return {
        ...state,
        reapply: {
          ...state.reject,
          isReapplyModalOpen: true,
        },
      };
    }
    case ActionTypes.CLOSE_RE_APPLY_REPORT_MODAL: {
      return {
        ...state,
        reapply: {
          ...state.reject,
          isReapplyModalOpen: false,
        },
      };
    }
    case ActionTypes.OPEN_REJECT_MODAL: {
      return {
        ...state,
        reject: {
          ...state.reject,
          isRejectModalOpen: true,
        },
      };
    }
    case ActionTypes.CLOSE_REJECT_MODAL: {
      return {
        ...state,
        reject: {
          ...state.reject,
          isRejectModalOpen: false,
        },
      };
    }
    case ActionTypes.INPUT_REJECT_REASON: {
      return {
        ...state,
        reject: {
          ...state.reject,
          rejectReason: action.value,
        },
      };
    }
    case ActionTypes.START_REPORT_REQUEST_JOB: {
      return {
        ...state,
        requestState: {
          ...state.requestState,
          jobType: action.jobType,
          isJobRunning: true,
        },
      };
    }
    case ActionTypes.CLOSE_REPORT_REQUEST_JOB: {
      return {
        ...state,
        requestState: {
          ...state.requestState,
          jobType: "",
          isJobRunning: false,
        },
      };
    }
    default:
      return transactionTable(state, action);
  }
};

export default corporateReport;
