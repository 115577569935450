import Api from 'utilities/api';
import React, {
  useCallback, useEffect, useMemo, useState,
} from 'react';
import {
  ReceiptFile,
  RotateEventHandler,
} from 'components/types/attachedFiles.types';
import { Subject, from } from 'rxjs';
import { switchMap, throttleTime } from 'rxjs/operators';

type DeleteCallback =  (file: ReceiptFile, error?: Error) => void;
type RotationCallback = (file: ReceiptFile, rotation: number) => void;

interface DeleteCallbacks {
  isConfirming: boolean;
  onDelete: (e: React.SyntheticEvent) => void;
  onConfirm: (e: React.SyntheticEvent) => void;
  onCancel: (e: React.SyntheticEvent) => void;
}

function deleteReceiptFile(receiptFile: ReceiptFile | null): Promise<ReceiptFile | null> {
  return new Promise((resolve, reject) => {
    if (!receiptFile || !('id' in receiptFile)) {
      resolve(receiptFile);
      return;
    }

    Api.s3Files.destroy({ id: receiptFile.id })
      .then(() => { resolve(receiptFile); })
      .catch((e) => { reject(e); });
  });
}

export function useDeleteCallback(receiptFile: ReceiptFile | null, onDelete: DeleteCallback | undefined): DeleteCallbacks {
  const [isConfirming, setIsConfirming] = useState<boolean>(false);

  const subject = useMemo(() => (new Subject<number>()), []);
  const fetch$ = useMemo(() => {
    return subject.pipe(
      throttleTime(300), // 連打対策
      switchMap(() => from(deleteReceiptFile(receiptFile))),
    );
  }, [subject, receiptFile]);

  useEffect(() => {
    const subscription = fetch$.subscribe(() => {
      if (receiptFile && onDelete) {
        onDelete(receiptFile);
      }
    }, (err) => {
      if (receiptFile && onDelete) {
        onDelete(receiptFile, err);
      }
    });

    return (): void => { subscription.unsubscribe(); };
  }, [fetch$, receiptFile, onDelete]);

  const onDeleteCallback = useCallback((e) => {
    if (!receiptFile) { return; }

    // 画像がアップロード済みの場合、確認する
    if ('id' in receiptFile) {
      setIsConfirming(true);
      return;
    }

    subject.next(e);
  }, [subject, receiptFile, setIsConfirming]);

  const onConfirmCallback = useCallback((e) => {
    if (receiptFile) {
      subject.next(e);
    }

    setIsConfirming(false);
  }, [subject, receiptFile, setIsConfirming]);

  const onCancelCallback = useCallback(() => {
    setIsConfirming(false);
  }, [setIsConfirming]);

  return {
    isConfirming,
    onDelete: onDeleteCallback,
    onConfirm: onConfirmCallback,
    onCancel: onCancelCallback,
  };
}

function updateRotation(receiptFile: ReceiptFile | null, rotation: number): Promise<number> {
  return new Promise((resolve) => {
    if (!receiptFile || !('id' in receiptFile)) {
      // ローカルのファイル回転
      resolve(rotation);
      return;
    }

    // 回転保存に失敗した時の影響は軽微と考えられるので、エラーは無視する
    Api.s3Files.update({ id: receiptFile.id, rotation })
      .then(() => resolve(rotation))
      .catch(() => resolve(rotation));
  });
}

export function useRotationCallback(receiptFile: ReceiptFile | null, onRotate?: RotationCallback): RotateEventHandler {
  const subject = useMemo(() => (new Subject<number>()), []);
  const fetch$ = useMemo(() => {
    return subject.pipe(
      throttleTime(300, void 0, { leading: false, trailing: true }),
      switchMap((rotation) => from(updateRotation(receiptFile, rotation))),
    );
  }, [subject, receiptFile]);

  useEffect(() => {
    const subscription = fetch$.subscribe((rotation) => {
      if (receiptFile && onRotate) {
        onRotate(receiptFile, rotation);
      }
    });

    return (): void => { subscription.unsubscribe(); };
  }, [fetch$, receiptFile, onRotate]);

  return useCallback((_e, rotation) => {
    if (receiptFile) {
      subject.next(rotation);
    }
  }, [subject, receiptFile]);
}
