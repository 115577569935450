import React from 'react';
import i18next from 'i18next';
import { OverlayTrigger, Popover } from 'react-bootstrap';

interface Props {
  /** ID */
  readonly id: string;
}

/**
 * 次回実行がない場合のメッセージコンポーネント
 */
export const NoNextExecution: React.FC<Props> = ({
  id,
}) => {
  return (
    <div style={ { whiteSpace: 'pre-wrap', wordWrap: 'break-word' } }>
      { i18next.t('accountingDataScheduledExports.tasks.table.noNextExecution') }
      <OverlayTrigger
        trigger={ ['hover', 'focus', 'click'] }
        placement='top'
        overlay={
          <Popover id={ id }>
            { i18next.t('accountingDataScheduledExports.tasks.table.noNextExecutionHelp1') }<br />
            { i18next.t('accountingDataScheduledExports.tasks.table.noNextExecutionHelp2') }
          </Popover>
        }>
        <i className='far fa-question-circle' style={ { color: '#AAA', marginLeft: '6px', cursor: 'pointer' } } />
      </OverlayTrigger>
    </div>
  );
};
