import React from 'react';
import colors from 'values/colors';
import styled from 'styled-components';

const PageView = styled.div`
  > .page-header {
    margin: 0;
    border: none;
    display: flex;
    align-items: center;
    padding: 9px 16px 0; // サイドバーの[新規]ボタンと高さ合わせる設定
    position: relative;
    color: ${colors.renewaled.textBlack};
    /* サイドバー:1000, モーダル:1050, ヘッダー:1025, テーブルヘッダー: 1, テーブルのオーバーレイ: 100。
    テーブルのオーバーレイよりも手前にあれば良いので500
    */
    z-index: 500;
    > .page-title {
      font-size: 16px;
      font-weight: 600;
      white-space: nowrap;
      margin-right: 64px;
      line-height: 28px;
    }
    .toolbar {
      width: 100%;
      display: flex;
      justify-content: space-between;
      align-items: center;
      .toolbar-right, .toolbar-left {
        display: flex;
        align-items: center;
        flex-direction: row;
      }
    }
  }
  > .page-body {
    padding: 0 16px;
  }
`;

/** 業務エリアに共通でpaddingが効いているので、暫定処置としてPageコンポーネント適用ページのみスタイルを書き換える
 *  共通のCSS: app/frontend/stylesheets/applications/applications.scss
 */
const mainContents = document.getElementsByClassName('main-contents') as HTMLCollectionOf<HTMLElement>;
if (mainContents[0]) {
  mainContents[0].style.padding = '0';
  mainContents[0].style.marginBottom = '0';
}

/** ページコンポーネント */
export const Page: React.FC = ({ children }) => {
  return (
    <PageView>
      {children}
    </PageView>
  );
};
