import ExportFormPollingModal, { Props }  from './ExportFormPollingModal';
import React, { FC } from 'react';

const ExportFormModal: FC<Props> = (props) => {
  return (
    props.show ? <ExportFormPollingModal { ...props } /> : null
  );
};

ExportFormModal.defaultProps = {
  exportJobs: [],
};

export default ExportFormModal;
