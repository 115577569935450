import { OperatorEntry } from "utilities/api_expenses/models/expenses/OperatorEntry";
import { AmountPerTaxCategory } from "./interface";

/**
 * 税区分別金額にオペレータ入力が適用されているかを返します。
 * @param operatorEntry オペレータ入力
 * @param amountPerTaxCategories 税区分別金額
 * @returns
 */
export const isAssignedOperatorEntry = (
  operatorEntry: OperatorEntry,
  amountPerTaxCategories: AmountPerTaxCategory[],
): boolean => {
  const isEqualTotal =
    operatorEntry.total ===
    amountPerTaxCategories.reduce((acc, v) => acc + Number(v.amount), 0);
  const isAssigned = operatorEntry.subtotals.every((subtotal) =>
    amountPerTaxCategories.some(
      (v) =>
        v.taxCategory.taxRate === subtotal.taxRate &&
        Number(v.amount) === subtotal.amount &&
        Number(v.taxAmount) === subtotal.taxAmount,
    ),
  );
  return isEqualTotal && isAssigned;
};
