import every from 'lodash/every';
import i18next from 'i18n';
import isNil from 'lodash/isNil';
import isNumber from 'lodash/isNumber';
import parseInt from 'lodash/parseInt';

const prefix = 'taxCategorySelections';

// 設定されている最大の並び順を返す
function maxSort(taxCategorySelections) {
  const selectionsWithSort = taxCategorySelections.filter((s) => { return s.selected; });
  const sorts = selectionsWithSort.map((s) => { return s.sort; });

  return sorts.length === 0 ? 0 : Math.max(...sorts);
}

function isValidTaxCategorySelection(taxCategorySelection) {
  const { selected, sort } = taxCategorySelection;

  if (!selected) {
    return true;
  }

  return isNumber(sort) && sort > 0;
}

function mapIsValidToTaxCategorySelections(taxCategorySelections) {
  return taxCategorySelections.map((o) => {
    return {
      ...o,
      isValid: isValidTaxCategorySelection(o),
    };
  });
}

export const SET_TAX_CATEGORY_SELECTIONS = `${prefix}/SET_TAX_CATEGORY_SELECTIONS`;
export function setTaxCategorySelections(taxCategorySelections, valid = true) {
  return {
    type: SET_TAX_CATEGORY_SELECTIONS,
    payload: {
      taxCategorySelections: valid ? mapIsValidToTaxCategorySelections(taxCategorySelections) : taxCategorySelections,
    },
  };
}

export function setTaxCategorySelected(taxCategoryId, selected) {
  return (dispatch, getState) => {
    const { taxCategorySelections } = getState();
    const newTaxCategorySelections = (taxCategorySelections.items || []).map((selection) => {
      if (selection.taxCategoryId === taxCategoryId) {
        return {
          ...selection,
          selected,
          sort: selected ? maxSort(taxCategorySelections.items) + 1 : null,
        };
      }
      return {
        ...selection,
      };
    });
    dispatch(setTaxCategorySelections(newTaxCategorySelections));
  };
}

export function setTaxCategorySort(taxCategoryId, sort) {
  return (dispatch, getState) => {
    const { taxCategorySelections } = getState();
    const newTaxCategorySelections = taxCategorySelections.items.map((selection) => {
      if (selection.taxCategoryId === taxCategoryId) {
        return {
          ...selection,
          sort: isNil(sort) ? null : parseInt(sort),
        };
      }
      return {
        ...selection,
      };
    });
    dispatch(setTaxCategorySelections(newTaxCategorySelections));
  };
}

export function validate(taxCategorySelections, callback) {
  const isAllValidSort = every(taxCategorySelections, (o) => { return o.isValid; });
  const errors = [];
  if (!isAllValidSort) {
    errors.push({ message: i18next.t('preferences.taxCategorySelections.errors.messages.invalidSort') });
  }
  const selections = taxCategorySelections.filter((o) => { return o.selected; });
  if (selections.length === 0) {
    errors.push({ message: i18next.t('preferences.taxCategorySelections.errors.messages.noSelection') });
  }
  callback(errors);
}

export const SET_TAX_CATEGORY_SELECTIONS_ERRORS = `${prefix}/SET_TAX_CATEGORY_SELECTIONS_ERRORS`;
export function setErrors(errors) {
  return {
    type: SET_TAX_CATEGORY_SELECTIONS_ERRORS,
    payload: {
      errors,
    },
  };
}

export function clearErrors() {
  return {
    type: SET_TAX_CATEGORY_SELECTIONS_ERRORS,
    payload: {
      errors: [],
    },
  };
}
