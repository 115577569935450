import * as actions from 'applications/approval_flows/actions/flowFormActions';

export const RESET_APPROVERS = actions.RESET_APPROVERS;
export const fetchApprovers = actions.fetchApprovers;

const prefix = 'report_requests';

/**
 * @see actions.resetApproversCheck
 */
export const RESET_APPROVERS_CHECK = actions.RESET_APPROVERS_CHECK;
export function resetApproversCheck(reportId, approvers) {
  return {
    ...actions.resetApproversCheck(
      approvers,
      // 申請提出時には承認者候補として承認部署と承認権限持ちユーザーしか選べないため
      // ぬるぽにならないようにダミーのObjectを渡す
      { isUpperLevelPostSelecting: false, relativeLevel: null, nearestPostsApprover: false },
    ),
    reportId,
  };
}

/**
 * @see actions.checkApprover
 */
export const SET_CHECKED_APPROVERS = actions.SET_CHECKED_APPROVERS;
export function checkApprover(reportId, approverType, approvers, approverId, checked) {
  return {
    ...actions.checkApprover(approverType, approvers, approverId, checked),
    reportId,
  };
}

/**
 * @see actions.setEditingApprovalIndex
 */
export const SET_EDITING_APPROVAL_INDEX = actions.SET_EDITING_APPROVAL_INDEX;
export function setEditingApprovalIndex(reportId, index) {
  return {
    ...actions.setEditingApprovalIndex(index),
    reportId,
  };
}

export const SET_APPROVALS = actions.SET_APPROVALS;
/**
 * @see actions.insertNewApproval
 */
export function insertNewApproval(reportId, index, approvals) {
  return {
    ...actions.insertNewApproval(index, approvals),
    reportId,
  };
}

/**
 * @see actions.removeApproval
 */
export function removeApproval(reportId, index, approvals) {
  return {
    ...actions.removeApproval(index, approvals),
    reportId,
  };
}

/**
 * @see actions.setApprovalProp
 */
export function setApprovalProp(prop, reportId, approvals, index, value) {
  return {
    ...actions.setApprovalProp(prop, approvals, index, value),
    reportId,
  };
}

export const setApprovalCondition = setApprovalProp.bind(null, 'condition');
export const setApprovalEditable = setApprovalProp.bind(null, 'editable');
export const setApprovers = setApprovalProp.bind(null, 'approvers');

/**
 * @see actions.toggleAddApproverModal
 */
export const TOGGLE_ADD_APPROVER_MODAL = actions.TOGGLE_ADD_APPROVER_MODAL;
export function toggleAddApproverModal(reportId, show) {
  return {
    ...actions.toggleAddApproverModal(show),
    reportId,
  };
}

export function openAddApproverModal(reportId) {
  return toggleAddApproverModal(reportId, true);
}

export function closeAddApproverModal(reportId) {
  return toggleAddApproverModal(reportId, false);
}

export const UPDATE_REQUEST_APPROVER = `${prefix}/UPDATE_REQUEST_APPROVER`;
export function updateRequestApprover(key, value) {
  return { type: UPDATE_REQUEST_APPROVER, key, value };
}

export const UPDATE_APPROVER_FOR_REPORT = `${prefix}/UPDATE_APPROVER_FOR_REPORT`;
export function updateApproverForReport(key, value, index) {
  return {
    type: UPDATE_APPROVER_FOR_REPORT, key, value, index,
  };
}

export function mergeSearchApprovers(index) {
  return (dispatch, getState) => {
    const { result } = getState().approversSearch;
    const { approvers } = getState().request.reports[index];
    const mergedSearchApprovers = {};

    Object.keys(approvers).forEach((type) => {
      const checkedApprovers = approvers[type].filter((v) => v.isChecked);
      const mergedApprovers = checkedApprovers.concat(result[type]);

      // IDをベースとして重複値を解消
      mergedSearchApprovers[type] = mergedApprovers.filter((approver, idx, self) => self.findIndex((v) => v.id === approver.id) === idx);

      // 選択状態を付与
      mergedSearchApprovers[type].map((approver) => Object.assign(approver, { isChecked: Boolean(approver.isChecked) }));
      dispatch(updateApproverForReport(type, mergedSearchApprovers[type], index));
    });
  };
}
