import RadioGroup from "components/fields/RadioGroup";
import LabeledComponent from "components/LabeledComponent";
import i18next from "i18n";
import PropTypes from "prop-types";
import React from "react";

const ToBePaidCondition = (props) => {
  return (
    <LabeledComponent
      label={i18next.t("transactions.index.searchbox.toBePaid.label")}
    >
      <RadioGroup
        isClearable={true}
        value={props.value}
        radios={[
          {
            value: true,
            label: i18next.t("transactions.index.searchbox.toBePaid.yes"),
          },
          {
            value: false,
            label: i18next.t("transactions.index.searchbox.toBePaid.no"),
          },
        ]}
        onChange={props.onChange}
      />
    </LabeledComponent>
  );
};

ToBePaidCondition.defaultProps = {
  value: null,
};

ToBePaidCondition.propTypes = {
  value: PropTypes.bool,
  onChange: PropTypes.func.isRequired,
};

export default ToBePaidCondition;
