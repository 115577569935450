import { ControlLabel } from "components/ControlLabel";
import SmartCheckBox, { CheckBoxColor } from "components/SmartCheckBox";
import i18next from "i18n";
import React, { FC } from "react";

export type { CheckBoxColor } from "components/SmartCheckBox";

interface CheckBoxProps {
  /** チェックボックスにつける色 */
  color: CheckBoxColor;

  /** チェックされているか否か */
  checked: boolean;

  /** チェックボックスの説明。説明が複雑でラベルに収まらない場合に使用 */
  description?: string;

  /** ラベル名 */
  label: string;

  /** チェックボタンが押下されたときの処理 */
  onChange: () => void;
}

interface Props {
  /** CheckBoxGroup に割り当てる className */
  className: string;

  /** 表示するチェックボックスのプロパティ */
  checkBoxesProps: Array<CheckBoxProps>;

  /** グルーピングしたチェックボックスにつける名前 */
  label: string;

  /** グルーピングしたチェックボックスの ControlLabel に渡すプロパティ */
  // @todo ControlLabel で型定義したものを参照するように変更すること
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  labelProps?: any;

  /** 要素全てをONとするような [全選択] ボタンを表示させるか否か */
  isAllSelectable: boolean;

  /**
   * 要素全てをONとするような [全選択] ボタンが押下された場合の処理
   * isAllSelectable が true の場合は必須
   */
  onChangeAllStatus?: () => void;
}

/**
 * ラベル付きのチェックボックスのグループを描画する
 * もしチェックボックス全てを ON にするような処理が必要な場合は isAllSelectable を有効にしてください
 */
const CheckBoxGroup: FC<Props> = ({
  checkBoxesProps,
  className,
  label,
  labelProps = {},
  isAllSelectable,
  onChangeAllStatus = (): void => void 0,
}) => {
  return (
    <div className={className}>
      <ControlLabel className="control-label" {...labelProps}>
        {label}
      </ControlLabel>

      <div className="checkbox-group">
        {checkBoxesProps.map((checkBox, index) => (
          <SmartCheckBox
            key={`checkbox-${index}`}
            color={checkBox.color || "accent"}
            checked={checkBox.checked || false}
            label={checkBox.label}
            description={checkBox.description}
            onChange={checkBox.onChange}
          />
        ))}
      </div>

      {isAllSelectable && (
        <div className="row">
          <button
            className="btn btn-accent col-sm-2"
            type="button"
            style={{
              position: "relative",
              top: "0px",
              marginLeft: "9px",
              marginTop: "5px",
            }}
            onClick={onChangeAllStatus}
          >
            {i18next.t("transactions.index.searchbox.selectAll")}
          </button>
        </div>
      )}
    </div>
  );
};

export default CheckBoxGroup;
