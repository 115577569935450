import FormItem from './FormItem';
import PropTypes from 'prop-types';
import React from 'react';
import formatter from 'utilities/formatter';

const Amount = (props) => {
  return (
    <FormItem label='amount'>
      { formatter.text(props.value, '-') }
    </FormItem>
  );
};

Amount.propTypes = {
  value: PropTypes.number,
};

export default React.memo(Amount);
