import React, { FC, useCallback, useState } from 'react';
import TaxCategorySelectionsForm from './TaxCategorySelectionsForm';
import every from 'lodash/every';
import i18next from 'i18n';
import isNil from 'lodash/isNil';
import isNumber from 'lodash/isNumber';
import parseInt from 'lodash/parseInt';
import { Modal } from 'react-bootstrap';
import { TaxCategorySelection } from 'utilities/api/models';

interface Props {
  readonly taxCategorySelections: TaxCategorySelection[];
  readonly show: boolean;
  readonly showSort: boolean;
  readonly onHideModal: () => void;
  readonly onChangeSelections: (selections: TaxCategorySelection[]) => void;
}

interface TaxCategorySelectionWithIsValid extends TaxCategorySelection {
  readonly isValid: boolean;
}

function isValidTaxCategorySelection(selection: TaxCategorySelection): boolean {
  const { selected, sort } = selection;

  if (!selected) return true;

  return isNumber(sort) && sort > 0;
}

function validate(
  taxCategorySelections: TaxCategorySelectionWithIsValid[],
  callback: (errors: string[]) => void,
): void {
  const errors: string[] = [];

  const isAllValidSort = every(taxCategorySelections, (o) => { return o.isValid; });
  if (!isAllValidSort) {
    errors.push(i18next.t('preferences.taxCategorySelections.errors.messages.invalidSort'));
  }

  const selections = taxCategorySelections.filter((o) => { return o.selected; });
  if (selections.length === 0) {
    errors.push(i18next.t('preferences.taxCategorySelections.errors.messages.noSelection'));
  }

  callback(errors);
}

// 設定されている最大の並び順を返す
function maxSort(taxCategorySelections): number {
  const selectionsWithSort = taxCategorySelections.filter((s) => { return s.selected; });
  const sorts = selectionsWithSort.map((s) => { return s.sort; });

  return sorts.length === 0 ? 0 : Math.max(...sorts);
}

/*
 * TaxCategorySelectionsModal はストアを利用しています
 * 本コンポーネントはストアを利用していないリファクタリングverです
 */
const TaxCategorySelectionsTSModal: FC<Props> = (props) => {
  const [errors, setErrors] = useState<string[]>([]);
  const {
    taxCategorySelections, showSort, onChangeSelections, onHideModal,
  } = props;
  const taxCategorySelectionsWithIsValid = taxCategorySelections.map((o) => { return { ...o, isValid: isValidTaxCategorySelection(o) }; });

  const onChangeSelection = useCallback((taxCategoryId: string, selected: boolean): void => {
    const newTaxCategorySelections = taxCategorySelections.map((selection) => {
      if (selection.taxCategoryId === taxCategoryId) {
        return {
          ...selection,
          selected,
          sort: selected ? maxSort(taxCategorySelections) + 1 : null,
        };
      }
      return {
        ...selection,
      };
    });

    onChangeSelections(newTaxCategorySelections);
  }, [onChangeSelections, taxCategorySelections]);

  const onChangeSort = useCallback((taxCategoryId: string, sort: string): void => {
    const newTaxCategorySelections = taxCategorySelections.map((selection) => {
      if (selection.taxCategoryId === taxCategoryId) {
        return {
          ...selection,
          sort: isNil(sort) ? null : parseInt(sort),
        };
      }
      return {
        ...selection,
      };
    });

    onChangeSelections(newTaxCategorySelections);
  }, [onChangeSelections, taxCategorySelections]);

  const onUpdate = useCallback((): void => {
    validate(taxCategorySelectionsWithIsValid, (newErrors: string[]) => {
      if (newErrors.length > 0 && showSort) {
        setErrors(newErrors);
      } else {
        setErrors([]);
        onHideModal();
      }
    });
  }, [onHideModal, showSort, taxCategorySelectionsWithIsValid]);

  const onHide = useCallback((): void => {
    setErrors([]);
    onHideModal();
  }, [onHideModal]);

  return (
    <Modal show={ props.show } onHide={ onHide }>
      <Modal.Header closeButton>
        <Modal.Title>{ i18next.t('preferences.taxCategorySelections.title') }</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {
          errors.length > 0 ? (
            <div className="alert alert-danger" role="alert">
              { errors.map((e) => e) }
            </div>) : null
        }
        <TaxCategorySelectionsForm
          showSort={ props.showSort }
          taxCategorySelections={ taxCategorySelectionsWithIsValid }
          onChangeSelection={ onChangeSelection }
          onChangeSort={ onChangeSort }
        />
      </Modal.Body>
      <Modal.Footer>
        <div className="text-center">
          <input
            type="button"
            onClick={ onUpdate }
            className="btn btn-success"
            value={ i18next.t<string>('preferences.taxCategorySelections.setting') }
            style={ { width: '120px' } }
          />
        </div>
      </Modal.Footer>
    </Modal>
  );
};

export default TaxCategorySelectionsTSModal;
