import * as actions from '../actions';
import * as analysisDetailActions from '../../analysis_details/actions/index';
import * as analysisDetailFetchActions from '../../analysis_details/actions/fetch';
import ExpenseAccountSelector from '../components/ExpenseAccountSelector';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import i18next from 'i18n';
import moment from 'moment';
import { connect } from 'react-redux';
import { getDatePickerDayConfig } from 'utilities/Utils';

export class ZenginExportForm extends Component {
  constructor(props) {
    super(props);
    this.datePickerConfig = {
      language: userPreferences.locale,
      format: 'yyyy/m/d',
      todayBtn: 'linked',
      autoclose: true,
      beforeShowDay: getDatePickerDayConfig,
    };
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleDateChange = this.handleDateChange.bind(this);
    this.state = { isDownloading: false };
  }

  componentDidMount() {
    const { payerAccounts, selectAccount } = this.props;

    $(this.refs.transactionDate)
      .datepicker(this.datePickerConfig)
      .on('changeDate', (e) => {
        this.handleDateChange(e);
      });

    if (payerAccounts) {
      selectAccount(payerAccounts[0]);
    }

    this.setState({ isDownloading: false });
  }

  handleDateChange(e) {
    this.props.setZenginTransactionDate(moment(e.date).format('YYYY/MM/DD'));
  }

  handleSubmit() {
    const {
      targetId, selectedPayerAccount, payeeAccounts, zenginTransactionDate, analysisId,
    } = this.props;

    this.setState({ isDownloading: true });
    this.props.downloadZenginFile(
      targetId,
      selectedPayerAccount,
      payeeAccounts,
      zenginTransactionDate,
      analysisId,
      () => { this.setState({ isDownloading: false }); },
    );
  }

  render() {
    return (
      <div className='row'>
        { this.renderContent() }
      </div>
    );
  }

  renderContent() {
    const buttonClassName = 'btn btn-outline btn-success';

    return (
      <div className='form-horizontal'>
        { this.renderTransactedAt() }
        <div className='form-group'>
          <label className='col-sm-3 control-label'>
            { i18next.t('paymentRequests.exports.zengin.paymentAccount') }
          </label>
          <div className='col-sm-6'>
            <div className='list-selector form-control'>
              <ExpenseAccountSelector
                expenseAccounts={ this.props.payerAccounts }
                selectedAccount={ this.props.selectedPayerAccount }
                selectAccount={ this.props.selectAccount }
              />
            </div>
          </div>
        </div>
        <div className='form-group'>
          <div className='col-sm-6 col-sm-offset-3'>
            <button
              className={ buttonClassName }
              disabled={ this.state.isDownloading }
              onClick={ this.handleSubmit }>
              { i18next.t('exports.inputs.download') }
            </button>
          </div>
        </div>
      </div>
    );
  }

  renderTransactedAt() {
    return (
      <div className='form-group'>
        <label className='col-sm-3 control-label'>
          { i18next.t('paymentRequests.exports.zengin.paymentDate') }
        </label>
        <div className='col-sm-6'>
          <input
            ref='transactionDate'
            type='text'
            className='form-control transaction-date'
            value={ this.props.zenginTransactionDate }
            onChange={ this.handleDateChange }
            placeholder={ i18next.t('paymentRequests.exports.zengin.placeHolder') } />
        </div>
      </div>
    );
  }
}

function mapStateToProps(state, ownProps) {
  // payerAccountなどは、propから直接受け取ったほうが、storeの構造に依存しないため、カスタマイズしやすいが、
  // 現状は集計画面でしか使用されないため、mapStateToPropsで対応する
  const { analysis, exportData } = state;
  const payeeAccounts = analysis.summariesByUser.map((x) => x.account);

  return {
    analysisId: analysis.id,
    selectedPayerAccount: exportData.selectedPayerAccount,
    zenginTransactionDate: exportData.zenginTransactionDate,
    payerAccounts: analysis.accounts,
    payeeAccounts,
  };
}

function mapDispatchToProps(dispatch, ownProps) {
  return {
    downloadZenginFile(id, payerAccount, payeeAccounts, date, analysisId, onFinish) {
      if (ownProps.type === 'analysis') {
        dispatch(actions.downloadZenginForReport(id, payerAccount, payeeAccounts, date, onFinish));
      } else if (ownProps.type === 'preAnalysis') {
        dispatch(actions.downloadZenginForPreReportUsingTempPayment(id, payerAccount, payeeAccounts, date, onFinish));
      }
      if (userPreferences.preference.paidAfterExportZengin) {
        if (ownProps.type === 'preAnalysis') {
          dispatch(analysisDetailFetchActions.changePreReportStatusesToTemporaryPaid(analysisId));
        } else {
          dispatch(analysisDetailFetchActions.changeReportStatusesToPaid(analysisId));
          dispatch(analysisDetailActions.requestPaidBatch());
        }
      }
    },
    selectAccount(account) {
      dispatch(actions.setPayerAccount(account));
    },
    setZenginTransactionDate(date) {
      dispatch(actions.setZenginTransactionDate(date));
    },
  };
}

ZenginExportForm.defaultProps = {
  type: 'analysis',
};

ZenginExportForm.propTypes = {
  downloadZenginFile: PropTypes.func.isRequired,
  payeeAccounts: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.string.isRequired,
  })), // 振込先の口座情報
  payerAccounts: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.string.isRequired,
  })), // 振込元の口座情報
  selectAccount: PropTypes.func.isRequired,
  selectedPayerAccount: PropTypes.shape({
    id: PropTypes.string, // string | null
  }),
  setZenginTransactionDate: PropTypes.func.isRequired,
  targetId: PropTypes.string.isRequired, // 経費集計画面 -> AnalysisのID
  type: PropTypes.oneOf(['analysis', 'preAnalysis']).isRequired, // 経費申請/事前申請（仮払）を指定
  zenginTransactionDate: PropTypes.string.isRequired,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(ZenginExportForm);
