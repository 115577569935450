import Formatter from 'utilities/formatter';
import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';
import i18next from 'i18n';
import { BootstrapTable, TableHeaderColumn } from 'react-bootstrap-table';

/**
 * 契約ページ: 月ごとの自動入力件数テーブル
 */
export default class MonthlyUsageTable extends PureComponent {
  constructor(props) {
    super(props);

    this.formatCount = this.formatCount.bind(this);
    this.formatYearMonth = this.formatYearMonth.bind(this);
  }

  /**
   * 年/月の欄
   */
  formatYearMonth(row, cell) {
    return (
      <div>
        { Formatter.datetimeWithFormat(cell.yearMonth, { emptyText: '-', format: 'YYYY/MM' }) }
      </div>
    );
  }

  /**
   * 自動入力件数欄
   */
  formatCount(row, cell) {
    return (<div>{ Formatter.number(cell.count, '-') }</div>);
  }

  render() {
    const options = {
      noDataText: i18next.t('commons.messages.noDataFound'),
    };

    return (
      <BootstrapTable
        className='monthly-usage-table'
        striped={ true }
        data={ this.props.dataSource }
        options={ options }
      >
        <TableHeaderColumn
          dataField="yearMonth"
          key="yearMonth"
          isKey={ true }
          dataFormat={ this.formatYearMonth }
          dataAlign="center"
          width="125"
        >
          { i18next.t('payments.tables.yearAndMonth') }
        </TableHeaderColumn>
        <TableHeaderColumn
          dataField="count"
          key="count"
          dataFormat={ this.formatCount }
        >
          { i18next.t('payments.tables.numberOfUsed') }
        </TableHeaderColumn>
      </BootstrapTable>
    );
  }
}

MonthlyUsageTable.defaultProps = {
  dataSource: [],
};

MonthlyUsageTable.propTypes = {
  dataSource: PropTypes.arrayOf(PropTypes.shape({
    yearMonth: PropTypes.string.isRequired,
    count: PropTypes.number,
  })).isRequired,
};
