import { DeleteEventHandler } from "components/types/attachedFiles.types";
import React, { useRef, useState } from "react";

/** 回転角度の間隔 */
const ROTATE_STEP = 90;

interface UseReturn {
  readonly currentPage: number | "";
  readonly rotate: number;
  readonly isHand: boolean;
  readonly pageNum: number;
  readonly fileName: string;
  readonly canvasAreaRef: React.RefObject<HTMLDivElement>;
  readonly wrapperRef: React.RefObject<HTMLDivElement>;
  readonly scrollAreaRef: React.RefObject<HTMLDivElement>;
  readonly onClickRotateTool: () => void;
  readonly onClickDeleteTool: (
    e: React.MouseEvent<HTMLElement, MouseEvent>,
  ) => void;
  readonly onClickHandTool: (
    e: React.MouseEvent<HTMLElement, MouseEvent>,
  ) => void;
  readonly changePageNum: (v: number) => void;
  readonly changeFileName: (v: string) => void;
  readonly changeCurrentPage: (v: number | "") => void;
}

/**
 * PDFビューアーのツールバー: ロジック
 */
export const useHooksToolBar = (onDelete?: DeleteEventHandler): UseReturn => {
  const [isHand, setIsHand] = useState<boolean>(false);
  const [currentPage, setCurrentPage] = useState<number | "">(1);
  const [rotate, setRotate] = useState<number>(0);
  const [pageNum, setPageNum] = useState<number>(1);
  const [fileName, setFileName] = useState<string>("");
  const canvasAreaRef = useRef<HTMLDivElement>(null);
  const wrapperRef = useRef<HTMLDivElement>(null);
  const scrollAreaRef = useRef<HTMLDivElement>(null);

  /**
   * ツールバー: 回転を押した
   */
  const onClickRotateTool = (): void => {
    const addRotate = rotate >= 270 ? 0 : rotate + ROTATE_STEP;
    setRotate(addRotate);
  };

  /**
   * ツールバー: 削除を押した
   */
  const onClickDeleteTool = (
    e: React.MouseEvent<HTMLElement, MouseEvent>,
  ): void => {
    e.stopPropagation();
    if (!onDelete) return;
    onDelete(e);
  };

  /**
   * ツールバー: 手のひらツール/矢印を押した
   */
  const onClickHandTool = (
    e: React.MouseEvent<HTMLElement, MouseEvent>,
  ): void => {
    e.stopPropagation();
    setIsHand(!isHand);
  };

  /**
   * ページ数を変更する
   */
  const changePageNum = (v: number): void => {
    setPageNum(v);
  };

  /**
   * ファイル名を変更する
   */
  const changeFileName = (v: string): void => {
    setFileName(v);
  };

  /**
   * 現在のページ番号を変更する
   */
  const changeCurrentPage = (v: number | ""): void => {
    setCurrentPage(v);
  };

  return {
    currentPage,
    rotate,
    isHand,
    pageNum,
    fileName,
    canvasAreaRef,
    wrapperRef,
    scrollAreaRef,
    onClickRotateTool,
    onClickDeleteTool,
    onClickHandTool,
    changePageNum,
    changeFileName,
    changeCurrentPage,
  };
};
