import Clearable from 'components/fields/Clearable';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import RemoteSearchBox from 'components/RemoteSearchBox';
import i18next from 'i18n';
import { Button } from 'components/renewaled_ui/buttons';
import {
  DropdownButton,
  ListGroup,
  ListGroupItem,
  MenuItem,
} from 'react-bootstrap';
import {
  Modal, ModalBody, ModalFooter, ModalHeader,
} from 'components/renewaled_ui/single_views';

export default class ListSelector extends Component {
  constructor(props) {
    super(props);

    this.state = {
      showModal: false,
      filter: (x) => x,
    };

    this.open = this.open.bind(this);
    this.close = this.close.bind(this);
    this.renderField = this.renderField.bind(this);
  }

  getSelected() {
    const empty = this.isMultiple() ? [] : null;
    const defaultValue = this.props.static
      ? this.props.items : empty;
    return this.props.value || defaultValue;
  }

  isMultiple() {
    return this.props.type === 'checkbox';
  }

  onItemSelect(item, prevSelected, e) {
    e.stopPropagation();
    e.preventDefault();

    const whenMultiple = () => {
      // 選択されたオブジェクトのリストは、更新時にpropsとして設定しなおされるので
      // オブジェクト自体の一致ではなく、idで一致判定する
      const clean = this.getSelected().filter((s) => s.id !== item.id);
      if (prevSelected) {
        return clean;
      }
      return clean.concat([item]);
    };
    const whenSingle = () => {
      this.close();
      return prevSelected ? null : item;
    };

    const nextSelected = this.isMultiple() ? whenMultiple() : whenSingle();
    if (this.props.onSelect) {
      if (!this.props.allowEmpty && ((this.isMultiple() && nextSelected.length === 0) || (!this.isMultiple() && !nextSelected))) {
        this.props.onSelect(this.getSelected());
      } else {
        // 親のonSelect関数で、props.valueをnextSelectedに変更する.
        this.props.onSelect(nextSelected);
      }
    }
  }

  open(e) {
    if (!this.props.static) {
      this.setState({
        showModal: true,
        filter: (x) => x,
      });
      this.props.onShowModal();
    }
  }

  close(e) {
    if (e && e.stopPropagation) {
      e.stopPropagation();
    }

    this.setState({
      showModal: false,
    });
  }

  onChangeSizePerPage(sizePerPage, e) {
    this.props.onChangeSizePerPage(sizePerPage);
  }

  renderField(selected) {
    const { isClearable } = this.props;
    const format = this.props.buttonFormat(selected, null, null, ['displayId', 'name']);
    return isClearable ? (
      <Clearable onClick={ () => {} }
        onClear={ this.props.onClear }
      >
        { format }
        <i className='caret' />
      </Clearable>
    ) : (
      <div>
        { format }&nbsp;<span className='caret'></span>
      </div>
    );
  }

  render() {
    const filtered = this.props.static ? this.getSelected() : this.props.items;
    const actionbox = (isActive, item) => {
      let icon;
      if (isActive) {
        icon = this.isMultiple() ? 'fa-check-square' : 'fa-dot-circle';
      } else {
        icon = this.isMultiple() ? 'fa-square' : 'fa-circle';
      }
      if (this.props.isDeletable && !this.props.staticItem(item)) {
        return (
          <div style={ { display: 'flex', alignItems: 'center' } }>
            <i className='far fa-trash-alt'
              style={ {
                marginRight: '16px',
              } }
              onClick={ (e) => { e.stopPropagation(); this.props.onDelete(item); } }
            />
            <i className={ `list-selector-checkbox far fa-fw fa-lg ${icon}` }></i>
          </div>
        );
      }
      return (<i className={ `list-selector-checkbox far fa-fw fa-lg ${icon}` }></i>);
    };
    const onClickHandler = (!this.props.static || filtered.length) && !this.props.disabled ? this.open : null;
    const selected = this.getSelected();
    const sizePerPageMenu = this.props.showSizePerPage
      ? this.props.sizePerPageList.map((size) => <MenuItem key={ size } eventKey={ size }>{ size }</MenuItem>) : null;
    return (
      <div className={ `list-selector-button ${this.props.disabled ? 'disabled' : ''}` }>
        <div onClick={ onClickHandler }>
          { this.renderField(selected) }
        </div>
        <Modal
          show={ this.state.showModal }
          onClose={ this.close }
          backdrop={ true }
          width='sm'
        >
          <ModalHeader title={ this.props.titleFormat(selected) } onClose={ this.close } />
          <ModalBody>
            { this.props.children }
            {
              this.props.showSearch && (
                <RemoteSearchBox
                  searchPlaceholder = { this.props.search.searchPlaceholder }
                  inputText         = { this.props.search.inputText }
                  onTextChange      = { this.props.search.onTextChange }
                  onClickSubmit     = { this.props.search.onClickSubmit }
                  onClickResetSearchConditionButton =
                    { this.props.search.onClickResetSearchConditionButton }
                />
              )
            }
            <ListGroup componentClass='ul'>
              {
                filtered.map((item) => {
                  const isSelected = this.isMultiple()
                    ? selected.filter((o) => o.id === item.id).length > 0
                    : selected && selected.id === item.id;
                  return (
                    <ListGroupItem
                      className = { this.props.static ? null : 'list-selector-item' }
                      href      = { this.props.static ? undefined : '#' }
                      key       = { item.id }
                      onClick   = { this.props.static ? null : this.onItemSelect.bind(this, item, isSelected) } >
                      <div style={ { display: 'flex', justifyContent: 'space-between', alignItems: 'center' } }>
                        <div>
                          { this.props.itemFormat(item) }
                        </div>
                        { this.props.static ? null : actionbox(isSelected, item) }
                      </div>
                    </ListGroupItem>
                  );
                })
              }
            </ListGroup>
          </ModalBody>
          <ModalFooter>
            <div style={ {
              width: '100%', display: 'flex', justifyContent: 'space-between', alignItems: 'center',
            } }>
              { this.props.showSizePerPage ? (
                <div className='pull-left'>
                  <div>{ i18next.t('commons.words.sizePerPage') }</div>
                  <div>
                    <DropdownButton
                      id='list-size-per-page'
                      dropup
                      title={ this.props.sizePerPage }
                      onSelect={ this.onChangeSizePerPage.bind(this) }>
                      { sizePerPageMenu }
                    </DropdownButton>
                  </div>
                </div>
              ) : null }
              {
                this.props.showSaveButton && (
                  <Button onClick={ this.close } styleType='primary'>{ i18next.t('commons.actions.save') }</Button>
                )
              }
            </div>
          </ModalFooter>
        </Modal>
      </div>
    );
  }
}

ListSelector.defaultProps = {
  isClearable:  false,
  isDeletable:  false,
  staticItem: () => false,
  onShowModal: () => null,
  static:       false,
  buttonFormat: () => '選択',
  titleFormat:  () => '選択',
  search: {
    searchPlaceholder: 'フィルタ',
    inputText: '',
    onTextChange: (e) => null,
    onClickSubmit: null,
    onClickResetSearchConditionButton: null,
  },
  disabled: false,
  showSizePerPage: false,
  onClear: () => null,
  allowEmpty:    true,
  showSearch: true,
  showSaveButton: true,
};

const withIdType = PropTypes.shape({
  id: PropTypes.string, // string | undefined
});

ListSelector.propTypes = {
  isClearable:  PropTypes.bool,
  isDeletable:  PropTypes.bool,
  staticItem:   PropTypes.func,
  onShowModal:  PropTypes.func,
  onDelete:     PropTypes.func,
  type:         PropTypes.oneOf(['radio', 'checkbox']).isRequired,
  static:       PropTypes.bool,
  children:     PropTypes.array,
  buttonFormat: PropTypes.func,
  titleFormat:  PropTypes.func,
  itemFormat:   PropTypes.func.isRequired,
  items:        PropTypes.arrayOf(withIdType).isRequired,
  value:        PropTypes.oneOfType([
    PropTypes.arrayOf(withIdType),
    withIdType,
  ]),
  onSelect:     PropTypes.func,
  search:       PropTypes.shape({
    searchPlaceholder: PropTypes.string,
    inputText:         PropTypes.string,
    onTextChange:      PropTypes.func.isRequired,
    onClickSubmit:     PropTypes.func,
    onClickResetSearchConditionButton: PropTypes.func,
  }),
  showSizePerPage:     PropTypes.bool,
  onChangeSizePerPage: PropTypes.func,
  sizePerPage:         PropTypes.number,
  sizePerPageList:     PropTypes.arrayOf(PropTypes.number),
  onClear:             PropTypes.func,
  allowEmpty:   PropTypes.bool,
  showSearch: PropTypes.bool,
  showSaveButton: PropTypes.bool,
};
