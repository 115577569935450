import fareFormatter from "applications/fare_transactions/fare_formatter";
import { hasFailedReceiptImage } from "applications/transactions/utilities/transactionFormPolicy";
import EditableInput from "components/EditableInput";
import S3Image from "components/S3Image";
import i18next from "i18n";
import _ from "lodash";
import React from "react";
import { OverlayTrigger, Popover } from "react-bootstrap";
import Formatter from "utilities/formatter";

/**
 * カラムの選択が出来るかどうか
 */
const isColumnSelectable = (column, props) => {
  if (column === "project" && !userPreferences.preference.inputProject) {
    return false;
  }
  if (column === "costAllocations" && !userPreferences.preference.inputGroup) {
    return false;
  }
  if (column === "ownerName" && !props.isSearchScopeAll) {
    return false;
  }
  if (column === "read" && !(props.authority === "approver")) {
    return false;
  }
  if (column === "preReportSequenceNum" && !props.hasPreReport) {
    return false;
  }
  if (
    column === "withholding" &&
    !(
      userPreferences.preference.rootGroupRequiresWithholding &&
      props.reportRequiresWithholding
    )
  ) {
    return false;
  }
  if (
    column === "hasValidatedReceiptMatching" &&
    !userPreferences.isPaperlessPlan
  ) {
    return false;
  }
  if (props.only) {
    return _.includes(props.only, column);
  }
  if (props.except) {
    return !_.includes(props.except, column);
  }
  return true;
};

/**
 * カラムを表示するかどうか
 */
const isColumnVisible = (dataField, props) => {
  const { columnVisibilities } = props;
  const selectable = isColumnSelectable(dataField, props);
  if (columnVisibilities && _.isBoolean(columnVisibilities[dataField])) {
    return selectable && columnVisibilities[dataField];
  }
  return selectable;
};

/**
 * Popover汎用フォーマット
 */
const detailsPopover = (prefix, title, value, id, formatter = (v) => v) => {
  return (
    <Popover id={`${prefix}-${id}`} placement="right" title={title}>
      <div style={{ wordWrap: "break-word" }}>{formatter(value)}</div>
    </Popover>
  );
};

/**
 * 画像の Popover 用フォーマット
 */
const imagePopover = (
  imageS3Url,
  imageId,
  setS3ImageUrl,
  expenseId,
  className = "",
) => {
  return (
    <Popover
      id={`image-popup-${expenseId}`}
      placement="right"
      className="popup-image"
    >
      <S3Image
        className={className}
        src={imageS3Url}
        imageId={imageId}
        onFetchPresignedUrl={(url) => {
          setS3ImageUrl(expenseId, url);
        }}
      />
    </Popover>
  );
};

/**
 * オーバーレイ付きのアイコン表示用フォーマット
 */
const formatIconWithOverlay = (overlay, icon, id, props = {}) => {
  const iProps = { className: `fa ${icon}`, ...props };
  return (
    <OverlayTrigger rootClose placement="right" overlay={overlay}>
      <i {...iProps}></i>
    </OverlayTrigger>
  );
};

/**
 * 画像用フォーマット
 *
 * @params {string} imageS3Url - S3 の URL
 * @params {object} receiptImages - DK に保存されている Receipt のサマリー
 * @params {UUID} expenseId - DK に保存されている経費の ID
 * @params {function} fetchS3Url  - imageS3Url が分からない場合に, 画像 ID から取得した imageS3Url を格納するための関数
 *   画像 ID から imageS3Url を取るAPIは内部で発行される
 */
const formatImage = (
  imageS3Url,
  receiptImages,
  expenseId,
  setS3ImageUrl,
  className = "",
) => {
  const foreside = _.get(receiptImages, "foreside[0]", null);
  if (!foreside) {
    return null;
  }

  if (hasFailedReceiptImage(receiptImages)) {
    // 失敗している画像がある場合
    return formatIconWithOverlay(
      detailsPopover(
        "failedUpload",
        i18next.t("transactions.properties.image"),
        i18next.t("transactions.errors.failedToUploadImage"),
        expenseId,
        (c) =>
          c
            .split(/\r?\n|\\n/g)
            .map((string, index) => <div key={index}>{string}</div>),
      ),
      "fa-question",
      expenseId,
    );
  }

  const imageId = foreside.id;
  return formatIconWithOverlay(
    imagePopover(imageS3Url, imageId, setS3ImageUrl, expenseId, className),
    "fa-paperclip",
    expenseId,
  );
};

/**
 * アイコンリスト表示用フォーマット
 * @returns {JSX.Element}
 */
const formatInfoIcons = (_cell, expense, setS3ImageUrl, className = "") => {
  return (
    <div className="icons">
      {formatImage(
        expense.imageS3Url,
        expense.receiptImages,
        expense.id,
        setS3ImageUrl,
        className,
      )}
    </div>
  );
};

/**
 * 金額用フォーマット
 */
const formatStaticAmount = (amount, row) => {
  if (row.route) {
    return fareFormatter.renderFare(amount, row.route.withTeiki, "left");
  }
  return Formatter.amount(amount);
};

/**
 * 経路用フォーマット
 */
const formatRoute = (route) => {
  return (
    <div>
      {fareFormatter.renderLabels(route.labels)}
      {fareFormatter.renderViaList(route.via || route.lines, "down")}
    </div>
  );
};

/**
 * 店名, 経路用フォーマット
 */
const formatShopOrRoute = (editmode, row) => {
  if (!editmode || _.isNil(row.route)) {
    return <span>{row.shopName}</span>;
  }

  const { route, shopName, id } = row;
  if (_.isNil(route.origin) || _.isNil(route.destination)) {
    return null;
  }

  const popover = detailsPopover(
    "route",
    void 0,
    route,
    `route-${id}`,
    formatRoute,
  );
  return (
    <OverlayTrigger rootClose placement="left" overlay={popover}>
      <span>
        {`${shopName || ""} (${route.origin.name} ${
          route.isRoundTrip ? "⇄" : "→"
        } ${route.destination.name})`}
      </span>
    </OverlayTrigger>
  );
};

/**
 * 入力欄のフォーマッターをビルドする
 *
 * @param type      表示するタイプ
 * @param cell      表示するセルの情報
 * @param row       表示されるセルを持つ行情報
 * @param editmode  編集モード中かどうか
 * @param editable  編集できるかどうか
 * @param sync      TODO: 要確認
 * @param onChange  値の変更時に実行されるコールバック
 * @param onUpdate  値の更新時に実行されるコールバック
 * @return {string|JSX.Element}
 */
const buildInputFormatter = (
  type,
  cell,
  row,
  editmode,
  editable,
  sync,
  onChange,
  onUpdate,
) => {
  if (!editmode || !editable) {
    switch (type) {
      case "date":
        return Formatter.date(cell, { withDayOfTheWeek: true });
      case "amount":
        return formatStaticAmount(cell, row);
      case "shopName":
        // 店名には経路情報が入ることがある
        return formatShopOrRoute(editmode, row);
      case "icons":
        return formatInfoIcons(cell, row);
      default:
    }
  }

  return (
    <EditableInput
      inputType={type}
      isEditing={true}
      sync={sync}
      formatter={(v) => Formatter.date(v, { withDayOfTheWeek: true })}
      text={cell}
      editingText={cell}
      onChange={onChange}
      onUpdate={onUpdate}
    />
  );
};

const buildTableHeaderColumnOption = (props) => {
  return {
    dataAlign: props.dataAlign,
    hidden: props.hidden,
    dataSort: props.dataSort,
    width: props.width,
  };
};

export {
  isColumnSelectable,
  isColumnVisible,
  buildInputFormatter,
  formatInfoIcons,
  buildTableHeaderColumnOption,
};
