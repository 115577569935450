import i18next from "i18n";
import React, { FC } from "react";
import styled from "styled-components";
import { InvoicingOrganization } from "utilities/api_managements/models/InvoicingOrganization";
import Formatter from "utilities/formatter";

const RegistratedNumberArea = styled.ul`
  padding-left: 0px;
`;

const RegistratedNumberTitleArea = styled.div<{ readOnly: boolean }>`
  margin-bottom: 0px;
  display: flex;
  background: ${({ readOnly }): string => (readOnly ? "#f4f4f4" : "")};
  padding: ${({ readOnly }): string => (readOnly ? "4px 8px" : "")};
`;

const RegistratedNumberVerifyResult = styled.div`
  margin-left: 12px;
  margin-right: 12px;
`;

const InvoicingOrganizationProcessDetail = styled.div`
  margin-top: 0px;
`;

const RegistratedNumberContentArea = styled.div<{ readOnly: boolean }>`
  padding-left: ${({ readOnly }): string => (readOnly ? "8px" : "")};
`;

const NonStyleUl = styled.ul`
  list-style: none;
  white-space: pre-wrap;
  padding-left: 0px;
  padding-top: 5px;
`;

const VALID_REGISTRATED_NUMBER_LENGTH = 13;

interface Props {
  readOnly: boolean;
  registratedNumber: string; // nullな時もあるが、EntryFormsの中で''を代入
  invoicingOrganization: InvoicingOrganization | null | undefined; // デフォルトはundefined。APIを叩いた結果により InvoicingOrganization もしくは null が入る
  isRegistratedNumberShow: boolean;
  onChangeNumber?: (number: string | null) => void;
  onVerifyNumber?: () => void;
}

const RegistratedNumberAndInvoicingOrganizationForm: FC<Props> = (props) => {
  const {
    readOnly,
    registratedNumber,
    invoicingOrganization,
    isRegistratedNumberShow,
    onChangeNumber,
    onVerifyNumber,
  } = props;

  const prefixedRegistratedNumber = `T${registratedNumber}`;

  const acceptOnlyNumbers = (s: string): string => s.replace(/[^\d\b]/g, ""); // 念の為バックスペースcharは許可
  const acceptOnly13strings = (s: string): string => s.substring(0, 13);
  const handleChangeRegistratedNumber = (
    e: React.ChangeEvent<HTMLInputElement>,
  ): void => {
    const onlyNum = acceptOnlyNumbers(e.target.value);
    const limitedString = acceptOnly13strings(onlyNum);
    const value = limitedString === "" ? null : limitedString; // 空文字の場合はnull(デフォルト値)にする

    if (onChangeNumber !== undefined) {
      onChangeNumber(value);
    }
  };

  const handleBlur = (e: React.ChangeEvent<HTMLInputElement>): void => {
    if (e.target.value.length === 14) {
      // Tがprefixとして入るので
      if (onVerifyNumber !== undefined) {
        onVerifyNumber();
      }
    }
  };

  // 登録番号フォームにフォーカスが当たっている状態で Enter を押すと未登録の状態で保存されてしまう
  // そのため、Enter を押したときに登録番号の確認のみを行い、デフォルトイベントは阻止して保存させないようにする
  const handleKeyPress = (e: React.KeyboardEvent<HTMLInputElement>): void => {
    if (e.key === "Enter") {
      e.preventDefault();

      if (onVerifyNumber !== undefined) {
        onVerifyNumber();
      }
    }
  };

  const handleClearRegistratedNumber = (): void => {
    if (onChangeNumber !== undefined) {
      onChangeNumber(null);
    }
  };

  const registratedNumberVerifyResult = (
    io: InvoicingOrganization | null | undefined,
  ): JSX.Element => {
    return (
      <span>
        {["01", "02"].includes(io?.process ?? "") ? (
          <i className={"fa fa-check color-success"} />
        ) : (
          <i className={"fa fa-times color-danger"} />
        )}
      </span>
    );
  };

  const invoicingOrganizationProcessDetail = (
    io: InvoicingOrganization | null,
  ): string => {
    if (io === null) {
      // 未登録
      return i18next.t(
        "managements.invoicingOrganizations.processDetail.unregistered",
      );
    }

    switch (io.process) {
      case "03": // 登録の失効
        return i18next.t(
          "managements.invoicingOrganizations.processDetail.expire",
        );
      case "04": // 登録の取消
        return i18next.t(
          "managements.invoicingOrganizations.processDetail.disposal",
        );
      case "99": // 登録の削除
        return i18next.t(
          "managements.invoicingOrganizations.processDetail.deleted",
        );
      default:
        return "";
    }
  };

  return (
    <>
      {/* 登録番号および適格請求書発行事業者 詳細 */}
      {isRegistratedNumberShow && (
        <RegistratedNumberArea className="registrated-number-area">
          <RegistratedNumberTitleArea
            readOnly={readOnly}
            className="registrated-number-title-area"
          >
            <>{i18next.t("transactions.properties.registratedNumber")}</>
            {registratedNumber.length === VALID_REGISTRATED_NUMBER_LENGTH &&
              invoicingOrganization !== undefined && (
                <>
                  <RegistratedNumberVerifyResult className="registrated-number-verify-result">
                    {registratedNumberVerifyResult(invoicingOrganization)}
                  </RegistratedNumberVerifyResult>
                  <InvoicingOrganizationProcessDetail className="invoicing-organization-process-detail">
                    <span className="text-danger">
                      {invoicingOrganizationProcessDetail(
                        invoicingOrganization,
                      )}
                    </span>
                  </InvoicingOrganizationProcessDetail>
                </>
              )}
          </RegistratedNumberTitleArea>
          <RegistratedNumberContentArea
            readOnly={readOnly}
            className="registrated-number-content-area"
          >
            {/* 登録番号 */}
            {readOnly && <>{prefixedRegistratedNumber}</>}
            {!readOnly && (
              <>
                <input
                  type="text"
                  key={"registrated_number_input"}
                  className={`form-control`}
                  onChange={handleChangeRegistratedNumber}
                  value={prefixedRegistratedNumber}
                  onBlur={handleBlur}
                  onKeyPress={handleKeyPress}
                />
                <span className="react-autosuggest-clear-btn">
                  <i
                    className="fa fa-times-circle"
                    onClick={(): void => {
                      handleClearRegistratedNumber();
                    }}
                  ></i>
                </span>
                {registratedNumber.length > 0 &&
                  registratedNumber.length < 13 && (
                    <span className="text-danger">
                      {i18next.t(
                        "paymentRequests.errors.registratedNumber.inputAlert",
                      )}
                    </span>
                  )}
              </>
            )}

            {/* 適格請求書発行事業者 詳細 */}
            {invoicingOrganization && (
              <NonStyleUl className="non-style-ul">
                <li>{invoicingOrganization.name}</li>
                {invoicingOrganization.address !== null && (
                  <li>{invoicingOrganization.address}</li>
                )}
                <li>
                  {i18next.t(
                    "managements.invoicingOrganizations.properties.registrationDate",
                  )}
                  :{" "}
                  {Formatter.date(invoicingOrganization.registrationDate, {
                    emptyText: "",
                  })}
                </li>
                <li>
                  {i18next.t(
                    "managements.invoicingOrganizations.properties.updateDate",
                  )}
                  :{" "}
                  {Formatter.date(invoicingOrganization.updateDate, {
                    emptyText: "",
                  })}
                </li>
              </NonStyleUl>
            )}
          </RegistratedNumberContentArea>
        </RegistratedNumberArea>
      )}
    </>
  );
};

RegistratedNumberAndInvoicingOrganizationForm.defaultProps = {
  readOnly: true,
  registratedNumber: "",
  invoicingOrganization: undefined,
  isRegistratedNumberShow: true,
};

export default RegistratedNumberAndInvoicingOrganizationForm;
