import Api from 'utilities/api';
import CompanionAppendForm from './CompanionAppendForm';
import CompanionDeleteConfirmModal from './CompanionDeleteConfirmModal';
import ListSelector from 'components/list_selector';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import SelectedCompanionlist from './SelectedCompanionList';
import flash from 'utilities/flash';
import i18next from 'i18n';
import { getMessageFromResponse, snakecaseKeys } from 'utilities/Utils';

/** 参加者の選択を行うモーダルを表示するボタン */
export default class CompanionSelectorCol extends Component {
  constructor(props) {
    super(props);
    this.state = {
      // 全てのclients/companion
      deleteModalOpen: false,
      willDeleteCompanion: null,
      companions: [],
      filteredCompanions: [],
      searchKeyword: '',
      transactionType: 'name',
      sizePerPage: 10,
    };

    this.onAppend = this.onAppend.bind(this);
    this.onChangeSizePerPage = this.onChangeSizePerPage.bind(this);
    this.closeDeleteConfirm = this.closeDeleteConfirm.bind(this);
    this.reloadCompanions = this.reloadCompanions.bind(this);
    this.fetchCompanions = this.fetchCompanions.bind(this);
    this.fetchAndReloadCompanions = this.fetchAndReloadCompanions.bind(this);
    this.clearSearchResult = this.clearSearchResult.bind(this);
    this.buttonFormat = this.buttonFormat.bind(this);
    this.isCompanionDeletable = this.isCompanionDeletable(this);
    this.openDeleteConfirm = this.openDeleteConfirm.bind(this);
    this.titleFormat = this.titleFormat.bind(this);
    this.itemFormat = this.itemFormat.bind(this);
    this.onSearchTextChange = this.onSearchTextChange.bind(this);
  }

  async componentDidMount() {
    // TODO: アンマウント後に後続処理が動かないように Rxjs でサブスクライブしておくこと
    const response = await this.fetchCompanions(0, this.state.sizePerPage, this.state.searchKeyword);
    // 取得に失敗している場合, エラー表示も終わっているので早期returnを行う
    if (response === void 0) { return; }

    const {
      /** 検索条件に基づいて取得された参加者 */
      companions,
      /** ログインしているユーザ自身を指し示す参加者情報 */
      currentUsersCompanion,
    } = response;

    this.reloadCompanions(companions);

    // 必要に応じてログインしているユーザ自身を、自社の参加者として追加する
    if (!this.props.isClient && this.props.shouldSelectSelfAsCompanion) {
      const companionIds = this.props.value.map((val) => val.id);
      if (!companionIds.includes(currentUsersCompanion.id)) {
        this.props.onSelect(this.props.value.concat(currentUsersCompanion));
      }
      this.props.onSelectCompanionsCategory();
    }
  }

  /**
   * 引数に基づいて、参加者の情報を取得します
   * @param offset 取得開始位置
   * @param limit 取得する件数
   * @param searchText 検索文字列
   */
  async fetchCompanions(offset, limit, searchText) {
    try {
      return await Api.companions.index(snakecaseKeys({
        limit,
        offset,
        searchText,
        isClient: this.props.isClient,
        ownerId: this.props.ownerId,
        includeBlockedUser: false,
        fields: ['current_users_companion'],
      }));
    } catch (e) {
      flash.error(getMessageFromResponse(e));
      return undefined;
    }
  }

  /** 引数の参加者情報を元に、 state の参加者情報をリロードします */
  reloadCompanions(companions) {
    // 検索結果と既に選択済みの対象を合わせてリストに表示させる
    this.setState({ companions }, () => {
      const filteredCompanions = this.state.companions.concat(this.props.value)
        .filter((companion, idx, self) => self.findIndex((v) => v.id === companion.id) === idx);
      this.setState({ filteredCompanions });
    });
  }

  /**
   * 引数に基づいて参加者の情報を取得し、 state の参加者情報をリロードします
   * @param offset 取得開始位置
   * @param limit 取得する件数
   * @param searchText 検索文字列
   */
  async fetchAndReloadCompanions(offset, limit, searchText) {
    const response = await this.fetchCompanions(offset, limit, searchText);
    // 取得に失敗している場合, エラー表示も終わっているので早期returnを行う
    if (response === void 0) { return; }

    this.reloadCompanions(response.companions);
  }

  /** 参加者を追加します */
  onAppend(companion) {
    this.fetchAndReloadCompanions(0, this.state.sizePerPage, this.state.searchKeyword);
    this.props.onSelect(this.props.value.concat(companion));
  }

  // 自分が作成した経費でなければ、参加者の「削除」は出来ない
  isCompanionDeletable() {
    return this.props.ownerId === userPreferences.id;
  }

  /** 参加者の削除モーダルを開けます */
  openDeleteConfirm(companion) {
    this.setState({ willDeleteCompanion: companion, deleteModalOpen: true });
  }

  /** 参加者の削除モーダルを閉じます */
  closeDeleteConfirm() {
    this.setState({ willDeleteCompanion: null, deleteModalOpen: false });
  }

  /** 参加者の追加モーダルを表示するためのボタンの表記フォーマット */
  buttonFormat(selected) {
    let anonymousNumber = 0;
    const withoutNumberCompanion = selected.filter((el) => !el.number);
    const includeNumberCompanion = selected.filter((el) => el.number);
    if (includeNumberCompanion.length > 0) {
      anonymousNumber = includeNumberCompanion.map((el) => el.number)
        .reduce((acc, value) => acc + value, 0);
    }

    const companyPrefix = i18next.t(`companions.${this.props.isClient ? 'partner' : 'ourCompany'}`);
    const unit = i18next.t('commons.units.person', { count: selected.length });
    return `${companyPrefix} ${anonymousNumber + withoutNumberCompanion.length} ${unit}`;
  }

  /** 参加者の追加モーダルに表示するタイトルのための表記フォーマット */
  titleFormat(selected) {
    let anonymousNumber = 0;
    const withoutNumberCompanion = selected.filter((el) => !el.number);
    const includeNumberCompanion = selected.filter((el) => el.number);
    if (includeNumberCompanion.length > 0) {
      anonymousNumber = includeNumberCompanion.map((el) => el.number)
        .reduce((acc, value) => acc + value, 0);
    }

    let label = null;
    if (this.props.static) {
      label = i18next.t(`companions.${this.props.isClient ? 'partners' : 'ours'}`);
    } else {
      label = i18next.t(`companions.${this.props.isClient ? 'addPartners' : 'addOurs'}`);
    }
    const unit = i18next.t('commons.units.person', { count: selected.length });
    return (
      <span>{ label }&nbsp;<small>{ `${anonymousNumber + withoutNumberCompanion.length} ${unit}` }</small></span>
    );
  }

  /** 参加者の追加モーダルに表示する各リスト項目のための表記フォーマット */
  itemFormat(item) {
    return (
      <div>
        <div>{ item.name }</div>
        <small>{ item.isClient ? item.company : item.department }&nbsp;</small>
      </div>
    );
  }

  /** 検索文字列が入力されたときのメソッド */
  onSearchTextChange(e) {
    this.setState({ searchKeyword: e.target.value });
  }

  /** 参加者の選択リストの表示件数を変更します */
  onChangeSizePerPage(sizePerPage) {
    this.fetchAndReloadCompanions(0, sizePerPage, this.state.searchKeyword);
    this.setState({ sizePerPage });
  }

  /** 検索キーワードをクリアします */
  clearSearchResult() {
    this.fetchAndReloadCompanions(0, this.state.sizePerPage, '');
    this.setState({ searchKeyword: '' });
  }

  render() {
    const search = {
      searchPlaceholder: i18next.t(`companions.${this.props.isClient ? 'partnerStaff' : 'ourStaff'}`),
      inputText: this.state.searchKeyword,
      onTextChange: this.onSearchTextChange,
      onClickSubmit: () => { this.fetchAndReloadCompanions(0, this.state.sizePerPage, this.state.searchKeyword); },
      onClickResetSearchConditionButton: this.clearSearchResult,
    };

    return (
      <ListSelector
        isDeletable={ this.isCompanionDeletable }
        staticItem={ (c) => c.isUser || c.groupId === userPreferences.rootGroup.id }
        onDelete={ this.openDeleteConfirm }
        type='checkbox'
        static={ this.props.static }
        buttonFormat={ this.buttonFormat }
        titleFormat={ this.titleFormat }
        itemFormat={ this.itemFormat }
        items={ this.state.filteredCompanions }
        value={ this.props.value }
        search={ search }
        onSelect={ this.props.onSelect }
        showSizePerPage={ true }
        sizePerPageList={ [5, 10, 25] }
        sizePerPage={ this.state.sizePerPage }
        onChangeSizePerPage={ this.onChangeSizePerPage }
        disabled={ this.props.disabled }
      >
        <SelectedCompanionlist
          selectedCompanions={ this.props.value }
          onSelect={ this.props.onSelect }
        />
        <CompanionAppendForm
          onAppend={ this.onAppend }
          ownerId={ this.props.ownerId }
          selectedCompanions={ this.props.value }
          isClient={ this.props.isClient }
        />
        <CompanionDeleteConfirmModal
          afterDelete={ async () => {
            await this.fetchAndReloadCompanions(0, this.state.sizePerPage, this.state.searchKeyword);
          } }
          companion={ this.state.willDeleteCompanion }
          show={ this.state.deleteModalOpen }
          onClose={ this.closeDeleteConfirm }
        />
      </ListSelector>
    );
  }
}

CompanionSelectorCol.defaultProps = {
  onError: () => null,
  disabled: false,
};

CompanionSelectorCol.propTypes = {
  value: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.string.isRequired,
  })),
  isClient:     PropTypes.bool,
  shouldSelectSelfAsCompanion: PropTypes.bool,
  onSelectCompanionsCategory: PropTypes.func,
  ownerId:      PropTypes.string,
  onError:      PropTypes.func,
  onSelect:     PropTypes.func,
  addPartnerNumber: PropTypes.func,
  disabled: PropTypes.bool,
};
