import React, { useState } from 'react';
import colors from 'values/colors';
import fonts from 'values/fonts';
import styled from 'styled-components';

interface Props {
  label: string;
  required?: boolean;
  isViewMode?: boolean
  showIcon?: JSX.Element;
}

// 本来であればisViewMode/openModeを変数化したいが、サイドメニュー内の経費作成フォームではstyle-componentsを使う事が出来ず、変数も使えない。
// よって、クラスを愚直に組み合わせている
const AccordionListFormView = styled.div`
  margin-bottom: 20px;

  &.close-mode.edit-mode {
    border-bottom: solid 2px #dedcdc;
  }

  &.open-mode {
    .accordion-body {
      max-height: 500px;
    }
  }

  &.view-mode {
    .accordion-top-label {
      background: ${colors.renewaled.whiteHover};
      padding: 4px 8px 2px;
      border-radius: 4px;
    }

    .accordion-body {
      padding-left: 8px;
      margin-bottom: 4px;

      .label-text {
        background: ${colors.renewaled.whiteHover};
        padding: 4px 8px 2px;
        border-radius: 4px;
      }
    }
  }

  .accordion-top-label {
    display: flex;
    cursor: pointer;
    margin-bottom: 0px;
    background: none;
    font-weight: ${fonts.weight.bold};
    padding: 0px;
    border-radius: 0px;
    .required-icon {
      color: red;
      font-weight: 400;
      margin-left: 2px;
    }
  }

  .accordion-body {
    padding-left: 0px;
    margin-bottom: 0px;
    max-height: 0px;
    overflow: hidden;
    transition: max-height 0.3s;

    .label-text {
      display: flex;
      font-weight: ${fonts.weight.bold};
      background: none;
      padding: 0px;
      border-radius: 0px;
      .required-icon {
        color: red;
        font-weight: 400;
        margin-left: 2px;
      }
    }
  }
`;

export const AccordionListForm: React.FC<Props> = ({
  label, showIcon, required, children, isViewMode,
}) => {
  const [isOpen, setIsOpen] = useState(true);

  return (
    <AccordionListFormView className={ `accordion-list-form ${isOpen ? 'open-mode' : 'close-mode'} ${isViewMode ? 'view-mode' : 'edit-mode'}` }>
      <label className="accordion-top-label" onClick={ ():void => { setIsOpen(!isOpen); } }>
        <div className="">
          { label.split('\n').map((l, i) => <div key={ i }>{ l }</div>) }
        </div>
        { required && <span className="required-icon">*</span> }
        <span style={ { paddingLeft: '5px' } }>{ showIcon }</span>
        <span className={ `fa fa-angle-${isOpen ? 'up' : 'down'}` } style={ { marginLeft: 'auto' } }/>
      </label>
      <div className="accordion-body">
        { children }
      </div>
    </AccordionListFormView>
  );
};
