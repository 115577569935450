import PropTypes from 'prop-types';
import React, { Component } from 'react';
import _ from 'lodash';

export default class AccordionButton extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isOpen: props.defaultOpen,
    };

    this.targetId = _.uniqueId('target');
    this.handleClick = this.handleClick.bind(this);
  }

  get className() {
    const baseClassName = 'accordion-button';

    if (this.props.className) {
      return `${this.props.className} ${baseClassName}`;
    }

    return baseClassName;
  }

  get toggleButtonClassName() {
    return `accordion-button--toggle-button${this.props.inline ? '-inline' : ''}`;
  }

  get defaultBodyClassName() {
    const baseClassName = 'collapse';

    if (this.props.defaultOpen) {
      return `${baseClassName} in`;
    }

    return baseClassName;
  }

  handleClick(e) {
    e.stopPropagation();

    this.setState({ isOpen: !this.state.isOpen });
  }

  render() {
    return (
      <div className={ this.className }>
        <a className={ this.toggleButtonClassName }
          data-toggle='collapse'
          data-target={ `#${this.targetId}` }
          aria-expanded={ this.state.isOpen }
          aria-controls={ this.targetId }
          onClick={ this.handleClick }
        >
          { this.props.renderButton() }
          {
            this.props.disabledArrow ? null : (
              <span className={ `fa fa-right fa-angle-${this.state.isOpen ? 'up' : 'down'}` } />
            )
          }
        </a>
        <div id={ this.targetId } className={ this.defaultBodyClassName }>
          { this.props.children }
        </div>
        { this.props.inline ? null : (<hr className='no-margin' />) }
      </div>
    );
  }
}

AccordionButton.defaultProps = {
  defaultOpen: true,
  inline: false,
  disabledArrow: false,
};

AccordionButton.propTypes = {
  className: PropTypes.string,
  inline: PropTypes.bool,
  renderButton: PropTypes.func.isRequired,
  defaultOpen: PropTypes.bool.isRequired,
  disabledArrow: PropTypes.bool,
};
