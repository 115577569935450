import * as actions from '../actions/reportRequests';
import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';
import ReportRequestModal from './ReportRequestModal';
import SimpleModal from 'components/SimpleModal';
import i18next from 'i18n';
import { connect } from 'react-redux';
import { isCorporatePlan } from 'utilities/Utils';

export class ReportSendModal extends PureComponent {
  constructor(props) {
    super(props);

    this.submitReportName = this.submitReportName.bind(this);
    this.saveAsDraft = this.saveAsDraft.bind(this);
  }

  submitReportName() {
    const { reportTitle, transactionIds } = this.props;

    if (isCorporatePlan()) {
      return this.props.fetchSplittedReports(reportTitle, transactionIds);
    }

    return this.props.createReportForPersonal(reportTitle, transactionIds);
  }

  saveAsDraft() {
    const { reportTitle, transactionIds } = this.props;
    return this.props.draftSaveReport(reportTitle, transactionIds);
  }

  renderReportNameModal() {
    const buttons = [
      {
        className: 'col-sm-6 col-sm-offset-3',
        color: 'success',
        content: i18next.t('commons.actions.finish'),
        onClick: this.props.isDraft ? this.saveAsDraft : this.submitReportName,
      },
    ];

    return (
      <SimpleModal
        title={ i18next.t(`reports.requests.${isCorporatePlan() ? 'enterReportTitle' : 'enterPersonalReportTitle'}`) }
        show={ this.props.show }
        close={ this.props.close }
        buttons={ buttons }
      >
        <input type="text" className="form-control"
          value={ this.props.reportTitle }
          onChange={ (e) => { this.props.onChangeTitle(e.target.value); } }
        />
      </SimpleModal>
    );
  }

  render() {
    return (
      <div>
        { this.renderReportNameModal() }
        {this.props.isReportRequestModalOpen && (
          <ReportRequestModal
            show={ this.props.isReportRequestModalOpen }
            close={ this.props.closeReportRequestModal }
            reports={ this.props.reports }
          />
        )}
      </div>
    );
  }
}

ReportSendModal.defaultProps = {
  transactions: [],
  isLoading: false,
};

ReportSendModal.propTypes = {
  show: PropTypes.bool.isRequired,
  close: PropTypes.func.isRequired,
  reportTitle: PropTypes.string.isRequired,
  transactionIds: PropTypes.arrayOf(PropTypes.string.isRequired).isRequired,
};

function mapStateToProps(state, ownProps) {
  return {
    reports: state.request.reports,
    reportTitle: state.request.reportTitle,
    isReportRequestModalOpen: state.request.isReportRequestModalOpen,
    isDraft: state.request.useReportSendModalAsDraftMode,
  };
}

function mapDispatchToProps(dispatch, ownProps) {
  return {
    onChangeTitle(reportTitle) {
      dispatch(actions.setReportTitle(reportTitle));
    },
    createReportForPersonal(reportTitle, transactionIds) {
      return dispatch(actions.createReportForPersonal(reportTitle, transactionIds));
    },
    fetchSplittedReports(reportTitle, transactionIds) {
      return dispatch(actions.fetchSplittedReports(reportTitle, transactionIds));
    },
    closeReportRequestModal() {
      dispatch(actions.toggleReportRequestModal(false));
    },
    draftSaveReport(reportTitle, transactionIds) {
      return dispatch(actions.draftSaveReport(reportTitle, transactionIds));
    },
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(ReportSendModal);
