import React from "react";

interface Props {
  /** 開くTabのindex番号 */
  readonly selectedIndex: number;
  /** 選択中のindex番号 */
  readonly value: number;
}

/**
 * タブ: コンテンツ部分
 */
export const TabPanel: React.FC<Props> = ({
  selectedIndex,
  value,
  children,
}) => {
  if (selectedIndex !== value) return null;

  return (
    <div className={`renewaled-tab-panel-${selectedIndex}`}>{children}</div>
  );
};
