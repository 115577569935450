import {
  changeUrl,
  clearMessage,
  displayMessage,
} from "actions/ActionCreators";
import i18next from "i18n";
import _get from "lodash/get";
import Api from "utilities/api";
import { snakecaseKeys } from "utilities/Utils";
import * as formActions from "../../request_types/actions/formActions";
import * as applicationActions from "./applicationActions";

const prefix = `APPLICATION_REQUEST`;

export const SET_COMMENT = `${prefix}/SET_COMMENT`;
export function setComment(comment) {
  return {
    type: SET_COMMENT,
    comment,
  };
}

export const SET_CANCEL_APPROVAL_COMMENT = `${prefix}/SET_CANCEL_APPROVAL_COMMENT`;
/**
 * 承認取消のコメントを設定します。
 * @param {String} comment コメント
 * @return {Object} 承認取消のコメントを設定するアクション
 */
export function setCancelApprovalComment(comment) {
  return {
    type: SET_CANCEL_APPROVAL_COMMENT,
    payload: {
      comment,
    },
  };
}

export function fetchRequest() {
  return async (dispatch, getState) => {
    try {
      const {
        requestType,
        application: { title },
        form,
      } = getState();
      formActions.validate(form);

      const params = { requestTypeId: requestType.id, title };
      const data = await Api.applicationRequests.request(snakecaseKeys(params));
      dispatch(setTitle(data.title));
      dispatch(setApprovalFlow(data.approvalFlow));
      dispatch(toggleModal(true));
    } catch (e) {
      dispatch(
        displayMessage(
          "error",
          _get(e, "responseJSON.message") ||
            e.message ||
            i18next.t("commons.errors.communicationError"),
        ),
      );
    }
  };
}

export function request(approvals, onError) {
  return async (dispatch, getState) => {
    const {
      requestType,
      application,
      applicationRequest: { title, comment },
      form,
    } = getState();

    const params = snakecaseKeys({
      applicationId: application && application.id, // idがあるときは、申請フローを変更する時
      requestTypeId: requestType.id,
      approvals,
      title,
      comment,
      formItemInputs: sliceFormParams(form),
    });
    try {
      const { applicationId } = await Api.applicationRequests.create(params);
      dispatch(toggleModal(false));
      dispatch(changeUrl(`/applications/${applicationId}`));
      dispatch(applicationActions.fetchApplication(applicationId));
      dispatch(clearMessage());
    } catch (e) {
      onError(
        _get(e, "responseJSON.message") ||
          i18next.t("commons.errors.communicationError"),
      );
    }
  };
}

export function reapply() {
  return async (dispatch, getState) => {
    const {
      application: {
        request: { id },
      },
      form,
    } = getState();
    try {
      formActions.validate(form);
      await dispatch(
        callAndReload(
          Api.requests.reapply,
          snakecaseKeys({ id, formItemInputs: sliceFormParams(form) }),
        ),
      );
    } catch (e) {
      dispatch(displayMessage("error", e.message));
    }
  };
}

export function recall() {
  return (dispatch, getState) => {
    const {
      application: {
        request: { id },
      },
    } = getState();
    return dispatch(callAndReload(Api.requests.destroy, { id }));
  };
}

/**
 * 汎用申請を差戻します。
 * @param {string} requestId リクエストID
 * @param {string} comment コメント
 * @param {string} stepId 差戻先のID（申請者の場合は null）
 */
export function reject(requestId, comment, stepId = null) {
  return (dispatch) => {
    try {
      dispatch(startRejecting());

      dispatch(
        callAndReload(
          Api.requests.reject,
          snakecaseKeys({ id: requestId, comment, stepId }),
        ),
      );
      dispatch(closeRejectModal());
    } catch (e) {
      // 何もしない
    } finally {
      dispatch(finishRejecting());
    }
  };
}

export function approve() {
  return (dispatch, getState) => {
    const {
      application: {
        request: { id },
      },
    } = getState();
    return dispatch(callAndReload(Api.requests.approve, { id }));
  };
}

function callAndReload(api, params) {
  return async (dispatch) => {
    try {
      await api(params);
      await dispatch(applicationActions.reload());
      dispatch(clearMessage());
    } catch (e) {
      dispatch(
        displayMessage(
          "error",
          _get(e, "responseJSON.message") ||
            i18next.t("commons.errors.communicationError"),
        ),
      );
    }
  };
}

export const TOGGLE_MODAL = `${prefix}/TOGGLE_MODAL`;
function toggleModal(showModal) {
  return {
    type: TOGGLE_MODAL,
    showModal,
  };
}

export function closeModal() {
  return toggleModal(false);
}

export const TOGGLE_CANCEL_APPROVAL_MODAL = `${prefix}/TOGGLE_CANCEL_APPROVAL_MODAL`;
/**
 * 承認取消モーダルの表示・非表示を切り替えます
 * @param {Boolean} showCancelApprovalModal 表示する場合 true、非表示の場合 false
 * @return {Object} 承認取消モーダルの表示・非表示を設定するアクション
 */
function toggleCancelApprovalModal(showCancelApprovalModal) {
  return {
    type: TOGGLE_CANCEL_APPROVAL_MODAL,
    payload: {
      showCancelApprovalModal,
    },
  };
}

/**
 * 承認取消モーダルを表示します。
 * @return {Object} 承認取消モーダルの表示・非表示を設定するアクション
 */
export function openCancelApprovalModal() {
  return toggleCancelApprovalModal(true);
}

/**
 * 承認取消モーダルを表示します。
 * @return {Object} 承認取消モーダルの表示・非表示を設定するアクション
 */
export function closeCancelApprovalModal() {
  return toggleCancelApprovalModal(false);
}

export const SET_TITLE = `${prefix}/SET_TITLE`;
function setTitle(title) {
  return {
    type: SET_TITLE,
    title,
  };
}

export const SET_APPROVAL_FLOW = `${prefix}/SET_APPROVAL_FLOW`;
function setApprovalFlow(approvalFlow) {
  return {
    type: SET_APPROVAL_FLOW,
    approvalFlow,
  };
}

export const SHOW_REJECT_MODAL = `${prefix}/SHOW_REJECT_MODAL`;
export function showRejectModal() {
  return {
    type: SHOW_REJECT_MODAL,
  };
}

export const CLOSE_REJECT_MODAL = `${prefix}/CLOSE_REJECT_MODAL`;
export function closeRejectModal() {
  return {
    type: CLOSE_REJECT_MODAL,
  };
}

export const INPUT_REJECT_REASON = `${prefix}/INPUT_REJECT_REASON`;
export function inputRejectReason(value) {
  return { type: INPUT_REJECT_REASON, value };
}

export const START_REJECTING = `${prefix}/START_REJECTING`;
function startRejecting() {
  return { type: START_REJECTING };
}

export const FINISH_REJECTING = `${prefix}/FINISH_REJECTING`;
function finishRejecting() {
  return { type: FINISH_REJECTING };
}

function sliceFormParams(formItems) {
  return formItems.map((item) => {
    if (item.type === "attachment") {
      return {
        id: item.id,
        attachmentId: item.value[0] && item.value[0].attachmentId,
      };
    }

    return {
      id: item.id,
      value: item.value,
    };
  });
}
