import i18next from "i18n";
import get from "lodash/get";
import React, { FC } from "react";
import { TableHeaderColumn } from "react-bootstrap-table";
import { canUpdateAll } from "../../utilities/transactionFormPolicy";
import { ColumnEditProps, ColumnProps, Row } from "./types";
import { buildInputFormatter, buildTableHeaderColumnOption } from "./utils";

type Props = ColumnEditProps & ColumnProps;

const formatAmount = (
  props: Props,
  cell: number,
  row: Row,
): string | JSX.Element => {
  return buildInputFormatter(
    "amount",
    cell,
    row,
    props.isEditable,
    !props.isEditing || !row.editable || !canUpdateAll(row.permissions),
    get(row, "editorSync.amount"),
    props.onChangeTransaction,
    props.onUpdateTransaction,
  );
};

const renderAmountColumn: FC<Props> = (props) => {
  return (
    <TableHeaderColumn
      {...buildTableHeaderColumnOption(props)}
      dataField={"amount"}
      dataFormat={formatAmount.bind(null, props)}
    >
      {i18next.t("transactions.properties.amount")}
    </TableHeaderColumn>
  );
};

export default renderAmountColumn;
