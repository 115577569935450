import PropTypes from 'prop-types';


const transactionType = {
  id: PropTypes.string,
  transactedAt: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.arrayOf(PropTypes.string),
  ]),
  amount: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  originalAmount: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  originalAmountCurrencyId: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  shopName: PropTypes.string,
  categoryName: PropTypes.string,
  groupName: PropTypes.string,
  reportTitle: PropTypes.string,
  comment: PropTypes.string,
  localReceiptFile: PropTypes.shape({
    foreside: PropTypes.shape({
      contentType: PropTypes.string.isRequired,
      file: PropTypes.object.isRequired,
      rotation: PropTypes.number.isRequired,
    }),
    backside: PropTypes.shape({
      contentType: PropTypes.string.isRequired,
      file: PropTypes.object.isRequired,
      rotation: PropTypes.number.isRequired,
    }),
  }),
  editable: PropTypes.bool,
  status: PropTypes.string,
  companions: PropTypes.array,
  project: PropTypes.shape({
    displayId: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
  }),
  category: PropTypes.shape({
    name: PropTypes.string.isRequired,
    requiresCompanion: PropTypes.bool.isRequired,
  }),
  route: PropTypes.shape({
    origin: PropTypes.shape({
      name: PropTypes.string.isRequired,
    }).isRequired,
    destination: PropTypes.shape({
      name: PropTypes.string.isRequired,
    }).isRequired,
    isRoundTrip: PropTypes.bool,
    lines: PropTypes.arrayOf(
      PropTypes.shape({
        color: PropTypes.string,
        name: PropTypes.string.isRequired,
      }),
    ).isRequired,
  }),
  creditCategoryName: PropTypes.string,
  formValues: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
  isDeleted: PropTypes.bool,
};

export default transactionType;
