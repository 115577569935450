import { useState } from "react";

interface UseReturn {
  readonly onMouseDown: () => void;
  readonly onMouseUp: () => void;
  readonly onMouseLeave: () => void;
  readonly onMouseMove: (e: React.MouseEvent<HTMLDivElement>) => void;
}

/**
 * PDFビューアー: 手のひらツールロジック
 */
export const useHooksHand = (
  isHand: boolean,
  scrollAreaRef: React.RefObject<HTMLDivElement>,
): UseReturn => {
  /**
   * クリック中か
   */
  const [isKeepClick, setIsKeepClick] = useState<boolean>(false);

  /**
   * マウスポジション履歴
   */
  let prevPosition = { x: 0, y: 0 };

  /**
   * ポジション履歴の更新
   */
  const setPosition = (x: number, y: number): void => {
    prevPosition = { x, y };
  };

  /**
   * ポジション情報を初期化する
   */
  const clearPosition = (): void => {
    if (!isHand) return;
    // クリック中を解除
    setIsKeepClick(false);
    // 位置履歴の初期化
    setPosition(0, 0);
  };

  /**
   * マウスダウンをした
   */
  const onMouseDown = (): void => {
    if (!isHand) return;
    setIsKeepClick(true);
  };

  /**
   * マウスアップをした
   */
  const onMouseUp = (): void => {
    clearPosition();
  };

  /**
   * マウスが領域外に出た
   */
  const onMouseLeave = (): void => {
    clearPosition();
  };

  /**
   * マウスが移動した
   */
  const onMouseMove = (e: React.MouseEvent<HTMLDivElement>): void => {
    // 手のひらツールでない場合は何もしない
    if (!isHand) return;
    // クリック中のみ動かす
    if (!isKeepClick) return;
    // 初めのクリックは位置を保存するのみ
    if (prevPosition.x === 0 && prevPosition.y === 0) {
      setPosition(e.clientX, e.clientY);
      return;
    }

    // 加算するポジション
    // クリック中に移動した量
    const addedX = prevPosition.x - e.clientX;
    const addedY = prevPosition.y - e.clientY;

    // 現在のスクロールポジション
    const scrollX = scrollAreaRef.current?.scrollLeft || 0;
    const scrollY = scrollAreaRef.current?.scrollTop || 0;

    // 更新するポジション
    // クリック中に移動した分だけ、スクロールを加算する
    const x = scrollX + addedX;
    const y = scrollY + addedY;

    // 丸めたポジション
    const roundX = x < 0 ? 0 : x;
    const roundY = y < 0 ? 0 : y;

    // スクロール位置の更新
    scrollAreaRef.current?.scrollTo(roundX, roundY);
    // ポジション履歴の更新
    setPosition(e.clientX, e.clientY);
  };

  return {
    onMouseDown,
    onMouseUp,
    onMouseMove,
    onMouseLeave,
  };
};
