import { displayMessage } from "actions/ActionCreators";
import { AllowanceIcon } from "applications/expenses/collections/AllowanceIcon";
import * as transactionActions from "applications/transactions/actions/transactionTable";
import TransactionListEditor from "applications/transactions/containers/TransactionListEditor";
import i18next from "i18n";
import _get from "lodash/get";
import _sortBy from "lodash/sortBy";
import PropTypes from "prop-types";
import React, { Component } from "react";
import { DropdownButton, MenuItem } from "react-bootstrap";
import { connect } from "react-redux";
import transactionType from "types/transaction";
import { getEmptyCostAllocations } from "../../transactions/actions";
import { foldFormValues } from "../../transactions/utilities/transactionFormBuilder";
import * as actions from "../actions/preReportActions";
import * as historyActions from "../actions/reportHistories";
import makeTransactionTableStateMapper from "../selectors/transactionTable";

export class PreReportTransactionTableCard extends Component {
  renderAddDropDown() {
    const { entryForms } = this.props;
    if (!entryForms.length) {
      return null;
    }

    const preReportParams = {
      formValues: this.props.preReportFormValues,
      fields: _get(
        entryForms.find((form) => form.id === "pre_report"),
        "formFields",
        [],
      ),
    };
    const dropDownMenus = _sortBy(entryForms, ["sort"])
      .filter((x) => x.enabled)
      .map((entryForm, idx) => {
        const defaultTransaction = {
          ...this.props.defaultTransaction,
          formId: entryForm.id,
          directProductTableId: entryForm.directProductTableId,
        };

        switch (entryForm.id) {
          case "normal":
            return (
              <MenuItem
                key="normal"
                href=""
                className="dropdown-item"
                onClick={this.props.openNewTransactionModal.bind(
                  null,
                  preReportParams,
                  defaultTransaction,
                  entryForm,
                )}
              >
                <i className="fas fa-pencil-alt fa-fw" />
                {entryForm.name}
              </MenuItem>
            );
          case "transit":
            return (
              <MenuItem
                key="transit"
                href=""
                className="dropdown-item"
                onClick={this.props.openNewTransactionModal.bind(
                  null,
                  preReportParams,
                  defaultTransaction,
                  entryForm,
                )}
              >
                <i className="fa fa-train fa-fw" />
                {entryForm.name}
              </MenuItem>
            );
          case "allowance":
            return (
              <MenuItem
                key={`allowance-${idx}`}
                href=""
                className="dropdown-item"
                onClick={this.props.openNewTransactionModal.bind(
                  null,
                  preReportParams,
                  defaultTransaction,
                  entryForm,
                )}
              >
                <AllowanceIcon iconName={entryForm.icon || ""} />
                {entryForm.name}
              </MenuItem>
            );
          default:
            return null;
        }
      });

    return (
      <DropdownButton
        bsStyle="outline btn-accent"
        pullRight
        title={i18next.t("transactions.index.addTransactions")}
      >
        {dropDownMenus}
      </DropdownButton>
    );
  }

  render() {
    const { isAddable, isEditable, openTransactionModal, moveExpenseCursor } =
      this.props;

    return (
      <div className="card">
        <div className="card-header">
          <div className="card-title inline">
            {i18next.t("transactions.titles.spentList")}
          </div>
          <div className="card-right">
            {isAddable ? this.renderAddDropDown() : null}
          </div>
        </div>
        <div className="card-content-no-margin">
          <TransactionListEditor
            {...this.props}
            openTransactionModal={openTransactionModal.bind(
              null,
              isEditable,
              this.props.preReportFormValues,
            )}
            moveExpenseCursor={moveExpenseCursor.bind(
              null,
              isEditable,
              this.props.preReportFormValues,
            )}
          />
        </div>
      </div>
    );
  }
}

PreReportTransactionTableCard.propTypes = {
  resetTransactions: PropTypes.func.isRequired,
  transactions: PropTypes.arrayOf(PropTypes.shape(transactionType)).isRequired,
  preReportId: PropTypes.string.isRequired,
  preReportTitle: PropTypes.string.isRequired,
  preReportFormValues: PropTypes.array,
};

function overwriteModalExpense(authority, isEditable, preReport, expense) {
  const forApprover = authority === "approver";

  return {
    ...expense,
    editable: isEditable && expense.editable, // 必要？
    permissions: {
      // 必要？
      updatable: _get(expense, "permissions.updatable"),
      detachable: _get(expense, "permissions.detachable"),
      deletable: _get(expense, "permissions.deletable"),
    },
    costAllocations:
      expense.costAllocations.length === 0
        ? getEmptyCostAllocations()
        : expense.costAllocations,
    showDisabledCategories: forApprover,
    preReportDepartment: preReport.department_input,
  };
}

function mapDispatchToProps(dispatch, ownProps) {
  return {
    /**
     * 編集モードで経費を更新する処理です。
     * transactions/components/TransactionTableから呼ばれます。
     * 申請者・承認者の場合に使用されます。
     * @param {string} id 経費ID
     * @param {string} key 更新した経費の属性名
     * @param {Object[]} transactions 更新した経費
     * @param {Object} transactionsObj inEditとmasterが入っている
     */
    async onUpdateTransaction(id, key, transactions, transactionsObj) {
      const updatedTransactions = transactions.filter(
        (t) => _get(t, "id") === id,
      );
      const data = await dispatch(
        transactionActions.updateTransactions(
          updatedTransactions,
          key,
          transactionsObj,
          false,
        ),
      );

      const forApprover = ownProps.authority === "approver";
      // TODO: 全経費を取得しているので、更新された経費だけを取得しなおす。
      await dispatch(
        actions.fetchPreReportExpenses({
          preReportId: ownProps.preReportId,
          forApprover,
        }),
      );

      if (data && data.message) {
        dispatch(displayMessage("success", data.message));
      }
    },
    fetchEntryForms() {
      dispatch(
        transactionActions.fetchEntryForms(true, true, ownProps.ownerId),
      );
    },
    onClickEditModeSwitch(state) {
      dispatch(transactionActions.toggleEditMode(state));
    },
    onClickUpdateAllButton(transaction, preReportTitle = null) {
      dispatch(
        transactionActions.setUpdateAllModalTransaction(
          transaction,
          preReportTitle,
        ),
      );
      dispatch(transactionActions.openTransactionModal());
    },
    onCreateSuccess(message, callback) {
      dispatch(
        displayMessage(
          "success",
          message || i18next.t("transactions.messages.create"),
        ),
      );
      callback();
    },
    onUpdateSuccess(message, callback) {
      dispatch(
        displayMessage(
          "success",
          message || i18next.t("transactions.messages.update"),
        ),
      );
      callback();
    },
    onDestroySuccess(message, callback) {
      dispatch(
        displayMessage(
          "success",
          message || i18next.t("transactions.messages.delete"),
        ),
      );
      callback();
    },
    onDetachSuccess(message, callback) {
      dispatch(
        displayMessage(
          "success",
          message || i18next.t("transactions.messages.detach"),
        ),
      );
      callback();
    },
    /**
     * 経費分割に成功した場合
     * @param {String} message メッセージ
     * @param {Function} callback コールバック
     */
    onSplitSuccess(message, callback) {
      dispatch(displayMessage("success", message));
      callback();
    },
    onSortChange(sortName, sortOrder) {
      dispatch(actions.onSortChange(sortName, sortOrder));
      return Promise.resolve();
    },
    // 行クリック or 全て既読にするボタン
    markAsRead(transactionIds) {
      dispatch(transactionActions.markAsRead(transactionIds));
    },
    markAsUnread(transactionIds) {
      dispatch(transactionActions.markAsUnread(transactionIds));
    },
    openNewTransactionModal(preReport, defaultTransaction, entryForm) {
      const formValues = foldFormValues(
        entryForm.formFields,
        {
          default: ["department_input"],
          additional: [],
        },
        { formValues: preReport.formValues, fields: preReport.fields },
      );

      dispatch(
        transactionActions.setModalTransaction({
          ...defaultTransaction,
          preReportId: ownProps.preReportId,
          preReportTitle: ownProps.preReportTitle,
          preReportDepartment: preReport.formValues.department_input,
          permissions: { deletable: false },
          editable: true,
          formValues: [
            ...formValues,
            {
              id: null,
              type: "pre_report_input",
              formValue: {
                id: ownProps.preReportId,
                title: ownProps.preReportTitle,
              },
            },
            {
              id: null,
              type: "cost_allocation_input",
              formValue: [
                {
                  payerType: "Group",
                  payerId: _get(
                    ownProps,
                    "preReportFormValues.department_input.id",
                  ),
                  payerName: _get(
                    ownProps,
                    "preReportFormValues.department_input.name",
                  ),
                  numerator: 100,
                  denominator: 100,
                },
              ],
            },
          ],
          showDisabledCategories: ownProps.authority === "approver",
        }),
      );
      dispatch(transactionActions.openTransactionModal());
    },
    openTransactionModal(
      isEditable,
      preReport,
      expenses,
      expense,
      currentPage,
      sizePerPage,
    ) {
      const config = {
        showDisabledCategories: true,
        shouldMarkAsRead: true,
        overwriteModalExpense: overwriteModalExpense.bind(
          null,
          ownProps.authority,
          isEditable,
          preReport,
        ),
      };
      dispatch(
        transactionActions.openTransactionEditModal(
          expenses,
          expense,
          currentPage,
          sizePerPage,
          config,
        ),
      );
    },
    closeTransactionModal() {
      dispatch(transactionActions.setShouldSelectSelfAsCompanion(true));
      dispatch(transactionActions.closeTransactionModal());
    },
    openCostAllocationModal(departments) {
      dispatch(transactionActions.openCostAllocationModal());
      dispatch(transactionActions.setCostAllocations(departments));
    },
    closeCostAllocationModal() {
      dispatch(transactionActions.closeCostAllocationModal());
      dispatch(transactionActions.resetCostAllocations());
    },
    onMouseEnterCostAlocationDiv(rowIdx) {
      dispatch(transactionActions.onMouseEnterCostAlocationDiv(rowIdx));
    },
    onMouseLeaveCostAlocationDiv() {
      dispatch(transactionActions.onMouseLeaveCostAlocationDiv());
    },
    moveExpenseCursor(
      isEditable,
      preReport,
      expenses,
      targetExpenseId,
      currentPage,
      sizePerPage,
    ) {
      const config = {
        showDisabledCategories: true,
        shouldMarkAsRead: true,
        overwriteModalExpense: overwriteModalExpense.bind(
          null,
          ownProps.authority,
          isEditable,
          preReport,
        ),
      };
      dispatch(
        transactionActions.moveExpenseCursor(
          expenses,
          targetExpenseId,
          currentPage,
          sizePerPage,
          config,
        ),
      );
    },
    rotateReceiptFile(expenseId, fileId, rotation) {
      dispatch(
        transactionActions.rotateReceiptFile(expenseId, fileId, rotation),
      );
    },
    onSelectCompanionsCategory() {
      dispatch(transactionActions.setShouldSelectSelfAsCompanion(false));
    },
    detachAll(transactions, callback) {
      dispatch(transactionActions.detachTransactions(transactions, callback));
    },
    openHistoryModal(expenseId) {
      dispatch(historyActions.openHistoryModal(expenseId));
    },
    closeHistoryModal() {
      dispatch(historyActions.closeHistoryModal());
    },
  };
}

export default connect(
  makeTransactionTableStateMapper,
  mapDispatchToProps,
)(PreReportTransactionTableCard);
