import React, { useEffect, useRef } from 'react';
import {
  HeaderGroup,
  Hooks,
  Row,
  TableBodyPropGetter,
  TableBodyProps,
  TablePropGetter,
  TableProps,
  useBlockLayout,
  useColumnOrder,
  useFlexLayout,
  usePagination,
  useResizeColumns,
  useRowSelect,
  useSortBy,
  useTable,
} from 'react-table';
import { RequiredTableDataProps, TableColumn, TableInitialStateSortBy } from 'components/renewaled_ui/Table/interface/table';
import { TableFirstCell } from 'components/renewaled_ui/Table/molecules/TableFirstCell';
import { TableFirstHeaderCell } from 'components/renewaled_ui/Table/molecules/TableFirstHeaderCell';
import { useSticky } from 'react-table-sticky';

interface UseReturn {
  readonly tableWrapperRef: React.RefObject<HTMLDivElement>;
  // eslint-disable-next-line @typescript-eslint/ban-types
  readonly headerGroup: HeaderGroup<object>;
  // eslint-disable-next-line @typescript-eslint/ban-types
  readonly page: Row<object>[];
  readonly columnLength: number;
  // eslint-disable-next-line @typescript-eslint/ban-types
  readonly getTableProps: (propGetter?: TablePropGetter<object> | undefined) => TableProps;
  // eslint-disable-next-line @typescript-eslint/ban-types
  readonly prepareRow: (row: Row<object>) => void;
  // eslint-disable-next-line @typescript-eslint/ban-types
  readonly getTableBodyProps: (propGetter?: TableBodyPropGetter<object> | undefined) => TableBodyProps;
}

/**
 * Table ロジック
 */
export const useHooks = <Data extends RequiredTableDataProps>(
  data: Data[],
  columns: TableColumn[],
  columnOrder?: TableColumn['accessor'][],
  showCheckBoxes?: boolean,
  useRowDragAndDrop?: boolean,
  initialStateSortBy?: TableInitialStateSortBy[],
  onSelect?: (data: Data, isSelected: boolean) => void,
  onSelectAll?: (isSelected: boolean) => void,
  onColumnWidthChange?: (id: string, width: number) => void,
): UseReturn => {
  /**
   * テーブルの重複しないIDを作るため、Refでclass名を取得する
   */
  const tableWrapperRef = useRef<HTMLDivElement>(null);
  const tableClassName = tableWrapperRef?.current?.className || '';
  const tableId = tableClassName.replace(/\s+/g, '-');

  /**
   * テーブル先頭のコンポーネントを追加する
   */
  const getTableView = (hooks: Hooks): void => {
    if (!showCheckBoxes || !onSelect || !onSelectAll) return;

    // テーブルの先頭にチェックボックスを追加する
    hooks.visibleColumns.push((selectionColumns) => [
      {
        id: 'selection',
        width: 40,
        maxWidth: 40,
        Header: ({ getToggleAllPageRowsSelectedProps }): JSX.Element => (
          <TableFirstHeaderCell<Data>
            id={ tableId }
            data={ data }
            onSelectAll={ onSelectAll }
            toggleAllPageRowsSelectedProps={ getToggleAllPageRowsSelectedProps() }
          />
        ),
        Cell: ({ row }): JSX.Element => (
          <TableFirstCell<Data>
            id={ tableId }
            data={ data }
            row={ row }
            onSelect={ onSelect }
          />
        ),
      },
      ...selectionColumns,
    ]);
  };

  // テーブル
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    setColumnOrder,
    page,
    state: {
      columnResizing,
    },
  } = useTable(
    {
      data,
      columns,
      manualSortBy: true,
      disableSortBy: false,
      initialState: {
        pageSize: 1000,
        sortBy: initialStateSortBy,
      },
    },
    useSortBy,
    useSticky,
    useBlockLayout,
    useResizeColumns,
    useFlexLayout,
    usePagination,
    useRowSelect,
    useColumnOrder,
    (hooks: Hooks) => getTableView(hooks),
  );

  const headerGroup = headerGroups.length !== 0 ? headerGroups[0] : {} as HeaderGroup;

  /**
   * カラム数を返す
   */
  const columnLength = (): number => {
    const dndNum = useRowDragAndDrop ? 1 : 0;
    const checkBoxNum = showCheckBoxes ? 1 : 0;
    return headerGroup.headers.length + dndNum + checkBoxNum;
  };

  /**
   * 列順変更
   */
  useEffect(() => {
    if (!columnOrder) return;

    setColumnOrder(columnOrder as string[]);
  }, [columnOrder, setColumnOrder]);

  /**
   * 列幅変更
   */
  useEffect(() => {
    const headerIdWidths = columnResizing.headerIdWidths as unknown as (number | string)[][];
    if (columnResizing.isResizingColumn || !columnResizing.columnWidths || !headerIdWidths || !onColumnWidthChange) return;

    if (headerIdWidths.length < 1) return;
    if (headerIdWidths[0].length < 1) return;
    const id = headerIdWidths[0][0] as string;
    if (!id) return;
    const width = columnResizing.columnWidths[id] as number;
    if (!width) return;
    onColumnWidthChange(id, width);

    // depsにcolumnWidthsを入れると無限ループするのでisResizingColumnだけ含める
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [onColumnWidthChange, columnResizing.isResizingColumn]);

  return {
    tableWrapperRef,
    getTableProps,
    headerGroup,
    page,
    prepareRow,
    getTableBodyProps,
    columnLength: columnLength(),
  };
};
