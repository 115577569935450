import { displayMessage } from "actions/ActionCreators";
import { fetchAsync, fetchAsyncAll } from "actions/AsyncAction";
import Api from "utilities/api";
import { snakecaseKeys } from "utilities/Utils";
import { setCategories } from "./settings";
import { buildCellMap, buildInput } from "./utils";

/**
 * 取得した表のデータを整形する
 * 主に、データ構造の変換と、数値を表す文字列を、Numberにキャストする処理を行う
 * （キャストは、小数点以下が0である数について、小数点以下が表示されないようにするために行う）
 *
 * @param {boolean} withInput ユーザの入力情報を初期化する場合、true
 */
export function setupDirectProductTables(
  data,
  withInput = true,
  defaultPeriod,
) {
  const directProductTables = data.map((table) => {
    // セル情報の入ったオブジェクトを初期化
    // セルのvalueには文字列が入る
    const cells = buildCellMap(
      table.cells.map((x) => ({ ...x, value: x.value && +x.value })),
    );

    let calculationFormula = {
      ast: {},
      variables: [],
    };

    if (table.calculationFormula) {
      calculationFormula = {
        ...table.calculationFormula,
        variables: table.calculationFormula.variables.map((variable) => {
          if (variable.options) {
            return {
              ...variable,
              // selectの選択肢のデータのvalueには文字列が入る
              options: variable.options.map((x) => ({
                ...x,
                value: x.value && +x.value,
              })),
            };
          }
          return variable;
        }),
      };
    }

    const newTable = {
      ...table,
      cells,
      calculationFormula,
    };

    if (withInput) {
      return {
        ...newTable,
        input: buildInput(table, cells, [], defaultPeriod),
      };
    }
    return newTable;
  });

  return directProductTables;
}

/**
 * 手当表の一覧を取得する
 *
 * @param {boolean} all trueの時、削除済みの手当表の情報も取得する。デフォルトでfalse
 * @param {boolean} withInput ユーザの入力情報を初期化する場合、true
 * @param {boolean} includeAvailability true の時、因子に対して使用可否のフラグを付与する
 * @param {uid} transactionId (defaultValueOnly: true時に有効) 経費に紐づく手当表の因子一覧を取得する
 * @param {string} ownerId 申請書などの申請者ID
 */
export function fetchDirectProductTables(
  all = false,
  withInput = true,
  includeAvailability = false,
  transactionId = null,
  defaultPeriod = null,
  ownerId = null,
) {
  return (dispatch, getState) => {
    return dispatch(
      fetchAsync(Api.directProductTables.index, {
        all,
        includeAvailability,
        transactionId,
        ownerId,
      }),
    ).then((data) => {
      return Promise.resolve(
        setupDirectProductTables(
          data,
          withInput,
          withInput ? defaultPeriod : null,
        ),
      );
    });
  };
}

/**
 * 計算式の情報を取得する
 *
 * @param {string} tableId
 * @param {string} formulaId
 */
export function fetchCalculationFormula(formulaId) {
  return (dispatch, getState) => {
    return dispatch(
      fetchAsync(Api.directProductTables.calculationFormula, { id: formulaId }),
    );
  };
}

/**
 * @param {Object} params 送信パラメータ
 */
export function createDirectProductTable(params) {
  return (dispatch, getState) => {
    return dispatch(fetchAsync(Api.directProductTables.create, params)).then(
      (data) => {
        dispatch(
          displayMessage("success", data.message || "手当表を登録しました"),
        );
      },
    );
  };
}

/**
 * @param {string} tableId 更新対象の手当表のID
 * @param {Object} params 送信パラメータ
 */
export function updateDirectProductTable(tableId, params) {
  return (dispatch, getState) => {
    return dispatch(
      fetchAsync(Api.directProductTables.update, { ...params, id: tableId }),
    ).then((data) => {
      dispatch(
        displayMessage("success", data.message || "手当表を更新しました"),
      );
    });
  };
}

export function destroyDirectProductTable(tableId) {
  return (dispatch, getState) => {
    return dispatch(
      fetchAsync(Api.directProductTables.destroy, { id: tableId }),
    ).then((data) => {
      dispatch(
        displayMessage("success", data.message || "手当表を削除しました"),
      );
    });
  };
}

export function fetchCategories() {
  return async (dispatch) => {
    const { data } = await fetchAsyncAll(
      (params) =>
        Api.rootCategories
          .index(
            snakecaseKeys({
              enable: true,
              selectable: true,
              ...params,
            }),
          )
          .then((result) => {
            return { data: result.categories, count: result.count };
          }),
      {
        /* APIを投げる際の追加パラメータは特になし */
      },
      { limit: 200 },
    );
    dispatch(setCategories(data || null));
  };
}
