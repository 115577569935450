import PropTypes from 'prop-types';
import React from 'react';
import TabBar from 'components/TabBar';
import isNil from 'lodash/isNil';

/**
 * タブ付きでComponentを描画する
 * タブがクリックされると、描画するComponentが切り替わる
 * Activeになっていないタブは、描画しない
 */
const TabbedComponent = (props) => {
  const activeTab = props.tabs.find((x) => x.isActive);

  return (
    <div className='tab-component-container'>
      <TabBar tabs={ props.tabs } renderTab={ props.renderTab } onClickTab={ props.onClickTab } />
      <div className='tab-content'>
        <div className='tab-pane-active'>
          { isNil(activeTab) ? null : props.renderContents(activeTab) }
        </div>
      </div>
    </div>
  );
};

export default TabbedComponent;

TabbedComponent.defaultProps = {
  onClickTab(tab) {},
};

TabbedComponent.propTypes = {
  tabs: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.string.isRequired,
    isActive: PropTypes.boolean,
    url: PropTypes.string,
  })),
  onClickTab: PropTypes.func,
  renderContents: PropTypes.func.isRequired,
};
