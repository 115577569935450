import React from "react";
import styled from "styled-components";

const Wrapper = styled.div`
  position: absolute;
  top: 50%;
  right: 24px;
  font-size: 22px;
`;
interface IconTopProps {
  isActive: boolean;
}
const IconTop = styled.i<IconTopProps>`
  position: absolute;
  color: ${(p): string => (p.isActive ? "#1a7edc" : "#e4e3e3")};
  margin-top: -16px;
`;
const IconBottom = styled(IconTop)`
  margin-top: -4px;
`;

interface Props {
  /** ソートされているか */
  isSorted: boolean;
  /** ソートは昇順か降順か */
  isSortedDesc?: boolean;
}

/**
 * 青のソートのアイコン
 */
export const TableSortIconBlue: React.FC<Props> = (props: Props) => {
  const isSortUp = props.isSorted && !props.isSortedDesc;
  const isSortDown = props.isSorted && (props.isSortedDesc || false);
  return (
    <Wrapper className="table-component-sort-icon">
      <IconTop className="fas fa-caret-up" isActive={isSortUp} />
      <IconBottom className="fas fa-caret-down" isActive={isSortDown} />
    </Wrapper>
  );
};
