import React from 'react';
import styled from 'styled-components';

const PageLinksView = styled.div`
  margin-bottom: 32px;
  .link-message {
    display: block;
    font-weight: bold;
    text-align: center;
    color: #5590e9;
    font-size: 1.2rem;
    margin-bottom: 4px;
  }
`;

interface PageLink {
  href: string;
  name: string;
}
export interface Props {
  pageLinks: PageLink[];
}

export const PageLinks: React.FC<Props> = (props) => {
  return (
    <PageLinksView>
      { props.pageLinks.map((link) => {
        return (
          <a
            key={ link.name }
            className="link-message"
            href={ link.href }>
            { link.name }
          </a>
        );
      }) }
    </PageLinksView>
  );
};
