/* eslint-disable @typescript-eslint/explicit-function-return-type */
import Api from 'utilities/api';
import ApiPaymentRequests from 'utilities/api_payment_requests';
import S3UploaderV2 from 'utilities/s3_uploader';
import _omit from 'lodash/omit';
import set from 'lodash/set';
import { displayMessage, redirectTo } from 'actions/ActionCreators';
import { getMessageFromResponse, snakecaseKeys } from 'utilities/Utils';

const prefix = 'import';

export const SET_IMPORT_FILE = `${prefix}/SET_IMPORT_FILE`;
export function setImportFile(file) {
  return {
    type: SET_IMPORT_FILE,
    file,
  };
}

export const CLEAR_IMPORT_FILE = `${prefix}/CLEAR_IMPORT_FILE`;
export function clearImportFile() {
  return {
    type: CLEAR_IMPORT_FILE,
  };
}

export const SET_FILE_FORMAT = `${prefix}/SET_FILE_FORMAT`;
export function setFileFormat(format) {
  return {
    type: SET_FILE_FORMAT,
    format,
  };
}

export const TOGGLE_ALL_IMPORT_RESULTS = `${prefix}/TOGGLE_ALL_IMPORT_RESULTS`;
export function showAllImportResults() {
  return {
    type: TOGGLE_ALL_IMPORT_RESULTS,
    value: true,
  };
}

export function hideConfirmedImportResults() {
  return {
    type: TOGGLE_ALL_IMPORT_RESULTS,
    value: false,
  };
}

export function requestFileUpload(file, type, format, uploadUrlAPi = Api.importJobs.uploadUrl, importApi = undefined, corporateCardId = '', exportFormatId = '', dataSetId = undefined, memberImportType = undefined) {
  return async (dispatch) => {
    const form = snakecaseKeys({
      type,
      fileName: file.name,
      ext: file.name.split('.').pop(),
      corporateCardId,
      exportFormatId,
      memberImportTypes: memberImportType ? [memberImportType] : [],
    });
    const retryNumber = 2; // 暫定で2回リトライしてみる

    try {
      dispatch(hideFileDroparea());
      const data = await new S3UploaderV2().uploadImportFile(file, form, uploadUrlAPi, retryNumber);

      set(form, 'format', format);
      set(form, 'requestId', data.requestId);
      if (dataSetId) {
        set(form, 'dataSetId', dataSetId);
      }
      set(form, 'memberImportType', memberImportType);
      dispatch(requestImport(form, importApi));
      dispatch(showFileDroparea());
    } catch (e) {
      dispatch(displayMessage('error', getMessageFromResponse(e)));
    } finally {
      dispatch(showFileDroparea());
    }
  };
}

export function requestImport(form, importApi = Api.importJobs.create) {
  return async (dispatch) => {
    try {
      dispatch(clearImportFile());
      await importApi(snakecaseKeys(form));
    } catch (e) {
      dispatch(displayMessage('error', getMessageFromResponse(e)));
    }
  };
}

export function fetchImportJobs(importType, all, exportFormatId, targetId = '', dataSetId) {
  return async (dispatch) => {
    try {
      const params = { type: importType, exportFormatId };

      if (dataSetId) {
        set(params, 'dataSetId', dataSetId);
      }

      if (all) {
        set(params, 'all', all);
      }

      if (targetId !== '') {
        set(params, 'targetId', targetId);
      }

      const importJobApi = getJobApiData(importType, '', params);
      const data = await importJobApi.fetchImportJobs(snakecaseKeys(importJobApi.params));

      dispatch(setImportJobs(data.importJobs));
    } catch (e) {
      dispatch(displayMessage('error', getMessageFromResponse(e)));
    }
  };
}

export function checkQueue(importType, queued = null, empty = null) {
  return async (dispatch) => {
    try {
      const data = await Api.importJobs.checkQueue(snakecaseKeys({ type: importType }));
      dispatch(setQueueCounts(data));
      if (!!empty && Object.values(data).reduce((a, b) => a + b) === 0) {
        dispatch(empty());
      }
      if (!!queued && Object.values(data).reduce((a, b) => a + b) !== 0) {
        dispatch(queued());
      }
    } catch (e) {
      dispatch(displayMessage('error', getMessageFromResponse(e)));
    }
  };
}

export const TOGGLE_FILE_DROPAREA = `${prefix}/TOGGLE_FILE_DROPAREA`;
export function hideFileDroparea() {
  return {
    type: TOGGLE_FILE_DROPAREA,
    value: true,
  };
}

export function showFileDroparea() {
  return {
    type: TOGGLE_FILE_DROPAREA,
    value: false,
  };
}

export const SET_IMPORT_JOBS = `${prefix}/SET_IMPORT_JOBS`;
export function setImportJobs(importJobs) {
  return {
    type: SET_IMPORT_JOBS,
    importJobs,
  };
}

export const SET_QUEUE_COUNTS = `${prefix}/SET_QUEUE_COUNTS`;
export function setQueueCounts(counts) {
  return {
    type: SET_QUEUE_COUNTS,
    counts,
  };
}

export function downloadResult(id, type, exportFormatId = '') {
  return async (dispatch) => {
    try {
      const baseUrl = getJobApiData(type, id).downloadResultBaseUrl;
      if (exportFormatId === '') {
        dispatch(redirectTo(`${baseUrl}?type=${type}`));
      } else {
        dispatch(redirectTo(`${baseUrl}?type=${type}&export_format_id=${exportFormatId}`));
      }
    } catch (e) {
      dispatch(displayMessage('error', getMessageFromResponse(e)));
    }
  };
}

export function getJobApiData(type, id = '', params = {}) {
  const data = { fetchImportJobs: Api.importJobs.index, downloadResultBaseUrl: `/import_jobs/download_result/${id}`, params };

  switch (type) {
    case 'expense_category':
      data.fetchImportJobs = Api.categories.importJobs.index;
      data.params = _omit(params, 'type');

      data.downloadResultBaseUrl = `/categories/import_jobs/${id}/download_result`;
      break;
    case 'payment_requests_debit_invoice_category':
      data.fetchImportJobs = ApiPaymentRequests.debitInvoiceCategories.importJobs.index;
      data.params = _omit(params, 'type');

      data.downloadResultBaseUrl = `/payment_requests/debit_invoice_categories/import_jobs/${id}/download_result`;
      break;
    case 'payment_requests_credit_invoice_category':
      data.fetchImportJobs = ApiPaymentRequests.creditInvoiceCategories.importJobs.index;
      data.params = _omit(params, 'type');

      data.downloadResultBaseUrl = `/payment_requests/credit_invoice_categories/import_jobs/${id}/download_result`;
      break;
    case 'payment_requests_report_item':
      data.fetchImportJobs = ApiPaymentRequests.reportItemImportJobs.index;
      data.params = _omit(params, 'type');
      break;
    case 'generic_field_items':
      data.fetchImportJobs = Api.genericFields.dataSets.items.importJobs.index;
      data.params = _omit(params, 'type');

      data.downloadResultBaseUrl = `/generic_fields/items/import_jobs/${id}/download_result`;
      break;
    default:
  }

  return data;
}
