import React from 'react';
import i18next from 'i18n';
import styled from 'styled-components';
import { ErrorMessage } from './ErrorMessage';
import { FormErrors, FormValues, validatePasswordResetForm } from '../utilities/validatePasswordResetForm';
import { FormSubmitButton } from './FormSubmitButton';
import { maxPasswordLength, minPasswordLength } from '../utilities/validatePassword';
import { useHtmlForm } from 'hooks';

const ResetPasswordFormView = styled.div`
  margin-bottom: 16px;
  .form-group {
    margin-bottom: 12px;
    .form-description {
      font-size: 1.2rem;
      margin-bottom: 8px;
      text-align: center;
    }
    .form-control {
      display: block;
      border-radius: 10px;
      box-shadow: none;
      padding: 19px 16px;
      font-size: 1.3rem;
      &:focus {
        box-shadow: none;
      }
    }
  }
  .form-button {
    margin-top: 16px;
  }
`;

interface Props {
  onSubmit: (form: HTMLFormElement) => void;
  resetPasswordToken: string;
}

export const ResetPasswordForm: React.FC<Props> = (props) => {
  const {
    errors,
    formElm,
    isSubmitting,
    touched,
    values,
    handleValuesChange,
    handleClick,
  } = useHtmlForm<FormValues, FormErrors>(validatePasswordResetForm, { password: '', passwordConfirmation: '' },  props.onSubmit);
  const csrfTokenNode = document.querySelector('head meta[name="csrf-token"]') as HTMLMetaElement;
  const csrfParamNode = document.querySelector('head meta[name="csrf-param"]') as HTMLMetaElement;
  const csrfTokenValue = csrfTokenNode?.content || '';
  const csrfParamName = csrfParamNode?.content || '';

  return (
    <ResetPasswordFormView>
      <form
        id="edit_password"
        ref= { formElm }
        acceptCharset='UTF-8'
        method='post'
        action="/users/password" >
        <div className='form-group'>
          <p className="form-description">
            {  i18next.t('logins.pageDescriptions.resetPassword') } <br />
            {  i18next.t('logins.pageDescriptions.passwordCondition', { minPasswordLength, maxPasswordLength }) } <br />
            {  i18next.t('logins.pageDescriptions.passwordConditionOfCharTypes') }
          </p>
          <input
            type='password'
            name='user[password]'
            id='user_new_password'
            className='form-control'
            autoComplete='off'
            value={ values.password }
            placeholder={ i18next.t('logins.placeholders.password') }
            onChange={ (e): void => handleValuesChange({ ...values, password: e.target.value }) } />
          { touched && errors.password && <ErrorMessage message={ errors.password } /> }
        </div>
        <div className="form-group">
          <input
            type='password'
            name='user[password_confirmation]'
            id='user_new_password_confirmation'
            className='form-control'
            autoComplete='off'
            value={ values.passwordConfirmation }
            placeholder={ i18next.t('logins.placeholders.passwordConfirmation') }
            onChange={ (e): void => handleValuesChange({ ...values, passwordConfirmation: e.target.value }) } />
          { touched && errors.passwordConfirmation && <ErrorMessage message={ errors.passwordConfirmation } /> }
        </div>
        <div className="form-group hidden-area">
          <input
            type="hidden"
            name='_method'
            value='put' />
          <input
            name={ csrfParamName }
            type='hidden'
            value={ csrfTokenValue } />
          <input
            type="hidden"
            name="user[reset_password_token]"
            id="user_reset_password_token"
            value={ props.resetPasswordToken } />
        </div>
        <div className="form-button">
          <FormSubmitButton
            isSubmitting={ isSubmitting }
            onClick={ handleClick }>
            { i18next.t('logins.buttons.resetPassword') }
          </FormSubmitButton>
        </div>
      </form>
    </ResetPasswordFormView>
  );
};
