import { MemberImportType } from "applications/members/types";
import SimpleModal, { ButtonProps } from "components/SimpleModal";
import i18next from "i18n";
import isNil from "lodash/isNil";
import React, { useRef } from "react";
import { connect, ConnectedProps } from "react-redux";
import { Merge } from "types/UtilityTypes";
import { CreateRequest } from "utilities/api/requests/imports";
import * as actions from "../../actions/importForm";
import getImportForm from "../../selectors/importForm";
import Contents, { ContentsProps } from "./Contents";
import DropArea, { DropAreaElement, DropAreaProps } from "./DropArea";
import ExportTypeSelector, {
  ExportTypeSelectorProps,
} from "./ExportTypeSelector";
import ImportResults, { ImportResultsProps } from "./ImportResults";

interface UploadUrlRequestBase {
  ext: string; // MimeType
  fileName: string; // ファイル名称
  format: string; // フォーマット名称
  type: string; // インポートのタイプ名
}

interface ImportApiRequestBase extends UploadUrlRequestBase {
  requestId: string; // ファイルアップロードのイベントに対してサーバが発行したUUID
}

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
function mapStateToProps(state, ownProps) {
  const importForm = getImportForm()(state, ownProps);
  return {
    importFile: importForm.importFile,
    format: importForm.format,
    uploading: importForm.uploading,
  };
}

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
function mapDispatchToProps(dispatch) {
  return {
    onFileSelect(file): void {
      dispatch(actions.setImportFile(file));
    },
    onFileFormatSelect(format): void {
      dispatch(actions.setFileFormat(format));
    },
    requestFileUpload<
      T extends UploadUrlRequestBase,
      U extends ImportApiRequestBase,
    >(
      file: File,
      importType: string,
      format: string,
      uploadUrlApi?: (param: T) => Promise<unknown>,
      importApi?: (param: U) => Promise<unknown>,
      corporateCardId?: string,
      exportFormatId?: string,
      dataSetId?: string,
      memberImportType?: MemberImportType,
    ): void {
      dispatch(
        actions.requestFileUpload(
          file,
          importType,
          format,
          uploadUrlApi,
          importApi,
          corporateCardId,
          exportFormatId,
          dataSetId,
          memberImportType,
        ),
      );
    },
  };
}

const connector = connect(mapStateToProps, mapDispatchToProps);
type PropsFromRedux = ConnectedProps<typeof connector>;

interface ContainerProps<T, U>
  extends ExportTypeSelectorProps,
    Omit<DropAreaProps, "anchorRef">,
    ContentsProps,
    ImportResultsProps {
  /** フォーマットの選択ができるか否か
   * @todo 変数名がおかしいので修正すること
   */
  isValid?: boolean;
  show?: boolean;
  inProcess?: boolean;
  title: string;
  close: () => void;
  /** 法人カードを対象にしたインポート時のみ参照する */
  corporateCardId?: string;
  /** 仕訳フォーマットを対象にしたインポート時のみ参照する */
  exportFormatId?: string;
  /** ファイル自体のアップロード処理 */
  uploadUrlApi?: (param: T) => Promise<unknown>;
  /** ファイルに対するインポート時の処理 */
  importApi?: (param: U) => Promise<unknown>;
  dataSetId?: string;
  /** 従業員インポートを１シートずつ行う場合のみ参照する */
  memberImportType?: MemberImportType;
}

type Props<T, U> = Merge<PropsFromRedux, ContainerProps<T, U>>;

type ImportFormModalComponent<
  T extends UploadUrlRequestBase = CreateRequest,
  U extends ImportApiRequestBase = CreateRequest,
> = React.FC<Props<T, U>>;

const ImportFormModal: ImportFormModalComponent = ({
  isValid = false,
  show = false,
  inProcess = false,
  requestFileUpload,
  uploading,
  importFile,
  importType,
  format = "normal",
  corporateCardId,
  exportFormatId,
  onFileSelect,
  close,
  title,
  onFileFormatSelect,
  fileExtensions,
  renderContent,
  importApi,
  uploadUrlApi,
  children,
  dataSetId = undefined,
  memberImportType = undefined,
}) => {
  const dropAreaRef = useRef<DropAreaElement>(null);
  const handleSubmit = (): void => {
    if (isNil(importFile) || uploading) {
      return;
    }

    if (dropAreaRef && dropAreaRef.current) {
      dropAreaRef.current.clearFiles();
    }
    requestFileUpload(
      importFile,
      importType,
      format,
      uploadUrlApi,
      importApi,
      corporateCardId,
      exportFormatId,
      dataSetId,
      memberImportType,
    );
  };

  const buttons: ButtonProps[] = [
    {
      color: "default",
      content: i18next.t("commons.actions.cancel"),
      onClick: close,
    },
    {
      className: isNil(importFile) || inProcess ? "disabled" : "",
      color: "primary",
      content: i18next.t("commons.actions.import"),
      onClick: handleSubmit,
    },
  ];

  return (
    <SimpleModal
      dialogClassName="import-form-modal"
      title={title}
      show={show}
      close={close}
      buttons={buttons}
    >
      <form className="form-horizontal">
        {isValid ? (
          <ExportTypeSelector
            format={format}
            onFileFormatSelect={onFileFormatSelect}
            importType={importType}
          />
        ) : null}
        <DropArea
          uploading={uploading}
          anchorRef={dropAreaRef}
          importFile={importFile}
          fileExtensions={fileExtensions}
          onFileSelect={onFileSelect}
        />
        <Contents renderContent={renderContent}>{children}</Contents>
        <ImportResults
          importType={importType}
          exportFormatId={exportFormatId}
          dataSetId={dataSetId}
        />
      </form>
    </SimpleModal>
  );
};

export default connector(ImportFormModal);
