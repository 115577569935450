import isNil from 'lodash/isNil';

// ジョブが動いている場合はエクスポート不可と判定する
export function isExportDisabled(exportJobs) {
  if (isNil(exportJobs) || exportJobs.length === 0) {
    return false;
  }

  if (isNil(exportJobs[0].completedAt)) {
    return true;
  }

  return false;
}

export default {
  isExportDisabled,
};
