import React from 'react';
import styled from 'styled-components';
import { ApprovalStep, ApprovalStepsForReject, RequestStep } from 'utilities/api/models/ApprovalStepsForReject';

interface Props {
  steps: ApprovalStepsForReject;
  selectedStep: RequestStep | ApprovalStep;
  onSelectStep: (newStep: RequestStep | ApprovalStep) => void;
}

const SelectorView = styled.div`
  .step {
    .title {
      margin: 4px 2px;
      font-weight: bold;

      .option {
        font-weight: normal;
        font-size: 0.75rem;
        background: #dddddd;
        border-radius: 4px;
        padding: 4px 8px;
        margin-left: 8px;
      }
    }
    .group {
      border: 2px solid #f3f3f3;
      border-radius: 4px;
      box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.1);
      background: #ffffff;
      cursor: pointer;

      &:hover {
        border: 2px solid #47BBA6;
        box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.1);
      }
    }
    .group.selected {
      border: 2px solid #47BBA6;
      box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.1);
      background: #E3F5F2;
    }

    .subject {
      background: rgba(71, 187, 166, 0.15);
      border-radius: 4px;
      display: inline-block;
      padding: 4px 8px;
      margin: 8px;
    }
  }
  .border {
    display: inline-block;
    width: 8px;
    height: 70px;
    background: #e5e5e5;
    position: relative;
    left: calc(100% / 2 - 4px);
    margin-bottom: -40px;
    z-index: -1;
  }
`;

/**
 * 差戻先選択
 */
export const RejectToSelector: React.FC<Props> = ({
  steps,
  selectedStep,
  onSelectStep,
}) => {
  return (
    <SelectorView>
      <div className='step'>
        <div className='title'>申請者</div>
        <div className={ `group ${!('approvers' in selectedStep) ? 'selected' : ''}` } onClick={ (): void => onSelectStep(steps.requestStep) }>
          <div className='subject'>{ steps.requestStep.name }</div>
        </div>
      </div>
      { steps.approvalSteps.length > 0 && <div className='border'></div> }
      {
        steps.approvalSteps.map((step, index) => (
          <div key={ `step-${index}` }>
            <div className='step'>
              <div className='title'>{ step.name }<span className='option'>{ step.type === 'any' ? '誰か一人' : '全員' }</span></div>
              <div className={ `group ${step.id === (selectedStep as ApprovalStep).id ? 'selected' : ''}` } onClick={ (): void => onSelectStep(step) }>
                {
                  step.approvers.map((approver, aIndex) => (
                    <div className='subject' key={ `approver-${aIndex}` }>
                      { approver.type === 'group' && <i className='fa fa-users'/> }
                      { approver.name }
                    </div>
                  ))
                }
              </div>
            </div>
            { index < steps.approvalSteps.length - 1 && <div className='border'></div> }
          </div>
        ))
      }
    </SelectorView>
  );
};
