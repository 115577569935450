import TextField from "components/fields/TextField";
import SimpleModal, { ButtonProps } from "components/SimpleModal";
import i18next from "i18n";
import React, { useCallback, useEffect, useState } from "react";
import { connect, ConnectedProps } from "react-redux";
import { ThunkDispatch } from "tdispatch";
import preReportType from "types/pre_report";
import * as ActionCreators from "../actions/CancelTemporaryPayment/ActionCreators";
import * as AsyncActions from "../actions/CancelTemporaryPayment/AsyncActions";
import { ModalState } from "../actions/CancelTemporaryPayment/Types";
import { closePreReportRequestJob } from "../actions/preReportRequestActions";

/* eslint-disable @typescript-eslint/explicit-function-return-type */
const mapState = (state: {
  preReport: preReportType;
  cancelTemporaryPaymentModal: ModalState;
}) => ({
  id: state.preReport.id,
  show: state.cancelTemporaryPaymentModal.show,
});
/* eslint-enable @typescript-eslint/explicit-function-return-type */

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
const mapDispatch = (dispatch: ThunkDispatch) => ({
  closeModal(): void {
    dispatch(ActionCreators.closeModal());
    dispatch(closePreReportRequestJob());
  },
  cancelTemporaryPayment(id: string, comment: string): void {
    dispatch(AsyncActions.cancelTemporaryPayment(id, comment));
  },
});

const connector = connect(mapState, mapDispatch);
type Props = ConnectedProps<typeof connector>;

const CancelTemporaryPaymentModalContainer: React.FC<Props> = (props) => {
  const { id, show, closeModal, cancelTemporaryPayment } = props;

  const [comment, setComment] = useState("");
  const [disabled, setDisabled] = useState(true);

  const handleCommentChange = useCallback((aComment: string): void => {
    setComment(aComment);
    setDisabled(aComment.length === 0);
  }, []);

  const handleSubmit = (): void => {
    setDisabled(true);
    cancelTemporaryPayment(id, comment);
  };

  useEffect(() => {
    setComment("");
    setDisabled(true);
  }, [id, show]);

  const buttons: ButtonProps[] = [
    {
      color: "danger",
      className: "col-sm-6 col-sm-offset-3",
      content: (
        <span>
          <i className="fa fa-left fa-undo"></i>
          {i18next.t("commons.actions.cancelTemporaryPayment")}
        </span>
      ),
      disabled,
      onClick: handleSubmit,
    },
  ];

  return (
    <SimpleModal
      title={i18next.t("commons.actions.cancelTemporaryPayment")}
      show={show}
      close={closeModal}
      buttons={buttons}
    >
      <div className="form-horizontal">
        <div className="form-group">
          <div className="col-sm-6 col-sm-offset-3">
            <TextField
              value={comment}
              placeholder={i18next.t("commons.actions.enterComment")}
              onValueChange={handleCommentChange}
            />
          </div>
        </div>
      </div>
    </SimpleModal>
  );
};

export default connector(CancelTemporaryPaymentModalContainer);
