import React from 'react';

interface Props {
  title?: string;
}

/** ページヘッダー
 *  styleは./index.tsxで指定
 */
export const PageHeader: React.FC<Props> = ({ children, title }) => {
  return (
    <header className='page-header'>
      { title ? <div className="page-title">{ title }</div> : <></>}
      <div className="toolbar">
        {children}
      </div>
    </header>
  );
};
