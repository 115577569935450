import PropTypes from 'prop-types';
import React from 'react';
import _isEmpty from 'lodash/isEmpty';
import formatter from 'utilities/formatter';

function formatValue(logValue) {
  const { type, value } = logValue;

  switch (type) {
    case 'client': {
      return formatter.text(`${value.companionName} (${value.company})`);
    }
    case 'colleague': {
      return formatter.text(value.companionName);
    }
    case 'generic_fields_item': {
      return formatter.text(value.itemName);
    }
    default: {
      return formatter.text(null);
    }
  }
}

const InputDiffLog = (props) => {
  const baseClassName = 'input-diff-log';
  const className = props.className ? `${baseClassName} ${props.className}` : baseClassName;

  return (
    <div className={ className }>
      {
        _isEmpty(props.increment) ? null : props.increment.map((x, idx) => (
          <div key={ idx }>
            <i className='fa fa-plus color-primary' style={ { paddingRight: '4px' } } />
            { formatValue(x) }
          </div>
        ))
      }
      {
        _isEmpty(props.decrement) ? null : props.decrement.map((x, idx) => (
          <div key={ idx }>
            <i className='fa fa-minus color-danger' style={ { paddingRight: '4px' } }/>
            { formatValue(x) }
          </div>
        ))
      }
    </div>
  );
};

InputDiffLog.defaultProps = {
  increment: [],
  decrement: [],
};

InputDiffLog.propTypes = {
  className: PropTypes.string,
  fieldType: PropTypes.string.isRequired,
  propertyName: PropTypes.string,
  increment: PropTypes.array.isRequired,
  decrement: PropTypes.array.isRequired,
};

export default InputDiffLog;
