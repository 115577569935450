import isNil from 'lodash/isNil';
import { Dispatch } from 'redux';
import { RequestsSearchPreReportResponse } from 'utilities/api/responses/requests/searchResults/preReport';
import { RequestsSearchReportResponse } from 'utilities/api/responses/requests/searchResults/report';
import { RequestsSearchTabType } from 'utilities/api/responses/requests';

const prefix = `EXPORT_MODAL`;

interface ActionType {
  readonly type: string;
}

interface ActionToggleModal extends ActionType {
  readonly payload: {
    readonly showModal: boolean;
  };
}
export const TOGGLE_MODAL = `${prefix}/TOGGLE_MODAL`;
function toggleModal(showModal: boolean): ActionToggleModal {
  return {
    type: TOGGLE_MODAL,
    payload: { showModal },
  };
}

interface ActionSetReportId extends ActionType {
  readonly payload: {
    readonly requestType: string;
    readonly id: string;
  };
}
export const SET_REPORT_ID = `${prefix}/SET_REPORT_ID`;
function setReportId(requestType: string, reportId: string): ActionSetReportId {
  return {
    type: SET_REPORT_ID,
    payload: { requestType, id: reportId },
  };
}

interface ActionSetExportFormats extends ActionType {
  readonly payload: {
    readonly exportFormats: string[];
  };
}
export const SET_EXPORT_FORMATS = `${prefix}/SET_EXPORT_FORMATS`;
function setExportFormats(exportFormats: string[]): ActionSetExportFormats {
  return {
    type: SET_EXPORT_FORMATS,
    payload: { exportFormats },
  };
}

/**
 * フォーマットの配列を返す
 */
function getExportFormatsForReport(requestType: string, report: RequestsSearchPreReportResponse | RequestsSearchReportResponse = {} as RequestsSearchPreReportResponse | RequestsSearchReportResponse): string[] {
  switch (requestType) {
    case 'report': {
      return ['xls', 'csv'];
    }
    case 'pre_report': {
      // ['xls', 'temporaryPaymentXls', 'preXls', 'csv']の順になるように
      const formats: string[] = [];

      if (!isNil((report as RequestsSearchPreReportResponse).report)) {
        formats.push('xls');
      }

      if (report.needsTemporaryPayment && report.hasApproved) {
        formats.push('temporaryPaymentXls');
      }

      return formats.concat(['preXls', 'csv']);
    }
    default: {
      return [];
    }
  }
}

/**
 * レポート用にモーダルを開く
 */
export function openModalForReport(requestType: RequestsSearchTabType, report: RequestsSearchPreReportResponse | RequestsSearchReportResponse): (dispatch: Dispatch) => void {
  return (dispatch: Dispatch): void => {
    dispatch(setReportId(requestType, report.id));
    dispatch(setExportFormats(getExportFormatsForReport(requestType, report)));
    dispatch(openModal());
  };
}

/**
 * モーダルを閉じる
 */
export function closeModal(): ActionToggleModal {
  return toggleModal(false);
}

/**
 * モーダルを開く
 */
function openModal(): ActionToggleModal {
  return toggleModal(true);
}
