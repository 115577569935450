import BootstrapCard from 'components/BootstrapCard';
import React from 'react';
import i18next from 'i18n';
import { Alert, Col, Row } from 'react-bootstrap';

const NoLicensePage: React.FC = () => {
  return (<BootstrapCard title={ i18next.t('preferences.security.samlSetting.title') }>
    <Row>
      <Col md={ 12 }>
        <Alert bsStyle='info'>
          { i18next.t('preferences.security.samlSetting.noLicense')}
        </Alert>
      </Col>
    </Row>
  </BootstrapCard>
  );
};

export default NoLicensePage;
