import { ControlLabel } from "components/ControlLabel";
import PropTypes from "prop-types";
import React, { Component } from "react";
import { Button, Modal } from "react-bootstrap";

class MergeableTransactionsSearchModal extends Component {
  render() {
    const { show, onHide, option } = this.props;
    const ignoreTransactedAt = this.props.editOption.bind(
      null,
      "ignoreTransactedAt",
    );

    return (
      <Modal show={show} onHide={onHide}>
        <Modal.Header>統合可能な経費の検索</Modal.Header>
        <Modal.Body>
          <div>
            <ControlLabel
              className="col-sm-12"
              placement="right"
              description="入力された経費または入力した経費（１件）とカード連携された経費（１件）が統合対象です"
            >
              統合可能な経費の組合せを検索します。
            </ControlLabel>
            <br />
            <h4 className="col-sm-12">検索オプション</h4>
            <div className="col-sm-1">
              <label>
                <input
                  type="checkbox"
                  checked={option.ignoreTransactedAt}
                  onChange={() =>
                    ignoreTransactedAt(!option.ignoreTransactedAt)
                  }
                />
              </label>
            </div>
            <div>利用日の違いを無視して組み合わせを検索する</div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <div>
            <Button bsStyle="default" onClick={this.props.onHide}>
              キャンセル
            </Button>
            <button
              className="btn btn-accent"
              onClick={() => this.props.search()}
            >
              検索
            </button>
          </div>
        </Modal.Footer>
      </Modal>
    );
  }
}

MergeableTransactionsSearchModal.propTypes = {
  show: PropTypes.bool.isRequired,
  onHide: PropTypes.func.isRequired,
  option: PropTypes.object.isRequired,
  search: PropTypes.func.isRequired,
};

export default MergeableTransactionsSearchModal;
