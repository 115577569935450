import { PDFPageProxy } from "pdfjs-dist";
import { RefObject, useCallback } from "react";
import { PDFPageProxys, WrapperSize } from "./interface";

interface UseReturn {
  readonly resetPDFArea: (
    pdfArea: HTMLDivElement | null,
    canvasAreaRef: RefObject<HTMLDivElement>,
  ) => HTMLDivElement;
  readonly createPDFPage: (
    pageNum: number,
    area: HTMLDivElement,
    pageProxy: PDFPageProxy,
  ) => PDFPageProxys;
  readonly updateCanvasArea: (
    info: PDFPageProxys,
    pdfWidth: number,
    pdfHeight: number,
    canvasContext: CanvasRenderingContext2D,
  ) => HTMLDivElement;
  readonly getCanvasStyle: (
    pageInfo: PDFPageProxys,
    pdfWidth: number,
    pdfHeight: number,
    size: WrapperSize,
  ) => HTMLCanvasElement;
}

/**
 * PDFビューアー: domロジック
 */
export const usePDFdomHooks = (): UseReturn => {
  /**
   * PDF表示欄の削除
   */
  const removePDFArea = (pdfArea: HTMLDivElement | null): void => {
    if (!pdfArea) return;
    pdfArea.remove();
  };

  /**
   * PDF表示欄の追加
   */
  const addPDFArea = (
    canvasAreaRef: RefObject<HTMLDivElement>,
  ): HTMLDivElement => {
    const area = document.createElement("div");
    area.id = "pdf-area";
    canvasAreaRef.current?.appendChild(area);
    return area;
  };

  /**
   * PDF表示欄のリセット
   */
  const resetPDFArea = useCallback(
    (
      pdfArea: HTMLDivElement | null,
      canvasAreaRef: RefObject<HTMLDivElement>,
    ): HTMLDivElement => {
      // Divのリセット
      removePDFArea(pdfArea);
      // 描画させるDivの追加
      return addPDFArea(canvasAreaRef);
    },
    [],
  );

  /**
   * PDFページのdom追加
   */
  const createPDFPage = useCallback(
    (
      pageNum: number,
      area: HTMLDivElement,
      pageProxy: PDFPageProxy,
    ): PDFPageProxys => {
      // テキストレイヤーとキャンバスの親
      const canvasWrapper = document.createElement("div");
      canvasWrapper.className = `pdf-page-${pageNum}`;
      canvasWrapper.style.margin = "auto";
      canvasWrapper.style.position = "relative";
      canvasWrapper.style.paddingBottom = "5px";
      canvasWrapper.style.boxSizing = "content-box";

      // テキストレイヤーとキャンバスの親
      const pageInnerScale = document.createElement("div");
      pageInnerScale.className = `pageInnerScale`;
      pageInnerScale.style.position = "absolute";
      canvasWrapper.appendChild(pageInnerScale);

      // テキストレイヤーとキャンバスの親
      const pageInner = document.createElement("div");
      pageInner.className = `pageInner`;
      pageInner.style.position = "absolute";
      pageInnerScale.appendChild(pageInner);

      // テキストレイヤー
      // 透明文字をキャンバスの上に重ねることによってテキストの選択ができるようになる
      const textLayer = document.createElement("div");
      textLayer.style.position = "absolute";
      textLayer.style.width = "100%";
      textLayer.style.height = "100%";
      textLayer.style.transformOrigin = "left top";
      pageInner.appendChild(textLayer);

      // 親要素に追加
      area.appendChild(canvasWrapper);

      // キャンバス
      const canvas = document.createElement("canvas");
      pageInner.appendChild(canvas);

      return {
        canvas,
        textLayer,
        canvasWrapper,
        pageInner,
        pageInnerScale,
        pageProxy,
      };
    },
    [],
  );

  /**
   * キャンバスのスタイルを設定
   */
  const getCanvasStyle = useCallback(
    (
      pageInfo: PDFPageProxys,
      pdfWidth: number,
      pdfHeight: number,
      size: WrapperSize,
    ): HTMLCanvasElement => {
      const canvas = pageInfo.canvas;

      // キャンバスのCSS縦横サイズを設定
      canvas.style.width = `${size.width}px`;
      canvas.style.height = `${size.height}px`;
      // キャンバスのサイズ
      canvas.width = pdfWidth;
      canvas.height = pdfHeight;

      // キャンバスのcssスタイルの設定
      canvas.style.boxShadow = "0 0 8px 0px #2d2e34b5";
      canvas.style.display = "block";
      canvas.style.margin = "auto";

      return canvas;
    },
    [],
  );

  /**
   * キャンバスの描画
   */
  const updateCanvasArea = useCallback(
    (
      info: PDFPageProxys,
      pdfWidth: number,
      pdfHeight: number,
      canvasContext: CanvasRenderingContext2D,
    ): HTMLDivElement => {
      const textLayerDiv = info.textLayer;
      const canvasWrapper = info.canvasWrapper;

      // テキストレイヤーの子要素の削除
      const layerList = textLayerDiv.children;
      for (let i = 0; i < layerList.length; i++) {
        const layer = layerList[i] as unknown as Node;
        textLayerDiv.removeChild(layer);
      }
      // テキストレイヤーの子要素の追加
      const textLayerInner = document.createElement("div");
      textLayerInner.className = "textLayer";
      textLayerDiv.appendChild(textLayerInner);

      // キャンバスの枠の縦幅と横幅を設定
      canvasWrapper.style.width = `${canvasContext.canvas.clientWidth}px`;
      canvasWrapper.style.height = `${canvasContext.canvas.clientHeight}px`;

      // テキストレイヤーのサイズを更新
      textLayerDiv.style.width = `${pdfWidth}px`;
      textLayerDiv.style.height = `${pdfHeight}px`;

      // テキストレイヤーの拡大率を、キャンバスサイズに合わせて設定する
      const textLayerScale = canvasContext.canvas.clientWidth / pdfWidth;
      textLayerDiv.style.transform = `scale(${textLayerScale})`;

      return textLayerInner;
    },
    [],
  );

  return {
    resetPDFArea,
    createPDFPage,
    updateCanvasArea,
    getCanvasStyle,
  };
};
