import React, { FC } from "react";
import styled from "styled-components";

const Wrapper = styled.div`
  display: grid;
  justify-content: center;
  justify-items: center;
  align-content: center;
  align-items: center;
  text-align: center;
  background-color: #cfcfcf;
  gap: 8px;
`;
const FileIcon = styled.i`
  color: #777777;
  size: 82px;
`;
const DownloadLink = styled.a`
  color: #777777;
  font-size: 14px;
  display: grid;
  grid-template-columns: 1fr minmax(0, auto);
  align-items: baseline;
`;
const FileName = styled.div`
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;
const Icon = styled.i`
  color: #777777;
`;

interface Props {
  readonly url?: string;
  readonly filename?: string;
}

/**
 * ダウンロードリンク
 */
export const OtherViewer: FC<Props> = (p) => (
  <Wrapper>
    <FileIcon className="far fa-file fa-fw fa-8x" />
    <DownloadLink href={p.url} download={p.filename}>
      <FileName>{p.filename}</FileName>
      <Icon className="fas fa-download fa-fw" />
    </DownloadLink>
  </Wrapper>
);
