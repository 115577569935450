import React, { Component } from "react";
import AccountList from "./account_list";
import * as listActions from "./account_list_actions";

export default class AccountListCard extends Component {
  constructor(props) {
    super(props);

    this.state = {
      accounts: [],
      activeTab: 0,
    };

    this.registerActions(listActions);
  }

  componentDidMount() {
    this.actions
      .fetchAccountList()
      .done(this.actions.onFetchAccountListDone.bind(this))
      .fail(this.actions.onFetchAccountListError.bind(this));
  }

  registerActions(actions) {
    const cardActions = {};
    Object.keys(actions).forEach((key) => {
      if (typeof actions[key] === "function") {
        cardActions[key] = actions[key].bind(this);
      } else {
        cardActions[key] = actions[key];
      }
    });
    this.actions = cardActions;
  }

  render() {
    return (
      <div className="card">
        <div className="card-header">
          <div className="card-title inline">
            連携カード・サービスを登録する
          </div>
        </div>
        <div className="card-content">
          <AccountList
            groups={this.state.accounts}
            onClickTab={this.actions.onClickTab.bind(this)}
            actions={this.actions}
          />
        </div>
      </div>
    );
  }
}
