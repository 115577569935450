import { useExportedCsvHooks } from "hooks/useExportedCsvHooks";
import Api from "utilities/api";
import { ExportedCsvResponse } from "utilities/api/responses/expenses/Reports/ExportedCsvsResponse";

interface UseReturn {
  readonly formData: ExportedCsvResponse[];
}

export const useHooks = (): UseReturn => {
  const data = useExportedCsvHooks(Api.expenses.reports.exportedCsvs);

  return { formData: data?.exportedCsvs || [] };
};
