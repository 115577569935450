import i18next from "i18n";
import React, { useState } from "react";
import styled from "styled-components";

const WrapperOuter = styled.div`
  height: 100%;
  font-size: 10px;
`;
const Wrapper = styled.div`
  background: #0000003b;
  height: 100%;
  box-shadow: 0 0 10px #00000052;
  cursor: pointer;
  padding: 0 5px;
`;
const Innner = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  text-align: center;
`;
const Annotation = styled.div`
  position: absolute;
  z-index: 10;
  padding: 5px;
  background: white;
  color: #333333;
  border-radius: 2px;
  box-shadow: 0 0 10px #000000ad;
  right: 10px;
`;

interface Props {
  readonly onClick: (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => void;
}

/**
 * PDFビューアーツールバー: ビューアー切り替えボタン
 */
const ViewerChangeButton: React.FC<Props> = (p) => {
  const [isShowAnnotation, setIsShowAnnotation] = useState<boolean>(false);

  /**
   * ホバーした
   */
  const onMouseEnter = (): void => {
    setIsShowAnnotation(true);
  };

  /**
   * ホバーを外れた
   */
  const onMouseLeave = (): void => {
    setIsShowAnnotation(false);
  };

  return (
    <WrapperOuter className="viewer-browser-pdf-change-button-wrapper-outer">
      <Wrapper
        className="viewer-browser-pdf-change-button-wrapper"
        onClick={p.onClick}
        onMouseEnter={onMouseEnter}
        onMouseLeave={onMouseLeave}
      >
        <Innner className="viewer-browser-pdf-change-button-innner">
          <div>
            <i className="fas fa-file" />
            <br />
            <i className="fas fa-exchange-alt" />
          </div>
        </Innner>
      </Wrapper>

      {isShowAnnotation && (
        <Annotation className="viewer-browser-pdf-change-button-annotation">
          {i18next.t("viewer.changeViewer")}
        </Annotation>
      )}
    </WrapperOuter>
  );
};

export default ViewerChangeButton;
