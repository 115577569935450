'user strict';

import * as ActionTypes from '../actions/taxCategorySelections';

const initialState = {
  items: [],
  errors: [],
};

const taxCategorySelections = (state = initialState, action) => {
  switch (action.type) {
    case ActionTypes.SET_TAX_CATEGORY_SELECTIONS:
      return {
        ...state,
        items: action.payload.taxCategorySelections,
      };
    case ActionTypes.SET_TAX_CATEGORY_SELECTIONS_ERRORS:
      return {
        ...state,
        errors: action.payload.errors,
      };
    default:
      return state;
  }
};

export default taxCategorySelections;
