import RadioGroup from "components/fields/RadioGroup";
import LabeledComponent from "components/LabeledComponent";
import i18next from "i18n";
import PropTypes from "prop-types";
import React from "react";

const PaidByCorporateCardCondition = (props) => {
  return (
    <LabeledComponent
      label={i18next.t(
        "transactions.index.searchbox.paidByCorporateCard.label",
      )}
      description={i18next.t(
        "transactions.index.searchbox.paidByCorporateCard.description",
      )}
    >
      <RadioGroup
        isClearable={true}
        value={props.value}
        radios={[
          {
            value: true,
            label: i18next.t(
              "transactions.index.searchbox.paidByCorporateCard.yes",
            ),
          },
          {
            value: false,
            label: i18next.t(
              "transactions.index.searchbox.paidByCorporateCard.no",
            ),
          },
        ]}
        onChange={props.onChange}
      />
    </LabeledComponent>
  );
};

PaidByCorporateCardCondition.defaultProps = {
  value: null,
};

PaidByCorporateCardCondition.propTypes = {
  value: PropTypes.bool,
  onChange: PropTypes.func.isRequired,
};

export default PaidByCorporateCardCondition;
