import NumberField from 'components/fields/NumberField';
import React, { useEffect } from 'react';
import Select from 'react-select';
import i18next from 'i18n';
import moment from 'moment';
import styled from 'styled-components';
import {
  ControlLabel, FormControl, FormGroup, HelpBlock,
} from 'react-bootstrap';
import { Controller, useFormContext } from 'react-hook-form';
import { RequiredIcon } from '../../../../components/icons/RequiredIcon';

const FormGroupView = styled(FormGroup)`
  .form-control:focus {
    outline: none;
    box-shadow: none;
    border: 2px solid #004acc;
    background-color: #ffffff;
  }
  &.has-success div[class$="-control"] {
    border-color: #399d46;
    box-shadow: none;
  }
  &.has-success .control-label {
    color: #399d46;
  }
  &.has-error div[class$="-control"] {
    border-color: #d9534f;
    box-shadow: none;
  }
  &.has-error .control-label {
    color: #d9534f;
  }
  &.has-error .help-block {
    color: #d9534f;
  }
  .has-error-input-number {
    border: 2px solid #d9534f !important;
  }
`;

interface Props {
  assigneeIndex: number;
}

interface Option {
  value: string | number;
  label: string;
}

const paymentSpecificationTypes: Option[] = [
  { value: 'execution_date', label: '実⾏⽇' },
  { value: 'specify_date', label: '⽇付指定' },
];

const paymentRelativeSpecificationTypes: Option[] = [
  { value: 'after_day', label: '⽇後' },
  { value: 'before_day', label: '⽇前' },
  { value: 'after_business_day', label: '営業⽇後' },
  { value: 'before_business_day', label: '営業⽇前' },
];

const currentDate = new Date();

/**
 * 全銀データ出⼒ ⽀払指定⽇ 入力フィールド
 */
export const PaymentSpecificationSelectField: React.FC<Props> = ({
  assigneeIndex,
}) => {
  const {
    control,
    watch,
    setValue,
    clearErrors,
    getValues,
  } = useFormContext();

  const paymentSpecificationType = watch(`companyExpenseAccountAssignees[${assigneeIndex}].paymentSpecificationType`);

  useEffect(() => {
    // [実行日][日付指定] の選択肢に変更がない場合、何もしない。
    if (paymentSpecificationType === getValues(`companyExpenseAccountAssignees[${assigneeIndex}].paymentSpecificationType`)) {
      return;
    }

    clearErrors(`companyExpenseAccountAssignees[${assigneeIndex}].paymentRelativeSpecificationDays`);
    clearErrors(`companyExpenseAccountAssignees[${assigneeIndex}].paymentSpecifiedDate`);
    switch (paymentSpecificationType) {
      case 'execution_date':
        setValue(`companyExpenseAccountAssignees[${assigneeIndex}].paymentRelativeSpecificationType`, 'after_day');
        setValue(`companyExpenseAccountAssignees[${assigneeIndex}].paymentRelativeSpecificationDays`, 1);
        setValue(`companyExpenseAccountAssignees[${assigneeIndex}].paymentSpecifiedDate`, null);
        break;
      case 'specify_date':
        setValue(`companyExpenseAccountAssignees[${assigneeIndex}].paymentRelativeSpecificationType`, null);
        setValue(`companyExpenseAccountAssignees[${assigneeIndex}].paymentRelativeSpecificationDays`, null);
        setValue(`companyExpenseAccountAssignees[${assigneeIndex}].paymentSpecifiedDate`, moment(currentDate).format('YYYY-MM-DD'));
        break;
      default:
        break;
    }
  }, [paymentSpecificationType, assigneeIndex, setValue, clearErrors]);

  return (
    <FormGroupView>
      <ControlLabel>{ <>{ i18next.t('accountingDataScheduledExports.tasks.forms.export.companyExpenseAccount.paymentSpecifiedTitle') } <RequiredIcon /></> }</ControlLabel>
      <div style={ { display: 'flex', gap: '5px', alignItems: 'center' } }>
        <Controller
          name={ `companyExpenseAccountAssignees[${assigneeIndex}].paymentSpecificationType` }
          rules={ { required: i18next.t<string>('accountingDataScheduledExports.tasks.forms.export.companyExpenseAccount.paymentSpecifiedRequired') } }
          control={ control }
          render={ ({ field: { onChange, value, ref: inputRef } }): React.ReactElement => (
            <div style={ { width: '120px' } }>
              <Select
                inputRef={ inputRef }
                options={ paymentSpecificationTypes }
                value={ paymentSpecificationTypes?.find((c) => c.value === value) }
                getOptionLabel={ (v: Option): string => v.label }
                getOptionValue={ (v: Option): string | number => v.value }
                onChange={ (v: Option): void => onChange(v?.value) }
                isSearchable={ false }
              />
            </div>
          ) }
        />
        {
          paymentSpecificationType === 'execution_date' && (
            <>
              <span>の</span>
              <Controller
                name={ `companyExpenseAccountAssignees[${assigneeIndex}].paymentRelativeSpecificationDays` }
                control={ control }
                rules={ { required: i18next.t<string>('accountingDataScheduledExports.tasks.forms.export.companyExpenseAccount.inputPaymentSpecifiedRequired') } }
                render={ ({ field: { onChange, value }, fieldState: { error } }): React.ReactElement => (
                  <div style={ { width: '80px' } }>
                    <NumberField
                      value={ value }
                      className={ `form-control ${error ? 'has-error-input-number' : ''}` }
                      allowMinus={ false }
                      allowDecimal={ false }
                      allowZeroPadding={ true }
                      onChange={ (v: Option): void => onChange(v) }
                    />
                    { error && <HelpBlock style={ { color: '#d9534f', position: 'absolute' } }>{error.message}</HelpBlock> }
                  </div>
                ) }
              />
              <Controller
                name={ `companyExpenseAccountAssignees[${assigneeIndex}].paymentRelativeSpecificationType` }
                control={ control }
                render={ ({ field: { onChange, value, ref: inputRef } }): React.ReactElement => (
                  <div style={ { width: '120px' } }>
                    <Select
                      inputRef={ inputRef }
                      options={ paymentRelativeSpecificationTypes }
                      value={ paymentRelativeSpecificationTypes?.find((c) => c.value === value) }
                      getOptionLabel={ (v: Option): string => v.label }
                      getOptionValue={ (v: Option): string | number => v.value }
                      onChange={ (v: Option): void => onChange(v?.value) }
                      isSearchable={ false }
                    />
                  </div>
                ) }
              />
            </>
          )
        }
        {
          paymentSpecificationType === 'specify_date' && (
            <Controller
              name={ `companyExpenseAccountAssignees[${assigneeIndex}].paymentSpecifiedDate` }
              control={ control }
              rules={ { required: i18next.t<string>('accountingDataScheduledExports.tasks.forms.export.companyExpenseAccount.inputPaymentSpecifiedRequired') } }
              render={ ({ field: { onChange, value }, fieldState: { error } }): React.ReactElement => (
                <div style={ { width: '150px' } }>
                  <FormControl
                    style={ { border: error ? '2px solid #d9534f' : '' } }
                    type='date'
                    value={ value }
                    onChange={ onChange }
                  />
                  { error && <HelpBlock style={ { color: '#d9534f', position: 'absolute' } }>{error.message}</HelpBlock> }
                </div>
              ) }
            />
          )
        }
      </div>
    </FormGroupView>
  );
};
