import PropTypes from 'prop-types';


const companionType = {
  id:          PropTypes.string.isRequired,
  name:        PropTypes.string.isRequired,
  nameYomi:    PropTypes.string.isRequired,
  company:     PropTypes.string,
  companyYomi: PropTypes.string,
  isClient:    PropTypes.bool.isRequired,
  department:  PropTypes.string,
  post:        PropTypes.string,
};

export default companionType;
