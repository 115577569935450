import ApiPaymentRequests from 'utilities/api_payment_requests';
import InfiniteSelector from 'components/fields/InfiniteSelector';
import React, {
  FC, useCallback, useEffect, useState,
} from 'react';
import SearchBox from 'components/SearchBox';
import SearchResult from 'components/SearchResult';
import i18next from 'i18n';
import styled from 'styled-components';
import { IndexRequest } from 'utilities/api_payment_requests/requests/suppliers';
import { ListForReportResponse } from 'utilities/api_payment_requests/responses/suppliers';
import { Supplier } from 'utilities/api_payment_requests/models';
import { Supplier as SupplierOption } from 'utilities/api/models/JournalEntries/Supplier';
import { snakecaseKeys } from 'utilities/Utils';

interface Props {
  /** 複数選択か */
  isMultiSelectable: boolean;
  /** 値 */
  value: null | SupplierOption | SupplierOption[];
  /** 選択時のハンドラ */
  onSelect: (value: SupplierOption | SupplierOption[]) => void;
}

const SearchFormView = styled.div`
  .search-result {
    margin-top: 16px;
  }
  .infinite-selector {
    margin-top: 8px;
  }
`;

// type guard function
function isSupplier(supplier: Supplier | SupplierOption): supplier is Supplier {
  return 'id' in supplier && 'paymentMethod' in supplier;
}

function buildOption(s: Supplier): SupplierOption {
  return { id: s.id, name: s.paymentMethod?.supplierName || '' };
}

/** Supplier型をInfiniteSelectorで利用可能なSupplierOption型の配列に変換
 *  @param suppliers: fetchした取引先一覧
 *  @param value: 選択値
 *  @return InfiniteSelectorで利用可能な取引先一覧
 */
function buildOptions(
  suppliers: Supplier[],
  value: null | SupplierOption | SupplierOption[],
): SupplierOption[] {
  const options = suppliers.map((s) => { return buildOption(s); });

  if (value instanceof Array) return [...options, ...value];

  if (value === null) return options;

  return [...options, value];
}

/** 取引先選択用コンポーネント */
export const SupplierSelector: FC<Props> = ({ value, isMultiSelectable, onSelect }) => {
  const [totalCount, setTotalCount] = useState<number | null>(null);
  const [searchText, setSearchText] = useState('');
  const [options, setOptions] = useState<null | SupplierOption | SupplierOption[]>([]);

  /** 取引先一覧取得 */
  const fetchSuppliers = useCallback(async (text: string, offset, limit = 10): Promise<ListForReportResponse> => {
    const params: IndexRequest = {
      limit,
      offset,
      supplierCodeName: text,
      blockingStatuses: [false], // 未凍結のみ
      sort: [{ key: 'name', order: 'asc' }],
    };

    return await ApiPaymentRequests.suppliers.listForReport(snakecaseKeys(params));
  }, []);

  /** 項目表示のView */
  const formatOption = useCallback((option?: SupplierOption): JSX.Element | null => {
    if (!option) return null;

    return (
      <div className='list-selector-item-content'>
        <div>{ option.name }</div>
      </div>
    );
  }, []);

  /** 選択肢変更時のハンドラ
      初回20件取得時にレスポンスをbuildOptionsで<SupplierOption>に変換してsetOptionsしており、
      スクロール時にfetchした結果は<Supplier>であるためselectedOptionsは<(Supplier | SupplierOption)[]>
      となってしまう
  */
  const handleOptionsChange = useCallback((selectedOptions: (Supplier | SupplierOption)[], count: null | number): void => {
    setTotalCount(count);
    const newOptions = selectedOptions.map((item) => {
      if (isSupplier(item)) return buildOption(item);
      return item;
    });

    setOptions(newOptions);
  }, []);

  /** 検索 */
  const handleSearch = useCallback(async (offset: number, limit: number): Promise<ListForReportResponse> => {
    return await fetchSuppliers(searchText, offset, limit);
  }, [fetchSuppliers, searchText]);

  useEffect(() => {
    let isSubscribed = true;
    const fetch = async (): Promise<void> => {
      const res = await fetchSuppliers('', 0, 20);
      setTotalCount(res.count);
      setOptions(buildOptions(res.data, value));
    };
    if (isSubscribed) fetch();
    return (): void => { isSubscribed = false; };
  }, [fetchSuppliers, value]);

  /** 選択時のハンドラ。
   *  SupplierOptionを受け取り、単一選択ならそのままonSelectに渡し、複数選択なら配列にして渡す */
  const handleSelect = useCallback((selectedOption: SupplierOption): void => {
    // 複数選択の場合
    if (isMultiSelectable && value instanceof Array) {
      let selectedOptions: SupplierOption[];
      const ids = value.map((v) => v.id);
      if (!ids.includes(selectedOption.id)) {
        selectedOptions = [...value, selectedOption];
      } else {
        selectedOptions = value.filter((v) => v.id !== selectedOption.id);
      }
      onSelect(selectedOptions);
      return;
    }

    // 単一選択の場合
    onSelect(selectedOption);
  }, [isMultiSelectable, onSelect, value]);

  return (
    <InfiniteSelector
      isMultiSelectable={ isMultiSelectable }
      options={ options }
      selection={ value }
      totalCount={ totalCount }
      rowHeight={ 42 }
      formatOption={ formatOption }
      fetchOptions={ handleSearch }
      onSelect={ handleSelect }
      onOptionsChange={ handleOptionsChange }
    >
      {(renderSelector, { onOptionsReset }): JSX.Element => {
        return (
          <SearchFormView>
            <SearchBox
              value={ searchText }
              placeholder={ i18next.t('paymentRequests.suppliers.actions.searchPlaceholder') }
              onChange={ setSearchText }
              onClick={ onOptionsReset }
            />
            <SearchResult count={ totalCount }>
              { renderSelector() }
            </SearchResult>
          </SearchFormView>
        );
      }}
    </InfiniteSelector>
  );
};
