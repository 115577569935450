import * as DepartmentPostRelationshipSelectorActionCreators from '../actions/DepartmentPostRelationshipSelector/ActionCreators';
import * as SelectedApproversListActionCreators from '../actions/ApprovalFormSelectedApproversList/ActionCreators';
import * as actions from '../actions/flowFormActions';
import * as approversSearchActions from '../actions/approversSearchActions';
import AddApproverModal from '../components/AddApproverModal';
import LabeledComponent from 'components/LabeledComponent';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import i18next from 'i18n';
import makeApproverFormStateMapper from '../selectors/approverForm';
import { connect } from 'react-redux';

export class ApproverForm extends Component {
  constructor(props) {
    super(props);

    this.handleChangeEditable = this.handleChangeEditable.bind(this);
    this.handleChangeSkippable = this.handleChangeSkippable.bind(this);
    this.handleChangeCondition = this.handleChangeCondition.bind(this);
    this.handleChangeSelfApprovable = this.handleChangeSelfApprovable.bind(this);
    this.handleChangeSelfApprovalSkippable = this.handleChangeSelfApprovalSkippable.bind(this);
    this.handleSelectApprover = this.handleSelectApprover.bind(this);
    this.handleRemoveSelectedApproverClick = this.handleRemoveSelectedApproverClick.bind(this);
    this.handleSelectDepartmentPostRelationship = this.handleSelectDepartmentPostRelationship.bind(this);
    this.handleDeselectDepartmentPostRelationship = this.handleDeselectDepartmentPostRelationship.bind(this);
    this.renderApprovalInput = this.renderApprovalInput.bind(this);
    this.renderApprovalInputForApprovalFlow = this.renderApprovalInputForApprovalFlow.bind(this);
    this.renderApprovalInputForApproverSelectFlow = this.renderApprovalInputForApproverSelectFlow.bind(this);
    this.renderIcons = this.renderIcons.bind(this);
    this.renderConditionInput = this.renderConditionInput.bind(this);
    this.renderConditionInputForApprovalFlow = this.renderConditionInputForApprovalFlow.bind(this);
    this.renderConditionInputForApproverSelectFlow = this.renderConditionInputForApproverSelectFlow.bind(this);
    this.renderDeleteButton = this.renderDeleteButton.bind(this);
    this.renderDeleteButtonForApprovalFlow = this.renderDeleteButtonForApprovalFlow.bind(this);
    this.renderDeleteButtonForApproverSelectFlow = this.renderDeleteButtonForApproverSelectFlow.bind(this);
  }

  handleChangeEditable(index, e) {
    this.props.onChangeEditable(this.props.approvals, index, !e.target.checked);
  }

  handleChangeSkippable(index, e) {
    this.props.onChangeSkippable(this.props.approvals, index, !e.target.checked);
  }

  handleChangeCondition(index, value) {
    this.props.onChangeCondition(this.props.approvals, index, value);
  }

  handleChangeSelfApprovable(index, e) {
    this.props.onChangeSelfApprovable(this.props.approvals, index, e.target.checked, this.props.isApproverSelectFlow);
  }

  handleChangeSelfApprovalSkippable(index, e) {
    this.props.onChangeSelfApprovalSkippable(this.props.approvals, index, e.target.checked);
  }

  handleSelectApprover(approverType, approvers, approverId, checked) {
    this.props.checkApprover(approverType, approvers, approverId, checked);
    const typesByApproverType = {
      'users': 'user',
      'groups': 'group',
      'projectPosts': 'project_post',
      'userDepartmentPosts': 'company_post',
      'payerDepartmentPosts': 'payer_department_post',
    };
    const fromApprover = approvers.find((a) => a.id === approverId);
    const approver = {
      id: fromApprover.id,
      postId: null,
      type: typesByApproverType[approverType],
      name: fromApprover.name,
    };
    this.switchSelectedApprover(approver, checked);
  }

  handleSelectDepartmentPostRelationship(departmentPostRelationship) {
    this.props.onSelectDepartmentPostRelationship(departmentPostRelationship);
    this.props.selectApprover(departmentPostRelationship);
  }

  handleDeselectDepartmentPostRelationship(departmentPostRelationship) {
    this.props.onDeselectDepartmentPostRelationship(departmentPostRelationship);
    this.props.deselectApprover(departmentPostRelationship);
  }

  handleRemoveSelectedApproverClick(approver) {
    this.props.deselectApprover(approver);
    if (approver.type === 'department_post_relationship') {
      this.props.onDeselectDepartmentPostRelationship(approver);
    } else {
      const approverTypesByType = {
        'user': 'users',
        'group': 'groups',
        'project_post': 'projectPosts',
        'company_post': 'userDepartmentPosts',
        'payer_department_post': 'payerDepartmentPosts',
      };
      const approverType = approverTypesByType[approver.type];
      const approvers = this.props.approvers[approverType];
      this.props.checkApprover(approverType, approvers, approver.id, false);
    }
  }

  /**
   * 指定された承認者等の選択状態を切り替えます。
   * @param {Approver} approver ステップに設定された承認者等
   *    承認者/承認部署/部署役職/プロジェクト役職/所属部署役職/費用負担部署役職のいずれか
   * @param {string} approver.id ユーザID/部署ID/役職ID/部署役職ID/プロジェクト役職ID
   * @param {string | null} approver.postId 役職ID（部署役職の場合のみ）
   * @param {string} approver.type 種別
   *    user: 承認者
   *    group: 承認部署
   *    departmentPostRelationship: 部署役職
   *    projectPost: プロジェクト役職
   *    companyPost: 所属部署役職
   *    payerDepartmentPost: 費用負担部署役職
   * @param {string} approver.name 名称
   * @param {Boolean} selected 選択状態（true: 選択、false: 選択解除）
   */
  switchSelectedApprover(approver, selected) {
    if (selected) {
      this.props.selectApprover(approver);
    } else {
      this.props.deselectApprover(approver);
    }
  }

  renderApprovalFlow() {
    const rows = [];

    rows.push(this.renderIcons(0));
    this.props.approvals.forEach((approval, approvalIndex) => {
      rows.push(this.renderApprovalInput(approval, approvalIndex));
      rows.push(this.renderIcons(approvalIndex + 1));
    });

    return (
      <div className="col-sm-12 row-0">
        { rows }
      </div>
    );
  }

  renderDeleteButton(approval, approvalIndex) {
    const { isApproverSelectFlow } = this.props;
    return isApproverSelectFlow ? this.renderDeleteButtonForApproverSelectFlow(approval, approvalIndex) : this.renderDeleteButtonForApprovalFlow(approval, approvalIndex);
  }

  renderDeleteButtonForApprovalFlow(approval, approvalIndex) {
    const { isEdit, approvals } = this.props;

    return (isEdit || (approval.editable && approval.skippable)) ? (
      <span className="txt-danger txt-xlg approver-delete"
        onClick={ this.props.removeApproval.bind(null, approvalIndex, approvals) }
      >
        ×
      </span>
    ) : (
      <span className="">
      </span>
    );
  }

  renderDeleteButtonForApproverSelectFlow(_approval, _approvalIndex) {
    return null;
  }

  renderApprovalInput(approval, approvalIndex) {
    const { isApproverSelectFlow } = this.props;
    return isApproverSelectFlow ? this.renderApprovalInputForApproverSelectFlow(approval, approvalIndex) : this.renderApprovalInputForApprovalFlow(approval, approvalIndex);
  }

  renderApprovalInputForApprovalFlow(approval, approvalIndex) {
    const { isEdit } = this.props;

    const step = i18next.t('commons.ordinals.no_interval', { postProcess: 'interval', count: approvalIndex + 1 });

    if (isEdit) {
      return (
        <div key={ `approver-form-${approvalIndex}` } className="form-group clear">
          <div className={ 'approval-step' }>
            <label className='col-sm-2 control-label approval-label approval-pl-0' htmlFor={ `approver-${approvalIndex}` }>
              { i18next.t('approvals.flows.approvalStep', { order: step }) }
            </label>
            <div className="col-sm-7 left" id={ `approver-${approvalIndex}` }>
              <div className="input-group approver-form">
                { this.renderConditionInput(approval, approvalIndex) }
                { this.renderApprover(approval, approvalIndex) }
              </div>
            </div>
            <div className="col-sm-3">
              { this.renderDeleteButton(approval, approvalIndex) }
            </div>
          </div>
          <div className="approval-flow-step-checks">
            <div className="col-sm-offset-4 col-sm-8">
              <input
                id={ `check-${approvalIndex}-editable` }
                type="checkbox"
                checked={ !approval.editable }
                onChange={ this.handleChangeEditable.bind(null, approvalIndex) }
                className="txt-pointer col-sm-1"/>
              <LabeledComponent
                className="txt-pointer col-sm-11"
                label={ i18next.t('approvals.flows.notEditable') }
                description={ i18next.t('approvals.flows.descriptions.notEditable') }
              >
              </LabeledComponent>
            </div>
            <div className="col-sm-offset-4 col-sm-8">
              <input
                id={ `check-${approvalIndex}-skippable` }
                type="checkbox"
                checked={ !approval.skippable }
                onChange={ this.handleChangeSkippable.bind(null, approvalIndex) }
                className="txt-pointer col-sm-1"/>
              <LabeledComponent
                className="txt-pointer col-sm-11"
                label={ i18next.t('approvals.flows.notSkippable') }
                description={ i18next.t('approvals.flows.descriptions.notSkippable') }
              >
              </LabeledComponent>
            </div>
            <div className="col-sm-offset-4 col-sm-8">
              <input
                id={ `check-${approvalIndex}-selfApprovable` }
                type="checkbox"
                checked={ approval.selfApprovable }
                onChange={ this.handleChangeSelfApprovable.bind(null, approvalIndex) }
                className="txt-pointer col-sm-1"/>
              <LabeledComponent
                className="txt-pointer col-sm-11"
                label={ i18next.t('approvals.flows.selfApprovable') }
                description={ i18next.t('approvals.flows.descriptions.selfApprovable') }
              >
              </LabeledComponent>
            </div>
            { // 自己承認不可かつ、「誰か1人」の時のみ選択可能
              !approval.selfApprovable && approval.condition === 'any' ? (
                <div className="col-sm-offset-4 col-sm-8">
                  <input
                    id={ `check-${approvalIndex}-selfApprovalSkippable` }
                    type="checkbox"
                    checked={ approval.selfApprovalSkippable }
                    onChange={ this.handleChangeSelfApprovalSkippable.bind(null, approvalIndex) }
                    className="txt-pointer col-sm-1"/>
                  <LabeledComponent
                    className="txt-pointer col-sm-11"
                    label={ i18next.t('approvals.flows.selfApprovalSkippable') }
                    description={ i18next.t('approvals.flows.descriptions.selfApprovalSkippable')  }
                  >
                  </LabeledComponent>
                </div>
              ) : null }
          </div>
        </div>
      );
    }
    return (
      <div key={ `approver-form-${approvalIndex}` } className={ `col-sm-12 form-group clear` }>
        <label className='col-sm-2 control-label approval-label approval-pl-0' htmlFor={ `approver-${approvalIndex}` }>
          { i18next.t('approvals.flows.approvalStep', { order: step }) }
        </label>
        <div className='col-sm-9' id={ `approver-${approvalIndex}` }>
          <div className="input-group approver-form">
            { this.renderConditionInput(approval, approvalIndex) }
            { this.renderApprover(approval, approvalIndex) }
          </div>
        </div>
        { this.renderDeleteButton(approval, approvalIndex) }
      </div>
    );
  }

  renderApprovalInputForApproverSelectFlow(approval, approvalIndex) {
    const { isEdit } = this.props;

    const step = i18next.t('commons.ordinals.no_interval', { postProcess: 'interval', count: approvalIndex + 1 });

    if (isEdit) {
      return (
        <div key={ `approver-form-${approvalIndex}` } className="form-group clear">
          <div className={ 'approval-step' }>
            <label className='col-sm-2 control-label approval-label approval-pl-0' htmlFor={ `approver-${approvalIndex}` }>
              { i18next.t('approvals.flows.approvalStep', { order: step }) }
            </label>
            <div className="col-sm-7 left" id={ `approver-${approvalIndex}` }>
              <div className="input-group approver-form">
                { this.renderConditionInput(approval, approvalIndex) }
                { this.renderApprover(approval, approvalIndex) }
              </div>
            </div>
            <div className="col-sm-3">
              { this.renderDeleteButton(approval, approvalIndex) }
            </div>
          </div>
          <div className="approval-flow-step-checks">
            <div className="col-sm-offset-4 col-sm-8">
              <input
                id={ `check-${approvalIndex}-editable` }
                type="checkbox"
                checked={ !approval.editable }
                onChange={ this.handleChangeEditable.bind(null, approvalIndex) }
                className="txt-pointer col-sm-1"/>
              <LabeledComponent
                className="txt-pointer col-sm-11"
                label={ i18next.t('approvals.flows.notEditable') }
                description={ i18next.t('approvals.flows.descriptions.notEditable') }
              >
              </LabeledComponent>
            </div>
            <div className="col-sm-offset-4 col-sm-8">
              <input
                id={ `check-${approvalIndex}-selfApprovable` }
                type="checkbox"
                checked={ approval.selfApprovable }
                onChange={ this.handleChangeSelfApprovable.bind(null, approvalIndex) }
                className="txt-pointer col-sm-1"/>
              <LabeledComponent
                className="txt-pointer col-sm-11"
                label={ i18next.t('approvals.flows.selfApprovable') }
                description={ i18next.t('approvals.flows.descriptions.selfApprovable') }
              >
              </LabeledComponent>
            </div>
          </div>
        </div>
      );
    }
    return (
      <div key={ `approver-form-${approvalIndex}` } className={ `col-sm-12 form-group clear` }>
        <label className='col-sm-2 control-label approval-label approval-pl-0' htmlFor={ `approver-${approvalIndex}` }>
          { i18next.t('approvals.flows.approvalStep', { order: step }) }
        </label>
        <div className='col-sm-9' id={ `approver-${approvalIndex}` }>
          <div className="input-group approver-form">
            { this.renderConditionInput(approval, approvalIndex) }
            { this.renderApprover(approval, approvalIndex) }
          </div>
        </div>
        { this.renderDeleteButton(approval, approvalIndex) }
      </div>
    );
  }

  renderIcons(index) {
    const { isEdit, isApproverSelectFlow, approvals } = this.props;

    if (isApproverSelectFlow) { return null; }

    if (index === 0 || index === approvals.length) {
      return (
        <i key={ `add-icon-${index}` }
          className={
            isEdit ? 'fa fa-plus-square accent plus-icon-edit form-group txt-pointer'
              : 'fa fa-plus-square accent plus-icon pull-right form-group txt-pointer'
          }
          onClick={ this.props.insertNewApproval.bind(null, index, approvals) }
        />
      );
    }
    return (
      <div key={ `add-icon-wrapper-${index}` }
        className='col-sm-12 row-0 form-group'>
        <i className={ isEdit ? 'fa fa-chevron-down down-icon-edit' : 'fa fa-chevron-down down-icon' } />
        <i
          className={
            isEdit ? 'fa fa-plus-square accent plus-icon-edit-middle txt-pointer'
              : 'fa fa-plus-square accent plus-icon pull-right txt-pointer'
          }
          onClick={ this.props.insertNewApproval.bind(null, index, approvals) }
        />
      </div>
    );
  }

  renderConditionInput(approval, index) {
    const { isApproverSelectFlow } = this.props;
    return isApproverSelectFlow ? this.renderConditionInputForApproverSelectFlow(approval, index) : this.renderConditionInputForApprovalFlow(approval, index);
  }

  renderConditionInputForApprovalFlow(approval, index) {
    const { editable, condition } = approval;

    if (editable || this.props.isEdit) {
      return (
        <div className="dropdown input-group-addon approve-num txt-pointer">
          <div
            id={ `num-${this.props.approvalFlowId}-${index}` }
            className="dropdown-toggle"
            data-toggle="dropdown">
            { (() => {
              if (condition === "all") {
                return (
                  <span>{ i18next.t('approvals.flows.all') }</span>
                );
              }
              return (
                <span>{ i18next.t('approvals.flows.someOne') }</span>
              );
            })() }
            <i className="fa fa-caret-down drop-icon fa-right"/>
          </div>
          { this.renderConditionSelector(condition, index) }
        </div>
      );
    }
    return (
      <div className="approve-num input-group-addon">
        { (() => {
          if (condition === "all") {
            return (
              <span>{ i18next.t('approvals.flows.all') }</span>
            );
          }
          return (
            <span>{ i18next.t('approvals.flows.someOne') }</span>
          );
        })() }
      </div>
    );
  }

  renderConditionInputForApproverSelectFlow(approval, index) {
    return (
      <div className="approve-num input-group-addon">
        <span>{ i18next.t('approvals.flows.someOne') }</span>
      </div>
    );
  }

  renderConditionSelector(condition, index) {
    if (condition === "all") {
      return (
        <ul
          className="dropdown-menu"
          role="menu"
          aria-labelledby={ `num-${this.props.approvalFlowId}-${index}` }>
          <li>
            <a onClick={ this.handleChangeCondition.bind(null, index, 'all') }>
              <i className="fa fa-check"/>
              <span>{ i18next.t('approvals.flows.all') }</span>
            </a>
          </li>
          <li>
            <a onClick={ this.handleChangeCondition.bind(null, index, 'any') }>
              <span>{ i18next.t('approvals.flows.someOne') }</span>
            </a>
          </li>
        </ul>
      );
    }
    return (
      <ul className="dropdown-menu" role="menu">
        <li>
          <a onClick={ this.handleChangeCondition.bind(null, index, 'all') }>
            <span>{ i18next.t('approvals.flows.all') }</span>
          </a>
        </li>
        <li>
          <a onClick={ this.handleChangeCondition.bind(null, index, 'any') }>
            <i className="fa fa-check"/>
            <span>{ i18next.t('approvals.flows.someOne') }</span>
          </a>
        </li>
      </ul>
    );
  }

  renderApprover(approval, approvalIndex) {
    const approvers = approval.approvers.map((it) => {
      switch (it.type) {
        case 'company_post':
          return `[所]${it.name}`;
        case 'payer_department_post':
          return `[費]${it.name}`;
        default:
          return it.name;
      }
    }).join(", ");

    let approversList = null;
    if (approval.approvers.length) {
      approversList = (<span className="approvers-list">{ approvers }</span>);
    } else if (approval.skippable) {
      approversList = (<span className="approvers-list txt-disabled">{ i18next.t('approvals.flows.empty') }</span>);
    } else {
      approversList = (
        <span className="approvers-list txt-disabled">
          { i18next.t('approvals.flows.empty') }<br/>
          { `※${i18next.t('approvals.flows.notSkippable')}` }
        </span>
      );
    }

    if (approval.editable || this.props.isEdit) {
      return (
        <div className="form-control scroll-form word-wrap txt-pointer"
          onClick={
            this.props.openAddApproverModal.bind(
              null,
              approvalIndex,
              approval,
              {
                isUpperLevelPostSelecting: approval.isUpperLevelPostSelecting,
                relativeLevel: approval.relativeLevel,
                nearestPostsApprover: approval.nearestPostsApprover,
              },
            )
          }
        >
          { approversList }
          <i className="fa fa-user-plus primary pull-right"/>
        </div>
      );
    }
    return (
      <div className="form-control scroll-form">
        { approversList }
        <i className="fa fa-lock disabled pull-right lock-icon"/>
      </div>
    );
  }

  renderManual() {
    const { isApproverSelectFlow } = this.props;
    return isApproverSelectFlow ? this.renderManualForApproverSelectFlow() : this.renderManualForApprovalFlow();
  }

  renderManualForApprovalFlow() {
    return (
      <div>
        <div className="clear">
          <span className="multi-select">
            <i className="fa fa-plus-square accent"/>
            <span>{ `= ${i18next.t('approvals.flows.addStep')}` }</span>
          </span>
          <span>
            <i className="fa fa-user-plus primary"/>
            <span>{ `= ${i18next.t('approvals.flows.addApprover')}` }</span>
          </span>
        </div>
        <div className="text-warning txt-sm">
          { `※ ${i18next.t('approvals.messages.stepEmpty')}` }<br/>
          { `※ ${i18next.t('approvals.messages.stepConstraint')}` }<br/>
        </div>
      </div>
    );
  }

  renderManualForApproverSelectFlow() {
    return (
      <div>
        <div className="clear">
          <span>
            <i className="fa fa-user-plus primary"/>
            <span>{ `= ${i18next.t('approvals.flows.addApprover')}` }</span>
          </span>
        </div>
        <div className="text-warning txt-sm">
          { `※ ${i18next.t('approvals.messages.stepConstraint')}` }<br/>
        </div>
      </div>
    );
  }

  render() {
    const { approvers, reportIndex } = this.props;
    const modalApprovers = {
      users: [], groups: [], userDepartmentPosts: [], projectPosts: [], payerDepartmentPosts: [], ...approvers,
    };

    return (
      <div className="col-sm-12 row-0">
        { this.renderApprovalFlow() }
        { this.renderManual() }
        <AddApproverModal
          selectedApprovers={ this.props.selectedApprovers }
          onRemoveSelectedApproverClick={ this.handleRemoveSelectedApproverClick }
          reportIndex= { reportIndex }
          show={ this.props.isAddApproverModalOpen }
          close={ this.props.closeAddApproverModal }
          displayPost={ this.props.isEdit }
          relativeLevel={ this.props.relativeLevel }
          isUpperLevelPostSelecting={ this.props.isUpperLevelPostSelecting }
          approvers={ modalApprovers }
          options={ this.props.options }
          checkApprover={ this.handleSelectApprover }
          checkUpperLevelSelector={ this.props.checkUpperLevelSelector }
          onChangeLevel={ this.props.onChangeRelativeLevel }
          checkNearestPostsApprover={ this.props.checkNearestPostsApprover }
          onSubmit={ this.props.finishEditingApprovers.bind(null, this.props.editingApprovalIndex, this.props.approvals) }
          setApproversUserSearchText={ this.props.setApproversUserSearchText }
          resetApproversUserSearchText={ this.props.resetApproversUserSearchText }
          approversSearchCondition={ this.props.approversSearchCondition }
          searchApprovers={ this.props.searchApprovers }
          selectedDepartmentPostRelationships={ this.props.selectedDepartmentPostRelationships }
          onSelectDepartmentPostRelationship={ this.handleSelectDepartmentPostRelationship }
          onDeselectDepartmentPostRelationship={ this.handleDeselectDepartmentPostRelationship }
        />
      </div>
    );
  }
}

ApproverForm.propTypes = {
  approvalFlowId: PropTypes.string,
  approvals: PropTypes.array, // 承認フローのステップの配列
  approvers: PropTypes.shape({ // 編集中のフローのステップの承認者
    users: PropTypes.array,
    groups: PropTypes.array,
    userDepartmentPosts: PropTypes.array,
    projectPosts: PropTypes.array,
    payerDepartmentPosts: PropTypes.array,
  }),
  options: PropTypes.shape({
    userDepartmentPosts: PropTypes.object.isRequired,
    payerDepartmentPosts: PropTypes.object.isRequired,
  }).isRequired,
  editingApprovalIndex: PropTypes.number.isRequired, // 承認者を編集中のステップのindex
  insertNewApproval: PropTypes.func.isRequired,
  removeApproval: PropTypes.func.isRequired,
  onChangeCondition: PropTypes.func.isRequired,
  onChangeEditable: PropTypes.func.isRequired,
  setApproversUserSearchText: PropTypes.func.isRequired,
  resetApproversUserSearchText: PropTypes.func.isRequired,
  onChangeSkippable: PropTypes.func,
  onChangeSelfApprovable: PropTypes.func,
  onChangeSelfApprovalSkippable: PropTypes.func,
  isEdit: PropTypes.bool,
  isApproverSelectFlow: PropTypes.bool,
};

export function mapDispatchToProps(dispatch, ownProps) {
  return {
    openAddApproverModal(index, approval, { isUpperLevelPostSelecting, relativeLevel, nearestPostsApprover }) {
      // モーダルを開く前に、現在の設定でチェックボックスにチェックを付ける
      dispatch(actions.resetApproversCheck(approval.approvers, { isUpperLevelPostSelecting, relativeLevel, nearestPostsApprover }));
      dispatch(actions.setEditingApprovalIndex(index));
      dispatch(SelectedApproversListActionCreators.selectApprovers(approval.approvers));
      dispatch(DepartmentPostRelationshipSelectorActionCreators.setSelectedDepartmentPostRelationships(approval.approvers.filter((approver) => approver.type === 'department_post_relationship')));
      dispatch(actions.openAddApproverModal());
    },
    closeAddApproverModal() {
      // dispatch(approversSearchActions.resetDepartmentPostFilter());
      dispatch(actions.closeAddApproverModal());
    },
    insertNewApproval(index, approvals) {
      dispatch(actions.insertNewApproval(index, approvals));
    },
    removeApproval(index, approvals) {
      dispatch(actions.removeApproval(index, approvals));
      dispatch(actions.setEditingApprovalIndex(-1));
    },
    finishEditingApprovers(index, approvals, approvers, options) {
      dispatch(actions.setApprovers(approvals, index, approvers, options));
      dispatch(actions.closeAddApproverModal());
    },
    checkApprover(approverType, approvers, approverId, checked) {
      dispatch(actions.checkApprover(approverType, approvers, approverId, checked));
    },
    checkUpperLevelSelector(approverType, checked) {
      dispatch(actions.toggleUpperLevelSelector(approverType, checked));
    },
    onChangeRelativeLevel(approverType, level) {
      dispatch(actions.setRelativeLevel(approverType, level));
    },
    checkNearestPostsApprover(approverType, checked) {
      dispatch(actions.toggleNearestPostApprover(approverType, checked));
    },
    onChangeCondition(approvals, index, condition) {
      dispatch(actions.setApprovalCondition(approvals, index, condition));
    },
    onChangeEditable(approvals, index, editable) {
      dispatch(actions.setApprovalEditable(approvals, index, editable));
    },
    setApproversUserSearchText(value) {
      dispatch(approversSearchActions.configureApproversUserSearchConditions('searchText', value));
    },
    resetApproversUserSearchText() {
      dispatch(approversSearchActions.configureApproversUserSearchConditions('searchText', ''));
    },
    searchApprovers(value) {
      dispatch(approversSearchActions.searchApprovers(value))
        .then(() => dispatch(actions.mergeSearchApprovers()));
    },
    onChangeSkippable(approvals, index, skippable) {
      dispatch(actions.setApprovalSkippable(approvals, index, skippable));
    },
    onChangeSelfApprovable(approvals, index, selfApprovable, isApproverSelectFlow) {
      dispatch(actions.setApprovalSelfApprovable(approvals, index, selfApprovable, isApproverSelectFlow));
    },
    onChangeSelfApprovalSkippable(approvals, index, selfApprovalSkippable) {
      dispatch(actions.setApprovalSelfApprovalSkippable(approvals, index, selfApprovalSkippable));
    },
    selectApprover(approver) {
      dispatch(SelectedApproversListActionCreators.selectApprover(approver));
    },
    deselectApprover(approver) {
      dispatch(SelectedApproversListActionCreators.deselectApprover(approver));
    },
    onSelectDepartmentPostRelationship(departmentPostRelationship) {
      dispatch(DepartmentPostRelationshipSelectorActionCreators.selectDepartmentPostRelationship(departmentPostRelationship));
    },
    onDeselectDepartmentPostRelationship(departmentPostRelationship) {
      dispatch(DepartmentPostRelationshipSelectorActionCreators.deselectDepartmentPostRelationship(departmentPostRelationship));
    },
  };
}

export default connect(
  makeApproverFormStateMapper,
  mapDispatchToProps,
)(ApproverForm);
