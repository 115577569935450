import { RequiredTableDataProps } from 'components/renewaled_ui/Table/interface/table';

interface UseReturn {
  readonly onChange: ()=> void;
  readonly isShowIndeterminate: () => boolean;
  readonly isChecked: () => boolean;
}

/**
 * ロジック: TableFirstHeader
 */
export const useHooks = <Data extends RequiredTableDataProps, >(data: Data[], onSelectAll: (isSelected: boolean) => void): UseReturn => {
  /**
   * チェックを押した
   */
  const onChange = (): void => {
    onSelectAll(data.every((d) => !d.isSelected) || false);
  };

  /**
   * 全てを選択している
   */
  const isAllSelected = (): boolean => data.every((d) => d.isSelected);

  /**
   * indeterminate状態にするか
   */
  const isShowIndeterminate = (): boolean => {
    const selectedNum = data.filter((d) => d.isSelected).length;
    // 1つ以上選択されていて、一つでも選択されていなければ、不選択状態
    return selectedNum > 0 && !isAllSelected();
  };

  /**
   * 選択状態にするか
   */
  const isChecked = (): boolean => {
    if (!data.length) return false;
    return isAllSelected();
  };

  return {
    onChange,
    isShowIndeterminate,
    isChecked,
  };
};
