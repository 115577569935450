import NumberField from "components/fields/NumberField";
import i18next from "i18n";
import PropTypes from "prop-types";
import React, { Component } from "react";
import { connect } from "react-redux";
import {
  renderInputWithDropdown,
  renderInputWithIcon,
} from "utilities/renderer";
import { initIntlCurrencyObj, resetValidateMessage } from "utilities/Utils";
import * as actions from "../actions";
import renderAmountPerPersonWrapper from "../utilities/renderer";

export class AmountInput extends Component {
  constructor(props) {
    super(props);

    this.handleOriginalAmountChange =
      this.handleOriginalAmountChange.bind(this);
    this.handleAmountChange = this.handleAmountChange.bind(this);
    this.handleCurrencyChange = this.handleCurrencyChange.bind(this);
    this.handleRateChange = this.handleRateChange.bind(this);
  }

  handleOriginalAmountChange(value) {
    const { exchangeRate, exchangePolicy, defaultCurrencyId, locale } =
      this.props;

    resetValidateMessage(`.amount-input-field`);

    this.props.setOriginalAmount(value);

    // 外貨入力中は、amountを計算する
    const amount = this.props.calcAmount(
      value,
      exchangeRate,
      exchangePolicy,
      defaultCurrencyId,
      locale,
    );
    this.props.setAmount(amount);
  }

  handleAmountChange(e) {
    const { originalAmount, defaultCurrencyId, locale } = this.props;
    const amount = this.props.calcAmount(
      e.target.value,
      1,
      "floor",
      defaultCurrencyId,
      locale,
    );
    const exchangeRate = this.props.calcApproximateRate(originalAmount, amount);
    this.props.setExchangeRate(exchangeRate);
    this.props.setAmount(amount);
  }

  handleCurrencyChange(currency) {
    this.props.setCurrency(currency);
  }

  handleRateChange(e) {
    const rate = e.target.value;
    this.props.setExchangeRate(rate);
    const { originalAmount, exchangePolicy, defaultCurrencyId, locale } =
      this.props;
    const amount = this.props.calcAmount(
      originalAmount,
      rate,
      exchangePolicy,
      defaultCurrencyId,
      locale,
    );
    this.props.setAmount(amount);
  }

  renderOriginalInput(currencyId) {
    const { className, originalAmount } = this.props;
    const option = initIntlCurrencyObj(currencyId).resolvedOptions();

    return (
      <NumberField
        className={className}
        allowMinus={true}
        allowZeroPadding={true}
        allowDecimal={true}
        value={originalAmount}
        onChange={this.handleOriginalAmountChange}
      />
    );
  }

  renderAmountInput() {
    const { className, amount, defaultCurrencyId } = this.props;
    const option = initIntlCurrencyObj(defaultCurrencyId).resolvedOptions();

    const input = (
      <input
        type="number"
        className={className}
        step={10 ** (-1 * (option.maximumFractionDigits || 0))}
        value={amount}
        onChange={this.handleAmountChange}
      />
    );
    return renderInputWithIcon(input, <span>{defaultCurrencyId}</span>);
  }

  renderRateInput(rate) {
    const { exchangeRate } = this.props;

    const rateInput = (
      <input
        type="number"
        className="form-control"
        step="0.001"
        value={exchangeRate}
        onChange={this.handleRateChange}
      />
    );

    return renderInputWithIcon(
      rateInput,
      <span>{i18next.t("transactions.properties.rate")}</span>,
    );
  }

  currencyToText({ currencyId, currencyName }) {
    let text = currencyId;
    if (currencyName) {
      text += ` (${currencyName})`;
    }
    return text;
  }

  render() {
    const {
      isModalOpen,
      currencies,
      exchangeRate,
      companions,
      amount,
      fields,
    } = this.props;
    const currencyId = this.props.currencyId || this.props.defaultCurrencyId;
    const enableForeignInput = currencyId !== this.props.defaultCurrencyId;
    const amountInput = (
      <div className="amount">
        <span className="calc-symbol">=</span>
        {this.renderAmountInput()}
      </div>
    );
    return (
      <div className="amount-input">
        <div className="original-amount">
          {renderAmountPerPersonWrapper(
            companions,
            amount,
            fields,
            renderInputWithDropdown(
              currencyId,
              currencies,
              this.handleCurrencyChange,
              this.renderOriginalInput(currencyId),
              this.currencyToText,
            ),
            !enableForeignInput,
            "amount_input",
          )}
          {enableForeignInput ? (
            <span className="calc-symbol">
              <i className="fa fa-times"></i>
            </span>
          ) : null}
          {enableForeignInput ? this.renderRateInput(exchangeRate) : null}
        </div>
        {enableForeignInput
          ? renderAmountPerPersonWrapper(
              companions,
              amount,
              fields,
              amountInput,
              enableForeignInput,
              "amount_input",
            )
          : null}
      </div>
    );
  }
}

AmountInput.defaultProps = {
  className:
    "form-control validate[required,custom[number],maxSize[15]] amount-input-field",
  onChange() {},
  companions: [],
  fields: [],
};

AmountInput.propTypes = {
  className: PropTypes.string,
  transactedAt: PropTypes.string,
  amount: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  originalAmount: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  exchangePolicy: PropTypes.string,
  currencyId: PropTypes.string,
  selector: PropTypes.func,
  setAmount: PropTypes.func.isRequired,
  setOriginalAmount: PropTypes.func.isRequired,
  setExchangeRate: PropTypes.func.isRequired,
  setCurrency: PropTypes.func.isRequired,
  companions: PropTypes.array,
  fields: PropTypes.array,
};

function mapStateToProps(state, ownProps) {
  const { formData, formState, suggestions } = state;
  return {
    defaultCurrencyId: formState.defaultCurrencyId,
    locale: formState.locale,
    currencies: suggestions.currencies.current,
    rateSettings: formState.rateSettings,
    currencyId: formData.originalAmountCurrencyId,
  };
}

function mapDispatchToProps(dispatch, ownProps) {
  return {
    setExchangeRate(rate) {
      dispatch(actions.setExchangeRate(rate));
    },
    calcAmount(...args) {
      return actions.calcAmount(...args);
    },
    calcApproximateRate(...args) {
      return actions.calcApproximateRate(...args);
    },
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(AmountInput);
