import * as actions from '../actions/analysisReportExportData';
import ExportForm from '../components/ExportForm';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import Timer from 'utilities/timer';
import i18next from 'i18n';
import isNil from 'lodash/isNil';
import { connect } from 'react-redux';

export class AnalysisXlsExportForm extends Component {
  constructor(props) {
    super(props);

    this.pollingAnalysisReportXlsJobs();

    this.onRequestDownload = this.onRequestDownload.bind(this);
    this.pollingAnalysisReportXlsJobs = this.pollingAnalysisReportXlsJobs.bind(this);
    this.stopPollingAnalysisReportXlsJobs = this.stopPollingAnalysisReportXlsJobs.bind(this);
  }

  componentWillUnmount() {
    this.stopPollingAnalysisReportXlsJobs();
  }

  pollingAnalysisReportXlsJobs() {
    const { loadAnalysisReportXlsJobs } = this.props;

    this.timer = new Timer(3000, [loadAnalysisReportXlsJobs]);
    this.timer.start();
  }

  stopPollingAnalysisReportXlsJobs() {
    if (!isNil(this.timer)) {
      this.timer.stop();
    }
  }

  onRequestDownload() {
    const { requestAnalysisReportXlsJobs, loadAnalysisReportXlsJobs } = this.props;
    requestAnalysisReportXlsJobs();
    loadAnalysisReportXlsJobs();
  }

  onDownloadClick(e, url) {
    e.stopPropagation();
    window.location.href = url;
  }

  render() {
    const { analysisReportExportData } = this.props;

    return (
      <ExportForm
        data={ analysisReportExportData.analysisReportXlsJobs }
        onRequestExport={ this.onRequestDownload }
        requestButtonTitle={ i18next.t('exports.inputs.requestXlsZip') }
        hiddenFields={ ['exportTypeName'] }
      />
    );
  }
}

AnalysisXlsExportForm.propTypes = {
  analysisReportExportData: PropTypes.shape({
    analysisReportXlsJobs: PropTypes.array.isRequired,
  }),
  loadAnalysisReportXlsJobs: PropTypes.func.isRequired,
  requestAnalysisReportXlsJobs: PropTypes.func.isRequired,
};

function mapStateToProps(state, ownProps) {
  const { analysisReportExportData } = state;
  return {
    analysisReportExportData,
  };
}

function mapDispatchToProps(dispatch, ownProps) {
  return {
    loadAnalysisReportXlsJobs() {
      dispatch(actions.fetchAnalysisReportXlsJobs());
    },
    requestAnalysisReportXlsJobs() {
      dispatch(actions.requestAnalysisReportXlsJobs());
    },
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(AnalysisXlsExportForm);
