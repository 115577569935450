import LabeledComponent from "components/LabeledComponent";
import i18next from "i18n";
import PropTypes from "prop-types";
import React from "react";
import formatter from "utilities/formatter";

const ExpenseSummary = (props) => {
  const {
    expense: { amount, transactedAt, shop, route },
  } = props;
  const i18nPrefix = "transactions.histories.fieldTypes";

  // TODO:  入力フォームのラベルと、入力値を対応付ける
  return (
    <div className="expense-summary-for-history">
      <LabeledComponent label={i18next.t(`${i18nPrefix}.transactedAt`)}>
        <div>{formatter.date(transactedAt)}</div>
      </LabeledComponent>

      <LabeledComponent label={i18next.t(`${i18nPrefix}.amount`)}>
        <div>{formatter.amount(amount)}</div>
      </LabeledComponent>

      <LabeledComponent label={i18next.t(`${i18nPrefix}.shop`)}>
        <div>{formatter.text(shop && shop.name)}</div>
      </LabeledComponent>

      {route && (
        <LabeledComponent label={i18next.t(`${i18nPrefix}.route`)}>
          <div>{formatter.route(route)}</div>
        </LabeledComponent>
      )}
    </div>
  );
};

ExpenseSummary.defaultProps = {
  expense: {},
};

ExpenseSummary.propTypes = {
  expense: PropTypes.shape({
    amount: PropTypes.number,
    transactedAt: PropTypes.string,
    shop: PropTypes.object,
    route: PropTypes.object,
  }),
};

export default ExpenseSummary;
