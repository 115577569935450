import { ListForm } from "components/renewaled_ui/form_views";
import i18next from "i18n";
import isArray from "lodash/isArray";
import isNaN from "lodash/isNaN";
import isNil from "lodash/isNil";
import PropTypes from "prop-types";
import React, { Component } from "react";
import { connect } from "react-redux";
import { stationType } from "types";
import { resetValidateMessage } from "utilities/Utils";
import * as actions from "../actions";
import FareOptions from "../components/FareOptions";
import StationSuggest from "../components/StationSuggest";
import RouteDisplay from "./RouteDisplay";

export class FareSearchBox extends Component {
  componentDidMount() {
    if (!isNil(this.props.route) && Object.keys(this.props.route).length) {
      // 交通費入力された経費を表示する際、検索結果によって経費の情報を更新しない
      // （NOTE: 入力時は、検索結果の先頭の経路を自動で選択する）
      this.props.fetchRouteSummaries(false);
    }
  }

  get stationSuggestProps() {
    const { checked, ownerId } = this.props;

    return {
      ownerId,
      includeTrain: checked.train,
      includeBus: checked.bus,
      includePlane: checked.plane,
    };
  }

  handleStartStationChange = (text) => {
    resetValidateMessage(`.route-show-field`);
    this.props.onStartStationChange(text);
  };

  handleGoalStationChange = (text) => {
    resetValidateMessage(`.route-show-field`);
    this.props.onGoalStationChange(text);
  };

  render() {
    const {
      transactedAt,
      inputs,
      checked,
      searchCondition,
      station,
      routes,
      route,
      expiredCache,
      companions,
      fields,
    } = this.props;

    const date = isArray(transactedAt) ? transactedAt[0] : transactedAt;
    if (
      !isNaN(new Date(date).getTime()) &&
      new Date(searchCondition.date).getTime() !== new Date(date).getTime()
    ) {
      this.props.onSearchConditionChange("date", date);
    }
    return (
      <div className="fare-search-box">
        <div className="">
          <div className="fare-search-box__stations">
            <ListForm label={i18next.t("transactions.properties.origin")}>
              <StationSuggest
                {...this.stationSuggestProps}
                text={inputs.start}
                value={station.start}
                onTextChange={this.handleStartStationChange}
                onSelect={this.props.onStartStationSelect}
              />
            </ListForm>

            <div
              className="fare-search-box__exchange-btn"
              onClick={this.props.onExchangeButtonClick}
            >
              <i className="fas fa-exchange-alt fa-x"></i>
            </div>

            <ListForm label={i18next.t("transactions.properties.destination")}>
              <StationSuggest
                {...this.stationSuggestProps}
                text={inputs.goal}
                value={station.goal}
                onTextChange={this.handleGoalStationChange}
                onSelect={this.props.onGoalStationSelect}
              />
            </ListForm>
          </div>
          {expiredCache ? (
            <a
              className="modal-link"
              onClick={() => this.props.fetchRouteSummaries(true)}
            >
              {i18next.t("transactions.inputs.searchAgain")}
            </a>
          ) : null}
        </div>

        <FareOptions
          checked={checked}
          searchCondition={searchCondition}
          onSearchOptionChange={this.props.onSearchOptionsChange}
          onSearchConditionChange={this.props.onSearchConditionChange}
        />

        <ListForm label={i18next.t("transactions.inputs.fare")} required>
          <RouteDisplay
            className=""
            routes={routes}
            selected={route}
            isRoundTrip={checked.roundTrip}
            companions={companions}
            fields={fields}
          />
        </ListForm>
      </div>
    );
  }
}

FareSearchBox.propTypes = {
  amount: PropTypes.number,
  checked: PropTypes.object,
  expiredCache: PropTypes.bool.isRequired,
  fetchRouteSummaries: PropTypes.func.isRequired,
  inputs: PropTypes.shape({
    destination: PropTypes.string,
    goal: PropTypes.string,
    start: PropTypes.string,
  }),
  onExchangeButtonClick: PropTypes.func.isRequired,
  onGoalStationChange: PropTypes.func.isRequired,
  onGoalStationSelect: PropTypes.func.isRequired,
  onSearchOptionsChange: PropTypes.func.isRequired,
  onStartStationChange: PropTypes.func.isRequired,
  onStartStationSelect: PropTypes.func.isRequired,
  ownerId: PropTypes.string,
  route: PropTypes.object,
  routes: PropTypes.array,
  searchConditions: PropTypes.object,
  station: PropTypes.shape({
    start: stationType,
    goal: stationType,
  }),
  companions: PropTypes.array,
  fields: PropTypes.array,
};

function mapStateToProps(state, ownProps) {
  const { searchBox } = state;
  return {
    checked: searchBox.checked,
    expiredCache: searchBox.expiredCache,
    inputs: searchBox.inputs,
    route: searchBox.route,
    routes: searchBox.routes,
    searchCondition: searchBox.searchCondition,
    station: searchBox.station,
  };
}

function mapDispatchToProps(dispatch, ownProps) {
  return {
    fetchRouteSummaries(updateRoute) {
      dispatch(actions.expiredRouteSummaryCache(false));
      dispatch(actions.fetchRouteSummaries(updateRoute));
    },
    onStartStationChange(value) {
      dispatch(actions.setStationInput("start", value));
    },
    onStartStationSelect(suggestion) {
      dispatch(actions.selectStationSuggestion("start", suggestion));
    },
    onGoalStationChange(value) {
      dispatch(actions.setStationInput("goal", value));
    },
    onGoalStationSelect(suggestion) {
      dispatch(actions.selectStationSuggestion("goal", suggestion));
    },
    onExchangeButtonClick() {
      dispatch(actions.exchangeStations());
    },
    onSearchOptionsChange(key) {
      dispatch(actions.setSearchOptions(key));
    },
    onSearchConditionChange(key, value) {
      dispatch(actions.setSearchCondition(key, value));
    },
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(FareSearchBox);
