import React, { useMemo } from 'react';
import i18next from 'i18next';
import styled from 'styled-components';
import { JobRequest } from 'utilities/api/models/accountingDataScheduledExports';

interface Props {
  jobRequest: null | JobRequest;
}

const Link = styled.a`
  &:hover {
    cursor: pointer;
  }
`;

const Icon = styled.i`
  margin-right: 0.5rem;
`;

const aggregateResultsText = i18next.t('accountingDataScheduledExports.jobRequests.latestJobExecution.aggregateResults');

export const AggregateSuccessItem: React.FC<Props> = ({
  jobRequest,
}) => {
  const analysis = useMemo(() => jobRequest?.jobExecution?.jobAggregation?.analysis, [jobRequest]);
  const analysisType = analysis?.type;

  const targetAnalysisUri = useMemo(() => {
    if (!analysis) return '';

    const aggregationAspectType = analysis.aggregationAspectType;

    if (analysisType === 'Analysis' && aggregationAspectType === null) return 'analyses';
    if (analysisType === 'PreAnalysis' && aggregationAspectType === 'temporary_payment') return 'temporary_payment_analyses';
    if (analysisType === 'PreAnalysis' && aggregationAspectType === null) return 'pre_analyses';
    if (analysisType === 'PaymentRequests::Analysis') return 'payment_requests/analyses';
    return '';
  }, [analysis, analysisType]);

  if (!jobRequest?.jobExecution?.id) { return <></>; }
  if (!analysisType) { return <></>; }

  const analysisId = analysis?.id;

  const isTestAnalysis = ['AccountingDataScheduledExports::TestAnalysis', 'AccountingDataScheduledExports::TestPreAnalysis', 'PaymentRequests::AccountingDataScheduledExports::TestAnalysis'].includes(analysisType);
  if (isTestAnalysis) { return <></>; }

  return (
    <div>
      <Link href={ `/${targetAnalysisUri}/${analysisId}` }>
        <Icon className='text-success fas fa-check' />{ aggregateResultsText }
      </Link>
    </div>
  );
};
