import React from "react";

interface Props {
  /** アイコン_オン */
  readonly iconClassOn: string;
  /** アイコン_オフ */
  readonly iconClassOff: string;
  /** オンかオフか */
  readonly isOn: boolean;
}

/**
 * トグルさせるアイコン
 */
const IconToggle: React.FC<Props> = (p) => {
  if (p.isOn) return <i className={p.iconClassOn} />;
  return <i className={p.iconClassOff} />;
};

export default IconToggle;
