import ImportResultsForm from '../ImportResultsForm';
import React, { FC } from 'react';
import styled from 'styled-components';

export interface ImportResultsProps {
  importType: string;
  exportFormatId?: string;
  targetId?: string;
  dataSetId?: string
}

export const Wrapper = styled.div`
  padding-right: 30px;
  padding-left: 30px;
`;

const ImportResults: FC<ImportResultsProps> = ({
  importType,
  exportFormatId,
  targetId,
  dataSetId,
}) => {
  return (
    <Wrapper className='form-group'>
      <ImportResultsForm importType={ importType } exportFormatId={ exportFormatId } targetId={ targetId } dataSetId={ dataSetId } />
    </Wrapper>
  );
};

export default ImportResults;
