import i18next from "i18n";
import React from "react";
import RequestStateButton from "./RequestStateButton";
import { Props } from "./Types";

/** 「承認を取り消す」ボタン */
const CancelApprovalButton: React.FC<Props> = ({ requestState, onClick }) => {
  return (
    <RequestStateButton
      buttonJobType="cancelApproval"
      buttonTitle={i18next.t("commons.actions.withdrawApproval")}
      buttonClassName="btn btn-block btn-danger"
      iconClassName="fa fa-left fa-undo"
      requestState={requestState}
      onClick={onClick}
    />
  );
};

export default CancelApprovalButton;
