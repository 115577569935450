import React from 'react';
import { ControlLabel } from 'components/ControlLabel';

/**
 * @deprecated components/FormElementを使ってください。
 * @param key
 * @param label
 * @param component
 * @param labelProps
 * @param contentProps
 * @returns
 */
export function renderListFormElement(
  key: string | number,
  label: string,
  component: JSX.Element,
  /** @todo object型は使わないように */
  // eslint-disable-next-line @typescript-eslint/ban-types
  labelProps: object = { className: 'col-sm-3' },
  /** @todo object型は使わないように */
  // eslint-disable-next-line @typescript-eslint/ban-types
  contentProps: object = { className: 'col-sm-6 col-md-6' },
): JSX.Element {
  return (
    <div
      className='form-group'
      key={ key }
    >
      <ControlLabel { ...labelProps }>
        { label.split('\n').map((l, i) => <div key={ i }>{ l }</div>) }
      </ControlLabel>
      <div { ...contentProps }>{ component }</div>
    </div>
  );
}

export default {
  renderListFormElement,
};
