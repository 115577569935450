import PropTypes from 'prop-types';
import React, { Component } from 'react';
import uniqueId from 'lodash/uniqueId';

/**
 * components/SmartCheckBoxと同等だが、stateを使うバージョン
 * 新規ComponentではSmartCheckBoxを使用
 */
export default class Checkbox extends Component {
  constructor(props) {
    super(props);
    this.state = {
      id: uniqueId('checkbox-'),
      checked: props.defaultChecked,
    };
  }

  isChecked() {
    return this.state.checked;
  }

  // eslint-disable-next-line camelcase
  UNSAFE_componentWillReceiveProps(props) {
    this.setState({ checked: props.defaultChecked });
  }

  render() {
    return (
      <div>
        <div className={ `checkbox ${this.props.className}` } style={ { display: 'inline-block' } }>
          <input type='checkbox' value='None'
            id={ this.state.id } checked={ this.state.checked }
            onChange={ (e) => {
              this.setState({ checked: e.target.checked });
              const handleOnChange = this.props.onChange || ((b) => (b));
              handleOnChange(e.target.checked);
            } }
          />
          <label htmlFor={ this.state.id }></label>
        </div>
        <span>{ this.props.label }</span>
      </div>
    );
  }
}

Checkbox.propTypes = {
  className: PropTypes.string,
  label: PropTypes.string.isRequired,
  defaultChecked: PropTypes.bool,
  onChange: PropTypes.func,
};
