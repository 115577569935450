import PropTypes from 'prop-types';
import React, { Component } from 'react';
import formatter from 'utilities/formatter';
import get from 'lodash/get';

/**
 * 支払口座ページ: ロックされた支払口座の詳細表示
 */
export default class ExpenseAccountViewer extends Component {
  render() {
    const { expenseAccount } = this.props;
    return (
      <div className='expense-account-viewer form-horizontal'>
        <div className='message warning'>
          <div className='message-content'>
            <ul>
              <li>口座設定がロックされています。編集する場合は、管理者にロック解除を依頼してください。</li>
            </ul>
          </div>
        </div>
        <div className='row'>
          <label className='col-sm-3 col-md-3 control-label'>金融機関名</label>
          <div className='col-sm-offset-1 col-md-offset-1 col-sm-8 col-md-8'>
            <p className='form-control-static'>{ formatter.text(get(expenseAccount, 'bank.name', null), '未設定') }</p>
          </div>
        </div>
        <div className='row'>
          <label className='col-sm-3 col-md-3 control-label'>支店名</label>
          <div className='col-sm-offset-1 col-md-offset-1 col-sm-8 col-md-8'>
            <p className='form-control-static'>{ formatter.text(get(expenseAccount, 'bankBranch.name', null), '未設定') }</p>
          </div>
        </div>
        <div className='row'>
          <label className='col-sm-3 col-md-3 control-label'>口座種類</label>
          <div className='col-sm-offset-1 col-md-offset-1 col-sm-8 col-md-8'>
            <p className='form-control-static'>{ formatter.text(expenseAccount.type, '不明') }</p>
          </div>
        </div>
        <div className='form-group'>
          <label className='col-sm-3 col-md-3 control-label'>口座番号</label>
          <div className='col-sm-offset-1 col-md-offset-1 col-sm-8 col-md-8'>
            <p className='form-control-static'>{ formatter.text(expenseAccount.number, '未設定') }</p>
          </div>
        </div>
        <div className='form-group'>
          <label className='col-sm-3 control-label'>名義人カナ</label>
          <div className='col-sm-offset-1 col-md-offset-1 col-sm-8 col-md-8'>
            <p className='form-control-static'>{ formatter.text(expenseAccount.holderKana, '未設定') }</p>
          </div>
        </div>
        { this.renderCompanyForm() }
      </div>
    );
  }

  /**
   * 委託者コード欄
   */
  renderCompanyForm() {
    if (!this.props.forCompany) {
      return null;
    }

    return (
      <div className='form-group'>
        <label className='col-sm-3 col-md-3 control-label'>委託者コード</label>
        <div className='col-sm-offset-1 col-md-offset-1 col-sm-8 col-md-8'>
          <p className='form-control-static'>{ formatter.text(this.props.expenseAccount.customerCode, '未設定') }</p>
        </div>
      </div>
    );
  }
}

ExpenseAccountViewer.defaultProps = {
  forCompany: false,
};

ExpenseAccountViewer.propTypes = {
  forCompany: PropTypes.bool.isRequired,
  expenseAccount: PropTypes.shape({
    bank: PropTypes.shape({
      name: PropTypes.string,
    }),
    bankBranch: PropTypes.shape({
      name: PropTypes.string,
    }),
    type: PropTypes.string,
    number: PropTypes.string,
    holderKana: PropTypes.string,
    customerCode: PropTypes.string,
  }).isRequired,
};
