import * as ActionTypes from '../actions/agents';
import _get from 'lodash/get';

const initialState = {
  formData: {
    id: void 0, // 編集時には、AgentのIDが入る
    // 選択されているユーザ（代理人）
    user: {
      id: void 0,
      email: void 0,
      name: '',
    },
    isRequestable: false, // 代理申請可能フラグ
    isApprovable: false, // 代理承認可能フラグ
    isPaymentRequestReportRequestable: false, // 支払依頼申請作成・申請可能フラグ
    isPaymentRequestReportApprovable: false, // 支払依頼申請承認可能フラグ
    isPaymentRequestProposalRequestable: false, // 稟議作成・申請可能フラグ
    isPaymentRequestProposalApprovable: false, // 稟議承認可能フラグ
  },
  agents: [],
  modalState: {
    isAgentModalOpen: false,
    isAssignModalOpen: false,
    isDeleteConfirmationModalOpen: false,
  },
  inProcess: false,
  currentPage: 1,
  sizePerPage: 10,
  searchText: '',
};

// TODO: tsに移行する
function detectAuthorityFormDataKey(authType) {
  switch (authType) {
    case 'request':
      return 'isRequestable';
    case 'approve':
      return 'isApprovable';
    case 'invoice-report-request':
      return 'isPaymentRequestReportRequestable';
    case 'invoice-report-approve':
      return 'isPaymentRequestReportApprovable';
    case 'invoice-proposal-request':
      return 'isPaymentRequestProposalRequestable';
    case 'invoice-proposal-approve':
      return 'isPaymentRequestProposalApprovable';
    default:
      return '';
  }
}

const formData = (state = initialState.formData, action) => {
  switch (action.type) {
    case ActionTypes.SELECT_AGENT: {
      return {
        id: _get(action.data, 'id'),
        user: {
          ...state.user,
          id: _get(action.data, 'id'),
          name: _get(action.data, 'name'),
          email: _get(action.data, 'email'),
          departments: _get(action.data, 'departments'),
        },
        isRequestable: _get(action.data, 'isRequestable'),
        isApprovable: _get(action.data, 'isApprovable'),
        isPaymentRequestReportRequestable: _get(action.data, 'isPaymentRequestReportRequestable'),
        isPaymentRequestReportApprovable: _get(action.data, 'isPaymentRequestReportApprovable'),
        isPaymentRequestProposalRequestable: _get(action.data, 'isPaymentRequestProposalRequestable'),
        isPaymentRequestProposalApprovable: _get(action.data, 'isPaymentRequestProposalApprovable'),
      };
    }
    case ActionTypes.SET_AGENT_INPUT: {
      return {
        ...state,
        user: {
          id: void 0,
          email: void 0,
          name: action.value,
        },
      };
    }
    case ActionTypes.SET_AUTHORITY: {
      const key = detectAuthorityFormDataKey(action.authType);
      return key
        ? {
          ...state,
          [key]: action.value,
        }
        : state;
    }
    default: {
      return state;
    }
  }
};

const agents = (state = initialState, action) => {
  switch (action.type) {
    case ActionTypes.SET_AGENTS: {
      return {
        ...state,
        agents: [...action.data],
      };
    }
    case ActionTypes.SELECT_AGENT:
    case ActionTypes.SET_AGENT_INPUT:
    case ActionTypes.SET_AUTHORITY: {
      return {
        ...state,
        formData: formData(state.formData, action),
      };
    }
    case ActionTypes.TOGGLE_ASSIGN_MODAL: {
      // 代理人設定モーダルを開くタイミングで、フォームの値はリセットする
      return {
        ...state,
        formData: {
          ...initialState.formData,
        },
        modalState: {
          ...state.modalState,
          isAssignModalOpen: action.show,
        },
      };
    }
    case ActionTypes.TOGGLE_DELETE_CONFIRMATION_MODAL: {
      return {
        ...state,
        modalState: {
          ...state.modalState,
          isDeleteConfirmationModalOpen: action.show,
        },
      };
    }
    case ActionTypes.TOGGLE_AGENTS_MODAL: {
      return {
        ...state,
        modalState: {
          ...state.modalState,
          isAgentModalOpen: action.show,
        },
      };
    }
    case ActionTypes.TOGGLE_FORM_BUTTON: {
      return {
        ...state,
        inProcess: action.disabled,
      };
    }
    case ActionTypes.SET_CURRENT_PAGE: {
      return {
        ...state,
        currentPage: action.page,
      };
    }
    case ActionTypes.SET_SIZE_PER_PAGE: {
      return {
        ...state,
        sizePerPage: action.sizePerPage,
      };
    }
    case ActionTypes.SET_SEARCH_TEXT: {
      return {
        ...state,
        searchText: action.searchText,
      };
    }
    default: return state;
  }
};

export default agents;
