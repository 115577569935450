import SimpleBalloon from "components/simple_balloon";
import i18next from "i18n";
import isNil from "lodash/isNil";
import React from "react";
import { Popover } from "react-bootstrap";
import { Expense } from "utilities/api/models";
import Formatter from "utilities/formatter";
import { IconWithOverlay } from "./IconWithOverlay";

type Props = Pick<Expense, "id" | "alerts" | "warnings" | "receiptImages">;

/** アラート/タイムスタンプアイコン */
export const AlertTimeStampIconsWithOverlay: React.FC<Props> = ({
  id,
  alerts,
  warnings,
  receiptImages,
}) => {
  const formatAlertIcon = (): JSX.Element | null => {
    const messages = alerts
      .concat(warnings)
      .map((a, i) => <div key={i}>{a.message}</div>);

    // エラー表示(申請不可)
    if (alerts.length > 0) {
      return (
        <SimpleBalloon
          popoverId={`alert-popup-${id}`}
          placement="top"
          icon="fa-exclamation-circle"
          color="danger"
          message={messages}
        ></SimpleBalloon>
      );
    }

    // 警告表示
    if (warnings.length > 0) {
      return (
        <SimpleBalloon
          popoverId={`warn-popup-${id}`}
          placement="top"
          icon="fa-exclamation-circle"
          color="warning"
          message={messages}
        ></SimpleBalloon>
      );
    }

    return null;
  };

  const detailsPopover = (
    prefix,
    title,
    value,
    formatter = (v): typeof v => v,
  ): JSX.Element => {
    return (
      <Popover id={`${prefix}-${id}`} placement="left" title={title}>
        <div style={{ wordWrap: "break-word" }}>{formatter(value)}</div>
      </Popover>
    );
  };

  const renderTimeStamps = (timeStamps): JSX.Element[] => {
    let list: JSX.Element[] = [];
    const foresideLabel = (
      <div key={"foreside-label"} style={{ fontWeight: "bold" }}>
        {i18next.t("timeStamps.foreside")}
      </div>
    );
    const backsideLabel = (
      <div key={"backside-label"} style={{ fontWeight: "bold" }}>
        {i18next.t("timeStamps.backside")}
      </div>
    );

    const foresideLength = timeStamps.foreside.length;
    const foresides = timeStamps.foreside.map(
      ({ timeStamp, uploadedBy }, i) => (
        <div key={`foreside-${i}`}>
          <span className="time-stamp-signed-at">{`Ver.${
            foresideLength - i
          }`}</span>
          <span>{Formatter.datetime(timeStamp.stampedAt * 1000)}</span>
          {isNil(uploadedBy) ? null : (
            <span>{Formatter.text(`  by ${uploadedBy.name}`)}</span>
          )}
        </div>
      ),
    );
    const backsideLength = timeStamps.backside.length;
    const backsides = timeStamps.backside.map(
      ({ timeStamp, uploadedBy }, i) => (
        <div key={`backside-${i}`}>
          <span className="time-stamp-signed-at">{`Ver.${
            backsideLength - i
          }`}</span>
          <span>{Formatter.datetime(timeStamp.stampedAt * 1000)}</span>
          {isNil(uploadedBy) ? null : (
            <span>{Formatter.text(`  by ${uploadedBy.name}`)}</span>
          )}
        </div>
      ),
    );

    if (foresides.length > 0) {
      list = list.concat([foresideLabel]);
      list = list.concat(foresides);
    }
    if (backsides.length > 0) {
      list = list.concat([backsideLabel]);
      list = list.concat(backsides);
    }

    return list;
  };

  const formatTimeStampIcon = (): JSX.Element | null => {
    if (isNil(receiptImages)) {
      return null;
    }

    const timeStamps = Object.keys(receiptImages).reduce(
      (acc, side) => ({
        ...acc,
        [side]: receiptImages[side]
          .filter((x) => !isNil(x.timeStamp))
          .map((x) => ({ timeStamp: x.timeStamp, uploadedBy: x.uploadedBy })),
      }),
      {},
    ) as Expense["receiptImages"];

    if (timeStamps.foreside.length === 0 && timeStamps.backside.length === 0) {
      return null;
    }

    return (
      <IconWithOverlay
        overlay={detailsPopover(
          "time-stamps",
          i18next.t("timeStamps.timeStamp"),
          timeStamps,
          renderTimeStamps,
        )}
        icon={"fas fa-certificate"}
      />
    );
  };

  return (
    <div className="icons">
      {formatAlertIcon()}
      {formatAlertIcon() && formatTimeStampIcon() && " "}
      {formatTimeStampIcon()}
    </div>
  );
};
