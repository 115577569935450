import Api from 'utilities/api';
import React, { useEffect, useState } from 'react';
import Select from 'react-select';
import i18next from 'i18n';
import styled from 'styled-components';
import {
  Alert,
  ControlLabel,
  FormGroup,
  HelpBlock,
} from 'react-bootstrap';
import {
  Controller,
  FieldErrors,
  useFormContext,
  useWatch,
} from 'react-hook-form';
import { FileTransferLocation } from 'utilities/api/models/accountingDataScheduledExports';
import { RequiredIcon } from '../../../../components/icons/RequiredIcon';

interface Props {
  name: string;
}

const FormGroupView = styled(FormGroup)`
  .form-control:focus {
    outline: none;
    box-shadow: none;
    border: 2px solid #004acc;
    background-color: #ffffff;
  }
  &.has-error div[class$="-control"] {
    border-color: #d9534f;
    box-shadow: none;
  }
  &.has-error .control-label {
    color: #d9534f;
  }
  &.has-error .help-block {
    color: #d9534f;
  }
`;

type ValidationState = 'error' | 'warning' | null | undefined;

const validationState = (error: FieldErrors | undefined): ValidationState => {
  return error ? 'error' : null;
};

export const FileTransferLocationSelectField: React.FC<Props> = ({
  name,
}) => {
  const { control } = useFormContext();

  const [processing, setProcessing] = useState<boolean>(false);
  const [fileTransferLocations, setFileTransferLocations] = useState<FileTransferLocation[]>([]);
  const watchedEnableFileTransfer = useWatch({
    control,
    name: 'enableFileTransfer',
  });

  useEffect(() => {
    const fetchFileTransferLocations = async (): Promise<void> => {
      setProcessing(true);

      try {
        const res = await Api.accountingDataScheduledExports.fileTransferLocations.index();
        setFileTransferLocations(res.data);
      } catch (e) {
        // nothing to do
      } finally {
        setProcessing(false);
      }
    };
    fetchFileTransferLocations();
  }, [setProcessing, setFileTransferLocations]);

  if (processing) {
    return (<div>{ i18next.t('accountingDataScheduledExports.tasks.forms.transfer.fileTransferLocation.loading') }</div>);
  }

  if (fileTransferLocations.length === 0) {
    return (<Alert bsStyle='danger'>{ i18next.t('accountingDataScheduledExports.tasks.forms.transfer.fileTransferLocation.notFound') }</Alert>);
  }

  return (
    <Controller
      name={ name }
      rules={ watchedEnableFileTransfer ? { required: i18next.t<string>('accountingDataScheduledExports.tasks.forms.transfer.fileTransferLocation.required') } : {} }
      control={ control }
      shouldUnregister={ true }
      render={ ({ field: { onChange, value, ref: inputRef }, fieldState: { error } }): React.ReactElement => (
        <FormGroupView validationState={ validationState(error) }>
          <ControlLabel>{ i18next.t('accountingDataScheduledExports.tasks.forms.transfer.fileTransferLocation.label') } <RequiredIcon /></ControlLabel>
          <Select
            inputRef={ inputRef }
            options={ fileTransferLocations }
            value={ fileTransferLocations?.find((c) => c.id === value?.id) }
            getOptionLabel={ (v: FileTransferLocation): string => v.name }
            getOptionValue={ (v: FileTransferLocation): string => v.id }
            onChange={ onChange }
            menuPlacement='top'
            isClearable
          />
          { error && <HelpBlock>{ error.message }</HelpBlock> }
        </FormGroupView>
      ) }
    />
  );
};
