import i18next from "i18n";
import PropTypes from "prop-types";
import React from "react";
import StationSuggest from "./StationSuggest";

const RelaysField = (props) => {
  return (
    <div className={props.className}>
      {props.data.map((x) => {
        return (
          <div className="via-form-set" key={x.id}>
            <StationSuggest
              ownerId={props.ownerId}
              includeTrain={props.includeTrain}
              includeBus={props.includeBus}
              includePlane={props.includePlane}
              text={x.text}
              value={x.value}
              onTextChange={props.onTextChange.bind(null, x.id)}
              onSelect={props.onSelect.bind(null, x.id)}
            />

            <i
              className="fa fa-times fa-2x danger txt-pointer close-icon"
              onClick={props.onRemove.bind(null, x.id)}
            />
          </div>
        );
      })}

      <button
        type="button"
        className="btn btn-success"
        onClick={props.onAppend}
      >
        {i18next.t("transactions.inputs.addVia")}
      </button>
    </div>
  );
};

RelaysField.defaultProps = {
  onAppend() {},
  onRemove(_id) {},
  /**
   * @param {string} _id - 経由駅の（入力フィールドの）ID
   * @param {string} _text - 駅名の文字列
   */
  onTextChange(_id, _text) {},
  /**
   * @param {string} _id - 経由駅の（入力フィールドの）ID
   * @param {object} _station - 経由駅
   */
  onSelect(_id, _station) {},
};

RelaysField.propTypes = {
  ownerId: PropTypes.string,
  data: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired, // 同一性を担保するための適当なID
      text: PropTypes.string.isRequired, // 入力文字列
      value: PropTypes.object, // 選択された経由駅
    }).isRequired,
  ).isRequired,
  includeBus: PropTypes.bool,
  includeTrain: PropTypes.bool,
  includePlane: PropTypes.bool,
  onAppend: PropTypes.func.isRequired,
  onRemove: PropTypes.func.isRequired,
  onTextChange: PropTypes.func.isRequired,
  onSelect: PropTypes.func.isRequired,
};

export default RelaysField;
