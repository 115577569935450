import React from 'react';
import i18next from 'i18n';
import { BottomLogo } from './components/BottomLogo';
import { Notification } from './components/Notification';
import { PageLinks } from './components/PageLinks';
import { ResetPasswordForm } from './components/ResetPasswordForm';
import { SignInTitle } from './components/SignInTitle';

export const pageLinks = [
  {
    name: i18next.t('logins.pageLinks.backToSignIn'),
    href: '/users/sign_in',
  },
  {
    name: i18next.t('logins.pageLinks.reSendInvitation'),
    href: '/users/confirmation/new',
  },
];

interface Props {
  resetPasswordToken: string;
  // フロントでバリデーションしているため、基本降りてくるerrorsは"再設定済み"のみ
  errors: string[];
}

/**
  @note
  ResetPasswordForm.tsxのテスト(testing-library)で、JSDomがform.submit()を実装していない為、
  propsで親から渡す構成にし、テスト内ではonSubmitにsinon.spy()を渡すことで代用。
 */
const handleSubmit = (form: HTMLFormElement): void => {
  form.submit();
};

export const ResetPasswordPage: React.FC<Props> = (props) => {
  const resetPasswordToken = props.resetPasswordToken;
  const errors = props.errors;

  return (
    <div>
      <SignInTitle pageExplanation={ i18next.t('logins.pageTitles.resetPassword') } />
      { errors.map((error) => {
        return (
          <Notification key={ error } notification={ { message: error, className: 'txt-caution' } } />
        );
      }) }
      <ResetPasswordForm onSubmit={ handleSubmit } resetPasswordToken={ resetPasswordToken } />
      <PageLinks pageLinks={ pageLinks } />
      <BottomLogo />
    </div>
  );
};
