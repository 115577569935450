import isNil from 'lodash/isNil';

const prefix = 'payment';

export const SUBMIT_FORM = `${prefix}/SUBMIT_FORM`;
export function collectStripeParams(formData) {
  return {
    number: formData.cardNum,
    cvc: formData.securityCode,
    'exp_month': formData.expiryMonth,
    'exp_year': formData.expiryYear,
  };
}

export function initCsrfToken() {
  const token = $('meta[name="csrf-token"]').attr('content');

  return (dispatch, getState) => {
    const { csrfToken } = getState();
    if (!csrfToken) {
      dispatch(setCsrfToken(token));
    }
  };
}

export function submitForm() {
  return (dispatch, getState) => {
    dispatch(lockFormButton());
    dispatch(initCsrfToken());
    const { formData } = getState();
    const params = collectStripeParams(formData);
    Stripe.card.createToken(params, stripeResponseHandler.bind(null, dispatch));
  };
}

export function submitToken() {
  return (dispatch, getState) => {
    const { formData, planId, csrfToken } = getState();
    const $form = $('#payment-form');
    $form.append($('<input type="hidden" name="plan" />').val(planId));
    $form.append($('<input type="hidden" name="stripeToken" />').val(formData.stripeToken));
    $form.append($('<input type="hidden" name="authenticity_token" />').val(csrfToken));
    $form.get(0).submit();
  };
}

export function stripeResponseHandler(dispatch, status, response) {
  const $form = $('#payment-form');
  if (response.error) {
    let message = null;
    if (response.error.code === 'invalid_number') {
      message = 'カード番号が正しくありません。';
    } else if (response.error.code === 'invalid_expiry_year') {
      message = '有効期限が正しくありません。';
    } else if (response.error.code === 'invalid_cvc') {
      message = 'セキュリティーコードが正しくありません。';
    } else {
      message = '入力情報が正しくありません';
    }
    dispatch(setErrorMessage(message));
    dispatch(unlockFormButton());
  } else {
    const token = response.id;
    if (isNil(token) || token.length === 0) {
      Stripe.card.createToken($form, stripeResponseHandler);
      return;
    }

    dispatch(clearErrorMessage());
    dispatch(setFormData('stripeToken', token));
    dispatch(submitToken());
  }
}

export const SET_CSRF_TOKEN = `${prefix}/SET_CSRF_TOKEN`;
export function setCsrfToken(csrfToken) {
  return {
    type: SET_CSRF_TOKEN,
    csrfToken,
  };
}

export const SWITCH_PLAN_TAB = `${prefix}/SWITCH_PLAN_TAB`;
export function switchPlanTab(plan) {
  return {
    type: SWITCH_PLAN_TAB,
    plan,
  };
}

export const SELECT_PLAN = `${prefix}/SELECT_PLAN`;
export function selectPlan(planId) {
  return {
    type: SELECT_PLAN,
    id: planId,
  };
}

export const SET_ERROR_MESSAGE = `${prefix}/SET_ERROR_MESSAGE`;
export function setErrorMessage(message) {
  return {
    type: SET_ERROR_MESSAGE,
    message,
  };
}

export function clearErrorMessage() {
  return {
    type: SET_ERROR_MESSAGE,
    message: null,
  };
}

export const SET_FORM_DATA = `${prefix}/SET_FORM_DATA`;
export function setFormData(prop, value) {
  return {
    type: SET_FORM_DATA,
    prop,
    value,
  };
}

export const setCardNum = setFormData.bind(null, 'cardNum');
export const setSecurityCode = setFormData.bind(null, 'securityCode');
export const setExpiryMonth = setFormData.bind(null, 'expiryMonth');
export const setExpiryYear = setFormData.bind(null, 'expiryYear');

export const TOGGLE_PAYMENT_FORM_MODAL = `${prefix}/TOGGLE_PAYMENT_FORM_MODAL`;
export function openPaymentFormModal() {
  return {
    type: TOGGLE_PAYMENT_FORM_MODAL,
    show: true,
  };
}

export function closePaymentFormModal() {
  return {
    type: TOGGLE_PAYMENT_FORM_MODAL,
    show: false,
  };
}

export const TOGGLE_FORM_BUTTON = `${prefix}/TOGGLE_FORM_BUTTON`;
export function lockFormButton() {
  return {
    type: TOGGLE_FORM_BUTTON,
    lock: true,
  };
}

export function unlockFormButton() {
  return {
    type: TOGGLE_FORM_BUTTON,
    lock: false,
  };
}
