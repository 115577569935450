import PropTypes from 'prop-types';
import React, { Component } from 'react';
import i18next from 'i18n';

export default class TeikiInformationField extends Component {
  renderVia(via, index) {
    if (index % 2) {
      return (
        <span key={ `${via}-${index}` }>
          <span className='teiki-info-txt'>{ via }</span>
          <span><i className="fas fa-long-arrow-alt-right teiki-info-txt"/></span>
        </span>
      );
    }
    return (
      <span key={ `${via}-${index}` }>
        <span className='teiki-info-txt'>({ via })</span>
        <span><i className="fas fa-long-arrow-alt-right teiki-info-txt"/></span>
      </span>
    );
  }

  renderTeiki() {
    if (this.props.teikiRoute.start && this.props.teikiRoute.goal) {
      return (
        <div className='col-sm-6 teiki-info-area'>
          <span className='teiki-info-txt'>{ this.props.teikiRoute.start }</span>
          <span><i className="fas fa-long-arrow-alt-right teiki-info-txt"/></span>
          {
            this.props.teikiRoute.via.map((via, index) => this.renderVia(via, index))
          }
          <span className='teiki-info-txt'>{ this.props.teikiRoute.goal }</span>
        </div>
      );
    }
    return (
      <div className='col-sm-6 teiki-info-area'>
        <div className='txt-center'>{ i18next.t('commons.actions.unset') }</div>
      </div>
    );
  }

  render() {
    return (
      <div className='form-group'>
        <label className='col-sm-3 control-label'>{ i18next.t('preferences.teiki.route') }</label>
        { this.renderTeiki() }
      </div>
    );
  }
}

TeikiInformationField.propTypes = {
  teikiRoute: PropTypes.object,
};
