import Formatter from 'utilities/formatter';
import ListSelector from 'components/list_selector';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import get from 'lodash/get';

export default class CustomSuggestInput extends Component {
  inputValue(x) {
    const { enableFreeInput } = this.props;

    return enableFreeInput ? x : get(x, 'displayName', null);
  }

  itemFormat(value) {
    return (
      <div className='list-selector-item-content'>
        { get(value, 'displayName', null) }
      </div>
    );
  }

  handleTextChange(e, total) {
    const value = e.target.value;
    this.props.onTextChange(get(value, 'length', 0) > 0 ? value : null, total);
  }

  render() {
    const {
      field, enableFreeInput, formValues, customInput, searchText, suggestions: { total, current },
    } = this.props;

    if (this.props.disabled || !field.editable) {
      return (
        <input type='text'
          className='form-control toggle'
          value={ this.inputValue(customInput) }
          disabled={ true }/>
      );
    }

    const search = {
      searchPlaceholder: `${field.label}を入力`,
      inputText: enableFreeInput ? customInput : searchText,
      onTextChange: this.handleTextChange.bind(this),
    };
    const params = {}

    const validateFunc = field.isRequired ? `custom-${field.id} validate[funcCallRequired[validateCustomValue]]` : '';
    return (
      <div className={ `list-selector ${validateFunc} form-control` }>
        <ListSelector
          type='radio'
          buttonFormat={ (x) => Formatter.text(this.inputValue(x)) }
          titleFormat={ () => `${field.label}を${enableFreeInput ? '入力' : '選択'}` }
          itemFormat={ this.itemFormat.bind(this) }
          items={ current }
          value={ customInput }
          onSelect={ this.props.onSelect }
          onShowModal={ this.props.fetchSuggestions.bind(this, params, enableFreeInput ? customInput : null) }
          search={ search }
          disabled={ this.props.disabled || !field.editable }/>
      </div>
    );
  }
}

CustomSuggestInput.defaultProps = {
  disabled: false,
  enableFreeInput: false, // falseの時の動作をしっかりチェックしてないので注意
};

CustomSuggestInput.propTypes = {
  field: PropTypes.object.isRequired,
  disabled: PropTypes.bool.isRequired,
  enableFreeInput: PropTypes.bool.isRequired,

  fetchSuggestions: PropTypes.func.isRequired,
  onTextChange: PropTypes.func.isRequired,
  onSelect: PropTypes.func.isRequired,
  clearInput: PropTypes.func.isRequired,
};
