import { displayMessage } from "actions/ActionCreators";
import { ThunkDispatch } from "tdispatch";
import Api from "utilities/api";
import { getMessageFromResponse } from "utilities/Utils";
import { reload as fetchApplication } from "./applicationActions";
import {
  closeCancelApprovalModal,
  setCancelApprovalComment,
} from "./applicationRequestActions";

/**
 * 承認を取り消します。
 * @param {string} id 申請ID
 * @param {string} comment コメント
 */
export function cancelApproval(id: string, comment: string) {
  return async (dispatch: ThunkDispatch): Promise<void> => {
    try {
      const { message } = await Api.requests.cancelApprovals({ id, comment });
      await dispatch(fetchApplication());
      dispatch(setCancelApprovalComment(""));
      dispatch(closeCancelApprovalModal());
      dispatch(displayMessage("success", message));
    } catch (e) {
      dispatch(displayMessage("error", getMessageFromResponse(e)));
    }
  };
}
