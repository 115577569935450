import get from "lodash/get";
import { createSelector } from "reselect";

const getSuggestions = (state, props) =>
  get(state, `suggestions.${props.field.id}`, { total: [], current: [] });
const getFormValues = (state, props) => get(state, "preReport.formValues");
const getSearchText = (state, props) =>
  get(state, `preReport.formValues.searchText-${props.field.id}`);
const getCustomInput = (state, props) =>
  get(state, `preReport.formValues.${props.field.id}`);

const makeCustomInputStateMapper = () =>
  createSelector(
    [getSuggestions, getFormValues, getSearchText, getCustomInput],
    (suggestions, formValues, searchText, customInput) => {
      return {
        enableFreeInput: true,
        suggestions,
        formValues,
        searchText,
        customInput,
      };
    },
  );

export default makeCustomInputStateMapper;
