import React, { useMemo } from 'react';
import i18next from 'i18next';
import { Category } from 'utilities/api/models';
import { DebitInvoiceCategory } from 'utilities/api_payment_requests/models';
import { InvoiceCategoryOption } from './fields/categories/payment_requests/hooks/useInvoiceCategorySelectorState';

type DebitInvoiceCategoryOptionWithIsSelected = InvoiceCategoryOption<DebitInvoiceCategory> & { isSelected: boolean }
type CategoryOptionWithIsSelected = Category & { isSelected: boolean }

export type OptionsWithIsSelected = DebitInvoiceCategoryOptionWithIsSelected[] | CategoryOptionWithIsSelected[]

interface Props {
  options: OptionsWithIsSelected;
  onSelectAll: (checked: boolean, options:OptionsWithIsSelected) => void;
  styledCheckBoxAll?: React.CSSProperties;
  labelCheckBoxAll?: string;
}

export const SelectAllCheckBox: React.FC<Props> = ({
  options, onSelectAll, styledCheckBoxAll = {}, labelCheckBoxAll,
}) => {
  const checked: boolean = useMemo(() => !options.some((c) => c.isSelected === false), [options]);

  if (!options.length) return <></>;

  const onClick = (e): void => {
    e.stopPropagation();
    onSelectAll(!checked, options);
  };

  const renderCheckBox = (): JSX.Element => {
    const iconClass = checked ? 'fa-check-square' : 'fa-square';

    return (
      <i
        className={ `far fa-fw fa-lg ${iconClass}` }
        onClick={ onClick }
      />
    );
  };

  return (
    <div className='select-all-check-box' style={ styledCheckBoxAll }>
      <div className='name-area'>
        { labelCheckBoxAll || i18next.t('genericFields.dataSets.usingCategoriesParam.checkAll') }
      </div>
      <div className='check-all-required-area'>
        { renderCheckBox() }
      </div>
    </div>
  );
};
