import {
  hasFailedReceiptImage,
  hasFailedToSignReceiptImage,
} from "applications/transactions/utilities/transactionFormPolicy";
import Thumbnail from "components/attached_files/Thumbnail";
import i18next from "i18n";
import React from "react";
import { Popover } from "react-bootstrap";
import { Expense } from "utilities/api/models";
import { IconWithOverlay } from "./IconWithOverlay";

type Props = Pick<Expense, "id" | "receiptImages">;

/** 添付領収書画像アイコン */
export const ImageIconWithOverlay: React.FC<Props> = ({
  receiptImages,
  id,
}) => {
  const detailsPopover = (
    prefix,
    title,
    value,
    formatter = (v): typeof v => v,
  ): JSX.Element => {
    return (
      <Popover id={`${prefix}-${id}`} placement="left" title={title}>
        <div style={{ wordWrap: "break-word" }}>{formatter(value)}</div>
      </Popover>
    );
  };

  const imagePopover = (image): JSX.Element => {
    return (
      <Popover
        id={`image-popup-${id}`}
        placement="left"
        className="popup-image"
      >
        <Thumbnail
          id={image.id}
          mimeType={image.contentType}
          width={200}
          minHeight={200}
          maxHeight={300}
          rotation={image.rotation}
          onClick={(): void => void 0}
          className="popup-image-thumbnail"
        />
      </Popover>
    );
  };

  const formatImage = (): JSX.Element | null => {
    if (receiptImages.foreside.length === 0) return null;

    if (hasFailedToSignReceiptImage(receiptImages)) {
      // 署名に失敗している画像がある場合
      return (
        <IconWithOverlay
          overlay={detailsPopover(
            "failedUpload",
            i18next.t("transactions.properties.image"),
            i18next.t("transactions.errors.failedToSignTimeStamp"),
            (c) =>
              c
                .split(/\r?\n|\\n/g)
                .map((string, index) => <div key={index}>{string}</div>),
          )}
          icon={"fas fa-question"}
        />
      );
    }

    if (hasFailedReceiptImage(receiptImages)) {
      // 失敗している画像がある場合
      return (
        <IconWithOverlay
          overlay={detailsPopover(
            "failedUpload",
            i18next.t("transactions.properties.image"),
            i18next.t("transactions.errors.failedToUploadImage"),
            (c) =>
              c
                .split(/\r?\n|\\n/g)
                .map((string, index) => <div key={index}>{string}</div>),
          )}
          icon={"fas fa-question"}
        />
      );
    }

    const file = receiptImages.foreside[0];

    return (
      <IconWithOverlay overlay={imagePopover(file)} icon={"fas fa-image"} />
    );
  };

  return <>{formatImage()}</>;
};
