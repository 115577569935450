import i18next from "i18n";
import React, { useEffect, useState } from "react";
import { AsyncPaginate } from "react-select-async-paginate";
import { ColourOption } from "react-select/async";
import ApiV3 from "utilities/api_v3";
import { PersonalCategory } from "utilities/api_v3/models/PersonalCategory";
import flash from "utilities/flash";
import { getMessageFromResponse, snakecaseKeys } from "utilities/Utils";
import { Category, GroupedCategory } from "../../types";
import {
  convertV3ResponseToV1Response,
  groupedCategories,
} from "../../utilities";

interface Props {
  /**
   * 経費科目一覧 (Api v1 で取得した経費科目一覧)
   * キャッシュしている経費科目一覧があり、それを使いまわしたい場合に指定
   */
  categories?: Category[];
  /** 分割元の経費科目 */
  categoryId: string;
  /** 選択時のハンドラ */
  onChange: (category: Category | null) => void;
}

interface CategoryOption {
  options: (Category | GroupedCategory)[];
  hasMore: boolean;
}

/** 経費科目選択欄 */
export const CategoryAsyncSelect: React.FC<Props> = ({
  categories,
  categoryId,
  onChange,
}) => {
  // デフォルトの選択候補
  const [defaultOptions, setDefaultOptions] = useState<
    (Category | GroupedCategory)[]
  >([]);
  // 選択されている経費科目
  const [selectedCategory, setSelectedCategory] =
    useState<PersonalCategory | null>();
  const [totalCategorys, setTotalCategorys] = useState<number | null>(null);

  /**
   * props の categories が与えられなかった場合に限り、
   * デフォルト値をフェッチしてきて初期化処理を実施する
   */
  useEffect(() => {
    if (categories && categories.length !== 0) {
      setDefaultOptions(groupedCategories(categories));
    } else {
      ApiV3.personalCategories
        .index(
          snakecaseKeys({
            selectable: true,
            includeAncestors: true,
            limit: 30,
          }),
        )
        .then((response) => {
          setTotalCategorys(response.pagination.count);
          setDefaultOptions(
            response.data
              .map((v: PersonalCategory) => convertV3ResponseToV1Response(v))
              .map((category: Category) =>
                // 階層構造下でない (親が存在しない) 場合は、ラベルの表記は実施しない
                category.name !== category.absolutePathName
                  ? { label: category.absolutePathName, options: [category] }
                  : category,
              ),
          );
        })
        .catch((e) => {
          flash.error(getMessageFromResponse(e));
        });
    }
  }, [categories]);

  /** 経費科目のIDが分かっている場合、その ID を元にしてデータを取得する */
  useEffect(() => {
    if (categoryId !== "") {
      if (categoryId === undefined) {
        flash.error(i18next.t("expenses.split.selectCategoryTitle"));
      } else {
        ApiV3.personalCategories
          .show({ id: categoryId })
          .then((response: PersonalCategory) => {
            setSelectedCategory(response);
          })
          .catch((e) => {
            flash.error(getMessageFromResponse(e));
          });
      }
    }
  }, [categoryId]);

  /**
   * 文字入力時に非同期で候補取得
   * @param inputValue 入力欄に書き込まれている文字列
   * @see https://react-select.com/async
   */
  const getCategories = (
    inputValue: string,
    offset = 0,
  ): Promise<ColourOption[]> => {
    return ApiV3.personalCategories
      .index(
        snakecaseKeys({
          selectable: true,
          name: inputValue,
          includeAncestors: true,
          limit: 30,
          offset,
        }),
      )
      .then((response) => {
        setTotalCategorys(response.pagination.count);
        return response.data
          .map((v: PersonalCategory) => convertV3ResponseToV1Response(v))
          .map((category: Category) =>
            // 階層構造下でない (親が存在しない) 場合は、ラベルの表記は実施しない
            category.name !== category.absolutePathName
              ? { label: category.absolutePathName, options: [category] }
              : category,
          );
      })
      .catch((e) => {
        flash.error(getMessageFromResponse(e));
        return [];
      });
  };

  const loadOptions = async (
    inputValue: string,
    loadedOptions,
  ): Promise<CategoryOption> => {
    if (loadedOptions.length === 0) {
      const options = await getCategories(inputValue, loadedOptions.length);

      return {
        options: inputValue ? options : defaultOptions,
        hasMore:
          options.length > 0 &&
          (totalCategorys ? totalCategorys > defaultOptions.length : true),
      };
    }

    const options = await getCategories(inputValue, loadedOptions.length);
    const hasMore =
      options.length > 0 &&
      (totalCategorys ? totalCategorys > loadedOptions.length + 30 : false);

    return {
      options,
      hasMore,
    };
  };

  /** 経費科目が選択された際に発火するアクション */
  const onSelectCategory = (category: PersonalCategory): void => {
    setSelectedCategory(category || null);
    onChange(category ? convertV3ResponseToV1Response(category) : null);
  };

  return (
    <div
      title={
        selectedCategory?.name ||
        i18next.t("expenses.split.amountBySeparatorsEditor.categorySelectTitle")
      }
    >
      <AsyncPaginate
        value={selectedCategory}
        cacheOptions
        isClearable
        loadOptions={loadOptions}
        onChange={onSelectCategory}
        getOptionLabel={(o: PersonalCategory): string => o.name || ""}
        getOptionValue={(o: PersonalCategory): string => o.id}
        placeholder={i18next.t("commons.status.notSelected")}
        additional={{ page: 1 }}
      />
    </div>
  );
};
