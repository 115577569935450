interface S3Parameter {
  [key: string]: any; // eslint-disable-line @typescript-eslint/no-explicit-any
}

// S3Uploaderが投げる例外クラス
export default class S3UploadError extends Error {
  constructor(public message: string, public stage: string, public param: S3Parameter, public file: File | null = null, public retryable = true) {
    super(message);

    this.name = this.constructor.name;
    this.stage = stage;
    this.param = param;
    this.file = file;
    this.retryable = retryable;

    if (typeof Error.captureStackTrace === 'function') {
      Error.captureStackTrace(this, this.constructor);
    } else {
      this.stack = (new Error(message)).stack;
    }
  }
}
