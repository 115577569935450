import { HeaderGroup, TableHeaderProps } from 'react-table';

interface UseReturn {
  readonly headerProps: (tableHeaderProps, { column }) => Partial<TableHeaderProps>[];
  readonly onClickHeader: (column: HeaderGroup) => void;
}

/**
 * ロジック: テーブルヘッダー
 */
export const useHooks = (onSortChange?: (sortName: string, sortOrder: 'ASC' | 'DESC') => void): UseReturn => {
  /**
   * 列毎のヘッダーのスタイル
   */
  const headerProps = (tableHeaderProps: TableHeaderProps, { column }): Partial<TableHeaderProps>[] => {
    return [
      tableHeaderProps,
      {
        style: {
          textAlign: column.dataAlign || 'left',
        },
      },
    ];
  };

  /**
   * ヘッダーをクリックした
   */
  const onClickHeader = (column: HeaderGroup): void => {
    // ソートする
    if (!onSortChange || !column.defaultCanSort) return;
    column.toggleSortBy(!column.isSortedDesc);
    onSortChange(column.id, column.isSortedDesc ? 'ASC' : 'DESC');
  };

  return {
    headerProps,
    onClickHeader,
  };
};
