import PropTypes from 'prop-types';


const stationType = PropTypes.shape({
  ekispertCode: PropTypes.number.isRequired,
  name: PropTypes.string.isRequired,
  prefecture: PropTypes.string,
  type: PropTypes.string.isRequired,
  yomi: PropTypes.string.isRequired,
});

export default stationType;
