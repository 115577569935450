import Api from 'utilities/api';
import {
  FetchParams,
  IPreReportExpenseDriver,
  PreReportExpensesResponse,
} from 'apis/IPreReportExpenseDriver';
import { fetchAsyncAll } from 'utilities/async';

export class PreReportExpenseDriver implements IPreReportExpenseDriver {
  /** 事前申請・精算申請に含まれる経費をすべて取得します */
  async fetchAll(apiParams: FetchParams): Promise<PreReportExpensesResponse> {
    const { preReportId, forApprover, expensesSize } = apiParams;

    return await fetchAsyncAll(
      (params) => Api.preReports.expenses({ preReportId, ...params, forApprover })
        .then((result) => {
          return { data: result.expenses, count: result.count };
        }),
      { /* APIを投げる際の追加パラメータは特になし */ },
      { maxFetchSize: expensesSize },
    );
  }
}
