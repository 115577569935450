import i18next from "i18n";
import React from "react";

interface Props {
  disabled: boolean;
  onClick: () => void;
}

/** 汎用申請の取り下げボタン */
export const RecallButton: React.FC<Props> = React.memo(
  ({ disabled, onClick }) => {
    return (
      <button
        className="btn btn-block btn-danger"
        disabled={disabled}
        onClick={(): void => {
          onClick();
        }}
      >
        <i className="fa fa-left fa-paper-plane" />
        {i18next.t("commons.actions.withdraw")}
      </button>
    );
  },
);
