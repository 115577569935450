import * as actions from 'applications/fare_transactions/actions';
import * as teikiRouteActions from '../actions/teikiRoutes';
import LabeledComponent from 'components/LabeledComponent';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import RouteSelector from 'applications/fare_transactions/components/RouteSelector';
import TeikiFare from '../components/TeikiFare';
import i18next from 'i18n';
import { connect } from 'react-redux';

export class TeikiDisplay extends Component {
  constructor(props) {
    super(props);

    this.state = { isModalOpen: false };

    this.handleModalOpen = this.handleModalOpen.bind(this);
    this.handleModalClose = this.handleModalClose.bind(this);
    this.handleSelect = this.handleSelect.bind(this);
  }

  get isTeikiFound() {
    // NOTE: 3, 6ヶ月定期がない時でも、1ヶ月はある、らしい（要出典）
    return this.props.selected && this.props.selected.teiki1;
  }

  get amount1() {
    return (this.props.selected && this.props.selected.teiki1) || 0;
  }

  get amount3() {
    return (this.props.selected && this.props.selected.teiki3) || 0;
  }

  get amount6() {
    return (this.props.selected && this.props.selected.teiki6) || 0;
  }

  formatAmount(amount) {
    return amount === 0 ? '-' : `${amount}`;
  }

  handleSelect(route) {
    this.props.onSelect(route);
    this.handleModalClose();
  }

  handleModalOpen() {
    this.setState({ isModalOpen: true });
  }

  handleModalClose() {
    this.setState({ isModalOpen: false });
  }

  render() {
    return (
      <div>
        {
          this.isTeikiFound && (
            <LabeledComponent className='form-group'
              labelClass='col-sm-3'
              label={ i18next.t('transactions.properties.amount') }
            >
              <div className='col-sm-6'>
                <TeikiFare type='oneMonth' value={ this.amount1 } />
                <TeikiFare type='threeMonths' value={ this.amount3 } />
                <TeikiFare type='sixMonths' value={ this.amount6 } />

                <div className='route-display'>
                  <a className='modal-control' onClick={ this.handleModalOpen }>
                    { i18next.t('transactions.inputs.showOtherRoutes') }
                  </a>
                </div>

                <RouteSelector
                  routes={ this.props.routes }
                  show={ this.state.isModalOpen }
                  closeModal={ this.handleModalClose }
                  onRouteSelect={ this.handleSelect }
                  withTeiki={ true }
                />
              </div>
            </LabeledComponent>
          )
        }
      </div>
    );
  }
}

function mapStateToProps(state, ownProps) {
  const { searchBox } = state;

  return {
    routes: searchBox.routes,
  };
}

function mapDispatchToProps(dispatch, ownProps) {
  return {
    onSelect(route) {
      dispatch(actions.setRoute(route));
      dispatch(teikiRouteActions.setSearchedTeikiRoute(route));
    },
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(TeikiDisplay);


TeikiDisplay.propTypes = {
  selected: PropTypes.object,
  onSelect: PropTypes.func.isRequired,
  routes: PropTypes.array,
};
