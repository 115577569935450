import Formatter from 'utilities/formatter';
import ListSelector from 'components/list_selector';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import escapeRegExp from 'lodash/escapeRegExp';
import i18next from 'i18n';
import isNil from 'lodash/isNil';
import { companyExpenseAccountType, expenseAccountType } from 'types/expense_account';

/**
 * @deprecated システム設定における支払口座から候補を選択する場合は CompanyExpenseAccount を使用してください
 */
export default class ExpenseAccountSelector extends Component {
  constructor(props) {
    super(props);

    this.itemFormat = this.itemFormat.bind(this);
  }

  search(text, items) {
    return items.filter((selection) => {
      const reg = new RegExp(`.*${escapeRegExp(text)}.*`, 'g');
      const bank = selection.bank.name || '';
      const bankBranch = selection.bankBranch.name || '';
      const number = selection.number || '';
      return bank.match(reg)
        || bankBranch.match(reg)
        || number.match(reg)
        || false;
    });
  }

  itemFormat(item) {
    const {
      bank,
      bankBranch,
      customerCode,
      holderKana,
      number,
      type,
    } = item;

    return (
      <div className='expense-account-selector txt-pointer'>
        <div className='bank-name'>
          { `${bank.name}  ${bankBranch.name}` }
        </div>
        <div className='account-info'>
          <div>{ `${i18next.t('preferences.expenseAccount.number')}:${number}` }</div>
          <div>{ !isNil(customerCode) ? `${i18next.t('preferences.expenseAccount.customerCode')}:${customerCode}` : null }</div>
        </div>
        <div className='account-info'>
          <div>{ `${i18next.t('preferences.expenseAccount.holderKana')}:${holderKana}` }</div>
          <div>{ `${i18next.t('preferences.expenseAccount.type')}:${type}` }</div>
        </div>
      </div>
    );
  }

  render() {
    const {
      allowEmpty = false, expenseAccounts, selectedAccount, selectAccount, showSearch, showSaveButton,
    } = this.props;

    const search = {
      placeholder: i18next.t('exports.actions.searchWithAccount'),
      func: this.search.bind(this),
      onTextChange() {},
    };

    return (
      <ListSelector
        allowEmpty={ allowEmpty }
        buttonFormat={ Formatter.expenseAccount }
        disabled={ expenseAccounts.length <= (allowEmpty ? 0 : 1) }
        itemFormat={ this.itemFormat }
        items={ expenseAccounts }
        onSelect={ selectAccount }
        search={ search }
        titleFormat={ () => i18next.t('exports.titles.expenseAccountSelector') }
        type='radio'
        value={ selectedAccount }
        showSearch={ showSearch }
        showSaveButton={ showSaveButton }
      />
    );
  }
}

ExpenseAccountSelector.defaultProps = {
  showSearch: true,
  showSaveButton: true,
};

ExpenseAccountSelector.propTypes = {
  allowEmpty: PropTypes.bool,
  expenseAccounts: PropTypes.arrayOf(
    PropTypes.oneOfType([
      PropTypes.shape(companyExpenseAccountType),
      PropTypes.shape(expenseAccountType),
    ]),
  ).isRequired,
  selectedAccount: PropTypes.oneOfType([
    PropTypes.shape(companyExpenseAccountType),
    PropTypes.shape(expenseAccountType),
  ]),
  selectAccount: PropTypes.func.isRequired,
  showSearch: PropTypes.bool,
  showSaveButton: PropTypes.bool,
};
