import Api from 'utilities/api';
import { camelizeKeys, getMessageFromResponse, snakecaseKeys } from 'utilities/Utils';
import { displayMessage } from 'actions/ActionCreators';
import { fetchAsync } from 'actions/AsyncAction';

const prefix = 'analyses';

export function fetchAggregationByUserJobs() {
  return async (dispatch, getState) => {
    const { analysis } = getState();

    try {
      const data = await Api.exportJobs.index(snakecaseKeys({ jobNames: ['aggregationByUser'], analysisId: analysis.id }));
      dispatch(setAggregationByUserJobs(camelizeKeys(data).aggregationByUserJobs));
    } catch (e) {
      dispatch(displayMessage('error', getMessageFromResponse(e)));
    }
  };
}

export function requestAggregationByUserJobs() {
  return (dispatch, getState) => {
    const { analysis } = getState();

    try {
      Api.exportJobs.create(snakecaseKeys({ jobName: 'aggregationByUser', analysisId: analysis.id }));
    } catch (e) {
      dispatch(displayMessage('error', getMessageFromResponse(e)));
    }
  };
}

export const SET_AGGREGATION_BY_USER_JOBS = `${prefix}/SET_AGGREGATION_BY_USER_JOBS`;
export function setAggregationByUserJobs(aggregationByUserJobs) {
  return {
    type: SET_AGGREGATION_BY_USER_JOBS,
    aggregationByUserJobs,
  };
}

export function fetchAggregationByCategoryJobs() {
  return async (dispatch, getState) => {
    const { analysis } = getState();

    try {
      const data = await Api.exportJobs.index(snakecaseKeys({ jobNames: ['aggregationByCategory'], analysisId: analysis.id }));
      dispatch(setAggregationByCategoryJobs(camelizeKeys(data).aggregationByCategoryJobs));
    } catch (e) {
      dispatch(displayMessage('error', getMessageFromResponse(e)));
    }
  };
}

export function requestAggregationByCategoryJobs() {
  return (dispatch, getState) => {
    const { analysis } = getState();

    try {
      Api.exportJobs.create(snakecaseKeys({ jobName: 'aggregationByCategory', analysisId: analysis.id }));
    } catch (e) {
      dispatch(displayMessage('error', getMessageFromResponse(e)));
    }
  };
}

export const SET_AGGREGATION_BY_CATEGORY_JOBS = `${prefix}/SET_AGGREGATION_BY_CATEGORY_JOBS`;
export function setAggregationByCategoryJobs(aggregationByCategoryJobs) {
  return {
    type: SET_AGGREGATION_BY_CATEGORY_JOBS,
    aggregationByCategoryJobs,
  };
}

export function fetchAnalysisReportXlsJobs() {
  return async (dispatch, getState) => {
    const { analysis } = getState();

    try {
      const data = await Api.analysisReportXlsJobs.index(snakecaseKeys({ jobNames: ['analysisReportXls'], analysisId: analysis.id }));

      dispatch(setAnalysisReportXlsJobs(camelizeKeys(data).analysisReportXlsJobs));
    } catch (e) {
      dispatch(displayMessage('error', getMessageFromResponse(e)));
    }
  };
}

export function requestAnalysisReportXlsJobs() {
  return (dispatch, getState) => {
    const { analysis } = getState();

    try {
      Api.analysisReportXlsJobs.create(snakecaseKeys({ jobName: 'analysisReportXls', analysisId: analysis.id }));
    } catch (e) {
      dispatch(displayMessage('error', getMessageFromResponse(e)));
    }
  };
}

export const SET_ANALYSIS_REPORT_XLS_JOBS = `${prefix}/SET_ANALYSIS_REPORT_XLS_JOBS`;
export function setAnalysisReportXlsJobs(analysisReportXlsJobs) {
  return {
    type: SET_ANALYSIS_REPORT_XLS_JOBS,
    analysisReportXlsJobs,
  };
}

export function fetchAccountingJobs() {
  return async (dispatch, getState) => {
    const { analysis } = getState();
    let params;

    if (analysis.preReports && analysis.preReports.length > 0) {
      params = { jobNames: ['preAccounting'], preAnalysisId: analysis.id };
    } else {
      params = { jobNames: ['accounting', 'journalEntriesForExpenses'], analysisId: analysis.id };
    }

    try {
      const data = await Api.exportJobs.index(snakecaseKeys(params));
      dispatch(setAccountingJobs(camelizeKeys(data).accountingJobs));
    } catch (e) {
      dispatch(displayMessage('error', getMessageFromResponse(e)));
    }
  };
}

export function requestAccountingJobs(formatKey, jobName, targetKey, targetId, transactedAt) {
  return (dispatch) => {
    try {
      Api.exportJobs.create(snakecaseKeys({
        formatKey, jobName, transactedAt, [targetKey]: targetId,
      }));
    } catch (e) {
      dispatch(displayMessage('error', getMessageFromResponse(e)));
    }
  };
}

export function setAnalysisFileExportFlag() {
  return (dispatch, getState) => {
    const { analysis } = getState();
    return dispatch(fetchAsync(Api.analysisReportXlsJobs.exported, { analysisId: analysis.id }));
  };
}

export const SET_ACCOUNTING_JOBS = `${prefix}/SET_ACCOUNTING_JOBS`;
export function setAccountingJobs(accountingJobs) {
  return {
    type: SET_ACCOUNTING_JOBS,
    accountingJobs,
  };
}
