import React from "react";
import { Expense } from "utilities/api/models";

interface Props {
  expense: Expense;
}

/** 電子領収書フラグアイコン */
export const IsElectronicReceiptImageIcon: React.FC<Props> = ({ expense }) => {
  const { isElectronicReceiptImage } = expense;

  if (isElectronicReceiptImage) {
    return (
      <span className="fa-stack">
        <i className="fa fa-check fa-stack-1x" />
      </span>
    );
  }

  return <span />;
};
