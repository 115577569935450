import _ from 'lodash';

// サイドバーのactiveをつけたり消したり
$(() => {
  const hrefs = $('.nav-sidebar a').map(/** @this HTMLElement */ function hrefs() {
    return $(this).attr('href');
  });
  $('.nav-sidebar a').each(/** @this HTMLElement */ function findPath() {
    const href  = $(this).attr('href');
    const child = new RegExp($(this).attr('data-child-path') || '^$');
    // 正規表現に一致しても他のメニューでhrefが一致するものがあればそちらを優先する
    const include = _.includes(hrefs, window.location.pathname);
    if (window.location.pathname === href || (!include && window.location.pathname.match(child))) {
      $(this).addClass('active');
      return false;
    }
    return true;
  });
});
