import Api from "../../../utilities/api";
import { ApproveJob } from "utilities/api_payment_requests/models/ApproveJob";
import { IndexRequest } from "utilities/api_payment_requests/requests/approveJobs";
import { snakecaseKeys } from "utilities/Utils";
import { useCallback } from "react";

interface ApproveJobRepository {
  readonly createApproveJobs: (selectedRows: string[]) => Promise<void>;
  readonly fetchApproveJobs: (request: IndexRequest) =>  Promise<ApproveJob[]>;
}

/**
 * 承認のジョブAPIの管理
 */
export const useApproveJobRepository = (
  createExecutor = Api.requests.approveJobs.bulkCreate,
  indexFetcher = Api.requests.approveJobs.index,
): ApproveJobRepository => {
  /**
   * API: 承認するためのジョブ作成
   */
  const createApproveJobs = useCallback(async (selectedRows: string[]): Promise<void> => {
    const param = { approvalIds: selectedRows };
    await createExecutor(snakecaseKeys(param));
  }, [createExecutor]);

  /**
   * API: 承認ジョブの一覧を取得
   */
  const fetchApproveJobs = useCallback((request: IndexRequest): Promise<ApproveJob[]> => {
    return indexFetcher(snakecaseKeys(request));
  }, [indexFetcher]);

  return {
    createApproveJobs,
    fetchApproveJobs,
  };
};
