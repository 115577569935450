import escape from 'lodash/escape';
import escapeRegExp from 'lodash/escapeRegExp';
import i18next from 'i18n';
import includes from 'lodash/includes';
import isEmpty from 'lodash/isEmpty';
import isNil from 'lodash/isNil';

/**
 * 配列に含まれているか
 * @param {String} message  エラー時に表示するメッセージ
 * @param {Array} array      この配列の中の値に、表示文字列と一致するものがあるかを確認する
 * @param {jQuery} field
 */
export function validateInArray(message, array, field) {
  const val = field.val();
  if (!isEmpty(val) && !includes(array, val)) {
    return message;
  }

  return void 0;
}

/**
 * jQuery validation engineに渡すcustom function
 * 勘定科目に対してvalidationを行う
 * 一覧として使用する配列は、外から取得したものを、第一引数にbindしておくこと
 */
export const validateCategory = validateInArray.bind(null, '正しい経費科目を入力してください');
export const validateSuperCategory = validateInArray.bind(null, '正しい貸方勘定科目を入力してください');
export const validateReportTitle = validateInArray.bind(null, '正しい申請名を入力してください');
export const validateProject = validateInArray.bind(null, '正しいプロジェクト名を入力してください');
export const validateGroup = validateInArray.bind(null, '正しい部署名を入力して下さい');
export const validateTaxCategory = validateInArray.bind(null, '正しい税区分を入力して下さい');
export const validateCostAllocation = validateInArray.bind(null, '正しい費用負担部署を選択して下さい');

/**
 * 出発駅・到着駅を選択されているか
 */
export function validateFare(field) {
  const regExp = /[0-9]+/g;
  if (!field.text().match(regExp)) {
    return i18next.t('transactions.errors.routeUnselected');
  }
  return void 0;
}

/**
 * 通貨コードが正しいかを判別する
 */
export function validateCurrency(currencies, field) {
  const val = field.val();
  if (!isNil(val) && !includes(currencies.map((c) => c.currencyId), val)) {
    return '正しい通貨コードを入力してください';
  }
  return void 0;
}

/**
 * 未来の日付になっているか
 */
export function validateFutureDate(field) {
  const val = new Date(field.val()).getTime();
  const today = new Date().getTime();
  if (val < today) {
    return '未来日付を指定してください';
  }
  return void 0;
}

/**
 * ひらがなであるか
 */
export function validateHirakana(field) {
  const regExp = /^[あ-ゞ]*$/;
  if (!regExp.test(field.val())) {
    return 'ひらがなで入力してください';
  }
  return void 0;
}

/**
 * 0より大きいか
 */
export function validateOverZero(field) {
  const number = parseInt(field.val(), 10);
  if (number <= 0) {
    return '0より大きい値を入力してください';
  }
  return void 0;
}

/**
 * 日当表の計算式のバリデーション
 * 宣言されている変数で、計算式に使用されていないものがあれば、エラーにする
 *
 * @param {string[]} variables  変数名の一覧
 * @param {jQuery} field
 */
export function validateFormula(variables, field) {
  const formula = field.val();
  if (!isNil(formula)) {
    const unusedVars = variables.filter((name) => {
      const regExp = new RegExp(escapeRegExp(`[${name}]`));

      return !regExp.test(formula);
    });
    if (unusedVars.length) {
      return `${i18next.t('allowance.notification.unusedVariable')} ${escape(unusedVars.join(', '))}`;
    }
  }
  return void 0;
}
