
import React from 'react';
import { Item } from './Item';
import { Period } from 'utilities/api/models/accountingDataScheduledExports/Period';
import { formatPeriod } from 'applications/accounting_data_scheduled_exports/utils';

interface Props {
  label: string;
  value: Period | null;
}

export const PeriodItem: React.FC<Props> = ({
  label,
  value,
}) => {
  if (value === null) { return <></>; }

  return (
    <Item label={ label } value={ formatPeriod(value) }></Item>
  );
};
