import ColorSelector from './ColorSelector';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import i18next from 'i18next';
import { OverlayTrigger, Popover } from 'react-bootstrap';
import { renderFormElement } from 'utilities/renderer';
import { yiqFromRGB } from 'utilities/Utils';

export default class TagForm extends Component {
  validate() {
    if (this.refs.form) {
      return $(this.refs.form).validationEngine('validate');
    }
    return false;
  }

  normalizeColor(color) {
    // 先頭の#が消されないように
    if (color.length > 0 && color[0] !== '#') {
      return `#${color}`;
    }
    return color;
  }

  renderNameInput(name, onChange) {
    return renderFormElement(i18next.t('employeeLabels.properties.name'), (
      <input type='text'
        className='form-control validate[required]'
        placeholder={ i18next.t('paymentRequests.invoiceSettings.labels.modal.name') }
        value={ name }
        onChange={ (e) => { onChange(e.target.value); } }
      />), { className: 'col-sm-3' }, { className: 'col-sm-6' });
  }

  renderColorInput(color, onChange) {
    const colorButtonStyle = {
      borderColor: '#ccc',
      backgroundColor: color,
      color: yiqFromRGB(color || '#fff') >= 128 ? '#000' : '#fff',
    };

    return renderFormElement(i18next.t('employeeLabels.properties.color'), (
      <div className='input-group'>
        <OverlayTrigger trigger='click' rootClose placement='bottom'
          overlay={ this.renderColorSelector(color, onChange) }
        >
          <span className='input-group-btn color-display'>
            <button type='button' className='btn' style={ colorButtonStyle }>
              <i className='fa fa-paint-brush' />
            </button>
          </span>
        </OverlayTrigger>
        <input type='text'
          className='form-control validate[required]'
          value={ color }
          onChange={ (e) => { onChange(this.normalizeColor(e.target.value)); } }
          maxLength={ 7 }
        />
      </div>), { className: 'col-sm-3' }, { className: 'col-sm-6' });
  }

  renderColorSelector(color, onChange) {
    return (
      <Popover id='color-selector' title={ i18next.t('paymentRequests.invoiceSettings.labels.modal.selectLabelColor') }>
        <ColorSelector color={ color } onSelect={ onChange } />
      </Popover>
    );
  }

  render() {
    const {
      id, name, color, onChange, onSubmit,
    } = this.props;

    return (
      <form ref='form' className='form-horizontal'>
        { this.renderNameInput(name, onChange.bind(null, 'name')) }
        { this.renderColorInput(color, onChange.bind(null, 'color')) }
      </form>
    );
  }
}

TagForm.prpTypes = {
  id: PropTypes.string,
  name: PropTypes.string,
  color: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
};
