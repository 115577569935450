import { SplitEventLog } from "applications/transactions/components/history/SplitEventLog";
import i18next from "i18n";
import React from "react";
import { BootstrapTable, TableHeaderColumn } from "react-bootstrap-table";
import { Expense } from "utilities/api/models";
import { Change, ExpenseHistory } from "../types";
import { formatChangedAt, formatEditor, historyTypeText } from "../utilities";
import { ExpenseLog } from "./ExpenseLog";

export interface Props {
  /** 編集履歴一覧 */
  histories: ExpenseHistory[];
  /** 経費 */
  expense: Expense;
}

/**
 * 経費編集履歴テーブル
 */
export const ExpenseHistoryTable: React.FC<Props> = ({
  histories,
  expense,
}) => {
  /**
   * 編集内容
   */
  const formatChangeLog = (changes: Change[], history): JSX.Element | null => {
    // ここに分割の場合のUI追加
    if (history.type === "split") {
      return <SplitEventLog splitEvent={history} />;
    }

    return (
      <ExpenseLog
        expenseHistoryType={history.type}
        expense={expense}
        changes={changes}
      />
    );
  };

  return (
    <BootstrapTable
      tableBodyClass="expense-history-table"
      data={histories.map((history, index) => {
        return { ...history, index: index + 1 };
      })}
      striped={true}
      hover={true}
    >
      <TableHeaderColumn dataField="id" hidden={true} isKey={true} />
      <TableHeaderColumn dataField="index" width="50">
        #
      </TableHeaderColumn>
      <TableHeaderColumn
        dataField="type"
        dataFormat={historyTypeText}
        width="70"
      />
      <TableHeaderColumn
        dataField="changedAt"
        dataFormat={formatChangedAt}
        width="170"
      >
        {i18next.t("transactions.histories.properties.changedAt")}
      </TableHeaderColumn>
      <TableHeaderColumn
        dataField="editor"
        dataFormat={formatEditor}
        width="150"
      >
        {i18next.t("transactions.histories.properties.editor")}
      </TableHeaderColumn>
      <TableHeaderColumn
        className="txt-pointer"
        dataField="changes"
        dataFormat={formatChangeLog}
        tdStyle={{ whiteSpace: "normal", overflowWrap: "break-word" }}
      >
        {i18next.t("transactions.histories.properties.changeLog")}
      </TableHeaderColumn>
    </BootstrapTable>
  );
};
