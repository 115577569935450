import ApiV3 from "utilities/api_v3";
import { DepartmentPostRelationshipSelectorDepartment, DepartmentPostRelationshipSelectorPost, DepartmentPostRelationshipSelectorSelectedDepartmentPost } from "./domain";
import { Pagination } from "utilities/api_v3/responses/kernels/organizations/departments/managements/SearchResponse";
import { departmentsAdaptor } from "./adaptor";

/**
 * 部署一覧を取得する
 * @param keyword キーワード
 * @param limit 取得最大件数
 * @param offset オフセット
 * @param defaultPosts 部署に紐づく役職がない場合のデフォルトの役職一覧
 * @param selectedDepartmentPostRelationships 選択中の部署役職一覧
 * @returns 部署一覧
 */
export const fetchDepartments = async (keyword: string, limit: number, offset: number, defaultPosts: DepartmentPostRelationshipSelectorPost[], selectedDepartmentPostRelationships: DepartmentPostRelationshipSelectorSelectedDepartmentPost[]): Promise<{ data: DepartmentPostRelationshipSelectorDepartment[], pagination: Pagination }> => {
  const { data, pagination } = await ApiV3.kernels.organizations.departments.managements.search({ limit, offset, keyword });
  return {
    data: departmentsAdaptor(data, defaultPosts, selectedDepartmentPostRelationships),
    pagination,
  };
};
