import PropTypes from 'prop-types';
import React from 'react';
import i18next from 'i18n';

const TeikiFare = ({ type, value }) => {
  return (
    <div className='fare-display'>
      <span className='teiki-info-txt'>{ i18next.t(`preferences.teiki.terms.${type}`) }:</span>
      <i className='fas fa-lg fa-yen-sign' />
      <p className='txt txt-lg validate[funcCallRequired[validateFare]]'>{ value || '-' }</p>
    </div>
  );
};

TeikiFare.propTypes = {
  type: PropTypes.oneOf(['oneMonth', 'threeMonths', 'sixMonths']).isRequired,
  value: PropTypes.number,
};

export default TeikiFare;
