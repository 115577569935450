import ActionTypes from './ActionTypes';
import { MessageLevel } from './Types';

/**
 * flashが複数存在しているため、messengerを引数に渡すようにしている
 * EmbeddedFlashが置き換えられたタイミングで、I/F変更する
 * @todo options の型定義を頑張る
 */

interface DisplayMessageAction {
  type: typeof ActionTypes.DISPLAY_MESSAGE;
  level: MessageLevel;
  message: string;
  messenger?: string;
  // eslint-disable-next-line @typescript-eslint/ban-types
  options?: object;
}

interface ClearMessageAction {
  type: typeof ActionTypes.CLEAR_MESSAGE;
  time: number;
}

interface ResetStateAction {
  type: typeof ActionTypes.RESET_STATE;
  exclude: string[];
}

interface RedirectToAction {
  type: typeof ActionTypes.REDIRECT_TO;
  url: string;
}

interface OpenTabAction {
  type: typeof ActionTypes.OPEN_TAB;
  url: string;
}

interface ChangeUrlAction {
  type: typeof ActionTypes.CHANGE_URL;
  url: string;
  title?: string;
}

interface SetFetchStatusAction {
  type: typeof ActionTypes.SET_FETCH_STATUS;
  id: string;
  status: string;
}

// eslint-disable-next-line @typescript-eslint/ban-types
export function displayMessage(level: MessageLevel, message: string, messenger?: string, options?: object): DisplayMessageAction {
  return {
    type: ActionTypes.DISPLAY_MESSAGE,
    level,
    message,
    messenger,
    options,
  };
}

export function clearMessage(time = 0): ClearMessageAction {
  return {
    type: ActionTypes.CLEAR_MESSAGE,
    time,
  };
}

export function resetState(exclude: string[] = []): ResetStateAction {
  return {
    type: ActionTypes.RESET_STATE,
    exclude,
  };
}

export function redirectTo(url: string): RedirectToAction {
  return {
    type: ActionTypes.REDIRECT_TO,
    url,
  };
}

export function openTab(url: string): OpenTabAction {
  return {
    type: ActionTypes.OPEN_TAB,
    url,
  };
}

export function changeUrl(url: string, title?: string): ChangeUrlAction {
  return {
    type: ActionTypes.CHANGE_URL,
    url,
    title,
  };
}

export function setFetchStatus(id: string, status: string): SetFetchStatusAction {
  return {
    type: ActionTypes.SET_FETCH_STATUS,
    id,
    status,
  };
}
