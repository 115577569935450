import DataFetchingTable from 'components/DataFetchingTable';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import SmartCheckBox from 'components/SmartCheckBox';
import formatter from 'utilities/formatter';
import i18next from 'i18n';
import { TableHeaderColumn } from 'react-bootstrap-table';

export default class MemberAgentTable extends Component {
  constructor(props) {
    super(props);

    this.renderAuthority = this.renderAuthority.bind(this);
    this.renderButton = this.renderButton.bind(this);
  }

  renderAgentName(name, row) {
    if (!name) {
      return formatter.text(name);
    }

    return formatter.member(row);
  }

  renderAuthority(type, disabled, checked, row) {
    const agentId = row.id;

    return (
      <SmartCheckBox
        checked={ checked }
        disabled={ disabled }
        onChange={ this.props.onClickEdit.bind(null, this.props.selectedMember, agentId, type) }
      />
    );
  }

  renderButton(agentId, row) {
    return (
      <div>
        <button className='btn btn-outline btn-danger'
          style={ { width: '55px' } }
          type='button'
          onClick={ this.props.onClickDelete.bind(null, row) }
        >
          { i18next.t('commons.actions.delete') }
        </button>
      </div>
    );
  }

  approveAuthorityText() {
    if (userPreferences.preference.usePaymentRequestsReportRequest) {
      return i18next.t('paymentRequests.agentSettings.agentAuthorities.canApprove');
    }

    return i18next.t('userPreferences.agentSettings.agentTable.canApprove');
  }

  render() {
    const options = {
      noDataText: i18next.t('commons.messages.noDataFound'),
    };

    return (
      <DataFetchingTable
        data={ this.props.data }
        fetchData={ this.props.fetchData.bind(null, this.props.selectedMember, this.props.data, this.props.searchText) }
        sizePerPage={ this.props.sizePerPage }
        sizePerPageList={ [10, 50, 100] }
        onSizePerPageList={ this.props.onSizePerPageList }
        currentPage={ this.props.currentPage }
        onPageChange={ this.props.onPageChange }
        options={ options }s
      >
        <TableHeaderColumn dataField='id' hidden={ true } isKey={ true } />
        <TableHeaderColumn
          dataField='name'
          dataFormat={ this.renderAgentName }
          width='230'
        >
          { i18next.t('userPreferences.agentSettings.agentTable.agentName') }
        </TableHeaderColumn>
        <TableHeaderColumn
          columnClassName='align-middle'
          dataField='isRequestable'
          dataFormat={ this.renderAuthority.bind(this, 'requestable', false) }
          dataAlign='center'
          width='110'
        >
          { i18next.t('userPreferences.agentSettings.agentTable.canMakeExpensesAndSubmit') }
        </TableHeaderColumn>
        <TableHeaderColumn
          columnClassName='align-middle'
          dataField='isApprovable'
          dataFormat={ this.renderAuthority.bind(this, 'approvable', !this.props.isApprover) }
          dataAlign='center'
          width='100'
        >
          { this.approveAuthorityText() }
        </TableHeaderColumn>
        <TableHeaderColumn
          dataField='id'
          dataFormat={ this.renderButton }
          dataAlign='center'
          width='80'
        >
        </TableHeaderColumn>
      </DataFetchingTable>
    );
  }
}

MemberAgentTable.defaultProps = {
  currentPage: 1,
  sizePerPage: 10,
  selectedMember: null,
  searchText: null,
};

MemberAgentTable.propTypes = {
  currentPage: PropTypes.number,
  sizePerPage: PropTypes.number,
  data: PropTypes.array.isRequired,
  onClickEdit: PropTypes.func.isRequired,
  onClickDelete: PropTypes.func.isRequired,
};
