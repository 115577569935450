import LabeledComponent from 'components/LabeledComponent';
import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';
import formatter from 'utilities/formatter';
import { getDatePickerDayConfig } from 'utilities/Utils';

/**
 * ログ検索ボックス: 日付フォーム
 */
export default class LoggedAt extends PureComponent {
  constructor(props) {
    super(props);

    this.handleDateChange = this.handleDateChange.bind(this);
  }

  componentDidMount() {
    const config = {
      language: 'ja',
      format: 'yyyy/mm/dd',
      orientation: 'bottom',
      autoclose: true,
      clearBtn: true,
      todayBtn: 'linked',
      beforeShowDay: getDatePickerDayConfig,
    };

    $(this.refs[this.props.propName]).datepicker(config).on('change', this.handleDateChange.bind(null, this.props.onDateChange));
  }

  /**
   * 日付の変更をした
   */
  handleDateChange(callback, e) {
    if (e.target.value) {
      callback(e.target.value);
    } else {
      // clear buttonがクリックされた時
      callback('');
    }
  }

  render() {
    const { propName, label, date } = this.props;

    return (
      <LabeledComponent label={ label }>
        <div className='input-group'>
          <div className='input-group-addon'>
            <span className='fas fa-calendar-alt'></span>
          </div>
          <input
            ref={ propName }
            type='text'
            className={ `form-control date-picker ${propName}` }
            value={ date && formatter.date(date) }
            onChange={ (_) => null }
          />
        </div>
      </LabeledComponent>
    );
  }
}

LoggedAt.defaultProps = {
  label: '&nbsp;',
  date: '',
  propName: 'loggedAt',
};

LoggedAt.propTypes = {
  label: PropTypes.string.isRequired,
  date: PropTypes.string.isRequired,
  propName: PropTypes.string,
  onDateChange: PropTypes.func.isRequired,
};
