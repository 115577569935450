import React from 'react';
import i18next from 'i18n';
import {
  FormControl,
  InputGroup,
} from 'react-bootstrap';

/** 選択された参加者一覧のテーブルにおける、詳細に表示する参加者人数を表示するコンポーネント */
const CompanionCount = (props) => {
  const {
    /** 選択されている全ての参加者 */
    selectedCompanions,
    /** 人数 */
    count,
    /** 会社名称 */
    company,
    /** 選択されたリストを更新するメソッド */
    onSelect,
  } = props;

  return (
    <InputGroup>
      <FormControl
        type='number'
        value={ count }
        onChange={ (e) => {
          const objIndex = selectedCompanions.findIndex((c) => (c.company === company && c.isCompanionNumber));
          const inputNumber = parseInt(e.target.value, 10);

          const nextRows = Array.from(selectedCompanions);
          nextRows[objIndex] = {
            ...selectedCompanions[objIndex],
            number: (inputNumber > 0) ? inputNumber : 1,
          };

          onSelect(nextRows);
        } }/>
      <InputGroup.Addon>{ i18next.t('commons.units.person') }</InputGroup.Addon>
    </InputGroup>
  );
};

export default CompanionCount;
