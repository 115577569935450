import { displayMessage, redirectTo } from "actions/ActionCreators";
import PaymentDateModal from "applications/analysis_details/components/PaymentDateModal.tsx";
import { NotFound } from "applications/approving_reports/components/NotFound.tsx";
import { RejectModal } from "components/RejectModal";
import i18next from "i18n";
import flatMap from "lodash/flatMap";
import get from "lodash/get";
import isNil from "lodash/isNil";
import last from "lodash/last";
import PropTypes from "prop-types";
import React, { Component } from "react";
import { connect } from "react-redux";
import { getMessageFromResponse } from "utilities/Utils";
import { initialPreTransaction } from "values/initial_pre_transaction";
import REPORT_STATUS from "../../../values/report_status";
import TimelineCard from "../../reports/timeline_card";
import { ReapplyingModal } from "../../requests/components/ReapplyingModal";
import * as cancelApprovalActionCreators from "../actions/CancelApproval/ActionCreators";
import * as cancelTemporaryPaymentModalActionCreators from "../actions/CancelTemporaryPayment/ActionCreators";
import * as cancelTemporaryPaymentRefundActionCreators from "../actions/CancelTemporaryPaymentRefund/ActionCreators";
import * as formStateActions from "../actions/formStateActions";
import * as preReportActions from "../actions/preReportActions";
import * as preReportRequestActions from "../actions/preReportRequestActions";
import * as suggestionActions from "../actions/suggestions";
import AmanaButtonsForRequesterAfterApproved from "../components/amana/ButtonsForRequesterAfterApproved";
import ButtonsForApprover from "../components/ButtonsForApprover";
import ButtonsForRequesterAfterApproved from "../components/ButtonsForRequesterAfterApproved";
import ButtonsForRequesterAfterApprovedWithReport from "../components/ButtonsForRequesterAfterApprovedWithReport";
import ButtonsForRequesterBeforeApproved from "../components/ButtonsForRequesterBeforeApproved";
import PreExpenseModal from "../components/PreExpenseModal";
import { PreReportFormCard } from "../components/PreReportFormCard";
import PreReportRequestModal from "../components/PreReportRequestModal";
import CancelApprovalModalContainer from "./CancelApprovalModalContainer";
import CancelTemporaryPaymentModalContainer from "./CancelTemporaryPaymentModalContainer";
import CancelTemporaryPaymentRefundModalContainer from "./CancelTemporaryPaymentRefundModalContainer";
import PreReportTransactionTableCard from "./PreReportTransactionTableCard";
import PreTransactionTableCard from "./PreTransactionTableCard";
import ReportHistoryCard from "./ReportHistoryCard";

export class PreReport extends Component {
  constructor(props) {
    super(props);

    this.reload = this.reload.bind(this);
    this.rejectReport = this.rejectReport.bind(this);
    this.isAmana = this.isAmana.bind(this);
    this.handleBeforeUnload = this.handleBeforeUnload.bind(this);
  }

  componentDidMount() {
    const { preReport } = this.props;

    // サジェストに利用する値の初期化
    this.props.initSuggestion();

    this.props.fetchRemoteData().then(() => {
      if (preReport.id) {
        this.reload();
      }
      if (preReport.isNew) {
        this.props.initDefaultPreReportName();
      }
    });

    window.addEventListener("beforeunload", this.handleBeforeUnload);
  }

  componentWillUnmount() {
    window.removeEventListener("beforeunload", this.handleBeforeUnload);
  }

  /**
   * この事前申請書を承認できるかどうかを返します。
   */
  get canApprove() {
    if (this.props.authority !== "approver") {
      return false;
    }

    const preReport = this.props.preReport;

    // REPORT_STATUS からステータスのキーを引き当てる。
    // TODO: バックエンドは、ステータスを言語設定に依存したラベル名ではなくコードで返却すること。
    const statusKey =
      Object.keys(REPORT_STATUS.ja).find(
        (key) => REPORT_STATUS.ja[key] === preReport.overallStatus.status,
      ) ||
      Object.keys(REPORT_STATUS.en).find(
        (key) => REPORT_STATUS.en[key] === preReport.overallStatus.status,
      );

    if (statusKey !== "applying") {
      return false;
    }
    if (!preReport.requests?.find((it) => it.canApprove)) {
      return false;
    }
    return true;
  }

  reload() {
    return this.props.loadPreReport(
      this.props.preReport.id,
      this.props.authority === "approver",
    );
  }

  submit(updateFlow, preReport, preReportRequest, approvals, onError) {
    if (preReport.hasApproved) {
      this.props.request(
        preReport.id,
        updateFlow ? preReport.report.id : null,
        preReportRequest,
        approvals,
        onError,
      );
    } else {
      this.props.requestPreReport(
        updateFlow,
        preReport.id,
        preReportRequest.comment,
        approvals,
        onError,
      );
    }
  }

  submitTransaction() {
    this.props.submitPreTransaction(
      this.props.formState.preTransaction,
      (error) => {
        this.refs.preTransactionModal.onError(error);
      },
    );
  }

  rejectReport(approvalId, comment, step) {
    this.props.reject(this.props.preReport.id, approvalId, comment, step?.id);
  }

  render() {
    if (!this.props.preReport.id && !this.props.preReport.isNew) {
      return <NotFound />;
    }

    const {
      authority,
      preReport,
      preReportRequest,
      formState,
      defaultPeriod,
      cancelApprovalModal,
      cancelTemporaryPaymentModal,
      cancelTemporaryPaymentRefundModal,
    } = this.props;

    const classNamePreReportFormCard = "pre-report-form-card";

    const forApprover = authority === "approver";
    let reportRequiresWithholding = false;
    if (preReport.transactions.length > 0) {
      reportRequiresWithholding = preReport.transactions
        .filter((t) => t)
        .some((t) => t.categoryRequiresWithholding);
    }

    const approvalFlow = {
      ...preReportRequest.approvalFlow,
      approvals:
        preReportRequest.approvals ||
        get(preReportRequest.approvalFlow, "approvals"),
    };

    return (
      <div>
        <div className="row">
          <div className="col-lg-6">
            <PreReportFormCard
              authority={authority}
              preReport={preReport}
              fields={formState.fields}
              isEditing={formState.isEditing}
              editable={preReport.editable}
              listeners={this.props.preReportListeners}
              onUpdate={this.props.update}
              reload={this.reload}
              buttons={this.renderButtons(
                preReport,
                preReportRequest.requestState,
              )}
              className={classNamePreReportFormCard}
            />
            {/* 精算申請用の再申請時のモーダル */}
            <ReapplyingModal
              showModal={preReportRequest.showReapplyModal}
              closeModal={this.props.closeReapplyModal}
              currentApprovals={get(
                last(preReport.requests),
                "remainingApproval",
                [],
              ).filter((x) => !isNil(x))}
              onClickUseCurrentApprovalFlow={this.props.reapplyOnReapplyModal.bind(
                this,
                preReport,
              )}
              onClickUseNewApprovalFlow={this.props.fetchRequestOnReapplyModal.bind(
                this,
                preReport.id,
              )}
            />
            {/* 事前申請用の再申請時のモーダル */}
            <ReapplyingModal
              showModal={preReportRequest.showPreReportReapplyModal}
              closeModal={this.props.closePreReportReapplyModal}
              currentApprovals={get(
                last(preReport.requests),
                "remainingApproval",
                [],
              ).filter((x) => !isNil(x))}
              onClickUseCurrentApprovalFlow={this.props.reapplyOnPreReportReapplyModal.bind(
                this,
                preReport,
              )}
              onClickUseNewApprovalFlow={this.props.fetchRequestOnPreReportReapplyModal.bind(
                this,
                preReport.id,
              )}
            />
          </div>
          <div className="col-lg-6">
            {this.renderTimeline(preReport, classNamePreReportFormCard)}
          </div>
        </div>
        <div className="row">
          <div className="col-md-12">
            <PreTransactionTableCard
              authority={authority}
              preTransactions={preReport.preTransactions}
              editable={!forApprover && preReport.editable}
              onAddBtnClick={() =>
                this.props.openPreTransaction(initialPreTransaction())
              }
              onDeleteBtnClick={this.props.deletePreTransaction.bind(this)}
              onRowClick={this.props.openPreTransaction.bind(this)}
              resetTransactions={this.reload}
            />
          </div>
        </div>
        {preReport.userId &&
        (preReport.hasApproved || preReport.transactions.length > 0) ? (
          <div className="row">
            <div className="col-md-12">
              <PreReportTransactionTableCard
                ownerId={preReport.userId}
                authority={authority}
                resetTransactions={this.reload}
                defaultPeriod={defaultPeriod}
                preReportFormValues={preReport.formValues}
                preReportId={preReport.id}
                preReportTitle={preReport.title}
                transactions={preReport.transactions}
                reportRequiresWithholding={reportRequiresWithholding}
                disableAssignableRportInput={true}
              />
            </div>
          </div>
        ) : null}
        {preReport.report && preReport.report.id && (
          <div className="row">
            <div className="col-sm-12">
              <ReportHistoryCard />
            </div>
          </div>
        )}
        <PreReportRequestModal
          showModal={preReportRequest.showModal}
          modalTitle={i18next.t("reports.types.preReport")}
          preReport={preReport}
          title={preReportRequest.name}
          amount={preReport.amount}
          preAmount={preReport.preAmount}
          comment={preReportRequest.comment}
          approvalFlow={approvalFlow}
          onSubmit={this.submit.bind(
            this,
            preReportRequest.updateFlow,
            preReport,
            preReportRequest,
          )}
          onCommentChange={this.props.setComment.bind(this)}
          closeModal={this.props.closeApprovalFlowsModal.bind(this)}
          requestState={preReportRequest.requestState}
        />

        {
          /* 事前申請の差し戻しモーダル */
          last(preReport.requests) && this.canApprove && (
            <RejectModal
              id={last(preReport.requests).remainingApproval[0].id}
              showModal={preReportRequest.isRejectModalOpen}
              closeModal={this.props.closeRejectModal}
              processing={preReportRequest.requestState.isJobRunning}
              rejectReport={this.rejectReport}
            />
          )
        }
        <PaymentDateModal
          buttons={[
            {
              color: "success",
              content: i18next.t("exports.titles.completedTemporaryPayment"),
              disabled: preReportRequest.shouldDisablePaymentButton,
              onClick: () =>
                this.props.recordTemporaryPayment(
                  preReport,
                  preReportRequest.paymentDate,
                  preReportRequest.paymentComment,
                ),
            },
            {
              color: "default",
              content: i18next.t("commons.actions.cancel"),
              onClick: this.props.closePaymentModal,
            },
          ]}
          close={this.props.closePaymentModal}
          onPaymentCommentChange={this.props.setPaymentComment}
          onPaymentDateChange={this.props.setPaymentDate}
          paymentComment={preReportRequest.paymentComment || ""}
          paymentCommentLabel={i18next.t(
            "preferences.temporaryPaymentDate.labels.notificationMessage",
          )}
          paymentDate={preReportRequest.paymentDate || ""}
          paymentDateLabel={i18next.t(
            "preferences.temporaryPaymentDate.labels.dateToBePaid",
          )}
          show={preReportRequest.shouldShowPaymentModal}
          title={i18next.t("commons.messages.confrimTemporaryPayment")}
          usePaymentDate={userPreferences.preference.useTemporaryPaymentDate}
        />
        <PreExpenseModal
          show={formState.showPreTransactionModal}
          disabled={forApprover || !preReport.editable}
          preExpense={formState.preTransaction}
          shouldSelectSelfAsCompanion={
            this.props.formState.shouldSelectSelfAsCompanion
          }
          onChangePreExpense={this.props.onChangePreExpense}
          onClose={this.props.closePreTransactionModal.bind(
            this,
            formState.preTransaction,
          )}
          onSelectParticipantsCategory={this.props.onSelectCompanionsCategory.bind(
            this,
          )}
          onSubmit={this.submitTransaction.bind(this)}
        />
        <CancelTemporaryPaymentModalContainer
          id={preReport.id}
          show={cancelTemporaryPaymentModal.show}
        />
        <CancelTemporaryPaymentRefundModalContainer
          id={preReport.id}
          show={cancelTemporaryPaymentRefundModal.show}
        />
        {get(last(preReport.requests), "id", null) && (
          <CancelApprovalModalContainer
            preReportId={preReport.id}
            requestId={last(preReport.requests).id}
            show={cancelApprovalModal.show}
          />
        )}
      </div>
    );
  }

  renderTimeline(preReport, classNameOfComponentCompare) {
    let status;
    if (isNil(preReport.overallStatus)) {
      status = get(preReport, "status");
    } else {
      status = get(preReport, "overallStatus.status");
    }
    return preReport.requests.length > 0 ? (
      <TimelineCard
        requestId={last(preReport.requests).id}
        reportStatus={status}
        requestEvents={flatMap(preReport.requests, (it) => it.requestEvents)}
        remainingApprovals={get(
          last(preReport.requests),
          "remainingApproval",
          [],
        ).filter((x) => !isNil(x))}
        onRefresh={this.reload.bind(this)}
        isSetHeight
        classNameOfComponentCompare={classNameOfComponentCompare}
      />
    ) : null;
  }

  renderButtons(preReport, requestState) {
    const { hasApproved, report } = preReport;

    if (this.props.authority === "approver") {
      return (
        <ButtonsForApprover
          locale={userPreferences.locale}
          preReport={preReport}
          requestState={requestState}
          onTemporaryPaymentButtonClick={this.props.openPaymentModal}
          onTemporaryPaymentRefundButtonClick={this.props.recordTemporaryPaymentRefund.bind(
            this,
            preReport,
          )}
          onCancelTemporaryPaymentButtonClick={this.props.openCancelTemporaryPaymentModal.bind(
            this,
          )}
          onCancelTemporaryPaymentRefundButtonClick={
            this.props.openCancelTemporaryPaymentRefundModal
          }
          onRejectButtonClick={this.props.showRejectModal}
          onApproveButtonClick={this.props.approve.bind(this, preReport)}
          onCancelApprovalButtonClick={this.props.openCancelApprovalModal.bind(
            this,
          )}
        />
      );
    }
    if (hasApproved) {
      // 事前申請
      if (!report) {
        // NOTE: アマナ様のみ修正申請できるため別コンポーネントに切り出し
        if (this.isAmana()) {
          return (
            <AmanaButtonsForRequesterAfterApproved
              locale={userPreferences.locale}
              preReport={preReport}
              requestState={requestState}
              isEditing={this.props.formState.isEditing}
              onSettlementButtonClick={this.props.fetchRequest.bind(
                this,
                preReport.id,
              )}
              onTemporaryPaymentDownloadButtonClick={this.props.redirectTo.bind(
                this,
                `/pre_reports/${preReport.id}/temporary_payment_xls`,
              )}
              onEditRequestButtonClick={this.props.update.bind(
                this,
                preReport,
                false,
                () => null,
              )}
              onSwitchEditContentButtonClick={this.props.preReportListeners.onSwitchContentEdit.bind(
                this,
                true,
              )}
            />
          );
        }
        return (
          <ButtonsForRequesterAfterApproved
            locale={userPreferences.locale}
            preReport={preReport}
            requestState={requestState}
            onSettlementButtonClick={this.props.fetchRequest.bind(
              this,
              preReport.id,
            )}
            onTemporaryPaymentDownloadButtonClick={this.props.redirectTo.bind(
              this,
              `/pre_reports/${preReport.id}/temporary_payment_xls`,
            )}
          />
        );
      }

      return (
        <ButtonsForRequesterAfterApprovedWithReport
          locale={userPreferences.locale}
          preReport={preReport}
          requestState={requestState}
          onApplyButtonClick={this.props.updateAndFetchRequest.bind(
            this,
            preReport,
          )}
          onReapplyButtonClick={this.props.toggleShowReapplyModal}
          onChangeFlowButtonClick={this.props.requestFlowChange.bind(
            this,
            preReport,
          )}
          onRecallButtonClick={this.props.recallRequest.bind(this, preReport)}
        />
      );
    }

    return (
      <ButtonsForRequesterBeforeApproved
        locale={userPreferences.locale}
        preReport={preReport}
        requestState={requestState}
        onSaveAndApplyButtonClick={this.props.createAndFetchRequest.bind(
          this,
          preReport,
        )}
        onApplyButtonClick={this.props.updateAndFetchRequest.bind(
          this,
          preReport,
        )}
        onReapplyButtonClick={this.props.showPreReportReapplyModal.bind(
          this,
          preReport,
          this.props.authority === "approver",
        )}
        onChangeFlowButtonClick={this.props.requestFlowChange.bind(
          this,
          preReport,
        )}
        onRecallButtonClick={this.props.recallRequest.bind(this, preReport)}
      />
    );
  }

  isAmana() {
    const {
      formState: { fields },
    } = this.props;
    return fields.map((f) => f.type).includes("amana_approval_document_input");
  }

  handleBeforeUnload(event) {
    const {
      preReportRequest: { shouldSave },
    } = this.props;
    if (shouldSave) {
      event.preventDefault();
      // eslint-disable-next-line no-param-reassign
      event.returnValue = "";
    }
  }
}

function mapStateToProps(state, ownProps) {
  const {
    preReport,
    preReportRequest,
    formState,
    authority,
    requestTypeId,
    defaultPeriod,
    cancelApprovalModal,
    cancelTemporaryPaymentModal,
    cancelTemporaryPaymentRefundModal,
    isRejectModalOpen,
  } = state;

  return {
    preReport,
    preReportRequest,
    formState,
    authority,
    requestTypeId,
    defaultPeriod,
    cancelApprovalModal,
    cancelTemporaryPaymentModal,
    cancelTemporaryPaymentRefundModal,
    isRejectModalOpen,
  };
}

function mapDispatchToProps(dispatch, ownProps) {
  return {
    initSuggestion: () => {
      dispatch(suggestionActions.initProjects());
    },
    fetchRemoteData: (requestTypeId) => {
      return dispatch(formStateActions.fetchEntryForm(requestTypeId));
    },
    redirectTo: (url) => dispatch(redirectTo(url)),
    loadPreReport: (id, forApprover) =>
      dispatch(preReportActions.fetchPreReport(id, forApprover)),
    preReportListeners: mapDispatchToPreReportListener(dispatch),
    setProperty: (key, value) =>
      dispatch(preReportActions.setProperty(key, value)),
    setComment: (comment) =>
      dispatch(preReportRequestActions.setComment(comment)),
    inputRejectReason: (value) =>
      dispatch(preReportRequestActions.inputRejectReason(value)),
    onChangePreExpense: (preExpense) =>
      dispatch(formStateActions.setPreTransaction(preExpense)),
    onSelectCompanionsCategory: () =>
      dispatch(formStateActions.setShouldSelectSelfAsCompanion(false)),
    ...mapDispatchToRequestActions(dispatch),
    ...mapDispatchToPreTransactionActions(dispatch),
    ...mapDispatchToCancelTemporaryPaymentModalActions(dispatch),
    ...mapDispatchToCancelTemporaryPaymentRefundActions(dispatch),
    ...mapDispatchToCancelApprovalActions(dispatch),
  };
}

function mapDispatchToPreTransactionActions(dispatch) {
  return {
    openPreTransaction: (preTransaction) =>
      dispatch(formStateActions.openPreTransaction(preTransaction)),
    closePreTransactionModal: (preTransaction) => {
      dispatch(formStateActions.initializeStateForCompanionSelector());
      dispatch(formStateActions.hidePreTransactionModal());
    },
    deletePreTransaction: (key) =>
      dispatch(preReportActions.deletePreTransaction(key)),
    submitPreTransaction: (preTransactionParams, onError) => {
      dispatch(formStateActions.initializeStateForCompanionSelector());
      dispatch(
        preReportActions.submitPreTransaction(preTransactionParams, onError),
      );
      dispatch(preReportRequestActions.setShouldSave(true));
    },
    initDefaultPreReportName: () =>
      dispatch(preReportActions.fetchDefaultPreReportName()),
  };
}

function mapDispatchToRequestActions(dispatch) {
  const {
    createAndFetchRequest,
    updateAndFetchRequest,
    fetchPreReportRequest,
    fetchRequest,
    recallRequest,
    update,
    requestPreReport,
    request,
    closeApprovalFlowsModal,
    toggleShowReapplyModal,
    showPreReportReapplyModal,
    closePreReportReapplyModal,
    requestFlowChange,
    approveRequest,
    rejectRequest,
    recordTemporaryPayment,
    recordTemporaryPaymentRefund,
    showPaymentModal,
    disablePaymentButton,
    setPaymentComment,
    setPaymentDate,
    updateAndReapply,
    cancelApproval,
    showRejectModal,
    closeRejectModal,
  } = preReportRequestActions;
  return {
    createAndFetchRequest: (preReport) =>
      dispatch(createAndFetchRequest(preReport)),
    updateAndFetchRequest: (preReport) =>
      dispatch(updateAndFetchRequest(preReport)),
    update: (preReport, forApprover, onSuccess) => {
      dispatch(preReportRequestActions.startPreReportRequest("editRequest"));
      dispatch(update(preReport, forApprover, onSuccess))
        .then(() =>
          dispatch(preReportRequestActions.closePreReportRequestJob()),
        )
        .catch((error) => {
          dispatch(displayMessage("error", getMessageFromResponse(error)));
          dispatch(preReportRequestActions.closePreReportRequestJob());
        });
    },
    recallRequest: (preReport) => dispatch(recallRequest(preReport)),
    approve: (preReport) => dispatch(approveRequest(preReport)),
    reject: (preReportId, approvalId, comment, stepId) =>
      dispatch(rejectRequest(preReportId, approvalId, comment, stepId)),
    recordTemporaryPayment: (preReport, paymentDate, paymentComment) => {
      dispatch(recordTemporaryPayment(preReport, paymentDate, paymentComment));
    },
    recordTemporaryPaymentRefund: (preReport) =>
      dispatch(recordTemporaryPaymentRefund(preReport)),
    openPaymentModal: () => {
      dispatch(setPaymentComment(""));
      dispatch(setPaymentDate(""));
      dispatch(showPaymentModal(true));
      dispatch(disablePaymentButton(false));
      dispatch(preReportRequestActions.closePreReportRequestJob());
    },
    closePaymentModal: () => {
      dispatch(showPaymentModal(false));
    },
    setPaymentComment: (comment) => dispatch(setPaymentComment(comment)),
    setPaymentDate: (date) => dispatch(setPaymentDate(date)),
    fetchPreReportRequest: (preReportId) =>
      dispatch(fetchPreReportRequest(preReportId)),
    fetchRequest: (preReportId) => dispatch(fetchRequest(preReportId)),
    fetchRequestOnReapplyModal: async (preReportId) => {
      await dispatch(fetchRequest(preReportId, true));
      dispatch(toggleShowReapplyModal());
    },
    fetchRequestOnPreReportReapplyModal: async (preReportId) => {
      await dispatch(fetchPreReportRequest(preReportId, true));
      dispatch(closePreReportReapplyModal());
    },
    requestPreReport: (updateFlow, preReportId, comment, approvals, onError) =>
      dispatch(
        requestPreReport(updateFlow, preReportId, comment, approvals, onError),
      ),
    request: (preReportId, reportId, preReportRequest, approvals, onError) =>
      dispatch(
        request(preReportId, reportId, preReportRequest, approvals, onError),
      ),
    closeApprovalFlowsModal: () => {
      dispatch(closeApprovalFlowsModal());
      dispatch(preReportRequestActions.closePreReportRequestJob());
    },
    showRejectModal: () => dispatch(showRejectModal()),
    closeRejectModal: () => dispatch(closeRejectModal()),
    toggleShowReapplyModal: () => dispatch(toggleShowReapplyModal()),
    showPreReportReapplyModal: (preReport, forApprover) => {
      // ユーザビリティを考え、申請フローの確認モーダルを開いた時点で事前申請は更新する
      // モーダルを閉じても事前申請は保存されているように
      dispatch(
        update(preReport, forApprover, () =>
          dispatch(showPreReportReapplyModal(preReport, forApprover)),
        ),
      );
    },
    closeReapplyModal: () => {
      dispatch(toggleShowReapplyModal());
      dispatch(preReportRequestActions.closePreReportRequestJob());
    },
    closePreReportReapplyModal: () => {
      dispatch(closePreReportReapplyModal());
      dispatch(preReportRequestActions.closePreReportRequestJob());
    },
    reapply: (preReport) => dispatch(updateAndReapply(preReport)),
    reapplyOnReapplyModal: async (preReport) => {
      await dispatch(updateAndReapply(preReport));
      dispatch(toggleShowReapplyModal());
    },
    reapplyOnPreReportReapplyModal: async (preReport) => {
      await dispatch(updateAndReapply(preReport));
      dispatch(closePreReportReapplyModal());
    },
    requestFlowChange: (preReport) => dispatch(requestFlowChange(preReport)),
    cancelApproval: (preReport, comment) =>
      dispatch(cancelApproval(preReport, comment)),
  };
}

function mapDispatchToPreReportListener(dispatch) {
  const setProperty = (key, value) => {
    dispatch(preReportActions.setProperty(key, value));
    dispatch(preReportRequestActions.setShouldSave(true));
  };
  return {
    onSwitchContentEdit: (isEditing) =>
      dispatch(formStateActions.toggleEditingPreReportContent(isEditing)),
    onTitleChange: setProperty.bind(null, "title"),
    onProjectChange: (value) => {
      setProperty("project", value);
      dispatch(
        preReportActions.editAllPreTransactionProperty("project", value),
      );
    },
    onDescriptionChange: setProperty.bind(null, "description"),
    onTypeChange: setProperty.bind(null, "type"),
    onDestinationChange: setProperty.bind(null, "destination"),
    onStartAtChange: setProperty.bind(null, "startAt"),
    onEndAtChange: setProperty.bind(null, "endAt"),
    onNeedsTemporaryPaymentChange: (bool) => {
      setProperty("needsTemporaryPayment", bool);
      if (!bool) {
        dispatch(preReportActions.clearTemporaryPaymentsForms());
      }
    },
    onTemporaryPaymentDueAtChange: setProperty.bind(
      null,
      "temporaryPaymentDueAt",
    ),
    onTemporaryPaymentReasonChange: setProperty.bind(
      null,
      "temporaryPaymentReason",
    ),
    setMetadata: (id, value) =>
      dispatch(preReportActions.setMetadata(id, value)),
    onDepartmentChange: setProperty.bind(null, "department"),
  };
}

function mapDispatchToCancelTemporaryPaymentModalActions(dispatch) {
  const { openModal } = cancelTemporaryPaymentModalActionCreators;

  return {
    openCancelTemporaryPaymentModal: () => dispatch(openModal()),
  };
}

function mapDispatchToCancelTemporaryPaymentRefundActions(dispatch) {
  const { openModal } = cancelTemporaryPaymentRefundActionCreators;

  return {
    openCancelTemporaryPaymentRefundModal: () => dispatch(openModal()),
  };
}

function mapDispatchToCancelApprovalActions(dispatch) {
  const { openModal } = cancelApprovalActionCreators;

  return {
    openCancelApprovalModal: () => dispatch(openModal()),
  };
}

PreReport.defaultProps = {
  authority: "approval",
};

PreReport.propTypes = {
  authority: PropTypes.string.isRequired,
  preReport: PropTypes.shape({
    id: PropTypes.string,
  }),
};

export default connect(mapStateToProps, mapDispatchToProps)(PreReport);
