import BootstrapCard from 'components/BootstrapCard';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import TargetPreTravelReportTable from 'applications/analyses/components/TargetPreTravelReportTable';
import TargetTemporaryPaymentReportTable from 'applications/analyses/components/TargetTemporaryPaymentReportTable';
import i18next from 'i18n';
import { connect } from 'react-redux';

/**
 * 旅費申請一覧カード
 */
export class AnalysisPreReportsCard extends Component {
  constructor(props) {
    super(props);

    this.onRowClick = this.onRowClick.bind(this);
  }

  onRowClick(report) {
    window.location.href = `/approving_pre_reports/${report.id}`;
  }

  render() {
    return (
      <BootstrapCard
        title={
          this.props.isTravel
            ? i18next.t('preTravelReports.titles.list')
            : i18next.t('preReports.titles.list')
          }
        titleIcon={ { className: 'fa-copy', style: 'regular' } }
      >
        {
          this.props.isTravel
            ? (
              <TargetPreTravelReportTable
                except={ ['check'] }
                onRowClick={ this.onRowClick }
                reports={ this.props.reports }
              />
            )
            : (
              <TargetTemporaryPaymentReportTable
                except={ ['check', 'aggregationStatus', 'exportationStatus', 'paymentStatus'] }
                onRowClick={ this.onRowClick }
                reports={ this.props.reports }
              />
            )
        }

      </BootstrapCard>
    );
  }
}

AnalysisPreReportsCard.propTypes = {
  isTravel: PropTypes.bool,
  reports: PropTypes.array.isRequired,
};

AnalysisPreReportsCard.defaultProps = {
  isTravel: false,
};

function mapStateToProps(state, ownProps) {
  const { analysis } = state;

  return {
    analysis,
  };
}

export default connect(
  mapStateToProps,
  void 0,
)(AnalysisPreReportsCard);
