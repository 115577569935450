import ExportForm from './ExportForm';
import React, { FC } from 'react';
import SimpleModal from 'components/SimpleModal';
import SmartCheckBox from 'components/SmartCheckBox';
import i18next from 'i18n';
import { ExportJob } from 'applications/preferences/metadata/Types';
import { isExportDisabled } from '../utilities/exportJobPolicy';
import { useExponentialBackOffWithAll } from 'hooks/useExponentialBackOff';

export interface Props {
  close: () => void;
  exportJobs: ExportJob[];
  loadExportJobs: (all: boolean) => void;
  renderAdditionalContent?: () => JSX.Element;
  requestExportJobs: () => void;
  requestButtonTitle?: string;
  show: boolean;
  title?: string;
  showLimitCheckbox?: boolean;
  allExportResults?: boolean;
  showAllResults?: () => void;
  hideConfirmedResults?: () => void;
}

const ExportFormPollingModal: FC<Props> = (props) => {
  const {
    exportJobs, title, renderAdditionalContent, show, close,
    requestButtonTitle, requestExportJobs, loadExportJobs,
    allExportResults = false,
    showAllResults = (): void => void 0,
    hideConfirmedResults = (): void => void 0,
    showLimitCheckbox,
  } = props;

  const { reset } = useExponentialBackOffWithAll(show, loadExportJobs, allExportResults);

  const onRequestDownload = (): void  => {
    requestExportJobs();
    reset();
  };

  const renderExportFromAdditionalContent = (): JSX.Element => {
    if (!showLimitCheckbox) {
      return (
        <div>
          { renderAdditionalContent?.() }
        </div>
      );
    }

    return (
      <div>
        { renderAdditionalContent?.() }
        <div className='row' style={ { paddingLeft: '30px' } }>
          <SmartCheckBox
            color='accent'
            checked={ allExportResults }
            label={ `${i18next.t('exports.actions.allExportResults')}(${i18next.t('exports.actions.defaultShow')})` }
            onChange={ (isChecked: boolean): void => (isChecked ? showAllResults() : hideConfirmedResults()) }/>
        </div>
      </div>
    );
  };

  return (
    <SimpleModal
      close={ close }
      dialogClassName='import-form-modal'
      show={ show }
      title={ title }>
      <ExportForm
        data={ exportJobs }
        hiddenFields={ ['exportTypeName'] }
        isExportButtonDisabled={ isExportDisabled(exportJobs) }
        onRequestExport={ onRequestDownload }
        renderAdditionalContent={ renderExportFromAdditionalContent }
        requestButtonTitle={ requestButtonTitle || i18next.t('exports.inputs.downloadXlsx') }
      />
    </SimpleModal>
  );
};

ExportFormPollingModal.defaultProps = {
  exportJobs: [],
  showLimitCheckbox: true,
};

export default ExportFormPollingModal;
