import React, { FC, ReactNode } from 'react';
import i18next from 'i18n';
import { Modal, OverlayTrigger, Popover } from 'react-bootstrap';

// TODO: よく使われてそうなので、どこかにtype定義する
/** Bootstrapのボタンカラー指定 */
type BSButtonColor =
  'caution' |
  'danger' |
  'default' |
  'info' |
  'primary' |
  'success' |
  'warning';

/** Bootstrapのサイズ指定 */
type BSSize = 'xs' | 'sm' | 'md' | 'lg';

export interface ButtonProps {
  className?: string;
  color: BSButtonColor;
  content: string | JSX.Element;
  disabled?: boolean;
  onClick: (e: React.MouseEvent<HTMLElement>) => void;
}

interface Props {
  bodyClassName?: string;
  footerClassName?: string;
  buttons?: ButtonProps[];
  close: () => void;
  dialogClassName?: string;
  enforceFocus?: boolean;
  info?: {
    text: ReactNode;
    title?: string;
  };
  onEntered?: () => void;
  show: boolean;
  size?: BSSize;
  title?: string;
  className?: string;
  openHelpModal?: () => void;
  helpText?: string;
}

const SimpleModal: FC<Props> = (props) => {
  const buttons = props.buttons || [];
  const size = props.size || 'md';
  const title = props.title || '';

  const renderHelpAndButtons = (): JSX.Element => {
    return (
      <div style={ { display: 'flex', justifyContent: 'space-between' } }>
        <div style={ { display: 'flex', alignItems: 'center' } }>
          <span
            className="btn btn-link no-padding"
            onClick={ props.openHelpModal }
          >
            <i
              className="far fa-question-circle"
              style={ {
                color: '#AAA',
                marginLeft: '6px',
                marginRight: '5px',
                cursor: 'pointer',
                fontSize: '20px',
              } }
            />
          </span>
          <span
            style={ { color: '#AAA', cursor: 'pointer', fontSize: '16px' } }
            onClick={ props.openHelpModal }
          >
            { props.helpText }
          </span>
        </div>
        <div>
          {buttons.map((button, idx) => (
            <button
              className={ `btn btn-${button.color} ${button.className}` }
              disabled={ !!button.disabled }
              key={ `simple-modal-button-${idx}` }
              onClick={ button.onClick }
            >
              {button.content}
            </button>
          ))}
        </div>
      </div>
    );
  };

  const renderButtons = (): JSX.Element[] => {
    return buttons.map((button, idx) => (
      <button
        className={ `btn btn-${button.color} ${button.className}` }
        disabled={ !!button.disabled }
        key={ `simple-modal-button-${idx}` }
        onClick={ button.onClick }
      >
        { button.content }
      </button>
    ));
  };

  const renderHeader = (): JSX.Element => {
    return (
      <Modal.Header closeButton>
        <Modal.Title>
          { title }
          { props.info
            && (
              <OverlayTrigger
                overlay={ (
                  <Popover id='popover-simple-modal' title={ props.info.title || i18next.t('commons.words.info') }>
                    { props.info.text }
                  </Popover>
                ) }
                placement='bottom'
                trigger={ ['click', 'hover'] }
              >
                <i className='far fa-question-circle' style={ { color: '#AAA', marginLeft: '6px', cursor: 'pointer' } } />
              </OverlayTrigger>
            )
          }
        </Modal.Title>
      </Modal.Header>
    );
  };

  const renderBody = (): JSX.Element | null => {
    if (!props.children) {
      return null;
    }

    return (
      <Modal.Body className={ props.bodyClassName }>
        { props.children }
      </Modal.Body>
    );
  };

  const renderFooter = (): JSX.Element | null => {
    if (!buttons || buttons.length === 0) {
      return null;
    }

    return (
      <Modal.Footer className={ props.footerClassName }>
        { props.openHelpModal === undefined ? renderButtons() : renderHelpAndButtons() }
      </Modal.Footer>
    );
  };

  const dialogClassName = (): string | undefined => {
    if (!props.dialogClassName && !props.className) {
      return undefined;
    }

    return [props.dialogClassName, props.className].filter((v) => v).join(' ');
  };

  // ReactBootstrap.ModalにbsSize='md'がない。
  // mdにするには「指定しない」しかない。(空文字もダメ)
  const modalProps: Modal.ModalProps = {
    dialogClassName: dialogClassName(),
    enforceFocus: props.enforceFocus,
    onEntered: props.onEntered,
    onHide: props.close,
    show: props.show,
  };

  if (size && size !== 'md') {
    modalProps.bsSize = size;
  }

  return (
    <Modal { ...modalProps }>
      { renderHeader() }
      { renderBody() }
      { renderFooter() }
    </Modal>
  );
};

export default SimpleModal;
