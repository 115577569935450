import { useDrop } from "components/renewaled_ui/FileViewer/Viewer/Other/ImageViewer/useImageViewer";
import {
  DropEventHandler,
  DropType,
  FileType,
  LoadingStatus,
} from "components/types/attachedFiles.types";
import i18next from "i18n";
import { useMemo } from "react";
import { DropzoneInputProps, DropzoneRootProps } from "react-dropzone";

interface UseReturn {
  readonly isDropzoneVisible: boolean;
  readonly fileLabel: string;
  readonly rootProps: DropzoneRootProps;
  readonly getInputProps: (
    props?: DropzoneInputProps | undefined,
  ) => DropzoneInputProps;
  readonly isShowFileSelect: boolean;
}

/**
 * ビューアーファイルロジック
 */
export const useHooksFile = (
  className: string,
  dropType: DropType,
  isDroppable = false,
  loadStatus: LoadingStatus,
  selectableTypes: FileType[],
  onDrop?: DropEventHandler<DropType>,
): UseReturn => {
  /**
   * ファイルが存在しない
   */
  const fileNotExists = ["notExist", "default"].includes(loadStatus);

  /**
   * ドロップ可能か
   */
  const isDropzoneVisible = isDroppable && fileNotExists;

  /**
   * ファイル種類の文言を返す
   */
  const useFileLabel = (types: FileType[]): string => {
    return useMemo(() => {
      const isImageSelectable = types.some((x) => x !== "pdf");
      const isPdfSelectable = types.some((x) => x === "pdf");

      const labels: string[] = [];

      if (isImageSelectable) {
        labels.push(i18next.t("transactions.droparea.image"));
      }

      if (isPdfSelectable) {
        labels.push("PDF");
      }

      return labels.join(", ");
    }, [types]);
  };

  /**
   * ファイル選択・ドロップ領域
   */
  const { getRootProps, getInputProps } = useDrop({
    dropType,
    onDrop,
    isDroppable,
    selectableTypes,
  });
  const fileLabel = useFileLabel(selectableTypes);
  const rootProps = getRootProps({ className });

  const isShowFileSelect = isDroppable && !isDropzoneVisible;

  return {
    isDropzoneVisible,
    fileLabel,
    getInputProps,
    rootProps,
    isShowFileSelect,
  };
};
