import AgentSuggestField from 'components/fields/AgentSuggestField';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import SmartCheckBox from 'components/SmartCheckBox';
import formatter from 'utilities/formatter';
import get from 'lodash/get';
import i18next from 'i18n';
import isNil from 'lodash/isNil';
import { PaymentRequestAgentAuthorityInputs } from 'applications/payment_requests/userPreferences/components/PaymentRequestAgentAuthorityInputs';
import { renderFormElement } from 'utilities/renderer';

export default class AgentAssignForm extends Component {
  renderAgentSelectForm() {
    return renderFormElement(i18next.t('userPreferences.agentSettings.addAgentModal.selectAgent'), this.renderAgentSelectInput());
  }

  renderAgentSelectInput() {
    return (
      <AgentSuggestField
        text={ get(this.props.user, 'name') }
        value={ this.props.user }
        onSelect={ this.props.onSelectAgent }
        onTextChange={ this.props.onChangeFormValue.bind(null, 'agent') }
        selectedMemberId={ this.props.selectedMemberId }
      />
    );
  }

  renderAgentSuggestion(agent) {
    return (
      <div>
        { formatter.text(agent.name, i18next.t('userPreferences.agentSettings.addAgentModal.unknownName')) }
        {
          isNil(agent.email) ? null
            : (<span className='txt txt-disabled' style={ { marginLeft: '4px' } }>{ `(${agent.email})` }</span>)
        }
      </div>
    );
  }

  renderAgentAuthorityForm() {
    return renderFormElement(i18next.t('userPreferences.agentSettings.addAgentModal.addAuthority'), this.renderAgentAuthorityInput(), void 0, { className: 'form-control-static col-sm-6 col-md-6' });
  }

  approveAuthorityText() {
    if (userPreferences.preference.usePaymentRequestsReportRequest) {
      return i18next.t('paymentRequests.agentSettings.agentAuthorities.canApprove');
    }

    return i18next.t('userPreferences.agentSettings.agentTable.canApprove');
  }

  renderAgentAuthorityInput() {
    return (
      <div>
        {
          userPreferences.preference.useDkRequests && (
            <>
              <SmartCheckBox label={ i18next.t('userPreferences.agentSettings.agentTable.canMakeExpensesAndSubmit') }
                color='primary'
                checked={ this.props.isRequestable }
                onChange={ this.props.onChangeFormValue.bind(null, 'request') }
              />
              <SmartCheckBox
                label={ this.approveAuthorityText() }
                color='primary'
                checked={ this.props.isApprovable }
                disabled={ !this.props.isApprover }
                onChange={ this.props.onChangeFormValue.bind(null, 'approve') }
              />
            </>
          )
        }
        { this.renderPaymentRequestAgentAuthorityInput() }
      </div>
    );
  }

  renderPaymentRequestAgentAuthorityInput() {
    if (userPreferences.preference.usePaymentRequest) {
      return (
        <PaymentRequestAgentAuthorityInputs
          isPrincipalApprover={ this.props.isApprover }
          isPaymentRequestReportRequestable={ this.props.isPaymentRequestReportRequestable }
          isPaymentRequestReportApprovable={ this.props.isPaymentRequestReportApprovable }
          isPaymentRequestProposalRequestable={ this.props.isPaymentRequestProposalRequestable }
          isPaymentRequestProposalApprovable={ this.props.isPaymentRequestProposalApprovable }
          onChangeFormValue={ this.props.onChangeFormValue }
        />
      );
    }

    return null;
  }

  renderInput(label, input) {
    return renderFormElement(label, input, void 0, { className: 'col-sm-6 col-md-6' });
  }

  render() {
    return (
      <div  className='form-horizontal'>
        { this.renderAgentSelectForm() }
        { this.renderAgentAuthorityForm() }
      </div>
    );
  }
}

AgentAssignForm.defaultProps = {
  user: null,
  users: [],
  isRequestable: false,
  isApprovable: false,
  isPaymentRequestReportRequestable: false,
  isPaymentRequestReportApprovable: false,
  isPaymentRequestProposalRequestable: false,
  isPaymentRequestProposalApprovable: false,
  selectedMemberId: null,
};

AgentAssignForm.propTypes = {
  user: PropTypes.shape({
    name: PropTypes.string.isRequired,
    email: PropTypes.string,
    id: PropTypes.string,
    departments: PropTypes.array,
  }).isRequired,
  users: PropTypes.arrayOf(PropTypes.shape({
    name: PropTypes.string.isRequired,
    email: PropTypes.string.isRequired,
    id: PropTypes.string.isRequired,
  })).isRequired,
  isRequestable: PropTypes.bool.isRequired,
  isApprovable: PropTypes.bool.isRequired,
  isPaymentRequestReportRequestable: PropTypes.bool.isRequired,
  isPaymentRequestReportApprovable: PropTypes.bool.isRequired,
  isPaymentRequestProposalRequestable: PropTypes.bool.isRequired,
  isPaymentRequestProposalApprovable: PropTypes.bool.isRequired,
  onChangeFormValue: PropTypes.func.isRequired,
  onSelectAgent: PropTypes.func.isRequired,
  selectedMemberId: PropTypes.string,
  isApprover: PropTypes.bool, // 選択中のユーザー（代理される方）が持つ承認権限フラグ
};
