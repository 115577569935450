import React from 'react';
import styled from 'styled-components';
import { TableCellProps } from 'react-table';

interface WrapperProps {
  readonly isSimpleDesignMode: boolean;
}
const Wrapper = styled.td<WrapperProps>`
  margin: 0;
  padding: ${(p): string => (p.isSimpleDesignMode ? '9px 8px 7px' : '4px 12px')};
  line-height: 24px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  ${(p): string => (p.isSimpleDesignMode ? '' : 'border-left: 1px solid #eee;')}
`;

interface Props {
  readonly isSimpleDesignMode: boolean;
  readonly cell: React.ReactNode;
  /** react-tableのdomのprops */
  readonly tableCellProps: TableCellProps;
  readonly onClick: () => void;
}

/**
 * table td
 */
export const Td: React.FC<Props> = (props: Props) => {
  const className = `td${props.tableCellProps.className ? ` ${props.tableCellProps.className}` : ''}`;
  const style = {
    ...props.tableCellProps.style,
    maxWidth: props.tableCellProps?.style?.width || 'auto',
    minWidth: '10px',
    display: 'table-cell',
  };

  return (
    <Wrapper
      { ...props.tableCellProps }
      className={ className }
      isSimpleDesignMode={ props.isSimpleDesignMode }
      onClick={ props.onClick }
      key={ props.tableCellProps.key }
      role={ props.tableCellProps.role }
      style={ style }
    >
      {props.cell}
    </Wrapper>
  );
};
