import ApiBase from './api_base';

let authPath = '/api/v1/auth';

if (global.userPreferences && global.userPreferences.agent) {
  authPath = '/api/v1/agent_auth';
}

export default new ApiBase('v3', { authPath })
  .get('/kernels/organizations/departments/managements/search')
  .get('/kernels/organizations/departments/search')
  .post('/notifications', { to: 'notifications.index', options: { headers: { 'X-HTTP-Method-Override': 'GET' } } })
  .resources('/personal_categories', { only: ['show', 'index'] })
  .build();
