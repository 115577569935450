import * as ResponsesGenericFieldsItems from 'utilities/api/responses/genericFields/dataSets/items';
import Api from 'utilities/api';
import InfiniteGenericFieldsSelector from './InfiniteGenericFieldsSelector';
import LazyElement from 'components/LazyElement';
import React, { PureComponent } from 'react';
import SearchBox from 'components/SearchBox';
import SearchResult from 'components/SearchResult';
import i18next from 'i18n';
import styled from 'styled-components';
import { GenericFieldsItemAppendForm } from './GenericFieldsItemAppendForm';
import { Item } from 'utilities/api/models/genericFields/Item';
import { snakecaseKeys } from 'utilities/Utils';

const BATCH_SIZE = 100; // 1回のリクエストで取得する件数
const ITEM_HEIGHT = 68; // 各リストアイテムの高さ

const NameView = styled.div`
  margin-bottom: .5rem;
  font-size: 1.5rem;
`;

const CodeView = styled.div`
  opacity: .85;
`;

const GenericFieldsItemsSearchResult = styled(SearchResult)`
  margin-top: 1.5rem;
`;

async function fetchGeneficFieldsItems(dataSetId: string, searchText: string, offset = 0, limit = BATCH_SIZE, includeDeleted = false): Promise<ResponsesGenericFieldsItems.IndexResponse> {
  const params = {
    limit,
    offset,
    dataSetId,
    searchText,
    includeDeleted,
  };

  return await Api.genericFields.dataSets.itemsForUsers.index(snakecaseKeys(params));
}

function formatOption(item?: Item): React.ReactElement | null {
  const children = (
    <div>
      <NameView>
        { item?.name }
      </NameView>
      <CodeView>
        { item?.code }
      </CodeView>
    </div>
  );

  return (
    <LazyElement height={ ITEM_HEIGHT } >
      { children }
    </LazyElement>
  );
}

interface Props {
  dataSet,
  isUserCreatableItem,
  isMultipleSelectable,
  onSelectItems,
  includeDeleted?: boolean,
}

interface State {
  searchText: string;
  options: Item[];
  totalCount: number | null;
}

/**
 * 汎用マスターセレクターコンポーネント
 *
 * @description InfiniteSelector を使用しているためPureComponentで実装（FCだと下位のSelectorが正しく動作しない）
 */
class GenericFieldsItemsSelector extends PureComponent<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {
      searchText: '',
      options: [] as Item[],
      totalCount: null,
    };

    this.handleSearch = this.handleSearch.bind(this);
    this.handleOptionsChange = this.handleOptionsChange.bind(this);
    this.handleSearchTextChange = this.handleSearchTextChange.bind(this);
    this.onSelect = this.onSelect.bind(this);
    this.onSelectNewlyCreatedItem = this.onSelectNewlyCreatedItem.bind(this);
  }

  async handleSearch(offset: number, limit: number): Promise<ResponsesGenericFieldsItems.IndexResponse> {
    return fetchGeneficFieldsItems(this.props.dataSet.dataSetId, this.state.searchText, offset, limit, this.props.includeDeleted);
  }

  handleOptionsChange(options: Item[], totalCount = this.state.totalCount): void {
    this.setState({ options, totalCount });
  }

  handleSearchTextChange(searchText: string): void {
    this.setState({ searchText });
  }

  onSelect(_newItem: Item, _isSelected: boolean, nextSelection: Item | Item[] | null): void {
    // nextSelectionの型は3種類存在するが、EntryForms.jsxで配列型(Item[])へ変換している
    this.props.onSelectItems(nextSelection);
  }

  onSelectNewlyCreatedItem(newItem: Item): void {
    const newItems = this.props.isMultipleSelectable ? this.props.dataSet.items.concat(newItem) : newItem;
    this.props.onSelectItems(newItems);

    const currentOptions = this.state.options;
    const currentCount = this.state.totalCount || 0;
    const newOptions = currentOptions.concat(newItem);
    const newCount = currentCount + 1;
    this.handleOptionsChange(newOptions, newCount);
  }

  render(): JSX.Element {
    const { dataSet, isUserCreatableItem, isMultipleSelectable } = this.props;
    const { dataSetId, items } = dataSet;

    return (
      <InfiniteGenericFieldsSelector
        isMultiSelectable={ isMultipleSelectable }
        selection={ isMultipleSelectable ? items : items[0] }
        totalCount={ this.state.totalCount }
        rowHeight={ ITEM_HEIGHT }
        batchSize={ BATCH_SIZE }
        options={ this.state.options }
        formatOption={ formatOption }
        fetchOptions={ this.handleSearch }
        onSelect={ this.onSelect }
        onOptionsChange={ this.handleOptionsChange }
    >
        {(renderSelector, { onOptionsReset }): JSX.Element => {
          return (
            <div className='generic-fields-selector'>
              { isUserCreatableItem && <GenericFieldsItemAppendForm
                dataSetId={ dataSetId }
                onSelectNewlyCreatedItem={ this.onSelectNewlyCreatedItem }
              />}
              <SearchBox value={ this.state.searchText }
                placeholder={ i18next.t('genericFields.items.search.placeholders') }
                onChange={ this.handleSearchTextChange }
                onClick={ onOptionsReset }
              />

              <GenericFieldsItemsSearchResult count={ this.state.totalCount } >
                { renderSelector() }
              </GenericFieldsItemsSearchResult>
            </div>
          );
        }}
      </InfiniteGenericFieldsSelector>
    );
  }
}

export default GenericFieldsItemsSelector;
