import { ExpenseViewData } from 'view_data/ExpenseViewData';
import { IReportDriver } from './IReportDriver';
import { IReportExpenseDriver } from './IReportExpenseDriver';
import { ReportViewData } from 'view_data/ReportViewData';
import { ShowResponse } from 'utilities/api/responses/reports';
import { toReportViewData } from './ReportTransformer';

type PickedKeys =
  'canApprove' |
  'count' |
  'remainingApprovals' |
  'requestEvents' |
  'requestId' |
  'user' |
  'userId';

export interface ReportResponse extends Pick<ShowResponse, PickedKeys> {
  report: ReportViewData;
  expenses: ExpenseViewData[];
}

export class ReportApi {
  constructor(private driver: IReportDriver, private expenseDriver: IReportExpenseDriver) {
    this.driver = driver;
    this.expenseDriver = expenseDriver;
  }

  /** 経費申請を取得します */
  async fetch(reportId: string): Promise<ReportResponse> {
    const report = await this.driver.fetch(reportId);
    const expenses = await this.expenseDriver.fetchAll({ reportId, expensesSize: report.count });

    return {
      canApprove: report.canApprove,
      count: report.count,
      remainingApprovals: report.remainingApprovals,
      report: toReportViewData(report),
      requestEvents: report.requestEvents,
      requestId: report.requestId,
      expenses: expenses.data,
      user: report.user,
      userId: report.userId,
    };
  }
}
