import set from "lodash/set";
import Api from "utilities/api";
import flash from "utilities/flash";

export function fetchAccountList() {
  return Api.accountForms.index();
}

/**
 * @this AccouontListCard
 */
export function onFetchAccountListDone(data) {
  if (data && data.length > 0) {
    set(data, "[0].isActive", true);
  }
  const accounts = processAccounts(data);
  this.setState({ accounts });
}

export function onFetchAccountListError(err) {
  const errMsg = "連携口座・カード一覧の取得に失敗しました。";
  flash.error(errMsg);
}

export function processAccounts(groups) {
  const accountsMap = new Map();
  const allAccounts = [];

  for (let i = 0; groups[i]; i++) {
    const group = groups[i];
    const { name, accounts } = group;

    accounts.forEach((account) => {
      accountsMap.set(account.id, name);
      allAccounts.push({ ...account });
    });
  }

  const groupsWithAll = [
    { name: "すべて", accounts: allAccounts, isActive: false },
    ...groups,
  ];

  return groupsWithAll.map((x) => {
    return {
      ...x,
      accounts: x.accounts.map((account) => {
        return { ...account, group: accountsMap.get(account.id) };
      }),
    };
  });
}

/**
 * @this AccouontListCard
 */
export function onClickTab(e, group) {
  resetActiveStatus.bind(this)();
  set(group, "isActive", true);
  this.setState({
    accounts: this.state.accounts,
  });
}

/**
 * @this Root Component
 */
export function resetActiveStatus() {
  this.state.accounts.forEach((group) => {
    set(group, "isActive", false);
  });
}

export function onClickAccountLink(e, datum) {
  redirectTo(`/account_forms/${datum.id}/new`);
}

export function redirectTo(url) {
  window.location.href = url;
}
