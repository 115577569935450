import Api from 'utilities/api_payment_requests';
import InfiniteSelector from 'components/fields/InfiniteSelector';
import React, { useCallback } from 'react';
import SearchBox from 'components/SearchBox';
import SearchResult from 'components/SearchResult';
import i18next from 'i18n';
import { CreditInvoiceCategory } from 'utilities/api_payment_requests/models';
import { InvoiceCategoryOption, SelectorValue, useInvoiceCategorySelectorState } from './payment_requests/hooks/useInvoiceCategorySelectorState';

interface Props {
  /** 複数選択可能か */
  isMultiSelectable: boolean;
  /** 選択時のハンドラ */
  onSelect: () => void;
  /** 子カテゴリのみか */
  onlyChildCategories: boolean;
  /** 返却する値(複数選択可能の場合は配列) */
  value: SelectorValue | SelectorValue[];
}

/** 貸方請求明細科目選択用コンポーネント */
export const CreditInvoiceCategorySelector: React.FC<Props> = ({
  isMultiSelectable, onSelect, onlyChildCategories, value,
}) => {
  const {
    searchText,
    filteredOptions,
    totalCount,
    setSearchText,
    search,
    onOptionsChange: handleOptionsChange,
  } = useInvoiceCategorySelectorState<CreditInvoiceCategory>({
    fetcher: Api.creditInvoiceCategories.index,
    isMultiSelectable,
    onlyChildCategories,
    value,
  });

  /** 項目表示 */
  const formatOption = useCallback((option: InvoiceCategoryOption): JSX.Element => {
    return (
      <div className='list-selector-item-content'>
        <div>{ option?.name }</div>
      </div>
    );
  }, []);

  return (
    <InfiniteSelector
      responseKey='categories'
      isMultiSelectable={ isMultiSelectable }
      options={ filteredOptions || [] }
      selection={ value }
      totalCount={ totalCount }
      rowHeight={ 42 }
      formatOption={ formatOption }
      fetchOptions={ search }
      onSelect={ onSelect }
      onOptionsChange={ handleOptionsChange }
    >
      {(renderSelector, { onOptionsReset }): JSX.Element => {
        return (
          <div className='category-selector'>
            <SearchBox value={ searchText }
              placeholder={ i18next.t('paymentRequests.creditInvoiceCategory.actions.searchPlaceholder') }
              onChange={ setSearchText }
              onClick={ onOptionsReset }
            />
            <SearchResult count={ totalCount }>
              { renderSelector() }
            </SearchResult>
          </div>
        );
      }}
    </InfiniteSelector>
  );
};
