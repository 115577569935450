const initialState = {
  ownerId: userPreferences.id,
};

const formData = (state = initialState, action) => {
  switch (action.type) {
    default: return state;
  }
};

export default formData;
