import ManualMergeModal from "applications/transactions/components/ManualMergeModal";
import MergeableTransactionsSearchModal from "applications/transactions/components/MergeableTransactionsSearchModal";
import { DataFetchingTable } from "components/renewaled_ui/DataFetchingTable";
import i18next from "i18n";
import React from "react";
import { Column } from "react-table";
import { Expense } from "utilities/api/models";
import Formatter from "utilities/formatter";
import fonts from "values/fonts";
import { ExpenseTableColumn } from "../../types";
import { AlertTimeStampIconsWithOverlay } from "./AlertTimeStampIconsWithOverlay";
import { RoutePayee } from "./column/RoutePayee";
import { CompanionsIconWithOverlay } from "./CompanionsIconWithOverlay";
import { IsElectronicReceiptImageIcon } from "./IsElectronicReceiptImageIcon";
import { LabelIconsWithOverlay } from "./LabelIconsWithOverlay";
import { ReceiptMatchingResultStatusIcon } from "./ReceiptMatchingResultStatusIcon";
import { UnreadIcon } from "./UnreadIcon";

/** reduxに保存されている経費情報 */
type ExpenseState = Expense & { isSelected: boolean };

/** DataFetchingTableに渡すDataの配列(未fetch分はundefined) */
type ExpenseDataFetchingTableDataInput = (ExpenseState | undefined)[];

/** reduxに保存されているtransactionsObjの型 */
type ExpensesObj = { master: ExpenseState[]; inEdit: ExpenseState[] };

/** 統合可能な経費の組合せ検索props */
interface PairsSearch {
  readonly isFetching: boolean;
  readonly option: {
    readonly ignoreTransactedAt: boolean;
  };
  readonly modal: boolean;
  readonly pairs: {
    readonly parent: string;
    readonly sub: string[];
  };
}

interface Props {
  /** 経費一覧 */
  readonly expenses: ExpenseState[];
  /** 現在のページ */
  readonly currentPage: number;
  /** 1ページ当たりの表示件数 */
  readonly sizePerPage: number;
  /** 経費一覧データ取得 */
  readonly fetchExpenses: (
    startIndex: number,
    sizePerPage: number,
    background: boolean,
  ) => Promise<void>;
  /** ソート時のハンドラ */
  readonly onSortChange: (sortName: string, sortOrder: string) => Promise<void>;
  /** ページ移動時のハンドラ */
  readonly onPageChange: (page: number) => void;
  /** 1ページの表示件数変更時のハンドラ */
  readonly onSizePerPageList: () => void;
  /** 経費を選択した際のハンドラ */
  readonly onSelect: (
    expensesObj: ExpensesObj,
    expense: ExpenseState | false,
    isSelected: boolean,
  ) => void;
  /** 経費をすべて選択した際のハンドラ */
  readonly onSelectAll: (
    currentPage: number,
    sizePerPage: number,
    expensesObj: ExpensesObj,
    isSelected: boolean,
  ) => void;
  /** Reduxで保持している経費情報 */
  readonly expensesObj: ExpensesObj;
  /** 経費テーブルの列情報 */
  readonly columns: ExpenseTableColumn[];
  /** 列幅変更時のハンドラ */
  readonly onColumnWidthChange: (id: string, width: number) => void;
  /** 行をクリックした際のハンドラ */
  readonly openExpenseModal: (
    expenses: ExpenseState[],
    expense: ExpenseState,
    currentPage: number,
    sizePerPage: number,
  ) => void;
  /** 経費編集モーダルを開いているか */
  readonly isExpenseModalOpen: boolean;
  /** シングルビューで開いている経費 */
  readonly modalExpense: ExpenseState;
  /** 統合可能な経費の組合せ検索props */
  readonly pairsSearch: PairsSearch;
  /** 統合可能な経費の組合せ検索モーダルを閉じる */
  readonly closeMergeableTransactionsPairsSearchModal: () => void;
  /** 統合可能な経費の組合せ検索オプションの変更 */
  readonly editOption: (key: string | null, value: string) => void;
  /** 経費統合モーダルの開閉状態 */
  readonly mergeSelecter: { modal: boolean };
  /** 統合可能な経費の組合せを検索 */
  readonly searchMergeableTransactionsPairs: () => void;
  /** 経費統合を実行 */
  readonly mergeTransactions: () => void;
  /** 経費統合モーダルを閉じる */
  readonly closeMergedSelecter: () => void;
  /** 統合経費を選択時のハンドラ */
  readonly selectMergedTransaction: (index: number) => void;
  /** 経費情報を取得中か */
  readonly isLoading: boolean;
  /**
   * テーブルの最大高さ
   * いろんな指定方法が想定されるので、cssのプロパティをそのまま文字列指定
   * maxHeighを超えた場合、ヘッダー固定でスクロールが発生する
   */
  readonly maxHeight?: string;
  /** 選択した項目の操作ドロップダウン内に表示するDOM */
  readonly renderSelectedItemsControlMenu?: () => JSX.Element;
  /**
   * テーブル左下の１ページあたりのデータ取得結果を表示するか
   * 申請可能経費を取得する場合、アラート有無のフィルターをSQL実行後に行うため
   * リクエストの件数と変えてくる件数に差がある場合があるため、申請可能経費取得時はfalseにして結果件数を隠す。
   */
  readonly showItemsCount: boolean;
}

/**
 * 経費テーブル
 */
export const ExpenseTable: React.FC<Props> = ({
  expenses,
  currentPage,
  sizePerPage,
  fetchExpenses,
  onSortChange,
  onPageChange,
  onSizePerPageList,
  onSelect,
  onSelectAll,
  expensesObj,
  columns,
  onColumnWidthChange,
  openExpenseModal,
  isExpenseModalOpen,
  modalExpense,
  pairsSearch,
  closeMergeableTransactionsPairsSearchModal,
  editOption,
  mergeSelecter,
  searchMergeableTransactionsPairs,
  mergeTransactions,
  closeMergedSelecter,
  selectMergedTransaction,
  isLoading,
  maxHeight,
  showItemsCount,
}) => {
  /** テーブルセル表示形式を指定 */
  const formatExpenseTableBodyCell = (
    expense: ExpenseState,
    key: string,
  ): JSX.Element | string | boolean => {
    switch (key) {
      case "isSelected":
        return expense.isSelected;
      case "id":
        return expense.id;
      case "error":
        return (
          <AlertTimeStampIconsWithOverlay
            id={expense.id}
            alerts={expense.alerts}
            warnings={expense.warnings}
            receiptImages={expense.receiptImages}
          />
        );
      case "read":
        return <UnreadIcon read={expense.read} />;
      case "transactedAt":
        return Formatter.date(expense.transactedAt, { withDayOfTheWeek: true });
      case "label":
        return <LabelIconsWithOverlay expense={expense} />;
      case "hasValidatedReceiptMatching":
        return <ReceiptMatchingResultStatusIcon expense={expense} />;
      case "isElectronicReceiptImage":
        return <IsElectronicReceiptImageIcon expense={expense} />;
      case "shopName":
        // 交通費であるような場合はそれ用の描画を実施する
        return expense.route ? (
          <RoutePayee
            id={expense.id}
            originStation={
              expense.route.origin?.name || i18next.t("commons.words.unknown")
            }
            destinationStation={
              expense.route.destination?.name ||
              i18next.t("commons.words.unknown")
            }
            isRoundTrip={expense.route.isRoundTrip}
            vias={expense.route.via || []}
            labels={expense.route.labels}
            payeeName={expense.shopName || ""}
            onewayDistance={expense.route.onewayDistance}
          />
        ) : (
          expense.shopName || ""
        );
      case "amount":
        return (
          <>
            <span style={{ marginLeft: "8px" }}>
              {Formatter.amount(expense.amount)}
            </span>
          </>
        );
      case "withholding":
        return Formatter.amount(expense.withholding);
      case "ownerName":
        return Formatter.text(expense.ownerName, "所有者なし");
      case "categoryName":
        return Formatter.categoryName(
          expense.categoryName,
          expense.inputBy,
          expense.status,
        );
      case "expenseAmountPerTaxCategories":
        return Formatter.text(
          expense.expenseAmountPerTaxCategories
            .map((o) => o.taxCategory.name)
            .join("、"),
        );
      case "comment":
        return expense.comment || "";
      case "transitPayee":
        return expense.transitPayee || "";
      case "companions":
        return (
          <CompanionsIconWithOverlay
            id={expense.id}
            companions={expense.companions}
          />
        );
      case "originAndDestinationByCategory":
        return expense.originByCategory && expense.destinationByCategory
          ? Formatter.text(
              `${expense.originByCategory} → ${expense.destinationByCategory}`,
            )
          : "";
      case "visitByCategory":
        return Formatter.text(expense.visitByCategory, "");
      case "purposeByCategory":
        return Formatter.text(expense.purposeByCategory, "");
      case "project":
        return Formatter.project(
          expense.project,
          null,
          null,
          ["name", "displayId"],
          true,
        );
      case "department":
        return Formatter.text(expense.department?.name, null, null);
      case "costAllocations":
        return Formatter.text(
          expense.costAllocations.map((o) => o.payerName).join("、"),
        );
      case "creditCategoryName":
        return Formatter.categoryName(
          expense.creditCategoryName,
          expense.inputBy,
          expense.status,
        );
      case "reportTitle":
        return Formatter.text(
          expense.reportTitle,
          i18next.t("commons.status.unsubmitted"),
        );
      case "isCorporate":
        // Refundは仮払い過剰時に従業員から企業への返金という意味合いで使われがちな印象。単語を変えたほうが良いかも。
        return expense.isCorporate
          ? (i18next.t("transactions.labels.noRefund") as string)
          : "";
      case "status":
        return Formatter.status(expense.status);
      case "createdAt":
        return Formatter.date(expense.createdAt);
      case "reportSequenceNum":
        return expense.reportSequenceNum || "";
      case "preReportSequenceNum":
        return expense.preReportSequenceNum || "";
      case "registratedNumber":
        return expense.registratedNumber ? `T${expense.registratedNumber}` : "";
      case "asEligibleInvoice":
        return expense.asEligibleInvoice ? <i className="fa fa-check"></i> : "";
      default: {
        const genericField = expense.genericFields?.find(
          (gf) => gf.dataSetId === key,
        );

        if (genericField) {
          const itemNames = genericField.items.map((i) => i.name).join("、");
          return Formatter.text(itemNames, "");
        }

        return "";
      }
    }
  };

  /** 経費データをDataFetchTableに渡すデータ型(未fetch分はundefined)に成型 */
  const formatExpenseDataFetchTableData =
    (): ExpenseDataFetchingTableDataInput => {
      const expenseTableDataInput = expenses.map((expense) => {
        if (!expense) return undefined;

        const data = {};
        columns.forEach((column) => {
          const key = column.accessor as string;
          data[key] = formatExpenseTableBodyCell(expense, key);
        });

        return data as ExpenseState;
      });

      return expenseTableDataInput;
    };

  /** 行クリックで経費モーダルを開く */
  const handleRowClick = (expense): void => {
    const clickedExpense = expenses.find((e) => e && e.id === expense.id);
    if (!clickedExpense) return;
    openExpenseModal(expenses, clickedExpense, currentPage, sizePerPage);
  };

  /** テーブルに渡す列データ生成(ReactTableに必要な型) */
  const tableColumns = (): Column[] => {
    return columns.filter((column) => column.show && column.selectable);
  };

  /** 列の並び順を生成(ReactTableに必要な型) */
  const tableColumnOrder = (): Column["accessor"][] => {
    return columns
      .filter((column) => column.show && column.selectable)
      .sort((a, b) => {
        if (a.index === null || b.index === null) return -1;
        return a.index - b.index;
      })
      .map((column) => column.accessor);
  };

  return (
    <div>
      <DataFetchingTable
        columns={tableColumns()}
        columnOrder={tableColumnOrder()}
        data={formatExpenseDataFetchTableData()}
        currentPage={currentPage}
        sizePerPage={sizePerPage}
        sizePerPageList={[10, 30, 50]}
        fetchData={fetchExpenses}
        onSortChange={onSortChange}
        onPageChange={onPageChange}
        onSizePerPageList={onSizePerPageList}
        showCheckBoxes={true}
        onSelect={(expense, isSelected): void =>
          onSelect(expensesObj, expense, isSelected)
        }
        onSelectAll={(isSelected): void =>
          onSelectAll(currentPage, sizePerPage, expensesObj, isSelected)
        }
        onColumnWidthChange={onColumnWidthChange}
        onRowClick={handleRowClick}
        viewingData={isExpenseModalOpen ? modalExpense : undefined}
        bodyFontWeight={Number(fonts.weight.bold) as 500 | 600}
        maxHeight={maxHeight}
        isLoading={isLoading && !isExpenseModalOpen}
        columnResizable
        showItemsCount={showItemsCount}
      />

      <MergeableTransactionsSearchModal
        show={pairsSearch.modal}
        onHide={(): void => closeMergeableTransactionsPairsSearchModal()}
        option={pairsSearch.option}
        editOption={editOption}
        search={(): void => searchMergeableTransactionsPairs()}
      />
      <ManualMergeModal
        show={mergeSelecter.modal}
        onHide={(): void => closeMergedSelecter()}
        mergeTargetList={pairsSearch.pairs}
        mergeTransactions={(): void => mergeTransactions()}
        selectMergeTarget={(pairIndex: number): void =>
          selectMergedTransaction(pairIndex)
        }
      />
    </div>
  );
};
