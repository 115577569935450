import * as ActionTypes from '../actions/approversSearchActions';

export const initialState = {
  status: {
    isFetching: false,
  },
  searchConditions: {
    user: {
      searchText: '',
    },
  },
  result: {
    groups: [],
    posts: [],
    projectPosts: [],
    users: [],
    departmentPosts: [],
  },
};

export const approversSearch = (state = initialState, action) => {
  switch (action.type) {
    case ActionTypes.CONFIG_APPROVERS_USER_SEARCH_CONDITION:
      return {
        ...state,
        searchConditions: {
          ...state.searchConditions,
          user: {
            [action.key]: action.value,
          },
        },
      };
    case ActionTypes.REQUEST_APPROVERS_SEARCH:
      return {
        ...state,
        status: {
          ...state.status,
          isFetching: true,
        },
      };
    case ActionTypes.RECEIVE_APPROVERS_SEARCH:
      return {
        ...state,
        status: {
          ...state.status,
          isFetching: false,
        },
        result: action.value,
      };
    default:
      return state;
  }
};

export default approversSearch;
