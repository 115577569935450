import AutoSuggestWrapper from "components/auto_suggest_wrapper";
import CheckBoxGroupAddon from "components/CheckBoxGroupAddon";
import i18next from "i18n";
import React, { FC } from "react";
import styled from "styled-components";

const ContainerView = styled.div`
  display: flex;

  .autosuggest-default {
    width: 100%;

    .react-autosuggest-input {
      border-top-right-radius: unset;
      border-bottom-right-radius: unset;
    }
  }

  .input-group-addon {
    width: fit-content;
    display: flex;
    justify-content: center;
    align-items: center;
  }
`;

interface Props {
  searchEmpty: boolean;
  shopName: string;
  shops: Array<string>;
  onShopNameChange: () => void;
  onShopNameSelect: () => void;
  onShopNameClearValue: () => void;
  onShopNameBlur: () => void;
  onSearchEmptyChange: () => void;
}

export const ShopName: FC<Props> = (props) => {
  return (
    <ContainerView className="transaction-shopname">
      <AutoSuggestWrapper
        value={props.shopName}
        suggestions={props.shops}
        getSuggestionValue={(v): void => v}
        placeholder={{
          placeholder: i18next.t("transactions.index.searchbox.all"),
          onBlur: props.onShopNameBlur,
        }}
        onTextChanged={props.onShopNameChange}
        onSelect={props.onShopNameSelect}
        clearValue={props.onShopNameClearValue}
        disabled={props.searchEmpty}
        key={props.searchEmpty}
      />
      <CheckBoxGroupAddon
        label={i18next.t("transactions.index.searchbox.blankSearch")}
        defaultChecked={props.searchEmpty}
        onChange={props.onSearchEmptyChange}
      />
    </ContainerView>
  );
};

ShopName.defaultProps = {
  shopName: "",
  searchEmpty: false,
  shops: [],
};

export default ShopName;
