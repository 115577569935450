import CompanionList from '../CompanionList';
import CompanionSelectorCol from './CompanionSelectorCol';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import styled from 'styled-components';

const CompanionListsView = styled.div`
  display: flex;
  margin-top: 8px;
  .companion-list {
    width: 50%;
  }
`;

/** 自社と取引先の参加者入力をサポートするコンポーネント */
export default class CompanionSelector extends Component {
  onSelect(key, value) {
    const isColleague = key === 'colleague';
    const colleagues = isColleague ? value
      : this.props.value.filter((c) => !c.isClient);
    const clients = !isColleague ? value
      : this.props.value.filter((c) => c.isClient);
    if (this.props.onSelect) {
      this.props.onSelect(colleagues.concat(clients));
    }
  }

  render() {
    return (
      <div>
        <div className={ 'list-selector form-control' }>
          <CompanionSelectorCol
            isClient={ false }
            onSelect={ this.onSelect.bind(this, 'colleague') }
            value={ this.props.value.filter((c) => !c.isClient) }
            ownerId={ this.props.ownerId }
            static={ this.props.static }
            shouldSelectSelfAsCompanion={ this.props.shouldSelectSelfAsCompanion }
            onSelectCompanionsCategory={ this.props.onSelectCompanionsCategory }
            disabled={ this.props.disabled } />
          <CompanionSelectorCol
            isClient={ true }
            onSelect={ this.onSelect.bind(this, 'client') }
            value={ this.props.value.filter((c) => c.isClient) }
            ownerId={ this.props.ownerId }
            static={ this.props.static }
            shouldSelectSelfAsCompanion={ this.props.shouldSelectSelfAsCompanion }
            onSelectCompanionsCategory={ this.props.onSelectCompanionsCategory }
            disabled={ this.props.disabled } />
        </div>
        <CompanionListsView>
          <CompanionList
            className='companion-list'
            companions={ this.props.value.filter((c) => !c.isClient) }
            showCompany={ false } />
          <CompanionList
            className='companion-list'
            companions={ this.props.value.filter((c) => c.isClient) }
            showCompany={ true } />
        </CompanionListsView>
      </div>
    );
  }
}

CompanionSelector.defaultProps = {
  value: [],
  shouldSelectSelfAsCompanion: true,
  onSelectCompanionsCategory: () => null,
  disabled: false,
};

CompanionSelector.propTypes = {
  value: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.string.isRequired,
  })),
  ownerId: PropTypes.string,
  static: PropTypes.bool,
  // 親要素側で、onSelect発火時にprops.valueを更新する
  onSelect: PropTypes.func.isRequired,
  shouldSelectSelfAsCompanion: PropTypes.bool,
  onSelectCompanionsCategory: PropTypes.func,
  disabled: PropTypes.bool,
};
