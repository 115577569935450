import Formatter, { departmentWithAbsolutePath } from 'utilities/formatter';
import ListSelector from 'components/list_selector';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import WebStorage from 'utilities/web_storage';
import escapeRegExp from 'lodash/escapeRegExp';
import i18next from 'i18n';
import isNil from 'lodash/isNil';

const localStorage = new WebStorage(['departmentSelector']);

export default class DepartmentSelector extends Component {
  constructor(props) {
    super(props);

    this.state = {
      // 検索フィルタ適用後の部署
      filteredDepartments: props.departments.slice(0, this.getSizePerPage()),
      searchText: '',
    };
  }

  // eslint-disable-next-line camelcase
  UNSAFE_componentWillReceiveProps(nextProps) {
    // 時間差で部署情報が与えられる場合は差分をみて更新する
    if (nextProps.departments.length !== this.props.departments.length) {
      this.setState({ searchText: '', filteredDepartments: nextProps.departments.slice(0, this.getSizePerPage()) });
    }
  }

  // 検索条件に引っかかるか
  isMatchedForSearchText(department, searchText) {
    const text = escapeRegExp(searchText);

    if (isNil(text)) {
      return true;
    }

    return new RegExp(text).test(department.name);
  }

  displayFormat(item) {
    if (isNil(this.props.displayFormat)) {
      return (
        <div className='list-selector-item-content'>
          <div>{ isNil(item.absolutePath) ? item.name : departmentWithAbsolutePath(item.absolutePath) }</div>
        </div>
      );
    }

    return (
      <div className='list-selector-item-content'>
        <div>{ this.props.displayFormat(item) }</div>
      </div>
    );
  }

  changeSearchText(e) {
    this.setState({ searchText: e.target.value });
  }

  submitSearchText() {
    this.setState({ filteredDepartments: this.props.departments.filter((d) => this.isMatchedForSearchText(d, this.state.searchText)).slice(0, this.getSizePerPage()) });
  }

  onClickResetSearchConditionButton() {
    this.setState({ searchText: '', filteredDepartments: this.props.departments.slice(0, this.getSizePerPage()) });
  }

  getSizePerPage() {
    try {
      return localStorage.getItem('sizePerPageForDepartmentSelector') || 10;
    } catch (_) {
      return 10;
    }
  }

  onChangeSizePerPage(sizePerPage) {
    localStorage.setItem('sizePerPageForDepartmentSelector', sizePerPage);
    this.submitSearchText();
  }

  render() {
    const search = {
      searchPlaceholder: i18next.t('groups.actions.searchPlaceholder'),
      inputText:         this.state.searchText,
      onTextChange:      this.changeSearchText.bind(this),
      onClickSubmit:     this.submitSearchText.bind(this),
      onClickResetSearchConditionButton: this.onClickResetSearchConditionButton.bind(this),
    };

    return (
      <ListSelector
        type={ this.props.isMultiSelectable ? 'checkbox' : 'radio' }
        buttonFormat={ this.props.buttonFormat }
        titleFormat={ () => (i18next.t('groups.titles.selectGroup')) }
        itemFormat={ this.displayFormat.bind(this) }
        items={ this.state.filteredDepartments }
        value={ this.props.selectedDepartment }
        onSelect={ this.props.onSelectDepartment }
        search={ search }
        disabled={ this.props.disabled }
        showSizePerPage={ true }
        sizePerPageList={ [10, 50, 100] }
        sizePerPage={ this.getSizePerPage() }
        onChangeSizePerPage={ this.onChangeSizePerPage.bind(this) }
        isClearable={ this.props.isClearable }
        onClear={ this.props.onClear }
      />
    );
  }
}

DepartmentSelector.defaultProps = {
  departments: [],
  selectedDepartment: null,
  disabled: false,
  isMultiSelectable: false,
  buttonFormat: (d, emptyText) => Formatter.text(d && d.name, emptyText),
  isClearable: false,
  onClear: () => null,
};

DepartmentSelector.propTypes = {
  departments: PropTypes.array.isRequired,
  selectedDepartment: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.object),
    PropTypes.object,
  ]),
  disabled: PropTypes.bool.isRequired,
  isMultiSelectable: PropTypes.bool,
  onSelectDepartment: PropTypes.func.isRequired,
  displayFormat: PropTypes.func,
  buttonFormat: PropTypes.func,
  isClearable:  PropTypes.bool,
  onClear: PropTypes.func,
};
