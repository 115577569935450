import AutoSuggestWrapper from "components/auto_suggest_wrapper";
import ProjectField from "components/fields/ProjectField";
import TextArea from "components/TextArea";
import i18next from "i18n";
import includes from "lodash/includes";
import isNil from "lodash/isNil";
import PropTypes from "prop-types";
import React, { Component } from "react";
import { OverlayTrigger, Popover } from "react-bootstrap";
import { connect } from "react-redux";
import * as actions from "../actions";

export class AccountPreferencesForm extends Component {
  componentDidMount() {
    const { fetchGroups, fetchCategories, isInEdit, onGroupChange } =
      this.props;

    fetchGroups();
    fetchCategories();
    if (!isInEdit) {
      onGroupChange(userPreferences.department.name);
    }

    if (
      !this.isCorporateCardAccountForm &&
      this.isCorporateCardAccountForPersonal
    ) {
      this.props.toggleIsCorporate(true);
    }
  }

  get isCorporateCardAccountForm() {
    return this.props.accountType === "CorporateCardAccount";
  }

  /**
   * TODO: カードのデフォルト値は、本来はサーバが判断すべき情報なので、フォームを返すAPIにこの情報を含ませるべき
   * 個人で法人カードに類するカードを登録した場合、そのカードはデフォルトでは "コーポレートカード（法人決済型）として登録" させたい
   * see account_aggregation.yml
   *  1218: MoneyT Global 法人カード
   */
  get isCorporateCardAccountForPersonal() {
    return [1218].includes(this.props.dwAccountFormId);
  }

  get isExcludingProductPurchaseCheckboxVisible() {
    // モバイルSuica、またはSMART ICOCAであれば該当チェックボックスを表示
    const { delegations, dwAccountFormId } = this.props;
    const { productPurchaseExcludableAccountIds } = delegations || {};
    return includes(
      productPurchaseExcludableAccountIds || [],
      dwAccountFormId || false,
    );
  }

  renderCheckboxes() {
    const {
      preferences,
      isCard,
      isEWallet,
      isExternalService,
      accountType,
      toggleIsCorporate,
      toggleMergeable,
      toggleIsAutoRegister,
      toggleIsExcludingProductPurchase,
    } = this.props;

    if (this.isCorporateCardAccountForm) {
      return null;
    }

    return (
      <div className="form-vertical" style={{ paddingBottom: "10px" }}>
        {(isCard || isEWallet || isExternalService) &&
          this.renderIsCorporateCheckbox(
            isCard,
            preferences.isCorporate,
            toggleIsCorporate,
          )}

        {this.renderCheckbox(
          "mergeable",
          i18next.t("accountForms.actions.toggleMergeable"),
          preferences.mergeable,
          toggleMergeable,
        )}

        {this.renderCheckbox(
          "is_auto_register",
          i18next.t("accountForms.actions.toggleAutoRegister"),
          preferences.isAutoRegister,
          toggleIsAutoRegister,
        )}
        {this.isExcludingProductPurchaseCheckboxVisible &&
          this.renderCheckbox(
            "is_excluding_product_purchase",
            i18next.t("accountForms.actions.toggleIsExcludingProductPurchase"),
            preferences.isExcludingProductPurchase,
            toggleIsExcludingProductPurchase,
          )}
      </div>
    );
  }

  renderCheckbox(id, label, checked, onChange, helpText = "") {
    return (
      <div className="form-group">
        <label className="col-sm-4 control-label">
          {label}
          {helpText === "" ? null : this.renderHelpPopover(helpText)}
        </label>
        <div className="col-sm-4">
          <label className="control-label" key={id}>
            <div className="checkbox-accent squared">
              <input
                type="checkbox"
                id={id}
                checked={checked}
                onChange={(e) => {
                  onChange(e.target.checked);
                }}
              />
            </div>
          </label>
        </div>
      </div>
    );
  }

  renderIsCorporateCheckbox(isCard, isCorporate, toggleIsCorporate) {
    const translationKey = isCard
      ? "registerAsCorporateCard"
      : "registerAsCorporateExpense";
    const label = i18next.t(`accountForms.actions.${translationKey}`);
    const helpText = i18next.t(`accountForms.help.${translationKey}`);

    return this.renderCheckbox(
      "is_corporate",
      label,
      isCorporate,
      toggleIsCorporate,
      helpText,
    );
  }

  renderHelpPopover(helpText) {
    const popover = <Popover id="registerAsCorporateCard">{helpText}</Popover>;
    return (
      <OverlayTrigger
        trigger={["hover", "focus", "click"]}
        placement="top"
        overlay={popover}
      >
        <i
          className="far fa-question-circle"
          style={{ color: "#AAA", marginLeft: "6px", cursor: "pointer" }}
        />
      </OverlayTrigger>
    );
  }

  renderDefaultParams() {
    const {
      userId,
      inputGroup,
      inputProject,
      preferences,
      suggestions,
      onCategoryNameChange,
      onGroupChange,
      onProjectChange,
      onCommentChange,
      onCategorySuggestionsUpdateRequested,
      onCategorySuggestionSelected,
      clearCategory,
      onGroupSuggestionsUpdateRequested,
      onGroupSuggestionSelected,
      clearGroup,
    } = this.props;

    return (
      <div className="form-horizontal">
        <div className="form-group">
          <label className="col-sm-4 control-label">
            {i18next.t("accountForms.actions.defaultCategory")}
          </label>
          <div className="col-sm-4">
            <AutoSuggestWrapper
              inputProps={{
                className:
                  "form-control validate[maxSize[255],funcCallRequired[validateCategory]]",
              }}
              value={preferences.defaultCategory?.name}
              suggestions={suggestions.categories.current}
              getSuggestionValue={(v) => v.name}
              onSuggestionsUpdateRequested={
                onCategorySuggestionsUpdateRequested
              }
              onTextChanged={onCategoryNameChange}
              onSuggestionSelected={onCategorySuggestionSelected}
              clearValue={clearCategory}
            />
          </div>
        </div>

        {inputGroup && (
          <div className="form-group">
            <label className="col-sm-4 control-label">
              {i18next.t("accountForms.actions.defaultGroup")}
            </label>
            <div className="col-sm-4">
              <AutoSuggestWrapper
                inputProps={{
                  className: "form-control validate[maxSize[255]]",
                }}
                value={preferences.defaultGroupName}
                suggestions={suggestions.groups.current}
                getSuggestionValue={(v) => v.name}
                onSuggestionsUpdateRequested={onGroupSuggestionsUpdateRequested}
                onTextChanged={onGroupChange}
                onSuggestionSelected={onGroupSuggestionSelected}
                clearValue={clearGroup}
              />
            </div>
          </div>
        )}

        {inputProject && (
          <div className="form-group">
            <label className="col-sm-4 control-label">
              {i18next.t("accountForms.actions.defaultProject")}
            </label>
            <div className="col-sm-4">
              <ProjectField
                value={preferences.defaultProject}
                onSelect={onProjectChange}
                ownerId={isNil(userId) ? null : userId}
              />
            </div>
          </div>
        )}

        <div className="form-group">
          <label className="col-sm-4 control-label">
            {i18next.t("accountForms.actions.defaultComment")}
          </label>
          <div className="col-sm-4">
            <TextArea
              className="form-control"
              value={preferences.defaultComment || ""}
              onChange={(e) => {
                onCommentChange(e.target.value);
              }}
            />
          </div>
        </div>
      </div>
    );
  }

  renderPreferences() {
    return (
      <div className="account-form-input">
        {this.renderCheckboxes()}
        <div className="form-group">{this.renderDefaultParams()}</div>
      </div>
    );
  }

  render() {
    const { id, isDisabled, isInEdit, onClickSubmit } = this.props;

    return (
      <form className="form-horizontal account-registration-form">
        {this.renderPreferences()}
        <div className="form-group row">
          <div className="col-sm-offset-5 col-sm-2">
            <button
              type="button"
              className={`btn btn-accent btn-block ${
                isDisabled ? "disabled" : ""
              }`}
              onClick={(e) => {
                onClickSubmit(id);
              }}
            >
              {isInEdit ? "設定を更新する" : "登録する"}
            </button>
          </div>
        </div>
      </form>
    );
  }
}

AccountPreferencesForm.defaultProps = {
  isInEdit: false,
  isNfc: false,
};

AccountPreferencesForm.propTypes = {
  id: PropTypes.string.isRequired,
  userId: PropTypes.string,
  accountType: PropTypes.string,
  isDisabled: PropTypes.bool.isRequired,
  isInEdit: PropTypes.bool.isRequired,

  inputGroup: PropTypes.bool.isRequired,
  inputProject: PropTypes.bool.isRequired,
  isCard: PropTypes.bool.isRequired,
  isEWallet: PropTypes.bool.isRequired,
  isNfc: PropTypes.bool.isRequired,
  isExternalService: PropTypes.bool.isRequired,

  dwAccountFormId: PropTypes.number,
  delegations: PropTypes.shape({
    productPurchaseExcludableAccountIds: PropTypes.array,
  }),

  preferences: PropTypes.shape({
    defaultCategory: PropTypes.shape({
      id: PropTypes.string,
      name: PropTypes.string,
    }),
    defaultGroupName: PropTypes.string,
    defaultProject: PropTypes.shape({
      name: PropTypes.string.isRequired,
      displayId: PropTypes.string.isRequired,
    }),
    defaultComment: PropTypes.string,
    isCorporate: PropTypes.bool.isRequired,
    mergeable: PropTypes.bool.isRequired,
    isAutoRegister: PropTypes.bool.isRequired,
    isExcludingProductPurchase: PropTypes.bool.isRequired,
  }),

  suggestions: PropTypes.shape({
    categories: PropTypes.shape({
      total: PropTypes.array,
      current: PropTypes.array,
    }),
    groups: PropTypes.shape({
      total: PropTypes.array,
      current: PropTypes.array,
    }),
  }).isRequired,

  onClickSubmit: PropTypes.func.isRequired,

  fetchGroups: PropTypes.func.isRequired,
  fetchCategories: PropTypes.func.isRequired,
  toggleIsCorporate: PropTypes.func.isRequired,
  toggleMergeable: PropTypes.func.isRequired,
  toggleIsAutoRegister: PropTypes.func.isRequired,
  toggleIsExcludingProductPurchase: PropTypes.func.isRequired,
  onCategoryNameChange: PropTypes.func.isRequired,
  onCategorySuggestionSelected: PropTypes.func.isRequired,
  onCategorySuggestionsUpdateRequested: PropTypes.func.isRequired,
  clearCategory: PropTypes.func.isRequired,
  onGroupChange: PropTypes.func.isRequired,
  onGroupSuggestionSelected: PropTypes.func.isRequired,
  onGroupSuggestionsUpdateRequested: PropTypes.func.isRequired,
  clearGroup: PropTypes.func.isRequired,
  onProjectChange: PropTypes.func.isRequired,
  onCommentChange: PropTypes.func.isRequired,
};

function mapStateToProps(state, ownProps) {
  const { accountForms, suggestions } = state;
  return {
    userId: accountForms.userId,
    accountType: accountForms.accountType,

    inputGroup: accountForms.inputGroup,
    inputProject: accountForms.inputProject,
    isCard: accountForms.isCard,
    isEWallet: accountForms.isEWallet,
    isExternalService: accountForms.isExternalService,
    isNfc: !accountForms.inputs.length,

    preferences: accountForms.preferences,
    dwAccountFormId: accountForms.dwAccountFormId,
    delegations: accountForms.delegations,

    suggestions,
  };
}

function mapDispatchToProps(dispatch, ownProps) {
  return {
    fetchGroups() {
      dispatch(actions.fetchGroups());
    },
    fetchCategories() {
      dispatch(actions.fetchCategories());
    },
    toggleIsCorporate(value) {
      dispatch(actions.toggleIsCorporate(value));
    },
    toggleMergeable(value) {
      dispatch(actions.toggleMergeable(value));
    },
    toggleIsAutoRegister(value) {
      dispatch(actions.toggleIsAutoRegister(value));
    },
    toggleIsExcludingProductPurchase(value) {
      dispatch(actions.toggleIsExcludingProductPurchase(value));
    },
    onCategoryNameChange(name) {
      dispatch(actions.setCategory("", name));
    },
    onCategorySuggestionSelected(e, { suggestion, suggestionValue, method }) {
      dispatch(actions.setCategory(suggestion.id, suggestion.name));
    },
    onCategorySuggestionsUpdateRequested({ value, reason }) {
      dispatch(actions.requestCategoryNameSuggestionsUpdate({ value, reason }));
    },
    clearCategory() {
      dispatch(actions.setCategory());
      dispatch(actions.fetchCategories());
    },
    onGroupChange(name) {
      dispatch(actions.setGroupName(name));
    },
    onGroupSuggestionSelected(e, { suggestion, suggestionValue, method }) {
      dispatch(actions.setGroupName(suggestionValue));
    },
    onGroupSuggestionsUpdateRequested({ value, reason }) {
      dispatch(actions.requestGroupNameSuggestionsUpdate({ value, reason }));
    },
    clearGroup() {
      dispatch(actions.setGroupName(""));
    },
    onProjectChange(project) {
      dispatch(actions.setProject(project));
    },
    onCommentChange(comment) {
      dispatch(actions.setComment(comment));
    },
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(AccountPreferencesForm);
