import LabeledComponent from 'components/LabeledComponent';
import PeriodInput from 'components/PeriodInput';
import React, { FC } from 'react';
import i18next from 'i18n';

interface Props {
  className?: string;
  appropriatedFrom: string | undefined;
  appropriatedTo: string | undefined;
  onAppropriatedFromChange: (date: string) => void;
  onAppropriatedToChange: (date: string) => void;
}

/**
 * Invoice における計上日の範囲を入力する
 */
export const AppropriatedAtRangeField: FC<Props> = ({
  className,
  appropriatedFrom,
  appropriatedTo,
  onAppropriatedFromChange,
  onAppropriatedToChange,
}) => {
  return (
    <LabeledComponent
      className={ className }
      label={ `${i18next.t('paymentRequests.properties.appropriatedAt')}（${i18next.t('menus.ip.invoices')}）` }
    >
      <PeriodInput
        className='appropriated-at-period'
        // undefinedの時にシステム日付がセットされるので空文字を渡す
        dateFrom={ appropriatedFrom ?? '' }
        dateTo={ appropriatedTo ?? '' }
        onChangeDateFrom={ (date: string): void => onAppropriatedFromChange(date ? new Date(date).toISOString() : date) }
        onChangeDateTo={ (date: string): void => onAppropriatedToChange(date ? new Date(date).toISOString() : date) }
      />
    </LabeledComponent>
  );
};
