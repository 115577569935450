import BootstrapCard from 'components/BootstrapCard';
import OriginalReceipt from '../forms/OriginalReceipt';
import PropTypes from 'prop-types';
import React from 'react';
import SendBackButton from '../buttons/SendBackButton';
import _isNull from 'lodash/isNull';
import i18next from 'i18n';

/**
 * 原本の詳細を表示するカード
 *
 * @note
 *   このコンポーネントは, 現状 'ペーパーレスを利用する事業所の管理者' と 'Beartailのペーパーレス管理者' が閲覧する
 *   原本に対して実行できるアクションは, ユーザ管理者なのか Beartail 管理者なのかによって異なる
 *   そのため, 表示するアクションは props.isPaperlessWorker に応じて変更する
 *   (UIがアプリ単位で分かれるまでの暫定的な記述になるはず...)
 */
const OriginalReceiptCard = (props) => {
  return (
    <BootstrapCard
      title={ i18next.t('paperless.originalReceipts.titles.details') }
      className={ props.className }
      style={ props.style }>
      <OriginalReceipt
        originalReceipt={ props.originalReceipt }
        dataLoadStatus={ props.dataLoadStatus }
        hiddenElements={ props.hiddenElements } />

      { /**
         * 削除のアクションが定義されていない,
         * あるいは ペーパーレスワーカー (社内ユーザ) でない場合は削除アクションを表示しない
         * @note 対応するアクションが多くなってきた場合, HOC を使うように修正すること
         */ }
      { _isNull(props.deleteOriginalReceipt) || !props.isPaperlessWorker
        ? null
        : (
          <>
            <div className='col-sm-12'>
              <hr style={ { width: '100%' } }/>
            </div>

            <div className='col-sm-12'>
              <SendBackButton
                className='col-sm-offset-10 col-sm-2'
                disabled={ !props.originalReceipt.id }
                onSendBack={ () => props.deleteOriginalReceipt(props.originalReceipt.id) } />
            </div>
          </>
        )
      }
    </BootstrapCard>
  );
};

OriginalReceiptCard.defaultProps = {
  isPaperlessWorker: false,
  className: '',
  style: null,
  hiddenElements: [],
  deleteOriginalReceipt: () => {},
  dataLoadStatus: '',
};

OriginalReceiptCard.propTypes = {
  isPaperlessWorker: PropTypes.bool,            // ペーパーレスのワーカーかどうか
  className: PropTypes.string,                  // class 指定
  style: PropTypes.object,                      // style 指定
  hiddenElements: PropTypes.array,              // 原本詳細で表示しないコンテンツ
  originalReceipt: PropTypes.object.isRequired, // 原本オブジェクト (ref: paperless/original_receipts/components/forms/OriginalReceipt)
  deleteOriginalReceipt: PropTypes.func,        // 原本の削除処理
  dataLoadStatus: PropTypes.string,             // 原本のロードステータス
};

export default OriginalReceiptCard;
