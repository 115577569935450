import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import camelCase from 'lodash/camelCase';
import formatter from 'utilities/formatter';
import i18next from 'i18n';
import moment from 'moment';
import styled from 'styled-components';
import {
  Button,
  Col,
  ControlLabel,
  FormControl,
  FormGroup,
  Row,
} from 'react-bootstrap';
import { Period } from 'utilities/api/models/accountingDataScheduledExports/Period';
import { Position } from '../types';
import { ScrollableForm } from '../ScrollableForm';
import { TargetReportType, Task, TaskCompanyExpenseAccountAssignee } from 'utilities/api/models/accountingDataScheduledExports';
import { formatPeriod } from 'applications/accounting_data_scheduled_exports/utils';

interface Props {
  task: Task;
}

const FormGroupView = styled.div`
  margin-bottom: 12px;
  border-radius: 6px;
  background: #f3f3f9;
  padding: 6px 12px;

  .title {
    margin: 12px 0 18px 0px;
    font-size: 14px;
    font-weight: bold;
  }
  .form-group {
    margin-bottom: 18px;
  }
  .control-label {
    display: block;
    background: #d9e2f4;
    border-radius: 4px;
    padding: 2px 4px;
  }
`;

const ButtonView = styled.div<{ active?: boolean; }>`
  text-align: center;
  border-radius: 6px;
  background: ${({ active }): string => (active ? '#e3edff' : 'transparent')};

  &:hover {
    background: #e3edff;
  }

  .btn.btn-link {
    color: ${({ active }): string => (active ? '#004acc' : '#020b16')};
    text-decoration: none;
    font-weight: ${({ active }): string => (active ? 'bold' : 'normal')};

    &:focus, &:focus-visible {
      outline: none;
    }

    &:hover {
      color: #004acc;
    }
  }
`;

interface MyField {
  label?: string;
  value?: string | null;
  isEmptyLine?: boolean;
}
interface FieldGroup {
  name: string;
  ref: React.RefObject<HTMLDivElement>;
  fields: MyField[];
}

const executionSchedule = ({
  executionInterval,
  executionDayOfMonth,
  executionDayLastDayOfMonth,
  executionDayOfWeek,
  executionOnceSpecifiedDate,
}): string => {
  moment.locale('ja');
  switch (executionInterval) {
    case 'by_month':
      if (executionDayLastDayOfMonth) {
        return '毎月 月末日';
      }
      return `毎月 ${executionDayOfMonth}日`;
    case 'by_weekday':
      return `毎週 ${moment().day(executionDayOfWeek).format('dddd')}`;
    case 'by_day':
      return '毎日';
    case 'by_once_specified_date':
      return `${moment(executionOnceSpecifiedDate).format('YYYY-MM-DD')}`;
    default:
      return '';
  }
};

const formatExecutionDate = (assignee: TaskCompanyExpenseAccountAssignee): string => {
  const { paymentRelativeSpecificationDays, paymentRelativeSpecificationType } = assignee;
  if (paymentRelativeSpecificationType) {
    return `${i18next.t('accountingDataScheduledExports.tasks.readOnlyForm.bankDataCreation.executionDate.prefix')}の${paymentRelativeSpecificationDays}${i18next.t(`accountingDataScheduledExports.tasks.readOnlyForm.bankDataCreation.executionDate.${camelCase(paymentRelativeSpecificationType)}`)}`;
  }

  return '';
};

const period = ({
  periodStartedAt,
  periodEndedAt,
}): string => {
  return `${moment(periodStartedAt).format('YYYY-MM-DD')}〜${moment(periodEndedAt).format('YYYY-MM-DD')}`;
};

const targetReportType = (type?: TargetReportType | null): string | null => {
  switch (type) {
    case 'is_report':
      return '経費精算';
    case 'is_temporary_payment':
      return '事前申請(仮払)';
    case 'is_travel':
      return '旅費申請';
    default:
      return null;
  }
};

const dateRange = (value?: Period | null): string | null => {
  if (!value) return null;

  return formatPeriod(value);
};

const checks = (objects: Record<string, boolean | null | undefined>): string | null => {
  const keys = Object.keys(objects).filter((k) => !!objects[k]);
  if (keys.length === 0) return null;

  return keys.join('、');
};

const specifiedDate = ({
  specifiedDateOfMonthRange,
  specifiedDateOfLastDayOfMonth,
  specifiedDateOfDay,
}): string | null => {
  switch (specifiedDateOfMonthRange) {
    case 'last_month_specified_date':
      if (specifiedDateOfLastDayOfMonth) {
        return '先月 月末日';
      }
      return `先月 ${specifiedDateOfDay}日`;
    case 'this_month_specified_date':
      if (specifiedDateOfLastDayOfMonth) {
        return '今月 月末日';
      }
      return `今月 ${specifiedDateOfDay}日`;
    case 'next_month_specified_date':
      if (specifiedDateOfLastDayOfMonth) {
        return '翌月 月末日';
      }
      return `翌月 ${specifiedDateOfDay}日`;
    default:
      return null;
  }
};

export const ReadOnlyForm: React.FC<Props> = ({
  task,
}) => {
  const [scrollTo, setScrollTo] = useState<Position>('base');
  const [activeArea, setActiveArea] = useState<Position>('base');
  const formRef = useRef<HTMLDivElement>(null);
  const refBase = useRef<HTMLDivElement>(null);
  const refAggregation = useRef<HTMLDivElement>(null);
  const refExport = useRef<HTMLDivElement>(null);
  const refBankExport = useRef<HTMLDivElement>(null);
  const refTransfer = useRef<HTMLDivElement>(null);

  const handleClick = useCallback((pos: Position) => setScrollTo(pos), [setScrollTo]);
  // スクロールを追跡する
  const handleScroll = useCallback((e: React.UIEvent<HTMLDivElement>) => {
    if (!(refBase.current && refAggregation.current && refExport.current && refTransfer.current)) return;

    const scrollTop = e.currentTarget.scrollTop;
    const visibleOffset = e.currentTarget.clientHeight / 2;

    if (e.currentTarget.scrollHeight - Math.floor(e.currentTarget.scrollTop) === e.currentTarget.clientHeight) {
      // 転送のエリアは高さが小さいため、底についたらアクティブにする。
      setActiveArea('transfer');
    } else if (scrollTop < visibleOffset) {
      setActiveArea('base');
    } else if (scrollTop < refExport.current.offsetTop - visibleOffset) {
      setActiveArea('aggregation');
    } else if (scrollTop < refTransfer.current.offsetTop - visibleOffset) {
      setActiveArea('export');
    }
  }, [refBase, refAggregation, refExport, refTransfer, setActiveArea]);

  const dataSetFields: MyField[] = userPreferences.preference.genericFields.map((gf) => {
    const genericFields = task.analysisAggregationConditions?.genericFields;
    const codeValue = genericFields?.find((v) => v.id === gf.id && v.type === 'code')?.value;
    const nameValue = genericFields?.find((v) => v.id === gf.id && v.type === 'name')?.value;

    return [
      { label: `${gf.name}（コード）`, value: codeValue },
      { label: `${gf.name}（名称）`, value: nameValue },
    ];
  }).flat();

  const fieldExportFormats = useMemo((): MyField[] => {
    return task.exportFormatAssignees ? task.exportFormatAssignees.map((item) => {
      return [
        { label: '出力形式', value: item.exportFormat?.name },
        { label: '出力ファイル名フォーマット', value: item.exportFileNameFormat },
      ];
    }).flat() : [];
  }, [task]);

  const fieldBankDataExports = useMemo((): MyField[] => (
    task.companyExpenseAccountAssignees ? task.companyExpenseAccountAssignees.map((assignee: TaskCompanyExpenseAccountAssignee, index) => {
      return [
        {
          label: i18next.t('accountingDataScheduledExports.tasks.readOnlyForm.bankDataCreation.exportFileName'),
          value: assignee.exportFileName,
        },
        {
          label: i18next.t('accountingDataScheduledExports.tasks.readOnlyForm.bankDataCreation.paymentDate'),
          value: assignee.paymentSpecificationType === 'specify_date' ? assignee.paymentSpecifiedDate : formatExecutionDate(assignee),
        },
        {
          label: i18next.t('accountingDataScheduledExports.tasks.readOnlyForm.bankDataCreation.paymentAccount'),
          value: assignee.companyExpenseAccount?.name,
        },
        task.companyExpenseAccountAssignees?.length !== index + 1 ? {
          isEmptyLine: true,
        } : {},
      ];
    }).filter((item) => Object.keys(item).length > 0).flat() : []
  ), [task]);

  const fieldGroups = useMemo((): FieldGroup[] => (
    [
      {
        name: '基本',
        ref: refBase,
        fields: [
          { label: 'タスク名', value: task.name },
          { label: '実行者', value: task.executor?.name },
          { label: '実行日', value: executionSchedule(task) },
          { label: '実行時間枠', value: task.executionTimetable.name },
          { label: '有効期限', value: period(task) },
          { label: '有効', value: task.enabled ? '有効' : '無効' },
        ],
      },
      {
        name: '集計',
        ref: refAggregation,
        fields: [
          { label: '集計名フォーマット', value: task.analysisAggregationName },
          { label: '申請種別', value: targetReportType(task.targetReportType) },
          { label: '期間', value: dateRange(task.analysisAggregationConditions?.startPeriod) },
          { label: '申請日', value: dateRange(task.analysisAggregationConditions?.submittedPeriod) },
          { label: '仮払希望日', value: dateRange(task.analysisAggregationConditions?.temporaryPaymentDuePeriod) },
          { label: '利用日(経費)', value: dateRange(task.analysisAggregationConditions?.transactedPeriod) },
          { label: '最終承認日', value: dateRange(task.analysisAggregationConditions?.lastApprovedPeriod) },
          { label: '目的地', value: task.analysisAggregationConditions?.destination },
          { label: '申請者の所属部署', value: task.analysisAggregationConditions?.applicantGroup?.name },
          { label: '申請者の役職', value: task.analysisAggregationConditions?.applicantPost?.name },
          { label: '承認済みの承認者', value: task.analysisAggregationConditions?.approvedApprover?.name },
          { label: '費用負担部署', value: task.analysisAggregationConditions?.paymentGroup?.name },
          { label: 'プロジェクトID', value: task.analysisAggregationConditions?.projectId },
          { label: 'プロジェクト名', value: task.analysisAggregationConditions?.projectName },
          ...dataSetFields,
          { label: '部署検索範囲', value: checks({ '下位部署を含む': task.analysisAggregationConditions?.includeChildDepartment }) },
          {
            label: '集計状況',
            value: checks({
              '未集計': task.analysisAggregationConditions?.includeNotAggregated,
              '集計済': task.analysisAggregationConditions?.includeAggregated,
            }),
          },
          {
            label: '会計データ出力状況',
            value: checks({
              '会計データ未出力': task.analysisAggregationConditions?.includeNotExported,
              '会計データ出力済み': task.analysisAggregationConditions?.includeExported,
            }),
          },
          {
            label: '支払い状況',
            value: checks({
              '未払い': task.analysisAggregationConditions?.includeNotPaid,
              '支払処理済み': task.analysisAggregationConditions?.includePaid,
            }),
          },
          {
            label: '仮払い状況',
            value: checks({
              '未払い': task.analysisAggregationConditions?.includeNotTemporaryPaid,
              '支払処理済み': task.analysisAggregationConditions?.includeTemporaryPaid,
            }),
          },
          {
            label: '精算状況',
            value: checks({
              '未精算': task.analysisAggregationConditions?.includeUnsettled,
              '精算済み': task.analysisAggregationConditions?.includeSettled,
            }),
          },
          {
            label: '原本確認状況',
            value: checks({
              '完了': task.analysisAggregationConditions?.matchedOriginalReceipt,
              '未完了': task.analysisAggregationConditions?.notMatchedOriginalReceipt,
            }),
          },
          {
            label: '登録番号',
            value: task.analysisAggregationConditions?.emptyRegistratedNumber ? i18next.t('transactions.index.searchbox.blankSearch') : (task.analysisAggregationConditions?.registratedNumber ?? ''),
          },
          {
            label: '適格請求書として扱う',
            value: checks({
              [i18next.t('accountingDataScheduledExports.tasks.analysisAggregationConditions.includeAsInvoice')]: task.analysisAggregationConditions?.includeAsInvoice,
              [i18next.t('accountingDataScheduledExports.tasks.analysisAggregationConditions.includeNotAsInvoice')]: task.analysisAggregationConditions?.includeNotAsInvoice,
            }),
          },
        ],
      },
      {
        name: '会計データ',
        ref: refExport,
        fields: [
          { label: '出力', value: task.enableAccountingDataCreation ? '出力する' : '出力しない' },
          ...fieldExportFormats,
          { label: '一括取引指定日付', value: specifiedDate(task) },
        ],
      },
      {
        name: i18next.t('accountingDataScheduledExports.tasks.readOnlyForm.bankDataCreation.title'),
        ref: refBankExport,
        fields: [
          { label: i18next.t('accountingDataScheduledExports.tasks.readOnlyForm.bankDataCreation.enableField.title'), value: i18next.t(`accountingDataScheduledExports.tasks.readOnlyForm.bankDataCreation.enableField.${task.enableBankDataCreation ? 'enable' : 'disable'}`) },
          ...fieldBankDataExports,
        ],
      },
      {
        name: 'ファイル転送',
        ref: refTransfer,
        fields: [
          { label: '転送', value: task.enableFileTransfer ? '転送する' : '転送しない' },
          { label: '転送先', value: task.fileTransferLocation?.name },
        ],
      },
    ]
  ), [dataSetFields, task, fieldExportFormats, fieldBankDataExports]);

  const refs: Record<Position, React.RefObject<HTMLHeadElement>> = {
    base: refBase,
    aggregation: refAggregation,
    export: refExport,
    transfer: refTransfer,
  };

  const ref = refs[scrollTo];

  useEffect(() => {
    if (ref?.current) {
      ref.current.scrollIntoView({
        behavior: 'smooth',
        block: 'start',
      });
    }
  }, [ref]);

  return (
    <Row>
      <Col sm={ 3 }>
        <ButtonView active={ activeArea === 'base' }><Button bsStyle='link' onClick={ (): void => handleClick('base') }>基本</Button></ButtonView>
        <ButtonView active={ activeArea === 'aggregation' }><Button bsStyle='link' onClick={ (): void => handleClick('aggregation') }>集計</Button></ButtonView>
        <ButtonView active={ activeArea === 'export' }><Button bsStyle='link' onClick={ (): void => handleClick('export') }>出力</Button></ButtonView>
        <ButtonView active={ activeArea === 'transfer' }><Button bsStyle='link' onClick={ (): void => handleClick('transfer') }>転送</Button></ButtonView>
      </Col>
      <ScrollableForm ref={ formRef } onScroll={ handleScroll }>
        <form>
          {
            fieldGroups.map((formGroup, i) => (
              <FormGroupView ref={ formGroup.ref } key={ `g-${i}` }>
                <h4 className='title'>{ formGroup.name }</h4>
                {
                  formGroup.fields.filter((f) => !!f.value || !!f.isEmptyLine).map((f, j) => (
                    f.value ? (
                      <FormGroup key={ `g-${i}-f-${j}` }>
                        <ControlLabel>{f.label}</ControlLabel>
                        <FormControl.Static>{formatter.text(f.value)}</FormControl.Static>
                      </FormGroup>
                    ) : (
                      <FormControl.Static key={ `g-${i}-f-${j}` }></FormControl.Static>
                    )
                  ))
                }
              </FormGroupView>
            ))
          }
        </form>
      </ScrollableForm>
    </Row>
  );
};
