import isNaN from 'lodash/isNaN';

/**
 * 日付が正しいかどうかを返します。
 * @param {Date | null} date 検査する日付
 * @return {boolean} 正しい日付の場合true、不正な日付の場合false
 */
export function isValidDate(date: Date | null): boolean {
  return date instanceof Date && !isNaN(date.getTime());
}
