import _omit from "lodash/omit";
import WebStorage from "utilities/web_storage";

/**
 * 経費一覧のカラム表示設定を保存するために使用する、LocalStorageのモデル
 */
export class ColumnParamsStorage {
  constructor() {
    this.storage = new WebStorage();

    this.version = "1.0";
    this.migrate();
  }

  get data() {
    return this._data || this.storage.getItem(this.key) || this.defaultData;
  }

  set data(params) {
    const newData = _omit(params, ["_version"]);

    this._data = newData;
    this.storage.setItem(this.key, { ...this._data, _version: this.version });
  }

  update(params) {
    this.data = { ...this.data, ...(params || {}) };
  }

  get defaultData() {
    return {
      creditCategoryName: false,
      companions: false,
      originAndDestination: false,
      visitByCategory: false,
      purposeByCategory: false,
      taxCategoryName: false,
      isCorporate: false,
      comment: false,
      reportSequenceNum: false,
      preReportSequenceNum: false,
      transitPayee: false,
    };
  }

  /**
   * @private
   */
  get key() {
    return ["expenses", "columnVisibilities"];
  }

  /**
   * @private
   */
  migrate() {
    this.data = this.migrateSchema(
      this.storage.getItem(["transactions", "columnVisibilities"]),
    );

    // 使用されない値を削除する
    // TODO: リリース後、一定期間経過後に、処理を削除
    this.storage.removeItem(["transactions", "columnVisibilities"]);
  }

  /**
   * @private
   * @param {object} params
   * @return {object}
   */
  migrateSchema(params) {
    return {
      ...this.defaultData,
      ...(params || this.data),
    };
  }
}

export default new ColumnParamsStorage();
