import React from 'react';
import moment from 'moment';
import { JobRequestBase } from 'utilities/api/models/accountingDataScheduledExports';

interface Props {
  jobRequest: null | JobRequestBase;
}

export const ExecutedAtItem: React.FC<Props> = ({
  jobRequest,
}) => {
  if (!jobRequest?.jobExecution?.createdAt) { return <></>; }

  const executedAtText = moment(jobRequest?.jobExecution?.createdAt).format('YYYY-MM-DD HH:mm');

  return (
    <div>{ executedAtText }</div>
  );
};
