import React from 'react';
import SecuritySubmenu, { MenuKey } from './SecuritySubmenu';

interface Props {
  currentUrl: MenuKey;
}

const SecurityPageLayout: React.FC<Props> = ({
  currentUrl,
  children,
}) => {
  return (<div className='card-content'>
    <div className='row'>
      <div className='col-sm-3'><SecuritySubmenu currentUrl={ currentUrl }/>
      </div>
      <div className='col-sm-9 col-sm-offest-3'>{ children }</div>
    </div>
  </div>);
};

export default SecurityPageLayout;
