import PropTypes from "prop-types";
import React from "react";

function getContainerStyle(type, show) {
  if (type !== "full") {
    return { position: "relative" };
  }

  return {
    position: "fixed",
    top: "0px",
    left: "0px",
    height: "100%",
    width: "100%",
    zIndex: show ? "100000" : "-1", // 表示時は、DK内で最大のz-indexを指定
  };
}

function getOverlayStyle(style = {}) {
  const centeringStyle = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translateX(-50%) translateY(-50%)",
    zIndex: 100, // 表示時は、DK内で最大のz-indexを指定
  };

  return {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-around",
    backgroundColor: "#ddd",
    opacity: 0.5,
    width: "100%",
    height: "100%",
    ...centeringStyle,
    ...style,
  };
}

const LoadingOverlay = ({
  className,
  containerClassName,
  show,
  scale,
  style,
  type = "default",
  children,
}) => {
  const scaleClass = scale ? `fa-${scale}x` : "fa-1x";
  const iconClass = `fa fa-spinner fa-spin fa-fw ${scaleClass}`;

  return (
    <div className={containerClassName} style={getContainerStyle(type, show)}>
      {children}

      {show ? (
        <div
          className={`overlay-component${className ? ` ${className}` : ""}`}
          style={getOverlayStyle(style)}
        >
          <i className={iconClass} />
        </div>
      ) : null}
    </div>
  );
};

LoadingOverlay.defaultProps = {
  type: "default",
  scale: 3,
};

LoadingOverlay.propTypes = {
  type: PropTypes.oneOf(["default", "full"]),
  scale: PropTypes.oneOf([1, 2, 3]),
  containerClassName: PropTypes.string,
};

export default LoadingOverlay;
