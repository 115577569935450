import React from 'react';
import i18next from 'i18next';
import { AggregateResultsItem } from './AggregateResultsItem';
import { DownloadItem } from './DownloadItem';
import { ErrorDetailItem } from './ErrorDetailItem';
import { ExecutedAtItem } from './ExecutedAtItem';
import { JobRequest } from 'utilities/api/models/accountingDataScheduledExports';
import { ReExecuteItem } from './ReExecuteItem';

interface Props {
  jobRequest: null | JobRequest;
}

export const List: React.FC<Props> = ({
  jobRequest,
}) => {
  if (!jobRequest) { return <>{ i18next.t('accountingDataScheduledExports.jobRequests.latestJobExecution.none') }</>; }

  const exportFiles = jobRequest?.jobExecution?.jobAccountingDataCreationExportFiles || [];
  const exportBankFiles = jobRequest?.jobExecution?.jobBankDataCreationExportFiles || [];

  return (
    <>
      <ExecutedAtItem jobRequest={ jobRequest }></ExecutedAtItem>
      <AggregateResultsItem jobRequest={ jobRequest }></AggregateResultsItem>
      {
        jobRequest.enableAccountingDataCreation && exportFiles.length > 0  && (
          <>
            <div>
              { i18next.t('accountingDataScheduledExports.jobRequests.latestJobExecution.label') }
            </div>
            {
              exportFiles.map((file, index) => {
                return (
                  <DownloadItem
                    key={ index }
                    exportFileName={ `${file.exportFileName} ${i18next.t('accountingDataScheduledExports.jobRequests.latestJobExecution.download')}` }
                    downloadUrl={ file.url }
                  />
                );
              })
            }
          </>
        )
      }
      {
        jobRequest.enableBankDataCreation && exportBankFiles.length > 0 && (
          <>
            <div>
              { i18next.t('accountingDataScheduledExports.jobRequests.latestJobExecution.bankData.label') }
            </div>
            {
              exportBankFiles.map((file, index) => {
                return (
                  <DownloadItem
                    key={ index }
                    exportFileName={ `${file.exportFileName} ${i18next.t('accountingDataScheduledExports.jobRequests.latestJobExecution.bankData.download')}` }
                    downloadUrl={ file.url }
                  />
                );
              })
            }
          </>
        )
      }
      <ReExecuteItem jobRequest={ jobRequest }></ReExecuteItem>
      <ErrorDetailItem jobRequest={ jobRequest }></ErrorDetailItem>
    </>
  );
};
