import Decimal from "decimal.js";
import { TaxCategorySelection } from "utilities/api/models";

/**
 * 税込金額から消費税額を端数切り捨てで計算します。
 * @param amountIncludeTax
 * @param taxRate
 * @returns
 */
export const calcTaxAmount = (
  amountIncludeTax: number,
  taxRate: number,
): number => {
  const a = new Decimal(amountIncludeTax || 0);
  const r = new Decimal(taxRate);

  // 税込金額 x 税率 / (1 + 税率)
  return a.times(r).div(new Decimal(1).plus(r)).floor().toNumber();
};

export const filterSelectables = (
  all: TaxCategorySelection[],
  asEligibleInvoice: boolean,
): TaxCategorySelection[] => {
  const eligibles = all.filter((a) => !a.eligibleId);
  const uneligibles = all.filter((a) => !!a.eligibleId);
  return asEligibleInvoice || uneligibles.length === 0
    ? eligibles
    : uneligibles;
};

export const filterDefaultSelected = (
  all: TaxCategorySelection[],
  asEligibleInvoice: boolean,
): TaxCategorySelection[] => {
  const eligibles = all.filter((a) => !a.eligibleId && a.selected);
  const uneligibles = all.filter((a) => !!a.eligibleId && a.selected);
  return asEligibleInvoice || uneligibles.length === 0
    ? eligibles
    : uneligibles;
};

/**
 * 同じ日付のリストかどうかを判定します。
 * @param a 日付のリスト
 * @param b 比較対象の日付のリスト
 * @returns
 */
export const isEqualDates = (a: string[], b: string[]): boolean => {
  return a.sort().join(",") === b.sort().join(",");
};
