import PropTypes from 'prop-types';
import React, { Component } from 'react';
import uniqueId from 'lodash/uniqueId';

import { OverlayTrigger, Popover } from 'react-bootstrap';

/**
 * @deprecated 変わりに components/SmartCheckBox の使用を検討してください
 */
export default class CheckBox extends Component {
  constructor(props) {
    super(props);
    this.id = uniqueId('checkbox-');
  }

  render() {
    return (
      <div>
        <div className={ `checkbox ${this.props.className}` } style={ { display: 'inline-block' } }>
          <input type='checkbox' value='None'
            id={ this.id } checked={ this.props.checked }
            onChange={ (e) => { this.props.onChange(e); } }
            disabled={ this.props.disabled }
          />
          <label htmlFor={ this.id }></label>
        </div>
        <span>
          { this.props.label }
          {
            this.props.helpText !== ''
              ? (
                <OverlayTrigger
                  overlay={ (<Popover id={ `${this.id}-help-text-popover` }>{ this.props.helpText }</Popover>) }
                  placement='top'
                  trigger={ ['hover', 'focus', 'click'] }
                >
                  <i className='far fa-question-circle' style={ { color: '#AAA', marginLeft: '6px', cursor: 'pointer' } } />
                </OverlayTrigger>
              ) : null }
        </span>
      </div>
    );
  }
}

CheckBox.displayName = 'CheckBox';

CheckBox.propTypes = {
  checked: PropTypes.bool.isRequired,
  onChange: PropTypes.func.isRequired,
  className: PropTypes.string,
  label: PropTypes.string.isRequired,
  disabled: PropTypes.bool.isRequired,
  helpText: PropTypes.string,
};

CheckBox.defaultProps = {
  checked: false,
  onChange() {},
  label: '',
  disabled: false,
  helpText: '',
};
