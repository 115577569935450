import { useHooks } from "components/renewaled_ui/FileViewer/hooks/hooks";
import { useHooksFile } from "components/renewaled_ui/FileViewer/hooks/hooksFile";
import BrowserPDFViewer from "components/renewaled_ui/FileViewer/Viewer/BrowserPDF";
import FileView from "components/renewaled_ui/FileViewer/Viewer/Other/FileView";
import ImageViewer from "components/renewaled_ui/FileViewer/Viewer/Other/ImageViewer";
import ViewerDropZone from "components/renewaled_ui/FileViewer/Viewer/Other/ImageViewer/DropZone";
import PDFViewer from "components/renewaled_ui/FileViewer/Viewer/PDF";
import { ViewerFooter } from "components/renewaled_ui/FileViewer/ViewerFooter";
import {
  DeleteEventHandler,
  DropEventHandler,
  DropType,
  FileLoadStatus,
  FileType,
  LoadingStatus,
  RotateEventHandler,
} from "components/types/attachedFiles.types";
import React, { CSSProperties } from "react";
import styled from "styled-components";
import { MIMEType } from "utilities/api/types/MIMEType";

const Wrapper = styled.div`
  position: relative;
  overflow-y: auto;
  overflow-x: hidden;
  cursor: pointer;
  height: 100%;
  background: rgba(0, 0, 0, 0.1);
`;

/**
 * ファイルビューアーのProps
 */
export interface FileViewerProps {
  /** wrapperのクラス名 */
  readonly className?: string;
  /** 画像のcss */
  readonly imageStyle?: CSSProperties;
  /** 横幅 */
  readonly width?: number | string;
  /** 縦幅 */
  readonly height?: number | string;
  /** オーバーレイを表示 */
  readonly showOverlay?: boolean;
  /** ファイルURL */
  readonly url?: string;
  /** ファイルタイプ */
  readonly mimeType?: MIMEType;
  /** ファイル名 */
  readonly filename?: string;
  /** ローディングのステータス */
  readonly loadStatus: FileLoadStatus;
  /** 詳細文言を表示するか */
  readonly showPlaceholderDescription?: boolean;
  /** ダウンロードのURL */
  readonly downloadLink?: string;
  /** ツールバーを表示するか */
  readonly showToolbar?: boolean;
  /** PDFツールバーを表示するか */
  readonly isShowToolBar?: boolean;
  /** PDFサブバーを表示するか */
  readonly isShowSubBar?: boolean;
  /** ファイル名 */
  readonly fileName?: string;
  /** 回転角度 */
  readonly defaultRotation?: number;
  /** 削除可能か */
  readonly isDeletable?: boolean;
  /** 複数のファイルドロップが可能か */
  readonly dropType?: DropType;
  /** ファイルドロップが可能か */
  readonly isDroppable?: boolean;
  /** 回転などが可能か */
  readonly isTransformable?: boolean;
  /** ダウンロードが可能か */
  readonly isDownloadable?: boolean;
  /** 扱えるファイルタイプ */
  readonly selectableTypes?: FileType[];
  /** フッター */
  readonly footer?: string | JSX.Element;
  /** 削除を実行した */
  readonly onDelete?: DeleteEventHandler;
  /** ファイルドロップをした */
  readonly onDrop?: DropEventHandler<DropType>;
  /** 回転をした */
  readonly onRotate?: RotateEventHandler;
  /** クリックをした */
  readonly onClick?: (e: React.SyntheticEvent, state: LoadingStatus) => void;
}

/**
 * ツールバー付きファイルビューアー
 * 表示ファイル: PDF, 画像
 * ダウンロードのみファイル: Word, Excel, CSV
 */
const FileViewer: React.FC<FileViewerProps> = (p) => {
  const {
    isDeletable,
    isDownloadable,
    isDroppable,
    isTransformable,
    isClickable,
    isShowToolBar,
    isShowSubBar,
    isPDF,
    isBrowserViewer,
    onClickChangeViewer,
  } = useHooks(
    p.showToolbar,
    p.isDeletable,
    p.isDownloadable,
    p.isDroppable,
    p.isTransformable,
    p.isShowToolBar,
    p.isShowSubBar,
    p.mimeType,
  );
  const {
    isDropzoneVisible,
    fileLabel,
    rootProps,
    getInputProps,
    isShowFileSelect,
  } = useHooksFile(
    p.className || "",
    p.dropType || "single",
    p.isDroppable || false,
    p.loadStatus.state,
    p.selectableTypes || [],
    p.onDrop,
  );
  const { ...viewProps } = p;

  // ファイルドロップゾーン
  if (isDropzoneVisible) {
    return (
      <Wrapper {...rootProps} className="pdf-dropzone-view-wrapper">
        <ViewerDropZone ext={fileLabel} getInputProps={getInputProps} />
        {!!p.footer && (
          <ViewerFooter className="viewer-dropzone-view-footer">
            {p.footer}
          </ViewerFooter>
        )}
      </Wrapper>
    );
  }

  // 画像やファイルの場合
  if (!isPDF) {
    return (
      <ImageViewer
        rootProps={rootProps}
        url={p.url}
        className={p.className}
        fileName={p.fileName}
        defaultRotation={p.defaultRotation}
        isDeletable={isDeletable && !!p.url}
        dropType={p.dropType}
        isShowFileSelect={isShowFileSelect}
        isDroppable={isDroppable}
        isDropzoneVisible={isDropzoneVisible}
        isTransformable={isTransformable && !!p.url}
        isClickable={isClickable}
        isShowToolBar={isShowToolBar}
        downloadLink={isDownloadable ? p.downloadLink || p.url : void 0}
        selectableTypes={p.selectableTypes}
        getInputProps={getInputProps}
        onDelete={p.onDelete}
        onRotate={p.onRotate}
        footer={p.footer}
      >
        <FileView
          {...viewProps}
          filename={p.fileName}
          className={p.className}
        />
      </ImageViewer>
    );
  }

  if (isBrowserViewer)
    return (
      <BrowserPDFViewer
        fileName={p.fileName}
        rootProps={rootProps}
        url={p.url || ""}
        className={p.className}
        dropType={p.dropType}
        isShowFileSelect={isShowFileSelect}
        isDroppable={isDroppable}
        selectableTypes={p.selectableTypes}
        isShowSubBar={isShowSubBar}
        isDropzoneVisible={isDropzoneVisible}
        downloadLink={isDownloadable ? p.downloadLink || p.url : void 0}
        isDeletable={isDeletable && !!p.url}
        isTransformable={isTransformable && !!p.url}
        isShowToolBar={isShowToolBar}
        loadStatus={p.loadStatus}
        width={p.width}
        height={p.height}
        showPlaceholderDescription={p.showPlaceholderDescription}
        getInputProps={getInputProps}
        footer={p.footer}
        onClickChangeViewer={onClickChangeViewer}
        onDelete={p.onDelete}
        onClick={p.onClick}
      />
    );

  // PDFの場合
  return (
    <PDFViewer
      fileName={p.fileName}
      rootProps={rootProps}
      url={p.url || ""}
      className={p.className}
      dropType={p.dropType}
      isShowFileSelect={isShowFileSelect}
      isDroppable={isDroppable}
      selectableTypes={p.selectableTypes}
      isShowSubBar={isShowSubBar}
      isDropzoneVisible={isDropzoneVisible}
      downloadLink={isDownloadable ? p.downloadLink || p.url : void 0}
      isDeletable={isDeletable && !!p.url}
      isTransformable={isTransformable && !!p.url}
      isShowToolBar={isShowToolBar}
      loadStatus={p.loadStatus}
      width={p.width}
      height={p.height}
      showPlaceholderDescription={p.showPlaceholderDescription}
      getInputProps={getInputProps}
      footer={p.footer}
      onClickChangeViewer={onClickChangeViewer}
      onDelete={p.onDelete}
      onClick={p.onClick}
    />
  );
};

export default FileViewer;
