import React from "react";
import { Modal } from "react-bootstrap";

interface Props {
  readonly children: React.ReactNode;
}

/**
 * モーダル: フッター部分
 */
export const ModalFooter: React.FC<Props> = ({ children }) => {
  return <Modal.Footer>{children}</Modal.Footer>;
};
