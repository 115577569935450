export default {
  ja: {
    created: '未申請',
    waitingForWorker: 'データ化中',
    workerFailed: 'データ化失敗',
    applying: '承認待ち',
    reapplying: '承認待ち',
    recalled: '取下げ',
    rejected: '差し戻し',
    aborted: 'フロー変更待ち',
    archived: 'フロー変更済み',
    approved: '承認済み',
    pendingTemporaryPayment: '仮払待ち',
    temporaryPaid: '仮払処理済み',
    pendingTemporaryPaymentRefund: '仮払差額返金待ち',
    closed: '申請完了',
    unsettled: '未精算',
    settled: '精算済み',
    paid: '支払処理済み',
  },
  en: {
    created: 'Not Submitted',
    waitingForWorker: 'Registering data',
    workerFailed: 'Failed to register data',
    applying: 'Approval Pending',
    reapplying: 'Reapplication',
    recalled: 'Withdrawal',
    rejected: 'Rejected',
    approved: 'Approved',
    aborted: 'Approval Flow Change Required',
    archived: 'Approval Flow Changed',
    pendingTemporaryPayment: 'Pending Temporary Payment',
    temporaryPaid: 'Temporary Payment Processed',
    pendingTemporaryPaymentRefund: 'Pending Temporary Payment Refund',
    closed: 'Application Completed',
    unsettled: 'Unsettled',
    settled: 'Settled',
    paid: 'Payment Processed',
  },
};
