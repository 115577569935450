import ApiBase from './api_base';

const relativePath = 'td';
const authPath = '/api/v1/auth';

export default new ApiBase('v1', { relativePath, authPath })
  .resources('/export_file_formats', { only: ['index'] })
  .resources('/ntd_export_jobs', { only: ['index', 'create'] })
  .resources('/preferences', { only: ['index'] })
  .put('/preferences/update')
  .resources('/user_preferences', { only: ['index'] })
  .put('/user_preferences/update')
  .build();
