import { CreateRequest } from "utilities/api/requests/accountingDataScheduledExports/fileTransferLocations";

export const defaultValues: CreateRequest = {
  name: '',
  description: '',
  protocol: 'sftp',
  host: '',
  port: 22,
  authMethod: 'password',
  authOptions: {
    password: '',
  },
  account: '',
  directory: '',
};
