import PropTypes from 'prop-types';
import React, { Component } from 'react';
import formatter from 'utilities/formatter';
import { getUserLocale, getDatePickerDayConfig } from 'utilities/Utils';

export default class PeriodInput extends Component {
  constructor(props) {
    super(props);

    this.handleDateChange = this.handleDateChange.bind(this);
  }

  get datePickerClass() {
    return this.props.oneline ? 'col-sm-6 col-md-6 no-padding' : 'col-sm-12 col-md-12 no-padding multiline';
  }

  get datePickerStyle() {
    return { paddingLeft: 0, paddingRight: 0 };
  }

  componentDidMount() {
    const config = {
      language: this.props.locale,
      format: 'yyyy/mm/dd',
      orientation: 'bottom',
      autoclose: true,
      clearBtn: true,
      todayBtn: 'linked',
      beforeShowDay: getDatePickerDayConfig,
    };

    $(this.refs.dateFrom).datepicker(config).on('change', this.handleDateChange.bind(null, this.props.onChangeDateFrom));
    $(this.refs.dateTo).datepicker(config).on('change', this.handleDateChange.bind(null, this.props.onChangeDateTo));
  }

  handleDateChange(callback, e) {
    if (e.target.value) {
      callback(e.target.value);
    } else {
      // clear buttonがクリックされた時
      callback('');
    }
  }

  renderDatePicker(key, date, isRequired = false) {
    return (
      <div className='input-group'>
        <div className='input-group-addon'>
          <span className='fas fa-calendar-alt'></span>
        </div>
        <input
          ref={ key }
          type='text'
          className={ `form-control date-picker ${key}${isRequired ? ' validate[required]' : ''}` }
          value={ date && formatter.date(date) }
          onChange={ () => { /* date-picker による変更のみ許容し、キーボードからの文字入力は禁止する */ } }
        />
      </div>
    );
  }

  renderPeriodEN(props) {
    const {
      dateFrom, dateTo, isDateFromRequired, isDateToRequired,
    } = props;
    const datePickerClass = this.datePickerClass;

    return (
      <div className={ `period-input ${this.props.className}` }>
        <div className={ datePickerClass }>
          <span className='col-sm-4 col-md-4 preposition-text no-padding'>From</span>
          <div className='col-sm-8 col-md-8 no-padding'>
            { this.renderDatePicker('dateFrom', dateFrom, isDateFromRequired) }
          </div>
        </div>
        <div className={ datePickerClass }>
          <span className='col-sm-4 col-md-4 preposition-text no-padding'>To</span>
          <div className='col-sm-8 col-md-8 no-padding'>
            { this.renderDatePicker('dateTo', dateTo, isDateToRequired) }
          </div>
        </div>
      </div>
    );
  }

  renderPeriodJP(props) {
    const {
      dateFrom, dateTo, isDateFromRequired, isDateToRequired,
    } = props;
    const datePickerClass = this.datePickerClass;

    return (
      <div className={ `period-input ${this.props.className}` }>
        <div className={ datePickerClass }>
          <div className='col-sm-8 col-md-8 no-padding'>
            { this.renderDatePicker('dateFrom', dateFrom, isDateFromRequired) }
          </div>
          <span className='col-sm-4 col-md-4 preposition-text no-padding'>から</span>
        </div>
        <div className={ datePickerClass }>
          <div className='col-sm-8 col-md-8 no-padding'>
            { this.renderDatePicker('dateTo', dateTo, isDateToRequired) }
          </div>
          <span className='col-sm-4 col-md-4 preposition-text no-padding'>まで</span>
        </div>
      </div>
    );
  }

  render() {
    if (this.props.locale === 'ja') {
      return this.renderPeriodJP(this.props);
    }
    return this.renderPeriodEN(this.props);
  }
}

PeriodInput.defaultProps = {
  locale: getUserLocale(),
  oneline: true,
  className: '',
  dateFrom: new Date().toISOString(),
  dateTo: '',
  dateFromDisabled: false,
  dateToDisabled: false,
};

PeriodInput.propTypes = {
  locale: PropTypes.string,
  oneline: PropTypes.bool,
  dateFrom: PropTypes.string,
  dateTo: PropTypes.string,
  isDateFromRequired: PropTypes.bool,
  isDateToRequired: PropTypes.bool,
  onChangeDateFrom: PropTypes.func.isRequired,
  onChangeDateTo: PropTypes.func.isRequired,
};
