import { Observable } from 'rxjs';
import { useCallback, useEffect, useState } from 'react';

class ExponentialBackOff {
  currentCount: number;

  nextCount: number;

  stepFactor: number;

  constructor(currentCount: number, nextCount: number, stepFactor: number) {
    this.currentCount = currentCount;
    this.nextCount = nextCount;
    this.stepFactor = stepFactor;
  }

  run(): Observable<number> {
    return new Observable((observer) => {
      observer.next(this.currentCount);
      const timer = setInterval(() => {
        if (this.currentCount === this.nextCount) {
          observer.next(this.currentCount);
          this.nextCount = Math.round(this.nextCount * this.stepFactor);
        }
        this.currentCount += 1;
      }, 1000);
      return (): void => clearInterval(timer);
    });
  }

  reset(): void {
    this.currentCount = 0;
    this.nextCount = 1;
  }
}

const exponentialBackOffExecutor = (exponentialBackOff: ExponentialBackOff, callback: () => void): (() => void) => {
  const subscription = exponentialBackOff.run().subscribe(callback);
  return (): void => subscription.unsubscribe();
};

interface UseExponentialBackOff {
  reset: () => void;
}

export function useExponentialBackOff(
  isSubscribing: boolean,
  callback: () => void,
  stepFactor = 2,
): UseExponentialBackOff {
  const [exponentialBackOff] = useState(new ExponentialBackOff(1, 1, stepFactor));
  const reset = useCallback(() => { exponentialBackOff.reset(); }, [exponentialBackOff]);
  useEffect(() => {
    if (isSubscribing) {
      return exponentialBackOffExecutor(exponentialBackOff, callback);
    }
    return (): void => void 0;
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isSubscribing]);

  return { reset };
}

export function useExponentialBackOffWithAll(
  isSubscribing: boolean,
  callback: (all: boolean) => void,
  all: boolean,
  stepFactor = 2,
): UseExponentialBackOff {
  const [exponentialBackOff] = useState(new ExponentialBackOff(1, 1, stepFactor));
  const reset = useCallback(() => { exponentialBackOff.reset(); }, [exponentialBackOff]);
  useEffect(() => {
    if (isSubscribing) {
      return exponentialBackOffExecutor(exponentialBackOff, () => callback(all));
    }
    return (): void => void 0;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isSubscribing, all]);

  return { reset };
}
