import React from 'react';
import styled from 'styled-components';

interface Props {
  message: string;
}

const ErrorMessageView = styled.p`
  font-size: 1.2rem;
  color: red;
  margin-top: 4px;
`;

export const ErrorMessage: React.FC<Props> = (props) => {
  return (
    <ErrorMessageView>{ props.message }</ErrorMessageView>
  );
};
