import { Category } from "components/fields/CategoryRecursiveSuggestField";
import SimpleModal, { ButtonProps } from "components/SimpleModal";
import i18next from "i18n";
import cloneDeep from "lodash/cloneDeep";
import isNil from "lodash/isNil";
import moment from "moment";
import React, { FC, useMemo, useState } from "react";
import { Participant, PreExpense, UserParticipant } from "utilities/api/models";
import PreExpenseAmountForm from "./PreExpenseAmountForm";
import PreExpenseCategoryForm from "./PreExpenseCategoryForm";
import PreExpenseContentForm from "./PreExpenseContentForm";
import PreExpenseDateForm from "./PreExpenseDateForm";
import PreExpenseParticipantsForm from "./PreExpenseParticipantsForm";

type Participants = Array<Participant | UserParticipant>;
export type PreExpenseWithKey = PreExpense & { key: string };

interface Props {
  show: boolean;
  disabled: boolean;
  preExpense: PreExpenseWithKey;
  shouldSelectSelfAsCompanion: boolean;
  onClose: () => void;
  onSubmit: () => void;
  onChangePreExpense: (preExpense: PreExpenseWithKey) => void;
  onSelectParticipantsCategory: () => void;
}

interface FormErrors {
  amount: string[];
  category: string[];
  content: string[];
  participants: string[];
  transactedAt: string[];
}

const initialFormErrors: FormErrors = {
  amount: [],
  category: [],
  content: [],
  participants: [],
  transactedAt: [],
};

function hasError(formErrors: FormErrors): boolean {
  return Object.values(formErrors).some((errors) => errors.length > 0);
}

const validatePreExpense = (preExpense: PreExpense): FormErrors => {
  const { comment, amount, transactedAt, category } = preExpense;
  const transactedAtYear = moment(new Date(transactedAt)).year();

  const errors: FormErrors = cloneDeep(initialFormErrors);

  if (isNil(comment) || comment.length === 0) {
    errors.content = [i18next.t("preReports.items.errors.content.blank")];
  }

  if (isNil(amount) || amount.toString().trim() === "") {
    errors.amount = [i18next.t("preReports.items.errors.amount.blank")];
  }

  if (isNil(transactedAt)) {
    errors.transactedAt = [i18next.t("preReports.items.errors.date.blank")];
  } else if (Number.isNaN(transactedAtYear) || transactedAtYear > 9999) {
    errors.transactedAt = [i18next.t("preReports.items.errors.date.invalid")];
  }

  if (isNil(category) || !category.selectable) {
    errors.category = [i18next.t("preReports.items.errors.category.blank")];
  }

  return errors;
};

const buttonContent = (disabled: boolean, isInitialized: boolean): string => {
  if (disabled) return i18next.t("commons.actions.close");
  return isInitialized
    ? i18next.t("commons.actions.add")
    : i18next.t("commons.actions.change");
};

const PreExpenseModal: FC<Props> = (props) => {
  const { preExpense } = props;
  const [formErrors, setFormErrors] = useState(initialFormErrors);
  const isNew = isNil(preExpense.id);
  const isInitialized = useMemo(
    () => isNew && preExpense.comment == null,
    [preExpense.key],
  );

  const onClose = (): void => {
    setFormErrors(initialFormErrors);
    props.onClose();
  };

  const onSubmit = (): void => {
    const errors = validatePreExpense(preExpense);
    setFormErrors(errors);

    if (!hasError(errors)) props.onSubmit();
  };

  const onChangeAmount = (amount: number | null): void => {
    setFormErrors({ ...formErrors, amount: [] });
    props.onChangePreExpense({ ...preExpense, amount });
  };

  const onChangeContent = (comment: string): void => {
    setFormErrors({ ...formErrors, content: [] });
    props.onChangePreExpense({ ...preExpense, comment });
  };

  const onChangeDate = (transactedAt: string): void => {
    setFormErrors({ ...formErrors, transactedAt: [] });
    props.onChangePreExpense({ ...preExpense, transactedAt });
  };

  const onSelectCategory = (category: Category | null): void => {
    setFormErrors({ ...formErrors, category: [] });
    props.onChangePreExpense({
      ...preExpense,
      category,
      categoryId: category?.id || "",
      categoryName: category?.name || "",
    });
  };

  const onSelectParticipants = (companions: Participants): void => {
    setFormErrors({ ...formErrors, participants: [] });
    props.onChangePreExpense({ ...preExpense, companions });
  };

  const buttons: ButtonProps[] = [
    {
      content: buttonContent(props.disabled, isInitialized),
      color: "success",
      onClick: props.disabled ? onClose : onSubmit,
    },
  ];

  return (
    <SimpleModal
      show={props.show}
      close={onClose}
      title={i18next.t("preReports.items.anItem")}
      buttons={buttons}
    >
      <form className="row transaction-form">
        <div className="row form-horizontal">
          <PreExpenseContentForm
            content={preExpense.comment}
            disabled={props.disabled}
            errors={formErrors.content}
            onChangeContent={onChangeContent}
          />
          <PreExpenseAmountForm
            amount={preExpense.amount}
            disabled={props.disabled}
            errors={formErrors.amount}
            onChangeAmount={onChangeAmount}
          />
          <PreExpenseDateForm
            date={preExpense.transactedAt}
            disabled={props.disabled}
            errors={formErrors.transactedAt}
            onChangeDate={onChangeDate}
          />
          <PreExpenseCategoryForm
            category={preExpense.category}
            disabled={props.disabled}
            errors={formErrors.category}
            onSelectCategory={onSelectCategory}
          />
          {preExpense.category?.requiresCompanion ? (
            <PreExpenseParticipantsForm
              disabled={props.disabled}
              errors={formErrors.participants}
              isNew={isNew}
              participants={preExpense.companions}
              shouldSelectSelfAsCompanion={props.shouldSelectSelfAsCompanion}
              onSelectParticipantsCategory={props.onSelectParticipantsCategory}
              oSelectParticipants={onSelectParticipants}
            />
          ) : null}
        </div>
      </form>
    </SimpleModal>
  );
};

export default PreExpenseModal;
