import CompanionSelector from "components/companion_selector";
import FormValidation from "components/FormValidation";
import LabeledComponent from "components/LabeledComponent";
import i18next from "i18n";
import React, { FC } from "react";
import { Participant, UserParticipant } from "utilities/api/models";

interface Props {
  disabled: boolean;
  errors: string[];
  isNew: boolean;
  participants: Array<Participant | UserParticipant>;
  shouldSelectSelfAsCompanion: boolean;
  onSelectParticipantsCategory: () => void;
  oSelectParticipants: (
    participants: Array<Participant | UserParticipant>,
  ) => void;
}

const PreExpenseParticipantsForm: FC<Props> = (props) => {
  return (
    <LabeledComponent
      className="form-group"
      labelClass="col-sm-3"
      label={i18next.t("transactions.properties.companion")}
    >
      <div className="col-sm-6">
        <FormValidation messages={props.errors}>
          <CompanionSelector
            static={props.disabled}
            value={props.participants}
            onSelect={props.oSelectParticipants}
            isNew={props.isNew}
            shouldSelectSelfAsCompanion={props.shouldSelectSelfAsCompanion}
            onSelectCompanionsCategory={props.onSelectParticipantsCategory}
          />
        </FormValidation>
      </div>
    </LabeledComponent>
  );
};

export default PreExpenseParticipantsForm;
