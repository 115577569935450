import i18next from "i18n";
import React from "react";
import RequestStateButton from "./RequestStateButton";
import { Props } from "./Types";

/** 「仮払済みにする」ボタン */
const TemporaryPaymentButton: React.FC<Props> = ({ requestState, onClick }) => {
  return (
    <RequestStateButton
      buttonJobType="recordTemporaryPayment"
      buttonTitle={i18next.t("reports.requests.temporaryPaymentProcessed")}
      buttonClassName="btn btn-block btn-accent"
      iconClassName="fa fa-left fa-check-square"
      requestState={requestState}
      onClick={onClick}
    />
  );
};

export default TemporaryPaymentButton;
