import {
  FileLoadStatus,
  LoadingStatus,
} from "components/types/attachedFiles.types";

interface UseReturn {
  readonly isLoaded: boolean;
  readonly canRotate: boolean;
  readonly canHand: boolean;
  readonly canUpload: boolean;
  readonly canTransform: boolean;
  readonly canDownload: boolean;
  readonly canDelete: boolean;
  readonly canOnClickView: boolean;
  readonly isShowToolBar: () => boolean;
  readonly onClickView: (e: React.SyntheticEvent) => void;
}

/**
 * PDFビューアー: ロジック
 */
export const useHooks = (
  isLoading: boolean,
  needsPassword: boolean,
  isShowToolBarProps: boolean,
  isShowFileSelect: boolean,
  loadStatus: FileLoadStatus,
  isDeletable: boolean,
  isTransformable: boolean,
  downloadLink?: string,
  onClick?: (e: React.SyntheticEvent, state: LoadingStatus) => void,
): UseReturn => {
  /**
   * ファイルのロードが完了したか
   * notFoundではないか
   */
  const isLoaded = loadStatus.state === "loaded" && !isLoading;

  /**
   * ツールバーを表示するか
   */
  const isShowToolBar = (): boolean => {
    if (!isShowToolBarProps) return false;
    if (isDeletable) return true;
    if (isShowFileSelect) return true;
    if (isTransformable) return true;
    if (downloadLink) return true;
    return false;
  };

  /**
   * 表示するか: PDFの表示操作
   */
  const isShowTransform = isLoaded && !needsPassword && isTransformable;

  /**
   * 表示するか: 回転
   */
  const canRotate = isShowTransform;

  /**
   * 表示するか: 手のひらツール
   */
  const canHand = isShowTransform;

  /**
   * 表示するか: 拡大縮小
   */
  const canTransform = isShowTransform;

  /**
   * 表示するか: アップロードボタン
   */
  const canUpload = !isLoading && isShowFileSelect;

  /**
   * 表示するか: ダウンロード
   */
  const canDownload = !isLoading && Boolean(downloadLink);

  /**
   * 表示するか: 削除ボタン
   */
  const canDelete = !isLoading && isDeletable;

  /**
   * クリックできるか
   */
  const canOnClickView = Boolean(onClick);

  /**
   * ビューをクリックした
   */
  const onClickView = (e: React.SyntheticEvent): void => {
    if (!canOnClickView) return;
    onClick!(e, loadStatus.state);
  };

  return {
    isLoaded,
    canRotate,
    canHand,
    canUpload,
    canTransform,
    canDownload,
    canDelete,
    canOnClickView,
    isShowToolBar,
    onClickView,
  };
};
