import React from 'react';
import styled from 'styled-components';
import { Button } from 'react-bootstrap';

const PageLinkButtonView = styled(Button)`
  color: #020b16;
  text-decoration: none;
  box-shadow: none;

  &.active {
    color: #004acc;
    box-shadow: 0px -3px #004acc inset;
    font-weight: 500;
  }

  &:hover {
    color: #2237a3;
    text-decoration: none;
    box-shadow: 0px -3px #2237a3 inset;
  }
`;

interface Props {
  href: string;
  active?: boolean;
}

export const PageLinkButton: React.FC<Props> = ({
  href,
  active = false,
  children,
}) => {
  return (
    <PageLinkButtonView bsStyle='link' href={ href } active={ active }>
      { children }
    </PageLinkButtonView>
  );
};
