import * as ActionTypes from '../actions';

const initialState = {
  icCardUseOption: userPreferences.preference.icCardOption,
  icCardDeductionOption: userPreferences.preference.icCardDeductionOption,
};

const IcCardOption = (state = initialState, action) => {
  switch (action.type) {
    case ActionTypes.SET_IC_CARD_USE_OPTION:
      return {
        ...state,
        icCardUseOption: action.value,
      };
    case ActionTypes.SET_IC_CARD_DEDUCTION_OPTION:
      return {
        ...state,
        icCardDeductionOption: action.value,
      };
    default:
      return state;
  }
};

export default IcCardOption;
