import { GenericFieldSearchCondition } from "applications/requests/utilities/SearchParamsStorage";
import { cloneDeep } from "lodash";
import pick from "lodash/pick";
import WebStorage from "utilities/web_storage";

export interface InitialConditions {
  _version?: string;
  startAt: string;
  endAt: string;
  destination: string;
  tags: [];
  applicantGroup: {
    id: void;
    name: string;
  };
  paymentGroup: {
    id: void;
    name: string;
  };
  project: {
    id: void;
    name: string;
  };
  post: {
    id: void;
    name: string;
  };
  approvedApprover: {
    id: void;
    name: string;
  };
  aggregationName: string;
  isAggregationNameModalOpen: boolean;
  isAggregationLoading: boolean;
  projectId: {
    name: string;
  };
  projectName: {
    name: string;
  };
  searchedProjectId: string;
  searchedProjectName: string;
  includeChildDepartment: boolean;
  includeNotAggregated: boolean;
  includeAggregated: boolean;
  includeNotExported: boolean;
  includeExported: boolean;
  includeNotPaid: boolean;
  includePaid: boolean;
  includeUnsettled: boolean;
  includeSettled: boolean;
  matchedOriginalReceipt: boolean;
  notMatchedOriginalReceipt: boolean;
  includeNotTemporaryPaid: boolean;
  includeTemporaryPaid: boolean;
  invoice: {
    registratedNumber: string;
    asInvoiceChecks: boolean[];
    emptyRegistratedNumber: boolean;
  };
  date: {
    submittedAtFrom: string;
    submittedAtTo: string;
    transactedAtFrom: string;
    transactedAtTo: string;
    lastApprovedAtFrom: string;
    lastApprovedAtTo: string;
    temporaryPaymentDueAtFrom: string;
    temporaryPaymentDueAtTo: string;
  };
  genericFields: GenericFieldSearchCondition[];
}

export interface StoredData {
  reports: InitialConditions;
  preTravelReports: InitialConditions;
  temporaryPaymentReports: InitialConditions;
}

export type AggregationType =
  | "reports"
  | "temporaryPaymentReports"
  | "preTravelReports";

export class SearchParamsStorage {
  private storage: WebStorage;

  private version: string;

  private _data: null | StoredData;

  private initialContidions: InitialConditions;

  constructor() {
    this.storage = new WebStorage();
    this._data = null;
    this.version = "1.0.0";

    this.initialContidions = {
      startAt: "",
      endAt: "",
      destination: "",
      tags: [],
      applicantGroup: {
        id: void 0,
        name: "",
      },
      paymentGroup: {
        id: void 0,
        name: "",
      },
      project: {
        id: void 0,
        name: "",
      },
      post: {
        id: void 0,
        name: "",
      },
      approvedApprover: {
        id: void 0,
        name: "",
      },
      aggregationName: "",
      isAggregationNameModalOpen: false,
      isAggregationLoading: false,
      projectId: {
        name: "",
      },
      projectName: {
        name: "",
      },
      searchedProjectId: "",
      searchedProjectName: "",
      includeChildDepartment: true,
      includeNotAggregated: true,
      includeAggregated: false,
      includeNotExported: true,
      includeExported: false,
      includeNotPaid: true,
      includePaid: false,
      includeUnsettled: false,
      includeSettled: false,
      matchedOriginalReceipt: false,
      notMatchedOriginalReceipt: false,
      includeNotTemporaryPaid: true,
      includeTemporaryPaid: false,
      date: {
        submittedAtFrom: "",
        submittedAtTo: "",
        transactedAtFrom: "",
        transactedAtTo: "",
        lastApprovedAtFrom: "",
        lastApprovedAtTo: "",
        temporaryPaymentDueAtFrom: "",
        temporaryPaymentDueAtTo: "",
      },
      invoice: {
        registratedNumber: "",
        asInvoiceChecks: [],
        emptyRegistratedNumber: false,
      },
      genericFields: [],
    };

    this.migrate();
  }

  public get data(): StoredData {
    return this._data || this.storage.getItem(this.key) || this.defaultData;
  }

  public set data(params: StoredData) {
    this._data = pick(params, Object.keys(this.defaultData)) as StoredData;
    this.storage.setItem(this.key, { ...this._data, _version: this.version });
  }

  public get defaultData(): Omit<StoredData, "_version"> {
    return {
      reports: this.initialContidions,
      preTravelReports: this.initialContidions,
      temporaryPaymentReports: this.initialContidions,
    };
  }

  public clear(): void {
    this.data = { ...this.defaultData };
  }

  public reset(aggregationType: AggregationType): void {
    const newData = cloneDeep(this.data);
    newData[aggregationType] = this.initialContidions;

    this.data = { ...newData };
  }

  private get key(): string[] {
    return ["analyses", "searchCondition"];
  }

  private migrate(): void {
    this.data = this.migrateSchema(this.data);
  }

  private migrateSchema(params: StoredData): StoredData {
    if (params) {
      return params;
    }

    return this.defaultData;
  }
}

export default new SearchParamsStorage();
