import ApiBase from "./api_base";

const relativePath = "adi";
const authPath = "/api/v1/auth";

export default new ApiBase("v1", { relativePath, authPath })
  .resource("/tax_documents/waiting_dedicated_inputs", { only: ["create"] })
  .resource("/tax_documents/waiting_worker_inputs", { only: ["create"] })
  .resources("/preferences", { only: ["index"] })
  .put("/preferences/update")

  .build();
