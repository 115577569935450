import React, {
  useCallback, useEffect, useState,
} from 'react';
import flash from 'utilities/flash';
import { FixedSizeList } from 'react-window';
import { getMessageFromResponse } from 'utilities/Utils';
import { isNil } from 'lodash';

type SparseArray<T> = Array<T>;
type Response<T> = {
  count: number;
  resultOptions: SparseArray<T>;
};

export type FetchItems<T> = (offset: number, limit: number) => Promise<Response<T>>;
export type ItemsChangeCallback<T> = (items: SparseArray<T>, count: number) => void;

interface RenderItemOptions {
  style: React.CSSProperties;
  index: number;
}

interface Props<T> {
  className?: string;
  innerRef?: React.Ref<HTMLDivElement>;
  outerRef?: React.Ref<HTMLDivElement>;
  totalCount?: number | null;
  batchSize?: number;
  throttleMs?: number;
  overscanCount?: number;
  items: SparseArray<T>;
  height: number;
  width?: number | string;
  itemHeight: number;
  style?: React.CSSProperties;
  fetchItems: FetchItems<T>;
  onItemsChange: ItemsChangeCallback<T>;
  renderItem: (item: T, options: RenderItemOptions) => React.ReactElement;
}

type Direction = 'forward' | 'backward';

const InfiniteScrollerWithPagination = <T extends { id: string }>(props: Props<T>): React.ReactElement<Props<T>> | null => {
  const {
    items, totalCount, batchSize = 20,  overscanCount = 8,
    innerRef, outerRef, style: listStyle, height, width = 'auto', itemHeight,
    fetchItems, onItemsChange, renderItem,
  } = props;

  const [scrollDirection, setScrollDirection] = useState<Direction>('forward');
  const [isLoading, setIsLoading] = useState(false);
  const getItemKey = useCallback((index) => {
    return items[index]?.id || index;
  }, [items]);
  const renderRow = useCallback(({ index, style }) => {
    return renderItem(items[index], { style, index });
  }, [renderItem, items]);
  const fetch$ = useCallback(async () => {
    setIsLoading(true);

    try {
      const { resultOptions, count } = await fetchItems(items.length, batchSize);

      onItemsChange(resultOptions, count);
    } catch (error) {
      flash.error(getMessageFromResponse(error as Record<string, unknown>));
    } finally {
      setIsLoading(false);
    }
  }, [batchSize, fetchItems, items.length, onItemsChange]);

  useEffect(() => {
    if (isNil(totalCount)) {
      fetch$();
    }
  }, [fetch$]); // eslint-disable-line react-hooks/exhaustive-deps

  const handleItemsRendered = useCallback(({ overscanStopIndex }) => {
    if (
      items.length - 1 === overscanStopIndex
        && scrollDirection === 'forward'
        && totalCount
        && items.length < totalCount
        && !isLoading
    ) fetch$();
  }, [fetch$, isLoading, items.length, scrollDirection, totalCount]);
  const handleScroll = useCallback((x) => {
    setScrollDirection(x.scrollDirection);
  }, []);

  return (
    <FixedSizeList
      innerRef={ innerRef }
      outerRef={ outerRef }
      style={ listStyle }
      itemKey={ getItemKey }
      itemCount={ items.length }
      overscanCount={ overscanCount }
      height={ height }
      width={ width }
      itemSize={ itemHeight }
      onItemsRendered={ handleItemsRendered }
      onScroll={ handleScroll }
        >
      { renderRow }
    </FixedSizeList>
  );
};

export default InfiniteScrollerWithPagination;
