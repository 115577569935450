import LoadingOverlay from "components/LoadingOverlay";
import i18next from "i18n";
import _ from "lodash";
import PropTypes from "prop-types";
import React, { PureComponent } from "react";
import { BootstrapTable, TableHeaderColumn } from "react-bootstrap-table";
import Formatter from "utilities/formatter";
import { isCorporatePlan } from "utilities/Utils";

export default class TargetTemporaryPaymentReportTable extends PureComponent {
  formatStatus(status, row) {
    const statusObj = { status, color: row.statusColor, label: status };
    return Formatter.requestStatus(statusObj);
  }

  formatPaymentGroup(paymentGroups = []) {
    const names = paymentGroups.map((group) => _.get(group, "name", null));

    if (names.length > 1) {
      return Formatter.text(
        "",
        i18next.t("analyses.properties.multiplePaymentGroups"),
      );
    }

    return Formatter.text(
      _.get(names, "[0]", ""),
      i18next.t("commons.status.nothingPaymentGroup"),
    );
  }

  formatProject(baseProjects = []) {
    const projects = baseProjects;
    projects.sort((a, b) => {
      if (a.displayId < b.displayId) {
        return -1;
      }
      if (a.displayId > b.displayId) {
        return 1;
      }
      return 0;
    });
    const projectName = _.get(projects, "[0].name", "");

    if (projects.length > 1) {
      const otherCountsText = `${projectName}\n${i18next.t(
        "commons.units.otherCounts",
        { count: baseProjects.length - 1 },
      )}`;
      return Formatter.text(otherCountsText, "", null, true);
    }

    return Formatter.text(
      projectName,
      i18next.t("commons.conditions.no"),
      null,
      true,
    );
  }

  formatApplicantGroup(cell, row) {
    const group = _.get(row, "userGroup");
    if (group) {
      return Formatter.text(group, group);
    }
    return Formatter.text(
      "",
      i18next.t("analyses.properties.nothingApplicantGroup"),
    );
  }

  formatAggregationStatus(flg) {
    if (flg) {
      return i18next.t("commons.status.aggregated");
    }
    return i18next.t("commons.status.notAggregated");
  }

  formatExportationStatus(flg) {
    if (flg) {
      return i18next.t("commons.status.exported");
    }
    return i18next.t("commons.status.notExported");
  }

  formatPaymentStatus(status) {
    if (status === i18next.t("commons.status.approved")) {
      return i18next.t("commons.status.notPaid");
    }
    return i18next.t("commons.status.paid");
  }

  trClassFormat(rowData, rowIndex) {
    switch (rowData.status) {
      case i18next.t("commons.status.unsubmitted"): {
        return "row-created";
      }
      case i18next.t("commons.status.recalled"): {
        return "row-recalled";
      }
      case i18next.t("commons.status.applying"): {
        return "row-applying";
      }
      case i18next.t("commons.status.reapplying"): {
        return "row-reapplying";
      }
      case i18next.t("commons.status.rejected"): {
        return "row-rejected";
      }
      case i18next.t("commons.status.approved"): {
        return "row-approved";
      }
      case i18next.t("commons.status.closed"): {
        return "row-closed";
      }
      default:
        return "";
    }
  }

  render() {
    const { reports } = this.props;

    const options = {
      onRowClick: this.props.onRowClick.bind(this),
      noDataText: i18next.t("commons.messages.noDataFound"),
    };

    const selectRowOptions = this.display("check")
      ? {
          mode: "checkbox",
          clickToSelect: true,
          selected: reports
            .filter((report) => report.isChecked)
            .map((report) => report.id),
          onSelect: this.props.onRowSelect.bind(this),
          onSelectAll: this.props.onAllRowSelect.bind(this),
        }
      : {};

    const isCorporate = isCorporatePlan();

    return (
      <LoadingOverlay iconClassName={"fa fa-spinner fa-spin fa-fw fa-3x"}>
        <BootstrapTable
          className="reports-table"
          trClassName={this.trClassFormat.bind(this)}
          data={reports}
          options={options}
          selectRow={selectRowOptions}
          tableBodyClass="multiline"
        >
          <TableHeaderColumn hidden={true} dataField="id" isKey={true}>
            ID
          </TableHeaderColumn>
          <TableHeaderColumn
            hidden={!this.display("title")}
            dataField="title"
            width="200"
          >
            {i18next.t(
              `analyses.titles.${
                isCorporate ? "reportTitle" : "personalReportTitle"
              }`,
            )}
          </TableHeaderColumn>
          <TableHeaderColumn
            hidden={!this.display("sequence")}
            dataFormat={(v) => Formatter.text(v)}
            dataField="sequence"
            width="120"
          >
            {i18next.t("analyses.titles.sequence")}
          </TableHeaderColumn>
          <TableHeaderColumn
            hidden={!this.display("userName")}
            dataField="userName"
            width="120"
          >
            {i18next.t("analyses.titles.userName")}
          </TableHeaderColumn>
          <TableHeaderColumn
            hidden={!this.display("userGroup")}
            dataFormat={this.formatApplicantGroup}
            width="200"
          >
            {i18next.t("analyses.titles.applicantGroup")}
          </TableHeaderColumn>
          <TableHeaderColumn
            hidden={!this.display("paymentGroup")}
            dataField="paymentGroups"
            dataFormat={this.formatPaymentGroup}
            width="200"
          >
            {i18next.t("analyses.titles.paymentGroup")}
          </TableHeaderColumn>
          <TableHeaderColumn
            hidden={!this.display("project")}
            dataField="projects"
            dataFormat={this.formatProject.bind(this)}
            width="200"
          >
            {i18next.t("analyses.titles.project")}
          </TableHeaderColumn>
          <TableHeaderColumn
            hidden={!this.display("preAmount")}
            dataField="preAmount"
            dataFormat={(v) => Formatter.amount(v)}
            dataAlign="right"
            width="120"
          >
            {i18next.t("analyses.titles.preAmount")}
          </TableHeaderColumn>
          <TableHeaderColumn
            hidden={!this.display("aggregationStatus")}
            dataField="isAggregated"
            dataFormat={(flg) => this.formatAggregationStatus(flg)}
            width="90"
          >
            {i18next.t("analyses.titles.aggregationStatus")}
          </TableHeaderColumn>
          <TableHeaderColumn
            hidden={!this.display("exportationStatus")}
            dataField="exported"
            dataFormat={(flg) => this.formatExportationStatus(flg)}
            width="150"
          >
            {i18next.t("analyses.titles.exportationStatus")}
          </TableHeaderColumn>
          <TableHeaderColumn
            hidden={!this.display("paymentStatus")}
            dataField="status"
            dataFormat={(status) => this.formatPaymentStatus(status)}
            width="150"
          >
            {i18next.t("analyses.titles.paymentStatus")}
          </TableHeaderColumn>
        </BootstrapTable>
      </LoadingOverlay>
    );
  }

  display(column) {
    let isVisible = true;
    if (this.props.only) {
      isVisible = _.includes(this.props.only, column);
    }
    if (this.props.except) {
      isVisible = !_.includes(this.props.except, column);
    }
    return isVisible;
  }
}

TargetTemporaryPaymentReportTable.defaultProps = {
  reports: [],
  onRowClick: () => null,
  onRowSelect: () => null,
  onAllRowSelect: () => null,
};

TargetTemporaryPaymentReportTable.propTypes = {
  reports: PropTypes.array,
  only: PropTypes.arrayOf(PropTypes.string),
  except: PropTypes.arrayOf(PropTypes.string),
  onRowClick: PropTypes.func,
  onRowSelect: PropTypes.func,
  onAllRowSelect: PropTypes.func,
  openExportModal: PropTypes.func,
  projectNameOfSearchCondition: PropTypes.string,
};
