import * as ActionTypes from '../actions/importForm';

const initialImportState = {
  importFile: null,
  fileFormat: 'normal',
  importJobs: [],
  allImportResults: false,
  queueCounts: {},
  uploading: false,
};

const ImportForm = (state = initialImportState, action) => {
  switch (action.type) {
    case ActionTypes.SET_IMPORT_JOBS: {
      return {
        ...state,
        importJobs: action.importJobs,
      };
    }
    case ActionTypes.SET_QUEUE_COUNTS: {
      return {
        ...state,
        queueCounts: {
          ...state.queueCounts,
          ...action.counts,
        },
      };
    }
    case ActionTypes.SET_FILE_FORMAT:
      return {
        ...state,
        format: action.format,
      };
    case ActionTypes.SET_IMPORT_FILE:
      return {
        ...state,
        importFile: action.file,
      };
    case ActionTypes.CLEAR_IMPORT_FILE:
      return {
        ...state,
        importFile: null,
      };
    case ActionTypes.TOGGLE_ALL_IMPORT_RESULTS:
      return {
        ...state,
        allImportResults: action.value,
      };
    case ActionTypes.TOGGLE_FILE_DROPAREA:
      return {
        ...state,
        uploading: action.value,
      };
    default:
      return state;
  }
};

export default ImportForm;
