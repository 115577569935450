import CheckBoxGroupAddon from "components/CheckBoxGroupAddon";
import DepartmentSelector from "components/DepartmentSelector";
import i18next from "i18n";
import React, { FC } from "react";
import styled from "styled-components";

const SelectorView = styled.div`
  .list-selector-button {
    height: 32px;
  }
`;

interface Props {
  includeChildDepartment: boolean;
  departments: Array<Department>;
  departmentId: string;
  onIncludeChildDepartmentChange: () => void;
  onSelectDepartment: () => void;
}

export interface Department {
  id: string;
  level: number;
  name: string;
  parentId: string | null;
  absolutePath: string;
  parent?: Department;
}

export const DepartmentAndChild: FC<Props> = (props) => {
  const renderDepartment = (): JSX.Element => {
    const department = props.departments.find(
      (dep) => dep.id === props.departmentId,
    );

    return (
      <div className="input-group">
        <SelectorView className="list-selector form-control">
          <DepartmentSelector
            departments={props.departments}
            selectedDepartment={department}
            onSelectDepartment={props.onSelectDepartment}
          />
        </SelectorView>
        <CheckBoxGroupAddon
          label={i18next.t(
            "transactions.index.searchbox.departmentScope.includeChildDepartment",
          )}
          defaultChecked={props.includeChildDepartment}
          onChange={props.onIncludeChildDepartmentChange}
        />
      </div>
    );
  };

  return <div>{renderDepartment()}</div>;
};

DepartmentAndChild.defaultProps = {
  includeChildDepartment: false,
  departments: [],
  departmentId: "",
};

export default DepartmentAndChild;
