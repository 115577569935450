import PropTypes from 'prop-types';
import React from 'react';
import _ from 'lodash';

const rootStyle = { position: 'relative' };
const buttonStyle = {
  color: '#ccc',
  position: 'absolute',
  top: '50%',
  right: '6px',
  transform: 'translateY(-50%)',
  cursor: 'pointer',
};

/**
 * 入力フィールドに対して、クリアボタンを右端に配置するために使う
 * divタグで囲む代わりに使用するイメージ
 *
 * @example
 *   <Clearable className='div-class' onClear={ onClear }>
 *     <input value={ inputValue } />
 *   </Clearable>
 */
const Clearable = (props) => {
  const handleClear = (e) => { e.stopPropagation(); props.onClear(e); };
  const className = props.className ? `clearable ${props.className}` : 'clearable';

  return (
    <div { ..._.omit(props, ['buttonStyle', 'onClear']) }
      className={ className }
      style={ { ...rootStyle, ...(props.style || {}) } }
      disabled={ props.disabled }
    >
      { props.children }

      <span type='button'
        style={ { ...buttonStyle, ...(props.buttonStyle || {}), visibility: props.disabled ? 'hidden' : undefined } }
      >
        <i role='button' className='fa fa-times-circle' onClick={ handleClear } />
      </span>
    </div>
  );
};

Clearable.propTypes = {
  buttonStyle: PropTypes.object,
  onClear: PropTypes.func,
  children: PropTypes.any.isRequired,
  disabled: PropTypes.bool,
};

export default Clearable;
