import Dropzone from 'react-dropzone';
import FileUploader from 'utilities/file_uploader';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import UPLOAD_STATUS from 'values/upload_status';

export default class AttachmentDroparea extends Component {
  onFileDrop(files) {
    const { onChange } = this.props;

    // Dropzoneはmultiple=falseで利用する時、複数ファイルをドロップするとfilesが空配列になる仕様
    // そのため、files.length > 1としない
    if (files.length !== 1) {
      onChange([{
        status: UPLOAD_STATUS.failed,
        error: "一度に複数のファイルをアップロードできません",
      }]);
      return;
    }

    const file = files[0];

    // 25MB以下しかアップロードさせないよ
    if (file.size > 25 * 1024 * 1024) {
      onChange([{
        status: UPLOAD_STATUS.failed,
        error: "25MB以下のファイルしかアップロードできません",
      }]);
      return;
    }

    onChange([{
      status:   UPLOAD_STATUS.uploading,
      fileName: file.name,
    }]);

    new FileUploader().upload(file)
      .then((data) => {
        onChange([{
          status:   UPLOAD_STATUS.uploaded,
          fileName: file.name,
          attachmentId: data.attachmentId,
        }]);
      })
      .catch((error) => {
        onChange([{
          status:   UPLOAD_STATUS.failed,
          fileName: file.name,
          error,
        }]);
      });
  }

  render() {
    return (
      <Dropzone multiple={ false } onDrop={ this.onFileDrop.bind(this) }>
        {
          ({ getRootProps, getInputProps }) => {
            return (
              <div { ...getRootProps() } className={ `attachment-dropzone ${this.props.className}` }>
                <input { ...getInputProps() } />

                { this.props.children }
              </div>
            );
          }
        }
      </Dropzone>
    );
  }
}

AttachmentDroparea.propTypes = {
  className: PropTypes.string,
  onChange:  PropTypes.func.isRequired,
  children:  PropTypes.node.isRequired,
  disabled:   PropTypes.bool.isRequired,
};
