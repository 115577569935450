import { displayMessage } from "actions/ActionCreators";
import { ThunkDispatch } from "tdispatch";
import Api from "utilities/api";
import { getMessageFromResponse } from "utilities/Utils";
import { fetchPreReport } from "../preReportActions";
import {
  closePreReportRequestJob,
  startPreReportRequest,
} from "../preReportRequestActions";
import * as ActionCreators from "./ActionCreators";

/**
 * 承認を取り消します。
 * @param preReportId 事前申請ID
 * @param requestId 申請ID
 * @param comment コメント
 */
export function cancelApproval(
  preReportId: string,
  requestId: string,
  comment: string,
) {
  return async (dispatch: ThunkDispatch): Promise<void> => {
    try {
      dispatch(startPreReportRequest("cancelApproval"));
      const { message } = await Api.requests.cancelApprovals({
        id: requestId,
        comment,
      });

      await dispatch(fetchPreReport(preReportId, true));
      dispatch(ActionCreators.closeModal());
      dispatch(displayMessage("success", message));
    } catch (e) {
      dispatch(displayMessage("error", getMessageFromResponse(e)));
    } finally {
      dispatch(closePreReportRequestJob());
    }
  };
}
