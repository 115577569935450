import React, { useState } from 'react';
import styled from 'styled-components';
import { DeleteConfirmModal } from './DeleteConfirmModal';
import { Dropdown, DropdownToggleButton, MenuItem } from 'components/renewaled_ui/Dropdown';
import { EditButton } from 'applications/accounting_data_scheduled_exports/components/buttons';
import { TaskBase } from 'utilities/api/models/accountingDataScheduledExports';

interface Props {
  task: TaskBase;
  processing: boolean;
  onEdit: () => void;
  onDelete: () => void;
  onEnable: () => void;
  onDisable: () => void;
  onExecute: () => void;
  onTest: () => void;
}

const ToolbarView = styled.div`
  display: flex;
  gap: 6px;
`;

export const DetailToolbar: React.FC<Props> = ({
  task,
  processing,
  onEdit,
  onDelete,
  onEnable,
  onDisable,
  onExecute,
  onTest,
}) => {
  const [showDeleteConfirmModal, setShowDeleteConfirmModal] = useState<boolean>(false);

  return (<>
    <ToolbarView>
      <EditButton onClick={ onEdit } disabled={ processing } />
      <Dropdown id='detail-drawer-toolbar-dropdown' direction='up' toggleButton={ <DropdownToggleButton styleType='secondary' bsRole='toggle' direction='up' disabled={ processing }><>その他の操作</></DropdownToggleButton> }>
        <MenuItem onClick={ onExecute }>即時実行</MenuItem>
        <MenuItem onClick={ onTest }>テスト実行</MenuItem>
        <MenuItem onClick={ onEnable } hidden={ task.enabled } >有効にする</MenuItem>
        <MenuItem onClick={ onDisable } hidden={ !task.enabled } >無効にする</MenuItem>
        <MenuItem onClick={ (): void => setShowDeleteConfirmModal(true) } color='#ff0000'>削除</MenuItem>
      </Dropdown>
    </ToolbarView>
    <DeleteConfirmModal
      show={ showDeleteConfirmModal }
      processing={ processing }
      onClose={ (): void => setShowDeleteConfirmModal(false) }
      onDelete={ onDelete }
    />
  </>);
};
