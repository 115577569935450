import React from 'react';
import styled from 'styled-components';

const Wrapper = styled.td`
  min-width: 40px;
  height: 50px;
  cursor: grab;
`;
const Inner = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

/**
 * ドラッグドロップするアイコン
 */
export const TableDraggableIcon = React.forwardRef<HTMLTableCellElement>((_, ref) => {
  return (
    <Wrapper
      className='td'
      ref={ ref }
    >
      <Inner>
        <i className="fas fa-bars" />
      </Inner>
    </Wrapper>
  );
});
