import {
  FetchParams,
  IReportExpenseDriver,
  ReportExpensesResponse,
} from './IReportExpenseDriver';

export class ReportExpenseApi {
  constructor(private driver: IReportExpenseDriver) {
    this.driver = driver;
  }

  /** 経費申請に含まれる経費をすべて取得します */
  async fetchAll(apiParams: FetchParams): Promise<ReportExpensesResponse> {
    const apiResponse = await this.driver.fetchAll(apiParams);
    return apiResponse;
  }
}
