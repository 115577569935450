import { displayMessage } from "actions/ActionCreators";
import { fetchAsync } from "actions/AsyncAction";
import Api from "utilities/api";
import { getMessageFromResponse } from "utilities/Utils";

const prefix = "payment/monthlyUsage";

export function fetchTableData(year) {
  return async (dispatch, getState) => {
    try {
      const data = await dispatch(
        fetchAsync(Api.transactions.monthlyWorkerInputUsage, { year }),
      );
      dispatch(setTableData(data.monthlyUsage));
    } catch (e) {
      dispatch(displayMessage("error", getMessageFromResponse(e)));
    }
  };
}

export const SET_YEAR = `${prefix}/SET_YEAR`;
export function setYear(year) {
  return {
    type: SET_YEAR,
    value: year,
  };
}

export const SET_TABLE_DATA = `${prefix}/SET_TABLE_DATA`;
export function setTableData(data) {
  return {
    type: SET_TABLE_DATA,
    data,
  };
}
