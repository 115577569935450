import React from 'react';
import styled from 'styled-components';

const AcceptDocumentsView = styled.div`
  margin-bottom: 16px;
  a {
    color: #5590e9;
  }
`;

export const documentLinks = [
  {
    name: '利用規約',
    href: 'https://www.keihi.com/terms',
  },
  {
    name: 'プライバシーポリシー',
    href: 'https://www.keihi.com/privacy-policy.html',
  },
  {
    name: 'セキュリティーポリシー',
    href: 'https://www.keihi.com/security-policy.html',
  },
  {
    name: '個人情報の取り扱いについて',
    href: 'https://www.keihi.com/personal-information.html',
  },
];

export const AcceptDocuments: React.FC = React.memo(() => {
  return (
    <AcceptDocumentsView>
      <p>このアプリを利用するにあたり</p>
      <ul>
        { documentLinks.map((link) => {
          return (
            <li key={ link.name }>
              <a
                target='_blank'
                rel="noopener noreferrer"
                href={ link.href }>
                { link.name }
              </a>
            </li>
          );
        }) }
      </ul>
      <p>に同意します。</p>
    </AcceptDocumentsView>
  );
});
