import React from 'react';
import { Alert } from 'react-bootstrap';

interface Props {
  type: string,
  alertText: string;
  alertUrl: { text: string, url: string };
  style?: React.CSSProperties;
}

export const AlertSetting: React.FC<Props> = ({
  type, alertText, alertUrl, style,
}) => {
  return (
    <Alert style={ style } bsStyle={ type }>
      <span>{ alertText }</span>
      <a style={ { fontWeight: 'bold' } } href={ alertUrl.url }>{ alertUrl.text }</a>
    </Alert>
  );
};
