import Api from 'utilities/api';
import LabelWithDateFormatPopover from './components/LabelWithDateFormatPopover';
import React, { useEffect, useMemo, useState } from 'react';
import i18next from 'i18next';
import styled from 'styled-components';
import { CompanyExpenseAccount, TaskCompanyExpenseAccountAssignee } from 'utilities/api/models/accountingDataScheduledExports';
import { CompanyExpenseAccountSelectField } from './fields/CompanyExpenseAccountSelectField';
import { InputField } from 'components/renewaled_ui/forms/inputs';
import { PaymentSpecificationSelectField } from './fields/PaymentSpecificationSelectField';
import { RequiredIcon } from 'applications/generic_fields/components/icons/RequiredIcon';
import { useFieldArray, useFormContext } from 'react-hook-form';

interface Props {
  initValue: TaskCompanyExpenseAccountAssignee,
  enableBankDataCreation: boolean,
}

const CompanyExpenseAccountStyled = styled.div`
  padding: 10px 15px 5px;
  border-radius: 8px;
  margin: 5px 0px;

  &:hover {
    background: #e2e7fd;
  }
`;

const ButtonAdd = styled.span`
  display: flex;
  justify-content: flex-end;
  span {
    cursor: pointer;
    color: #3264c4;
  }
`;

const CompanyExpenseAccountAssigneeComponent = ({
  initValue,
  enableBankDataCreation,
}: Props): JSX.Element => {
  const { control } = useFormContext();

  const [processing, setProcessing] = useState<boolean>(false);
  const [companyExpenseAccounts, setCompanyExpenseAccounts] = useState<CompanyExpenseAccount[]>([]);

  const { fields, append, remove } = useFieldArray({ control, name: "companyExpenseAccountAssignees" });

  const rules = useMemo(() => (enableBankDataCreation
    ? { required: i18next.t('accountingDataScheduledExports.tasks.forms.export.exportFileNameFormat.required') } : {}
  ), [enableBankDataCreation]);

  const showCloseButton = useMemo(() => fields && Array.isArray(fields) && fields.length > 1, [fields]);

  const renderLabel = (): JSX.Element => {
    return (
      <>
        <LabelWithDateFormatPopover
          label={
            <>
              {i18next.t('accountingDataScheduledExports.tasks.forms.export.exportFileNameFormat.label')}
              <RequiredIcon />
            </>
          }
        />
      </>
    );
  };

  useEffect(() => {
    const fetchCompanyExpenseAccounts = async (): Promise<void> => {
      setProcessing(true);

      try {
        const res = await Api.accounting.accountingDataScheduledExports.companyExpenseAccounts.index();
        setCompanyExpenseAccounts(res.data);
      } catch (e) {
        // nothing to do
      } finally {
        setProcessing(false);
      }
    };
    fetchCompanyExpenseAccounts();
  }, []);

  return (
    <>
      {
        fields.map((item, index) => (
          <CompanyExpenseAccountStyled key={ item.id }>
            {
              showCloseButton && <button type="button" className="close" onClick={ (): void => remove(index) } >
                <span>×</span>
              </button>
            }
            <InputField
              name={ `companyExpenseAccountAssignees[${index}].exportFileName` }
              label={ renderLabel() }
              rules={ rules }
              control={ control }
              placeholder={ i18next.t('accountingDataScheduledExports.tasks.forms.export.exportFileNameFormat.placeholder') }
            />
            <PaymentSpecificationSelectField
              assigneeIndex={ index }
            />
            <CompanyExpenseAccountSelectField
              name={ `companyExpenseAccountAssignees[${index}].companyExpenseAccount` }
              processing={ processing }
              companyExpenseAccounts={ companyExpenseAccounts }
            />
          </CompanyExpenseAccountStyled>
        ))
      }
      <ButtonAdd>
        <span className="" onClick={ (): void => append(initValue) }>+ 出力形式を追加</span>
      </ButtonAdd>
    </>
  );
};

export default CompanyExpenseAccountAssigneeComponent;
