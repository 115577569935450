import assign from "lodash/assign";
import get from "lodash/get";
import isNil from "lodash/isNil";
import { createSelector } from "reselect";

const getPreReport = (state, props) => state.preReport;
const getPreTransactions = (state, props) =>
  get(state, "preReport.preTransactions");
const getEntryForms = (state, props) => {
  // 費用負担部署について、entryForm内にdefault値を持っている場合、
  // 経費追加モーダル表示時にはその値を優先するため、
  // 上書きたい内容で入れておく
  // この仕組み自体見直すべきなきがする...
  const entryForms = get(state, "transactionTable.entryForms");
  const costAllocations = getCostAllocations(state, props);

  return entryForms.map((form) => {
    const fields = form.formFields.map((field) => {
      // NOTE: 事前申請項目から経費を登録する場合、利用日の初期値は事前申請項目の利用日とする。
      if (
        field.type === "date_input" &&
        state.formState.transaction.transactedAt
      ) {
        return {
          ...field,
          defaultValue: state.formState.transaction.transactedAt,
        };
      }
      if (field.type === "cost_allocation_input") {
        return {
          ...field,
          defaultValue: costAllocations,
        };
      }

      return field;
    });

    return {
      ...form,
      formFields: fields,
    };
  });
};
const getCostAllocations = (state, props) => {
  const dep = get(state, "preReport.department", null);
  return [
    {
      payerType: "Group",
      payerId: get(dep, "id"),
      payerName: get(dep, "name"),
      numerator: 100,
      denominator: 100,
    },
  ];
};
const getEditable = (state, props) =>
  props.authority !== "approver" && get(state, "preReport.editable", false);

// 事前申請が承認済みで、経費申請が未申請、もしくは編集可能な時に、経費の追加が可能。承認者は経費追加できない。
const getTransactionAddable = (state, props) =>
  get(state, "preReport.hasApproved") &&
  props.authority !== "approver" &&
  (isNil(get(state, "preReport.report")) ||
    get(state, "preReport.report.editable", false));

const getHasApproved = (state, props) => get(state, "preReport.hasApproved");
const getIsTransactionModalOpen = (state, props) =>
  get(state, "formState.showTransactionModal", false);
const getModalTransaction = (state, props) =>
  get(state, "formState.transaction", null);
const getShouldSelectSelfAsCompanion = (state, props) =>
  get(state, "formState.shouldSelectSelfAsCompanion");

const makePreTransactionTableStateMapper = () =>
  createSelector(
    [
      getPreReport,
      getPreTransactions,
      getEntryForms,
      getEditable,
      getTransactionAddable,
      getHasApproved,
      getIsTransactionModalOpen,
      getModalTransaction,
      getShouldSelectSelfAsCompanion,
    ],
    (
      preReport,
      preTransactions,
      entryForms,
      editable,
      transactionAddable,
      hasApproved,
      isTransactionModalOpen,
      modalTransaction,
      shouldSelectSelfAsCompanion,
    ) => ({
      preReport,
      // 何がしたいのか不明なので、削除したい
      preTransactions: preTransactions.map((t) => assign(t, { key: t.key })),
      entryForms,
      editable,
      transactionAddable,
      hasApproved,
      isTransactionModalOpen,
      modalTransaction,
      shouldSelectSelfAsCompanion,
    }),
  );

export default makePreTransactionTableStateMapper;
