import i18next from "i18n";
import React, { FC } from "react";
import { TableHeaderColumn } from "react-bootstrap-table";
import Formatter from "utilities/formatter";
import { ColumnProps } from "./types";
import { buildTableHeaderColumnOption } from "./utils";

const formatReportStatus = (cell: string): string => {
  return Formatter.status(cell);
};

const renderReportStatusColumn: FC<ColumnProps> = (props: ColumnProps) => {
  return (
    <TableHeaderColumn
      {...buildTableHeaderColumnOption(props)}
      dataField={"status"}
      dataFormat={formatReportStatus}
    >
      {i18next.t("transactions.properties.status")}
    </TableHeaderColumn>
  );
};

export default renderReportStatusColumn;
