import { displayMessage } from "actions/ActionCreators";
import i18next from "i18n";
import _get from "lodash/get";
import Api from "utilities/api";
import UPLOAD_STATUS from "values/upload_status";
import * as formActions from "../../request_types/actions/formActions";
import * as requestTypeActions from "./requestTypeActions";

const prefix = `APPLICATION`;

export const SET_TITLE = `${prefix}/SET_TITLE`;
export function setTitle(title) {
  return {
    type: SET_TITLE,
    title,
  };
}

export const SET_APPLICATION = `${prefix}/SET_APPLICATION`;
export function setApplication(application) {
  return {
    type: SET_APPLICATION,
    application,
  };
}

export function fetchApplication(id) {
  return async (dispatch, getState) => {
    try {
      const application = await Api.applications.show({ id });
      dispatch(setApplication(application));
      dispatch(
        formActions.setForm(
          application.request.formItemInputs.map((input) => {
            if (input.type === "attachment") {
              const { attachment, ...newInput } = input;
              return {
                ...newInput,
                value:
                  (attachment && [
                    {
                      attachmentId: attachment.id,
                      fileName: attachment.fileName,
                      status: UPLOAD_STATUS.uploaded,
                    },
                  ]) ||
                  [],
              };
            }
            return input;
          }),
        ),
      );
      dispatch(requestTypeActions.setRequestType(application.requestType));
    } catch (e) {
      dispatch(
        displayMessage(
          "error",
          _get(e, "responseJSON.message") ||
            i18next.t("commons.errors.communicationError"),
        ),
      );
    }
  };
}

export function reload() {
  return (dispatch, getState) => {
    return dispatch(fetchApplication(getState().application.id));
  };
}
