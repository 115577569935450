export default {
  SET_SAML_SETTINGS: 'preferences/security/saml_settings/SET_SAML_SETTINGS',
  SET_SAML_SETTING_DETAIL: 'preferences/security/saml_settings/SET_SAML_SETTING_DETAIL',
  SET_IN_PROGRESS: 'preferences/security/saml_settings/SET_IN_PROGRESS',
  FINISH_IN_PROGRESS: 'preferences/security/saml_settings/FINISH_IN_PROGRESS',
  TOGGLE_NEW_SAML_SETTING_MODAL: 'preferences/security/saml_settings/TOGGLE_NEW_SAML_SETTING_MODAL',
  TOGGLE_UPDATE_SAML_SETTING_MODAL: 'preferences/security/saml_settings/TOGGLE_UPDATE_SAML_SETTING_MODAL',
  TOGGLE_DESTROY_SAML_SETTING_MODAL: 'preferences/security/saml_settings/TOGGLE_DESTROY_SAML_SETTING_MODAL',
  TOGGLE_CREATE_UPDATE_IDP_SETTING_MODAL: 'preferences/security/saml_settings/TOGGLE_UPDATE_IdP_SETTING_MODAL',
  SET_IP_RESTRICTION_ENABLE: 'preferences/security/saml_settings/SET_IP_RESTRICTION_ENABLE',
} as const;
