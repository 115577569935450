import _ from "lodash";
import { TaxCategorySelection } from "utilities/api/models";
import { OperatorEntry } from "utilities/api_expenses/models/expenses/OperatorEntry";
import { AmountPerTaxCategory, TaxCategory } from "./interface";
import { calcTaxAmount, filterDefaultSelected } from "./utils";

/**
 * 金額によって税区分別金額を変更します。
 * @param values 現在の税区分別金額
 * @param amount 次の金額
 * @returns
 */
export const changeByAmount = (
  values: AmountPerTaxCategory[],
  amount: number,
): AmountPerTaxCategory[] => {
  if (isInputtedOnlyOne(values)) {
    return assignInputted(values, amount);
  }
  return assignFirst(values, amount);
};

/**
 * 新しい選択可能な税区分によって税区分別金額を変更します。
 * @param values 現在の税区分別金額
 * @param selects 次の選択可能な税区分
 * @param amount 現在の金額
 * @returns
 */
export const changeBySelectedTaxCategories = (
  expenseId: string | null,
  values: AmountPerTaxCategory[],
  selects: TaxCategory[],
  amount: number,
): AmountPerTaxCategory[] => {
  if (!isEqualTotal(values, amount)) {
    return changeByAmount(buildNewValues(expenseId, selects), amount);
  }
  if (areAllTaxCategoriesIncluded(values, selects)) {
    return assignEachTaxCategory(expenseId, values, selects);
  }
  if (areAllTaxRatesIncluded(values, selects)) {
    return assignEachTaxRate(expenseId, values, selects);
  }
  return assignFirst(buildNewValues(expenseId, selects), amount);
};

/**
 * 税区分別金額にオペレータ入力結果を設定します。
 * @param expenseId 経費ID
 * @param operatorEntry 経費の税区分別金額
 * @param selects 選択可能な税区分
 * @returns
 */
export const assignOperatorEntry = (
  expenseId: string | null,
  operatorEntry: OperatorEntry,
  selects: TaxCategory[],
): AmountPerTaxCategory[] => {
  const assigned: number[] = [];
  return selects.map((s) => {
    const subtotal = operatorEntry.subtotals.find(
      (o) => o.taxRate === s.taxRate,
    );
    if (subtotal && !assigned.includes(s.taxRate)) {
      assigned.push(s.taxRate);
      return {
        id: null,
        expenseId,
        taxCategory: s,
        amount: subtotal.amount,
        taxAmount: subtotal.taxAmount,
      };
    }
    return newValue(expenseId, s);
  });
};

/**
 * 税区分別金額一覧をゼロ埋めします。
 * @param expenseId 経費ID
 * @param allTaxCategories 税区分一覧
 * @param values 税区分別金額
 * @param asEligibleInvoice 適格請求書フラグ
 * @returns
 */
export const zeroPaddingValues = (
  expenseId: string | null,
  allTaxCategories: TaxCategorySelection[],
  values: AmountPerTaxCategory[],
  asEligibleInvoice: boolean,
): AmountPerTaxCategory[] => {
  const selects = filterDefaultSelected(allTaxCategories, asEligibleInvoice);
  return assignEachTaxCategory(expenseId, values, selects);
};

const isEqualTotal = (
  values: AmountPerTaxCategory[],
  amount: number,
): boolean => values.reduce((sum, v) => sum + Number(v.amount), 0) === amount;

export const operatorEntryInclucesTaxCategories = (
  operatorEntry: OperatorEntry,
  selects: TaxCategory[],
): boolean =>
  operatorEntry.subtotals.every((o) =>
    selects.some((s) => s.taxRate === o.taxRate),
  );

/**
 * 税区分別金額がひとつだけ入力されているかを返します。
 * @param values
 * @returns
 */
const isInputtedOnlyOne = (values: AmountPerTaxCategory[]): boolean => {
  return values.filter((o) => o.amount > 0).length === 1;
};

/**
 * 入力されている税区分別金額に金額に割り当てます。
 * @param values
 * @param amount
 * @returns
 */
const assignInputted = (
  values: AmountPerTaxCategory[],
  amount: number,
): AmountPerTaxCategory[] => {
  let assigned = false;

  return values.map((value) => {
    if (value.amount > 0 && !assigned) {
      assigned = true;
      return {
        ...value,
        amount,
        taxAmount: calcTaxAmount(amount, value.taxCategory.taxRate),
      };
    }
    return resetValue(value);
  });
};

/**
 * 最初の税区分別金額に金額を割り当て、消費税額を計算します。
 * @param taxCategories
 * @param amount
 * @returns
 */
const assignFirst = (
  values: AmountPerTaxCategory[],
  amount: number,
): AmountPerTaxCategory[] => {
  return values.map((value, i) => {
    if (i === 0) {
      return {
        ...value,
        amount,
        taxAmount: calcTaxAmount(amount, value.taxCategory.taxRate),
      };
    }
    return resetValue(value);
  });
};

/**
 * 税区分別金額の税区分別に金額を割り当てます。
 * @param values 現在の税区分別金額
 * @param selects 税区分
 * @returns
 */
const assignEachTaxCategory = (
  expenseId: string | null,
  values: AmountPerTaxCategory[],
  selects: TaxCategory[],
): AmountPerTaxCategory[] => {
  return selects.map((s) => {
    const value = values.find((v) => v.taxCategory.id === s.id);
    if (value) {
      return { ...value, taxCategory: s };
    }
    return newValue(expenseId, s);
  });
};

/**
 * 税区分別金額の税率別に金額を割り当てます。
 * @param values 現在の税区分別金額
 * @param selects 税区分
 * @returns
 */
const assignEachTaxRate = (
  expenseId: string | null,
  values: AmountPerTaxCategory[],
  selects: TaxCategory[],
): AmountPerTaxCategory[] => {
  const prevsByRate = _.groupBy(values, (v) => v.taxCategory.taxRate);
  const nextsByRate = _.groupBy(selects, (s) => s.taxRate);
  const inputtedPrevsByRate = _.groupBy(
    values.filter((v) => anyInputted(v)),
    (v) => v.taxCategory.taxRate,
  );

  const assigned: { [key: string]: AmountPerTaxCategory } = {};

  const rates = _.uniq(selects.map((s) => s.taxRate));

  rates.forEach((rate) => {
    const prevs = prevsByRate[rate];
    const nexts = nextsByRate[rate];
    const inputtedPrevs = inputtedPrevsByRate[rate];

    if (_.isEmpty(prevs)) {
      const nextValue = newValue(expenseId, nexts[0]);
      assigned[nexts[0].id] = nextValue;
    } else if (prevs.length <= nexts.length) {
      _.times(nexts.length, (index) => {
        const prev = prevs[index];
        const next = nexts[index];

        const nextValue = prev
          ? { ...prev, taxCategory: next }
          : newValue(expenseId, next);
        assigned[next.id] = nextValue;
      });
    } else if (inputtedPrevs && inputtedPrevs.length <= nexts.length) {
      _.times(nexts.length, (index) => {
        const prev = inputtedPrevs[index];
        const next = nexts[index];

        const nextValue = prev
          ? { ...prev, taxCategory: next }
          : newValue(expenseId, next);
        assigned[next.id] = nextValue;
      });
    } else {
      const totalAmount = prevs.reduce((sum, v) => sum + Number(v.amount), 0);
      const totalTaxAmount = prevs.reduce(
        (sum, v) => sum + Number(v.taxAmount),
        0,
      );

      _.times(nexts.length, (index) => {
        const next = nexts[index];

        if (index === 0) {
          assigned[next.id] = {
            id: null,
            expenseId,
            taxCategory: next,
            amount: totalAmount,
            taxAmount: totalTaxAmount,
          };
        } else {
          assigned[next.id] = newValue(expenseId, next);
        }
      });
    }
  });

  return selects.map((s) => assigned[s.id] || newValue(expenseId, s));
};

/**
 * 税区分別金額が入力されているかを返します。
 * @param value 税区分別金額
 * @returns
 */
const anyInputted = (value: AmountPerTaxCategory): boolean =>
  Number(value.amount) !== 0 || Number(value.taxAmount) !== 0;

/**
 * 空の税区分別金額を作成します。
 * @param value
 * @returns
 */
const resetValue = (value: AmountPerTaxCategory): AmountPerTaxCategory => {
  return {
    ...value,
    amount: 0,
    taxAmount: 0,
  };
};

/**
 * 新しい税区分別金額一覧を作成します。
 * @param expenseId
 * @param taxCategories
 * @returns
 */
const buildNewValues = (
  expenseId: string | null,
  taxCategories: TaxCategory[],
): AmountPerTaxCategory[] => {
  return taxCategories.map((t) => newValue(expenseId, t));
};

/**
 * 新しい税区分別金額を作成します。
 * @param expenseId
 * @param taxCategory
 * @returns
 */
const newValue = (
  expenseId: string | null,
  taxCategory: TaxCategory,
): AmountPerTaxCategory => {
  return {
    id: null,
    expenseId,
    taxCategory,
    amount: 0,
    taxAmount: 0,
  };
};

/**
 * 現在の税区分別金額の税区分が、次の税区分をすべて含んでいるかを返します。
 * @param values 現在の税区分別金額
 * @param selects 次の税区分
 * @returns
 */
const areAllTaxCategoriesIncluded = (
  values: AmountPerTaxCategory[],
  selects: TaxCategory[],
): boolean => {
  if (values.length === 0) {
    return false;
  }
  const currents = values.map((v) => v.taxCategory.id);
  const nexts = selects.map((t) => t.id);
  return currents.every((id) => nexts.includes(id));
};

/**
 * 現在の税区分別金額が、次の税区分の税率をすべて含んでいるかを返します。
 * @param values 現在の税区分別金額
 * @param selects 次の税区分
 * @returns
 */
const areAllTaxRatesIncluded = (
  values: AmountPerTaxCategory[],
  selects: TaxCategory[],
): boolean => {
  if (values.length === 0) {
    return false;
  }
  const currents = values
    .filter((v) => !!v.amount || !!v.taxAmount)
    .map((v) => v.taxCategory.taxRate);
  const nexts = selects.map((t) => t.taxRate);
  return currents.every((taxRate) => nexts.includes(taxRate));
};
