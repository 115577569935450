import Formatter from 'utilities/formatter';
import React, {
  useCallback, useMemo, useState,
} from 'react';
import fonts from 'values/fonts';
import i18next from 'i18next';
import { DataFetchingTable } from 'components/renewaled_ui/DataFetchingTable';
import { PreReport } from 'utilities/api/models';

interface Props {
  preReports: PreReport[];
  isFetching: boolean;
}

/** 申請可能事前申請選択 */
export const RequestablePreReportSelector: React.FC<Props> = ({ preReports, isFetching }) => {
  const [sizePerPage, setSizePerPage] = useState(30);
  const [currentPage, setCurrentPage] = useState(1);

  const preReportTableColumns = useMemo(() => [
    {
      accessor: 'id',
      Header: '',
      width: 0,
      defaultCanSort: false,
      selectable: false,
      show: false,
      index: null,
    },
    {
      accessor: 'title',
      Header: i18next.t('preReports.properties.title'),
      width: 300,
      minWidth: 10,
      defaultCanSort: false,
      selectable: false,
      show: true,
      index: null,
    },
    {
      accessor: 'project',
      Header: i18next.t('preReports.properties.project'),
      width: 200,
      minWidth: 10,
      defaultCanSort: false,
      selectable: false,
      show: true,
      index: null,
    },
    {
      accessor: 'department',
      Header: i18next.t('preReports.properties.department'),
      width: 200,
      minWidth: 10,
      defaultCanSort: false,
      selectable: false,
      show: true,
      index: null,
    },
    {
      accessor: 'pre_amount',
      Header: i18next.t('preReports.properties.preAmount'),
      width: 200,
      minWidth: 10,
      defaultCanSort: false,
      selectable: false,
      show: true,
      index: null,
    },
    {
      accessor: 'overall_status',
      Header: i18next.t('reports.properties.status'),
      width: 200,
      minWidth: 10,
      defaultCanSort: false,
      selectable: false,
      show: true,
      index: null,
    },
    {
      accessor: 'sequence',
      Header: i18next.t('preReports.properties.sequence'),
      width: 300,
      minWidth: 10,
      defaultCanSort: false,
      selectable: false,
      show: true,
      index: null,
    },
  ], []);

  /** テーブルボディの表示形式 */
  const formatPreReportTableBodyCell = (preReport: PreReport, key: string): JSX.Element | string | boolean => {
    switch (key) {
      case 'title':
        return preReport.title;
      case 'id':
        return preReport.id;
      case 'sequence':
        return preReport.sequence;
      case 'project':
        return Formatter.project(preReport.project, null, null, ['name', 'displayId'], true);
      case 'department':
        return Formatter.text(preReport.department?.name, null, null, true);
      case 'pre_amount':
        return Formatter.amount(preReport.preAmount);
      case 'overall_status':
        return preReport.overallStatus.status;
      default:
        return '';
    }
  };

  /** DataFetchTableに渡すデータ型に成型 */
  const formatPreReportDataFetchTableData = useCallback((): PreReport[] => {
    const expenseTableDataInput = preReports.map((preReport) => {
      if (!preReport) return undefined;

      const data = {};
      preReportTableColumns.forEach((column) => {
        const key = column.accessor as string;
        data[key] = formatPreReportTableBodyCell(preReport, key);
      });

      return data;
    });

    return expenseTableDataInput as PreReport[];
  }, [preReportTableColumns, preReports]);

  return (
    <DataFetchingTable
      columns={ preReportTableColumns.filter((column) => column.show) }
      data={ formatPreReportDataFetchTableData() }
      currentPage={ currentPage }
      sizePerPage={ sizePerPage }
      sizePerPageList={ [10, 30, 50] }
      onPageChange={ (page): void => { setCurrentPage(page); } }
      onSizePerPageList={ setSizePerPage }
      showCheckBoxes={ false }
      href='/pre_reports'
      bodyFontWeight={ Number(fonts.weight.bold) as 500 | 600 }
      maxHeight={ '100px' }
      columnResizable
      isLoading={ isFetching }
      showItemsCount={ false }
    />
  );
};
