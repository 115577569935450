import { ExpenseReadOnlyModal } from "applications/expenses/history/components/ExpenseReadOnlyModal";
import i18next from "i18n";
import React, { useCallback, useState } from "react";
import styled from "styled-components";
import formatter from "utilities/formatter";

export interface Props {
  /** 経費分割イベント */
  splitEvent: SplitEvent;
}

/** 経費分割イベント */
interface SplitEvent {
  /** 分割元経費 */
  source: Expense;
  /** 分割先経費 */
  destinations: Expense[];
}

/** 分割元または分割先経費の情報 */
interface Expense {
  id: string;
  amount: number;
  /** 何で分割したか */
  separatedBy: SeparatedBy;
  /** 申請に含まれる経費か */
  attached?: boolean;
}

/** 何で分割したか */
interface SeparatedBy {
  /** 分割項目種別 */
  type: "Category" | "Project";
  /** 分割項目のID */
  id: string;
  /** 分割項目の名称 */
  name: string;
}

/** 分割元・分割先経費1件の項目表示用テーブル */
const BorderlessTable = styled.table`
  background: transparent;
  width: 100%;

  th,
  td {
    padding: 8px;
  }
  tbody > tr:hover {
    background: transparent;
  }
`;

/** 分割元・分割先経費1件の枠 */
const RoundRectView = styled.div`
  border-radius: 4px;
  border: solid 1px #ccc;

  > div {
    padding: 8px;
  }

  &:hover {
    cursor: pointer;
    background: #cceaf7;
  }
`;

/** 分割履歴の変更内容表示レイアウト */
const ContentView = styled.div`
  > div {
    display: flex;

    > div {
      flex: 1;
    }
    &:last-child {
      align-self: "stretch";

      > div {
        padding: 4px;

        &:first-child > div {
          height: 100%;
        }
        &:last-child > div:not(:first-child) {
          margin-top: 4px;
        }
      }
    }
    &:first-child > div {
      padding-left: 4px;
      font-weight: 600;
    }
  }
`;

/** 申請に含まれる経費であることの表示 */
const AttachedLabel = styled.div`
  font-size: 12px;
  color: #555;
  border-bottom: solid 1px #ccc;
  padding: 8px;
`;

/**
 * 申請に含まれる経費であることを表示します
 * @returns 申請経費表示欄
 */
const renderAttachedLabel = (): JSX.Element => {
  return (
    <AttachedLabel>
      <i className="fas fa-paperclip" />{" "}
      {i18next.t("transactions.histories.properties.split.attached")}
    </AttachedLabel>
  );
};

/**
 * 何で分割したかを文字列で返します。
 * @param separatedBy 分割項目
 * @returns 項目名
 */
const getKeyName = (separatedBy: SeparatedBy): string => {
  switch (separatedBy.type) {
    case "Category":
      return i18next.t("transactions.properties.category");
    case "Project":
      return i18next.t("transactions.properties.project");
    default:
      return "";
  }
};

/**
 * 経費を1つ描画します。
 * @param expense 分割元または分割先の経費
 * @param onExpenseClick クリックしたとき
 * @returns 経費表示欄
 */
const renderExpense = (expense: Expense, onExpenseClick): JSX.Element => {
  const value = expense.separatedBy.name;

  return (
    <RoundRectView
      onClick={(): void => {
        onExpenseClick(expense.id);
      }}
      key={`split-expense-table-${expense.id}`}
    >
      {expense.attached && renderAttachedLabel()}
      <div>
        <BorderlessTable>
          <tbody>
            <tr>
              <th>{getKeyName(expense.separatedBy)}</th>
              <td>
                {formatter.text(value, i18next.t("commons.actions.unset"))}
              </td>
            </tr>
            <tr>
              <th>{i18next.t("transactions.histories.fieldTypes.amount")}</th>
              <td>{formatter.amount(expense.amount)}</td>
            </tr>
          </tbody>
        </BorderlessTable>
      </div>
    </RoundRectView>
  );
};

/**
 * 分割先経費を描画します。
 * @param destinations 分割先経費の一覧
 * @param onExpenseClick クリックしたとき
 * @returns 分割先経費表示欄
 */
const renderDestinations = (
  destinations: Expense[],
  onExpenseClick,
): JSX.Element => {
  return <>{destinations.map((dest) => renderExpense(dest, onExpenseClick))}</>;
};

export const SplitEventLog: React.FC<Props> = ({ splitEvent }) => {
  const [showExpenseModal, setShowExpenseModal] = useState<boolean>(false);
  const [expenseId, setExpenseId] = useState<string | null>(null);
  const handleCloseExpenseModal = useCallback(() => {
    setShowExpenseModal(false);
    setExpenseId(null);
  }, [setShowExpenseModal]);
  const handleExpenseClick = useCallback(
    (id: string) => {
      setExpenseId(id);
      setShowExpenseModal(true);
    },
    [setExpenseId, setShowExpenseModal],
  );

  return (
    <>
      <ContentView>
        <div>
          <div>
            {i18next.t("transactions.histories.properties.split.source")}
          </div>
          <div>
            {i18next.t("transactions.histories.properties.split.destination")}
          </div>
        </div>
        <div>
          <div>{renderExpense(splitEvent.source, handleExpenseClick)}</div>
          <div>
            {renderDestinations(splitEvent.destinations, handleExpenseClick)}
          </div>
        </div>
      </ContentView>
      {expenseId && (
        <ExpenseReadOnlyModal
          show={showExpenseModal}
          closeModal={handleCloseExpenseModal}
          expenseId={expenseId}
        />
      )}
    </>
  );
};
