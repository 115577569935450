import * as ActionTypes from '../actions/analysisReportExportData';

const initialState = {
  aggregationByUserJobs: [],
  aggregationByCategoryJobs: [],
  analysisReportXlsJobs: [],
  accountingJobs: [],
};

const analysisReportExportData = (state = initialState, action) => {
  switch (action.type) {
    case ActionTypes.SET_AGGREGATION_BY_USER_JOBS: {
      return {
        ...state,
        aggregationByUserJobs: action.aggregationByUserJobs,
      };
    }
    case ActionTypes.SET_AGGREGATION_BY_CATEGORY_JOBS: {
      return {
        ...state,
        aggregationByCategoryJobs: action.aggregationByCategoryJobs,
      };
    }
    case ActionTypes.SET_ANALYSIS_REPORT_XLS_JOBS: {
      return {
        ...state,
        analysisReportXlsJobs: action.analysisReportXlsJobs,
      };
    }
    case ActionTypes.SET_ACCOUNTING_JOBS: {
      return {
        ...state,
        accountingJobs: action.accountingJobs,
      };
    }
    default:
      return state;
  }
};

export const initialAnalysisReportExportData = initialState;
export default analysisReportExportData;
