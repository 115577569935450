import Api  from 'utilities/api';
import React, { PureComponent } from 'react';
import SuggestField from 'components/fields/SuggestField';
import formatter from 'utilities/formatter';
import {
  Bank,
  BankBranch,
  BankBranches,
  CommonProps,
} from './types';
import { fetchAsync } from 'utilities/async';

interface Props extends CommonProps<BankBranch> {
  bank: Bank | null;
  disabled?: boolean;
}

interface State {
  branches: BankBranches;
}

export default class BankBranchSuggestField extends PureComponent<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = { branches: [] };

    this.renderOption = this.renderOption.bind(this);
    this.initializeOptions = this.initializeOptions.bind(this);
  }

  componentDidMount(): void {
    const id = this.props.bank && this.props.bank.id ? this.props.bank.id : null;
    if (id) {
      this.initializeOptions().then((branches: BankBranches) => this.setState({ branches }));
    }
  }

  componentDidUpdate(prevProps: Props): void {
    const id = this.props.bank && this.props.bank.id ? this.props.bank.id : null;
    const prevId = prevProps.bank && prevProps.bank.id ? prevProps.bank.id : null;
    // 銀行情報が変わったタイミングで取り直す
    if (id && id !== prevId) {
      this.initializeOptions().then((branches: BankBranches) => this.setState({ branches }));
    }
  }

  initializeOptions(): Promise<BankBranches> {
    return fetchAsync(Api.banks.branches, { bankName: this.props.bank && this.props.bank.name })
      .then((data) => {
        return data.branches.map((branch: BankBranch) => ({
          id: branch.id,
          name: branch.name,
        }));
      });
  }

  getOptionText(branch: BankBranch | null): string {
    return branch ? branch.name : '';
  }

  renderOption(branch: BankBranch | null): JSX.Element {
    return formatter.text(branch && branch.name);
  }

  render(): JSX.Element {
    return (
      <SuggestField key={ this.props.bank && this.props.bank.id ? this.props.bank.id : '' }
        async={ false }
        text={ this.props.text }
        initialOptions={ this.state.branches }
        getOptionText={ this.getOptionText }
        renderOption={ this.renderOption }
        onSelect={ this.props.onSelect }
        onTextChange={ this.props.onTextChange }
        disabled={ this.props.disabled }
     />
    );
  }
}
