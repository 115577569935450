import { Department, DepartmentChoice } from 'utilities/api/responses/departments/IndexResponse';

/**
 * V1 Groups controller index は, 一般的な部署表示に不要な情報があり, パフォーマンスが悪い
 * そのため, V1 Groups controller index から V1 Departments controller index への置き換えが必要になる
 *
 * しかし, Response の構造が違うために, 単に API を置き換えただけでは正常に動作しない
 *
 * この Builder では, この単に置き換えた場合に発生する Response の構造差異を吸収する
 *
 * @param {Array<Department>} response V1 Departments controller index の departments
 * @returns {Array<DepartmentChoice>} department の表示に必要な親情報を解決したオブジェクト
 */
export function toDepartmentChoices(response: Array<Department>): Array<DepartmentChoice> {
  const parentMap = {};
  response.forEach((dept) => {
    parentMap[dept.id] = dept;
  });

  const departments: Array<DepartmentChoice> = response.map(
    (department) => {
      const parent = department.parentId ? parentMap[department.parentId] : undefined;

      return {
        id: department.id,
        level: department.level,
        name: department.name,
        parent: parent ? {
          id: parent.id,
          name: parent.name,
        } : null,
      };
    },
  );

  return departments;
}
