import { NeighborLinks } from "applications/requests/components/NeighborLinks";
import BootstrapCard from "components/BootstrapCard";
import { FormElement } from "components/FormElement";
import React from "react";
import { Form } from "../../request_types/components/Form";
import { FormItemModel } from "../../request_types/Types";
import { ApproverButtons, RequesterButtons } from "./Buttons";
import { SequenceIdField, TitleField } from "./Fields";

interface Actions {
  approve: () => Promise<void>;
  fetchRequest: () => Promise<void>;
  openCancelApprovalModal: () => void;
  reapply: () => Promise<void>;
  recall: () => Promise<void>;
  reject: () => Promise<void>;
  showRejectModal: () => void;
}

interface Props {
  actions: Actions;
  application; // TODO: actionがTS化されたら型定義する
  cardTitle: string;
  editable: boolean;
  forApprover: boolean;
  formItems: FormItemModel[];
  onChange: (index: number, value: string[]) => void;
  onTitleChange: (value: string) => void;
  sequence: string;
  title: string;
  titleEditable: boolean;
  className?: string;
}

/** 汎用申請の入力フォーム */
export const ApplicationFormCard: React.FC<Props> = React.memo(
  ({
    actions,
    application,
    cardTitle,
    forApprover,
    onTitleChange,
    sequence,
    title,
    titleEditable,
    editable,
    formItems,
    onChange,
    className = "",
  }) => {
    return (
      <BootstrapCard title={cardTitle} className={className}>
        <div className="form-horizontal">
          <FormElement label="申請名">
            <TitleField
              title={title}
              disabled={!titleEditable}
              onChange={onTitleChange}
            />
          </FormElement>
          {!titleEditable && (
            <FormElement label="申請ID">
              <SequenceIdField sequence={sequence} />
            </FormElement>
          )}
          <Form editable={editable} formItems={formItems} onChange={onChange} />
        </div>
        <div className="row">
          <div className="col-md-8 col-md-offset-2">
            {forApprover ? (
              <ApproverButtons actions={actions} application={application} />
            ) : (
              <RequesterButtons actions={actions} application={application} />
            )}
          </div>
        </div>
        <div className="row">
          <NeighborLinks currentId={application.id} />
        </div>
      </BootstrapCard>
    );
  },
);
