import PropTypes from 'prop-types';
import React from 'react';

const MessageBox = (props) => {
  return (
    <div className='message-box'>
      <div className={ `alert alert-${props.type} ${props.dismissable ? 'alert-dismissable' : ''}` }
        style={ { marginBottom: '8px' } }
        role='alert'
      >
        {
          props.dismissable ? (
            <a href='#' className='close' data-dismiss='alert' aria-label='close' title='close'>×</a>
          ) : null
        }
        {
          props.title ? (
            <div className='msg-header'>
              <strong className='txt txt-lg'>{ props.title }</strong>
            </div>
          ) : null
        }
        {
          props.messages.length > 0 ? (
            <div className='msg-content'>
              <ul style={ { 'list-style': props.listStyle, 'padding-left': props.listStyle === 'none' ? '20px' : '40px' } }>
                { props.messages.map((x, index) => (<li key={ `${props.key}-message-${index}` }><p>{ x }</p></li>)) }
              </ul>
            </div>
          ) : null
        }
        { props.children }
      </div>
    </div>
  );
};

MessageBox.defaultProps = {
  type: 'info',
  dismissable: false, // trueの時、閉じるボタンが表示される
  key: '',
  messages: [],
  listStyle: 'disc',
};

MessageBox.propTypes = {
  type: PropTypes.oneOf(['warning', 'error', 'success', 'info']).isRequired,
  dismissable: PropTypes.bool.isRequired,
  key: PropTypes.string,
  title: PropTypes.string,
  messages: PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.string, PropTypes.element])).isRequired,
  listStyle: PropTypes.oneOf(['disc', 'circle', 'square', 'none']),
};

export default MessageBox;
