import { NeighborLinks } from "applications/requests/components/NeighborLinks";
import * as requestFormPolicy from "applications/transactions/utilities/transactionFormPolicy";
import BootstrapCard from "components/BootstrapCard";
import DateInput from "components/date_input";
import ProjectField from "components/fields/ProjectField";
import SelectField from "components/fields/SelectField";
import CheckBox from "components/NoStateCheckBox";
import TextArea from "components/TextArea";
import TimeSelector from "components/TimeSelector";
import i18next from "i18n";
import _ from "lodash";
import isNil from "lodash/isNil";
import moment from "moment";
import qs from "qs";
import React, { ReactElement } from "react";
import Formatter from "utilities/formatter";
import { renderFormElement } from "utilities/renderer";
import CustomInputField from "../containers/CustomInputFIeld";
import {
  Expense,
  FormField,
  FormFieldOption,
  FormValue,
  FormValueDepartment,
  FormValueProject,
  FormValues,
  Listeners,
  PreReport,
  ReactBootstrapTableButton,
} from "../interface";

interface Props {
  /** 操作者の権限 */
  readonly authority: string;
  /** ボタンのリスト */
  readonly buttons: ReactBootstrapTableButton[] | null;
  /** クラス名 */
  readonly className?: string;
  /** 事前申請の編集可否 */
  readonly editable: boolean;
  /** EntryForm */
  readonly fields: FormField[];
  /** フィールドの値 */
  readonly formValues: FormValues;
  /** 編集中かどうか */
  readonly isEditing: boolean;
  /** フィールドのリスナー */
  readonly listeners: Listeners;
  /** 所有者 */
  readonly ownerId: string;
  /** 事前申請 */
  readonly preReport: PreReport;
  /** 事前申請のTypeを変更可能か */
  readonly preReportTypeChangable: boolean;
  /** ステータス */
  readonly status: string;
  /** 事前申請の更新 */
  readonly onUpdate: (
    preReport: PreReport,
    reload: boolean,
    callback: (edit: boolean) => void,
  ) => void;
  /** 事前申請のリロード */
  readonly reload: () => void;
}

/**
 * 事前申請のフォーム
 */
export const PreReportFormCard: React.FC<Props> = (props) => {
  /**
   * EntryForm のフィールドを配列で生成する
   * @returns {React.ReactNode[]}
   */
  const buildEntryForms = (): React.ReactNode[] => {
    const { authority, preReport, fields, listeners } = props;

    const forApprover = authority === "approver";
    const readableFields = _.sortBy(
      fields.filter((f) => canRead(f)),
      ["sort"],
    );
    const formValues = _.get(preReport, "formValues", {});

    const entryForms = readableFields.map((field) => {
      if (_.isNil(field.id)) {
        switch (field.type) {
          case "department_input":
            return renderForm(
              field.label,
              renderDepartmentInput(
                preReport,
                formValues[field.type],
                listeners.onDepartmentChange,
              ),
              field.sort,
            );
          case "text_input":
            return renderForm(
              field.label,
              renderInput(formValues[field.type], listeners.onTitleChange),
              field.sort,
            );
          case "project_input":
            return renderForm(
              field.label,
              renderProjectInput(
                formValues[field.type],
                listeners.onProjectChange,
              ),
              field.sort,
            );
          case "pre_report_summary_input":
            return !props.isEditing ? (
              <div key={field.sort}>
                {renderId(preReport)}
                {renderAmount(preReport)}
                {renderWithholding(preReport)}
                {renderPreAmount(preReport)}
                {renderReimbursableAmount(preReport)}
              </div>
            ) : null;
          case "multi_line_text_input":
            return renderForm(
              field.label,
              renderTextArea(
                formValues[field.type],
                listeners.onDescriptionChange,
              ),
              field.sort,
            );
          case "travel_check_input":
            return !forApprover && field.editable
              ? renderForm(
                  field.label,
                  renderCheck(formValues[field.type], (b) =>
                    listeners.onTypeChange(b),
                  ),
                  field.sort,
                )
              : null;
          case "travel_destination_input":
            return renderForm(
              field.label,
              renderInput(
                formValues[field.type],
                listeners.onDestinationChange,
              ),
              field.sort,
            );
          case "travel_start_at_input":
            return renderForm(
              field.label,
              renderDateInput(
                formValues[field.type],
                listeners.onStartAtChange,
                { showTimeSelect: true },
              ),
              field.sort,
            );
          case "travel_end_at_input":
            return renderForm(
              field.label,
              renderDateInput(formValues[field.type], listeners.onEndAtChange, {
                showTimeSelect: true,
              }),
              field.sort,
            );
          case "temporary_payment_check_input":
            return !forApprover && field.editable
              ? renderForm(
                  field.label,
                  renderCheck(
                    formValues[field.type],
                    listeners.onNeedsTemporaryPaymentChange,
                  ),
                  field.sort,
                )
              : null;
          case "temporary_payment_due_at_input":
            return renderForm(
              field.label,
              renderDateInput(
                formValues[field.type],
                listeners.onTemporaryPaymentDueAtChange,
              ),
              field.sort,
            );
          case "temporary_payment_reason_input":
            return renderForm(
              field.label,
              renderTextArea(
                formValues[field.type],
                listeners.onTemporaryPaymentReasonChange,
              ),
              field.sort,
            );
          case "temporary_payment_info_input": {
            if (preReport.temporaryPayment) {
              const { temporaryPayment } = preReport;
              const { paidAt, refundAmount, refundedAt } = temporaryPayment;
              return (
                <div key={field.sort}>
                  {paidAt
                    ? renderForm(
                        i18next.t("temporaryPayments.properties.paymentDate"),
                        renderInput(Formatter.datetime(paidAt), null, "", true),
                        field.sort,
                      )
                    : null}
                  {refundAmount
                    ? renderForm(
                        i18next.t("temporaryPayments.properties.refundAmount"),
                        renderInput(
                          Formatter.amount(refundAmount),
                          null,
                          "",
                          true,
                        ),
                        field.sort,
                      )
                    : null}
                  {refundedAt
                    ? renderForm(
                        i18next.t("temporaryPayments.properties.refundDate"),
                        renderInput(
                          Formatter.datetime(refundedAt),
                          null,
                          "",
                          true,
                        ),
                        field.sort,
                      )
                    : null}
                </div>
              );
            }
            return null;
          }
          default:
            return null;
        }
      }

      return renderForm(
        field.label,
        renderMetadataInput(
          field,
          formValues[field.id] || "",
          listeners.setMetadata,
        ),
        field.sort,
      );
    });

    return Array.prototype.concat.apply([], entryForms);
  };

  /**
   * 精算申請であるか
   * （事前申請として開いている場合は false を返す）
   * @returns {boolean}
   */
  const isSettlement = (): boolean => {
    // 経費精算・精算申請の場合は、URLに ?request_type=report が付与される
    // TODO: Props として渡す
    const searchQuery = window.location.search;
    if (searchQuery.length === 0) return false;

    const searchCondition = qs.parse(searchQuery, { ignoreQueryPrefix: true });
    const requestType = searchCondition.request_type;

    return props.preReport.report && requestType === "report";
  };

  /**
   * レンダリング
   * @returns {ReactElement}
   */
  const render = (): ReactElement => {
    const { authority, preReport, isEditing, className = "" } = props;

    let headerButtons: ReactBootstrapTableButton[] = [];
    let contentButtons = props.buttons;
    if (authority === "approver" && props.editable) {
      headerButtons = isEditing
        ? [
            {
              color: "default",
              content: i18next.t("commons.actions.cancel"),
              onClick: props.reload.bind(null),
            },
            {
              color: "primary",
              icons: [
                { position: "right", className: "fa-save", style: "regular" },
              ],
              content: i18next.t("commons.actions.save"),
              onClick: props.onUpdate.bind(
                null,
                preReport,
                true,
                props.listeners.onSwitchContentEdit.bind(null, false),
              ),
            },
          ]
        : [
            {
              color: "primary",
              icons: [
                {
                  position: "right",
                  className: "fa-pencil-alt",
                  style: "solid",
                },
              ],
              content: i18next.t("commons.actions.edit"),
              onClick: props.listeners.onSwitchContentEdit.bind(null, true),
            },
          ];

      if (isEditing) {
        contentButtons = null;
      }
    }

    return (
      <BootstrapCard
        title={i18next.t("preReports.properties.content")}
        buttons={headerButtons}
        className={className}
      >
        <div className="form-horizontal">
          {buildEntryForms()}
          {preReport.editable ? null : renderStatus(preReport)}
          <div className="row">{contentButtons}</div>
          <div className="row">
            <NeighborLinks
              currentId={isSettlement() ? preReport.report.id : preReport.id}
            />
          </div>
        </div>
      </BootstrapCard>
    );
  };

  /**
   * 部署入力をレンダリングする
   * @param preReport 事前申請
   * @param formValue 部署入力の値
   * @param onDepartmentChange 部署入力の値変更時のリスナー
   * @returns {React.ReactNode}
   */
  const renderDepartmentInput = (
    preReport: PreReport,
    formValue: FormValueDepartment,
    onDepartmentChange: (d: FormValueDepartment) => void,
  ): React.ReactNode => {
    return (
      <div>
        {preReport.isNew ? (
          <SelectField
            value={formValue}
            options={userPreferences.departments}
            getOptionValue={(x): FormValueDepartment => x}
            getOptionLabel={(x): string => x.name}
            onChange={(option: FormValueDepartment): void =>
              option && onDepartmentChange(option)
            }
            isClearable={false}
          />
        ) : (
          <div className="form-control-static" style={{ paddingLeft: "10px" }}>
            {Formatter.text(_.get(formValue, "name"))}
          </div>
        )}
      </div>
    );
  };

  /**
   * 総額をレンダリングする
   * @param preReport 事前申請
   * @returns {React.ReactNode}
   */
  const renderAmount = (preReport: PreReport): React.ReactNode => {
    if (preReport.report) {
      // 文字列を下揃えするために、marginを調整
      return renderForm(
        i18next.t("preReports.properties.amount"),
        <div
          className="form-control-static"
          style={{ paddingLeft: "10px", marginTop: "-4px" }}
        >
          {Formatter.amount(preReport.amount)}
        </div>,
        `${preReport.id}amount`,
      );
    }

    return null;
  };

  /**
   * 源泉徴収税総額をレンダリングする
   * @param preReport 事前申請
   * @returns {React.ReactNode}
   */
  const renderWithholding = (preReport: PreReport): React.ReactNode => {
    const requiresWithholding = preReport.transactions
      .filter((t): Expense => t)
      .some((t): boolean => t.categoryRequiresWithholding);

    if (preReport.report && requiresWithholding) {
      // 文字列を下揃えするために、marginを調整
      return renderForm(
        i18next.t("preReports.properties.withholding"),
        <div
          className="form-control-static"
          style={{ paddingLeft: "10px", marginTop: "-4px" }}
        >
          {Formatter.amount(preReport.withholding)}
        </div>,
        `${preReport.id}withholding`,
      );
    }

    return null;
  };

  /**
   * 事前申請額をレンダリングする
   * @param preReport 事前申請
   * @returns {React.ReactNode}
   */
  const renderPreAmount = (preReport: PreReport): React.ReactNode => {
    // 精算前（事前申請中）の段階では、事前申請の額を表示する
    return renderForm(
      i18next.t("preReports.properties.preAmount"),
      <div className="form-control-static" style={{ paddingLeft: "10px" }}>
        {Formatter.amount(preReport.preAmount)}
      </div>,
      `${preReport.id}preAmount`,
    );
  };

  /**
   * 立替精算額をレンダリングする
   * @param preReport 事前申請
   * @returns {React.ReactNode}
   */
  const renderReimbursableAmount = (preReport: PreReport): React.ReactNode => {
    if (preReport.transactions.some((x) => x.isCorporate)) {
      // 文字列を下揃えするために、marginを調整
      return renderForm(
        i18next.t("reports.properties.reimbursableExpense"),
        <div className="form-control-static" style={{ paddingLeft: "10px" }}>
          {Formatter.amount(
            preReport.transactions
              .filter((x): boolean => !x.isCorporate)
              .reduce((acc, x): number => acc + x.amount, 0),
          )}
        </div>,
        "reimbursableAmount",
      );
    }

    return null;
  };

  /**
   * 申請IDをレンダリングする
   * @param preReport 事前申請
   * @returns {React.ReactNode}
   */
  const renderId = (preReport: PreReport): React.ReactNode => {
    return (
      <div>
        <hr />
        {renderForm(
          i18next.t("preReports.properties.sequence"),
          renderInput(preReport.sequence, null, "", true),
          `${preReport.id}sequence`,
        )}
      </div>
    );
  };

  /**
   * ステータスをレンダリングする
   * @param preReport 事前申請
   * @returns {React.ReactNode}
   */
  const renderStatus = (preReport: PreReport): React.ReactNode => {
    return (
      <div>
        <hr />
        {renderForm(
          i18next.t("preReports.properties.status"),
          renderInput(preReport.overallStatus.label, null, "", true),
          `${preReport.id}status`,
        )}
      </div>
    );
  };

  /**
   * プロジェクト入力をレンダリングする
   * @param value プロジェクト入力の値
   * @param listener プロジェクト入力の値変更時のリスナー
   * @returns {React.ReactNode}
   */
  const renderProjectInput = (
    value: FormValueProject,
    listener: (v: FormValueProject) => void,
  ): React.ReactNode => {
    return props.isEditing ? (
      <ProjectField
        isStatic={!props.isEditing}
        value={value}
        onSelect={listener}
        ownerId={isNil(props.ownerId) ? null : props.ownerId}
      />
    ) : (
      renderInput(
        value
          ? `${value.name}(${value.displayId})`
          : i18next.t("commons.status.notEntered"),
        null,
        "",
        true,
      )
    );
  };

  /**
   * 日付入力(時間選択)をレンダリングする
   * @param value 日付入力の値
   * @param listener 日付入力の値変更時のリスナー
   * @param showTimeSelect 時間選択を表示するかどうか
   * @param className 日付入力のクラス名
   * @returns {React.ReactNode}
   */
  const renderDateInput = (
    value: Date | null,
    listener: (d: string) => void,
    { showTimeSelect = false, className = "" } = {},
  ): React.ReactNode => {
    const dateTime = value ? moment(value) : null;

    const onChange = listener;
    const onHourChange = (hour): void => {
      const dt = dateTime || moment().hour(0).minute(0).second(0);
      onChange(dt.hour(hour).format());
    };
    const onMinuteChange = (minute): void => {
      const dt = dateTime || moment().hour(0).minute(0).second(0);
      onChange(dt.minute(minute).format());
    };

    const editableDateInput = (): React.ReactNode => (
      <div>
        <DateInput
          className={`col-sm-5 ${className}`}
          style={{ paddingLeft: "0px", paddingRight: "0px" }}
          inputClassName={`form-control toggle ${className}`}
          value={value}
          onChange={listener}
          withDayOfTheWeek={true}
        />
        {showTimeSelect ? (
          <TimeSelector
            style={{ paddingRight: "0px" }}
            className={`col-sm-7 ${className}`}
            hour={dateTime ? dateTime.hour() : null}
            onHourChange={onHourChange}
            minute={dateTime ? dateTime.minute() : null}
            onMinuteChange={onMinuteChange}
          />
        ) : null}
      </div>
    );
    const staticDateInput = (): React.ReactNode => (
      <div className="form-control-static" style={{ paddingLeft: "12px" }}>
        <DateInput disabled={true} value={value} withDayOfTheWeek={true} />
        {showTimeSelect ? (
          <TimeSelector
            style={{ paddingLeft: "8px" }}
            disabled={true}
            hour={dateTime ? dateTime.hour() : null}
            minute={dateTime ? dateTime.minute() : null}
          />
        ) : null}
      </div>
    );

    return props.isEditing ? editableDateInput() : staticDateInput();
  };

  /**
   * テキストエリアをレンダリングする
   * @param value テキストエリアの値
   * @param listener テキストエリアの値変更時のリスナー
   * @param className テキストエリアのクラス名
   * @returns {React.ReactNode}
   */
  const renderTextArea = (
    value: string | null,
    listener: (v: string) => void,
    className = "",
  ): React.ReactNode => {
    return (
      <TextArea
        className={`form-control toggle ${className}`}
        value={value || ""}
        onChange={(e): void => listener(e.target.value)}
        maxRows={30}
        disabled={!props.isEditing}
      />
    );
  };

  /**
   * テキスト入力をレンダリングする
   * @param value テキスト入力の値
   * @param listener テキスト入力の値変更時のリスナー
   * @param className テキスト入力のクラス名
   * @param disabled テキスト入力の編集可否
   * @returns {React.ReactNode}
   */
  const renderInput = (
    value: string | null,
    listener: ((v: string) => void) | null,
    className = "",
    disabled = !props.isEditing,
  ): React.ReactNode => {
    return (
      <input
        type="text"
        className={`form-control toggle ${className}`}
        value={isNil(value) ? "" : value}
        onChange={listener ? (e): void => listener(e.target.value) : undefined}
        disabled={disabled}
      />
    );
  };

  /**
   * チェックボックスをレンダリングする
   * @param value チェックボックスの値
   * @param listener チェックボックスの値変更時のリスナー
   * @param disabled チェックボックスの編集可否
   * @returns {React.ReactNode}
   */
  const renderCheck = (
    value: boolean,
    listener: (v: boolean) => void,
    disabled = !props.isEditing,
  ): React.ReactNode => {
    return (
      <CheckBox
        className="no-margin"
        checked={value}
        onChange={(e): void => listener(e.target.checked)}
        disabled={disabled}
      />
    );
  };

  /**
   * 付加情報のフィールドをレンダリングする(アマナ環境のみ)(読取専用)
   * @param field フィールド
   * @param formValue フィールドの値
   * @returns {React.ReactNode}
   * @note Amana環境でのみ使用している。
   */
  const renderStaticMetadataInput = (
    field: FormField,
    formValue: string,
  ): React.ReactNode => {
    const className = "form-control toggle";

    if (field.type === "multi_line_text_input") {
      return (
        <TextArea className={className} disabled={true} value={formValue} />
      );
    }

    if (field.type === "check_input") {
      const value = formValue
        ? i18next.t("commons.actions.yes")
        : i18next.t("commons.actions.no");
      return (
        <input
          type="text"
          className={className}
          value={value}
          disabled={true}
        />
      );
    }

    return (
      <input
        type="text"
        className={className}
        value={formValue}
        disabled={true}
      />
    );
  };

  /**
   * 付加情報のフィールドをレンダリングする(アマナ環境のみ)
   * @param field フィールド
   * @param formValue フィールドの値
   * @param listener フィールドの値変更時のリスナー
   * @param disabled フィールドの編集可否
   * @returns {React.ReactNode}
   */
  const renderMetadataInput = (
    field: FormField,
    formValue: FormValue,
    listener: (id: string, v: string | boolean | null) => void,
    disabled = !props.isEditing,
  ): React.ReactNode => {
    if (
      ![
        "text_input",
        "number_input",
        "multi_line_text_input",
        "select_input",
        "check_input",
        "date_input",
      ].includes(field.type)
    ) {
      return (
        <CustomInputField key={field.sort} field={field} disabled={disabled} />
      );
    }

    if (!field.editable || disabled) {
      return renderStaticMetadataInput(field, formValue as string);
    }

    switch (field.type) {
      case "text_input":
        return (
          <input
            type="text"
            className="form-control toggle"
            value={formValue as string}
            onChange={(e): void => listener(field.id, e.target.value)}
          />
        );
      case "number_input":
        return (
          <input
            type="number"
            className="form-control"
            step={1}
            value={formValue as number}
            onChange={(e): void => listener(field.id, e.target.value)}
          />
        );
      case "multi_line_text_input":
        return (
          <textarea
            className="form-control"
            value={formValue as string}
            onChange={(e): void => listener(field.id, e.target.value)}
          />
        );
      case "select_input":
        return (
          <SelectField
            value={formValue ? { value: formValue } : null}
            options={field.options.map((c): { value: FormValue } => ({
              value: c.value,
            }))}
            getOptionLabel={(x: FormFieldOption): string => x.value}
            onChange={(c): void => listener(field.id, _.get(c, "value", null))}
          />
        );
      case "check_input":
        return (
          <CheckBox
            className="no-margin"
            checked={formValue}
            onChange={(e): void => listener(field.id, e.target.checked)}
          />
        );
      case "date_input":
        return (
          <div>
            <DateInput
              className="col-md-5"
              style={{ paddingLeft: "0px", paddingRight: "0px" }}
              inputClassName="form-control"
              value={formValue}
              onChange={(d): void => listener(field.id, d)}
              withDayOfTheWeek={true}
            />
          </div>
        );
      default:
        return null;
    }
  };

  /**
   * フィールドをレンダリングする
   * @param label ラベル
   * @param input 入力フォーム
   * @param key key
   * @returns {React.ReactNode}
   */
  const renderForm = (
    label: string,
    input: React.ReactNode,
    key: number | string,
  ): React.ReactNode => {
    return (
      <div key={key}>
        {renderFormElement(
          label,
          input,
          { className: "col-sm-3 control-label" },
          { className: "col-sm-8" },
        )}
      </div>
    );
  };

  /**
   * フィールドを表示可能かどうか
   * @param field フィールド
   * @returns {boolean}
   */
  const canRead = (field: FormField): boolean => {
    const { formValues, status, authority } = props;

    return requestFormPolicy.canRead(field, {
      status,
      authority,
      ...formValues,
    });
  };

  return render();
};
