import { CompositeSpecification } from './RequestSearchSpecification/';

/**
 * TOKIUM 経費精算において表示が可能か否か判断する仕様
 */
export class KeihiReportRequestSearchSpecification extends CompositeSpecification {
  forApprover: boolean;

  isAdmin: boolean;

  isAccountant: boolean;

  isPaperlessPlan: boolean;

  useDkRequests: boolean;

  constructor(
    forApprover: boolean,
  ) {
    super();

    const {
      isAdmin,
      isPaperlessPlan,
      isAccountant,
      preference: { useDkRequests },
    } = userPreferences;

    this.forApprover = forApprover;
    this.isAdmin = isAdmin;
    this.isAccountant = isAccountant;
    this.isPaperlessPlan = isPaperlessPlan;
    this.useDkRequests = useDkRequests;
  }

  isSatisfiedBy(candidate: string): boolean {
    if (!this.useDkRequests) {
      return false;
    }

    switch (candidate) {
      case 'transactedAt': return true;
      case 'entire': return this.forApprover && (this.isAdmin || this.isAccountant);
      case 'paperlessStatus': return this.forApprover && this.isAdmin && this.isPaperlessPlan;
      case 'categoryId': return this.forApprover;
      default: return false;
    }
  }
}
