import DatePicker, { registerLocale } from 'react-datepicker';
import Formatter from 'utilities/formatter';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import get from 'lodash/get';
import isNil from 'lodash/isNil';
import ja from 'date-fns/locale/ja';
import { getDatePickerClassName } from 'utilities/Utils';

const jaLocale = {
  ...ja,
  options: {
    ...ja.options,
    weekStartsOn: 0
  }
};

registerLocale('ja', jaLocale);

class PopperContainer extends Component {
  constructor(props) {
    super(props);

    this.container = document.createElement('div');
  }

  get root() {
    return document.getElementsByTagName('body')[0];
  }

  componentDidMount() {
    this.root.appendChild(this.container);
  }

  componentWillUnmount() {
    this.root.removeChild(this.container);
  }

  render() {
    return ReactDOM.createPortal(this.props.children, this.container);
  }
}

export default class DateInput extends Component {
  get today() {
    const date = new Date();
    date.setHours(0, 0, 0, 0);

    return date;
  }

  handleDateChange(dateTime, selected) {
    const date = dateTime || this.today;

    if (selected) {
      const overwritten = new Date(selected);
      overwritten.setHours(date.getHours(), date.getMinutes(), date.getSeconds(), date.getMilliseconds());
      this.props.onChange(overwritten.toISOString());
    } else {
      this.props.onChange(null);
    }
  }

  handleBlur(e) {
    this.props.onBlur(e);
  }

  render() {
    const {
      inline, value, inputClassName, className, style, disabled, autoFocus, isClearable, placeholder,
      popoverAttachment, popoverTargetAttachment, popoverTargetOffset, withDayOfTheWeek,
    } = this.props;
    const dateTime = value ? new Date(value) : null;
    const format = `yyyy/MM/dd${withDayOfTheWeek ? ' (EEE)' : ''}`;
    const maxDate = new Date('9999/12/31')

    if (disabled && !isNil(dateTime)) {
      return (
        <span className={ className } style={ style }>{ Formatter.date(value, { withDayOfTheWeek }) }</span>
      );
    }

    return (
      <div className={ className } style={ style }>
        <DatePicker popperContainer={ PopperContainer }
          autoFocus={ autoFocus }
          inline={ inline }
          locale={  get(window.userPreferences, 'locale', 'ja') }
          selected={ dateTime }
          className={ inputClassName }
          onChange={ this.handleDateChange.bind(this, dateTime) }
          onBlur={ this.handleBlur.bind(this) }
          disabled={ disabled }
          isClearable={ isClearable }
          placeholderText={ placeholder }
          popoverAttachment={ popoverAttachment }
          popoverTargetAttachment={ popoverTargetAttachment }
          popoverTargetOffset={ popoverTargetOffset }
          dateFormat={ format }
          maxDate={ maxDate }
          dayClassName={ getDatePickerClassName }
        />
      </div>
    );
  }
}

DateInput.propTypes = {
  value: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  onBlur: PropTypes.func.isRequired,
  inputClassName: PropTypes.string,
  className: PropTypes.string,
  style: PropTypes.object,
  autoFocus: PropTypes.bool,
  inline: PropTypes.bool.isRequired,
  isClearable: PropTypes.bool.isRequired,
  disabled: PropTypes.bool.isRequired,
  placeholder: PropTypes.string,
  popoverAttachment: PropTypes.string,
  popoverTargetAttachment: PropTypes.string,
  popoverTargetOffset: PropTypes.string,
  withDayOfTheWeek: PropTypes.bool,
};

DateInput.defaultProps = {
  onChange() {},
  onBlur() {},
  inline: false,
  isClearable: false,
  disabled: false,
  placeholder: '',
  popoverAttachment: 'bottom center',
  popoverTargetAttachment: 'top center',
  popoverTargetOffset: '0px 0px',
  withDayOfTheWeek: false,
};
