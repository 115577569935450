import React, { FC } from 'react';
import SmartCheckBox from 'components/SmartCheckBox';
import { ControlLabel } from 'react-bootstrap';

export interface CheckListItem {
  checked: boolean;
  disabled: boolean;
  key: string;
  value: string;
}

type CheckListColor =
  'accent' |
  'danger' |
  'default' |
  'info' |
  'primary' |
  'success' |
  'disabled';

type CheckListType = 'col' | 'row';

interface Props {
  className?: string;
  color?: CheckListColor;
  items: CheckListItem[];
  onChange?: (key: string, checked: boolean) => void;
  title: string;
  type?: CheckListType;
}

/** チェックボックス一覧を表示する */
const CheckList: FC<Props> = (props) => {
  const style = props.type === 'col' ? {
    display: 'block',
    marginBottom: '15px',
  } : {
    display: 'inline-block',
    marginRight: '15px',
  };
  const listItems = props.items.map((item, index) => (
    <SmartCheckBox
      checked={ item.checked }
      color={ props.color }
      disabled={ item.disabled }
      key={ `${item.key}${index}` }
      label={ item.value }
      onChange={ (): void => props.onChange?.(item.key, item.checked) }
      style={ style }
    />
  ));

  return (
    <div className={ `form-group ${props.className}` } >
      <ControlLabel>{ props.title }</ControlLabel>
      <div className='checkbox-group'>
        { listItems }
      </div>
    </div>
  );
};

CheckList.defaultProps = {
  className: '',
  color: 'success',
  items: [],
  title: '',
  type: 'row',
};

export default React.memo(CheckList);
