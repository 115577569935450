const prefix = 'analyses/index';

export const TOGGLE_CONFIRM_PAYMENT_MODAL = `${prefix}/TOGGLE_CONFIRM_PAYMENT_MODAL`;
export function openConfirmPaymentModal() {
  return { type: TOGGLE_CONFIRM_PAYMENT_MODAL, show: true };
}

export function closeConfirmPaymentModal() {
  return { type: TOGGLE_CONFIRM_PAYMENT_MODAL, show: false };
}

export const SET_PAYMENT_DATE = `${prefix}/SET_PAYMENT_DATE`;
export function setPaymentDate(date) {
  return {
    type: SET_PAYMENT_DATE,
    payload: {
      date,
    },
  };
}

export const SET_PAYMENT_COMMENT = `${prefix}/SET_PAYMENT_COMMENT`;
export function setPaymentComment(comment) {
  return {
    type: SET_PAYMENT_COMMENT,
    payload: {
      comment,
    },
  };
}

export const REQUEST_PAID_BATCH = `${prefix}/REQUEST_PAID_BATCH`;
export function requestPaidBatch() {
  return { type: REQUEST_PAID_BATCH, requested: true };
}

export const TOGGLE_CONFIRM_EXPORT_MODAL = `${prefix}/TOGGLE_CONFIRM_EXPORT_MODAL`;
export function toggleConfirmExportModal(show) {
  return {
    type: TOGGLE_CONFIRM_EXPORT_MODAL,
    show,
  };
}
