import RadioGroup from 'components/fields/RadioGroup';
import React, { FC } from 'react';
import i18next from 'i18n';
import { AccountType } from './types';

interface Props {
  accountType: AccountType;
  onChange: (accountType: AccountType) => void;
  disabled?: boolean;
}

const AccountTypeField: FC<Props> = (props) => {
  return (
    <RadioGroup isClearable={ false }
      value={ props.accountType }
      radios={
        [
          { value: 'ordinal', label: i18next.t('preferences.expenseAccount.ordinary') },
          { value: 'current', label: i18next.t('preferences.expenseAccount.current') },
          { value: 'saving', label: i18next.t('preferences.expenseAccount.saving') },
          { value: 'other', label: i18next.t('paymentRequests.bankAccount.types.other') },
        ]
      }
      onChange={ props.onChange }
      isDisabled={ props.disabled }
    />
  );
};

export default AccountTypeField;
