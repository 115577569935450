import PropTypes from 'prop-types';
import React, { Component } from 'react';
import i18next from 'i18n';

export default class PreXlsExportForm extends Component {
  constructor(props) {
    super(props);
    this.onExportXls = this.onExportXls.bind(this);
  }

  onExportXls() {
    window.location.href = `/pre_reports/${this.props.preReportId}/pre_xls`;
    this.onExport();
  }

  onExport() {
    this.props.onExport();
  }

  render() {
    return (
      <div className='row'>
        { this.renderContent() }
      </div>
    );
  }

  renderContent() {
    return (
      <div className='form-horizontal xls-export'>
        <div className='form-group'>
          <div className='col-sm-6'>
            <button
              className='btn btn-outline btn-success export-btn'
              onClick={ this.onExportXls }>{ i18next.t('exports.inputs.downloadPreXls') }</button>
          </div>
        </div>
      </div>
    );
  }
}

PreXlsExportForm.defaultProps = {
  onExport: () => null,
};

PreXlsExportForm.propTypes = {
  preReportId: PropTypes.string,
  onExport: PropTypes.func,
};
