import get from "lodash/get";
import PropTypes from "prop-types";
import React, { Component } from "react";

export default class WithholdingInput extends Component {
  componentDidMount() {
    const { field, onAutoChange, isNew, isEditingWithholdingCategory } =
      this.props;

    if (!field.edited && (isNew || !isEditingWithholdingCategory)) {
      onAutoChange(this.calculateWithholding());
    }
  }

  componentDidUpdate(prevProps) {
    const {
      constants,
      field,
      amount,
      onAutoChange,
      fromPreTransaction,
      isEditingWithholdingCategory,
    } = this.props;
    const prevAmount = get(prevProps, "amount");
    const prevConstants = get(prevProps, "constants");
    const prevIsEditingWithholdingCategory = get(
      prevProps,
      "isEditingWithholdingCategory",
    );

    if (
      (prevConstants !== constants && fromPreTransaction && !field.edited) ||
      (prevAmount !== amount && !field.edited)
    ) {
      onAutoChange(this.calculateWithholding());
    }
  }

  calculateWithholding() {
    const { constants, field, amount, onAutoChange } = this.props;

    if (amount <= constants.million) {
      return Math.floor(constants.rate * amount);
    }
    return Math.floor(
      (amount - constants.million) * constants.overMillionRate +
        constants.overMillionConstant,
    );
  }

  render() {
    const { field, value, onChange } = this.props;

    return (
      <input
        type="number"
        className={"form-control"}
        value={value}
        onChange={onChange}
        disabled={!field.editable}
      />
    );
  }
}

WithholdingInput.defaultProps = {
  className: "form-control validate[required,custom[number],maxSize[15]]",
  value: 0,
};

WithholdingInput.propTypes = {
  className: PropTypes.string,
  field: PropTypes.object.isRequired,
  constants: PropTypes.shape({
    rate: PropTypes.number.isRequired,
    overMillionRate: PropTypes.number.isRequired,
    overMillionConstant: PropTypes.number.isRequired,
    million: PropTypes.number.isRequired,
  }),
  value: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  amount: PropTypes.number,
  onChange: PropTypes.func.isRequired,
  onAutoChange: PropTypes.func.isRequired,
  fromPreTransaction: PropTypes.bool.isRequired,
};
