/**
 * 関数を一定時間毎に実行するタイマー
 * @param {number} interval - 時間ms
 * @param {array} functions - 実行される関数の一覧
 */
export default class Timer {
  constructor(interval, functions = []) {
    this.timerId = 0;
    this.interval = interval;
    this.functions = functions;

    this.runNext = this.runNext.bind(this);
  }

  /**
   * 実行される関数を追加する
   */
  add(fn) {
    this.functions.push(fn);
  }

  /**
   * タイマーのIDをセットする
   */
  setTimerId(timerId) {
    this.timerId = timerId;
  }

  /**
   * 関数の定期実行を開始する
   */
  start() {
    if (this.timerId) {
      return;
    }

    this.runNext();
  }

  /**
   * interval間隔で関数を実行するイベントの追加
   * 受け取った関数配列の全てに対して行う
   */
  runNext() {
    if (this.functions.length > 0) {
      for (let i = 0; i < this.functions.length; i++) {
        if (this.functions[i]() === false) {
          this.functions.splice(i, 1);
          i--;
        }
      }
      this.setTimerId(setTimeout(this.runNext, this.interval));
    }
  }

  /**
   * 関数一覧の実行を停止する
   */
  stop() {
    clearTimeout(this.timerId);
    this.timerId = 0;
  }

  /**
   * 実行される関数を全て削除する
   */
  clear() {
    this.functions = [];
  }
}
