import i18next from "i18n";
import get from "lodash/get";
import isNil from "lodash/isNil";
import React from "react";
import { OverlayTrigger, Popover } from "react-bootstrap";
import { Expense } from "utilities/api/models";

interface Props {
  expense: Expense;
}

/** 原本確認アイコン */
export const ReceiptMatchingResultStatusIcon: React.FC<Props> = ({
  expense,
}) => {
  const foreside = get(expense, "receiptImages.foreside[0]", null);
  const backside = get(expense, "receiptImages.backside[0]", null);
  const { receiptExpenseMatching, isElectronicReceiptImage } = expense;

  if (isElectronicReceiptImage) {
    const popover = (
      <Popover id="receiptExpenseMatchingComment">
        <span style={{ whiteSpace: "pre-line", wordWrap: "break-word" }}>
          原本確認不要（電子領収書）
        </span>
      </Popover>
    );

    return (
      <span>
        <OverlayTrigger
          overlay={popover}
          placement={"top"}
          trigger={["click", "hover"]}
        >
          <span
            className="fa-stack"
            style={{
              cursor: "pointer",
              fontSize: "0.666em",
              verticalAlign: "top",
            }}
          >
            <i className="fa fa-circle fa-stack-2x txt-warning" />
            <i
              className="fa fa-window-minimize fa-stack-1x"
              style={{ fontSize: "12px", lineHeight: "1" }}
            />
          </span>
        </OverlayTrigger>
      </span>
    );
  }

  if (isNil(foreside) && isNil(backside)) {
    return (
      <span>
        <i className="fa fa-window-minimize" style={{ fontSize: "12px" }} />
      </span>
    );
  }

  if (receiptExpenseMatching?.originalReceipt) {
    return (
      <span>
        <i className="fa fa-check fa-lg" style={{ fontSize: "12px" }} />
      </span>
    );
  }

  if (receiptExpenseMatching?.status === "confirmed") {
    const popover = (
      <Popover
        id="receiptExpenseMatchingComment"
        title={i18next.t(
          "paperless.expense.forms.markingAsMatchedForm.labels.comment",
        )}
      >
        <span style={{ whiteSpace: "pre-line", wordWrap: "break-word" }}>
          {receiptExpenseMatching.comment}
        </span>
      </Popover>
    );

    return (
      <span>
        <OverlayTrigger
          overlay={popover}
          placement={"top"}
          trigger={["click", "hover"]}
        >
          <span
            className="fa-stack"
            style={{
              cursor: "pointer",
              fontSize: "0.666em",
              verticalAlign: "top",
            }}
          >
            <i className="fa fa-circle fa-stack-2x txt-warning" />
            <i className="fa fa-check fa-stack-1x" />
          </span>
        </OverlayTrigger>
      </span>
    );
  }

  return <span />;
};
