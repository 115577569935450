export interface State {
  // expenseMatch: ExpenseMatchState;
  // export: ExportState;
  // memberSearch: MemberSearchState;
  search: SearchState;
  // table: TableState;
}

// interface ExpenseMatchState {}
// interface ExportState {}
// interface MemberSearchState {}
interface SearchState {
  Conditions: SearchConditionState;
  // Result: SearchResultState;
  // State: SearchStateState;
}
// interface TableState {}

export const initialSearchConditionState = {
  amountFrom: '',
  amountTo: '',
  applying: false,          // 申請中
  canceled: false,          // 突合解除済み
  closeApplying: false,     // 最終承認済み
  collectedAtFrom: '',
  collectedAtTo: '',
  notified: false,          // 通知済
  originalReceiptId: '',
  // DKユーザー画面とPLPワーカー画面両方で参照されるため、userPreferencesが取れない場合の処理を含めている
  paperlessPlanSubscription: window.userPreferences?.paperlessPlanSubscriptions[0] ?? null,
  receiptBoxName: '',
  shopName: '',
  transactedAtFrom: '',
  transactedAtTo: '',
  unnecessary: false,       // 突合不要
  unnotified: false,        // 未通知
  unsubmitted: false,       // 未申請
  waitCreateExpense: false, // 経費作成待ち
  waitLastApplying: false,  // 最終承認待ち
  waitMatching: false,      // 突合待ち
};

export type SearchConditionState = typeof initialSearchConditionState;
