import {
  Modal,
  ModalBody,
  ModalHeader,
} from "components/renewaled_ui/single_views/Modal";
import { RemoteReceiptFile } from "components/types/attachedFiles.types";
import i18next from "i18n";
import _orderBy from "lodash/orderBy";
import React, { useState } from "react";
import styled from "styled-components";
import ResolutionInformationItem from "./ResolutionInformationItem";
import ResolutionInformationTopSummary from "./ResolutionInformationTopSummary";

const ModalInner = styled.div`
  h5 {
    font-weight: bold;
    margin: 15px 0;
  }

  .foreside-area,
  .backside-area {
    padding-left: 5%;

    .side-detail-item {
      list-style: none;

      .ver-text {
        margin: 5px 0;
      }

      .info-area {
        width: calc(100% - 25px);
        text-align: right;
      }
    }
  }

  .foreside-area {
    border-bottom: 1px solid #e5e5e5;
    margin-bottom: 15px;
  }
`;

interface Props {
  versions: {
    foreside?: Array<RemoteReceiptFile>;
    backside?: Array<RemoteReceiptFile>;
  };
}

const ResolutionInformationsModal: React.FC<Props> = (props) => {
  const { versions } = props;

  const [isShowingDrawer, toggleIsShowingDrawer] = useState<boolean>(false);

  const sortedForesideFiles = _orderBy(
    versions.foreside || [],
    "createdAt",
    "desc",
  );
  const sortedBacksideFiles = _orderBy(
    versions.backside || [],
    "createdAt",
    "desc",
  );
  const foresideLatestFile = sortedForesideFiles[0] || null;
  const backsideLatestFile = sortedBacksideFiles[0] || null;

  return (
    <>
      <ResolutionInformationTopSummary
        onClick={(): void => {
          toggleIsShowingDrawer(!isShowingDrawer);
        }}
        foresideLatestFile={foresideLatestFile}
        backsideLatestFile={backsideLatestFile}
      />
      <Modal
        width={"sm"}
        show={isShowingDrawer}
        onClose={(): void => toggleIsShowingDrawer(false)}
      >
        <ModalInner>
          <ModalHeader
            onClose={(): void => toggleIsShowingDrawer(false)}
            title={i18next.t(
              "transactions.index.resolutionInformation.requirementTitle",
            )}
          />
          <ModalBody>
            <h5>
              {i18next.t(
                "transactions.properties.resolutionInformation.foreside",
              )}
            </h5>
            <ul className="foreside-area">
              {sortedForesideFiles.length === 0
                ? i18next.t(
                    "transactions.properties.resolutionInformation.noContent",
                  )
                : sortedForesideFiles.map((file, index) => (
                    <ResolutionInformationItem
                      key={index}
                      file={file}
                      index={index}
                      count={sortedForesideFiles.length}
                    />
                  ))}
            </ul>
            <h5>
              {i18next.t(
                "transactions.properties.resolutionInformation.backside",
              )}
            </h5>
            <ul className="backside-area">
              {sortedBacksideFiles.length === 0
                ? i18next.t(
                    "transactions.properties.resolutionInformation.noContent",
                  )
                : sortedBacksideFiles.map((file, index) => (
                    <ResolutionInformationItem
                      key={index}
                      file={file}
                      index={index}
                      count={sortedBacksideFiles.length}
                    />
                  ))}
            </ul>
          </ModalBody>
        </ModalInner>
      </Modal>
    </>
  );
};

export default ResolutionInformationsModal;
