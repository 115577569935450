import i18next from 'i18n';

export const minPasswordLength = 8;
export const maxPasswordLength = 256;
export const errorMessages = {
  blank: i18next.t('logins.errors.password.blank'),
  tooShort: i18next.t('logins.errors.password.tooShort', { minPasswordLength }),
  notMatch: i18next.t('logins.errors.password.notMatch'),
};

// パスワード文字列を受け取りエラーメッセージを返却
export const passwordErrorMessage = (password: string): string => {
  if (!password) return errorMessages.blank;

  if (password.length < minPasswordLength) return errorMessages.tooShort;

  return '';
};
