import compact from "lodash/compact";

// メモ欄が編集済みか否かを判定する(テンプレートのみは編集済みとしない)
export function isEditedMemo(memo, commentTemplate, categoryMemoTemplate) {
  if (
    memo === null ||
    memo.replace("\n", "").replace("　", "").replace(" ", "") === "" ||
    memo
      .replace(commentTemplate, "")
      .replace(categoryMemoTemplate, "")
      .replace("\n", "")
      .trim() === ""
  ) {
    return false;
  }
  return true;
}

// メモ欄に入力するテンプレートを取得する
export function memoTemplate(commentTemplate, categoryMemoTemplate) {
  const commentFormat = [];
  commentFormat.push(commentTemplate);
  commentFormat.push(categoryMemoTemplate);

  return compact(commentFormat).join("\n\n");
}

export function buildMemo(memo, commentTemplate, categoryMemoTemplate) {
  if (isEditedMemo(memo, commentTemplate, categoryMemoTemplate)) {
    return memo;
  }

  return memoTemplate(commentTemplate, categoryMemoTemplate);
}

export function mergeMemo(memo, commentTemplate, categoryMemoTemplate) {
  const commentFormat = [];
  commentFormat.push(memo);
  commentFormat.push(commentTemplate);
  commentFormat.push(categoryMemoTemplate);

  return compact(commentFormat).join("\n\n");
}
