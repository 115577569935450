import BootstrapCard from 'components/BootstrapCard';
import EditableText from 'components/editable_text';
import Formatter from 'utilities/formatter';
import PropTypes from 'prop-types';
import React, { Component } from 'react';

/**
 * TK集計詳細ページ: 集計詳細
 */
export default class PreAnalysisInfoCard extends Component {
  render() {
    const { analysis, isTravel, isTemporaryPayment } = this.props;

    return (
      <BootstrapCard
        title='集計詳細'
        titleIcon={ { className: 'fa-signal' } }
      >
        <table className='table table-bordered'>
          <tbody>
            { this.renderItem('名前', this.renderName(analysis)) }
            { this.renderItem('作成日', Formatter.date(analysis.createdAt)) }
            { this.renderItem('合計金額', Formatter.amount(analysis.amount, '')) }
            {
              !isTemporaryPayment
                ? this.renderItem('仮払合計金額', Formatter.amount(analysis.temporaryPaymentAmount, ''))
                : null
            }
            { this.renderItem('項目数', analysis.preCount || analysis.count) }
            { this.renderItem(isTravel ? '旅費申請数' : '事前申請数', analysis.preReports.length) }
          </tbody>
        </table>
      </BootstrapCard>
    );
  }

  /**
   * tableの行
   */
  renderItem(label, value) {
    return (
      <tr>
        <td>{ label }</td>
        <td>{ value }</td>
      </tr>
    );
  }

  /**
   * 名前の欄
   */
  renderName(analysis) {
    return (
      <EditableText
        text={ analysis.name }
        onEdit={ (name) => this.props.onChangeTitle(analysis.id, name) }
        validate={ ['required'] }
      />
    );
  }
}

PreAnalysisInfoCard.propTypes = {
  isTravel: false,
  isTemporaryPayment: false,
};

PreAnalysisInfoCard.propTypes = {
  analysis: PropTypes.shape({
    reports: PropTypes.array.isRequired,
  }),
  isTravel: PropTypes.bool.isRequired,
  isTemporaryPayment: PropTypes.bool.isRequired,
  onChangeTitle: PropTypes.func,
};
