import * as ActionTypes from '../actions';
import cloneDeep from 'lodash/cloneDeep';
import merge from 'lodash/merge';
import { exportPreferencInitialState } from '../Types';

const ExportPreference = (state = exportPreferencInitialState, action) => {
  switch (action.type) {
    case ActionTypes.SET_FORMAT_KEY: {
      return {
        ...state,
        formatKey: action.value,
      };
    }
    case ActionTypes.SET_EXPORT_CATEGORY_RULES: {
      const newRules = cloneDeep(action.rules);

      return {
        ...state,
        exportCategoryRules: newRules,
        exportCategoryColumns: action.columns,
      };
    }
    case ActionTypes.SET_EXPORT_TAX_CATEGORY_RULES: {
      const newRules = cloneDeep(action.rules);
      return {
        ...state,
        exportTaxRules: newRules,
        exportTaxColumns: action.columns,
      };
    }
    case ActionTypes.SET_AGGREGATION_COLUMNS: {
      const aggregationColumns = merge(state.aggregationColumns, action.value);
      return {
        ...state,
        aggregationColumns,
      };
    }
    case ActionTypes.SET_ACCOUNTING_SUMMARY_FORMAT: {
      return {
        ...state,
        accountingSummaryFormat: action.value,
      };
    }
    case ActionTypes.SET_USE_PAYMENT_DATE: {
      return {
        ...state,
        usePaymentDate: action.payload.value,
      };
    }
    case ActionTypes.SET_USE_TEMPORARY_PAYMENT_DATE: {
      return {
        ...state,
        useTemporaryPaymentDate: action.payload.value,
      };
    }
    default:
      return state;
  }
};

export default ExportPreference;
