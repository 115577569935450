import i18next from "i18n";
import React, { FC } from "react";
import { TableHeaderColumn } from "react-bootstrap-table";
import { ColumnProps } from "./types";
import { buildTableHeaderColumnOption, formatInfoIcons } from "./utils";

type IconProps = {
  /** @todo object型は使わないように */
  // eslint-disable-next-line @typescript-eslint/ban-types
  setS3ImageUrl: (expenseId: string, url: object) => void;
} & ColumnProps;

/** @todo object型は使わないように */
// eslint-disable-next-line @typescript-eslint/ban-types
const formatIcon = (
  props: IconProps,
  cell: string,
  row: object,
): JSX.Element => {
  return formatInfoIcons(cell, row, props.setS3ImageUrl, "expense-image");
};

const renderIconsColumn: FC<IconProps> = (props: IconProps) => {
  return (
    <TableHeaderColumn
      {...buildTableHeaderColumnOption(props)}
      dataAlign={"center"}
      dataField={"misc"}
      dataFormat={formatIcon.bind(null, props)}
    >
      {i18next.t("commons.misc")}
    </TableHeaderColumn>
  );
};

export default renderIconsColumn;
