import React from 'react';
import { Cells } from 'components/renewaled_ui/Table/molecules/Cells';
import { RequiredTableDataProps } from 'components/renewaled_ui/Table/interface/table';
import { Row as RowProps } from 'react-table';
import { Tr } from 'components/renewaled_ui/Table/atoms/Tr';
import { TrA } from 'components/renewaled_ui/Table/atoms/TrA';
import { useHooks } from './hooks';

interface Props<Data> {
  /** テーブルデータ */
  readonly data: Data[];
  /** 列 */
  readonly row: RowProps;
  /** 詳細閲覧中データ */
  readonly viewingData?: Data;
  /** 行をクリックした際の遷移先(指定した場合aリンクになる) */
  readonly href?: string;
  /** onRowClickがあるか(行をクリック可能か) */
  readonly hasOnRowClick?: boolean;
  /** 列情報 */
  readonly prepareRow: (row: RowProps) => void;
  /** 行をクリックした際のハンドラ */
  readonly onRowClick?: (data: Data) => void;
  /** 枠線がないシンプルなデザイン */
  readonly isSimpleDesignMode: boolean;
}

/**
 * Tableの行
 */
export const Row = <Data extends RequiredTableDataProps, >(props: Props<Data>): JSX.Element => {
  const { handleRowClick } = useHooks(props.onRowClick);
  const isSelected = (props.data[props.row.id].isSelected || (props.viewingData?.id === (props.row.original as Data).id));
  const isHighLight = props.data[props.row.id]?.isHighLight || false;

  props.prepareRow(props.row);

  // hrefが指定された場合、行をリンクにする。
  // [ctrl+クリック]で別タブで開くなどの動作ができるよう、aタグに置き換える
  if (props.href) {
    return (
      <TrA
        key={ props.row.getRowProps().key }
        isSelected={ isSelected }
        isHighLight={ isHighLight }
        tableRowProps={ props.row.getRowProps() }
        hasOnRowClick={ props.hasOnRowClick }
        href={ `${props.href}/${(props.row.original as Data).id}` }
        isSimpleDesignMode={ props.isSimpleDesignMode }
      >
        <Cells
          cells={ props.row.cells }
          onClickCell={ (id, data): void => handleRowClick(id, data as Data) }
          isSimpleDesignMode={ props.isSimpleDesignMode }
        />
      </TrA>
    );
  }

  return (
    <Tr
      key={ props.row.getRowProps().key }
      isSelected={ isSelected }
      isHighLight={ isHighLight }
      tableRowProps={ props.row.getRowProps() }
      hasOnRowClick={ props.hasOnRowClick }
      isSimpleDesignMode={ props.isSimpleDesignMode }
    >
      <Cells
        cells={ props.row.cells }
        onClickCell={ (id, data): void => handleRowClick(id, data as Data) }
        isSimpleDesignMode={ props.isSimpleDesignMode }
      />
    </Tr>
  );
};
