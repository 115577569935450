import { ListForm } from "components/renewaled_ui/form_views";
import TextArea from "components/TextArea";
import i18next from "i18n";
import React, { FC } from "react";
import { OverlayTrigger, Popover } from "react-bootstrap";
import styled from "styled-components";
import { InvoicingOrganization } from "utilities/api_managements/models/InvoicingOrganization";
import RegistratedNumberAndInvoicingOrganizationForm from "./RegistratedNumberAndInvoicingOrganizationForm";

const EligibleInvoiceConfirmationFormWrapper = styled.div`
  padding-top: 10px;
  padding-left: 10px;
  padding-right: 10px;
  padding-bottom: 10px;
  background: #f0f0f0;
`;

interface Props {
  asEligibleInvoice: boolean;
  registratedNumber: string; // nullな時もあるが、EntryFormsの中で''を代入
  invoicingOrganization: InvoicingOrganization | null | undefined; // デフォルトはundefined。APIを叩いた結果により InvoicingOrganization もしくは null が入る
  paidAddress: string; // nullな時もあるが、EntryFormsの中で''を代入
  notActiveAsEligibleInvoice: boolean;
  isRegistratedNumberShow: boolean;
  isPaidAddressShow: boolean;
  onChangeAsEligibleInvoice: (eligible: boolean) => void;
  onChangeRegistratedNumber: (number: string | null) => void;
  onVerifyRegistratedNumber: () => void;
  onChangePaidAddress: (address: string) => void;
}

const EligibleInvoiceConfirmationForm: FC<Props> = (props) => {
  const {
    asEligibleInvoice,
    registratedNumber,
    invoicingOrganization,
    paidAddress,
    notActiveAsEligibleInvoice,
    isRegistratedNumberShow,
    isPaidAddressShow,
    onChangeAsEligibleInvoice,
    onChangeRegistratedNumber,
    onVerifyRegistratedNumber,
    onChangePaidAddress,
  } = props;

  const handleChangeAsEligibleInvoice = (
    e: React.ChangeEvent<HTMLInputElement>,
  ): void => {
    onChangeAsEligibleInvoice(e.target.checked);
  };

  const handleChangePaidAddress = (
    e: React.ChangeEvent<HTMLInputElement>,
  ): void => {
    onChangePaidAddress(e.target.value);
  };

  const noticePopover = (
    <Popover id="notice">
      {i18next.t("transactions.properties.eligibleInvoiceNotice")}
    </Popover>
  );

  return (
    <EligibleInvoiceConfirmationFormWrapper className="eligible-invoice-confirmation-view">
      {/* 適格請求書として扱う */}
      <div style={{ display: "flex", marginBottom: "20px" }}>
        <div style={{ paddingRight: "5px" }}>
          <input
            type="checkbox"
            checked={asEligibleInvoice}
            onChange={handleChangeAsEligibleInvoice}
            disabled={notActiveAsEligibleInvoice}
          />
        </div>
        <span>
          {i18next.t("transactions.properties.asEligibleInvoice")}
          <OverlayTrigger
            trigger={["hover", "focus", "click"]}
            placement="top"
            overlay={noticePopover}
          >
            <i
              className="far fa-question-circle"
              style={{ color: "#AAA", marginLeft: "6px", cursor: "pointer" }}
            />
          </OverlayTrigger>
        </span>
      </div>

      {/* 登録番号および適格請求書発行事業者 詳細  */}
      <RegistratedNumberAndInvoicingOrganizationForm
        readOnly={false}
        registratedNumber={registratedNumber}
        invoicingOrganization={invoicingOrganization}
        isRegistratedNumberShow={isRegistratedNumberShow}
        onChangeNumber={onChangeRegistratedNumber}
        onVerifyNumber={onVerifyRegistratedNumber}
      />

      {/* 住所又は所在地 */}
      {isPaidAddressShow && (
        <ListForm
          key={"as_paid_address_input"}
          label={i18next.t("transactions.properties.paidAddress")}
        >
          <TextArea
            className="form-control"
            value={paidAddress}
            onChange={handleChangePaidAddress}
            style={{ height: "54px", resize: "none" }}
          />
        </ListForm>
      )}
    </EligibleInvoiceConfirmationFormWrapper>
  );
};

EligibleInvoiceConfirmationForm.defaultProps = {
  asEligibleInvoice: false,
  registratedNumber: "",
  invoicingOrganization: undefined,
  paidAddress: "",
  notActiveAsEligibleInvoice: false,
  isRegistratedNumberShow: false,
  isPaidAddressShow: false,
};

export default EligibleInvoiceConfirmationForm;
