import Api from 'utilities/api';
import i18next from 'i18n';
import { displayMessage, redirectTo } from 'actions/ActionCreators';
import { getMessageFromResponse } from 'utilities/Utils';

const prefix = 'exports';

export function downloadZenginForReport(id, payerAccount, payeeAccounts, date, onFinish) {
  return async (dispatch, getState) => {
    if (!payerAccount) {
      dispatch(onNoAnalysisError());
      return;
    }

    try {
      const data = await Api.analyses.validateZengin({ id });
      dispatch(redirectTo(`/analyses/${id}/zengin?date=${date}&account_id=${payerAccount.id}`));
    } catch (e) {
      dispatch(displayMessage('error', getMessageFromResponse(e)));
    } finally {
      onFinish();
    }
  };
}

// @param String payerAccount 支払口座のID。複数の口座から選択できるようになった際に利用
export function downloadZenginForPreReportUsingTempPayment(id, payerAccount, payeeAccounts, date, onFinish) {
  return async (dispatch, getState) => {
    if (!payerAccount) {
      dispatch(onNoAnalysisError());
      return;
    }

    try {
      await Api.preAnalyses.validateZengin({ id });
      dispatch(redirectTo(`/temporary_payment_analyses/${id}/zengin?date=${date}&account_id=${payerAccount.id}`));
    } catch (e) {
      dispatch(displayMessage('error', getMessageFromResponse(e)));
    } finally {
      onFinish();
    }
  };
}

function onNoPayeeAccountError() {
  return (dispatch) => {
    dispatch(displayMessage('error', i18next.t('exports.errors.payeeAccountBlank')));
  };
}

function onNoAnalysisError() {
  return (dispatch) => {
    dispatch(displayMessage('error', i18next.t('exports.errors.payerCompanyAccountBlank')));
  };
}

export const SET_TRANSACTIONS = `${prefix}/SET_TRANSACTIONS`;
export function setTransactions(transactions) {
  return {
    type: SET_TRANSACTIONS,
    transactions,
  };
}

export const SET_PAYER_ACCOUNT = `${prefix}/SET_PAYER_ACCOUNT`;
export function setPayerAccount(data) {
  return {
    type: SET_PAYER_ACCOUNT,
    data,
  };
}

export const SET_ZENGIN_TRANSACTION_DATE = `${prefix}/SET_ZENGIN_TRANSACTION_DATE`;
export function setZenginTransactionDate(date) {
  return {
    type: SET_ZENGIN_TRANSACTION_DATE,
    date,
  };
}

export const SETUP_TABS = `${prefix}/SETUP_TABS`;
export function setupTabs(tabs) {
  return {
    type: SETUP_TABS,
    tabs,
  };
}

export const ON_CLICK_TAB = `${prefix}/ON_CLICK_TAB`;
export function onClickTab(e, group) {
  return (dispatch, getState) => {
    dispatch(resetActiveTabs());
    dispatch(activateTab(group.name));
  };
}

export const ACTIVATE_TAB = `${prefix}/ACTIVATE_TAB`;
function activateTab(groupName) {
  return {
    type: ACTIVATE_TAB,
    groupName,
  };
}

export const RESET_ACTIVE_TAB = `${prefix}/RESET_ACTIVE_TAB`;
function resetActiveTabs() {
  return {
    type: RESET_ACTIVE_TAB,
  };
}

export const TOGGLE_HAS_BEEN_EXPORTED = `${prefix}/TOGGLE_HAS_BEEN_EXPORTED`;
export function toggleHasBeenExported(hasBeenExported) {
  return {
    type: TOGGLE_HAS_BEEN_EXPORTED,
    hasBeenExported,
  };
}

export const TOGGLE_EXPORTING = `${prefix}/TOGGLE_EXPORTING`;
export function toggleExporting(exporting) {
  return {
    type: TOGGLE_EXPORTING,
    exporting,
  };
}

export const TOGGLE_SHOW = `${prefix}/TOGGLE_SHOW`;
export function openModal() {
  return {
    type: TOGGLE_SHOW,
    show: true,
  };
}

export function closeModal() {
  return {
    type: TOGGLE_SHOW,
    show: false,
  };
}

export const TOGGLE_SHOW_AGGREGATION_BY_USER = `${prefix}/TOGGLE_SHOW_AGGREGATION_BY_USER`;
export function openAggregatonByUserModal() {
  return {
    type: TOGGLE_SHOW_AGGREGATION_BY_USER,
    showAggregationByUserModal: true,
  };
}

export function closeAggregatonByUserModal() {
  return {
    type: TOGGLE_SHOW_AGGREGATION_BY_USER,
    showAggregationByUserModal: false,
  };
}

export const TOGGLE_SHOW_AGGREGATION_BY_CATEGORY = `${prefix}/TOGGLE_SHOW_AGGREGATION_BY_CATEGORY`;
export function openAggregatonByCategoryModal() {
  return {
    type: TOGGLE_SHOW_AGGREGATION_BY_CATEGORY,
    showAggregationByCategoryModal: true,
  };
}

export function closeAggregatonByCategoryModal() {
  return {
    type: TOGGLE_SHOW_AGGREGATION_BY_CATEGORY,
    showAggregationByCategoryModal: false,
  };
}

export const TOGGLE_SHOW_ANALYSIS_REPORT = `${prefix}/TOGGLE_SHOW_ANALYSIS_REPORT`;
export function openAnalysisReportModal() {
  return {
    type: TOGGLE_SHOW_ANALYSIS_REPORT,
    showAnalysisReportModal: true,
  };
}

export function closeAnalysisReportModal() {
  return {
    type: TOGGLE_SHOW_ANALYSIS_REPORT,
    showAnalysisReportModal: false,
  };
}
