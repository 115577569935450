import PropTypes from 'prop-types';


export const expenseAccountType = {
  id: PropTypes.string,
  bank: PropTypes.shape({
    name: PropTypes.string,
  }),
  brankBranch: PropTypes.shape({
    name: PropTypes.string,
  }),
  type: PropTypes.oneOf(['普通', '貯蓄']),
  number: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  holderKana: PropTypes.string,
  editable: PropTypes.bool.isRequired,
};

export const companyExpenseAccountType = {
  id: PropTypes.string,
  bank: PropTypes.shape({
    name: PropTypes.string,
  }),
  brankBranch: PropTypes.shape({
    name: PropTypes.string,
  }),
  type: PropTypes.oneOf(['普通', '当座']),
  number: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  holderKana: PropTypes.string,
  customerCode: PropTypes.string,
  editable: PropTypes.bool,
};
