import AmountWithTaxPerTaxCategoryTable from "applications/transactions/components/AmountWithTaxPerTaxCategoryTable";
import React from "react";
import { AmountPerTaxCategory } from "../interface";

interface Props {
  readonly values: AmountPerTaxCategory[];
  readonly isShowNameColumn: boolean;
}

export const ReadOnlyTable: React.FC<Props> = ({
  values,
  isShowNameColumn,
}) => {
  return (
    <AmountWithTaxPerTaxCategoryTable
      values={values}
      showName={isShowNameColumn}
      editable={false}
      onAmountChange={(): void => void 0}
      onTaxAmountChange={(): void => void 0}
    />
  );
};
