import NumberField from 'components/fields/NumberField';
import React, { FC } from 'react';

interface Props {
  accountNumber: string;
  onChange: (accountNumber: string) => void;
  disabled?: boolean;
}

const AccountNumberField: FC<Props> = (props) => {
  return (
    <NumberField
      value={ props.accountNumber }
      onChange={ props.onChange }
      options={ { maxLength: 7 } }
      allowZeroPadding={ true }
      disabled={ props.disabled }
    />
  );
};

export default AccountNumberField;
