import initialPreReport from "values/initial_pre_report";
import {
  buildFormValues,
  setFormValues,
} from "../../transactions/utilities/transactionFormBuilder";
import * as FormStateActionTypes from "../actions/formStateActions";
import * as ActionTypes from "../actions/preReportActions";

const preReport = (state = initialPreReport, action) => {
  switch (action.type) {
    case FormStateActionTypes.SET_FIELDS: {
      return {
        ...state,
        formValues: buildFormValues(action.fields),
      };
    }
    case ActionTypes.SET_PRE_REPORT: {
      return {
        ...action.preReport,
        formValues: buildFormValues(action.fields, action.preReport),
      };
    }
    case ActionTypes.SET_PROPERTY: {
      return {
        ...state,
        [action.key]: action.value,
        formValues: setFormValues(
          state.formValues,
          action.key,
          action.value,
          action.defaultValues,
        ),
      };
    }
    case ActionTypes.SET_METADATA: {
      return {
        ...state,
        formValues: setFormValues(
          state.formValues,
          action.key,
          action.value,
          action.defaultValues,
        ),
      };
    }
    case ActionTypes.SET_PRE_TRANSACTION: {
      let hasReplaced = false;
      const preTransactions = state.preTransactions.map((t) => {
        if (t.key === action.preTransaction.key) {
          hasReplaced = true;
          return action.preTransaction;
        }
        return t;
      });
      if (!hasReplaced) {
        preTransactions.unshift(action.preTransaction);
      }

      return {
        ...state,
        preTransactions,
      };
    }
    case ActionTypes.EDIT_ALL_PRE_TRANSACTION_PROPERTY: {
      return {
        ...state,
        preTransactions: state.preTransactions.map((x) => {
          return { ...x, [action.key]: action.value };
        }),
      };
    }
    case ActionTypes.DELETE_PRE_TRANSACTION: {
      return {
        ...state,
        preTransactions: state.preTransactions.filter(
          (it) => it.key !== action.key,
        ),
      };
    }
    default:
      return state;
  }
};

export default preReport;
