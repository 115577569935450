import React, {
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react';
import i18next from 'i18n';
import styled from 'styled-components';
import {
  AggregationForm,
  BankExportForm,
  BaseForm,
  ExportForm,
  TransferForm,
} from './forms';
import { Button, Col, Row } from 'react-bootstrap';
import { Position, SystemSetting } from '../types';
import { ScrollableForm } from './ScrollableForm';

const ButtonView = styled.div<{ active?: boolean; }>`
  text-align: center;
  border-radius: 6px;
  background: ${({ active }): string => (active ? '#e3edff' : 'transparent')};

  &:hover {
    background: #e3edff;
  }

  .btn.btn-link {
    color: #004acc;
    text-decoration: none;
    font-weight: ${({ active }): string => (active ? 'bold' : 'normal')};

    &:focus, &:focus-visible {
      outline: none;
    }
  }
`;

const FormGroupView = styled.div`
  margin-bottom: 12px;
  border-radius: 6px;
  background: #f3f3f9;
  padding: 6px 12px;

  .title {
    margin: 12px 0 18px 0px;
    font-size: 14px;
    font-weight: bold;
  }
  .form-group {
    margin-bottom: 18px;
  }
  .control-label {
    margin-bottom: 1px;
  }
`;

interface Props {
  systemSetting: SystemSetting;
  isShowingForm?: boolean,
  offsetHeight?: number;
}

export const FormLayout: React.FC<Props> = ({
  systemSetting,
  isShowingForm = true,
  offsetHeight,
}) => {
  const formRef = useRef<HTMLDivElement>(null);
  const refBase = useRef<HTMLDivElement>(null);
  const refAggregation = useRef<HTMLDivElement>(null);
  const refExport = useRef<HTMLDivElement>(null);
  const refTransfer = useRef<HTMLDivElement>(null);

  const [scrollTo, setScrollTo] = useState<Position>('base');
  const [activeArea, setActiveArea] = useState<Position>('base');

  const handleClick = useCallback((pos: Position) => setScrollTo(pos), [setScrollTo]);

  // スクロールを追跡する
  const handleScroll = useCallback((e: React.UIEvent<HTMLDivElement>) => {
    if (!(refBase.current && refAggregation.current && refExport.current && refTransfer.current)) return;

    const scrollTop = e.currentTarget.scrollTop;
    const visibleOffset = e.currentTarget.clientHeight / 2;

    if (e.currentTarget.scrollHeight - Math.floor(e.currentTarget.scrollTop) === e.currentTarget.clientHeight) {
      // 転送のエリアは高さが小さいため、底についたらアクティブにする。
      setActiveArea('transfer');
    } else if (scrollTop < visibleOffset) {
      setActiveArea('base');
    } else if (scrollTop < refExport.current.offsetTop - visibleOffset) {
      setActiveArea('aggregation');
    } else if (scrollTop < refTransfer.current.offsetTop - visibleOffset) {
      setActiveArea('export');
    }
  }, [refBase, refAggregation, refExport, refTransfer, setActiveArea]);

  useEffect(() => {
    const refs: Record<Position, React.RefObject<HTMLHeadElement>> = {
      base: refBase,
      aggregation: refAggregation,
      export: refExport,
      transfer: refTransfer,
    };

    const ref = refs[scrollTo];
    if (ref?.current) {
      ref.current.scrollIntoView({
        behavior: 'smooth',
        block: 'start',
      });
    }
  }, [scrollTo]);

  return (
    <Row>
      <Col sm={ 3 }>
        { (['base', 'aggregation', 'export', 'transfer'] as Position[]).map((position) => (
          <ButtonView active={ activeArea === position } key={ position }>
            <Button bsStyle='link' onClick={ (): void => handleClick(position) }>
              { i18next.t(`accountingDataScheduledExports.tasks.forms.${position}.heading`) }
            </Button>
          </ButtonView>
        )) }
      </Col>
      <ScrollableForm ref={ formRef } onScroll={ handleScroll } offsetHeight={ offsetHeight }>
        <FormGroupView><BaseForm ref={ refBase } /></FormGroupView>
        <FormGroupView><AggregationForm ref={ refAggregation } systemSetting={ systemSetting } isShowingForm={ isShowingForm } /></FormGroupView>
        <FormGroupView><ExportForm ref={ refExport } /></FormGroupView>
        <FormGroupView><BankExportForm /></FormGroupView>
        <FormGroupView><TransferForm ref={ refTransfer } /></FormGroupView>
      </ScrollableForm>
    </Row>
  );
};
