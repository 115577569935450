import PropTypes from 'prop-types';
import React, { Component } from 'react';
import SmartCheckBox from 'components/SmartCheckBox';
import i18next from 'i18n';
import { Dropdown, MenuItem } from 'react-bootstrap';

export default class TableColumnSelector extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isOpen: false,
    };

    this.onToggle = this.onToggle.bind(this);
  }

  onToggle(isOpen, event, type) {
    // リスト項目操作時はドロップダウンリストを閉じないための処理
    if (type.source !== 'select') {
      this.setState({ isOpen });
    }
  }

  onSelect(checked, eventKey) {
    if (this.props.onColumnSelectionChange) {
      const currentSelection = this.fetchCurrentSelections();
      this.props.onColumnSelectionChange({
        ...currentSelection,
        [eventKey]: !checked,
      });
    }
  }

  onRestoreDefaultClick() {
    if (this.props.onRestoreDefaultColumnSelectionClick) {
      this.props.onRestoreDefaultColumnSelectionClick();
    }
  }

  fetchCurrentSelections() {
    return this.props.columns.reduce((selection, p) => {
      const { dataField, hidden } = p;
      return {
        ...selection,
        [dataField]: !hidden, // ここで取得するのはVisibility状態のため、NOTを指定
      };
    }, {});
  }

  renderMenuItems() {
    return this.props.columns.map((p, i) => {
      const { label, dataField, hidden } = p;
      const checkState = !hidden;
      return (
        <MenuItem
          key={ i } eventKey={ dataField }
          className='dropdown-item'
          onSelect={ this.onSelect.bind(this, checkState) }
        >
          <SmartCheckBox
            style={ { margin: '0px' } }
            label={ label }
            checked={ checkState }
          />
        </MenuItem>
      );
    });
  }

  render() {
    const { rightAligned, dropup } = this.props;

    return (
      <Dropdown id='table-column-selector'
        open={ this.state.isOpen }
        onToggle={ this.onToggle }
        pullRight={ rightAligned }
        dropup={ dropup }
      >
        <Dropdown.Toggle
          bsStyle="link"
          bsSize="xsmall"
        >
          { i18next.t('transactions.index.columnSelection') }
        </Dropdown.Toggle>
        <Dropdown.Menu>
          { this.renderMenuItems() }
          <MenuItem divider />
          <MenuItem
            onSelect={ this.onRestoreDefaultClick.bind(this) }>
            { i18next.t('transactions.index.restoreDefaultColumnSelection') }
          </MenuItem>
        </Dropdown.Menu>
      </Dropdown>
    );
  }
}

TableColumnSelector.defaultProps = {
  columns: [],
};

TableColumnSelector.propTypes = {
  columns: PropTypes.arrayOf(
    PropTypes.shape({
      dataField: PropTypes.string,
      hidden: PropTypes.bool,
      label: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
    }),
  ).isRequired,
  dropup: PropTypes.bool, // 上方向へのドロップアップを行うかどうか
  rightAligned: PropTypes.bool, // ドロップダウンメニューの右寄せの有無
  onColumnSelectionChange: PropTypes.func, // 列の表示/非表示が再設定されたときに実行
  onRestoreDefaultColumnSelectionClick: PropTypes.func, // 初期化が選択されたときに実行
};
