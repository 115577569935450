import Api  from 'utilities/api';
import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';
import SuggestField from 'components/fields/SuggestField';
import { fetchAsync } from 'utilities/async';
import { from } from 'rxjs';

export default class DepartmentSuggestField extends PureComponent {
  constructor(props) {
    super(props);

    this.state = { departments: [] };

    this.renderOption = this.renderOption.bind(this);
  }

  componentDidMount() {
    this.fetchSubscription = from(this.initializeOptions())
      .subscribe(({ data }) => this.setState({ departments: data }));
  }

  componentWillUnmount() {
    if (this.fetchSubscription) {
      this.fetchSubscription.unsubscribe();
    }
  }

  async initializeOptions() {
    let response;
    if (this.props.belongedDepartmentOnly) {
      response = await fetchAsync(Api.members.departments.index, { memberId: this.props.ownerId });
    } else {
      response = await fetchAsync(Api.departments.index, { excludeRoot: true });
    }

    return { data: response.departments };
  }

  getOptionText(option) {
    return option && option.name;
  }

  renderOption(department) {
    const path = department.absolutePath.split('/').slice(0, -1).join('/');
    return (
      <>
        <small style={ { color:"#888888" } }>
          { path }
        </small>
        <div>
          { department.name }
        </div>
      </>
    );
  }

  render() {
    return (
      <SuggestField async={ false }
        text={ this.props.text }
        initialOptions={ this.state.departments }
        getOptionText={ this.getOptionText }
        renderOption={ this.renderOption }
        onSelect={ this.props.onSelect }
        onTextChange={ this.props.onTextChange }
      />
    );
  }
}

DepartmentSuggestField.defaultProps = {
  belongedDepartmentOnly: false,
};

DepartmentSuggestField.propTypes = {
  /** 選択対象となる部署を、渡される ownerId の所属する部署に絞るか否か */
  belongedDepartmentOnly: PropTypes.bool,
  /** belongedDepartmentOnly が true の場合に参照するユーザのID */
  ownerId: PropTypes.string,
  text: PropTypes.string.isRequired,
  onSelect: PropTypes.func.isRequired,
  onTextChange: PropTypes.func,
};
