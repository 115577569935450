import React from 'react';
import colors from 'values/colors';
import styled, { FlattenSimpleInterpolation} from 'styled-components';
import { RequiredTableDataProps, TableColumn, TableInitialStateSortBy } from 'components/renewaled_ui/Table/interface/table';
import { TableBody } from 'components/renewaled_ui/Table/molecules/TableBody';
import { TableDNDWrapper } from 'components/renewaled_ui/Table/layouts/TableDNDWrapper';
import { TableHeader } from 'components/renewaled_ui/Table/molecules/TableHeader';
import { reportItemTableStyle } from 'components/renewaled_ui/Table/layouts/ReportItemStyle';
import { useHooks } from './hooks';

interface WrapperProps {
  readonly isReportItemDesignMode: boolean;
}
const Wrapper = styled.div<WrapperProps>`
  position: relative;
  ${(p): FlattenSimpleInterpolation | string =>
    p.isReportItemDesignMode ? reportItemTableStyle : ""}
`;
interface TableWrapperProps {
  /** 最大サイズ */
  readonly maxHeight?: string;
}
const TableWrapper = styled.div<TableWrapperProps>`
  overflow: auto;
  max-height: ${(p): string => p.maxHeight || "auto"};
  position: relative;
  line-height: 23px;
  min-height: 150px;
  color: ${colors.renewaled.textBlack};
`;
interface TableViewProps {
  /** 枠線がないシンプルなデザイン */
  readonly isSimpleDesignMode?: boolean;
}
const TableView = styled.table<TableViewProps>`
  border-collapse: collapse;
  ${(p): string => (p.isSimpleDesignMode ? "" : "border: 1px solid #ddd;")}
  ${(p): string => (p.isSimpleDesignMode ? "" : "border-radius: 5px;")}
`;

interface Props<Data> {
  /** テーブルの列情報 */
  readonly columns: TableColumn[];
  /** 列の並び順 */
  readonly columnOrder?: TableColumn["accessor"][];
  /** テーブルデータ */
  readonly data: Data[];
  /** 行のチェックボックスを表示するか */
  readonly showCheckBoxes?: boolean;
  /** 列幅変更するか */
  readonly columnResizable?: boolean;
  /** 詳細閲覧中データ */
  readonly viewingData?: Data;
  /** データ読み込み中か */
  readonly isLoading?: boolean;
  /** ヘッダーを表示するか(default true) */
  readonly showHeader?: boolean;
  /** 行をクリックした際の遷移先(指定した場合aリンクになる) */
  readonly href?: string;
  /** 行のドラッグ&ドロップを有効にするか */
  readonly useRowDragAndDrop?: boolean;
  /** ボディー上部に追加される要素 */
  readonly bodyElement?: JSX.Element;
  /**
   * テーブルの最大高さ
   * maxHeighを超えた場合、ヘッダー固定でスクロールが発生する
   */
  readonly maxHeight?: string;
  /** テーブルボディーのfont-weight */
  readonly bodyFontWeight?: 400 | 500 | 600;
  /** onRowClickがあるか(行をクリック可能か) */
  readonly hasOnRowClick?: boolean;
  /** ソートの初期値 */
  readonly initialStateSortBy?: TableInitialStateSortBy[];
  /** ソート実行時のハンドラ */
  readonly onSortChange?: (sortName: string, sortOrder: "ASC" | "DESC") => void;
  /** 行のチェックボックスを選択した際のハンドラ */
  readonly onSelect?: (data: Data, isSelected: boolean) => void;
  /** ページ内の全行のチェックボックスを選択した際のハンドラ */
  readonly onSelectAll?: (isSelected: boolean) => void;
  /** 列の幅変更時のハンドラ */
  readonly onColumnWidthChange?: (id: string, width: number) => void;
  /** 行をクリックした際のハンドラ */
  readonly onRowClick?: (data: Data) => void;
  /** 行移動時のハンドラ */
  readonly onMoveRow?: (
    dragIndex: number,
    targetIndex: number,
  ) => Promise<void>;
  /**
   * 枠線がないシンプルなデザイン
   * TODO: テーブルデザインが一つになるので消える
   */
  readonly isSimpleDesignMode?: boolean;
  /**
   * 請求書刷新でのテーブルデザイン
   * TODO: テーブルデザインが一つになるので消える
   */
  readonly isReportItemDesignMode?: boolean;
}

/**
 * テーブル
 * NOTE: /components/CreateMenu/ReportCreateModalView.tsxでは
 * styled-componentsが適応されないため、classNameを使ってcss指定がされている
 */
export const Table = <Data extends RequiredTableDataProps>({
  data = [],
  columns = [],
  columnOrder,
  onSortChange,
  showCheckBoxes,
  onSelect,
  onSelectAll,
  onColumnWidthChange,
  onRowClick,
  columnResizable,
  viewingData,
  bodyFontWeight,
  maxHeight,
  showHeader = true,
  isLoading,
  href,
  useRowDragAndDrop,
  onMoveRow,
  bodyElement,
  initialStateSortBy = [],
  isSimpleDesignMode = true,
  isReportItemDesignMode = false,
}: Props<Data>): JSX.Element => {
  const {
    tableWrapperRef,
    getTableProps,
    headerGroup,
    page,
    prepareRow,
    getTableBodyProps,
    columnLength,
  } = useHooks(data, columns, columnOrder, showCheckBoxes, useRowDragAndDrop, initialStateSortBy, onSelect, onSelectAll, onColumnWidthChange);

  return (
    <TableDNDWrapper isDnd={useRowDragAndDrop}>
      <Wrapper
        className="table-component-wrapper-wrapper"
        isReportItemDesignMode={isReportItemDesignMode}
      >
        <TableWrapper
          className="table-component-wrapper"
          maxHeight={maxHeight}
          ref={tableWrapperRef}
        >
          <TableView
            className="table-component"
            isSimpleDesignMode={isSimpleDesignMode}
            {...getTableProps()}
            style={{
              width: headerGroup.getHeaderGroupProps().style?.width || "auto",
            }}
          >
            {/* ヘッダー */}
            {showHeader && (
              <TableHeader
                useRowDragAndDrop={useRowDragAndDrop}
                columnResizable={columnResizable}
                onSortChange={onSortChange}
                headerGroup={headerGroup}
                isLoading={isLoading}
                isSimpleDesignMode={isSimpleDesignMode}
                isReportItemDesignMode={isReportItemDesignMode}
              />
            )}

            {/* ボディー */}
            <TableBody
              data={data}
              bodyFontWeight={bodyFontWeight}
              hasOnRowClick={!!onRowClick}
              isSimpleDesignMode={isSimpleDesignMode}
              href={href}
              isLoading={isLoading}
              columnLength={columnLength}
              onRowClick={onRowClick}
              page={page}
              prepareRow={prepareRow}
              viewingData={viewingData}
              onMove={onMoveRow}
              useRowDragAndDrop={useRowDragAndDrop}
              getTableBodyProps={getTableBodyProps}
              bodyElement={bodyElement}
            />
          </TableView>
        </TableWrapper>
      </Wrapper>
    </TableDNDWrapper>
  );
};
