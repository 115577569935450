import React, { useRef } from "react";
import { OverlayTrigger } from "react-bootstrap";

interface Props {
  overlay: JSX.Element;
  icon: string;
  props?: { [key: string]: string | number };
}

/** オーバーレイ付きアイコン */
export const IconWithOverlay: React.FC<Props> = ({
  overlay,
  icon,
  props = {},
}) => {
  const overlayTriggerIconRef = useRef<HTMLElement | null>(null);
  const iProps = { className: `${icon}`, ...props };

  // 列の位置によって、オーバーレイが画面外に出てしまう(特にサイドバーを畳んだ時)事を防ぐため、x-positionによって出す方向を変える。
  // 上下に出すと、オーバーレイ自体がホバー位置に被ってしまい開閉動作が無限ループしてしまうので左右で出し分ける。
  const placement = (): string => {
    const iconPositionX =
      overlayTriggerIconRef.current?.getBoundingClientRect().x;

    if (iconPositionX === undefined) return "left";

    return iconPositionX >= 260 ? "left" : "right";
  };

  return (
    <OverlayTrigger rootClose placement={placement()} overlay={overlay}>
      <i {...iProps} ref={overlayTriggerIconRef}></i>
    </OverlayTrigger>
  );
};
