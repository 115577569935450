import * as FetchActionTypes from '../../actions/search/fetch';
import * as ResultActionTypes from '../../actions/search/result';
import _ from 'lodash';

const initialState = {
  // todo: 帰ってくる検索結果の形式については、別途interfaceを定義しておく
  index: {
    response: [],
    error: null,
  },
  show: {
    response: [],
    error: null,
  },
};

/**
 * フェッチしたデータはそのままでは使えないのでデシリアライズする
 */
function formatResponseData(response) {
  return response.map((v) => { return responseMapper(v); });
}

/**
 * APIのレスポンスをデシリアライズする
 */
const responseMapper = (response) => {
  return {
    amount: _.get(response, 'amount'),
    boxAddress: _.get(response, 'receiptPackage.receiptBox.address'),
    boxName: _.get(response, 'receiptPackage.receiptBox.name'),
    canNotify: _.get(response, 'canNotify', false),
    collectedAt: _.get(response, 'receiptPackage.receiptCollectionCycle.collectedAt'),
    departmentName: _.get(response, 'expense.departmentName'),
    expenseAuthorName: _.get(response, 'expense.userName'),
    id: _.get(response, 'id'),
    image: {
      id: _.get(response, 'id'),
      mimetype: _.get(response, 'mimetype'),
      url: _.get(response, 'url'),
    },
    matchingStatus: _.get(response, 'matchingStatus'),
    report: {
      id: _.get(response, 'expense.report.id'),
      preReportId: _.get(response, 'expense.report.preReportId'),
      status: _.get(response, 'expense.report.status'),
      title:  _.get(response, 'expense.report.title'),
    },
    sequenceNumber: _.get(response, 'expense.report.sequenceNum'),
    shopName: _.get(response, 'shopName'),
    storageCode: _.get(response, 'receiptPackage.storageCode'),
    subscriptionEndAt: _.get(response, 'receiptPackage.receiptCollectionCycle.subscriptionEndAt'),
    subscriptionStartAt: _.get(response, 'receiptPackage.receiptCollectionCycle.subscriptionStartAt'),
    transactedAt: _.get(response, 'transactedAt'),
    expenses: _.get(response, 'expenses', []),
  };
};

const result = (state = initialState, action) => {
  switch (action.type) {
    case FetchActionTypes.SUCCESS_INDEX_REQUEST: {
      // データサイズが異なる場合, リソースとなる配列をクリアする
      const collection = state.index.response.length !== action.payload.result.count
        ? [...new Array(action.payload.result.count)]
        : [...state.index.response];

      collection.splice(
        action.payload.result.offset,
        action.payload.result.data.length,
        ...formatResponseData(action.payload.result.data),
      );

      return {
        ...state,
        index: {
          response: collection,
          error: null,
        },
      };
    }
    case FetchActionTypes.FAILED_INDEX_REQUEST: {
      return {
        ...state,
        index: {
          ...state.index,
          error: action.payload.error,
        },
      };
    }
    case FetchActionTypes.CLEAR_INDEX_REQUEST: {
      return {
        ...state,
        index: { ...initialState.index },
      };
    }
    case FetchActionTypes.SUCCESS_SHOW_REQUEST: {
      return {
        ...state,
        show: {
          response: action.payload.result,
          error: null,
        },
      };
    }
    case FetchActionTypes.FAILED_SHOW_REQUEST: {
      return {
        ...state,
        show: {
          ...state.show,
          error: action.payload.error,
        },
      };
    }
    case ResultActionTypes.CLEAR_RESULT: {
      return { ...initialState };
    }
    default: {
      return state;
    }
  }
};

export default result;
