import NumberField from "components/fields/NumberField";
import i18next from "i18n";
import React from "react";
import styled from "styled-components";
import Formatter from "utilities/formatter";
import colors from "values/colors";
import fonts from "values/fonts";

interface TaxCategory {
  expireAt: string | null;
  id: string;
  isDeleted: boolean;
  name: string;
  startAt: string | null;
  taxRate: number;
}

interface FormValue {
  amount: number;
  taxCategory: TaxCategory;
  /* 消費税額(2023年10月施行インボイス制度対応のリリース前の経費には null が設定される) */
  taxAmount: number | null;
}

interface Props {
  values: FormValue[];
  editable: boolean;
  showName: boolean;
  onAmountChange: (taxCategoryId: string, amount: number) => void;
  onTaxAmountChange: (taxCategoryId: string, taxAmount: number) => void;
}

const TaxCategoryTableView = styled.div`
  margin-bottom: 0px;
`;

const TaxCategoryHeaderView = styled.div`
  display: flex;
  .tax-category-label-fields {
    width: 50%;
  }
  .amount-label-fields {
    width: 50%;
    display: flex;
    justify-content: space-between;
    .amount-column {
      white-space: nowrap;
      width: 100%;
    }
    .with-tax {
      padding-right: 9px;
    }
  }
  .label-text {
    font-weight: ${fonts.weight.bold};
  }
  &.uneditable {
    background: #f4f4f4;
    padding: 4px 0 2px 8px;
    border-radius: 4px;
    margin-bottom: 4px;
  }
`;

const TaxCategoryRowView = styled.div`
  display: flex;
  align-items: center;
  padding: 4px 0;
  border-bottom: 1px solid ${colors.renewaled.borderGrey};
  .tax-category-fields {
    width: 50%;
    display: flex;
    .name-column {
      overflow-wrap : break-word;
      width: calc(50% - 30px);
      min-width: 90px;
      margin-right: 8px;
      margin-left: 4px;
    }
    .rate-column {
      width: 30px
      margin-right: 8px;
    }
  }
  .amount-fields {
    width: 50%;
    display: flex;
    .amount-column {
      white-space: nowrap;
      margin-right: 4px;
      width: 100%;
    }
  }
  :nth-child(1) {
    border-top: 1px solid ${colors.renewaled.borderGrey};
  }
  &.uneditable {
    padding: 4px 8px 2px 4px;
  }
`;

const AmountWithTaxPerTaxCategoryTable: React.FC<Props> = ({
  values,
  showName,
  editable,
  onAmountChange,
  onTaxAmountChange,
}) => {
  const handleAmountChange = (taxCategoryId: string, amount: number): void => {
    onAmountChange(taxCategoryId, amount);
  };

  const handleTaxAmountChange = (
    taxCategoryId: string,
    taxAmount: number,
  ): void => {
    onTaxAmountChange(taxCategoryId, taxAmount);
  };

  const renderTaxCategoryNameColumn = (name: string): JSX.Element => {
    return <div className="name-column">{name}</div>;
  };

  const renderTaxRateColumn = (taxRate: number): JSX.Element => {
    return <div className="rate-column">{Formatter.percent(taxRate)}</div>;
  };

  const renderEditableAmountColumn = (
    taxCategoryId: string,
    amount: number,
  ): JSX.Element => {
    if (values.length > 1) {
      return (
        <div className="amount-column">
          <NumberField
            className={`form-control text-right visible`}
            allowMinus={true}
            allowZeroPadding={true}
            allowDecimal={true}
            value={amount}
            onChange={(value: number): void => {
              handleAmountChange(taxCategoryId, value);
            }}
          />
        </div>
      );
    }

    return <></>;
  };

  const renderEditableTaxAmountColumn = (
    taxCategoryId: string,
    taxAmount: number,
  ): JSX.Element => {
    return (
      <div
        className={`amount-column ${displayAmountColumnLabel && "text-right"}`}
      >
        <NumberField
          className={"form-control text-right"}
          allowMinus={true}
          allowZeroPadding={true}
          allowDecimal={true}
          value={taxAmount}
          onChange={(value: number): void => {
            handleTaxAmountChange(taxCategoryId, value);
          }}
        />
      </div>
    );
  };

  const renderTaxAmountColumn = (taxAmount: number): JSX.Element => {
    return (
      <div
        className={`amount-column ${displayAmountColumnLabel && "text-right"}`}
      >
        <p className="form-control-static">{Formatter.amount(taxAmount)}</p>
      </div>
    );
  };

  const renderAmountColumn = (amount: number): JSX.Element => {
    return (
      <div className="amount-column">
        <p className="form-control-static">{Formatter.amount(amount)}</p>
      </div>
    );
  };

  const displayAmountColumnLabel = !editable || values.length > 1;

  return (
    <TaxCategoryTableView className="list-form tax-category-table-fields">
      <TaxCategoryHeaderView
        className={`header-fields ${editable || "uneditable"}`}
      >
        <div className="tax-category-label-fields">
          {showName ? (
            <label className="label-text">
              {i18next.t("transactions.properties.taxCategoryForAmountLabel")}
            </label>
          ) : null}
        </div>
        <div className="amount-label-fields">
          {displayAmountColumnLabel && (
            <label className="amount-column label-text">
              {i18next.t("transactions.properties.amountPerTaxCategory")}
            </label>
          )}
          <label
            className={`amount-column label-text ${
              displayAmountColumnLabel && "text-right with-tax"
            }`}
          >
            {i18next.t("transactions.properties.taxAmountPerTaxCategory")}
          </label>
        </div>
      </TaxCategoryHeaderView>
      {values.map((value) => {
        const {
          taxCategory: { id, name, taxRate },
          amount,
          taxAmount,
        } = value;
        return (
          <TaxCategoryRowView
            key={id}
            className={`tax-category-row ${editable || "uneditable"}`}
          >
            <div className="tax-category-fields">
              {showName ? renderTaxCategoryNameColumn(name) : null}
              {renderTaxRateColumn(taxRate)}
            </div>
            <div className="amount-fields">
              {editable
                ? renderEditableAmountColumn(id, amount)
                : renderAmountColumn(amount)}
              {editable
                ? renderEditableTaxAmountColumn(id, taxAmount as number) // 「消費税額」リリース前に作成された経費にはtaxAmountがnumberではなくnullとなっているが、その経費に対してはそもそもこのコンポーネントは表示されない
                : renderTaxAmountColumn(taxAmount as number)}
            </div>
          </TaxCategoryRowView>
        );
      })}
    </TaxCategoryTableView>
  );
};

export default AmountWithTaxPerTaxCategoryTable;
