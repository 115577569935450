import React, { useCallback, useState } from 'react';
import ResolutionInformationsModal from '../../../applications/transactions/components/resolution_information/ResolutionInformationsModal';
import ResolutionInformationsViewer from '../../../applications/transactions/components/resolution_information/ResolutionInformationsViewer';
import SideSwitch from './SideSwitch';
import SingleSideView from './SingleSideView';
import VersionSelector from './VersionSelector';
import i18next from 'i18n';
import styled from 'styled-components';
import {
  FileSide, LocalReceiptFile, ReceiptFile, RemoteReceiptFile, resolutionInformationDisplayStyleType,
} from 'components/types/attachedFiles.types';

type DeleteCallback =  (file: ReceiptFile) => void;
type RotationCallback = (file: ReceiptFile, rotation: number) => void;
type DropCallback = (side: FileSide, file: File, isAccepted: boolean) => void;

interface Props {
  /** 先頭から新しい順 */
  versions: {
    foreside?: Array<RemoteReceiptFile>;
    backside?: Array<RemoteReceiptFile>;
  };
  /** アップロード前のファイル */
  localTemp?: {
    foreside?: LocalReceiptFile;
    backside?: LocalReceiptFile;
  };

  isVisibleResolutionInformation?: boolean;
  isEditable?: boolean;
  isElectronicReceiptImage?: boolean,
  showVersion?: boolean;
  showUploader?: boolean;
  isOriginalReceiptMatched?: boolean;
  isTimestampEnabled?: boolean;
  width?: number;
  height?: number;
  style?: React.CSSProperties;
  resolutionInformationDisplayStyle?: resolutionInformationDisplayStyleType;

  onDelete?: DeleteCallback;
  onRotate?: RotationCallback;
  onDrop?: DropCallback;
}

interface FlippableCardProps {
  isFlipped: boolean;
  h?: number; // height。Modal内で使用時に、画像がモーダル外に飛び出すことへの対策
}

const ControlBar = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 6px;

  > * {
    padding: 0 8px;

    &:first-child {
      padding-left: 0;
    }

    &:last-child {
      padding-right: 0;
    }
  }
`;

const FlippableCard = styled.div<FlippableCardProps>`
  position: relative;
  height: ${(props): string => (typeof props.h === 'number' ? `${props.h}px` : 'auto')};
  transition: transform 0.6s;
  transform: ${(props): string => `rotateY(${props.isFlipped ? '180deg' : '0deg'})`};
  transform-style: preserve-3d;
`;

function generateDownloadLink(version: RemoteReceiptFile | LocalReceiptFile | null, isTimestampEnabled: boolean): string | undefined {
  if (!isTimestampEnabled || !version || !('id' in version)) {
    return void 0;
  }

  return `/s3_files/${version.id}/timestamped`;
}

// visibleFileが階調情報プロパティ(resolutionInformations)を持つかを判定、持つ場合はRemoteReceiptFile型だとする
function checkFileIsRemoteReceiptFile(visibleFile: unknown): visibleFile is RemoteReceiptFile {
  if (visibleFile !== null && (visibleFile as RemoteReceiptFile).resolutionInformations !== undefined) return true;

  return false;
}

// visibleFile(RemoteReceiptFile型だと推論されている) が1件以上の解像度チェック結果を持っているかを判定。
// これにより、解像度チェック機能のリリース(2022/06/23) 以前に作成された経費および、現在検証中の画像については解像度検証の情報をへ表示させなくなった
function checkFileHasResolutionInformations(visibleFile: RemoteReceiptFile): boolean {
  if (visibleFile.resolutionInformations.length > 0) return true;

  return false;
}

const VersionedReceiptFile: React.FunctionComponent<Props> = (props) => {
  const {
    versions,
    localTemp,
    isVisibleResolutionInformation = false,
    isEditable = false,
    isElectronicReceiptImage = false,
    isOriginalReceiptMatched,
    isTimestampEnabled = false,
    showVersion = true,
    showUploader = true,
    width,
    height,
    style,
    resolutionInformationDisplayStyle = 'single-view',
    onDelete,
    onRotate,
    onDrop,
  } = props;

  // 最初は表面を表示
  const [side, setSide] = useState<FileSide>('foreside');
  // Chromeでobjectタグを同時に重ねて描画した場合、
  // 裏面の pointer-events を none にしても表面のマウスイベントが効かなくなる問題があるため、
  // 初回描画時は裏面を描画しないようにする。
  const [backsideInitialized, setBacksideInitialized] = useState<boolean>(false);
  const [foresideVersion, setForesideVersion] = useState<RemoteReceiptFile | null>((versions.foreside || [])[0] || null);
  const [backsideVersion, setBacksideVersion] = useState<RemoteReceiptFile | null>((versions.backside || [])[0] || null);
  const foresideFile = localTemp?.foreside || foresideVersion;
  const backsideFile = localTemp?.backside || backsideVersion;
  const visibleFile = side === 'backside' ? backsideFile : foresideFile;
  const isUploading = !!visibleFile && !('id' in visibleFile);
  const uploader = visibleFile?.uploadedBy;

  const handleSelectVersion = useCallback((receiptFile) => {
    if (side === 'foreside') {
      setForesideVersion(receiptFile);
    } else {
      setBacksideVersion(receiptFile);
    }
  }, [side]);
  const handleFlip = useCallback((nextSide) => {
    if (!isTimestampEnabled) { return; }

    setSide(nextSide);

    if (nextSide ===  'backside' && !backsideInitialized) {
      setBacksideInitialized(true);
    }
  }, [backsideInitialized, isTimestampEnabled]);

  return (
    <div style={ style }>
      { isVisibleResolutionInformation // その事業所が、事業所設定で解像度チェック情報表示をONにしているか
        && !isElectronicReceiptImage // その経費の、電子領収書フラグがOFFか
        && checkFileIsRemoteReceiptFile(visibleFile) // その経費が、永続化されているものか
        && checkFileHasResolutionInformations(visibleFile) // その経費が、解像度情報を持っているか
        && <>
          {
            resolutionInformationDisplayStyle === 'single-view'
            && <ResolutionInformationsViewer
              versions={ versions }
              />
          }
          {
            resolutionInformationDisplayStyle === 'modal'
            && <ResolutionInformationsModal
              versions={ versions }
              />
          }
        </>
      }
      <FlippableCard isFlipped={ side === 'backside' } h={ height } className={ `flippable-card ${side === 'backside' && 'flipped'}` }>
        <SingleSideView side='foreside'
          isEditable={ isEditable }
          isOriginalReceiptMatched={ isOriginalReceiptMatched }
          isVisible={ side === 'foreside' }
          downloadLink={ generateDownloadLink(foresideFile, isTimestampEnabled) }
          receiptFile={ foresideFile }
          width={ width }
          height={ height }
          onDelete={ onDelete }
          onRotate={ onRotate }
          onDrop={ onDrop }
        />

        {
          isTimestampEnabled && backsideInitialized && (
            <SingleSideView side='backside'
              isEditable={ isEditable }
              isOriginalReceiptMatched={ isOriginalReceiptMatched }
              isVisible={ side === 'backside' }
              downloadLink={ generateDownloadLink(backsideFile, isTimestampEnabled) }
              receiptFile={ backsideFile }
              width={ width }
              height={ height }
              onDelete={ onDelete }
              onRotate={ onRotate }
              onDrop={ onDrop }
            />
          )
        }
      </FlippableCard>

      {
        showUploader && (
          <p className='text-muted txt-sm text-right' style={ { marginBottom: '4px', minHeight: '17px' } }>
            { isUploading && i18next.t('transactions.messages.waitingToUpload') }
            {
              !isUploading && visibleFile
                && (uploader ? i18next.t('transactions.messages.uploadedBy', uploader) : i18next.t('transactions.messages.uploadedByYourself'))
            }
          </p>
        )
      }

      {
        isTimestampEnabled && (
          <ControlBar className='control-bar'>
            {
              showVersion && (
                <VersionSelector
                  style={ { flexGrow: 1 } }
                  versions={ versions[side] }
                    // ローカルでファイルを編集している時は、表示中の画像を変更できないようにする
                  isDisabled={ isUploading }
                  selectedVersion={ visibleFile && 'id' in visibleFile ? visibleFile : null }
                  onChange={ handleSelectVersion }
                />
              )
            }
            <SideSwitch side={ side } onChange={ handleFlip } />
          </ControlBar>
        )
      }
    </div>
  );
};

export default VersionedReceiptFile;
