import React, { useCallback } from "react";
import * as Types from "../types";
import { CategoryAsyncSelect, ProjectAsyncSelect } from "./forms";

interface Props {
  /** 分割項目 */
  separator: Types.Separator;
  /** 分割項目種別 */
  separatorType: Types.SeparatorType;
  /**
   * 分割項目変更時
   * @param separator 分割項目
   */
  onChange: (separator: Types.Separator) => void;
}

/** 分割項目選択欄 */
export const SeparatorSelector: React.FC<Props> = ({
  separatorType,
  separator,
  onChange,
}) => {
  const handleChange = useCallback(
    (item, type) => {
      // 削除時はitemがnullになるので、idに空文字をセット
      if (!item) {
        onChange({ id: "", type });
        return;
      }

      onChange({ id: item.id, type });
    },
    [onChange],
  );

  switch (separatorType) {
    case "category":
      return (
        <CategoryAsyncSelect
          categoryId={separator.id}
          onChange={(o): void => handleChange(o, separatorType)}
        />
      );
    case "project":
      return (
        <ProjectAsyncSelect
          projectId={separator.id}
          onSelect={(o): void => handleChange(o, separatorType)}
        />
      );
    default:
      return null;
  }
};
