import ApproverSearchBox from '../ApproverSearchBox';
import React from 'react';
import SelectDepartmentPostModal from '../SelectDepartmentPostModal';
import i18next from 'i18next';
import { DepartmentPostRelationshipSelectorPost, DepartmentPostRelationshipSelectorSelectedDepartmentPost } from './domain';
import { useHooks } from './hooks';

interface Props {
  /** 更新ボタン */
  updateButton: React.ReactNode;
  /** 役職一覧 */
  defaultPosts: DepartmentPostRelationshipSelectorPost[];
  /** 選択済み部署役職一覧 */
  selectedDepartmentPostRelationships: DepartmentPostRelationshipSelectorSelectedDepartmentPost[];
  /** 部署役職選択時のコールバック */
  onSelectDepartmentPostRelationship: (departmentPostRelationship: DepartmentPostRelationshipSelectorSelectedDepartmentPost) => void;
  /** 部署役職選択解除時のコールバック */
  onDeselectDepartmentPostRelationship: (departmentPostRelationship: DepartmentPostRelationshipSelectorSelectedDepartmentPost) => void
}

/**
 * 部署役職選択欄
 */
export const DepartmentPostRelationshipSelector: React.FC<Props> = ({
  updateButton,
  defaultPosts,
  selectedDepartmentPostRelationships,
  onSelectDepartmentPostRelationship,
  onDeselectDepartmentPostRelationship,
}) => {
  const {
    departments,
    searchText,
    canReadMore,
    onSearch,
    onReadMore,
    onChangeSearchText,
    onClearSearchText,
    onToggleExpandDepartment,
    onSelectDepartmentPost,
  } = useHooks(defaultPosts, selectedDepartmentPostRelationships, onSelectDepartmentPostRelationship, onDeselectDepartmentPostRelationship);

  return (
    <div>
      <ApproverSearchBox
        resetSearchText={ onClearSearchText }
        setSearchText={ onChangeSearchText }
        searchText={ searchText }
        search={ onSearch }
        placeholder={ i18next.t('groups.properties.name') }
      />
      { updateButton }
      <SelectDepartmentPostModal
        departmentPosts={ departments }
        onSelectDepartment={ onToggleExpandDepartment }
        onSelectDepartmentPost={ onSelectDepartmentPost }
      />
      {
        canReadMore && (
          <div className="text-center">
            <button className="btn btn-link" onClick={ onReadMore }>
              { i18next.t('commons.actions.loadMore') }
            </button>
          </div>
        )
      }
    </div>
  );
};
