import WebStorage from 'utilities/web_storage';

/**
 * サイドバーの開閉時にwindowでdispatchされるイベントのtype
 */
export const EVENT_TYPE_CHANGE_SIDE_BAR_COLLAPSING = "ChangeSideBarCollapsing";

/**
 * サイドバーの開閉時にwindowでdispatchされるイベントの作成
 */
function createSideBarCollapseEvent(collapse: boolean): CustomEvent<boolean> {
  return new CustomEvent(EVENT_TYPE_CHANGE_SIDE_BAR_COLLAPSING, { detail: collapse });
}

/** サイドバーの開閉状態を保存するLocalStorageのモデル */
export class SideBarCollapsedState {
  private readonly storage: WebStorage;

  private _data: boolean;

  private static instance: SideBarCollapsedState;

  public static getInstance(): SideBarCollapsedState {
    if (!SideBarCollapsedState.instance) {
      SideBarCollapsedState.instance = new SideBarCollapsedState();
    }

    return SideBarCollapsedState.instance;
  }

  private constructor(initial?: boolean) {
    this.storage = new WebStorage();

    this._data = initial ?? this.data;

    this.migrate();
  }

  get data(): boolean {
    return this._data ?? (this.storage.getItem(this.key) ?? this.defaultData);
  }

  set data(isSideBarCollapsed: boolean) {
    const oldValue = this._data;
    this._data = isSideBarCollapsed;
    this.storage.setItem(this.key, this._data);

    // 値に変更があった場合、windowでカスタムイベントを発行
    if (oldValue !== this._data) {
      window.dispatchEvent(createSideBarCollapseEvent(this._data));
    }
  }

  update(isSideBarCollapsed: boolean): void {
    this.data = isSideBarCollapsed;
  }

  private get defaultData(): boolean {
    return false;
  }

  private get key(): 'isSideBarCollapsed' {
    return 'isSideBarCollapsed';
  }

  private migrate(): void {
    this.data = this.migrateSchema(this.storage.getItem('isSideBarCollapsed'));

    this.storage.removeItem('isSideBarCollapsed');
  }

  private migrateSchema(isSideBarCollapsed: boolean): boolean {
    return isSideBarCollapsed || this.defaultData;
  }
}
