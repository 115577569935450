import React from 'react';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';

interface Props {
  /** ドラッグ可能にするか */
  readonly isDnd?: boolean;
  readonly children: JSX.Element;
}

/**
 * ReactDnd
 */
export const TableDNDWrapper: React.FC<Props> = (props) => {
  if (!props.isDnd) return props.children;

  return (
    <DndProvider backend={ HTML5Backend }>
      {props.children}
    </DndProvider>
  );
};
