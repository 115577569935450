import i18next from "i18n";
import React from "react";
import { OverlayTrigger, Popover } from "react-bootstrap";
import styled from "styled-components";
import { RequiredIcon } from "../../../../components/icons/RequiredIcon";

interface Props {
  label: JSX.Element;
}

const Spacer = styled.div`
  height: 5px;
`;

const description = i18next
  .t("accountingDataScheduledExports.tasks.descriptions.executionDay")
  .split(/(\n)/)
  .map((t, i) =>
    t === "\n" ? (
      <React.Fragment key={i}>
        <br />
        <Spacer />
      </React.Fragment>
    ) : (
      t
    ),
  );

const descriptionPopover = <Popover id="description">{description}</Popover>;

const LabelWithExecutionDayPopover: React.FC<Props> = () => {
  return (
    <label className={"control-label"}>
      実行日 <RequiredIcon />
      <OverlayTrigger
        overlay={descriptionPopover}
        placement={"right"}
        trigger={["click"]}
        rootClose={true}
      >
        <i
          className="far fa-question-circle"
          style={{ color: "#AAA", marginLeft: "6px", cursor: "pointer" }}
        />
      </OverlayTrigger>
    </label>
  );
};

export default LabelWithExecutionDayPopover;
