import Api from 'utilities/api';
import {
  FetchParams,
  IReportExpenseDriver,
  ReportExpensesResponse,
} from 'apis/IReportExpenseDriver';
import { fetchAsyncAll } from 'utilities/async';

export class ReportExpenseDriver implements IReportExpenseDriver {
  /** 経費申請に含まれる経費をすべて取得します */
  async fetchAll(apiParams: FetchParams): Promise<ReportExpensesResponse> {
    const { reportId, expensesSize } = apiParams;

    return await fetchAsyncAll(
      (params) => Api.reports.expenses({ reportId, ...params })
        .then((result) => {
          return { data: result.expenses, count: result.count };
        }),
      { isApproving: true },
      { maxFetchSize: expensesSize },
    );
  }
}
