import React from 'react';
import i18next from 'i18next';
import { AnalysisAggregationConditions } from 'utilities/api/models/accountingDataScheduledExports/AnalysisAggregationConditions';
import { IncludeItem } from './IncludeItem';
import { Item } from './Item';
import { PeriodItem } from './PeriodItem';
import { SelectedItem } from './SelectedItem';
import { TagItem } from './TagItem';

interface Props {
  analysisAggregationConditions: AnalysisAggregationConditions;
}

export const List: React.FC<Props> = ({
  analysisAggregationConditions,
}) => {
  const conditionPath = "accountingDataScheduledExports.tasks.analysisAggregationConditions";

  return (
    <>
      <PeriodItem label={ i18next.t(`${conditionPath}.startPeriod`) } value={ analysisAggregationConditions.startPeriod }></PeriodItem>
      <Item label={ i18next.t(`${conditionPath}.destination`) } value={ analysisAggregationConditions.destination }></Item>
      <PeriodItem label={ i18next.t(`${conditionPath}.submittedPeriod`) } value={ analysisAggregationConditions.submittedPeriod }></PeriodItem>
      <PeriodItem label={ i18next.t(`${conditionPath}.temporaryPaymentDuePeriod`) } value={ analysisAggregationConditions.temporaryPaymentDuePeriod }></PeriodItem>
      <PeriodItem label={ i18next.t(`${conditionPath}.transactedPeriod`) } value={ analysisAggregationConditions.transactedPeriod }></PeriodItem>
      <PeriodItem label={ i18next.t(`${conditionPath}.lastApprovedPeriod`) } value={ analysisAggregationConditions.lastApprovedPeriod }></PeriodItem>
      <SelectedItem label={ i18next.t(`${conditionPath}.applicantGroup`) } value={ analysisAggregationConditions.applicantGroup }></SelectedItem>
      <SelectedItem label={ i18next.t(`${conditionPath}.applicantPost`) } value={ analysisAggregationConditions.applicantPost }></SelectedItem>
      <SelectedItem label={ i18next.t(`${conditionPath}.approvedApprover`) } value={ analysisAggregationConditions.approvedApprover }></SelectedItem>
      <SelectedItem label={ i18next.t(`${conditionPath}.paymentGroup`) } value={ analysisAggregationConditions.paymentGroup }></SelectedItem>
      <Item label={ i18next.t(`${conditionPath}.projectId`) } value={ analysisAggregationConditions.projectId }></Item>
      <Item label={ i18next.t(`${conditionPath}.projectName`) } value={ analysisAggregationConditions.projectName }></Item>
      <Item label={ i18next.t(`${conditionPath}.registratedNumber`) }
        value={ analysisAggregationConditions.emptyRegistratedNumber ? <>{ i18next.t('transactions.index.searchbox.blankSearch') }</> : analysisAggregationConditions.registratedNumber }>
      </Item>
      <IncludeItem label={ 'asInvoice' } conditions={ analysisAggregationConditions }></IncludeItem>
      <IncludeItem label={ 'departmentSearchRange' } conditions={ analysisAggregationConditions }></IncludeItem>
      <TagItem label={ i18next.t(`${conditionPath}.tags`) } value={ analysisAggregationConditions.tags }></TagItem>
      <IncludeItem label={ 'departmentSearchRange' } conditions={ analysisAggregationConditions }></IncludeItem>
      <IncludeItem label={ 'aggregationStatus' } conditions={ analysisAggregationConditions }></IncludeItem>
      <IncludeItem label={ 'exportationStatus' } conditions={ analysisAggregationConditions }></IncludeItem>
      <IncludeItem label={ 'paymentStatus' } conditions={ analysisAggregationConditions }></IncludeItem>
      <IncludeItem label={ 'temporaryPaymentStatus' } conditions={ analysisAggregationConditions }></IncludeItem>
      <IncludeItem label={ 'payOffStatus' } conditions={ analysisAggregationConditions }></IncludeItem>
      <IncludeItem label={ 'matchedOriginalReceiptStatus' } conditions={ analysisAggregationConditions }></IncludeItem>
    </>
  );
};
