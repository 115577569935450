import React, { useState } from 'react';
import i18next from 'i18next';
import { Button } from 'components/renewaled_ui/buttons';
import { debounce } from 'lodash';

interface Props {
  /** 下書き保存ボタン押下時のハンドラ */
  readonly onSaveDraft: () => void;
  /** 選択した経費のIDの配列 */
  readonly selectedIds: string[];
}

/**
 * 申請書の下書き保存ボタン
 */
export const SaveAsDraftButton: React.FC<Props> = React.memo(({ onSaveDraft, selectedIds }) => {
  const [hovered, setHovered] = useState(false);

  const description = (): JSX.Element => {
    if (selectedIds.length) {
      return (
        <div style={ { textAlign: 'left' } }>
          <div>{ i18next.t(`reports.requests.createReportWithSelectedExpenses`) }</div>
          <div>{ i18next.t(`reports.requests.youCanAddExpenseLaterAndRequest`) }</div>
        </div>
      );
    }

    return (
      <div style={ { textAlign: 'left' } }>
        <div>{ i18next.t(`reports.requests.createBlankReport`) }</div>
        <div>{ i18next.t(`reports.requests.youCanAddExpenseLaterAndRequest`) }</div>
      </div>
    );
  };

  const debouncedHandleMouseOver = debounce((): void => setHovered(true), 200);

  const handlOnMouseLeave = (): void => {
    setHovered(false);
    debouncedHandleMouseOver.cancel();
  };

  return (
    <div
      style={ { position: 'relative' } }
      onMouseOver={ debouncedHandleMouseOver }
      onMouseOut={ handlOnMouseLeave }
    >
      <Button
        styleType='secondary'
        onClick={ onSaveDraft }
      >
        { i18next.t(`reports.requests.saveAsDraft`) }
        <i
          className='far fa-question-circle'
          style={ {
            marginLeft: '4px',
            marginBottom: '1px',
          } }
        />
      </Button>

      { hovered && (
        <div className="tool-tip center">
          <div className='tool-tip-content'>{ description() }</div>
        </div>
      ) }
    </div>
  );
});
