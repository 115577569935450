import React, { FC } from 'react';
import { OverlayTrigger, Popover } from 'react-bootstrap';

interface List {
  id: string;
  isChecked: boolean;
  name: string;
  popoverText?: string;
}

interface Props {
  list: List[];
  listClicked: (id: string, isChecked: boolean) => void;
}

const ListSelector: FC<Props> = (props) => {
  const { list, listClicked } = props;

  return (
    <>
      {list.map((element) => (
        <div
          className='approve-selector txt-pointer'
          key={ element.id }
          onClick={ (): void => listClicked(element.id, !element.isChecked) }
        >
          <div className='approve-selector__name'>
            {element.name}
            {!!element.popoverText && (
              <OverlayTrigger
                overlay={ <Popover id='listSelector'>{element.popoverText}</Popover> }
                placement='top'
                trigger={ ['hover', 'click', 'focus'] }
              >
                <i className='far fa-question-circle' style={ {
                  color: '#AAA',  cursor: 'pointer', fontSize: '14px', marginLeft: '14px',
                } } />
              </OverlayTrigger>
            )}
          </div>
          <input
            checked={ element.isChecked }
            className='approve-selector__input'
            id={ `check-${element.id}` }
            readOnly
            type='checkbox'
          />
        </div>
      ))}
    </>
  );
};

export default ListSelector;
