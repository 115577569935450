const prefix = `dynamic_form`;

import UPLOAD_STATUS from 'values/upload_status';


export const SET_VALUE = `${prefix}/SET_VALUE`;
export function setValue(index, value) {
  return {
    type: SET_VALUE,
    index,
    value,
  };
}

export const SET_FORM = `${prefix}/SET_FORM`;
export function setForm(formItems) {
  return {
    type: SET_FORM,
    formItems,
  };
}


export function validate(items) {
  const emptyItems = items.filter((item) => item.isRequired && (item.value.length === 0 || item.value[0].length === 0));
  if (emptyItems.length > 0) {
    throw new Error(`${emptyItems[0].label}を入力して下さい`);
  }

  const uploadingItems = items.filter((item) => item.type === "attachment" && (item.value[0] && item.value[0].status === UPLOAD_STATUS.uploading));
  if (uploadingItems.length > 0) {
    throw new Error(`${uploadingItems[0].label}のアップロード中です。アップロード完了までしばらくお待ちください`);
  }

  const failedItems = items.filter((item) => item.type === "attachment" && (item.value[0] && item.value[0].status === UPLOAD_STATUS.failed));
  if (failedItems.length > 0) {
    throw new Error(`${failedItems[0].label}を再アップロードして下さい`);
  }
}
