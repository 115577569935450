import CustomSuggestInput from "components/CustomSuggestInput";
import * as actions from "containers/actions/customSuggest";
import get from "lodash/get";
import { connect } from "react-redux";
import * as preReportActions from "../actions/preReportActions";
import makeCustomInputStateMapper from "../selectors/customInput";

function mapDispatchToProps(dispatch, ownProps) {
  return {
    fetchSuggestions(params = null, customInput = null) {
      dispatch(
        actions.fetchCustomInputList(ownProps.field, params, customInput),
      );
    },
    onTextChange(text, total) {
      dispatch(preReportActions.setMetadata(ownProps.field.id, text));
      dispatch(
        actions.updateCustomInputSuggestions(ownProps.field, text, total),
      );
    },
    onSelect(suggestion) {
      dispatch(
        preReportActions.setMetadata(
          ownProps.field.id,
          get(suggestion, "id", ""),
        ),
      );
    },
    clearInput() {
      dispatch(preReportActions.setMetadata(ownProps.field.id, null));
      dispatch(actions.resetCustomInputSuggestions(ownProps.field));
    },
  };
}

export default connect(
  makeCustomInputStateMapper,
  mapDispatchToProps,
)(CustomSuggestInput);
