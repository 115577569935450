import CategoryRecursiveSuggestField, {
  Category,
} from "components/fields/CategoryRecursiveSuggestField";
import FormValidation from "components/FormValidation";
import LabeledComponent from "components/LabeledComponent";
import i18next from "i18n";
import React, { FC } from "react";

interface Props {
  category: Category | null;
  disabled: boolean;
  errors: string[];
  onSelectCategory: (category: Category | null) => void;
}

const PreExpenseCategoryForm: FC<Props> = (props) => {
  return (
    <LabeledComponent
      className="form-group"
      labelClass="col-sm-3"
      label={i18next.t("transactions.properties.category")}
    >
      <div className="col-sm-6">
        <FormValidation messages={props.errors}>
          <CategoryRecursiveSuggestField
            disable={props.disabled}
            onlyUsePreReportItem={true}
            value={props.category}
            onSelect={props.onSelectCategory}
          />
        </FormValidation>
      </div>
    </LabeledComponent>
  );
};

export default PreExpenseCategoryForm;
