import FareFormatter from "applications/fare_transactions/fare_formatter";
import i18next from "i18n";
import React from "react";
import Formatter from "utilities/formatter";

interface Companion {
  number?: number;
  isCompanionNumber: boolean;
}
interface Field {
  type: string;
  show: boolean;
}
interface Option {
  selected?: {
    withTeiki: boolean;
  };
}

function renderAmountInputTemplate(
  component: JSX.Element,
  amountPerPerson: number,
  disabled: boolean,
): JSX.Element {
  return (
    <div className="form-inline" style={{ width: "100%" }}>
      <div
        style={{ display: "inline-block", width: disabled ? "100%" : "50%" }}
      >
        {component}
      </div>
      {disabled ? null : (
        <span style={{ marginLeft: "10px" }} className="text-muted">
          {i18next.t("transactions.properties.amountPerPerson", {
            amount: amountPerPerson,
          })}
        </span>
      )}
    </div>
  );
}

function rednerRouteDisplayTemplate(
  amount: number,
  amountPerPerson: number,
  disabled: boolean,
  option?: Option,
): JSX.Element {
  const formattedAmount = FareFormatter.renderFare(
    amount,
    option?.selected?.withTeiki || false,
    "right",
  );
  const formattedAmountText = (
    <p
      style={{ display: "inline" }}
      className="txt txt-lg validate[funcCallRequired[validateFare]] route-show-field"
    >
      {formattedAmount}
    </p>
  );
  const amountPerPersonText = (
    <p
      style={{ display: "inline", marginLeft: "10px" }}
      className="text-muted txt-lg"
    >
      {i18next.t("transactions.properties.amountPerPerson", {
        amount: Formatter.amount(amountPerPerson),
      })}
    </p>
  );

  if (disabled) {
    return formattedAmountText;
  }

  return (
    <div className="">
      {formattedAmountText}
      {amountPerPersonText}
    </div>
  );
}

function renderTransactionTemplate(
  component: JSX.Element,
  amountPerPerson: number,
  disabled: boolean,
): JSX.Element {
  if (disabled) {
    return component;
  }

  return (
    <span>
      <span> {component}</span>
      <span style={{ marginLeft: "10px" }} className="text-muted">
        {i18next.t("transactions.properties.amountPerPerson", {
          amount: Formatter.amount(amountPerPerson),
        })}
      </span>
    </span>
  );
}

export default function renderAmountPerPersonWrapper(
  companions: Array<Companion>,
  amount: number,
  fields: Array<Field>,
  component: JSX.Element,
  display: boolean,
  type: string,
  option?: Option,
): JSX.Element {
  const companionAvailable = fields.some(
    (f) => f.type === "companion_input" && f.show,
  );
  const companionNum = companions.reduce(
    (a, c) => a + (c.isCompanionNumber ? Number(c.number) : 1),
    0,
  );

  const amountPerPerson = Math.ceil(amount / companionNum);
  const amountPerPersondisable =
    !companionAvailable ||
    companionNum === 0 ||
    !display ||
    Number(amount) === 0;

  switch (type) {
    case "route_display":
      return rednerRouteDisplayTemplate(
        amount,
        amountPerPerson,
        amountPerPersondisable,
        option,
      );
    case "transaction":
      return renderTransactionTemplate(
        component,
        amountPerPerson,
        amountPerPersondisable,
      );
    case "amount_input":
    default:
      return renderAmountInputTemplate(
        component,
        amountPerPerson,
        amountPerPersondisable,
      );
  }
}
