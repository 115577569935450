import React from "react";

interface Props {
  sequence: string;
}

/** 汎用申請の申請ID */
export const SequenceIdField: React.FC<Props> = React.memo(({ sequence }) => {
  return (
    <input defaultValue={sequence} className="form-control toggle" disabled />
  );
});
