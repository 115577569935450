import AllowanceInput from "applications/allowances/components/AllowanceInput";
import * as transactionFormPolicy from "applications/transactions/utilities/transactionFormPolicy";
import AutoSuggestWrapper from "components/auto_suggest_wrapper";
import CompanionSelector from "components/companion_selector";
import ConfirmationModal from "components/ConfirmationModal";
import { AssignableReportFieldWithInputModal } from "components/fields/assignable_reports/AssignableReportFieldWithInputModal";
import ProjectField from "components/fields/ProjectField";
import ElectronicReceiptAlert from "components/fields/receipt_files/ElectronicReceiptAlert";
import VersionedReceiptFile from "components/fields/receipt_files/VersionedReceiptFile";
import SelectField from "components/fields/SelectField";
import FlipSwitch from "components/FlipSwitch";
import GenericFieldsSelector from "components/generic_fields_selector";
import { ListForm } from "components/renewaled_ui/form_views";
import TextArea from "components/TextArea";
import Decimal from "decimal.js";
import i18next from "i18n";
import _camelCase from "lodash/camelCase";
import _get from "lodash/get";
import isNil from "lodash/isNil";
import isNumber from "lodash/isNumber";
import _merge from "lodash/merge";
import _sortBy from "lodash/sortBy";
import moment from "moment";
import PropTypes from "prop-types";
import React, { Component } from "react";
import { components } from "react-select";
import styled from "styled-components";
import transactionType from "types/transaction";
import flash from "utilities/flash";
import {
  getMessageFromResponse,
  initIntlCurrencyObj,
  resetValidateMessage,
} from "utilities/Utils";
import TRANSACTION_STATUS from "values/transaction_status";
import RouteInputField from "../../fare_transactions/containers/RouteInputField";
import AmountInput from "../containers/AmountInput";
import CurrencyAndRateInput from "../containers/CurrencyAndRateInput";
import CustomInputField from "../containers/CustomInputField";
import PayerDepartmentInput from "../containers/PayerDepartmentInput";
import * as memoBuilder from "../utilities/transactionMemoBuilder";
import { AmountPerTaxCategoryCollectionFormField } from "./EntryForms/AmountPerTaxCategoryCollectionFormField";
import { EligibleInvoiceFormField } from "./EntryForms/EligibleInvoiceFormField";
import MultipleDateField from "./MultipleDateField";
import WithholdingInput from "./WithholdingInput";

const showPaperlessMatchingButtons =
  userPreferences.isPaperlessPlan && isNil(userPreferences.agent);
const rightDrawerButtomOffset = showPaperlessMatchingButtons ? 176 : 140;
const EntryFormsView = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  margin: 0 auto;
  .left-area {
    width: ${({ isNew }) => (isNew ? "60%" : "67%")};
    padding-right: 8px;
    margin-right: 24px;
    max-height: ${({ isNew }) =>
      isNew
        ? "calc(100vh - 245px)"
        : `calc(100vh - ${rightDrawerButtomOffset}px)`};
    overflow-y: scroll;
  }
  .right-area {
    width: ${({ isNew }) => (isNew ? "40%" : "33%")};
    // 右ドロワーの場合、チャットアイコン分の隙間確保
    padding-bottom: ${({ isNew }) => (isNew ? "0" : "60px")};
    max-height: ${({ isNew, inModal }) =>
      isNew
        ? "calc(100vh - 245px)"
        : `calc(100vh - ${inModal ? "176" : rightDrawerButtomOffset}px)`};
    overflow-y: auto;
    padding-right: 8px;
  }
  .full-area {
    width: 100%;
  }
`;

const OriginAndDestinationView = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  i {
    padding: 5px 16px;
    color: #757575;
    &:hover {
      color: #f39c12;
      cursor: pointer;
    }
  }
`;

export class EntryForms extends Component {
  constructor(props) {
    super(props);

    this.state = {
      showCancelReceiptMatchingConfirmationModal: false,
      isBackside: false,
      selectedImage: null,
      /** 領収書画像表示エリアの高さ */
      receiptImageAreaHeight: 500,
    };

    this.handleShopNameChange = this.handleShopNameChange.bind(this);
    this.handleTransitPayeeChange = this.handleTransitPayeeChange.bind(this);
    this.handleOriginByCategoryChange =
      this.handleOriginByCategoryChange.bind(this);
    this.handleDestinationByCategoryChange =
      this.handleDestinationByCategoryChange.bind(this);
    this.handleVisitByCategoryChange =
      this.handleVisitByCategoryChange.bind(this);
    this.handlePurposeByCategoryChange =
      this.handlePurposeByCategoryChange.bind(this);
    this.handleAsEligibleInvoiceChange =
      this.handleAsEligibleInvoiceChange.bind(this);
    this.handleRegistratedNumberChange =
      this.handleRegistratedNumberChange.bind(this);
    this.handleRegistratedNumberVerify =
      this.handleRegistratedNumberVerify.bind(this);
    this.handlePaidAddressChange = this.handlePaidAddressChange.bind(this);
    this.handleExchangeButtonClick = this.handleExchangeButtonClick.bind(this);
    this.handleCommentChange = this.handleCommentChange.bind(this);
    this.handleAddressChange = this.handleAddressChange.bind(this);
    this.handleWithholdingChange = this.handleWithholdingChange.bind(this);
    this.handleFullNameChange = this.handleFullNameChange.bind(this);
    this.handleCategorySuggestionSelected =
      this.handleCategorySuggestionSelected.bind(this);
    this.handleCategoryChange = this.handleCategoryChange.bind(this);
    this.handleClearCategory = this.handleClearCategory.bind(this);
    this.handleAllowanceInputChange =
      this.handleAllowanceInputChange.bind(this);
    this.isEditedMemo = this.isEditedMemo.bind(this);
    this.canRead = this.canRead.bind(this);
    this.validationClass = this.validationClass.bind(this);
    this.handleImageDelete = this.handleImageDelete.bind(this);
    this.handleImageSelect = this.handleImageSelect.bind(this);
    this.handleImageConfirm = this.handleImageConfirm.bind(this);
    this.updateReceiptImageAreaHeight =
      this.updateReceiptImageAreaHeight.bind(this);
    this.debouncedUpdateReceiptImageAreaHeight = this.debounce(
      this.updateReceiptImageAreaHeight,
      200,
    );
  }

  componentDidMount() {
    this.updateReceiptImageAreaHeight();
    window.addEventListener(
      "resize",
      this.debouncedUpdateReceiptImageAreaHeight,
    );
  }

  componentWillUnmount() {
    window.removeEventListener(
      "resize",
      this.debouncedUpdateReceiptImageAreaHeight,
    );
  }

  componentDidUpdate(prevProps) {
    if (
      Object.keys(prevProps.withholdingConstants).length > 0 &&
      Object.keys(this.props.withholdingConstants).length === 0
    ) {
      this.props.fetchWithholdingConstants();
    }
    const { isNew, formId, allowanceTable, formValues, defaultCurrencyId } =
      this.props;

    if (isNew && formId === "allowance" && !isNil(allowanceTable)) {
      const amounts = _get(formValues, "amount_per_tax_category_input");
      const allowanceAmount = this.calcAmountOfAllowance(
        defaultCurrencyId,
        allowanceTable,
        {},
        {},
      );
      if (
        amounts.length > 0 &&
        amounts.every((a) => a.amount === 0) &&
        allowanceAmount > 0
      ) {
        this.props.setAmount(allowanceAmount);
      }
    }
    if (this.willUpdateFormFields(prevProps)) {
      this.updateReceiptImageAreaHeight();
    }
  }

  /**
   * フォームのフィールドが更新されるかどうかを返します。
   */
  willUpdateFormFields(prevProps) {
    return this.props.fields.length !== prevProps.fields.length;
  }

  /**
   * 領収書画像エリアの高さを更新します。
   */
  updateReceiptImageAreaHeight() {
    this.setState({
      ...this.state,
      receiptImageAreaHeight: this.calcImageDropAreaHeight(),
    });
  }

  /**
   * 領収書画像エリアの高さを計算します。
   */
  calcImageDropAreaHeight() {
    const formHeight =
      document.querySelector(".transaction-form")?.clientHeight;

    if (!formHeight) {
      return undefined;
    }
    if (this.props.isNew) {
      // 100px は再利用入力のUIの高さ
      return formHeight - 100;
    }
    // 80px はバージョンや裏表切替のUIの高さ
    return formHeight - 80;
  }

  /**
   * デバウンス処理を実施します。
   * @param {*} func 関数
   * @param {*} wait 待機時間
   * @returns
   */
  debounce(func, wait) {
    let timeout;
    return (...args) => {
      clearTimeout(timeout);
      timeout = setTimeout(() => func.apply(this, args), wait);
    };
  }

  /**
   * 電子領収書の添付を促すアラートを表示するかどうかを返します。
   */
  get showElectronicReceiptAlert() {
    const { formValues, isEditable } = this.props;

    return (
      _get(formValues, "electronic_receipt_image_flag", false) && isEditable
    );
  }

  getActualTaxCategoryName(inputField) {
    const { taxCategoryName, taxCategory, formValues } = this.props;
    const p = _get(formValues[inputField.type], "name", "");
    const q = _get(formValues, "category_input.taxCategory.name", "");

    if (!isNil(taxCategoryName)) return taxCategoryName;
    if (!isNil(taxCategory)) return _get(taxCategory, "name", "");
    return p || q;
  }

  handleDateChange(dates) {
    resetValidateMessage(`.transacted-at-input-field`);
    const expenseField = this.props.fields.find(
      (f) => f.type === "expense_input" && f.show,
    );

    if (isNil(dates) || dates.length === 0 || dates instanceof Array) {
      this.props.onDateChange(dates);
      return;
    }
    // 価格が入力出来ない場合は、UI上で通貨の設定が出来ないので、読み込まない
    if (expenseField && _get(expenseField, "editable")) {
      // 通貨再取得、経費金額再計算、税区分取得の順に実行させるため、
      // このタイミングでは利用日を変更しない。
      this.props.reloadCurrencies(this.props.currencyId, dates);
    } else {
      // 交通費、日当は expenseField が undefined となるため、
      // 通貨再取得を実施せず、利用日のみ変更する。
      this.props.onDateChange(dates);
    }
  }

  handleInputChange(e, fn) {
    fn(e.target.value);
  }

  handleShopNameChange(e) {
    resetValidateMessage(`.shop-input-field`);
    this.handleInputChange(e, this.props.onShopNameChange);
  }

  handleOriginByCategoryChange(e, destinationByCategory) {
    this.props.onOriginByCategoryChange(e.target.value, destinationByCategory);
  }

  handleDestinationByCategoryChange(e, destinationByCategory) {
    this.props.onDestinationByCategoryChange(
      e.target.value,
      destinationByCategory,
    );
  }

  handleVisitByCategoryChange(e) {
    this.handleInputChange(e, this.props.onVisitByCategoryChange);
  }

  handlePurposeByCategoryChange(e) {
    this.handleInputChange(e, this.props.onPurposeByCategoryChange);
  }

  handleAsEligibleInvoiceChange(asEligibleInvoice) {
    this.props.onAsEligibleInvoiceChange(asEligibleInvoice);
  }

  handleRegistratedNumberChange(registratedNumber) {
    this.props.onRegistratedNumberChange(registratedNumber);
  }

  handleRegistratedNumberVerify() {
    this.props.onRegistratedNumberVerify();
  }

  handlePaidAddressChange(paidAddress) {
    this.props.onPaidAddressChange(paidAddress);
  }

  handleExchangeButtonClick(originByCategory, destinationByCategory) {
    this.props.onExchangeButtonClick(originByCategory, destinationByCategory);
  }

  handleTransitPayeeChange(e) {
    this.handleInputChange(e, this.props.onTransitPayeeChange);
  }

  handleCommentChange(e) {
    this.handleInputChange(e, this.props.onCommentChange);
  }

  handleWithholdingChange(e) {
    this.handleInputChange(e, this.props.onWithholdingChange);
  }

  handleAddressChange(e) {
    this.handleInputChange(e, this.props.onAddressChange);
  }

  handleFullNameChange(e) {
    this.handleInputChange(e, this.props.onFullNameChange);
  }

  handleMerge(e) {
    e.preventDefault();
    const withAggregation = !isNil(this.props.mergeableAggregation);

    this.props.mergeTransaction(withAggregation, this.props.onSubmit);
  }

  handleCategorySuggestionSelected(
    e,
    { suggestion, suggestionValue, method },
    index,
  ) {
    if (!this.isEditedMemo()) {
      this.props.onCommentChange(
        memoBuilder.memoTemplate(
          _get(userPreferences, "preference.commentFormat", ""),
          _get(suggestion, "memoTemplate", ""),
        ),
      );
    }
    this.props.onCategorySuggestionSelected(
      e,
      { suggestion, suggestionValue, method },
      index,
    );
  }

  handleClearCategory(index) {
    const { isNew, allowanceTable, formValues } = this.props;
    const isAllowance = !isNil(allowanceTable);
    const asInvoiceProcess = ["01", "02"].includes(
      formValues?.eligible_invoice_confirmation_input?.invoicingOrganization
        ?.process ?? "",
    ); // 適格請求書発行事業者が、適格な事業所として扱われる時(= 処理区分が 01 もしくは 02 )

    if (!this.isEditedMemo()) {
      this.props.onCommentChange(
        _get(userPreferences, "preference.commentFormat", null),
      );
    }

    this.props.clearCategory(
      this.props.category,
      index,
      _get(this.props.category, "selectable"),
      asInvoiceProcess,
      isNew,
      isAllowance,
    );
  }

  handleCategoryChange(text, index) {
    resetValidateMessage(`.category-input-field`);
    const { suggestions, isNew, allowanceTable, formValues } = this.props;
    const isAllowance = !isNil(allowanceTable);
    const asInvoiceProcess = ["01", "02"].includes(
      formValues?.eligible_invoice_confirmation_input?.invoicingOrganization
        ?.process ?? "",
    ); // 適格請求書発行事業者が、適格な事業所として扱われる時(= 処理区分が 01 もしくは 02 )

    if (!this.isEditedMemo()) {
      this.props.onCommentChange(
        _get(userPreferences, "preference.commentFormat", null),
      );
    }

    this.props.onCategoryChange(
      text,
      suggestions,
      index,
      asInvoiceProcess,
      isNew,
      isAllowance,
    );
  }

  handleAllowanceInputChange(allowanceTable, key, value) {
    this.props.setAllowanceInput(allowanceTable, key, value);
    const { defaultCurrencyId } = this.props;
    this.props.setAmount(
      this.calcAmountOfAllowance(defaultCurrencyId, allowanceTable, key, value),
    );
  }

  selectCell(factorTypes, factorIds, cells) {
    const key = factorTypes.map((x) => `[${factorIds[x.id]}]`).join("");
    return _get(cells, key, {});
  }

  /**
   * 日当の総額を計算する
   * TODO: AllowanceInput 関連のコードに移行し、合計金額を formData.amount と同期するようにする。
   *       以下の関数は、AllowanceInput 関連の reducer(allowances/reducers/transactions.js),
   *       renderAmountInput(AllowanceInput.jsx)からコピーしているため、重複している。
   *
   * @param {Object} defaultCurrencyId
   * @param {Object} allowanceTable
   * @param {String} key
   * @param {Object} value
   */
  calcAmountOfAllowance(defaultCurrencyId, allowanceTable, key, value) {
    // 日当の入力値を求める
    let input = null;
    if (key === "factorIds") {
      const nextFactorIds = {
        ...allowanceTable.input.factorIds,
        ...value,
      };

      const cell = this.selectCell(
        allowanceTable.factorTypes,
        nextFactorIds,
        allowanceTable.cells,
      );

      input = {
        ...allowanceTable.input,
        factorIds: nextFactorIds,
        variableInputs: {
          ...allowanceTable.input.variableInputs,
          [allowanceTable.input.tableVariableId]: {
            id: cell.id,
            value: cell.value,
            editable: cell.editable,
          },
        },
      };
    } else {
      let nextValue = null;

      if (key === "variableInputs") {
        nextValue = _merge({ ...allowanceTable.input[key] }, { ...value });
      } else {
        nextValue = value;
      }

      input = {
        ...allowanceTable.input,
        [key]: nextValue,
      };
    }
    // 合計額を求める
    const allowanceInput = new AllowanceInput();
    const amount = allowanceInput.calcAmountFromAst(
      allowanceTable.calculationFormula.ast,
      input.variableInputs,
    );
    const currencyOption =
      initIntlCurrencyObj(defaultCurrencyId).resolvedOptions();
    const power = 10 ** (currencyOption.maximumFractionDigits || 0);
    const totalAmount = amount.times(new Decimal(power)).floor() / power;

    return totalAmount;
  }

  isEditedMemo() {
    return memoBuilder.isEditedMemo(
      this.props.comment,
      _get(userPreferences, "preference.commentFormat", ""),
      _get(this.props, "category.memoTemplate", ""),
    );
  }

  validationClass(field) {
    if (!field.isRequired) {
      return "";
    }

    const validations = [];
    if (!["project_input", "select_input"].includes(field.type)) {
      validations.push("required");
    }

    if (
      [
        "category_input",
        "super_category_input",
        "tax_category_input",
        "project_input",
        "select_input",
        "cost_allocation_input",
      ].includes(field.type)
    ) {
      const funcName = _camelCase(
        `validate_${field.type.replace("_input", "")}`,
      );
      validations.push(`funcCallRequired[${funcName}]`);
    }

    return `validate[${validations.join(", ")}]`;
  }

  canRead(field) {
    const {
      formValues,
      status,
      authority,
      reportId,
      preReportId,
      isMultipleEditing,
    } = this.props;

    return transactionFormPolicy.canRead(field, {
      status,
      authority,
      reportId,
      preReportId,
      isMultipleEditing,
      ...formValues,
    });
  }

  renderTransactionDateInput(field) {
    const { formValues, isNew } = this.props;

    return (
      <MultipleDateField
        key={field.sort}
        className={`form-control transacted-at ${this.validationClass(
          field,
        )} transacted-at-input-field`}
        isMultiSelectable={isNew}
        value={formValues[field.type] || []}
        editable={field.editable}
        onValueChanged={this.handleDateChange.bind(this)}
      />
    );
  }

  renderAmountInput(field) {
    const { formValues, fields } = this.props;

    return (
      <AmountInput
        key={field.sort}
        amount={_get(formValues[field.type], "amount", "")}
        originalAmount={_get(formValues[field.type], "originalAmount", "")}
        exchangeRate={_get(formValues[field.type], "exchangeRate")}
        exchangePolicy={_get(formValues[field.type], "exchangePolicy")}
        setOriginalAmount={this.props.setOriginalAmount}
        setAmount={this.props.setAmount}
        setCurrency={this.props.setCurrency}
        currencyId={this.props.currencyId}
        disabled={!field.editable}
        companions={formValues.companion_input}
        fields={fields}
      />
    );
  }

  renderCurrencyAndRateInput(field) {
    const { formValues } = this.props;
    const currencies = [...userPreferences.preference.currency.exchangeRates];
    currencies.push(userPreferences.preference.currency.defaultCurrency);

    return (
      <CurrencyAndRateInput
        key={field.sort}
        currencies={currencies}
        exchangeRate={_get(formValues[field.type], "exchangeRate")}
        editable={field.editable}
      />
    );
  }

  renderAllowanceInput(field) {
    const { allowanceTable, defaultCurrencyId, formValues, fields } =
      this.props;
    if (isNil(allowanceTable)) {
      return null;
    }

    const { cells, calculationFormula, input, factorTypes } = allowanceTable;

    return (
      <AllowanceInput
        key={field.sort}
        defaultCurrencyId={defaultCurrencyId}
        factorTypes={factorTypes}
        tableCells={cells}
        calculationFormula={calculationFormula}
        factorIds={input.factorIds}
        variableInputs={input.variableInputs}
        tableCellId={input.cellId}
        amount={input.amount}
        onChange={(key, value) => {
          this.handleAllowanceInputChange(allowanceTable, key, value);
        }}
        setOriginalAmount={this.props.setOriginalAmount}
        setCurrency={this.props.setCurrency}
        disabled={!field.editable}
        companions={formValues.companion_input}
        fields={fields}
      />
    );
  }

  renderShopNameInput(field) {
    const { formValues } = this.props;

    return (
      <div style={{ position: "relative" }}>
        <input
          key={field.sort}
          type="text"
          className={`form-control select-${field.id} ${this.validationClass(
            field,
          )} shop-input-field`}
          value={formValues[field.type] || ""}
          onChange={this.handleShopNameChange}
          disabled={!field.editable}
        />
      </div>
    );
  }

  renderWithholdingInput(field) {
    const {
      formValues,
      withholdingConstants,
      onWithholdingAutoChange,
      fromPreTransaction,
      isNew,
      isEditingWithholdingCategory,
      transportCalculatedAmount,
    } = this.props;

    return (
      <WithholdingInput
        field={field}
        constants={withholdingConstants}
        value={isNil(formValues[field.type]) ? "" : formValues[field.type]}
        amount={
          _get(formValues, "expense_input.amount") ||
          transportCalculatedAmount ||
          _get(formValues, "route_input.amount") ||
          0
        }
        onChange={this.handleWithholdingChange}
        onAutoChange={onWithholdingAutoChange}
        fromPreTransaction={fromPreTransaction}
        isNew={isNew}
        isEditingWithholdingCategory={isEditingWithholdingCategory}
      />
    );
  }

  renderAddressInput(field) {
    const { formValues } = this.props;

    return (
      <TextArea
        key={field.sort}
        className="form-control"
        value={formValues[field.type] || ""}
        onChange={this.handleAddressChange}
        disabled={!field.editable}
      />
    );
  }

  renderFullNameInput(field) {
    const { formValues } = this.props;

    return (
      <input
        key={field.sort}
        type="text"
        className={`form-control select-${field.id} ${this.validationClass(
          field,
        )}`}
        value={formValues[field.type] || ""}
        onChange={this.handleFullNameChange}
        disabled={!field.editable}
      />
    );
  }

  renderTransitPayeeInput(field) {
    const { formValues } = this.props;

    return (
      <TextArea
        key={field.sort}
        className="form-control"
        value={formValues[field.type] || ""}
        onChange={this.handleTransitPayeeChange}
        disabled={!field.editable}
      />
    );
  }

  renderCategoryInput(field, index) {
    const {
      nestedCategoryNames,
      suggestions,
      onCategorySuggestionsUpdateRequested,
    } = this.props;
    const currentLevelCategories = !isNil(
      _get(suggestions.nestedCategories[index], "current", null),
    )
      ? suggestions.nestedCategories[index].current
      : [];

    return (
      <AutoSuggestWrapper
        isImmutable
        key={field.sort}
        inputProps={{
          className: `form-control ${this.validationClass(
            field,
          )} category-input-field`,
        }}
        value={nestedCategoryNames[index] || ""}
        suggestions={currentLevelCategories}
        getSuggestionValue={(v) => v.name}
        onSuggestionsUpdateRequested={(updatedValue) => {
          onCategorySuggestionsUpdateRequested(updatedValue, index);
        }}
        onTextChanged={(changedCategoryName) =>
          this.handleCategoryChange(changedCategoryName, index)
        }
        onSuggestionSelected={(e, values) =>
          this.handleCategorySuggestionSelected(e, values, index)
        }
        clearValue={() => {
          this.handleClearCategory(index);
        }}
        disabled={!field.editable}
      />
    );
  }

  renderNestedCategories(field) {
    const {
      suggestions: { nestedCategories },
    } = this.props;
    const categoryInputNum = nestedCategories.length;

    return (
      <div key={field.sort}>
        {new Array(categoryInputNum).fill(null).map((c, index) => (
          <ListForm
            key={index}
            label={index === 0 ? field.label : ""}
            required={field.isRequired}
          >
            {this.renderCategoryInput(field, index)}
          </ListForm>
        ))}
      </div>
    );
  }

  renderCreditCategoryInput(field) {
    const {
      creditCategoryName,
      suggestions,
      onCreditCategorySuggestionsUpdateRequested,
      onCreditCategoryChange,
      onCreditCategorySuggestionSelected,
      formValues,
    } = this.props;

    return (
      <AutoSuggestWrapper
        isImmutable
        key={field.sort}
        inputProps={{
          className: `form-control ${this.validationClass(field)}`,
        }}
        value={creditCategoryName || _get(formValues[field.type], "name", "")}
        suggestions={suggestions.superCategories}
        getSuggestionValue={(v) => v.name}
        onSuggestionsUpdateRequested={
          onCreditCategorySuggestionsUpdateRequested
        }
        onTextChanged={onCreditCategoryChange}
        onSuggestionSelected={onCreditCategorySuggestionSelected}
        clearValue={this.props.clearCreditCategory}
        disabled={!field.editable}
      />
    );
  }

  renderReportTitleInput(field) {
    const {
      formValues,
      suggestions,
      onReportTitleSuggestionsUpdateRequested,
      onReportTitleChange,
      onReportTitleSuggestionSelected,
    } = this.props;

    return (
      <AutoSuggestWrapper
        isImmutable
        key={field.sort}
        inputProps={{
          className: `form-control ${this.validationClass(field)}`,
        }}
        value={formValues[field.type]?.title}
        suggestions={suggestions.reportTitles}
        getSuggestionValue={(v) => v}
        onSuggestionsUpdateRequested={onReportTitleSuggestionsUpdateRequested}
        onTextChanged={onReportTitleChange}
        onSuggestionSelected={onReportTitleSuggestionSelected}
        clearValue={this.props.clearReportTitle}
        disabled={!field.editable}
      />
    );
  }

  renderPreReportTitleInput(field) {
    const { formValues, suggestions, onPreReportChange } = this.props;
    const value = formValues[field.type] ? formValues[field.type] : null;

    return (
      <SelectField
        key={field.sort}
        menuShouldScrollIntoView={false}
        isDisabled={!field.editable}
        value={value}
        getOptionLabel={(x) => x.title}
        getOptionValue={(x) => x}
        options={suggestions.preReports}
        onChange={(c) => onPreReportChange(c || null)}
      />
    );
  }

  renderAssignabelReportInput(field) {
    const {
      onAssignableReportSelect,
      formValues,
      disableAssignableRportInput,
    } = this.props;

    return (
      <AssignableReportFieldWithInputModal
        key={field.sort}
        value={formValues[field.type]}
        onSelectPreReport={(preReport) =>
          onAssignableReportSelect({ ...preReport, type: "pre_report" })
        }
        onSelectReport={(report) =>
          onAssignableReportSelect({ ...report, type: "report" })
        }
        disabled={
          disableAssignableRportInput === undefined
            ? !field.editable
            : disableAssignableRportInput
        }
      />
    );
  }

  renderGenericFieldsInput(field) {
    const { formValues, onGenericFieldsSelect } = this.props;

    // 1つのEntryFormsの中には複数のgeneric_fields_input(汎用マスタの入力フィールド)が存在するが、それらはdataSetIdで判別している。
    // よって、該当するdataSetIdのフィールドのみを描画する事で重複を回避
    const genericFields = formValues[field.type];
    const targetDataSetId = field.dataSetId;
    const targetDataSet = formValues[field.type].find(
      (dataSet) => dataSet.dataSetId === targetDataSetId,
    );
    if (!targetDataSet) {
      return <></>;
    }

    const isUserCreatableItem = field.creatableItem;
    const selectedCategoryID = this.props.category?.id;
    const isMultipleSelectable = field.multipleConditions[0].value.some(
      (id) => id === selectedCategoryID,
    );

    const mappingSelectedItemsToGenericFields = (newItems) => {
      const newGenericFieldsValues = genericFields.map((gf) => {
        if (gf.dataSetId !== targetDataSetId) return gf;

        // 子要素で仕様しているInfiniteSelectorコンポーネントの仕様上、newItemsの型は下記の3パターンとなる。
        // (1) Item[] :「複数選択」時
        // (2) Item :「単一選択」かつ「新規に選択」時
        // (3) null   :「単一選択」かつ「選択の解除」時
        // よって、(2)(3)の時は形を合わせる
        let formatedNewItems = [];
        if (isMultipleSelectable) formatedNewItems = newItems; // (1)
        else if (newItems) formatedNewItems = [newItems]; // (2)
        else formatedNewItems = []; // (3)

        return {
          ...gf,
          items: formatedNewItems,
        };
      });

      onGenericFieldsSelect(newGenericFieldsValues);
    };

    return (
      <div key={field.sort}>
        <GenericFieldsSelector
          dataSet={targetDataSet}
          label={field.label}
          onSelectItems={mappingSelectedItemsToGenericFields}
          disabled={!field.editable}
          isUserCreatableItem={isUserCreatableItem}
          isMultipleSelectable={isMultipleSelectable}
        />
      </div>
    );
  }

  renderCompanionsInput(field) {
    const {
      formValues,
      onCompanionsSelect,
      isNew,
      shouldSelectSelfAsCompanion,
    } = this.props;

    return (
      <div key={field.sort}>
        <CompanionSelector
          value={formValues[field.type] || []}
          onSelect={onCompanionsSelect}
          ownerId={isNil(this.props.ownerId) ? null : this.props.ownerId}
          disabled={!field.editable}
          isNew={isNew}
          shouldSelectSelfAsCompanion={shouldSelectSelfAsCompanion}
          onSelectCompanionsCategory={this.props.onSelectCompanionsCategory}
        />
      </div>
    );
  }

  renderOriginAndDestinationInput(field) {
    const { originByCategory, destinationByCategory } =
      this.props.formValues[field.type];

    return (
      <OriginAndDestinationView
        key={field.sort}
        className="origin-and-destination-view"
      >
        <ListForm
          label={i18next.t("transactions.properties.originByCategory")}
          required={field.isRequired}
        >
          <input
            type="text"
            className={`form-control select-${field.id}`}
            value={originByCategory || ""}
            onChange={(e) =>
              this.handleOriginByCategoryChange(e, destinationByCategory)
            }
            disabled={!field.editable}
          />
        </ListForm>
        <div
          className="exchange-btn"
          onClick={() =>
            this.handleExchangeButtonClick(
              originByCategory,
              destinationByCategory,
            )
          }
        >
          <i className="fas fa-exchange-alt fa-x"></i>
        </div>
        <ListForm
          label={i18next.t("transactions.properties.destinationByCategory")}
          required={field.isRequired}
        >
          <input
            type="text"
            className={`form-control select-${field.id}`}
            value={destinationByCategory || ""}
            onChange={(e) =>
              this.handleDestinationByCategoryChange(e, originByCategory)
            }
            disabled={!field.editable}
          />
        </ListForm>
      </OriginAndDestinationView>
    );
  }

  renderVisitInput(field) {
    const { formValues } = this.props;

    return (
      <input
        type="text"
        className={`form-control select-${field.id}`}
        value={formValues[field.type] || ""}
        onChange={(e) => this.handleVisitByCategoryChange(e)}
        disabled={!field.editable}
      />
    );
  }

  renderPurposeInput(field) {
    const { formValues } = this.props;

    return (
      <input
        type="text"
        className={`form-control select-${field.id}`}
        onChange={(e) => this.handlePurposeByCategoryChange(e)}
        value={formValues[field.type] || ""}
        disabled={!field.editable}
      />
    );
  }

  renderProjectInput(field) {
    const { formValues, onProjectSelect } = this.props;

    return (
      <ProjectField
        key={field.sort}
        value={formValues[field.type]}
        validation={this.validationClass(field)}
        onSelect={onProjectSelect}
        ownerId={isNil(this.props.ownerId) ? null : this.props.ownerId}
        disabled={!field.editable}
        authority={this.props.authority}
      />
    );
  }

  renderGroupInput(field) {
    return (
      <PayerDepartmentInput
        key={field.sort}
        suggestions={this.props.suggestions.groups}
        onChangeDepartmentName={this.props.onChangeCostAllocationInput}
        onChangeDepartment={this.props.onSelectCostAllocation}
        disabled={!field.editable}
        costAllocations={this.props.formValues[field.type] || []}
      />
    );
  }

  renderCorporateCheck(field) {
    const { formValues } = this.props;

    return (
      <input
        key={field.sort}
        type="checkbox"
        style={{ paddingTop: "5px" }}
        checked={formValues[field.type]}
        onChange={(e) => this.props.onCheckCorporate(e.target.checked)}
        disabled={!field.editable}
      />
    );
  }

  renderElectronicReceiptImageCheck(field) {
    const { formValues } = this.props;

    return (
      <input
        key={field.sort}
        type="checkbox"
        style={{ paddingTop: "5px" }}
        checked={formValues[field.type]}
        onChange={(e) =>
          this.props.onCheckElectronicReceiptImage(e.target.checked)
        }
        disabled={!field.editable}
      />
    );
  }

  renderCommentInput(field) {
    const { formValues } = this.props;

    return (
      <TextArea
        key={field.sort}
        className="form-control"
        value={formValues[field.type] || ""}
        onChange={this.handleCommentChange}
        disabled={!field.editable}
      />
    );
  }

  renderMetadataInput(field) {
    const formValue = this.props.formValues[field.id] || "";

    switch (field.type) {
      case "text_input":
        return (
          <input
            key={field.sort}
            type="text"
            className={`form-control ${this.validationClass(field)}`}
            value={formValue}
            onChange={(e) => this.props.setMetadata(field.id, e.target.value)}
            disabled={!field.editable}
          />
        );
      case "multi_line_text_input":
        return (
          <TextArea
            key={field.sort}
            className={`form-control ${this.validationClass(field)}`}
            value={formValue}
            onChange={(e) => this.props.setMetadata(field.id, e.target.value)}
            disabled={!field.editable}
          />
        );
      case "number_input":
        return (
          <input
            key={field.sort}
            type="number"
            className={`form-control ${this.validationClass(field)}`}
            step={1}
            value={formValue}
            onChange={(e) => this.props.setMetadata(field.id, e.target.value)}
          />
        );
      case "check_input":
        return (
          <FlipSwitch
            key={field.sort}
            className={field.enable ? "" : "disabled"}
            checked={formValue}
            onChange={(_, checked) => this.props.setMetadata(field.id, checked)}
          />
        );
      case "select_input":
        return (
          <SelectField
            key={field.sort}
            className={`${
              field.isRequired ? this.validationClass(field) : ""
            } select-${field.id}`}
            value={formValue ? { value: formValue, label: formValue } : null}
            isDisabled={!field.editable}
            options={field.options.map((c) => ({
              value: c.value,
              label: c.value,
            }))}
            onChange={(c) =>
              this.props.setMetadata(field.id, _get(c, "label", null))
            }
          />
        );
      case "date_input": {
        return (
          <MultipleDateField
            key={field.sort}
            className={`form-control ${this.validationClass(field)}`}
            isMultiSelectable={false}
            value={formValue}
            editable={field.editable}
            onValueChanged={this.props.setMetadata.bind(this, field.id)}
          />
        );
      }
      default:
        return <CustomInputField key={field.sort} field={field} />;
    }
  }

  renderExportLineId() {
    return (
      <div style={{ marginTop: "6px" }}>
        <a href="https://secure.keihi.com">10416000005</a>
      </div>
    );
  }

  handleImageDelete(receiptFile, error) {
    if (error) {
      flash.error(getMessageFromResponse(error));
      return;
    }

    this.props.onDeleteImage(receiptFile);
  }

  handleImageSelect(side, file, isAccepted) {
    if (!isAccepted) {
      return;
    }

    const isBackside = side === "backside";

    if (this.props.isReceiptMatched) {
      this.setState({
        showCancelReceiptMatchingConfirmationModal: true,
        selectedImage: file,
        isBackside,
      });
      return;
    }

    this.props.onImageSelect(file, isBackside);
  }

  handleImageConfirm() {
    this.props.onImageSelect(this.state.selectedImage, this.state.isBackside);
    this.setState({
      showCancelReceiptMatchingConfirmationModal: false,
      selectedImage: null,
      isBackside: false,
    });
  }

  renderImageDropArea(isEditable) {
    const {
      localReceiptFile,
      receiptImages,
      isReceiptMatched,
      onRotateImage,
      isElectronicReceiptImage,
    } = this.props;
    const { foreside, backside } = receiptImages;

    // 領収書のリストが変わった場合、領収書の表示をリセットする（最新の領収書が表示されるようにしたい）
    const key = foreside
      .map((x) => x.id)
      .concat(backside.map((x) => x.id))
      .join(":");

    return (
      <>
        {this.showElectronicReceiptAlert && <ElectronicReceiptAlert />}
        <VersionedReceiptFile
          key={key}
          isEditable={isEditable}
          isReceiptMatched={isReceiptMatched}
          isVisibleResolutionInformation={
            userPreferences.preference.visibleResolutionInformation
          }
          isElectronicReceiptImage={isElectronicReceiptImage}
          isTimestampEnabled={userPreferences.preference.addTimeStamp}
          versions={receiptImages}
          localTemp={localReceiptFile}
          height={this.state.receiptImageAreaHeight}
          style={{ width: "100%" }}
          onRotate={onRotateImage}
          onDelete={this.handleImageDelete}
          onDrop={this.handleImageSelect}
          resolutionInformationDisplayStyle={
            this.props.resolutionInformationDisplayStyle
          }
        />
        <ConfirmationModal
          title={i18next.t(
            "transactions.confirmationTitles.confirmCancelReceiptMatching",
          )}
          show={this.state.showCancelReceiptMatchingConfirmationModal}
          close={() =>
            this.setState({
              showCancelReceiptMatchingConfirmationModal: false,
              selectedImage: null,
              isBackside: false,
            })
          }
          buttonText={"OK"}
          onConfirm={this.handleImageConfirm}
          content={i18next.t(
            "transactions.messages.confirmCancelReceiptMatching",
          )}
        />
      </>
    );
  }

  renderDepartmentOption({ data, ...props }) {
    const path = data.absolutePath.split("/").slice(0, -1).join("/");
    return (
      <components.Option {...props}>
        <>
          <small style={{ color: props.isSelected ? null : "#888888" }}>
            {path}
          </small>
          <div>{data.name}</div>
        </>
      </components.Option>
    );
  }

  renderDepartmentInput(field, preReportField) {
    const {
      onDepartmentChanged,
      formValues,
      reportTitle,
      preReportTitle,
      preReportDepartment,
      isMultipleEditing,
      suggestions: { userDepartments },
    } = this.props;

    // TODO field.editable で全て制御できるようにリファクタリングをする
    // 申請が紐づけられている場合は所属部署を入力不可にする
    const reportEditable = isMultipleEditing
      ? !reportTitle
      : !_get(formValues, "report_input", null);
    // 事前申請が入力不可の場合は所属部署も不可にする
    const preReportEditable = isMultipleEditing
      ? !preReportTitle
      : _get(preReportField, "editable", true);
    const department = formValues[field.type];

    return (
      <SelectField
        key={field.sort}
        value={department || null}
        options={!isNil(userDepartments) ? userDepartments.current : []}
        getOptionLabel={(x) => x.name}
        getOptionValue={(x) => x.id}
        isClearable={isMultipleEditing}
        isDisabled={
          !field.editable ||
          (!!preReportDepartment && (!reportEditable || !preReportEditable))
        }
        onChange={(option) => onDepartmentChanged(option, isMultipleEditing)}
        components={{ Option: this.renderDepartmentOption }}
        menuPortalTarget={document.body}
        menuPortal={{ zIndex: 9999 }}
        menuPlacement="bottom"
        maxMenuHeight={200}
      />
    );
  }

  // 入力フォームをネスト無しの配列で返す
  formList(arr) {
    const readableFields = _sortBy(
      this.props.fields.filter((f) => this.canRead(f)),
      ["sort"],
    );
    const preReportField = readableFields.find(
      (f) => f.type === "pre_report_input",
    );

    return arr.map((field) => {
      if (isNil(field.id)) {
        switch (field.type) {
          case "route_input":
            return (
              <RouteInputField
                key={field.sort}
                ownerId={this.props.ownerId}
                transactedAt={_get(this.props.formValues, "date_input")}
                companions={this.props.formValues.companion_input}
                fields={this.props.fields}
                destination={this.props.destination}
                onDestinationChange={this.props.onDestinationChange}
                onDestinationSelect={this.props.onDestinationSelect}
              />
            );
          case "date_input":
            return (
              <ListForm
                key={field.sort}
                label={field.label}
                required={field.isRequired}
              >
                {this.renderTransactionDateInput(field)}
              </ListForm>
            );
          case "transit_payee_input":
            return (
              <ListForm
                key={field.sort}
                label={field.label}
                required={field.isRequired}
              >
                {this.renderTransitPayeeInput(field)}
              </ListForm>
            );
          case "expense_input":
            return (
              <ListForm
                key={field.sort}
                label={field.label}
                required={field.isRequired}
              >
                {this.renderAmountInput(field)}
              </ListForm>
            );
          case "currency_and_rate_input":
            return this.renderCurrencyAndRateInput(field);
          case "direct_product_table_input":
            return this.renderAllowanceInput(field);
          case "shop_input":
            return (
              <ListForm
                key={field.sort}
                label={
                  this.props.category?.requiresVisit
                    ? i18next.t("transactions.properties.shop")
                    : field.label
                }
                required={field.isRequired}
              >
                {this.renderShopNameInput(field)}
              </ListForm>
            );
          case "category_input":
            return this.renderNestedCategories(field);
          case "withholding_input":
            return (
              <ListForm
                key={field.sort}
                label={field.label}
                required={field.isRequired}
              >
                {this.renderWithholdingInput(field)}
              </ListForm>
            );
          case "address_input":
            return (
              <ListForm
                key={field.sort}
                label={field.label}
                required={field.isRequired}
              >
                {this.renderAddressInput(field)}
              </ListForm>
            );
          case "full_name_input":
            return (
              <ListForm
                key={field.sort}
                label={field.label}
                required={field.isRequired}
              >
                {this.renderFullNameInput(field)}
              </ListForm>
            );
          case "super_category_input":
            return (
              <ListForm
                key={field.sort}
                label={field.label}
                required={field.isRequired}
              >
                {this.renderCreditCategoryInput(field)}
              </ListForm>
            );
          case "companion_input":
            return (
              <ListForm
                key={field.sort}
                label={field.label}
                required={field.isRequired}
              >
                {this.renderCompanionsInput(field)}
              </ListForm>
            );
          case "origin_and_destination_by_category_input":
            return this.renderOriginAndDestinationInput(field);
          case "visit_by_category_input":
            return (
              <ListForm
                key={field.sort}
                label={field.label}
                required={field.isRequired}
              >
                {this.renderVisitInput(field)}
              </ListForm>
            );
          case "purpose_by_category_input":
            return (
              <ListForm
                key={field.sort}
                label={field.label}
                required={field.isRequired}
              >
                {this.renderPurposeInput(field)}
              </ListForm>
            );
          case "eligible_invoice_confirmation_input": {
            if (!this.props.formValues[field.type]) {
              return <></>;
            }

            return (
              <EligibleInvoiceFormField
                key={field.sort}
                isRequired={field.isRequired}
                isAllowance={!isNil(this.props.allowanceTable)}
                category={this.props.category}
                value={this.props.formValues[field.type]}
                onChangeAsEligibleInvoice={this.handleAsEligibleInvoiceChange}
                onChangeRegistratedNumber={this.handleRegistratedNumberChange}
                onVerifyRegistratedNumber={this.handleRegistratedNumberVerify}
                onChangePaidAddress={this.handlePaidAddressChange}
              />
            );
          }
          case "amount_per_tax_category_input": {
            const asEligibleInvoice =
              !isNil(this.props.allowanceTable) ||
              this.props.formValues?.eligible_invoice_confirmation_input
                ?.asEligibleInvoice;
            const categoryId = this.props.formValues?.category_input?.id;
            const formatDates = () => {
              const transactedAt = this.props.formValues?.date_input;
              if (!transactedAt) return [];

              if (Array.isArray(transactedAt)) {
                return transactedAt.map((d) => moment(d).format("YYYY-MM-DD"));
              }
              return [moment(transactedAt).format("YYYY-MM-DD")];
            };
            const currentAmount = [
              this.props.transportCalculatedAmount,
              this.props.amount,
              this.props.formValues?.expense_input?.amount,
            ].find((a) => isNumber(a));
            return (
              Object.keys(this.props.formValues).length > 0 && (
                <AmountPerTaxCategoryCollectionFormField
                  key={field.sort}
                  expenseId={this.props.id}
                  amount={currentAmount}
                  dates={formatDates()}
                  categoryId={categoryId}
                  isTaxAmountShow={this.props.isTaxAmountShow}
                  isEditable={this.props.isEditable}
                  asEligibleInvoice={asEligibleInvoice}
                  amountPerTaxCategories={
                    this.props.formValues[field.type] || []
                  }
                  label={field.label}
                  authority={userPreferences}
                  isTaxCategoryEditable={
                    userPreferences.preference.taxCategoryEditable
                  }
                  operatorEntry={this.props.operatorEntry}
                  onChange={this.props.onChangeAmountPerTaxCategories}
                />
              )
            );
          }
          case "report_input":
            return (
              <ListForm
                key={field.sort}
                label={field.label}
                required={field.isRequired}
              >
                {this.renderReportTitleInput(field)}
              </ListForm>
            );
          case "pre_report_input":
            return (
              <ListForm
                key={field.sort}
                label={field.label}
                required={field.isRequired}
              >
                {this.renderPreReportTitleInput(field)}
              </ListForm>
            );
          case "project_input":
            return (
              <ListForm
                key={field.sort}
                label={field.label}
                required={field.isRequired}
              >
                {this.renderProjectInput(field)}
              </ListForm>
            );
          case "generic_fields_input": {
            // 汎用マスタが必須入力か否か、現在の経費科目によって変わる
            const selectedCategoryID = this.props.category?.id;
            const isRequired = field.requiredConditions[0].value.some(
              (id) => id === selectedCategoryID,
            );

            return (
              <div key={field.sort} className="generic-fields-view">
                <ListForm
                  key={field.sort}
                  label={field.label}
                  required={isRequired}
                >
                  {this.renderGenericFieldsInput(field)}
                </ListForm>
              </div>
            );
          }
          case "assignable_report_input":
            return (
              <ListForm
                key={field.sort}
                label={field.label}
                required={field.isRequired}
              >
                {this.renderAssignabelReportInput(field)}
              </ListForm>
            );
          case "cost_allocation_input":
          case "group_input":
            return (
              <ListForm
                key={field.sort}
                label={field.label}
                required={field.isRequired}
              >
                {this.renderGroupInput(field)}
              </ListForm>
            );
          case "refund_check_input":
            return (
              // チェックボックスは必須マーク不要
              <ListForm key={field.sort} label={field.label}>
                {this.renderCorporateCheck(field)}
              </ListForm>
            );
          case "electronic_receipt_image_flag":
            return (
              // チェックボックスは必須マーク不要
              <ListForm key={field.sort} label={field.label}>
                {this.renderElectronicReceiptImageCheck(field)}
              </ListForm>
            );
          case "multi_line_text_input":
            return (
              <ListForm
                key={field.sort}
                label={field.label}
                required={field.isRequired}
              >
                {this.renderCommentInput(field)}
              </ListForm>
            );
          case "receipt_file_input":
            return (
              <ListForm key={field.sort} label={""} required={field.isRequired}>
                {this.renderImageDropArea(field.editable)}
              </ListForm>
            );
          case "department_input":
            return (
              <ListForm
                key={field.sort}
                label={field.label}
                required={field.isRequired}
              >
                {this.renderDepartmentInput(field, preReportField)}
              </ListForm>
            );
          default:
            return null;
        }
      }

      switch (field.type) {
        case "multi_line_text_input": {
          return (
            <ListForm
              key={field.sort}
              label={field.label}
              required={field.isRequired}
            >
              {this.renderMetadataInput(field)}
            </ListForm>
          );
        }
        default: {
          return (
            <ListForm
              key={field.sort}
              label={field.label}
              required={field.isRequired}
            >
              {this.renderMetadataInput(field)}
            </ListForm>
          );
        }
      }
    });
  }

  renderStatus() {
    const status = TRANSACTION_STATUS[this.props.status];
    if (!status) return null;

    return (
      <ListForm
        label={i18next.t("transactions.properties.status")}
        required={false}
      >
        {status}
      </ListForm>
    );
  }

  render() {
    const { fields, isNew, inModal } = this.props;
    const readableFields = _sortBy(
      fields.filter((f) => this.canRead(f)),
      ["sort"],
    );

    const leftAreaFormTypes = ["receipt_file_input"];
    const leftAreaFields = readableFields.filter((form) =>
      leftAreaFormTypes.includes(form.type),
    );
    const rightAreaFields = readableFields.filter(
      (form) => !leftAreaFormTypes.includes(form.type),
    );

    return (
      <EntryFormsView
        className="expense-entry-forms"
        isNew={isNew}
        inModal={inModal}
      >
        {leftAreaFields.length ? (
          <>
            <div className="left-area">
              {Array.prototype.concat.apply([], this.formList(leftAreaFields))}
            </div>
            <div className="right-area">
              {Array.prototype.concat.apply([], this.formList(rightAreaFields))}
              {this.renderStatus()}
            </div>
          </>
        ) : (
          // 左カラムに表示するものが無ければ1カラムでフルサイズ表示
          <div className="full-area">
            {Array.prototype.concat.apply([], this.formList(rightAreaFields))}
          </div>
        )}
      </EntryFormsView>
    );
  }
}

EntryForms.defaultProps = {
  inModal: false,
  onSubmit(_options, _expenses, _error, _message) {},
  isEditingWithholdingCategory: true,
  withholdingConstants: {},
  isDatePickerRendered: false,
  isMultiDateChecked: false,
  emptyRequiredFields: [],
  isWithholdingConfirmationModalOpen: false,
  reuseInput: false,
  onDeleteImage() {},
  nestedCategories: [],
  nestedCategoryNames: [],
  shouldSelectSelfAsCompanion: true,
  isReceiptMatched: false,
  resolutionInformationDisplayStyle: "single-view",
};

EntryForms.propTypes = {
  isNew: PropTypes.bool.isRequired,
  isDatePickerRendered: PropTypes.bool.isRequired,
  isMultiDateChecked: PropTypes.bool.isRequired,
  shouldSelectSelfAsCompanion: PropTypes.bool.isRequired,
  isEditable: PropTypes.bool.isRequired,
  emptyRequiredFields: PropTypes.array.isRequired,
  isElectronicReceiptImage: PropTypes.bool,
  isWithholdingConfirmationModalOpen: PropTypes.bool.isRequired,
  inModal: PropTypes.bool.isRequired,
  formId: PropTypes.string, // string | undefined
  fields: PropTypes.array.isRequired,
  formValues: PropTypes.object.isRequired,
  taxCategory: PropTypes.shape({
    name: PropTypes.string.isRequired,
  }),
  category: PropTypes.shape({
    name: PropTypes.string.isRequired,
    requiresCompanion: PropTypes.bool.isRequired,
  }),
  nestedCategories: PropTypes.array.isRequired,
  nestedCategoryNames: PropTypes.array.isRequired,
  reuseInput: PropTypes.bool.isRequired,
  ...transactionType,
  suggestions: PropTypes.shape({
    taxCategories: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.string,
        name: PropTypes.string,
        taxRate: PropTypes.float,
        groupId: PropTypes.string,
      }),
    ),
    categories: PropTypes.arrayOf(
      PropTypes.shape({
        name: PropTypes.string.isRequired,
        requiresCompanion: PropTypes.bool.isRequired,
      }),
    ),
    superCategories: PropTypes.arrayOf(
      PropTypes.shape({
        name: PropTypes.string.isRequired,
      }),
    ),
    reportTitles: PropTypes.arrayOf(PropTypes.string),
    preReports: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.string.isRequired,
        title: PropTypes.string.isRequired,
        department: PropTypes.shape({
          id: PropTypes.string.isRequired,
          name: PropTypes.string.isRequired,
        }),
      }),
    ),
    groups: PropTypes.arrayOf(
      PropTypes.shape({
        name: PropTypes.string.isRequired,
      }),
    ),
  }),
  companions: PropTypes.array,
  mergeableAggregation: PropTypes.shape({
    name: PropTypes.string,
    categoryName: PropTypes.string,
  }),
  onSubmit: PropTypes.func.isRequired,
  submitForm: PropTypes.func.isRequired,
  mergeTransaction: PropTypes.func.isRequired,
  onDateChange: PropTypes.func.isRequired,
  reloadCurrencies: PropTypes.func.isRequired,
  setAmount: PropTypes.func.isRequired,
  setOriginalAmount: PropTypes.func.isRequired,
  setCurrency: PropTypes.func.isRequired,
  setMetadata: PropTypes.func.isRequired,
  onShopNameChange: PropTypes.func.isRequired,
  onOriginByCategoryChange: PropTypes.func.isRequired,
  onDestinationByCategoryChange: PropTypes.func.isRequired,
  onAsEligibleInvoiceChange: PropTypes.func.isRequired,
  onRegistratedNumberChange: PropTypes.func.isRequired,
  onRegistratedNumberVerify: PropTypes.func.isRequired,
  onPaidAddressChange: PropTypes.func.isRequired,
  onCheckCorporate: PropTypes.func.isRequired,
  onCheckElectronicReceiptImage: PropTypes.func.isRequired,
  onCommentChange: PropTypes.func.isRequired,
  onWithholdingChange: PropTypes.func.isRequired,
  onAddressChange: PropTypes.func.isRequired,
  onFullNameChange: PropTypes.func.isRequired,
  onPreReportChange: PropTypes.func.isRequired,
  onReuseCheckChange: PropTypes.func.isRequired,
  onTaxCategoryChange: PropTypes.func.isRequired,
  onTaxCategorySuggestionSelected: PropTypes.func.isRequired,
  onTaxCategorySuggestionsUpdateRequested: PropTypes.func.isRequired,
  clearTaxCategory: PropTypes.func.isRequired,
  onCategoryChange: PropTypes.func.isRequired,
  onCategorySuggestionSelected: PropTypes.func.isRequired,
  onCategorySuggestionsUpdateRequested: PropTypes.func.isRequired,
  clearCategory: PropTypes.func.isRequired,
  onCreditCategoryChange: PropTypes.func.isRequired,
  onCreditCategorySuggestionSelected: PropTypes.func.isRequired,
  onCreditCategorySuggestionsUpdateRequested: PropTypes.func.isRequired,
  clearCreditCategory: PropTypes.func.isRequired,
  onReportTitleChange: PropTypes.func.isRequired,
  onReportTitleSuggestionSelected: PropTypes.func.isRequired,
  onReportTitleSuggestionsUpdateRequested: PropTypes.func.isRequired,
  clearReportTitle: PropTypes.func.isRequired,
  onAssignableReportSelect: PropTypes.func.isRequired,
  onGenericFieldsSelect: PropTypes.func.isRequired,
  onCompanionsSelect: PropTypes.func.isRequired,
  onProjectSelect: PropTypes.func.isRequired,
  onImageSelect: PropTypes.func.isRequired,
  onDeleteImage: PropTypes.func.isRequired,
  onRotateImage: PropTypes.func,
  setDatePickerStatus: PropTypes.func.isRequired,
  switchMultiDate: PropTypes.func.isRequired,
  openConfirmationModal: PropTypes.func.isRequired,
  openWithholdingConfirmationModal: PropTypes.func.isRequired,
  closeWithholdingConfirmationModal: PropTypes.func.isRequired,
  costAllocations: PropTypes.arrayOf(
    PropTypes.shape({
      payerId: PropTypes.string,
      payerType: PropTypes.string,
      numerator: PropTypes.integer,
      denominator: PropTypes.integer,
    }),
  ),
  onDepartmentChanged: PropTypes.func.isRequired,
  reportId: PropTypes.string,
  reportTitle: PropTypes.string,
  preReportId: PropTypes.string,
  preReportTitle: PropTypes.string,
  preReportDepartment: PropTypes.string,
  isReceiptMatched: PropTypes.bool,
  onChangeAmountPerTaxCategories: PropTypes.func.isRequired,
};
