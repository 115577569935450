import VersionedReceiptFile from "components/fields/receipt_files/VersionedReceiptFile";
import {
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
} from "components/renewaled_ui/single_views";
import i18next from "i18n";
import React from "react";
import { Alert } from "react-bootstrap";
import styled from "styled-components";
import { useSplitModal } from "../hooks/useSplitModal";
import * as Types from "../types";
import { AmountsBySeparatorEditor } from "./AmountsBySeparatorEditor";
import * as Buttons from "./buttons";
import { SplitConfirmModal } from "./SplitConfirmModal";
import { TotalAmountDisplay } from "./TotalAmountDisplay";
import { WarningMessage } from "./WarningMessage";

export interface Props {
  /** 分割元の経費 */
  expense: Types.Expense;
  /** モーダルを表示するか */
  show: boolean;
  /** 分割項目 */
  separatorType: Types.SeparatorType;
  /** モーダルを閉じる場合 */
  close: () => void;
  /**
   * 分割に成功した場合
   * @param message メッセージ
   */
  onSuccess: (message: string) => void;
  isVisibleResolutionInformation?: boolean; // 解像度情報を表示するか
}

/** モーダルボディのレイアウト */
const ModalBodyView = styled.div`
  display: flex;
  justify-content: space-between;
  .modal-body-left {
    width: 40%;
    margin-right: 16px;
  }
  .modal-body-right {
    width: 60%;
  }
`;

/** 表のレイアウト。TotalAmountDisplayとAmountsBySeparatorEditorそれぞれの表で共用 */
export const tableStyle: Types.TableStyle = {
  tableWidth: "100%",
  firstColSpan: 2,
  secondColWidth: "100px",
  thirdColWidth: "50px",
};

/** 経費分割モーダル */
export const SplitModal: React.FC<Props> = ({
  expense,
  show,
  separatorType,
  isVisibleResolutionInformation,
  close,
  onSuccess,
}) => {
  // メソッドが多いためhookに切り出し
  const {
    amountBySeparators,
    totalSeparatedAmount,
    diffMessage,
    processing,
    errorMessage,
    isSplittableAmountSeparators,
    showConfirmModal,
    handleAdd,
    handleRemove,
    handleChangeAmount,
    handleUpdateDiffMessage,
    handleChangeSeparator,
    handleClose,
    handleShowConfirmModal,
    handleCloseConfirmModal,
    handleSplit,
  } = useSplitModal(expense, separatorType, show, close, onSuccess);

  return (
    <Modal show={show} onClose={handleClose} width="md" className="split-modal">
      <ModalHeader
        title={i18next.t("expenses.split.amountBySeparatorsEditor.title")}
        onClose={handleClose}
      />
      <ModalBody>
        <ModalBodyView className="split-modal-body">
          <div className="modal-body-left">
            <VersionedReceiptFile
              showVersion={false}
              showUploader={false}
              isVisibleResolutionInformation={isVisibleResolutionInformation}
              isTimestampEnabled={true}
              versions={expense.receiptImages}
              height={500}
              style={{ width: "100%" }}
            />
          </div>
          <div className="modal-body-right">
            <TotalAmountDisplay
              amountBySeparators={amountBySeparators.current}
              totalAmount={expense.amount}
              totalSeparatedAmount={totalSeparatedAmount}
              diffMessage={diffMessage}
              tableStyle={tableStyle}
            />
            <AmountsBySeparatorEditor
              amountBySeparators={amountBySeparators.current}
              separatorType={separatorType}
              totalAmount={expense.amount}
              totalSeparatedAmount={totalSeparatedAmount}
              canAddRow={amountBySeparators.canAddSeparator}
              canRemoveRow={amountBySeparators.canRemoveSeparator}
              onAdd={handleAdd}
              onRemove={handleRemove}
              onChangeAmount={handleChangeAmount}
              onBlurAmountInput={handleUpdateDiffMessage}
              onChangeSeparator={handleChangeSeparator}
              tableStyle={tableStyle}
            />
            <WarningMessage />
            {errorMessage && <Alert bsStyle="danger"> {errorMessage} </Alert>}
          </div>
        </ModalBodyView>
      </ModalBody>
      <ModalFooter>
        <Buttons.SplitButton
          onClick={handleShowConfirmModal}
          disabled={!isSplittableAmountSeparators}
        />
        <Buttons.CancelButton onClick={handleClose} />
        <SplitConfirmModal
          show={showConfirmModal}
          close={handleCloseConfirmModal}
          onConfirm={handleSplit}
          disabled={processing}
          processing={processing}
        />
      </ModalFooter>
    </Modal>
  );
};
