import i18next from "i18n";
import React from "react";
import { Button } from "react-bootstrap";

interface Props {
  disabled: boolean;
  onClick: () => void;
}

/** 行追加ボタン */
export const AddRowButton: React.FC<Props> = ({ disabled, onClick }) => {
  return (
    <Button
      onClick={onClick}
      disabled={disabled}
      bsStyle="link"
      style={{ outline: "none", paddingRight: "0" }}
    >
      <i className="fas fa-plus-circle" />
      <span style={{ fontWeight: "bold", marginLeft: "4px" }}>
        {i18next.t("expenses.split.amountBySeparatorsEditor.addButton")}
      </span>
    </Button>
  );
};
