import { displayMessage } from "actions/ActionCreators";
import { fetchAsync } from "actions/AsyncAction";
import { getExpenseApi } from "api_builders/ExpenseApi";
import i18next from "i18n";
import _ from "lodash";
import Api from "utilities/api";
import flash from "utilities/flash";
import {
  getMessageFromResponse,
  isCorporatePlan,
  snakecaseKeys,
} from "utilities/Utils";
import { getEmptyCostAllocations } from "./index";

const prefix = "transactions/index";

const defaultEditorState = {
  transactedAt: true,
  shopName: true,
  amount: true,
  taxCategoryName: true,
  categoryName: true,
  creditCategoryName: true,
  project: true,
  groupName: true,
  reportId: true,
  reportTitle: true,
  department: true,
};

/**
 * 取得した経費の一覧データを整形する
 *
 * @param {object[]} current
 * @param {object[]} transactions
 * @param {number} count
 * @param {number} offset
 * @return {Function} (dispatch) => Promise<{transactions: {master: object[], edit: object[]}}>
 */
export function initTransactions(current, { transactions, count, offset = 0 }) {
  return async (dispatch) => {
    const defaultSuggestions = {
      taxCategoryName: [],
      categoryName: [],
      creditCategoryName: [],
      project: [],
      groupName: [],
      department: [],
    };

    let transactionSize = count;

    if (_.isNil(count)) {
      transactionSize = transactions.length;
    }
    const nextMaster = [...new Array(transactionSize)]; // 全てのelementを初期化する

    // これまでにキャッシュしてあるデータを詰める
    current.some((item, idx) => {
      // 配列の外に出てしまう時は、処理を打ち切り
      if (idx >= nextMaster.length) {
        return true;
      }

      nextMaster[idx] = item;
      return false;
    });

    // 取得した経費のデータを詰める
    transactions.forEach((item, idx) => {
      // 現在の選択状態を反映させる
      // リクエストした件数に対して、取得件数が少ないケースでは、表示に必要なデータが全てキャッシュされている
      // にも関わらず、サーバーに問い合わせを行う可能性がある
      //   (e.g. 申請可能経費一覧では、現在総件数と実際に返されるデータの件数が一致しない事がある
      //        （アラート設定により、申請不可になる経費など）
      //         この時、50件中、1~30件目を表示する際に、1~25件目しか取得できないケースでは、
      //         26~30件目は、常に空の状態となり、これらが含まれるページを表示するたびに、
      //         サーバーにリクエストを送る
      //   )
      // このような場合において、ページを移動した時に、選択状態が維持されるようにする
      const currentTransaction = _.get(current, `[${idx + offset}]`);
      // 再検索など、経費の順番が変わりうる場合においては、DataFetchingTableの動きにより、
      // キャッシュがクリアされるので、リクエスト前の同じ位置にあったデータのみを参照する
      //  (findを使うと遅くなる)
      const currentIsSelected =
        _.get(currentTransaction, "id") === item.id &&
        _.get(current, `[${idx + offset}].isSelected`, false);

      nextMaster[idx + offset] = {
        ...item,
        isSelected: currentIsSelected,
      };
    });

    // 編集用のリストを作成する
    // @todo テーブルに表示中のデータだけを作成するように
    const nextInEdit = nextMaster.map((item, idx) => {
      if (_.isNil(item)) {
        return item;
      }

      return {
        ...item,
        selectText: {
          categoryName: item.categoryName,
          creditCategoryName: item.creditCategoryName,
          taxCategoryName: item.taxCategoryName,
          project: item.project && item.project.displayId,
          groupName: item.groupName,
          department: item.department && item.department.name,
        },
        editorSync: { ...defaultEditorState },
        suggestions: { ...defaultSuggestions },
      };
    });

    const transactionObj = { master: nextMaster, inEdit: nextInEdit };
    dispatch(setTransactions(transactionObj));

    const isSearchWithOnlyDeleted = transactions.some(
      (transaction) => transaction.isDeleted,
    );
    dispatch(setIsSearchWithOnlyDeleted(isSearchWithOnlyDeleted));

    return { transactions: transactionObj };
  };
}

export function getQueryObject(searchConditions) {
  const queryObj = {};
  const keys = [
    "dateFrom",
    "dateTo",
    "shopName",
    "categoryName",
    "ownerName",
    "amountFrom",
    "amountTo",
    "sequenceNum",
    "exportLineId",
    "departmentId",
    "includeChildDepartment",
    "status",
    "includeDraftSaved",
    "receiptExpenseMatching",
    "onlyDeleted",
    "emptyAmount",
    "emptyTransactedAt",
    "emptyShopname",
    "registratedNumber",
    "emptyRegistratedNumber",
    "asInvoiceChecks",
  ];

  // TODO: 最初から、必要な条件だけ入っているように、ExpenseStorageのスキーマを変更する
  keys.forEach((x) => {
    if (searchConditions[x]) {
      if (
        x === "onlyDeleted" &&
        !userPreferences.searchPreference.canDeletedExpenseSearch
      )
        return;
      _.set(queryObj, x, searchConditions[x]);
    }
  });

  // falsyな値を含む場合は個別に書き下す
  _.set(queryObj, "toBePaid", searchConditions.toBePaid);
  _.set(queryObj, "paidByCorporateCard", searchConditions.paidByCorporateCard);
  _.set(
    queryObj,
    "isElectronicReceiptImage",
    searchConditions.isElectronicReceiptImage,
  );

  if (
    isCorporatePlan() &&
    (userPreferences.isAdmin || userPreferences.isAuditor) &&
    searchConditions.scope
  ) {
    _.set(queryObj, "scope", "all");
  }

  const genericFields =
    searchConditions.genericFields &&
    Array.isArray(searchConditions.genericFields) &&
    searchConditions.genericFields.filter((gf) => gf.value !== "");
  _.set(queryObj, "genericFields", genericFields);

  return snakecaseKeys(queryObj);
}

/**
 * @todo sort順を複数指定できるように
 */
export function fetchTransactions(
  offset = 0,
  limit = 30,
  sortParams = null,
  isRequestableExpenses = false,
) {
  return (dispatch, getState) => {
    const { searchConditions } = getState();

    const queryObj = {
      ...getQueryObject(searchConditions),
      offset,
      limit,
    };

    // 申請可能経費一覧画面のみでrequestableとopenをセットする
    if (isRequestableExpenses) {
      queryObj.requestable = true;
      queryObj.open = true;
    }

    // ソート順は個別にパラメータ追加する
    // 保存済みの検索条件に対して、sortParamsの方を優先させる
    let sortKeys = searchConditions.sort || [];

    if (sortParams) {
      // 既存のパラメータから、指定されたパラメータを除外して、指定パラメータは先頭に追加する
      sortKeys = sortKeys.filter(
        (x) => !sortParams.find((p) => p.key === x.key),
      );
      sortKeys.unshift(...sortParams);
    }

    dispatch(requestFetchTransactions());
    return dispatch(
      fetchAsync(Api.transactions.index, { ...queryObj, sort: sortKeys }),
    )
      .then((data) => {
        const { transactions } = getState();
        return dispatch(initTransactions(transactions.master, data));
      })
      .catch(() => {
        /* エラー表示後なので何もしない */
      })
      .finally(() => {
        dispatch(receiveFetchTransactions());
      });
  };
}

export const REQUEST_FETCH_TRANSACTIONS = `${prefix}/REQUEST_FETCH_TRANSACTIONS`;
function requestFetchTransactions() {
  return { type: REQUEST_FETCH_TRANSACTIONS };
}

export const RECEIVE_FETCH_TRANSACTIONS = `${prefix}/RECEIVE_FETCH_TRANSACTIONS`;
function receiveFetchTransactions() {
  return { type: RECEIVE_FETCH_TRANSACTIONS };
}

export function fetchMergeableAggregation(transactionId) {
  return (dispatch, getState) => {
    return dispatch(
      fetchAsync(Api.aggregationResults.mergeable, { transactionId }),
    ).then((data) => dispatch(setMergeableAggregationResult(data)));
  };
}

/**
 * エントリーフォームを取得します。
 * @param {boolean} isDeleted
 * @param {boolean} forPreReport
 * @param {string} ownerId 申請書などの所有者ID
 */
export function fetchEntryForms(
  isDeleted = false,
  forPreReport = false,
  ownerId = null,
) {
  return (dispatch, getState) => {
    return dispatch(
      fetchAsync(Api.entryForms.index, { isDeleted, forPreReport, ownerId }),
    )
      .then((data) => {
        const summaries = _.get(data, "summaries", []).map((d) => ({
          ...d,
          edited: false,
        }));
        dispatch(setEntryForms(summaries));
      })
      .catch(() => {
        /* エラー表示後なので何もしない */
      });
  };
}

export function fetchEntryForm(formId, directProductTableId) {
  return (dispatch, getState) => {
    const state = getState();
    const entryForms =
      state.entryForms || _.get(state, "transactionTable.entryForms", []);
    const entryForm = entryForms.find(
      (form) =>
        form.id === formId &&
        form.directProductTableId === directProductTableId,
    );
    if (entryForm) {
      return Promise.resolve(entryForm);
    }

    return dispatch(
      fetchAsync(Api.entryForms.show, { id: formId, directProductTableId }),
    ).then((data) => {
      dispatch(setEntryForms([...entryForms, data]));
      return data;
    });
  };
}

export function onSortChange(sortName, sortOrder) {
  return (dispatch, getState) => {
    const { searchConditions, currentPage, sizePerPage } = getState();

    // 既存のソート条件と重複した場合、上書きする
    const sortKeys = (searchConditions.sort || []).filter(
      (x) => x.key !== sortName,
    );
    sortKeys.unshift({ key: sortName, order: sortOrder });

    dispatch(setSearchConditions({ ...searchConditions, sort: sortKeys }));

    const offset = sizePerPage * (currentPage - 1);
    return dispatch(fetchTransactions(offset, sizePerPage, sortKeys)).then(
      () => {
        const ranges = [{ end: offset }, { start: offset + sizePerPage }];
        dispatch(clearStaleTransactions(ranges));

        return Promise.resolve();
      },
    );
  };
}

/**
 * 検索ボタンがクリックされた時に、キャッシュをクリアしてから経費一覧を取得する
 * 現在表示中のページにかかわらず、1ページ目を表示する
 */
export function onClickSearchButton() {
  return (dispatch, getState) => {
    const { sizePerPage } = getState();
    dispatch(clearAllCacheTransactions());
    dispatch(saveSearchConditions());

    return dispatch(fetchTransactions(0, sizePerPage)).then(() => {
      dispatch(setCurrentPage(1));
    });
  };
}

export function onClickResetSearchConditionsButton() {
  return (dispatch, getState) => {
    dispatch(resetSearchConditions());
    dispatch(saveSearchConditions());

    // 検索条件がリセットされていることが保証できていない気がする
    return dispatch(fetchTransactions());
  };
}

// SearchBox
export const SET_SEARCHBOX_REF = `${prefix}/SET_SEARCHBOX_REF`;
export function setSearchBoxRef(ref) {
  return {
    type: SET_SEARCHBOX_REF,
    ref,
  };
}

/**
 * 検索条件をlocalStorageに保存する
 */
export const SAVE_SEARCH_CONDITIONS = `${prefix}/SAVE_SEARCH_CONDITIONS`;
export function saveSearchConditions() {
  return { type: SAVE_SEARCH_CONDITIONS };
}

export const RESET_SEARCH_CONDITIONS = `${prefix}/RESET_SEARCH_CONDITIONS`;
export function resetSearchConditions() {
  return {
    type: RESET_SEARCH_CONDITIONS,
  };
}

export const SET_SEARCH_CONDITIONS = `${prefix}/SET_SEARCH_CONDITIONS`;
export function setSearchConditions(queryObj) {
  return {
    type: SET_SEARCH_CONDITIONS,
    data: queryObj,
  };
}

export const SET_COLUMN_VISIBILITIES = `${prefix}/SET_COLUMN_VISIBILITIES`;
export function setColumnVisibilities(columnVisibilities) {
  return {
    type: SET_COLUMN_VISIBILITIES,
    columnVisibilities,
  };
}

export const SET_REACT_TABLE_COLUMN_PARAMS = `${prefix}/SET_REACT_TABLE_COLUMN_PARAMS`;
export function setReactTableColumnParams(reactTableColumnParams) {
  return {
    type: SET_REACT_TABLE_COLUMN_PARAMS,
    reactTableColumnParams,
  };
}

export const SET_REACT_TABLE_COLUMN_WIDTH = `${prefix}/SET_REACT_TABLE_COLUMN_WIDTH`;
export function changeColumnWidth(id, width) {
  return {
    type: SET_REACT_TABLE_COLUMN_WIDTH,
    payload: {
      id,
      width,
    },
  };
}

/**
 * カラムの可視状態をデフォルト値に変更する
 */
export const RESET_COLUMN_VISIBILITIES = `${prefix}/RESET_COLUMN_VISIBILITIES`;
export function resetColumnVisibilities() {
  return { type: RESET_COLUMN_VISIBILITIES };
}

export const RESET_REACT_TABLE_COLUMN_PARAMS = `${prefix}/RESET_REACT_TABLE_COLUMN_PARAMS`;
export function resetReactTableColumnParams() {
  return { type: RESET_REACT_TABLE_COLUMN_PARAMS };
}

export const SET_CURRENT_PAGE = `${prefix}/SET_CURRENT_PAGE`;
export function setCurrentPage(page) {
  return {
    type: SET_CURRENT_PAGE,
    value: page,
  };
}

export const SET_SIZE_PER_PAGE = `${prefix}/SET_SIZE_PER_PAGE`;
export function setSizePerPage(sizePerPage) {
  return {
    type: SET_SIZE_PER_PAGE,
    value: sizePerPage,
  };
}

// Transaction Table
/*
 * @param {string} prop - 更新するプロパティ名。nullの時は、全てのプロパティを更新する
 * @param {Array[object]} transactions - 経費リスト
 */
export function collectParams(transactions, prop) {
  return {
    transactions: snakecaseKeys(
      transactions.map((t) => {
        const ownerId = t.ownerId;

        if (_.isNil(prop)) {
          return { ...t, ownerId };
        }
        // 外貨入力されているデータの金額が編集された時、originalAmountはそのままにする
        if (prop === "amount") {
          return { ...buildAmountParams(t), ownerId };
        }
        if (prop === "project" && _.isNil(t[prop])) {
          return { id: t.id, [prop]: {}, ownerId };
        }
        if (prop === "department") {
          return {
            id: t.id,
            departmentId: _.get(t[prop], "id", null),
            ownerId,
          };
        }
        return { id: t.id, [prop]: t[prop], ownerId };
      }),
    ),
  };
}

/**
 * 金額のパラメータを生成します。
 *
 * @param {Transaction} expense 経費
 * @return {Object} 金額更新のパラメータ
 */
function buildAmountParams(expense) {
  const params = { id: expense.id, amount: +expense.amount };

  const isForeignCurrency =
    !_.isNil(expense.originalAmountCurrencyId) &&
    expense.originalAmountCurrencyId !== expense.baseCurrencyId;
  if (isForeignCurrency) {
    // サーバーにレートを再計算させるために、transactedAt, originalAmount, originalAmountCurrencyIdを再送する
    Object.assign(params, {
      originalAmount: expense.originalAmount,
      transactedAt: expense.transactedAt,
      originalAmountCurrencyId: expense.originalAmountCurrencyId,
    });
  } else {
    // 外貨入力されていないデータの金額が編集された時、originalAmountとamountは同じ値にする
    Object.assign(params, { originalAmount: params.amount });
  }
  const hasOneTaxCategoryAmount =
    _.get(expense.expenseAmountPerTaxCategories, "length", 0) === 1;
  // 税区分金額が1件の場合、金額を同期する。
  if (hasOneTaxCategoryAmount) {
    Object.assign(params, {
      amountPerTaxCategories: [
        {
          ...expense.expenseAmountPerTaxCategories[0],
          amount: params.amount,
        },
      ],
    });
  }
  return params;
}

export function updateTransactions(
  transactions,
  prop = null,
  currentTransactions = {},
  updateExpenseProps = true,
) {
  return async (dispatch, getState) => {
    const noExpenses = !updateExpenseProps; // 更新後の経費取得を別アクションで行う場合にtrueにする
    const params = collectParams(transactions, prop);

    // 編集モードで経費科目を更新するとき、経費科目が未設定では更新しない。APIが入力値検証に対応していないため。
    if (
      prop === "categoryName" &&
      params.transactions.length > 0 &&
      !params.transactions[0].category_name
    ) {
      return null;
    }

    dispatch(lockFormButton());

    let data = null;
    try {
      data = await getExpenseApi().updateAll({ ...params, noExpenses });

      if (updateExpenseProps) {
        dispatch(
          insertPropsToTransactions(data.transactions, currentTransactions),
        );
        dispatch(unlockFormButton());
      }

      return data;
    } catch (e) {
      dispatch(displayMessage("error", getMessageFromResponse(e)));
    } finally {
      dispatch(unlockFormButton());
    }

    return data;
  };
}

function insertPropsToTransactions(updatedTransactions, currentTransactions) {
  return (dispatch, getState) => {
    const { master, inEdit } = currentTransactions;

    const nextMaster = [...master];
    const nextInEdit = [...inEdit];

    updatedTransactions.forEach((updatedTransaction) => {
      const masterIndex = master.findIndex(
        (t) => t.id === updatedTransaction.id,
      );
      nextMaster[masterIndex] = {
        ...master[masterIndex],
        ...updatedTransaction,
        ...{ editorSync: defaultEditorState },
      };
      const editIndex = inEdit.findIndex((t) => t.id === updatedTransaction.id);
      nextInEdit[editIndex] = {
        ...inEdit[editIndex],
        ...updatedTransaction,
        ...{ editorSync: defaultEditorState },
      };
    });

    dispatch(setTransactions({ master: nextMaster, inEdit: nextInEdit }));
  };
}

export function destroyTransactions(resetTransactions) {
  return async (dispatch, getState) => {
    const { transactions, searchBoxRef } = getState();

    try {
      const selectedTransactions = transactions.master.filter(
        (x) => x && x.isSelected,
      );
      const ids = selectedTransactions.map((x) => x.id);
      const data = await Api.transactions.destroy_all({ ids });
      dispatch(displayMessage("success", data.message));

      resetTransactions();
    } catch (e) {
      dispatch(
        displayMessage(
          "error",
          getMessageFromResponse(
            e,
            i18next.t("transactions.errors.failedToDelete"),
          ),
        ),
      );
    }
  };
}

export function detachTransactions(transactions, resetTransactions) {
  return async (dispatch, getState) => {
    try {
      const selectedTransactions = transactions.master.filter(
        (x) => x && x.isSelected,
      );
      const inReport = selectedTransactions.filter((x) => x.reportId);
      const inPreReport = selectedTransactions.filter(
        (x) => !x.reportId && x.preReportId,
      );

      if (inReport.length > 0) {
        const grouped = _.groupBy(inReport, (x) => x.reportId);
        await Promise.all(
          Object.keys(grouped).map((reportId) =>
            Api.expenses.reports.detach({
              id: reportId,
              transaction_ids: grouped[reportId].map((x) => x.id), // eslint-disable-line camelcase
            }),
          ),
        );
      }

      if (inPreReport.length > 0) {
        const grouped = _.groupBy(inPreReport, (x) => x.preReportId);
        await Promise.all(
          Object.keys(grouped).map((preReportId) =>
            Api.preReports.detach({
              id: preReportId,
              transaction_ids: grouped[preReportId].map((x) => x.id), // eslint-disable-line camelcase
            }),
          ),
        );
      }

      dispatch(
        displayMessage("success", i18next.t("transactions.messages.detach")),
      );

      resetTransactions();
    } catch (e) {
      dispatch(
        displayMessage(
          "error",
          getMessageFromResponse(
            e,
            i18next.t("transactions.errors.failedToDetach"),
          ),
        ),
      );
    }
  };
}

export function mergeTransactions(ids, resetTransactions) {
  return (dispatch, getState) => {
    return dispatch(fetchAsync(Api.transactions.merge, { ids })).then(
      (data) => {
        dispatch(
          displayMessage("success", i18next.t("transactions.messages.merge")),
        );
        resetTransactions();
      },
    );
  };
}

/**
 * キャッシュしている経費一覧のレコードから、既に古くなったレコードを消す
 *
 * @param {object[]} ranges
 * @param {number} ranges.start キャッシュから削除する対象範囲のスタート位置
 * @param {number} ranges.end キャッシュから削除する対象範囲の終了位置。削除対象には含まれない
 */
export function clearStaleTransactions(ranges) {
  return (dispatch, getState) => {
    const {
      transactions: { master, inEdit },
    } = getState();

    const nextMaster = [...master];
    const nextInEdit = [...inEdit];

    ranges.forEach((range) => {
      const start = _.get(range, "start", 0);
      const end = _.get(range, "end", nextMaster.length);

      for (let i = start; i < end; i++) {
        nextMaster[i] = void 0;
        nextInEdit[i] = void 0;
      }
    });

    dispatch(setTransactions({ master: nextMaster, inEdit: nextInEdit }));
  };
}

export function clearAllCacheTransactions() {
  return (dispatch, getState) => {
    const ranges = [{ start: 0 }, { end: null }];
    dispatch(clearStaleTransactions(ranges));
  };
}

export const SET_TRANSACTIONS = `${prefix}/SET_TRANSACTIONS`;
export function setTransactions(obj) {
  return {
    type: SET_TRANSACTIONS,
    data: obj,
  };
}

export function selectTransaction(transactions, obj, selected = true) {
  return (dispatch, getState) => {
    const newMasterTransactions = transactions.master.map((x) => {
      if (_.isNil(x)) {
        return x;
      }
      if (x.id === obj.id) {
        return { ...x, isSelected: selected };
      }
      return { ...x };
    });

    const newEditingTransactions = transactions.inEdit.map((x) => {
      if (_.isNil(x)) {
        return x;
      }
      if (x.id === obj.id) {
        return { ...x, isSelected: selected };
      }
      return { ...x };
    });

    dispatch(
      setTransactions({
        master: newMasterTransactions,
        inEdit: newEditingTransactions,
      }),
    );
  };
}

export function selectTransactions(ids) {
  return (dispatch, getState) => {
    const { transactions } = getState();

    const newMasterTransactions = transactions.master.map((x) => {
      if (_.isNil(x)) {
        return x;
      }
      if (_.includes(ids, x.id)) {
        return { ...x, isSelected: true };
      }
      return { ...x, isSelected: false };
    });

    const newEditingTransactions = transactions.inEdit.map((x) => {
      if (_.isNil(x)) {
        return x;
      }
      if (_.includes(ids, x.id)) {
        return { ...x, isSelected: true };
      }
      return { ...x, isSelected: false };
    });

    dispatch(
      setTransactions({
        master: newMasterTransactions,
        inEdit: newEditingTransactions,
      }),
    );
  };
}

export function selectAllTransactions(
  currentPage,
  sizePerPage,
  transactions,
  selected = true,
) {
  return (dispatch, getState) => {
    const offset = (currentPage - 1) * sizePerPage;

    const newMasterTransactions = transactions.master.map((x, idx) => {
      // 未取得の経費、及び画面に表示していない経費は無視する
      if (_.isNil(x) || idx < offset || idx >= offset + sizePerPage) {
        return x;
      }

      return {
        ...x,
        isSelected: selected,
      };
    });

    const newEditingTransactions = transactions.inEdit.map((x) => {
      if (_.isNil(x)) {
        return x;
      }

      return {
        ...x,
        isSelected: selected,
      };
    });

    dispatch(
      setTransactions({
        master: newMasterTransactions,
        inEdit: newEditingTransactions,
      }),
    );
  };
}

// AutoSuggest
// suggestionの更新イベント
export function requestSuggestionsUpdate(id, key, reason, value) {
  return (dispatch, getState) => {
    if (
      reason === "click" ||
      reason === "enter" ||
      _.isNil(value) ||
      value.length === 0
    ) {
      dispatch(initCurrentSuggestions(id, key));
      return;
    }

    const { suggestions, transactionTable } = getState();
    let correctSuggestions;
    if (transactionTable?.suggestions) {
      // NOTE: 精算申請の経費一覧から使った場合にストアの形が違うため、目的のsuggestionsを取得する
      correctSuggestions = transactionTable?.suggestions;
    } else {
      // 経費一覧の場合
      correctSuggestions = suggestions;
    }

    const nextSuggestions = filterSuggestions(
      key,
      correctSuggestions[key],
      value,
    );
    dispatch(updateCurrentSuggestions(id, key, nextSuggestions));
  };
}

// 全体の候補を初期化する。サーバからfetchした値をセットする。
export const INIT_TOTAL_SUGGESTIONS = `${prefix}/INIT_TOTAL_SUGGESTIONS`;
export function initTotalSuggestions(key, data) {
  return {
    type: INIT_TOTAL_SUGGESTIONS,
    key,
    data,
  };
}

export function selectSuggestion(id, key, value) {
  return (dispatch, getState) => {
    dispatch(setFormData(id, key, value));
    dispatch(initCurrentSuggestions(id, key));
  };
}

/**
 * 入力された値から候補になりうる対象を取り出す
 * 経費の編集モードにおける選択系のセルに文字を入力した際に発火する
 *
 * @param {string} key 編集対象のカラム
 * @param {string[]} total キーに対応する候補のリスト
 * @param {string} value ユーザの入力値
 * @returns {string[]} 候補となりえる対象
 */
export function filterSuggestions(key, total, value) {
  const reg = new RegExp(`.*${_.escapeRegExp(value.trim())}.*`, "g");
  switch (key) {
    case "categoryName":
    case "creditCategoryName":
    case "groupName": {
      return total.filter((x) => x.match(reg));
    }
    case "project": {
      return total.filter((x) => x.name.match(reg) || x.displayId.match(reg));
    }
    default:
      return total;
  }
}

// suggestionを初期化する
export const INIT_CURRENT_SUGGESTIONS = `${prefix}/INIT_CURRENT_SUGGESTIONS`;
export function initCurrentSuggestions(id, key) {
  return {
    type: INIT_CURRENT_SUGGESTIONS,
    id,
    key,
  };
}

// suggestionを空にする
export const CLEAR_CURRENT_SUGGESTIONS = `${prefix}/CLEAR_CURRENT_SUGGESTIONS`;
export function clearCurrentSuggestions(id, key) {
  return {
    type: CLEAR_CURRENT_SUGGESTIONS,
    id,
    key,
  };
}

// suggestionsをフィルタして、値をセットする
export const UPDATE_CURRENT_SUGGESTIONS = `${prefix}/UPDATE_CURRENT_SUGGESTIONS`;
export function updateCurrentSuggestions(id, key, suggestions) {
  return {
    type: UPDATE_CURRENT_SUGGESTIONS,
    id,
    key,
    data: suggestions,
  };
}

// AutoSuggestに入力中のテキストをセットする
export const SET_SELECT_TEXT = `${prefix}/SET_SELECT_TEXT`;
export function setSelectText(id, key, value) {
  return {
    type: SET_SELECT_TEXT,
    id,
    key,
    value,
  };
}

export const SET_FORM_DATA = `${prefix}/SET_FORM_DATA`;
export function setFormData(id, key, value) {
  return {
    type: SET_FORM_DATA,
    id,
    key,
    value,
  };
}

export const SET_MODAL_TRANSACTION = `${prefix}/SET_MODAL_TRANSACTION`;
export function setModalTransaction(expense, previousId = null, nextId = null) {
  return {
    type: SET_MODAL_TRANSACTION,
    payload: { expense, previousId, nextId },
  };
}

export const SET_MERGABLE_AGGREGATION_RESULT = `${prefix}/SET_MERGEABLE_AGGREGATION_RESULT`;
export function setMergeableAggregationResult(result) {
  return {
    type: SET_MERGABLE_AGGREGATION_RESULT,
    data: result,
  };
}

export const SET_ENTRY_FORMS = `${prefix}/SET_ENTRY_FORMS`;
export function setEntryForms(data) {
  return {
    type: SET_ENTRY_FORMS,
    data,
  };
}

export const TOGGLE_DELETE_MODAL = `${prefix}/TOGGLE_DELETE_MODAL`;
export function openDeleteModal() {
  return {
    type: TOGGLE_DELETE_MODAL,
    show: true,
  };
}

export function closeDeleteModal() {
  return {
    type: TOGGLE_DELETE_MODAL,
    show: false,
  };
}

export const TOGGLE_TRANSACTION_MODAL = `${prefix}/TOGGLE_TRANSACTION_MODAL`;
export function openTransactionModal() {
  return async (dispatch, getState) => {
    flash.setOptions({ positionClass: "toast-modal-lg-top-right" });
    const action = {
      type: TOGGLE_TRANSACTION_MODAL,
      show: true,
    };

    const state = getState();
    const modal = state.modal || _.get(state, "transactionTable.modal");

    if (modal) {
      const directProductTableId = _.get(
        modal,
        "transaction.directProductTableId",
      );

      if (directProductTableId) {
        await dispatch(fetchEntryForm("allowance", directProductTableId));
      }
    }

    return dispatch(action);
  };
}

export function closeTransactionModal() {
  return (dispatch, getState) => {
    flash.resetOptions();
    const action = {
      type: TOGGLE_TRANSACTION_MODAL,
      show: false,
    };

    dispatch(action);
  };
}

export const TOGGLE_COST_ALLOCATION_MODAL = `${prefix}/TOGGLE_COST_ALLOCATION_MODAL`;
export function openCostAllocationModal() {
  return {
    type: TOGGLE_COST_ALLOCATION_MODAL,
    show: true,
  };
}

export function closeCostAllocationModal() {
  return {
    type: TOGGLE_COST_ALLOCATION_MODAL,
    show: false,
  };
}

export const SET_COST_ALLOCATIONS = `${prefix}/SET_COST_ALLOCATIONS`;
export function setCostAllocations(departments) {
  const data = departments.map((department) =>
    _.pick(department, ["payerId", "payerAbsolutePath", "numerator"]),
  );
  return {
    type: SET_COST_ALLOCATIONS,
    data,
  };
}

export const RESET_COST_ALLOCATIONS = `${prefix}/RESET_COST_ALLOCATIONS`;
export function resetCostAllocations() {
  return {
    type: RESET_COST_ALLOCATIONS,
  };
}

export const SET_COST_ALLOCATION_HOVOR_ROW_IDX = `${prefix}/SET_COST_ALLOCATION_HOVOR_ROW_IDX`;
export function onMouseEnterCostAlocationDiv(rowIdx) {
  return {
    type: SET_COST_ALLOCATION_HOVOR_ROW_IDX,
    rowIdx,
  };
}

export function onMouseLeaveCostAlocationDiv() {
  return {
    type: SET_COST_ALLOCATION_HOVOR_ROW_IDX,
    idx: null,
  };
}

/**
 * @param {object[]} expenses
 * @param {object[]} expense
 * @param {string} currentPage - 経費一覧の現在のページ
 * @param {string} sizePerPage - 経費一覧の現在のページ表示件数
 * @param {object} config
 * @param {boolean} config.requireMergeableAggregation
 * @param {boolean} config.showDisabledCategories
 * @param {boolean} config.shouldMarkAsRead
 * @param {function} config.overwriteModalExpense - 承認画面や精算申請詳細画面で経費のobjectを無理やり書き換えるために使う。deprecatedなのは言うまでもない
 */
export function openTransactionEditModal(
  expenses,
  expense,
  currentPage,
  sizePerPage,
  config = {},
) {
  return async (dispatch, getState) => {
    if (expense.categoryRequiresCompanion) {
      dispatch(setShouldSelectSelfAsCompanion(false));
    }

    const modalExpense = config.showDisabledCategories
      ? {
          ...expense,
          showDisabledCategories: true,
        }
      : expense;

    // 経費保存時に、保存前の時点での前後の経費に移動可能なようにしておくため、経費表示前に予め前後の経費を取得しておく
    // 保存後だと、表示中の経費が一覧中（の同じ場所）に存在する保証がなくなる
    const { page, previousId, nextId } = await dispatch(
      fetchPageAndAdjacentExpenses(
        expenses,
        expense,
        currentPage,
        sizePerPage,
        config.isRequestableExpenses,
      ),
    );

    if (page !== currentPage) {
      dispatch(setCurrentPage(page));
    }

    dispatch(
      setUpdateModalTransaction(
        config.overwriteModalExpense
          ? config.overwriteModalExpense(modalExpense)
          : modalExpense,
        previousId,
        nextId,
      ),
    );

    if (config.requireMergeableAggregation) {
      dispatch(fetchMergeableAggregation(expense.id));
    }

    dispatch(openTransactionModal());

    if (config.shouldMarkAsRead) {
      dispatch(markAsRead(expense.id.split()));
    }
  };
}

/**
 * 未取得の経費が含まれる最小範囲を返す.
 *
 * @param {object[]} expenses
 * @param {number} fromIdx - 探索開始位置で、探索対象に含まれる.
 * @param {number} toIdx - 探索終了位置で、探索対象に含まれない.
 */
function findRangeToBeFetched(expenses, fromIdx = 0, toIdx = expenses.length) {
  let minIdx = -1;
  let maxIdx = -1;

  for (let i = fromIdx; i < Math.min(toIdx, expenses.length); i++) {
    if (!expenses[i]) {
      maxIdx = i;

      if (minIdx === -1) {
        minIdx = i;
      }
    }
  }

  return [minIdx, maxIdx];
}

function fetchPageAndAdjacentExpenses(
  expenses,
  expense,
  currentPage,
  sizePerPage,
  isRequestableExpenses,
) {
  return async (dispatch, _getState) => {
    const expenseIdx = expenses.findIndex((x) => x?.id === expense.id);

    if (expenseIdx === void 0) {
      // 表示中の経費が、検索結果から消失している場合
      // 通常実行されないはず
      return { page: currentPage, previousId: null, nextId: null };
    }

    // 以下で、前後の経費、および新しく表示するページの経費を取得しておく

    const nextPage = Math.floor(expenseIdx / sizePerPage) + 1;
    const nextPageOffset = (nextPage - 1) * sizePerPage;
    const minIdx = Math.min(nextPageOffset, Math.max(0, expenseIdx - 5)); // 前5件の経費、および表示ページの先頭の経費が取得されるように
    const maxIdx = Math.max(
      nextPageOffset + sizePerPage - 1,
      Math.min(expenseIdx + 5, expenses.length - 1),
    ); // 後ろ5件の経費、および表示ページの最後の経費が取得されるように
    const range = findRangeToBeFetched(expenses, minIdx, maxIdx + 1);

    if (range[0] < 0) {
      return {
        page: nextPage,
        previousId: expenseIdx - 1 < 0 ? null : expenses[expenseIdx - 1].id,
        nextId:
          expenseIdx + 1 < expenses.length ? expenses[expenseIdx + 1].id : null,
      };
    }

    // TODO: 経費の取得はComponentに任せたい（ページを更新すればComponentが必要なデータを取得するなど）
    const {
      transactions: { master },
    } = await dispatch(
      fetchTransactions(
        range[0],
        range[1] - range[0] + 1,
        null,
        isRequestableExpenses,
      ),
    );

    // NOTE:
    //  以降、currentExpenseに対応する経費のindexが、expensesとmasterで一致する前提の処理.
    //  masterの位置が変わってしまっている場合、前後の経費が取得できている保証がなくなり、処理が完了しない.

    return {
      page: nextPage,
      // ATTENTION!:
      //   申請可能経費一覧においては、サーバー実装にバグがあり、offset + limit < countであっても、指定したlimitより少ない経費しか取得できないことがある
      previousId: expenseIdx - 1 < 0 ? null : master[expenseIdx - 1]?.id,
      nextId:
        expenseIdx + 1 < master.length ? master[expenseIdx + 1]?.id : null,
    };
  };
}

/**
 * @param {object[]} expenses
 * @param {string} targetExpenseId - 次にモーダルで開く経費のID。null
 * @param {string} currentPage - 経費一覧の現在のページ
 * @param {string} sizePerPage - 経費一覧の現在のページ表示件数
 * @param {object} openModalConfig - 経費モーダルを開く際の前処理用のフラグ。画面によってなぜか処理がことなるので
 */
export function moveExpenseCursor(
  expenses,
  targetExpenseId,
  currentPage,
  sizePerPage,
  openModalConfig = {},
) {
  return async (dispatch, _getState) => {
    const nextExpense = expenses.find((x) => x?.id === targetExpenseId);

    if (nextExpense) {
      // 通常trueになるはず
      dispatch(
        openTransactionEditModal(
          expenses,
          nextExpense,
          currentPage,
          sizePerPage,
          openModalConfig,
        ),
      );
    }
  };
}

export const TOGGLE_FORM_BUTTON = `${prefix}/TOGGLE_FORM_BUTTON`;
export function lockFormButton() {
  return {
    type: TOGGLE_FORM_BUTTON,
    disabled: true,
  };
}

export function unlockFormButton() {
  return {
    type: TOGGLE_FORM_BUTTON,
    disabled: false,
  };
}

export const TOGGLE_EDIT_MODE = `${prefix}/TOGGLE_EDIT_MODE`;
export function toggleEditMode(state) {
  return {
    type: TOGGLE_EDIT_MODE,
    state,
  };
}

export const SET_SHOULD_SELECT_SELF_AS_COMPANION = `${prefix}/SET_SHOULD_SELECT_SELF_AS_COMPANION`;
export function setShouldSelectSelfAsCompanion(state) {
  return {
    type: SET_SHOULD_SELECT_SELF_AS_COMPANION,
    state,
  };
}

export function setNewModalTransaction(transaction) {
  return (dispatch, getState) => {
    dispatch(
      setModalTransaction({
        ...transaction,
        permissions: { deletable: false },
        editable: true,
      }),
    );
  };
}

export function setUpdateModalTransaction(
  transaction,
  previousId = null,
  nextId = null,
) {
  return (dispatch, _getState) => {
    dispatch(
      setModalTransaction(
        {
          ...transaction,
          costAllocations:
            transaction.costAllocations.length === 0
              ? getEmptyCostAllocations()
              : transaction.costAllocations,
        },
        previousId,
        nextId,
      ),
    );
  };
}

export function setUpdateAllModalTransaction(
  transaction,
  preReportTitle = null,
) {
  return (dispatch, getState) => {
    dispatch(
      setModalTransaction({
        ...transaction,
        transactedAt: "",
        groupName: "",
        status: "default",
        formValues: [
          { id: null, type: "pre_report_input", formValue: preReportTitle },
        ],
        // TransactionとFareTransactionに共通する項目のみ入力できるように
        // 画像は複数の経費に同じ画像を設定する事が無い想定
        permissions: { margeable: false, deletable: false, detachable: false },
        editable: true,
        submitOnlyFilledInput: true,
        costAllocations: getEmptyCostAllocations(),
        preReportTitle,
      }),
    );
  };
}

// 複数のtransactionのpermissionから、全てのtransactionに適用できるpermissionを求める
// 許可しない設定が優先される
// onlyは積集合、exceptは和集合、allは積集合
export function mergePermissions(permissions) {
  return permissions.reduce((acc, permission) => {
    const permissionTypes = [
      "deletable",
      "detachable",
      "updatable",
      "readable",
    ];

    const permissionBuffer = permissionTypes.reduce((buff, permissionType) => {
      switch (permissionType) {
        case "deletable":
        case "detachable": {
          if (_.isNil(permission[permissionType])) {
            return { ...buff, [permissionType]: false };
          }

          const permitted = !!acc[permissionType];
          return { ...buff, [permissionType]: permitted };
        }
        default:
          return buff;
      }
    }, {});

    return permissionBuffer;
  }, {});
}

export function markAsRead(transactionIds) {
  return (dispatch, getState) => {
    const { approvalId } = getState();
    return dispatch(
      fetchAsync(Api.transactionReadMarks.markAsRead, {
        transactionIds,
        approvalId,
      }),
    ).then((data) => dispatch(setReadIds(transactionIds)));
  };
}

export function markAsUnread(transactionIds) {
  return (dispatch, getState) => {
    return dispatch(
      fetchAsync(Api.transactionReadMarks.markAsUnread, { transactionIds }),
    ).then((data) => dispatch(setUneadIds(transactionIds)));
  };
}

export function setReadIds(transactionIds) {
  return (dispatch) => {
    transactionIds.forEach((id, index) => {
      dispatch(setReadMark(id));
    });
  };
}

export function setUneadIds(transactionIds) {
  return (dispatch) => {
    transactionIds.forEach((id, index) => {
      dispatch(setUnreadMark(id));
    });
  };
}

export const SET_READ_AS_MARK = `${prefix}/SET_READ_AS_MARK`;
export function setReadMark(id) {
  return {
    type: SET_READ_AS_MARK,
    id,
  };
}

export const SET_UNREAD_AS_MARK = `${prefix}/SET_UNREAD_AS_MARK`;
export function setUnreadMark(id) {
  return {
    type: SET_UNREAD_AS_MARK,
    id,
  };
}

/**
 * 領収書画像の回転情報を保存する
 * 経費詳細を再度開いた時に、直前の回転状態で表示されるようにするため
 *
 * TODO: 回転保存後に、対象の経費1件の情報だけをサーバーから取り直すのが簡明と思われるので、経費入力フォームの改修時には処理を要検討
 */
export const ROTATE_RECEIPT_FILE = `${prefix}/ROTATE_RECEIPT_FILE`;
export function rotateReceiptFile(expenseId, fileId, rotation) {
  return {
    type: ROTATE_RECEIPT_FILE,
    id: expenseId,
    payload: { fileId, rotation },
  };
}

export const IS_SEARCH_WITH_ON_DELETED = `${prefix}/IS_SEARCH_WITH_ON_DELETED`;
export function setIsSearchWithOnlyDeleted(isSearchWithOnlyDeleted) {
  return {
    type: IS_SEARCH_WITH_ON_DELETED,
    isSearchWithOnlyDeleted,
  };
}
