import NumberField from "components/fields/NumberField";
import PropTypes from "prop-types";
import React, { Component } from "react";
import styled from "styled-components";
import Formatter from "utilities/formatter";
import colors from "values/colors";

const TaxCategoryRowView = styled.div`
  display: flex;
  align-items: center;
  padding: 4px 0;
  border-bottom: 1px solid ${colors.renewaled.borderGrey};
  .name-column {
    overflow-wrap: "break-word";
    width: 50%;
    min-width: 150px;
    margin-right: 8px;
    margin-left: 4px;
  }
  .rate-column {
    width: 60px;
    margin-right: 8px;
  }
  .amount-column {
    white-space: "nowrap";
    margin-right: 4px;
  }
  :nth-child(1) {
    border-top: 1px solid ${colors.renewaled.borderGrey};
  }
`;

export default class AmountPerTaxCategoryTable extends Component {
  constructor(props) {
    super(props);

    this.handleAmountChange = this.handleAmountChange.bind(this);
  }

  handleAmountChange(taxCategoryId, amount) {
    this.props.onAmountChange(taxCategoryId, amount);
  }

  renderTaxCategoryNameColumn(name) {
    return <div className="name-column">{name}</div>;
  }

  renderTaxRateColumn(taxRate) {
    return <div className="rate-column">{Formatter.percent(taxRate)}</div>;
  }

  renderAmountColumn(amount) {
    return (
      <div className="amount-column">
        <p className="form-control-static">{Formatter.amount(amount)}</p>
      </div>
    );
  }

  renderEditableAmountColumn(taxCategoryId, amount) {
    // 2件未満の場合、金額は表示しない。
    const visibility = this.props.values.length < 2 ? "invisible" : "visible";

    return (
      <div className="amount-column">
        <NumberField
          className={`form-control text-right ${visibility}`}
          allowMinus={true}
          allowZeroPadding={true}
          allowDecimal={true}
          value={amount}
          onChange={(value) => {
            this.handleAmountChange(taxCategoryId, value);
          }}
        />
      </div>
    );
  }

  render() {
    const { values, showName, editable } = this.props;

    // 編集可能な場合は税区分金額が0件以上の場合、または、編集が不可能な場合、テーブル表示させる
    const showTable = (values.length > 0 && editable) || !editable;

    return (
      <>
        {showTable && (
          <div className="">
            {values.map((value) => {
              const {
                taxCategory: { id, name, taxRate },
                amount,
              } = value;
              return (
                <TaxCategoryRowView key={id} className="tax-category-row">
                  {showName ? this.renderTaxCategoryNameColumn(name) : null}
                  {this.renderTaxRateColumn(taxRate)}
                  {editable
                    ? this.renderEditableAmountColumn(id, amount)
                    : this.renderAmountColumn(amount)}
                </TaxCategoryRowView>
              );
            })}
          </div>
        )}
      </>
    );
  }
}

AmountPerTaxCategoryTable.defaultProps = {
  values: [],
  editable: false,
  showName: false,
  onAmountChange: () => {},
  asEligibleInvoice: false,
  dateInput: null,
};

const taxCategoryPropTypes = PropTypes.shape({
  id: PropTypes.string,
  name: PropTypes.string,
  taxRate: PropTypes.number,
  startAt: PropTypes.string,
  expireAt: PropTypes.string,
});

const amountPerTaxCategoryPropTypes = PropTypes.shape({
  id: PropTypes.string,
  taxCategory: taxCategoryPropTypes,
  amount: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
});

AmountPerTaxCategoryTable.propTypes = {
  values: PropTypes.arrayOf(amountPerTaxCategoryPropTypes),
  editable: PropTypes.bool,
  showName: PropTypes.bool,
  onUpdate: PropTypes.func,
  asEligibleInvoice: PropTypes.bool,
  dateInput: PropTypes.string,
};
