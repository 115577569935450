import i18next from "i18n";
import React from "react";
import { TemporaryPayment } from "utilities/api/models/TemporaryPayment";
import REPORT_STATUS from "../../../../values/report_status";
import * as Buttons from "../buttons";
import { RequestState } from "../buttons/Types";

interface Props {
  locale: string;
  preReport: {
    status: string;
    temporaryPayment?: TemporaryPayment;
  };
  isEditing: boolean;
  requestState: RequestState;
  onEditRequestButtonClick: () => void;
  onSettlementButtonClick: () => void;
  onTemporaryPaymentDownloadButtonClick: () => void;
  onSwitchEditContentButtonClick: () => void;
}

/**
 * アマナ様専用 承認前の事前申請（精算申請なし）の申請者用ボタン
 * 修正申請が可能
 */
const ButtonsForRequesterAfterApproved: React.FC<Props> = ({
  locale,
  preReport,
  requestState,
  isEditing,
  onEditRequestButtonClick,
  onSettlementButtonClick,
  onTemporaryPaymentDownloadButtonClick,
  onSwitchEditContentButtonClick,
}) => {
  // 事前申請編集中
  if (isEditing) {
    return (
      <Buttons.EditRequestButton
        requestState={requestState}
        onClick={onEditRequestButtonClick}
      />
    );
  }

  if (preReport.status === REPORT_STATUS[locale].pendingTemporaryPayment) {
    return (
      <button
        className="btn btn-block btn-success"
        onClick={onTemporaryPaymentDownloadButtonClick}
      >
        <i className="fa fa-left fa-paper-plane" />
        {i18next.t("commons.actions.downloadCashAdvanceRequest")}
      </button>
    );
  }

  if (preReport.temporaryPayment) {
    return (
      <Buttons.SettlementButton
        requestState={requestState}
        onClick={onSettlementButtonClick}
      />
    );
  }

  return (
    <div className="row">
      <div className="col-md-6">
        <button
          className="btn btn-block"
          onClick={onSwitchEditContentButtonClick}
        >
          {i18next.t("commons.actions.edit")}
        </button>
      </div>
      <div className="col-md-6">
        <Buttons.SettlementButton
          requestState={requestState}
          onClick={onSettlementButtonClick}
        />
      </div>
    </div>
  );
};

export default ButtonsForRequesterAfterApproved;
