import React from 'react';
import i18next from 'i18n';
import { Button } from 'components/renewaled_ui/buttons/Button';

interface Props {
  onClick: () => void;
  disabled: boolean;
  show?: boolean;
}

export const ConfirmConnectionButton: React.FC<Props> = ({
  onClick,
  disabled,
  show = true,
}) => {
  return (
    <Button styleType='link-secondary' onClick={ onClick } disabled={ disabled } show={ show }>
      { i18next.t('accountingDataScheduledExports.fileTransferLocations.detailDrawer.connectionCheck') }
    </Button>
  );
};
