import Approvers from './Approvers';
import React from 'react';
import { ApprovalStep, RequestStep } from 'utilities/api/models/ApprovalStepsForReject';
import { HelpBlock } from 'react-bootstrap';

interface Props {
  selectedStep: RequestStep | ApprovalStep;
}

/**
 * 選択された差戻先の名称のリストを表示します。
 */

export const RejectToDetail: React.FC<Props> = ({
  selectedStep,
}) => {
  if ('approvers' in selectedStep) {
    return (
      <HelpBlock>
        <Approvers
          label={ (selectedStep as ApprovalStep).approvers.map((a) => a.name).join(', ') }
          totalApprover={ (selectedStep as ApprovalStep).approvers?.length }
          selectedStep={ selectedStep }
       />
      </HelpBlock>
    );
  }

  return <HelpBlock>{ (selectedStep as RequestStep).name }</HelpBlock>;
};
