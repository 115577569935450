import React from "react";
import { RouteLabel, RouteVia } from "utilities/api/models/Route";
import { ViaPoint } from "./ViaPoint";

interface Props {
  /** 利用した経路 */
  vias: Array<RouteVia>;
  /** 経路に対するメタ的なラベル */
  labels: Array<RouteLabel>;
}

/** 経路の持つラベルから、経路全体において描画するラベルクラス名を返す */
function labelClass(label: RouteLabel): string {
  switch (label) {
    case "楽":
      return "route-label raku";
    case "早":
      return "route-label haya";
    case "安":
      return "route-label yasu";
    default:
      return "route-label";
  }
}

/** 詳細な経路情報を描画する */
export const RoutePath = ({ vias, labels }: Props): React.ReactElement => {
  return (
    <div style={{ wordWrap: "break-word" }}>
      <div className="route-label-display">
        {labels.map((label, index) => (
          <div className={labelClass(label)} key={`label-${index}`}>
            {label}
          </div>
        ))}
      </div>
      {vias.map((via, index) => (
        <ViaPoint
          key={index}
          type={via.type}
          name={via.name}
          color={via.color}
        />
      ))}
    </div>
  );
};
