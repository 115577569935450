import i18next from "i18next";
import React from "react";
import { Props } from "./Types";

/** 「差し戻す」ボタン */
const RejectButton: React.FC<Props> = ({ requestState, onClick }) => {
  return (
    <button
      className={`btn btn-outline btn-block btn-danger${
        requestState.isJobRunning ? " disabled" : ""
      }`}
      onClick={onClick}
    >
      <i className={"fa fa-left fa-undo"} />{" "}
      {i18next.t("commons.actions.reject")}
    </button>
  );
};

export default RejectButton;
