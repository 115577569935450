import * as ActionTypes from '../actions';
import { analysesPreferenceInitialState } from '../Types';

const AnalysesPreference = (state = analysesPreferenceInitialState, action) => {
  switch (action.type) {
    case ActionTypes.SET_EXPORT_CHECK_FLG:
      return {
        ...state,
        watchAccountingDataExport: action.watchAccountingDataExport,
      };
    case ActionTypes.SET_PAYMENT_REQUEST_EXPORT_CHECK_FLG:
      return {
        ...state,
        paymentRequestsWatchAccountingDataExport: action.paymentRequestsWatchAccountingDataExport,
      };
    default:
      return state;
  }
};

export default AnalysesPreference;
