import SmartCheckBox from "components/SmartCheckBox";
import i18next from "i18n";
import get from "lodash/get";
import isNil from "lodash/isNil";
import map from "lodash/map";
import pickBy from "lodash/pickBy";
import PropTypes from "prop-types";
import React, { Component } from "react";
import { BootstrapTable, TableHeaderColumn } from "react-bootstrap-table";
import { connect } from "react-redux";
import Formatter from "utilities/formatter";
import Timer from "utilities/timer";
import * as actions from "../actions/importForm";
import getImportForm from "../selectors/importForm";

export class ImportResultsForm extends Component {
  constructor(props) {
    super(props);

    this.pollingImportJobs = this.pollingImportJobs.bind(this);
    this.stopPollingImportJobs = this.stopPollingImportJobs.bind(this);
    this.renderDownloadButton = this.renderDownloadButton.bind(this);
    this.renderQueueInfo = this.renderQueueInfo.bind(this);
  }

  // eslint-disable-next-line camelcase
  UNSAFE_componentWillMount() {
    this.pollingImportJobs();
  }

  componentWillUnmount() {
    this.stopPollingImportJobs();
  }

  pollingImportJobs() {
    const {
      checkQueue,
      loadImportJobs,
      importType,
      exportFormatId,
      targetId,
      dataSetId,
    } = this.props;
    this.timer = new Timer(3000, [
      () =>
        checkQueue(
          importType,
          this.props.onExistQueue,
          this.props.onEmptyQueue,
        ),
      () =>
        loadImportJobs(
          importType,
          this.props.allImportResults,
          exportFormatId,
          targetId,
          dataSetId,
        ),
    ]);
    this.timer.start();
  }

  stopPollingImportJobs() {
    if (!isNil(this.timer)) {
      this.timer.stop();
    }
  }

  render() {
    const {
      allImportResults,
      showAllResults,
      hideConfirmedResults,
      importType,
      exportFormatId,
      targetId,
      dataSetId,
    } = this.props;

    const label = `${i18next.t(
      "transactions.imports.allImportResults",
    )}(${i18next.t("transactions.imports.defaultShow")})`;

    return (
      <div>
        <div className="row" style={{ paddingLeft: "10px" }}>
          <SmartCheckBox
            color="accent"
            checked={allImportResults}
            label={label}
            onChange={(isChecked) =>
              isChecked
                ? showAllResults(
                    importType,
                    exportFormatId,
                    targetId,
                    dataSetId,
                  )
                : hideConfirmedResults(
                    importType,
                    exportFormatId,
                    targetId,
                    dataSetId,
                  )
            }
          />
        </div>
        {this.renderQueueInfo()}
        <div className="row">
          <div className="form-horizontal xls-export">
            {this.renderImportJobTable()}
          </div>
        </div>
      </div>
    );
  }

  renderQueueInfo() {
    const { queueCounts } = this.props;
    const messageMap = {
      import: "インポートを実行中...",
      userRegister: "従業員を登録中...",
    };
    const existQueues = pickBy(queueCounts, (value, key) => value > 0);

    const status = map(existQueues, (value, key) => (
      <div key={`queue-${key}`} style={{ paddingBottom: "10px" }}>
        <span>
          <i
            className="fa fa-left fa-spinner fa-spin"
            style={{ marginRight: "5px" }}
          />
          {`${get(messageMap, key)}残り${value}件`}
        </span>
      </div>
    ));

    return Object.keys(existQueues).length > 0 ? (
      <div className="row" style={{ paddingTop: "10px", paddingLeft: "20px" }}>
        {status}
      </div>
    ) : null;
  }

  renderDownloadButton(url, row) {
    const { downloadResult, importType, exportFormatId } = this.props;

    if (
      ["success", "failure"].includes(row.status) ||
      (row.status === "error" && !isNil(url))
    ) {
      const className = `btn ${
        row.status === "success" ? "btn-accent" : "btn-outline btn-danger"
      }`;

      return (
        <div className="report-btn-group">
          <span>
            <button
              type="button"
              className={className}
              onClick={downloadResult.bind(
                null,
                row.id,
                importType,
                exportFormatId,
              )}
            >
              {i18next.t("exports.inputs.download")}
            </button>
          </span>
        </div>
      );
    }

    return null;
  }

  renderImportJobTable() {
    const { importJobs } = this.props;

    const options = {
      noDataText: i18next.t("commons.messages.noDataFound"),
    };

    return (
      <BootstrapTable
        className="import-jobs-table"
        data={importJobs}
        options={options}
        striped={true}
      >
        <TableHeaderColumn dataField="id" isKey={true} hidden={true}>
          ID
        </TableHeaderColumn>
        <TableHeaderColumn
          width="30"
          dataField="createdAt"
          dataFormat={(createdAt) =>
            (Formatter.datetime(createdAt, "") || "").replace(" ", `</br>`)
          }
        >
          {i18next.t("imports.startAt")}
        </TableHeaderColumn>
        <TableHeaderColumn
          width="30"
          dataField="completedAt"
          dataFormat={(completedAt) =>
            (Formatter.datetime(completedAt, "") || "").replace(" ", `</br>`)
          }
        >
          {i18next.t("imports.endAt")}
        </TableHeaderColumn>
        <TableHeaderColumn dataField="status" hidden={true}>
          ステータス
        </TableHeaderColumn>
        <TableHeaderColumn
          width="200"
          tdStyle={{ wordBreak: "break-word", whiteSpace: "normal" }}
          dataField="message"
          dataFormat={(message, row) => {
            const messages = {
              started: i18next.t("importStatus.started"),
              importing: i18next.t("importStatus.importing"),
              uploading: i18next.t("importStatus.uploading"),
              failure: i18next.t("importStatus.failure"),
              success: i18next.t("importStatus.success"),
              error: i18next.t("importStatus.error"),
            };

            const messageText = Formatter.text(
              ["success", "failure", "error"].includes(row.status)
                ? row?.message || messages[row.status]
                : null,
              row?.message || messages[row.status],
              {
                position: "left",
                className: "fa-spinner fa-spin",
                style: { marginRight: "5px" },
              },
            );
            const fileName = Formatter.text(
              ["success", "failure", "error"].includes(row.status)
                ? `${i18next.t("paymentRequests.common.fileName")}:${
                    row.uploadFilename
                  }`
                : null,
              `${i18next.t("paymentRequests.common.fileName")}:(${
                row.uploadFilename
              })`,
            );

            return (
              <div>
                {messageText}
                <br />
                {fileName}
              </div>
            );
          }}
        >
          {i18next.t("imports.message")}
        </TableHeaderColumn>
        <TableHeaderColumn
          width="40"
          dataField="url"
          dataFormat={this.renderDownloadButton}
          dataAlign="center"
        >
          {i18next.t("imports.importResult")}
        </TableHeaderColumn>
      </BootstrapTable>
    );
  }
}

ImportResultsForm.defaultProps = {
  onExistQueue: null,
  onEmptyQueue: null,
};

ImportResultsForm.propTypes = {
  onExistQueue: PropTypes.func,
  onEmptyQueue: PropTypes.func,

  importJobs: PropTypes.array.isRequired,
  loadImportJobs: PropTypes.func.isRequired,
  checkQueue: PropTypes.func.isRequired,
  downloadResult: PropTypes.func.isRequired,

  importType: PropTypes.string.isRequired,
  exportFormatId: PropTypes.string,
  targetId: PropTypes.string,
};

function mapDispatchToProps(dispatch, ownProps) {
  return {
    loadImportJobs(importType, all, exportFormatId, targetId, dataSetId) {
      dispatch(
        actions.fetchImportJobs(
          importType,
          all,
          exportFormatId,
          targetId,
          dataSetId,
        ),
      );
    },
    checkQueue(importType, queued, empty) {
      dispatch(actions.checkQueue(importType, queued, empty));
    },
    downloadResult(id, importType, exportFormatId) {
      dispatch(actions.downloadResult(id, importType, exportFormatId));
    },
    showAllResults(importType, exportFormatId, targetId, dataSetId) {
      dispatch(
        actions.fetchImportJobs(
          importType,
          true,
          exportFormatId,
          targetId,
          dataSetId,
        ),
      );
      dispatch(actions.showAllImportResults());
    },
    hideConfirmedResults(importType, exportFormatId, targetId, dataSetId) {
      dispatch(
        actions.fetchImportJobs(
          importType,
          false,
          exportFormatId,
          targetId,
          dataSetId,
        ),
      );
      dispatch(actions.hideConfirmedImportResults());
    },
  };
}

export default connect(getImportForm, mapDispatchToProps)(ImportResultsForm);
