import EmbeddedFlash from 'components/embedded_flash';
import Formatter from 'utilities/formatter';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import i18next from 'i18n';
import isNil from 'lodash/isNil';
import { BootstrapTable, TableHeaderColumn } from 'react-bootstrap-table';

const watchAccountingDataExport = userPreferences.preference.watchAccountingDataExport;

export default class ExportForm extends Component {
  constructor(props) {
    super(props);
    this.renderDownloadButton = this.renderDownloadButton.bind(this);
  }

  async onDownloadClick(e, url) {
    e.stopPropagation();

    if (watchAccountingDataExport) {
      if (!isNil(this.props.setAnalysisFileExportFlag)) {
        await this.props.setAnalysisFileExportFlag();
      }
    }

    window.location.href = url;
  }

  render() {
    return (
      <div className='row'>
        <EmbeddedFlash
          ref='embeddedFlash'
          style={ { padding: '0px 15px 15px' } }
        />
        { this.renderContent() }
      </div>
    );
  }

  renderDownloadButton(url) {
    const downloading = isNil(url);

    if (downloading) {
      return null;
    }

    return (
      <div className='report-btn-group'>
        <span>
          <button className='btn btn-accent'
            onClick={ (e) => (this.onDownloadClick(e, url)) }>
            { i18next.t('exports.inputs.download') }
          </button>
        </span>
      </div>
    );
  }

  renderAnalysisReportJobTable() {
    const options = {
      noDataText: i18next.t('commons.messages.noDataFound'),
    };
    const rows = this.props.data.map((x) => ({ ...x, completed: { completedAt: x.completedAt, errorMessage: x.errorMessage } }));
    return (
      <BootstrapTable className='analysis-report-jobs-table'
        data={ rows }
        options={ options }
        tableBodyClass='multiline'>
        <TableHeaderColumn dataField="id" isKey={ true } hidden={ true }>
          ID
        </TableHeaderColumn>
        <TableHeaderColumn
          dataField='createdAt'
          dataFormat={ Formatter.datetime }>
          { i18next.t('exports.inputs.requestedAt') }
        </TableHeaderColumn>
        <TableHeaderColumn
          dataField='completed'
          dataFormat={
            (completed) => (
              isNil(completed.errorMessage) ? Formatter.datetime(
                completed.completedAt,
                i18next.t('exports.inputs.processing'),
                {
                  position: 'left',
                  className: 'fa-spinner fa-spin',
                  style: { marginRight: '5px' },
                },
              ) : ''
            ) }>
          { i18next.t('exports.inputs.completedAt') }
        </TableHeaderColumn>
        <TableHeaderColumn
          hidden={ this.props.hiddenFields.indexOf('exportTypeName')  >= 0 }
          dataField='exportTypeName'>
          { i18next.t('exports.inputs.exportType') }
        </TableHeaderColumn>
        <TableHeaderColumn
          dataField='url'
          dataFormat={ this.renderDownloadButton }>
          { i18next.t('exports.inputs.download') }
        </TableHeaderColumn>
        <TableHeaderColumn
          hidden={ this.props.data.map((d) => d.errorMessage).every((d) => isNil(d)) }
          dataField='errorMessage'>
          { i18next.t('exports.inputs.error') }
        </TableHeaderColumn>
        <TableHeaderColumn
          hidden={ this.props.data.map((d) => d.resultMessage).every((d) => isNil(d)) }
          dataField='resultMessage'>
          { i18next.t('exports.inputs.result') }
        </TableHeaderColumn>
      </BootstrapTable>
    );
  }

  renderContent() {
    const {
      onRequestExport, requestButtonTitle, isExportButtonDisabled, renderAdditionalContent,
    } = this.props;

    return (
      <div className='form-horizontal xls-export'>
        <div className='form-group' style={ { paddingRight: '15px', paddingLeft: '15px' } }>
          <button
            className={ `btn btn-outline btn-success export-btn ${isExportButtonDisabled ? 'disabled' : ''}` }
            onClick={ onRequestExport }>
            { requestButtonTitle }
          </button>
        </div>
        <div className='form-group' style={ { paddingRight: '15px', paddingLeft: '15px' } }>
          { renderAdditionalContent() }
        </div>
        { this.renderAnalysisReportJobTable() }
      </div>
    );
  }
}

ExportForm.defaultProps = {
  hiddenFields: [],
  isExportButtonDisabled: false,
  renderAdditionalContent: () => {},
};

ExportForm.propTypes = {
  hiddenFields: PropTypes.arrayOf(PropTypes.string),
  data: PropTypes.arrayOf(PropTypes.object).isRequired,
  onRequestExport: PropTypes.func.isRequired,
  requestButtonTitle: PropTypes.string.isRequired,
  isExportButtonDisabled: PropTypes.bool.isRequired,
};
