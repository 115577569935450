import { MutableRefObject, useEffect, useRef } from 'react';

/**
 * unmountされた後にstateを操作しないようにするためのreferenceをフックします。
 *
 * 本来は、unmountされたコンポーネントがリソースを操作しているとメモリリークの原因になるため警告が出るようになっています。
 * unmount時に実行中の非同期アクションをキャンセルするなど、適切な処理をおこなうべきですが、
 * 修正が難しい場合にこのフックを使用します。
 *
 * @see {@link https://www.debuggr.io/react-update-unmounted-component/}
 * @see {@link https://medium.com/@shanplourde/avoid-react-state-update-warnings-on-unmounted-components-bcecf054e953}
 * @see {@link https://www.freecodecamp.org/news/how-to-work-with-react-the-right-way-to-avoid-some-common-pitfalls-fc9eb5e34d9e/}
 * @example
 * // unmount後に非同期アクションが完了し、state操作を避ける場合のコード
 * const mountedRef = useMountedRef();
 * const [processing, setProcessing] = useState(false);
 * const handleClick = useCallback(() => {
 *   setProcessing(true);
 *   onClick.finally(() => {
 *     if (mountedRef.current) {
 *       setProcessing(false);
 *     }
 *   });
 * });
 */
export function useMountedRef(): MutableRefObject<boolean> {
  const mountedRef = useRef(true); // unmountされた後にstateを操作しないようにするため

  useEffect(() => {
    mountedRef.current = true;
    return (): void => { mountedRef.current = false; };
  });

  return mountedRef;
}
