import PropTypes from 'prop-types';
import React, { Component } from 'react';
import ToggleButton from 'components/toggle_button';
import i18next from 'i18n';

/**
 * @classdesc This class provides editable text component.
 *            1つのテキストだけを編集するようなフォームを作りたいときに、さくっとその場で編集できるUIを提供する。
 */
export default class EditableText extends Component {
  constructor(props) {
    super(props);
    this.state = {
      text: this.props.text,
      editingText: false,
      receivingError: false,
    };
  }

  // eslint-disable-next-line camelcase
  UNSAFE_componentWillReceiveProps(nextProps) {
    this.setState({ text: nextProps.text });
  }


  componentDidUpdate() {
    if (this.refs.text) {
      this.refs.text.focus();
    }
  }

  onEditText(e) {
    e.stopPropagation();
    this.setState({ editingText: true });
  }

  onStopEditingText(e) {
    e.stopPropagation();
    // Clickイベントを発火させるため、非同期処理
    setTimeout(() => {
      if (!this.state.receivingError) {
        this.setState({
          editingText: false,
          receivingError: false,
        });
      }
    }, 200);
    this.props.onCancel();
  }

  onChangeText(e) {
    e.stopPropagation();
    e.preventDefault();
    const text = this.refs.text.value;

    if (!$('.edit-text-form').validationEngine('validate', this.props.validator)) {
      this.refs.button.activate();
      return;
    }
    if (text === this.state.text) {
      this.refs.button.activate();
      this.setState({
        editingText: false,
        receivingError: false,
      });
      return;
    }
    (async () => {
      try {
        await this.props.onEdit(text);
        this.setState({
          text,
          editingText: false,
          receivingError: false,
        });
      } catch (eAwait) {
        this.setState({
          editingText: true,
          receivingError: true,
        });
        setTimeout(() => {
          this.setState({
            receivingError: false,
          });
        }, 300);
        this.refs.button.activate();
      }
    })();
  }

  render() {
    return this.state.editingText
      ? this.renderOnEditing()
      : this.renderNotOnEditing();
  }

  renderOnEditing() {
    const validates = this.props.validate
      .reduce((a, b) => (`${a} validate[${b}]`), '');
    const inputClass = `${this.props.input.className} ${validates}`;
    return (
      <form className='edit-text-form'>
        <div className='form-group'>
          <div className='input-group col-sm-12'>
            <input
              type='text'
              className={ inputClass }
              placeholder={ this.props.input.placeholder }
              defaultValue={ this.state.text }
              // TODO: Firefox 88, Chrome 90, Edge 90 Blur -> Clickイベントの順番になっているのでクリックイベントが動かなくなるのでBlurイベントを無効にする
              // onBlur={ this.onStopEditingText.bind(this) }
              ref='text'
            />
            <span className='input-group-btn'>
              <ToggleButton
                ref='button'
                className={ this.props.button.className }
                type={ this.props.button.type }
                onClick={ this.onChangeText.bind(this) }
              >
                { this.props.button.text }
              </ToggleButton>
            </span>
          </div>
        </div>
      </form>
    );
  }

  renderNotOnEditing() {
    return (
      <span className={ this.props.className }>
        { this.state.text }
        <i
          aria-hidden='true'
          className={ this.props.icon.className }
          style={ this.props.icon.style }
          onClick={ this.onEditText.bind(this) }
        >
        </i>
      </span>
    );
  }
}

EditableText.defaultProps = {
  text:   '',
  className: 'editable-text',
  icon: {
    className: 'fas fa-pencil-alt txt-pointer',
    style: {
      marginLeft: '5px',
    },
  },
  onEdit: () => null,
  onCancel: () => null,
  validator: {},
  validate: [],
  input: {
    className: 'col-sm-12 form-control',
    placeholder: '',
  },
  button: {
    className: 'btn btn-success',
    type: 'button',
    text: i18next.t('commons.actions.save'),
  },
};

EditableText.propTypes = {
  text: PropTypes.string,
  className: PropTypes.string,
  icon: PropTypes.shape({
    className: PropTypes.string,
    style: PropTypes.object,
  }),
  onEdit: PropTypes.func,
  onCancel: PropTypes.func,
  validator: PropTypes.object,
  validate: PropTypes.arrayOf(PropTypes.string),
  input: PropTypes.shape({
    className: PropTypes.string,
    placeholder: PropTypes.string,
  }),
  button: PropTypes.shape({
    className: PropTypes.string,
    type: PropTypes.string,
    text: PropTypes.string,
  }),
};
