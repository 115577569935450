import React from 'react';
import i18next from 'i18n';
import { CompanyExpenseAccount } from 'utilities/api/models/CompanyExpenseAccount';

interface Props {
  item: CompanyExpenseAccount;
}

export const Item = ({
  item,
}: Props): React.ReactElement => {
  return (
    <div className='expense-account-selector txt-pointer'>
      <div className='bank-name'>
        { `${item.bank.name}  ${item.bankBranch.name}` }
      </div>
      <div className='account-info'>
        <div>{ `${i18next.t('preferences.expenseAccount.number')}:${item.number}` }</div>
        <div>{ (item.customerCode) && `${i18next.t('preferences.expenseAccount.customerCode')}:${item.customerCode}` }</div>
      </div>
      <div className='account-info'>
        <div>{ `${i18next.t('preferences.expenseAccount.holderKana')}:${item.holderKana}` }</div>
        <div>{ `${i18next.t('preferences.expenseAccount.type')}:${item.type}` }</div>
      </div>
    </div>
  );
};
