import { CreatorsToActions } from "types/UtilityTypes";
import * as ActionCreators from "../actions/CancelTemporaryPaymentRefund/ActionCreators";
import { cancelTemporaryPaymentRefundTypes as ActionTypes } from "../actions/CancelTemporaryPaymentRefund/ActionTypes";
import {
  initialState,
  State,
} from "../actions/CancelTemporaryPaymentRefund/Types";

type Actions = CreatorsToActions<typeof ActionCreators>;

export default function reducer(state = initialState, action: Actions): State {
  switch (action.type) {
    case ActionTypes.TOGGLE_MODAL: {
      return {
        ...state,
        show: action.payload.show,
      };
    }
    default:
      return state;
  }
}
