import React from 'react';
import colors from 'values/colors';
import i18next from 'i18n';
import styled from 'styled-components';
import { IconButton } from 'components/renewaled_ui/buttons';
import { useHooks } from './hooks';

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  color: ${colors.renewaled.textBlack};
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
`;
const ButtonGroup = styled.div`
  display: flex;
  align-items: center;
  margin-right: 24px;

  button {
    margin-right: 2px;

    i {
      font-size: 24px;
    }
  }
`;
const CurrentPage = styled.div`
  font-weight: 600;
  margin-right: 24px;
  font-size: 12px;
`;
const PaginationInputForm = styled.form`
  font-weight: 600;
  display: flex;
  align-items: center;
`;
const PaginationInput = styled.input`
  background: ${colors.renewaled.whiteHover};
  text-align: right;
  width: 70px;
  padding: 4px;
  border: none;
  border-radius: 4px;
  margin-right: 2px;

  &:focus {
    outline: none;
  }
`;
const PaginationInputText = styled.span`
  font-size: 12px;

  &.ja {
    margin-left: 4px;
  }
  &.en {
    margin-right: 4px;
  }
`;

interface Props {
  /** 現在のページ番号 */
  readonly currentPage: number;
  /** ページネーションの最大数 */
  readonly paginationSize: number;
  /** １ページあたりの数 */
  readonly sizePerPage: number;
  /** ページが変更された */
  readonly onPageChange: (page: number, sizePerPage: number) => void;
}

/**
 * テーブルのページネーション
 */
export const PaginationNew: React.FC<Props> = (props) => {
  const {
    page,
    canNextPage,
    canPreviousPage,
    handleInputPage,
    handlePageChange,
  } = useHooks(props.onPageChange, props.currentPage, props.paginationSize, props.sizePerPage);

  return (
    <Wrapper className='table-pagination'>
      <ButtonGroup className="button-group">
        {/* 一番前に戻る */}
        <IconButton
          onClick={ (): void => props.onPageChange(1, props.sizePerPage) }
          disabled={ !canPreviousPage() }
          className='fas fa-angle-double-left'
        />
        {/* 一つ前に戻る */}
        <IconButton
          onClick={ (): void => props.onPageChange(props.currentPage - 1, props.sizePerPage) }
          disabled={ !canPreviousPage() }
          className='fas fa-angle-left'
        />
        {/* 一つ進む */}
        <IconButton
          onClick={ (): void => props.onPageChange(props.currentPage + 1, props.sizePerPage) }
          disabled={ !canNextPage() }
          className='fas fa-angle-right'
        />
        {/* 一番最後に進む */}
        <IconButton
          onClick={ (): void => props.onPageChange(props.paginationSize, props.sizePerPage) }
          disabled={ !canNextPage() }
          className="fas fa-angle-double-right"
        />
      </ButtonGroup>

      <CurrentPage className='current-page'>
        { props.currentPage } / { props.paginationSize } { i18next.t('commons.table.pagenation.page') }
      </CurrentPage>

      <PaginationInputForm className='page-input' onSubmit={ handlePageChange }>
        { userPreferences.locale === 'en' && (
          <PaginationInputText className='page-input-text en'>
            { i18next.t('commons.table.pagenation.moveTo') }
          </PaginationInputText>
        ) }

        <PaginationInput
          type="text"
          value={ page }
          onChange={ handleInputPage }
          onBlur={ handlePageChange }
        />

        {/* 日本語の場合は、入力欄の次に表示する */}
        { userPreferences.locale === 'ja' && (
          <PaginationInputText className='page-input-text ja'>
            { i18next.t('commons.table.pagenation.moveTo') }
          </PaginationInputText>
        ) }
      </PaginationInputForm>
    </Wrapper>
  );
};
