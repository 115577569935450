import Api from 'utilities/api';
import InfiniteSelectorWithPagination from 'components/InfiniteSelectorWithPagination';
import LazyElement from 'components/LazyElement';
import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';
import SearchBox from 'components/SearchBox';
import SimpleProjectCreateForm from './SimpleProjectCreateForm';
import SmartCheckBox from 'components/SmartCheckBox';
import i18next from 'i18n';
import styled from 'styled-components';
import { fetchAsync } from 'utilities/async';

const NonNameProject = styled.span({
  color: '#bdc3c7',
});

export default class ProjectSelector extends PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      // プロジェクトが作成可能な時のみ使用される
      displayId: '',
      name: '',

      // 候補の検索関連のデータ
      // 一覧をスクロールしたときにデーxタ取得が実行されるので、検索ボックスの値と取得時に使用する値を分けてもっておく。
      searchText: '',
      searchFieldText: '',
      options: [],
      totalCount: null,
      showDeleted: false,

      displayingRecentlyUsed: true,
    };

    this.handleOptionsChange = this.handleOptionsChange.bind(this);
    this.handleSearchFieldTextChange = this.handleSearchFieldTextChange.bind(this);
    this.handleFormChange = this.handleFormChange.bind(this);
    this.handleCreate = this.handleCreate.bind(this);
    this.handleShowDeletedChange = this.handleShowDeletedChange.bind(this);
  }

  /** 一回のリクエストで取得するプロジェクト数 */
  get limit() {
    return this.maxCount;
  }

  /** 取得する総件数 */
  get maxCount() {
    return 100;
  }

  get selectableCount() {
    if (this.state.totalCount == null) {
      return null;
    }

    return this.state.totalCount || this.maxCount;
  }

  handleOptionsChange(options, totalCount = options.length) {
    this.setState({ options, totalCount, displayingRecentlyUsed: this.state.searchText === '' });
  }

  handleSearchFieldTextChange(text) {
    this.setState({ searchFieldText: text });
  }

  handleFormChange(diff) {
    this.setState(diff);
  }

  async handleCreate(onFetchingOptions, project) {
    // プロジェクトが作成された時、作成されたプロジェクトが選択された状態にする。
    this.props.onSelect(project, true, project);
    onFetchingOptions();
  }

  handleShowDeletedChange(onFetchingOptions, showDeleted) {
    this.setState({ showDeleted });
    onFetchingOptions();
  }

  search(showDeleted, offset = 0, limit = this.limit) {
    const commonParams = {
      noCount: false,
      ownerId: this.props.ownerId,
      scope: this.props.scope,
      showDeleted,
      skipAllValidation: this.props.skipAllValidation,
    };

    const conditionalParams = this.state.searchText ? {
      limit,
      offset,
      searchText: this.state.searchText,
    } : {
      // 検索条件なしの場合は使用履歴を優先する
      sortByRecentlyUsed: true,
      offset,
      limit,
    };

    return fetchAsync(Api.projects.index, { ...commonParams, ...conditionalParams });
  }

  formatOption(item) {
    const warningBadge = item?.isDeleted
      ? (
        <small className='badge badge-pill badge-secondary' style={ { fontSize: 10 } }>
          <i className='fa fa-exclamation-triangle' style={ { marginRight: 4 } }></i>
          { i18next.t('projects.deleted') }
        </small>
      ) : null;
    return (
      <div className='list-selector-item-content'>
        <LazyElement height={ 19 } margin='4px 0'>
          { item && <div>{ warningBadge } { item.name || <NonNameProject>{i18next.t('projects.none')}</NonNameProject> }</div> }
        </LazyElement>
        <LazyElement height={ 13 } margin='2px 0'>
          { item && <small>{ item.displayId }</small> }
        </LazyElement>
      </div>
    );
  }

  renderSearchReultMessage() {
    let message;
    if (this.state.options.length === 0) {
      message = i18next.t('searchResults.messages.selectableItemNotFound', { itemName: i18next.t('projects.project') });
    } else if (this.state.displayingRecentlyUsed) {
      message = this.props.showRecentlyUsedMessage ? i18next.t('searchResults.messages.displayingRecentlyUsed') : null;
    } else {
      message = null;
    }
    return (
      <p style={ { marginBottom: '2px', marginTop: '6px' } }>
        { message }
      </p>
    );
  }

  render() {
    return (
      <InfiniteSelectorWithPagination
        responseKey='projects'
        isMultiSelectable={ this.props.isMultiSelectable }
        options={ this.state.options }
        selection={ this.props.selection }
        batchSize={ this.limit }
        totalCount={ this.selectableCount }
        rowHeight={ 61 }
        formatOption={ this.formatOption }
        fetchOptions={ this.search.bind(this, this.state.showDeleted) }
        onSelect={ this.props.onSelect }
        onOptionsChange={ this.handleOptionsChange }
        itemDisabledAttributeName={ 'isDeleted' }
        maxHeight={ 428 }
        buildSelection={ this.buildSelection }
      >
        {(renderSelector, { onFetchingOptions }) => {
          return (
            <div className='project-selector'>
              {
                this.props.isCreatable && (
                  <SimpleProjectCreateForm
                    name={ this.state.name }
                    displayId={ this.state.displayId }
                    onCreated={ this.handleCreate.bind(null, onFetchingOptions) }
                    onChange={ this.handleFormChange }
                    disabled={ this.props.disabled }
                  />
                )
              }

              <SearchBox value={ this.state.searchFieldText }
                placeholder={ i18next.t('projects.actions.searchPlaceholder') }
                onChange={ this.handleSearchFieldTextChange }
                onClick={ () => { this.setState({ searchText: this.state.searchFieldText }, () => { onFetchingOptions(); }); } }
              />

              {
                this.props.showDeletedCheckBox && (
                  <SmartCheckBox
                    checked={ this.state.showDeleted }
                    label={ i18next.t('projects.actions.searchWithDeleted') }
                    onChange={ this.handleShowDeletedChange.bind(null, onFetchingOptions) }
                  />
                )
              }

              <div>
                { this.renderSearchReultMessage()}
                { renderSelector }
              </div>
            </div>
          );
        }}
      </InfiniteSelectorWithPagination>
    );
  }
}

ProjectSelector.defaultProps = {
  isCreatable: false,
  isMultiSelectable: false,
  onSelect(_project, _isSelected, _selection) {
    window.console.warn('onSelect is not implemented: ProjectSelector');
  },
  scope: null,
  showDeletedCheckBox: false,
  showRecentlyUsedMessage: true,
  skipAllValidation: false,
};

ProjectSelector.propTypes = {
  selection: PropTypes.oneOfType([
    PropTypes.arrayOf(
      PropTypes.shape({
        name: PropTypes.string,
      }),
    ),
    PropTypes.shape({
      name: PropTypes.string,
    }),
  ]),
  isCreatable: PropTypes.bool.isRequired, // 作成用のフォームを表示するか否かのフラグ

  // 承認者が申請者のプロジェクト候補を取得するのに使う
  ownerId: PropTypes.string,
  isMultiSelectable: PropTypes.bool,
  onSelect: PropTypes.func.isRequired,
  scope: PropTypes.oneOf(['all']),
  showDeletedCheckBox: PropTypes.bool,
  showRecentlyUsedMessage: PropTypes.bool,
  skipAllValidation: PropTypes.bool,
};
