import allowance from "applications/allowances/reducers/transaction";
import searchBox from "applications/fare_transactions/reducers/searchBox";
import taxCategorySelections from "applications/root_categories/reducers/taxCategorySelections";
import { combineReducers } from "redux";
import formData from "./formData";
import formState from "./formState";
import markingAsMatched from "./MarkingAsMatched";
import suggestions from "./suggestions";

export default combineReducers({
  formData,
  formState,
  suggestions,
  allowance,
  searchBox,
  taxCategorySelections,
  markingAsMatched,
});
