import i18next from "i18n";
import React from "react";
import styled from "styled-components";
import { isSafari } from "utilities/Utils";

const Wrapper = styled.div`
  width: 100%;
  height: 100%;
`;
const PDFErrorArea = styled.div`
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`;
const ChangeButton = styled.div`
  background: #294790;
  border-radius: 4px;
  padding: 8px 0;
  margin-top: 10px;
  box-shadow: 0 0 5px 0px #00000045;
  cursor: pointer;
`;
const ErrorText = styled.div`
  word-wrap: break-word;
  white-space: break-spaces;
  color: white;
  text-align: center;
  font-size: 14px;
`;
const PDFArea = styled.object`
  width: 100%;
  height: 100%;
`;

interface Props {
  /** PDFファイルURL */
  readonly url: string;
  /** 切り替えるボタン */
  readonly onClickChangeViewer: (
    e: React.MouseEvent<HTMLElement, MouseEvent>,
  ) => void;
}

/**
 * ブラウザ版PDFビューアー
 */
const BrowserPDFViewerObject: React.FC<Props> = (p) => {
  if (isSafari()) {
    return (
      <PDFArea
        key={p.url}
        data={`${p.url}#view=FitH`}
        className="viewer-browser-pdf-viewer-pdf-area"
      />
    );
  }

  return (
    <Wrapper className="viewer-browser-pdf-viewer-wrapper">
      <PDFArea
        className="viewer-browser-pdf-viewer-pdf-area"
        key={p.url}
        data={`${p.url}#view=FitH`}
        type="application/pdf"
      >
        <PDFErrorArea className="viewer-browser-pdf-viewer-pdf-error-area">
          <ErrorText className="viewer-browser-pdf-viewer-error-text">
            {i18next.t("viewer.browserPDFError.title")}
            <ChangeButton
              className="viewer-browser-pdf-viewer-change-button"
              onClick={p.onClickChangeViewer}
            >
              {i18next.t("viewer.browserPDFError.button")}
            </ChangeButton>
          </ErrorText>
        </PDFErrorArea>
      </PDFArea>
    </Wrapper>
  );
};

export default BrowserPDFViewerObject;
