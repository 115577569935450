import i18next from "i18n";
import React from "react";
import REPORT_STATUS from "../../../values/report_status";
import * as Buttons from "./buttons";
import { RequestState } from "./buttons/Types";

interface Props {
  locale: string;
  preReport: {
    status: string;
  };
  requestState: RequestState;
  onSettlementButtonClick: () => void;
  onTemporaryPaymentDownloadButtonClick: () => void;
}

/** 承認後の事前申請（精算申請なし）の申請者用ボタン */
const ButtonsForRequesterAfterApproved: React.FC<Props> = ({
  locale,
  preReport,
  requestState,
  onSettlementButtonClick,
  onTemporaryPaymentDownloadButtonClick,
}) => {
  if (preReport.status === REPORT_STATUS[locale].pendingTemporaryPayment) {
    return (
      <button
        className="btn btn-block btn-success"
        onClick={onTemporaryPaymentDownloadButtonClick}
      >
        <i className="fa fa-left fa-paper-plane" />
        {i18next.t("commons.actions.downloadCashAdvanceRequest")}
      </button>
    );
  }

  return (
    <Buttons.SettlementButton
      requestState={requestState}
      onClick={onSettlementButtonClick}
    />
  );
};

export default ButtonsForRequesterAfterApproved;
