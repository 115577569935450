import FareFormatter from "applications/fare_transactions/fare_formatter";
import renderAmountPerPersonWrapper from "applications/transactions/utilities/renderer";
import * as transactionFormPolicy from "applications/transactions/utilities/transactionFormPolicy";
import CompanionSelector from "components/companion_selector";
import VersionedReceiptFile from "components/fields/receipt_files/VersionedReceiptFile";
import GenericFieldsSelector from "components/generic_fields_selector";
import { ListForm } from "components/renewaled_ui/form_views";
import i18next from "i18n";
import _get from "lodash/get";
import isEmpty from "lodash/isEmpty";
import isNil from "lodash/isNil";
import _sortBy from "lodash/sortBy";
import PropTypes from "prop-types";
import React, { Component } from "react";
import styled from "styled-components";
import transactionType from "types/transaction";
import Formatter from "utilities/formatter";
import TRANSACTION_STATUS from "values/transaction_status";
import AmountPerTaxCategoryTable from "./AmountPerTaxCategoryTable";
import AmountWithTaxPerTaxCategoryTable from "./AmountWithTaxPerTaxCategoryTable";
import { EligibleInvoiceFormReadOnlyField } from "./EntryForms/EligibleInvoiceFormField/ReadOnlyField";

const showPaperlessMatchingButtons =
  userPreferences.isPaperlessPlan && isNil(userPreferences.agent);
const rightDrawerButtomOffset = showPaperlessMatchingButtons ? 172 : 140;
const TransactionFormView = styled.div`
  display: flex;
  flex-direction: row-reverse;
  align-items: flex-start;
  .receipt-file-area {
    width: 67%;
    padding-right: 8px;
    margin-right: 24px;
    max-height: ${() => `calc(100vh - ${rightDrawerButtomOffset}px)`};
    overflow-y: auto;
  }
  .form-area {
    width: 33%;
    padding-bottom: 60px;
    max-height: ${({ inModal }) =>
      `calc(100vh - ${inModal ? "172" : rightDrawerButtomOffset}px)`};
    overflow-y: auto;
    padding-right: 8px;
  }
`;

const OriginAndDestinationView = styled.div`
  display: flex;
  justify-content: start;
  align-items: center;
  i {
    padding: 5px 16px;
    color: #757575;
  }
`;

export default class Transaction extends Component {
  canRead(field) {
    const { formValues, status, authority, reportId, preReportId } = this.props;

    return transactionFormPolicy.canRead(field, {
      status,
      authority,
      reportId,
      preReportId,
      ...formValues,
    });
  }

  renderRouteDetail() {
    const { route } = this.props;

    return isNil(route) ? null : (
      <div>
        <div>{FareFormatter.renderLabels(route.labels)}</div>

        {isNil(route.via) ? (
          <div>
            <div>{FareFormatter.renderRoute(route)}</div>
            <div>{FareFormatter.renderViaList(route.lines)}</div>
          </div>
        ) : (
          <div>{FareFormatter.renderViaList(route.via)}</div>
        )}
      </div>
    );
  }

  render() {
    const {
      formValues,
      receiptImages,
      isElectronicReceiptImage,
      inModal,
      isTaxAmountShow,
    } = this.props;
    const receiptField = this.props.fields.find(
      (f) => f.type === "receipt_file_input",
    );

    return (
      <div>
        {transactionFormPolicy.hasFailedReceiptImage(receiptImages) ? (
          <div className="">
            <p className="bg-danger" style={{ padding: "8px" }}>
              {i18next.t("transactions.errors.failedToUploadImage")}
            </p>
          </div>
        ) : null}
        {transactionFormPolicy.hasFailedToSignReceiptImage(receiptImages) ? (
          <div className="">
            <p className="bg-danger" style={{ padding: "8px" }}>
              {i18next.t("transactions.errors.failedToSignTimeStamp")}
            </p>
          </div>
        ) : null}
        <TransactionFormView inModal={inModal}>
          <div className="form-area">
            {Array.prototype.concat
              .apply(
                [],
                _sortBy(
                  this.props.fields.filter((f) => this.canRead(f)),
                  ["sort"],
                ),
              )
              .map((field) => {
                if (isNil(field.id)) {
                  switch (field.type) {
                    case "route_input":
                      return [
                        this.renderItem("経路", this.renderRouteDetail(), {
                          key: field.sort,
                        }),
                        this.renderAmount(field, formValues[field.type]),
                      ];
                    case "date_input":
                      return this.renderItem(
                        field.label,
                        Formatter.date(formValues[field.type], {
                          key: field.sort,
                          withDayOfTheWeek: true,
                        }),
                      );
                    case "direct_product_table_input":
                      return this.renderAllowance(formValues[field.type]);
                    case "transit_payee_input":
                      return this.renderItem(
                        field.label,
                        Formatter.text(formValues[field.type]),
                        { key: field.sort },
                      );
                    case "expense_input":
                      return this.renderAmount(field, formValues[field.type]);
                    case "shop_input":
                    case "multi_line_text_input":
                      return this.renderItem(
                        field.label,
                        Formatter.text(formValues[field.type]),
                        { key: field.sort },
                      );
                    case "report_input":
                    case "pre_report_input":
                      return this.renderItem(
                        field.label,
                        Formatter.text(formValues[field.type]?.title),
                        { key: field.sort },
                      );
                    case "category_input":
                      return this.renderItem(
                        field.label,
                        Formatter.text(_get(formValues[field.type], "name")),
                        { key: field.sort },
                      );
                    case "amount_per_tax_category_input":
                      return isTaxAmountShow
                        ? this.renderAmountWithTaxPerTaxCategory(
                            field,
                            formValues[field.type],
                          )
                        : this.renderAmountPerTaxCategories(
                            field,
                            formValues[field.type],
                          );
                    case "withholding_input":
                      return this.renderItem(
                        field.label,
                        Formatter.amount(formValues[field.type]),
                        { key: field.sort },
                      );
                    case "address_input":
                    case "full_name_input":
                      return this.renderItem(
                        field.label,
                        Formatter.text(formValues[field.type]),
                        { key: field.sort },
                      );
                    case "super_category_input":
                    case "department_input":
                      return this.renderItem(
                        field.label,
                        Formatter.text(_get(formValues[field.type], "name")),
                        { key: field.sort },
                      );
                    case "companion_input":
                      return this.renderItem(
                        field.label,
                        <CompanionSelector
                          value={formValues[field.type] || []}
                          static
                        />,
                        { key: field.sort },
                      );
                    case "project_input":
                      return this.renderItem(
                        field.label,
                        Formatter.project(formValues[field.type], "", null, [
                          "name",
                          "displayId",
                        ]),
                        { key: field.sort },
                      );
                    case "generic_fields_input":
                      return this.renderGenericFields(field, formValues);
                    case "cost_allocation_input":
                    case "group_input":
                      return this.renderGroupNames(
                        field,
                        field.label,
                        formValues[field.type],
                      );
                    case "origin_and_destination_by_category_input":
                      return this.renderOriginAndDestinationByCategory(
                        field,
                        field.label,
                        formValues[field.type],
                      );
                    case "visit_by_category_input":
                      return this.renderItem(
                        field.label,
                        Formatter.text(formValues[field.type]),
                        { key: field.sort },
                      );
                    case "purpose_by_category_input":
                      return this.renderItem(
                        field.label,
                        Formatter.text(formValues[field.type]),
                        { key: field.sort },
                      );
                    case "refund_check_input":
                      return this.renderItem(
                        field.label,
                        formValues[field.type] ? "はい" : "いいえ",
                        { key: field.sort },
                      );
                    case "electronic_receipt_image_flag":
                      return this.renderItem(
                        field.label,
                        formValues[field.type] ? "はい" : "いいえ",
                        { key: field.sort },
                      );
                    case "eligible_invoice_confirmation_input":
                      if (!formValues[field.type]) {
                        return <></>;
                      }

                      return (
                        <EligibleInvoiceFormReadOnlyField
                          value={formValues[field.type]}
                          isAllowance={!isNil(this.props.allowanceTable)}
                          category={formValues.category_input}
                        />
                      );
                    default:
                      return null;
                  }
                }

                return this.renderMetadata(field);
              })}

            {this.renderReportSequenceNum()}
            {this.renderPreReportSequenceNum()}
            {this.renderStatus()}
          </div>
          {receiptField && this.canRead(receiptField) && (
            <div className="receipt-file-area">
              <VersionedReceiptFile
                isVisibleResolutionInformation={
                  userPreferences.preference.visibleResolutionInformation
                }
                isElectronicReceiptImage={isElectronicReceiptImage}
                isTimestampEnabled={userPreferences.preference.addTimeStamp}
                versions={receiptImages}
                height={500}
                style={{ width: "100%" }}
                onRotate={this.props.onRotateImage}
                resolutionInformationDisplayStyle={
                  this.props.resolutionInformationDisplayStyle
                }
              />
            </div>
          )}
        </TransactionFormView>
      </div>
    );
  }

  renderCompanions(companions) {
    return this.renderItem(
      i18next.t("transactions.properties.companion"),
      <CompanionSelector value={companions} static />,
    );
  }

  renderReportSequenceNum() {
    const reportSequenceNum = this.props.reportSequenceNum;

    if (!reportSequenceNum) {
      return null;
    }

    const isAdmin = userPreferences.isAdmin;
    const isOwner = userPreferences.id === this.props.ownerId;
    const reportId = this.props.reportId;
    const preReportId = this.props.preReportId;
    const label = i18next.t("transactions.properties.reportSequenceNum");

    if (isOwner) {
      const reportUrl = preReportId
        ? `/pre_reports/${preReportId}`
        : `/reports/${reportId}`;
      return this.renderItemWithLink(label, reportSequenceNum, reportUrl);
    }

    if (isAdmin) {
      const reportUrl = preReportId
        ? `/approving_pre_reports/${preReportId}`
        : `/approving_reports/${reportId}`;
      return this.renderItemWithLink(label, reportSequenceNum, reportUrl);
    }

    return this.renderItem(label, reportSequenceNum);
  }

  renderPreReportSequenceNum() {
    if (this.props.preReportSequenceNum != null) {
      return this.renderItem(
        i18next.t("transactions.properties.preReportSequenceNum"),
        this.props.preReportSequenceNum,
      );
    }
    return null;
  }

  renderStatus() {
    const status = TRANSACTION_STATUS[this.props.status];
    if (!status) {
      return null;
    }
    return this.renderItem(i18next.t("transactions.properties.status"), status);
  }

  renderItem(label, value, options = {}) {
    return (
      <div {...options}>
        <ListForm label={label} isViewMode>
          {value}
        </ListForm>
      </div>
    );
  }

  renderItemWithLink(label, value, link) {
    return (
      <div className="">
        <ListForm label={label} isViewMode>
          <a href={link} target="_blank">
            {value}
          </a>
        </ListForm>
      </div>
    );
  }

  currencyInfo(currencyId, originalAmount, exchangeRate) {
    return `${currencyId}: ${originalAmount} レート: ${exchangeRate}`;
  }

  renderAmount(field, expenseValue) {
    const { defaultCurrencyId, route, companions, fields } = this.props;
    const { originalAmount, exchangeRate, amount } = expenseValue;

    if (route) {
      return this.renderItem(
        i18next.t("transactions.properties.amount"),
        FareFormatter.renderFare(amount, route.withTeiki),
        { key: field.sort },
      );
    }

    const currencyId = this.props.currencyId || defaultCurrencyId;
    const enableForeignInput = currencyId !== defaultCurrencyId;

    if (amount !== 0 && !amount) {
      return this.renderItem(
        i18next.t("transactions.properties.amount"),
        Formatter.amount(amount),
        { key: field.sort },
      );
    }

    const currencyInfo =
      !isNil(amount) && enableForeignInput
        ? ` (${this.currencyInfo(currencyId, originalAmount, exchangeRate)})`
        : "";
    return this.renderItem(
      i18next.t("transactions.properties.amount"),
      renderAmountPerPersonWrapper(
        companions,
        amount,
        fields,
        Formatter.amount(amount) + currencyInfo,
        true,
        "transaction",
      ),
      { key: field.sort },
    );
  }

  // TODO: Component（AllowanceInput）で、静的表示できるようにする
  renderSelectedFactor(factorTypes, factorIds) {
    return (
      <div>
        {factorTypes.map((factorType) => {
          const selectedFactorId = factorIds[factorType.id];
          const selectedFactor = factorType.options.find(
            (x) => x.id === selectedFactorId,
          );

          return this.renderItem(
            factorType.name,
            Formatter.text(selectedFactor?.name),
            { key: factorType.id },
          );
        })}
      </div>
    );
  }

  renderVariable(variable, inputs) {
    const input = inputs[variable.id];

    switch (variable.inputType) {
      case "number": {
        return this.renderItem(variable.name, Formatter.number(input));
      }
      case "table": {
        return this.renderItem(variable.name, Formatter.amount(input.value));
      }
      case "select": {
        const option = variable.options.find((x) => x.id === input.id);
        return this.renderItem(variable.name, Formatter.text(option.name));
      }
      case "period": {
        return this.renderItem(
          variable.name,
          `${Formatter.date(input.from, {
            withDayOfTheWeek: true,
          })} ~ ${Formatter.date(input.to, { withDayOfTheWeek: true })}`,
        );
      }
      default: {
        // 実行されない
        return null;
      }
    }
  }

  renderVariables(variables, inputs) {
    return (
      <div>
        {variables.map((variable) => {
          return this.renderVariable(variable, inputs, { key: variable.id });
        })}
      </div>
    );
  }

  renderAllowance(field) {
    const { allowanceTable } = this.props;

    if (isNil(allowanceTable)) {
      return null;
    }

    const {
      calculationFormula: { variables },
      input,
      factorTypes,
    } = allowanceTable;

    return (
      <div key={field.sort}>
        {isEmpty(factorTypes)
          ? null
          : this.renderSelectedFactor(factorTypes, input.factorIds)}
        {isEmpty(variables)
          ? null
          : this.renderVariables(variables, input.variableInputs)}
        {this.renderItem(
          i18next.t("transactions.properties.amount"),
          Formatter.amount(field.amount),
        )}
      </div>
    );
  }

  renderGenericFields(field, formValues) {
    // 1つのEntryFormsの中には複数のgeneric_fields_input(汎用マスタの入力フィールド)が存在するが、それらはdataSetIdで判別している。
    // よって、該当するdataSetIdのフィールドのみを描画する事で重複を回避
    const targetDataSetId = field.dataSetId;
    const targetDataSet = formValues[field.type].find(
      (dataSet) => dataSet.dataSetId === targetDataSetId,
    );
    if (!targetDataSet) {
      return <></>;
    }

    return (
      <div key={field.sort}>
        <ListForm label={field.label} isViewMode>
          <GenericFieldsSelector dataSet={targetDataSet} disabled={true} />
        </ListForm>
      </div>
    );
  }

  renderGroupNames(field, label, costAllocations) {
    return (
      <div key={field.sort}>
        <ListForm label={label} isViewMode>
          {costAllocations.length > 1 ? (
            <div>
              {
                <div
                  style={{
                    marginRight: "0px",
                    marginLeft: "0px",
                    height: "34px",
                    fontWeight: "bold",
                  }}
                >
                  <div className="col-sm-6">部署</div>
                  <div className="col-sm-6">負担率</div>
                </div>
              }
              {costAllocations.map((x, i) => (
                <div
                  key={i}
                  style={{
                    marginRight: "0px",
                    marginLeft: "0px",
                    height: "34px",
                  }}
                >
                  <div className="col-sm-6">{Formatter.text(x.payerName)}</div>
                  <div className="col-sm-6">{Formatter.text(x.numerator)}%</div>
                </div>
              ))}
            </div>
          ) : (
            costAllocations.map((x, i) => (
              <div
                key={i}
                style={{
                  marginRight: "0px",
                  marginLeft: "0px",
                  height: "34px",
                }}
              >
                {Formatter.text(x.payerName)}
              </div>
            ))
          )}
        </ListForm>
      </div>
    );
  }

  renderOriginAndDestinationByCategory(
    field,
    label,
    originAndDestinationByCategory,
  ) {
    const originByCategory = Formatter.text(
      originAndDestinationByCategory.originByCategory,
    );
    const destinationByCategory = Formatter.text(
      originAndDestinationByCategory.destinationByCategory,
    );

    return (
      <div key={field.sort}>
        <ListForm label={label} isViewMode>
          <OriginAndDestinationView>
            <div>{originByCategory}</div>
            <i className="fas fa-long-arrow-alt-right" />
            <div>{destinationByCategory}</div>
          </OriginAndDestinationView>
        </ListForm>
      </div>
    );
  }

  renderMetadata(field) {
    const { formValues } = this.props;

    switch (field.type) {
      case "text_input":
      case "time_input":
      case "multi_line_text_input":
      case "number_input":
      case "select_input":
        return this.renderItem(
          field.label,
          Formatter.text(formValues[field.id]),
          { key: field.sort },
        );
      case "check_input":
        return this.renderItem(
          field.label,
          formValues[field.id] ? "はい" : "いいえ",
          { key: field.sort },
        );
      case "date_input":
        return this.renderItem(
          field.label,
          Formatter.date(formValues[field.id], {
            key: field.sort,
            withDayOfTheWeek: true,
          }),
        );
      default: {
        const customInput =
          _get(formValues[field.id], "displayName") || formValues[field.id];
        return this.renderItem(field.label, Formatter.text(customInput), {
          key: field.sort,
        });
      }
    }
  }

  renderAmountPerTaxCategories(field, values) {
    return (
      <div key={field.sort}>
        <ListForm label={field.label} isViewMode>
          <AmountPerTaxCategoryTable
            values={values}
            showName={true}
            editable={this.props.editable}
          />
        </ListForm>
      </div>
    );
  }

  renderAmountWithTaxPerTaxCategory(field, values) {
    return (
      <div key={field.sort}>
        <AmountWithTaxPerTaxCategoryTable
          values={values}
          showName={true}
          editable={this.props.editable}
        />
      </div>
    );
  }
}

Transaction.defaultProps = {
  inModal: false,
  editable: false,
  companions: [],
  fields: [],
  resolutionInformationDisplayStyle: "single-view",
};

Transaction.propTypes = {
  ...transactionType,
  authority: PropTypes.string.isRequired,
  currencyId: PropTypes.string,
  defaultCurrencyId: PropTypes.string,
  editable: PropTypes.bool,
  exchangeRate: PropTypes.number,
  fields: PropTypes.array.isRequired,
  formValues: PropTypes.object.isRequired,
  isElectronicReceiptImage: PropTypes.bool,
  inModal: PropTypes.bool.isRequired,
  onRotateImage: PropTypes.func,
  ownerId: PropTypes.string,
  preReportId: PropTypes.string,
  preReportSequenceNum: PropTypes.string,
  preReportTitle: PropTypes.string,
  reportId: PropTypes.string,
  reportSequenceNum: PropTypes.string,
  reportTitle: PropTypes.string,
};
