import React from 'react';
import UPLOAD_STATUS from 'values/upload_status';
import { FileValue } from '../Types';

interface Props {
  editable: boolean;
  onFileDelete: (e: React.MouseEvent) => void;
  value?: FileValue;
}

const propsAreEqual = (prevProps: Readonly<Props>, nextProps: Readonly<Props>): boolean => {
  return prevProps.editable === nextProps.editable
  && JSON.stringify(prevProps.value) === JSON.stringify(nextProps.value);
};

/**
 * 汎用申請のファイルドロップ
 */
export const DropZone: React.FC<Props> = React.memo(({ editable, onFileDelete, value }) => {
  if (!value) {
    return (
      <span className={ "txt-disabled" }>
        { editable ? "クリックしてファイルを選択" : "空欄" }
      </span>
    );
  }

  switch (value.status) {
    case UPLOAD_STATUS.uploaded:
      return (
        <span>
          <span className='file-input'>
            <span className='file-name-display'>
              <a href={ `/attachments/${value.attachmentId}` } onClick={ (e): void => e.stopPropagation() }>
                { value.fileName }
              </a>
            </span>
            {
              editable && (
                <>
                  <span className="fas fa-times fa-fw pull-right txt-danger fa-middle"
                    onClick={ onFileDelete }/>
                  <span className="fas fa-pencil-alt fa-fw pull-right txt-disabled fa-middle"/>
                </>
              )
            }
          </span>
        </span>
      );
    case UPLOAD_STATUS.uploading:
      return (
        <span className={ "txt-info" }>{ `${value.status} ${value.fileName}` }</span>
      );
    case UPLOAD_STATUS.failed:
      return (
        <span className={ "txt-danger" }>
          <span>
            { value.error }
            <span className="fas fa-pencil-alt fa-fw pull-right txt-disabled fa-middle"/>
          </span>
        </span>
      );
    default:
      return (
        <span></span>
      );
  }
}, propsAreEqual);
