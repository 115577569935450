import React, { useEffect, useState } from "react";
import { ControlLabel, FormGroup } from "react-bootstrap";
import { Controller, useFormContext } from "react-hook-form";
import Select from "react-select";
import Api from "utilities/api";
import { Department } from "utilities/api/responses/departments/IndexResponse";
import { snakecaseKeys } from "utilities/Utils";

interface Props {
  name: string;
  label?: string | JSX.Element;
  controlCustomStyles?: Record<symbol, string>;
}

export const DepartmentSelectField: React.FC<Props> = ({
  name,
  label,
  controlCustomStyles,
}) => {
  const { control } = useFormContext();
  const [processing, setProcessing] = useState<boolean>(false);
  const [departments, setDepartments] = useState<Department[]>([]);

  useEffect(() => {
    const fetchDepartments = async (): Promise<void> => {
      setProcessing(true);

      try {
        const res = await Api.departments.index(
          snakecaseKeys({ excludeRoot: true }),
        );
        setDepartments(res.departments);
      } catch (e) {
        // nothing to do
      } finally {
        setProcessing(false);
      }
    };
    fetchDepartments();
  }, [setProcessing, setDepartments]);

  const formatOptionLabel = ({ name: depName, absolutePath }): JSX.Element => (
    <div>
      <div style={{ color: "#666", fontSize: "12px" }}>{absolutePath}</div>
      <div>{depName}</div>
    </div>
  );

  if (processing) {
    return <div>部署を読込中...</div>;
  }

  return departments.length > 0 ? (
    <Controller
      name={name}
      control={control}
      render={({
        field: { onChange, value, ref: inputRef },
      }): React.ReactElement => (
        <FormGroup>
          {label && <ControlLabel>{label}</ControlLabel>}
          <Select
            inputRef={inputRef}
            options={departments}
            value={departments.find((v) => v.id === (value as Department)?.id)}
            formatOptionLabel={formatOptionLabel}
            getOptionValue={(v: Department): string => v.id}
            filterOption={({ data }, inputValue: string): boolean =>
              data.name.includes(inputValue)
            }
            onChange={onChange}
            isClearable
            styles={{
              control: (baseStyles): void => ({
                ...baseStyles,
                ...(controlCustomStyles || {}),
              }),
            }}
          />
        </FormGroup>
      )}
    />
  ) : (
    <></>
  );
};
