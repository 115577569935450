import React, { useCallback, useEffect, useState } from 'react';
import SmartCheckBox from 'components/SmartCheckBox';
import { FormGroup } from 'react-bootstrap';
import { PeriodPointField } from './PeriodPointField';
import { useFormContext } from 'react-hook-form';

interface Props {
  label: string;
  basePropertyName: string;
}

export const PeriodField: React.FC<Props> = ({
  label,
  basePropertyName,
}) => {
  const {
    setValue,
    watch,
  } = useFormContext();

  const [checked, setChecked] = useState(false);

  const basePropertyNameValue = watch(basePropertyName);

  const handleUsingClick = useCallback((newChecked) => {
    if (newChecked) {
      setValue(`${basePropertyName}.from.unit`, 'month');
      setValue(`${basePropertyName}.from.value`, 1);
      setValue(`${basePropertyName}.from.direction`, 'before');
      setValue(`${basePropertyName}.from.dayOfMonth`, 1);
      setValue(`${basePropertyName}.from.lastDayOfMonth`, false);
      setValue(`${basePropertyName}.from.weekday`, null);
      setValue(`${basePropertyName}.to.unit`, 'month');
      setValue(`${basePropertyName}.to.value`, 1);
      setValue(`${basePropertyName}.to.direction`, 'before');
      setValue(`${basePropertyName}.to.dayOfMonth`, null);
      setValue(`${basePropertyName}.to.lastDayOfMonth`, true);
      setValue(`${basePropertyName}.to.weekday`, null);
    } else {
      setValue(basePropertyName, null);
    }
    setChecked(newChecked);
  }, [setChecked, basePropertyName, setValue]);

  useEffect(() => {
    if (!basePropertyNameValue) return;

    setChecked(true);
  }, [basePropertyNameValue, setChecked]);

  useEffect(() => {
    return (): void => {
      setValue(basePropertyName, null);
      setChecked(false);
    };
  }, [setValue, basePropertyName, setChecked]);

  return (
    <FormGroup>
      <SmartCheckBox onChange={ handleUsingClick } checked={ checked } label={ label } />
      {
        checked && <>
          <PeriodPointField basePropertyName={ `${basePropertyName}.from` }/> から
          <PeriodPointField basePropertyName={ `${basePropertyName}.to` }/> まで
        </>
      }
    </FormGroup>
  );
};
