import React from "react";
import styled from "styled-components";

type Position = "left" | "right";

interface ContainerProps {
  isDisabled: boolean;
  position: Position;
}

interface Props {
  position: Position;
  onClick: (e: React.SyntheticEvent) => void;
}

const bgColor = "rgba(228, 228, 228, 0.3)";
const bgColorLight = "rgba(240, 240, 240, 0.6)";

const Container = styled.div<ContainerProps>`
  position: absolute;
  top: 0;
  bottom: 0;
  ${(props): string => `${props.position}: -96px`};

  width: 80px;

  display: flex;
  justify-content: center;
  align-items: center;

  background-color: ${bgColor};

  > i {
    font-size: 32px;
    color: #555;
  }

  &:hover {
    background-color: ${(props): string =>
      props.isDisabled ? bgColor : bgColorLight};

    i {
      font-size: 40px;
      color: #333;
    }
  }
`;

const GoToAdjacentExpenseButton: React.FunctionComponent<Props> = (props) => {
  const isDisabled = !props.onClick;

  return (
    <Container
      position={props.position}
      role="button"
      isDisabled={isDisabled}
      onClick={props.onClick}
    >
      {!isDisabled && <i className={`fas fa-angle-${props.position}`} />}
    </Container>
  );
};

export default GoToAdjacentExpenseButton;
