import React from 'react';
import colors from 'values/colors';
import fonts from 'values/fonts';
import styled from 'styled-components';

interface Props {
  label: string;
  required?: boolean;
  isViewMode?: boolean
}

const ListFormView = styled.div<{ isViewMode: boolean }>`
  margin-bottom: 20px;
  .label-text {
    display: flex;
    font-weight: ${fonts.weight.bold};
    background: ${({ isViewMode }): string => (isViewMode ? colors.renewaled.whiteHover : 'none')};
    padding: ${({ isViewMode }): string => (isViewMode ? '4px 8px 2px' : '0')};
    border-radius: ${({ isViewMode }): string => (isViewMode ? '4px' : '0')};
    margin-bottom: ${({ isViewMode }): string => (isViewMode ? '4px' : '0')};
    .required-icon {
      color: red;
      font-weight: 400;
      margin-left: 2px;
    }
  }
  .list-form-body {
    padding-left: ${({ isViewMode }): string => (isViewMode ? '8px' : '0')};
  }
`;

export const ListForm: React.FC<Props> = ({
  label, required, children, isViewMode,
}) => {
  return (
    <ListFormView isViewMode={ isViewMode || false } className='list-form'>
      <label className="label-text">
        <div className="">
          { label.split('\n').map((l, i) => <div key={ i }>{ l }</div>) }
        </div>
        { required && <span className="required-icon">*</span> }
      </label>
      <div className="list-form-body">
        { children }
      </div>
    </ListFormView>
  );
};
