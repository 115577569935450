import * as ActionTypes from '../actions/index';
import * as FetchActionTypes from '../actions/fetch';
import _get from 'lodash/get';

const initialState = {
  accounts: [],
  amount: '',
  canExport: true,
  cannotBePaid: false,
  count: '',
  createdAt: '',
  exported: false, // 事前申請（仮払）のみ使用
  exportedUser: null,  // 経費集計のみ使用
  id: void 0,
  isExportConfirmationModalOpen: false,
  isPaymentConfirmationModalOpen: false,
  name: '',
  paidBatchRequested: false,
  paymentComment: '',
  paymentCompleted: false,
  paymentDate: '',
  preReports: [],
  reports: [],
  reportsCount: void 0,
  reportsCurrentPage: 1,
  reportsSizePerPage: 50,
  status: '',
  statusColor: '',
  summariesByCategory: [],
  summariesByUser: [],
  temporaryPaymentAmount: '',
};

const analysis = (state = initialState, action) => {
  switch (action.type) {
    case FetchActionTypes.SET_ANALYSIS: {
      return {
        ...state,
        accounts: _get(action.data, 'accounts', state.accounts),
        allAggregationExported: _get(action.data, 'allAggregationExported', state.allAggregationExported),
        amount: _get(action.data, 'amount', state.amount),
        count: _get(action.data, 'count', state.count),
        createdAt: _get(action.data, 'createdAt', state.createdAt),
        exported: _get(action.data, 'exported', state.exported),
        exportedUser: _get(action.data, 'exportedUser', state.exportedUser),
        id: _get(action.data, 'id', state.id),
        name: _get(action.data, 'name', state.name),
        paymentCompleted: _get(action.data, 'paymentCompleted', state.paymentCompleted),
        status: _get(action.data, 'status', state.status),
        statusColor: _get(action.data, 'statusColor', state.statusColor),
        temporaryPaymentAmount: _get(action.data, 'temporaryPaymentAmount', state.temporaryPaymentAmount),
        temporaryPaymentCompleted: _get(action.data, 'temporaryPaymentCompleted', state.temporaryPaymentCompleted),
      };
    }
    case FetchActionTypes.SET_CATEGORY_ANALYSIS: {
      return {
        ...state,
        summariesByCategory: action.data,
      };
    }
    case FetchActionTypes.SET_USER_ANALYSIS: {
      return {
        ...state,
        summariesByUser: action.data,
      };
    }
    case FetchActionTypes.SET_ANALYSIS_REPORTS: {
      return {
        ...state,
        reports: action.data,
      };
    }
    case FetchActionTypes.SET_ANALYSIS_REPORTS_CURRENT_PAGE: {
      return {
        ...state,
        reportsCurrentPage: action.value,
      };
    }
    case FetchActionTypes.SET_ANALYSIS_REPORTS_SIZE_PER_PAGE: {
      return {
        ...state,
        reportsSizePerPage: action.value,
      };
    }
    case FetchActionTypes.SET_PRE_REPORTS_SUMMARIES: {
      return {
        ...state,
        preReports: action.data,
      };
    }
    case ActionTypes.TOGGLE_CONFIRM_PAYMENT_MODAL: {
      return {
        ...state,
        isPaymentConfirmationModalOpen: action.show,
      };
    }
    case ActionTypes.SET_PAYMENT_DATE: {
      return {
        ...state,
        paymentDate: action.payload.date,
      };
    }
    case ActionTypes.SET_PAYMENT_COMMENT: {
      return {
        ...state,
        paymentComment: action.payload.comment,
      };
    }
    case ActionTypes.REQUEST_PAID_BATCH: {
      return {
        ...state,
        paidBatchRequested: action.requested,
      };
    }
    case ActionTypes.TOGGLE_CONFIRM_EXPORT_MODAL: {
      return {
        ...state,
        isExportConfirmationModalOpen: action.show,
      };
    }
    default:
      return state;
  }
};

export const initialAnalysis = initialState;
export default analysis;
