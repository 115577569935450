import PropTypes from 'prop-types';
import React, { Component } from 'react';
import i18next from 'i18n';
import isNil from 'lodash/isNil';
import { SelectAllCheckBox } from 'components/SelectAllCheckBox';

const SearchResultSummary = (props) => {
  const {
    count = 0,
    maxCount,
    resultMessage,
    selectedCount = undefined,
    listMember = [],
    handleAllOptionSelect,
    isAllSelectable = false,
    styledCheckBoxAll,
    labelCheckBoxAll,
    searchResultSummaryStyle = {}, // TODO: SearchResultの使われ方が統一されたら,引数としての受け渡しは不要になるはずなので削除すること。
  } = props;
  const isCountLimited = isNil(maxCount) ? false : count > maxCount;
  const isSelectedCount = !isNil(selectedCount);

  return (
    <div className='search-result__summary' style={ searchResultSummaryStyle }>
      {
        count > 0 ? (
          <>
            <p className='txt txt-default'>
              { `${resultMessage || i18next.t('searchResults.messages.results')}：` }
              <span className='search-result__summary--bold'>{ count }</span>
              { i18next.t('commons.units.result', { count }) }

              {
               isSelectedCount && <span style={ { paddingLeft: '5px' } }>
                 { `${resultMessage || i18next.t('searchResults.messages.selection')}：` }
                 <span className='search-result__summary--bold'>{ selectedCount }</span>
                 { i18next.t('commons.units.result', { selectedCount }) }
               </span>
              }
            </p>

            { isCountLimited && (<p>{ `(${i18next.t('searchResults.messages.truncated', { count: maxCount })})` }</p>) }
          </>
        ) : (
          <p className='txt txt-default'>
            { i18next.t('searchResults.messages.notFound') }
          </p>
        )
      }
      {
        isAllSelectable && <SelectAllCheckBox
          options={ listMember }
          onSelectAll={ handleAllOptionSelect }
          styledCheckBoxAll={ styledCheckBoxAll }
          labelCheckBoxAll={ labelCheckBoxAll }
      />
      }
    </div>
  );
};

export default class SearchResult extends Component {
  get className() {
    const baseClass = 'search-result';

    if (isNil(this.props.className)) {
      return baseClass;
    }

    return `${baseClass} ${this.props.className}`;
  }

  render() {
    const {
      count, maxCount, resultMessage, style, selectedCount, isAllSelectable, listResult, handleAllOptionSelect, styledCheckBoxAll, labelCheckBoxAll, searchResultSummaryStyle,
    } = this.props;
    // TODO: スタイルをSCSSに書く

    return (
      <div className={ this.className } style={ style }>
        { !isNil(count) && (<SearchResultSummary
          count={ count }
          maxCount={ maxCount }
          resultMessage={ resultMessage }
          selectedCount={ selectedCount }
          isAllSelectable={ isAllSelectable }
          listMember={ listResult }
          handleAllOptionSelect={ handleAllOptionSelect }
          styledCheckBoxAll={ styledCheckBoxAll }
          labelCheckBoxAll={ labelCheckBoxAll }
          searchResultSummaryStyle={ searchResultSummaryStyle }
        />) }

        { this.props.children }
      </div>
    );
  }
}

SearchResult.propTypes = {
  className: PropTypes.string,
  count: PropTypes.number,
  maxCount: PropTypes.number,
  style: PropTypes.object,
  children: PropTypes.oneOfType([
    PropTypes.element, PropTypes.arrayOf(PropTypes.element),
  ]),
  resultMessage: PropTypes.string,
  searchResultSummaryStyle: PropTypes.object,
};
