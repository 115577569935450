import SimpleModal from "components/SimpleModal";
import i18next from "i18n";
import React from "react";
import * as Buttons from "./buttons";

export interface Props {
  /** 分割実行ハンドラ */
  onConfirm: () => void;
  /** モーダルを閉じるハンドラ */
  close: () => void;
  /** モーダル表示するか */
  show: boolean;
  /** ボタン非活性化するか */
  disabled: boolean;
  /** 分割処理中か */
  processing: boolean;
}

/** 分割実行の確認モーダル */
export const SplitConfirmModal: React.FC<Props> = ({
  show,
  processing,
  disabled,
  close,
  onConfirm,
}) => {
  return (
    <SimpleModal
      title={i18next.t("expenses.split.confirmModalTitle")}
      show={show}
      close={close}
    >
      <div>
        <Buttons.SplitConfirmButton
          onClick={onConfirm}
          disabled={disabled || processing || !show}
          processing={processing}
        />{" "}
        <Buttons.CancelButton onClick={close} />
      </div>
    </SimpleModal>
  );
};
