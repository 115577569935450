import { ListForm } from "components/renewaled_ui/form_views";
import i18next from "i18n";
import PropTypes from "prop-types";
import React, { Component } from "react";
import FareDetailOptions from "./FareDetailOptions";

export default class FareOptions extends Component {
  constructor(props) {
    super(props);

    this.handleCheckBox = this.handleCheckBox.bind(this);
    this.handleRadio = this.handleRadio.bind(this);
  }

  handleCheckBox(e, func, key) {
    e.stopPropagation();
    if (func === "option") {
      this.props.onSearchOptionChange(key);
    } else {
      this.props.onSearchConditionChange(key, e.target.checked);
    }
  }

  handleRadio(e, value) {
    e.stopPropagation();
    this.props.onSearchConditionChange("surchargeKind", value);
  }

  renderOption() {
    const { searchCondition } = this.props;
    const { roundTrip } = this.props.checked;

    return (
      <ListForm label={i18next.t("transactions.inputs.others")}>
        <div className="fare-options-checkbox-area">
          <label className="checkbox-inline" key="round-trip-option">
            <input
              type="checkbox"
              id="roundTrip"
              checked={roundTrip}
              onChange={(e) => this.handleCheckBox(e, "option", "roundTrip")}
            />
            {i18next.t("transactions.inputs.round")}
          </label>
          <label className="checkbox-inline" key="ic-option">
            <input
              type="checkbox"
              id="icTicket"
              checked={searchCondition.withIc}
              onChange={(e) => this.handleCheckBox(e, "condition", "withIc")}
            />
            {i18next.t("transactions.inputs.icCard")}
          </label>
        </div>
        <div className="">
          <FareDetailOptions
            searchCondition={searchCondition}
            onSearchConditionChange={this.props.onSearchConditionChange}
          />
        </div>
      </ListForm>
    );
  }

  renderTransport() {
    const { checked } = this.props;

    return (
      <ListForm label={i18next.t("transactions.inputs.means")}>
        <div className="fare-options-checkbox-area">
          {["train", "bus", "plane"].map((type, index) => (
            <label className="checkbox-inline" key={`${type}-option`}>
              <input
                type="checkbox"
                id={type}
                checked={checked[type]}
                onChange={(e) => this.handleCheckBox(e, "option", type)}
              />
              {i18next.t(`transactions.inputs.${type}`)}
            </label>
          ))}
        </div>
      </ListForm>
    );
  }

  render() {
    return (
      <div className="search-option-box">
        {this.renderTransport()}
        {this.renderOption()}
      </div>
    );
  }
}

FareOptions.defaultProps = {
  searchCondition: {
    connectionBus: true,
    highwayBus: true,
    limitedExpress: true,
    liner: true,
    localBus: true,
    midnightBus: true,
    plane: true,
    shinkansen: true,
    ship: true,
    surcharge: "free",
    withIc: true,
  },
};

FareOptions.propTypes = {
  checked: PropTypes.shape({
    bus: PropTypes.bool.isRequired,
    plane: PropTypes.bool.isRequired,
    roundTrip: PropTypes.bool.isRequired,
    train: PropTypes.bool.isRequired,
  }),
  onSearchConditionChange: PropTypes.func.isRequired,
  onSearchOptionChange: PropTypes.func.isRequired,
  searchCondition: PropTypes.shape({
    connectionBus: PropTypes.bool.isRequired,
    highwayBus: PropTypes.bool.isRequired,
    limitedExpress: PropTypes.bool.isRequired,
    liner: PropTypes.bool.isRequired,
    localBus: PropTypes.bool.isRequired,
    midnightBus: PropTypes.bool.isRequired,
    plane: PropTypes.bool.isRequired,
    shinkansen: PropTypes.bool.isRequired,
    ship: PropTypes.bool.isRequired,
    surcharge: PropTypes.string.isRequired,
    withIc: PropTypes.bool.isRequired,
  }),
};
