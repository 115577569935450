import { RemoteReceiptFile } from "components/types/attachedFiles.types";
import i18next from "i18n";
import React, { useMemo } from "react";
import styled from "styled-components";

const Link = styled.a`
  &:hover {
    cursor: pointer;
  }
`;

const RESULT = {
  OK: "OK",
  NG: "NG",
  PROCESSING: "PROCESSING",
} as const;

const OK = RESULT.OK;
const NG = RESULT.NG;
const PROCESSING = RESULT.PROCESSING;

interface Props {
  onClick: () => void;
  foresideLatestFile: RemoteReceiptFile | null;
  backsideLatestFile: RemoteReceiptFile | null;
}

const ResolutionInformationTopSummary: React.FC<Props> = (props) => {
  const { onClick, foresideLatestFile, backsideLatestFile } = props;

  const checkLatestFilesSatisfied = useMemo(() => {
    // 表面/裏面の最新画像のうちいずれかにつき、まだ解像度検証が完了していない時
    if (
      (foresideLatestFile &&
        foresideLatestFile.resolutionInformations.length === 0) ||
      (backsideLatestFile &&
        backsideLatestFile.resolutionInformations.length === 0)
    )
      return PROCESSING;

    // 表面と裏面のいずれか片方のみに画像がある場合は、その面のsatisfiedのみで判断。
    if (!foresideLatestFile)
      return backsideLatestFile?.resolutionInformations?.every(
        (info) => info.satisfied,
      )
        ? OK
        : NG;
    if (!backsideLatestFile)
      return foresideLatestFile?.resolutionInformations?.every(
        (info) => info.satisfied,
      )
        ? OK
        : NG;

    // 表面と裏面の両方に画像が存在し、かつ両方の解像度検証が完了している場合
    return foresideLatestFile?.resolutionInformations?.every(
      (info) => info.satisfied,
    ) &&
      backsideLatestFile?.resolutionInformations?.every(
        (info) => info.satisfied,
      )
      ? OK
      : NG;
  }, [foresideLatestFile, backsideLatestFile]);

  return (
    <div className="resolution-information-top-summary">
      <Link onClick={onClick}>
        {i18next.t("transactions.properties.resolutionInformation.dpiLabel")}：
        {checkLatestFilesSatisfied === "OK" && (
          <span>
            OK
            <i className="fas fa-check text-success" />
          </span>
        )}
        {checkLatestFilesSatisfied === "NG" && (
          <span>
            NG
            <i className="fas fa-times text-danger" />
          </span>
        )}
        {checkLatestFilesSatisfied === "PROCESSING" && <></>}
      </Link>
    </div>
  );
};

export default ResolutionInformationTopSummary;
