import FormItem from './FormItem';
import PropTypes from 'prop-types';
import React from 'react';
import formatter from 'utilities/formatter';

const TransactedDate = (props) => {
  return (
    <FormItem label='transactedAt'>
      { formatter.date(props.value, { emptyText: '-' }) }
    </FormItem>
  );
};

TransactedDate.propTypes = {
  value: PropTypes.string,
};

export default React.memo(TransactedDate);
