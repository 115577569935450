import { AxiosError } from "axios";
import {
  FileFetch,
  FileLoadStatus,
  ReceiptFile,
} from "components/types/attachedFiles.types";
import { useCallback, useEffect, useMemo, useState } from "react";
import { defer, Observable } from "rxjs";
import Api from "utilities/api";
import { MIMEType } from "utilities/api/types/MIMEType";
import ApiPaperless from "utilities/api_paperless";
import { isEdge } from "utilities/Utils";

export type ObjectURL = string | undefined;

interface ResponseCache {
  [key: string]: ArrayBuffer | File;
}

function defaultFallbackUrl(url: string): string {
  return url;
}

/**
 * ペーパーレスワーカー画面かDKの画面かを判定する
 * なぜか同一のコードで画面表示を行う箇所があるため、実装
 */
const FOR_DK = !window.location.pathname.startsWith("/paperless");

async function generateObjectURL(
  arrayBuffer: ArrayBuffer,
  mimeType: MIMEType,
  callback: (url: string) => void,
): Promise<void> {
  const blob = new Blob([arrayBuffer], { type: mimeType });
  const blobUrl = URL.createObjectURL(blob);

  callback(blobUrl);
}

/**
 * サーバーから取得するファイルのビューアーのロジック
 */
export const useRemoteFile = (
  fetchApi: FileFetch,
  url?: string,
  mimeType?: MIMEType,
  quality = 0.8,
  getFallbackUrl = defaultFallbackUrl,
): [ObjectURL, FileLoadStatus] => {
  const [fileLoadStatus, setFileLoadStatus] = useState<FileLoadStatus>({
    state: "beforeLoading",
  });
  const [objectUrl, setObjectUrl] = useState<string>();

  const onObjectUrlUpdate = useCallback((newObjectUrl: string) => {
    // 実際はダウンロードが完了していない可能性があるが、ロード状況を検知できないため、完了済みとする
    setFileLoadStatus({ state: "loaded" });
    setObjectUrl(newObjectUrl);
  }, []);

  const onDownloadProgress = useCallback((event) => {
    const progress = Math.floor((event.loaded * 100) / event.total);
    setFileLoadStatus({ state: "loading", summary: { progress } });
  }, []);

  const remoteFile: Observable<ArrayBuffer> = useMemo(() => {
    return defer(() => {
      const headers = { Accept: `${mimeType};q=${quality}` };
      if (mimeType) {
        headers["Content-Type"] = mimeType;
      }

      return fetchApi(url, {
        responseType: "arraybuffer",
        headers,
        onDownloadProgress,
      }) as Promise<ArrayBuffer>;
    });
  }, [fetchApi, url, mimeType, quality, onDownloadProgress]);

  useEffect(() => {
    if (!url) {
      setFileLoadStatus({ state: "notExist" });
      setObjectUrl(void 0);

      return (): void => {
        /** URLの指定がない場合、処理を行わない */
      };
    }

    setFileLoadStatus({ state: "loading", summary: { progress: 0 } });

    if (isEdge() && mimeType === "application/pdf") {
      // Edgeは、Blob URLだとPDFを開くことが出来ない
      // Blob URLを作成せずに、リモートのURLをそのまま渡す（ロード状況の表示不可）
      onObjectUrlUpdate(getFallbackUrl(url));
      return (): void => {
        /** ダウンロードをブラウザに任せるので、何もしない */
      };
    }

    const subscription = remoteFile.subscribe(
      async (file) => {
        await generateObjectURL(file, mimeType as MIMEType, onObjectUrlUpdate);
      },
      (error: AxiosError) => {
        if (error.response && error.response.status === 404) {
          // アップロード失敗・未完了
          setFileLoadStatus({ state: "notFound", summary: { error } });
        } else {
          // ロードに失敗した場合のエラー内容を通知する
          Bugsnag.notify(error);
          setFileLoadStatus({ state: "failedToLoad", summary: { error } });
        }
      },
    );

    return (): void => {
      subscription.unsubscribe();
    };
  }, [url, remoteFile, mimeType, getFallbackUrl, onObjectUrlUpdate]);

  useEffect(() => {
    return (): void => {
      // IE, Edgeの場合はリモートURLをそのまま使用していることがあるため、objectUrlとurlを比較して処理をスキップ
      if (objectUrl && objectUrl !== url) {
        URL.revokeObjectURL(objectUrl);
      }
    };
  }, [objectUrl, url]);

  return [objectUrl, fileLoadStatus];
};

export const useCachedFileFetch = (fetchApi: FileFetch): FileFetch => {
  const cache: ResponseCache = useMemo(() => ({}), []);

  const func: FileFetch = useCallback(
    (url, config) => {
      if (url && cache[url]) {
        return Promise.resolve(cache[url]);
      }

      return fetchApi(url, config).then((data) => {
        cache[url as string] = data;

        return data;
      });
    },
    [fetchApi, cache],
  );

  func.cache = cache;

  return func;
};

/**
 * useRemoteFileにおいて、レスポンスをcacheする
 * urlが頻繁に変更され、かつ過去のurlが再度使用される可能性がある場合に有効
 */
export const useRemoteFileWithCache = (
  fetchApi: FileFetch,
  url?: string,
  mimeType?: MIMEType,
  quality = 0.8,
): [ObjectURL, FileLoadStatus] => {
  const fetchApiWithCache = useCachedFileFetch(fetchApi);

  return useRemoteFile(fetchApiWithCache, url, mimeType, quality);
};

export const useS3File = (
  id?: string,
  mimeType?: MIMEType,
  quality = 0.8,
): [ObjectURL, FileLoadStatus] => {
  // 原則 `/api/v1/s3_files/[id]` にリクエストを送る
  // IE11, EdgeにおいてPDFを取得する場合は、 `/s3_files/[id]` からデータを取得する（Cookieによる認証）
  const url = id ? `/s3_files/${id}` : void 0;
  return useRemoteFile(Api.fetch, url, mimeType, quality);
};

export const useReceiptFile = (
  receiptFile: ReceiptFile | null,
  quality = 0.8,
): [ObjectURL, FileLoadStatus] => {
  const fetchApiWithCache = useCachedFileFetch(Api.fetch);

  const url =
    receiptFile && "id" in receiptFile
      ? `/s3_files/${receiptFile.id}`
      : receiptFile?.file?.name;

  if (receiptFile && "file" in receiptFile && fetchApiWithCache.cache) {
    fetchApiWithCache.cache[url as string] = receiptFile.file;
  }
  return useRemoteFile(
    fetchApiWithCache,
    url,
    receiptFile?.contentType,
    quality,
  );
};

export const useThumbnail = (
  id?: string,
  mimeType?: MIMEType,
  size = 128,
  quality = 0.8,
): [ObjectURL, FileLoadStatus] => {
  const url = id ? `/receipt_images/${id}/thumbnail?size=${size}` : void 0;
  return useRemoteFile(Api.fetch, url, mimeType, quality);
};

function getOriginalReceiptFallbackUrl(url: string): string {
  return `${url}/file`;
}

export const useOriginalReceipt = (
  id?: string,
  mimeType?: MIMEType,
  quality = 0.8,
): [ObjectURL, FileLoadStatus] => {
  // 原則 `/api/v1/(paperless/)original_receipts/[id]` にリクエストを送る
  // IE11, EdgeにおいてPDFを取得する場合は、 `/original_receipts/[id]/file` からデータを取得する（Cookieによる認証）
  const url = id ? `/original_receipts/${id}` : void 0;
  const fetchApi = FOR_DK ? Api.fetch : ApiPaperless.fetch;

  return useRemoteFile(
    fetchApi,
    url,
    mimeType,
    quality,
    getOriginalReceiptFallbackUrl,
  );
};

export default useRemoteFile;
