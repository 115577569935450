import SimpleModal from "components/SimpleModal";
import i18next from "i18n";
import React from "react";
import { CostAllocation } from "../types/transactionTable";
import CostAllocationDepartmentTable from "./CostAllocationDepartmentTable";

interface Props {
  show: boolean;
  close: () => void;
  costAllocations: Array<CostAllocation>;
}

const CostAllocationModal: React.FC<Props> = (props) => {
  return (
    <SimpleModal
      show={props.show}
      title={i18next.t("transactions.costAllocationModal.title")}
      close={props.close}
      buttons={[
        {
          content: i18next.t("commons.actions.close"),
          color: "success",
          onClick: props.close,
        },
      ]}
    >
      <CostAllocationDepartmentTable costAllocations={props.costAllocations} />
    </SimpleModal>
  );
};

export default CostAllocationModal;
