import React from "react";
import REPORT_STATUS from "../../../values/report_status";
import * as Buttons from "./buttons";
import { RequestState } from "./buttons/Types";

interface PreReportRequest {
  canApprove: boolean;
}

interface Props {
  locale: string;
  preReport: {
    permission?: {
      canCancelApproval: boolean;
      canCancelTemporaryPayment: boolean;
      canCancelTemporaryPaymentRefund: boolean;
    };
    overallStatus?: {
      status: string;
    };
    status?: string;
    requests?: Array<PreReportRequest>;
  };
  requestState: RequestState;
  onTemporaryPaymentButtonClick: () => void;
  onTemporaryPaymentRefundButtonClick: () => void;
  onCancelTemporaryPaymentButtonClick: () => void;
  onCancelTemporaryPaymentRefundButtonClick: () => void;
  onRejectButtonClick: () => void;
  onApproveButtonClick: () => void;
  onCancelApprovalButtonClick: () => void;
}

/** 承認者用のボタン */
const ButtonsForApprover: React.FC<Props> = ({
  locale,
  preReport,
  requestState,
  onTemporaryPaymentButtonClick,
  onTemporaryPaymentRefundButtonClick,
  onCancelTemporaryPaymentButtonClick,
  onCancelTemporaryPaymentRefundButtonClick,
  onRejectButtonClick,
  onApproveButtonClick,
  onCancelApprovalButtonClick,
}) => {
  const preReportStatus = preReport.overallStatus?.status || preReport.status;

  switch (preReportStatus) {
    case REPORT_STATUS[locale].pendingTemporaryPayment:
    case REPORT_STATUS.ja.pendingTemporaryPayment: {
      const temporaryPaymentButton = (
        <Buttons.TemporaryPaymentButton
          requestState={requestState}
          onClick={onTemporaryPaymentButtonClick}
        />
      );
      if (preReport.permission?.canCancelApproval) {
        return (
          <div className="row">
            <div className="col-md-6">{temporaryPaymentButton}</div>
            <div className="col-md-6">
              <Buttons.CancelApprovalButton
                requestState={requestState}
                onClick={onCancelApprovalButtonClick}
              />
            </div>
          </div>
        );
      }
      return temporaryPaymentButton;
    }
    case REPORT_STATUS[locale].pendingTemporaryPaymentRefund:
    case REPORT_STATUS.ja.pendingTemporaryPaymentRefund: {
      const refundButton = (
        <Buttons.TemporaryPaymentRefundButton
          requestState={requestState}
          onClick={onTemporaryPaymentRefundButtonClick}
        />
      );
      if (preReport.permission?.canCancelApproval) {
        return (
          <div className="row">
            <div className="col-md-6">{refundButton}</div>
            <div className="col-md-6">
              <Buttons.CancelApprovalButton
                requestState={requestState}
                onClick={onCancelApprovalButtonClick}
              />
            </div>
          </div>
        );
      }
      return refundButton;
    }
    case REPORT_STATUS[locale].approved:
    case REPORT_STATUS.ja.approved: {
      if (preReport.permission?.canCancelTemporaryPaymentRefund) {
        return (
          <Buttons.CancelTemporaryPaymentRefundButton
            requestState={requestState}
            onClick={onCancelTemporaryPaymentRefundButtonClick}
          />
        );
      }
      if (preReport.permission?.canCancelApproval) {
        return (
          <Buttons.CancelApprovalButton
            requestState={requestState}
            onClick={onCancelApprovalButtonClick}
          />
        );
      }
      return null;
    }
    case REPORT_STATUS[locale].applying:
    case REPORT_STATUS.ja.applying: {
      const request = preReport.requests?.find((it) => it.canApprove);
      if (!request) {
        return null;
      }
      return (
        <div className="row">
          <div className="col-md-6">
            <Buttons.RejectButton
              requestState={requestState}
              onClick={onRejectButtonClick}
            />
          </div>
          <div className="col-md-6">
            <Buttons.ApproveButton
              requestState={requestState}
              onClick={onApproveButtonClick}
            />
          </div>
        </div>
      );
    }
    case REPORT_STATUS[locale].unsettled:
    case REPORT_STATUS.ja.unsettled: {
      if (preReport.permission?.canCancelTemporaryPayment) {
        return (
          <Buttons.CancelTemporaryPaymentButton
            requestState={requestState}
            onClick={onCancelTemporaryPaymentButtonClick}
          />
        );
      }
      if (preReport.permission?.canCancelApproval) {
        return (
          <Buttons.CancelApprovalButton
            requestState={requestState}
            onClick={onCancelApprovalButtonClick}
          />
        );
      }
      return null;
    }
    case REPORT_STATUS[userPreferences.locale].settled:
    case REPORT_STATUS.ja.settled: {
      if (preReport.permission?.canCancelApproval) {
        return (
          <Buttons.CancelApprovalButton
            requestState={requestState}
            onClick={onCancelApprovalButtonClick}
          />
        );
      }
      return null;
    }
    default:
      return null;
  }
};

export default ButtonsForApprover;
