import React from "react";
import styled from "styled-components";
import colors from "values/colors";
import { Tab } from "./Tab";

interface TabProps {
  /** タブのindex番号 */
  readonly index: number;
  /** 表示ラベル */
  readonly label: string;
}

interface Props {
  /** タブ情報一覧 */
  readonly tabs: TabProps[];
  /** 開くタブのindex番号 */
  readonly value: number;
  /** タブを選択した */
  readonly onSelect: (value: number) => void;
}

const TabsView = styled.div`
  display: flex;
  border-bottom: 1px solid ${colors.renewaled.borderGrey};
  padding-left: 8px;
`;

/**
 * タブの一覧
 */
export const Tabs: React.FC<Props> = ({ tabs, value, onSelect }) => {
  return (
    <TabsView className="renewaled-tabs">
      {tabs.map((tab) => {
        return (
          <Tab
            key={tab.index}
            selectedIndex={tab.index}
            value={value}
            label={tab.label}
            onClick={onSelect}
          />
        );
      })}
    </TabsView>
  );
};
