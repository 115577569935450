import * as actions from '../actions/reportRequests';
import * as flowFormActions from '../actions/flowFormActions';
import ApproverFormForRequest from './ApproverFormForRequest';
import CostAllocationDepartmentModal from 'applications/transactions//components/CostAllocationDepartmentModal';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import TransactionsModal from 'applications/transactions/components/TransactionsModal';
import _get from 'lodash/get';
import _isNil from 'lodash/isNil';
import formatter from 'utilities/formatter';
import i18next from 'i18n';
import { Modal } from 'react-bootstrap';
import { connect } from 'react-redux';
import { displayMessage } from 'actions/ActionCreators';
import { getMessageFromResponse } from 'utilities/Utils';

export class ReportRequestModal extends Component {
  constructor(props) {
    super(props);

    this.renderReportForm = this.renderReportForm.bind(this);
    this.onChangeComment = this.onChangeComment.bind(this);
    this.submit = this.submit.bind(this);
  }

  componentDidMount() {
    this.props.fetchApprovers();
  }

  submit() {
    const { reports, originalReportId, sizePerPage } = this.props;

    this.props.requestReportsAndFetchTransactions(reports, originalReportId, sizePerPage);
  }

  onChangeComment(reportId, e) {
    this.props.onChangeComment(reportId, e.target.value);
  }

  renderReportForm(report, index) {
    return (
      <div key={ `report-${index}` } className='request-page card'>
        <table className='table table-bordered border-side'>
          <tbody>
            { this.renderItem(i18next.t('reports.properties.applicationName'), report.name) }
            { this.renderItem(i18next.t('reports.properties.amount'), this.renderAmount(report)) }
            {
              this.renderItem(
                i18next.t('reports.properties.comment'),
                <div className='form-group'>
                  <input
                    className='form-control'
                    type="text"
                    placeholder={ i18next.t('reports.requests.enterComment') }
                    value={ _get(report, 'requestComment', '') }
                    onChange={ this.onChangeComment.bind(null, report.id) }
                  />
                </div>,
              )
            }
            {
              this.renderItem(
                i18next.t('reports.properties.approvalFlow'),
                <ApproverFormForRequest
                  reportIndex= { index }
                  reportId={ report.id }
                  approvalFlowId={ report.approvalFlowId }
                  approvals={ report.approvals }
                  approvers={ report.approvers }
                  editingApprovalIndex={ report.editingApprovalIndex }
                  isAddApproverModalOpen={ report.isAddApproverModalOpen }
                  approversSearchCondition={ this.props.approversSearch.searchConditions }
                  selectedApprovers={ this.props.selectedApprovers }
                  isEdit={ false }
                />,
              )
            }
          </tbody>
        </table>
      </div>
    );
  }

  renderAmount(report) {
    const count = report.transactionIds.length || 0;
    const amount = report.amount;

    const unit = i18next.t('commons.units.result', { count });
    return (
      <div>
        { `${formatter.amount(amount, "")} (${count} ${unit})` }
        <span className="txt-accent txt-pointer pull-right"
          onClick={ this.props.openTransactionsModal.bind(null, report.id) }
        >
          { i18next.t('reports.requests.showExpenses') }
        </span>
      </div>
    );
  }

  renderItem(label, value) {
    return (
      <tr className="row">
        <td className="col-sm-2">{ label }</td>
        <td className="col-sm-10">{ value }</td>
      </tr>
    );
  }

  render() {
    const buttons = [
      {
        className: 'col-sm-6 col-sm-offset-3',
        color: 'accent',
        content: i18next.t('reports.requests.submit'),
        onClick: this.submit,
      },
    ];
    const { reports, selectedReportId, selectedReport } = this.props;

    return (
      <Modal
        show={ this.props.isReportRequestModalOpen }
        onHide={ this.props.toggleReportRequestModal }>
        <Modal.Header closeButton>
          <Modal.Title>
            { i18next.t('reports.titles.checkContents', { count: reports.length }) }
          </Modal.Title>
        </Modal.Header>
        <Modal.Body className='request-modal'>
          { reports.map(this.renderReportForm) }
          <TransactionsModal
            title={ _get(selectedReport, 'title') }
            show={ this.props.isTransactionsModalOpen }
            close={ this.props.closeTransactionsModal }
            fetchTransactions={ this.props.fetchTransactions.bind(null, selectedReport) }
            onPageChange={ this.props.onChangePageValue.bind(null, selectedReportId, 'currentPage') }
            onSizePerPageList={ this.props.onChangePageValue.bind(null, selectedReportId, 'sizePerPage') }
            currentPage={ _get(selectedReport, 'transactions.currentPage') }
            sizePerPage={ _get(selectedReport, 'transactions.sizePerPage') }
            transactions={ _get(selectedReport, 'transactions.data', []) }
            openCostAllocationModal={ this.props.openCostAllocationModal }
            costAllocationHovorRowIdx={ this.props.costAllocationHovorRowIdx }
            onMouseEnterCostAlocationDiv={ this.props.onMouseEnterCostAlocationDiv }
            onMouseLeaveCostAlocationDiv={ this.props.onMouseLeaveCostAlocationDiv }
          />
          <CostAllocationDepartmentModal
            show={ this.props.isCostAllocationModalOpen }
            close={ this.props.closeCostAllocationModal }
            costAllocations={ this.props.costAllocations }
          />
        </Modal.Body>
        <Modal.Footer>
          { buttons.map((button, idx) => (
            <button
              key={ `simple-modal-button-${idx}` }
              className={ `btn btn-${button.color} ${button.className}` }
              onClick={ button.onClick }
              disabled={ this.props.isRequestInProgress }
            >
              { button.content }
            </button>
          )) }
        </Modal.Footer>
      </Modal>
    );
  }
}

ReportRequestModal.defaultProps = {
  originalReportId: null,
  referingReportId: null,
  reports: [],
};

ReportRequestModal.propTypes = {
  // 申請フローを変更する際に、現在の申請のIDを保持する
  originalReportId: PropTypes.string,
  // 経費の一覧を参照中の申請のID
  // 経費の一覧をモーダルで表示する際に使用する
  selectedReportId: PropTypes.string,
  reports: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string,
      transactions: PropTypes.shape({
        currentPage: PropTypes.number,
        sizePerPage: PropTypes.number,
        data: PropTypes.arrayOf(PropTypes.object).isRequired,
      }),
      approvals: PropTypes.arrayOf(
        PropTypes.shape({
          condition: PropTypes.string,
          editable: PropTypes.bool,
          approvers: PropTypes.arrayOf(
            PropTypes.shape({
              type: PropTypes.string,
              id: PropTypes.string,
              email: PropTypes.string,
              name: PropTypes.string,
            }),
          ),
        }),
      ),
    }),
  ).isRequired,
};

function mapStateToProps(state, ownProps) {
  return {
    isReportRequestModalOpen: state.request.isReportRequestModalOpen,
    selectedReportId: state.request.selectedReportId,
    selectedReport: state.request.reports.find((x) => x.id === state.request.selectedReportId),
    isTransactionsModalOpen: state.request.isTransactionsModalOpen,
    isCostAllocationModalOpen: state.request.costAllocationModal.show,
    costAllocationHovorRowIdx: state.request.costAllocationModal.hovor,
    costAllocations: state.request.costAllocationModal.costAllocations,
    approversSearch: state.approversSearch,
    isRequestInProgress: state.request.isLoading,
    sizePerPage: state.sizePerPage,
    selectedApprovers: state.selectedApproversList.selectedApprovers,
  };
}

function mapDispatchToProps(dispatch, ownProps) {
  return {
    fetchTransactions(selectedReport, offset, limit) {
      if (_isNil(selectedReport)) {
        return Promise.resolve({});
      }

      const { id, transactionIds } = selectedReport;
      return dispatch(actions.fetchReportTransactions(id, transactionIds, offset, limit));
    },
    fetchApprovers() {
      dispatch(flowFormActions.fetchApprovers());
    },
    requestReportsAndFetchTransactions(reports, originalReportId, sizePerPage) {
      dispatch(actions.requestReportsAndFetchTransactions(reports, originalReportId, sizePerPage))
        .then(this.fetchApprovers())
        .catch((e) => {
          dispatch(displayMessage('error', getMessageFromResponse(e, e.message)));
        });
    },
    onChangePageValue(reportId, key, value) {
      dispatch(actions.setReportTransactionsPageValue(reportId, key, value));
    },
    onChangeComment(reportId, comment) {
      dispatch(actions.setReportFormValue(reportId, 'requestComment', comment));
    },
    openTransactionsModal(reportId) {
      dispatch(actions.toggleTransactionsModal(reportId, true));
    },
    closeTransactionsModal(reportId) {
      dispatch(actions.toggleTransactionsModal(reportId, false));
    },
    openCostAllocationModal(departments) {
      dispatch(actions.toggleCostAllocationModal(true));
      dispatch(actions.setCostAllocations(departments));
    },
    closeCostAllocationModal() {
      dispatch(actions.toggleCostAllocationModal(false));
      dispatch(actions.setCostAllocations([]));
    },
    onMouseEnterCostAlocationDiv(rowIdx) {
      dispatch(actions.setCostAllocationHovorRowIdx(rowIdx));
    },
    onMouseLeaveCostAlocationDiv() {
      dispatch(actions.setCostAllocationHovorRowIdx(null));
    },
    toggleReportRequestModal() {
      dispatch(actions.toggleReportRequestModal());
    },
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(ReportRequestModal);
