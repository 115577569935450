import _get from "lodash/get";
import { createSelector } from "reselect";

const getEntryForms = (state) => _get(state, "entryForms", []);
const getHistories = (state) => _get(state, "histories", {});
const getTransaction = (state) => _get(state, "transactionForReference");

const makeReportHistoryStateMapper = () =>
  createSelector(
    [getEntryForms, getHistories, getTransaction],
    (entryForms, histories, transaction) => {
      return {
        entryForms,
        histories,
        transaction,
      };
    },
  );

export default makeReportHistoryStateMapper;
