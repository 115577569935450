import React from "react";
import { Modal } from "react-bootstrap";

interface Props {
  readonly children: React.ReactNode;
}

/**
 * モーダル: ボディ部分
 */
export const ModalBody: React.FC<Props> = ({ children }) => {
  return <Modal.Body>{children}</Modal.Body>;
};
