import * as actions from '../actions/analysisReportExportData';
import ExportForm from '../components/ExportForm';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import Timer from 'utilities/timer';
import i18next from 'i18n';
import isNil from 'lodash/isNil';
import { connect } from 'react-redux';

class CategoryAnalysisExportForm extends Component {
  constructor(props) {
    super(props);

    this.pollingAnalysisReportJobs();

    this.onRequestDownload = this.onRequestDownload.bind(this);
    this.pollingAnalysisReportJobs = this.pollingAnalysisReportJobs.bind(this);
    this.stopPollingAnalysisReportJobs = this.stopPollingAnalysisReportJobs.bind(this);
  }

  componentWillUnmount() {
    this.stopPollingAnalysisReportJobs();
  }

  pollingAnalysisReportJobs() {
    const { loadAggregationByCategoryJobs } = this.props;

    this.timer = new Timer(3000, [loadAggregationByCategoryJobs]);
    this.timer.start();
  }

  stopPollingAnalysisReportJobs() {
    if (!isNil(this.timer)) {
      this.timer.stop();
    }
  }

  onRequestDownload() {
    const { requestAggregationByCategoryJobs, loadAggregationByCategoryJobs } = this.props;
    requestAggregationByCategoryJobs();
    loadAggregationByCategoryJobs();
  }

  onDownloadClick(e, url) {
    e.stopPropagation();
    window.location.href = url;
  }

  render() {
    const { analysisReportExportData } = this.props;

    return (
      <ExportForm
        data={ analysisReportExportData.aggregationByCategoryJobs }
        onRequestExport={ this.onRequestDownload }
        requestButtonTitle={ i18next.t('exports.inputs.requestAggregationByCategoryExportFile') }
        hiddenFields={ ['exportTypeName'] }
      />
    );
  }
}

CategoryAnalysisExportForm.propTypes = {
  analysisReportExportData: PropTypes.shape({
    aggregationByCategoryJobs: PropTypes.array.isRequired,
  }),
  loadAggregationByCategoryJobs: PropTypes.func.isRequired,
  requestAggregationByCategoryJobs: PropTypes.func.isRequired,
};

function mapStateToProps(state, ownProps) {
  const { analysisReportExportData } = state;
  return {
    analysisReportExportData,
  };
}

function mapDispatchToProps(dispatch, ownProps) {
  return {
    loadAggregationByCategoryJobs() {
      dispatch(actions.fetchAggregationByCategoryJobs());
    },
    requestAggregationByCategoryJobs() {
      dispatch(actions.requestAggregationByCategoryJobs());
    },
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(CategoryAnalysisExportForm);
