import PropTypes from 'prop-types';
import stationType from './station';

const destinationType = PropTypes.shape({
  name: PropTypes.string.isRequired,
  station1: stationType.isRequired,
  station2: stationType.isRequired,
});

export default destinationType;
