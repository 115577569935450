import React from 'react';
import i18next from 'i18n';
import { OverlayTrigger, Popover } from 'react-bootstrap';
import { RequiredIcon } from '../components/icons/RequiredIcon';

interface Props {
  type: string;
}

const LabelWithDescription: React.FC<Props> = ({ type }) => {
  const label = i18next.t(`accountingDataScheduledExports.fileTransferLocations.form.${type}`);
  const description = i18next.t(`accountingDataScheduledExports.fileTransferLocations.form.popoverDescription.${type}`);

  const descriptionPopover = (): JSX.Element => {
    const descriptionText = (
      description.split(/(\n)/).map(
        (t, i) => (
          (t === '\n')
            ? <React.Fragment key={ i }><br /></React.Fragment>
            : t
        ),
      )
    );

    return <Popover id='description'>{ descriptionText }</Popover>;
  };

  return (
    <label className={ 'control-label' }>
      { label } <RequiredIcon />
      <OverlayTrigger
        overlay={ descriptionPopover() }
        placement={ 'right' }
        trigger={ ['click'] }
        rootClose={ true }
      >
        <i className='far fa-question-circle' style={ { color: '#AAA', marginLeft: '6px', cursor: 'pointer' } } />
      </OverlayTrigger>
    </label>
  );
};

export default LabelWithDescription;
