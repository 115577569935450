import { Button } from "components/renewaled_ui/buttons";
import i18next from "i18n";
import React from "react";
import { ReceiptExpenseMatching } from "utilities/api/models/ReceiptExpenseMatching";

interface Props {
  marked?: boolean;
  onClick: () => void;
  receiptExpenseMaching: ReceiptExpenseMatching;
  show?: boolean;
  style?: React.CSSProperties;
  disabled: boolean;
}

const MarkingAsMatchedButton: React.FC<Props> = (props) => {
  const { marked = false, onClick, show = true, disabled } = props;

  // 基本はtrue。電子領収書フラグを非表示として欲しい」という事業所に対してのみ特例的にfalseとなる。
  // それらの事業所に対しては、(電子良書がONであったとしても) 必ず当ボタンを表示させる。でないと、経費を「原本確認済み」にステータスを変更できなくなる
  const enableElectronigReceiptImageGroup =
    userPreferences.preference.enableExpenseElectronicReceiptImageFlag;

  if (enableElectronigReceiptImageGroup && !show) {
    return null;
  }

  return (
    <Button
      small
      styleType={marked ? "link-danger" : "link-primary"}
      onClick={onClick}
      disabled={disabled}
      minWidth={0}
    >
      {marked
        ? i18next.t("paperless.expense.reviseMarkedAsMatched")
        : i18next.t("paperless.expense.markAsMatched")}
    </Button>
  );
};

export default MarkingAsMatchedButton;
