import ExtendedFileView from 'components/renewaled_ui/FileViewer';
import React, { useCallback, useState } from 'react';
import SimpleModal from 'components/SimpleModal';
import styled from 'styled-components';
import { FileLoadStatus, LoadingStatus } from 'components/types/attachedFiles.types';
import { MIMEType } from "utilities/api/types/MIMEType";

interface Props {
  title: string;
  loadStatus: FileLoadStatus;
  url?: string;
  mimeType?: MIMEType;
  fileName?: string;
}

const ContainerView = styled.div`
  display: inline-block;

  .file-history-view__thumbnail--loaded:hover {
    cursor: pointer;
  }
`;

const FileHistoryView: React.FunctionComponent<Props> = (props) => {
  const {
    title, url, mimeType, fileName, loadStatus,
  } = props;

  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  const openModal = useCallback((e: React.SyntheticEvent, state: LoadingStatus) => {
    e.stopPropagation();

    if (state === 'loading' || state === 'loaded') {
      setIsModalOpen(true);
    }
  }, []);
  const closeModal = useCallback(() => {
    setIsModalOpen(false);
  }, []);
  const clickView = useCallback((e: React.SyntheticEvent) => {
    // 表示中のテーブル行のクリックイベントが発火しないように
    e.stopPropagation();
  }, []);

  return (
    <ContainerView onClick={ clickView }>
      <ExtendedFileView
        className='file-history-view__thumbnail'
        url={ url }
        mimeType={ mimeType }
        fileName={ fileName }
        loadStatus={ loadStatus }
        width={ 200 }
        height={ 150 }
        showToolbar={ false }
        isShowToolBar={ false }
        onClick={ openModal }
      />

      <SimpleModal title={ title }
        show={ isModalOpen }
        close={ closeModal }
      >
        <div style={ { display: 'flex', justifyContent: 'center' } }>
          <ExtendedFileView url={ url }
            mimeType={ mimeType }
            fileName={ fileName }
            loadStatus={ loadStatus }
            width={ 500 }
            height={ 375 }
          />
        </div>
      </SimpleModal>
    </ContainerView>
  );
};

export default FileHistoryView;
