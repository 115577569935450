import i18next from "i18n";
import React from "react";
import { Popover } from "react-bootstrap";
import { Expense } from "utilities/api/models";
import { IconWithOverlay } from "./IconWithOverlay";

type Props = Pick<Expense, "isCorporate">;

/**
 * 精算対象外アイコン
 */
export const IsCorporateIconWithOverlay: React.FC<Props> = ({
  isCorporate,
}) => {
  if (!isCorporate) {
    return null;
  }

  const isCorporateOverlay = (): JSX.Element => {
    return (
      <Popover id="corporate-popup">
        {i18next.t("transactions.messages.isCorporate")}
      </Popover>
    );
  };

  return (
    <IconWithOverlay overlay={isCorporateOverlay()} icon={"far fa-building"} />
  );
};
