import { DeleteEventHandler } from "components/types/attachedFiles.types";
import React from "react";

interface UseReturn {
  readonly isLoaded: boolean;
  readonly canUpload: boolean;
  readonly canDownload: boolean;
  readonly canDelete: boolean;
  readonly isShowToolBar: () => boolean;
  readonly onClickDeleteTool: (
    e: React.MouseEvent<HTMLElement, MouseEvent>,
  ) => void;
}

/**
 * PDFビューアー: ロジック
 */
export const useHooks = (
  loadStatus: string,
  isShowFileSelect: boolean,
  isDeletable: boolean,
  isShowToolBarProps: boolean,
  downloadLink?: string,
  onDelete?: DeleteEventHandler,
): UseReturn => {
  /**
   * ツールバー: 削除を押した
   */
  const onClickDeleteTool = (
    e: React.MouseEvent<HTMLElement, MouseEvent>,
  ): void => {
    e.stopPropagation();
    if (!onDelete) return;
    onDelete(e);
  };

  /**
   * ファイルのロードが完了したか
   * notFoundではないか
   */
  const isLoaded = loadStatus === "loaded";

  /**
   * 表示するか: アップロードボタン
   */
  const canUpload = isShowFileSelect;

  /**
   * 表示するか: ダウンロード
   */
  const canDownload = Boolean(downloadLink);

  /**
   * 表示するか: 削除ボタン
   */
  const canDelete = isDeletable;

  /**
   * ツールバーを表示するか
   */
  const isShowToolBar = (): boolean => {
    if (!isShowToolBarProps) return false;
    if (canDelete) return true;
    if (canUpload) return true;
    if (canDownload) return true;
    return false;
  };

  return {
    isLoaded,
    canUpload,
    canDownload,
    canDelete,
    isShowToolBar,
    onClickDeleteTool,
  };
};
