import get from 'lodash/get';
import { createSelector } from 'reselect';

const getIsAddApproverModalOpen = (state, props) => get(state, 'flowForm.showAddApproverModal');
const getEditingApprovalIndex = (state, props) => get(state, 'flowForm.editingApprovalIndex');
const getIsUpperLevelPostSelecting = (state, props) => get(state, 'flowForm.isUpperLevelPostSelecting');
const getRelativeLevel = (state, props) => get(state, 'flowForm.relativeLevel');
const getUsers = (state, props) => get(state, 'flowForm.approvers.users.current');
const getGroups = (state, props) => get(state, 'flowForm.approvers.groups.current');
const getUserDepartmentPosts = (state, props) => get(state, 'flowForm.approvers.userDepartmentPosts.current');
const getUserDepartmentPostOptions = (state, props) => get(state, 'flowForm.approvers.userDepartmentPosts.options');
const getProjectPosts = (state, props) => get(state, 'flowForm.approvers.projectPosts.current');
const getPayerDepartmentPosts = (state, props) => get(state, 'flowForm.approvers.payerDepartmentPosts.current');
const getPayerDepartmentPostOptions = (state, props) => get(state, 'flowForm.approvers.payerDepartmentPosts.options');
const getApprovalFlow = (state, props) => get(state, 'flowForm.approvalFlow');
const getApprovals = (state, props) => {
  const approvals = get(state, 'flowForm.approvalFlow.approvals').map((approval) => {
    // 承認ステップ内で共通のデータではあるものの、
    // approver_posts.relative_levelにデータを保持している関係で、
    // approverからrelativeLevelを取得する
    let newApproval = approval;
    approval.approvers.forEach((approver) => {
      if (approver.relativeLevel > 0) { // 承認者個人が指定されている場合など、undefinedになることもあり
        newApproval = {
          ...approval,
          relativeLevel: approver.relativeLevel, // N個上の部署役職
          isUpperLevelPostSelecting: true, // N個上の部署役職を指定するかどうか
        };
      }
    });

    return newApproval;
  });

  return approvals;
};
const getApproversSearchCondition = (state, props) => get(state, 'approversSearch.searchConditions');
const getApproverFormSelectedApprovers = (state) => get(state, 'approvalFormSelectedApproversList.selectedApprovers');
const getSelectedDepartmentPostRelationships = (state) => get(state, 'departmentPostRelationshipSelector.selectedDepartmentPostRelationships', []);

export const makeApproverFormStateMapper = () => createSelector(
  [getIsAddApproverModalOpen, getEditingApprovalIndex, getIsUpperLevelPostSelecting, getRelativeLevel,
    getUsers, getGroups, getUserDepartmentPosts, getUserDepartmentPostOptions, getProjectPosts, getPayerDepartmentPosts, getPayerDepartmentPostOptions, getApprovalFlow, getApprovals, getApproversSearchCondition, getApproverFormSelectedApprovers, getSelectedDepartmentPostRelationships],
  (isAddApproverModalOpen, editingApprovalIndex, isUpperLevelPostSelecting, relativeLevel,
    users, groups, userDepartmentPosts, userDepartmentPostOptions, projectPosts, payerDepartmentPosts, payerDepartmentPostOptions, approvalFlow, approvals, searchConditions, approverFormSelectedApprovers, selectedDepartmentPostRelationships) => {
    const approvalFlowId = get(approvalFlow, 'id') || get(approvalFlow, 'approvalFlowId');
    const isApproverSelectFlow = get(approvalFlow, 'isApproverSelectFlow');

    return {
      isAddApproverModalOpen,
      editingApprovalIndex,
      isUpperLevelPostSelecting,
      relativeLevel,
      approvers: {
        users,
        groups,
        userDepartmentPosts,
        projectPosts,
        payerDepartmentPosts,
      },
      options: {
        userDepartmentPosts: userDepartmentPostOptions,
        payerDepartmentPosts: payerDepartmentPostOptions,
      },
      approvals,
      approvalFlowId,
      approversSearchCondition: searchConditions,
      selectedApprovers: approverFormSelectedApprovers,
      isApproverSelectFlow,
      selectedDepartmentPostRelationships,
    };
  },
);

export default makeApproverFormStateMapper;
