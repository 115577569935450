import DateInput from 'components/date_input';
import React from 'react';

interface Props {
  disabled: boolean;
  value: string;
  onChange: (value: string) => void;
}

/**
 * 汎用フォームの日付入力
 */
export const FormItemDateInput: React.FC<Props> = React.memo(({
  disabled, value, onChange,
}) => {
  return disabled ? (
    <div className="form-control-static" style={ { paddingLeft: '12px' } }>
      <DateInput
        disabled={ disabled }
        value={ value }
      />
    </div>
  ) : (
    <DateInput
      disabled={ disabled }
      value={ value }
      inputClassName="form-control toggle"
      onChange={ (date: string): void => onChange(date) }
    />
  );
});
