import { Button } from "components/renewaled_ui/buttons";
import i18next from "i18n";
import React from "react";

interface Props {
  disabled: boolean;
  processing: boolean;
  onClick: () => void;
}

/** 分割実行ボタン */
export const SplitConfirmButton: React.FC<Props> = ({
  disabled,
  processing,
  onClick,
}) => {
  return (
    <Button
      onClick={onClick}
      styleType="primary"
      disabled={disabled || processing}
      loading={processing}
    >
      {i18next.t("commons.actions.confirmSplit")}
    </Button>
  );
};
