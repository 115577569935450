import { DepartmentPostRelationshipSelectorDepartment, DepartmentPostRelationshipSelectorPost, DepartmentPostRelationshipSelectorSelectedDepartmentPost } from "./domain";
import { SearchResponseDepartment, SearchResponsePost } from "utilities/api_v3/responses/kernels/organizations/departments/managements/SearchResponse";

/**
 * APIから取得した役職一覧をドメインに変換する
 * @param posts APIから取得した役職一覧
 * @param selectedDepartmentPostRelationships 選択中の部署役職一覧
 * @returns ドメインに変換した役職一覧
 */
const departmentPostAdaptor = (posts: SearchResponsePost[] | DepartmentPostRelationshipSelectorPost[], selectedDepartmentPostRelationships: DepartmentPostRelationshipSelectorSelectedDepartmentPost[]): DepartmentPostRelationshipSelectorPost[] => {
  return posts.map((post) => (
    {
      name: post.name,
      postId: post.id,
      postRelationshipId: post.relationshipId,
      isChecked: selectedDepartmentPostRelationships.some((r) => r.postId === post.id),
    }
  ));
};

/**
 * APIから取得した部署一覧をドメインに変換する
 * @param departments APIから取得した部署一覧
 * @param defaultPosts 部署に紐づく役職がない場合のデフォルトの役職一覧
 * @param selectedDepartmentPostRelationships 選択中の部署役職一覧
 * @returns ドメインに変換した部署一覧
 */
export const departmentsAdaptor = (departments: SearchResponseDepartment[], defaultPosts: DepartmentPostRelationshipSelectorPost[], selectedDepartmentPostRelationships: DepartmentPostRelationshipSelectorSelectedDepartmentPost[]): DepartmentPostRelationshipSelectorDepartment[] => {
  return departments.map((department) => (
    {
      id: department.id,
      name: department.name,
      code: department.code,
      parents: department.parents.map((parent) => ({ id: parent.id, name: parent.name })),
      posts: departmentPostAdaptor(department.posts.length === 0 ? defaultPosts : department.posts, selectedDepartmentPostRelationships.filter((r) => r.id === department.id)),
      isChecked: selectedDepartmentPostRelationships.some((r) => r.id === department.id),
    }
  ));
};
