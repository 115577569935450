import { Button } from "components/renewaled_ui/buttons";
import {
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
} from "components/renewaled_ui/single_views";
import i18next from "i18n";
import isNil from "lodash/isNil";
import PropTypes from "prop-types";
import React, { Component } from "react";
import Formatter from "utilities/formatter";
import fareFormatter from "../fare_formatter";

export default class RouteSelector extends Component {
  renderOneWayAmount(route) {
    return (
      <div className="row">
        <label className="control-label col-sm-4">
          {i18next.t("transactions.inputs.onewayFare")}
        </label>
        <p className="fare-value txt txt-lg col-sm-8 route-show-field">
          {fareFormatter.renderFare(route.onewayAmount, route.withTeiki)}
        </p>
      </div>
    );
  }

  renderTeikiFareList(route) {
    return (
      <div>
        {this.renderTeikiFare("oneMonth", route.teiki1)}
        {this.renderTeikiFare("threeMonths", route.teiki3)}
        {this.renderTeikiFare("sixMonths", route.teiki6)}
      </div>
    );
  }

  renderTeikiFare(label, amount) {
    const key = `preferences.teiki.terms.${label}`;
    return (
      <div className="row">
        <label className="control-label col-sm-4">{i18next.t(key)}</label>
        <p className="fare-value txt txt-lg col-sm-8">
          {fareFormatter.renderFare(amount)}
        </p>
      </div>
    );
  }

  renderRoute(route, idx) {
    const renderAmount = this.props.withTeiki
      ? this.renderTeikiFareList(route)
      : this.renderOneWayAmount(route);
    return (
      <div key={`route-${idx}`}>
        <div
          className="route-info-box"
          onClick={(e) => {
            this.props.onRouteSelect(route);
          }}
        >
          {
            /* routeが定期の場合、labelsが存在しない */
            route.labels && route.labels.length > 0 ? (
              <div className="row">
                <label className="control-label col-sm-4"></label>
                <div className="fare-value txt txt-lg col-sm-8">
                  {fareFormatter.renderLabels(route.labels)}
                </div>
              </div>
            ) : null
          }
          {renderAmount}
          {userPreferences.preference.showDistance && route.onewayDistance ? (
            <div className="row">
              <label className="control-label col-sm-4">
                {i18next.t("transactions.inputs.distance")}
              </label>
              <p className="txt col-sm-8">
                {Formatter.distance(route.onewayDistance)}
              </p>
            </div>
          ) : null}
          <div className="row">
            <label className="control-label col-sm-4">
              {i18next.t("transactions.inputs.rideTime")}
            </label>
            <p className="time-value txt col-sm-8">
              {fareFormatter.renderTime(route.duration)}
            </p>
          </div>
          <div className="row">
            <label className="control-label col-sm-4">
              {i18next.t("transactions.inputs.facilities")}
            </label>
            <div className="col-sm-8">
              {fareFormatter.renderViaList(route.via)}
            </div>
          </div>
        </div>
        <hr />
      </div>
    );
  }

  render() {
    const { routes, show, closeModal } = this.props;
    if (isNil(routes) || routes.length === 0) {
      return null;
    }
    return (
      <Modal show={show} onClose={closeModal} width="sm">
        <ModalHeader
          title={i18next.t("transactions.inputs.selectRoute")}
          onClose={closeModal}
        />
        <ModalBody>{routes.map(this.renderRoute.bind(this))}</ModalBody>
        <ModalFooter>
          <Button onClick={closeModal} styleType="link-secondary">
            {i18next.t("commons.actions.close")}
          </Button>
        </ModalFooter>
      </Modal>
    );
  }
}

RouteSelector.propTypes = {
  routes: PropTypes.array.isRequired,
  onRouteSelect: PropTypes.func.isRequired,
  closeModal: PropTypes.func.isRequired,
  show: PropTypes.bool.isRequired,
  withTeiki: PropTypes.bool,
};

RouteSelector.defaultProps = {
  withTeiki: false,
};
