/* eslint-disable @typescript-eslint/explicit-function-return-type */
import * as ActionCreators from "../actions/ApprovalFormSelectedApproversList/ActionCreators";
import { approvalFormSelectedApproversListActionTypes as ActionTypes } from '../actions/ApprovalFormSelectedApproversList/ActionTypes';
import { CreatorsToActions } from "types/UtilityTypes";
import { State, initialState } from '../actions/ApprovalFormSelectedApproversList/Types';

type Actions = CreatorsToActions<typeof ActionCreators>;

const reducer = (state = initialState, action: Actions): State => {
  switch (action.type) {
    case ActionTypes.SELECT_APPROVERS: {
      return {
        ...state,
        selectedApprovers: [...action.payload.approvers],
      };
    }
    case ActionTypes.SELECT_APPROVER: {
      const selectedApprovers = [...state.selectedApprovers];
      selectedApprovers.push(action.payload.approver);
      return {
        ...state,
        selectedApprovers,
      };
    }
    case ActionTypes.DESELECT_APPROVER: {
      const approver = action.payload.approver;
      const selectedApprovers = state.selectedApprovers.filter((a) => {
        if (a.type === 'department_post_relationship') {
          return !(a.id === approver.id && a.type === approver.type && a.postId === approver.postId);
        }
        return !(a.id === approver.id && a.type === approver.type);
      });

      return {
        ...state,
        selectedApprovers,
      };
    }
    default:
      return state;
  }
};

export default reducer;
