import AddApproverModal from 'applications/approval_flows/add_approver_modal';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import i18next from 'i18n';

// 新規Componentでは使用禁止
// containers/ApproverForm を使用すること
export default class ApproverForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      approvalFlow: this.props.approvalFlow,
      approvers: [],
      index: -1,
    };
    this.addApproverModal = React.createRef();
  }

  getApprovals() {
    return this.state.approvalFlow.approvals;
  }

  addApprovalFlow(index) {
    const approvalFlow = this.state.approvalFlow;
    const approve = {
      condition: 'any',
      editable: true,
      skippable: false,
      approvers: [],
      label: null,
      created: true,
    };
    approvalFlow.approvals.splice(index, 0, approve);
    this.setState({ approvalFlow });
  }

  selectApproverNum(condition, index) {
    const approvalFlow = this.state.approvalFlow;
    approvalFlow.approvals[index].condition = condition;
    this.setState({ approvalFlow });
  }

  openAddApprovalModal(index) {
    const approvers = this.state.approvalFlow.approvals[index].approvers;
    this.setState({
      approvers,
      index,
    });
    this.addApproverModal.current.open();
  }

  onSubmitApprovers(approvers) {
    const approvalFlow = this.state.approvalFlow;
    approvalFlow.approvals[this.state.index].approvers = approvers;
    this.setState({ approvers });
  }

  changeEditable(index, e) {
    const approvalFlow = this.state.approvalFlow;
    approvalFlow.approvals[index].editable = !e.target.checked;
    this.setState({ approvalFlow });
  }

  onDeleteApproval(index) {
    const approvalFlow = this.state.approvalFlow;
    approvalFlow.approvals.splice(index, 1);
    this.setState({ approvalFlow });
  }

  renderApprovalFlow() {
    const rows = [];

    rows.push(this.renderIcons(0));
    this.state.approvalFlow.approvals.forEach((approve, approvalIndex) => {
      rows.push(this.renderForm(approve, approvalIndex));
      rows.push(this.renderIcons(approvalIndex + 1));
    });

    return (
      <div className="col-sm-12 row-0">
        { rows }
      </div>
    );
  }

  renderForm(approve, approvalIndex) {
    const step = i18next.t('commons.ordinals.no_interval', { postProcess: 'interval', count: approvalIndex + 1 });
    const deleteBtn = (this.props.isEdit || approve.created || (approve.editable && approve.skippable)) ? (
      <span className="txt-danger txt-xlg approver-delete" onClick={ this.onDeleteApproval.bind(this, approvalIndex) }>
        ×
      </span>
    ) : (
      <span className="">
      </span>
    );

    if (this.props.isEdit) {
      return (
        <div key={ `approver-form-${approvalIndex}` } className="col-sm-12 row-0 form-group clear">
          <label className='col-sm-2 control-label approval-label approval-pl-0' htmlFor={ `approver-${approvalIndex}` }>
            { i18next.t('approvals.flows.approvalStep', { order: step }) }
          </label>
          <div className="col-sm-7 left" id={ `approver-${approvalIndex}` }>
            <div className="input-group approver-form">
              { this.renderApproverNum(approve, approvalIndex) }
              { this.renderApprover(approve, approvalIndex) }
            </div>
          </div>
          <div className="col-sm-3 editable-check">
            { deleteBtn }
            <input
              id={ `check-${this.state.approvalFlow.approvalFlowId ? this.state.approvalFlow.approvalFlowId : this.state.approvalFlow.id}-${approvalIndex}` }
              type="checkbox"
              checked={ !approve.editable }
              onChange={ this.changeEditable.bind(this, approvalIndex) }
              className="txt-pointer"/>
            <label
              htmlFor={ `check-${this.state.approvalFlow.approvalFlowId ? this.state.approvalFlow.approvalFlowId : this.state.approvalFlow.id}-${approvalIndex}` }
              className="txt-pointer">
              { i18next.t('approvals.flows.notEditable') }
            </label>
          </div>
        </div>
      );
    }
    return (
      <div key={ `approver-form-${approvalIndex}` } className="col-sm-12 row-0 form-group clear">
        <label className='col-sm-2 control-label approval-label approval-pl-0' htmlFor={ `approver-${approvalIndex}` }>
          { i18next.t('approvals.flows.approvalStep', { order: step }) }
        </label>
        <div className="col-sm-9" id={ `approver-${approvalIndex}` }>
          <div className="input-group approver-form">
            { this.renderApproverNum(approve, approvalIndex) }
            { this.renderApprover(approve, approvalIndex) }
          </div>
        </div>
        { deleteBtn }
      </div>
    );
  }

  renderIcons(index) {
    if (index === 0 || index === this.state.approvalFlow.approvals.length) {
      return (
        <i key={ `add-icon-${index}` }
          className={ this.props.isEdit ? "fa fa-plus-square accent plus-icon-edit form-group txt-pointer" : "fa fa-plus-square accent plus-icon pull-right form-group txt-pointer" }
          onClick={ this.addApprovalFlow.bind(this, index) }/>
      );
    }
    return (
      <div key={ `add-icon-wrapper-${index}` }
        className="col-sm-12 row-0 form-group">
        <i className={ this.props.isEdit ? "fa fa-chevron-down down-icon-edit" : "fa fa-chevron-down down-icon" }/>
        <i
          className={ this.props.isEdit ? "fa fa-plus-square accent plus-icon-edit-middle txt-pointer" : "fa fa-plus-square accent plus-icon pull-right txt-pointer" }
          onClick={ this.addApprovalFlow.bind(this, index) }/>
      </div>
    );
  }

  renderApproverNum(approve, index) {
    if (approve.editable) {
      return (
        <div className="dropdown input-group-addon approve-num txt-pointer">
          <div
            id={ `num-${this.state.approvalFlow.approvalFlowId ? this.state.approvalFlow.approvalFlowId : this.state.approvalFlow.id}-${index}` }
            className="dropdown-toggle"
            data-toggle="dropdown">
            { (() => {
              if (approve.condition === 'all') {
                return (
                  <span>{ i18next.t('approvals.flows.all') }</span>
                );
              }
              return (
                <span>{ i18next.t('approvals.flows.someOne') }</span>
              );
            })() }
            <i className="fa fa-caret-down drop-icon fa-right"/>
          </div>
          { this.renderApproverNumSelect(approve.condition, index) }
        </div>
      );
    }
    return (
      <div className="approve-num input-group-addon">
        { (() => {
          if (approve.condition === 'all') {
            return (
              <span>{ i18next.t('approvals.flows.all') }</span>
            );
          }
          return (
            <span>{ i18next.t('approvals.flows.someOne') }</span>
          );
        })() }
      </div>
    );
  }

  renderApproverNumSelect(condition, index) {
    if (condition === 'all') {
      return (
        <ul
          className="dropdown-menu"
          role="menu"
          aria-labelledby={ `num-${this.state.approvalFlow.approvalFlowId ? this.state.approvalFlow.approvalFlowId : this.state.approvalFlow.id}-${index}` }>
          <li>
            <a onClick={ this.selectApproverNum.bind(this, 'all', index) }>
              <i className="fa fa-check"/>
              <span>{ i18next.t('approvals.flows.all') }</span>
            </a>
          </li>
          <li>
            <a onClick={ this.selectApproverNum.bind(this, 'any', index) }>
              <span>{ i18next.t('approvals.flows.someOne') }</span>
            </a>
          </li>
        </ul>
      );
    }
    return (
      <ul className="dropdown-menu" role="menu">
        <li>
          <a onClick={ this.selectApproverNum.bind(this, 'all', index) }>
            <span>{ i18next.t('approvals.flows.all') }</span>
          </a>
        </li>
        <li>
          <a onClick={ this.selectApproverNum.bind(this, 'any', index) }>
            <i className="fa fa-check"/>
            <span>{ i18next.t('approvals.flows.someOne') }</span>
          </a>
        </li>
      </ul>
    );
  }

  renderApprover(approve, approvalIndex) {
    const approvers = approve.approvers.map((it) => it.name).join(", ");

    let approversList = null;
    if (approve.approvers.length) {
      approversList = (<span className="approvers-list">{ approvers }</span>);
    } else if (approve.skippable) {
      approversList = (<span className="approvers-list txt-disabled">{ i18next.t('approvals.flows.empty') }</span>);
    } else {
      approversList = (
        <span className="approvers-list txt-disabled">
          { i18next.t('approvals.flows.empty') }<br/>
          { `※${i18next.t('approvals.flows.notSkippable')}` }
        </span>
      );
    }

    if (approve.editable || this.props.isEdit) {
      return (
        <div className="form-control scroll-form word-wrap txt-pointer" onClick={ this.openAddApprovalModal.bind(this, approvalIndex) }>
          { approversList }
          <i className="fa fa-user-plus primary pull-right"/>
        </div>
      );
    }
    return (
      <div className="form-control scroll-form">
        { approversList }
        <i className="fa fa-lock disabled pull-right lock-icon"/>
      </div>
    );
  }

  render() {
    return (
      <div className="col-sm-12 row-0">
        { this.renderApprovalFlow() }
        <div className="clear">
          <span className="multi-select">
            <i className="fa fa-plus-square accent"/>
            <span>{ `= ${i18next.t('approvals.flows.addStep')}` }</span>
          </span>
          <span>
            <i className="fa fa-user-plus primary"/>
            <span>{ `= ${i18next.t('approvals.flows.addApprover')}` }</span>
          </span>
        </div>
        <div className="txt-disabled txt-sm">
          { `※ ${i18next.t('approvals.messages.stepEmpty')}` }<br/>
          { `※ ${i18next.t('approvals.messages.stepConstraint')}` }<br/>
        </div>
        <AddApproverModal
          ref={ this.addApproverModal }
          displayPost={ this.props.isEdit }
          approvers={ this.state.approvers }
          onClick={ this.onSubmitApprovers.bind(this) }
        />
      </div>
    );
  }
}

ApproverForm.propTypes = {
  approvalFlow: PropTypes.object.isRequired,
  isEdit: PropTypes.bool,
};
