import Clearable from 'components/fields/Clearable';
import FieldWithInputModal from 'components/fields/FieldWithInputModal';
import GenericFieldsItemsSelector from './GenericFieldsItemsSelector';
import React, { FC, ReactNode, useCallback } from "react";
import i18next from 'i18n';
import { Item } from 'utilities/api/models/genericFields/Item';

const formatButton = (items: Item[], isMultipleSelectable): ReactNode => {
  if (isMultipleSelectable) {
    if (items.length) {
      return (
        <span>{ `${items.length} ${i18next.t('genericFields.items.unit')}` }</span>
      );
    }

    return (
      <span>{ i18next.t('commons.status.notEntered') }</span>
    );
  }

  if (items.length) {
    const displayName = (item): string => {
      if (item.isDeleted) return `${item.name} (${i18next.t('genericFields.items.labels.isDeleted')})`;

      return `${item.name}`;
    };

    return (
      <span>{ displayName(items[0]) }</span>
    );
  }

  return (
    <span>{ i18next.t('commons.status.notEntered') }</span>
  );
};

interface Props {
  dataSet,
  disabled,
  isUserCreatableItem,
  isMultipleSelectable,
  label,
  onSelectItems,
}

export const GenericFieldsItemsSelectModal: FC<Props> = ({
  dataSet,
  disabled,
  isUserCreatableItem,
  isMultipleSelectable,
  label,
  onSelectItems,
}) => {
  const selectedItems = dataSet.items;

  const renderField = useCallback(({ className, onClick }) => {
    // 複数選択時
    if (isMultipleSelectable) {
      return (
        <div
          onClick={ onClick }
          className={ `${className} form-control` }
          style={ selectedItems.length === 0 ? { color: '#bdc3c7' } : {} }
        >
          { formatButton(selectedItems, isMultipleSelectable) }
          <i className='caret' style={ { color: '#000' } } />
        </div>
      );
    }

    // 単一選択時
    return (
      <Clearable
        onClick={ onClick }
        className={ `${className} form-control` }
        onClear={ ():void => onSelectItems(null) }
        disabled={ disabled }
        style={ selectedItems.length === 0 ? { color: '#bdc3c7' } : {} }
      >
        { formatButton(selectedItems, isMultipleSelectable) }
        <i className='caret' style={ { color: '#000' } } />
      </Clearable>
    );
  }, [selectedItems, isMultipleSelectable, disabled, onSelectItems]);

  const renderTitle = `${label} ${i18next.t('genericFields.items.titles.selectorTitle')}`;

  const renderModalBody = useCallback(() => {
    return (
      <GenericFieldsItemsSelector
        dataSet={ dataSet }
        isUserCreatableItem={ isUserCreatableItem }
        isMultipleSelectable={ isMultipleSelectable }
        onSelectItems={ onSelectItems }
      />
    );
  }, [dataSet, isUserCreatableItem, isMultipleSelectable, onSelectItems]);

  return (
    <FieldWithInputModal
      className={ 'generic-fields-input' }
      isStatic={ disabled }
      renderField={ renderField }
      renderModalBody={ renderModalBody }
      modalTitle={ renderTitle }
      modalSize='md'
      showCloseButton={ true }
    />
  );
};
