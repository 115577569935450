import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { yiqFromRGB } from 'utilities/Utils';

export default class Tag extends Component {
  get disabledStyle() {
    return {
      color: 'white',
      backgroundColor: '#ccc',
    };
  }

  parseRGB(color) {
    const rgbMatch = color.match(/#([0-9a-fA-F]{6})/i);

    if (!rgbMatch) {
      return [255, 255, 255];
    }

    const rgbStr = rgbMatch[1];
    const r = parseInt(rgbStr.substr(0, 2), 16);
    const g = parseInt(rgbStr.substr(2, 2), 16);
    const b = parseInt(rgbStr.substr(4, 2), 16);

    return [r, g, b];
  }

  calcYIQ(r, g, b) {
    return ((r * 299) + (g * 587) + (b * 114)) / 1000;
  }

  calcTextColor(backgroundColor, yiqSize) {
    const yiq = yiqFromRGB(backgroundColor);

    return yiq >= yiqSize ? '#000000' : '#ffffff';
  }

  render() {
    const {
      className, color, name, height, tagStyle, containerStyle, disabled, yiq
    } = this.props;
    // 背景が白の場合のみ枠線をつける
    const isWhite = yiqFromRGB(color) === 255;
    const isBorder = !disabled && this.props.isBorderForWhite && isWhite;

    const contentStyle = {
      height: `${height}px`,
      borderRadius: `${height / 2}px`,
      backgroundColor: color,
      color: this.calcTextColor(color, yiq),
      padding: `4px ${height / 2}px`,
      border: isBorder ? '0.5px solid #191414' : 'none',
      ...(disabled ? this.disabledStyle : {}),
      ...tagStyle,
    };

    return (
      <div className={ `${className || ''} tag-container` } style={ containerStyle }>
        <span style={ contentStyle }>{ name }</span>
      </div>
    );
  }
}

Tag.defaultProps = {
  className: '',
  height: 24, // pxを指定
  tagStyle: {},
  containerStyle: {},
  disabled: false,
  yiq: 128,
  isBorderForWhite: false,
};

Tag.propTypes = {
  className: PropTypes.string,
  height: PropTypes.number.isRequired,
  tagStyle: PropTypes.object.isRequired,
  containerStyle: PropTypes.object.isRequired,
  name: PropTypes.string.isRequired,
  color: PropTypes.string.isRequired,
  disabled: PropTypes.bool,
  /** 背景色が薄ければ文字を黒くする値 0~255 */
  yiq: PropTypes.number,
  /** 背景が白の場合に枠線を表示する */
  isBorderForWhite: PropTypes.bool,
};
