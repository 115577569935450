import * as ResponsesExpenses from 'utilities/api/responses/expenses';
import Api from 'utilities/api';
import flash from 'utilities/flash';
import i18next from 'i18next';
import { ExportCondition } from './export_condition';
import { fetchAsync } from 'utilities/async';

let isLoading = false;

interface BuildExportCsvApi {
  readonly api: () => Promise<ResponsesExpenses.Reports.CreateResponse>;
  readonly params: {
    readonly exportCondition: ExportCondition;
    readonly exportTargetType: string;
    readonly formatKey: string;
    readonly transactedAt: string;
    readonly userIds: [];
  }
}

const buildExportCsvApi = (params, contacts): BuildExportCsvApi => {
  return {
    api: Api.expenses.reports.reportExportJobs,
    params: {
      ...params,
      userIds: contacts.map((x) => x.id),
    },
  };
};

const isSupportedFormat = (name): boolean => ['accounting', 'journalEntriesForExpenses'].includes(name);

export const handleExportCsv = async (state, reportExportRequestPrams): Promise<void> => {
  if (isLoading) {
    return;
  }

  if (!isSupportedFormat(state.jobName)) {
    flash.error('対応していない出力形式です');
    return;
  }

  try {
    isLoading = true;
    const exportApi = buildExportCsvApi(reportExportRequestPrams, state.contacts);
    await fetchAsync(exportApi.api, exportApi.params);
    flash.success(i18next.t('exports.messages.startGenerateCsv'));
  } catch (e) {
    flash.error(e.message);
    return;
  } finally {
    isLoading = false;
  }
};
