import {
  FetchParams,
  IPreReportExpenseDriver,
  PreReportExpensesResponse,
} from './IPreReportExpenseDriver';

export class PreReportExpenseApi {
  constructor(private driver: IPreReportExpenseDriver) {
    this.driver = driver;
  }

  /** 事前申請に含まれる経費をすべて取得します */
  async fetchAll(apiParams: FetchParams): Promise<PreReportExpensesResponse> {
    const apiResponse = await this.driver.fetchAll(apiParams);
    return apiResponse;
  }
}
