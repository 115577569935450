import * as ActionTypes from '../actions/autoSuggest';

const initialState = {
  total: [],
  current: [],
  fetchStatus: 'finished', // ['ready', 'waiting', 'finished']
  defaultFetchStatus: 'finished', // ['ready', 'waiting', 'finished']
};

const autoSuggest = (state = initialState, action) => {
  switch (action.type) {
    case ActionTypes.SET_FETCH_STATUS:
      return {
        ...state,
        fetchStatus: action.status,
      };
    case ActionTypes.SET_DEFAULT_FETCH_STATUS:
      return {
        ...state,
        defaultFetchStatus: action.status,
      };
    case ActionTypes.SET_TOTAL_SUGGESTIONS:
      return {
        ...state,
        total: action.suggestions,
        current: [...action.suggestions],
      };
    case ActionTypes.SET_CURRENT_SUGGESTIONS:
      return {
        ...state,
        current: action.suggestions,
      };
    case ActionTypes.FILTER_SUGGESTIONS: {
      const { total, current } = state;
      return {
        total,
        current: total.filter(action.filter),
      };
    }
    case ActionTypes.RESET_SUGGESTIONS:
      return {
        ...state,
        current: [...state.total],
      };
    default:
      return state;
  }
};

export default autoSuggest;
