import * as SelectedApproversListActionCreators from 'applications/approval_flows/actions/ApprovalFormSelectedApproversList/ActionCreators';
import * as actions from '../actions/flowFormActions';
import * as approversSearchActions from 'applications/approval_flows/actions/approversSearchActions';
import { ApproverForm } from 'applications/approval_flows/containers/ApproverForm';
import { connect } from 'react-redux';

/**
 * ApproverFormが一画面に複数存在するため、区別できるようなactionを構成する必要がある
 */
export function mapDispatchToProps(dispatch, ownProps) {
  const { reportId } = ownProps;
  return {
    openAddApproverModal(index, approval) {
      // モーダルを開く前に、現在の設定でチェックボックスにチェックを付ける
      dispatch(actions.resetApproversCheck(reportId, approval.approvers));
      dispatch(actions.setEditingApprovalIndex(reportId, index));
      dispatch(SelectedApproversListActionCreators.selectApprovers(approval.approvers));
      dispatch(approversSearchActions.configureApproversUserSearchConditions('searchText', ''));
      dispatch(actions.openAddApproverModal(reportId));
    },
    closeAddApproverModal() {
      dispatch(actions.closeAddApproverModal(reportId));
    },
    insertNewApproval(index, approvals) {
      dispatch(actions.insertNewApproval(reportId, index, approvals));
    },
    removeApproval(index, approvals) {
      dispatch(actions.removeApproval(reportId, index, approvals));
    },
    finishEditingApprovers(index, approvals, approvers) {
      dispatch(actions.setApprovers(reportId, approvals, index, approvers));
      dispatch(actions.closeAddApproverModal(reportId));
    },
    checkApprover(approverType, approvers, approverId, checked) {
      dispatch(actions.checkApprover(reportId, approverType, approvers, approverId, checked));
    },
    onChangeCondition(approvals, index, condition) {
      dispatch(actions.setApprovalCondition(reportId, approvals, index, condition));
    },
    onChangeEditable(approvals, index, editable) {
      dispatch(actions.setApprovalEditable(reportId, approvals, index, editable));
    },
    setApproversUserSearchText(value) {
      dispatch(approversSearchActions.configureApproversUserSearchConditions('searchText', value));
    },
    resetApproversUserSearchText() {
      dispatch(approversSearchActions.configureApproversUserSearchConditions('searchText', ''));
    },
    searchApprovers(value, index) {
      dispatch(approversSearchActions.searchApprovers(value))
        .then(() => dispatch(actions.mergeSearchApprovers(index)));
    },
    selectApprover(approver) {
      dispatch(SelectedApproversListActionCreators.selectApprover(approver));
    },
    deselectApprover(approver) {
      dispatch(SelectedApproversListActionCreators.deselectApprover(approver));
    },
  };
}

export default connect(
  void 0,
  mapDispatchToProps,
)(ApproverForm);
