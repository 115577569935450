import * as ActionTypes from "../actions/requestTypeActions";

const initialState = {
  name: "申請",
};

const requestType = (state = initialState, action) => {
  switch (action.type) {
    case ActionTypes.SET_REQUEST_TYPE:
      return action.requestType;
    default:
      return state;
  }
};

export default requestType;
