import React from 'react';
import { Cell as CellProps, TableCellProps } from 'react-table';
import { Td } from 'components/renewaled_ui/Table/atoms/Td';

interface Props {
  /** セルの一覧 */
  readonly cells: CellProps[];
  /** セルをクリックした */
  // eslint-disable-next-line @typescript-eslint/ban-types
  readonly onClickCell: (id: string, data: object) => void;
  readonly isSimpleDesignMode: boolean;
}

/**
 * セルの一覧
 */
export const Cells: React.FC<Props> = (props: Props) => {
  /**
   * 列毎のボディーのスタイル
   */
  const cellProps = (prop, { cell }): Partial<TableCellProps>[] => {
    return [
      prop,
      {
        style: {
          textAlign: cell.column.dataAlign || 'left',
        },
      },
    ];
  };

  return (
    <>
      {props.cells.map((cell: CellProps) => (
        <Td
          isSimpleDesignMode={ props.isSimpleDesignMode }
          tableCellProps={ cell.getCellProps(cellProps) }
          key={ cell.getCellProps().key }
          onClick={ (): void => props.onClickCell(cell.column.id, cell.row.original) }
          cell={ cell.render('Cell') }
        />
      ))}
    </>
  );
};
