import ApiBase from './api_base';

export default new ApiBase('v1', { relativePath: 'paperless', authPath: '/api/v1/paperless/auth', afterSignOutPath: '/paperless/workers/sign_in' })
  .resources('/receipt_collection_cycles', { only: ['index', 'show', 'create', 'destroy'] })
  .resources('/receipt_boxes', { only: ['index', 'create', 'update', 'destroy'] })
  .resources('/receipt_packages', { only: ['index', 'show', 'create', 'update', 'destroy'] })
  .resources('original_receipts', { only: ['show', 'create', 'update'] })
  .resources('/paperless_plan_subscriptions', { only: ['create', 'update', 'destroy'] })
  .resources('/root_groups', { only: ['index'] })
  .resources('/import_jobs', { only: ['index', 'create'] })
  .get('/import_jobs/check_queue')
  .get('/import_jobs/upload_url')
  .get('/root_groups/paperless_plan_subscriptions')
  .get('original_receipts/next')
  .get('original_receipts/count')
  .get('original_receipts/upload_url')
  .post('original_receipts/{id}/send_back')
  .post('original_receipts/{id}/match')
  .get('expenses/suggest')
  .get('workers_matching_operation_logs/count')
  .build();
