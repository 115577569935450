import i18next from "i18n";
import React from "react";
import RequestStateButton from "./RequestStateButton";
import { Props } from "./Types";

/** 「仮払返金を取り消す」ボタン */
const CancelTemporaryPaymentRefundButton: React.FC<Props> = ({
  requestState,
  onClick,
}) => {
  return (
    <RequestStateButton
      buttonJobType="cancelTemporaryPaymentRefund"
      buttonTitle={i18next.t("commons.actions.cancelTemporaryPaymentRefund")}
      buttonClassName="btn btn-block btn-danger"
      iconClassName="fa fa-left fa-undo"
      requestState={requestState}
      onClick={onClick}
    />
  );
};

export default CancelTemporaryPaymentRefundButton;
