import React from 'react';
import i18next from 'i18n';
import styled from 'styled-components';

interface TdProps {
  readonly isLoading: boolean;
}
const Td = styled.td<TdProps>`
  height: 110px;
  text-align: center;
  background: #fafafa;
  justify-content: center;
  color: #71727c;
`;

interface Props {
  /** ローディング中 */
  readonly isLoading: boolean | undefined;
  /** カラム数 */
  readonly columnLength: number;
}

/**
 * テーブルデータがない場合
 */
export const TableNoData: React.FC<Props> = (props) => {
  return (
    <tr>
      <Td
        className='table-component-no-data-td'
        colSpan={ props.columnLength }
        isLoading={ props.isLoading || false }
        style={ { opacity: props.isLoading ? 0 : 1 } }
      >
        { i18next.t('commons.messages.noDataFound') }
      </Td>
    </tr>
  );
};
