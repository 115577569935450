import { Button } from "components/renewaled_ui/buttons";
import i18next from "i18n";
import React from "react";

interface Props {
  disabled: boolean;
  onClick: () => void;
}

/** 分割ボタン */
export const SplitButton: React.FC<Props> = ({ disabled, onClick }) => {
  return (
    <Button onClick={onClick} styleType="primary" disabled={disabled}>
      {i18next.t("commons.actions.split")}
    </Button>
  );
};
