import * as ActionCreators from '../actions/ActionCreators';
import ActionTypes from '../actions/ActionTypes';
import { CreatorsToActions } from "types/UtilityTypes";
import { State, initialState } from '../actions/Types';

type Actions = CreatorsToActions<typeof ActionCreators>;

const samlSettings = (state: State = initialState, action: Actions): State => {
  switch (action.type) {
    case ActionTypes.SET_IN_PROGRESS: {
      return { ...state, inProgress: action.payload.inProgress };
    }
    case ActionTypes.SET_SAML_SETTINGS: {
      return { ...state, samlSettings: action.payload.samlSettings };
    }
    case ActionTypes.SET_SAML_SETTING_DETAIL: {
      return { ...state, samlSettingDetail: action.payload.samlSettingDetail };
    }
    case ActionTypes.TOGGLE_NEW_SAML_SETTING_MODAL: {
      return { ...state, showNewSamlSettingModal: action.payload.show };
    }
    case ActionTypes.TOGGLE_UPDATE_SAML_SETTING_MODAL: {
      return { ...state, showUpdateSamlSettingModal: action.payload.show };
    }
    case ActionTypes.TOGGLE_DESTROY_SAML_SETTING_MODAL: {
      return { ...state, showDestroySamlSettingModal: action.payload.show };
    }
    case ActionTypes.TOGGLE_CREATE_UPDATE_IDP_SETTING_MODAL: {
      return { ...state, showCreateUpdateIdPModal: action.payload.show };
    }
    case ActionTypes.SET_IP_RESTRICTION_ENABLE: {
      return { ...state, ipRestrictionEnable: action.payload.enable };
    }
    default: {
      return state;
    }
  }
};

export default samlSettings;
