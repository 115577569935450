import i18next from "i18n";
import _get from "lodash/get";
import PropTypes from "prop-types";
import React, { Component } from "react";
import { BootstrapTable, TableHeaderColumn } from "react-bootstrap-table";
import { empty } from "utilities/formatter";
import ExpenseLog from "./history/ExpenseLog";
import { SplitEventLog } from "./history/SplitEventLog";

export default class ExpenseHistoryTable extends Component {
  constructor(props) {
    super(props);

    this.getTransactionId = this.getTransactionId.bind(this);
    this.getHistoryId = this.getHistoryId.bind(this);
    this.formatChangeLog = this.formatChangeLog.bind(this);
  }

  get histories() {
    return this.props.histories.map((x, idx) => {
      return {
        ...x,
        index: idx + 1,
        id: this.getHistoryId(x),
      };
    });
  }

  getTransactionId(history) {
    return history.transactionId || _get(history, "transaction.id");
  }

  getHistoryId(history) {
    const { type, changedAt, index } = history;
    // index は本来不要なものの、過去データの都合で必要
    return `${this.getTransactionId(history)}-${changedAt}-${type}-${index}`;
  }

  formatType(type) {
    switch (type) {
      case "add": {
        return (
          <span className="color-primary">
            {i18next.t("transactions.histories.types.add")}
          </span>
        );
      }
      case "edit": {
        return (
          <span className="color-success">
            {i18next.t("transactions.histories.types.edit")}
          </span>
        );
      }
      case "delete": {
        return (
          <span className="color-warning">
            {i18next.t("transactions.histories.types.delete")}
          </span>
        );
      }
      case "split": {
        return (
          <span className="color-success">
            {i18next.t("transactions.histories.types.split")}
          </span>
        );
      }
      default:
        return "-";
    }
  }

  formatChangeAtAndEditor(_, row) {
    const changedAt = this.formatChangedAt(row.changedAt);
    const editor = this.formatEditor(row.editor);

    return (
      <>
        {changedAt}
        <br />
        {editor}
      </>
    );
  }

  formatChangedAt(date) {
    return new Date(date).toLocaleString();
  }

  formatEditor(editor, row) {
    if (editor.type === "system") {
      return empty(i18next.t("transactions.histories.editorTypes.system"));
    }

    const agentUserName = (editor.agent && editor.agent.name) || "";
    const userName = agentUserName
      ? `${editor.name}(${agentUserName})`
      : editor.name;

    return <span>{userName}</span>;
  }

  formatChangeLog(changes, history) {
    if (!["add", "delete", "edit", "split"].includes(history.type)) {
      return null;
    }

    if (history.type === "split") {
      return (
        <SplitEventLog
          splitEvent={history}
          onExpenseClick={this.props.onExpenseClick}
        />
      );
    }

    return (
      <ExpenseLog
        type={history.type}
        expense={history.transaction}
        changes={changes}
      />
    );
  }

  render() {
    return (
      <BootstrapTable
        tableBodyClass="expense-history-table"
        data={this.histories}
        striped={true}
        hover={true}
        options={this.props.options}
      >
        <TableHeaderColumn dataField="id" hidden={true} isKey={true} />
        <TableHeaderColumn dataField="index" width="50">
          #
        </TableHeaderColumn>
        <TableHeaderColumn
          dataField="type"
          dataFormat={this.formatType}
          width="70"
        />
        <TableHeaderColumn
          dataFormat={this.formatChangeAtAndEditor.bind(this)}
          width="170"
        >
          {i18next.t("transactions.histories.properties.changedAt")} /{" "}
          {i18next.t("transactions.histories.properties.editor")}
        </TableHeaderColumn>
        <TableHeaderColumn
          className="txt-pointer"
          dataField="changes"
          dataFormat={this.formatChangeLog}
        >
          {i18next.t("transactions.histories.properties.changeLog")}
        </TableHeaderColumn>
      </BootstrapTable>
    );
  }
}

ExpenseHistoryTable.defaultProps = {
  histories: [],
  options: {},
  onExpenseClick: () => {},
};

ExpenseHistoryTable.propTypes = {
  histories: PropTypes.array.isRequired,
  options: PropTypes.object,
  onExpenseClick: PropTypes.func,
};
