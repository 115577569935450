import React from 'react';
import i18next from 'i18n';
import styled from 'styled-components';
import { ApprovalStep, ApprovalStepsForReject, RequestStep } from 'utilities/api/models/ApprovalStepsForReject';
import { ControlLabel, FormGroup } from 'react-bootstrap';
import { RejectToDetail } from './RejectToDetail';
import { RejectToField } from './RejectToField';

const FormView = styled.div`
  display: flex;
  justify-content: center;
`;

const RejectDetailView = styled.div`
  display: flex;
  justify-content: start;
  align-items: start;
`;

const SelectedStepTypeView = styled.span`
  color: #737373;
  margin-top: 5px;
  margin-right: 5px;
  min-width: fit-content;
`;

interface Props {
  steps: ApprovalStepsForReject | null;
  comment: string;
  selectedStep: RequestStep | ApprovalStep | null;
  onChangeComment: (comment: string) => void;
  onSelectStep: (step: RequestStep | ApprovalStep) => void;
}

/**
 * 差戻フォーム
 */
export const RejectForm: React.FC<Props> = ({
  steps,
  comment,
  selectedStep,
  onChangeComment,
  onSelectStep,
}) => {
  return (
    <>
      <FormView>
        <div className="col-sm-6">
          {
            (steps && selectedStep) && (
              <FormGroup>
                <ControlLabel>差し戻し先</ControlLabel>
                <RejectToField selectedStep={ selectedStep } onSelectStep={ onSelectStep } steps={ steps } />
                <RejectDetailView>
                  { ('type' in selectedStep) && <SelectedStepTypeView>{ selectedStep.type === 'all' ? i18next.t('approvals.flows.all') : i18next.t('approvals.flows.someOne') }:</SelectedStepTypeView> }
                  <RejectToDetail selectedStep={ selectedStep } />
                </RejectDetailView>
              </FormGroup>
            )
          }
          <FormGroup>
            <ControlLabel>コメント</ControlLabel>
            <input
              type='text'
              className='form-control'
              value={ comment }
              onChange={ (e): void => onChangeComment(e.target.value) }
            />
          </FormGroup>
        </div>
      </FormView>
    </>
  );
};
