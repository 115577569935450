import _isNil from 'lodash/isNil';
import { RequiredTableDataProps } from 'components/renewaled_ui/Table/interface/table';
import { useCallback, useEffect, useState } from 'react';

interface UseReturn<Data> {
  readonly isBackgroundLoading: boolean;
  readonly paginationSize: number;
  readonly selectedItemsLength: number;
  readonly currentPageData: Data[];
  readonly handleSortChange: (sortName: string, sortOrder: 'ASC' | 'DESC') => void;
  readonly handlePageChange:(page: number, _sizePerPage?: number) => void;
  readonly isShowPagination: () => boolean;
}

/**
 * DataFetchingTable ロジック
 */
export const useHooks = <Data extends RequiredTableDataProps>(
  data: (Data | undefined)[],
  currentPage: number,
  sizePerPage: number,
  onPageChange: (page: number) => void,
  isLoading?: boolean,
  onSortChange?: (sortName: string, sortOrder: 'ASC' | 'DESC') => Promise<void>,
  fetchData?: (startIndex: number, sizePerPage: number, background: boolean, sortName?: string, sortOrder?: 'ASC' | 'DESC',) => Promise<void>,
): UseReturn<Data> => {
  const [isBackgroundLoading, setIsBackgroundLoading] = useState<boolean>(false);

  /**
   * 表示件数
   */
  const selectedItemsLength: number = data.filter((d) => d && d.isSelected).length;

  /**
   * ページネーションサイズ
   */
  const paginationSize: number =  Math.ceil(data.length / sizePerPage);

  /**
   * キャッシュ（reduxのstate）に保存された現在のページの表示データの配列
   */
  const getDataOnPage = useCallback((
    _data,
    page: number,
    _sizePerPage: number,
  ) => {
    const startIndex = (page - 1) * _sizePerPage;
    return _data.slice(startIndex, startIndex + _sizePerPage);
  }, []);

  /**
   * 現在のページのデータ一覧
   */
  const currentPageData: Data[] = getDataOnPage(data, currentPage, sizePerPage).filter((x) => x) as Data[];

  /**
   * ページネーションを表示させるか
   */
  const isShowPagination = (): boolean => {
    if (!paginationSize) return false;
    if (!currentPage) return false;
    if (!sizePerPage) return false;

    // ページサイズが2以上でないと表示させない
    return paginationSize >= 2;
  };

  /**
   * 引数で指定したページのデータをフェッチしてキャッシュする。
   * すでにキャッシュがある場合はフェッチしない。
   */
  const cacheDataOnPage = useCallback((
    page: number,
    _sizePerPage: number,
    background: boolean,
    _sortName?: string,
    _sortOrder?: 'ASC' | 'DESC',
  ): Promise<void> => {
    // データ取得をしない場合は何もしない
    if (fetchData === undefined) return Promise.resolve();

    const dataOnPage = getDataOnPage(data, page, _sizePerPage);

    // まだデータ取得していない場合は、データ取得する
    // 配列にundefinedが一つでもあれば実行する
    if (dataOnPage.some((x) => _isNil(x))) {
      // 先頭のデータ番号
      const startIndex = (page - 1) * _sizePerPage;

      if (background) setIsBackgroundLoading(true);

      return fetchData(startIndex, _sizePerPage, background, _sortName, _sortOrder)
        .then(() => { void 0; })
        .catch((e) => {
          return Promise.reject(e);
        })
        .finally(() => {
          setIsBackgroundLoading(false);
        });
    }

    return Promise.resolve();
  }, [data, fetchData, getDataOnPage]);

  /**
   * 前後のページのデータをキャッシュする
   */
  const cacheBackground = async (
    _currentPage: number,
    _sizePerPage: number,
    _sortName?: string,
    _sortOrder?: 'ASC' | 'DESC',
  ): Promise<void> => {
    await Promise.all([
      cacheDataOnPage(_currentPage - 1, _sizePerPage, true, _sortName, _sortOrder),
      cacheDataOnPage(_currentPage + 1, _sizePerPage, true, _sortName, _sortOrder),
    ]);
  };

  /**
   * ページ移動を押された
   */
  const handlePageChange = (
    page: number,
    _sizePerPage: number = sizePerPage,
  ): void => {
    // データロード中は、ページ移動をさせない
    if (isLoading) return;

    // 必要なデータを取得してから、表示ページを切り替える
    // 切り替え後に、前後のページをキャッシュしておく
    cacheDataOnPage(page, _sizePerPage, false)
      .then(() => {
        onPageChange(page);
        return Promise.resolve();
      })
      .then(() => {
        cacheBackground(page, _sizePerPage);
      });
  };

  /**
   * ソートが押された
   */
  const handleSortChange = (sortName: string, sortOrder: 'ASC' | 'DESC'): void => {
    if (!onSortChange) return;

    onSortChange(sortName, sortOrder)
      .then(() => {
        cacheBackground(currentPage, sizePerPage, sortName, sortOrder);
      })
      .catch((e) => {
        return Promise.reject(e);
      });
  };

  /**
   * 1ページの表示件数を変更した際、フェッチが必要であれば行う
   */
  useEffect(() => {
    cacheDataOnPage(currentPage, sizePerPage, false);
    // sizePerPage以外の変更時のcacheDataOnPageは別途行われるためsizePerPageのみ含める
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sizePerPage]);

  /**
   * 1ページの表示件数を増やした際、ページ数が減り現在ページの件数が0になった場合、最終ページに移動
   */
  useEffect(() => {
    // 現ページが最後のページを超えている場合
    if (paginationSize && currentPage > paginationSize) {
      // 最後のページへ移動する
      onPageChange(paginationSize);
    }
  }, [currentPage, onPageChange, paginationSize]);

  return {
    isBackgroundLoading,
    handleSortChange,
    paginationSize,
    handlePageChange,
    selectedItemsLength,
    currentPageData,
    isShowPagination,
  };
};
