import { ListForm } from "components/renewaled_ui/form_views";
import React from "react";

/** 行あるいは列といった要素を表す型 */
interface FactorType {
  id: string;
  name: string;
  sort: number;
}

/** オプション */
interface FactorOption {
  /**
   * 利用可能か否か
   * @deprecated 過去に契約のあった KLINE 様専用に追加されているプロパティ。既に意味を持っていないため非推奨。
   */
  available: boolean;
  /** オプション UUID */
  id: string;
  /** オプション名 */
  name: string;
  /** オプションの並び順 */
  sort: number;
  /** オプションが属している要素 */
  type: FactorType;
}

/** 行あるいは列といった要素の持つプロパティ */
interface FactorProperty {
  /**
   * デフォルト値として使用するオプション
   * @note 使用できるか否か加味されていないため、事前に options に含まれているか要確認
   */
  defaultOption: FactorOption;
  /** factorType の UUID */
  id: string;
  /** factorType の 名前 */
  name: string;
  /** factorType で 使用できるオプション */
  options: Array<FactorOption>;
  /** factorType の 並び順 */
  sort: number;
}

interface Props {
  /** 行あるいは列といった要素の持つプロパティ */
  factorType: FactorProperty;
  /** 選択されているオプションのID */
  selectedFactorId: string;
  /** オプション変更時に実行するメソッド */
  onChange: (evt: React.ChangeEvent<HTMLSelectElement>, id: string) => void;
}

/**
 * 日当・手当表のセルを特定する際に使用する、行・列などの値のセレクトボックスを表示する
 */
export const FactorTypeSelector = ({
  factorType,
  selectedFactorId,
  onChange,
}: Props): React.ReactElement => {
  return (
    <ListForm label={factorType.name} required>
      <select
        className="form-control"
        onChange={(e: React.ChangeEvent<HTMLSelectElement>): void => {
          onChange(e, factorType.id);
        }}
        value={selectedFactorId}
      >
        {factorType.options.map((option, index) => (
          <option key={`factor-${index}`} value={option.id}>
            {option.name}
          </option>
        ))}
      </select>
    </ListForm>
  );
};
