import { MenuItem } from "components/renewaled_ui/Dropdown";
import i18next from "i18n";
import React from "react";
import styled from "styled-components";
import colors from "values/colors";
import * as Types from "../../types";

interface Props {
  /** 選択時のハンドラ */
  onSelect: (separatorType: Types.SeparatorType) => void;
}

interface SeparatorTypeOption {
  /** 経費分割項目 */
  value: Types.SeparatorType;
  /** 経費分割項目のテキスト */
  label: string;
}

const DropdownView = styled.div`
  position: relative;
  .dropdown-toggle,
  .dropdown-item > a {
    color: ${colors.renewaled.textBlack};
    text-decoration: none;
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 100%;
    padding: 10px 20px;
    box-shadow: none !important;
    &:hover {
      background-color: ${colors.renewaled.whiteHover};
    }
  }
  .create-menu-item-link:focus,
  .dropdown-item > a:focus {
    transition: 100ms;
    color: ${colors.renewaled.main};
    background-color: ${colors.renewaled.whiteHoverActive};
    font-weight: 600;
  }
  // hoverでDropdownを表示するようにする
  &:hover > .dropdown-menu {
    display: block;
  }
  .dropdown-menu {
    position: absolute;
    bottom: -8px;
    top: auto;
    left: 140px;
    width: 100px;
    padding: 4px 0;
    border: none;
  }
  .dropdown-item > a {
    border-radius: 0;
    display: block;
  }
`;

/** 経費分割開始ドロップダウンボタン */
export const SplitStartDropdownButton: React.FC<Props> = ({ onSelect }) => {
  // プロジェクト機能を使用しない設定の場合「プロジェクトで分割」は非表示
  const canSplitByProject = userPreferences.preference.inputProject;
  const options: SeparatorTypeOption[] = canSplitByProject
    ? [
        {
          value: "category",
          label: i18next.t("transactions.properties.category"),
        },
        {
          value: "project",
          label: i18next.t("transactions.properties.project"),
        },
      ]
    : [
        {
          value: "category",
          label: i18next.t("transactions.properties.category"),
        },
      ];

  return (
    <DropdownView className="split-start-dropdown-button">
      <a href="#" className="dropdown-toggle" data-toggle="dropdown">
        {i18next.t("commons.actions.split")}
        <i className="fas fa-chevron-right" />
      </a>
      <ul className="dropdown-menu">
        {options.map((o) => {
          return (
            <MenuItem key={o.value} onClick={(): void => onSelect(o.value)}>
              {i18next.t("expenses.split.dropdownButtonItem", {
                item: o.label,
              })}
            </MenuItem>
          );
        })}
      </ul>
    </DropdownView>
  );
};
