import Api from 'utilities/api';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import flash from 'utilities/flash';
import i18next from 'i18n';
import { camelizeKeys } from 'utilities/Utils';

export default class ImportFormatSelect extends Component {
  constructor(props) {
    super(props);
    this.state = {
      importers: [],
      format: this.props.defaultImportFormat || '',
    };
  }

  componentDidMount() {
    this.loadImporters();
  }

  onFormatSelect() {
    if (this.props.onFormatSelect) {
      this.props.onFormatSelect(this.refs.format.value);
    }
  }

  loadImporters() {
    Api.importers({ type: this.props.type })
      .fail(this.onError.bind(this))
      .done((data) => {
        const receivedData = camelizeKeys(data);
        this.setState({
          importers: receivedData.importers,
        });
      });
  }

  onError(xhr) {
    flash.error(i18next.t('commons.errors.communicationError'));
  }

  render() {
    return (
      <div className="import-format-select">
        <label className='col-sm-3 control-label'>
          { i18next.t('exports.inputs.selectFormat') }
        </label>
        <div className='col-sm-6'>
          { this.importerSelect() }
        </div>
      </div>
    );
  }

  importerSelect() {
    return (
      <select ref='format' className='form-control'
        onChange={ this.onFormatSelect.bind(this) }
        value = { this.props.defaultImportFormat } >
        {
          this.state.importers.map((importer) => (
            <option key={ importer.key } value={ importer.key }>{ importer.value }</option>
          ))
        }
      </select>
    );
  }
}

ImportFormatSelect.propTypes = {
  type: PropTypes.string.isRequired,
  defaultImportFormat: PropTypes.string,
  onFormatSelect: PropTypes.func,
};
