import i18next from "i18n";
import React from "react";
import { Button } from "react-bootstrap";

interface Props {
  /** クリック時のハンドラ */
  readonly onClick: () => void;
}

/**
 * 閉じるボタン
 */
export const CloseButton: React.FC<Props> = ({ onClick }) => {
  return (
    <Button onClick={onClick}>{i18next.t("commons.actions.close")}</Button>
  );
};
