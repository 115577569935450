import LoadingOverlay from "components/LoadingOverlay";
import i18next from "i18n";
import includes from "lodash/includes";
import React, { PureComponent } from "react";
import { BootstrapTable, TableHeaderColumn } from "react-bootstrap-table";
import Formatter from "utilities/formatter";
import { StateAnalysisPreReport } from "../../analysis_details/interfaces/state/analysis";

interface Props {
  readonly reports: StateAnalysisPreReport[];
  readonly only: string[];
  readonly except: string[];
  readonly onRowClick: (report: StateAnalysisPreReport) => void;
  readonly onRowSelect: () => void;
  readonly onAllRowSelect: () => void;
  readonly openExportModal: () => void;
}

/**
 * TK集計詳細ページ: 旅費申請一覧テーブル
 */
export default class TargetPreTravelReportTable extends PureComponent<Props> {
  public static defaultProps = {
    reports: [],
  };

  /**
   * 精算状況の欄
   */
  formatStatus(
    status: string,
    row: StateAnalysisPreReport,
  ): JSX.Element | null {
    const statusObj = {
      status,
      color: row.statusColor,
      label: status,
    };
    return Formatter.requestStatus(statusObj);
  }

  /**
   * cssクラスを付加する
   */
  trClassFormat(rowData: StateAnalysisPreReport): string {
    switch (rowData.status) {
      case i18next.t("commons.status.unsubmitted"): {
        return "row-created";
      }
      case i18next.t("commons.status.recalled"): {
        return "row-recalled";
      }
      case i18next.t("commons.status.applying"): {
        return "row-applying";
      }
      case i18next.t("commons.status.reapplying"): {
        return "row-reapplying";
      }
      case i18next.t("commons.status.rejected"): {
        return "row-rejected";
      }
      case i18next.t("commons.status.approved"): {
        return "row-approved";
      }
      case i18next.t("commons.status.closed"): {
        return "row-closed";
      }
      default:
        return "";
    }
  }

  render(): JSX.Element {
    const { reports } = this.props;

    const options = {
      onRowClick: this.props.onRowClick.bind(this),
      noDataText: i18next.t("commons.messages.noDataFound"),
    };

    const selectRowOptions = this.display("check")
      ? {
          mode: "checkbox",
          clickToSelect: true,
          selected: reports
            .filter((report) => report.isChecked)
            .map((report) => report.id),
          onSelect: this.props.onRowSelect.bind(this),
          onSelectAll: this.props.onAllRowSelect.bind(this),
        }
      : {};

    return (
      <LoadingOverlay iconClassName={"fa fa-spinner fa-spin fa-fw fa-3x"}>
        <BootstrapTable
          className="reports-table"
          trClassName={this.trClassFormat.bind(this)}
          data={reports}
          options={options}
          selectRow={selectRowOptions}
        >
          <TableHeaderColumn hidden={true} dataField="id" isKey={true}>
            ID
          </TableHeaderColumn>
          <TableHeaderColumn
            hidden={!this.display("title")}
            dataFormat={(v): (JSX.Element | string) | null => Formatter.text(v)}
            dataField="title"
            width="200"
          >
            {i18next.t("analyses.titles.preTravelReportTitle")}
          </TableHeaderColumn>
          <TableHeaderColumn
            hidden={!this.display("sequence")}
            dataFormat={(v): (JSX.Element | string) | null => Formatter.text(v)}
            dataField="sequence"
            width="120"
          >
            {i18next.t("analyses.titles.sequence")}
          </TableHeaderColumn>
          <TableHeaderColumn
            hidden={!this.display("userName")}
            dataField="userName"
            width="120"
          >
            {i18next.t("analyses.titles.userName")}
          </TableHeaderColumn>
          <TableHeaderColumn
            hidden={!this.display("applicantGroup")}
            dataField="userGroup"
            width="200"
          >
            {i18next.t("analyses.titles.applicantGroup")}
          </TableHeaderColumn>
          <TableHeaderColumn
            hidden={!this.display("term")}
            dataFormat={(cell, row): (JSX.Element | string) | null =>
              Formatter.term(row.startAt, row.endAt)
            }
            dataAlign="left"
            width="185"
          >
            期間
          </TableHeaderColumn>
          <TableHeaderColumn
            hidden={!this.display("destination")}
            dataField="destination"
            dataFormat={(v): (JSX.Element | string) | null => Formatter.text(v)}
            dataAlign="left"
            width="120"
          >
            目的地
          </TableHeaderColumn>
          <TableHeaderColumn
            hidden={!this.display("amount")}
            dataField="amount"
            dataFormat={(v): (JSX.Element | string) | null =>
              Formatter.amount(v)
            }
            dataAlign="right"
            width="120"
          >
            {i18next.t("analyses.titles.amount")}
          </TableHeaderColumn>
          <TableHeaderColumn
            hidden={!this.display("status")}
            dataField="status"
            dataFormat={this.formatStatus.bind(this)}
            width="130"
          >
            {i18next.t("analyses.titles.payOffStatus")}
          </TableHeaderColumn>
        </BootstrapTable>
      </LoadingOverlay>
    );
  }

  /**
   * カラムを表示するかどうか
   */
  display(column: string): boolean {
    let isVisible = true;
    if (this.props.only) {
      isVisible = includes(this.props.only, column);
    }
    if (this.props.except) {
      isVisible = !includes(this.props.except, column);
    }
    return isVisible;
  }
}
