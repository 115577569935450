import Api from 'utilities/api';
import LabeledComponent from 'components/LabeledComponent';
import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';
import TextField from 'components/fields/TextField';
import i18next from 'i18n';
import { fetchAsync } from 'utilities/async';

class SimpleProjectCreateForm extends PureComponent {
  constructor(props) {
    super(props);

    this.handleIdChange = this.handleIdChange.bind(this);
    this.handleNameChange = this.handleNameChange.bind(this);
    this.submit = this.submit.bind(this);

    this.state = { inProcess: false };
  }

  handleIdChange(displayId) {
    this.props.onChange({ displayId });
  }

  handleNameChange(name) {
    this.props.onChange({ name });
  }

  lockButton() {
    this.setState({ inProcess: true });
  }

  unlockButton() {
    this.setState({ inProcess: false });
  }

  validate() {
    if (!this._form) {
      return false;
    }

    return $(this._form).validationEngine('validate');
  }

  create(name, displayId) {
    const params = { name, displayId };

    return fetchAsync(Api.projects.create, params);
  }

  async submit() {
    if (!this.validate()) {
      return;
    }

    try {
      this.lockButton();

      const result = await this.create(this.props.name, this.props.displayId);
      this.props.onCreated(result);
    } finally {
      this.unlockButton();
    }
  }

  render() {
    return (
      <form ref={ (form) => { this._form = form; } }
        className='project-inline-form'
      >
        <LabeledComponent label={ i18next.t('projects.properties.name') }>
          <TextField value={ this.props.name }
            onValueChange={ this.handleNameChange }
          />
        </LabeledComponent>

        <LabeledComponent label={ i18next.t('projects.properties.id') }>
          <TextField className='form-control validate[required]'
            value={ this.props.displayId }
            onValueChange={ this.handleIdChange }
          />
        </LabeledComponent>

        <button className='btn btn-accent'
          type='button'
          onClick={ this.submit }
          disabled={ this.state.inProcess }
        >
          { i18next.t('projects.actions.addProject') }
        </button>
      </form>
    );
  }
}

SimpleProjectCreateForm.defaultProps = {
  name: '',
  displayId: '',
  /**
  * @params {string} key - 'name' or 'displayId'
  * @params {string} value
  */
  onChange(key, value) {},
  onCreated(result) {},
};

SimpleProjectCreateForm.propTypes = {
  name: PropTypes.string.isRequired,
  displayId: PropTypes.string.isRequired,
  onChange: PropTypes.func,
  onCreated: PropTypes.func,
};

export default SimpleProjectCreateForm;
