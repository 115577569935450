import { fetchAsync } from "actions/AsyncAction";
import { AnyAction } from "redux";
import Api from "utilities/api";

const prefix = `pre_report/suggestions`;

export const SET_PROJECTS = `${prefix}/SET_PROJECTS`;
function setProjects(projects): AnyAction {
  return {
    type: SET_PROJECTS,
    payload: { projects },
  };
}

export function initProjects(): (dispatch) => Promise<void> {
  return async (dispatch): Promise<void> => {
    const data = await dispatch(
      fetchAsync(Api.projects.index, { offset: 0, limit: 20 }),
    );

    dispatch(setProjects(data.projects));
  };
}
