import { errorMessages, passwordErrorMessage } from './validatePassword';

export interface FormValues {
  password: string;
  passwordConfirmation: string;
}

export interface FormErrors {
  password?: string;
  passwordConfirmation?: string;
  submit?: string;
}

export const validatePasswordResetForm = (values: FormValues): FormErrors => {
  const errors: FormErrors = {};

  // passwordの単体バリデーション
  const passwordError = passwordErrorMessage(values.password);
  if (passwordError) {
    errors.password = passwordError;
    return errors;
  }

  // passwordとpasswordConfirmationの一致バリデーション
  if (values.password !== values.passwordConfirmation) {
    errors.passwordConfirmation = errorMessages.notMatch;
  }

  return errors;
};
