import React, { useEffect, useRef, useState } from 'react';
import styled from 'styled-components';
import { AcceptDocuments } from './AcceptDocuments';
import { ErrorMessage } from './ErrorMessage';
import { FormErrors, validateInvitationForm } from '../utilities/validateInvitationForm';
import { FormSubmitButton } from './FormSubmitButton';

// Style
const AcceptInvitationFormView = styled.div`
margin-bottom: 16px;
p {
  font-size: 1.2rem;
  margin-bottom: 4px;
}
.form-group {
  margin-bottom: 16px;
}
.form-control {
  border-radius: 10px;
  box-shadow: none;
  padding: 19px 16px;
  font-size: 1.3rem;
  &:focus {
    box-shadow: none;
  }
}
`;

interface Props {
  invitationToken: string;
  onSubmit: (form: HTMLFormElement | null) => void;
}

export const AcceptInvitationForm: React.FC<Props> = (props) => {
  const [password, setPassword] = useState<string>('');
  const [errors, setErrors] = useState<FormErrors>({});
  const [touched, setTouched] = useState<boolean>(false);
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
  const formElm = useRef<HTMLFormElement>(null);

  const handleClick = (e: React.MouseEvent<HTMLButtonElement>): void => {
    e.preventDefault();
    setTouched(true);
    if (!errors.password) {
      setIsSubmitting(true);
      props.onSubmit(formElm.current);
    }
  };

  useEffect(() => {
    setErrors(validateInvitationForm({ password }));
  }, [password]);

  return (
    <AcceptInvitationFormView>
      <form
        id="accept_invitation_form"
        ref= { formElm }
        acceptCharset='UTF-8'
        method='post'
        action='/users/invitation' >
        <div className='form-group'>
          <p>パスワードを設定してください。(8文字以上)</p>
          <input
            type='password'
            name='user[password]'
            className='form-control'
            autoComplete='off'
            placeholder='パスワード'
            value={ password }
            onChange={ (e): void => setPassword(e.target.value) } />
          { touched && errors.password && <ErrorMessage message={ errors.password } /> }
          <input
            type="hidden"
            value="put"
            name="_method"/>
          <input
            type="hidden"
            value={ props.invitationToken }
            name="user[invitation_token]"
            id="user_invitation_token" />
        </div>
        <AcceptDocuments />
        <FormSubmitButton
          isSubmitting={ isSubmitting }
          onClick={ handleClick }>
          承認して登録
        </FormSubmitButton>
      </form>
    </AcceptInvitationFormView>
  );
};
