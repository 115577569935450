import ViewerSettingStorage from "components/renewaled_ui/FileViewer/hooks/ViewerSettingStorage";
import PDFToggleButton from "components/renewaled_ui/FileViewer/Viewer/BrowserPDF/parts/ToggleButton";
import ViewerChangeButton from "components/renewaled_ui/FileViewer/Viewer/BrowserPDF/parts/ViewerChangeButton";
import React, { useState } from "react";
import { DropzoneInputProps, DropzoneRootProps } from "react-dropzone";
import styled from "styled-components";

const Spacer = styled.div`
  width: 10px;
`;
const RightSpace = styled.div`
  width: 25px;
`;
const WrapperOuter = styled.div`
  color: #ffffffe0;
`;
const Wrapper = styled.div`
  overflow: hidden;
  height: 35px;
`;
const BarHeight = styled.div`
  height: 35px;
`;
const MainBar = styled.div`
  position: absolute;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 35px;
  padding: 0 10px;
  background: #2e2e38;
  transition: 0.2s;
`;
const Left = styled.div`
  display: flex;
  align-items: center;
  width: 50%;
`;
const Right = styled.div`
  display: flex;
  justify-content: end;
  align-items: center;
  height: 100%;
  width: 50%;
`;
const UploadButton = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  background: #294690;
  padding: 5px 10px;
  border-radius: 4px;
  transition: 0.2s;
  cursor: pointer;

  &:hover {
    opacity: 0.8;
  }
`;
const DownloadButton = styled.a`
  display: flex;
  justify-content: center;
  align-items: center;
  background: #294690;
  color: #ffffffe0;
  padding: 5px 10px;
  border-radius: 4px;
  transition: 0.2s;
  cursor: pointer;

  &:hover {
    color: #ffffffe0;
    opacity: 0.8;
  }
  &:focus {
    color: #ffffffe0;
    opacity: 0.8;
  }
`;
const DeleteButton = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  background: #b63939;
  padding: 5px 10px;
  border-radius: 4px;
  transition: 0.2s;
  cursor: pointer;

  &:hover {
    opacity: 0.8;
  }
`;
const FileName = styled.div`
  font-size: 12px;
  width: 120px;
  opacity: 0.5;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;
const RightButton = styled.div`
  position: absolute;
  right: 8px;
  top: 8px;
`;

interface Props {
  /** 現在のページ */
  readonly rootProps: DropzoneRootProps;
  /** ファイルinput */
  readonly getInputProps: (
    props?: DropzoneInputProps | undefined,
  ) => DropzoneInputProps;
  /** ダウンロードリンク */
  readonly downloadLink: string;
  /** ダウンロードファイル名 */
  readonly fileName: string;
  /** アップロード可能か */
  readonly canUpload: boolean;
  /** ダウンロード可能か */
  readonly canDownload: boolean;
  /** 削除可能か */
  readonly canDelete: boolean;
  /** 削除を押した */
  readonly onClickDelete: (
    e: React.MouseEvent<HTMLDivElement, MouseEvent>,
  ) => void;
  /** 切り替えを押した */
  readonly onClickChangeViewer: (
    e: React.MouseEvent<HTMLDivElement, MouseEvent>,
  ) => void;
}

/**
 * ブラウザ版PDFビューアーのツールバー
 */
const BrowserPDFToolBar: React.FC<Props> = (p) => {
  const [isHideBar, setIsHideBar] = useState<boolean>(
    ViewerSettingStorage.data.isHideToolBar,
  );

  /**
   * バーを隠すトグルボタン
   */
  const onClickHide = (): void => {
    setIsHideBar(!isHideBar);
    ViewerSettingStorage.set({
      ...ViewerSettingStorage.data,
      isHideToolBar: !isHideBar,
    });
  };

  return (
    <WrapperOuter className="viewer-browser-pdf-toolbar-wrapper-outer">
      <Wrapper
        className="viewer-browser-pdf-toolbar-wrapper"
        style={{ height: isHideBar ? "0" : "35px" }}
      >
        <MainBar
          className="viewer-browser-pdf-toolbar-main-bar"
          onClick={(e: React.MouseEvent<HTMLElement, MouseEvent>): void =>
            e.stopPropagation()
          }
          style={{ top: isHideBar ? "-35px" : "0" }}
        >
          <Left className="viewer-browser-pdf-toolbar-left">
            {p.canUpload && (
              <>
                <UploadButton
                  className="viewer-browser-pdf-toolbar-upload-button"
                  onClick={p.rootProps.onClick}
                >
                  <i className="fas fa-exchange-alt" />
                  <span role="button">
                    <input {...p.getInputProps()} />
                  </span>
                </UploadButton>
                <Spacer className="viewer-browser-pdf-toolbar-spacer" />
              </>
            )}
            {p.canDownload && (
              <>
                <DownloadButton
                  className="viewer-browser-pdf-toolbar-download-button"
                  href={p.downloadLink}
                  download={p.fileName}
                >
                  <i className="fa fa-download" />
                </DownloadButton>
                <Spacer className="viewer-browser-pdf-toolbar-spacer" />
              </>
            )}
            <FileName className="viewer-browser-pdf-toolbar-file-name">
              {p.fileName}
            </FileName>
          </Left>
          <Right className="viewer-browser-pdf-toolbar-right">
            <ViewerChangeButton onClick={p.onClickChangeViewer} />
            {p.canDelete && (
              <>
                <Spacer className="viewer-browser-pdf-toolbar-spacer" />
                <DeleteButton
                  className="viewer-browser-pdf-toolbar-delete-button"
                  onClick={p.onClickDelete}
                >
                  <i className="fas fa-trash-alt" />
                </DeleteButton>
              </>
            )}
            <RightSpace className="viewer-browser-pdf-toolbar-right-space" />
          </Right>
        </MainBar>

        <BarHeight className="viewer-browser-pdf-toolbar-bar-height" />
      </Wrapper>

      <RightButton
        className="viewer-browser-pdf-toolbar-right-button"
        onClick={onClickHide}
      >
        <PDFToggleButton isUp={isHideBar} />
      </RightButton>
    </WrapperOuter>
  );
};

export default BrowserPDFToolBar;
