import Api from 'utilities/api';
import ApproverSearchBox from 'applications/approval_flows/components/ApproverSearchBox';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import ToggleButton from 'components/toggle_button';
import _ from 'lodash';
import flash from 'utilities/flash';
import i18next from 'i18n';
import {
  Alert,
  Modal,
  Tab,
  Tabs,
} from 'react-bootstrap';
import { snakecaseKeys } from 'utilities/Utils';

// 新規Componentでは使用禁止
// components/AddApproverModal を使用すること
export default class AddApproverModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      show: false,
      approvers: {
        users: [],
        groups: [],
        posts: [],
      },
      searchConditions: { searchText: '' },
    };
  }

  componentDidMount() {
    Api.approvers.index()
      .done((data) => {
        this.setState({
          approvers: {
            users: data.users,
            groups: data.groups,
            posts: data.posts,
          },
        });
      })
      .fail((xhr, message) => { flash.error(message || i18next.t('commons.errors.communicationError')); });
  }

  componentDidUpdate() {
    this.props.approvers.forEach((approver) => {
      $(`#check-${approver.id}`).prop("checked", true);
    });
  }

  open() {
    this.setState({ show: true });
  }

  close() {
    this.setState({ show: false });
  }

  onSubmit() {
    const approvers = [];
    this.state.approvers.users.forEach((user) => {
      if ($(`#check-${user.id}`).prop("checked")) {
        _.assign(user, { type: "user" });
        approvers.push(user);
      }
    });
    this.state.approvers.groups.forEach((group) => {
      if ($(`#check-${group.id}`).prop("checked")) {
        _.assign(group, { type: "group" });
        approvers.push(group);
      }
    });
    this.state.approvers.posts.forEach((post) => {
      if ($(`#check-${post.id}`).prop("checked")) {
        _.assign(post, { type: "company_post" });
        approvers.push(post);
      }
    });
    this.props.onClick(approvers);
    this.close.bind(this)();
  }

  selectApprover(id) {
    $(`#check-${id}`).prop("checked", !$(`#check-${id}`).prop("checked"));
  }

  onClickInput(e) {
    e.stopPropagation();
  }

  renderHeader() {
    return (
      <Modal.Header closeButton>
        <Modal.Title>{ i18next.t('approvals.flows.addApprover') }</Modal.Title>
      </Modal.Header>
    );
  }

  renderBody() {
    return (
      <Modal.Body bsClass="approver-modal">
        { this.renderTabs() }
      </Modal.Body>
    );
  }

  renderTabs() {
    return (
      <Tabs defaultActiveKey={ 1 } animation={ false }>
        <Tab eventKey={ 1 } title={ i18next.t('approvals.flows.approver') }>{ this.renderUsers() }</Tab>
        <Tab eventKey={ 2 } title={ i18next.t('approvals.flows.department') }>{ this.renderGroups() }</Tab>
        {
          this.props.displayPost
            ? <Tab eventKey={ 3 } title={ i18next.t('approvals.flows.post') }>{ this.renderPosts() }</Tab>
            : null
        }
      </Tabs>
    );
  }

  renderSaveButton() {
    const button = {
      className: 'col-sm-offset-10 col-sm-2',
      color: 'primary',
      onClick: this.onSubmit,
      content: i18next.t('commons.actions.update'),
    };

    return (
      <button key={ `add-approver-update-button` }
        className={ `btn btn-${button.color} ${button.className}` }
        onClick={ this.onSubmit.bind(this) }
        disabled={ !!button.disabled }
        style={ { marginBottom: '10px' } }
      >
        { button.content }
      </button>
    );
  }

  renderPosts() {
    return (
      <div>
        { this.renderSaveButton() }
        { this.state.approvers.posts.map((post) => (
          <div key={ post.id } className='approve-selector txt-pointer' onClick={ this.selectApprover.bind(this, post.id) }>
            <div className='approve-selector__name'>{ post.name }</div>
            <input className='approve-selector__input' type='checkbox' id={ `check-${post.id}` } onClick={ this.onClickInput }/>
          </div>
        )) }
      </div>
    );
  }

  setSearchText(value, state) {
    const searchConditions = { ...state.searchConditions };
    searchConditions.searchText = value;
    this.setState({ searchConditions });
  }

  approverSearch(state) {
    const value = state.searchConditions.searchText;
    Api.approvers.index(snakecaseKeys({ searchText: value }))
      .done((data) => {
        this.setState({
          approvers: {
            users: this.mergedApprovers(state, data.users, 'users'),
            groups: this.mergedApprovers(state, data.groups, 'groups'),
            posts: this.mergedApprovers(state, data.posts, 'posts'),
          },
        });
      })
      .fail((xhr, message) => {
        flash.error(message || i18next.t('commons.errors.communicationError'));
      });
  }

  mergedApprovers(state, searchResult, type) {
    const checkedApprovers = state.approvers[type].filter((v) => $(`#check-${v.id}`).prop("checked"));
    const mergedApprovers = searchResult.concat(checkedApprovers);

    // データを統合し、重複値を削除
    const mergedSearchApprovers = mergedApprovers.filter((approver, idx, self) => self.findIndex((v) => v.id === approver.id) === idx);
    return mergedSearchApprovers;
  }

  renderUsers() {
    const { searchConditions } = this.state;
    return (
      <div className="col-md-12" style={ { paddingTop: '15px', paddingBottom: '15px' } }>
        <ApproverSearchBox
          placeholder={ `${i18next.t('approvals.flows.approver')} または ${i18next.t('preferences.users.inputs.email')}` }
          resetSearchText={ () => this.setSearchText('', this.state) }
          search={ () => this.approverSearch(this.state) }
          searchText={ searchConditions.searchText }
          setSearchText={ (value) => this.setSearchText(value, this.state) }
        />
        { this.renderSaveButton() }
        {
          (this.state.approvers.users.length > 0) ? this.state.approvers.users.map((user) => (
            <div key={ user.id } className="approve-selector txt-pointer col-md-12" onClick={ this.selectApprover.bind(this, user.id) }>
              <div className='approve-selector__wrapper'>
                <div className='approve-selector__name'>{ user.name }</div>
                <div className='approve-selector__mail'>{ user.email }</div>
              </div>
              <input className='approve-selector__input' type='checkbox' id={ `check-${user.id}` } onClick={ this.onClickInput }/>
            </div>
          )) : (
            <Alert bsStyle='warning' className="col-md-12">
              { i18next.t('approvals.flows.noResults') }
            </Alert>
          )
        }
      </div>
    );
  }

  renderGroups() {
    return (
      <div className='col-md-12' style={ { paddingTop: '15px', paddingBottom:'15px' } }>
        { this.renderSaveButton() }
        { this.state.approvers.groups.map((group) => (
          <div key={ group.id } className='approve-selector txt-pointer' onClick={ this.selectApprover.bind(this, group.id) }>
            <div className='approve-selector__name'>{ group.name }</div>
            <input className='approve-selector__input' type='checkbox' id={ `check-${group.id}` } onClick={ this.onClickInput }/>
          </div>
        )) }
      </div>
    );
  }

  renderFooter() {
    return (
      <Modal.Footer>
        <div className='text-left'>
          <ToggleButton
            className='btn btn-primary col-sm-6 col-sm-offset-3'
            onClick={ this.onSubmit.bind(this) }>
            { i18next.t('commons.actions.update') }
          </ToggleButton>
        </div>
      </Modal.Footer>
    );
  }

  render() {
    return (
      <Modal
        show={ this.state.show }
        onHide={ this.close.bind(this) }>
        { this.renderHeader() }
        { this.renderBody() }
        { this.renderFooter() }
      </Modal>
    );
  }
}

AddApproverModal.propTypes = {
  approvers: PropTypes.arrayOf(
    PropTypes.shape({
      type: PropTypes.string,
      id: PropTypes.string,
      email: PropTypes.string,
      name: PropTypes.string,
    }),
  ).isRequired,
  displayPost: PropTypes.bool.isRequired,
  onClick: PropTypes.func.isRequired,
};
