import React, {
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react';
import colors from 'values/colors';
import styled from 'styled-components';

type SwitchConfig = {
  label?: string;
  backgroundColor?: string;
  fontColor?: string;
  switchColor?: string;
};

export interface Props {
  className?: string;
  checked?: boolean;
  size?: 'medium' | 'small';
  active?: SwitchConfig;
  inactive?: SwitchConfig;
  onChange?: (e: React.SyntheticEvent, checked: boolean) => void;
  disabled?: boolean;
  description?: string;
  descriptionClassName?: string;
  descriptionStyle?: { [key: string]: string };
}

interface ContainerViewProps {
  backgroundColor: string;
  fontColor: string;
  labelWidth: number | null;
  slideSpace: number;
  switchSize: number;
  disabled?: boolean;
}

interface SwitchViewProps {
  switchColor: string;
  isActive: boolean;
  labelWidth: number | null;
  slideSpace: number;
  switchSize: number;
}

interface LabelViewProps {
  isActive: boolean;
  slideSpace: number;
}

interface DescriptionProps {
  className?: string;
  style?: { [key: string]: string };
  size: 'medium' | 'small';
  description: string | undefined;
}

const NORMAL_SWITCH_SIZE = 22;
const SMALL_SWITCH_SIZE = 15;
const SWITCH_MARGIN = 2;
const SLIDE_MARGIN = 2;
const LABEL_MARGIN = 11;
const NORMAL_SLIDE_SPACE = 50;
const SMALL_SLIDE_SPACE = 30;
const TRANSITION_DURATION = '0.4s';

const SwitchRootView = styled.div`
  display: inline-block;
`;

/**
 * SwitchViewの表示領域を切り取るComponent
 */
const ContainerView = styled.label<ContainerViewProps>`
  border-radius: ${(props): string => `${(props.switchSize / 2) + SWITCH_MARGIN}px`};
  overflow: hidden;

  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.5), 0 1px 0 rgba(255, 255, 255, 0.2);
  width: ${(props): string => `${(props.labelWidth || 0) + LABEL_MARGIN + SLIDE_MARGIN + props.slideSpace}px`};
  min-width: ${(props): string => `${LABEL_MARGIN + SLIDE_MARGIN + props.slideSpace}`}px; // ラベルなしの時に、スイッチの幅が潰れないように

  background: ${(props): string => props.backgroundColor};
  color: ${(props): string => props.fontColor};

  // 計算・表示を簡単にするため、デフォルトのスタイルをクリアする
  padding-left: 0;
  padding-right: 0;
  margin-bottom: 0;

  transition: background ${TRANSITION_DURATION} ease, color ${TRANSITION_DURATION} ease;

  &:hover {
    cursor: ${(props): string => (props.disabled ? 'not-allowed' : 'pointer')};
  }
`;

const Checkbox = styled.input`
  visibility: hidden;
  position: absolute;
  outline: none;
  margin: 0 !important; // Bootstrapのスタイルを上書き
`;

const LabelView = styled.span<LabelViewProps>`
  text-align: ${(props): string => (props.isActive ? 'left' : 'right')};
  white-space: nowrap;

  // ラベルとスイッチの間のスペースを調整
  margin-right: ${(props): string => (props.isActive ? `${SLIDE_MARGIN + (props.slideSpace / 2)}px` : '0')};
  margin-left: ${(props): string => (props.isActive ? '0' : `${SLIDE_MARGIN + (props.slideSpace / 2)}px`)};
`;

/**
 * ラベル、スイッチの表示用Component
 * ラベルとスイッチ部分をスライドさせることにより、表示を切り替える
 *
 * NOTE: 長さの計算
 *   LABEL_WIDTH - ラベル文字列の表示領域の幅
 *   SLIDE_MARGIN - 非表示のラベルが写り込まないように、余分に設けておく幅
 *   SLIDE_SPACE - ◯の動く領域の幅。背景も動くので、実際に見えている範囲よりは狭い
 *   LABEL_MARGIN - Switchの両端の空白領域の幅。SwitchViewの長さには含まれない
 *
 *   SwitchViewの構成
 *   |<- LABEL_WIDTH ->|<- SLIDE_MARGIN ->|<- SLIDE_SPACE ->|<- SLIDE_MARGIN ->|<- LABEL_WIDTH ->|
 *
 *   Activeの時の表示領域
 *   |<- LABEL_MARGIN ->|<- LABEL_WIDTH ->|<- SLIDE_MARGIN ->|<- SLIDE_SPACE ->|
 *
 *   Inactiveの時の表示領域
 *   |<- SLIDE_SPACE ->|<- SLIDE_MARGIN ->|<- LABEL_WIDTH ->|<- LABEL_MARGIN ->|
 */
const SwitchView = styled.div<SwitchViewProps>`
  width: ${(props): string => `${(2 * ((props.labelWidth || 0) + SLIDE_MARGIN)) + props.slideSpace}px`};

  display: flex;
  align-items: center;
  user-select: none;

  height: ${(props): string => `${(props.switchSize) + (SWITCH_MARGIN * 2)}px`};
  transform: ${(props): string => `translateX(${props.isActive ? `${LABEL_MARGIN}px` : `-${(props.labelWidth || 0) + SLIDE_MARGIN}px`})`};

  transition: transform ${TRANSITION_DURATION} ease;

  ::before {
    content: '';

    position: absolute;
    left: 50%;
    transform: ${(props): string => `translateX(calc(-50% ${props.isActive ? '+' : '-'} ${((props.slideSpace - props.switchSize) / 2) - SWITCH_MARGIN}px))`};

    width: ${(props): string => `${props.switchSize}px`};
    height: ${(props): string => `${props.switchSize}px`};
    border-radius: ${(props): string => `${props.switchSize / 2}px`};

    background: ${(props): string => props.switchColor};
    box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.3);

    transition: transform ${TRANSITION_DURATION} ease;
  }

  > ${LabelView} {
    width: ${(props): string => (props.labelWidth ? `${props.labelWidth}px` : 'auto')};
    min-width: ${(props): string => (props.labelWidth ? `${props.labelWidth}px` : 'auto')};
  }
`;

function buildConfig(disabled: boolean | undefined, isActive: boolean, config: SwitchConfig = {}): Required<Omit<SwitchConfig, 'label'>> {
  const fontColor = config.fontColor || colors.white;
  const backgroundColor = config.backgroundColor || (isActive ? colors.status.success : '#777');
  const switchColor = disabled ? '#d6d7d1' : (config.switchColor || '#fcfff4');

  return { fontColor, backgroundColor, switchColor };
}

const renderDescription: React.FC<DescriptionProps> = (props: DescriptionProps) => {
  if (!props.description) return <></>;

  const style: { [key: string]: string } =  { marginRight: '10px', ...props.style };
  style.verticalAlign = props.size === 'medium' ? 'bottom' : 'top';

  return (
    <label className={ props.className } style={ style }>
      { props.description }
    </label>
  );
};

const FlipSwitch: React.FunctionComponent<Props> = (props: Props) => {
  const {
    className, checked = true, onChange,
    disabled,
    description,
    descriptionStyle,
    descriptionClassName,
    active = {},
    size = 'medium',
    inactive = {},
  } = props;
  const [inProgress, setInProgress] = useState(false);

  const handleChange = useCallback((e) => {
    if (onChange && !inProgress && !disabled) {
      // 連打対策のため、500msの間は入力を受け付けないようにする
      setInProgress(true);
      onChange(e, !checked);
    }
  }, [checked, inProgress, onChange, disabled]);

  useEffect(() => {
    if (inProgress) {
      const timer = setTimeout(() => setInProgress(false), 500);
      return (): void => clearTimeout(timer);
    }
    return (): void => void 0;
  }, [inProgress]);

  const config = buildConfig(disabled, checked, checked ? active : inactive);
  const switchRef = useRef<HTMLDivElement>(null);
  const [labelWidth, setLabelWidth] = useState<number | null>(null);
  const slideSpace = (size === 'medium' ?  NORMAL_SLIDE_SPACE : SMALL_SLIDE_SPACE)  - SLIDE_MARGIN - LABEL_MARGIN;
  const switchSize = size === 'medium' ? NORMAL_SWITCH_SIZE : SMALL_SWITCH_SIZE;

  useEffect(() => {
    // ラベルが変わった時に、width: autoで再描画して、必要な幅を計算
    setLabelWidth(null);
  }, [active.label, inactive.label]);

  useEffect(() => {
    if (labelWidth !== null) { return; }

    // width: autoの状態では、幅の再計算が必要
    if (switchRef.current) {
      const activeLabelElement = switchRef.current.children[0];
      const inactiveLabelElement = switchRef.current.children[1];

      const width = Math.max(activeLabelElement.getBoundingClientRect().width, inactiveLabelElement.getBoundingClientRect().width);
      setLabelWidth(width);
    }
  }, [active.label, inactive.label, labelWidth]);

  return (
    <>
      { renderDescription({
        description, size, style: descriptionStyle, className: descriptionClassName,
      })}
      <SwitchRootView className={ className }>
        <ContainerView
          labelWidth={ labelWidth }
          backgroundColor={ config.backgroundColor }
          fontColor={ config.fontColor }
          slideSpace={ slideSpace }
          switchSize={ switchSize }
          disabled={ disabled }
          className={ `container-view ${checked && 'checked'}` }
      >
          <Checkbox type='checkbox' onChange={ handleChange } className='check-box' />
          <SwitchView
            ref={ switchRef }
            isActive={ checked || false }
            switchColor={ config.switchColor }
            labelWidth={ labelWidth }
            slideSpace={ slideSpace }
            switchSize={ switchSize }
            className={ `switch-view ${checked && 'active'}` }
        >
            <LabelView isActive={ true } slideSpace={ slideSpace } className='label-view-active'>{ active.label }</LabelView>
            <LabelView isActive={ false } slideSpace={ slideSpace } className='label-view-inactive'>{ inactive.label }</LabelView>
          </SwitchView>
        </ContainerView>
      </SwitchRootView>
    </>
  );
};

export default FlipSwitch;
