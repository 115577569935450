import { useEffect, useRef } from 'react';

/** polling を行うための hook */
export function useInterval(
  /** ポーリングしたいメソッド */
  callback: () => void,
  /** 実行間隔 (milliseconds) */
  delay: number,
  /** ポーリングの実行状態 */
  inProgress = true,
): void {
  const initCall = useRef(true);
  const savedCallback = useRef<() => void>();

  // callback をメモ化しつつ, 変更があった場合に上書きする
  useEffect(() => {
    savedCallback.current = callback;
  }, [callback]);

  // 初めてポーリング対象の関数が実行可能になった時、インターバルを待たずに1度だけ実行する
  useEffect(() => {
    if (initCall.current && savedCallback.current && inProgress) {
      savedCallback.current();
      initCall.current = false;
    }
  }, [inProgress]);

  useEffect(() => {
    function tick(): void {
      if (savedCallback.current && inProgress) {
        savedCallback.current();
      }
    }

    if (delay != null) {
      const id = setInterval(tick, delay);
      return (): void => {
        clearInterval(id);
      };
    }
    return undefined;
  }, [callback, delay, inProgress]);
}
