import { displayMessage } from "actions/ActionCreators";
import reportRequestReducers from "applications/report_requests/reducers/reportRequests";
import i18next from "i18n";
import React, { Component } from "react";
import { connect, Provider } from "react-redux";
import { configureReducers } from "reducers";
import { createStore } from "redux";
import { createEnhancer } from "utilities/react_helper";
import { isExpenseCreatableAndRequestableMember } from "utilities/Utils";
import * as manualMergeActions from "../actions/manualMergeActions";
import * as actions from "../actions/transactionTable";
import * as AsyncActions from "../actions/TransactionTableAsyncActions";
import { AddExpenseDropdown } from "../components/AddExpenseDropdown";
import AddAttachableExpensesModal from "../components/modals/AddAttachableExpensesModal";
import { initialTransaction } from "../reducers/formData";
import makeTransactionTableStateMapper from "../selectors/transactionTable";
import TransactionListEditor from "./TransactionListEditor";

export class TransactionTableCard extends Component {
  constructor(props) {
    super(props);

    this.store = createStore(
      configureReducers(reportRequestReducers),
      undefined,
      createEnhancer(),
    );

    this.state = {
      showAddRequestableExpenseModal: false,
    };
  }

  get isAddable() {
    const { isAddable } = this.props;

    return isAddable && !userPreferences.isAuditor;
  }

  render() {
    return (
      <div className="card">
        <div className="card-header">
          <div className="card-title inline">
            {i18next.t("transactions.titles.list")}
          </div>
          <div className="card-right">
            {this.isAddable && isExpenseCreatableAndRequestableMember() && (
              <AddExpenseDropdown
                entryForms={this.props.entryForms}
                defaultTransaction={this.props.defaultTransaction}
                isApprovingReport={this.props.authority === "approver"}
                openNewTransactionModal={this.props.openNewTransactionModal}
                onShowAddRequestableExpenseModal={() =>
                  this.setState({
                    showAddRequestableExpenseModal: true,
                  })
                }
              />
            )}
          </div>
        </div>
        <div className="card-content-no-margin">
          <TransactionListEditor {...this.props} />
        </div>

        {/* 作成済み経費を追加するモーダル */}
        <Provider store={this.store}>
          <AddAttachableExpensesModal
            reportId={this.props.reportId}
            showAddRequestableExpenseModal={
              this.state.showAddRequestableExpenseModal
            }
            setShowAddRequestableExpenseModal={(show) =>
              this.setState({
                showAddRequestableExpenseModal: show,
              })
            }
          />
        </Provider>
      </div>
    );
  }
}

function mapDispatchToProps(dispatch, ownProps) {
  return {
    fetchTransactions(offset = 0, limit = 30) {
      return dispatch(actions.fetchTransactions(offset, limit));
    },
    fetchEntryForms() {
      return dispatch(actions.fetchEntryForms(true));
    },
    fetchSuggestionsForOwner(ownerId) {
      return dispatch(AsyncActions.fetchSuggestionsForOwner(ownerId));
    },
    saveSearchConditions() {
      dispatch(actions.saveSearchConditions());
    },
    setSearchConditions(queryObj) {
      dispatch(actions.setSearchConditions(queryObj));
    },
    onClickSearchButton() {
      dispatch(actions.onClickSearchButton());
    },
    onClickResetSearchConditionsButton() {
      return dispatch(actions.onClickResetSearchConditionsButton());
    },
    onClickEditModeSwitch(state) {
      dispatch(actions.toggleEditMode(state));
    },
    onClickUpdateAllButton(transaction) {
      dispatch(
        actions.setUpdateAllModalTransaction(
          transaction,
          transaction.preReportTitle,
        ),
      );
      dispatch(actions.openTransactionModal());
    },
    onCreateSuccess(message, callback) {
      dispatch(
        displayMessage(
          "success",
          message || i18next.t("transactions.messages.create"),
        ),
      );
      callback();
    },
    onUpdateSuccess(message, callback) {
      dispatch(
        displayMessage(
          "success",
          message || i18next.t("transactions.messages.update"),
        ),
      );
      callback();
    },
    onDestroySuccess(message, callback) {
      dispatch(
        displayMessage(
          "success",
          message || i18next.t("transactions.messages.delete"),
        ),
      );
      callback();
    },
    onDetachSuccess(message, callback) {
      dispatch(
        displayMessage(
          "success",
          message || i18next.t("transactions.messages.detach"),
        ),
      );
      callback();
    },
    /**
     * 経費分割に成功した場合
     * @param {String} message メッセージ
     * @param {Function} callback コールバック
     */
    onSplitSuccess(message, callback) {
      dispatch(displayMessage("success", message));
      callback();
    },
    onSelectCompanionsCategory() {
      dispatch(actions.setShouldSelectSelfAsCompanion(false));
    },
    resetTransactions(offset = 0, limit = 30) {
      return dispatch(actions.fetchTransactions(offset, limit)).then(() => {
        const ranges = [{ end: offset }, { start: offset + limit }];
        dispatch(actions.clearStaleTransactions(ranges));
      });
    },
    onSortChange(sortName, sortOrder) {
      return dispatch(actions.onSortChange(sortName, sortOrder));
    },
    destroyAll(callback) {
      dispatch(actions.destroyTransactions(callback));
    },
    detachAll(transactions, callback) {
      dispatch(actions.detachTransactions(transactions, callback));
    },
    mergeAll(ids, callback) {
      dispatch(actions.mergeTransactions(ids, callback));
    },
    setSearchBoxRef(ref) {
      dispatch(actions.setSearchBoxRef(ref));
    },
    openNewTransactionModal(transaction) {
      if (typeof ga !== "undefined") {
        ga(
          "send",
          "event",
          "web",
          "経費一覧から経費を追加",
          transaction.formId,
        );
      }
      dispatch(actions.setNewModalTransaction(transaction));
      dispatch(actions.openTransactionModal());
    },
    openTransactionModal(expenses, expense, currentPage, sizePerPage) {
      dispatch(
        actions.openTransactionEditModal(
          expenses,
          expense,
          currentPage,
          sizePerPage,
          { requireMergeableAggregation: !expense.isDeleted },
        ),
      );
    },
    closeTransactionModal() {
      dispatch(actions.setShouldSelectSelfAsCompanion(true));
      dispatch(actions.closeTransactionModal());
    },
    openCostAllocationModal(departments) {
      dispatch(actions.openCostAllocationModal());
      dispatch(actions.setCostAllocations(departments));
    },
    closeCostAllocationModal() {
      dispatch(actions.closeCostAllocationModal());
      dispatch(actions.resetCostAllocations());
    },
    onMouseEnterCostAlocationDiv(rowIdx) {
      dispatch(actions.onMouseEnterCostAlocationDiv(rowIdx));
    },
    onMouseLeaveCostAlocationDiv() {
      dispatch(actions.onMouseLeaveCostAlocationDiv());
    },
    moveExpenseCursor(expenses, targetExpenseId, currentPage, sizePerPage) {
      dispatch(
        actions.moveExpenseCursor(
          expenses,
          targetExpenseId,
          currentPage,
          sizePerPage,
          { requireMergeableAggregation: true },
        ),
      );
    },
    rotateReceiptFile(expenseId, fileId, rotation) {
      dispatch(actions.rotateReceiptFile(expenseId, fileId, rotation));
    },
    openMergeableTransactionsPairsSearchModal() {
      dispatch(manualMergeActions.openMergeableTransactionsPairsSearchModal());
    },
    closeMergeableTransactionsPairsSearchModal() {
      dispatch(manualMergeActions.closeMergeableTransactionsPairsSearchModal());
    },
    editOption(key, value) {
      dispatch(manualMergeActions.editOption(key, value));
    },
    searchMergeableTransactionsPairs() {
      dispatch(manualMergeActions.searchMergeableTransactionsPairs());
    },
    closeMergedSelecter() {
      dispatch(manualMergeActions.closeMergedSelecter());
    },
    mergeTransactions() {
      dispatch(manualMergeActions.mergeTransactions());
    },
    selectMergedTransaction(transacitons) {
      dispatch(manualMergeActions.selectMergedTransaction(transacitons));
    },
  };
}

TransactionTableCard.defaultProps = {
  defaultTransaction: initialTransaction,
};

export default connect(
  makeTransactionTableStateMapper,
  mapDispatchToProps,
)(TransactionTableCard);
