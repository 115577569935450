import _ from 'lodash';
import { createSelector } from 'reselect';

const getTransactionStore = (state, props) => _.get(state, 'transactions', {});
const getIsEditing = (state, props) => _.get(state, 'isEditing', false);
const getModalTransaction = (state, props) => _.get(state, 'modal.transaction', {});
const getIsTransactionModalOpen = (state, props) => _.get(state, 'modal.isTransactionModalOpen', false);
const getIsReportSendModalOpen = (state, props) => _.get(state, 'request.isReportSendModalOpen', false);
const getIsLoading = (state, props) => _.get(state, 'request.isLoading', false);

const makeTransactionSelectCardStateMapper = () => createSelector(
  [getTransactionStore, getIsEditing, getModalTransaction, getIsTransactionModalOpen, getIsReportSendModalOpen, getIsLoading],
  (transactionStore, isEditing, modalTransaction, isTransactionModalOpen, isReportSendModalOpen, isLoading) => {
    const transactions = isEditing ? transactionStore.inEdit : transactionStore.master;

    const projectSelectable = userPreferences.preference.inputProject;
    const payerSelectable = userPreferences.preference.noRefundEditable;

    return {
      transactions,
      selectedIds: transactions.filter((x) => x && x.isSelected).map((x) => x.id),
      modalTransaction,
      isTransactionModalOpen,
      isReportSendModalOpen,
      isLoading,
      projectSelectable,
      payerSelectable,
    };
  },
);

export default makeTransactionSelectCardStateMapper;
