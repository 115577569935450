import Api from 'utilities/api';
import ApiPaperless from 'utilities/api_paperless';
import S3UploadBatchError from './S3UploadBatchError';
import S3UploadError from './S3UploadError';
import i18next from 'i18n';
import { getMessageFromResponse, sleep, snakecaseKeys } from 'utilities/Utils';

// S3にファイルをアップロードする
export default class S3UploaderV2 {
  get stages() {
    return {
      fetchParam: 'dk1',
      upload: 's3',
      updateStatus: 'dk2',
      deleteReport: 'dk3',
    };
  }

  makeUploadFormData(uploadParams, file) {
    const { params } = uploadParams;
    const formData = new FormData();

    Object.keys(params).forEach((key) => {
      formData.append(key, params[key]);
    });

    formData.append('file', file);

    return formData;
  }

  /*
   * @param uploadOption {Object} getUploadParamに渡すパラメータのオブジェクト
   * @param getS3Param {Function} S3にアップロードする際のパラメータを、getUploadParamの戻り値から取り出す関数
   *                              Controllerによって、返すオブジェクトの形状が異る可能性があるため
   * @param retryNumber {number} S3アップロード時の、失敗許容回数。失敗するごとに、2秒置いて再通信を行う
   */
  async uploadV2(getUploadParam, file, { uploadOption, getS3Param, retryNumber }) {
    let param = null;

    try {
      param = await getUploadParam(snakecaseKeys(uploadOption));
    } catch (e) {
      // DKサーバの通信に失敗する時は、retryしない
      return Promise.reject(new S3UploadError(getMessageFromResponse(e, e.message), this.stages.fetchParam, param));
    }

    const maxIter = 1 + retryNumber;
    const iterable = this.uploadIterator(file, param, getS3Param, maxIter);
    let result = null;

    for await (result of iterable) { /* 何もしない */ } // eslint-disable-line no-restricted-syntax

    if (result instanceof Error) {
      return Promise.reject(result);
    }

    return Promise.resolve(result);
  }

  async *uploadIterator(file, param, getS3Param, maxIter = 5) {
    let iteration = maxIter;

    while (iteration-- > 0) {
      try {
        yield this.uploadToS3(file, param, getS3Param);
        break;
      } catch (e) {
        yield e;

        if (!(e instanceof S3UploadError) || !e.retryable) {
          // バグ、もしくはretry不可
          break;
        }

        if (iteration > 0) {
          await sleep(2000);
        }
      }
    }
  }

  /*
   * @param getUploadParams {Function} 複数枚のS3送信用のパラメータを取得するAPI
   * @param uploadOption {Object} getUploadParamsに渡すパラメータのオブジェクト
   * @param getS3Param {Function} S3にアップロードする際のパラメータを、getUploadParamsの戻り値から取り出す関数
   *                              Controllerによって、返すオブジェクトの形状が異る可能性があるため
   *                              配列が返される前提で、配列の各要素のオブジェクトを引数に取る
   */
  async uploadV2Multiple(getUploadParams, files, { uploadOption, getS3Param }) {
    let params = null;

    try {
      params = await getUploadParams(snakecaseKeys(uploadOption));
    } catch (e) {
      const errorMessage = getMessageFromResponse(e, e.message);
      return Promise.reject(
        new S3UploadBatchError(
          errorMessage,
          this.stages.fetchParam,
          // 全ファイル送信失敗
          files.map((x) => (new S3UploadError(errorMessage, this.stages.fetchParam, null, x))),
        ),
      );
    }

    // params内のパラメータの順序は、filesの順序と一致している前提
    // エラーが発生したファイルを全て捕捉するため、Promise.allは使わない
    // （Promise.allだと、最初に失敗したファイルのエラーしか捕捉できない）
    return new Promise((resolve, reject) => {
      // アップロード完了した数をカウントする
      // JavaScriptの変数への書き込み処理は、直列実行なので、並列処理のバグは入らないはず
      let fin = 0;
      const results = [];

      params.forEach(async (param, index) => {
        try {
          results[index] = await this.uploadToS3(files[index], param, getS3Param);
        } catch (e) {
          // 失敗した場合は、エラーとしつつ、最後のファイルアップロード完了まで例外を投げないように
          results[index] = e;
        } finally {
          fin += 1;

          // params.length == 送信ファイル数
          if (fin >= params.length) {
            const errors = results.filter((result) => (result instanceof S3UploadError));

            if (errors.length === 0) {
              resolve(results);
            } else {
              reject(new S3UploadBatchError(
                'アップロードに失敗したファイルがあります',
                this.stages.upload,
                errors,
              ));
            }
          }
        }
      });
    });
  }

  async uploadTransactionReceiptImage(image, uploadOption = {}, retryNumber = 0) {
    const option = {
      uploadOption: { ...uploadOption, 'image_extension': image.type },
      getS3Param(data) { return data.uploadParams; },
      retryNumber,
    };

    return this.uploadV2(Api.receiptImages.uploadUrl, image, option)
      .then(async ({ dkResult, s3Result }) => {
        try {
          // サーバー側に、S3のアップロード完了を通知
          const params = snakecaseKeys({ id: dkResult.uploadParams.transactionId, ownerId: dkResult.uploadParams.ownerId, receiptImageIds: [dkResult.receiptImage.id] });
          const result = await Api.transactions.notifyImageUploaded(params);

          return Promise.resolve(result);
        } catch (e) {
          return Promise.reject(new S3UploadError(getMessageFromResponse(e, e.message), this.stages.updateStatus, dkResult, image));
        }
      });
  }

  async uploadTransactionReceiptImageForAutoInput(image, uploadOption = {}, retryNumber = 0) {
    return this.uploadTransactionReceiptImage(image, { ...uploadOption, inputBy: 'worker' }, retryNumber);
  }

  async uploadImportFile(file, uploadOption = {}, fetchUploadUrl = Api.importJobs.uploadUrl, retryNumber = 0) {
    const option = {
      uploadOption,
      getS3Param(data) { return data.uploadParams; },
      retryNumber,
    };

    return this.uploadV2(fetchUploadUrl, file, option)
      .then(({ dkResult, s3Result }) => Promise.resolve({ requestId: dkResult.requestId }));
  }

  async uploadOriginalReceiptImage(image, uploadOption = {}, retryNumber = 0) {
    const option = {
      uploadOption: { ...uploadOption },
      getS3Param(data) { return data.uploadParams; },
      retryNumber,
    };

    return this.uploadV2(ApiPaperless.originalReceipts.uploadUrl, image, option);
  }

  // 直接呼ばないこと。uploadV2を経由してcallする
  async uploadToS3(file, param, getS3Param = (x) => (x.uploadParams)) {
    const s3Param = getS3Param(param);
    // content-type?
    const axioParams = {
      method: 'POST',
      baseURL: '/',
      headers: { 'Content-Type': 'multipart/form-data' },
      data: this.makeUploadFormData(s3Param, file),
    };

    return Api.fetch(s3Param.url, axioParams)
      .then((data) => ({ dkResult: param, s3Result: data }))
      .catch((error) => {
        return Promise.reject(this.formatS3Error(error, this.stages.upload, param, file));
      });
  }

  formatS3Error(error, stage, param, file) {
    // レスポンス不明
    if (!error.response) {
      // ネットワークエラー等
      return new S3UploadError(error.message, stage, param, file, true);
    }

    const responseType = (error.response.headers && error.response.headers['content-type']) || null;

    if (responseType !== 'application/xml') {
      // S3のエラーなら、XMLでレスポンスが返るので、以下は実行されない想定
      // 一応retryしてみる
      return new S3UploadError(error.message, stage, param, file, true);
    }

    const parser = new DOMParser();
    const doc = parser.parseFromString(error.response.data, 'application/xml');

    if (doc.documentElement.nodeName === "parsererror") {
      // パース失敗、エラー詳細不明
      return new S3UploadError(error.message, stage, param, false);
    }

    const errorCode = doc.documentElement.querySelector('Code')?.textContent;

    if (!errorCode) {
      // エラー詳細不明. 実行されない想定
      return new S3UploadError(error.message, stage, param, file, false);
    }

    switch (errorCode) {
      case 'InternalError':
      case 'OperationAborted': {
        return new S3UploadError(i18next.t('transactions.errors.failedToUploadImage'), stage, param, file, true);
      }
      case 'EntityTooLarge': {
        return new S3UploadError(i18next.t('transactions.errors.uploadLimitError'), stage, param, file, false);
      }
      default: {
        return new S3UploadError(i18next.t('transactions.errors.failedToUploadImage'), stage, param, file, false);
      }
    }
  }
}
