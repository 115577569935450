import React from 'react';
import colors from 'values/colors';
import styled from 'styled-components';
import { Pagination as RBPagination } from 'react-bootstrap';
import { useHooks } from './hooks';

const greenColor = "#00bfa5";
const Wrapper = styled.div`
  display: flex;
  align-items: center;
  color: ${colors.renewaled.textBlack};
`;
// ライブラリの色の設定
const PaginationUl = styled(RBPagination)`
  .active span {
    background-color: ${greenColor};
    border-color: ${greenColor};

    &:hover, &:focus, &:active {
      background-color: ${greenColor};
      border-color: ${greenColor};
    }
  }
  li a {
    color: ${greenColor};

    &:hover, &:focus, &:active {
      color: ${greenColor};
    }
  }
`;

interface Props {
  /** 現在のページ番号 */
  readonly currentPage: number;
  /** ページネーションの最大数 */
  readonly paginationSize: number;
  /** １ページあたりの数 */
  readonly sizePerPage: number;
  /** ページが変更された */
  readonly onPageChange: (page: number, sizePerPage: number) => void;
}

/**
 * テーブルのページネーション
 */
export const Pagination: React.FC<Props> = (props) => {
  const {
    items,
    canNextPage,
    onClickPrev,
    canPreviousPage,
    canPreviousTopPage,
    canLastPage,
    onClickNext,
    onClickTopPage,
    onClickLastPage,
    onClickPageItem,
  } = useHooks(props.onPageChange, props.currentPage, props.paginationSize, props.sizePerPage);

  return (
    <Wrapper className='table-pagination'>
      <PaginationUl>
        {canPreviousTopPage() && (
          <RBPagination.Item onClick={ onClickTopPage }>
            <i className='fas fa-angle-double-left'/>
          </RBPagination.Item>
        )}
        {canPreviousPage() && (
          <RBPagination.Item onClick={ onClickPrev }>
            <i className='fas fa-angle-left'/>
          </RBPagination.Item>
        )}

        {items().map((item) => (
          <RBPagination.Item
            active={ item === props.currentPage }
            key={ item }
            onClick={ (): void => onClickPageItem(item) }
          >
            {item}
          </RBPagination.Item>
        ))}

        {canNextPage() && (
          <RBPagination.Item onClick={ onClickNext }>
            <i className='fas fa-angle-right'/>
          </RBPagination.Item>
        )}
        {canLastPage() && (
          <RBPagination.Item onClick={ onClickLastPage }>
            <i className='fas fa-angle-double-right'/>
          </RBPagination.Item>
        )}
      </PaginationUl>
    </Wrapper>
  );
};
