import NumberField from "components/fields/NumberField";
import FormValidation from "components/FormValidation";
import LabeledComponent from "components/LabeledComponent";
import i18next from "i18n";
import React, { FC } from "react";

interface Props {
  amount: number | null;
  disabled: boolean;
  errors: string[];
  onChangeAmount: (amount: number | null) => void;
}

const PreExpenseAmountForm: FC<Props> = (props) => {
  return (
    <LabeledComponent
      className="form-group"
      labelClass="col-sm-3"
      label={i18next.t("transactions.properties.amount")}
    >
      <div className="col-sm-6">
        <FormValidation messages={props.errors}>
          <NumberField
            disabled={props.disabled}
            value={props.amount}
            onChange={(value): void =>
              props.onChangeAmount(value ? parseInt(value, 10) : null)
            }
            options={{ placeholder: i18next.t("commons.status.notEntered") }}
          />
        </FormValidation>
      </div>
    </LabeledComponent>
  );
};

export default PreExpenseAmountForm;
