import React, {
  FC, useCallback, useEffect, useState,
} from 'react';
import Tag from 'applications/tags/components/Tag';
import TagSelector from 'applications/tags/components/TagSelector';
import i18next from 'i18n';
import styled from 'styled-components';
import { NationalTaxDocumentLabel } from 'utilities/api_payment_requests/models/NationalTaxDocumentLabel';
import { OverlayTrigger, Popover } from 'react-bootstrap';
import { useNationalTaxDocumentLabels } from 'applications/payment_requests/NationalTaxDocumentSettings/nationalTaxDocumentLabels/hooks/useNationalTaxDocumentLabels';

type LabelFormProps = {
  container?: HTMLElement | null;
  selection: NationalTaxDocumentLabel[];
  disabled: boolean;
  onLabelsChange: (labels: NationalTaxDocumentLabel[]) => void;
};

const InputLikeView = styled.div`
  border: 1px solid #ccc;
  border-radius: 4px;
  box-shadow: inset 0 1px 1px rgb(0 0 0 / 8%);
  padding: 6px;
  &.disabled {
    cursor: not-allowed;
    background-color: #eeeeee;
    opacity: 1;
  }
`;

const LabelContainerDiv = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: start;
  gap: 4px;
`;

const ScrollablePopover = styled(Popover)`
  .popover-content {
    overflow-y: auto;
    max-height: 250px;
  }
`;

type NationalTaxDocumentLabelWithSelectStatus = NationalTaxDocumentLabel & { selected: boolean; };

export const LabelField: FC<LabelFormProps> = ({
  container = null, selection, disabled, onLabelsChange,
}) => {
  const { labels: fetchLabels } = useNationalTaxDocumentLabels();
  // 選択肢に表示するラベル
  const [labels, setLabels] = useState<NationalTaxDocumentLabelWithSelectStatus[]>([]);

  useEffect(() => {
    if (fetchLabels) {
      setLabels(
        fetchLabels.map((lab) => ({ ...lab, selected: selection.some((sel) => sel.id === lab.id) })),
      );
    }
  }, [fetchLabels, selection]);

  // ポップオーバーからラベルを選択したとき
  const handleSelectLabel = useCallback((label: NationalTaxDocumentLabel, selected: boolean) => {
    // 選択肢したラベルの状態(selected)を変更する
    const newLabels = labels.map((lab) => {
      if (lab.id === label.id) return { ...lab, selected };
      return lab;
    });

    onLabelsChange(newLabels.filter((lab) => lab.selected));
    setLabels(newLabels);
  }, [labels, onLabelsChange]);

  const renderLabels = (selectionLabels: NationalTaxDocumentLabel[]): JSX.Element => {
    if (selectionLabels.length === 0) {
      return (
        <span className='txt'>
          { i18next.t('commons.actions.unset') }
        </span>
      );
    }

    return (
      <LabelContainerDiv>
        {
          selectionLabels.map((label) => (
            <Tag
              key={ `label-${label.id}` }
              containerStyle={ { margin: '4px 0' } }
              name={ label.name }
              color={ label.color }
            />
          ))
        }
      </LabelContainerDiv>
    );
  };

  return (
    <OverlayTrigger
      trigger='click'
      placement='top'
      rootClose
      container={ container }
      overlay={ !disabled ? (
        <ScrollablePopover id='label-popup' title={ i18next.t('members.titles.selectLabel') }>
          <TagSelector tags={ labels } onClickTag={ handleSelectLabel } />
        </ScrollablePopover>
      ) : (<span></span>) }>
      <InputLikeView className={ disabled ? 'disabled' : 'txt-pointer' }>
        { renderLabels(selection) }
      </InputLikeView>
    </OverlayTrigger>
  );
};
