import FormItem from './FormItem';
import PropTypes from 'prop-types';
import React from 'react';
import formatter from 'utilities/formatter';

const PayedShop = (props) => {
  return (
    <FormItem label='shopName'>
      { formatter.text(props.value, '-') }
    </FormItem>
  );
};

PayedShop.propTypes = {
  value: PropTypes.string,
};

export default React.memo(PayedShop);
