import React from 'react';
import i18next from 'i18n';
import { Alert } from 'react-bootstrap';

export const NotFound: React.FC = (): JSX.Element => {
  return (
    <Alert bsStyle='warning'>
      <p>{ i18next.t('reports.messages.noData') }</p>
      { userPreferences.tenants.length > 1 && <p> { i18next.t('commons.actions.changeTenant') }</p> }
    </Alert>
  );
};
