import Api from 'utilities/api';
import {
  CreateParams,
  ExpenseCreateResponse,
  ExpenseShowResponse,
  ExpenseUpdateResponse,
  IExpenseDriver,
  ShowParams,
  UpdateParams,
} from 'apis/IExpenseDriver';
import { snakecaseKeys } from 'utilities/Utils';

export class ExpenseDriver implements IExpenseDriver {
  /** 複数経費を作成します */
  async createAll(apiParams: CreateParams): Promise<ExpenseCreateResponse> {
    return await Api.transactions.create(snakecaseKeys(apiParams));
  }

  /** 複数経費を更新します */
  async updateAll(apiParams: UpdateParams): Promise<ExpenseUpdateResponse> {
    return await Api.transactions.update(snakecaseKeys(apiParams));
  }

  /** 経費を取得します */
  async show(apiParams: ShowParams): Promise<ExpenseShowResponse> {
    return await Api.transactions.show(snakecaseKeys(apiParams));
  }
}
