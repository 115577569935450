import i18next from "i18n";
import get from "lodash/get";
import React from "react";
import { TableHeaderColumn } from "react-bootstrap-table";

/**
 * 未読 / 既読カラム
 */
const renderReadStatusColumn = (props) => {
  const readStatus = (cell, _row) => {
    const readMark = get(cell, "read") ? "" : "●";
    return <span className="read-mark">{readMark}</span>;
  };

  return (
    <TableHeaderColumn
      dataField="read"
      hidden={props.hedden}
      dataFormat={readStatus}
      dataSort={props.sortable}
      dataAlign={props.align}
      width={props.width}
    >
      {i18next.t("transactions.properties.unread")}
    </TableHeaderColumn>
  );
};

export default renderReadStatusColumn;
