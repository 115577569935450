import get from "lodash/get";
import isNil from "lodash/isNil";
import { createSelector } from "reselect";
import REPORT_STATUS from "../../../values/report_status";

const getAuthority = (state, props) => props.authority;
const getReport = (state, props) => get(state, "preReport.report");
const getOwnerId = (state, props) => get(state, "preReport.userId");
const getTransactions = (state, props) =>
  get(state, "transactionTable.transactions");
const getEntryForms = (state, props) => {
  // 費用負担部署について、entryForm内にdefault値を持っている場合、
  // 経費追加モーダル表示時にはその値を優先するため、
  // 上書きたい内容で入れておく
  // この仕組み自体見直すべきなきがする...
  const entryForms = get(state, "transactionTable.entryForms");
  const costAllocations = getCostAllocations(state, props);

  return entryForms.map((form) => {
    const fields = form.formFields.map((field) => {
      if (field.type === "cost_allocation_input") {
        return {
          ...field,
          defaultValue: costAllocations,
        };
      }

      return field;
    });

    return {
      ...form,
      formFields: fields,
    };
  });
};
const getCostAllocations = (state, props) => {
  const dep = get(state, "preReport.department", null);
  return [
    {
      payerType: "Group",
      payerId: get(dep, "id"),
      payerName: get(dep, "name"),
      numerator: 100,
      denominator: 100,
    },
  ];
};
const getIsEditing = (state, props) => get(state, "transactionTable.isEditing");
const getCurrentPage = (state, props) =>
  get(state, "transactionTable.currentPage");
const getSizePerPage = (state, props) =>
  get(state, "transactionTable.sizePerPage");
const getShouldSelectSelfAsCompanion = (state, props) =>
  get(state, "transactionTable.shouldSelectSelfAsCompanion");

// 経費追加・編集可能であるか
const getIsEditable = (state, props) => {
  // 事前申請が承認済みでない場合は、経費追加できない
  if (!get(state, "preReport.hasApproved")) {
    return false;
  }

  // 申請者で、経費申請が未申請、もしくは編集可能な時は可能
  if (
    props.authority !== "approver" &&
    (isNil(get(state, "preReport.report")) ||
      get(state, "preReport.report.editable", false))
  ) {
    return true;
  }

  // 承認者で、経費申請が承認待ちの時は可能
  if (
    props.authority === "approver" &&
    get(getReport(state, props), "originalStatus") ===
      REPORT_STATUS[userPreferences.locale].applying
  ) {
    return true;
  }

  // 以上の条件に当てはまらない場合は不可
  return false;
};

const getModalState = (state, props) => get(state, "transactionTable.modal");
const getCostAllocationModalState = (state, props) =>
  get(state, "transactionTable.costAllocationModal");
const getColumnVisibilities = (state, props) =>
  get(state, "transactionTable.columnVisibilities");

const getHistories = (state, props) =>
  get(state, "histories.expenseHistories", []);
const getSelectedExpenseId = (state, props) =>
  get(state, "histories.selectedExpenseId", null);

const makeTransactionTableStateMapper = () =>
  createSelector(
    [
      getAuthority,
      getOwnerId,
      getReport,
      getTransactions,
      getEntryForms,
      getIsEditing,
      getIsEditable,
      getModalState,
      getCostAllocationModalState,
      getCurrentPage,
      getSizePerPage,
      getShouldSelectSelfAsCompanion,
      getColumnVisibilities,
      getHistories,
      getSelectedExpenseId,
    ],
    (
      authority,
      ownerId,
      report,
      transactions,
      entryForms,
      isEditing,
      isEditable,
      modalState,
      costAllocationModalState,
      currentPage,
      sizePerPage,
      shouldSelectSelfAsCompanion,
      columnVisibilities,
      histories,
      selectedExpenseId,
    ) => {
      const transactionsForTable = isEditing
        ? transactions.inEdit
        : transactions.master;
      return {
        transactions: transactionsForTable,
        transactionsObj: transactions,
        entryForms,
        currentPage,
        sizePerPage,
        columnVisibilities,
        ownerId,
        isAddable: isEditable,
        isEditable,
        isDeletable: false,
        withSearchBox: false,
        isEditing,
        isMergeable: false,
        isDetachable: isEditable,
        modalTransaction: modalState.transaction,
        nextExpenseId: modalState.nextId,
        previousExpenseId: modalState.previousId,
        isTransactionModalOpen: modalState.isTransactionModalOpen,
        costAllocations: costAllocationModalState.costAllocations,
        isCostAllocationModalOpen: costAllocationModalState.show,
        costAllocationHovorRowIdx: costAllocationModalState.hovor,
        except: [],
        showNumberOfResults: true,
        shouldSelectSelfAsCompanion,
        histories,
        selectedTransactionIdForHistory: selectedExpenseId,
      };
    },
  );

export default makeTransactionTableStateMapper;
