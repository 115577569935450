import i18next from "i18next";
import React, { FC, useEffect } from "react";
import AccountRegistrationWarning from "./AccountRegistrationWarning";

interface Props {
  /** @deprecated 親コンテナでやって */
  fetchAccount?: () => void;
  showWarning?: boolean;
  title?: string;
}

const AccountForm: FC<Props> = (props) => {
  const showWarning = props.showWarning || false;
  const title = props.title || i18next.t("accountForms.titles.newAccount");

  useEffect(() => {
    if (props.fetchAccount) {
      props.fetchAccount(); // 一度だけ実行する
    }
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <div className="card">
      <div className="card-header">
        <div className="card-title inline">{title}</div>
      </div>
      <div className="card-content">
        {showWarning ? (
          <div className="row">
            <AccountRegistrationWarning />
          </div>
        ) : null}
        {props.children}
      </div>
    </div>
  );
};

export default AccountForm;
