import Api from 'utilities/api';
import { IReportDriver } from 'apis/IReportDriver';
import { ShowResponse } from 'utilities/api/responses/reports';
import { snakecaseKeys } from 'utilities/Utils';

export class ReportDriver implements IReportDriver {
  /** 経費申請を取得します */
  async fetch(reportId: string): Promise<ShowResponse> {
    return await Api.expenses.reports.show(snakecaseKeys({ id: reportId }));
  }
}
