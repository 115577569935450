import { LocalStorageUtil } from "utilities/local_storage_util";

/**
 * LocalStrage: データ定義
 */
export interface ViewerSettingStorageParam {
  /** バージョン */
  readonly _version?: string;
  /** ブラウザ版ビューアーを使うか */
  readonly isBrowserViewer: boolean;
  /** ツールバーを閉じるか */
  readonly isHideToolBar: boolean;
}

/**
 * スキーマのバージョン。変更するたびに上げる。
 * ただし、バージョン比較が文字列比較なので、バージョンの各桁はそれぞれ0~9までの数字のみとする。（例、OK = 1.0.9、NG = 1.0.11 ）
 */
const version = "1.0.0";
const key = ["viewer", "setting"];
const defaultData: ViewerSettingStorageParam = {
  isBrowserViewer: true,
  isHideToolBar: false,
};

const ViewerSettingStorage = new LocalStorageUtil<ViewerSettingStorageParam>(
  version,
  key,
  defaultData,
);

export default ViewerSettingStorage;
