
import React from 'react';
import { Item } from './Item';
import { Tag } from 'utilities/api/models/accountingDataScheduledExports/Tag';

interface Props {
  label: string;
  value: Tag[] | null;
}

export const TagItem: React.FC<Props> = ({
  label,
  value,
}) => {
  if (!value?.length) { return <></>; }

  const tagNames = value.map((tag) => tag.name).join(', ');

  return (
    <Item label={ label } value={ tagNames }></Item>
  );
};
