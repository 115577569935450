import { useCallback, useRef } from "react";
import {
  AmountPerTaxCategory,
  AmountPerTaxCategoryWithoutTax,
  Authority,
} from "../interface";
import { calcTaxAmount } from "../utils";

interface HooksReturnType {
  /** 税区分名のカラムを表示するか */
  readonly isShowNameColumn: boolean;
  /** 消費税額ありの行 */
  readonly withTaxRows: AmountPerTaxCategory[];
  /** 消費税額なしの行 */
  readonly withoutTaxRows: AmountPerTaxCategoryWithoutTax[];
  /** ユーザが意図して変更したか */
  readonly userInputtedRef: React.MutableRefObject<boolean>;
  /** 金額を変更した場合 */
  readonly handleChangeAmount: (taxCategoryId: string, amount: number) => void;
  /** 消費税額を変更した場合 */
  readonly handleChangeTaxAmount: (
    taxCategoryId: string,
    taxAmount: number,
  ) => void;
}

export const useTable = (
  authority: Authority,
  amountPerTaxCategories: AmountPerTaxCategory[],
  onChange: ((amountPerTaxCategories: AmountPerTaxCategory[]) => void) | null,
): HooksReturnType => {
  const useInputtedRef = useRef(false);

  const isShowNameColumn = showNameColumn(authority, amountPerTaxCategories);

  const withTaxRows = amountPerTaxCategories.map((a) => ({
    id: a.id,
    expenseId: a.expenseId,
    amount: a.amount,
    taxCategory: a.taxCategory,
    taxAmount: a.taxAmount,
  }));

  const withoutTaxRows = amountPerTaxCategories.map((a) => ({
    id: a.id,
    expenseId: a.expenseId,
    taxCategory: a.taxCategory,
    amount: a.amount,
  }));

  const handleChangeAmount = useCallback(
    (taxCategoryId: string, amount: number) => {
      const newAmountPerTaxCategories = amountPerTaxCategories.map((o) => {
        if (o.taxCategory.id === taxCategoryId) {
          return {
            ...o,
            amount,
            taxAmount:
              o.amount === amount
                ? o.taxAmount
                : calcTaxAmount(amount, o.taxCategory.taxRate),
          };
        }
        return o;
      });

      useInputtedRef.current = true;
      if (onChange) onChange(newAmountPerTaxCategories);
    },
    [amountPerTaxCategories, onChange],
  );

  const handleChangeTaxAmount = useCallback(
    (taxCategoryId: string, taxAmount: number) => {
      const newAmountPerTaxCategories = amountPerTaxCategories.map((o) => {
        if (o.taxCategory.id === taxCategoryId) {
          return {
            ...o,
            taxAmount,
          };
        }
        return o;
      });

      useInputtedRef.current = true;
      if (onChange) onChange(newAmountPerTaxCategories);
    },
    [amountPerTaxCategories, onChange],
  );

  return {
    isShowNameColumn,
    withTaxRows,
    withoutTaxRows,
    handleChangeAmount,
    handleChangeTaxAmount,
    userInputtedRef: useInputtedRef,
  };
};

/**
 * 税区分名称を表示するかどうかを返す。
 * @return [Boolean] 税区分名称を表示する場合: true, それ以外: false
 */
const showNameColumn = (authority, amountPerTaxCategories): boolean => {
  const { isAdmin, isAccountant, isApprover, isAuditor } = authority;

  const multipleTaxesApplied = amountPerTaxCategories.length > 1;

  // 税区分候補が複数存在、または税区分入力が有効、または管理者、承認者、集計者、監査人のいずれかの場合、税区分名称を表示する。
  return (
    multipleTaxesApplied || isAdmin || isApprover || isAccountant || isAuditor
  );
};
