import FileDropAreaV2 from "components/FileDropAreaV2";
import i18next from "i18n";
import React, { FC } from "react";
import styled from "styled-components";

export type DropAreaElement = HTMLElement & { clearFiles: () => void };

export interface DropAreaProps {
  uploading: boolean;
  anchorRef?: React.RefObject<DropAreaElement>;
  importFile: File | null;
  fileExtensions: Array<string>;
  onFileSelect: (files: File | File[]) => void;
}

export const Wrapper = styled.div`
  padding-right: 30px;
  padding-left: 30px;
`;

const DropArea: FC<DropAreaProps> = ({
  uploading,
  anchorRef,
  importFile,
  fileExtensions,
  onFileSelect,
}) => {
  return (
    <Wrapper className="form-group">
      {uploading ? (
        <div className="droparea">
          {i18next.t("transactions.imports.uploading")}
        </div>
      ) : (
        <FileDropAreaV2
          ref={anchorRef}
          fileNames={importFile ? [importFile.name] : []}
          multiple={false}
          validContentTypes={fileExtensions}
          onSelect={onFileSelect}
        />
      )}
    </Wrapper>
  );
};

export default DropArea;
