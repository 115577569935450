import React from 'react';
import { NotificationMessage } from '../types/index';

interface Props {
  /** 注釈文言 */
  readonly notification: NotificationMessage;
}

/**
 * ログインページ: 注釈
 */
export const Notification: React.FC<Props> = ({ notification }) => {
  // メッセージが無ければ何も表示しない
  if (!notification.message) {
    return null;
  }

  let icon: null | JSX.Element = null;
  if (notification.className !== 'txt-info') {
    icon = <span className='fa fa-fw fa-exclamation-triangle' />;
  }

  return (
    <div className={ `form-group ${notification.className}` } >
      <p role='note'>
        {icon}
        {notification.message}
      </p>
    </div>
  );
};
