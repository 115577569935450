import PropTypes from 'prop-types';
import React, { Component } from 'react';

export default class ColorSelector extends Component {
  render() {
    // デフォルトだと、10x2のサイズで表示する
    const containerStyle = {
      display: 'flex',
      flexWrap: 'wrap',
      width: '280px',
      ...this.props.containerStyle,
    };
    const blockStyle = {
      width: '28px',
      height: '28px',
      ...this.props.blockStyle,
    };

    return (
      <div style={ containerStyle }>
        {
          this.props.colors.map((color) => {
            return (
              <div key={ `color-${color}` }
                style={ { ...blockStyle, backgroundColor: color } }
                onClick={ (e) => { this.props.onSelect(color); } }
              />
            );
          })
        }
      </div>
    );
  }
}

ColorSelector.defaultProps = {
  containerStyle: {},
  blockStyle: {},
  colors: [
    '#1F77B4',
    '#1EC7E8',
    '#FF7F0E',
    '#FFBB78',
    '#2CA02C',
    '#98DF8A',
    '#D62728',
    '#FF9896',
    '#9467BD',
    '#C5B0D5',
    '#8C564B',
    '#C49C94',
    '#E377C2',
    '#E7B6D2',
    '#7F7F7F',
    '#C7C7C7',
    '#BCBD22',
    '#DBDB8D',
    '#17BECF',
    '#9EDAE5',
  ],
};

ColorSelector.propTypes = {
  containerStyle: PropTypes.object.isRequired, // Rootのdivタグのスタイル
  blockStyle: PropTypes.object.isRequired, // 色表示用のブロックのスタイル
  color: PropTypes.string, // 選択中の色
  colors: PropTypes.arrayOf(PropTypes.string).isRequired, // 選択可能な色
  onSelect: PropTypes.func.isRequired,
};
