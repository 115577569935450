import _flash from 'utilities/flash';
import { ApproveJob } from "utilities/api_payment_requests/models/ApproveJob";
import { ErrorResponse } from "hooks/useFetcher";
import { getMessageFromResponse } from "utilities/Utils";
import {
  useCallback, useEffect, useMemo, useRef,
} from "react";
import { useExponentialBackOff } from 'hooks/useExponentialBackOff';

interface ApproveJobActions {
  readonly subscribe: (subscriber: Subscriber) => void;
  readonly unsubscribe: (subscriber: Subscriber) => void;
}

type Subscriber = (approveJobs: ApproveJob[]) => void;

/**
 * 承認のジョブの監視のロジック
 */
export const useApproveJobs = (
  subscribingRequestIds: string[],
  fetchApproveJobs: (request) => Promise<ApproveJob[]>,
  isWindowActive: boolean,
  flash = _flash,
): ApproveJobActions => {
  const subscribers = useRef<Subscriber[]>([]);

  const updateApproveJobs = useCallback(async (): Promise<void> => {
    if (subscribingRequestIds.length === 0) return;
    try {
      const approveJobs = await fetchApproveJobs({ approvalIds: subscribingRequestIds });
      subscribers.current.forEach((subscriber) => subscriber(approveJobs));
    } catch (e) {
      flash.error(getMessageFromResponse(e as ErrorResponse));
    }
  }, [fetchApproveJobs, flash, subscribingRequestIds]);

  const isSubscribing = useMemo(() => isWindowActive && (subscribingRequestIds.length > 0), [isWindowActive, subscribingRequestIds]);

  const { reset } = useExponentialBackOff(isSubscribing, updateApproveJobs);

  /**
   * ポーリングでのデータ取得を開始する: 1秒間隔
   */
  const subscribe = useCallback((subscriber: Subscriber) => {
    subscribers.current.push(subscriber);
  }, []);

  /**
   * ポーリングでのデータ取得を停止する
   */
  const unsubscribe = useCallback((subscriber: Subscriber) => {
    const removedSubscribers = subscribers.current.filter((s) => s !== subscriber);
    subscribers.current = removedSubscribers;
  }, []);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => reset(), [subscribingRequestIds]);

  return {
    subscribe,
    unsubscribe,
  };
};
