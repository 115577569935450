import {
  ExpenseTableColumn,
  TabelColumnParams,
} from "applications/expenses/types";
import i18next from "i18n";
import { getReportTitleLabel } from "utilities/Utils";

/** 経費テーブルの列情報
 *  大きいため、applications/transactions/selectors/transactionTable.jsから切り出し。
 */
export const expenseTableColumns = (
  reactTableColumnParams: TabelColumnParams,
  authority: string,
  reportRequiresWithholding: boolean,
  hasPreReport: boolean,
  scope: boolean,
): ExpenseTableColumn[] => {
  const listExpenseTableColumn: ExpenseTableColumn[] = [
    {
      accessor: "isSelected",
      Header: "",
      width: 0,
      defaultCanSort: false,
      selectable: false,
      show: false,
      index: null,
    },
    {
      accessor: "id",
      Header: "",
      width: 150,
      minWidth: 10,
      defaultCanSort: false,
      selectable: false,
      show: false,
      index: null,
    },
    {
      accessor: "error",
      Header: "",
      defaultCanSort: false,
      dataAlign: "center",
      selectable: true,
      minWidth: 10,
      ...reactTableColumnParams.error,
    },
    {
      accessor: "read",
      Header: i18next.t("transactions.properties.unread") as string,
      defaultCanSort: true,
      dataAlign: "center",
      selectable: authority === "approver",
      minWidth: 10,
      ...reactTableColumnParams.read,
    },
    {
      accessor: "transactedAt",
      Header: i18next.t("transactions.properties.date") as string,
      defaultCanSort: true,
      selectable: true,
      minWidth: 10,
      ...reactTableColumnParams.transactedAt,
    },
    {
      accessor: "label",
      Header: i18next.t("transactions.properties.label") as string,
      defaultCanSort: false,
      selectable: true,
      minWidth: 130,
      ...reactTableColumnParams.label,
    },
    {
      accessor: "hasValidatedReceiptMatching",
      Header: i18next.t(
        "transactions.properties.hasValidatedReceiptMatching",
      ) as string,
      defaultCanSort: false,
      dataAlign: "center",
      selectable: userPreferences.isPaperlessPlan,
      minWidth: 10,
      ...reactTableColumnParams.hasValidatedReceiptMatching,
    },
    {
      accessor: "isElectronicReceiptImage",
      Header: i18next.t(
        "transactions.properties.isElectronicReceiptImage",
      ) as string,
      defaultCanSort: false,
      dataAlign: "center",
      selectable:
        userPreferences.preference.enableExpenseElectronicReceiptImageFlag,
      minWidth: 10,
      ...reactTableColumnParams.isElectronicReceiptImage,
    },
    {
      accessor: "shopName",
      Header: `${i18next.t("transactions.properties.shop")}/${i18next.t(
        "transactions.properties.visited",
      )}`,
      defaultCanSort: true,
      selectable: true,
      minWidth: 10,
      ...reactTableColumnParams.shopName,
    },
    {
      accessor: "transitPayee",
      Header: i18next.t("transactions.properties.transitPayee") as string,
      defaultCanSort: false,
      dataAlign: "left",
      minWidth: 10,
      selectable: true,
      ...reactTableColumnParams.transitPayee,
    },
    {
      accessor: "amount",
      Header: i18next.t("transactions.properties.amount") as string,
      defaultCanSort: true,
      dataAlign: "right",
      selectable: true,
      minWidth: 10,
      ...reactTableColumnParams.amount,
    },
    {
      accessor: "withholding",
      Header: i18next.t("transactions.properties.withholding") as string,
      defaultCanSort: false,
      dataAlign: "right",
      selectable:
        userPreferences.preference.rootGroupRequiresWithholding &&
        reportRequiresWithholding,
      minWidth: 10,
      ...reactTableColumnParams.withholding,
    },
    {
      accessor: "ownerName",
      Header: i18next.t("transactions.properties.employeeName") as string,
      defaultCanSort: true,
      selectable: scope, // 検索ボックスで「会社全体」を選ぶとtrueになる
      minWidth: 10,
      ...reactTableColumnParams.ownerName,
    },
    {
      accessor: "categoryName",
      Header: i18next.t("transactions.properties.category") as string,
      defaultCanSort: true,
      selectable: true,
      minWidth: 10,
      ...reactTableColumnParams.categoryName,
    },
    {
      accessor: "expenseAmountPerTaxCategories",
      Header: i18next.t("transactions.properties.taxCategory") as string,
      defaultCanSort: false,
      selectable: true,
      minWidth: 10,
      ...reactTableColumnParams.expenseAmountPerTaxCategories,
    },
    {
      accessor: "comment",
      Header: i18next.t("transactions.properties.comment") as string,
      defaultCanSort: false,
      selectable: true,
      minWidth: 10,
      ...reactTableColumnParams.comment,
    },
    {
      accessor: "companions",
      Header: i18next.t("transactions.properties.companion") as string,
      defaultCanSort: false,
      dataAlign: "center",
      selectable: true,
      minWidth: 10,
      ...reactTableColumnParams.companions,
    },
    {
      accessor: "originAndDestinationByCategory",
      Header: i18next.t(
        "transactions.properties.originAndDestinationByCategory",
      ) as string,
      defaultCanSort: false,
      selectable: true,
      minWidth: 10,
      ...reactTableColumnParams.originAndDestinationByCategory,
    },
    {
      accessor: "visitByCategory",
      Header: i18next.t("transactions.properties.visitByCategory") as string,
      defaultCanSort: false,
      selectable: true,
      minWidth: 10,
      ...reactTableColumnParams.visitByCategory,
    },
    {
      accessor: "purposeByCategory",
      Header: i18next.t("transactions.properties.purposeByCategory") as string,
      defaultCanSort: false,
      selectable: true,
      minWidth: 10,
      ...reactTableColumnParams.purposeByCategory,
    },
    {
      accessor: "project",
      Header: i18next.t("transactions.properties.project") as string,
      defaultCanSort: true,
      selectable: userPreferences.preference.inputProject,
      minWidth: 10,
      ...reactTableColumnParams.project,
    },
    {
      accessor: "department",
      Header: i18next.t("transactions.properties.userDepartment") as string,
      defaultCanSort: true,
      selectable: true,
      minWidth: 10,
      ...reactTableColumnParams.department,
    },
    {
      accessor: "costAllocations",
      Header: i18next.t("transactions.properties.department") as string,
      defaultCanSort: false,
      selectable: userPreferences.preference.inputGroup,
      minWidth: 10,
      ...reactTableColumnParams.costAllocations,
    },
    {
      accessor: "creditCategoryName",
      Header: i18next.t("transactions.properties.creditCategory") as string,
      defaultCanSort: false,
      selectable: true,
      minWidth: 10,
      ...reactTableColumnParams.creditCategoryName,
    },
    {
      accessor: "reportTitle",
      Header: getReportTitleLabel(),
      defaultCanSort: true,
      selectable: true,
      minWidth: 10,
      ...reactTableColumnParams.reportTitle,
    },
    {
      accessor: "isCorporate",
      Header: i18next.t("transactions.properties.noRefund") as string,
      defaultCanSort: false,
      selectable: true,
      minWidth: 10,
      ...reactTableColumnParams.isCorporate,
    },
    {
      accessor: "status",
      Header: i18next.t("transactions.properties.status") as string,
      defaultCanSort: true,
      selectable: true,
      minWidth: 10,
      ...reactTableColumnParams.status,
    },
    {
      accessor: "createdAt",
      Header: i18next.t("transactions.properties.registrationDate") as string,
      defaultCanSort: true,
      selectable: true,
      minWidth: 10,
      ...reactTableColumnParams.createdAt,
    },
    {
      accessor: "reportSequenceNum",
      Header: i18next.t("transactions.properties.reportSequenceNum") as string,
      defaultCanSort: false,
      selectable: true,
      minWidth: 10,
      ...reactTableColumnParams.reportSequenceNum,
    },
    {
      accessor: "preReportSequenceNum",
      Header: i18next.t(
        "transactions.properties.preReportSequenceNum",
      ) as string,
      defaultCanSort: false,
      selectable: hasPreReport,
      minWidth: 10,
      ...reactTableColumnParams.preReportSequenceNum,
    },
    {
      accessor: "registratedNumber",
      Header: i18next.t("transactions.properties.registratedNumber") as string,
      defaultCanSort: false,
      selectable: true,
      minWidth: 10,
      ...reactTableColumnParams.registratedNumber,
    },
    {
      accessor: "asEligibleInvoice",
      Header: i18next.t(
        "transactions.properties.eligibleInvoiceTitle",
      ) as string,
      defaultCanSort: false,
      dataAlign: "center",
      selectable: true,
      minWidth: 10,
      ...reactTableColumnParams.asEligibleInvoice,
    },
  ];

  if (Array.isArray(userPreferences.preference.genericFields)) {
    userPreferences.preference.genericFields.forEach((genericField) => {
      listExpenseTableColumn.push({
        accessor: genericField.id,
        Header: genericField.name as string,
        defaultCanSort: false,
        selectable: true,
        minWidth: 10,
        ...reactTableColumnParams[genericField.id],
      });
    });
  }

  return listExpenseTableColumn;
};
