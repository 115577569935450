import PropTypes from 'prop-types';
import React, { Component } from 'react';
import groupBy from 'lodash/groupBy';
import i18next from 'i18n';

export default class CompanionList extends Component {
  render() {
    const {
      companions, showCompany, className, anonymous,
    } = this.props;
    const allCompanion = anonymous ? companions.concat(anonymous) : companions;

    let companionsByCompany = groupBy(allCompanion, (c) => c.company);
    companionsByCompany = Object.keys(companionsByCompany).map((company) => companionsByCompany[company]);
    return (
      <div className={ `${className} companion-list` }>
        { companionsByCompany.map((companyCompanions, i) => (
          companyCompanions.length > 0 ?  this.renderStaffsList(companyCompanions, showCompany, i) : null)) }
      </div>
    );
  }

  renderStaffsList(staffs, showCompany, id) {
    return (
      <div key={ id }>
        <span className="company-name">{ showCompany ? this.companyName(staffs[0]) : null }</span>
        <ul>
          { staffs.map((staff, i) => (<li key={ i }>{ staff.isCompanionNumber ? `${staff.name} (${staff.number} ${i18next.t('commons.units.person')})` : staff.name }</li>)) }
        </ul>
      </div>
    );
  }

  companyName(staff) {
    return this.props.companyName || staff.company;
  }
}

CompanionList.propTypes = {
  companions: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      company: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired,
    }),
  ).isRequired,
  showCompany: PropTypes.bool.isRequired,
  companyName: PropTypes.string,
};
