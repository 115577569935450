import Checkbox from "components/checkbox";
import MemberSuggestField from "components/fields/MemberSuggestField";
import LabeledComponent from "components/LabeledComponent";
import i18next from "i18n";
import PropTypes from "prop-types";
import React, { Component } from "react";

export default class AdvancedSearchConditions extends Component {
  constructor(props) {
    super(props);

    this.handleOwnerSelect = this.handleOwnerSelect.bind(this);
  }

  get employeeSuggestions() {
    return this.props.employees || [];
  }

  get owner() {
    return this.props.ownerName ? { name: this.props.ownerName } : null;
  }

  handleOwnerSelect(_obj) {
    // TODO: owner.idで検索すべき
    // 何もしない
  }

  render() {
    return (
      <div className={this.props.className}>
        <LabeledComponent
          className="col-sm-3"
          label={i18next.t("transactions.index.searchbox.scope.label")}
        >
          <div className="checkbox-group">
            {this.props.showSearchAll && (
              <Checkbox
                className="squared checkbox-accent"
                label={i18next.t("transactions.index.searchbox.scope.all")}
                defaultChecked={this.props.searchAll}
                onChange={this.props.onScopeChange}
              />
            )}
            {this.props.showDeletedSearch && (
              <Checkbox
                className="squared checkbox-accent"
                label={i18next.t("transactions.index.searchbox.onlyDeleted")}
                defaultChecked={this.props.searchOnlyDeleted}
                onChange={this.props.onOnlyDeletedChange}
              />
            )}
          </div>
        </LabeledComponent>

        {this.props.showEmployeeSearch && (
          <LabeledComponent
            className="col-md-3"
            label={i18next.t("groups.properties.employee")}
          >
            <MemberSuggestField
              text={this.props.ownerName}
              value={this.owner}
              placeholder={i18next.t("transactions.index.searchbox.all")}
              onSelect={this.handleOwnerSelect}
              onTextChange={this.props.onOwnerNameChange}
            />
          </LabeledComponent>
        )}
      </div>
    );
  }
}

AdvancedSearchConditions.defaultProps = {
  showSearchAll: false,
  showEmployeeSearch: false,
  showDeletedSearch: false,
  searchAll: false,
  searchOnlyDeleted: false,
};

AdvancedSearchConditions.propTypes = {
  showSearchAll: PropTypes.bool.isRequired,
  showEmployeeSearch: PropTypes.bool.isRequired,
  showDeletedSearch: PropTypes.bool.isRequired,
  searchAll: PropTypes.bool.isRequired,
  searchOnlyDeleted: PropTypes.bool.isRequired,
  ownerName: PropTypes.string,
  employees: PropTypes.array.isRequired,
  onScopeChange: PropTypes.func.isRequired,
  onOwnerNameChange: PropTypes.func.isRequired,
  onOnlyDeletedChange: PropTypes.func.isRequired,
};
