import React from 'react';
import i18next from 'i18next';
import { TaskBase } from 'utilities/api/models/accountingDataScheduledExports';

interface Props {
  task: TaskBase;
}

export const ScheduleItem: React.FC<Props> = ({
  task,
}) => {
  let dayText = "";

  const schedulePath = "accountingDataScheduledExports.tasks.schedule";

  switch (task.executionInterval) {
    case 'by_month': {
      let byMonthText = "";

      if (task.executionDayLastDayOfMonth) {
        byMonthText = i18next.t(`${schedulePath}.executeAtEndOfEachMonth`);
      } else {
        byMonthText = i18next.t(`${schedulePath}.executionDayOfMonth`, { date: task.executionDayOfMonth });
      }
      dayText = byMonthText;

      break;
    }
    case 'by_weekday': {
      dayText = i18next.t(`${schedulePath}.executionDayOfWeek`, { day: task.executionDayOfWeek });

      break;
    }
    case 'by_day': {
      dayText = i18next.t(`${schedulePath}.executionDaily`);

      break;
    }
    case 'by_once_specified_date': {
      dayText = `${task.executionOnceSpecifiedDate}`;

      break;
    }
    default:
      break;
  }

  const timetableText = task.executionTimetable.name;

  if (task.executionInterval === 'by_once_specified_date') {
    return (<div>{ `${dayText} ${timetableText}` }</div>);
  }

  return (<div>{ `${dayText}: ${timetableText}` }</div>);
};
