import EditableBase from 'components/EditableBase';
import React from 'react';

// 基本的にはEditableInputを経由して使用する
// EditableInputのinputTypeにtext、またはnumberを指定すると、
// このComponentが使用される
export default class EditableText extends EditableBase {
  renderInEditMode() {
    let inputClass = this.props.input ? this.props.input.className : 'editable-text-input';
    if (this.props.sync) {
      inputClass += ' input-sync';
    } else if (this.props.sync === false) {
      inputClass += ' input-in-edit';
    }

    const { updateOnBlur } = this.props;
    const saveButton = (
      <span className='input-group-btn'>
        <button type='button'
          id={ this.buttonId }
          className={ this.props.button.className }
          onMouseDown={ this.onUpdate }
        >
          { this.props.button.text }
        </button>
      </span>
    );

    return (
      <form className='edit-text-form'>
        <div className={ `${updateOnBlur ? '' : 'input-group '}col-sm-12` }>
          <input
            type='text'
            id={ this.id }
            ref={ (ref) => { this._input = ref; } }
            className={ inputClass }
            placeholder={ this.props.input.placeholder }
            value={ this.props.editingText }
            onChange={ this.onTextChange }
            onBlur={ this.handleBlur }
            onKeyPress={ this.handleKeyPress }
          />
          { updateOnBlur ? null : saveButton }
        </div>
      </form>
    );
  }
}
