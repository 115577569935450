import i18next from "i18next";
import React, { useCallback } from "react";
import { Modal } from "react-bootstrap";
import { OperatorEntry } from "utilities/api_expenses/models/expenses/OperatorEntry";
import formatter from "utilities/formatter";

interface Props {
  /** オペレータ入力結果 */
  readonly operatorEntry: OperatorEntry;
  /** モーダルを表示するか */
  readonly show: boolean;
  /** そのまま保存する場合 */
  readonly onAccept: () => void;
  /** 修正する場合 */
  readonly onClose: () => void;
}

const I18N_PREFIX = "expenses.expenses.components.operatorEntryConfirmModal";

/**
 * 税区分別金額の入力値がオペレータ入力結果と異なる場合に確認するモーダル
 */
export const OperatorEntryConfirmModal: React.FC<Props> = ({
  operatorEntry,
  show,
  onAccept,
  onClose,
}) => {
  const handleAccept = useCallback(() => {
    onClose();
    onAccept();
  }, [onClose, onAccept]);

  return (
    <Modal show={show} onHide={onClose}>
      <Modal.Header>
        <Modal.Title>{i18next.t(`${I18N_PREFIX}.header.title`)}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <p>{i18next.t(`${I18N_PREFIX}.body.message1`)}</p>
        <ul>
          {operatorEntry.subtotals.map((subtotal, index) => (
            <li key={index}>
              {subtotal.taxRate * 100}%:{" "}
              {i18next.t(`${I18N_PREFIX}.body.subtotal`, {
                amount: formatter.amount(subtotal.amount),
                taxAmount: formatter.amount(subtotal.taxAmount),
              })}
            </li>
          ))}
        </ul>
        <p>{i18next.t(`${I18N_PREFIX}.body.message2`)}</p>
      </Modal.Body>
      <Modal.Footer>
        <button onClick={onClose} className="btn btn-default">
          {i18next.t(`${I18N_PREFIX}.footer.reject`)}
        </button>
        <button onClick={handleAccept} className="btn btn-accept">
          {i18next.t(`${I18N_PREFIX}.footer.accept`)}
        </button>
      </Modal.Footer>
    </Modal>
  );
};
