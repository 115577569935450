import Api from 'utilities/api';
import Formatter from 'utilities/formatter';
import ListSelector from 'components/list_selector';
import React, { useEffect, useState } from 'react';
import escapeRegExp from 'lodash/escapeRegExp';
import flash from 'utilities/flash';
import i18next from 'i18n';
import { CompanyExpenseAccount } from 'utilities/api/models/CompanyExpenseAccount';
import { Item } from './Item';
import { getMessageFromResponse } from 'utilities/Utils';

interface Props {
  allowEmpty: boolean;
  onSelect: (account: CompanyExpenseAccount) => void;
  value: CompanyExpenseAccount;
}

/** システム設定における支払口座から候補を選択するセレクタ */
export const CompanyExpenseAccountSelector = ({
  allowEmpty,
  onSelect,
  value,
}: Props): React.ReactElement => {
  const [companyExpenseAccounts, setCompanyExpenseAccounts] = useState<CompanyExpenseAccount[]>([]);

  useEffect(() => {
    async function fetchData(): Promise<void> {
      try {
        const response = await Api.companyExpenseAccounts.index();
        setCompanyExpenseAccounts(response);
      } catch (error) {
        flash.error(getMessageFromResponse(error as Record<string, unknown>));
      }
    }
    fetchData();
  }, []);

  const itemFilter = (text: string, items: CompanyExpenseAccount[]): CompanyExpenseAccount[] => {
    return items.filter((selection) => {
      const reg = new RegExp(`.*${escapeRegExp(text)}.*`, 'g');
      const bank = selection.bank.name || '';
      const bankBranch = selection.bankBranch.name || '';
      const number = selection.number || '';
      return bank.match(reg)
        || bankBranch.match(reg)
        || number.match(reg)
        || false;
    });
  };

  return (
    <ListSelector
      allowEmpty={ allowEmpty }
      buttonFormat={ Formatter.expenseAccount }
      disabled={ companyExpenseAccounts.length <= (allowEmpty ? 0 : 1) }
      itemFormat={ (item: CompanyExpenseAccount): React.ReactElement => {
        return (<Item item={ item } />);
      } }
      items={ companyExpenseAccounts }
      onSelect={ onSelect }
      search={ {
        placeholder: i18next.t('exports.actions.searchWithAccount'),
        func: itemFilter,
        onTextChange: (): undefined => undefined,
      } }
      titleFormat={ (): string => i18next.t('exports.titles.expenseAccountSelector') }
      type='radio'
      value={ value }
      showSearch={ false }
      showSaveButton={ false }
    />
  );
};
