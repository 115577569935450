import React from 'react';
import fonts from 'values/fonts';
import styled from 'styled-components';
import { MenuItem as BsMenuItem } from 'react-bootstrap';

const textColor = (disabled? : boolean, color?: string): string => {
  if (disabled) return 'gray';
  return color || '#020b16';
};

const MenuItemView = styled(BsMenuItem)<Props>`
  display: ${({ hidden }): string => (hidden ? 'none' : 'list-item')};
  margin-left: 0 !important;
  i {
    width: 20px;
    text-align: center;
    margin-right: 10px;
    font-size: 16px;
  }
  // aタグにデフォルトスタイルがあるので!importantで上書き
  a {
    display: flex !important;
    align-items: center !important;
    padding: 10px 20px !important;
    color: ${({ disabled, color }): string => textColor(disabled, color)} !important;
    font-weight: ${fonts.weight.bold} !important;
    width: 100% !important;
    outline: none !important;
    cursor: ${({ disabled }): string => (disabled ? 'not-allowed' : 'pointer')} !important;
  }
  // id='label'の項目に付与
  #label {
    min-width: 280px;
    :hover,
    :focus {
      cursor: auto;
      background: none;
    }
  }
`;

interface Props {
  /** aタグのhref */
  href?: string;
  /** aタグのtarget */
  target?: '_blank';
  /** aタグのrel */
  rel?: string;
  /** クリックした際のハンドラ */
  onClick?: () => void;
  /** label属性 */
  id?: string;
  /** 線にするプロパティ */
  divider?: boolean;
  /** 文字色コード */
  color?: string;
  /** 不活性にするか */
  disabled?: boolean;
  /** 非表示にするか */
  hidden?: boolean;
  /** class名 */
  className?: string;
}

/** react-bootstrapのMenuItemのラッパー */
export const MenuItem: React.FC<Props> = (props) => {
  const handleClick = (): void => {
    if (!props.onClick || props.disabled) return;
    props.onClick();
  };
  return <MenuItemView { ...props } onClick={ handleClick } className={ `drop-down-menu-item ${props.disabled && 'disabled'}${!props.className ? '' : ` ${props.className}`}` } />;
};
