import  * as ActionTypes from 'applications/requests/actions/actionType';
import { RequestsSearchResult } from 'utilities/api/responses/requests';
import { StateRequestsDeleteModal } from 'applications/requests/interface/deleteModal';

interface Action {
  /** アクションタイプ */
  readonly type: string;
  readonly reportType: string;
  readonly reportTypeId: string;
  readonly id: RequestsSearchResult | null;
  readonly showModal: boolean;
}

/**
 * State: 初期値
 */
const initialState: StateRequestsDeleteModal = {
  targetId: null,
  reportType: '',
  reportTypeId: '',
  showModal: false,
};

/**
 * Redux reducers: 削除モーダル
 */
const deleteModal = (state: StateRequestsDeleteModal = initialState, action: Action): StateRequestsDeleteModal => {
  switch (action.type) {
    case ActionTypes.SET_DELETE_TARGET_ID:
      return {
        ...state,
        reportType: action.reportType,
        targetId: action.id,
        reportTypeId: action.reportTypeId,
      };
    case ActionTypes.TOGGLE_MODAL:
      return {
        ...state,
        showModal: action.showModal,
      };
    default:
      return state;
  }
};

export default deleteModal;
