import { RefObject, useCallback, useEffect, useRef, useState } from "react";

/**
 * ツールバー最小横幅
 */
const MIN_WIDTH = 380;

interface UseReturn {
  readonly wrapperRef: RefObject<HTMLDivElement>;
  readonly isShowSubBar: boolean;
  readonly scalePercent: number;
}

/**
 * ツールバーロジック
 */
export const useHooks = (scaleSize: number): UseReturn => {
  const wrapperRef = useRef<HTMLDivElement>(null);
  /**
   * ツールバーの２段目を表示するか
   */
  const [isShowSubBar, setIsSubBar] = useState<boolean>(false);

  /**
   * サブバー表示を変更したか
   */
  let isChangedSubBar = false;

  /**
   * 横幅が小さくなったら２段目を表示する
   */
  const changeWidth = useCallback(
    (ref: React.RefObject<HTMLDivElement>): void => {
      const width = ref.current?.offsetWidth || 0;
      if (width < MIN_WIDTH && !isChangedSubBar) {
        setIsSubBar(true);
        // eslint-disable-next-line react-hooks/exhaustive-deps
        isChangedSubBar = true;
      } else if (width >= MIN_WIDTH && isChangedSubBar) {
        setIsSubBar(false);
        isChangedSubBar = false;
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );

  /**
   * 横幅の取得
   */
  useEffect(() => {
    changeWidth(wrapperRef);
  }, [wrapperRef.current?.offsetWidth, changeWidth]);

  /**
   * 横幅の変更を取得する
   */
  useEffect(() => {
    window.addEventListener("resize", () => changeWidth(wrapperRef));
  }, [wrapperRef, changeWidth]);

  /**
   * 拡大率のパーセント
   */
  const scalePercent = Math.floor(scaleSize * 100);

  return {
    wrapperRef,
    isShowSubBar,
    scalePercent,
  };
};
