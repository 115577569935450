import React from 'react';
import i18next from 'i18next';
import { AnalysisAggregationConditions } from 'utilities/api/models/accountingDataScheduledExports/AnalysisAggregationConditions';
import { Item } from './Item';

type Label = "departmentSearchRange" | "aggregationStatus" | "exportationStatus" | "paymentStatus" | "temporaryPaymentStatus" | "payOffStatus" | "matchedOriginalReceiptStatus" | "asInvoice";

interface Props {
  label: Label;
  conditions: AnalysisAggregationConditions;
}

export const IncludeItem: React.FC<Props> = ({
  label,
  conditions,
}) => {
  const conditionPath = "accountingDataScheduledExports.tasks.analysisAggregationConditions";

  const targetKeys: string[] = [];

  // labelに対応する項目をtargetKeysに追加する
  switch (label) {
    case "departmentSearchRange": // 部署検索範囲
      targetKeys.push("includeChildDepartment");
      break;
    case "aggregationStatus": // 集計状況
      targetKeys.push("includeAggregated", "includeNotAggregated");
      break;
    case "exportationStatus": // 会計データ出力状況
      targetKeys.push("includeExported", "includeNotExported");
      break;
    case "paymentStatus": // 支払い状況
      targetKeys.push("includePaid", "includeNotPaid");
      break;
    case "temporaryPaymentStatus": // 仮払い状況
      targetKeys.push("includeTemporaryPaid", "includeNotTemporaryPaid");
      break;
    case "payOffStatus": // 精算状況
      targetKeys.push("includeSettled", "includeUnsettled");
      break;
    case "matchedOriginalReceiptStatus": // 精算状況
      targetKeys.push("matchedOriginalReceipt", "notMatchedOriginalReceipt");
      break;
    case 'asInvoice': // 適格請求書として扱う
      targetKeys.push("includeAsInvoice", "includeNotAsInvoice");
      break;
    default:
      break;
  }

  const selectedKeys: string[] = [];
  targetKeys.forEach((targetKey) => {
    if (conditions[targetKey] === true) {
      selectedKeys.push(targetKey);
    }
  });

  if (selectedKeys.length === 0) { return <></>; }

  const displayKeys = <>{ selectedKeys.map((key, i) => <div key={ i }>{ i18next.t(`${conditionPath}.${key}`) }</div>) }</>;

  return (
    <Item label={ i18next.t(`${conditionPath}.includeLabel.${label}`) } value={ displayKeys }></Item>
  );
};
