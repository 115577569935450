import Api from 'utilities/api';
import React, { useState } from 'react';
import flash from 'utilities/flash';
import i18next from 'i18next';
import styled from 'styled-components';
import { JobRequest } from 'utilities/api/models/accountingDataScheduledExports';
import { getMessageFromResponse } from 'utilities/Utils';

interface Props {
  jobRequest: null | JobRequest;
}

const Link = styled.a`
  &:hover {
    cursor: pointer;
  }
`;

const Icon = styled.i`
  margin-right: 0.5rem;
`;

export const ReExecuteItem: React.FC<Props> = ({
  jobRequest,
}) => {
  const [processing, setProcessing] = useState(false);

  const jobCompletion = jobRequest?.jobCompletion;
  const errorLog = jobRequest?.jobExecution?.logs?.find((log) => log.level === 'error');
  if (!(jobCompletion && errorLog)) { return <></>; }

  const reExecute = async (e, jobRequestId): Promise<void> => {
    e.stopPropagation();

    if (processing) return;

    setProcessing(true);

    try {
      const { message } = await Api.accountingDataScheduledExports.jobs.reExecute({ id: jobRequestId });
      flash.success(message);
    } catch (error) {
      flash.error(getMessageFromResponse(error as Record<string, unknown>));
    } finally {
      setProcessing(false);
    }
  };

  const jobRequestId = jobRequest?.id;
  const reExecuteText = i18next.t('accountingDataScheduledExports.jobRequests.latestJobExecution.reExecute');

  return (
    <div>
      <Link onClick={ (e): Promise<void> => reExecute(e, jobRequestId) }><Icon className='text-success fas fa-redo'></Icon>{ reExecuteText }</Link>
    </div>
  );
};
