import React, { useCallback, useEffect, useState } from "react";
import { connect } from "react-redux";
import { TDispatch } from "tdispatch";
import { ReceiptExpenseMatching } from "utilities/api/models/ReceiptExpenseMatching";
import { CancelMarkedAsMatchedRequest } from "utilities/api/requests/expenseRequests";
import { MarkAsMatchedRequest } from "utilities/api/requests/expenseRequests/MarkAsMatchedRequest";
import * as ActionCreators from "../actions/MarkingAsMatched/ActionCreators";
import * as AsyncAction from "../actions/MarkingAsMatched/AsyncAction";
import MarkingAsMatchedModal from "../components/modals/MarkingAsMatchedModal"; // eslint-disable-line import/no-unresolved
import RevisingMarkedAsMatchedModal from "../components/modals/RevisingMarkedAsMatchedModal";
import { State as MarkingAsMatchedState } from "../reducers/MarkingAsMatched";

interface Props extends MarkingAsMatchedState {
  cancelMarkedAsMatched: (payload: CancelMarkedAsMatchedRequest) => void;
  closeModal: () => void;
  expenseId: string;
  markAsMatched: (payload: MarkAsMatchedRequest) => void;
  onSubmitSuccess: () => void;
  receiptExpenseMatching: ReceiptExpenseMatching | null;
}

interface State {
  markingAsMatched: MarkingAsMatchedState;
}

const buildInitialPayload = (
  id: Props["expenseId"],
  receiptExpenseMatching: Props["receiptExpenseMatching"],
): MarkAsMatchedRequest => ({
  comment: receiptExpenseMatching?.comment ?? "",
  id,
});

const MarkingAsMatchedModalContainer: React.FC<Props> = (props) => {
  const {
    cancelMarkedAsMatched,
    closeModal,
    expenseId,
    isLoading,
    isModalOpen,
    markAsMatched,
    receiptExpenseMatching,
  } = props;

  const [payload, setPayload] = useState<MarkAsMatchedRequest>(
    buildInitialPayload(expenseId, receiptExpenseMatching),
  );

  const handleCommentChange = useCallback(
    (comment: string): void => setPayload({ ...payload, comment }),
    [payload],
  );
  const handleSubmit = useCallback(
    (): void => markAsMatched(payload),
    [markAsMatched, payload],
  );
  const handleCancel = useCallback(
    (): void => cancelMarkedAsMatched({ id: expenseId }),
    [cancelMarkedAsMatched, expenseId],
  );

  // モーダルの対象経費・表示/非表示切り替えごとにペイロードをリセット
  useEffect(() => {
    setPayload(buildInitialPayload(expenseId, receiptExpenseMatching));
  }, [expenseId, receiptExpenseMatching, isModalOpen]);

  return (
    <div>
      {receiptExpenseMatching ? (
        <RevisingMarkedAsMatchedModal
          close={closeModal}
          comment={payload.comment}
          disabled={isLoading}
          onCancelButtonClick={handleCancel}
          onCommentChange={handleCommentChange}
          onUpdateButtonClick={handleSubmit}
          show={isModalOpen}
        />
      ) : (
        <MarkingAsMatchedModal
          close={closeModal}
          comment={payload.comment}
          disabled={isLoading}
          onCommentChange={handleCommentChange}
          onSubmitButtonClick={handleSubmit}
          show={isModalOpen}
        />
      )}
    </div>
  );
};

function mapStateToProps(state: State): MarkingAsMatchedState {
  return {
    ...state.markingAsMatched,
  };
}

/** @todo object型は使わないように */
// eslint-disable-next-line @typescript-eslint/ban-types
function mapDispatchToProps(dispatch: TDispatch, ownProps: Props): object {
  return {
    closeModal(): void {
      dispatch(ActionCreators.closeModal());
    },
    cancelMarkedAsMatched(payload: CancelMarkedAsMatchedRequest): void {
      dispatch(
        AsyncAction.cancelMarkedAsMatched(payload, ownProps.onSubmitSuccess),
      );
    },
    markAsMatched(payload: MarkAsMatchedRequest): void {
      dispatch(AsyncAction.markAsMatched(payload, ownProps.onSubmitSuccess));
    },
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(MarkingAsMatchedModalContainer);
