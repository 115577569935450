import * as Types from "../types";

/**
 * react-selectのGroupd表示用に経費科目配列を成型
 * @param categories 経費科目一覧
 * @return react-selectのGroupd表示に対応した経費科目一覧
 */
export const groupedCategories = (
  categories: Types.Category[],
): (Types.Category | Types.GroupedCategory)[] => {
  // 配列長を合わせる("親科目"と"親子関係を持たない単独科目"のみの配列を生成)
  const groupAndIndependentCategories = categories.filter((category) => {
    const hasParentCategory = !!category.parentId;
    const hasChildCategory = categories.some((c) => c.parentId === category.id);
    const isSmallestChild = hasParentCategory && !hasChildCategory;

    return !isSmallestChild;
  });

  return groupAndIndependentCategories.map((category) => {
    // "親子関係を持たない単独科目" の場合は科目オブジェクトをそのまま返す
    const hasParent = !!category.parentId;
    const hasChild = categories.some((c) => c.parentId === category.id);
    const isIndependentCategory = !hasParent && !hasChild;

    if (isIndependentCategory) return category;

    // "親科目" の場合はラベルと子科目配列を持ったオブジェクトを返す
    const childCategories = categories.filter((c) => {
      const isChildOfThisCategory = c.parentId === category.id;
      const isParentOfSomeone = categories.some((cc) => cc.parentId === c.id);
      const isSmallestChild = isChildOfThisCategory && !isParentOfSomeone;
      return isSmallestChild;
    });

    return {
      label: category.absolutePathName,
      options: childCategories,
    };
  });
};
