import Clearable from "components/fields/Clearable";
import LabeledComponent from "components/LabeledComponent";
import TextArea from "components/TextArea";
import i18next from "i18n";
import React, { useCallback } from "react";

interface Props {
  className?: React.CSSProperties;
  comment: string;
  disabled?: boolean;
  onCommentChange: (comment: string) => void;
}

const MarkingAsMatchedForm: React.FC<Props> = (props) => {
  const { comment, disabled = false, onCommentChange } = props;

  const handleCommentInputClear = useCallback(
    (): void => onCommentChange(""),
    [onCommentChange],
  );
  const handleCommentInputChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>): void =>
      onCommentChange(e.target?.value || ""),
    [onCommentChange],
  );

  return (
    <div className="row form-group">
      <LabeledComponent
        className="col-sm-offset-2 col-sm-8"
        label={i18next.t(
          "paperless.expense.forms.markingAsMatchedForm.labels.comment",
        )}
      >
        <Clearable onClear={handleCommentInputClear}>
          <TextArea
            className="form-control"
            disabled={disabled}
            maxLength={255}
            onChange={handleCommentInputChange}
            placeholder={i18next.t(
              "paperless.expense.forms.markingAsMatchedForm.placeholders.comment",
            )}
            value={comment}
          />
        </Clearable>
      </LabeledComponent>
    </div>
  );
};

export default MarkingAsMatchedForm;
