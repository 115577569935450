import PropTypes from "prop-types";
import React, { Component } from "react";
import { Modal } from "react-bootstrap";
import AccountingExportForm from "../containers/AccountingExportForm";
import AnalysisXlsExportForm from "../containers/AnalysisXlsExportForm";
import { ApplicationExportForm } from "../containers/ApplicationExportForm";
import CategoryAnalysisExportForm from "../containers/CategoryAnalysisExportForm";
import { PreReportExportForm } from "../containers/PreReportExportForm";
import { ReportExportForm } from "../containers/ReportExportForm";
import UserAnalysisExportForm from "../containers/UserAnalysisExportForm";

export default class ExportJobModal extends Component {
  renderBody(isShow, formName) {
    if (!isShow) {
      return null;
    }
    switch (formName) {
      case "CategoryAnalysisExportForm": {
        return <CategoryAnalysisExportForm />;
      }
      case "UserAnalysisExportForm": {
        return <UserAnalysisExportForm />;
      }
      case "AnalysisXlsExportForm": {
        return <AnalysisXlsExportForm />;
      }
      case "AccountingExportForm": {
        return (
          <AccountingExportForm
            formatKey={this.props.formatKey}
            jobName={this.props.jobName}
            targetId={this.props.targetId}
            targetKey={this.props.targetKey}
            transactedAt={this.props.transactedAt}
          />
        );
      }
      case "ReportExportForm": {
        return <ReportExportForm onExportCsv={this.props.onExportCsv} />;
      }
      case "PreReportExportForm": {
        return (
          <PreReportExportForm params={this.props.preReportsExportParams} />
        );
      }
      case "ApplicationExportForm": {
        return <ApplicationExportForm params={this.props.applicationsExportParams} />;
      }
      default: {
        return null;
      }
    }
  }

  render() {
    return (
      <Modal show={this.props.show} onHide={this.props.closeModal} bsSize="lg">
        <Modal.Header closeButton>
          <Modal.Title>{this.props.title}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {this.renderBody(this.props.show, this.props.formName)}
        </Modal.Body>
      </Modal>
    );
  }
}

ExportJobModal.propTypes = {
  closeModal: PropTypes.func.isRequired,
  formatKey: PropTypes.string,
  formName: PropTypes.string.isRequired,
  jobName: PropTypes.string,
  show: PropTypes.bool.isRequired,
  targetId: PropTypes.string,
  targetKey: PropTypes.string,
  title: PropTypes.string.isRequired,
  transactedAt: PropTypes.string,
  preReportsExportParams: PropTypes.object,
  applicationsExportParams: PropTypes.object,
};
