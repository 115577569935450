import { ReportHistoryCard } from "applications/reports/containers/ReportHistoryCard";
import { connect } from "react-redux";
import * as actions from "../actions/reportHistories";
import mapStateToProps from "../selectors/reportHistory";

function mapDispatchToProps(dispatch) {
  return {
    selectTransaction(expense, entryForm) {
      return dispatch(actions.selectTransaction(expense, entryForm));
    },
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(ReportHistoryCard);
