import React from 'react';
import i18next from 'i18next';
import styled from 'styled-components';
import {
  ControlLabel,
  FormControl,
  FormGroup,
  Label,
} from 'react-bootstrap';
import { FileTransferLocation } from 'utilities/api/models/accountingDataScheduledExports';
import { LoadingIcon } from '../components/icons/LoadingIcon';

interface Props {
  fileTransferLocation?: FileTransferLocation | null;
}

const protocolLabels = {
  sftp: 'SFTP',
  ftp: 'FTP',
};

const authMethodLabels = {
  password: i18next.t('accountingDataScheduledExports.fileTransferLocations.form.authMethodPassword'),
};

const connectionConfirmation = (confirm: boolean | null): JSX.Element => {
  if (confirm === null) {
    return <Label bsStyle='default'>{ i18next.t('accountingDataScheduledExports.fileTransferLocations.form.connectionNoCheck') }</Label>;
  }
  if (confirm) {
    return <Label bsStyle='success'>{ i18next.t('accountingDataScheduledExports.fileTransferLocations.form.connectionSuccess') }</Label>;
  }
  return <Label bsStyle='danger'>{ i18next.t('accountingDataScheduledExports.fileTransferLocations.form.connectionFail') }</Label>;
};

export const FormView = styled.form`
  overflow-y: scroll;
  max-height: calc(100vh - 120px);
`;

export const ReadOnlyForm: React.FC<Props> = ({
  fileTransferLocation,
}) => {
  return (
    <FormView>
      <FormGroup>
        <ControlLabel>{ i18next.t('accountingDataScheduledExports.fileTransferLocations.form.name') }</ControlLabel>
        <FormControl.Static>{ fileTransferLocation?.name || <LoadingIcon /> }</FormControl.Static>
      </FormGroup>
      <FormGroup>
        <ControlLabel>{ i18next.t('accountingDataScheduledExports.fileTransferLocations.form.description') }</ControlLabel>
        <FormControl.Static>{ fileTransferLocation?.description || <LoadingIcon /> }</FormControl.Static>
      </FormGroup>
      <FormGroup>
        <ControlLabel>{ i18next.t('accountingDataScheduledExports.fileTransferLocations.form.protocol') }</ControlLabel>
        <FormControl.Static>{ fileTransferLocation ? protocolLabels[fileTransferLocation?.protocol] : <LoadingIcon /> }</FormControl.Static>
      </FormGroup>
      <FormGroup>
        <ControlLabel>{ i18next.t('accountingDataScheduledExports.fileTransferLocations.form.host') }</ControlLabel>
        <FormControl.Static>{ fileTransferLocation?.host || <LoadingIcon /> }</FormControl.Static>
      </FormGroup>
      <FormGroup>
        <ControlLabel>{ i18next.t('accountingDataScheduledExports.fileTransferLocations.form.port') }</ControlLabel>
        <FormControl.Static>{ fileTransferLocation?.port || <LoadingIcon /> }</FormControl.Static>
      </FormGroup>
      <FormGroup>
        <ControlLabel>{ i18next.t('accountingDataScheduledExports.fileTransferLocations.form.authMethod') }</ControlLabel>
        <FormControl.Static>{ fileTransferLocation ? authMethodLabels[fileTransferLocation.authMethod] : <LoadingIcon /> }</FormControl.Static>
      </FormGroup>
      <FormGroup>
        <ControlLabel>{ i18next.t('accountingDataScheduledExports.fileTransferLocations.form.account') }</ControlLabel>
        <FormControl.Static>{ fileTransferLocation?.account || <LoadingIcon /> }</FormControl.Static>
      </FormGroup>
      <FormGroup>
        <ControlLabel>{ i18next.t('accountingDataScheduledExports.fileTransferLocations.form.directory') }</ControlLabel>
        <FormControl.Static>{ fileTransferLocation?.directory || <LoadingIcon /> }</FormControl.Static>
      </FormGroup>
      <FormGroup>
        <ControlLabel>{ i18next.t('accountingDataScheduledExports.fileTransferLocations.form.connectionConfirmation') }</ControlLabel>
        <FormControl.Static>{ fileTransferLocation ? connectionConfirmation(fileTransferLocation.connectionConfirmationSuccessful) : <LoadingIcon /> }</FormControl.Static>
      </FormGroup>
    </FormView>
  );
};
