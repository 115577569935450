import PropTypes from 'prop-types';
import React, { Component } from 'react';
import i18next from 'i18n';
import isNil from 'lodash/isNil';
import { Button } from 'react-bootstrap';

export class RemoteSearchBox extends Component {
  render() {
    const {
      onTextChange, onClickSubmit,
      inputText, onClickResetSearchConditionButton, searchPlaceholder,
    } = this.props;

    const materialIconStyle = {
      fontSize: '15px',
      verticalAlign: 'middle',
      paddingBottom: '3px',
    };

    return (
      <div>
        {
          !isNil(onClickResetSearchConditionButton) ? (
            <div className = 'text-right'>
              <i className = 'material-icons' style={ materialIconStyle }>clear</i>
              <a onClick = { onClickResetSearchConditionButton }>
                { i18next.t('transactions.index.searchbox.resetCondition') }
              </a>
            </div>
          ) : null
        }
        <form className = 'list-selector-item-form'>
          <div className = 'form-group'>
            <input
              type = 'text'
              className = 'form-control'
              placeholder = { searchPlaceholder }
              value = { inputText }
              onKeyDown={ this.onKeyDown.bind(this) }
              onChange = { (e) => { onTextChange(e); } }
            />
          </div>
          {
            !isNil(onClickSubmit) ? (
              <div className = 'form-group wrap-child'>
                <Button
                  className = 'btn btn-accent'
                  type = 'button'
                  onClick = { (e) => { onClickSubmit(e); } }
                > { i18next.t('commons.actions.search') } </Button>
              </div>
            ) : null
          }
        </form>
      </div>
    );
  }

  onKeyDown(e) {
    const ENTER = 13;
    if (e.keyCode === ENTER && !isNil(this.props.onClickSubmit)) {
      e.preventDefault();
      this.props.onClickSubmit();
    }
  }
}

RemoteSearchBox.defaultProps = {
  searchPlaceholder: null,
  inputText: '',
  onTextChange: (e) => null,
  onClickSubmit: null,
  onClickResetSearchConditionButton: null,
};

RemoteSearchBox.propTypes = {
  searchPlaceholder: PropTypes.string,
  inputText: PropTypes.string,
  onTextChange: PropTypes.func.isRequired,
  onClickSubmit: PropTypes.func,
  onClickResetSearchConditionButton: PropTypes.func,
};

export default RemoteSearchBox;
