import Api from 'utilities/api';
import i18next from 'i18n';
import { closeConfirmPaymentModal, requestPaidBatch } from './index';
import { displayMessage } from 'actions/ActionCreators';
import { fetchAsync } from 'actions/AsyncAction';
import { mergeFetchingData } from 'utilities/Utils';

const prefix = 'analyses/fetch';

export function fetchAnalysis(analysisId) {
  return async (dispatch, getState) => {
    return dispatch(fetchAsync(Api.analyses.show, { id: analysisId }))
      .then((data) => { dispatch(setAnalysis(data)); });
  };
}

export function fetchCategoryAnalysis(analysisId) {
  return async (dispatch, getState) => {
    return dispatch(fetchAsync(Api.analyses.summariesByCategory, { id: analysisId }))
      .then((data) => { dispatch(setCategoryAnalysis(data)); });
  };
}

export function fetchUserAnalysis(analysisId) {
  return async (dispatch, getState) => {
    return dispatch(fetchAsync(Api.analyses.summariesByUser, { id: analysisId }))
      .then((data) => { dispatch(setUserAnalysis(data)); });
  };
}

export function fetchAnalysisReports(analysisId, analysisReportsCount, analysisReports = [], offset = 0, limit = 30) {
  return async (dispatch, getState) => {
    const fetchedAnalysisReports = await dispatch(fetchAsync(Api.analyses.reportsSummaries, { id: analysisId, limit, offset }));
    const updatedAnalysisReports = mergeFetchingData(analysisReports, fetchedAnalysisReports, analysisReportsCount, offset);
    dispatch(setAnalysisReports(updatedAnalysisReports));
  };
}

export function fetchAll(api, apiParam, totalCount, limit = 100) {
  return async (dispatch, getState) => {
    let offset = 0;
    let iter = 0;
    const iterable = [];
    do {
      offset = limit * iter;
      iterable.push(dispatch(fetchAsync(api, { ...apiParam, ...{ limit, offset } })));
      iter++;
    } while (offset + limit < totalCount);

    const fetchedData = await Promise.all(iterable);
    let results = [];
    fetchedData.forEach((data) => {
      const appending = data.map((summary) => ({
        ...summary,
        key: summary.id,
      }));
      results = [...results, ...appending];
    });
    return results;
  };
}

export function fetchPreReportAggregation(analysisId) {
  return async (dispatch, getState) => {
    return dispatch(fetchAsync(Api.preAnalyses.show, { id: analysisId }))
      .then((data) => { dispatch(setAnalysis(data)); });
  };
}

export function fetchPreReportSummariesByCategory(analysisId) {
  return async (dispatch, getState) => {
    return dispatch(fetchAsync(Api.preAnalyses.summariesByCategory, { id: analysisId }))
      .then((data) => { dispatch(setCategoryAnalysis(data)); });
  };
}

export function fetchPreReportSummariesByUser(analysisId) {
  return async (dispatch, getState) => {
    return dispatch(fetchAsync(Api.preAnalyses.summariesByUser, { id: analysisId }))
      .then((data) => { dispatch(setUserAnalysis(data)); });
  };
}

export function fetchPreReportsSummaries(analysisId, preReportsCount) {
  return async (dispatch, getState) => {
    const limit = 100;
    const offset = 0;
    const preReportsSummaries = await dispatch(fetchAll(Api.preAnalyses.preReportsSummaries, { id: analysisId, limit, offset }, preReportsCount, limit));
    dispatch(setPreReportsSummaries(preReportsSummaries));
  };
}

export const SET_ANALYSIS = `${prefix}/SET_ANALYSIS`;
export function setAnalysis(data) {
  return {
    type: SET_ANALYSIS,
    data,
  };
}

export const SET_CATEGORY_ANALYSIS = `${prefix}/SET_CATEGORY_ANALYSIS`;
export function setCategoryAnalysis(data) {
  return {
    type: SET_CATEGORY_ANALYSIS,
    data,
  };
}

export const SET_USER_ANALYSIS = `${prefix}/SET_USER_ANALYSIS`;
export function setUserAnalysis(data) {
  return {
    type: SET_USER_ANALYSIS,
    data,
  };
}

export const SET_ANALYSIS_REPORTS = `${prefix}/SET_ANALYSIS_REPORTS`;
export function setAnalysisReports(data) {
  return {
    type: SET_ANALYSIS_REPORTS,
    data,
  };
}

export const SET_ANALYSIS_REPORTS_CURRENT_PAGE = `${prefix}/SET_ANALYSIS_REPORTS_CURRENT_PAGE`;
export function setAnalysisReportsCurrentPage(page) {
  return {
    type: SET_ANALYSIS_REPORTS_CURRENT_PAGE,
    value: page,
  };
}

export const SET_ANALYSIS_REPORTS_SIZE_PER_PAGE = `${prefix}/SET_ANALYSIS_REPORTS_SIZE_PER_PAGE`;
export function setAnalysisReportsSizePerPage(sizePerPage) {
  return {
    type: SET_ANALYSIS_REPORTS_SIZE_PER_PAGE,
    value: sizePerPage,
  };
}

export const SET_ANALYSIS_REPORTS_COUNT = `${prefix}/SET_ANALYSIS_REPORTS_COUNT`;
export function setAnalysisReportsCount(analysisReportsCount) {
  return {
    type: SET_ANALYSIS_REPORTS_COUNT,
    value: analysisReportsCount,
  };
}

export const SET_CANNOT_BE_PAID = `${prefix}/SET_CANNOT_BE_PAID`;
export function setCannotBePaid(cannotBePaid) {
  return {
    type: SET_CANNOT_BE_PAID,
    value: cannotBePaid,
  };
}

export const SET_CAN_EXPORT = `${prefix}/SET_CAN_EXPORT`;
export function setCanExport(canExport) {
  return {
    type: SET_CAN_EXPORT,
    value: canExport,
  };
}


export const SET_PRE_REPORTS_SUMMARIES = `${prefix}/SET_PRE_REPORTS_SUMMARIES`;
export function setPreReportsSummaries(data) {
  return {
    type: SET_PRE_REPORTS_SUMMARIES,
    data,
  };
}

export function changeReportStatusesToPaid(analysisId, paymentDate, paymentComment) {
  return async (dispatch, getState) => {
    return dispatch(fetchAsync(Api.requests.paidBatch, { analysisId, paymentDate, paymentComment }))
      .then((data) => {
        dispatch(displayMessage('success', data.message || 'ステータスを更新しました'));
        dispatch(requestPaidBatch());
        dispatch(closeConfirmPaymentModal());
      });
  };
}

export function changeReportStatusesToExported(analysisId) {
  return async (dispatch, getState) => {
    const data = await dispatch(fetchAsync(Api.analysisReportXlsJobs.exported, { analysisId }));
    dispatch(displayMessage('success', data.message));
  };
}

export function changePreReportStatusesToTemporaryPaid(preAnalysisId, paymentDate, paymentComment) {
  return async (dispatch, getState) => {
    const data = await dispatch(fetchAsync(Api.preAnalyses.temporaryPaid, { preAnalysisId, paymentDate, paymentComment }));
    dispatch(displayMessage('success', data.message || 'ステータスを更新しました'));
    dispatch(requestPaidBatch());
    dispatch(closeConfirmPaymentModal());
  };
}

export function changeExportTitle(analysisId, title) {
  return async (dispatch) => {
    return dispatch(fetchAsync(Api.analyses.title, { id: analysisId, title }))
      .then((data) => {
        dispatch(fetchAnalysis(analysisId));
        dispatch(displayMessage('success', i18next.t('exports.messages.changeAnalysisTitleSucceed')));
      });
  };
}

export function changePreAnalysisTitle(analysisId, title) {
  return async (dispatch) => {
    try {
      await dispatch(fetchAsync(Api.preAnalyses.title, { id: analysisId, title }));
      dispatch(displayMessage('success', i18next.t('exports.messages.changeAnalysisTitleSucceed')));
    } finally {
      dispatch(fetchPreReportAggregation(analysisId));
    }
  };
}
