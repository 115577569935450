import { Period } from "utilities/api/models/accountingDataScheduledExports/Period";
import { PeriodPoint } from "utilities/api/models/accountingDataScheduledExports/PeriodPoint";

const weekdays = ['日', '月', '火', '水', '木', '金', '土'];

const formatMonth = (point: PeriodPoint): string => {
  let monthString = '';
  if (point.value === 0) monthString = '今月';
  else {
    switch (point.direction) {
      case 'before': {
        if (point.value === 1) monthString = '先月';
        monthString = `${point.value}ヶ月前の`;
        break;
      }
      case 'after': {
        if (point.value === 1) monthString = '来月';
        monthString = `${point.value}ヶ月後の`;
        break;
      }
      default:
        break;
    }
  }

  let dayString = '';
  if (point.lastDayOfMonth) {
    if (point.value === 0 || point.value === 1) {
      dayString = '末';
    } else {
      dayString = '末日';
    }
  } else dayString = `${point.dayOfMonth}日`;

  return `${monthString}${dayString}`;
};

const formatWeek = (point: PeriodPoint): string => {
  const weekday = weekdays[point.weekday as number];

  if (point.value === 0) {
    return `今週${weekday}曜日`;
  }
  if (point.direction === 'before') {
    if (point.value === 1) {
      return `先週${weekday}曜日`;
    }
    return `${point.value}週前の${weekday}曜日`;
  }
  if (point.value === 1) {
    if (point.lastDayOfMonth) {
      return `翌週末`;
    }
    return `翌週${weekday}曜日`;
  }
  return `${point.value}週後の${weekday}曜日`;
};

const formatDay = (point: PeriodPoint): string => {
  if (point.value === 0) {
    return `本日`;
  }
  if (point.direction === 'before') {
    return `${point.value}日前`;
  }
  return `${point.value}日後`;
};

const formatPoint = (point: PeriodPoint): string => {
  switch (point.unit) {
    case 'month': return formatMonth(point);
    case 'weekday': return formatWeek(point);
    case 'day': return formatDay(point);
    default: return '';
  }
};

export const formatPeriod = (period: Period): string => {
  return `${formatPoint(period.from)}〜${formatPoint(period.to)}`;
};
