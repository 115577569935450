import i18next from "i18n";
import React, { FC } from "react";
import { TableHeaderColumn } from "react-bootstrap-table";
import Formatter from "utilities/formatter";
import { ColumnProps } from "./types";
import { buildTableHeaderColumnOption } from "./utils";

type Department = {
  id?: string;
  name?: string;
};

const formatDepartmentName = (
  cell: Department,
): string | JSX.Element | null => {
  return Formatter.text(cell && cell.name);
};

const renderDepartmentNameColumn: FC<ColumnProps> = (props: ColumnProps) => {
  return (
    <TableHeaderColumn
      {...buildTableHeaderColumnOption(props)}
      dataField={"department"}
      dataFormat={formatDepartmentName}
    >
      {i18next.t("transactions.properties.userDepartment")}
    </TableHeaderColumn>
  );
};

export default renderDepartmentNameColumn;
