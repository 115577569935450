import AsyncButton from 'applications/payment_requests/components/AsyncButton';
import ModalColumnControl from 'components/renewaled_ui/ModalColumnControl';
import React from 'react';
import i18next from 'i18n';
import styled from 'styled-components';
import { DataFetchingTable } from 'components/renewaled_ui/DataFetchingTable';
import { ReportTableRow, ReportTypes } from './interface';
import { RequestsSearchReportResponse } from 'utilities/api/responses/requests/searchResults/report';
import { useHooks } from './hooks';

const Wrapper = styled.div`
  padding: 10px;
`;
interface TableHeaderToolBarProps {
  readonly isSpaceBetween: boolean;
}
const TableHeaderToolBar = styled.div<TableHeaderToolBarProps>`
  display: flex;
  justify-content: ${(p): string => (p.isSpaceBetween ? 'space-between' : 'flex-end')};
  padding-bottom: 10px;
`;
const DisplayColumnButton = styled.div`
  display: flex;
  align-items: flex-end;
  cursor: pointer;
`;
const DisplayColumnTitle = styled.span`
  margin-left: 2px;
`;

interface Props {
  readonly reports: RequestsSearchReportResponse[];
  readonly only?: string[];
  readonly except: string[];
  readonly selectedType?: 'all' | 'normal' | 'settlement';
  readonly currentPage: number;
  readonly sizePerPage: number;
  readonly isLoading: boolean;
  readonly displayStatusForApprover: boolean;
  readonly isApprover: boolean;
  readonly isApprovingPage: boolean;
  readonly reportTypes: ReportTypes;
  readonly searchedProjectId: string;
  readonly searchedProjectName: string;
  readonly fetchData: (offset: number, limit: number, options?: { reset: boolean; }) => void;
  readonly onSortChange: (sortName: string, sortOrder: 'ASC' | 'DESC') => Promise<void>;
  readonly onPageChange: (page: number) => void;
  readonly onRowClick: (row: RequestsSearchReportResponse) => void;
  readonly onExportClick: (report: RequestsSearchReportResponse) => void;
  readonly onDeleteClick: (report: RequestsSearchReportResponse) => void;
  /** レポートタイプを変更された */
  readonly onChangeReportOfReportTypes: () => void;
  readonly onChangeNoTemporaryPaymentOfReportTypes: () => void;
  readonly onChangeTemporaryPaymentOfReportTypes: () => void;
  readonly onColumnWidthChange: (id: string, width: number) => void;
  readonly onSizePerPageList: (sizePerPage: number) => void;
}

/**
 * 経費精算テーブル: 申請/承認ページ
 */
const RequestReportTable: React.FC<Props> = (props) => {
  const {
    isShowColumnModal,
    isEnableReportBulkApprove,
    inProgressCreateApproveJobs,
    onBulkApprove,
    disabledBulkApprovalButton,
    selectedCount,
    getSelectableColumns,
    getReactTableColumnParams,
    getReactTableColumnParamsDefault,
    onSetColumnInModal,
    getReports,
    getHeaderColumns,
    onClickShowModal,
    onHideModal,
    onFetchData,
    onSelectTable,
    onSelectAllTable,
  } = useHooks(props);

  return (
    <Wrapper>
      <TableHeaderToolBar isSpaceBetween={ isEnableReportBulkApprove }>
        {
          isEnableReportBulkApprove ? (
            <AsyncButton
              bsStyle='primary'
              className='btn-sm'
              inProgress={ inProgressCreateApproveJobs }
              onClick={ onBulkApprove }
              disabled={ disabledBulkApprovalButton }
            >
              { `${i18next.t('paymentRequests.approveJobs.bulkApproveSelectedReports')}(${selectedCount})` }
            </AsyncButton>
          ) : null
        }

        <DisplayColumnButton onClick={ onClickShowModal }>
          <DisplayColumnTitle>
            <i className='fas fa-cog' /> { i18next.t('transactions.titles.columnSettings') }
          </DisplayColumnTitle>
        </DisplayColumnButton>
      </TableHeaderToolBar>

      <DataFetchingTable<ReportTableRow>
        data={ getReports() }
        isLoading={ props.isLoading }
        currentPage={ props.currentPage }
        sizePerPage={ props.sizePerPage }
        sizePerPageList={ [20, 50, 100] }
        isSimpleDesignMode={ false }
        columnResizable={ true }
        showCheckBoxes = { isEnableReportBulkApprove }
        maxHeight='calc(100vh - 340px)'
        columns={ getHeaderColumns() }
        fetchData={ onFetchData }
        onPageChange={ props.onPageChange }
        onSortChange={ props.onSortChange }
        onSizePerPageList={ props.onSizePerPageList }
        onSelect={ onSelectTable }
        onSelectAll={ onSelectAllTable }
        onRowClick={ (row): void => props.onRowClick(row.response) }
        onColumnWidthChange={ props.onColumnWidthChange }
      />

      {/* 表示項目選択モーダル */}
      <ModalColumnControl
        isShow={ isShowColumnModal }
        onHide={ onHideModal }
        selectableColumns={ getSelectableColumns() }
        columnValues={ getReactTableColumnParams() }
        defaultColumnParams={ getReactTableColumnParamsDefault() }
        onSetColumn={ onSetColumnInModal }
      />
    </Wrapper>
  );
};

export default RequestReportTable;
