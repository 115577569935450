import Api  from 'utilities/api';
import React, { PureComponent } from 'react';
import SuggestField from 'components/fields/SuggestField';
import formatter from 'utilities/formatter';
import {
  Bank,
  Banks,
  CommonProps,
  Subscription,
} from './types';
import { fetchAsync } from 'utilities/async';
import { from } from 'rxjs';

interface State {
  banks: Banks;
}

interface Props extends CommonProps<Bank> {
  disabled?: boolean;
}

export default class BankSuggestField extends PureComponent<Props, State> {
  private fetchSubscription?: Subscription = undefined;

  constructor(props: Props) {
    super(props);

    this.state = { banks: [] };

    this.renderOption = this.renderOption.bind(this);
  }

  componentDidMount(): void {
    this.fetchSubscription = from(this.initializeOptions())
      .subscribe((banks: Banks) => this.setState({ banks }));
  }

  componentWillUnmount(): void {
    if (this.fetchSubscription) {
      this.fetchSubscription.unsubscribe();
    }
  }

  initializeOptions(): Promise<Banks> {
    return fetchAsync(Api.banks.index)
      .then((data) => {
        return data.banks.map((bank: Bank) => ({
          id: bank.id,
          name: bank.name,
        }));
      });
  }

  getOptionText(bank: Bank | null): string {
    return bank ? bank.name : '';
  }

  renderOption(bank: Bank | null): JSX.Element {
    return formatter.text(bank && bank.name);
  }

  render(): JSX.Element {
    return (
      <SuggestField async={ false }
        text={ this.props.text }
        initialOptions={ this.state.banks }
        getOptionText={ this.getOptionText }
        renderOption={ this.renderOption }
        onSelect={ this.props.onSelect }
        onTextChange={ this.props.onTextChange }
        disabled={ this.props.disabled }
     />
    );
  }
}
