import LogoIp from '../assets/TOKIUM-logo-invoice.png';
import LogoRp from '../assets/TOKIUM-logo-keihi.png';
import LogoTd from '../assets/TOKIUM-logo-dencho.png';
import React from 'react';
import styled from 'styled-components';

const LogoGroup = styled.div`
  display: flex;
  align-items: center;
  .logo-img {
    display: inline-block;
    width: 80px;
  }
  .border {
    display: inline-block;
    height: 28px;
    width: 2px;
    margin: 0 10px;
    background-color: #333333;
  }
`;
const OtherSeries = styled.p`
  text-align: center;
  font-size: 1.2rem;
  font-weight: bold;
  margin-bottom: 12px;
`;

/**
 * 下部に表示するTOKIUMサービス一覧
 */
export const BottomLogo: React.FC = () => {
  return (
    <>
      <OtherSeries>TOKIUMシリーズ各詳細はこちら</OtherSeries>
      <LogoGroup className=''>
        <a href='https://www.keihi.com/'>
          <img className='logo-img' src={ LogoRp } alt='TOKIUM経費精算' />
        </a>
        <span className='border'></span>
        <a href='https://www.keihi.com/invoice/'>
          <img className='logo-img' src={ LogoIp } alt='TOKIUMインボイス' />
        </a>
        <span className='border'></span>
        <a href='https://www.keihi.com/denshichobo/'>
          <img className='logo-img' src={ LogoTd } alt='TOKIUM電子帳簿保存' />
        </a>
      </LogoGroup>
    </>
  );
};
