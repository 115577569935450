import React, { FC } from 'react';
import TextField from 'components/fields/TextField';

interface Props {
  accountHolder: string;
  onChange: (accountHolder: string) => void;
  disabled?: boolean;
}

const AccountHolderKanaField: FC<Props> = (props) => {
  return (
    <TextField
      value={ props.accountHolder }
      onValueChange={ props.onChange }
      editable={ !props.disabled }
    />
  );
};

export default AccountHolderKanaField;
