import PropTypes from 'prop-types';
import React, { Component } from 'react';
import SimpleModal from 'components/SimpleModal';
import i18next from 'i18n';

export default class DeleteConfirmationModal extends Component {
  constructor(props) {
    super(props);

    this.handleDelete = this.handleDelete.bind(this);
  }

  handleDelete() {
    try {
      this.props.onDelete();
      this.props.close();
    } catch (e) {
      this.props.onError(e);
    }
  }

  render() {
    const buttons = [
      { color: 'default', content: i18next.t('commons.actions.cancel'), onClick: this.props.close },
      { color: 'danger', content: i18next.t('commons.actions.delete'), onClick: this.handleDelete },
    ];
    return (
      <SimpleModal
        show={ this.props.show }
        close={ this.props.close }
        title={ i18next.t('commons.messages.confirmDelete') }
        buttons={ buttons }
      >
        { this.props.children }
      </SimpleModal>
    );
  }
}

DeleteConfirmationModal.defaultProps = {
  show: false,
  onDelete() {},
  onError() {},
};

DeleteConfirmationModal.propTypes = {
  show: PropTypes.bool.isRequired,
  close: PropTypes.func.isRequired,
  onDelete: PropTypes.func.isRequired,
  onError: PropTypes.func,
};
