export default {
  id:                     null,
  title:                  null,
  project:                null,
  needsTemporaryPayment:  false,
  temporaryPaymentDueAt:  null,
  temporaryPaymentReason: null,
  description:            userPreferences.preference.preReportDetailFormat,
  status:                 "未申請",
  editable:               true,
  destination:            null,
  startAt:                null,
  endAt:                  null,
  type:                   'PreReport',
  preTransactions:        [],
  transactions:           [],
  requests:               [],
  hasApproved:            false,
  report:                 null,
  editTaxCategory:        userPreferences.preference.editTaxCategory,
  formValues:             {},
};
