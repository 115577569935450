import { TableColumnControlCacheParams, TableColumnControlParam } from 'components/renewaled_ui/ModalColumnControl/interface';
import { useColumnControl } from 'components/renewaled_ui/ModalColumnControl/modules/columnControl';

interface UseHooks {
  readonly tableColumns: TableColumnControlParam[];
  readonly onClickCheck: (accessor: string) => void;
  readonly onClickTop: (accessor: string) => void;
  readonly onClickUp: (accessor: string) => void;
  readonly onClickDown: (accessor: string) => void;
  readonly onClickRemove: (accessor: string) => void;
  readonly onClickSave: () => void;
  readonly onClickCancel: () => void;
  readonly onClickReset: () => void;
  readonly canUpColumn: (accessor: string) => boolean;
  readonly canDownColumn: (accessor: string) => boolean;
}

/**
 * ロジック: テーブルの表示項目/表示順設定モーダル
 */
export const useHooks = (selectableColumns: TableColumnControlParam[], cacheColumns: TableColumnControlCacheParams, onSetColumn: (params: TableColumnControlCacheParams) => void, onHide: () => void, defaultColumnParams: TableColumnControlCacheParams): UseHooks => {
  const {
    tableColumns,
    canUpColumn,
    canDownColumn,
    toggleVisible,
    reset,
    setColumns,
    upTopColumn,
    upColumn,
    downColumn,
  } = useColumnControl(selectableColumns, cacheColumns);

  /**
   * 表示項目設定で、チェックボックスを押した
   */
  const onClickCheck = (accessor: string): void => {
    toggleVisible(accessor);
  };

  /**
   * 順番変更欄で、一番上に移動ボタンを押した
   */
  const onClickTop = (accessor: string): void => {
    upTopColumn(accessor);
  };

  /**
   * 順番変更欄で、上ボタンを押した
   */
  const onClickUp = (accessor: string): void => {
    upColumn(accessor);
  };

  /**
   * 順番変更欄で、下ボタンを押した
   */
  const onClickDown = (accessor: string): void => {
    downColumn(accessor);
  };

  /**
   * 順番変更欄で、削除ボタンを押した
   */
  const onClickRemove = (accessor: string): void => {
    toggleVisible(accessor);
  };

  /**
   * 変更内容の適用をする
   * LocalStorageへ保存
   */
  const onClickSave = (): void => {
    const arr = tableColumns.map((c) => ([
      c.accessor,
      {
        show: c.show,
        index: c.index,
      },
    ]));
    const columns = Object.fromEntries(arr);

    // 保存する関数に配列を渡す
    onSetColumn(columns);

    // モーダルを閉じる
    onHide();
  };

  /**
   * 変更内容をキャンセルしモーダルを閉じる
   */
  const onClickCancel = (): void => {
    // モーダル開く前の状態に戻す
    reset();

    // モーダルを閉じる
    onHide();
  };

  /**
   * 初期設定に戻すボタンを押した
   */
  const onClickReset = (): void => {
    const defaultColumns = selectableColumns.map((c) => {
      const dc = defaultColumnParams[c.accessor];
      return {
        accessor: c.accessor,
        show: dc.show,
        index: dc.index,
        Header: c.Header,
      };
    });
    setColumns(defaultColumns);
  };

  return {
    tableColumns,
    onClickCheck,
    onClickTop,
    onClickUp,
    onClickDown,
    onClickRemove,
    onClickSave,
    onClickCancel,
    onClickReset,
    canUpColumn,
    canDownColumn,
  };
};
