import PropTypes from 'prop-types';
import React, { Component } from 'react';
import filter from 'lodash/filter';
import { CSSTransition, TransitionGroup } from 'react-transition-group';

/**
 * @note TransactionModal にて ref 属性が差し込まれているため, 関数コンポーネントへしないように.
 * @see https://ja.reactjs.org/docs/refs-and-the-dom.html#refs-and-function-components
 */
export default class EmbeddedFlash extends Component {
  constructor(props) {
    super(props);
    this.state = {
      notifications: [],  // :: [Notification]
      maxKey: 0,
    };
  }

  notify(icon, color, elem) {
    const nots = this.state.notifications;
    const notifications = nots.concat(
      new Notification(this.newKey(), icon, color, elem),
    );
    this.setState({ notifications });
  }

  newKey() {
    const key = this.state.maxKey + 1;
    this.setState({ maxKey: key });
    return key;
  }

  success(txt) {
    this.notify(this.props.icon, 'success', (<span>{ txt }</span>));
  }

  warning(txt) {
    this.notify(this.props.icon, 'warning', (<span>{ txt }</span>));
  }

  error(txt) {
    this.notify(this.props.icon, 'danger', (<span>{ txt }</span>));
  }

  hide(key) {
    const notifications = filter(
      this.state.notifications,
      (n) => n.key !== key,
    );
    this.setState({ notifications });
  }

  clear(callback = null) {
    this.setState({ notifications: [] }, callback);
  }

  render() {
    const notifications = this.state.notifications.map((x) => {
      return (
        <CSSTransition key={ x.key }
          classNames='embedded-flash'
          timeout={ { enter: 500, exit: 500 } }
        >
          { this.renderNotification(x) }
        </CSSTransition>
      );
    });

    return (
      <TransitionGroup>
        { notifications }
      </TransitionGroup>
    );
  }

  renderNotification(n) {
    const card = `card card-${n.color} txt`;
    const iconClass = `fa fa-fw fa-left ${n.icon}`;
    return (
      <div className='row flash' style={ this.props.style }>
        <div className='col-sm-12'>
          <div className={ card }>
            <div className='card-content'>
              <span className='flash-message'>
                <span className={ iconClass }></span>
                { n.elem }
              </span>
              <div className='card-right'>
                <i
                  className='fas fa-times disabled'
                  onClick={ this.hide.bind(this, n.key) }
                  style={ { cursor: 'pointer' } }
                ></i>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

EmbeddedFlash.defaultProps = {
  icon: 'fa-exclamation-circle',
  style: {},
};

EmbeddedFlash.propTypes = {
  icon:   PropTypes.string,
  style:  PropTypes.object,
};

class Notification {
  constructor(key, icon, color, elem) {
    this.key = key;
    this.icon = icon;
    this.color = color;
    this.elem = elem;
  }
}
