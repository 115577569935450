import Checkbox from "components/checkbox";
import PersonalCategorySuggestField from "components/fields/PersonalCategorySuggestField";
import FlipSwitch from "components/FlipSwitch";
import LabeledComponent from "components/LabeledComponent";
import { ToolTip } from "components/renewaled_ui/ToolTip/ToolTip";
import i18next from "i18n";
import _ from "lodash";
import PropTypes from "prop-types";
import React, { Component } from "react";
import { Col, Row } from "react-bootstrap";
import Api from "utilities/api";
import { buildDepartmentsWithParent } from "utilities/ApiUtilities";
import flash from "utilities/flash";
import {
  getMessageFromResponse,
  isCorporatePlan,
  snakecaseKeys,
} from "utilities/Utils";
import AdvancedSearchConditions from "./components/searchbox/AdvancedSearchConditions";
import AmountRange from "./components/searchbox/AmountRange";
import AsInvoiceChecksCheckBoxGroupField from "./components/searchbox/AsInvoiceChecksCheckBoxGroupField";
import DateRange from "./components/searchbox/DateRange";
import DepartmentAndChild from "./components/searchbox/DepartmentAndChild";
import ElectronicReceiptImageCondition from "./components/searchbox/ElectronicReceiptImageCondition";
import GenericFieldSearchBoxCondition from "./components/searchbox/GenericFieldSearchCondition";
import ReceiptExpenseMatchingCondition from "./components/searchbox/ReceiptExpenseMatchingCondition";
import RegistratedNumberSearchField from "./components/searchbox/RegistratedNumberSearchField";
import SettlementCondition from "./components/searchbox/SettlementCondition";
import ShopName from "./components/searchbox/ShopName";

/**
 * 経費一覧検索ボックス
 */
export default class TransactionSearchBox extends Component {
  constructor(props) {
    super(props);

    this.state = {
      shops: { total: [], current: [] },
      employees: { total: [], current: [] },
      employeesInfo: [],
      departments: [],
      canSwitchIncludeDraftSaved: [
        "default",
        "waiting_for_worker",
        "denied",
      ].some((x) => props.searchCondition.status?.includes(x)),
    };

    this.handleResetSearchConditionButtonClick =
      this.handleResetSearchConditionButtonClick.bind(this);
    this.handleSequenceNumChange = this.handleSequenceNumChange.bind(this);
    this.handleExportLineIdChange = this.handleExportLineIdChange.bind(this);
    this.handleShopNameChange = this.handleShopNameChange.bind(this);
    this.handleShopNameSelect = this.handleShopNameSelect.bind(this);
    this.handleSuggestionsUpdateRequested =
      this.handleSuggestionsUpdateRequested.bind(this);
    this.handleCategoryNameChange = this.handleCategoryNameChange.bind(this);
    this.handleCategoryNameSelect = this.handleCategoryNameSelect.bind(this);
    this.handleScopeChange = this.handleScopeChange.bind(this);
    this.handleOwnerNameChange = this.handleOwnerNameChange.bind(this);
    this.handleDepartmentSelect = this.handleDepartmentSelect.bind(this);
    this.handleShopNameClearValue = this.handleShopNameClearValue.bind(this);
    this.handleDepartmentClear = this.handleDepartmentClear.bind(this);
    this.handleShopNameBlur = this.handleShopNameBlur.bind(this);
    this.handleDepartmentBlur = this.handleDepartmentBlur.bind(this);
    this.handleSettlementConditionChange =
      this.handleSettlementConditionChange.bind(this);
    this.handleElectronicReceiptImageConditionChange =
      this.handleElectronicReceiptImageConditionChange.bind(this);
    this.filterSuggestions = this.filterSuggestions.bind(this);
    this.handleDepartmentScopeChange =
      this.handleDepartmentScopeChange.bind(this);
    this.handleReceiptExpenseMatchingChange =
      this.handleReceiptExpenseMatchingChange.bind(this);
    this.handleOnlyDeletedChange = this.handleOnlyDeletedChange.bind(this);
    this.handleAmountChange = this.handleAmountChange.bind(this);
    this.handleDateChange = this.handleDateChange.bind(this);
    this.handleEmptyTransactedAtChange =
      this.handleEmptyTransactedAtChange.bind(this);
    this.handleEmptyAmountChange = this.handleEmptyAmountChange.bind(this);
    this.handleEmptyShopnameChange = this.handleEmptyShopnameChange.bind(this);
    this.handleIncludeDraftSavedChange =
      this.handleIncludeDraftSavedChange.bind(this);
    this.handleGenericFieldsSearchChange =
      this.handleGenericFieldsSearchChange.bind(this);
    this.handleRegistratedNumberChange =
      this.handleRegistratedNumberChange.bind(this);
    this.handleEmptyRegistratedNumberChange =
      this.handleEmptyRegistratedNumberChange.bind(this);
    this.handleInvoiceChecksChange = this.handleInvoiceChecksChange.bind(this);
  }

  processShops(json) {
    return json.shops.map((shop) => ({ value: shop.name }));
  }

  static getDerivedStateFromProps(props, state) {
    return {
      canSwitchIncludeDraftSaved: [
        "default",
        "waiting_for_worker",
        "denied",
      ].some((x) => props.searchCondition.status?.includes(x)),
    };
  }

  componentDidMount() {
    // TODO: TransactionListEditorから必要な選択肢を渡してもらう
    Api.transactions
      .recentShops()
      .then((data) => {
        this.setState({
          shops: {
            total: data.shops.map((s) => s.name),
            current: data.shops.map((s) => s.name),
          },
        });
      })
      .catch((error) => {
        flash.error(getMessageFromResponse(error));
      });
    // TODO: TransactionListEditorから必要な選択肢を渡してもらう
    Api.departments
      .index(snakecaseKeys({ excludeRoot: true }))
      .then((data) => {
        const departments = buildDepartmentsWithParent(data.departments);
        this.setState({ departments });
      })
      .catch((error) => {
        flash.error(getMessageFromResponse(error));
      });

    if (
      isCorporatePlan() &&
      (userPreferences.isAdmin || userPreferences.isAuditor)
    ) {
      // TODO: TransactionListEditorから必要な選択肢を渡してもらう
      Api.members
        .index(snakecaseKeys({ notBaseBlocked: true }))
        .then((data) => {
          this.setState({
            employees: {
              total: _.map(data.members, "name"),
              current: _.map(data.members, "name"),
            },
            employeesInfo: data.members,
          });
        })
        .catch((error) => {
          flash.error(getMessageFromResponse(error));
        });
    }
  }

  get isAuditEnabled() {
    return (
      isCorporatePlan() &&
      (userPreferences.isAdmin || userPreferences.isAuditor)
    );
  }

  get showAdvancedSearchConditions() {
    return (
      this.showSearchAll || this.showEmployeeSearch || this.showDeletedSearch
    );
  }

  get showSearchAll() {
    return this.isAuditEnabled;
  }

  get showEmployeeSearch() {
    return this.isAuditEnabled;
  }

  get showDeletedSearch() {
    return userPreferences.searchPreference?.canDeletedExpenseSearch || false;
  }

  get showTimeStamp() {
    return userPreferences.preference.addTimeStamp;
  }

  statusCheck(status) {
    if (_.isNil(this.props.searchCondition.status)) {
      return false;
    }

    return this.props.searchCondition.status.includes(status);
  }

  handleAmountChange(e, type) {
    const value = e.target.value;
    if (type === "amountFrom") {
      this.setSearchConditions({ amountFrom: value });
    } else if (type === "amountTo") {
      this.setSearchConditions({ amountTo: value });
    }
  }

  handleSequenceNumChange(e) {
    this.setSearchConditions({ sequenceNum: e.target.value });
  }

  handleExportLineIdChange(e) {
    this.setSearchConditions({ exportLineId: e.target.value });
  }

  handleGenericFieldsSearchChange(e, dataSetId, type) {
    const genericFields = this.props.searchCondition.genericFields;
    const dataSetIndex = genericFields.findIndex(
      (gf) => gf.id === dataSetId && gf.type === type,
    );
    if (dataSetIndex === -1) {
      genericFields.push({
        id: dataSetId,
        value: e.target.value,
        type,
      });
    } else {
      genericFields[dataSetIndex].value = e.target.value;
    }

    this.setSearchConditions({ genericFields });
  }

  handleScopeChange(checked) {
    this.setSearchConditions({ scope: checked });
  }

  handleOnlyDeletedChange(checked) {
    this.setSearchConditions({ onlyDeleted: checked });
  }

  handleStatusChange(checked, value) {
    const filtered = this.props.searchCondition.status.filter(
      (x) => x !== value || checked,
    );
    const additional = checked && _.indexOf(filtered, value) < 0;
    const status = additional ? [...filtered, value] : filtered;
    let data = { status };
    if (
      ["default", "waiting_for_worker", "denied"].some((x) =>
        status?.includes(x),
      ) === false
    ) {
      data = _.merge(data, { includeDraftSaved: false });
    }
    this.setSearchConditions(data);
  }

  handleIncludeDraftSavedChange(checked) {
    this.setSearchConditions({ includeDraftSaved: checked });
  }

  handleShopNameChange(changedShopName) {
    this.setSearchConditions({ shopName: changedShopName });
  }

  handleShopNameSelect(e, obj) {
    this.setSearchConditions({ shopName: obj.suggestion });
  }

  filterSuggestions(total, value, getter = (x) => x) {
    const reg = new RegExp(`.*${_.escapeRegExp(value.trim())}.*`, "g");
    return total.filter((x) => getter(x).match(reg));
  }

  handleSuggestionsUpdateRequested(e, key) {
    const total = this.state[key].total;
    this.setState({
      [key]: {
        total,
        current: this.filterSuggestions(total, e.value),
      },
    });
  }

  handleShopNameClearValue() {
    this.setSearchConditions({ shopName: "" });
  }

  handleShopNameBlur(e) {
    if (!e.target.value) {
      this.setSearchConditions({ shopName: "" });
    }
  }

  handleCategoryNameChange(changedCategoryName) {
    this.setSearchConditions({ categoryName: changedCategoryName });
  }

  handleCategoryNameSelect(category) {
    this.setSearchConditions({ categoryName: category?.name || "" });
  }

  handleOwnerNameChange(ownerName) {
    this.setSearchConditions({ ownerName });
  }

  handleDepartmentSelect(department) {
    this.setSearchConditions({ departmentId: _.get(department, "id", "") });
  }

  handleDepartmentClear() {
    this.setSearchConditions({ departmentId: "" });
  }

  handleDepartmentBlur(e) {
    if (!e.target.value) {
      this.setSearchConditions({ departmentId: "" });
    }
  }

  handleDepartmentScopeChange(checked) {
    this.setSearchConditions({ includeChildDepartment: checked });
  }

  handleSettlementConditionChange(type, checked) {
    this.setSearchConditions({ [type]: checked });
  }

  handleElectronicReceiptImageConditionChange(type, checked) {
    this.setSearchConditions({ [type]: checked });
  }

  handleReceiptExpenseMatchingChange(condition) {
    this.setSearchConditions({ receiptExpenseMatching: condition });
  }

  handleDateChange(type, value) {
    if (type === "dateFrom") {
      this.setSearchConditions({ dateFrom: value });
    } else if (type === "dateTo") {
      this.setSearchConditions({ dateTo: value });
    }
  }

  handleEmptyTransactedAtChange(checked) {
    let data = { emptyTransactedAt: checked };
    if (checked) data = _.merge(data, { dateFrom: "", dateTo: "" });

    this.setSearchConditions(data);
  }

  handleEmptyAmountChange(checked) {
    let data = { emptyAmount: checked };
    if (checked) data = _.merge(data, { amountFrom: "", amountTo: "" });

    this.setSearchConditions(data);
  }

  handleEmptyShopnameChange(checked) {
    let data = { emptyShopname: checked };
    if (checked) data = _.merge(data, { shopName: "" });

    this.setSearchConditions(data);
  }

  handleRegistratedNumberChange(registratedNumber) {
    this.setSearchConditions({ registratedNumber });
  }

  handleEmptyRegistratedNumberChange(checked) {
    let data = { emptyRegistratedNumber: checked };
    if (checked) data = _.merge(data, { registratedNumber: "" });
    this.setSearchConditions(data);
  }

  handleInvoiceChecksChange(asInvoiceChecks) {
    this.setSearchConditions({ asInvoiceChecks });
  }

  setSearchConditions(cond) {
    const obj = {
      ...this.props.searchCondition,
      ...cond,
    };
    this.props.setSearchConditions(obj);
  }

  handleResetSearchConditionButtonClick() {
    this.props.onClickResetSearchConditionButton();
  }

  render() {
    return (
      <div className="transaction-searchbox">
        {this.showAdvancedSearchConditions ? (
          <>
            <AdvancedSearchConditions
              className="row"
              showSearchAll={this.showSearchAll}
              showEmployeeSearch={this.showEmployeeSearch}
              showDeletedSearch={this.showDeletedSearch}
              searchAll={this.props.searchCondition.scope}
              searchOnlyDeleted={this.props.searchCondition.onlyDeleted}
              ownerName={this.props.searchCondition.ownerName}
              employees={this.state.employees.current}
              onScopeChange={this.handleScopeChange}
              onOwnerNameChange={this.handleOwnerNameChange}
              onOnlyDeletedChange={this.handleOnlyDeletedChange}
            />
            <hr />
          </>
        ) : null}

        <div className="row">
          <LabeledComponent
            className="col-sm-3"
            label={i18next.t("transactions.index.searchbox.sequenceNum")}
          >
            <input
              type="text"
              className="form-control validate[required, maxSize[255]]"
              value={this.props.searchCondition.sequenceNum}
              onChange={this.handleSequenceNumChange}
            />
          </LabeledComponent>
        </div>
        <hr />

        <div className="row">
          <LabeledComponent
            className="col-sm-6"
            label={i18next.t("transactions.properties.date")}
          >
            <DateRange
              dateFrom={this.props.searchCondition.dateFrom}
              dateTo={this.props.searchCondition.dateTo}
              searchEmpty={this.props.searchCondition.emptyTransactedAt}
              onDateChange={this.handleDateChange}
              onSearchEmptyChange={this.handleEmptyTransactedAtChange}
            />
          </LabeledComponent>

          <LabeledComponent
            className="col-sm-6"
            label={i18next.t("transactions.properties.shop")}
          >
            <ShopName
              shopName={this.props.searchCondition.shopName}
              shops={this.state.shops.current}
              searchEmpty={this.props.searchCondition.emptyShopname}
              onShopNameBlur={this.handleShopNameBlur}
              onShopNameChange={this.handleShopNameChange}
              onShopNameSelect={this.handleShopNameSelect}
              onShopNameClearValue={this.handleShopNameClearValue}
              onSearchEmptyChange={this.handleEmptyShopnameChange}
            />
          </LabeledComponent>
        </div>

        <div className="row">
          <LabeledComponent
            className="col-sm-6"
            label={i18next.t("transactions.properties.amount")}
          >
            <AmountRange
              amountFrom={this.props.searchCondition.amountFrom}
              amountTo={this.props.searchCondition.amountTo}
              searchEmpty={this.props.searchCondition.emptyAmount}
              onAmountChange={this.handleAmountChange}
              onSearchEmptyChange={this.handleEmptyAmountChange}
            />
          </LabeledComponent>

          <LabeledComponent
            className="col-sm-6"
            label={i18next.t("transactions.properties.category")}
          >
            <PersonalCategorySuggestField
              text={this.props.searchCondition.categoryName || ""}
              value={null} // 使わない
              placeholder={i18next.t("transactions.index.searchbox.all")}
              includeDisabled={this.isAuditEnabled}
              selectable={true}
              onTextChange={this.handleCategoryNameChange}
              onSelect={this.handleCategoryNameSelect}
            />
          </LabeledComponent>
        </div>

        <div className="row">
          <LabeledComponent
            className="col-sm-6 col-md-6"
            label={i18next.t("transactions.index.searchbox.userDepartment")}
          >
            <DepartmentAndChild
              includeChildDepartment={
                this.props.searchCondition.includeChildDepartment
              }
              departments={this.state.departments}
              departmentId={this.props.searchCondition.departmentId}
              onSelectDepartment={this.handleDepartmentSelect}
              onIncludeChildDepartmentChange={this.handleDepartmentScopeChange}
            />
          </LabeledComponent>

          <SettlementCondition
            className="col-sm-6 col-md-6"
            isToBePaid={this.props.searchCondition.toBePaid}
            isPaidByCorporateCard={
              this.props.searchCondition.paidByCorporateCard
            }
            onChange={this.handleSettlementConditionChange}
          />
        </div>

        <div className="row">
          {this.showTimeStamp && (
            <div className="col-sm-6" style={{ display: "flex" }}>
              <LabeledComponent
                style={{ width: "50%" }}
                label={i18next.t("transactions.index.searchbox.exportLineId")}
              >
                <input
                  type="text"
                  className="form-control validate[required, maxSize[255]]"
                  value={this.props.searchCondition.exportLineId}
                  onChange={this.handleExportLineIdChange}
                />
              </LabeledComponent>
            </div>
          )}
          {userPreferences.preference
            .enableExpenseElectronicReceiptImageFlag && (
            <ElectronicReceiptImageCondition
              isElectronicReceiptImage={
                this.props.searchCondition.isElectronicReceiptImage
              }
              onChange={this.handleElectronicReceiptImageConditionChange}
            />
          )}
        </div>

        {userPreferences.isPaperlessPlan && (
          <div className="row">
            <div className="col-sm-9">
              <ReceiptExpenseMatchingCondition
                onChange={this.handleReceiptExpenseMatchingChange}
                value={this.props.searchCondition.receiptExpenseMatching}
              />
            </div>
          </div>
        )}

        <Row>
          {this.props.searchCondition.genericFields &&
            (Array.isArray(userPreferences.preference.genericFields) &&
            userPreferences.preference.genericFields.length > 0 ? (
              userPreferences.preference.genericFields.map((dataSet, index) => (
                <div key={index}>
                  <GenericFieldSearchBoxCondition
                    handleGenericFieldSearchChange={(e) =>
                      this.handleGenericFieldsSearchChange(
                        e,
                        dataSet.id,
                        "code",
                      )
                    }
                    value={
                      this.props.searchCondition.genericFields.find(
                        (gf) => gf.id === dataSet.id && gf.type === "code",
                      )?.value || ""
                    }
                    label={`${dataSet.name}(${i18next.t(
                      "genericFields.dataSets.items.code",
                    )})`}
                  />
                  <GenericFieldSearchBoxCondition
                    handleGenericFieldSearchChange={(e) =>
                      this.handleGenericFieldsSearchChange(
                        e,
                        dataSet.id,
                        "name",
                      )
                    }
                    value={
                      this.props.searchCondition.genericFields.find(
                        (gf) => gf.id === dataSet.id && gf.type === "name",
                      )?.value || ""
                    }
                    label={`${dataSet.name}(${i18next.t(
                      "genericFields.dataSets.items.name",
                    )})`}
                  />
                </div>
              ))
            ) : (
              <Col sm={6}></Col>
            ))}
          <Col sm={3}>
            <RegistratedNumberSearchField
              textFormValue={this.props.searchCondition.registratedNumber}
              checkboxValue={this.props.searchCondition.emptyRegistratedNumber}
              onChangeTextForm={this.handleRegistratedNumberChange}
              onChangeCheckbox={this.handleEmptyRegistratedNumberChange}
            />
          </Col>
          <Col sm={3}>
            <AsInvoiceChecksCheckBoxGroupField
              asInvoiceChecks={this.props.searchCondition.asInvoiceChecks}
              onChange={this.handleInvoiceChecksChange}
            />
          </Col>
        </Row>

        <div className="row">
          <LabeledComponent
            className="col-sm-6"
            label={i18next.t("transactions.properties.status")}
          >
            <div className="vertical-checkbox-group">
              <div style={{ display: "flex" }}>
                <Checkbox
                  className="squared checkbox-accent"
                  label={i18next.t("commons.status.unsubmitted")}
                  defaultChecked={this.statusCheck("default")}
                  onChange={(checked) =>
                    this.handleStatusChange(checked, "default")
                  }
                />
                {userPreferences.preference.enableReportDraftSave && (
                  <div
                    className="switch-include-draft-saved"
                    style={{
                      height: "19px",
                      display: "flex",
                      marginLeft: "28px",
                    }}
                  >
                    <FlipSwitch
                      disabled={!this.state.canSwitchIncludeDraftSaved}
                      size={"small"}
                      checked={
                        this.props.searchCondition.includeDraftSaved || false
                      }
                      onChange={(e) => {
                        this.handleIncludeDraftSavedChange(e.target.checked);
                      }}
                    />
                    <ToolTip
                      align="center"
                      overlay={
                        <>
                          <div>
                            {i18next.t(
                              "transactions.index.searchbox.includeDraftSaved.description",
                            )}
                          </div>
                          {!this.state.canSwitchIncludeDraftSaved && (
                            <div>
                              {i18next.t(
                                "transactions.index.searchbox.includeDraftSaved.disabled",
                              )}
                            </div>
                          )}
                        </>
                      }
                    >
                      <span
                        style={
                          this.state.canSwitchIncludeDraftSaved
                            ? { marginLeft: "2px" }
                            : { marginLeft: "2px", color: "#bdc3c7" }
                        }
                      >
                        {i18next.t(
                          "transactions.index.searchbox.includeDraftSaved.label",
                        )}
                      </span>
                      <i
                        className="far fa-question-circle"
                        style={{ marginLeft: "2px" }}
                      />
                    </ToolTip>
                  </div>
                )}
              </div>
              <Checkbox
                className="squared checkbox-accent"
                label={i18next.t("commons.status.creating")}
                defaultChecked={this.statusCheck("waiting_for_worker")}
                onChange={(checked) =>
                  this.handleStatusChange(checked, "waiting_for_worker")
                }
              />
              <Checkbox
                className="squared checkbox-accent"
                label={i18next.t("commons.status.failed")}
                defaultChecked={this.statusCheck("denied")}
                onChange={(checked) =>
                  this.handleStatusChange(checked, "denied")
                }
              />
              <Checkbox
                className="squared checkbox-accent"
                label={i18next.t("commons.status.applying")}
                defaultChecked={this.statusCheck("applying")}
                onChange={(checked) =>
                  this.handleStatusChange(checked, "applying")
                }
              />
              <Checkbox
                className="squared checkbox-accent"
                label={i18next.t("commons.status.approved")}
                defaultChecked={this.statusCheck("approved")}
                onChange={(checked) =>
                  this.handleStatusChange(checked, "approved")
                }
              />
            </div>
          </LabeledComponent>
          <div className="col-sm-6 col-md-6">
            <div className="col-sm-offset-6 col-md-offset-6 col-sm-6 col-md-6 text-right">
              <a
                className={`anchor-reset-condition ${
                  this.props.isSearching ? " disabled" : ""
                }`}
                onClick={this.handleResetSearchConditionButtonClick}
              >
                {i18next.t("transactions.index.searchbox.resetCondition")}
              </a>
            </div>
            <button
              className={`btn btn-accent col-sm-offset-6 col-md-offset-6 col-sm-6 col-md-6 ${
                this.props.isSearching ? " disabled" : ""
              }`}
              type="button"
              onClick={this.props.onClickSearchButton}
            >
              <i
                className={`fa fa-left ${
                  this.props.isSearching ? " fa-spinner fa-spin" : " fa-search"
                }`}
              />
              {i18next.t("transactions.index.searchbox.search")}
            </button>
          </div>
        </div>
      </div>
    );
  }
}

TransactionSearchBox.defaultProps = {
  searchCondition: {
    dateFrom: null,
    dateTo: null,
    amountFrom: null,
    amountTo: null,
    status: [],
    includeDraftSaved: false,
    shopName: null,
    categoryName: null,
    scope: false,
    ownerName: null,
    sequenceNum: null,
    exportLineId: null,
    department: null,
    onlyDeleted: false,
    emptyAmount: false,
    emptyTransactedAt: false,
    emptyShopname: false,
    genericFields: [],
  },
  isSearching: false,
};

TransactionSearchBox.propTypes = {
  onClickResetSearchConditionButton: PropTypes.func.isRequired,
  onClickSearchButton: PropTypes.func.isRequired,
  searchCondition: PropTypes.object,
  setSearchConditions: PropTypes.func.isRequired,
  isSearching: PropTypes.bool.isRequired,
};
