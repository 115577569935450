import CheckBoxGroup, {
  CheckBoxGroupItem,
} from "components/fields/CheckBoxGroup";
import i18next from "i18n";
import React from "react";
import { ControlLabel, FormGroup } from "react-bootstrap";

const defaultItems: CheckBoxGroupItem<boolean>[] = [
  {
    key: true,
    label: i18next.t("transactions.index.searchbox.asInvoiceCheck.true"),
  },
  {
    key: false,
    label: i18next.t("transactions.index.searchbox.asInvoiceCheck.false"),
  },
];

interface Props {
  onChange: (asInvoiceChecks: boolean[]) => void;
  asInvoiceChecks: boolean[];
}

const AsInvoiceChecksCheckBoxGroupField: React.FC<Props> = (props) => {
  return (
    <FormGroup>
      <ControlLabel>
        {i18next.t("transactions.index.searchbox.asInvoiceForSearch")}
      </ControlLabel>
      <CheckBoxGroup
        checkboxes={defaultItems}
        onChange={props.onChange}
        values={props.asInvoiceChecks ?? []}
      />
    </FormGroup>
  );
};

export default AsInvoiceChecksCheckBoxGroupField;
