import { Category, EligibleInvoiceFormFieldValue } from "./interfaces";

/**
 * Hooksの返り値
 */
interface HooksReturn {
  /** 適格請求書として扱うは変更可能かどうか */
  canChangeAsEligibleInvoice: boolean;
  /** 適格請求書発行事業者が有効かどうかを表示するか */
  isShowValidation: boolean;
  /** 適格請求書発行事業者が有効かどうか */
  isValid: boolean;
  /** 登録番号フィールドを表示するか */
  isShowRegistratedNumberField: boolean;
  /** 住所又は所在地フィールドを表示するか */
  isShowPaidAddressField: boolean;
}

/** 適格請求書発行事業者の有効な処理番号 */
const VALID_IO_PROCESS_NUMBERS = ["01", "02"];
/** 登録番号の有効な桁数 */
const VALID_REGISTRATED_NUMBER_LENGTH = 13;

/**
 * 適格請求書項目欄の表示条件や検証結果を返します。
 */
export const useHooks = (
  value: EligibleInvoiceFormFieldValue,
  isAllowance: boolean,
  category: Category | null,
): HooksReturn => {
  if (isAllowance) {
    // 日当の場合、[適格請求書として扱う]は true の固定値で変更できない仕様としている。
    // また、[登録番号][住所又は所在地]を必要としていないため、非表示としている。
    return {
      canChangeAsEligibleInvoice: false,
      isShowValidation: false,
      isValid: false,
      isShowRegistratedNumberField: false,
      isShowPaidAddressField: false,
    };
  }

  const isShowRegistratedNumberField =
    (!!category && category.requiresRegistratedNumber) || !category;

  // 経費科目が源泉徴収対応である場合、源泉徴収の[受給者の住所]と同義であるため、
  // [住所又は所在地]を表示しない仕様である。
  const isShowPaidAddressField =
    !!category && category.requiresPaidAddress && !category.requiresWithholding;

  // invoicingOrganization は、適格請求書発行事業者が未判定の場合は undefined であり、
  // 適格請求書発行事業者を確認後に存在しない場合 null と定義されている。
  // このため undefined で判定している。
  const isShowValidation =
    value.invoicingOrganization !== undefined &&
    !!value.registratedNumber &&
    value.registratedNumber.length === VALID_REGISTRATED_NUMBER_LENGTH &&
    ((!!category && category.requiresRegistratedNumber) || !category);

  const isValid =
    !!value.invoicingOrganization &&
    !!value.invoicingOrganization.process &&
    VALID_IO_PROCESS_NUMBERS.includes(value.invoicingOrganization.process);

  return {
    canChangeAsEligibleInvoice: true,
    isShowValidation,
    isValid,
    isShowRegistratedNumberField,
    isShowPaidAddressField,
  };
};
