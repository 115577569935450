import React from "react";
import styled from "styled-components";

interface Props {
  /** 表示するかどうか */
  readonly visible: boolean;
}

const EntryFormsLoadingOverlayView = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: white;
  opacity: 0.5;
  font-size: 40px;
  z-index: 1000;
  display: flex;
  border-radius: 4px;
  align-items: center;
  justify-content: center;
`;

/**
 * EntryForm をロード中に表示するコンポーネント
 */
export const EntryFormsLoadingOverlay: React.FC<Props> = ({ visible }) => {
  return visible ? (
    <EntryFormsLoadingOverlayView>
      <i className="fa fa-spinner fa-spin" />
    </EntryFormsLoadingOverlayView>
  ) : null;
};
