import { useEffect } from "react";
import { ApiFunc } from "../actions/AsyncAction";
import { RequestObject } from "../utilities/Utils";
import { useExportedCsvFetcher } from "./useExportedCsvFetcher";

export const useExportedCsvHooks = <Res, Req extends RequestObject>(
  url: ApiFunc<Res, Req>,
  params: Req | undefined = undefined,
): Res | undefined => {
  const fetcher = useExportedCsvFetcher(url, params);

  useEffect(() => {
    fetcher.start();

    return (): void => {
      fetcher.stop();
    };
  }, []);

  return fetcher.data;
};
