import DisplayIndexController from 'components/renewaled_ui/ModalColumnControl/molecules/DisplayIndexController';
import React from 'react';
import VisibleCheckboxs from 'components/renewaled_ui/ModalColumnControl/molecules/VisibleCheckboxs';
import i18next from 'i18n';
import styled from 'styled-components';
import { Button } from 'components/renewaled_ui/buttons';
import {
  Modal, ModalBody, ModalFooter, ModalHeader,
} from 'components/renewaled_ui/single_views';
import { TableColumnControlCacheParams, TableColumnControlParam } from 'components/renewaled_ui/ModalColumnControl/interface';
import { useHooks } from 'components/renewaled_ui/ModalColumnControl/hooks';

const Controllers = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: start;
`;
const ResetNutton = styled.div`
  margin-top: 16px;
`;
const ModalFooterView = styled.div`
  text-align: left;
`;
const FooterButtons = styled.div`
  display: flex;
  margin-bottom: 4px;

  button {
    margin-right: 8px;
  }
`;
const FooterCaution = styled.div`
  font-size: 12px;
  margin-top: 4px;
`;

interface Props {
  /** モーダル表示するか */
  readonly isShow: boolean;
  /** リセットするための初期値 */
  readonly defaultColumnParams: TableColumnControlCacheParams;
  /** 表示/非表示の選択可能な列データ */
  readonly selectableColumns: TableColumnControlParam[];
  /**
   * LocalStorageに保存している列データ
   * 表示するかどうかと順番を保持
   * 全項目を送る
   */
  readonly columnValues: TableColumnControlCacheParams;
  /** モーダル閉じた */
  readonly onHide: () => void;
  /** カラム情報が更新された */
  readonly onSetColumn: (params: TableColumnControlCacheParams) => void;
}

/**
 * モーダル: テーブルの表示項目/表示順の設定
 */
const ModalColumnControl: React.FC<Props> = (p) => {
  const {
    tableColumns,
    onClickCheck,
    onClickTop,
    onClickUp,
    onClickDown,
    onClickRemove,
    onClickSave,
    onClickCancel,
    onClickReset,
    canUpColumn,
    canDownColumn,
  } = useHooks(p.selectableColumns, p.columnValues, p.onSetColumn, p.onHide, p.defaultColumnParams);

  return (
    <Modal
      width='md'
      show={ p.isShow }
      onClose={ onClickCancel }
    >
      <ModalHeader
        onClose={ onClickCancel }
        title={ i18next.t('transactions.index.displayItemsSettings') }
      />
      <ModalBody>
        <div>
          <Controllers>
            <VisibleCheckboxs
              selectableColumns={ p.selectableColumns }
              tableColumns={ tableColumns }
              onCheck={ onClickCheck }
            />
            <DisplayIndexController
              tableColumns={ tableColumns }
              onClickTop={ onClickTop }
              onClickUp={ onClickUp }
              onClickDown={ onClickDown }
              onClickRemove={ onClickRemove }
              canUpColumn={ canUpColumn }
              canDownColumn={ canDownColumn }
            />
          </Controllers>
          <ResetNutton>
            <Button
              onClick={ onClickReset }
              styleType='secondary'
            >
              { i18next.t('transactions.index.restoreDefaultColumnSelection') }
            </Button>
          </ResetNutton>
        </div>
      </ModalBody>
      <ModalFooter>
        <ModalFooterView>
          <FooterButtons>
            <Button onClick={ onClickSave } styleType='primary'>
              { i18next.t('commons.actions.save') }
            </Button>
            <Button onClick={ onClickCancel } styleType='link-secondary'>
              { i18next.t('commons.actions.cancel') }
            </Button>
          </FooterButtons>
          <FooterCaution>
            { i18next.t('transactions.index.displayItemsSettingsCaution') }
          </FooterCaution>
        </ModalFooterView>
      </ModalFooter>
    </Modal>
  );
};

export default ModalColumnControl;
