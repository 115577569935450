import React from 'react';
import i18next from 'i18next';
import styled from 'styled-components';
import { Alert } from 'react-bootstrap';

const SummaryText = styled.div`
  white-space: pre-line;
  font-weight: bold;
`;

// 経費精算・インボイス共通項目用のNoLicenseコンポーネント
export const NoLicensePage: React.FC = () => {
  return (
    <>
      <Alert bsStyle='info'>
        { i18next.t('accountingDataScheduledExports.noLicense.message') }
      </Alert>
      <SummaryText>
        { i18next.t('accountingDataScheduledExports.noLicense.summary') }
      </SummaryText>
    </>
  );
};
