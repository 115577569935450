import React from 'react';
import { Button, StyleType } from '../buttons/Button';
import { DropdownDirection } from './types';

interface Props {
  /** ボタンのスタイル */
  styleType?: StyleType;
  /** クリック時のハンドラ */
  onClick?: React.MouseEventHandler<HTMLButtonElement>;
  /** react-bootstrapがRefを渡すために指定する */
  bsRole: string;
  /** disabledか */
  disabled?: boolean;
  /** メニューを表示する方向 */
  direction?: DropdownDirection;
  /** ボタン小 */
  small?: boolean;
  children: JSX.Element;
  /** HTMLclass名にdisabledを含まずに実質disabledにするか */
  alternativeDisabled?: boolean;
  /** 最小幅 */
  minWidth?: number;
}

/** Buttonコンポーネントをラップしたトグルボタン */
export const DropdownToggleButton = React.forwardRef<HTMLButtonElement, Props>(({
  styleType = 'primary',
  disabled = false,
  onClick,
  direction = 'down',
  children,
  small,
  alternativeDisabled,
  minWidth,
}, ref) => {
  return (
    <Button
      styleType={ styleType }
      disabled={ disabled }
      onClick={ onClick }
      small={ small }
      alternativeDisabled={ alternativeDisabled }
      minWidth={ minWidth }
    >
      {direction === 'left' && (
        <i
          ref={ ref }
          className={ `fas fa-angle-${direction}` }
          style={ { marginRight: '6px' } }
        />
      )}
      { children }
      {direction !== 'left' && (
        <i
          ref={ ref }
          className={ `fas fa-angle-${direction}` }
          style={ { marginLeft: '6px' } }
        />
      )}
    </Button>
  );
});
