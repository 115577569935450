import isBoolean from "lodash/isBoolean";
import isEmpty from "lodash/isEmpty";
import isNaN from "lodash/isNaN";
import isNil from "lodash/isNil";
import isNumber from "lodash/isNumber";
import omit from "lodash/omit";
import toNumber from "lodash/toNumber";
import { ExpenseAssignedGenericField } from "utilities/api/models/ExpenseAssignedGenericField";
import { snakecaseKeys } from "utilities/Utils";
import {
  AmountValue,
  CalculationFormulaVariable,
  EditingField,
  ExpenseAmountValue,
  ExpenseEntryForm,
  ExpenseEntryFormRequest,
  ExpenseEntryFormRequestPayload,
  FormField,
  FormValues,
} from "./types/ExpenseEntryFormRequest";

/** 経費作成・更新APIに送信するリクエストパラメータに変換します */
export function toExpenseEntryFormRequest(
  param: ExpenseEntryFormRequestPayload,
): ExpenseEntryFormRequest {
  let entryForms: ExpenseEntryForm[];

  if (param.ids.length > 0) {
    entryForms = param.ids.map((x) => ({ ...omit(param, "ids"), id: x }));
  } else {
    entryForms = [param];
  }

  return { entryForms };
}

interface FormData {
  directProductTableId: string | null;
  formValues: FormValues;
  id: string | null;
  ids: string[] | null;
  ownerId: string;
  status: string; // TODO: サーバー側で使っていなければ削除する
  submitOnlyFilledInput: boolean;
}

interface CollectArgs {
  allowanceTable: AllowanceTable | null;
  formData: FormData;
  fields: EditingField[];
  isAutoWithholding: boolean;
  route: Route | null;
  isMultipleEdit: boolean;
}

/** 経費フォームのデータからAPIリクエスト用のentry formを作ります */
export function collectFieldParams({
  allowanceTable = null,
  formData,
  fields,
  isAutoWithholding = false,
  route = null,
  isMultipleEdit = false,
}: CollectArgs): ExpenseEntryFormRequestPayload {
  const allowanceParams = isNil(allowanceTable)
    ? {}
    : collectAllowanceParams(allowanceTable);
  const transaction = {
    directProductTableId: formData.directProductTableId,
    formFields: fields.reduce(
      (fieldArray: FormField[], field: EditingField) =>
        buildFormField({
          allowanceParams,
          field,
          fieldArray,
          formValues: formData.formValues,
          route,
        }),
      [],
    ),
    id: formData.id || void 0,
    ids: formData.ids || [],
    isAutoWithholding,
    isMultipleEdit,
    ownerId: formData.ownerId,
    status: formData.status,
  };

  if (formData.submitOnlyFilledInput) {
    // isEmptyはbooleanとnumberをemptyと判断してしまうので、true/falseと数値は残す
    transaction.formFields = transaction.formFields.filter(
      (f) =>
        !isNil(f.formValue) &&
        (isBoolean(f.formValue) ||
          isNumber(f.formValue) ||
          !isEmpty(f.formValue)),
    );
  }

  return snakecaseKeys(transaction);
}

interface AllowanceTable {
  id: string;
  input: {
    variableInputs: {
      [key: string]: unknown;
    };
  };
}

function collectAllowanceParams(
  allowanceTable: AllowanceTable,
): AllowanceParams {
  const { variableInputs } = allowanceTable.input;

  return {
    directProductTableId: allowanceTable.id,
    calculationFormulaVariables: Object.keys(variableInputs).map(
      (variableId) => {
        const variableInput = variableInputs[variableId];
        return {
          id: variableId,
          // variableInputは、inputTypeがnumberの時は値
          // table, select, periodの時は、inputがObjectであることに注意
          //
          // type: table
          //   { id: [UUID], value: [value] }  valueは、実費入力時のみサーバー側で処理され、その他の場合は無視される
          // type: select
          //   { id: [UUID], value: [value] }  valueは無視される
          // type: period
          //   { from: [Date], to: [Date] }
          input: variableInput,
        };
      },
    ),
  };
}

interface Route {
  destination: string;
  fare: number;
  id: string;
  isRoundTrip: boolean;
}

interface AllowanceParams {
  calculationFormulaVariables: CalculationFormulaVariable[];
  directProductTableId: string;
}

interface Args {
  field: EditingField;
  formValues: FormValues;
  route: Route | null;
  allowanceParams: AllowanceParams | Record<string, never>;
  fieldArray: FormField[];
}

function buildFormField({
  allowanceParams,
  field,
  fieldArray,
  formValues,
  route = null,
}: Args): FormField[] {
  // デフォルトフォームの値はtypeごとに1つだけ送信する
  //   ※ 経費申請名等、非表示になっているが送信したいパラメータもデフォルトフォームにはあるので表示/非表示はチェックしない
  // 追加フォームの値は表示されているモノだけ送信する
  const defaultFormTypes = fieldArray
    .filter((f) => isNil(f.id))
    .map((f) => f.type);
  if (
    (isNil(field.id) && defaultFormTypes.includes(field.type)) ||
    (!isNil(field.id) && !field.show)
  ) {
    return fieldArray;
  }

  if (field.id) {
    // カスタマイズした事業所のfield
    return [
      ...fieldArray,
      { id: field.id, type: field.type, formValue: formValues[field.id] },
    ];
  }

  const key = field.type;

  switch (key) {
    case "category_input": {
      const formValue = formValues[key];
      const categoryValue = formValue
        ? {
            ...formValue,
            skipCompanions: (formValues[key] || []).length === 0,
          }
        : null;

      return [...fieldArray, { id: null, type: key, formValue: categoryValue }];
    }
    case "companion_input": {
      const formValue = formValues[key];
      const companionValue = (formValue || []).map(snakecaseKeys);

      return [
        ...fieldArray,
        { id: null, type: key, formValue: companionValue },
      ];
    }
    case "project_input": {
      const formValue = formValues[key];
      const projectValue = formValue ? snakecaseKeys(formValue) : {};

      return [...fieldArray, { id: null, type: key, formValue: projectValue }];
    }
    case "cost_allocation_input": {
      const formValue = formValues[key];
      const costAllocationValues = (formValue || []).filter(
        (x) => x !== null && x.numerator !== 0 && x.payerId,
      );

      return [
        ...fieldArray,
        { id: null, type: key, formValue: costAllocationValues },
      ];
    }
    case "direct_product_table_input": {
      if (allowanceParams.calculationFormulaVariables) {
        return [
          ...fieldArray,
          {
            id: null,
            type: key,
            formValue: allowanceParams.calculationFormulaVariables,
          },
        ];
      }
      return [...fieldArray];
    }
    case "route_input": {
      if (route) {
        const amountValue = { amount: route.fare };
        const shopValue = route.destination;
        const routeValue = omit(route, ["fare", "destination"]);

        return [
          ...fieldArray,
          { id: null, type: "expense_input", formValue: amountValue },
          { id: null, type: "shop_input", formValue: shopValue },
          { id: null, type: "route_input", formValue: routeValue },
        ];
      }
      return [...fieldArray];
    }
    case "tax_category_input": {
      return [...fieldArray];
    }
    case "amount_per_tax_category_input": {
      const formValue = formValues[key];
      const expenseAmounts = formValue
        ? convertCalculatableExpenseAmounts(formValue)
        : [];

      return [
        ...fieldArray,
        { id: null, type: key, formValue: expenseAmounts },
      ];
    }
    case "currency_and_rate_input": {
      const formValue = formValues[key];
      const currency = formValue?.currencyId ? formValue : {};

      return [...fieldArray, { id: null, type: key, formValue: currency }];
    }
    case "generic_fields_input": {
      // フロントエンドでは、formData.formValues.genericFieldsの中で複数の汎用マスタのフォームの値を入れて管理しているが、リクエストパラメーターを送る際にはformState.formFields毎に分割する
      const genericFieldsValues = formValues[
        key
      ] as Array<ExpenseAssignedGenericField>;
      const mapToFormFields = genericFieldsValues.map((f) => ({
        id: null,
        type: key,
        dataSetId: f.dataSetId,
        formValue: f.items,
      }));
      return [...fieldArray, ...mapToFormFields];
    }
    default: {
      // date_input, refund_check_inputなど、formValueに対して特に処理を必要としない場合
      const formValue = formValues[key];
      return [...fieldArray, { id: null, type: key, formValue }];
    }
  }
}

/**
 * 計算可能な税区分金額一覧に変換する。
 * @param expenseAmountPerTaxCategories 税区分金額一覧
 * @return 計算可能な税区分金額一覧
 */
function convertCalculatableExpenseAmounts(
  expenseAmountPerTaxCategories: ExpenseAmountValue[],
): ExpenseAmountValue[] {
  return expenseAmountPerTaxCategories.map((o) => {
    return {
      ...o,
      amount: toNumberAmount(o.amount),
    };
  });
}

/**
 * 金額を数値に変換する。
 * @param amount
 * @return 金額の数値
 */
function toNumberAmount(amount: AmountValue): number {
  const number = toNumber(amount);
  return isNaN(number) ? 0 : number;
}
