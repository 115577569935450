/* eslint-disable @typescript-eslint/explicit-function-return-type */
import { approvalFormSelectedApproversListActionTypes as ActionTypes } from './ActionTypes';
import { Approver } from 'utilities/api/models/Approver';

interface SelectApproversAction {
  type: typeof ActionTypes.SELECT_APPROVERS;
  payload: {
    approvers: Approver[];
  };
}

interface DeselectApproverAction {
  type: typeof ActionTypes.DESELECT_APPROVER;
  payload: {
    approver: Approver;
  };
}

interface SelectApproverAction {
  type: typeof ActionTypes.SELECT_APPROVER;
  payload: {
    approver: Approver;
  };
}

export function selectApprovers(approvers: Approver[]): SelectApproversAction {
  return {
    type: ActionTypes.SELECT_APPROVERS,
    payload: {
      approvers,
    },
  };
}

export function deselectApprover(approver: Approver): DeselectApproverAction {
  return {
    type: ActionTypes.DESELECT_APPROVER,
    payload: {
      approver,
    },
  };
}

export function selectApprover(approver: Approver): SelectApproverAction {
  return {
    type: ActionTypes.SELECT_APPROVER,
    payload: {
      approver,
    },
  };
}
