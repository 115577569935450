import React, { useCallback } from 'react';
import SelectField from 'components/fields/SelectField';
import formatter from 'utilities/formatter';
import omit from 'lodash/omit';
import { RemoteReceiptFile } from 'components/types/attachedFiles.types';
import i18next from 'i18next';

interface Option extends RemoteReceiptFile {
  label: React.ReactElement;
}

interface Props {
  isDisabled?: boolean;
  versions?: Array<RemoteReceiptFile>;
  selectedVersion?: RemoteReceiptFile | null;
  onChange: (option: RemoteReceiptFile | null) => void;
  style?: React.CSSProperties;
}

function buildVersionIcon(receiptFile: RemoteReceiptFile): React.ReactElement | null {
  const expired = receiptFile.timeStamp?.expired === true;

  if (expired || receiptFile.status === 'failed_to_convert' || receiptFile.status === 'failed_to_sign') return <i className='fa fa-times color-danger' />;
  if (receiptFile.status === 'uploaded' && receiptFile.timeStamp?.stampedAt === undefined) return null 
  if (receiptFile.timeStamp?.stampedAt !== undefined) return <i className='fa fa-check color-success' />;

  return null; 
}

function buildVersionStampedAt(receiptFile: RemoteReceiptFile): React.ReactElement {
  if (receiptFile.status === 'failed_to_convert' || receiptFile.status === 'failed_to_sign') return <span className='txt txt-disabled'>{ i18next.t('expenses.timestamp.failed') }</span>;
  if (receiptFile.status === 'uploaded' && receiptFile.timeStamp?.stampedAt === undefined) return <span className='txt txt-disabled'>{ i18next.t('expenses.timestamp.inprogress') }</span>;
  if (receiptFile.timeStamp?.stampedAt !== undefined) return formatter.datetime(receiptFile.timeStamp.stampedAt * 1000);

  return <span className='txt txt-disabled'>{ i18next.t('commons.status.notEntered') }</span>
}

function buildVersionLabel(version: number, receiptFile: RemoteReceiptFile): React.ReactElement {
  const icon = buildVersionIcon(receiptFile);
  const stampedAt = buildVersionStampedAt(receiptFile);

  return (
    <span>
      { `Ver. ${version}` }
      <span style={ { margin: '0 4px 0 10px' } }>
        { stampedAt }
      </span>
      { icon }
    </span>
  );
}

const VersionSelector: React.FunctionComponent<Props> = (props) => {
  const {
    isDisabled = false, versions = [], style, onChange,
  } = props;
  const options: Array<Option> = versions.map((version, idx) => {
    return {
      ...version,
      label: buildVersionLabel(versions.length - idx, version),
    };
  });
  const selectedVersion = options.find((x) => (x.id === props.selectedVersion?.id));
  const handleChange = useCallback((option?: Option) => {
    onChange(option ? omit(option, 'label') : null);
  }, [onChange]);

  return (
    <div style={ style }>
      <SelectField value={ selectedVersion || null }
        options={ options }
        onChange={ handleChange }
        getOptionValue={ (x: Option): string => (x.id) }
        getOptionLabel={ (x: Option): React.ReactElement => (x.label) }
        isClearable={ false }
        isDisabled={ isDisabled || versions.length === 0 }
      />
    </div>
  );
};

export default VersionSelector;
