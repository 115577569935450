import React, { useEffect, useState } from "react";
import { Props as BaseProps } from "./Types";

interface Props extends BaseProps {
  buttonJobType: string;
  buttonTitle: string;
  buttonClassName: string;
  iconClassName: string;
}

/**
 * 送信状態ボタン
 * `RequestState` の状態に応じて、ボタンの有効無効を切り替え、二重送信を防止します。
 * 「申請する」「承認する」などの各ボタンで利用されます。
 */
const RequestStateButton: React.FC<Props> = ({
  buttonJobType,
  buttonTitle,
  buttonClassName,
  iconClassName,
  requestState,
  onClick,
}) => {
  const [disabled, setDisabled] = useState(requestState.isJobRunning);

  useEffect(() => {
    setDisabled(requestState.isJobRunning);
  }, [requestState]);

  const handleSubmit = (): void => {
    setDisabled(true);
    onClick();
  };

  if (disabled) {
    if (buttonJobType === requestState.jobType) {
      return (
        <button className={`${buttonClassName} disabled`}>
          <i className="fa fa-spinner fa-spin" /> {buttonTitle}
        </button>
      );
    }
    return (
      <button className={`${buttonClassName} disabled`}>
        <i className={iconClassName} /> {buttonTitle}
      </button>
    );
  }
  return (
    <button className={buttonClassName} onClick={handleSubmit}>
      <i className={iconClassName} /> {buttonTitle}
    </button>
  );
};

export default RequestStateButton;
