import OriginalReceipt from '../forms/OriginalReceipt';
import PropTypes from 'prop-types';
import React from 'react';
import SimpleModal from 'components/SimpleModal';
import _get from 'lodash/get';
import i18next from 'i18n';

/**
 * 原本の詳細を表示するモーダル
 *
 * @note
 *   このコンポーネントは, 現状 'ペーパーレスを利用する事業所の管理者' と 'Beartailのペーパーレス管理者' が閲覧する
 *   原本に対して実行できるアクションは, ユーザ管理者なのか Beartail 管理者なのかによって異なる
 *   そのため, 表示するアクションは userPreferences に応じて変更する
 *   (UIがアプリ単位で分かれるまでの暫定的な記述になるはず...)
 */
const isPaperlessPlanUser = _get(userPreferences, 'isPaperlessPlan');

const OriginalReceiptDetailModal = (props) => {
  return (
    <SimpleModal
      title={ props.title }
      show={ props.isShowDetail }
      close={ props.closeDetail }
      size='lg'
      bodyClassName='original-receipt-table__modal-body'
      buttons={ props.buttons }
    >
      <OriginalReceipt
        originalReceipt={ props.originalReceipt }
        hiddenElements={ props.hiddenElements }
      />
    </SimpleModal>
  );
};

OriginalReceiptDetailModal.defaultProps = {
  title: i18next.t('paperless.originalReceipts.titles.details'),
  isShowDetail: false,
  // ペーパーレス利用ユーザ者にとって自社の会社名とストレージコード、ファイル名は意味を成さないので, デフォルトでは表示しない
  hiddenElements: isPaperlessPlanUser ? ['companyName', 'storageCode', 'originalFilename'] : [],
};

OriginalReceiptDetailModal.propTypes = {
  title: PropTypes.string,
  isShowDetail: PropTypes.bool,            // モーダルを表示するかどうか
  closeDetail: PropTypes.func.isRequired,  // モーダルを閉じる際に行う処理
  /**
   * 以下, 詳細: paperless/original_receipts/components/forms/OriginalReceipt
   */
  originalReceipt: PropTypes.object,  // 原本画像
  hiddenElements: PropTypes.arrayOf(PropTypes.string),  // 詳細を隠す要素
  buttons: PropTypes.arrayOf(PropTypes.object), // 表示するボタン
};

export default React.memo(OriginalReceiptDetailModal);
