import React from 'react';
import i18next from 'i18next';
import styled from 'styled-components';
import { PageLinkButton } from './PageLinkButton';
import { ToolbarAction } from './ToolbarAction';
import { ToolbarTitle } from './ToolbarTitle';

interface Props {
  tasks?: boolean;
  jobs?: boolean;
  fileTransferLocation?: boolean;
}

const TASKS_URL = '/accounting_data_scheduled_exports/tasks';
const FILE_TRANSFER_LOCATIONS_URL = '/accounting_data_scheduled_exports/file_transfer_locations';
const JOBS_URL = '/accounting_data_scheduled_exports/jobs';

const ToolbarView = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  column-gap: 8px;
  margin: 0 0 12px;

  .toolbar-title {
    margin-right: auto;
  }

  .toolbar-action {
    margin-left: auto;
  }
`;

const ToolbarComponent: React.FC<Props> = ({
  tasks = false,
  jobs = false,
  fileTransferLocation = false,
  children,
}) => {
  return (
    <ToolbarView>
      <PageLinkButton href={ TASKS_URL } active={ tasks }>{ i18next.t('accountingDataScheduledExports.tasks.indexPage.title') }</PageLinkButton>
      <PageLinkButton href={ JOBS_URL } active={ jobs }>{ i18next.t('accountingDataScheduledExports.tasks.indexPage.jobIndex') }</PageLinkButton>
      <PageLinkButton href={ FILE_TRANSFER_LOCATIONS_URL } active={ fileTransferLocation }>{ i18next.t('accountingDataScheduledExports.tasks.indexPage.setUpFileTransferLocation') }</PageLinkButton>
      { children }
    </ToolbarView>
  );
};

export const Toolbar = Object.assign(ToolbarComponent, {
  Title: ToolbarTitle,
  Action: ToolbarAction,
});
