import * as actions from '../actions/teikiRoutes';
import Formatter from 'utilities/formatter';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import get from 'lodash/get';
import i18next from 'i18n';
import { connect } from 'react-redux';
import { getDatePickerDayConfig } from 'utilities/Utils';

export class TeikiTerm extends Component {
  componentDidMount() {
    $('.date-picker').datepicker({
      language:  userPreferences.locale,
      format:    'yyyy/mm/dd',
      orientation: 'bottom',
      autoclose: true,
      clearBtn:  true,
      todayBtn: 'linked',
      beforeShowDay: getDatePickerDayConfig,
    }).on("changeDate", (e) => {
      if (e.target.name === 'startAt') {
        this.props.onChangeStartAt(e.date);
      } else if (e.target.name === 'expireAt') {
        this.props.onChangeExpireAt(e.date);
      }
    });
  }

  render() {
    return (
      <div className='search-option-box'>
        <div className='form-group row'>
          <label className='col-sm-3 control-label'>{ i18next.t('preferences.teiki.startAt') }</label>
          <div className='col-sm-6'>
            <div className='input-group'>
              <div className='input-group-addon'>
                <span className='fas fa-calendar-alt'></span>
              </div>
              <input
                defaultValue={ this.props.defaultStartAt }
                name="startAt"
                type='text'
                className='form-control date-picker' />
            </div>
          </div>
        </div>
        <div className='form-group row'>
          <label className='col-sm-3 control-label'>{ i18next.t('preferences.teiki.expireAt') }</label>
          <div className='col-sm-6'>
            <div className='input-group'>
              <div className='input-group-addon'>
                <span className='fas fa-calendar-alt'></span>
              </div>
              <input
                defaultValue={ this.props.defaultExpireAt }
                name="expireAt"
                type='text'
                className='form-control date-picker' />
            </div>
          </div>
        </div>

      </div>
    );
  }
}

function mapStateToProps(state, ownProps) {
  const startAt = get(state, 'teikiRoutesTable.searchedTeikiRoute.startAt', null);
  const expireAt = get(state, 'teikiRoutesTable.searchedTeikiRoute.expireAt', null);

  return {
    defaultStartAt: startAt ? Formatter.date(startAt) : null,
    defaultExpireAt: expireAt ? Formatter.date(expireAt) : null,
  };
}

function mapDispatchToProps(dispatch, ownProps) {
  return {
    onChangeStartAt(value) {
      dispatch(actions.setTeikiRouteStartAt(value));
    },
    onChangeExpireAt(value) {
      dispatch(actions.setTeikiRouteExpireAt(value));
    },
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(TeikiTerm);

TeikiTerm.propTypes = {
  defaultValue:      PropTypes.string,
  onChangeStartAt: PropTypes.func.isRequired,
  onChangeExpireAt:   PropTypes.func.isRequired,
};
