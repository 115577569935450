/**
 * フォントに関する共通設定or動的に変更する設定
 * 特定の画面でのみ使用される場合は個々のComponentで定義する
 */
import { isWindows } from 'utilities/Utils';

export default {
  weight: {
    // Windowsで表示されるRobotoの600が、Macで表示されるHiragino Sansの500と同じくらいなので出し分ける
    bold: isWindows() ? '600' : '500',
  },
};
