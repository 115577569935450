import PropTypes from 'prop-types';
import React from 'react';
import Select from 'react-select';
import _has from 'lodash/has';
import i18next from 'i18n';

const styles = {
  control: (provided, _state) => {
    return {
      ...provided,
      minHeight: '32px', // bootstrapのform-controlと合わせる
    };
  },
  dropdownIndicator: (provided, _state) => {
    return {
      ...provided,
      padding: '4px',
    };
  },
  clearIndicator: (provided, _state) => {
    return {
      ...provided,
      padding: '4px',
    };
  },
};

const SelectField = (props) => {
  return (
    <Select className={ props.className }
      styles={ {
        ...styles,
        ...(props.styles || {}),
        menuPortal: (base) => ({ ...base, ...props.menuPortal }),
      } }
      value={ props.value }
      placeholder={ _has(props, 'placeholder') ? props.placeholder : i18next.t('commons.status.notSelected') }
      defaultValue={ props.defaultValue }
      filterOption={ props.filterOption }
      options={ props.options }
      getOptionLabel={ props.getOptionLabel }
      getOptionValue={ props.getOptionValue }
      onChange={ props.onChange }
      isClearable={ props.isClearable }
      isDisabled={ props.isDisabled }
      isMulti={ props.isMulti }
      isSearchable={ props.isSearchable }
      menuShouldScrollIntoView={ props.menuShouldScrollIntoView }
      menuPlacement={ props.menuPlacement }
      menuPortalTarget={ props.menuPortalTarget }
      menuPosition={ props.menuPosition }
      components={ props.components }
      maxMenuHeight={ props.maxMenuHeight }
    />
  );
};

SelectField.defaultProps = {
  isClearable: true,
  isDisabled: false,
  isMulti: false,
  menuPlacement: 'auto',
  menuPortal: {},
};

SelectField.propTypes = {
  className: PropTypes.string,
  placeholder: PropTypes.string,
  value: PropTypes.object,
  defaultValue: PropTypes.object,
  styles: PropTypes.object,
  options: PropTypes.arrayOf(PropTypes.object).isRequired,
  isClearable: PropTypes.bool,
  isDisabled: PropTypes.bool,
  isMulti: PropTypes.bool,
  menuShouldScrollIntoView: PropTypes.bool,
  menuPlacement: PropTypes.string,
  getOptionLabel: PropTypes.func,
  getOptionValue: PropTypes.func,
  onChange: PropTypes.func.isRequired,
  components: PropTypes.object,
  menuPortalTarget: PropTypes.object,
  menuPosition: PropTypes.string,
  maxMenuHeight: PropTypes.number,
  menuPortal: PropTypes.object,
  filterOption: PropTypes.func,
};

export default React.memo(SelectField);
