import React, { useState } from 'react';
import { BottomLogo } from './components/BottomLogo';
import { IdP, NotificationMessage } from './types/index';
import { Notification } from './components/Notification';
import { OAuthGroup } from './components/OAuthGroup';
import { SignInForm } from './components/SignInForm';
import { SignInTitle } from './components/SignInTitle';
import { isEmpty } from 'lodash';

interface Props {
  readonly idp?: IdP;
  readonly notification: NotificationMessage;
}

/**
 * ログインページ
 */
export const SignInPage: React.FC<Props> = (props) => {
  const url = new URL(document.location.href);
  const params = new URLSearchParams(url.search);
  const hasToken = !isEmpty(params.get('change_tenant_token'));
  /** エラーメッセージ */
  const [notification, setNotification] = useState<NotificationMessage>(props.notification || {});

  return (
    <>
      { hasToken && <div>テナントを切り替えています...</div> }
      <div style={ { display: hasToken ? 'none' : 'inherit' } }>
        <SignInTitle pageExplanation="ログインはこちらから" />
        <Notification notification={ notification } />
        <SignInForm idp={ props.idp } setNotification={ setNotification } />
        <OAuthGroup />
        <BottomLogo />
      </div>
    </>
  );
};
