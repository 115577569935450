import i18next from "i18n";
import React from "react";

interface Props {
  disabled: boolean;
  onClick: () => void;
}

/** 汎用申請の差し戻しボタン */
export const RejectButton: React.FC<Props> = React.memo(
  ({ disabled, onClick }) => {
    return (
      <button
        className="btn btn-danger btn-block btn-outline"
        disabled={disabled}
        onClick={(): void => {
          onClick();
        }}
      >
        {i18next.t("commons.actions.reject")}
      </button>
    );
  },
);
