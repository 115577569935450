interface UseReturn<Data> {
  readonly handleRowClick: (id: string, selectedData: Data) => void;
}

/**
 * ロジック: テーブルのページネーション
 */
export const useHooks = <Data>(onRowClick?: ((data: Data) => void)): UseReturn<Data> => {
  /**
   * 行をクリック
   */
  const handleRowClick = (id: string, selectedData: Data): void => {
    if (!onRowClick || id === 'selection') return;

    onRowClick(selectedData);
  };

  return {
    handleRowClick,
  };
};
