import Api from 'utilities/api';
import flash from 'utilities/flash';
import i18next from 'i18n';
import { PreReport, Report } from 'utilities/api/models';
import { debounce } from 'lodash';
import { fetchAsync } from 'utilities/async';
import {
  useCallback, useEffect, useMemo, useRef, useState,
} from 'react';

interface HookReturn {
  /** 選択されているタブNo. */
  selectedTabNum: number;
  /** 事前申請の選択肢 */
  preReportOptions: PreReport[];
  /** 経費申請の選択肢 */
  reportOptions: Report[];
  /** 申請名の検索文字列 */
  searchedAssignableReportTitle: string;
  /** モーダルのタイトル */
  modalTitle: string;
  /** 申請書の種類選択タブ */
  tabs: Array<{
    index: number;
    label: string;
  }>;
  /** 事前申請データ取得中か */
  isLoadingPreReport: boolean;
  /** 経費申請データ取得中か */
  isLoadingReport: boolean;
  /** 事前申請を使用する事業所か */
  usePreReportRequest: boolean;
  /** タブを選択時のハンドラ */
  setSelectedTabNum: React.Dispatch<React.SetStateAction<number>>;
  /** 検索文字列変更時ののハンドラ */
  handleChangeText: (title: string) => void;
}

/** 経費に割り当て可能な申請書選択モーダルを管理するhook */
export const useAssignableReportInputModal = (): HookReturn => {
  const [selectedTabNum, setSelectedTabNum] = useState(2);
  const [preReportOptions, setPreReportOptions] = useState<PreReport[]>([]);
  const [reportOptions, setReportOptions] = useState<Report[]>([]);
  const [searchedAssignableReportTitle, setSearchedAssignableReportTitle] = useState('');
  const [isLoadingPreReport, setIsLoadingPreReport] = useState(false);
  const [isLoadingReport, setIsLoadingReport] = useState(false);

  /** 事前申請を使用する事業所か */
  const usePreReportRequest = useMemo(() => userPreferences.preference.requestTypes.some((v) => v.type === 'pre_report_request'), []);

  /** モーダルのタイトル */
  const modalTitle = useMemo(() => (usePreReportRequest ? i18next.t('reports.properties.preReportAndReport') : i18next.t('reports.types.report')), [usePreReportRequest]);

  /**
   * 申請書の種類の選択タブ
   * @memo
   * タブの並び順が [経費申請] → [事前申請] で、デフォルトが [事前申請] になっている理由
   * 順番は、申請一覧画面や新規経費精算画面と合わせた方が統一される意味で [経費申請] → [事前申請]
   * デフォルトはより多く使用されている意味で [事前申請] を選択
   */
  const tabs = useMemo(() => [
    {
      index: 1,
      label: i18next.t('reports.types.report'),
    },
    {
      index: 2,
      label: i18next.t('reports.types.preReport'),
    },
  ], []);

  const preReportRequestTypeId = useMemo(() => userPreferences.preference.requestTypes.find((v) => v.type === 'pre_report_request')?.id, []);
  const reportRequestTypeId = useMemo(() => userPreferences.preference.requestTypes.find((v) => v.type === 'report_request')?.id, []);

  /** 事前申請取得 */
  const fetchPreReport = useCallback(async (title?: string): Promise<void> => {
    if (!usePreReportRequest) return;

    setIsLoadingPreReport(true);

    try {
      const apiParams = {
        for: 'reportable',
        "request_types": [preReportRequestTypeId],
        title: title || null,
      };
      const data = await fetchAsync(Api.requests.search, apiParams);

      setPreReportOptions(data[0]?.results);
    } catch (e) {
      flash.error(i18next.t('commons.errors.failedToFetch'));
    }

    setIsLoadingPreReport(false);
  }, [preReportRequestTypeId, usePreReportRequest]);

  /** 経費申請取得 */
  const fetchReport = useCallback(async (title?: string): Promise<void> => {
    setIsLoadingReport(true);

    try {
      const apiParams = {
        for: 'list',
        "request_types": [reportRequestTypeId],
        status: ['created', 'rejected', 'recalled'],
        title: title || null,
      };
      const data = await fetchAsync(Api.requests.search, apiParams);

      setReportOptions(data[0]?.results);
    } catch (e) {
      flash.error(i18next.t('commons.errors.failedToFetch'));
    }

    setIsLoadingReport(false);
  }, [reportRequestTypeId]);

  /** 検索ボックス文字列変更 */
  const handleChangeText = (title: string): void => {
    setSearchedAssignableReportTitle(title);

    handleDebouncedSearch(title);
  };

  /** 検索 */
  const handleDebouncedSearch = useRef(
    debounce(async (title: string): Promise<void> => {
      await Promise.all([fetchPreReport(title), fetchReport(title)]);
    }, 500),
  ).current;

  useEffect(() => {
    if (!preReportRequestTypeId) return;

    fetchPreReport();
  }, [fetchPreReport, preReportRequestTypeId]);

  useEffect(() => {
    if (!reportRequestTypeId) return;

    fetchReport();
  }, [fetchReport, reportRequestTypeId]);

  return {
    selectedTabNum,
    preReportOptions,
    reportOptions,
    searchedAssignableReportTitle,
    modalTitle,
    tabs,
    isLoadingPreReport,
    isLoadingReport,
    usePreReportRequest,
    setSelectedTabNum,
    handleChangeText,
  };
};
