import Formatter from 'utilities/formatter';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import i18next from 'i18n';
import moment from 'moment';
import { renderInputWithIcon } from 'utilities/renderer';
import { getDatePickerDayConfig } from 'utilities/Utils';

export default class MultipleDateField extends Component {
  constructor(props) {
    super(props);

    this.handleDateChange = this.handleDateChange.bind(this);
    this.handleDateClick = this.handleDateClick.bind(this);
    this.handleMultiSelectableChange = this.handleMultiSelectableChange.bind(this);
    this.input = null;
    this.state = {
      // 複数日選択のチェックボックスの状態を監視
      isMultiSelectable: false,
      appendedCheckBox: false,
    };
  }

  get inputText() {
    if (!this.props.value) {
      return null;
    }

    if (this.props.value instanceof Array) {
      return this.props.value.map((v) => Formatter.date(v)).join(', ');
    }

    return Formatter.date(this.props.value);
  }

  getDatepickerConfig(isMultiSelectable = this.state.isMultiSelectable) {
    return {
      language: userPreferences.locale,
      format: 'yyyy/mm/dd',
      clearBtn: true,
      todayBtn: 'linked',
      multidate: isMultiSelectable,
      autoclose: !isMultiSelectable,
      orientation: 'bottom',
      showOnFocus: false,
      beforeShowDay: getDatePickerDayConfig,
    };
  }

  componentDidMount() {
    $(this.input).datepicker(this.getDatepickerConfig())
      .on('changeDate', this.handleDateChange);
  }

  handleMultiSelectableChange(e) {
    // ラベルをクリックしてもチェックされるようにする
    const checked = e.target.control ? !e.target.control.checked : e.target.checked;
    this.setState({ isMultiSelectable: checked });

    $(this.input).datepicker('hide')
      .datepicker('destroy')
      .datepicker(this.getDatepickerConfig(checked))
      .datepicker('show');

    this.appendMultiDateCheckBox();
    $('#multi-date input:checkbox').prop('checked', checked);
  }

  handleDateChange(e) {
    this.props.onValueChanged(this.formatDateValue(e.target.value));
  }

  handleDateClick(e) {
    $(this.input).datepicker('hide')
      .datepicker('show');
    if (this.state.appendedCheckBox) {
      return;
    }
    this.appendMultiDateCheckBox();
  }

  appendMultiDateCheckBox() {
    if (!this.props.isMultiSelectable) {
      return;
    }

    $('.datepicker tfoot').append('<tr id="multi-date"></tr>');
    $('#multi-date')
      .append(`<th colspan="7" style="display: table-cell;">
          <label class="txt-pointer">
            <input type="checkbox" />
            ${i18next.t('transactions.inputs.multiDate')}
          </label>
        </th>`)
      .on('click', this.handleMultiSelectableChange);
    $('#multi-date input:checkbox')
      .on('click', (e) => { e.preventDefault(); });

    this.setState({ appendedCheckBox: true });
  }

  formatDateValue(dateText) {
    const dates = dateText.split(',')
      .map((dateString) => {
        if (!moment(dateString, 'Y/MM/DD', true).isValid()) {
          return null;
        }

        const date = new Date(dateString);
        return date;
      })
      .filter((date) => date)
      .sort((a, b) => {
        // 数値比較する
        const aTime = a.getTime();
        const bTime = b.getTime();

        if (aTime < bTime) {
          return -1;
        }
        if (aTime > bTime) {
          return 1;
        }
        return 0;
      })
      .map((date) => moment(date).format('Y/MM/DD'));

    if (this.state.isMultiSelectable) {
      return dates;
    }

    return dates[0] || '';
  }

  renderInput() {
    return (
      <input type='text'
        ref={ (input) => { this.input = input; } }
        className={ this.props.className }
        value={ this.inputText }
        onClick={ this.handleDateClick }
        onChange={ this.handleDateChange }
        disabled={ !this.props.editable }
      />
    );
  }

  render() {
    return renderInputWithIcon(
      this.renderInput(),
      <i className='fas fa-calendar-alt fa-fw' aria-hidden='true' />,
    );
  }
}

MultipleDateField.defaultProps = {
  className: 'form-control input-sm',
  isMultiSelectable: false,
  editable: true,
  value: '',
  onValueChanged(text) {},
};

MultipleDateField.propTypes = {
  isMultiSelectable: PropTypes.bool.isRequired,
  value: PropTypes.oneOfType([
    PropTypes.string, PropTypes.arrayOf(PropTypes.string.isRequired),
  ]).isRequired,
  editable: PropTypes.bool,
  onValueChanged: PropTypes.func,
};
