import i18next from "i18n";
import React from "react";
import RequestStateButton from "./RequestStateButton";
import { Props } from "./Types";

/** 「フローを再設定する」ボタン */
const ChangeFlowButton: React.FC<Props> = ({ requestState, onClick }) => {
  return (
    <RequestStateButton
      buttonJobType="flowChange"
      buttonTitle={i18next.t("commons.actions.resetFlow")}
      buttonClassName="btn btn-block btn-accent"
      iconClassName="fa fa-left fa-paper-plane"
      requestState={requestState}
      onClick={onClick}
    />
  );
};

export default ChangeFlowButton;
