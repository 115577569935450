import React, { useCallback } from 'react';
import { RegistratedNumberSearchField } from 'applications/payment_requests/components/reports/fields/RegistratedNumberSearchField';
import { useFormContext } from 'react-hook-form';

interface Props {
  name: string;
  checkboxName: string;
  styleTextForm?: React.CSSProperties;
}

export const RegistratedNumberField: React.FC<Props> = ({
  name,
  checkboxName,
  styleTextForm,
}) => {
  const { watch, setValue } = useFormContext();

  const registratedNumber = watch(name);
  const emptyRegistratedNumber = watch(checkboxName);

  const handleCheckboxChange = useCallback((value: boolean) => {
    setValue(checkboxName, value);
  }, [checkboxName, setValue]);

  const handleTextChange = useCallback((value: string) => {
    setValue(name, value);
  }, [name, setValue]);

  return (
    <RegistratedNumberSearchField
      textFormValue={ registratedNumber }
      checkboxValue={ emptyRegistratedNumber }
      onChangeTextForm={ handleTextChange }
      onChangeCheckbox={ handleCheckboxChange }
      styleTextForm={ styleTextForm }
    />
  );
};
