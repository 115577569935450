import React, { forwardRef } from 'react';
import styled from 'styled-components';
import { TableToggleAllRowsSelectedProps } from 'react-table';
import { useHooks } from './hooks';

interface WrapperProps {
  readonly isDisabled?: boolean;
}
const Wrapper = styled.span<WrapperProps>`
  .label {
    box-sizing: border-box;
    cursor: pointer;
    display: block;
    padding: 0;
    position: relative;
    width: auto;
  
    &::before {
      background: ${(p): string => (p.isDisabled ? '#f0f0f0' : '#fff')};
      border: 1px solid #c5c8c9;
      border-radius: 4px;
      content: '';
      display: block;
      position: absolute;
      z-index: 1;
      height: 19px;
      width: 19px;
      top: 0px;
      left: -7px;
    }
    &::after {
      border-right: 2px solid white;
      border-bottom: 2px solid white;
      content: '';
      display: block;
      width: 7px;
      height: 10px;
      left: -1px;
      top: 3px;
      opacity: 0;
      position: absolute;
      z-index: 1;
      transform: rotate(45deg);
    }
  }
  input[type=checkbox] {
    display: none;
  }
  input[type=checkbox]:checked + .label::before {
    background: #327dff;
  }
  input[type=checkbox]:checked + .label::after {
    opacity: 1;
  }
  input[type=checkbox]:indeterminate + .label::before {
    background: #c5c8c9;
  }
  input[type=checkbox]:indeterminate + .label::after {
    opacity: 1;
    transform: rotate(0);
    width: 13px;
    height: 3px;
    background: white;
    border: none;
    border-radius: 2px;
    top: 8px;
    left: -4px;
  }
`;

/**
 * ReactTableで使用する行選択のチェックボックス
 * Indeterminateとはcheckboxの不確定状態
 */
export const IndeterminateCheckbox = forwardRef<HTMLInputElement, TableToggleAllRowsSelectedProps & { id: string, isDisabled?: boolean }>(
  ({
    indeterminate, id, isDisabled, ...rest
  }, ref) => {
    const { resolvedRef } = useHooks(ref, indeterminate);

    return (
      <Wrapper
        className='label indeterminate-checkbox'
        isDisabled={ isDisabled }
      >
        <input
          id={ id }
          type="checkbox"
          ref={ resolvedRef as React.MutableRefObject<HTMLInputElement> }
          { ...rest }
          disabled={ isDisabled }
        />
        <label
          htmlFor={ id }
          className="label"
          style={ { cursor: isDisabled ? 'not-allowed' : 'pointer' } }
        />
      </Wrapper>
    );
  },
);
