import AccountHolderKanaField from './AccountHolderKanaField';
import AccountNumberField from './AccountNumberField';
import AccountTypeField from './AccountTypeField';
import BankBranchSuggestField from './BankBranchSuggestField';
import BankSuggestField from './BankSuggestField';
import FormValidation from 'components/FormValidation';
import HelpIcon from 'applications/payment_requests/components/HelpIcon';
import LabeledComponent from 'components/LabeledComponent';
import React, { FC } from 'react';
import i18next from 'i18n';
import styled from 'styled-components';
import {
  AccountType,
  Bank,
  BankBranch,
  PayeeAccount,
} from './types';
import { OverlayTrigger, Popover } from 'react-bootstrap';

interface ErrorProp {
  payeeAccount?: {
    bank?: Array<string>;
    bankBranch?: Array<string>;
    number?: Array<string>;
    holderKana?: Array<string>;
  };
}

interface HelpProp {
  holderKana?: string;
}

interface Props {
  payeeAccount: PayeeAccount;
  setPayeeAccount: (payeeAccount: PayeeAccount) => void;
  errors: ErrorProp;
  disabled?: boolean;
  helps?: HelpProp;
}

const PreWrappedPopover = styled(Popover)`
  white-space: pre-wrap;
`;

const PayeeAccountForm: FC<Props> = (props) => {
  const handleChangePayeeAccount = (field: string, value: string | Bank | BankBranch): void => {
    const payeeAccount: PayeeAccount = {
      ...props.payeeAccount,
      [field]: value,
    };

    props.setPayeeAccount(payeeAccount);
  };

  return (
    <>
      <FormValidation messages={ props.errors.payeeAccount?.bank || [] }>
        <LabeledComponent label={ i18next.t('preferences.expenseAccount.bank') }>
          <BankSuggestField text={ props.payeeAccount.bank.name }
            onSelect={ (bank: Bank | null): void => handleChangePayeeAccount('bank', bank || { id: null, name: '' }) }
            onTextChange={ (text: string): void => handleChangePayeeAccount('bank', { id: null, name: text }) }
            disabled={ props.disabled }
          />
        </LabeledComponent>
      </FormValidation>
      {
        props.payeeAccount.bank.id
          && (
            <>
              <FormValidation messages={ props.errors.payeeAccount?.bankBranch || [] }>
                <LabeledComponent label={ i18next.t('preferences.expenseAccount.bankBranch') }>
                  <BankBranchSuggestField text={ props.payeeAccount.bankBranch.name }
                    bank={ props.payeeAccount.bank }
                    onSelect={ (branch: BankBranch | null): void => handleChangePayeeAccount('bankBranch', branch || { id: null, name: '' }) }
                    onTextChange={ (text: string): void => handleChangePayeeAccount('bankBranch', { id: null, name: text }) }
                    disabled={ props.disabled }
                  />
                </LabeledComponent>
              </FormValidation>
              <LabeledComponent label={ i18next.t('preferences.expenseAccount.type') }>
                <AccountTypeField accountType={ props.payeeAccount.accountType }
                  onChange={ (accountType: AccountType): void => handleChangePayeeAccount('accountType', accountType) }
                  disabled={ props.disabled }
                />
              </LabeledComponent>
              <FormValidation messages={ props.errors.payeeAccount?.number || [] }>
                <LabeledComponent label={ i18next.t('preferences.expenseAccount.number') }>
                  <AccountNumberField accountNumber={ props.payeeAccount.accountNumber }
                    onChange={ (accountNumber: string): void => handleChangePayeeAccount('accountNumber', accountNumber) }
                    disabled={ props.disabled }
                  />
                </LabeledComponent>
              </FormValidation>
              <FormValidation messages={ props.errors.payeeAccount?.holderKana || [] }>
                <LabeledComponent label={ i18next.t('preferences.expenseAccount.holderKana') }>
                  { props.helps?.holderKana && <OverlayTrigger
                    overlay={
                      <PreWrappedPopover>
                        { props.helps.holderKana }
                      </PreWrappedPopover>
                    }
                    trigger={ ['hover'] }
                  >
                    <HelpIcon />
                  </OverlayTrigger>
                  }
                  <AccountHolderKanaField accountHolder={ props.payeeAccount.accountHolder }
                    onChange={ (accountHolder: string): void => handleChangePayeeAccount('accountHolder', accountHolder) }
                    disabled={ props.disabled }
                  />
                </LabeledComponent>
              </FormValidation>
            </>
          )
      }
    </>
  );
};

export default PayeeAccountForm;
