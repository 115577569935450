import PropTypes from 'prop-types';
import React from 'react';

const TabBar = (props) => {
  const { tabs } = props;

  return (
    <div className='tab-bar'>
      <ul className='nav nav-tabs'>
        {
          tabs.map((tab, index) => {
            const handleTabClick = props.onClickTab ? props.onClickTab.bind(null, tab, index) : null;

            return (
              <li key={ `tab-${index}` }
                className={ tab.isActive ? 'active' : void 0 }
                role='navigation'
              >
                <a href={ tab.url } onClick={ handleTabClick }>
                  { props.renderTab(tab) }
                </a>
              </li>
            );
          })
        }
      </ul>
    </div>
  );
};

TabBar.defaultProps = {
  onClickTab(tab, index) {},
};

TabBar.propTypes = {
  tabs: PropTypes.arrayOf(PropTypes.shape({
    isActive: PropTypes.bool,
    url: PropTypes.string,
  })),
  onClickTab: PropTypes.func,
  renderTab: PropTypes.func.isRequired,
};

export default TabBar;
