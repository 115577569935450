import Placeholder from "components/renewaled_ui/FileViewer/Viewer/Other/FileView/parts/Placeholder";
import i18next from "i18n";
import React from "react";
import { DropzoneInputProps } from "react-dropzone";
import styled from "styled-components";

const Wrapper = styled.div`
  height: 100%;
`;
const Content = styled.div`
  position: absolute;
  height: 100%;
  width: 100%;
  top: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #eee;
  border: 4px dashed #aaa;
  opacity: 0;
  transition: opacity 0.3s ease;

  &:hover {
    opacity: 0.8;
  }
  & > p {
    font-weight: bold;
    font-size: 1.2em;
    text-align: center;
  }
`;

interface Props {
  /** 拡張子 */
  readonly ext: string;
  /** ファイルinput */
  readonly getInputProps: (
    props?: DropzoneInputProps | undefined,
  ) => DropzoneInputProps;
}

/**
 * ファイルビューのファイルドロップエリア
 */
const ViewerDropZone: React.FC<Props> = (p) => {
  return (
    <Wrapper className="pdf-dropzone-view-wrapper-inner">
      <input {...p.getInputProps()} />
      <Placeholder type="notExist" />
      <Content className="pdf-dropzone-view-wrapper-inner-content">
        <p>
          {i18next.t("transactions.droparea.click", { ext: p.ext })}
          <br />
          <em>{i18next.t("transactions.droparea.or")}</em>
          <br />
          {i18next.t("transactions.droparea.dragAndDrop", { ext: p.ext })}
          <br />
        </p>
      </Content>
    </Wrapper>
  );
};

export default ViewerDropZone;
