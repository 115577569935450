export const ImageMIMETypes = [
  'application/pdf',
  'image/jpeg',
  'image/png',
] as const;

export const MIMETypes = [
  ...ImageMIMETypes,
  'image/gif',
  'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
  'application/vnd.ms-excel',
  'text/csv',
  'text/plain',
  'application/msword',
  'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
] as const;

export const ViewableMIMETypes = [
  ...ImageMIMETypes,
  'image/gif',
] as const;

export type MIMEType = typeof MIMETypes[number];
