import Api from 'utilities/api';
import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';
import SuggestField from 'components/fields/SuggestField';
import formatter from 'utilities/formatter';
import { fetchAsync } from 'utilities/async';

/**
 * 従業員を選択する際、入力内容に応じてサジェストを出すComponent
 * 現在は、必ず通信が発生する
 */
export default class MemberSuggestField extends PureComponent {
  fetchOptions(text, offset, limit) {
    return fetchAsync(Api.members.index, { name: text, offset, limit })
      .then((response) => {
        const members = response.members.map((member) => {
          return { id: member.id, name: member.name, email: member.email };
        });
        return { count: response.count, data: members };
      });
  }

  /**
   * @param {string} option.id
   * @param {string} option.name
   * @param {string} option.email
   */
  getOptionText(option) {
    return option.name;
  }

  renderOption(option) {
    return formatter.memberPull(option);
  }

  render() {
    return (
      <SuggestField async={ true }
        text={ this.props.text }
        value={ this.props.value }
        placeholder={ this.props.placeholder }
        fetchOptions={ this.fetchOptions }
        initialOptions={ this.props.initialOptions }
        getOptionText={ this.getOptionText }
        renderOption={ this.renderOption }
        onSelect={ this.props.onSelect }
        onTextChange={ this.props.onTextChange }
      />
    );
  }
}

MemberSuggestField.propTypes = {
  initialOptions: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    email: PropTypes.string.isRequired,
  })),
  text: PropTypes.string,
  value: PropTypes.shape({
    id: PropTypes.string,
    name: PropTypes.string.isRequired,
    email: PropTypes.string,
  }),
  placeholder: PropTypes.string,
  onSelect: PropTypes.func.isRequired,
  onTextChange: PropTypes.func,
};
