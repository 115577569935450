import { cancelTemporaryPaymentRefundTypes as ActionTypes } from "./ActionTypes";

interface ModalAction {
  type: typeof ActionTypes.TOGGLE_MODAL;
  payload: {
    show: boolean;
  };
}

function toggleModal(show: boolean): ModalAction {
  return {
    type: ActionTypes.TOGGLE_MODAL,
    payload: {
      show,
    },
  };
}

export function openModal(): ModalAction {
  return toggleModal(true);
}

export function closeModal(): ModalAction {
  return toggleModal(false);
}
