import { SamlSetting } from 'utilities/api_security/models/SamlSetting';
import { SamlSettingDetail } from 'utilities/api_security/models';

export interface State {
  samlSettings: SamlSetting[];
  samlSettingDetail?: SamlSettingDetail;
  inProgress: boolean;
  showNewSamlSettingModal: boolean;
  showUpdateSamlSettingModal: boolean;
  showDestroySamlSettingModal: boolean;
  showCreateUpdateIdPModal: boolean;
  ipRestrictionEnable: boolean;
}

export const initialState: State = {
  samlSettings: [] as SamlSetting[],
  inProgress: false,
  showNewSamlSettingModal: false,
  showUpdateSamlSettingModal: false,
  showDestroySamlSettingModal: false,
  showCreateUpdateIdPModal: false,
  ipRestrictionEnable: false,
};
