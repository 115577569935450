import { useResize } from "applications/payment_requests/reports/components/ResizeBar";
import React, { useCallback, useEffect, useRef } from "react";
import styled, { css } from "styled-components";
import colors from "values/colors";
import { ResizeBar } from "./ResizeBar";

interface StyleProps {
  /**
   * ドロワーの幅(px)
   * 最大幅はコンポーネント側で制限を設けています
   */
  readonly width: number;
  /**
   * 高さを超えた場合のover-flow-y
   * 2カラム表示の場合はhiddenにしておく必要あり。
   */
  readonly overflowY?: "auto" | "hidden";
  /** 幅が可変か */
  readonly resizable?: boolean;
}

interface ComponentProps {
  /** 表示するか */
  readonly show: boolean;
  /** ローディング中か */
  readonly isLoading?: boolean;
  /** クリック時に閉じないエリアのクラス名 */
  readonly preventCloseSelector?: string;
  /** クリック時に強制的に閉じるエリアのクラス名 */
  readonly closeSelector?: string;
  /** resizable=trueの場合の、右方向の最大値 */
  readonly maxPositiveOffset?: number;
  /** resizable=trueの場合の、左方向の最大値 */
  readonly maxNegativeOffset?: number;
  /** 表示をする */
  readonly onShow: () => void;
  /** 非表示にする */
  readonly onClose: () => void;
}

export type Props = StyleProps & ComponentProps;

const widthTransitionStyle = css`
  transition: width 300ms ease;
`;

const RightDrawerView = styled.div<StyleProps>`
  @keyframes slideIn {
    0% {
      right: ${({ width }): string => `-${width}px`};
    }
    100% {
      right: 0;
    }
  }
  animation: slideIn 80ms ease 1;
  position: fixed;
  right: 0;
  background-color: #fff;
  // サイドバー:1000, モーダル:1050, ヘッダー:1025
  z-index: 1030;
  box-shadow: 0 0 12px rgb(0 0 0 / 18%);
  overflow-y: ${({ overflowY }): string => overflowY || "auto"};
  width: ${({ width }): string => `${width}px`};
  max-width: calc(100vw - 170px); // 170px: サイドバー幅
  overflow-x: hidden;
  top: 40px;
  bottom: 60px;
  color: ${colors.renewaled.textBlack};
  ${widthTransitionStyle}

  // コンポーネントは./RightDrawerHeader.tsx
  .right-drawer-header {
    animation: slideIn 80ms ease 1;
    background-color: #fff;
    position: fixed;
    z-index: 1031;
    top: 0;
    right: 0;
    width: ${({ width }): string => `${width}px`};
    height: 40px;
    box-shadow: 0 1px 0 ${colors.renewaled.borderGrey};
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 24px;
    max-width: calc(100vw - 170px); // 170px: サイドバー幅
    box-shadow: 0 -4px 12px rgb(0 0 0 / 18%);
    ${widthTransitionStyle}

    > .right-drawer-title {
      font-size: 16px;
      font-weight: 600;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }
  // コンポーネントは./RightDrawerFooter.tsx
  .right-drawer-footer {
    animation: slideIn 80ms ease 1;
    background-color: #fff;
    position: fixed;
    z-index: 1031;
    bottom: 0;
    right: 0;
    width: ${({ width }): string => `${width}px`};
    height: 60px;
    display: flex;
    align-items: center;
    padding: 0 24px;
    max-width: calc(100vw - 170px); // 170px: サイドバー幅
    box-shadow: 0 4px 12px rgb(0 0 0 / 18%);
    ${widthTransitionStyle}
  }
  .right-drawer-body {
    padding: 24px;
    padding-bottom: 0;
    height: 100%;
  }
  .right-drawer-header-toolbar {
    display: flex;
    align-items: center;
  }
  .prev-next-icons {
    display: flex;
  }
  .prev-icon,
  .next-icon,
  .close-button {
    color: ${colors.renewaled.textGrey};
    height: 36px;
    width: 36px;
    border-radius: 4px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    user-select: none;
    transition: 150ms;
    margin-left: 4px;
    :hover {
      background: ${colors.renewaled.whiteHover};
    }
    &.disabled {
      color: ${colors.renewaled.borderGrey};
      cursor: auto;
      :hover {
        background: transparent;
      }
    }
  }
  .close-button {
    margin-left: 16px;
  }
  .overlay {
    z-index: 1032;
    position: fixed;
    top: 40px;
    bottom: 0;
    width: ${({ width }): string => `${width}px`};
    background-color: rgba(255, 255, 255, 0.8);
    display: flex;
    justify-content: center;
    align-items: center;
    > i {
      font-size: 32px;
      color: #808080;
    }
  }
`;

/**
 * 右ドロワー
 */
export const RightDrawer: React.FC<Props> = ({
  show,
  children,
  onShow,
  onClose,
  width,
  isLoading,
  preventCloseSelector,
  closeSelector,
  overflowY,
  resizable,
  maxPositiveOffset,
  maxNegativeOffset,
}) => {
  const {
    hover,
    offsetWidth,
    onMouseMove,
    startResizing,
    stopResizing,
    mouseLeave,
    setHover,
    resizingWidth,
  } = useResize({ maxPositiveOffset, maxNegativeOffset });

  /**
   * 右ドロワーに渡すRef
   */
  const rightDrawerRef = useRef<HTMLDivElement>(null);

  /**
   * handleCloseを発火するイベント
   */
  const eventNames = React.useMemo(() => ["click", "keydown"], []);

  /**
   * 閉じる(閉じるボタン、右ドロワー外のクリック、Escキーで閉じる)
   */
  const handleClose = useCallback(
    (event): void => {
      // 右ドロワー内のクリックであれば閉じない。
      // セレクトボックスの中身など、クリックした瞬間DOMが消える要素は「右ドロワーの中身でない」と誤認されてしまうため、
      // クリックした要素がhtml要素の中に無ければ「クリックした瞬間消えたDOM」と判別し、右ドロワーを閉じないようにする。
      const html = document.querySelector("html");
      const disappeared = !html?.contains(event.target);
      if (rightDrawerRef.current?.contains(event.target) || disappeared) return;

      // preventCloseSelector に指定された場所のクリックであれば閉じない
      if (preventCloseSelector) {
        const elements = document.querySelectorAll(preventCloseSelector);
        const elementArray = Array.prototype.slice.call(elements);
        if (elementArray.some((elm) => elm.contains(event.target))) return;
      }

      // closeSelector に指定された場所のクリックであれば強制的に閉じる
      if (closeSelector) {
        const elements = document.querySelectorAll(closeSelector);
        const elementArray = Array.prototype.slice.call(elements);
        if (elementArray.some((elm) => elm.contains(event.target))) {
          onClose();
          return;
        }
      }

      // モーダル内のクリックであれば閉じない
      const modals = document.getElementsByClassName("modal");
      const modalArray = Array.prototype.slice.call(modals);
      if (modalArray.some((modal) => modal.contains(event.target))) return;

      // イベントがキーダウンかクリックか
      const isKeyDown = event.type === "keydown";
      // Escキー以外のキー入力であれば閉じない
      const escKeyPressed = event.keyCode === 27;
      if (isKeyDown && !escKeyPressed) return;

      onClose();

      eventNames.forEach((eventName) =>
        document.removeEventListener(eventName, handleClose),
      );
    },
    [closeSelector, eventNames, onClose, preventCloseSelector],
  );

  /**
   * RightDrawerを開いたタイミングで閉じるイベントをListen開始
   */
  useEffect(() => {
    if (show) {
      onShow();
      eventNames.forEach((eventName) =>
        document.addEventListener(eventName, handleClose),
      );
    }

    return (): void => {
      eventNames.forEach((eventName) =>
        document.removeEventListener(eventName, handleClose),
      );
    };
  }, [eventNames, handleClose, onShow, show]);

  return (
    <RightDrawerView
      ref={rightDrawerRef}
      width={resizable ? width - offsetWidth : width}
      hidden={!show}
      overflowY={overflowY}
      data-testid="custom-element"
      className="right-drawer"
      draggable={false}
    >
      <div className="right-drawer-body">{children}</div>

      {resizable && (
        <ResizeBar
          hover={hover}
          mouseLeave={mouseLeave}
          setHover={setHover}
          startResizing={startResizing}
          onMouseMove={onMouseMove}
          stopResizing={stopResizing}
          isResizing={resizingWidth}
        />
      )}

      {isLoading && (
        <div className="overlay">
          <i className="fas fa-lg fa-spin fa-spinner loading-icon" />
        </div>
      )}
    </RightDrawerView>
  );
};
