import PropTypes from 'prop-types';
import React, { Component } from 'react';
import i18next from 'i18n';

export default class TeikiOptions extends Component {
  render() {
    return (
      <div className='search-option-box'>
        <label className='col-sm-3 control-label'>{ i18next.t('transactions.inputs.facilities') }</label>
        <div className='col-sm-9'>
          <label className='checkbox-inline' key='train-option'>
            <input
              type='checkbox'
              id='isTrain'
              name="train"
              checked={ this.props.isTrain }
              onChange={ this.props.onChange } />
            { i18next.t('transactions.inputs.train') }
          </label>
          <label className='checkbox-inline' key='bus-option'>
            <input
              type='checkbox'
              id='isBus'
              name='bus'
              checked={ this.props.isBus }
              onChange={ this.props.onChange } />
            { i18next.t('transactions.inputs.bus') }
          </label>
        </div>
      </div>
    );
  }
}

TeikiOptions.propTypes = {
  isTrain: PropTypes.bool,
  isBus: PropTypes.bool,
  onChange: PropTypes.func,
};
