import React from "react";
import { Modal } from "react-bootstrap";

interface Props {
  /** モーダルタイトル */
  readonly title: string;
  /** 閉じるボタンを表示するか */
  readonly showCloseButton?: boolean;
  /** 非表示にした */
  readonly onClose: () => void;
}

/**
 * モーダル: ヘッダー部分
 */
export const ModalHeader: React.FC<Props> = ({
  title,
  showCloseButton = true,
  onClose,
}) => {
  return (
    <Modal.Header closeButton={showCloseButton} onHide={onClose}>
      <Modal.Title>{title}</Modal.Title>
    </Modal.Header>
  );
};
