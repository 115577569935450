import * as ActionTypes from '../actions';
import moment from 'moment';

export const initialState = {
  reportId: null,
  analysisId: null,
  show: false,
  showAggregationByUserModal: false,
  showAggregationByCategoryModal: false,
  showAnalysisReportModal: false,
  transactions: [],
  tabs: [],
  hasOauthToken: false,
  authUrl: '',
  hasBeenExported: false,
  exporting: false,
  zenginTransactionDate: moment(getDateFromNow(1)).format('YYYY/MM/DD'),
  payerAccounts: [],
  selectedPayerAccount: {
    id: null,
    bank: { name: null },
    bankBranch: { name: null },
    number: null,
    type: null,
    holderKana: null,
    costumerCode: null,
  },
};

function getDateFromNow(days) {
  const currentDate = new Date();

  return currentDate.setDate(currentDate.getDate() + days);
}

const exportData = (state = initialState, action) => {
  switch (action.type) {
    case ActionTypes.SET_TRANSACTIONS:
      return {
        ...state,
        transactions: action.transactions,
      };
    case ActionTypes.SETUP_TABS:
      return {
        ...state,
        tabs: action.tabs,
      };
    case ActionTypes.ACTIVATE_TAB: {
      const { tabs } = state;
      const newTabs = tabs.map((x) => {
        if (x.name === action.groupName) {
          return { ...x, isActive: true };
        }

        return x;
      });

      return {
        ...state,
        tabs: newTabs,
      };
    }
    case ActionTypes.RESET_ACTIVE_TAB: {
      const { tabs } = state;

      return {
        ...state,
        tabs: tabs.map((x) => ({ ...x, isActive: false })),
      };
    }
    case ActionTypes.SET_PAYER_ACCOUNT: {
      return {
        ...state,
        selectedPayerAccount: action.data,
      };
    }
    case ActionTypes.TOGGLE_HAS_BEEN_EXPORTED:
      return {
        ...state,
        hasBeenExported: action.hasBeenExported,
      };
    case ActionTypes.TOGGLE_EXPORTING:
      return {
        ...state,
        exporting: action.exporting,
      };
    case ActionTypes.TOGGLE_SHOW:
      return {
        ...state,
        show: action.show,
      };
    case ActionTypes.TOGGLE_SHOW_AGGREGATION_BY_USER:
      return {
        ...state,
        showAggregationByUserModal: action.showAggregationByUserModal,
      };
    case ActionTypes.TOGGLE_SHOW_AGGREGATION_BY_CATEGORY:
      return {
        ...state,
        showAggregationByCategoryModal: action.showAggregationByCategoryModal,
      };
    case ActionTypes.TOGGLE_SHOW_ANALYSIS_REPORT:
      return {
        ...state,
        showAnalysisReportModal: action.showAnalysisReportModal,
      };
    case ActionTypes.SET_ZENGIN_TRANSACTION_DATE:
      return {
        ...state,
        zenginTransactionDate: action.date,
      };
    default:
      return state;
  }
};

export const initialExportData = initialState;
export default exportData;
