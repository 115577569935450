import LabeledComponent from "components/LabeledComponent";
import React, { FC } from "react";

interface Props {
  value: string;
  label: string;
  handleGenericFieldSearchChange: () => void;
}

const GenericFieldSearchBoxCondition: FC<Props> = (props) => {
  return (
    <LabeledComponent
      className="col-sm-3"
      label={props.label}
      style={{ paddingBottom: "8px" }}
    >
      <input
        type="text"
        className="form-control validate[required, maxSize[255]]"
        value={props.value}
        onChange={props.handleGenericFieldSearchChange}
      />
    </LabeledComponent>
  );
};

export default GenericFieldSearchBoxCondition;
