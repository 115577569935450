/* eslint-disable @typescript-eslint/explicit-module-boundary-types, @typescript-eslint/explicit-function-return-type */

import Api from 'utilities/api';
import flash from 'utilities/flash';
import { getMessageFromResponse } from 'utilities/Utils';
import { useCallback, useState } from 'react';

export const useFetchTasks = ({
  setTasks,
}) => {
  const [processing, setProcessing] = useState<boolean>(false);

  const fetchTasks = useCallback(async (): Promise<void> => {
    try {
      setProcessing(true);

      const res = await Api.accountingDataScheduledExports.tasks.index();
      setTasks(res.data);
    } catch (error) {
      flash.error(getMessageFromResponse(error as Record<string, unknown>));
    } finally {
      setProcessing(false);
    }
  }, [setTasks]);

  return {
    fetchTasks,
    processing,
  };
};
