import BankSuggestField from 'components/fields/payeeAccounts/BankSuggestField';
import CategoryFieldWithInputModal from 'components/fields/categories/CategoryFieldWithInputModal';
import DepartmentSelector from 'components/DepartmentSelector';
import Formatter from 'utilities/formatter';
import ItemFieldWithModalSelector from 'components/fields/genericFields/ItemFieldWithModalSelector';
import NumberField from 'components/fields/NumberField';
import ProjectField from 'components/fields/ProjectField';
import React, { FC } from 'react';
import SelectField from 'components/fields/SelectField';
import SuperCategoryFieldWithInputModal from 'components/fields/superCategories/SuperCategoryFieldWithInputModal';
import TextField from 'components/fields/TextField';
import i18next from 'i18n';
import last from 'lodash/last';
import styled from 'styled-components';
import {
  Bank, Category, ConditionLeftHandSide, ConditionRightHandSide, DebitInvoiceCategory, Department, Project, SuperCategory, Supplier, TaxCategory,
} from 'utilities/api/models/JournalEntries';
import { Item } from 'utilities/api/models/genericFields';
import { Operator } from 'utilities/api/types/JournalEntries';
import { PaymentMethodField } from 'components/fields/paymentMethods/PaymentMethodField';
import { PaymentMethodType } from 'utilities/api_payment_requests/models/PaymentMethod';
import { ReportLabel } from 'utilities/api_payment_requests/models';
import { ReportLabelField } from 'components/fields/reportLabels/ReportLabelField';
import { SupplierFieldWithInputModal } from 'components/fields/suppliers/SupplierFieldWithInputModal';

export interface DataSetWithItems {
  dataSetId: string;
  name: string;
  items?: Item[];
}

interface Props {
  departments?: Department[];
  dataSet: DataSetWithItems | undefined;
  operator: Operator;
  left: ConditionLeftHandSide;
  right: ConditionRightHandSide;
  taxCategories?: TaxCategory[];
  onChangeBooleanValue: (booleanValue: boolean) => void;
  onChangeValue: (value: string) => void;
  onSelectBank: (bank: Bank | null) => void;
  onSelectPayFee: (payFee: 'our' | 'their' | null) => void;
  onSelectCategory: (categories: Category[]) => void;
  onSelectDepartment: (departments: Department[]) => void;
  onChangeNumber: (number: number) => void;
  onSelectProject: (projects: Project[]) => void;
  onSelectSuperCategory: (superCategories: SuperCategory | SuperCategory[]) => void;
  onSelectCreditSuperCategory: (creditSuperCategories: SuperCategory | SuperCategory[]) => void;
  onSelectTaxCategory: (taxCategory: TaxCategory) => void;
  onSelectCreditInvoiceCategory: (creditInvoiceCategory: DebitInvoiceCategory) => void;
  onSelectInvoiceCategory: (debitInvoiceCategories: DebitInvoiceCategory[]) => void;
  onSelectSupplier: (suppliers: Supplier | Supplier[]) => void;
  onSelectReportLabel: (reportLabels: ReportLabel[]) => void;
  onSelectPaymentMethodType: (paymentMethodType: PaymentMethodType | undefined) => void;
  onSelectPaymentMethodTypes: (paymentMethodTypes: PaymentMethodType[]) => void;
  onSelectGenericFieldItems: (items: Item[]) => void;
}

const RightHandSideFormView = styled.div`
  flex-grow: 1;
  height: auto;

  .category-field {
    height: 100%;

    .input-field {
      height: 100%;
    }
  }

  .project-field {
    height: 100%;

    .input-field {
      height: 100%;
    }
  }

  .select-field {
    width: 100%;
  }

  .super-category-field {
    height: 100%;

    .input-field {
      height: 100%;
    }
  }

  .text-left {
    height: 100%;
  }

  .generic-fields-input {
    height: 100%;
    
    .input-field {
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
`;

function departmentButtonFormat(department: Department | Department[], emptyText): JSX.Element {
  if (department instanceof Array) {
    return Formatter.text(department && department.map((d) => d.name).join(', '), emptyText);
  }

  return Formatter.text(department && department.name, emptyText);
}

const ConditionRightHandSideForm: FC<Props> = (props) => {
  const {
    onChangeBooleanValue, onChangeValue, onChangeNumber, onSelectPayFee, onSelectBank, onSelectCategory, onSelectDepartment,
    onSelectProject, onSelectSuperCategory, onSelectCreditSuperCategory, onSelectTaxCategory,
    onSelectCreditInvoiceCategory, onSelectInvoiceCategory, onSelectSupplier,
    onSelectReportLabel, onSelectPaymentMethodType, onSelectPaymentMethodTypes,
    departments, operator, left, right, taxCategories,
  } = props;
  const multiSelectable = operator === 'in' || operator === 'not_in' || operator === 'all_equal' || operator === 'contains_any' || operator === 'contains_all' || operator === 'not_contain_any';
  const rightHandSideType = last(right.type.split('::'));

  if (rightHandSideType === 'Boolean') {
    type BooleanOption = {
      value: boolean;
      label: string;
    };
    const options = [
      { value: true, label: right.trueExpression },
      { value: false, label: right.falseExpression },
    ];
    const value = options.find((o) => { return o.value === right.booleanValue; });

    return (
      <RightHandSideFormView>
        <SelectField className='select-field'
          value={ value }
          options={ options }
          getOptionLabel={ (x: BooleanOption): string => x.label }
          getOptionValue={ (x: BooleanOption): boolean => x.value }
          onChange={ (option: BooleanOption): void => onChangeBooleanValue(option.value) }
          isClearable={ false }
        />
      </RightHandSideFormView>
    );
  }

  if (rightHandSideType === 'PayFeeReference') {
    type PayFeeOption = {
      value: 'their' | 'our';
      label: string;
    };
    const options: PayFeeOption[] = [
      { value: 'their', label: i18next.t('paymentRequests.properties.their') },
      { value: 'our', label: i18next.t('paymentRequests.properties.our') },
    ];
    const option = options.find((o) => { return o.value === right.payFee; });

    return (
      <RightHandSideFormView>
        <SelectField className='select-field'
          value={ option }
          options={ options }
          onChange={ (o: PayFeeOption): void => onSelectPayFee(o ? o.value : null) }
          />
      </RightHandSideFormView>
    );
  }

  if (rightHandSideType === 'BankReference') {
    // 銀行は複数選択に非対応
    const value = right.bank || { id: '', name: '' };

    return (
      <RightHandSideFormView>
        <BankSuggestField
          text={ value.name }
          onSelect={ onSelectBank }
          onTextChange={ (text: string): void => onSelectBank({ id: null, name: text }) }
        />
      </RightHandSideFormView>
    );
  }

  if (rightHandSideType === 'CategoryReference') {
    const value = multiSelectable ? (right.categories || []) : (right.category || { id: '', name: '' });

    return (
      <RightHandSideFormView>
        <CategoryFieldWithInputModal className={ 'category-field' }
          isMultiSelectable={ multiSelectable }
          value={ value }
          onSelect={ onSelectCategory }
          onlyChildCategories={ true }
        />
      </RightHandSideFormView>
    );
  }

  if (rightHandSideType === 'DepartmentReference') {
    const selectedDepartment = multiSelectable ? (right.departments || []) : (right.department || { id: '', name: '' });

    return (
      <RightHandSideFormView>
        <div className='list-selector form-control'>
          <DepartmentSelector
            buttonFormat={ (d, emptyText): JSX.Element => departmentButtonFormat(d, emptyText) }
            departments={ departments || [] }
            isMultiSelectable={ multiSelectable }
            selectedDepartment={ selectedDepartment }
            onSelectDepartment={ onSelectDepartment }
          />
        </div>
      </RightHandSideFormView>
    );
  }

  if (rightHandSideType === 'Number') {
    return (
      <RightHandSideFormView>
        <NumberField
          allowDecimal={ true }
          allowMinus={ true }
          className="form-control text-left"
          value={ right.number }
          onChange={ onChangeNumber }
        />
      </RightHandSideFormView>
    );
  }

  if (rightHandSideType === 'SuperCategoryReference') {
    const value = multiSelectable ? (right.superCategories || []) : (right.superCategory || { id: '', name: '' });

    return (
      <RightHandSideFormView>
        <SuperCategoryFieldWithInputModal className={ 'super-category-field' }
          isMultiSelectable={ multiSelectable }
          isStatic={ false }
          value={ value }
          onSelect={ onSelectSuperCategory }
        />
      </RightHandSideFormView>
    );
  }

  if (rightHandSideType === 'CreditSuperCategoryReference') {
    const value = multiSelectable ? (right.creditSuperCategories || []) : (right.creditSuperCategory || { id: '', name: '' });

    return (
      <RightHandSideFormView>
        <SuperCategoryFieldWithInputModal className={ 'super-category-field' }
          isMultiSelectable={ multiSelectable }
          isStatic={ false }
          value={ value }
          onSelect={ onSelectCreditSuperCategory }
        />
      </RightHandSideFormView>
    );
  }

  if (rightHandSideType === 'ProjectReference') {
    const value = multiSelectable ? (right.projects || []) : (right.project || { id: '', displayId: '', name: '' });

    return (
      <RightHandSideFormView>
        <ProjectField className={ 'project-field' }
          isMultiSelectable={ multiSelectable }
          isStatic={ false }
          value={ value }
          onSelect={ onSelectProject }
        />
      </RightHandSideFormView>
    );
  }

  if (rightHandSideType === 'String') {
    return (
      <RightHandSideFormView>
        <TextField
          className="form-control text-left"
          editable={ true }
          placeholder={ left.type.includes("::Metadata") ? i18next.t('commons.actions.unset') : i18next.t('journalEntries.placeholder.blank') }
          value={ right.value }
          onValueChange={ onChangeValue }
        />
      </RightHandSideFormView>
    );
  }

  if (rightHandSideType === 'TaxCategoryReference') {
    const value = taxCategories?.find((t) => { return t.id === right.taxCategory?.id; });

    return (
      <RightHandSideFormView>
        <SelectField className='select-field'
          value={ value }
          options={ taxCategories }
          getOptionLabel={ (t): string => t.name }
          getOptionValue={ (t): boolean => t.id }
          onChange={ onSelectTaxCategory }
          isClearable={ false }
        />
      </RightHandSideFormView>
    );
  }

  if (rightHandSideType === 'CreditInvoiceCategoryReference') {
    const value = multiSelectable ? (right.creditInvoiceCategories || []) : (right.creditInvoiceCategory || { id: '', name: '' });

    return (
      <RightHandSideFormView>
        <CategoryFieldWithInputModal className={ 'category-field' }
          isMultiSelectable={ multiSelectable }
          value={ value }
          onSelect={ onSelectCreditInvoiceCategory }
          onlyChildCategories={ true }
          categoryType='invoice-credit'
        />
      </RightHandSideFormView>
    );
  }

  if (rightHandSideType === 'DebitInvoiceCategoryReference') {
    const value = multiSelectable ? (right.debitInvoiceCategories || []) : (right.debitInvoiceCategory || { id: '', name: '' });

    return (
      <RightHandSideFormView>
        <CategoryFieldWithInputModal className={ 'category-field' }
          isMultiSelectable={ multiSelectable }
          value={ value }
          onSelect={ onSelectInvoiceCategory }
          onlyChildCategories={ true }
          categoryType='invoice-debit'
        />
      </RightHandSideFormView>
    );
  }

  if (rightHandSideType === 'SupplierReference') {
    const value = multiSelectable ? (right.suppliers || []) : right.supplier || null;

    return (
      <RightHandSideFormView>
        <SupplierFieldWithInputModal className={ 'supplier-field' }
          isMultiSelectable={ multiSelectable }
          isStatic={ false }
          value={ value }
          onSelect={ onSelectSupplier }
        />
      </RightHandSideFormView>
    );
  }

  if (rightHandSideType === 'ReportLabelReference') {
    return (
      <RightHandSideFormView>
        <ReportLabelField
          selection={ right.reportLabels || [] }
          onReportLabelsChange={ onSelectReportLabel }
        />
      </RightHandSideFormView>
    );
  }

  if (rightHandSideType === 'PaymentMethodType') {
    if (multiSelectable) {
      return (
        <RightHandSideFormView>
          <PaymentMethodField
            multiSelectable
            methods={ right.paymentMethodTypes || [] }
            onPaymentMethodsChange={ onSelectPaymentMethodTypes }
          />
        </RightHandSideFormView>
      );
    }

    return (
      <RightHandSideFormView>
        <PaymentMethodField
          methods={ right.paymentMethodType }
          onPaymentMethodsChange={ onSelectPaymentMethodType }
        />
      </RightHandSideFormView>
    );
  }

  if (rightHandSideType === 'DataSetReference') {
    return (
      <RightHandSideFormView>
        <ItemFieldWithModalSelector
          dataSet={ props.dataSet }
          isMultipleSelectable={ multiSelectable }
          onSelectItems={ props.onSelectGenericFieldItems }
        />
      </RightHandSideFormView>
    );
  }

  return null; // 通らない
};

export default ConditionRightHandSideForm;
