import { useCallback, useState } from "react";

type NodeRef = (node: HTMLElement | null) => void;

/**
 * アイコンの最小サイズ
 */
const MINIMUM_ICON_SIZE = 16;

/**
 * アイコン表示のレイアウトのためのロジック
 */
const useIcon = (): [NodeRef, number] => {
  const [iconSize, setIconSize] = useState<number>(MINIMUM_ICON_SIZE);
  const ref: NodeRef = useCallback((node: HTMLElement | null) => {
    if (node) {
      const { width, height } = node.getBoundingClientRect();

      setIconSize(
        Math.max(MINIMUM_ICON_SIZE, Math.ceil(Math.min(width, height) / 7)),
      );
    }
  }, []);

  return [ref, iconSize];
};

export default useIcon;
