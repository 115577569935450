import React, { Component } from 'react';
import isNil from 'lodash/isNil';
import uniqueId from 'lodash/uniqueId';

type Text = string | number;
type VisualMode = 'display' | 'edit';
type SwitchMode = (mode: VisualMode) => void;

interface Props {
  /** ユーザがデータを変更中かどうかのフラグ。inputタグの枠線の色を変更するために使用 */
  sync?: boolean;
  text?: Text;
  editingText?: Text;
  className?: string;
  /** trueの時、blurイベントで更新処理を行う */
  updateOnBlur: boolean;
  /** trueの時、編集モードで表示する */
  isEditing: boolean;
  /** 表示モードの時に表示するテキストを生成する */
  formatter: (text?: Text) => Text | React.ReactNode;
  /** サーバへの更新処理 */
  onUpdate: (text?: Text) => Promise<void>;
  /** inputの内容の変更処理 */
  onChange: (v: string) => void;
  /** 編集モードから表示モードに戻る際の処理 */
  onCancel?: () => void;
  /** モード切替処理 */
  switchMode?: SwitchMode;
  input?: {
    className?: string;
    type?: string;
    placeholder: string;
  };
  icon?: {
    className?: string;
    /** @todo object型は使わないように */
    // eslint-disable-next-line @typescript-eslint/ban-types
    style?: object;
  };
  button?: {
    className?: string;
    text?: string;
  };
}

/**
 * テキストのインライン編集のためのBaseクラス
 * これを継承し、renderInEditMode関数を実装する
 */
export default class EditableBase extends Component<Props> {
  static defaultProps: Partial<Props> = {
    formatter: (text?: Text) => text,
  }

  /** コンポーネントへ割り当てるID */
  id: string;

  /** ボタンに割り当てるID */
  buttonId: string;

  /** 編集時にどう見せるかレンダリングを制御する */
  renderInEditMode(): React.ReactElement {
    return (<></>);
  }

  constructor(props: Props) {
    super(props);

    this.id = uniqueId('editable-input-');
    this.buttonId = `${this.id}-button`;
    this.renderInEditMode = this.renderInEditMode.bind(this);
    this.renderInDisplayMode = this.renderInDisplayMode.bind(this);
    this.switchToDisplayMode = this.switchToDisplayMode.bind(this);
    this.switchToEditMode = this.switchToEditMode.bind(this);
    this.handleKeyPress = this.handleKeyPress.bind(this);
    this.handleBlur = this.handleBlur.bind(this);
    this.onUpdate = this.onUpdate.bind(this);
    this.onTextChange = this.onTextChange.bind(this);
    this.onClickIcon = this.onClickIcon.bind(this);
  }

  onClickIcon(): void {
    const { isEditing } = this.props;

    if (isEditing) {
      this.switchToDisplayMode();
    } else {
      this.switchToEditMode();
    }
  }

  switchToDisplayMode(): void {
    if (this.props.switchMode) {
      this.props.switchMode('display');
    }
    if (this.props.onCancel) {
      this.props.onCancel();
    }
  }

  switchToEditMode(): void {
    if (this.props.switchMode) {
      this.props.switchMode('edit');
    }
  }

  handleKeyPress(e: React.KeyboardEvent<HTMLInputElement>): void {
    // formのsubmitイベントの発生を防ぎつつ、更新処理を行う
    if (e.keyCode === 13 || e.which === 13) {
      e.preventDefault();
      this.onUpdate(e);
    }
  }

  handleBlur(e: React.FocusEvent<HTMLInputElement>): void {
    // updateOnBlurがtrueなら、無条件に更新
    if (this.props.updateOnBlur) {
      this.onUpdate(e);
      return;
    }

    // jQueryのイベントオブジェクトで、クリックイベントを検知
    const relatedTarget = e.relatedTarget as HTMLElement | null;
    if (!isNil(relatedTarget) && relatedTarget.id === this.buttonId) {
      // クリックイベントでもblurイベントが発火してしまうため、blurイベントでもクリック時の処理を行う
      this.onUpdate(e);
    } else {
      // ボタン以外の場所でクリックすると、表示モードへ
      this.switchToDisplayMode();
    }
  }

  onUpdate(e: React.SyntheticEvent<HTMLInputElement>): void {
    if (e) {
      e.stopPropagation();
      e.preventDefault();
    }

    (async (): Promise<void> => {
      // 通信時の例外処理は、利用するComponent側で
      await this.props.onUpdate(this.props.editingText);
      this.switchToDisplayMode();
    })();
  }

  onTextChange(e: React.ChangeEvent<HTMLInputElement>): void {
    e.stopPropagation();

    this.props.onChange(e.target.value);
  }

  render(): React.ReactElement {
    return this.props.isEditing
      ? this.renderInEditMode()
      : this.renderInDisplayMode();
  }

  renderInDisplayMode(): React.ReactElement {
    return (
      <div style={ { display: '-webkit-inline-box' } }>
        <span className={ this.props.className }>
          { this.props.formatter(this.props.text) }
        </span>
        <i
          aria-hidden='true'
          className={ this.props.icon?.className }
          style={ this.props.icon?.style }
          onClick={ this.onClickIcon }
        />
      </div>
    );
  }
}
