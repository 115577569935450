import transactionTable from "applications/transactions/reducers/transactionTable";
import { combineReducers } from "redux";
import cancelApprovalModal from "./CancelApprovalModal";
import cancelTemporaryPaymentModal from "./CancelTemporaryPaymentModal";
import cancelTemporaryPaymentRefundModal from "./CancelTemporaryPaymentRefundModal";
import formState from "./formState";
import histories from "./histories";
import preReport from "./preReport";
import preReportRequest from "./preReportRequest";
import suggestions from "./suggestions";

export default combineReducers({
  preReport,
  preReportRequest,
  formState,
  suggestions,
  authority: (state = "approval") => state,
  defaultPeriod: (state = { from: null, to: null }) => state,
  requestTypeId: (state = null) => state,
  transactionTable,
  histories,
  cancelApprovalModal,
  cancelTemporaryPaymentModal,
  cancelTemporaryPaymentRefundModal,
});
