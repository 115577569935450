import Clearable from 'components/fields/Clearable';
import PropTypes from 'prop-types';
import React, { useCallback, useMemo } from 'react';
import colors from 'values/colors';
import fonts from 'values/fonts';
import i18next from 'i18n';
import { debounce } from 'lodash';

/** debounceを使用する場合に適用される入力バッファ時間(ms) */
const DEBOUNCE_TIME_MS = 200;

/**
 * 送信を取り扱うコールバックを返す。

 * debounceを利用する場合は、handleKeyDownとhandleClickの両方をdebounceしたものを返す。
 * @param {string} value
 * @param {(value: string) => void} onClick
 * @param {boolean} isSubmitDebounced
 * @returns
 */
function useSubmitCallbacks(value, onClick, isSubmitDebounced) {
  const debouncedOnClick = useMemo(() => debounce(onClick, DEBOUNCE_TIME_MS), [onClick]);

  const handleKeyDown = useCallback((e) => {
    e.persist();
    const ENTER = 13;
    if (e.keyCode === ENTER || e.which === ENTER) {
      e.preventDefault();
      if (isSubmitDebounced) {
        debouncedOnClick(value);
      } else {
        onClick(value);
      }
    }
  }, [debouncedOnClick, isSubmitDebounced, onClick, value]);

  const handleClick = useCallback((e) => {
    e.preventDefault();
    if (isSubmitDebounced) {
      debouncedOnClick(value);
    } else {
      onClick(value);
    }
  }, [debouncedOnClick, isSubmitDebounced, onClick, value]);

  return {
    handleKeyDown,
    handleClick,
  };
}

const SearchBox = (props) => {
  const {
    className, inputProps, value, placeholder, isSubmitDebounced, onChange, onClick,
  } = props;
  const handleTextChange = useCallback((e) => { onChange(e.target.value); }, [onChange]);
  const handleClear = useCallback(() => { onChange(''); }, [onChange]);
  const { handleKeyDown, handleClick } = useSubmitCallbacks(value, onClick, isSubmitDebounced);

  return (
    <Clearable className={ `searchbox ${className}` } buttonStyle={ { right: '76px', zIndex: 3 } } onClear={ handleClear }>
      <div className='input-group'>
        <span className='input-group-addon'>
          <i className='fa fa-search'></i>
        </span>

        <input type='search'
          className='form-control'
          placeholder={ placeholder }
          value={ value }
          onChange={ handleTextChange }
          onKeyDown={ handleKeyDown }
          { ...inputProps }
          style={ { paddingRight: '18px', ...(inputProps.style || {}) } }
        />

        <span className='input-group-btn'>
          <button className='btn'
            style={
              {
                background: colors.renewaled.main,
                color: '#fff',
                fontWeight: fonts.weight.bold,
                width: '70px',
              }
            }
            type='button'
            onClick={ handleClick }
          >
            { i18next.t('commons.actions.search') }
          </button>
        </span>
      </div>
    </Clearable>
  );
};

SearchBox.defaultProps = {
  className: '',
  placeholder: '',
  inputProps: {},
  isSubmitDebounced: false,
};

SearchBox.propTypes = {
  className: PropTypes.string.isRequired,
  inputProps: PropTypes.object,
  value: PropTypes.string,
  placeholder: PropTypes.string.isRequired,
  onClick: PropTypes.func,
  onChange: PropTypes.func,
  isSubmitDebounced: PropTypes.bool,
};

export default SearchBox;
