/* eslint-disable @typescript-eslint/explicit-function-return-type */
import { ActionTypes } from './ActionTypes';
import { DepartmentPostRelationshipApprover } from 'utilities/api/models/Approver';

interface SetAction {
  type: typeof ActionTypes.SET_SELECTED_DEPARTMENT_POST_RELATIONSHIPS;
  payload: {
    selectedDepartmentPostRelationships: DepartmentPostRelationshipApprover[];
  };
}

interface SelectAction {
  type: typeof ActionTypes.SELECT_DEPARTMENT_POST_RELATIONSHIP;
  payload: {
    departmentPostRelationship: DepartmentPostRelationshipApprover;
  };
}

interface DeselectAction {
  type: typeof ActionTypes.DESELECT_DEPARTMENT_POST_RELATIONSHIP;
  payload: {
    departmentPostRelationship: DepartmentPostRelationshipApprover;
  };
}

/**
 * 選択済み部署役職をセットする
 * @param selectedDepartmentPostRelationships 選択済み部署役職
 */
export function setSelectedDepartmentPostRelationships(selectedDepartmentPostRelationships: DepartmentPostRelationshipApprover[]): SetAction {
  return {
    type: ActionTypes.SET_SELECTED_DEPARTMENT_POST_RELATIONSHIPS,
    payload: {
      selectedDepartmentPostRelationships,
    },
  };
}

/**
 * 部署役職を選択する
 * @param departmentPostRelationship 部署役職
 */
export function selectDepartmentPostRelationship(departmentPostRelationship: DepartmentPostRelationshipApprover): SelectAction {
  return {
    type: ActionTypes.SELECT_DEPARTMENT_POST_RELATIONSHIP,
    payload: {
      departmentPostRelationship,
    },
  };
}

/**
 * 部署役職を選択解除する
 * @param departmentPostRelationship 部署役職
 */
export function deselectDepartmentPostRelationship(departmentPostRelationship: DepartmentPostRelationshipApprover): DeselectAction {
  return {
    type: ActionTypes.DESELECT_DEPARTMENT_POST_RELATIONSHIP,
    payload: {
      departmentPostRelationship,
    },
  };
}
