import React from "react";
import * as Types from "../types";
import { RemoveRowButton } from "./buttons";
import { AmountInput } from "./forms";
import { SeparatorSelector } from "./SeparatorSelector";

interface Props {
  /** 分割項目別金額 */
  amountBySeparator: Types.AmountBySeparator;
  /** 行を削除可能か */
  canRemoveRow: boolean;
  /** 分割項目種別 */
  separatorType: Types.SeparatorType;
  /** テーブルレイアウト */
  tableStyle: Types.TableStyle;
  /**
   * 金額変更時
   * @param amount 金額
   */
  onChangeAmount: (amount: number) => void;
  /** 金額入力inputからフォーカスアウト時 */
  onBlurAmountInput: () => void;
  /**
   * 分割項目変更時
   * @param separator 分割項目
   */
  onChangeSeparator: (separator: Types.Separator) => void;
  /** 行削除時 */
  onRemove: () => void;
}

/** 分割項目別金額行 */
export const AmountBySeparatorRow: React.FC<Props> = ({
  amountBySeparator,
  canRemoveRow,
  tableStyle,
  onChangeAmount,
  onBlurAmountInput,
  onChangeSeparator,
  onRemove,
  separatorType,
}) => {
  return (
    <tr data-testid="split-modal-table-row">
      <td colSpan={tableStyle.firstColSpan}>
        <SeparatorSelector
          separatorType={separatorType}
          separator={amountBySeparator.separator}
          onChange={onChangeSeparator}
        />
      </td>
      <td style={{ width: tableStyle.secondColWidth }}>
        <AmountInput
          amount={amountBySeparator.amount}
          onChange={onChangeAmount}
          onBlurAmountInput={onBlurAmountInput}
        />
      </td>
      <td style={{ width: tableStyle.thirdColWidth }}>
        {canRemoveRow && <RemoveRowButton onClick={onRemove} />}
      </td>
    </tr>
  );
};
