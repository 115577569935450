import * as ActionTypes from "../actions/reportHistories";

const initialState = {
  reportHistories: [],
  expenseHistories: [],
  cache: {}, // transactionのsummaryをcacheする
  expenseForReference: null,
  selectedExpenseId: null,
};

const histories = (state = initialState, action) => {
  switch (action.type) {
    case ActionTypes.INIT_REPORT_HISTORIES: {
      const { reportHistories, transactionHistories } = action.payload;

      return {
        ...state,
        reportHistories: [...(reportHistories || [])],
        expenseHistories: [...(transactionHistories || [])],
      };
    }
    case ActionTypes.WRITE_HISTORY_CACHE: {
      return {
        ...state,
        cache: {
          ...state.cache,
          [action.payload.id]: { ...action.payload },
        },
      };
    }
    case ActionTypes.SET_TRANSACTION_FOR_REFERENCE: {
      return {
        ...state,
        expenseForReference: action.payload,
      };
    }
    case ActionTypes.TOGGLE_HISTORY_MODAL: {
      return {
        ...state,
        selectedExpenseId: action.payload.show
          ? action.payload.transactionId
          : null,
      };
    }
    default:
      return state;
  }
};

export default histories;
