import LabeledComponent from 'components/LabeledComponent';
import PeriodInput from 'components/PeriodInput';
import React, { FC } from 'react';
import i18next from 'i18n';

interface Props {
  className?: string;
  scheduleFrom: string | undefined;
  scheduleTo: string | undefined;
  onScheduleFromChange: (date: string) => void;
  onScheduleToChange: (date: string) => void;
}

/**
 * Invoice における支払希望日の範囲を入力する
 */
export const ScheduleAtRangeField: FC<Props> = ({
  className,
  scheduleFrom,
  scheduleTo,
  onScheduleFromChange,
  onScheduleToChange,
}) => {
  return (
    <LabeledComponent
      className={ className }
      label={ `${i18next.t('paymentRequests.properties.scheduleAt')}（${i18next.t('menus.ip.invoices')}）` }
    >
      <PeriodInput
        className='lastapproved-at-period'
        dateFrom={ scheduleFrom }
        dateTo={ scheduleTo }
        onChangeDateFrom={ (date: string): void => onScheduleFromChange(date ? new Date(date).toISOString() : date) }
        onChangeDateTo={ (date: string): void => onScheduleToChange(date ? new Date(date).toISOString() : date) }
      />
    </LabeledComponent>
  );
};
