import Api from 'utilities/api';
import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';
import SuggestField from 'components/fields/SuggestField';
import _isNil from 'lodash/isNil';
import formatter from 'utilities/formatter';
import { fetchAsync } from 'utilities/async';

/**
 * 従業員を選択する際、入力内容に応じてサジェストを出すComponent
 * 現在は、必ず通信が発生する
 */
export default class AgentSuggestField extends PureComponent {
  fetchOptions(selectedMemberId, searchText, offset, limit) {
    const userId = !_isNil(selectedMemberId) ? selectedMemberId : userPreferences.id;
    const params = {
      userId,
      searchText,
      offset,
      limit,
    };

    return fetchAsync(Api.agents.candidates, params)
      .then((response) => {
        return { count: response.count, data: response.data };
      });
  }

  /**
   * @param {string} option.id
   * @param {string} option.name
   * @param {string} option.email
   */
  getOptionText(option) {
    return option.name;
  }

  renderOption(option) {
    return formatter.memberPull(option);
  }

  render() {
    return (
      <SuggestField async={ true }
        text={ this.props.text }
        value={ this.props.value }
        placeholder={ this.props.placeholder }
        fetchOptions={ this.fetchOptions.bind(null, this.props.selectedMemberId) }
        initialOptions={ this.props.initialOptions }
        getOptionText={ this.getOptionText }
        renderOption={ this.renderOption }
        onSelect={ this.props.onSelect }
        onTextChange={ this.props.onTextChange }
      />
    );
  }
}

AgentSuggestField.propTypes = {
  initialOptions: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    email: PropTypes.string.isRequired,
  })),
  text: PropTypes.string,
  value: PropTypes.shape({
    id: PropTypes.string,
    name: PropTypes.string.isRequired,
    email: PropTypes.string,
  }),
  placeholder: PropTypes.string,
  onSelect: PropTypes.func.isRequired,
  onTextChange: PropTypes.func,
  selectedMemberId: PropTypes.string,
};
