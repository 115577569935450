import React from 'react';
import colors from 'values/colors';
import fonts from 'values/fonts';
import i18next from 'i18n';
import styled from 'styled-components';
import { TableColumnControlParam } from 'components/renewaled_ui/ModalColumnControl/interface';

const Wrapper = styled.div`
  width: 40%;
`;
const Title = styled.div`
  font-weight: ${fonts.weight.bold};
  margin-bottom: 8px;
  user-select: none;
`;
const Item = styled.li`
  display: flex;
  align-items: center;
  padding: 5px 8px 3px;
  border-radius: 4px;
  cursor: pointer;
  margin-bottom: 4px;
  user-select: none;
  font-weight: ${fonts.weight.bold};
  line-height: 20px;

  &:hover {
    background: ${colors.renewaled.whiteHoverActive}
  }
`;
const Items = styled.ul`
  height: 400px;
  overflow-y: auto;
  padding: 0;
  margin: 0;
`;

interface Props {
  /** 選択可能な列データ */
  readonly selectableColumns: TableColumnControlParam[];
  /** 表示/非表示列データ */
  readonly tableColumns: TableColumnControlParam[];
  /** チェック欄を押した */
  readonly onCheck: (accessor: string) => void;
}

/**
 * 左の欄: 表示/非表示チェックボックス一覧
 */
const VisibleCheckboxs: React.FC<Props> = (p) => {
  return (
    <Wrapper>
      <Title>
        { i18next.t('transactions.index.displayItems') }
      </Title>
      <Items>
        { p.selectableColumns.map((sc) => {
          const c = p.tableColumns.find((tc) => tc.accessor === sc.accessor) || {} as TableColumnControlParam;
          return (
            <Item
              key={ `visible-checkbox-${c.accessor}` }
              onClick={ (): void => p.onCheck(c.accessor) }
            >
              <input
                type='checkbox'
                checked={ c.show }
                onChange={ (): void => p.onCheck(c.accessor) }
                style={ { margin: "0 8px 2px 0" } }
              />
              <div>
                { c.accessor === 'error' ? i18next.t('commons.status.alert') : c.Header }
              </div>
            </Item>
          );
        })}
      </Items>
    </Wrapper>
  );
};

export default VisibleCheckboxs;
