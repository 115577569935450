import LabelWithDescription from './LabelWithDescription';
import React from 'react';
import i18next from 'i18next';
import styled from 'styled-components';
import { InputField } from 'components/renewaled_ui/forms/inputs';
import { RequiredIcon } from '../components/icons/RequiredIcon';
import { useFormContext } from 'react-hook-form';

export const FormView = styled.form`
  overflow-y: scroll;
  max-height: calc(100vh - 120px);
`;

interface Props {
  action: 'create' | 'update';
}

export const Form: React.FC<Props> = ({ action }) => {
  const { control } = useFormContext();

  return (
    <FormView autoComplete='off'>
      <InputField
        name='name'
        label={ <LabelWithDescription type="name" /> }
        control={ control }
        rules= { { required: true } }
        autoComplete='false'
      />
      <InputField
        name='description'
        componentClass='textarea'
        label={ <LabelWithDescription type="description" /> }
        control={ control }
        rules= { { required: true } }
        autoComplete='false'
      />
      <InputField
        name='protocol'
        componentClass='select'
        label={ <>{ i18next.t('accountingDataScheduledExports.fileTransferLocations.form.protocol') } <RequiredIcon /></> }
        control={ control }
        rules= { { required: true } }
        autoComplete='false'
      >
        <option value='sftp'>SFTP</option>
        <option value='ftp'>FTP</option>
      </InputField>
      <InputField
        name='host'
        type='text'
        label={ <LabelWithDescription type="host" /> }
        control={ control }
        rules= { { required: true } }
        autoComplete='false'
      />
      <InputField
        name='port'
        type='text'
        label={ <LabelWithDescription type="port" /> }
        control={ control }
        rules= { { required: true } }
        autoComplete='false'
      />
      <InputField
        name='authMethod'
        componentClass='select'
        label={ <>{ i18next.t('accountingDataScheduledExports.fileTransferLocations.form.authMethod') } <RequiredIcon /></> }
        control={ control }
        rules= { { required: true } }
        autoComplete='false'
      >
        <option value='password'>{ i18next.t('accountingDataScheduledExports.fileTransferLocations.form.authMethodPassword') }</option>
      </InputField>
      <InputField
        name='account'
        type='text'
        label={ <LabelWithDescription type="account" /> }
        control={ control }
        rules= { { required: true } }
        autoComplete='false'
      />
      <InputField
        name='authOptions.password'
        type='password'
        label={ <LabelWithDescription type="password" /> }
        control={ control }
        rules={ { required: action === "create" } }
        autoComplete='false'
        placeholder='Password'
      />
      <InputField
        name='directory'
        type='text'
        label={ <LabelWithDescription type="directory" /> }
        control={ control }
        rules= { { required: true } }
        autoComplete='false'
      />
    </FormView>
  );
};
