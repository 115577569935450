import isNaN from "lodash/isNaN";
import React, { FC } from "react";
import { CurrencyIdType } from "../types/currencyType";

interface Props {
  rate: number;
  currencyId: CurrencyIdType | null;
  disabled: boolean;
  onChange: (rate: number) => void;
}

const RateInput: FC<Props> = (props) => {
  const handleRateChange = (e: React.ChangeEvent<HTMLInputElement>): void => {
    const rate = parseFloat(e.target.value);
    if (isNaN(rate)) {
      props.onChange(0);
    } else {
      props.onChange(parseFloat(e.target.value));
    }
  };

  return (
    <input
      type="number"
      className="form-control"
      min="0"
      step="0.001"
      autoComplete="off"
      autoCorrect="off"
      value={props.rate}
      onChange={handleRateChange}
      disabled={props.disabled}
    />
  );
};

RateInput.defaultProps = {
  rate: 0.0,
  disabled: false,
};

export default RateInput;
