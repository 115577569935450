import * as actions from '../actions/teikiRoutes';
import * as searchRouteActions from '../../fare_transactions/actions/index';
import FareDetailOptions from 'applications/fare_transactions/components/FareDetailOptions';
import LabeledComponent from 'components/LabeledComponent';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import RelaysField from 'applications/fare_transactions/components/RelaysField';
import StationSuggest from 'applications/fare_transactions/components/StationSuggest';
import TeikiDisplay from './TeikiDisplay';
import TeikiInformationField from '../teiki_information_field';
import TeikiOptions from '../teiki_options';
import TeikiTerm from './TeikiTerm';
import get from 'lodash/get';
import i18next from 'i18n';
import uniqueId from 'lodash/uniqueId';
import { Button } from 'react-bootstrap';
import { connect } from 'react-redux';

export class TeikiRouteForm extends Component {
  constructor(props) {
    super(props);

    this.handleAppendRelay = this.handleAppendRelay.bind(this);
    this.handleRemoveRelay = this.handleRemoveRelay.bind(this);
  }

  componentDidMount() {
    this.initializeStation();
  }

  // TODO: 明らかにおかしいので処理を削除する（自分自身のPropをコントロールするのはおかしく、必要があれば上位のComponentで行う）
  initializeStation() {
    const stations = get(this.props.teikiRoute.detail, 'stations', []);
    const start = this.props.teikiRoute.start ? { name: this.props.teikiRoute.start, ekispertCode: get(stations[0], 'ekispertCode') } : null;
    const goal = this.props.teikiRoute.goal ? { name: this.props.teikiRoute.goal, ekispertCode: get(stations[stations.length - 1], 'ekispertCode') } : null;
    const relays = get(this.props.teikiRoute.detail, 'relays', []).map((x) => ({ ...this.emptyRelay, text: x.name, value: { ...x } }));

    this.props.setStation('start', start);
    this.props.setStationInput('start', get(start, 'name', ''));

    // 別のTeikiRouteによって入った値を空配列にリセット
    this.props.onRelaysChange(relays, false);

    this.props.setStation('goal', goal);
    this.props.setStationInput('goal', get(goal, 'name', ''));
  }

  get stationSuggestProps() {
    const { searchOptions, userId } = this.props;

    return {
      ownerId: userId,
      includeTrain: searchOptions.train,
      includeBus: searchOptions.bus,
      includePlane: false,
    };
  }

  get emptyRelay() {
    return {
      id: uniqueId('relay-'),
      text: '',
      value: null,
    };
  }

  handleAppendRelay() {
    this.props.onRelaysChange([...this.props.relays, this.emptyRelay], false);
  }

  handleRemoveRelay(id) {
    this.props.onRelaysChange(this.props.relays.filter((x) => x.id !== id), true);
  }

  renderDetailOption() {
    const { searchCondition } = this.props;

    return (
      <div className='col-sm-offset-3 col-sm-9 search-option-box' style={ { padding: '0 0 15px 15px' } }>
        <FareDetailOptions
          searchCondition={ searchCondition }
          onSearchConditionChange={ this.props.onSearchConditionChange }/>
      </div>
    );
  }

  render() {
    const { inputs, station } = this.props;

    return (
      <div>
        <TeikiOptions
          isTrain={ this.props.searchOptions.train }
          isBus={ this.props.searchOptions.bus }
          onChange={ this.props.onChangeSearchOptions }/>

        { this.renderDetailOption() }

        <TeikiTerm />

        <LabeledComponent className='form-group'
          labelClass='col-sm-3'
          label={ i18next.t('transactions.properties.origin') }
        >
          <div className='col-sm-6'>
            <StationSuggest { ...this.stationSuggestProps }
              text={ inputs.start }
              value={ station.start }
              onTextChange={ this.props.onStartStationChange }
              onSelect={ this.props.onStartStationSuggestionSelected }
            />
          </div>
        </LabeledComponent>

        <div className='form-group teiki-search-form'>
          <div className='col-sm-offset-3 col-sm-6'>
            <div className='col-sm-offset-1 col-sm-11 row teiki-border'>
              <RelaysField className='via-forms col-sm-12'
                { ...this.stationSuggestProps }
                data={ this.props.relays }
                onTextChange={ this.props.onRelayTextChange }
                onSelect={ this.props.onRelaySelect }
                onAppend={ this.handleAppendRelay }
                onRemove={ this.handleRemoveRelay }
              />
            </div>
          </div>
        </div>

        <LabeledComponent className='form-group'
          labelClass='col-sm-3'
          label={ i18next.t('transactions.properties.destination') }
        >
          <div className='col-sm-6'>
            <StationSuggest { ...this.stationSuggestProps }
              text={ inputs.goal }
              value={ station.goal }
              onTextChange={ this.props.onGoalStationChange }
              onSelect={ this.props.onGoalStationSuggestionSelected }
            />
          </div>
        </LabeledComponent>

        <div className='form-group'>
          <div className='col-sm-6 col-sm-offset-3'>
            <Button className='col-sm-12'
              onClick={ this.props.onSearchTeikiSummaries }
              disabled={ this.props.isSearching }
              bsStyle='primary'>
              { this.props.isSearching
                ? <i className='fa fa-spinner fa-spin'></i>
                : i18next.t('preferences.teiki.search')
              }
            </Button>
          </div>
        </div>

        <TeikiInformationField teikiRoute={ this.props.searchedTeikiRoute } />

        <TeikiDisplay selected={ this.props.route || this.props.teikiRoute } />
      </div>
    );
  }
}

function mapStateToProps(state, ownProps) {
  const {
    searchBox: {
      routes, relays, route, checked, station, inputs, searchCondition,
    },
    teikiRoutesTable: { searchedTeikiRoute },
  } = state;

  return {
    routes,
    relays,
    route,
    searchedTeikiRoute,
    searchOptions: checked,
    station,
    inputs,
    searchCondition,
  };
}

function mapDispatchToProps(dispatch, ownProps) {
  /**
   * TODO:
   *   駅選択時に定期検索を行うが、経費入力のコードを流用しているために、経路検索をoffにするようなフラグを使っている。
   *   これを避けるため、駅選択時に呼ばれるcallbackは、外部から指定できるようにactionを修正する。
   * NOTE:
   *   callbackがStoreを参照するように実装されているが、Storeの更新とcallbackの実行の順序が保証されていないので、潜在バグがある。
   */
  return {
    setStation(key, value) {
      dispatch(searchRouteActions.setStation(key, value, false));
    },
    setStationInput(key, value) {
      dispatch(searchRouteActions.setStationInput(key, value));
    },
    onChangeSearchOptions(e) {
      dispatch(searchRouteActions.setSearchOptions(e.target.name));
    },
    onSearchConditionChange(key, value) {
      dispatch(searchRouteActions.setSearchCondition(key, value, actions.fetchTeikiSummaries));
    },
    onStartStationChange(value) {
      dispatch(searchRouteActions.setStationInput('start', value));
    },
    onStartStationSuggestionSelected(station) {
      dispatch(searchRouteActions.selectStationSuggestion('start', station, false));
    },
    onGoalStationChange(value) {
      dispatch(searchRouteActions.setStationInput('goal', value));
    },
    onGoalStationSuggestionSelected(station) {
      dispatch(searchRouteActions.selectStationSuggestion('goal', station, false));
    },
    onRelaysChange(relays, autoSearch = false) {
      dispatch(searchRouteActions.setRelays(relays));
    },
    onRelayTextChange(id, text) {
      dispatch(searchRouteActions.setRelayInput(id, text));
    },
    onRelaySelect(id, station) {
      dispatch(searchRouteActions.selectRelay(id, station));
    },
    onSearchTeikiSummaries() {
      dispatch(actions.fetchTeikiSummaries());
    },
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(TeikiRouteForm);

TeikiRouteForm.defaultProps = {
  teikiRoute: {},
  isProcessing: false,
  isSearching: false,
};

TeikiRouteForm.propTypes = {
  userId: PropTypes.string,
  routes: PropTypes.array,
  route: PropTypes.object,
  teikiRoute: PropTypes.object.isRequired,
  searchedTeikiRoute: PropTypes.object.isRequired,
  searchOptions: PropTypes.object.isRequired,
  searchCondition: PropTypes.object.isRequired,
  station: PropTypes.object.isRequired,
  inputs: PropTypes.object.isRequired,
  relays: PropTypes.array.isRequired,
  setStationInput: PropTypes.func.isRequired,
  onChangeSearchOptions: PropTypes.func.isRequired,
  onSearchConditionChange: PropTypes.func.isRequired,
  onStartStationChange: PropTypes.func.isRequired,
  onStartStationSuggestionSelected: PropTypes.func.isRequired,
  onGoalStationChange: PropTypes.func.isRequired,
  onGoalStationSuggestionSelected: PropTypes.func.isRequired,
  onRelaysChange: PropTypes.func.isRequired,
  isProcessing: PropTypes.bool.isRequired,
  isSearching: PropTypes.bool.isRequired,
};
