const presetEntryFormType = {
  amount: "expense_input",
  originalAmount: "original_amount_input",
  originalAmountCurrencyId: "original_amount_currency_input",
  exchangeRate: "exchange_rate_input",
  exchangePolicy: "exchange_policy_input",
  type: "travel_check_input",
  destination: "travel_destination_input",
  startAt: "travel_start_at_input",
  endAt: "travel_end_at_input",
  needsTemporaryPayment: "temporary_payment_check_input",
  temporaryPaymentDueAt: "temporary_payment_due_at_input",
  temporaryPaymentReason: "temporary_payment_reason_input",
  reportTitle: "report_input",
  report: "report_input",
  preReportTitle: "pre_report_input",
  preReport: "pre_report_input",
  transactedAt: "date_input",
  shopName: "shop_input",
  companions: "companion_input",
  originAndDestination: "origin_and_destination_by_category_input",
  visitByCategory: "visit_by_category_input",
  purposeByCategory: "purpose_by_category_input",
  creditCategory: "super_category_input",
  costAllocations: "cost_allocation_input",
  comment: "multi_line_text_input",
  withholding: "withholding_input",
  address: "address_input",
  fullName: "full_name_input",
  description: "multi_line_text_input",
  title: "text_input",
  isCorporate: "refund_check_input",
  isElectronicReceiptImage: "electronic_receipt_image_flag",
  category: "category_input",
  taxCategory: "tax_category_input",
  project: "project_input",
  creditCategoryName: "super_category_name_input",
  categoryName: "category_name_input",
  taxCategoryName: "tax_category_name_input",
  expenseAmountPerTaxCategories: "amount_per_tax_category_input",
  documentNumber: "document_number_input",
  department: "department_input",
  assignableReport: "assignable_report_input",
  genericFields: "generic_fields_input",
  eligibleInvoiceConfirmation: "eligible_invoice_confirmation_input",
  transitPayee: "transit_payee_input",
};

export default presetEntryFormType;
