import _isNil from "lodash/isNil";
import * as ManualMergeActionTypes from "../actions/manualMergeActions";
import * as ActionTypes from "../actions/transactionTable";
import columnParamsStorage from "../utilities/columnParamsStorage";
import reactTableColumnParamsStorage from "../utilities/reactTableColumnParamsStorage";
import searchParamsStorage from "../utilities/searchParamsStorage";

const initialState = {
  transactions: {
    master: [],
    inEdit: [],
  },
  entryForms: [],
  currentPage: 1,
  sizePerPage: 30,
  columnVisibilities: { ...columnParamsStorage.data },
  reactTableColumnParams: { ...reactTableColumnParamsStorage.data },
  searchConditions: { ...searchParamsStorage.data },
  searchBoxRef: null,
  modal: {
    isDeleteModalOpen: false,
    isTransactionModalOpen: false,
    transaction: {},
    previousId: null, // 表示中の経費の一つ前の経費ID
    nextId: null, // 表示中の経費の一つ後の経費ID
  },
  costAllocationModal: {
    show: false,
    costAllocations: [],
    hovor: null,
  },
  shouldSelectSelfAsCompanion: true,
  inProcess: false,
  isEditing: false,
  isSearchWithOnlyDeleted: false,
  suggestions: {
    categoryName: [],
    superCategoryName: [],
    project: [],
    groupName: [],
  },
  // 以下manualMergeだったもの
  pairsSearch: {
    modal: false,
    isFeatching: false,
    pairs: [],
    option: {
      ignoreTransactedAt: false,
    },
  },
  mergeSelecter: {
    modal: false,
  },
  // manualMerge終わり
};

function updateRotation(fileId, rotation, image) {
  if (image?.id !== fileId) {
    return image;
  }

  return { ...image, rotation };
}

function updateTransaction(transactions, suggestions, action) {
  return transactions.map((x) => {
    if (_isNil(x)) {
      return x;
    }

    if (x.id === action.id) {
      const { type, key } = action;
      switch (type) {
        case ActionTypes.SET_FORM_DATA: {
          return {
            ...x,
            [key]: action.value,
            editorSync: {
              ...x.editorSync,
              [key]: false,
            },
          };
        }
        case ActionTypes.ROTATE_RECEIPT_FILE: {
          const { fileId, rotation } = action.payload;
          const updateImage = updateRotation.bind(null, fileId, rotation);

          return {
            ...x,
            receiptImages: {
              foreside: (x.receiptImages.foreside || []).map(updateImage),
              backside: (x.receiptImages.backside || []).map(updateImage),
            },
          };
        }
        case ActionTypes.SET_SELECT_TEXT: {
          return {
            ...x,
            selectText: {
              ...x.selectText,
              [key]: action.value,
            },
            editorSync: {
              ...x.editorSync,
              [key]: false,
            },
          };
        }
        case ActionTypes.SET_READ_AS_MARK: {
          return {
            ...x,
            read: true,
          };
        }
        case ActionTypes.SET_UNREAD_AS_MARK: {
          return {
            ...x,
            read: false,
          };
        }
        case ActionTypes.INIT_CURRENT_SUGGESTIONS: {
          return {
            ...x,
            suggestions: {
              ...x.suggestions,
              [key]: [...suggestions[key]],
            },
          };
        }
        case ActionTypes.CLEAR_CURRENT_SUGGESTIONS: {
          return {
            ...x,
            suggestions: {
              ...x.suggestions,
              [key]: [],
            },
          };
        }
        case ActionTypes.UPDATE_CURRENT_SUGGESTIONS: {
          return {
            ...x,
            suggestions: {
              ...x.suggestions,
              [key]: [...action.data],
            },
          };
        }
        default:
          return x;
      }
    }
    return x;
  });
}

function mergeableTransactionsPairsSearch(
  state = initialState.pairsSearch,
  action,
) {
  switch (action.type) {
    case ManualMergeActionTypes.OPEN_MERGEABLE_TRANSACTIONS_PAIRS_SEARCH_MODAL:
      return {
        ...state,
        modal: true,
      };
    case ManualMergeActionTypes.CLOSE_MERGEABLE_TRANSACTIONS_PAIRS_SEARCH_MODAL:
      return {
        ...state,
        modal: false,
      };
    case ManualMergeActionTypes.SEARCH_MERGEABLE_TRANSACTIONS_PAIRS:
      return {
        ...state,
        isFeatching: action.state === "fetching",
        pairs: action.data,
      };
    case ManualMergeActionTypes.EDIT_OPTION:
      return {
        ...state,
        option: {
          ...state.option,
          [action.key]: action.value,
        },
      };
    case ManualMergeActionTypes.SELECT_MERGED_TRANSACTION: {
      const nextPairs = state.pairs;
      const nextState = !state.pairs[action.index].selected || false;
      nextPairs[action.index].selected = nextState;
      return {
        ...state,
        pairs: nextPairs,
      };
    }
    default:
      return state;
  }
}

function mergeSelecter(state = initialState.mergeSelecter, action) {
  switch (action.type) {
    case ManualMergeActionTypes.OPEN_MERGED_SELECTER:
      return {
        ...state,
        modal: true,
      };
    case ManualMergeActionTypes.CLOSE_MERGED_SELECTER:
      return {
        ...state,
        modal: false,
      };
    default:
      return state;
  }
}

const transactionTable = (subState, action) => {
  // 他のモジュールのreducerからも呼ばれるので、`undefined`を防ぐためにinitialStateを継承する.
  const state = {
    ...initialState,
    ...subState,
  };
  switch (action.type) {
    case ActionTypes.REQUEST_FETCH_TRANSACTIONS: {
      return { ...state, inProcess: true };
    }
    case ActionTypes.RECEIVE_FETCH_TRANSACTIONS: {
      return { ...state, inProcess: false };
    }
    case ActionTypes.SET_SEARCH_CONDITIONS: {
      return {
        ...state,
        searchConditions: {
          ...state.searchConditions,
          ...action.data,
        },
      };
    }
    case ActionTypes.RESET_SEARCH_CONDITIONS: {
      return {
        ...state,
        searchConditions: { ...searchParamsStorage.defaultData },
      };
    }
    case ActionTypes.SAVE_SEARCH_CONDITIONS: {
      searchParamsStorage.data = state.searchConditions;
      return state;
    }
    case ActionTypes.SET_SEARCHBOX_REF: {
      return {
        ...state,
        searchBoxRef: action.ref,
      };
    }
    case ActionTypes.SET_TRANSACTIONS: {
      return {
        ...state,
        transactions: action.data,
      };
    }
    case ActionTypes.SET_ENTRY_FORMS: {
      return {
        ...state,
        entryForms: action.data,
      };
    }
    case ActionTypes.INIT_TOTAL_SUGGESTIONS: {
      return {
        ...state,
        suggestions: {
          ...state.suggestions,
          [action.key]: [...action.data],
        },
      };
    }
    case ActionTypes.SET_FORM_DATA:
    case ActionTypes.SET_SELECT_TEXT:
    case ActionTypes.INIT_CURRENT_SUGGESTIONS:
    case ActionTypes.CLEAR_CURRENT_SUGGESTIONS:
    case ActionTypes.UPDATE_CURRENT_SUGGESTIONS: {
      return {
        ...state,
        transactions: {
          master: state.transactions.master,
          inEdit: updateTransaction(
            state.transactions.inEdit,
            state.suggestions,
            action,
          ),
        },
      };
    }
    case ActionTypes.SET_READ_AS_MARK:
    case ActionTypes.SET_UNREAD_AS_MARK: {
      return {
        ...state,
        transactions: {
          master: updateTransaction(state.transactions.master, null, action),
          inEdit: updateTransaction(state.transactions.inEdit, null, action),
        },
      };
    }
    case ActionTypes.ROTATE_RECEIPT_FILE: {
      return {
        ...state,
        transactions: {
          ...state.transactions,
          master: updateTransaction(state.transactions.master, null, action),
        },
      };
    }
    case ActionTypes.SET_MODAL_TRANSACTION: {
      const {
        payload: { expense, previousId, nextId },
      } = action;

      return {
        ...state,
        modal: {
          ...state.modal,
          transaction: { ...expense },
          // ATTENTION: 対象の経費は予め取得しておかないとIDが特定できない
          previousId,
          nextId,
        },
      };
    }
    case ActionTypes.SET_MERGABLE_AGGREGATION_RESULT: {
      return {
        ...state,
        modal: {
          ...state.modal,
          transaction: {
            ...state.modal.transaction,
            mergeableAggregation: action.data,
          },
        },
      };
    }
    case ActionTypes.SET_CURRENT_PAGE: {
      return {
        ...state,
        currentPage: action.value,
      };
    }
    case ActionTypes.SET_SIZE_PER_PAGE: {
      return {
        ...state,
        sizePerPage: action.value,
      };
    }
    case ActionTypes.TOGGLE_DELETE_MODAL: {
      return {
        ...state,
        modal: {
          ...state.modal,
          isDeleteModalOpen: action.show,
        },
      };
    }
    case ActionTypes.TOGGLE_TRANSACTION_MODAL: {
      return {
        ...state,
        modal: {
          ...state.modal,
          isTransactionModalOpen: action.show,
        },
      };
    }
    case ActionTypes.TOGGLE_COST_ALLOCATION_MODAL: {
      return {
        ...state,
        costAllocationModal: {
          ...state.costAllocationModal,
          show: action.show,
        },
      };
    }
    case ActionTypes.SET_COST_ALLOCATIONS: {
      return {
        ...state,
        costAllocationModal: {
          ...state.costAllocationModal,
          costAllocations: action.data,
        },
      };
    }
    case ActionTypes.RESET_COST_ALLOCATIONS: {
      return {
        ...state,
        costAllocationModal: {
          ...state.costAllocationModal,
          costAllocations: [],
        },
      };
    }
    case ActionTypes.SET_COST_ALLOCATION_HOVOR_ROW_IDX: {
      return {
        ...state,
        costAllocationModal: {
          ...state.costAllocationModal,
          hovor: action.rowIdx,
        },
      };
    }
    case ActionTypes.TOGGLE_EDIT_MODE: {
      return {
        ...state,
        isEditing: action.state,
      };
    }
    case ActionTypes.SET_SHOULD_SELECT_SELF_AS_COMPANION: {
      return {
        ...state,
        shouldSelectSelfAsCompanion: action.state,
      };
    }
    case ActionTypes.SET_COLUMN_VISIBILITIES: {
      columnParamsStorage.update(action.columnVisibilities);

      return {
        ...state,
        columnVisibilities: { ...columnParamsStorage.data },
      };
    }
    case ActionTypes.SET_REACT_TABLE_COLUMN_PARAMS: {
      reactTableColumnParamsStorage.update(action.reactTableColumnParams);

      return {
        ...state,
        reactTableColumnParams: { ...reactTableColumnParamsStorage.data },
      };
    }
    case ActionTypes.SET_REACT_TABLE_COLUMN_WIDTH: {
      reactTableColumnParamsStorage.updateWidth(action.payload);

      return {
        ...state,
        reactTableColumnParams: { ...reactTableColumnParamsStorage.data },
      };
    }
    case ActionTypes.RESET_COLUMN_VISIBILITIES: {
      columnParamsStorage.data = columnParamsStorage.defaultData;

      return {
        ...state,
        columnVisibilities: { ...columnParamsStorage.data },
      };
    }
    case ActionTypes.RESET_REACT_TABLE_COLUMN_PARAMS: {
      reactTableColumnParamsStorage.data =
        reactTableColumnParamsStorage.defaultData;

      return {
        ...state,
        reactTableColumnParams: { ...reactTableColumnParamsStorage.data },
      };
    }
    case ActionTypes.IS_SEARCH_WITH_ON_DELETED: {
      return {
        ...state,
        isSearchWithOnlyDeleted: action.isSearchWithOnlyDeleted,
      };
    }

    // 以下manualMergeに存在していたもの
    case ManualMergeActionTypes.OPEN_MERGEABLE_TRANSACTIONS_PAIRS_SEARCH_MODAL:
    case ManualMergeActionTypes.CLOSE_MERGEABLE_TRANSACTIONS_PAIRS_SEARCH_MODAL:
    case ManualMergeActionTypes.SEARCH_MERGEABLE_TRANSACTIONS_PAIRS:
    case ManualMergeActionTypes.EDIT_OPTION:
    case ManualMergeActionTypes.SELECT_MERGED_TRANSACTION: {
      return {
        ...state,
        pairsSearch: mergeableTransactionsPairsSearch(
          state.pairsSearch,
          action,
        ),
      };
    }
    case ManualMergeActionTypes.OPEN_MERGED_SELECTER:
    case ManualMergeActionTypes.CLOSE_MERGED_SELECTER: {
      return {
        ...state,
        mergeSelecter: mergeSelecter(state.mergeSelecter, action),
      };
    }
    // manualMerge終わり
    default:
      return state;
  }
};

export default transactionTable;
