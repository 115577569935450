import FieldInputLog from "components/histories/FieldInputLog";
import PropTypes from "prop-types";
import React from "react";
import ExpenseSummary from "./ExpenseSummary";

const buildProps = (changeLog) => {
  const {
    formField: { type, label },
    changes,
  } = changeLog;

  const props = {
    label,
    key: type,
    fieldType: type,
    changes,
  };

  return props;
};

const ExpenseLog = (props) => {
  if (props.type === "add" || props.type === "delete") {
    return <ExpenseSummary expense={props.expense} />;
  }

  const propsForChangeSummary = props.changes.map(buildProps);

  return (
    <div>
      {propsForChangeSummary.map((x) => (
        <FieldInputLog key={x.key} {...x} />
      ))}
    </div>
  );
};

ExpenseLog.defaultProps = {
  expense: {},
  changes: [],
};

ExpenseLog.propTypes = {
  type: PropTypes.oneOf(["add", "delete", "edit"]).isRequired,
  expense: PropTypes.object, // 追加・削除の履歴表示時には必須
  changes: PropTypes.array, // 編集の履歴表示時には必須
};

export default ExpenseLog;
