import DeleteConfirmationModal from "components/delete_confirmation_modal";
import EditableText from "components/editable_text";
import i18next from "i18n";
import _ from "lodash";
import PropTypes from "prop-types";
import React, { Component } from "react";
import Api from "utilities/api";
import flash from "utilities/flash";
import Formatter from "utilities/formatter";
import { isCorporatePlan } from "utilities/Utils";

export default class ReportDescriptionCard extends Component {
  constructor(props) {
    super(props);
    this.state = { modal: { show: false } };
  }

  destroy() {
    Api.expenses.reports.destroy({ id: this.props.report.id }).done((data) => {
      window.location.href = "/requests/";
    });
  }

  onChangeTitle(id, title) {
    return Api.expenses.reports
      .title({
        title,
        report_id: id, // eslint-disable-line camelcase
      })
      .fail((xhr, message) => {
        flash.error(message || i18next.t("commons.errors.communicationError"));
      })
      .done((data) => {
        this.props.onChangeTitle(title);
        flash.success(i18next.t("reports.messages.changeReportTitleSuccess"));
      });
  }

  isDeletable() {
    return this.props.report.deletable;
  }

  render() {
    const { report, transactions, reportRequiresWithholding } = this.props;
    return (
      <div className="card report-description-card">
        <div className="card-header">
          <EditableText
            text={report.title}
            className="card-title"
            onEdit={this.onChangeTitle.bind(this, report.id)}
            validate={["required"]}
          />
          {this.renderDeleteButton()}
        </div>
        <div className="card-content">
          <table className="table table-bordered">
            <tbody>
              {this.renderItem(
                i18next.t("reports.properties.sequence"),
                Formatter.text(report.sequence, ""),
              )}
              {this.renderItem(
                i18next.t("reports.properties.amount"),
                Formatter.amount(report.amount, ""),
              )}
              {this.renderWithholding(
                report.withholding,
                reportRequiresWithholding,
              )}
              {this.renderReimbursableAmount(transactions)}
              {this.renderItem(
                i18next.t("reports.properties.applicantName"),
                (this.props.user || {}).name,
              )}
              {this.renderItem(
                i18next.t("reports.properties.status"),
                this.renderStatus(),
              )}
            </tbody>
          </table>
        </div>
        {this.renderFooter()}
        <DeleteConfirmationModal
          show={this.state.modal.show}
          onDelete={this.destroy.bind(this)}
          onCancel={() => {
            this.setState({ modal: { show: false } });
          }}
        />
      </div>
    );
  }

  renderWithholding(withholding, reportRequiresWithholding) {
    if (reportRequiresWithholding) {
      return this.renderItem(
        i18next.t("reports.properties.withholding"),
        Formatter.amount(withholding, ""),
      );
    }

    return null;
  }

  renderReimbursableAmount(transactions) {
    if (transactions.some((x) => x.isCorporate)) {
      const total = transactions
        .filter((x) => x && !x.isCorporate)
        .reduce((acc, x) => acc + x.amount, 0);
      return this.renderItem(
        i18next.t("reports.properties.reimbursableExpense"),
        Formatter.amount(total, ""),
      );
    }

    return null;
  }

  renderFooter() {
    if (!this.props.children) {
      return null;
    }

    return <div className="card-footer inverse">{this.props.children}</div>;
  }

  renderDeleteButton() {
    if (!this.isDeletable()) {
      return null;
    }

    return (
      <div className="card-right">
        <button
          className="btn btn-sm btn-outline btn-danger"
          onClick={() => {
            this.setState({ modal: { show: true } });
          }}
        >
          {i18next.t("commons.actions.delete")}
        </button>
      </div>
    );
  }

  renderItem(label, value) {
    return (
      <tr>
        <td>{label}</td>
        <td>{value}</td>
      </tr>
    );
  }

  renderStatus() {
    const report = this.props.report;
    const requestEvents = this.props.requestEvents;
    let title = "";
    if (!!requestEvents && requestEvents.length > 0) {
      const event = _([].concat(requestEvents))
        .reverse()
        .find((e) =>
          _.includes(
            [
              "reapplying",
              "applying",
              "rejected",
              "approved",
              "recalled",
              "canceled",
            ],
            e.type,
          ),
        );
      title = (event || {}).title;
    }

    let requestedAt = "";
    if (this.props.report.createdAt) {
      requestedAt = i18next.t(
        isCorporatePlan()
          ? "reports.messages.statusFormat"
          : "reports.messages.statusFormatPersonalPlan",
        {
          datetime: Formatter.datetime(this.props.report.createdAt, ""),
          interpolation: { escapeValue: false },
        },
      );
    }

    /** 未申請 */
    if (
      isCorporatePlan() &&
      this.props.report.status === i18next.t("commons.status.created")
    ) {
      requestedAt = i18next.t("commons.status.created");
    }

    /** パーソナルプラン用の未申請表示（status-bar無し） */
    if (!title && !requestedAt) {
      return i18next.t("commons.status.unsubmitted");
    }

    return (
      <div>
        <div className={`status-bar-${this.props.report.progress}`}></div>
        <div>{title}</div>
        <div className="txt txt-sm">{requestedAt}</div>
      </div>
    );
  }
}

ReportDescriptionCard.defaultProps = {
  deletable: false,
  onChangeTitle: () => null,
};

ReportDescriptionCard.propTypes = {
  user: PropTypes.object,
  report: PropTypes.object.isRequired,
  requestEvents: PropTypes.array.isRequired,
  deletable: PropTypes.bool,
  onChangeTitle: PropTypes.func,
  remainingApprovals: PropTypes.array,
  reapplyRequest: PropTypes.func,
  children: PropTypes.node,
};
