import PropTypes from 'prop-types';
import React from 'react';
import { ControlLabel } from 'components/ControlLabel';

const LabeledComponent = (props) => {
  return (
    <div className={ props.className } style={ props.style }>
      <ControlLabel
        className={ props.labelClass }
        description={ props.description }
        popoverTitle={ props.popoverTitle }
        placement={ props.placement }
        trigger={ props.trigger }
        rootClose={ props.rootClose }
        style={ props.labelStyle }
      >
        { props.label }
      </ControlLabel>

      { props.children }
    </div>
  );
};

LabeledComponent.propTypes = {
  className: PropTypes.string,
  style: PropTypes.object,
  labelClass: PropTypes.string,
  labelStyle: PropTypes.object,
  label: PropTypes.string.isRequired,
  description: PropTypes.string,
  popoverTitle: PropTypes.string,
  placement: PropTypes.string,
  trigger: PropTypes.array,
  rootClose: PropTypes.bool,
};

export default LabeledComponent;
