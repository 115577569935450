import React, { useEffect, useRef } from 'react';

interface UseReturn {
  readonly resolvedRef:  React.MutableRefObject<HTMLInputElement | null> | ((instance: HTMLInputElement | null) => void) | React.MutableRefObject<undefined>;
}

type Ref = ((instance: HTMLInputElement | null) => void) | React.MutableRefObject<HTMLInputElement | null> | null;

/**
 * ロジック: IndeterminateCheckbox
 */
export const useHooks = (ref: Ref, indeterminate?: boolean): UseReturn => {
  const defaultRef = useRef();
  const resolvedRef = ref || defaultRef;

  useEffect(() => {
    const inputElement = (resolvedRef as React.MutableRefObject<HTMLInputElement>).current;
    if (!resolvedRef || !inputElement) return;

    inputElement.indeterminate = indeterminate || false;
  }, [resolvedRef, indeterminate]);

  return {
    resolvedRef,
  };
};
