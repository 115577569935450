import React from 'react';
import colors from 'values/colors';
import fonts from 'values/fonts';
import styled, { css } from 'styled-components';

/**
 *  primary:        システムにポジティブな影響を及ぼす箇所に使用する。(例) 保存ボタン
 *  secondary:      データの操作、画面の操作に影響する箇所に使用する。(例) 新規作成ボタン
 *  danger:         とても重要な情報を削除、変更する場合に使用する。（経費の削除、には使用しない。事業所全体の運用が止まるようなもの）
 *  link-primary:   ページ移動を伴う箇所に使用する。
 *  link-secondary: 動作の中断を行う箇所、前の画面に遷移する箇所に使用する。(例) モーダル内のキャンセルボタン、戻るボタン
 *  link-danger:    データの削除を実行する箇所に使用する。（例）経費を複数選択して削除する際の確認モーダル内の削除ボタン
 */
export type StyleType = 'primary' |
                        'secondary' |
                        'danger' |
                        'link-primary' |
                        'link-secondary' |
                        'link-danger' |
                        'success';

export interface Props {
  /** タイプ */
  type?: 'submit' | 'button';
  /** ボタンスタイル 利用シーンに合わせて選択 */
  styleType?: StyleType;
  /** disabledか */
  disabled?: boolean;
  /** HTMLclass名にdisabledを含まずに実質disabledにするか */
  alternativeDisabled?: boolean;
  /** ロード中か */
  loading?: boolean;
  /** クリック時のハンドラ */
  onClick?: React.MouseEventHandler<HTMLButtonElement>;
  /** 遷移先(指定した場合アンカーリンクになる) */
  href?: string;
  /** 表示するか */
  show?: boolean;
  /** bootstrapのDropdownのトグルボタンとして使用するときに渡す */
  bsRole?: 'toggle';
  /** ボタン小 */
  small?: boolean;
  /** 最小幅 */
  minWidth?: number;
  /** リンク表示(padding無し) */
  linkText?: boolean;
  /** 追加クラス名 */
  className?: string;
  /** 子要素 */
  children: React.ReactNode;
}

const mixinPaddingForLinkButton = css`
  padding: 6px 12px 4px; // link-系は横幅小さめに。
`;

const ButtonView = styled.button<{ minWidth: number }>`
  min-width: ${(props): number => props.minWidth}px;
  border: none;
  outline: none;
  border-radius: 8px;
  padding: 6px 16px 4px; // 文字が上下中心になる設定
  transition: 150ms;
  background: none;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  user-select: none;
  font-weight: ${fonts.weight.bold};
  &.small {
    font-size: 12px;
    height: 24px;
    border-radius: 4px;
    line-height: 24px;
    padding: 0 8px;
    &.link-primary {
      padding: 0 8px;
    }
  }
  &.primary {
    color: #fff;
    background: linear-gradient(180deg, #336bcc 0%, #004acc 100%);
    box-shadow: 0px 0.5px 1.5px rgba(56, 127, 255, 0.25);
    border: 1px solid transparent;
    &:hover, &:focus {
      box-shadow: none;
      filter: brightness(95%);
    }
  }
  &.secondary {
    color: ${colors.renewaled.textBlack};
    background: linear-gradient(180deg, #FAFAFA 0%, #F2F2F2 100%);
    box-shadow: 0px 0.5px 1.5px rgba(56, 127, 255, 0.25);
    border: 1px solid #bbbbbb;
    &:hover, &:focus {
      box-shadow: none;
      filter: brightness(98%);
    }
  }
  &.danger {
    color: #fff;
    background: linear-gradient(180deg, #FF0000 0%, #D80000 100%);
    box-shadow: 0px 0.5px 1.5px rgba(56, 127, 255, 0.25);
    &:hover, &:focus {
      box-shadow: none;
      filter: brightness(95%);
    }
  }
  &.success {
    color: #fff;
    background: #00bfa5;
    border: 1px solid transparent;
    &:hover, &:focus {
      background: #00a68f;
      box-shadow: none;
    }
  }
  &.link-primary {
    color: #004ACC;
    ${mixinPaddingForLinkButton}
    &:hover, &:focus {
      background: #EDF4FF;
    }
  }
  &.link-secondary {
    color: ${colors.renewaled.textBlack};
    ${mixinPaddingForLinkButton}
    &:hover, &:focus {
      background: #EFEFEF;
    }
  }
  &.link-danger {
    color: #FF0000;
    ${mixinPaddingForLinkButton}
    &:hover, &:focus {
      background: #FFF2F2;
    }
  }
  &.primary.disabled:not(.loading),
  &.secondary.disabled:not(.loading),
  &.danger.disabled:not(.loading),
  &.success.disabled:not(.loading),
  &.primary.alternative-disabled:not(.loading),
  &.secondary.alternative-disabled:not(.loading),
  &.danger.alternative-disabled:not(.loading) {
    color: white;
    background: #888888;
    border: 1px solid #888888; // 通常時とボタン高さ変えないために必要
    box-shadow: none;
    cursor: not-allowed;
    &:hover {
      background: #888888;
      filter: none;
    }
  }
  &.success.alternative-disabled:not(.loading) {
    color: white;
    background: #888888;
    border: 1px solid #888888; // 通常時とボタン高さ変えないために必要
    box-shadow: none;
    cursor: not-allowed;
    &:hover {
      background: #888888;
      filter: none;
    }
  }
  &.link-primary.disabled:not(.loading),
  &.link-secondary.disabled:not(.loading),
  &.link-danger.disabled:not(.loading),
  &.link-primary.alternative-disabled:not(.loading),
  &.link-secondary.alternative-disabled:not(.loading),
  &.link-danger.alternative-disabled:not(.loading) {
    color: #888888;
    cursor: not-allowed;
    &:hover {
      background: none;
    }
  }
  &.link-text {
    padding: 0;
    transition: 150ms;
    &:hover, &:focus {
      background: inherit;
      text-decoration: underline;
    }
  }
`;

/** ボタン */
export const Button = React.forwardRef<HTMLButtonElement, Props>(({
  type = 'button',
  styleType = 'primary',
  disabled = false,
  alternativeDisabled = false,
  loading = false,
  onClick,
  href = '',
  show = true,
  small = false,
  minWidth = 120,
  linkText,
  children,
  className,
}, ref) => {
  const renderButton = (): JSX.Element => {
    return (
      <ButtonView
        data-testid="button"
        type={ type }
        disabled={ disabled || loading || false }
        style={ { display: show ? 'inline-block' : 'none' } }
        className={ `${styleType} ${disabled && 'disabled'} ${alternativeDisabled && 'alternative-disabled'} ${loading && 'loading'} ${linkText && 'link-text'} ${small && 'small'} ${className} renewaled-button` }
        onClick={ alternativeDisabled ? undefined : onClick }
        ref={ ref }
        minWidth={ minWidth }
      >
        {loading ? <i className="fas fa-lg fa-spin fa-spinner loading-icon" style={ { margin: '3px 0' } } /> : children}
      </ButtonView>
    );
  };

  // リンクの場合はaタグで囲って返す
  if (href && !disabled && !alternativeDisabled && !loading) return <a href={ href }>{renderButton()}</a>;

  return renderButton();
});
