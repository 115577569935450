import React from "react";
import { connect } from "react-redux";
import * as actions from "../actions/expenseSelector";
import PresentationalComponent from "../components/ExpenseSelector";

/**
 * usage:
 * - reducer
 *   import expenseMatch from 'applications/transactions/reducers/expenseMatch';
 *   export default combineReducers({
 *     expenseMatch,
 *     // ...,
 *   });
 */
const ExpenseSelector = (props) => {
  return (
    <PresentationalComponent
      isSearching={props.isSearching}
      transactedAtFrom={props.transactedAtFrom}
      transactedAtTo={props.transactedAtTo}
      amountFrom={props.amountFrom}
      amountTo={props.amountTo}
      shopName={props.shopName}
      ownerName={props.ownerName}
      department={props.department}
      includeChildDepartment={props.includeChildDepartment}
      expenses={props.suggestedExpenses}
      selectedExpense={props.selectedExpense}
      currentPage={props.currentPage}
      sizePerPage={props.sizePerPage}
      setSearchConditions={props.setSearchCondition}
      fetchExpenses={props.fetchExpenses.bind(null, props.forAdmin)}
      onPageChange={props.changeCurrentPage}
      onSelectRow={props.setMatchedExpense}
      setS3ImageUrl={props.setS3ImageUrl}
      forAdmin={props.forAdmin}
      sort={props.sort}
      onSortChange={props.onSortChange}
      clearExpenses={props.clearExpenses}
    />
  );
};

// TODO: selector を使うように
function mapStateToProps(state, ownProps) {
  const expenseMatch = state.expenseMatch;

  return {
    ...expenseMatch.search.conditions,
    ...expenseMatch.expenseTable,
    isSearching: expenseMatch.search.status === "fetching",
    suggestedExpenses: expenseMatch.search.results,
    selectedExpense: expenseMatch.matchedExpense,
  };
}

function mapDispatchToProps(dispatch, ownProps) {
  return {
    // 検索結果が変わることがあるので, 突合候補は一度空にする
    fetchExpenses(forAdmin, conditions) {
      dispatch(actions.setMatchedExpense({}));
      return dispatch(actions.fetchExpenses(conditions, forAdmin));
    },
    setS3ImageUrl(expenseId, s3ImageUrl) {
      dispatch(actions.setExpenseImageUrl(expenseId, s3ImageUrl));
    },
    setMatchedExpense(expense) {
      dispatch(actions.setMatchedExpense(expense));
    },
    setSearchCondition(key, data) {
      dispatch(actions.setSearchCondition(key, data));
    },
    // 表示ページ変更に伴って, 突合候補は一度空にする
    changeCurrentPage(page) {
      dispatch(actions.setMatchedExpense({}));
      dispatch(actions.changeCurrentPage(page));
    },
    onSortChange(sortName, sortOrder) {
      dispatch(actions.onSortChange(sortName, sortOrder));
    },
    clearExpenses() {
      dispatch(actions.clearExpenses());
    },
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(ExpenseSelector);
