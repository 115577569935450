/**
 * 経費詳細画面上から自動入力修正依頼のGoogleフォームを開くための関数
 *
 * フォームのURLなどの情報をフロントエンドに直書きすることは、以下の理由で問題ないと判断している。
 *   1. 本番環境・ステージング環境・開発環境で同一のGoogleフォームを使うため、URLやエントリ(それぞれの入力場所)のIDの切り替えが不要
 *   2. フォームのURLが今後変更される可能性が低いため、柔軟に切り替え可能な設計にするコストが高い
 *
 * 状況が変わり、フォームのURLを柔軟に切り替える必要性が生じるなどした場合は、AWS SSMなどを利用して
 * フォームのURLを外部から取得するように変更することも考える。
 */

export function openWorkerInputCorrectionRequestForm(expenseId: string): void {
  /** 経費の自動入力修正依頼フォームの通常のURL */
  const baseFormURL =
    "https://docs.google.com/forms/d/e/1FAIpQLSflNu-Q624snqREa9rlv_rmb5CUV-73P596jN8ms1ipT9CDIg/viewform?usp=pp_url";
  /**
   * 事前入力されたGoogleFormのURLを生成するためのパラメーター
   * 自動の事前入力が必要な入力フォームに、値を対応させておく
   */
  const urlParams = new URLSearchParams({
    "entry.244103454": userPreferences.rootGroup.name, // テナント名
    "entry.1070557395": userPreferences.name, // 氏名
    "entry.1088978997": userPreferences.email, // メールアドレス
    "entry.1387635117": expenseId, // UUID
  });

  const prefilledFormURL = `${baseFormURL}&${urlParams.toString()}`;

  window.open(prefilledFormURL, "_blank", "noreferer");
}
