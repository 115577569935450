import React, {
  FC, FormEvent,
} from "react";
import i18next from "i18n";
import styled from 'styled-components';
import { BootstrapTable, TableHeaderColumn } from 'react-bootstrap-table';
import { FormControl } from 'react-bootstrap';

interface TransferFee {
  readonly index: number;
  readonly id: string;
  readonly type: string;
  feeThreshold: number;
  eqOrHeigherFee: number;
  lowerFee: number;
}

interface Props {
  readonly transferFees: Array<TransferFee>;
  readonly setTransferFees: (transferFees: Array<TransferFee>) => void;
}

const Wrapper = styled.div`
tbody > tr:hover {
  background: #fff;
}
`;

/**
 * 振込先欄
 */
const formatBankType = (v: unknown, row: TransferFee): string => {
  switch (row.type) {
    case 'TransferFees::OtherBank':
      return i18next.t('preferences.expenseAccount.otherBank');
    case 'TransferFees::SameBank':
      return i18next.t('preferences.expenseAccount.sameBank');
    case 'TransferFees::SameBankBranch':
      return i18next.t('preferences.expenseAccount.sameBankBranch');
    default:
      return '';
  }
};

/**
 * 順番を返す
 */
const typeToSortKey = (v: unknown, row: TransferFee): number => {
  switch (row.type) {
    case 'TransferFees::OtherBank':
      return 0;
    case 'TransferFees::SameBank':
      return 1;
    case 'TransferFees::SameBankBranch':
      return 2;
    default:
      return 3;
  }
};

/**
 * 支払口座モーダルの中身
 */
const TransferFeeForm: FC<Props> = ({
  transferFees,
  setTransferFees,
}) => {
  /**
   * 振込金額欄の入力フォーム
   */
  const feeThresholdForm = (row: TransferFee): JSX.Element => {
    return (
      <FormControl
        style={ { margin: '10px', width: '50%', display: 'inline-block' } }
        type={ 'number' }
        value={ row.feeThreshold.toString() }
        min={ 0 }
        onChange={ (e: FormEvent<FormControl & HTMLInputElement>): void => {
          const newTransferFees = [...transferFees];
          newTransferFees[row.index].feeThreshold = e.currentTarget.value === '' ? 0 : Number(e.currentTarget.value);
          setTransferFees(newTransferFees);
        } }
      />
    );
  };

  /**
   * 振込金額欄
   */
  const formatFeeThreshold = (v: unknown, row: TransferFee): JSX.Element => {
    return (
      <>
        <div>
          { feeThresholdForm(row) }
          <span style={ { display: 'inline-block' } }>
            { i18next.t('preferences.expenseAccount.lower') }
          </span>
        </div>
        <hr style={ { margin: '0px', width: '100%' } }/>
        <div>
          { feeThresholdForm(row) }
          <span style={ { display: 'inline-block' } }>
            { i18next.t('preferences.expenseAccount.eqOrHeigher') }
          </span>
        </div>
      </>
    );
  };

  /**
   * 振込手数料欄
   */
  const formatFee = (v: unknown, row: TransferFee): JSX.Element => {
    return (
      <>
        <div>
          <FormControl
            style={ { margin: '10px', width: '50%', display: 'inline-block' } }
            type={ 'number' }
            value={ row.lowerFee.toString() }
            min={ 0 }
            onChange={ (e: FormEvent<FormControl & HTMLInputElement>): void => {
              const newTransferFees = [...transferFees];
              newTransferFees[row.index].lowerFee = e.currentTarget.value === '' ? 0 : Number(e.currentTarget.value);
              setTransferFees(newTransferFees);
            } }
          />
          <span style={ { display: 'inline-block' } }>{ i18next.t('preferences.expenseAccount.yen') }</span>
        </div>
        <hr style={ { margin: '0px', width: '100%' } }/>
        <div>
          <FormControl
            style={ { margin: '10px', width: '50%', display: 'inline-block' } }
            type={ 'number' }
            value={ row.eqOrHeigherFee.toString() }
            min={ 0 }
            onChange={ (e: FormEvent<FormControl & HTMLInputElement>): void => {
              const newTransferFees = [...transferFees];
              newTransferFees[row.index].eqOrHeigherFee = e.currentTarget.value === '' ? 0 : Number(e.currentTarget.value);
              setTransferFees(newTransferFees);
            } }
          />
          <span style={ { display: 'inline-block' } }>{ i18next.t('preferences.expenseAccount.yen') }</span>
        </div>
      </>
    );
  };

  return (
    <Wrapper>
      <BootstrapTable
        bordered={ false }
        hover={ false }
        data={ transferFees }
        options={ { defaultSortName: 'type', defaultSortOrder: 'desc' } }
      >
        <TableHeaderColumn dataField='index' isKey hidden></TableHeaderColumn>
        <TableHeaderColumn
          dataField='type'
          dataFormat={ typeToSortKey }
          dataSort
          hidden
        ></TableHeaderColumn>
        <TableHeaderColumn
          className='ws-normal'
          width='20%'
          tdStyle={ { verticalAlign: 'middle', fontWeight: 'bold' } }
          dataFormat={ formatBankType }
        >
          { i18next.t('preferences.expenseAccount.to') }
        </TableHeaderColumn>
        <TableHeaderColumn
          className='ws-normal'
          width='40%'
          tdStyle={ { padding: '0px' } }
          dataFormat={ formatFeeThreshold }
        >
          {i18next.t('preferences.expenseAccount.amount') }
        </TableHeaderColumn>
        <TableHeaderColumn
          className='ws-normal'
          width='40%'
          tdStyle={ { padding: '0px' } }
          dataFormat={ formatFee }
        >
          { i18next.t('preferences.expenseAccount.transferFee') }
        </TableHeaderColumn>
      </BootstrapTable>
    </Wrapper>
  );
};

export default TransferFeeForm;
