import React, { useCallback, useMemo, useState } from 'react';

import PersonalCategorySuggestField from './PersonalCategorySuggestField';
import i18next from 'i18n';
import styled from 'styled-components';
import { PersonalCategory } from 'utilities/api_v3/models/PersonalCategory';

export interface Category extends PersonalCategory {
  parent?: Category;
}

interface FetchParams {
  disable?: boolean;
  formId?: string;
  includeDisabled?: boolean;
  includeTaxCategory?: boolean;
  ownerId?: string;
  requestType?: string;
}

interface NodeSuggestFieldProps extends FetchParams {
  disable?: boolean;
  onlyUsePreReportItem?: boolean;
  value: Category | null;
  parent: Category | null;
  onSelect: (value: Category | null) => void;
}

interface RecursiveSuggestFieldProps extends FetchParams {
  onlyUsePreReportItem?: boolean;
  value: Category | null;
  onSelect: (value: Category | null) => void;
}

export function flattenCategories(category: Category | null): Array<Category> {
  if (!category) {
    return [];
  }

  return flattenCategories(category.parent || null).concat(category);
}

// TODO: UIを変更する
const CategoryNodeSuggestField: React.FunctionComponent<NodeSuggestFieldProps> = (props) => {
  const {
    disable, onlyUsePreReportItem, value, parent, onSelect, ...requestParams
  } = props;

  const [text, setText] = useState<string>(value?.name || '');
  const handleSelect = useCallback((category) => {
    if (!category) {
      // クリアする場合
      // 親科目までの選択状態は維持して良いので、親科目が選択されたものとみなす
      onSelect(parent || null);
      return;
    }

    if (category.id === value?.id) {
      // 現在選択されている科目と同じ科目が選択された場合、何もしなくて良い
      return;
    }

    onSelect({ ...category, parent });
  }, [parent, value, onSelect]);

  return (
    <PersonalCategorySuggestField { ...requestParams }
      disable={ disable }
      onlyUsePreReportItem={ onlyUsePreReportItem }
      parentId={ parent?.id || null }
      text={ text }
      value={ value }
      onSelect={ handleSelect }
      onTextChange={ setText }
      placeholder={ i18next.t('commons.status.notSelected') }
    />
  );
};

const ContainerView = styled.div`
  > div:not(:last-child) {
    margin-bottom: 15px; // form-groupのmargin-bottomと合わせている
  }
`;

// TODO: 入力バリデーションをつける
const CategoryRecursiveSuggestField: React.FunctionComponent<RecursiveSuggestFieldProps> = (props)  => {
  const {
    disable, onlyUsePreReportItem, value, onSelect, ...fetchParams
  } = props;

  // 途中の入力状態を正常に表示するため、子科目が選択されているかどうかによらず、Componentのツリー構造が変わらないようにしておく
  const categoryValueAndParents  = useMemo(() => {
    const categoryNodes = flattenCategories(value);
    const categoryPairs: { value: Category | null; parent: Category | null  }[] = categoryNodes.map((x) => {
      return { value: x, parent: x.parent || null };
    });

    const isLeaf = value?.selectable || false;

    if (!isLeaf) {
      // categoryNodesの最後の要素が親科目（未選択状態なら空配列となりnull）
      categoryPairs.push({ value: null, parent: categoryNodes[categoryNodes.length - 1] || null });
    }

    return categoryPairs;
  }, [value]);

  return (
    <ContainerView>
      {
        categoryValueAndParents.map((x, idx) => {
          // NOTE: idxをkeyにするのは意図的
          //       入力フィールド内の文字列を書き換えようとした時に、経費科目の選択解除 -> Componentがremountされて文字列がクリアされるのを防ぐ
          return (
            <CategoryNodeSuggestField key={ idx }
              { ...fetchParams }
              disable={ disable }
              onlyUsePreReportItem={ onlyUsePreReportItem }
              value={ x.value }
              parent={ x.parent }
              onSelect={ onSelect }
            />
          );
        })
      }
    </ContainerView>
  );
};

export default CategoryRecursiveSuggestField;
