import PropTypes from 'prop-types';
import React, { Component } from 'react';
import ToggleButton from 'components/toggle_button';
import { Modal } from 'react-bootstrap';

export default class SimpleModal extends Component {
  constructor(props) {
    super(props);
    this.state = { show: false };
  }

  onClick() {
    if (this.props.validate()) {
      this.props.button.onClick();
      this.close();
    } else {
      this.refs.button.activate();
    }
  }

  render() {
    // ReactBootstrap.ModalにbsSize='md'がない。
    // mdにするには「指定しない」しかない。(空文字もダメ)
    if (this.props.size !== 'md') {
      return (
        <Modal
          show={ this.state.show }
          onHide={ this.close.bind(this) }
          onEntered={ this.props.onEntered.bind(this) }
          bsSize={ this.props.size } >
          { this.renderHeader() }
          { this.renderBody() }
          { this.renderFooter() }
        </Modal>
      );
    }
    return (
      <Modal
        dialogClassName={ this.props.dialogClassName }
        show={ this.state.show }
        onHide={ this.close.bind(this) }>
        { this.renderHeader() }
        { this.renderBody() }
        { this.renderFooter() }
      </Modal>
    );
  }


  // overridable
  renderHeader() {
    return (
      <Modal.Header closeButton>
        <Modal.Title>{ this.props.title }</Modal.Title>
      </Modal.Header>
    );
  }

  // overridable
  renderBody() {
    if (!this.props.message && !this.props.children) {
      return null;
    }
    return (
      <Modal.Body className={ this.props.bodyClassName }>
        { this.props.message }
        { this.props.children }
      </Modal.Body>
    );
  }

  // overridable
  renderFooter() {
    if (this.props.button.none) {
      return null;
    }

    const className = !this.props.button.className
      ? `col-sm-6 col-sm-offset-3 btn btn-${this.props.button.color}`
      : this.props.button.className;

    return (
      <Modal.Footer>
        <div className='text-left'>
          <ToggleButton
            ref='button'
            className={ className }
            onClick={ this.onClick.bind(this) }>
            { this.props.button.text }
          </ToggleButton>
        </div>
      </Modal.Footer>
    );
  }

  open() {
    this.setState({ show: true });
  }

  close() {
    this.setState({ show: false });
  }
}

SimpleModal.defaultProps = {

  title:   '',
  message: '',
  size:    'md',
  onEntered: () => null,
  validate: () => true,
  bodyClassName: null,

  button: {
    text:      'OK',
    color:     'success',
    className: '',
    onClick:   () => null,
  },

};

SimpleModal.propTypes = {

  title:     PropTypes.string,
  message:   PropTypes.string,
  size:      PropTypes.oneOf(['xs', 'sm', 'md', 'lg']),
  onEntered: PropTypes.func,
  validate:  PropTypes.func,
  dialogClassName: PropTypes.string,
  bodyClassName: PropTypes.string,

  // ボタンの設定
  button: PropTypes.shape({
    none:      PropTypes.bool,
    text:      PropTypes.string,
    color:     PropTypes.oneOf(['primary', 'success', 'info', 'warning', 'caution', 'danger']),
    className: PropTypes.string,
    onClick:   PropTypes.func,
  }),
};
