import React from 'react';
import { IndeterminateCheckbox } from 'components/renewaled_ui/Table/atoms/IndeterminateCheckbox';
import { RequiredTableDataProps } from 'components/renewaled_ui/Table/interface/table';
import { TableToggleAllRowsSelectedProps } from 'react-table';
import { useHooks } from './hooks';

interface Props<Data> {
  /** checkboxのための重複しないID */
  readonly id: string;
  /** テーブルデータ */
  readonly data: Data[];
  /** 選択しているprops */
  readonly toggleAllPageRowsSelectedProps: TableToggleAllRowsSelectedProps;
  /** 全選択を押した */
  readonly onSelectAll: (isSelected: boolean) => void;
}

/**
 * テーブルの先端のヘッダー部分
 */
export const TableFirstHeaderCell = <Data extends RequiredTableDataProps, >(props: Props<Data>): JSX.Element => {
  const {
    isChecked,
    onChange,
    isShowIndeterminate,
  } = useHooks(props.data, props.onSelectAll);

  return (
    <div
      style={ {
        display: 'flex',
        justifyContent: 'center',
        height: 24,
        marginLeft: '-3px',
      } }
    >
      <IndeterminateCheckbox
        { ...props.toggleAllPageRowsSelectedProps }
        checked={ isChecked() }
        onChange={ (): void => onChange() }
        indeterminate={ isShowIndeterminate() }
        // チェックボックスのIDは全体でユニークである必要があるため、Table毎にIDを分ける。
        id={ `${props.id}-tbody-selecet-all-rows` }
      />
    </div>
  );
};
