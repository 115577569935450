import React from 'react';
import styled from 'styled-components';
import { ControlLabel } from 'components/ControlLabel';
import { Dropdown, MenuItem } from 'components/renewaled_ui/Dropdown';

/**
 * @deprecated components/FormElementを使ってください。
 * @param {string} label,
 * @param {object} component ReactElement (JSX)
 * @param {object} [labelProps={ className = 'col-sm-3' }] label's property
 * @param {object} [contentProps={ className = 'col-sm-6' }] contents div's property
 */
export function renderFormElement(label, component,
  labelProps = { className: 'col-sm-3' }, contentProps = { className: 'col-sm-6 col-md-6' }) {
  return (
    <div className='form-group'>
      <ControlLabel { ...labelProps }>{ label.split('\n').map((l, i) => <div key={ i }>{ l }</div>) }</ControlLabel>
      <div { ...contentProps }>{ component }</div>
    </div>
  );
}

export function renderFormElementWithIcon(label, component, iconProps,
  labelProps = { className: 'col-sm-3 control-label' }, contentProps = { className: 'col-sm-6 col-md-6' }) {
  const componentWithIcon = renderInputWithIcon(component, (<i { ...iconProps }></i>));

  return renderFormElement(label, componentWithIcon, labelProps, contentProps);
}

export function renderInputWithIcon(component, iconComponent) {
  return (
    <div className='input-group' style={ { width: '100%' } }>
      <span className='input-group-addon'>
        { iconComponent }
      </span>
      { component }
    </div>
  );
}

const DropdownButtonView = styled.button`
  width: 67px;
  height: 100%;
  border: 1px solid #ccc;
  border-right: none;
  border-radius: 4px 0 0 4px;
  span {
    margin-left: 8px;
  }
`;

export function renderInputWithDropdown(defaultValue, options, onClick, component, renderOption = (x) => (x)) {
  return (
    <div className='input-group' style={ { display: 'flex' } }>
      <Dropdown
        id='add-drop-down'
        direction='down'
        align='left'
        menuMaxHeight='320px'
        toggleButton={ (
          <DropdownButtonView
            className='input-with-dropdown-button'
            bsRole='toggle'
            type='button'
          >
            { defaultValue }
            <span className='caret' />
          </DropdownButtonView>
        ) }
      >
        {
          options.map((option, idx) => (
            <MenuItem key={ `option-${idx}` } onClick={ () => { onClick(option); } }>
              { renderOption(option) }
            </MenuItem>
          ))
        }
      </Dropdown>
      { component }
    </div>
  );
}

/* renderFormElementの代わりに作る
 *
 * @param {string} label,
 * @param {object} component ReactElement (JSX)
 * @param {object} [labelProps={ className = 'col-sm-3' }] label's property
 * @param {object} [contentProps={ className = 'col-sm-6' }] contents div's property
 */
export function renderInputWithLabel(type, label,
  {
    labelClass = 'control-label col-sm-4 col-md-3',
    containerClass = 'col-sm-8 col-md-9',
    inputClass = 'form-control',
    value = null,
    onChange = () => null,
    inputProps = {},
    component = null,
  }) {
  const props = { ...inputProps, className: inputClass };
  const getOptions = (inputType) => {
    switch (inputType) {
      case 'text':
        return {
          value,
          onChange,
        };
      case 'checkbox':
        return {
          value,
          label,
          onChange,
        };
      case 'component':
        return { component };
      default:
        return null;
    }
  };
  const options = getOptions(type);

  return (
    <div className='form-group'>
      {
        type !== 'checkbox'
          ? <label className={ labelClass }>{ label }</label>
          : null
      }
      <div className={ containerClass }>
        { renderInputHelper(type, props, options) }
      </div>
    </div>
  );
}

export function renderInputHelper(type, props, options) {
  switch (type) {
    case 'text': {
      const onChange = (e) => {
        if (typeof options.onChange === 'function') { options.onChange(e.target.value); }
      };
      return (<input type='text' { ...props } value={ options.value || '' } onChange={ onChange }/>);
    }
    case 'checkbox': {
      const onChange = (e) => {
        if (typeof options.onChange === 'function') { options.onChange(e.target.checked); }
      };
      return (
        <div className="checkbox">
          <label>
            <input type='checkbox' { ...props } checked={ options.value } onChange={ onChange }/>
            { options.label }
          </label>
        </div>
      );
    }
    case 'component': {
      return options.component;
    }
    default: return null;
  }
}
