import _get from 'lodash/get';

/**
 * 指定されたキーに従ってソートする   Array.prototype.sort 用 comparator 関数
 *
 * ソートはJSデフォルトのUnicode辞書順で行われます。
 * - 通常の使用例: `[].sort(compareBy.bind(null, "key", "desc"))`
 * - BootstrapTableでの使用例: `sortFunc={ (a, b, order) => compareBy('key', order, a, b) }`
 *
 * @param propKey 検索対象とするキー指定（ lodash#get で指定する object path ）
 * @param order 昇順 "asc" 、降順 "desc" の指定
 * @param a 左辺
 * @param b 右辺
 * @todo object型は使わないように
 */
// eslint-disable-next-line @typescript-eslint/ban-types
export default function compareBy<T extends object>(propKey: string | string[], order: 'desc' | 'asc', a: T, b: T): 0 | 1 | -1 {
  const ax = _get(a, propKey);
  const bx = _get(b, propKey);

  if (ax > bx) {
    return (order === 'desc') ? -1 : 1;
  }

  if (ax < bx) {
    return (order === 'desc') ? 1 : -1;
  }

  return 0;
}
