import DepartmentSelector from 'components/DepartmentSelector';
import React from 'react'; // @todo SuggestField など、外部から与えられた departments を使うのではなく, 自身が部署を取得するようなコンポーネントへ置き換える
import i18next from 'i18n';

const AdvancedSearchConditionForm = (props) => {
  return (
    <div style={ props.style }>
      <label>{ i18next.t('originalReceiptIdentificationRequests.properties.department') }</label>
      <div className='list-selector form-control'>
        <DepartmentSelector
          departments={ props.departments }
          selectedDepartment={ props.selectedDepartment }
          onSelectDepartment={ props.onSelectDepartment }
          displayFormat={ (item) => item.name }
        />
      </div>
    </div>
  );
};

export default React.memo(AdvancedSearchConditionForm);
