import React from 'react';
import colors from 'values/colors';
import styled from 'styled-components';

interface StyleProps {
  /** ボタンサイズ(px) */
  buttonSize?: number;
  /** アイコンサイズ(px) */
  fontSize?: number;
  /** disabled */
  disabled?: boolean;
}

interface ComponentProps {
  /** react-bootstrapがRefを渡すために指定する */
  bsRole?: string;
  /** fontawsomeのアイコン指定 */
  className: string;
  /** クリック時のハンドラ */
  onClick?: React.MouseEventHandler<HTMLButtonElement>;
}

type Props = StyleProps & ComponentProps;

const ButtonView = styled.button<StyleProps>`
  display: flex;
  justify-content: center;
  align-items: center;
  width: ${({ buttonSize }): string => (buttonSize ? `${buttonSize}px` : '36px')};
  height: ${({ buttonSize }): string => (buttonSize ? `${buttonSize}px` : '36px')};
  border-radius: 4px;
  transition: 150ms;
  border: none;
  outline: none;
  background: none;
  &:hover {
    background: ${({ disabled }): string => (disabled ? 'inherit' : colors.renewaled.whiteHover)};
  }
  i {
    color: ${({ disabled }): string => (disabled ? '#c5c8c9' : colors.renewaled.textBlack)};
    position: relative;
    font-size: ${({ fontSize }): string => (fontSize ? `${fontSize}px !important` : '20px !important')};
    transition: 300ms;
  }
`;

/** FontAwsomeのアイコンボタン */
export const IconButton = React.forwardRef<HTMLButtonElement, Props>(({
  className, buttonSize, fontSize, onClick, disabled = false,
}, ref) => {
  return (
    <ButtonView
      className={ `icon-button ${disabled && 'disabled'}` }
      buttonSize={ buttonSize }
      fontSize={ fontSize }
      onClick={ onClick }
      disabled={ disabled }
    >
      <i ref={ ref } className={ className } />
    </ButtonView>
  );
});
