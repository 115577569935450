import i18next from "i18n";
import React from "react";
import RequestStateButton from "./RequestStateButton";
import { Props } from "./Types";

/** 「再申請する」ボタン */
const ReapplyButton: React.FC<Props> = ({ requestState, onClick }) => {
  return (
    <RequestStateButton
      buttonJobType="request"
      buttonTitle={i18next.t("commons.actions.reapply")}
      buttonClassName="btn btn-block btn-accent"
      iconClassName="fa fa-left fa-paper-plane"
      requestState={requestState}
      onClick={onClick}
    />
  );
};

export default ReapplyButton;
