import _get from 'lodash/get';
import { createSelector } from 'reselect';

// 表示に必要な原本データを整形する
const getOriginalReceipt = (state, _props) => {
  const data = state.originalReceipt;

  return {
    id: _get(data, 'id'),
    amount: _get(data, 'amount'),
    mimetype: _get(data, 'mimetype'),
    shopName: _get(data, 'shopName'),
    transactedAt: _get(data, 'transactedAt'),
    url: _get(data, 'url'),
    rootGroupName: _get(data, 'rootGroup.name'),
    storageCode: _get(data, 'receiptPackage.storageCode'),
    report: {
      id: _get(data, 'expense.report.id'),
      title: _get(data, 'expense.report.title'),
      status: _get(data, 'expense.report.status'),
      preReportId: _get(data, 'expense.report.preReportId'),
    },
    boxName: _get(data, 'receiptPackage.receiptBox.name'),
    boxAddress:  _get(data, 'receiptPackage.receiptBox.address'),
    matchingStatus:  _get(data, 'matchingStatus'),
  };
};

export const selector = () => createSelector(
  [getOriginalReceipt],
  (originalReceipt) => {
    return { originalReceipt };
  },
);

export default selector;
