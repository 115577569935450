import React from "react";
import { Button } from "react-bootstrap";

interface Props {
  onClick: () => void;
}

/** 行削除ボタン */
export const RemoveRowButton: React.FC<Props> = ({ onClick }) => {
  return (
    <Button
      onClick={onClick}
      bsStyle="link"
      style={{ outline: "none" }}
      data-testid="remove-row-button"
    >
      <i className="fas fa-times" style={{ fontSize: "20px", color: "red" }} />
    </Button>
  );
};
