import * as ActionTypes from "../actions/applicationActions";

const initialState = {
  editable: true,
  title: "",
};

const application = (state = initialState, action) => {
  switch (action.type) {
    case ActionTypes.SET_TITLE:
      return {
        ...state,
        title: action.title,
      };
    case ActionTypes.SET_APPLICATION:
      return {
        ...state,
        ...action.application,
      };
    default:
      return state;
  }
};

export default application;
