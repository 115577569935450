import * as ActionTypes from '../actions/reportRequests';
import * as FlowActionTypes from '../actions/flowFormActions';
import * as approversSearchActionTypes from 'applications/approval_flows/actions/approversSearchActions';
import _get from 'lodash/get';
import _includes from 'lodash/includes';
import selectedApproversListReducer from 'applications/approval_flows/reducers/ApprovalFormSelectedApproversList';
import transactionTable from 'applications/transactions/reducers/transactionTable';
import { approversSearch, initialState as approversSearchState } from 'applications/approval_flows/reducers/approversSearch';
import { initialState as selectedApproversInitialState } from 'applications/approval_flows/actions/ApprovalFormSelectedApproversList/Types';
import { approvalFormSelectedApproversListActionTypes as selectedApproversListActionTypes } from 'applications/approval_flows/actions/ApprovalFormSelectedApproversList/ActionTypes';

const initialState = {
  currentPage: 1,
  sizePerPage: 30,
  isEditing: false,
  inProcess: false,
  defaultReportName: null,
  transactions: {
    master: [],
    inEdit: [],
  },
  suggestions: {
    categoryName: [],
    project: [],
    department: userPreferences.departments,
  },
  modal: {
    transaction: null,
    isTransactionModalOpen: false,
  },
  request: {
    // 全承認者の情報を持つ
    approvers: {
      users: [],
      groups: [],
      posts: [],
      projectPosts: [],
    },
    reports: [],
    reportTitle: '',
    selectedReportId: null,  // 経費一覧モーダルに表示中の申請のID
    isReportSendModalOpen: false,
    useReportSendModalAsDraftMode: false, // 作成だけして申請はしない際のフラグ
    isReportRequestModalOpen: false,
    isTransactionsModalOpen: false,
    test: false,
    costAllocationModal: {
      show: false,
      costAllocations: [],
      hovor: null,
    },
    // inProcessと役割は重複するものの、参照する部品が異なるので、別途用意
    // isLoadingがtrueの間は、全画面触れなくなる
    isLoading: false,
  },
  approversSearch: approversSearchState,
  selectedApproversList: selectedApproversInitialState,
};

/**
 * 分岐した申請の中から、情報の更新対象の申請を見つけて、処理を実行するためのヘルパー関数
 */
const reports = (state = {}, action, reducer) => {
  return state.reports.map((report) => {
    if (report.id !== action.reportId) {
      return report;
    }
    return reducer(report);
  });
};

/**
 * 主に、申請対象の経費を選択後、申請完了までに使用するデータを処理する
 */
const request = (state = initialState.request, action) => {
  switch (action.type) {
    case ActionTypes.SET_REPORT_TITLE: {
      return {
        ...state,
        reportTitle: action.value,
      };
    }
    case ActionTypes.SET_REPORTS: {
      return {
        ...state,
        reports: [...action.data],
      };
    }
    case ActionTypes.SET_REPORT_FORM_VALUE: {
      return {
        ...state,
        reports: reports(state, action, (report) => {
          if (report.id === action.reportId) {
            return {
              ...report,
              [action.key]: action.value,
            };
          }
          return report;
        }),
      };
    }
    case ActionTypes.SET_REPORT_TRANSACTIONS: {
      return {
        ...state,
        reports: reports(state, action, (report) => {
          const { transactions } = report;
          return {
            ...report,
            // 取得した部分の経費を詰める
            transactions: {
              ...transactions,
              data: [
                ...transactions.data.slice(0, action.offset),
                ...action.data,
                ...transactions.data.slice(action.offset + action.data.length),
              ],
            },
          };
        }),
      };
    }
    /**
     * currentPage, sizePerPageを更新する
     */
    case ActionTypes.SET_REPORT_TRANSACTIONS_PAGE_VALUE: {
      return {
        ...state,
        reports: reports(state, action, (report) => {
          return {
            ...report,
            transactions: {
              ...report.transactions,
              [action.key]: action.value,
            },
          };
        }),
      };
    }
    /** 承認者の一覧の情報をセットする */
    case FlowActionTypes.RESET_APPROVERS: {
      return {
        ...state,
        approvers: {
          ...state.approvers,
          [action.approverType]: [...action.data],
        },
      };
    }
    /** 承認者のチェック状態を初期化する */
    case FlowActionTypes.RESET_APPROVERS_CHECK: {
      const checkDefault = (checkedIds, x) => {
        if (_includes(checkedIds, x.id)) {
          return { ...x, isChecked: true };
        }
        return { ...x, isChecked: false };
      };

      const users = _get(state.approvers, 'users') || [];
      const groups = _get(state.approvers, 'groups') || [];

      return {
        ...state,
        reports: reports(state, action, (report) => {
          return {
            ...report,
            approvers: {
              users: users.map(checkDefault.bind(null, action.checkedIds.users)),
              groups: groups.map(checkDefault.bind(null, action.checkedIds.groups)),
            },
          };
        }),
      };
    }
    case FlowActionTypes.SET_CHECKED_APPROVERS: {
      return {
        ...state,
        reports: reports(state, action, (report) => {
          return {
            ...report,
            approvers: {
              ...report.approvers,
              [action.approverType]: [...action.data],
            },
          };
        }),
      };
    }
    case FlowActionTypes.SET_APPROVALS: {
      return {
        ...state,
        reports: reports(state, action, (report) => {
          return {
            ...report,
            approvals: [...action.data],
          };
        }),
      };
    }
    case FlowActionTypes.SET_EDITING_APPROVAL_INDEX: {
      return {
        ...state,
        reports: reports(state, action, (report) => {
          return {
            ...report,
            editingApprovalIndex: action.value,
          };
        }),
      };
    }
    case FlowActionTypes.TOGGLE_ADD_APPROVER_MODAL: {
      return {
        ...state,
        reports: reports(state, action, (report) => {
          return {
            ...report,
            isAddApproverModalOpen: action.show,
          };
        }),
      };
    }
    case FlowActionTypes.UPDATE_REQUEST_APPROVER:
      return {
        ...state,
        approvers: {
          ...state.approvers,
          [action.key]: action.value,
        },
      };
    case FlowActionTypes.UPDATE_APPROVER_FOR_REPORT: {
      const nextReports = [...state.reports];
      nextReports[action.index] = {
        ...state.reports[action.index],
        approvers: {
          ...state.reports[action.index].approvers,
          [action.key]: action.value,
        },
      };

      return {
        ...state,
        reports: nextReports,
      };
    }
    case ActionTypes.TOGGLE_REPORT_SEND_MODAL: {
      return {
        ...state,
        isReportSendModalOpen: action.show,
        useReportSendModalAsDraftMode: action.isDraft,
      };
    }
    case ActionTypes.TOGGLE_REPORT_REQUEST_MODAL: {
      return {
        ...state,
        isReportRequestModalOpen: action.show,
      };
    }
    case ActionTypes.TOGGLE_TRANSACTIONS_MODAL: {
      return {
        ...state,
        isTransactionsModalOpen: action.show,
        selectedReportId: action.show ? action.reportId : null,
      };
    }
    case ActionTypes.TOGGLE_LOADING_STATUS: {
      return {
        ...state,
        isLoading: action.value,
      };
    }
    case ActionTypes.TOGGLE_COST_ALLOCATION_MODAL: {
      return {
        ...state,
        costAllocationModal: {
          ...state.costAllocationModal,
          show: action.show,
        },
      };
    }
    case ActionTypes.SET_COST_ALLOCATIONS: {
      return {
        ...state,
        costAllocationModal: {
          ...state.costAllocationModal,
          costAllocations: action.data,
        },
      };
    }
    case ActionTypes.SET_COST_ALLOCATION_HOVOR_ROW_IDX: {
      return {
        ...state,
        costAllocationModal: {
          ...state.costAllocationModal,
          hovor: action.rowIdx,
        },
      };
    }
    default: {
      return state;
    }
  }
};

const reportRequests = (state = initialState, action) => {
  switch (action.type) {
    case ActionTypes.SET_REPORT_TITLE:
    case ActionTypes.SET_REPORTS:
    case ActionTypes.SET_REPORT_FORM_VALUE:
    case ActionTypes.SET_REPORT_TRANSACTIONS:
    case ActionTypes.SET_REPORT_TRANSACTIONS_PAGE_VALUE:
    case FlowActionTypes.RESET_APPROVERS:
    case FlowActionTypes.RESET_APPROVERS_CHECK:
    case FlowActionTypes.SET_CHECKED_APPROVERS:
    case FlowActionTypes.SET_APPROVALS:
    case FlowActionTypes.SET_EDITING_APPROVAL_INDEX:
    case FlowActionTypes.TOGGLE_ADD_APPROVER_MODAL:
    case FlowActionTypes.UPDATE_REQUEST_APPROVER:
    case FlowActionTypes.UPDATE_APPROVER_FOR_REPORT:
    case ActionTypes.TOGGLE_REPORT_SEND_MODAL:
    case ActionTypes.TOGGLE_REPORT_REQUEST_MODAL:
    case ActionTypes.TOGGLE_TRANSACTIONS_MODAL:
    case ActionTypes.TOGGLE_LOADING_STATUS:
    case ActionTypes.TOGGLE_COST_ALLOCATION_MODAL:
    case ActionTypes.SET_COST_ALLOCATIONS:
    case ActionTypes.SET_COST_ALLOCATION_HOVOR_ROW_IDX: {
      return {
        ...state,
        request: request(state.request, action),
      };
    }
    case approversSearchActionTypes.CONFIG_APPROVERS_USER_SEARCH_CONDITION:
    case approversSearchActionTypes.REQUEST_APPROVERS_SEARCH:
    case approversSearchActionTypes.RECEIVE_APPROVERS_SEARCH: {
      return {
        ...state,
        approversSearch: approversSearch(state.approversSearch, action),
      };
    }
    case selectedApproversListActionTypes.SELECT_APPROVERS:
    case selectedApproversListActionTypes.SELECT_APPROVER:
    case selectedApproversListActionTypes.DESELECT_APPROVER: {
      const selectedApproversList = selectedApproversListReducer(state.selectedApproversList, action);
      return {
        ...state,
        selectedApproversList,
      };
    }
    case ActionTypes.TOGGLE_IN_PROCESS_STATUS:
      return {
        ...state,
        inProcess: action.value,
      };
    default: return transactionTable(state, action);
  }
};

export default reportRequests;
