import omit from "lodash/omit";
import WebStorage from "utilities/web_storage";

/**
 * 経費の検索条件を保存するために使用する、LocalStorageのモデル
 */
export class SearchParamsStorage {
  constructor() {
    this.storage = new WebStorage();

    this.version = "1.1.1";
    this.migrate();
  }

  get data() {
    return this._data || this.storage.getItem(this.key) || this.defaultData;
  }

  set data(params) {
    const newData = omit(params, ["_version"]);

    this._data = newData;
    // 初期表示時は、並べ替え条件がリセットされるので、保存しない
    this.storage.setItem(this.key, {
      ...omit(this._data, ["sort"]),
      _version: this.version,
    });
  }

  get defaultData() {
    return {
      status: ["default", "waiting_for_worker", "denied"],
      includeDraftSaved: null,
      shopName: "",
      categoryName: "",
      ownerName: "",
      sequenceNum: "",
      exportLineId: "",
      dateFrom: "",
      dateTo: "",
      amountFrom: "",
      amountTo: "",
      scope: false,
      departmentId: "",
      includeChildDepartment: true,
      receiptExpenseMatching: null,
      genericFields: [],
      registratedNumber: "",
      asInvoiceChecks: [],
    };
  }

  clear() {
    this.data = { ...this.defaultData };
  }

  /**
   * @private
   */
  get key() {
    return ["expenses", "searchCondition"];
  }

  /**
   * @private
   */
  migrate() {
    this.data = this.migrateSchema(this.data);

    // 使用されない値を削除する
    this.storage.removeItem(["transactions", "searchCondition"]);
  }

  /**
   * @private
   * @param {object} params
   * @return {object}
   */
  migrateSchema(params) {
    // TODO: リリース後、一定期間経過後に、古い移行処理を削除し、localStorageの値を初期化していく
    if (params) {
      const oldValue = params;
      // eslint-disable-next-line dot-notation
      const oldVersion = oldValue?.["_version"];

      /** v1.0以下 -> v1.1.1の移行 (最新値に置換) */
      if (["1.0.1", "1.1.0", "1.1.1"].indexOf(oldVersion) < 0) {
        return this.defaultData;
      }

      /** v1.1.0 -> v1.1.1の移行 */
      if (typeof oldValue.matchedOriginalReceipt !== "undefined") {
        oldValue.receiptExpenseMatching =
          oldValue.matchedOriginalReceipt ??
          this.defaultData.receiptExpenseMatching;
        delete oldValue.matchedOriginalReceipt;
      }

      return oldValue;
    }

    return this.defaultData;
  }
}

export default new SearchParamsStorage();
