import React, { FC } from "react";

interface Props {
  name: string;
  onSubmit: () => void;
}

const OauthAccountRegistrationForm: FC<Props> = (props) => (
  <div>
    <div className="row">
      <div className="col-md-offset-4 col-md-4 text-center">
        <button
          className={`btn btn-accent btn-block`}
          onClick={props.onSubmit}
          type="button"
        >
          連携設定を開始する
        </button>
      </div>
    </div>
    <div className="row">
      <div className="col-md-12 small text-center">
        {`${props.name}のログインページを開くための画面が開きます。`}
      </div>
    </div>
  </div>
);

export default OauthAccountRegistrationForm;
