import PropTypes from 'prop-types';
import React, { Component } from 'react';
import compareBy from 'utilities/sort_comparators/compareBy';
import formatter from 'utilities/formatter';
import i18next from 'i18n';
import { BootstrapTable, TableHeaderColumn } from 'react-bootstrap-table';

/**
 * 勘定科目毎集計テーブル
 */
export default class CategoryAnalysisTable extends Component {
  render() {
    const options = {
      noDataText: i18next.t('commons.messages.noDataFound'),
      sizePerPageList: [10, 30, 50],
      sizePerPage: 10,
    };

    const summaries = this.props.summaries.map((x) => ({ ...x, id: x.category.id }));

    return (
      <BootstrapTable
        data={ summaries }
        options={ options }
        striped={ true }
        pagination={ true }
      >
        <TableHeaderColumn
          dataField='id'
          hidden={ true }
          isKey={ true }
        />
        <TableHeaderColumn
          dataField='category'
          dataFormat={ this.formatCategory }
          dataSort={ true }
          sortFunc={ this.sortCategory }
          width='250'
        >
          勘定科目
        </TableHeaderColumn>
        <TableHeaderColumn
          dataField='count'
          dataSort={ true }
          width='80'
        >
          経費数
        </TableHeaderColumn>
        <TableHeaderColumn
          dataField='sum'
          dataFormat={ (v) => (formatter.amount(v)) }
          dataSort={ true }
          dataAlign='right'
          width='150'
        >
          合計金額
        </TableHeaderColumn>
      </BootstrapTable>
    );
  }

  /**
   * 勘定科目の欄
   */
  formatCategory(category) {
    return formatter.text(category.name, '経費科目なし');
  }

  /**
   * 勘定科目のソート
   */
  sortCategory(a, b, order) {
    return compareBy('category.name', order, a, b);
  }
}

CategoryAnalysisTable.propTypes = {
  summaries: PropTypes.array.isRequired,
};
