import React, { useCallback, useState } from 'react';
import styled from 'styled-components';
import { ApprovalStep, ApprovalStepsForReject, RequestStep } from 'utilities/api/models/ApprovalStepsForReject';
import { RejectToSelectorModal } from './RejectToSelectorModal';

interface Props {
  steps: ApprovalStepsForReject;
  selectedStep: RequestStep | ApprovalStep;
  onSelectStep: (newStep: RequestStep | ApprovalStep) => void;
}

const FieldView = styled.div`
  position: relative;

  .form-control-icon {
    position: absolute;
    z-index: 2;
    display: block;
    font-size: 1.75rem;
    text-align: center;
    pointer-events: none;
    color: #aaa;
    right: 8px;
    top: 8px;
  }
  .form-control {
    cursor: pointer;
    background: #fff;
  }
`;

/**
 * 差戻先フィールド
 */
export const RejectToField: React.FC<Props> = ({
  steps,
  selectedStep,
  onSelectStep,
}) => {
  const [showModal, setShowModal] = useState(false);
  const handleClick = useCallback(() => setShowModal(true), [setShowModal]);
  const handleClose = useCallback(() => setShowModal(false), [setShowModal]);

  return (
    <>
      <FieldView>
        <i className='fa fa-angle-right form-control-icon' />
        <input type='text' className='form-control' value={ ('approvers' in selectedStep) ? (selectedStep as ApprovalStep).name : '申請者' } placeholder='選択してください' readOnly onClick={ handleClick }/>
      </FieldView>
      <RejectToSelectorModal show={ showModal } onClose={ handleClose } selectedStep={ selectedStep } onSelectStep={ (step): void => { onSelectStep(step); setShowModal(false); } } steps={ steps }/>
    </>
  );
};
