import React from 'react';
import { FormItem } from './FormItem';
import { FormItemModel } from '../Types';

interface Props {
  editable: boolean;
  formItems: FormItemModel[];
  onChange: (index: number, value: string[]) => void;
}

const propsAreEqual = (prevProps: Readonly<Props>, nextProps: Readonly<Props>): boolean => (
  prevProps.editable === nextProps.editable
  && JSON.stringify(prevProps.formItems) === JSON.stringify(nextProps.formItems)
  && prevProps.onChange === nextProps.onChange
);

/**
 * 汎用申請の入力フォーム
 * 申請名・申請IDを除くユーザー定義部分
 */
export const Form: React.FC<Props> = React.memo(({ formItems, editable, onChange }) => {
  return (
    <div className='form-dynamic form-horizontal'>
      {
        formItems.map((formItem, index) => (
          <FormItem
            key={ formItem.id }
            formItem={ formItem }
            editable={ editable }
            onChange={ onChange.bind(null, index) } // NOTE: 引数の型を合わせるためにbindする
          />
        ))
      }
    </div>
  );
}, propsAreEqual);
