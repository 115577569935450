import * as ActionTypes from 'applications/requests/actions/exportModalActions';
import { StateRequestsExportModal } from 'applications/requests/interface/exportModal';

interface Action {
  /** アクションタイプ */
  readonly type: string;
  readonly payload: {
    readonly id: string | null;
    readonly exportFormats: string[];
    readonly requestType: string;
    readonly showModal: boolean;
  };
}

/**
 * State: 初期値
 */
const initialState: StateRequestsExportModal = {
  reportId: null,
  preReportId: null,
  showModal: false,
  exportFormats: [],
};

/**
 * ID名を取得する
 */
const getIdName = (requestType: string): string => {
  switch (requestType) {
    case 'report': { return 'reportId'; }
    case 'pre_report': { return 'preReportId'; }
    default: { return 'report'; }
  }
};

/**
 * Redux reducers: エクスポートモーダル
 */
const exportModal = (state: StateRequestsExportModal = initialState, action: Action): StateRequestsExportModal => {
  switch (action.type) {
    case ActionTypes.SET_EXPORT_FORMATS:
      return {
        ...state,
        exportFormats: action.payload.exportFormats,
      };
    case ActionTypes.SET_REPORT_ID: {
      return {
        ...state,
        reportId: null,
        preReportId: null,
        [getIdName(action.payload.requestType)]: action.payload.id,
      };
    }
    case ActionTypes.TOGGLE_MODAL:
      return {
        ...state,
        showModal: action.payload.showModal,
      };
    default:
      return state;
  }
};

export default exportModal;
