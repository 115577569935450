const initialModalState = {
  show: false,
};

export type ModalState = typeof initialModalState;

export const initialState = {
  id: "",
  ...initialModalState,
};

export type State = typeof initialState;
