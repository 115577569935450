import Api from "utilities/api";
import { TaxCategorySelection } from "utilities/api/models";
import ApiExpenses from "utilities/api_expenses";
import { OperatorEntry } from "utilities/api_expenses/models/expenses/OperatorEntry";
import { snakecaseKeys } from "utilities/Utils";

/**
 * 利用可能なオペレータ入力を取得します。
 * @param expenseId 経費ID
 * @returns
 */
export const fetchOperatorEntry = async (
  expenseId: string,
): Promise<OperatorEntry | null> => {
  try {
    const data = await ApiExpenses.expenses.operatorEntry({
      id: expenseId,
    });
    return data;
  } catch {
    return null;
  }
};

/**
 * 選択可能な税区分を取得します。
 * @param dates 利用日
 * @param categoryId 経費科目ID
 * @returns
 */
export const fetchTaxCategories = async (
  dates: string | string[],
  categoryId: string | null,
): Promise<TaxCategorySelection[]> => {
  try {
    const data = await Api.transactions.selectableTaxCategories(
      snakecaseKeys({ dates, categoryId }),
    );
    return data;
  } catch {
    return [];
  }
};
