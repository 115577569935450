import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';
import withStaticInput from 'composers/withStaticInput';

class TextField extends PureComponent {
  constructor(props) {
    super(props);

    this.handleTextChange = this.handleTextChange.bind(this);
  }

  get value() {
    return this.props.value || '';
  }

  handleTextChange(e) {
    this.props.onValueChange(e.target.value);
  }

  render() {
    return (
      <input className={ this.props.className }
        type='text'
        autoFocus={ this.props.autoFocus }
        value={ this.value }
        placeholder={ this.props.placeholder }
        onChange={ this.handleTextChange }
        onBlur={ this.props.onBlur }
        onKeyDown={ this.props.onKeyDown }
        disabled={ !this.props.editable }
        { ...this.props.options }
      />
    );
  }
}

TextField.defaultProps = {
  className: 'form-control',
  editable: true,
  options: {},
  placeholder: '',
  onValueChange(text) {
    window.console.warn('onValueChange is not implemented: TextField');
  },
};

TextField.propTypes = {
  className: PropTypes.string,
  value: PropTypes.string,
  editable: PropTypes.bool,
  options: PropTypes.object,
  placeholder: PropTypes.string,
  autoFocus: PropTypes.bool,
  onValueChange: PropTypes.func,
  onBlur: PropTypes.func,
  onKeyDown: PropTypes.func,
};

export default withStaticInput()(TextField);
