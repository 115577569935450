import Clearable from 'components/fields/Clearable';
import React, { useState } from 'react';
import i18next from 'i18next';
import { AssignableReportInputModal } from './AssignableReportInputModal';
import { PreReport, Report } from 'utilities/api/models';

interface Props {
  value: PreReport | Report | null;
  onSelectPreReport: (preReport: PreReport | null) => void;
  onSelectReport: (report: Report | null) => void;
  disabled: boolean;
}

/** 経費にアタッチする事前申請・経費申請選択フィールド */
export const AssignableReportFieldWithInputModal: React.FC<Props> = ({
  value, onSelectPreReport, onSelectReport, disabled,
}) => {
  const [showModal, setShowModal] = useState(false);

  /** 閉じるボタン押下時のハンドラ */
  const handleClose = (): void => setShowModal(false);

  /** Clearableの×ボタン押したときのハンドラ */
  const handleClear = (): void => {
    onSelectPreReport(null);
    onSelectReport(null);
  };

  /** モーダルを開く */
  const handleShowModal = (): void => {
    if (disabled) return;

    setShowModal(true);
  };

  return (
    <>
      <Clearable
        className={ `form-control` }
        onClick={ handleShowModal }
        onClear={ handleClear }
        disabled={ disabled }
      >
        <div style={ {
          whiteSpace: 'nowrap',
          overflow: 'hidden',
          textOverflow: 'ellipsis',
        } }
        >{ value?.title || i18next.t('commons.status.notSelected') }</div>
      </Clearable>

      <AssignableReportInputModal
        show={ showModal }
        value={ value }
        onClose={ handleClose }
        onSelectPreReport={ onSelectPreReport }
        onSelectReport={ onSelectReport }
      />
    </>
  );
};
