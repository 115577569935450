import { LocalStorageUtil } from "utilities/local_storage_util";

export interface ApprovingRequestsStorageHeaderDisplayParam {
  /** 表示key */
  readonly accessor: string;
  /** 順番 */
  readonly index: number;
  /** 表示するか */
  readonly isShow: boolean;
}
interface TableStorageParamHeaderDisplay {
  /** 経費申請 */
  readonly reports: ApprovingRequestsStorageHeaderDisplayParam[] | null;
  /** 事前申請 */
  readonly preReports: ApprovingRequestsStorageHeaderDisplayParam[] | null;
  /** 汎用申請 */
  readonly applications: ApprovingRequestsStorageHeaderDisplayParam[] | null;
  /** 支払依頼申請 */
  readonly paymentRequestsReports:
    | ApprovingRequestsStorageHeaderDisplayParam[]
    | null;
  /** 稟議申請 */
  readonly paymentRequestsProposals:
    | ApprovingRequestsStorageHeaderDisplayParam[]
    | null;
  /** 一括支払依頼申請 */
  readonly paymentRequestsConsolidatedReports:
    | ApprovingRequestsStorageHeaderDisplayParam[]
    | null;
}
export interface ApprovingRequestsStorageHeaderWidthReport {
  readonly createdAt: number;
  readonly title: number;
  readonly sequence: number;
  readonly needsTemporaryPayment: number;
  readonly approvalFlowName: number;
  readonly applicant: number;
  readonly projects: number;
  readonly amount: number;
  readonly withholding: number;
  readonly preAmount: number;
  readonly department: number;
  readonly matchedOriginalReceipt: number;
  readonly statusForApprover: number;
  readonly status: number;
  readonly buttons: number;
}
export interface ApprovingRequestsStorageHeaderWidthPreReport {
  readonly title: number;
  readonly sequence: number;
  readonly approvalFlowName: number;
  readonly applicant: number;
  readonly project: number;
  readonly department: number;
  readonly temporaryPaymentDueAt: number;
  readonly preAmount: number;
  readonly statusForApprover: number;
  readonly status: number;
  readonly buttons: number;
}
export interface ApprovingRequestsStorageHeaderWidthApplication {
  readonly title: number;
  readonly sequence: number;
  readonly approvalFlowName: number;
  readonly applicant: number;
  readonly statusForApprover: number;
  readonly status: number;
  readonly buttons: number;
}
export interface ApprovingRequestsStorageHeaderWidthPRReport {
  readonly createdAt: number;
  readonly dueAt: number;
  readonly scheduleAt: number;
  readonly appropriatedAt: number;
  readonly supplierCode: number;
  readonly supplierName: number;
  readonly title: number;
  readonly sequenceNumber: number;
  readonly approvalFlowName: number;
  readonly projects: number;
  readonly proposalSequenceNumbers: number;
  readonly requester: number;
  readonly belongingDepartment: number;
  readonly registratedNumber: number;
  readonly asInvoice: number;
  readonly reportNumber: number;
  readonly amount: number;
  readonly withholding: number;
  readonly memo: number;
  readonly reportLabels: number;
  readonly statusForApprover: number;
  readonly status: number;
  readonly buttons: number;
}
export interface ApprovingRequestsStorageHeaderWidthPRProposal {
  readonly createdAt: number;
  readonly scheduleAt: number;
  readonly title: number;
  readonly sequenceNumber: number;
  readonly approvalFlowName: number;
  readonly projects: number;
  readonly proposals: number;
  readonly proposalSequenceNumbers: number;
  readonly requester: number;
  readonly belongingDepartment: number;
  readonly amount: number;
  readonly withholding: number;
  readonly statusForApprover: number;
  readonly status: number;
  readonly buttons: number;
}
export interface ApprovingRequestsStorageHeaderWidthPRConsolidatedReport {
  readonly createdAt: number;
  readonly title: number;
  readonly sequenceNumber: number;
  readonly approvalFlowName: number;
  readonly projects: number;
  readonly requester: number;
  readonly belongingDepartment: number;
  readonly totalAmount: number;
  readonly totalWithholding: number;
  readonly statusForApprover: number;
  readonly status: number;
  readonly buttons: number;
}
interface StorageHeaderWidth {
  /** 経費申請 */
  readonly report: ApprovingRequestsStorageHeaderWidthReport;
  /** 事前申請 */
  readonly preReport: ApprovingRequestsStorageHeaderWidthPreReport;
  /** 汎用申請 */
  readonly application: ApprovingRequestsStorageHeaderWidthApplication;
  /** 支払依頼申請 */
  readonly paymentRequestsReport: ApprovingRequestsStorageHeaderWidthPRReport;
  /** 稟議申請 */
  readonly paymentRequestsProposal: ApprovingRequestsStorageHeaderWidthPRProposal;
  /** 一括支払依頼申請 */
  readonly paymentRequestsConsolidatedReport: ApprovingRequestsStorageHeaderWidthPRConsolidatedReport;
}

/**
 * LocalStrage: テーブルの設定を保存する
 */
export interface ApprovingRequestsTableStorageParam {
  /** バージョン */
  readonly _version?: string;
  /** 選択しているページのサイズ */
  readonly sizePerPage: number;
  /** ヘッダーカラムの横幅 */
  readonly headerWidth: StorageHeaderWidth;
  /** ヘッダーカラムの表示と順序 */
  readonly headerDisplay: TableStorageParamHeaderDisplay;
}

/**
 * スキーマのバージョン。変更するたびに上げる。
 * ただし、バージョン比較が文字列比較なので、バージョンの各桁はそれぞれ0~9までの数字のみとする。（例、OK = 1.0.9、NG = 1.0.11 ）
 */
const version = "1.0.1";
const key = ["approving_requests", "table"];
const defaultData: ApprovingRequestsTableStorageParam = {
  sizePerPage: 20,
  headerDisplay: {
    reports: null,
    preReports: null,
    applications: null,
    paymentRequestsReports: null,
    paymentRequestsProposals: null,
    paymentRequestsConsolidatedReports: null,
  },
  headerWidth: {
    report: {
      createdAt: 120,
      title: 220,
      sequence: 120,
      needsTemporaryPayment: 150,
      approvalFlowName: 120,
      applicant: 120,
      projects: 120,
      amount: 120,
      withholding: 120,
      preAmount: 120,
      department: 120,
      matchedOriginalReceipt: 120,
      statusForApprover: 190,
      status: 190,
      buttons: 200,
    },
    preReport: {
      title: 220,
      sequence: 120,
      approvalFlowName: 120,
      applicant: 120,
      project: 120,
      department: 120,
      temporaryPaymentDueAt: 120,
      preAmount: 120,
      statusForApprover: 190,
      status: 190,
      buttons: 200,
    },
    application: {
      title: 220,
      sequence: 200,
      approvalFlowName: 220,
      applicant: 150,
      statusForApprover: 190,
      status: 190,
      buttons: 200,
    },
    paymentRequestsReport: {
      createdAt: 120,
      dueAt: 120,
      scheduleAt: 120,
      appropriatedAt: 120,
      supplierCode: 150,
      supplierName: 135,
      title: 220,
      sequenceNumber: 120,
      approvalFlowName: 120,
      projects: 120,
      proposalSequenceNumbers: 120,
      requester: 120,
      belongingDepartment: 250,
      registratedNumber: 140,
      asInvoice: 100,
      reportNumber: 120,
      amount: 120,
      withholding: 120,
      memo: 120,
      reportLabels: 140,
      statusForApprover: 190,
      status: 190,
      buttons: 200,
    },
    paymentRequestsProposal: {
      createdAt: 110,
      scheduleAt: 110,
      title: 220,
      sequenceNumber: 120,
      approvalFlowName: 120,
      projects: 120,
      proposals: 120,
      proposalSequenceNumbers: 120,
      requester: 120,
      belongingDepartment: 120,
      amount: 120,
      withholding: 120,
      statusForApprover: 190,
      status: 190,
      buttons: 200,
    },
    paymentRequestsConsolidatedReport: {
      createdAt: 110,
      title: 220,
      sequenceNumber: 120,
      approvalFlowName: 120,
      projects: 120,
      requester: 120,
      belongingDepartment: 120,
      totalAmount: 120,
      totalWithholding: 120,
      statusForApprover: 190,
      status: 190,
      buttons: 200,
    },
  },
};

// 支払依頼申請の表に取引先コードの列を追加する
const version101 = (
  params: ApprovingRequestsTableStorageParam,
): ApprovingRequestsTableStorageParam => {
  // 支払依頼申請の表の並び順、表示・非表示が初期設定かどうかを判定する
  const isDefaultSetting = (
    headerDisplay: ApprovingRequestsStorageHeaderDisplayParam[],
  ): boolean => {
    const {
      showApprovalFlowName,
      showTransactionsProject,
      showWithholding,
      usePaymentRequest,
      usePaymentRequestsReportRequest,
      usePaymentRequestsProposalRequest,
    } = userPreferences.preference;
    const showProposals =
      usePaymentRequest &&
      usePaymentRequestsReportRequest &&
      usePaymentRequestsProposalRequest;
    const optionColumns = {
      // ユーザによっては表示されない列
      // @see ReportRequestTableSpecification
      approvalFlowName: showApprovalFlowName,
      projects: showTransactionsProject,
      proposalSequenceNumbers: showProposals,
      withholding: showWithholding,
    };
    const allHeaderColumns = [
      // app/frontend/javascripts/applications/payment_requests/requests/components/ReportRequestTable/hooks.tsxのheaderColumnsと同じ順番で定義
      "createdAt",
      "dueAt",
      "scheduleAt",
      "appropriatedAt",
      "supplierName",
      "title",
      "sequenceNumber",
      "approvalFlowName",
      "projects",
      "proposalSequenceNumbers",
      "requester",
      "belongingDepartment",
      "registratedNumber",
      "asInvoice",
      "reportNumber",
      "amount",
      "withholding",
      "memo",
      "statusForApprover",
      "status",
    ];
    const headerColumns = allHeaderColumns.filter(
      (column) => optionColumns[column] !== false,
    );
    for (let i = 0; i < headerColumns.length; i++) {
      const column = headerColumns[i];
      const item = headerDisplay.find(
        (storagedItem) => storagedItem.accessor === column,
      );
      if (item === undefined) return false;
      if (item.index !== i) return false;
      if (item.isShow !== true) return false;
    }
    return true;
  };

  const migrateHeaderDisplay = (
    headerDisplay: ApprovingRequestsStorageHeaderDisplayParam[] | null,
  ): ApprovingRequestsStorageHeaderDisplayParam[] | null => {
    if (headerDisplay === null) return null;

    let showingItemCount = headerDisplay.filter((item) => item.isShow).length;
    const isDefault = isDefaultSetting(headerDisplay);
    // 支払依頼申請の表の並び順、表示・非表示が初期設定の場合は、左から5番目に取引先コードの列を追加する
    // 初期設定でない場合は、表示中の列の右端に取引先コードの列を追加する
    const insertSupplierCodeIndex = isDefault ? 4 : showingItemCount;
    showingItemCount += 1;
    // 同じく支払依頼申請の表の並び順、表示・非表示が初期設定の場合は、右から3番目(承認状況の左)に列を追加する
    // 初期設定でない場合は、表示中の列の右端に請求書ラベルの列を追加する
    const insertReportLabelsIndex = isDefault
      ? showingItemCount - 2
      : showingItemCount;
    const afterReportLabelsColumnIndex = insertReportLabelsIndex - 1;
    const updatedIndexHeaderDisplay = headerDisplay.map((item) => {
      let newIndex = item.index;
      if (item.index >= insertSupplierCodeIndex) {
        newIndex += 1;
      }
      if (item.index >= afterReportLabelsColumnIndex) {
        newIndex += 1;
      }
      return { ...item, index: newIndex };
    });

    return [
      ...updatedIndexHeaderDisplay.slice(0, insertSupplierCodeIndex),
      {
        accessor: "supplierCode",
        index: insertSupplierCodeIndex,
        isShow: true,
      },
      ...updatedIndexHeaderDisplay.slice(
        insertSupplierCodeIndex,
        afterReportLabelsColumnIndex,
      ),
      {
        accessor: "reportLabels",
        index: insertReportLabelsIndex,
        isShow: true,
      },
      ...updatedIndexHeaderDisplay.slice(afterReportLabelsColumnIndex),
    ];
  };

  return {
    ...params,
    headerWidth: {
      ...params.headerWidth,
      paymentRequestsReport: {
        ...params.headerWidth.paymentRequestsReport,
        supplierCode: 150,
        reportLabels: 140,
      },
    },
    headerDisplay: {
      ...params.headerDisplay,
      paymentRequestsReports: migrateHeaderDisplay(
        params.headerDisplay.paymentRequestsReports,
      ),
    },
  };
};

const migration = (
  params: ApprovingRequestsTableStorageParam,
): ApprovingRequestsTableStorageParam => {
  const v = params._version; // eslint-disable-line no-underscore-dangle
  let d: ApprovingRequestsTableStorageParam = params;
  if (v === "1.0.0") {
    d = version101(d);
  }
  return d;
};

const ApprovingRequestsTableStorage =
  new LocalStorageUtil<ApprovingRequestsTableStorageParam>(
    version,
    key,
    defaultData,
    migration,
  );

export default ApprovingRequestsTableStorage;
