import TextField from "components/fields/TextField";
import FormValidation from "components/FormValidation";
import LabeledComponent from "components/LabeledComponent";
import i18next from "i18n";
import React, { FC } from "react";

interface Props {
  content: string;
  disabled: boolean;
  errors: string[];
  onChangeContent: (content: string) => void;
}

const PreExpenseContentForm: FC<Props> = (props) => {
  return (
    <LabeledComponent
      className="form-group"
      labelClass="col-sm-3"
      label={i18next.t("transactions.properties.preTransactionContent")}
    >
      <div className="col-sm-6">
        <FormValidation messages={props.errors}>
          <TextField
            editable={!props.disabled}
            placeholder={i18next.t("commons.status.notEntered")}
            value={props.content}
            onValueChange={props.onChangeContent}
          />
        </FormValidation>
      </div>
    </LabeledComponent>
  );
};

export default PreExpenseContentForm;
