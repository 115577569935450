import { canRead } from "applications/transactions/utilities/transactionFormPolicy";
import * as ActionTypes from "../actions";
import presetEntryFormType from "../utilities/keyMap";

const currencyPref = userPreferences.preference.currency;
const initialState = {
  name: "手入力",
  fields: [],
  inProcess: false,
  isMultiDateChecked: false,
  isDatePickerRendered: false,
  defaultCurrencyId: currencyPref.defaultCurrency.currencyId,
  exchangeRates: currencyPref.exchangeRates,
  locale: userPreferences.locale === "ja" ? "ja-JP" : "en-US",
  toggle: {
    deleteModal: false,
    detachModal: false,
    confirmationModal: false,
    withholdingConfirmationModal: false,
    cancelReceiptMatchingModal: false,
    confirmCancelMatchingModal: false,
  },
  reuseInput: false,
  exceptUnknownCurrency: false,
  shouldSelectSelfAsCompanion: true,
  withholdingConstants: {},
  isEditingWithholdingCategory: true,
  emptyRequiredFields: [],
  isFetchingInvoicingOrganization: false,
  /** {boolean} オペレータ入力結果を確認するモーダル */
  showOperatorEntryConfirmModal: false,
};

const toggle = (state = initialState.toggle, action) => {
  switch (action.type) {
    case ActionTypes.SET_PROJECT_PREF:
      return {
        ...state,
        project: action.show,
      };
    case ActionTypes.TOGGLE_DELETE_MODAL:
      return {
        ...state,
        deleteModal: action.show !== void 0 ? action.show : !state.deleteModal,
      };
    case ActionTypes.TOGGLE_CONFIRMATION_MODAL:
      return {
        ...state,
        confirmationModal:
          action.show !== void 0 ? action.show : !state.confirmationModal,
      };
    case ActionTypes.TOGGLE_DETACH_MODAL:
      return {
        ...state,
        detachModal: action.show !== void 0 ? action.show : !state.detachModal,
      };
    case ActionTypes.TOGGLE_CANCEL_RECEIPT_MATCHING_MODAL:
      return {
        ...state,
        cancelReceiptMatchingModal:
          action.show !== void 0
            ? action.show
            : !state.cancelReceiptMatchingModal,
      };
    case ActionTypes.TOGGLE_CONFIRM_DETACH_MODAL:
      return {
        ...state,
        confirmCancelMatchingModal:
          action.show !== void 0
            ? action.show
            : !state.confirmCancelMatchingModal,
      };
    case ActionTypes.TOGGLE_WITHHOLDING_CONFIRMATION_MODAL:
      return {
        ...state,
        withholdingConfirmationModal:
          action.show !== void 0
            ? action.show
            : !state.withholdingConfirmationModal,
      };
    default:
      return state;
  }
};

const formFields = (state = [], action) => {
  switch (action.type) {
    case ActionTypes.SET_FORM_DATA:
      return state.map((field) => {
        const key = presetEntryFormType[action.prop];
        return {
          ...field,
          show: canRead(field, { [key]: action.value }),
          edited: field.type === key || field.edited,
        };
      });
    case ActionTypes.SET_META_DATA:
      return state.map((field) => {
        if (field.id === action.prop) {
          return {
            ...field,
            show: canRead(field, { [action.prop]: action.value }),
            edited: !action.autoInput,
          };
        }
        return field;
      });
    default:
      return state;
  }
};

const formState = (state = initialState, action) => {
  switch (action.type) {
    case ActionTypes.RESET_TRANSACTION:
      return {
        ...initialState,
        ...action.formState,
      };
    case ActionTypes.LOCK_FORM_BUTTON:
      return {
        ...state,
        inProcess: true,
      };
    case ActionTypes.UNLOCK_FORM_BUTTON:
      return {
        ...state,
        inProcess: false,
      };
    case ActionTypes.SWITCH_MULTI_DATE:
      return {
        ...state,
        isMultiDateChecked: !state.isMultiDateChecked,
      };
    case ActionTypes.SET_DATE_PICKER_STATUS:
      return {
        ...state,
        isDatePickerRendered: action.value,
      };
    case ActionTypes.CHECK_REUSE_INPUT:
      return {
        ...state,
        reuseInput: !state.reuseInput,
      };
    case ActionTypes.SET_EXCHANGE_RATE_PREF:
      return {
        ...state,
        defaultCurrencyId: action.defaultCurrencyId,
        exchangeRates: action.exchangeRates,
      };
    case ActionTypes.SET_FORM_DATA:
    case ActionTypes.SET_META_DATA:
      return {
        ...state,
        fields: formFields(state.fields, action),
      };
    case ActionTypes.SET_EXCEPT_UNKNOWN_CURRENCY:
      return {
        ...state,
        exceptUnknownCurrency: action.exceptUnknownCurrency,
      };
    case ActionTypes.SET_SHOULD_SELECT_SELF_AS_COMPANION:
      return {
        ...state,
        shouldSelectSelfAsCompanion: action.value,
      };
    case ActionTypes.SET_WITHHOLDING_CONSTANTS:
      return {
        ...state,
        withholdingConstants: action.value,
      };
    case ActionTypes.SET_IS_EDITING_WITHHOLDING_CATEGORY:
      return {
        ...state,
        isEditingWithholdingCategory: action.value,
      };
    case ActionTypes.SET_EMPTY_REQUIRED_FIELDS:
      return {
        ...state,
        emptyRequiredFields: action.emptyRequiredFields,
      };
    case ActionTypes.SET_IS_FETCHING_INVOICING_ORGANIZATION:
      return {
        ...state,
        isFetchingInvoicingOrganization: action.value,
      };
    case ActionTypes.OPEN_OPERATOR_ENTRY_CONFIRM_MODAL:
      return {
        ...state,
        showOperatorEntryConfirmModal: true,
      };
    case ActionTypes.CLOSE_OPERATOR_ENTRY_CONFIRM_MODAL:
      return {
        ...state,
        showOperatorEntryConfirmModal: false,
      };
    default: {
      return {
        ...state,
        toggle: toggle(state.toggle, action),
      };
    }
  }
};

export default formState;
