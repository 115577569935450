import PropTypes from 'prop-types';
import React from 'react';
import { OverlayTrigger, Popover } from 'react-bootstrap';

const onChange = () => {}; // onClickを使うので、何もしない

const RadioButton = (props) => {
  const renderDescriptionPopover = () => {
    return (
      <Popover id='description' title='項目説明'>
        { props.description }
      </Popover>
    );
  };

  const renderDescription = () => {
    // hoverだけだとmouseがない端末で発火しないため、clickを追加
    return (
      <OverlayTrigger trigger={ ['click', 'hover'] }
        placement={ props.placement || 'top' }
        overlay={ renderDescriptionPopover() }
      >
        <i className='far fa-question-circle'
          style={
            {
              color: '#AAA',
              marginLeft: '6px',
              marginRight: '6px',
              cursor: 'pointer',
            }
          }
        />
      </OverlayTrigger>
    );
  };

  return (
    <label className='radio-inline'>
      <input type='radio'
        checked={ props.isChecked }
        value={ props.value }
        onClick={ props.onChange.bind(null, props.value) }
        onChange={ onChange }
        disabled={ props.isDisabled }
      />
      { props.label }
      { props.description ? renderDescription() : null }
    </label>
  );
};

RadioButton.defaultProps = {
  isChecked: false,
  isDisabled: false,
  onChange(_value, _e) {},
};

RadioButton.propTypes = {
  isChecked: PropTypes.bool.isRequired,
  isDisabled: PropTypes.bool.isRequired,
  label: PropTypes.string.isRequired,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.bool, PropTypes.number]).isRequired,
  onChange: PropTypes.func,
  description: PropTypes.string,
};

export default RadioButton;
