import React from "react";
import { OperatorEntry } from "utilities/api_expenses/models/expenses/OperatorEntry";
import { Editor } from "./components/Editor";
import { EditorWithTaxCategory } from "./components/EditorWithTaxCategory";
import { ReadOnly } from "./components/ReadOnly";
import { AmountPerTaxCategory, Authority } from "./interface";

interface Props {
  /** 経費ID */
  readonly expenseId: string | null;
  /** 税区分別金額 */
  readonly amountPerTaxCategories: AmountPerTaxCategory[];
  /** 利用日 */
  readonly dates: string[];
  /** 金額 */
  readonly amount: number | null;
  /** 経費科目ID */
  readonly categoryId: string | null;
  /** 適格請求書として扱うか */
  readonly asEligibleInvoice: boolean;
  /** 消費税額を表示するか */
  readonly isTaxAmountShow: boolean;
  /** 編集可能か */
  readonly isEditable: boolean;
  /** ラベル */
  readonly label: string;
  /** 権限 */
  readonly authority: Authority;
  /** 税区分入力可能か */
  readonly isTaxCategoryEditable: boolean;
  /** オペレータ入力結果 */
  readonly operatorEntry: OperatorEntry | null;
  /** 税区分別金額変更時のコールバック */
  readonly onChange: (amountPerTaxCategories: AmountPerTaxCategory[]) => void;
}

/**
 * 税区分別金額一覧入力欄
 */
export const AmountPerTaxCategoryCollectionFormField: React.FC<Props> = ({
  expenseId,
  amount,
  dates,
  categoryId,
  isTaxAmountShow,
  isEditable,
  asEligibleInvoice,
  amountPerTaxCategories,
  label,
  authority,
  isTaxCategoryEditable,
  operatorEntry,
  onChange,
}) => {
  if (!isEditable) {
    return (
      <ReadOnly
        isTaxAmountShow={isTaxAmountShow}
        isTaxCategoryEditable={isTaxCategoryEditable}
        label={label}
        values={amountPerTaxCategories}
        authority={authority}
      />
    );
  }

  return (
    <>
      {isTaxCategoryEditable ? (
        <EditorWithTaxCategory
          expenseId={expenseId}
          amount={amount}
          dates={dates}
          categoryId={categoryId}
          isTaxAmountShow={isTaxAmountShow}
          asEligibleInvoice={asEligibleInvoice}
          values={amountPerTaxCategories}
          label={label}
          authority={authority}
          operatorEntry={operatorEntry}
          onChange={onChange}
        />
      ) : (
        <Editor
          expenseId={expenseId}
          amount={amount}
          dates={dates}
          categoryId={categoryId}
          isTaxAmountShow={isTaxAmountShow}
          asEligibleInvoice={asEligibleInvoice}
          values={amountPerTaxCategories}
          label={label}
          authority={authority}
          operatorEntry={operatorEntry}
          onChange={onChange}
        />
      )}
    </>
  );
};
