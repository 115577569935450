import i18next from "i18next";
import React, { FC } from "react";
import { Alert } from "react-bootstrap";
import styled from "styled-components";
import Api from "utilities/api";
import { fetchAsync } from "utilities/async";
import flash from "utilities/flash";
import ExportForm from "../../components/ExportForm";
import { ExportCondition } from "../export_condition";
import { useHooks } from "./hooks";

interface Props {
  readonly params: {
    readonly for: string;
    readonly requestTypeId: string;
    readonly exportCondition: ExportCondition;
  };
}

const GuideForMaxExportable = styled(Alert)`
  color: black;
  margin-bottom: 30px;
`;

const maxExportSize = 10000;

export const ApplicationExportForm: FC<Props> = (props) => {
  const { params } = props;
  const { formData } = useHooks({ requestTypeId: params.requestTypeId });
  let isLoading = false;

  const onExportCsv = async (): Promise<void> => {
    if (isLoading) return;

    try {
      isLoading = true;

      await fetchAsync(Api.expenses.applications.csvExportJobs, params);
      flash.success(i18next.t("exports.messages.startGenerateCsv"));
    } catch (e) {
      flash.error(e.message);
    } finally {
      isLoading = false;
    }
  };

  return (
    <>
      <GuideForMaxExportable bsStyle="info">
        {i18next.t("reports.messages.exportSize", {
          size: maxExportSize.toLocaleString(),
        })}
      </GuideForMaxExportable>
      <ExportForm
        data={formData}
        requestButtonTitle={i18next.t("reports.modals.actions.createCsv")}
        onRequestExport={onExportCsv}
      />
    </>
  );
};
