import styled from "styled-components";
import colors from "values/colors";

/**
 * フッターのスタイル
 */
export const ViewerFooter = styled.div`
  background-color: ${colors.main};
  position: absolute;
  bottom: 0;
  height: 32px;
  width: 100%;
  padding: 0 8px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  font-size: ${`${Math.floor(32 * 0.7)}px`};
  color: ${colors.white};
  opacity: 0.8;
  transition-duration: 2s;
  transition-timing-function: step-end;

  &:hover {
    opacity: 0;
    height: 0;
    transition-duration: 0s;
  }
`;
