import CheckBoxGroupAddon from "components/CheckBoxGroupAddon";
import i18next from "i18n";
import React, { FC } from "react";
import styled from "styled-components";

const RangerTextWrap = styled.div`
  border-top: 1px solid #ccc;
  border-bottom: 1px solid #ccc;
  span {
    background-color: white;
    font-size: 20px;
    font-weight: bold;
    display: flex;
    height: 32px;
    justify-content: center;
  }

  @media (max-width: 767px) {
    border: none;
    span {
      background-color: unset;
      height: 100%;
    }
  }
`;

const WrapAmoutFrom = styled.div`
  .amount-ip {
    border-right: none;
    border-top-right-radius: unset;
    border-bottom-right-radius: unset;
  }

  @media (max-width: 767px) {
    .amount-ip {
      border: 1px solid #ccc;
      border-radius: 4px;
    }
  }
`;

const WrapAmoutTo = styled.div`
  .amount-ip {
    border-left: none;
    border-radius: unset;
  }

  @media (max-width: 767px) {
    .amount-ip {
      border: 1px solid #ccc;
      border-radius: 4px;
    }
  }
`;

interface Props {
  searchEmpty: boolean;
  amountFrom: string;
  amountTo: string;
  onAmountChange: (
    e: React.ChangeEvent<HTMLInputElement>,
    type: string,
  ) => void;
  onSearchEmptyChange: () => void;
}

export const AmountRange: FC<Props> = (props) => {
  const renderAmountRange = (): JSX.Element => {
    if (userPreferences.locale === "ja") {
      return (
        <div className="transaction-amount-input">
          <WrapAmoutFrom className="col-sm-4 col-md-4">
            <input
              type="number"
              className="form-control amount-ip"
              value={props.amountFrom}
              onChange={(e): void => props.onAmountChange(e, "amountFrom")}
              disabled={props.searchEmpty}
            />
          </WrapAmoutFrom>
          <RangerTextWrap className="col-sm-1 col-md-1">
            <span>~</span>
          </RangerTextWrap>
          <WrapAmoutTo className="col-sm-7 col-md-7">
            <div className="input-group">
              <input
                type="number"
                className="form-control amount-ip"
                value={props.amountTo}
                onChange={(e): void => props.onAmountChange(e, "amountTo")}
                disabled={props.searchEmpty}
              />
              <CheckBoxGroupAddon
                label={i18next.t("transactions.index.searchbox.blankSearch")}
                defaultChecked={props.searchEmpty}
                onChange={props.onSearchEmptyChange}
              />
            </div>
          </WrapAmoutTo>
        </div>
      );
    }
    return (
      <div className="transaction-amount-input">
        <WrapAmoutFrom className="col-sm-4 col-md-4">
          <input
            type="text"
            className="form-control amount-ip validate[required, maxSize[255]]"
            value={props.amountFrom}
            onChange={(e): void => props.onAmountChange(e, "amountFrom")}
            disabled={props.searchEmpty}
          />
        </WrapAmoutFrom>
        <RangerTextWrap className="col-sm-1 col-md-1">
          <span>~</span>
        </RangerTextWrap>
        <WrapAmoutTo className="col-sm-7 col-md-7">
          <div className="input-group">
            <input
              type="text"
              className="form-control amount-ip validate[required, maxSize[255]]"
              value={props.amountTo}
              onChange={(e): void => props.onAmountChange(e, "amountTo")}
              disabled={props.searchEmpty}
            />
            <CheckBoxGroupAddon
              label={i18next.t("transactions.index.searchbox.blankSearch")}
              defaultChecked={props.searchEmpty}
              onChange={props.onSearchEmptyChange}
            />
          </div>
        </WrapAmoutTo>
      </div>
    );
  };

  return <div>{renderAmountRange()}</div>;
};

AmountRange.defaultProps = {
  searchEmpty: false,
  amountFrom: "",
  amountTo: "",
};

export default AmountRange;
