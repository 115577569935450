import * as expenseAccountAction from 'applications/settings/actions/expenseAccount';
import AccordionButton from 'components/accordion_button';
import DeleteConfirmationModal from "components/DeleteConfirmationModal";
import ExpenseAccountForm from './ExpenseAccountForm';
import ExpenseAccountViewer from '../components/ExpenseAccountViewer';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import SimpleModal from 'components/SimpleModal';
import TransferFeeForm from '../components/TransferFeesForm';
import TransferFeeHelpCard from "applications/payment_requests/exports/components/TransferFeeHelpCard";
import getExpenseAccountForm from '../selectors/expenseAccount';
import i18next from 'i18n';
import isNil from 'lodash/isNil';
import styled from 'styled-components';
import {
  OverlayTrigger, Popover, Tab, Tabs,
} from 'react-bootstrap';
import { connect } from 'react-redux';

const Content = styled.div`
  .tab-content {
    margin-top: 0px;
  }
`;

/**
 * 支払口座ページ: 支払口座作成/更新モーダル
 */
export class ExpenseAccountModal extends Component {
  constructor(props) {
    super(props);

    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleDeletionSubmit = this.handleDeletionSubmit.bind(this);
  }

  /**
   * 更新/追加を押した
   */
  handleSubmit() {
    const { inProcess, onSubmit } = this.props;

    if (!this.refs.expenseAccountForm.validateForm() || inProcess) {
      return;
    }

    onSubmit();
  }

  /**
   * 削除を押した
   */
  handleDeletionSubmit() {
    const { openExpenseAccountDeleteConfirmationModal } = this.props;
    openExpenseAccountDeleteConfirmationModal();
  }

  render() {
    const {
      close,
      closeExpenseAccountDeleteConfirmationModal,
      deleteExpenseAccount,
      expenseAccount,
      forCompany,
      inProcess,
      isExpenseAccountDeleteConfirmationModalOpen,
      show,
      transferFees,
      setTransferFees,
    } = this.props;

    const isLockedPersonal = !forCompany && !expenseAccount.editable;
    const buttons = [];
    if (!isNil(expenseAccount.id)) {
      buttons.push({
        color: 'danger',
        className: inProcess ? 'disabled' : '',
        content: i18next.t('commons.actions.delete'),
        onClick: this.handleDeletionSubmit,
      });
    }
    buttons.push({
      color: 'success',
      className: inProcess ? 'disabled' : '',
      content: isNil(expenseAccount.id)
        ? i18next.t('commons.actions.create')
        : i18next.t('commons.actions.update'),
      onClick: this.handleSubmit,
    });

    /**
     * 振込手数料のタイトル欄
     */
    const renderTransferFeeTitle = () => {
      const popover = (
        <Popover id='tab-transfer-fee-help-message'>
          { i18next.t('preferences.expenseAccount.transferFeeHelp') }
        </Popover>
      );
      return (
        <span>
          { i18next.t('preferences.expenseAccount.transferFee') }
          <OverlayTrigger
            trigger={ ['hover', 'focus', 'click'] }
            placement='top'
            overlay={ popover }
          >
            <i
              className='far fa-question-circle'
              style={ { color: '#AAA', marginLeft: '6px', cursor: 'pointer' } }
            />
          </OverlayTrigger>
        </span>
      );
    };

    /**
     * 支払口座モーダルの中身
     */
    const renderExpenseAccountSettingPanel = () => {
      if (isLockedPersonal) {
        return (<ExpenseAccountViewer expenseAccount={ expenseAccount } />);
      }
      if (forCompany && userPreferences.preference.usePaymentRequest) {
        return (
          <Content>
            <Tabs
              id='report-forms-tab-panel'
              className='tab-bar'
              activeKey={ this.props.tabKey }
              onSelect={ this.props.setTabKey }
            >
              <Tab eventKey='account' title={ i18next.t('preferences.expenseAccount.account') }>
                <div style={ { marginTop: '30px' } }>
                  <ExpenseAccountForm
                    ref='expenseAccountForm'
                    forCompany={ forCompany }
                    inProcess={ inProcess }
                    expenseAccount={ expenseAccount }
                  />
                </div>
              </Tab>
              <Tab eventKey='transferFees' title={ renderTransferFeeTitle() }>
                <AccordionButton
                  defaultOpen={ false }
                  renderButton={ () => { return (<span className='text-warning'>{ i18next.t('preferences.expenseAccount.openTransferFeeHelp') }</span>); } }
                >
                  <TransferFeeHelpCard message={ i18next.t('preferences.expenseAccount.transferFeeHelpText') }/>
                </AccordionButton>
                <TransferFeeForm
                  transferFees={ transferFees }
                  setTransferFees={ setTransferFees }
                />
              </Tab>
            </Tabs>
          </Content>
        );
      }
      return (
        <ExpenseAccountForm
          ref='expenseAccountForm'
          forCompany={ forCompany }
          inProcess={ inProcess }
          expenseAccount={ expenseAccount }
        />
      );
    };

    return (
      <div>
        <SimpleModal
          show={ show }
          close={ close }
          title={ forCompany
            ? i18next.t('preferences.titles.companyExpenseAccount')
            : i18next.t('preferences.titles.expenseAccount') }
          buttons={ isLockedPersonal ? null : buttons }
        >
          { renderExpenseAccountSettingPanel() }
        </SimpleModal>
        <DeleteConfirmationModal
          show={ isExpenseAccountDeleteConfirmationModalOpen }
          close={ () => closeExpenseAccountDeleteConfirmationModal() }
          onDelete={ () => deleteExpenseAccount() }
        />
      </div>
    );
  }
}

ExpenseAccountModal.defaultProps = {
  forCompany: false,
  deleteExpenseAccount() {},
};

ExpenseAccountModal.propTypes = {
  close: PropTypes.func.isRequired,
  deleteExpenseAccount: PropTypes.func.isRequired,
  expenseAccount: PropTypes.object.isRequired,
  transferFees: PropTypes.array,
  forCompany: PropTypes.bool.isRequired,
  inProcess: PropTypes.bool.isRequired,
  onSubmit: PropTypes.func.isRequired,
  show: PropTypes.bool.isRequired,
};

function mapStateToProps(state, ownProps) {
  const form = getExpenseAccountForm()(state, ownProps);

  return {
    isExpenseAccountDeleteConfirmationModalOpen: form.toggle.isExpenseAccountDeleteConfirmationModalOpen,
  };
}

function mapDispatchToProps(dispatch, ownProps) {
  return {
    /**
     * 削除ボタンを押した
     */
    openExpenseAccountDeleteConfirmationModal() {
      dispatch(expenseAccountAction.openExpenseAccountDeleteConfirmationModal());
      dispatch(expenseAccountAction.closeExpenseAccountModal());
    },
    /**
     * 削除確認モーダルを閉じる
     */
    closeExpenseAccountDeleteConfirmationModal() {
      dispatch(expenseAccountAction.closeExpenseAccountDeleteConfirmationModal());
    },
    /**
     * タブを選択した
     */
    setTabKey(value) {
      dispatch(expenseAccountAction.setTabKey(value));
    },
    /**
     * 振込手数料を入力した
     */
    setTransferFees(value) {
      dispatch(expenseAccountAction.setTransferFees(value));
    },
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(ExpenseAccountModal);
