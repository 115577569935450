import _ from 'lodash';
import flash from 'utilities/flash';
import i18next from 'i18n';
import { from, generate } from 'rxjs';
import { map, mergeMap } from 'rxjs/operators';
import { snakecaseKeys } from 'utilities/Utils';

/**
 * APIリクエストにエラーした時の処理
 */
export function handleError(e, message) {
  flash.error(message || _.get(e, 'message') || i18next.t('commons.errors.communicationError'));
}

/**
 * 非同期でAPIにリクエストを送信
 */
export function fetchAsync(fetchApi, params, message = null, mute = false, options = {}) {
  const promise = fetchApi(snakecaseKeys(params), options);

  // jQueryとPromiseの仕様差異を解消する
  if (promise.fail) {
    // eslint-disable-next-line no-async-promise-executor
    return new Promise(async (resolve, reject) => {
      try {
        const data = await promise;
        resolve(data);
      } catch (e) {
        if (!mute) {
          handleError(e.responseJSON, message);
        }
        reject(e);
      }
    });
  }

  return promise.catch((e) => {
    if (!mute) {
      handleError(e, message);
    }

    return Promise.reject(e);
  });
}

/**
 * ページネーション対応のAPIに対して、全てのデータを取得する必要がある場合に実行する
 *
 * @note
 *   使用する前に, 叩くAPIが適切かどうか, APIの修正も視野に入れて考えてください
 *   もし使用する場合, このメソッドはオプションによってはサーバに高負荷をかけてしまうので慎重に使用してください
 *
 * @param {fetchApi} fetchApi 叩くAPI
 * @param {hash} params limit, offset を除くAPIへ渡すパラタメータ
 * @param {hash} options 実行時オプション
 *   e.g.
 *     {
 *       limit: 50,          1回のAPIリクエストで取得するデータ数
 *       concurrent: 5,      APIの並列実行数 (429 Too Many Requests 回避のため)
 *       message: 'Err!',    エラー発生時に独自メッセーシを表示したい時に指定
 *       maxFetchSize: 100,  予め取得対象全体の要素数が分かっている場合に指定
 *                           指定すると全体数を取得するための初回APIも並列実行に含まれるため, return が多少早くなる
 *     }
 * @return {Promise} データのフェッチに関する promise を返す
 */
export async function fetchAsyncAll(fetchApi, params, {
  limit = 50, concurrent = 5, message = null, maxFetchSize = null,
} = {}) {
  let data = [];
  let count = 0;

  if (maxFetchSize === null) {
    try {
      const response = await fetchApi(snakecaseKeys({ ...params, offset: 0, limit }));
      data = response.data;
      count = response.count;
    } catch (e) {
      handleError(e.responseJSON, message);
      throw e;
    }
  } else {
    count = maxFetchSize;
  }

  const results = [data];
  const offset = data.length;
  const apiResults = [];

  const eventSource$ = generate(offset, (x) => x < count, (x) => x + limit)
    .pipe(
      map((x) => (snakecaseKeys({ ...params, offset: x, limit }))),
      mergeMap(
        (apiParams) => from(fetchApi(apiParams)),
        (apiParams, response) => {
          return {
            data: response.data,
            offset: apiParams.offset, // offset順に取得結果を並び替えるために使用する
          };
        },
        concurrent,
      ),
    );

  const orderedResults = () => {
    return apiResults.sort((a, b) => {
      if (a.offset < b.offset) {
        return -1;
      }
      if (a.offset > b.offset) {
        return 1;
      }
      return 0;
    });
  };

  const mergeApiResults = () => {
    orderedResults();

    apiResults.forEach((apiResult) => {
      results.push(apiResult.data);
    });

    return results.flat();
  };

  return new Promise((resolve, reject) => {
    eventSource$.subscribe(
      (result) => { apiResults.push(result); },
      (e) => { handleError(e.responseJSON, message); reject(e); },
      () => resolve({ count, data: mergeApiResults() }),
    );
  });
}
