import i18next from "i18n";
import React, { FC } from "react";
import styled from "styled-components";
import { InvoicingOrganization } from "utilities/api_managements/models/InvoicingOrganization";
import Formatter from "utilities/formatter";

interface Props {
  invoicingOrganization: InvoicingOrganization;
}

const NonStyleUl = styled.ul`
  list-style: none;
  white-space: pre-wrap;
  padding-left: 0;
  margin-bottom: 0;
`;

export const InvoicingOrganizationDetailView: FC<Props> = ({
  invoicingOrganization,
}) => {
  return (
    <NonStyleUl>
      <li>{invoicingOrganization.name}</li>
      {invoicingOrganization.address !== null && (
        <li>{invoicingOrganization.address}</li>
      )}
      <li>
        {i18next.t(
          "managements.invoicingOrganizations.properties.registrationDate",
        )}
        :{" "}
        {Formatter.date(invoicingOrganization.registrationDate, {
          emptyText: "",
        })}
      </li>
      <li>
        {i18next.t("managements.invoicingOrganizations.properties.updateDate")}:{" "}
        {Formatter.date(invoicingOrganization.updateDate, { emptyText: "" })}
      </li>
    </NonStyleUl>
  );
};
