import Api from 'utilities/api';
import React, { useEffect, useState } from 'react';
import Select from 'react-select';
import i18next from 'i18n';
import styled from 'styled-components';
import {
  Alert,
  ControlLabel,
  FormGroup,
  HelpBlock,
} from 'react-bootstrap';
import { Controller, FieldErrors, useFormContext } from 'react-hook-form';
import { Executor } from 'utilities/api/models/accountingDataScheduledExports';
import { RequiredIcon } from 'applications/accounting_data_scheduled_exports/components/icons/RequiredIcon';

const FormGroupView = styled(FormGroup)`
  .form-control:focus {
    outline: none;
    box-shadow: none;
    border: 2px solid #004acc;
    background-color: #ffffff;
  }
  &.has-error div[class$="-control"] {
    border-color: #d9534f;
    box-shadow: none;
  }
  &.has-error .control-label {
    color: #d9534f;
  }
  &.has-error .help-block {
    color: #d9534f;
  }
`;

type ValidationState = 'error' | 'warning' | null | undefined;

const validationState = (error: FieldErrors | undefined): ValidationState => {
  return error ? 'error' : null;
};

export const ExecutorSelectField: React.FC = () => {
  const { control } = useFormContext();

  const [processing, setProcessing] = useState<boolean>(false);
  const [executors, setExecutors] = useState<Executor[]>([]);

  useEffect(() => {
    const fetchExecutors = async (): Promise<void> => {
      setProcessing(true);

      try {
        const res = await Api.accountingDataScheduledExports.executors.index();
        setExecutors(res.data);
      } catch (e) {
        // nothing to do
      } finally {
        setProcessing(false);
      }
    };
    fetchExecutors();
  }, [setProcessing, setExecutors]);

  const formatOptionLabel = ({ name, email }): JSX.Element => (
    <div>
      <div>{ name }</div>
      <div style={ { color: '#666', fontSize: '12px' } }>{ email }</div>
    </div>
  );

  if (processing) {
    return (<div>{ i18next.t('accountingDataScheduledExports.tasks.forms.export.executor.loading') }</div>);
  }

  if (executors.length === 0) {
    return (<Alert bsStyle='danger'>{ i18next.t('accountingDataScheduledExports.tasks.forms.export.executor.notFound') }</Alert>);
  }

  return (
    <Controller
      name='executor'
      rules={ { required: i18next.t<string>('accountingDataScheduledExports.tasks.forms.export.executor.required') } }
      control={ control }
      render={ ({ field: { onChange, value, ref: inputRef }, fieldState: { error } }): React.ReactElement => (
        <FormGroupView validationState={ validationState(error) }>
          <ControlLabel>{ i18next.t('accountingDataScheduledExports.tasks.forms.export.executor.label') } <RequiredIcon /></ControlLabel>
          <Select
            inputRef={ inputRef }
            options={ executors }
            value={ executors?.find((c) => c.name === (value as Executor)?.name) || null }
            formatOptionLabel={ formatOptionLabel }
            filterOption={ ({ data }, inputValue: string): boolean => data.name.includes(inputValue) || data.email.includes(inputValue) }
            getOptionValue={ (v: Executor): string => v.id }
            onChange={ onChange }
            isClearable
          />
          { error && <HelpBlock>{ error.message }</HelpBlock> }
        </FormGroupView>
      ) }
    />
  );
};
