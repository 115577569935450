import i18next from "i18next";
import React from "react";
import styled from "styled-components";
import { OperatorEntry } from "utilities/api_expenses/models/expenses/OperatorEntry";
import formatter from "utilities/formatter";

interface Props {
  readonly operatorEntry: OperatorEntry;
}

const OperatorEntryInfoView = styled.details`
  padding: 6px;
  border-radius: 4px;
  border: 1px solid #ccc;
  background-color: #f4f4f4;
  color: #666;
  font-size: 0.9em;
  font-weight: bold;
  cursor: pointer;
  margin-bottom: 6px;

  span.tax-rate {
    border: 1px solid #ccc;
    border-radius: 16px;
    padding: 0 8px;
    font-size: 0.8em;
    line-height: 16px;
  }
  summary .tax-rate {
    margin-left: 6px;
  }

  &:hover {
    background-color: #f0f8ff;
  }

  [open] summary {
    &:before {
      font-family: "Font Awesome 5 Free";
      content: "\f106";
      display: inline-block;
      padding-right: 4px;
      padding-left: 4px;
      vertical-align: middle;
      font-weight: 900;
    }
  }

  summary:after {
    font-family: "Font Awesome 5 Free";
    content: "\f107";
    display: inline-block;
    padding-right: 4px;
    padding-left: 4px;
    vertical-align: middle;
    font-weight: 900;
    float: right;
  }

  table {
    margin: 8px;
    td {
      padding-left: 4px;
      span.total {
        font-size: 0.8em;
        padding: 0 8px;
      }
      .amount {
        text-align: right;
        padding: 0 8px;
      }
    }
  }
`;

const I18N_PREFIX = "expenses.expenses.components.operatorEntryInfo";

export const OperatorEntryInfo: React.FC<Props> = ({ operatorEntry }) => {
  return (
    <OperatorEntryInfoView>
      <summary>
        <span>
          <i
            className="material-icons"
            style={{
              fontSize: 14,
              marginLeft: 2,
              marginRight: 2,
              verticalAlign: "text-top",
            }}
          >
            cloud_done
          </i>
        </span>
        {i18next.t(`${I18N_PREFIX}.title`)}
        {operatorEntry.subtotals.map((subtotal, index) => (
          <span key={index} className="tax-rate">
            {subtotal.taxRate * 100}%
          </span>
        ))}
      </summary>
      <table>
        <tbody>
          {operatorEntry.subtotals.map((subtotal, index) => (
            <tr key={index}>
              <td>
                <span className="tax-rate">{subtotal.taxRate * 100}%</span>
              </td>
              <td className="amount">{formatter.amount(subtotal.amount)}</td>
              <td className="amount">
                ({formatter.amount(subtotal.taxAmount)})
              </td>
            </tr>
          ))}
          <tr>
            <td>
              <span className="total">{i18next.t(`${I18N_PREFIX}.total`)}</span>
            </td>
            <td className="amount">{formatter.amount(operatorEntry.total)}</td>
            <td></td>
          </tr>
        </tbody>
      </table>
    </OperatorEntryInfoView>
  );
};
