import PropTypes from 'prop-types';
import React from 'react';
import i18next from 'i18n';
import { Button } from 'react-bootstrap';

/**
 * 領収書原本一覧ページ検索欄: 検索ボタン
 */
const SearchButton = (props) => {
  const className = `${props.className} ${props.inProcess ? 'disabled' : ''}`;

  return (
    <Button
      className={ className }
      onClick={ props.handleClick }
      disabled={ props.disabled }
      bsStyle='success'
    >
      { props.inProcess
        ? <i className='fa fa-spinner fa-spin'></i>
        : i18next.t('commons.actions.search')
      }
    </Button>
  );
};

SearchButton.defaultProps = {
  className: '',
  inProcess: false,
  disabled: false,
};

SearchButton.propTypes = {
  className: PropTypes.string,
  inProcess: PropTypes.bool,
  handleClick: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
};

export default React.memo(SearchButton);
