import Formatter from 'utilities/formatter';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import isNil from 'lodash/isNil';
import moment from 'moment';
import toInteger from 'lodash/toInteger';

export default class TimeSelector extends Component {
  constructor(props) {
    super(props);

    this.handleHourChange = this.handleHourChange.bind(this);
    this.handleMinuteChange = this.handleMinuteChange.bind(this);
  }

  handleHourChange(value) {
    if (isNil(value)) {
      this.props.onHourChange(0);
      return;
    }

    const hour = toInteger(value) || 0;

    if (hour >= 0 && hour <= 23) {
      this.props.onHourChange(hour);
      // input.valueの先頭に0が付くのを防ぐ https://github.com/facebook/react/issues/11021
      this.hour.value = hour;
    }
  }

  handleMinuteChange(value) {
    if (isNil(value)) {
      this.props.onMinuteChange(0);
      return;
    }

    const minute = toInteger(value) || 0;

    if (minute >= 0 && minute <= 59) {
      this.props.onMinuteChange(minute);
      // input.valueの先頭に0が付くのを防ぐ https://github.com/facebook/react/issues/11021
      this.minute.value = minute;
    }
  }

  render() {
    const {
      hour, minute, style, className, disabled,
    } = this.props;

    if (disabled && !isNil(hour) && !isNil(minute)) {
      const dateTime = moment().hour(hour).minute(minute);

      return (
        <span className={ className } style={ style }>{ Formatter.text(dateTime.format('HH:mm')) }</span>
      );
    }

    return (
      <div className={ className } style={ style }>
        <div className="col-sm-5" style={ { marginLeft: '0px', paddingLeft: '0px', paddingRight: '0px' } }>
          <input
            type='number'
            ref={ (input) => { this.hour = input; } }
            className='form-control'
            min={ 0 } max={ 23 } step={ 1 }
            value={ hour }
            onChange={ (e) => this.handleHourChange(e.target.value) }/>
        </div>
        <div className="col-sm-1" style={ { paddingTop: '5px', paddingLeft: '5px', paddingRight: '5px' } }>
          { '  :  ' }
        </div>
        <div className="col-sm-5" style={ { paddingLeft: '0px', paddingRight: '0px' } }>
          <input
            type='number'
            ref={ (input) => { this.minute = input; } }
            className='form-control'
            min={ 0 } max={ 59 } step={ 1 }
            value={ minute }
            onChange={ (e) => this.handleMinuteChange(e.target.value) }/>
        </div>
      </div>
    );
  }
}

TimeSelector.propTypes = {
  hour: PropTypes.number,
  minute: PropTypes.number,
  onHourChange: PropTypes.func.isRequired,
  onMinuteChange: PropTypes.func.isRequired,
  disabled: PropTypes.bool.isRequired,
  className: PropTypes.string,
  style: PropTypes.object,
};

TimeSelector.defaultProps = {
  onHourChange() {},
  onMinuteChange() {},
  disabled: false,
};
