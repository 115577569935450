import Api from 'utilities/api_payment_requests';
import InfiniteSelector from 'components/fields/InfiniteSelector';
import React, { useCallback } from 'react';
import SearchBox from 'components/SearchBox';
import SearchResult from 'components/SearchResult';
import i18next from 'i18n';
import { DebitInvoiceCategory } from 'utilities/api_payment_requests/models/DebitInvoiceCategory';
import { InvoiceCategoryOption, SelectorValue, useInvoiceCategorySelectorState } from './payment_requests/hooks/useInvoiceCategorySelectorState';
import { OptionsWithIsSelected, SelectAllCheckBox } from 'components/SelectAllCheckBox';

interface Props {
  /** 複数選択可能か */
  isMultiSelectable: boolean;
  /** 選択時のハンドラ */
  onSelect: () => void;
  /** 全選択時のハンドラ */
  onSelectAll?: (checked: boolean, options: OptionsWithIsSelected, displayedOptionIds: string[]) => void;
  /** 全選択可能か */
  isAllSelectable?: boolean;
  /** 子カテゴリのみか */
  onlyChildCategories: boolean;
  /** 返却する値(複数選択可能の場合は配列) */
  value: SelectorValue | SelectorValue[];
  style: React.CSSProperties;
}

/** 借方請求明細科目選択用コンポーネント */
export const DebitInvoiceCategorySelector: React.FC<Props> = ({
  isMultiSelectable, onSelect, onlyChildCategories, value, onSelectAll, isAllSelectable = false, style,
}) => {
  const {
    searchText,
    filteredOptions,
    totalCount,
    setSearchText,
    search,
    onOptionsChange: handleOptionsChange,
  } = useInvoiceCategorySelectorState<DebitInvoiceCategory>({
    fetcher: Api.debitInvoiceCategories.index,
    isMultiSelectable,
    onlyChildCategories,
    value,
  });

  /** 項目表示 */
  const formatOption = useCallback((option: InvoiceCategoryOption): JSX.Element => {
    return (
      <div className='list-selector-item-content'>
        <div>{ option?.name }</div>
      </div>
    );
  }, []);

  return (
    <InfiniteSelector
      style={ style || {} }
      responseKey='categories'
      isMultiSelectable={ isMultiSelectable }
      options={ filteredOptions || [] }
      selection={ value }
      totalCount={ totalCount }
      rowHeight={ 42 }
      formatOption={ formatOption }
      fetchOptions={ search }
      onSelect={ onSelect }
      onOptionsChange={ handleOptionsChange }
      onSelectAll={ onSelectAll }
      isAllSelectable={ isAllSelectable }
    >
      {(renderSelector, { onOptionsReset, options, handleAllOptionSelect }): JSX.Element => {
        return (
          <div className='category-selector'>
            <SearchBox value={ searchText }
              placeholder={ i18next.t('paymentRequests.debitInvoiceCategory.actions.searchPlaceholder') }
              onChange={ setSearchText }
              onClick={ onOptionsReset }
            />
            <SearchResult count={ totalCount }>
              {
                isAllSelectable && (
                  <SelectAllCheckBox
                    options={ options }
                    onSelectAll={ handleAllOptionSelect }
                  />
                )
              }
              { renderSelector() }
            </SearchResult>
          </div>
        );
      }}
    </InfiniteSelector>
  );
};
