import PropTypes from 'prop-types';
import React, { Component } from 'react';
import get from 'lodash/get';

export default class SelectDepartmentPostModal extends Component {
  render() {
    const { departmentPosts } = this.props;
    return (
      <div>
        {
          departmentPosts.map((department) => (
            <div key={ department.id } className="approve-selector-wrapper">
              <div className="approve-selector txt-pointer col-md-12"
                onClick={ () => this.props.onSelectDepartment(department, !department.isChecked) }>
                <div>
                  { this.renderParentDepartments(department.parents) }
                  <div>{ this.renderDepartmentName(department) }</div>
                </div>
              </div>
              { (department.isChecked)
                ? department.posts.map((post) => (
                  <div key={ post.postId } className="approval-department-post-selector txt-pointer col-md-12"
                    onClick={ () => this.props.onSelectDepartmentPost(department, post, !post.isChecked) }>
                    <div>{ `┗ ${post.name}` }</div>
                    <input type="checkbox" id={ `check-${post.postId}` } checked={ post.isChecked } readOnly/>
                  </div>
                )) : null
              }
            </div>
          ))
        }
      </div>
    );
  }

  renderDepartmentName(department) {
    const isSelectedPost = department.posts.some((p) =>  get(p, 'isChecked', false));

    if (isSelectedPost) {
      return (
        <div>
          { `${department.name}(${department.posts.filter((post) => get(post, 'isChecked', false)).map((post) => post.name).join(', ')})` }
        </div>
      );
    }

    return (
      <div>{ department.name }</div>
    );
  }

  /**
   * 親部署を表示します
   * @param {Array} parents 親部署のリスト
   * @returns
   */
  renderParentDepartments(parents) {
    if (parents.length === 0) return null;

    return (<div style={ { fontSize: '12px', color: '#999' } }>{ parents.map((parent) => (parent.name)).join('/') }</div>);
  }
}

SelectDepartmentPostModal.propTypes = {
  departmentPosts: PropTypes.array.isRequired,
  onSelectDepartment: PropTypes.func,
  onSelectDepartmentPost: PropTypes.func,
};
