import React from 'react';
import styled from 'styled-components';

interface Props {
  onScroll: (e: React.UIEvent<HTMLDivElement>) => void;
  offsetHeight?: number;
}

const FormView = styled.div<{ offsetHeight: number; }>`
  overflow: scroll;
  height: calc(100vh${({ offsetHeight }): string => ` - ${offsetHeight}px`});
  position: relative;
`;

export const ScrollableForm = React.forwardRef<HTMLDivElement, React.ReactNode & Props>(({ onScroll, offsetHeight = 140, children }, ref) => {
  return (
    <FormView ref={ ref } className='col-sm-9' onScroll={ onScroll } offsetHeight={ offsetHeight }>
      { children }
    </FormView>
  );
});
