import PropTypes from 'prop-types';
import React, { Component } from 'react';
import SimpleModal from 'components/SimpleModal';
import colors from 'values/colors';
import i18next from 'i18n';
import styled from 'styled-components';

const FieldWithInputModalView = styled.div`
  .input-field {
    text-align: center;
    transition: background 0.3s;
    word-wrap: break-word;
    overflow: auto;
    height: auto;

    &--active:hover {
      background: ${colors.whiteHover};
      cursor: pointer;
    }

    &.disabled {
      cursor: auto;
      background: ${colors.whiteHover};
    }
  }
`;

/**
 * 入力フィールドの内、実際の入力はモーダルによって行うComponent
 * モーダルの開閉処理を担当する
 */
export default class FieldWithInputModal extends Component {
  constructor(props) {
    super(props);

    this.state = { isModalOpen: false };

    this.openModal = this.openModal.bind(this);
    this.closeModal = this.closeModal.bind(this);
  }

  get baseClassName() {
    return this.props.className || 'field-with-input-modal';
  }

  get fieldProps() {
    return {
      className: `input-field input-field--${this.props.isStatic ? 'static' : 'active'}`,
      onClick: this.props.isStatic ? null : this.openModal,
    };
  }

  openModal() {
    if (this.props.setShowModal) {
      this.props.setShowModal(true)
    } else {
      this.setState({ isModalOpen: true });
    }
  }

  closeModal() {
    if (this.props.setShowModal) {
      this.props.setShowModal(false)
    } else {
      this.setState({ isModalOpen: false });
    }
  }

  render() {
    const buttons = this.props.showCloseButton ? [{
      content: i18next.t('commons.actions.close'),
      onClick: this.closeModal,
    }] : [];

    return (
      <FieldWithInputModalView className={ this.baseClassName } style={ this.props.style }>
        { this.props.renderField(this.fieldProps) }

        {
          !this.props.isStatic && (
            <SimpleModal title={ this.props.modalTitle }
              show={ this.props.show || this.state.isModalOpen }
              close={ this.closeModal }
              size={ this.props.modalSize }
              buttons={ buttons }
            >
              { this.props.renderModalBody() }
            </SimpleModal>
          )
        }
      </FieldWithInputModalView>
    );
  }
}

FieldWithInputModal.defaultProps = {
  isStatic: false,
  showCloseButton: false,
  renderField(props) { return null; },
  renderModalBody() { return null; },
  style: {},
};

FieldWithInputModal.propTypes = {
  className: PropTypes.string,
  isStatic: PropTypes.bool.isRequired,
  showCloseButton: PropTypes.bool.isRequired,
  // モーダルが閉じている際に表示されている部分（フィールド）を描画する
  renderField: PropTypes.func.isRequired,
  renderModalBody: PropTypes.func.isRequired,
  modalTitle: PropTypes.string,
  modalSize: PropTypes.oneOf(['xs', 'sm', 'md', 'lg']),
  style: PropTypes.object,
  show: PropTypes.bool,
  setShowModal: PropTypes.func,
};
