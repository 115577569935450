import React from 'react';
import { DragObjectWithType, useDrag, useDrop } from 'react-dnd';
import { Row as RowProps } from 'react-table';

interface UseReturn<Data> {
  readonly dropTrRef: React.RefObject<HTMLTableRowElement>;
  readonly dropARef: React.RefObject<HTMLAnchorElement>;
  readonly dragTdRef: React.RefObject<HTMLTableCellElement>;
  readonly isDragging: boolean;
  readonly handleRowClick: (id: string, selectedData: Data) => void;
}

/**
 * ロジック: DraggableRow
 */
export const useHooks = <Data>(
  row: RowProps,
  prepareRow: (row: RowProps) => void,
  index: number,
  onMove: (dragIndex: number, targetIndex: number) => Promise<void>,
  onRowClick?: ((data: Data) => void),
  href?: string,
): UseReturn<Data> => {
  const DND_ITEM_TYPE = 'row';
  const dropTrRef: React.RefObject<HTMLTableRowElement> = React.useRef<HTMLTableRowElement>(null);
  const dropARef: React.RefObject<HTMLAnchorElement> = React.useRef<HTMLAnchorElement>(null);
  const dragTdRef: React.RefObject<HTMLTableCellElement> = React.useRef<HTMLTableCellElement>(null);

  const [, drop] = useDrop({
    accept: DND_ITEM_TYPE,
    drop(item: DragObjectWithType & { index: number }) {
      if (!href ? !dropTrRef.current : !dropARef.current) return;

      // ドラッグ中の要素のIndex
      const dragIndex = item.index;
      // ドロップ対象の要素のIndex
      const dropIndex = index;

      if (dragIndex === dropIndex) return;

      // 順番を入れ替える為に引数の値を書き換えているが、公式ドキュメントの実装なので許容
      // eslint-disable-next-line no-param-reassign
      item.index = dropIndex;

      onMove(dragIndex, dropIndex);
    },
  });

  const [{ isDragging }, drag, preview] = useDrag({
    item: {
      type: DND_ITEM_TYPE,
      index,
    },
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
    }),
  });

  preview(drop(!href ? dropTrRef : dropARef));

  drag(dragTdRef);

  /**
   * 行をクリック
   */
  const handleRowClick = (id: string, selectedData: Data): void => {
    if (!onRowClick || id === 'selection') return;

    onRowClick(selectedData);
  };

  prepareRow(row);

  return {
    dropTrRef,
    dropARef,
    dragTdRef,
    isDragging,
    handleRowClick,
  };
};
