import CheckBoxGroupAddon from "components/CheckBoxGroupAddon";
import i18next from "i18n";
import isFunction from "lodash/isFunction";
import React, { FC, FormEvent } from "react";
import {
  ControlLabel,
  FormControl,
  FormControlProps,
  FormGroup,
} from "react-bootstrap";

export interface TextSearchFormProps
  extends Pick<FormControlProps, "className"> {
  label: string | JSX.Element;
  disabled?: boolean;
  textFormValue: string | undefined;
  checkboxValue?: boolean | false;
  onChangeTextForm: (value: string) => void;
  onChangeCheckbox?: (value: boolean) => void;
}

const TextSearchForm: FC<TextSearchFormProps> = ({ label, ...props }) => {
  const handleChangeTextForm = (
    e: FormEvent<FormControl & HTMLInputElement>,
  ): void => {
    props.onChangeTextForm(e.currentTarget.value);
  };

  const handleCheckboxChange = (value: boolean): void => {
    if (isFunction(props.onChangeCheckbox)) props.onChangeCheckbox(value);
  };

  const inputFormContent = (): JSX.Element => {
    if (isFunction(props.onChangeCheckbox)) {
      return (
        <div className="input-group">
          <FormControl
            disabled={props.disabled || props.checkboxValue === true}
            className="wrap-form"
            type="text"
            value={props.textFormValue}
            onChange={handleChangeTextForm}
          />
          <CheckBoxGroupAddon
            label={i18next.t("transactions.index.searchbox.blankSearch")}
            defaultChecked={props.checkboxValue}
            onChange={handleCheckboxChange}
          />
        </div>
      );
    }

    return (
      <FormControl
        className="wrap-form"
        type="text"
        value={props.textFormValue}
        onChange={handleChangeTextForm}
      />
    );
  };

  return (
    <FormGroup>
      <ControlLabel>{label}</ControlLabel>
      {inputFormContent()}
    </FormGroup>
  );
};

export default TextSearchForm;
