import { Bank } from "components/fields/payeeAccounts/types";
import React, { useEffect, useState } from "react";
import { ControlLabel, FormGroup } from "react-bootstrap";
import { Controller, useFormContext } from "react-hook-form";
import Select from "react-select";
import Api from "utilities/api";

interface Props {
  name: string;
  label?: string | JSX.Element;
  controlCustomStyles?: Record<symbol, string>;
}

const formatOptionLabel = ({ name: depName, absolutePath }): JSX.Element => (
  <div>
    <div style={{ color: "#666", fontSize: "12px" }}>{absolutePath}</div>
    <div>{depName}</div>
  </div>
);

export const BankSelectField: React.FC<Props> = ({
  name,
  label,
  controlCustomStyles,
}) => {
  const { control } = useFormContext();
  const [processing, setProcessing] = useState<boolean>(false);
  const [banks, setBanks] = useState<Bank[]>([]);

  useEffect(() => {
    const fetchBanks = async (): Promise<void> => {
      setProcessing(true);

      try {
        const res = await Api.banks.index();
        setBanks(res.banks);
      } catch (e) {
        // nothing to do
      } finally {
        setProcessing(false);
      }
    };
    fetchBanks();
  }, []);

  if (processing) {
    return <div>金融機関名を読込中...</div>;
  }

  return banks.length > 0 ? (
    <Controller
      name={name}
      control={control}
      render={({
        field: { onChange, value, ref: inputRef },
      }): React.ReactElement => (
        <FormGroup>
          {label && <ControlLabel>{label}</ControlLabel>}
          <Select
            inputRef={inputRef}
            options={banks}
            value={banks.find((v) => v.id === (value as Bank)?.id)}
            formatOptionLabel={formatOptionLabel}
            getOptionValue={(v: Bank): string => v.id || ""}
            getOptionLabel={(v: Bank): string => v.name}
            onChange={onChange}
            isClearable
            styles={{
              control: (baseStyles): void => ({
                ...baseStyles,
                ...(controlCustomStyles || {}),
              }),
            }}
          />
        </FormGroup>
      )}
    />
  ) : (
    <></>
  );
};
