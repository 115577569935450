import { ListForm } from "components/renewaled_ui/form_views";
import React from "react";
import AmountPerTaxCategoryTable from "../../../AmountPerTaxCategoryTable";
import { AmountPerTaxCategoryWithoutTax } from "../interface";

interface Props {
  readonly label: string;
  readonly values: AmountPerTaxCategoryWithoutTax[];
  readonly isShowNameColumn: boolean;
}

export const ReadOnlyTableWithoutTaxAmount: React.FC<Props> = ({
  label,
  values,
  isShowNameColumn,
}) => {
  return (
    <ListForm label={label}>
      <AmountPerTaxCategoryTable
        values={values}
        showName={isShowNameColumn}
        editable={false}
        onAmountChange={(): void => void 0}
      />
    </ListForm>
  );
};
