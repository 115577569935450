import PropTypes from "prop-types";
import React, { Component } from "react";
import { renderComponentWithTab } from "utilities/tab_renderer";

export default class AccountList extends Component {
  tabFormatter(group) {
    return (
      <span>
        <span className="group-name">{group.name}</span>
        <span className="group-length">{group.accounts.length}</span>
      </span>
    );
  }

  renderGroupInfo(group) {
    let icon = null;
    if (AccountList.groupIcon[group]) {
      icon = <i className={`fas ${AccountList.groupIcon[group]}`}></i>;
    }

    return (
      <span className="account-group">
        {icon}
        {group}
      </span>
    );
  }

  renderAccount(account, idx) {
    return (
      <div className="col-sm-4 account-link-box" key={`account-${idx}`}>
        <div
          className="account-link"
          onClick={(e) => {
            this.props.actions.onClickAccountLink(e, account);
          }}
        >
          <div>
            <span className="account-name">{account.name}</span>
            <br />
            {this.renderGroupInfo(account.group)}
          </div>
          <div>
            <span>
              <i className="fa fa-chevron-right"></i>
            </span>
          </div>
        </div>
      </div>
    );
  }

  renderAccountList(group, idx) {
    return group.accounts.map(this.renderAccount.bind(this));
  }

  render() {
    return (
      <div>
        {renderComponentWithTab(
          this.props.groups,
          this.renderAccountList.bind(this),
          this.props.onClickTab,
          "account",
          this.tabFormatter,
        )}
        <div className="row" key="space"></div>
      </div>
    );
  }
}

AccountList.groupIcon = {
  電子マネー: "fa-mobile-alt",
  カード: "fa-credit-card",
};

AccountList.defaultProps = {
  onClickTab() {},
};

AccountList.propTypes = {
  groups: PropTypes.array.isRequired,
  onClickTab: PropTypes.func.isRequired,
  actions: PropTypes.object,
};
