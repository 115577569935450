import React, {
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';
import formatter from 'utilities/formatter';
import i18next from 'i18next';
import { DetailDrawer } from './DetailDrawer';
import { FileTransferLocation } from 'utilities/api/models/accountingDataScheduledExports';
import { Table } from 'components/renewaled_ui/Table';

const BASE_PATH = '/accounting_data_scheduled_exports/file_transfer_locations';

interface Props {
  fileTransferLocations: FileTransferLocation[];
  defaultSelectedId?: string;
  isLoading: boolean;
  onDeleted: () => void;
  onUpdated: () => void;
}

const protocolLabels = {
  sftp: 'SFTP',
  ftp: 'FTP',
};

export const FileTransferLocationTable: React.FC<Props> = ({
  defaultSelectedId,
  fileTransferLocations,
  isLoading,
  onDeleted,
  onUpdated,
}) => {
  const [selectedId, setSelectedId] = useState<string | null>(defaultSelectedId || null);
  const [showDetailModal, setShowDetailModal] = useState<boolean>(!!defaultSelectedId);

  useEffect(() => {
    if (selectedId) {
      window.history.replaceState(null, '', `${BASE_PATH}/${selectedId}`);
    } else {
      window.history.replaceState(null, '', BASE_PATH);
    }
  }, [selectedId]);

  const handleRowClick = useCallback((fileTransferLocation: FileTransferLocation) => {
    setSelectedId(fileTransferLocation.id);
    setShowDetailModal(true);
  }, [setSelectedId, setShowDetailModal]);

  const handleCloseDetailModal = useCallback(() => {
    setShowDetailModal(false);
    setSelectedId(null);
  }, [setSelectedId, setShowDetailModal]);

  const handleDeleted = useCallback(() => {
    setShowDetailModal(false);
    setSelectedId(null);
    onDeleted();
  }, [setSelectedId, setShowDetailModal, onDeleted]);

  const columns = useMemo(() => [
    {
      accessor: 'protocol',
      Header: i18next.t('accountingDataScheduledExports.fileTransferLocations.table.protocol'),
      Cell: ({ value }): string => protocolLabels[value],
      width: 100,
    },
    {
      accessor: 'name',
      Header: i18next.t('accountingDataScheduledExports.fileTransferLocations.table.name'),
      Cell: ({ value }): string => formatter.text(value),
      width: 300,
    },
    {
      accessor: 'description',
      Header: i18next.t('accountingDataScheduledExports.fileTransferLocations.table.description'),
      Cell: ({ value }): string => formatter.text(value),
      width: window.innerWidth - 170 - (16 * 2) - 400,
    },
  ], []);

  return (
    <>
      <Table<FileTransferLocation>
        data={ fileTransferLocations }
        onRowClick={ handleRowClick }
        showCheckBoxes={ false }
        columns={ columns }
        columnResizable={ false }
        isLoading={ isLoading }
      />
      {
        selectedId && (
          <DetailDrawer
            show={ showDetailModal }
            selectedId={ selectedId }
            onClose={ handleCloseDetailModal }
            onDeleted={ handleDeleted }
            onUpdated={ onUpdated }
          />
        )
      }
    </>
  );
};
