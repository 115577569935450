import React from 'react';
import i18next from 'i18n';
import styled from 'styled-components';
import { TableDropdown } from "components/renewaled_ui/DataFetchingTable/molecules/simpleMode/TableDropdown";

const Wrapper = styled.span`
  font-size: 12px;
  user-select: none;
  margin-left: 20px;
`;

interface Props {
  /** 表示件数 */
  readonly selectedItemsLength: number;
  /** 選択した項目の操作ドロップダウン内に表示するDOM */
  readonly renderSelectedItemsControlMenu?: () => JSX.Element;
}

/**
 * 選択している件数メニュー
 */
export const SelectedItemsMenu: React.FC<Props> = (props) => {
  // １件も選択されていない場合は非表示
  if (!props.selectedItemsLength) return <></>;

  // 操作メニューを描画しない場合は表示件数テキストのみ表示
  if (!props.renderSelectedItemsControlMenu) {
    return (
      <Wrapper>
        { props.selectedItemsLength } { i18next.t('commons.dataFetchTable.itemsAreSelected') }
      </Wrapper>
    );
  }

  return (
    <TableDropdown selectedItemsLength={ props.selectedItemsLength }>
      { props.renderSelectedItemsControlMenu() }
    </TableDropdown>
  );
};
