import Formatter from 'utilities/formatter';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import get from 'lodash/get';
import i18next from 'i18n';
import { BootstrapTable, TableHeaderColumn } from 'react-bootstrap-table';
import { snakecaseKeys } from 'utilities/Utils';

/**
 * 従業員毎集計テーブル
 */
export default class UserAnalysisTable extends Component {
  /**
   * CSVダウンロードのボタンを押した
   */
  downloadCSVFromUserId(outerUserId) {
    return (e) => {
      const userId = outerUserId;
      const { analysisId } = this.props;
      const param = $.param(snakecaseKeys({
        transactedAt: new Date().toISOString(),
        userId,
        analysisId,
      }));
      window.location.href = `/exports/csv?${param}`;
      e.stopPropagation();
    };
  }

  render() {
    const options = {
      noDataText: 'データがありません',
      onRowClick: this.props.onRowClick.bind(this),
      sizePerPageList: [10, 30, 50],
      sizePerPage: 10,
    };

    const summaries = this.props.summariesByUser.map((summary) => {
      const sum = summary;
      sum.userId = get(summary, 'user.id');
      sum.userName = get(summary, 'user.name');
      sum.employeeId = get(summary, 'user.employeeId');
      return sum;
    });

    return (
      <BootstrapTable
        data={ summaries }
        options={ options }
        striped={ true }
        pagination={ true }
      >
        <TableHeaderColumn
          dataField="userId"
          hidden={ true }
          isKey={ true }
        >
          userId
        </TableHeaderColumn>
        <TableHeaderColumn
          dataField="employeeId"
          dataSort={ true }
          width="120"
        >
          社員番号
        </TableHeaderColumn>
        <TableHeaderColumn
          dataField="userName"
          dataSort={ true }
          width="200"
        >
          従業員名
        </TableHeaderColumn>
        <TableHeaderColumn
          dataField='account'
          dataFormat={ this.expenseAccountFormat.bind(this) }
          width="150"
        >
          { i18next.t('paymentRequests.analysisDetail.aggregationPerPayee.memberTable.account') }
        </TableHeaderColumn>
        <TableHeaderColumn
          dataField="count"
          dataSort={ true }
          width="80"
        >
          経費数
        </TableHeaderColumn>
        <TableHeaderColumn
          dataField="sum"
          dataFormat={ (v) => (Formatter.amount(v)) }
          dataSort={ true }
          dataAlign="right"
          width="150"
        >
          合計金額
        </TableHeaderColumn>
        <TableHeaderColumn
          dataField="paidSum"
          dataFormat={ (v) => (Formatter.amount(v)) }
          dataSort={ true }
          dataAlign="right"
          width="150"
        >
          振込金額
        </TableHeaderColumn>
        {
          this.props.isDownloadable
            ? (
              <TableHeaderColumn
                dataField="userId"
                dataFormat={ this.downloadButtonFormatter.bind(this) }
                width="100"
                dataAlign="center"
              >
                ダウンロード
              </TableHeaderColumn>
            )
            : null
        }

      </BootstrapTable>
    );
  }

  /**
   * ダウンロードの欄
   */
  downloadButtonFormatter(row) {
    return (
      <button
        className="btn btn-sm btn-outline btn-accent"
        onClick={ this.downloadCSVFromUserId(row).bind(this) }
      >
        CSV出力
      </button>
    );
  }

  /**
   * 口座の欄
   */
  expenseAccountFormat(row) {
    return row ? (<span className='txt txt-disabled'>設定済み</span>) : (<span className='txt txt-caution'>未設定</span>);
  }
}

UserAnalysisTable.propTypes = {
  analysisId: PropTypes.string.isRequired,
  summariesByUser: PropTypes.array.isRequired,
  onRowClick: PropTypes.func.isRequired,
  isDownloadable: PropTypes.bool,
};

UserAnalysisTable.defaultProps = {
  isDownloadable: true,
};
