import { ExpenseViewData } from 'view_data/ExpenseViewData';
import { IPreReportDriver } from './IPreReportDriver';
import { IPreReportExpenseDriver } from './IPreReportExpenseDriver';
import { ShowResponse } from 'utilities/api/responses/preReports';

interface PreReportResponse extends ShowResponse {
  transactions: ExpenseViewData[];
}

export class PreReportApi {
  constructor(private driver: IPreReportDriver, private expenseDriver: IPreReportExpenseDriver) {
    this.driver = driver;
    this.expenseDriver = expenseDriver;
  }

  /** 事前申請を取得します */
  async fetch(preReportId: string, forApprover: boolean): Promise<PreReportResponse> {
    const preReport = await this.driver.fetch(preReportId, forApprover);
    const expenses = await this.expenseDriver.fetchAll({ preReportId, forApprover, expensesSize: preReport.count });

    return {
      ...preReport,
      transactions: expenses.data,
    };
  }
}
