import { useHooks } from "components/renewaled_ui/FileViewer/Viewer/BrowserPDF/hooks";
import BrowserPDFToolBar from "components/renewaled_ui/FileViewer/Viewer/BrowserPDF/parts/ToolBar";
import BrowserPDFViewerObject from "components/renewaled_ui/FileViewer/Viewer/BrowserPDF/parts/Viewer";
import ViewerMessage from "components/renewaled_ui/FileViewer/Viewer/PDF/parts/ViewerMessage";
import { ViewerFooter } from "components/renewaled_ui/FileViewer/ViewerFooter";
import {
  DeleteEventHandler,
  DropType,
  FileLoadStatus,
  FileType,
  LoadingStatus,
} from "components/types/attachedFiles.types";
import React from "react";
import { DropzoneInputProps, DropzoneRootProps } from "react-dropzone";
import styled from "styled-components";

/**
 * 縦横サイズのCSSの値を整形し返す
 */
function normalizeSize(isAuto: boolean, size?: number | string): string {
  if (typeof size === "number") return `${size}px`;
  if (size) return size;
  return isAuto ? "auto" : "100%";
}

const Wrapper = styled.div`
  position: relative;
  overflow: hidden;
  background: #69696c;
  display: flex;
  flex-flow: column;
`;
const Content = styled.div`
  flex-grow: 1;
`;
const Loading = styled.div`
  position: absolute;
  display: flex;
  justify-content: center;
  width: 100%;
  height: 100%;
  align-items: center;
  z-index: 100;
`;
const LoadingIcon = styled.i`
  opacity: 0.4;
  font-size: 50px;
`;

interface Props {
  readonly fileName?: string;
  readonly rootProps: DropzoneRootProps;
  readonly className?: string;
  readonly isDroppable?: boolean;
  readonly isDropzoneVisible?: boolean;
  readonly selectableTypes?: FileType[];
  readonly dropType?: DropType;
  readonly isShowFileSelect: boolean;
  /** PDFファイルURL */
  readonly url: string;
  readonly downloadLink?: string;
  readonly isShowSubBar: boolean;
  readonly isDeletable: boolean;
  readonly isTransformable: boolean;
  readonly isShowToolBar: boolean;
  readonly width?: number | string;
  readonly height?: number | string;
  readonly loadStatus: FileLoadStatus;
  readonly showPlaceholderDescription?: boolean;
  /** ファイルinput */
  readonly getInputProps: (
    props?: DropzoneInputProps | undefined,
  ) => DropzoneInputProps;
  /** フッター */
  readonly footer?: string | JSX.Element;
  readonly onClickChangeViewer: (
    e: React.MouseEvent<HTMLElement, MouseEvent>,
  ) => void;
  /** 削除をする */
  readonly onDelete?: DeleteEventHandler;
  readonly onClick?: (e: React.SyntheticEvent, state: LoadingStatus) => void;
}

/**
 * ブラウザ版PDFビューアー
 */
const BrowserPDFViewer: React.FC<Props> = (p) => {
  const {
    isLoaded,
    canUpload,
    canDownload,
    canDelete,
    isShowToolBar,
    onClickDeleteTool,
  } = useHooks(
    p.loadStatus.state,
    p.isShowFileSelect,
    p.isDeletable,
    p.isShowToolBar,
    p.downloadLink,
    p.onDelete,
  );

  /**
   * wrapperのクラス名
   */
  const wrapperClassName = `viewer-browser-pdf-wrapper${
    p.className ? ` ${p.className}` : ""
  }`;

  return (
    <Wrapper
      className={wrapperClassName}
      style={{
        height: normalizeSize(false, p.height),
        width: normalizeSize(true, p.width),
      }}
    >
      {p.loadStatus.state === "loading" && (
        <Loading className="viewer-browser-pdf-wrapper-loading">
          <LoadingIcon className="fa fa-spinner fa-spin viewer-browser-pdf-wrapper-loading-icon" />
        </Loading>
      )}

      {isShowToolBar() && (
        <BrowserPDFToolBar
          rootProps={p.rootProps}
          getInputProps={p.getInputProps}
          fileName={p.fileName || ""}
          downloadLink={p.downloadLink || ""}
          canUpload={canUpload}
          canDownload={canDownload}
          canDelete={canDelete}
          onClickDelete={onClickDeleteTool}
          onClickChangeViewer={p.onClickChangeViewer}
        />
      )}
      <Content className="viewer-browser-pdf-wrapper-content">
        {
          // ファイルを読み込めなかった場合
          !isLoaded && (
            <ViewerMessage
              loadStatus={p.loadStatus}
              showPlaceholderDescription={p.showPlaceholderDescription}
            />
          )
        }

        {p.url !== "" && (
          <BrowserPDFViewerObject
            url={p.url}
            onClickChangeViewer={p.onClickChangeViewer}
          />
        )}

        {!!p.footer && (
          <ViewerFooter className="viewer-dropzone-view-footer">
            {p.footer}
          </ViewerFooter>
        )}
      </Content>
    </Wrapper>
  );
};

export default BrowserPDFViewer;
