import _escape from 'lodash/escape';
import toastr from 'toastr/toastr';

/*
 * positionClass: toastrがdefaultで用意しているクラス以外に、toast-modal-lg-top-rightを用意
 */
const defaultOptions = {
  ...toastr.options,
  closeButton: true,
  preventDuplicates: true,
  positionClass: 'toast-top-right',
  showDuration: 300, // 長過ぎると、メッセージが連続した時に、アニメーションの動きが悪くなる
  hideDuration: 500,
  timeOut: 5000,
  extendedTimeout: 0, // hover中は消えないように
};

toastr.options = { ...defaultOptions };

export function setOptions(options) {
  toastr.options = { ...toastr.options, ...options };
}

export function resetOptions(options = defaultOptions) {
  toastr.options = { ...options };
}

function callWithOptions(type, message, options) {
  const preOptions = { ...toastr.options };
  if (options) {
    setOptions(options);
  }
  toastr[type](_escape(message));

  // optionを戻しておく
  if (options) {
    resetOptions(preOptions);
  }
}

/**
 * Toastメッセージ
 */
export default {
  toastr,
  success: callWithOptions.bind(null, 'success'),
  warning: callWithOptions.bind(null, 'warning'),
  error: callWithOptions.bind(null, 'error'),
  info: callWithOptions.bind(null, 'info'),
  clear: toastr.clear,
  remove: toastr.remove,
  callWithOptions,
  setOptions,
  resetOptions,
};
