import React from 'react';

/** ページのメイン表示領域
 *  styleは./index.tsxで指定
 */
export const PageBody: React.FC = ({ children }) => {
  return (
    <div className="page-body">
      {children}
    </div>
  );
};
