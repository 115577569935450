import Api from 'utilities/api';
import React, { useCallback, useEffect, useState } from 'react';
import TagSelector from 'applications/tags/components/TagSelector';
import { ControlLabel, FormGroup } from 'react-bootstrap';
import { Controller, useFormContext, useWatch } from 'react-hook-form';
import { Tag } from 'utilities/api/models/Tag';

interface SelectableTag extends Tag {
  selected: boolean;
}

export const TagsSelectField: React.FC = () => {
  const { control } = useFormContext();

  const [processing, setProcessing] = useState<boolean>(false);
  const [fetchedTags, setFetchedTags] = useState<Tag[]>([]);
  const [selectableTags, setSelectableTags] = useState<SelectableTag[]>([]);

  const watchedTags = useWatch({
    control,
    name: 'analysisAggregationConditions.tags',
    defaultValue: [],
  });

  useEffect(() => {
    const fetchTags = async (): Promise<void> => {
      setProcessing(true);

      try {
        const data = await Api.tags.index();
        setFetchedTags(data);
      } catch (e) {
        // nothing to do
      } finally {
        setProcessing(false);
      }
    };
    fetchTags();
  }, [setProcessing, setFetchedTags]);

  useEffect(() => {
    if (watchedTags != null) {  // 大元のanalysisAggregationConditionsの値がnullであった時(= 集計条件に何も指定しなかった時)、watchedTagsの初期値もnullとなる。その場合にぬるぽを回避
      setSelectableTags(fetchedTags.map((t) => ({ ...t, selected: watchedTags?.some((st) => st.id === t.id) })));
    }
  }, [fetchedTags, setSelectableTags, watchedTags]);

  const handleChange = useCallback((tag: SelectableTag, tags: Tag[], onChange: (selectedTags: Tag[]) => void) => {
    const newTags = tags ? [...tags] : []; // 大元のanalysisAggregationConditionsの値がnullであった時(= 集計条件に何も指定しなかった時)、tagsにはnullが渡ってくる。その場合にぬるぽを回避
    const index = newTags.findIndex((t) => t.id === tag.id);

    if (index < 0) {
      newTags.push(tag);
    } else {
      newTags.splice(index, 1);
    }

    onChange(newTags);
  }, []);

  if (processing) {
    return (<div>従業員ラベルを読込中...</div>);
  }

  return fetchedTags.length > 0 ? (
    <FormGroup>
      <ControlLabel>従業員ラベル</ControlLabel>
      <Controller
        name='analysisAggregationConditions.tags'
        control={ control }
        rules={ {} }
        render={ ({ field: { value, onChange } }): React.ReactElement => {
          return <TagSelector tags={ selectableTags } onClickTag={ (tag): void => handleChange(tag, value, onChange) } />;
        } }
      />
    </FormGroup>
  ) : <></>;
};
