import React, { Component } from 'react';
import isEqual from 'lodash/isEqual';
import styled from 'styled-components';
import { HelpBlock } from 'react-bootstrap';

interface Props {
  messages: Array<string>;
  className?: string;
}

type FormErrorProps = {
  messages: Array<string>;
  className?: string;
}

const StyledDiv = styled.div`
  .has-error {
    .form-control {
      border-color: #d9534f;
    }
    .help-block {
      color: #d9534f;
    }
    .input-group-addon {
      color: #d9534f;
      background-color: #f2dede;
      border-color: #d9534f;
    }
    .form-control-feedback {
      color: #a94442;
    }
  }
`;

export default class FormValidation extends Component<Props> {
  render(): JSX.Element {
    return (
      <StyledDiv>
        <div className={ this.props.messages.length > 0 ? 'has-error' : '' }>
          { this.props.children }
          <FormError messages={ this.props.messages }/>
        </div>
      </StyledDiv>
    );
  }
}

class FormError extends Component<FormErrorProps> {
  shouldComponentUpdate(nextProps: FormErrorProps): boolean {
    // 同じメッセージの場合にrenderされるのを防ぐ
    return !isEqual(this.props.messages, nextProps.messages);
  }

  render(): JSX.Element | null {
    if (this.props.messages.length > 0) {
      return (
        <div className={ this.props.className || '' }>
          { this.props.messages.map((m: string, idx: number): JSX.Element => (<HelpBlock key={ `help-block-${idx}` }>{ m }</HelpBlock>)) }
        </div>
      );
    }

    return null;
  }
}
