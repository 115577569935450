import React from 'react';

export const ToolbarAction: React.FC = ({
  children,
}) => {
  return (<>
    {
      React.Children.map(children, (child) => (<div className='toolbar-action'>{ child }</div>))
    }
  </>);
};
