import CircularProgressBar from "components/CircularProgressBar";
import React from "react";
import styled from "styled-components";
import useIcon from "./useIcon";

const Wrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: bold;
`;

interface Props {
  readonly progress?: number;
}

/**
 * 画像・ファイルのプログレスバー付きローディング
 */
const Loading: React.FunctionComponent<Props> = (p: Props) => {
  const [ref, iconSize] = useIcon();

  return (
    <Wrapper ref={ref} className="image-loading-view">
      <CircularProgressBar
        progress={p.progress || 0}
        size={iconSize}
        showNumber={(iconSize || 16) > 32}
        borderColor="#32abbc"
      />
    </Wrapper>
  );
};

export default Loading;
