import get from "lodash/get";
import isBoolean from "lodash/isBoolean";
import isEmpty from "lodash/isEmpty";
import isNil from "lodash/isNil";
import pick from "lodash/pick";

export function checkPermission(permissions, permissionType) {
  let permitted = false;

  switch (permissionType) {
    case "deletable":
      permitted = !!permissions.deletable;
      break;
    case "detachable":
      permitted = !!permissions.detachable;
      break;
    default:
      permitted = false;
  }

  return permitted;
}

/*
 * フィールドの表示/非表示を判別する
 * ※constraintsに渡したパラメータ条件のみ判定を行う
 *
 * @param {Oject} field - 表示/非表示の判定するフィールド
 * @param {Object} constraints - 現在の経費のステータスやフィールドの入力値
 *
 * @example
 * const field = {
 *   ...,
 *   conditions: [
 *     {
 *       type: 'authority',
 *       operator: 'in',
 *       value: ['approver'],
 *     },
 *     {
 *       type: 'category_input',
 *       operator: 'in',
 *       value: ['xxxx-xxxx-xxxx', 'yyyy-yyyy-yyyy'],
 *     },
 *   ],
 * };
 * const constraints1 = { category_input: { id: 'xxxx-xxxx-xxxx', name: '交通費' } };
 * const constraints2 = { authority: 'approval', category_input: { id: 'yyyy-yyyy-yyyy', name: '消耗品費 ' } }
 *
 * // canRead(field, constraints1) => true
 * // canRead(field, constraints2) => false
 */
export function canRead(field, constraints) {
  // isMultipleEditing:一括更新専用フォームかどうかのチェック
  const isMultipleEditing = get(constraints, "isMultipleEditing", false);
  const forMultipleEditing = get(field, "forMultipleEditing", false);
  if (forMultipleEditing && !isMultipleEditing) {
    return false;
  }

  const conditions = field.conditions.reduce(
    (obj, cond) => ({ ...obj, [cond.type]: cond }),
    {},
  );
  if (isEmpty(pick(conditions, Object.keys(constraints)))) {
    return get(field, "show", isEmpty(conditions));
  }

  return Object.entries(constraints).every(([type, obj]) => {
    const condition = conditions[type];
    if (isNil(condition)) {
      return true;
    }

    let value = null;
    if (obj instanceof Array) {
      value = obj;
    } else if (obj instanceof Object) {
      switch (type) {
        case "expense_input":
          value = get(obj, "amount", 0);
          break;
        case "project_input":
          value =
            condition.operator === "mt"
              ? get(obj, "displayId", "")
              : get(obj, "id", null);
          break;
        default:
          value = get(obj, "id", null);
          break;
      }
    } else {
      value = obj;
    }

    switch (condition.operator) {
      case "eq":
        return value === condition.value;
      case "not_eq":
        return value !== condition.value;
      case "lt":
        return value < condition.value;
      case "gt":
        return value > condition.value;
      case "in":
        return condition.value
          .map((v) => (v === "creating" ? null : v))
          .includes(value);
      case "mt":
        return new RegExp(condition.value).test(value);
      default:
        return false;
    }
  });
}

export function canDetach(permission) {
  return checkPermission(permission, "detachable");
}

export function canDelete(permission) {
  return checkPermission(permission, "deletable");
}

// TODO: canUpdateが実装されたら削除する
export function canUpdateAll(permission) {
  const updatable = isNil(permission) ? {} : permission.updatable;
  if (!isNil(updatable)) {
    if (isBoolean(updatable.all) && updatable.all === false) {
      return false;
    }
  }

  return true;
}

/**
 * 最新の領収書画像が送信に失敗しているかどうか。
 *
 * @param receiptImages
 * @returns {boolean}
 */
export function hasFailedReceiptImage(receiptImages) {
  return (
    hasFailedForeSideReceiptImage(receiptImages) ||
    hasFailedBackSideReceiptImage(receiptImages)
  );
}

/**
 * 最新の領収書画像（表面）が送信に失敗しているかどうか。
 *
 * @param receiptImages
 * @returns {boolean}
 */
export function hasFailedForeSideReceiptImage(receiptImages) {
  return hasFailedReceiptImageSide(receiptImages, "foreside.0");
}

/**
 * 最新の領収書画像（裏面）が送信に失敗しているかどうか。
 *
 * @param receiptImages
 * @returns {boolean}
 */
export function hasFailedBackSideReceiptImage(receiptImages) {
  return hasFailedReceiptImageSide(receiptImages, "backside.0");
}

function hasFailedReceiptImageSide(receiptImages, attr) {
  const image = get(receiptImages, attr, false);

  return image && ["worker_failed", "uploading"].includes(image.status);
}

/**
 * 最新の領収書画像がタイムスタンプ署名に失敗しているかどうか。
 *
 * @param receiptImages
 * @returns {boolean}
 */
export function hasFailedToSignReceiptImage(receiptImages) {
  return (
    hasFailedToSignForeSideReceiptImage(receiptImages) ||
    hasFailedToSignBackSideReceiptImage(receiptImages)
  );
}

/**
 * 最新の領収書画像（表面）がタイムスタンプ署名に失敗しているかどうか。
 *
 * @param receiptImages
 * @returns {boolean}
 */
export function hasFailedToSignForeSideReceiptImage(receiptImages) {
  return hasFailedToSignTimeStamp(receiptImages, "foreside.0");
}

export function hasFailedToSignBackSideReceiptImage(receiptImages) {
  return hasFailedToSignTimeStamp(receiptImages, "backside.0");
}

function hasFailedToSignTimeStamp(receiptImages, attr) {
  const image = get(receiptImages, attr, false);

  return (
    image && ["failed_to_sign", "failed_to_convert"].includes(image.status)
  );
}
