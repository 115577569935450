import Api from 'utilities/api';
import React, { useCallback, useRef, useState } from 'react';
import ToggleButton from 'components/toggle_button';
import flash from 'utilities/flash';
import i18next from 'i18n';
import {
  ControlLabel,
  DropdownButton,
  FormControl,
  FormGroup,
  InputGroup,
  MenuItem,
} from 'react-bootstrap';
import { getMessageFromResponse, snakecaseKeys } from 'utilities/Utils';
import { validateOverZero } from 'utilities/validator';

/**
 * @todo TS化する際に type をちゃんと書く
 * @param {*} props プロパティ
 *   selectedCompanions: 選択中の参加者情報
 *   ownerId: 実行ユーザのID
 *   isClient: 取引先の追加か否か
 *   onAppend: 作成される参加者情報を追加する処理
 */

/** 参加者の追加を行うフォーム */
const CompanionAppendForm = (props) => {
  /** ToggleButton のアクションを無理やり発火させるための Ref */
  const addButtonEl = useRef();
  /** デフォルトで入力させる会社名 */
  const defaultCompanyName = props.isClient ? '' : userPreferences.rootGroup.name;

  // 参加者として入力する種別
  const [inputType, setInputType] = useState('name');
  // 参加者の会社名
  const [companyName, setCompanyName] = useState(defaultCompanyName);
  const handleChangeCompanyName = useCallback(
    (e) => { setCompanyName(e.target.value); },
    [setCompanyName],
  );
  // 参加者名
  const [companionName, setCompanionName] = useState('');
  const handleChangeCompanionName = useCallback(
    (e) => { setCompanionName(e.target.value); },
    [setCompanionName],
  );
  // 参加者人数
  const [companionCount, setCompanionCount] = useState(0);
  const handleChangeCompanionCount = useCallback(
    (e) => { setCompanionCount(e.target.value); },
    [setCompanionCount],
  );

  /**
   * 参加者を作成し, 作成した結果を追加のためのメソッドへ渡します
   * @param ownerId 実行ユーザのID
   * @param isClient 取引先か否か
   * @param newCompanyName 会社名
   * @param newCompanionName 参加者名
   * @param newCompanionCount 参加者の人数
   */
  const createAndAppendCompanion = useCallback(
    async (ownerId, isClient, newCompanyName, newCompanionName, newCompanionCount) => {
      if (!$('.list-selector-item-form').validationEngine('validate', {
        customFunctions: {
          validateOverZero,
        },
      })) {
        if (addButtonEl.current) {
          addButtonEl.current.activate();
        }
        return;
      }

      try {
        const response = await Api.companions.create(snakecaseKeys({
          isClient,
          ownerId,
          name: newCompanionName,
          company: newCompanyName,
          createFor: 'personal',
        }));

        const companionNumberRegisteredCompanies = props.selectedCompanions.filter((c) => c.isCompanionNumber).map((val) => val.company);
        if (response.isCompanionNumber && companionNumberRegisteredCompanies.includes(response.company)) {
          flash.error('その会社の参加者数は既に登録されています');
          return;
        }

        props.onAppend({
          ...response,
          number: parseInt(newCompanionCount, 10) || null,
        });
        flash.success(i18next.t('companions.messages.added'));

        setCompanyName(defaultCompanyName);
        setCompanionName('');
        setCompanionCount(0);
      } catch (e) {
        flash.error(getMessageFromResponse(e));
      } finally {
        if (addButtonEl.current) {
          addButtonEl.current.activate();
        }
      }
    },
    [defaultCompanyName, props],
  );

  return (
    <form className='list-selector-item-form'>
      { props.isClient && (
        <FormGroup>
          <ControlLabel>{ i18next.t('companions.companyName') }</ControlLabel>
          <FormControl
            type="text"
            value={ companyName }
            onChange={ handleChangeCompanyName }
          />
        </FormGroup>
      ) }
      <FormGroup>
        <ControlLabel>{ i18next.t('companions.addCompanion') }</ControlLabel>
        <InputGroup>
          <DropdownButton
            componentClass={ InputGroup.Button }
            id="input-dropdown-addon"
            title={ i18next.t(`companions.${inputType}`) }
            onSelect={ (eventKey) => { setInputType(eventKey); } }>
            <MenuItem eventKey='name'>{ i18next.t('companions.name') }</MenuItem>
            <MenuItem eventKey='number'>{ i18next.t('companions.number') }</MenuItem>
          </DropdownButton>
          { (inputType === 'name') && (
            <FormControl
              className='validate[required]'
              type="text"
              value={ companionName }
              onChange={ handleChangeCompanionName }
            />
          ) }
          { (inputType === 'number') && (
            <FormControl
              className='validate[required, funcCall[validateOverZero]]'
              type="number"
              value={ companionCount }
              onChange={ handleChangeCompanionCount }
            />
          ) }
        </InputGroup>
      </FormGroup>
      <div className='form-group wrap-child'>
        <ToggleButton
          ref={ addButtonEl }
          className = 'btn btn-accent'
          onClick = { (e) => {
            e.preventDefault();
            createAndAppendCompanion(
              props.ownerId,
              props.isClient,
              companyName,
              (inputType === 'name' ? companionName : ""),
              (inputType === 'number' ? companionCount : 0),
            );
          } }>
          { i18next.t('commons.actions.add') }
        </ToggleButton>
      </div>
    </form>
  );
};

export default CompanionAppendForm;
