import InfiniteSelector from './fields/InfiniteSelector';
import React from 'react';
import { Tab, Tabs } from 'react-bootstrap';

/**
 * ランダムな文字列を作る
 * Tabs が id 必須だが、固定の ID とならないようにするために利用.
 */
function randomString(): string {
  return Math.random().toString(36).substring(2, 15) + Math.random().toString(36).substring(2, 15);
}

const defaultProps = {
  id: `pickList-${randomString()}`,
  defaultTabIndex: 1,
  sourceTitle: 'source data',
  sourceSize: 0,
  sourceItems: [],
  selectedTitle: 'selected',
  selectedSize: 0,
  selectedItems: [],
  onOptionsChange: (): void => { /** ダミー */ },
};

type DefaultProps = Readonly<typeof defaultProps>

type Props = {
  /** コンポーネントの id を指定する */
  id?: string;
  responseKey: string;
  /** アイテムをフェッチする際に発火するアクション */
  fetchSourceItems: (offset: number, limit: number) => void;
  /**
   * コンポーネント内部で表示するソースのアイテムが変更された際に発火させる処理
   * @param newSourceItems - スクロールによってフェッチが走るなどして、変更された後のソースのアイテム
   * @param totalItemSize - DB上に保存されているアイテム全体の数
   * @todo object型は使わないように
   */
  // eslint-disable-next-line @typescript-eslint/ban-types
  onOptionsChange?: (newSourceItems: object[], totalItemSize: number) => void;
  /**
   * アイテムの選択状態が変更されたときに発火するアクション
   * @todo object型は使わないように
   */
  // eslint-disable-next-line @typescript-eslint/ban-types
  onSelect: (item: object, isSelected: boolean, selectedItems: object[]) => void;
  /** デフォルトで表示するタブのインデックス */
  defaultTabIndex?: number;
  /**
   * 行をどのようにレンダリングするか
   * @todo object型は使わないように
   */
  // eslint-disable-next-line @typescript-eslint/ban-types
  renderRow?: (item: object) => JSX.Element;

  // データソースに関する Props
  /** ソースとなるアイテムリストのタイトル */
  sourceTitle?: string;
  /** 表示対象になりうるソース全体のアイテム数 */
  sourceSize?: number;
  /**
   * ソースとなるアイテム
   * @todo object型は使わないように
   */
  // eslint-disable-next-line @typescript-eslint/ban-types
  sourceItems?: object[];

  // 選択されたアイテムに関する Props
  /** 選択されたアイテムリストのタイトル */
  selectedTitle?: string;
  /** 選択されているソース全体のアイテム数 */
  selectedSize?: number;
  /**
   * 選択されているアイテム
   * @todo object型は使わないように
   */
  // eslint-disable-next-line @typescript-eslint/ban-types
  selectedItems?: object[] | object;
} & DefaultProps

const ROW_HEIGHT = 61;

/**
 * 元となるリストから, あるリストへアイテムを移動させるコンポーネント
 */
class PickList extends React.Component<Props> {
  static defaultProps = defaultProps;

  render(): JSX.Element {
    const {
      id, defaultTabIndex, sourceTitle, responseKey, sourceItems, selectedItems,
      sourceSize, renderRow, onSelect, fetchSourceItems, onOptionsChange,
      selectedTitle, selectedSize,
    } = this.props;

    return (
      <Tabs id={ id } defaultActiveKey={ defaultTabIndex }>
        <Tab eventKey={ 1 } title={ sourceTitle }>
          <InfiniteSelector
            responseKey={ responseKey }
            batchSize={ 30 }
            totalCount={ sourceSize }   // 表示候補全体の数
            rowHeight={ ROW_HEIGHT }
            isMultiSelectable={ true }
            options={ sourceItems }     // 表示するアイテムリスト
            selection={ selectedItems } // チェックを入れる対象のリスト
            formatOption={ renderRow }  // 行のレンダリング方法
            onSelect={ onSelect }       // 選択時のアクション
            fetchOptions={ fetchSourceItems }   // フェッチ時に叩くAPI
            onOptionsChange={ onOptionsChange } // 候補が変更された際に発火
          />
        </Tab>
        <Tab eventKey={ 2 } title={ selectedTitle }>
          <InfiniteSelector
            responseKey={ responseKey }
            rowHeight={ ROW_HEIGHT }
            isMultiSelectable={ true }
            options={ selectedItems }   // 表示するアイテムリスト
            selection={ selectedItems } // チェックを入れる対象のリスト
            totalCount={ selectedSize } // 表示候補全体の数
            formatOption={ renderRow }  // 行のレンダリング方法
            onSelect={ onSelect }     // 選択時のアクション
            fetchOptions={ (): void => { /* 選択された候補はローカルにあるため, フェッチ不要 */ } }
            onOptionsChange={ (): void => { /* 候補の動的変更無いため不要 */ } }
          />
        </Tab>
      </Tabs>
    );
  }
}

export default PickList;
