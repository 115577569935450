import React from 'react';
import styled from 'styled-components';

interface Props {
  label: string;
  value?: Value;
}

type Value = string | number | boolean | JSX.Element | null;

const ItemView = styled.div`
  margin: 12px 6px;
  display: flex;
  gap: 6px;
`;

const LabelView = styled.div`
  font-weight: bold;
  text-align: right;
  padding: 0px;
  min-width: 150px;
  white-space: nowrap;
`;

const ValueView = styled.div`
  min-width: 200px;
  margin-left: 1rem;
`;

export const Item: React.FC<Props> = ({
  label,
  value,
}) => {
  if (!value) { return <></>; }

  return (
    <ItemView>
      <LabelView>{ label }</LabelView>
      <ValueView>{ value }</ValueView>
    </ItemView>
  );
};
