import React from 'react';
import SmartCheckBox from 'components/SmartCheckBox';
import i18next from 'i18n';
import { Controller, useFormContext } from 'react-hook-form';
import { FileTransferLocationSelectField } from './fields/FileTransferLocationSelectField';

export const TransferForm = React.forwardRef((_, ref?: React.Ref<HTMLDivElement>) => {
  const { control, watch } = useFormContext();
  const enableFileTransfer = watch('enableFileTransfer');

  return (
    <>
      <div ref={ ref } />
      <h4 className='title'>
        { i18next.t('accountingDataScheduledExports.tasks.forms.transfer.title') }
      </h4>
      <Controller
        name='enableFileTransfer'
        control={ control }
        render={ ({ field: { onChange, value } }): React.ReactElement => (
          <SmartCheckBox onChange={ onChange } checked={ value || false } label={ i18next.t('accountingDataScheduledExports.tasks.forms.transfer.enableFileTransfer.label') } />
        ) }
      />
      {
        enableFileTransfer && (
          <FileTransferLocationSelectField
            name='fileTransferLocation'
          />
        )
      }
    </>
  );
});
