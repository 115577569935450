import React from 'react';

interface Props {
  progress: number;
  size?: number;
  showNumber?: boolean;
  showShadow?: boolean;

  /** 文字色 */
  color?: string;
  /** 内円（文字の背景）の色 */
  backgroundColor?: string;
  /** 外円（インジケータ）の色 */
  borderColor?: string;
}

function getLabelStyle(size: string, backgroundColor: string, color: string): React.CSSProperties {
  const borderSize = `calc(${size} / 10)`;

  return {
    backgroundColor,
    color,
    borderRadius: '50%',

    position: 'absolute',
    left: borderSize,
    top: borderSize,
    right: borderSize,
    bottom: borderSize,

    fontSize: `calc(${size} / 4)`,
    lineHeight: `calc(${size} * .7)`,
    textAlign: 'center',
  };
}

function getCircleContainerStyle(progress: number, size: string): React.CSSProperties {
  // 50%以下の時は、円の左半分を隠す
  const clip = progress <= 50 ? `rect(0, ${size}, ${size}, calc(${size} / 2)` : void 0;

  return {
    clip,
    height: '100%',
    width: '100%',
    position: 'absolute',
    top: 0,
    left: 0,
  };
}

// TODO: animationつけたい（clipが厄介）
function getSemiCircleStyle(size: string, borderColor: string): React.CSSProperties {
  return {
    height: '100%',
    width: '100%',
    border: `calc(${size} / 10) solid ${borderColor}`,
    borderRadius: '50%',
    position: 'absolute',
    top: 0,
    left: 0,
  };
}

function getHeadStyle(progress: number, size: string, borderColor: string): React.CSSProperties {
  return {
    ...getSemiCircleStyle(size, borderColor),
    // 左半円を回転させて表示
    transform: `rotate(${progress * 3.6}deg)`,
    clip: `rect(0, calc(${size} / 2), ${size}, 0)`,
  };
}

function getTailStyle(progress: number, size: string, borderColor: string): React.CSSProperties {
  return {
    ...getSemiCircleStyle(size, borderColor),
    // 50%を超えた時、右半円を表示
    clip: `rect(0, ${size}, ${size}, calc(${size} / 2))`,
    display: progress <= 50 ? 'none' : void 0,
  };
}

function getShadowStyle(size: string): React.CSSProperties {
  return {
    height: '100%',
    width: '100%',
    border: `calc(${size} / 10) solid #d2dade`,
    borderRadius: '50%',
  };
}

const CircularProgressBar: React.FunctionComponent<Props> = (props: Props) => {
  const {
    showNumber = false, showShadow = false,
    color = '#7f8c8d', backgroundColor = 'transparent', borderColor = '#97a3a7',
  } = props;

  const progress = Math.max(0, Math.min(100, props.progress)); // 0 <= progress <= 100
  const size = `${props.size || 16}px`;

  return (
    <div style={ { height: size, width: size, position: 'relative' } }>
      <div style={ getLabelStyle(size, backgroundColor, color) }>
        {
          showNumber && (
            <>
              { progress }
              <span className='unit' style={ { fontSize: '.45em', verticalAlign: 'super' } }>%</span>
            </>
          )
        }
      </div>
      <div style={ getCircleContainerStyle(progress, size) }>
        <div className='left-circle' style={ getHeadStyle(progress, size, borderColor) } />
        <div className='right-circle' style={ getTailStyle(progress, size, borderColor) } />
      </div>
      { showShadow && <div style={ getShadowStyle(size) } /> }
    </div>
  );
};

export default CircularProgressBar;
