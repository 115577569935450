import { useExportedCsvHooks } from "hooks/useExportedCsvHooks";
import Api from "utilities/api";
import { ExportedCsvResponse } from "utilities/api/responses/expenses/Applications/ExportedCsvsResponse";

interface UseReturn {
  readonly formData: ExportedCsvResponse[];
}

export const useHooks = (params: {requestTypeId: string}): UseReturn => {
  const data = useExportedCsvHooks(Api.expenses.applications.requestTypes.exportedCsvs, params);

  return { formData: data?.exportedCsvs || [] };
};
