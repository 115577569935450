import React, { Component } from 'react';

const withStaticInput = (formatter = (v) => v) => (WrappedComponent) => {
  return class extends Component {
    get staticClassName() {
      const baseClassName = 'form-control-static';

      if (this.props.className) {
        return `${baseClassName} ${this.props.className}`;
      }

      return baseClassName;
    }

    render() {
      if (this.props.isStatic) {
        return (
          <p className={ this.staticClassName }>
            { formatter(this.props.value) }
          </p>
        );
      }

      return (<WrappedComponent { ...this.props } />);
    }
  };
};

export default withStaticInput;
