import PropTypes from "prop-types";
import React, { Component } from "react";
import { connect } from "react-redux";
import * as actions from "../actions";

export class AccountRegistrationForm extends Component {
  constructor(props) {
    super(props);

    this.renderCaptcha = this.renderCaptcha.bind(this);
  }

  renderInput(input, idx) {
    const { onChangeForm } = this.props;

    const isHidden = input.inputType === "hidden";
    return (
      <div className="account-form-input" key={`account-input-${idx}`}>
        {this.renderCaptcha(input)}
        <div className={isHidden ? "hidden" : "form-group"}>
          <label className="col-sm-4 control-label">{input.label}</label>
          <div className="col-sm-4">
            <input
              className="form-control validate[required]"
              type={input.inputType}
              name={input.inputName}
              autoComplete="new-password"
              onChange={(e) => {
                onChangeForm({ [input.inputName]: e.target.value });
              }}
            />
          </div>
        </div>
      </div>
    );
  }

  renderCaptcha(input) {
    const isCaptcha = !!input.inputImage;
    if (isCaptcha) {
      return (
        <div className="form-group">
          <label className="col-sm-4 control-label"></label>
          <div className="col-sm-4">
            <img alt="Captcha" src={input.inputImage}></img>
          </div>
        </div>
      );
    }
    return null;
  }

  render() {
    const { isDisabled, isInEdit, inputs, onClickSubmit } = this.props;

    return (
      <form className="form-horizontal account-registration-form">
        {inputs.map(this.renderInput.bind(this))}
        {isInEdit ? (
          <div className="form-group row">
            <div className="col-sm-offset-5 col-sm-2">
              <button
                type="button"
                className={`btn btn-accent btn-block ${
                  isDisabled ? "disabled" : ""
                }`}
                onClick={(e) => {
                  onClickSubmit();
                }}
              >
                更新する
              </button>
            </div>
          </div>
        ) : null}
      </form>
    );
  }
}

AccountRegistrationForm.defaultProps = {
  isInEdit: false,

  onClickSubmit: () => null,
};

AccountRegistrationForm.propTypes = {
  id: PropTypes.string.isRequired,
  isDisabled: PropTypes.bool.isRequired,
  isInEdit: PropTypes.bool.isRequired,

  inputs: PropTypes.arrayOf(PropTypes.object),
  form: PropTypes.object.isRequired,

  onClickSubmit: PropTypes.func.isRequired,

  onChangeForm: PropTypes.func.isRequired,
};

function mapStateToProps(state, ownProps) {
  return {
    inputs: state.accountForms.inputs,
    form: state.accountForms.form,
  };
}

function mapDispatchToProps(dispatch, ownProps) {
  return {
    onChangeForm(datum) {
      dispatch(actions.setFormInput(datum));
    },
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(AccountRegistrationForm);
