import * as ActionTypes from "../actions/MarkingAsMatched/ActionTypes";
import * as Types from "../actions/MarkingAsMatched/Types";

export interface State {
  isLoading: boolean;
  isModalOpen: boolean;
}

const initialState: State = {
  isLoading: false,
  isModalOpen: false,
};

function reducer(state = initialState, action: Types.Action): State {
  switch (action.type) {
    case ActionTypes.SET_MARKING_AS_MATCHED_FETCHING_STATUS: {
      return {
        ...state,
        isLoading: action.payload.isLoading,
      };
    }
    case ActionTypes.TOGGLE_MARKING_AS_MATCHED_MODAL: {
      return {
        ...state,
        isModalOpen: action.payload.show,
      };
    }
    default:
      return state;
  }
}

export default reducer;
