import i18next from "i18n";
import React from "react";

interface Props {
  onClick: () => void;
}

export const OpenCancelApprovalModalButton: React.FC<Props> = React.memo(
  ({ onClick }) => {
    return (
      <button
        className="btn btn-danger btn-block btn-outline"
        onClick={onClick}
      >
        <i className="fa fa-left fa-undo" />
        {i18next.t("commons.actions.withdrawApproval")}
      </button>
    );
  },
);
