import _get from 'lodash/get';
import isNil from 'lodash/isNil';

/** fetch remote data */
export function onFetchErrorHelper(error, onError) {
  const message = _get(error, 'responseJSON.message');
  if (typeof onError === 'function') {
    onError(error, message);
  }
}

export function initializeSuggestionsHelper(path, fetchSuggestions, onFetchError) {
  return async (dispatch) => {
    try {
      dispatch(setDefaultFetchStatus(path, 'waiting'));
      const suggestions = await fetchSuggestions();
      dispatch(setTotalSuggestions(path, suggestions));
      dispatch(setDefaultFetchStatus(path, 'finished'));
    } catch (error) {
      onFetchErrorHelper(error, onFetchError);
    }
  };
}

/** set action */
export const SET_DEFAULT_FETCH_STATUS = 'SET_DEFAULT_FETCH_STATUS';
export function setDefaultFetchStatus(path, status) {
  return {
    type: SET_DEFAULT_FETCH_STATUS,
    path,
    status,
  };
}

export const SET_FETCH_STATUS = 'SET_FETCH_STATUS';
export function setFetchStatus(path, status) {
  return {
    type: SET_FETCH_STATUS,
    path,
    status,
  };
}

export const SET_TOTAL_SUGGESTIONS = 'SET_TOTAL_SUGGESTIONS';
export function setTotalSuggestions(path, suggestions) {
  return {
    type: SET_TOTAL_SUGGESTIONS,
    path,
    suggestions,
  };
}

export const SET_CURRENT_SUGGESTIONS = 'SET_CURRENT_SUGGESTIONS';
export function setCurrentSuggestions(path, suggestions) {
  return {
    type: SET_CURRENT_SUGGESTIONS,
    path,
    suggestions,
  };
}

export const FILTER_SUGGESTIONS = 'FILTER_SUGGESTIONS';
export function filterSuggestions(path, value, filter) {
  return {
    type: FILTER_SUGGESTIONS,
    path,
    filter: filter.bind(null, value),
  };
}

export const RESET_SUGGESTIONS = 'RESET_SUGGESTIONS';
export function resetSuggestions(path) {
  return {
    type: RESET_SUGGESTIONS,
    path,
  };
}

export const REQUEST_SUGGESTIONS_UPDATE = 'REQUEST_SUGGESTIONS_UPDATE';
export function requestSuggestionsUpdate(path, value, reason, filter) {
  return (dispatch) => {
    if (reason === 'click' || reason === 'enter' || isNil(value) || value.length === 0) {
      dispatch(resetSuggestions(path));
      return;
    }
    dispatch(filterSuggestions(path, value, filter));
  };
}

export function requestSuggestionsUpdateAsync(path, value, reason, updateAsync, onFetchError) {
  return (dispatch) => {
    if (reason === 'click' || reason === 'enter' || isNil(value) || value.length === 0) {
      dispatch(resetSuggestions(path));
      return Promise.resolve();
    }

    dispatch(setFetchStatus(path, 'waiting'));
    return updateAsync(value)
      .then((suggestions) => {
        dispatch(setCurrentSuggestions(path, suggestions));
        dispatch(setFetchStatus(path, 'finished'));
      })
      .catch((error) => { onFetchErrorHelper(error, onFetchError); });
  };
}
