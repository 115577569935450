import { MenuItem } from "components/renewaled_ui/Dropdown";
import i18next from "i18n";
import React from "react";
import styled from "styled-components";
import { openWorkerInputCorrectionRequestForm } from "./openWorkerInputCorrectionRequestForm";

interface Props {
  readonly expenseId: string;
}

const Icon = styled.i`
  margin-left: 5px;
`;

/**
 * 経費詳細画面上から自動入力修正依頼のGoogleフォームを開くためのメニュー
 */
export const WorkerInputCorrectionRequestMenu: React.FC<Props> = ({
  expenseId,
}) => {
  return (
    <MenuItem
      onClick={(): void => {
        openWorkerInputCorrectionRequestForm(expenseId);
      }}
    >
      {i18next.t("expenses.workerInputCorrectionRequest.button")}
      <Icon className="fas fa-external-link-alt" />
    </MenuItem>
  );
};
