import { displayMessage } from "actions/ActionCreators";
import { getPreReportApi } from "api_builders/PreReportApi";
import { getPreReportExpenseApi } from "api_builders/PreReportExpenseApi";
import * as transactionActions from "applications/transactions/actions/transactionTable";
import * as TransactionAsyncActions from "applications/transactions/actions/TransactionTableAsyncActions";
import i18next from "i18n";
import assign from "lodash/assign";
import find from "lodash/find";
import get from "lodash/get";
import isEmpty from "lodash/isEmpty";
import isNil from "lodash/isNil";
import orderBy from "lodash/orderBy";
import reduce from "lodash/reduce";
import Api from "utilities/api";
import * as formStateActions from "./formStateActions";
import { fetchReportHistories } from "./reportHistories";

const prefix = `pre_report`;

export function fetchDefaultPreReportName() {
  return function fetchDefaultPreReportNameThunk(dispatch, getState) {
    return Promise.resolve(Api.preferences.defaultPreReportName())
      .then((data) => dispatch(setProperty("title", data.defaultPreReportName)))
      .catch((error) => {
        dispatch(
          displayMessage(
            "error",
            get(error, "responseJSON.message") ||
              i18next.t("commons.errors.communicationError"),
          ),
        );
      });
  };
}

export const FETCH_PRE_REPORT = `${prefix}/FETCH_PRE_REPORT`;
export function fetchPreReport(id, forApprover) {
  return async (dispatch, getState) => {
    const {
      transactionTable,
      formState: { fields },
    } = getState();
    const data = await getPreReportApi().fetch(id, forApprover);

    dispatch(
      setPreReport(
        {
          ...data,
          preTransactions: data.preTransactions.map((t) =>
            assign(t, { key: t.id }),
          ),
        },
        fields,
        forApprover,
      ),
    );
    dispatch(
      transactionActions.initTransactions(
        transactionTable.transactions.master,
        { transactions: data.transactions },
      ),
    );
    dispatch(TransactionAsyncActions.fetchSuggestionsForOwner(data.userId));

    if (data.report?.id) {
      dispatch(fetchReportHistories(data.report.id));
    }

    return data;
  };
}

export function fetchPreReportExpenses({ preReportId, forApprover }) {
  return async (dispatch, getState) => {
    const { transactionTable, preReport } = getState();
    const { data } = await getPreReportExpenseApi().fetchAll({
      preReportId,
      forApprover,
      expensesSize: transactionTable.transactions.master.length,
    });

    dispatch(
      transactionActions.initTransactions(
        transactionTable.transactions.master,
        { transactions: data },
      ),
    );
    await dispatch(
      TransactionAsyncActions.fetchSuggestionsForOwner(preReport.userId),
    );
  };
}

export const SET_PRE_REPORT = `${prefix}/SET_PRE_REPORT`;
export function setPreReport(preReport, fields, forApprover) {
  return {
    type: SET_PRE_REPORT,
    preReport,
    fields,
    forApprover,
  };
}

export const SET_PROPERTY = `${prefix}/SET_PROPERTY`;
export function setProperty(key, value) {
  return {
    type: SET_PROPERTY,
    key,
    value,
  };
}

export const SET_METADATA = `${prefix}/SET_METADATA`;
export function setMetadata(key, value) {
  return {
    type: SET_METADATA,
    key,
    value,
  };
}

export const SET_PRE_TRANSACTION = `${prefix}/SET_PRE_TRANSACTION`;
export function setPreTransaction(preTransaction) {
  return {
    type: SET_PRE_TRANSACTION,
    preTransaction,
  };
}

export const EDIT_ALL_PRE_TRANSACTION_PROPERTY = `${prefix}/EDIT_ALL_PRE_TRANSACTION_PROPERTY`;
export function editAllPreTransactionProperty(key, value) {
  return {
    type: EDIT_ALL_PRE_TRANSACTION_PROPERTY,
    key,
    value,
  };
}

export const DELETE_PRE_TRANSACTION = `${prefix}/DELETE_PRE_TRANSACTION`;
export function deletePreTransaction(key) {
  return {
    type: DELETE_PRE_TRANSACTION,
    key,
  };
}

export function submitPreTransaction(preTransactionParams, onError) {
  return (dispatch, getState) => {
    const preTransaction = preTransactionParams;
    const preTransactionCategory = preTransaction.category;

    if (
      !isNil(preTransactionCategory) &&
      !preTransactionCategory.requiresCompanion
    ) {
      preTransaction.companions = [];
    }

    // commentは空白入力可のため最初と最後の空白を削除
    if (!isEmpty(preTransaction.comment)) {
      preTransaction.comment = preTransaction.comment.trim();
    }

    dispatch(setPreTransaction(preTransaction));
    dispatch(formStateActions.clearPreTransaction());
    dispatch(formStateActions.hidePreTransactionModal());
  };
}

export function onSortChange(sortName, sortOrder) {
  function sort(transactions) {
    const sortKeys =
      !isNil(transactions[0]) && transactions[0][sortName]
        ? transactions[0][sortName].sortKeys
        : null;

    if (isNil(sortKeys)) {
      return orderBy(transactions, sortName, sortOrder);
    }
    const reversedSortKeys = sortKeys.reverse();
    return reduce(
      reversedSortKeys,
      (result, sortKey) =>
        orderBy(
          result,
          (t) => (isNil(t[sortName]) ? "" : t[sortName][sortKey]),
          sortOrder,
        ),
      transactions,
    );
  }

  return (dispatch, getState) => {
    const { transactions } = getState().transactionTable;
    const master = sort(transactions.master);
    const inEdit = sort(transactions.inEdit);

    dispatch(transactionActions.setTransactions({ master, inEdit }));
  };
}

// 仮払いがオフのとき入力が不要となるフォームの入力をクリアする
export function clearTemporaryPaymentsForms() {
  return (dispatch, getState) => {
    const {
      formState: { fields },
    } = getState();
    const metadataFields = fields.filter(
      (field) =>
        !isNil(field.id) &&
        find(field.conditions, ["type", "temporary_payment_check_input"]),
    );
    metadataFields.forEach((metadataField) =>
      dispatch(setMetadata(metadataField.id, null)),
    );
    dispatch(setProperty("temporaryPaymentDueAt", null));
    dispatch(setProperty("temporaryPaymentReason", null));
  };
}
