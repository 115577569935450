import PropTypes from 'prop-types';
import React, { Component } from 'react';
import isNaN from 'lodash/isNaN';

export default class TextArea extends Component {
  constructor(props) {
    super(props);

    this.state = { charactersPerLine: 100 };
  }

  componentDidMount() {
    this.updateMaxCharactersPerLine();
  }

  componentDidUpdate() {
    this.updateMaxCharactersPerLine();
  }

  updateMaxCharactersPerLine() {
    if (!this.textarea) { return; }

    const style = getComputedStyle(this.textarea);
    const width = parseFloat(style.width);
    const fontSize = parseFloat(style.fontSize);
    const charactersPerLine = Math.ceil(width / fontSize);

    // テスト対策
    if (isNaN(charactersPerLine)) { return; }

    if (this.state.charactersPerLine !== charactersPerLine) {
      this.setState({ charactersPerLine });
    }
  }

  get textRows() {
    const { value, maxRows } = this.props;

    if (!value) { return 1; }

    const lines = value.split(/\r|\n|\r\n/g);
    const rows = lines.map((line) => {
      return line.length === 0 ? 1 : Math.ceil(line.length / this.state.charactersPerLine);
    }).reduce((acc, x) => (acc + x), 1);

    return maxRows ? Math.min(maxRows, rows) : rows;
  }

  render() {
    return (
      <textarea
        name={ this.props.name }
        className={ this.props.className }
        ref={ (element) => { this.textarea = element; } }
        style={ this.props.style }
        disabled={ this.props.disabled }
        value={ this.props.value }
        rows={ this.textRows }
        placeholder={ this.props.placeholder }
        maxLength={ this.props.maxLength }
        onChange={ this.props.onChange }
        onKeyDown={ this.props.onKeyDown }
        onBlur={ this.props.onBlur }
      />
    );
  }
}

TextArea.defaultProps = {
  name: '',
  className: '',
  disabled: false,
  maxRows: 15,
  style: {
    resize: 'none',
  },
};

TextArea.propTypes = {
  name: PropTypes.string,
  className: PropTypes.string,
  disabled: PropTypes.bool.isRequired,
  value: PropTypes.string,
  maxRows: PropTypes.number,
  maxLength: PropTypes.number,
  placeholder: PropTypes.string,
  onChange: PropTypes.func,
  onKeyDown: PropTypes.func,
  onBlur: PropTypes.func,
  style: PropTypes.object,
};
