import AccordionButton from "components/accordion_button";
import FlipSwitch from "components/FlipSwitch";
import SimpleModal from "components/simple_modal";
import i18next from "i18n";
import get from "lodash/get";
import isNil from "lodash/isNil";
import uniq from "lodash/uniq";
import PropTypes from "prop-types";
import React, { Component } from "react";
import formFieldsForMultipleEdit from "values/form_fields_for_multiple_edit";
import CostAllocationDepartmentModal from "../components/CostAllocationDepartmentModal";
import ManualMergeModal from "../components/ManualMergeModal";
import MergeableTransactionsSearchModal from "../components/MergeableTransactionsSearchModal";
import TransactionSearchBox from "../transaction_searchbox";
import EditableTransactionTable from "./EditableTransactionTable";
import TransactionModalWrapper from "./TransactionModalWrapper";

export default class TransactionListEditor extends Component {
  constructor(props) {
    super(props);

    this.handleCreateSuccess = this.handleCreateSuccess.bind(this);
    this.handleUpdateSuccess = this.handleUpdateSuccess.bind(this);
    this.handleDestroySuccess = this.handleDestroySuccess.bind(this);
    this.handleDetachSuccess = this.handleDetachSuccess.bind(this);
    this.onDestroyOrDetachSuccess = this.onDestroyOrDetachSuccess.bind(this);
    this.handleDestroy = this.handleDestroy.bind(this);
    this.handleDetach = this.handleDetach.bind(this);
    this.handleMerge = this.handleMerge.bind(this);
    this.handleTransactionModalOpen =
      this.handleTransactionModalOpen.bind(this);
    this.handleGoToNextExpense = this.handleGoToNextExpense.bind(this);
    this.handleGoToPreviousExpense = this.handleGoToPreviousExpense.bind(this);
    this.handleImageDestroy = this.handleImageDestroy.bind(this);
    this.resetTransactions = this.resetTransactions.bind(this);
    this.handleMarkAsRead = this.handleMarkAsRead.bind(this);
    this.handleMarkAsUnread = this.handleMarkAsUnread.bind(this);
    this.handleSplitSuccess = this.handleSplitSuccess.bind(this);
  }

  componentDidMount() {
    if (!userPreferences.isAuditor && this.props.fetchSuggestionsForOwner) {
      // 編集モードで使われている選択肢を取得している
      this.props.fetchSuggestionsForOwner(userPreferences.id);
    }

    if (this.props.withSearchBox) {
      // @todo 検索条件を保存しつつ、loadTransactionsの引数に渡すように
      //   Storeに保存されるタイミングに依存する可能性があるため
      this.props.fetchEntryForms().then((data) => {
        this.props.fetchTransactions();
      });

      this.props.setSearchBoxRef(this.refs.searchBox);

      return;
    }

    this.props.fetchEntryForms();
  }

  filterSelected(transactions) {
    return transactions.filter((x) => x && x.isSelected);
  }

  handleCreateSuccess(_expenses, reuseInput) {
    if (!reuseInput) this.props.closeTransactionModal();
    this.props.onCreateSuccess(null, this.resetTransactions);
  }

  handleUpdateSuccess(_expenses) {
    this.props.onUpdateSuccess(null, this.resetTransactions);
  }

  onDestroyOrDetachSuccess() {
    // 削除後のデータを取り直してから次の経費に遷移する.
    // データ更新前に遷移すると、遷移先の経費から見て、削除した経費が直前の経費として扱われてしまう.
    this.resetTransactions().then(() => {
      if (this.props.nextExpenseId) {
        this.handleGoToNextExpense();
      } else {
        this.props.closeTransactionModal();
      }
    });
  }

  handleDestroySuccess() {
    this.props.onDestroySuccess(null, this.onDestroyOrDetachSuccess);
  }

  handleDetachSuccess() {
    this.props.onDetachSuccess(null, this.onDestroyOrDetachSuccess);
  }

  handleImageDestroy(_receiptFile) {
    // callback必要？
    this.props.onUpdateSuccess(
      i18next.t("transactions.messages.deleteImage"),
      this.resetTransactions,
    );
  }

  handleDetach() {
    this.props.detachAll(this.props.transactionsObj, this.resetTransactions);
  }

  handleMerge(mergeable) {
    this.props.mergeAll(
      mergeable.map((m) => ({
        workerId: m.worker.id,
        aggregationId: m.aggregation.id,
      })),
      this.resetTransactions,
    );
  }

  handleTransactionModalOpen(expense) {
    this.props.openTransactionModal(
      this.props.transactions,
      expense,
      this.props.currentPage,
      this.props.sizePerPage,
    );
  }

  handleGoToNextExpense() {
    this.props.moveExpenseCursor(
      this.props.transactions,
      this.props.nextExpenseId,
      this.props.currentPage,
      this.props.sizePerPage,
    );
  }

  handleGoToPreviousExpense() {
    this.props.moveExpenseCursor(
      this.props.transactions,
      this.props.previousExpenseId,
      this.props.currentPage,
      this.props.sizePerPage,
    );
  }

  handleDestroy() {
    this.props.destroyAll(this.resetTransactions);
  }

  // 全て既読にするボタン押下
  handleMarkAsRead() {
    const transactionIds = this.props.transactions.map((t) => t.id);
    this.props.markAsRead(transactionIds);
  }

  /*
   * 未読にするボタン押下（モーダルへ渡す）
   * @param {string} transactionId : 経費ID
   */
  handleMarkAsUnread(transactionId) {
    if (transactionId) {
      this.props.markAsUnread([transactionId]);
    }
  }

  /**
   * 経費分割が成功した場合
   * @param {String} message メッセージ
   */
  handleSplitSuccess(message) {
    this.props.closeTransactionModal();
    this.props.onSplitSuccess(message, this.resetTransactions);
  }

  resetTransactions() {
    const { currentPage, sizePerPage } = this.props;

    // 経費順送り機能でページ端の経費から前後の経費に移動するために、ページ外の経費も取得しておく必要がある
    const pageOffset = (currentPage - 1) * sizePerPage;
    return this.props.resetTransactions(
      Math.max(0, pageOffset - 1),
      sizePerPage + 2,
    );
  }

  canNotEdit() {
    return !this.props.isEditable || this.props.isSearchWithOnlyDeleted;
  }

  renderEditModeButton() {
    if (this.canNotEdit()) return null;
    const { isEditing } = this.props;

    return (
      <div
        style={{
          float: "left",
          marginRight: "10px",
          marginTop: "3px",
          width: "140px",
        }}
      >
        <label
          className="control-label"
          style={{ float: "left", marginTop: "3px", marginRight: "10px" }}
        >
          {i18next.t("transactions.index.editMode")}
        </label>
        <FlipSwitch
          checked={isEditing}
          onChange={() => {
            this.props.onClickEditModeSwitch(!isEditing);
          }}
        />
      </div>
    );
  }

  isSearchScopeAll() {
    return this.props.searchConditions && this.props.withSearchBox
      ? this.props.searchConditions.scope === true
      : false;
  }

  renderEditAllButton(selectedTransactions) {
    if (this.canNotEdit()) return null;
    const selected = selectedTransactions.length > 0;
    let preReportTitle = null;
    if (uniq(selectedTransactions.map((x) => x.preReportTitle)).length === 1) {
      preReportTitle = get(selectedTransactions, "[0].preReportTitle", null);
    }

    return (
      <button
        type="button"
        className={`btn btn-outline btn-primary${selected ? "" : " disabled"}`}
        onClick={() =>
          selectedTransactions.length === 1
            ? this.handleTransactionModalOpen(selectedTransactions[0])
            : this.props.onClickUpdateAllButton(
                {
                  ...this.props.defaultTransaction,
                  ids: selectedTransactions.map((x) => x.id),
                },
                preReportTitle,
              )
        }
      >
        {i18next.t("transactions.index.editSelected")}
      </button>
    );
  }

  renderDetachAllButton(selectedTransactions) {
    const selected = selectedTransactions.length > 0;

    return (
      <button
        type="button"
        className={`btn btn-outline btn-danger${selected ? "" : " disabled"}`}
        onClick={() => this.refs.detachModal.open()}
      >
        {i18next.t("transactions.index.detachSelected")}
      </button>
    );
  }

  renderMarkAsReadAllButton(transactions) {
    const enable = transactions.length > 0;

    return (
      <button
        type="button"
        className={`btn btn-outline btn-danger${enable ? "" : " disabled"}`}
        onClick={() => this.refs.markAsReadModal.open()}
      >
        {i18next.t("commons.actions.markAllAsRead")}
      </button>
    );
  }

  canNotMerge() {
    return (
      this.props.isSearchWithOnlyDeleted ||
      !(this.props.manualMergeable && this.props.isMultipleSelected)
    );
  }

  renderMergeTransactionsButton() {
    if (this.canNotMerge()) return null;

    return (
      <button
        type="button"
        className="btn btn-outline btn-primary"
        onClick={() => this.props.openMergeableTransactionsPairsSearchModal()}
      >
        {i18next.t("transactions.index.mergeSelected")}
      </button>
    );
  }

  renderMergeableTransactionsModal() {
    return (
      <div>
        <MergeableTransactionsSearchModal
          show={this.props.pairsSearch.modal}
          onHide={() => this.props.closeMergeableTransactionsPairsSearchModal()}
          option={this.props.pairsSearch.option}
          editOption={this.props.editOption}
          search={() => this.props.searchMergeableTransactionsPairs()}
        />
        <ManualMergeModal
          show={this.props.mergeSelecter.modal}
          onHide={() => this.props.closeMergedSelecter()}
          mergeTargetList={this.props.pairsSearch.pairs}
          mergeTransactions={() => this.props.mergeTransactions()}
          selectMergeTarget={(pairIndex) =>
            this.props.selectMergedTransaction(pairIndex)
          }
        />
      </div>
    );
  }

  canNotDelete() {
    return !this.props.showDeleteButton || this.props.isSearchWithOnlyDeleted;
  }

  renderDeleteButton(selectedTransactions) {
    if (this.canNotDelete()) return null;
    const selected = selectedTransactions.length > 0;
    const { isDeletable } = this.props;

    return (
      <button
        type="button"
        className={`btn btn-outline btn-danger${
          isDeletable && selected ? "" : " hidden"
        }`}
        onClick={() => {
          this.refs.deleteModal.open();
        }}
      >
        {i18next.t("transactions.index.deleteSelected")}
      </button>
    );
  }

  render() {
    const {
      entryForms,
      withSearchBox,
      isEditable,
      defaultPeriod,
      isEditing,
      isSearchWithOnlyDeleted,
      isDetachable,
      modalTransaction,
      previousExpenseId,
      nextExpenseId,
      showDeleteButton,
    } = this.props;

    const selected = this.filterSelected(this.props.transactions);
    let entryForm = null;
    if (modalTransaction) {
      if (get(modalTransaction, "ids", []).length) {
        const editable = selected.every((t) => t.editable);
        entryForm = {
          id: "multiple",
          name: "経費編集",
          sort: 0,
          enable: true,
          formFields: formFieldsForMultipleEdit(
            selected.some((t) => t.preReportId || t.reportId),
          ).map((field) => ({ ...field, editable })),
        };
      } else {
        entryForm = entryForms.find((form) => {
          if (form.id === "allowance") {
            return (
              form.directProductTableId ===
              this.props.modalTransaction.directProductTableId
            );
          }

          return form.id === this.props.modalTransaction.formId;
        });
      }
    }

    const hasPreReport = entryForms.some(
      (form) => form.id === "pre_report" && !isNil(form.enabled),
    );

    const searchBox = withSearchBox ? (
      <AccordionButton
        className="transaction-table-appendix"
        defaultOpen={false}
        renderButton={() => (
          <span>
            <i className="fa fa-fw fa-search txt-accent" />
            <span className="button-text">
              {i18next.t("transactions.index.searchbox.openForm")}
            </span>
          </span>
        )}
      >
        <TransactionSearchBox
          ref="searchBox"
          onClickSearchButton={this.props.onClickSearchButton}
          onClickResetSearchConditionButton={
            this.props.onClickResetSearchConditionsButton
          }
          searchCondition={this.props.searchConditions}
          setSearchConditions={this.props.setSearchConditions}
          isSearching={this.props.inProcess}
        />
      </AccordionButton>
    ) : null;

    return (
      <div className="transaction-list-editor">
        {searchBox}
        <div className="card-content">
          {" "}
          {/* テーブル本体は余白をとるために、card-contentを使う */}
          <div className="table-btn-group">
            {this.renderEditModeButton()}
            {this.renderEditAllButton(selected)}
            {isDetachable && this.renderDetachAllButton(selected)}
            {this.props.authority === "approver" &&
              this.renderMarkAsReadAllButton(this.props.transactions)}
            {this.renderDeleteButton(selected)}
            {this.renderMergeTransactionsButton()}
            <SimpleModal
              ref="deleteModal"
              title={i18next.t("transactions.messages.confirmDelete")}
              button={{
                text: i18next.t("commons.actions.delete"),
                color: "danger",
                onClick: this.handleDestroy,
              }}
            />
            <SimpleModal
              ref="detachModal"
              title={i18next.t("transactions.messages.confirmAllDetach")}
              button={{
                text: i18next.t("reports.requests.detach"),
                color: "danger",
                onClick: this.handleDetach,
              }}
            />
            <SimpleModal
              ref="markAsReadModal"
              title={i18next.t("commons.messages.markAllAsRead")}
              button={{
                text: i18next.t("commons.actions.markAllAsRead"),
                color: "danger",
                onClick: this.handleMarkAsRead,
              }}
            />
            {this.props.manualMergeable
              ? this.renderMergeableTransactionsModal()
              : null}
          </div>
          <EditableTransactionTable
            fetchTransactions={this.props.fetchTransactions}
            isEditing={isEditing}
            isEditable={isEditable}
            isDetachable={isDetachable}
            isSearchScopeAll={this.isSearchScopeAll()}
            transactions={this.props.transactions}
            members={
              this.props.searchBoxRef
                ? this.props.searchBoxRef.state.employeesInfo
                : []
            }
            transactionsObj={this.props.transactionsObj}
            currentPage={this.props.currentPage}
            sizePerPage={this.props.sizePerPage}
            columnVisibilities={this.props.columnVisibilities}
            histories={this.props.histories}
            selectedTransactionIdForHistory={
              this.props.selectedTransactionIdForHistory
            }
            editExceptions={this.props.editExceptions}
            only={this.props.only}
            except={this.props.except}
            onRowClick={this.handleTransactionModalOpen}
            onSelect={this.props.selectTransactions}
            onSortChange={this.props.onSortChange}
            resetTransactions={this.resetTransactions}
            openHistoryModal={this.props.openHistoryModal}
            closeHistoryModal={this.props.closeHistoryModal}
            openCostAllocationModal={this.props.openCostAllocationModal}
            costAllocationHovorRowIdx={this.props.costAllocationHovorRowIdx}
            onMouseEnterCostAlocationDiv={
              this.props.onMouseEnterCostAlocationDiv
            }
            onMouseLeaveCostAlocationDiv={
              this.props.onMouseLeaveCostAlocationDiv
            }
            authority={this.props.authority}
            /* 申請可能経費を検索などで件数がズレる場合がある時に使用する。falseの時に、結果件数を表示しない。 */
            showNumberOfResults={this.props.showNumberOfResults}
            hasPreReport={hasPreReport}
            reportRequiresWithholding={this.props.reportRequiresWithholding}
            hideSelectColumn={isSearchWithOnlyDeleted}
            onUpdateTransaction={this.props.onUpdateTransaction}
          />
        </div>
        <TransactionModalWrapper
          show={this.props.isTransactionModalOpen}
          transaction={{ ...this.props.modalTransaction, defaultPeriod }}
          formFields={get(entryForm, "formFields", [])}
          authority={this.props.authority}
          onCreateSuccess={this.handleCreateSuccess}
          onUpdateSuccess={this.handleUpdateSuccess}
          onDestroySuccess={this.handleDestroySuccess}
          onDetachSuccess={this.handleDetachSuccess}
          onDeleteImage={this.handleImageDestroy}
          onSplitSuccess={this.handleSplitSuccess}
          closeModal={this.props.closeTransactionModal}
          shouldSelectSelfAsCompanion={this.props.shouldSelectSelfAsCompanion}
          onSelectCompanionsCategory={this.props.onSelectCompanionsCategory}
          onUnread={this.handleMarkAsUnread}
          onRotateImage={this.props.rotateReceiptFile}
          ownerId={this.props.modalTransaction?.ownerId || this.props.ownerId}
          onGoToPreviousExpense={
            previousExpenseId ? this.handleGoToPreviousExpense : void 0
          }
          onGoToNextExpense={
            nextExpenseId ? this.handleGoToNextExpense : void 0
          }
          disableAssignableRportInput={this.props.disableAssignableRportInput}
        />
        <CostAllocationDepartmentModal
          show={this.props.isCostAllocationModalOpen}
          close={this.props.closeCostAllocationModal}
          costAllocations={this.props.costAllocations}
        />
      </div>
    );
  }
}

TransactionListEditor.defaultProps = {
  isAddable: true,
  isEditable: true,
  isDeletable: true,
  isDetachable: false,
  isEditing: false,
  withSearchBox: true,
  isSearchWithOnlyDeleted: false,
  authority: "approval",
  showNumberOfResults: true,
  shouldSelectSelfAsCompanion: true,
  reportRequiresWithholding: true,
  showDeleteButton: true,
  onSelectCompanionsCategory: () => null,
  fetchTransactions: () => null, // ページ切替時の取得が必要な場合は指定する。経費申請詳細画面では一度に経費を取得しているため、指定不要。
};

TransactionListEditor.propTypes = {
  fetchTransactions: PropTypes.func.isRequired,
  isAddable: PropTypes.bool.isRequired,
  isEditable: PropTypes.bool.isRequired,
  isDeletable: PropTypes.bool.isRequired,
  isDetachable: PropTypes.bool.isRequired,
  isEditing: PropTypes.bool.isRequired,
  isSearchWithOnlyDeleted: PropTypes.bool.isRequired,
  withSearchBox: PropTypes.bool.isRequired,
  defaultSearchCondition: PropTypes.object,
  openHistoryModal: PropTypes.func,
  isCostAllocationModalOpen: PropTypes.bool.isRequired,
  openCostAllocationModal: PropTypes.func.isRequired,
  closeCostAllocationModal: PropTypes.func.isRequired,
  resetTransactions: PropTypes.func.isRequired,
  authority: PropTypes.string.isRequired,
  selectedTransactionIdForHistory: PropTypes.string,
  /* 申請可能経費を検索などで件数がズレる場合がある時に使用する。falseの時に、結果件数を表示しない。 */
  showNumberOfResults: PropTypes.bool.isRequired,
  shouldSelectSelfAsCompanion: PropTypes.bool.isRequired,
  showDeleteButton: PropTypes.bool.isRequired,
  onSelectCompanionsCategory: PropTypes.func.isRequired,
  reportRequiresWithholding: PropTypes.bool.isRequired,
  /** 申請書などの申請者ID */
  ownerId: PropTypes.string,
};
