import { LocalStorageUtil } from "utilities/local_storage_util";

export interface RequestsStorageHeaderDisplayParam {
  /** 表示key */
  readonly accessor: string;
  /** 順番 */
  readonly index: number;
  /** 表示するか */
  readonly isShow: boolean;
}
interface TableStorageParamHeaderDisplay {
  /** 経費申請 */
  readonly reports: RequestsStorageHeaderDisplayParam[] | null;
  /** 事前申請 */
  readonly preReports: RequestsStorageHeaderDisplayParam[] | null;
  /** 汎用申請 */
  readonly applications: RequestsStorageHeaderDisplayParam[] | null;
  /** 支払依頼申請 */
  readonly paymentRequestsReports: RequestsStorageHeaderDisplayParam[] | null;
  /** 稟議申請 */
  readonly paymentRequestsProposals: RequestsStorageHeaderDisplayParam[] | null;
  /** 一括支払依頼申請 */
  readonly paymentRequestsConsolidatedReports:
    | RequestsStorageHeaderDisplayParam[]
    | null;
}
export interface RequestsStorageHeaderWidthReport {
  readonly createdAt: number;
  readonly title: number;
  readonly sequence: number;
  readonly needsTemporaryPayment: number;
  readonly approvalFlowName: number;
  readonly applicant: number;
  readonly projects: number;
  readonly amount: number;
  readonly withholding: number;
  readonly preAmount: number;
  readonly department: number;
  readonly matchedOriginalReceipt: number;
  readonly statusForApprover: number;
  readonly status: number;
  readonly buttons: number;
}
export interface RequestsStorageHeaderWidthPreReport {
  readonly title: number;
  readonly sequence: number;
  readonly approvalFlowName: number;
  readonly applicant: number;
  readonly project: number;
  readonly department: number;
  readonly temporaryPaymentDueAt: number;
  readonly preAmount: number;
  readonly statusForApprover: number;
  readonly status: number;
  readonly buttons: number;
}
export interface RequestsStorageHeaderWidthApplication {
  readonly title: number;
  readonly sequence: number;
  readonly approvalFlowName: number;
  readonly applicant: number;
  readonly statusForApprover: number;
  readonly status: number;
  readonly buttons: number;
}
export interface RequestsStorageHeaderWidthPRReport {
  readonly createdAt: number;
  readonly dueAt: number;
  readonly scheduleAt: number;
  readonly appropriatedAt: number;
  readonly supplierCode: number;
  readonly supplierName: number;
  readonly title: number;
  readonly sequenceNumber: number;
  readonly approvalFlowName: number;
  readonly projects: number;
  readonly proposalSequenceNumbers: number;
  readonly requester: number;
  readonly belongingDepartment: number;
  readonly registratedNumber: number;
  readonly asInvoice: number;
  readonly reportNumber: number;
  readonly amount: number;
  readonly withholding: number;
  readonly memo: number;
  readonly reportLabels: number;
  readonly statusForApprover: number;
  readonly status: number;
  readonly buttons: number;
}
export interface RequestsStorageHeaderWidthPRProposal {
  readonly createdAt: number;
  readonly scheduleAt: number;
  readonly title: number;
  readonly sequenceNumber: number;
  readonly approvalFlowName: number;
  readonly projects: number;
  readonly proposals: number;
  readonly proposalSequenceNumbers: number;
  readonly requester: number;
  readonly belongingDepartment: number;
  readonly amount: number;
  readonly withholding: number;
  readonly statusForApprover: number;
  readonly status: number;
  readonly buttons: number;
}
export interface RequestsStorageHeaderWidthPRConsolidatedReport {
  readonly createdAt: number;
  readonly title: number;
  readonly sequenceNumber: number;
  readonly approvalFlowName: number;
  readonly projects: number;
  readonly requester: number;
  readonly belongingDepartment: number;
  readonly totalAmount: number;
  readonly totalWithholding: number;
  readonly statusForApprover: number;
  readonly status: number;
  readonly buttons: number;
}
interface StorageHeaderWidth {
  /** 経費申請 */
  readonly report: RequestsStorageHeaderWidthReport;
  /** 事前申請 */
  readonly preReport: RequestsStorageHeaderWidthPreReport;
  /** 汎用申請 */
  readonly application: RequestsStorageHeaderWidthApplication;
  /** 支払依頼申請 */
  readonly paymentRequestsReport: RequestsStorageHeaderWidthPRReport;
  /** 稟議申請 */
  readonly paymentRequestsProposal: RequestsStorageHeaderWidthPRProposal;
  /** 一括支払依頼申請 */
  readonly paymentRequestsConsolidatedReport: RequestsStorageHeaderWidthPRConsolidatedReport;
}

/**
 * LocalStrage: テーブルの設定を保存する
 */
export interface RequestsTableStorageParam {
  /** バージョン */
  readonly _version?: string;
  /** 選択しているページのサイズ */
  readonly sizePerPage: number;
  /** ヘッダーカラムの横幅 */
  readonly headerWidth: StorageHeaderWidth;
  /** ヘッダーカラムの表示と順序 */
  readonly headerDisplay: TableStorageParamHeaderDisplay;
}

/**
 * スキーマのバージョン。変更するたびに上げる。
 * ただし、バージョン比較が文字列比較なので、バージョンの各桁はそれぞれ0~9までの数字のみとする。（例、OK = 1.0.9、NG = 1.0.11 ）
 */
const version = "1.0.0";
const key = ["requests", "table"];
const defaultData: RequestsTableStorageParam = {
  sizePerPage: 20,
  headerDisplay: {
    reports: null,
    preReports: null,
    applications: null,
    paymentRequestsReports: null,
    paymentRequestsProposals: null,
    paymentRequestsConsolidatedReports: null,
  },
  headerWidth: {
    report: {
      createdAt: 120,
      title: 220,
      sequence: 120,
      needsTemporaryPayment: 150,
      approvalFlowName: 120,
      applicant: 120,
      projects: 120,
      amount: 120,
      withholding: 120,
      preAmount: 120,
      department: 120,
      matchedOriginalReceipt: 120,
      statusForApprover: 190,
      status: 190,
      buttons: 200,
    },
    preReport: {
      title: 220,
      sequence: 120,
      approvalFlowName: 120,
      applicant: 120,
      project: 120,
      department: 120,
      temporaryPaymentDueAt: 120,
      preAmount: 120,
      statusForApprover: 190,
      status: 190,
      buttons: 200,
    },
    application: {
      title: 220,
      sequence: 200,
      approvalFlowName: 220,
      applicant: 150,
      statusForApprover: 190,
      status: 190,
      buttons: 200,
    },
    paymentRequestsReport: {
      createdAt: 120,
      dueAt: 120,
      scheduleAt: 120,
      appropriatedAt: 120,
      supplierCode: 150,
      supplierName: 120,
      title: 220,
      sequenceNumber: 120,
      approvalFlowName: 120,
      projects: 120,
      proposalSequenceNumbers: 120,
      requester: 120,
      belongingDepartment: 250,
      registratedNumber: 140,
      asInvoice: 100,
      reportNumber: 120,
      amount: 120,
      withholding: 120,
      memo: 120,
      reportLabels: 140,
      statusForApprover: 190,
      status: 190,
      buttons: 200,
    },
    paymentRequestsProposal: {
      createdAt: 110,
      scheduleAt: 110,
      title: 220,
      sequenceNumber: 120,
      approvalFlowName: 120,
      projects: 120,
      proposals: 120,
      proposalSequenceNumbers: 120,
      requester: 120,
      belongingDepartment: 120,
      amount: 120,
      withholding: 120,
      statusForApprover: 190,
      status: 190,
      buttons: 200,
    },
    paymentRequestsConsolidatedReport: {
      createdAt: 110,
      title: 220,
      sequenceNumber: 120,
      approvalFlowName: 120,
      projects: 120,
      requester: 120,
      belongingDepartment: 120,
      totalAmount: 120,
      totalWithholding: 120,
      statusForApprover: 190,
      status: 190,
      buttons: 200,
    },
  },
};
const RequestsTableStorage = new LocalStorageUtil<RequestsTableStorageParam>(
  version,
  key,
  defaultData,
);

export default RequestsTableStorage;
