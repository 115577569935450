import React from 'react';
import styled from 'styled-components';
import { Dropdown as BSDropdown } from 'react-bootstrap';
import { DropdownAlign, DropdownDirection } from './types';

interface Props {
  /** Dropdownに渡すid */
  id: string;
  /** メニューを表示する方向 */
  direction?: DropdownDirection;
  /** メニューを整列する方向 */
  align?: DropdownAlign;
  /** トグルボタン */
  toggleButton: JSX.Element;
  /** メニューの最大高さ
   *  メニューの高さがここで指定した高さを超える場合スクロールを発生させる。
   *  大量のメニュー表示が想定される場合、画面を見切れる恐れがあるので指定する。
   */
  menuMaxHeight?: string;
  /** ホバーでメニューを開くか */
  openOnHover?: boolean;
}

const BSDropdownView = styled(BSDropdown)<{ openonhover?: boolean }>`
  :hover > .dropdown-menu {
    display: ${({ openonhover }): string => (openonhover ? 'block' : 'hidden')};
  }
`;

/** Dropdown */
export const Dropdown: React.FC<Props> = ({
  id,
  direction = 'down',
  align = 'right',
  toggleButton,
  children,
  menuMaxHeight = 'none',
  openOnHover,
}) => {
  /** メニューを出す方向 */
  const directionStyle = (dropdownDirection: DropdownDirection): {[key: string]: string} => {
    switch (dropdownDirection) {
      case 'up':
        return {
          top: 'auto',
          bottom: 'calc(100% + 4px)',
        };
      case 'right':
        return {
          right: 'auto',
          left: 'calc(100% + 4px)',
        };
      case 'left':
        return {
          right: 'calc(100% + 4px)',
          left: 'auto',
        };
      case 'over':
        return {
          top: '-8px',
          bottom: 'auto',
        };
      default:
        return {
          top: 'calc(100% + 4px)',
          bottom: 'auto',
        };
    }
  };

  /** メニューとボタンを整列する方向 */
  const alignStyle = (dropdownAlign: DropdownAlign): {[key: string]: string} => {
    switch (dropdownAlign) {
      case 'top':
        return {
          top: '0',
          bottom: 'auto',
        };
      case 'bottom':
        return {
          top: 'auto',
          bottom: '0',
        };
      case 'left':
        return {
          right: 'auto',
          left: '0',
        };
      default:
        return {
          right: '0',
          left: 'auto',
        };
    }
  };

  return (
    <BSDropdownView id={ id } openonhover={ openOnHover }>
      { toggleButton }
      {/* Dropdown.Menuを切り出すと、childrenのレンダリングがうまく行かなく直接スタイル書く */}
      <BSDropdown.Menu
        style={ {
          padding: '8px 0',
          border: 'none',
          maxHeight: menuMaxHeight,
          minWidth: '160px',
          overflowY: menuMaxHeight === 'none' ? 'visible' : 'auto',
          ...directionStyle(direction),
          ...alignStyle(align),
        } }
      >
        { children }
      </BSDropdown.Menu>
    </BSDropdownView>
  );
};
