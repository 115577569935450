import Clearable from 'components/fields/Clearable';
import PropTypes from 'prop-types';
import React from 'react';
import { ControlLabel } from 'react-bootstrap';
import { getUserLocale } from 'utilities/Utils';

/**
 * 任意の入力値の幅を入力する
 */
const RangeField = (props) => {
  const rangeClass = props.oneline ? 'col-sm-6 no-padding' : 'col-sm-12 no-padding multiline';

  return (
    <div className={ `form-group ${props.className}` }>
      <ControlLabel>{ props.title }</ControlLabel>
      <div className={ `period-input` }>
        <div className={ rangeClass }>
          { props.locale === 'en' && <span className='col-sm-4 no-padding preposition-text'>From</span> }
          <div className='col-sm-8 no-padding'>
            <Clearable onClear={ () => props.onChange('from') }>
              {
                props.renderInput(
                  'from',
                  { value: props.from, onChange: props.onChange.bind(null, 'from') },
                )
              }
            </Clearable>
          </div>
          { props.locale === 'ja' && <span className='col-sm-4 no-padding preposition-text'>から</span> }
        </div>
        <div className={ rangeClass }>
          { props.locale === 'en' && <span className='col-sm-4 no-padding preposition-text'>To</span> }
          <div className='col-sm-8 no-padding'>
            <Clearable onClear={ () => props.onChange('to') }>
              {
                props.renderInput(
                  'to',
                  { value: props.to, onChange: props.onChange.bind(null, 'to') },
                )
              }
            </Clearable>
          </div>
          { props.locale === 'ja' && <span className='col-sm-4 no-padding preposition-text'>まで</span> }
        </div>
      </div>
    </div>
  );
};

RangeField.defaultProps = {
  title: '',
  renderInput: (_type, childProps) => {
    return (<input className='form-control' { ...childProps } />);
  },
  locale: getUserLocale(),
  oneline: true,
  className: '',
  from: null,
  to: null,
  onChange: () => { /* none */ },
};

RangeField.propTypes = {
  title: PropTypes.string,
  renderInput: PropTypes.func.isRequired,
  locale: PropTypes.string,
  oneline: PropTypes.bool,
  className: PropTypes.string,
  from: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  to: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  onChange: PropTypes.func.isRequired,
};

export default React.memo(RangeField);
