import get from "lodash/get";
import isNil from "lodash/isNil";
import pick from "lodash/pick";
import moment from "moment";
import presetEntryFormType from "./keyMap";
import { canRead } from "./transactionFormPolicy";

export function buildFormValues(formFields, initTransaction = null) {
  // 経費の新規作成時には{}となる。詳細/編集時にkey及びvalueが入る
  // generic_fields_input(汎用マスタ)以外でまずはformValuesを組み立てる
  const formValues = get(initTransaction, "formValues", [])
    .filter((v) => v.type !== "generic_fields_input")
    .reduce((obj, value) => {
      const key = value.id || value.type;

      return { ...obj, [key]: value };
    }, {});

  const genericFieldsInputs = get(initTransaction, "formValues", []).filter(
    (v) => v.type === "generic_fields_input",
  );
  if (genericFieldsInputs.length) {
    // 汎用マスタの入力フォーム(form_fields)毎にgeneric_fields_inputが分割してリクエストが来る。ストアの構築前にそれらを統合し、右の形で取り扱えるようにする genericFields: [{ dataSetId: '◯◯', items: [{},...]},...]
    const formValuesOfGenericFields = genericFieldsInputs.reduce(
      (acc, value) => {
        return [...acc, { dataSetId: value.dataSetId, items: value.formValue }];
      },
      [],
    );

    // eslint-disable-next-line dot-notation
    formValues["generic_fields_input"] = {
      id: null,
      type: "generic_fields_input",
      formValue: formValuesOfGenericFields,
    };
  }

  return formFields.reduce((valueObj, field) => {
    const id = field.id;
    if (id) {
      const defaultValue = field.conditions.length ? null : field.defaultValue;

      let formValue = get(formValues[id], "formValue");
      if (field.type === "date_input") {
        formValue = formValue ? moment(formValue).format("YYYY/MM/DD") : null;
      }
      return { ...valueObj, [id]: formValue || defaultValue };
    }

    switch (field.type) {
      // 経路情報と日当表の入力値は別action/reducerで管理するため
      case "pre_report_summary_input":
        return { ...valueObj, [field.type]: null };
      case "route_input":
      case "direct_product_table_input":
        return {
          ...valueObj,
          [field.type]: get(
            formValues.expense_input,
            "formValue",
            expenseValues,
          ),
        };
      case "expense_input": {
        const defaultExpense = field.conditions.length
          ? expenseValues
          : field.defaultValue;

        return {
          ...valueObj,
          [field.type]: get(
            formValues[field.type],
            "formValue",
            defaultExpense,
          ),
        };
      }
      case "report_input":
      case "pre_report_input": {
        const defaultValue = field.conditions.length
          ? null
          : get(field.defaultValue, "title");
        const reportTitleValue =
          get(formValues[field.type], "formValue.title") ||
          get(formValues[field.type], "formValue") ||
          defaultValue;
        const reportIdValue = get(formValues[field.type], "formValue.id");

        return {
          ...valueObj,
          [field.type]: { id: reportIdValue, title: reportTitleValue },
        };
      }
      case "assignable_report_input": {
        const defaultValue = field.conditions.length
          ? null
          : get(field.defaultValue, "title");
        const reportTitleValue =
          get(formValues[field.type], "formValue.title") ||
          get(formValues[field.type], "formValue") ||
          defaultValue;
        const reportIdValue = get(formValues[field.type], "formValue.id");
        const reportTypeValue = get(formValues[field.type], "formValue.type");
        return {
          ...valueObj,
          [field.type]: {
            id: reportIdValue,
            title: reportTitleValue,
            type: reportTypeValue,
          },
        };
      }
      case "date_input": {
        const defaultValue =
          field.conditions.length || isNil(field.defaultValue)
            ? null
            : moment(field.defaultValue).format();
        const transactedAt = get(
          formValues[field.type],
          "formValue",
          defaultValue,
        );
        //  ISO8601形式。EntryForms側でlacale情報反映も反映したYYYY/MM/DD形式に変換。
        return {
          ...valueObj,
          [field.type]: isNil(transactedAt)
            ? null
            : moment(transactedAt).format(),
        };
      }
      case "shop_input": {
        const defaultValue = field.conditions.length
          ? null
          : get(field.defaultValue, "name", null);
        return {
          ...valueObj,
          [field.type]: get(
            formValues[field.type],
            "formValue.name",
            defaultValue,
          ),
        };
      }
      case "project_input": {
        const defaultValue = field.conditions.length
          ? null
          : field.defaultValue;
        return {
          ...valueObj,
          [field.type]: get(formValues[field.type], "formValue", defaultValue),
        };
      }
      case "cost_allocation_input":
      case "group_input": {
        const defaultValue = field.conditions.length
          ? []
          : field.defaultValue || [];
        const costAllocations = get(
          formValues[field.type],
          "formValue",
          defaultValue,
        );
        return {
          ...valueObj,
          [field.type]: costAllocations.length
            ? costAllocations
            : emptyCostAllocation,
        };
      }
      case "companion_input": {
        const defaultValue = field.conditions.length
          ? []
          : field.defaultValue || [];
        return {
          ...valueObj,
          [field.type]: get(formValues[field.type], "formValue", defaultValue),
        };
      }
      case "generic_fields_input": {
        // formFieldsの中には複数のgeneric_fields_inputが入っているが、フロントエンドではformValuesの中でgeneric_fields: [ {dataSetId: 'hoge', items: []},...]の形でまとめて状態管理している
        const defaultValue = formFields
          .filter((f) => f.type === "generic_fields_input")
          .map((f) => ({
            dataSetId: f.dataSetId,
            items: [],
          }));
        return {
          ...valueObj,
          [field.type]: get(formValues[field.type], "formValue", defaultValue),
        };
      }
      case "origin_and_destination_by_category_input": {
        const defaultValue = field.conditions.length
          ? {}
          : field.defaultValue || {};
        return {
          ...valueObj,
          [field.type]: get(formValues[field.type], "formValue", defaultValue),
        };
      }
      case "super_category_input": {
        // 貸方勘定は条件の有無に関わらずデフォルト値でフィールドを初期化する
        return {
          ...valueObj,
          [field.type]: get(
            formValues[field.type],
            "formValue",
            field.defaultValue,
          ),
        };
      }
      case "department_input": {
        // デフォルト所属部署情報は主所属部署とする
        // まとめて編集時はデフォルトをnullにする
        const ids = get(initTransaction, "ids");
        const dep =
          isNil(ids) || ids.length < 2 ? userPreferences.mainDepartment : null;
        return {
          ...valueObj,
          [field.type]: get(formValues[field.type], "formValue", dep),
        };
      }
      case "eligible_invoice_confirmation_input": {
        const defaultValue = {
          asEligibleInvoice: false,
          registratedNumber: null,
          invoicingOrganization: undefined,
          paidAddress: null,
        };
        return {
          ...valueObj,
          [field.type]: get(formValues[field.type], "formValue", defaultValue),
        };
      }
      default: {
        const defaultValue = field.conditions.length
          ? null
          : field.defaultValue;
        return {
          ...valueObj,
          [field.type]: get(formValues[field.type], "formValue", defaultValue),
        };
      }
    }
  }, {});
}

export function buildFormFields(
  formFields,
  { authority, status },
  formValues = {},
) {
  return formFields
    .sort((a, b) => a.conditions.length - b.conditions.length)
    .map((field) => {
      const constraints = {
        authority,
        status,
        ...pick(
          formValues,
          field.conditions.map((cond) => cond.type),
        ),
      };

      const show = canRead(field, constraints);

      // 源泉徴収税額は常にフォーム手動入力するまでは自動計算結果が入力される仕様のため
      if (field.type === "withholding_input") {
        return { ...field, show, edited: false };
      }
      return { ...field, show, edited: !isNil(status) && show };
    });
}

export function setFormValues(formValues, prop, value, defaultValues = {}) {
  let obj = {};
  if (Object.keys(formValues).includes(prop)) {
    obj = { [prop]: value };
  } else {
    const formValueKey = presetEntryFormType[prop];

    switch (prop) {
      case "amount":
      case "originalAmount":
      case "originalAmountCurrencyId":
      case "exchangeRate":
      case "exchangePolicy": {
        obj = { expense_input: { ...formValues.expense_input, [prop]: value } }; // eslint-disable-line camelcase
        break;
      }
      case "costAllocations": {
        obj = {
          [formValueKey]:
            isNil(value) || get(value, "length", 0) === 0
              ? emptyCostAllocation
              : value,
        };

        break;
      }
      case "reportTitle":
      case "preReportTitle": {
        // report_input, pre_report_inputはprop='report', 'pre_report'で更新されているので、
        // 何もしない
        break;
      }
      default: {
        obj = { [formValueKey]: value };
        break;
      }
    }
  }

  return { ...formValues, ...defaultValues, ...obj };
}

// parentに渡されたformValuesの値でchildに渡されたformValuesの値を上書きし、formFieldsの形式の配列で返す
export function foldFormValues(formFields, inputTypes, ...paramsList) {
  paramsList.reverse();
  const params = { default: {}, additional: {} };

  paramsList.forEach(({ formValues, fields }) => {
    fields.forEach((f) => {
      if (isNil(f.id) && inputTypes.default.includes(f.type)) {
        params.default[f.type] = formValues[f.type];
      }

      if (!isNil(f.id) && inputTypes.additional.includes(f.type)) {
        params.additional[f.type] = formValues[f.id];
      }
    });
  });

  return formFields.map((formField) =>
    formField.type === "generic_fields_input"
      ? {
          id: formField.id,
          type: formField.type,
          dataSetId: formField.dataSetId, // 'generic_fields_input'の時はdataSetIdが必要
          formValue: [], // 'generic_fields_input'の時は[]とする必要がある
        }
      : {
          id: formField.id,
          type: formField.type,
          formValue: isNil(formField.id)
            ? params.default[formField.type]
            : params.additional[formField.type],
        },
  );
}

const emptyCostAllocation = [
  {
    payerType: "Group",
    payerId: null,
    payerName: null,
    numerator: 100,
    denominator: 100,
  },
];

const expenseValues = {
  amount: null,
  originalAmount: null,
  originalAmountCurrencyId: null,
  exchangeRate: 1.0,
  exchangePolicy: "floor",
};
