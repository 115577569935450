import React from "react";

/**
 * 日当表が font awesome で利用しているアイコン名
 * @see https://stackoverflow.com/a/54061487
 */
const faIcon = ["book", "car", "truck", "star"];
type FaIconSet = (typeof faIcon)[number];

/**
 * 日当表が material icon で利用しているアイコン名
 * @see https://stackoverflow.com/a/54061487
 */
const materialIcon = ["flight", "bag", "hotel"];
type MaterialIconSet = (typeof materialIcon)[number];

interface Props {
  readonly iconName: FaIconSet | MaterialIconSet;
}

/**
 * 日当で使用しているアイコン
 * TODO: 経費一覧ページ/申請詳細ページ/承認詳細ページで使用しているため注意
 */
export const AllowanceIcon = ({
  iconName,
}: Props): React.ReactElement | null => {
  if (faIcon.includes(iconName)) {
    return <i className={`fa fa-${iconName} fa-fw`} />;
  }

  if (materialIcon.includes(iconName)) {
    return (
      <i
        className="material-icons"
        style={{ marginRight: "10px", fontSize: "16px" }}
      >
        {iconName === "bag" ? "work" : iconName}
      </i>
    );
  }

  return null;
};
