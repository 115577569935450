import FormItem from './FormItem';
import PropTypes from 'prop-types';
import React from 'react';
import formatter from 'utilities/formatter';

const CompanyName = (props) => {
  return (
    <FormItem label='companyName'>
      { formatter.text(props.value, '-') }
    </FormItem>
  );
};

CompanyName.propTypes = {
  value: PropTypes.string,
};

export default React.memo(CompanyName);
