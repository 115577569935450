import React from "react";
import styled from "styled-components";
import colors from "values/colors";

interface Props {
  /** ホバー中か */
  readonly hover: boolean;
  /** サイズ変更可能か */
  readonly isResizing: boolean;
  /** マウスが離れた */
  readonly mouseLeave: () => void;
  /** マウスがホバーした */
  readonly setHover: (hover: boolean) => void;
  /** サイズ変更中 */
  readonly startResizing: (e: React.MouseEvent) => void;
  /** ドラック中 */
  readonly onMouseMove: (e: React.MouseEvent) => void;
  /** マウスクリックを離した */
  readonly stopResizing: () => void;
}

const DraggableAreaView = styled.div<{ isResizing: boolean }>`
  position: fixed;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  cursor: col-resize;
  user-select: ${({ isResizing }): string => (isResizing ? "none" : "auto")};
`;
const ResizeBarView = styled.div<{ isResizing: boolean }>`
  position: absolute;
  left: 0;
  top: 0;
  height: 100%;
  width: 16px;
  cursor: col-resize;
  border-left: 4px solid ${colors.renewaled.main};
  opacity: ${({ isResizing }): number => (isResizing ? 1 : 0)};
  :hover {
    opacity: 1;
    transition-duration: 300ms;
  }
`;

/**
 * 右ドロワー用のResizeBar
 */
export const ResizeBar: React.FC<Props> = ({
  hover,
  startResizing,
  setHover,
  mouseLeave,
  onMouseMove,
  stopResizing,
  isResizing,
}) => {
  return (
    <>
      {hover && (
        <DraggableAreaView
          onMouseMove={onMouseMove}
          onMouseUp={stopResizing}
          isResizing={isResizing}
        />
      )}

      <ResizeBarView
        onMouseDown={startResizing}
        onMouseUp={stopResizing}
        onMouseEnter={(): void => {
          setHover(true);
        }}
        onMouseLeave={mouseLeave}
        draggable={false}
        isResizing={isResizing}
      />
    </>
  );
};
