import React from 'react';
import i18next from 'i18n';
import { ExecutionTimetableSelectField } from './fields/ExecutionTimetableSelectField';
import { ExecutorSelectField } from './fields/ExecutorSelectField';
import { InputField } from 'components/renewaled_ui/forms/inputs';
import { RequiredIcon } from '../../../components/icons/RequiredIcon';
import { ScheduleSelectField } from './fields/ScheduleSelectField';
import { useFormContext } from 'react-hook-form';

export const BaseForm = React.forwardRef((_, ref?: React.Ref<HTMLDivElement>) => {
  const { control } = useFormContext();

  return (
    <>
      <div ref={ ref } />
      <h4 className='title'>
        { i18next.t('accountingDataScheduledExports.tasks.forms.base.title') }
      </h4>
      <InputField
        name='name'
        label={ <>{ i18next.t('accountingDataScheduledExports.tasks.forms.base.name.label') } <RequiredIcon /></> }
        rules={ { required: i18next.t('accountingDataScheduledExports.tasks.forms.base.name.required') } }
        control={ control }
      />
      <ExecutorSelectField />
      <ScheduleSelectField />
      <ExecutionTimetableSelectField
        name='executionTimetable'
      />
      <InputField
        type='date'
        name='periodStartedAt'
        label={ <>{ i18next.t('accountingDataScheduledExports.tasks.forms.base.periodStartedAt.label') }<RequiredIcon /></> }
        rules={ { required: i18next.t('accountingDataScheduledExports.tasks.forms.base.periodStartedAt.required') } }
        control={ control }
        max="2999-12-31"
      />
      <InputField
        type='date'
        name='periodEndedAt'
        label={ <>{ i18next.t('accountingDataScheduledExports.tasks.forms.base.periodEndedAt.label') }<RequiredIcon /></> }
        rules={ { required: i18next.t('accountingDataScheduledExports.tasks.forms.base.periodEndedAt.required') } }
        control={ control }
        max="2999-12-31"
      />
    </>
  );
});
