import * as actions from '../actions/expenseAccount';
import LabeledComponent from 'components/LabeledComponent';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import SuggestField from 'components/fields/SuggestField';
import getExpenseAccountForm from '../selectors/expenseAccount';
import i18next from 'i18n';
import isNil from 'lodash/isNil';
import { companyExpenseAccountType, expenseAccountType } from 'types/expense_account';
import { connect } from 'react-redux';

export class ExpenseAccountForm extends Component {
  constructor(props) {
    super(props);

    this.validateForm = this.validateForm.bind(this);
    this.renderTypeCheck = this.renderTypeCheck.bind(this);
    this.formClass = this.formClass.bind(this);
  }

  componentDidMount() {
    this.props.fetchBanks();
  }

  validateForm() {
    return $(`.${this.formClass()}`).validationEngine('validate');
  }

  formClass() {
    return this.props.forCompany ? 'company-expense-account-form' : 'expense-account-form';
  }

  formatFixedText(text) {
    return (
      <span className='col-sm-6 form-control-static'>
        { text }
      </span>
    );
  }

  render() {
    const { selectedAccount } = this.props;
    const disabled = !selectedAccount.editable;

    if (isNil(selectedAccount.id) && disabled) {
      return null;
    }

    return (
      <form className={ `form-horizontal ${this.formClass()}` }>
        <LabeledComponent
          label={ i18next.t('preferences.expenseAccount.bank') }
          labelClass='col-sm-3'
          className='form-group'>
          <div className='col-sm-6'>
            {
              disabled
                ? this.formatFixedText(selectedAccount.bank.name)
                : <SuggestField
                  async={ false }
                  getOptionText={ (x) => x.name }
                  renderOption={ (x) => x.name }
                  initialOptions={ this.props.bankSuggestions }
                  isStatic={ false }
                  onSelect={ this.props.onBankSelect }
                  onTextChange={ this.props.onBankNameChange }
                  placeholder={ i18next.t('preferences.format.placeholder.bank') }
                  text={ selectedAccount.bank.name || '' }
                  className='form-control validate[required]'
                />
            }
          </div>
        </LabeledComponent>
        <LabeledComponent
          label={ i18next.t('preferences.expenseAccount.bankBranch') }
          labelClass='col-sm-3'
          className='form-group'>
          <div className='col-sm-6'>
            {
              disabled
                ? this.formatFixedText(selectedAccount.bankBranch.name)
                : <SuggestField
                  async={ false }
                  getOptionText={ (x) => x.name }
                  renderOption={ (x) => x.name }
                  initialOptions={ this.props.branchSuggestions }
                  isStatic={ false }
                  onSelect={ this.props.onBranchSelect }
                  onTextChange={ this.props.onBranchNameChange }
                  placeholder={ i18next.t('preferences.format.placeholder.bankBranch') }
                  text={ selectedAccount.bankBranch.name || '' }
                  className='form-control validate[required]'
                />
            }
          </div>
        </LabeledComponent>
        <LabeledComponent
          label={ i18next.t('preferences.expenseAccount.type') }
          labelClass='col-sm-3'
          className='form-group'>
          <div className="col-sm-6">
            {
              disabled
                ? this.formatFixedText(selectedAccount.type)
                : <div className="form-inline">
                  <div className="radio-inline">
                    { this.renderTypeCheck("普通", i18next.t('preferences.expenseAccount.ordinary')) }
                  </div>
                  <div className="radio-inline">
                    { this.renderTypeCheck(this.props.forCompany ? "当座" : "貯蓄", this.props.forCompany ? i18next.t('preferences.expenseAccount.current') : i18next.t('preferences.expenseAccount.saving')) }
                  </div>
                </div>
            }
          </div>
        </LabeledComponent>
        <LabeledComponent
          label={ i18next.t('preferences.expenseAccount.number') }
          labelClass='col-sm-3'
          className='form-group'>
          <div className="col-sm-6">
            {
              disabled
                ? this.formatFixedText(selectedAccount.number)
                : < input
                  type='number'
                  className='form-control validate[required,number,minSize[7],maxSize[7]]'
                  value={ selectedAccount.number || '' }
                  onChange={ (e) => this.props.onAccountNumberChange(e.target.value) }
                  placeholder={ i18next.t('preferences.format.placeholder.number') }
                />
            }
          </div>
        </LabeledComponent>
        <LabeledComponent
          label={ i18next.t('preferences.expenseAccount.holderKana') }
          labelClass='col-sm-3'
          className='form-group'>
          <div className="col-sm-6">
            {
              disabled
                ? this.formatFixedText(selectedAccount.holderKana)
                : < input
                  type='text'
                  className='form-control validate[required,maxSize[40]]'
                  value={ selectedAccount.holderKana || '' }
                  onChange={ (e) => this.props.onHolderKanaChange(e.target.value) }
                  placeholder={ i18next.t('preferences.format.placeholder.holderKana') }
                />
            }
          </div>
        </LabeledComponent>
        { this.renderCompanyForm() }
      </form>
    );
  }

  renderCompanyForm() {
    return this.props.forCompany ? (
      <LabeledComponent
        label={ i18next.t('preferences.expenseAccount.customerCode') }
        labelClass='col-sm-3'
        className='form-group'>
        <div className="col-sm-6">
          <input
            type='number'
            className='form-control validate[required,number,minSize[10],maxSize[10]]]'
            value={ this.props.selectedAccount.customerCode || '' }
            onChange={ (e) => this.props.onCustomerCodeChange(e.target.value) }
            placeholder={ i18next.t('preferences.format.placeholder.customerCode') }
          />
        </div>
      </LabeledComponent>
    ) : null;
  }

  renderTypeCheck(value, displayName) {
    return (
      <label>
        <input type='radio'
          name='type'
          value={ value }
          checked={ this.props.selectedAccount.type === value }
          onChange={ (e) => this.props.onAccountTypeChange(e.target.value) }
        />
        { displayName }
      </label>
    );
  }
}

ExpenseAccountForm.defaultProps = {
  forCompany: false,
  forModal: false,
  inProcess: false,
};

ExpenseAccountForm.propTypes = {
  fetchBanks: PropTypes.func.isRequired,
  forCompany: PropTypes.bool.isRequired,
  forModal: PropTypes.bool.isRequired,
  inProcess: PropTypes.bool.isRequired,
  onAccountNumberChange: PropTypes.func.isRequired,
  onAccountTypeChange: PropTypes.func.isRequired,
  onBankNameChange: PropTypes.func.isRequired,
  onBankSelect: PropTypes.func.isRequired,
  onBankSuggestionsUpdateRequested: PropTypes.func.isRequired,
  onBranchNameChange: PropTypes.func.isRequired,
  onBranchSelect: PropTypes.func.isRequired,
  onBranchSuggestionsUpdateRequested: PropTypes.func.isRequired,
  onCustomerCodeChange: PropTypes.func.isRequired,
  onHolderKanaChange: PropTypes.func.isRequired,
  selectedAccount: PropTypes.oneOfType(
    [
      PropTypes.shape(companyExpenseAccountType),
      PropTypes.shape(expenseAccountType),
    ],
  ).isRequired,
};

function mapStateToProps(state, ownProps) {
  const form = getExpenseAccountForm()(state, ownProps);

  return {
    bankSuggestions: form.banks.current,
    branchSuggestions: form.branches.current,
    inProcess: form.inProcess,
    selectedAccount: form.selectedAccount,
  };
}

function mapDispatchToProps(dispatch, ownProps) {
  return {
    fetchBanks() {
      dispatch(actions.fetchBanks());
    },
    onBankNameChange(name) {
      dispatch(actions.setBankName(name));
    },
    onBankSelect(bank) {
      dispatch(actions.selectBank(bank));
      dispatch(actions.setBranchName(''));
    },
    onBankSuggestionsUpdateRequested({ value, reason }) {
      dispatch(actions.requestSuggestionsUpdate('banks', { value, reason }));
    },
    onBranchNameChange(name) {
      dispatch(actions.setBranchName(name));
    },
    onBranchSelect(branch) {
      dispatch(actions.setBranch(branch));
    },
    onBranchSuggestionsUpdateRequested({ value, reason }) {
      dispatch(actions.requestSuggestionsUpdate('branches', { value, reason }));
    },
    onAccountTypeChange(value) {
      dispatch(actions.setAccountType(value));
    },
    onAccountNumberChange(value) {
      dispatch(actions.setAccountNumber(value));
    },
    onHolderKanaChange(value) {
      dispatch(actions.setHolderKana(value));
    },
    onCustomerCodeChange(value) {
      dispatch(actions.setCustomerCode(value));
    },
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
  void 0,
  { forwardRef: true },
)(ExpenseAccountForm);
