// TODO: formattersフォルダ以下に、各関数の定義を置き、index.jsからexportする
import Decimal from 'decimal.js';
import React from 'react';
import _ from 'lodash';
import i18next from 'i18n';
import moment from 'moment';
import { getUserLocale, initIntlCurrencyObj, isUnknownCategory } from 'utilities/Utils'; // eslint-disable-line import/named

/**
 * 日付を表示形式にする 年/月/日 (曜日)
 * @param {?string} dateVal
 * @param {*} param1
 * @returns {(?JSX.Element|string)} JSX.Element | null | string
 */
export function date(dateVal, { emptyText, icon = null, withDayOfTheWeek = false } = {}) {
  const locale = getUserLocale() || 'ja';
  const format = `YYYY/MM/DD${withDayOfTheWeek ? ' (ddd)' : ''}`;
  return !dateVal ? empty(emptyText, icon) : moment(new Date(dateVal)).locale(locale).format(format);
}

/**
 * 日付を表示形式にする 年/月/日 時:分:秒
 * @param {?string} dateVal
 * @param {*} param1
 * @returns {(?JSX.Element|string)} JSX.Element | null | string
 */
export function datetimeToLocale(dateVal, { emptyText, icon = null } = {}) {
  const locale = getUserLocale() || 'ja';
  const format = `YYYY/MM/DD HH:mm:ss`;
  return !dateVal ? empty(emptyText, icon) : moment(new Date(dateVal)).locale(locale).format(format);
}

/**
 * 期間を表示形式にする YYYY/MM/DD 〜 YYYY/MM/DD
 * @param {?string} startAt
 * @param {?string} endAt
 * @param {string} emptyText
 * @param {object} icon
 * @returns {(?JSX.Element|string)} JSX.Element | null | string
 */
export function term(startAt, endAt, emptyText, icon = null) {
  if (!startAt && !endAt) {
    return empty(emptyText, icon);
  }

  return `${!startAt ? '' : moment(new Date(startAt)).format('YYYY/MM/DD')} 〜 ${!endAt ? '' : moment(new Date(endAt)).format('YYYY/MM/DD')}`;
}

/**
 * 経路を表示形式にする (出発場所 ⇄ 到着場所)
 * @param {*} routeVal
 * @param {*} emptyText
 * @param {*} icon
 * @returns {(?JSX.Element|string)} JSX.Element | null | string
 */
export function route(routeVal, emptyText, icon = null) {
  return !routeVal ? empty(emptyText, icon) : `${routeVal.origin.name} ${routeVal.isRoundTrip ? "⇄" : "→"} ${routeVal.destination.name}`;
}

/**
 * 日付を表示形式にする 年/月/日 時:分:秒
 * @param {*} dateVal
 * @param {*} emptyText
 * @param {*} icon
 * @returns {(?JSX.Element|string)} JSX.Element | null | string
 */
export function datetime(dateVal, emptyText, icon = null) {
  return !dateVal ? empty(emptyText, icon) : moment(dateVal).format('YYYY/MM/DD HH:mm');
}

/**
 * 日付を表示形式にする 年/月/日 時:分
 * @param {*} dateVal
 * @param {*} param1
 * @returns {(?JSX.Element|string)} JSX.Element | null | string
 */
export function datetimeWithFormat(dateVal, { emptyText, icon = null, format = 'YYYY/MM/DD HH:mm' } = {}) {
  return !dateVal ? empty(emptyText, icon) : moment(dateVal).format(format);
}

/**
 * 数値を表示形式にする
 * @param {*} amountNum
 * @param {*} emptyText
 * @param {*} icon
 * @param {*} currency
 * @param {*} maximumFractionDigits
 * @returns {(?JSX.Element|string)} JSX.Element | null | string
 */
export function amount(amountNum, emptyText, icon = null, currency = 'JPY', maximumFractionDigits = 0) {
  return (amountNum !== 0 && !amountNum)
    ? empty(emptyText, icon)
    : parseFloat(amountNum * 1).toLocaleString('ja-JP', { style: 'currency', currency, maximumFractionDigits });
}

/**
 * 距離データを表示用の形式にフォーマットする
 * @param {*} distanceNum 100m単位の距離
 * @returns {string} 表示用の形式の距離（距離データがない場合は空文字）
 */
export function distance(distanceNum) {
  return _.isNil(distanceNum)
    ? ''
    : `${distanceNum / 10}km`;
}

/**
 * 数字を表示形式にする
 * @param {*} value
 * @param {*} emptyText
 * @returns {(?JSX.Element|string)} JSX.Element | null | string
 */
export function number(value, emptyText) {
  return _.isNil(value) ? text(null, emptyText) : text(`${+value}`, emptyText);
}

/**
 * 文章を表示形式にする
 * @param {?string} textVal
 * @param {?string} emptyText
 * @param {object} icon
 * @param {boolean} htmlEscape
 * @returns {(?JSX.Element|string)} JSX.Element | null | string
 */
export function text(textVal, emptyText, icon = null, htmlEscape = false) {
  if (htmlEscape) {
    return !textVal ? empty(emptyText, icon) : _.escape(textVal);
  }
  return !textVal ? empty(emptyText, icon) : <span>{ textVal }</span>;
}

/**
 * 従業員情報を表示形式にする 2行
 * @param {*} value
 * @param {*} emptyText
 * @param {*} icon
 * @returns {?JSX.Element} JSX.Element | null
 */
export function member(value, emptyText, icon = null) {
  if (_.isNil(value)) {
    return empty(emptyText, icon);
  }

  return (
    <div key={ `formatted-member-${value.id}` }>
      <span className='txt'>
        { value.name } <br/>
      </span>
      <span className="txt-sm" style={ { color: '#888' } }>
        { value.email ? `${value.email}`  : '' }
      </span>
    </div>
  );
}

/**
 * 従業員情報を表示形式にする 1行
 * @param {*} value
 * @param {*} emptyText
 * @param {*} icon
 * @returns {?JSX.Element} JSX.Element | null
 */
export function memberPull(value, emptyText, icon = null) {
  if (_.isNil(value)) {
    return empty(emptyText, icon);
  }

  return (
    <div key={ `formatted-member-pull-${value.id}` }>
      <span className='txt'>
        { value.name }
        <span className="txt-sm" style={ { color: '#888', paddingLeft: '1em' } }>
          { value.email ? value.email : '' }
        </span>
      </span>
    </div>
  );
}

/**
 * プロジェクトを表示形式にする
 * @param {*} projectDict
 * @param {*} attributes
 * @returns {?string} string | null
 */
export function projectText(projectDict, attributes = ['name']) {
  if (!projectDict) return null;

  const projectAttr0 = projectDict[attributes[0]];
  if (attributes.length === 1) return projectAttr0 || projectDict.displayId || '';

  if (attributes.length === 2) {
    const projectAttr1 = projectDict[attributes[1]];
    if (projectAttr0 && projectAttr1) return `${projectAttr0}(${projectAttr1})`;
    return projectAttr0 || projectAttr1 || '';
  }

  return null;
}

/**
 * TODO: オプションによる表示制御をしない
 * @returns {(?JSX.Element|string)} JSX.Element | null | string
 */
export function project(projectDict, emptyText, icon = null, attributes = ['name'], htmlEscape = false) {
  return text(projectText(projectDict, attributes), emptyText, icon, htmlEscape);
}

/**
 * 検索文字列を含むプロジェクトを探す
 * project.displayId が displayId を含む、もしくは project.name が name を含むか否か
 */
function containSearchText(projectObject, displayId, name) {
  return (!displayId || (projectObject.displayId.indexOf(displayId) > -1))
    && (!name || (projectObject.name && projectObject.name.indexOf(name) > -1));
}

/**
 * メインとなるプロジェクトを取得する
 */
function findMainProject(projectObjects, searchedProjectId, searchedProjectName) {
  const containedProjects = projectObjects.filter((p) => containSearchText(p, searchedProjectId, searchedProjectName));
  if (projectObjects.length === 0 && containedProjects.length === 0) {
    return null;
  }

  // 何らかの理由で Project が検索条件と異なっている場合、
  // 何も表示しないのではなく無作為に取った先頭を主だったプロジェクトとする
  if (projectObjects.length !== 0 && containedProjects.length === 0) {
    return projectObjects[0];
  }

  // 完全一致するプロジェクトを探す
  const matchedProject = containedProjects.find((p) => p.displayId === searchedProjectId || p.name === searchedProjectName);

  // 完全一致したプロジェクトがある場合は、そのプロジェクトを、
  // ない場合は、displayIdでソートをかけて一番最初にくるプロジェクトを返す
  if (matchedProject) return matchedProject;

  containedProjects.sort((a, b) => {
    if (a.displayId < b.displayId) {
      return -1;
    }
    if (a.displayId > b.displayId) {
      return 1;
    }
    return  0;
  });

  return containedProjects[0];
}

/**
 * 複数のプロジェクトを「プロジェクト名(プロジェクトID) 他○件」(プロジェクト名が空の場合は「プロジェクトID 他○件」)の形に整形する
 * 代表して表示するプロジェクトはプロジェクトID昇順の最初の1件
 * ただし検索パラメータがある場合、完全一致するプロジェクトがある場合そのプロジェクト、部分一致するプロジェクトが複数ある場合はその中でプロジェクトID昇順の最初の1件
 * @param {Array.<{ name: string, displayId: string }>} projectObjects
 * @param {string} searchedProjectId
 * @param {string} searchedProjectName
 * @returns {(?JSX.Element|string)}
 */
export function projectBySearch(projectObjects = [], searchedProjectId = '', searchedProjectName = '') {
  const mainProject = findMainProject(projectObjects, searchedProjectId, searchedProjectName);
  const displayIds = projectObjects.map((p) => p.displayId);
  const uniqCount = Array.from(new Set(displayIds)).length;

  if (mainProject && uniqCount > 1) {
    const projectName = mainProject.name ? `${mainProject.name}(${mainProject.displayId})` : mainProject.displayId;
    const otherCountsText = `${projectName}\n${i18next.t('commons.units.otherCounts', { count: (uniqCount - 1) })}`;
    return text(otherCountsText, '', null, true);
  }

  return project(mainProject, i18next.t('commons.conditions.no'), null, ['name', 'displayId'], true);
}

/**
 * プロジェクトの一覧を表示形式にする
 * @param {*} projectsDict
 * @returns {(?JSX.Element|string)} JSX.Element | null | string
 */
export function projects(projectsDict) {
  if (_.isNil(projectsDict) || projectsDict.length === 0) {
    return project(null, null);
  }

  return text(projectsDict.map((p) => projectText(p, ['name', 'displayId'])).join(', '));
}

/**
 * 口座情報を表示形式にする
 * @param {*} account
 * @param {*} emptyText
 * @param {*} icon
 * @returns {(?JSX.Element|string)} JSX.Element | null | string
 */
export function expenseAccount(account, emptyText, icon = null) {
  let display = null;
  if (account) {
    display = `${account.bank.name + account.bankBranch.name} ${i18next.t('preferences.expenseAccount.number')}:${account.number}`;
  }
  return text(display, emptyText);
}

/**
 * 権限一覧のセレクトボックス
 * @param {string} roleVal
 * @returns {JSX.Element}
 */
export function role(roleVal) {
  return (
    <select ref={ 'role' } className='form-control' defaultValue={ roleVal }>
      <option key='1' value='admin,approver'>管理者 かつ 承認者</option>
      <option key='2' value='admin'>管理者</option>
      <option key='3' value='approver'>承認者</option>
      <option key='4' value='request_only'>承認者</option>
    </select>
  );
}

/**
 * 科目を表示形式にする
 * @param {*} categoryNameVal
 * @param {*} transactionInputBy
 * @param {*} transactionStatus
 * @param {*} emptyText
 * @param {*} icon
 * @param {*} htmlEscape
 * @returns {(?JSX.Element|string)} JSX.Element | null | string
 */
export function categoryName(categoryNameVal, transactionInputBy, transactionStatus, emptyText = null, icon = null, htmlEscape = false) {
  return text(categoryNameVal, isUnknownCategory(transactionInputBy, transactionStatus) ? i18next.t('transactions.index.unknownCategory') : null, null, htmlEscape);
}

/**
 * 数値をパーセント表記にする
 * @param {string} value
 * @returns {string}
 */
export function percent(value) {
  return `${new Decimal(value).times(100)}%`;
}

/**
 * 値がない場合に、未入力と表示させる形式にする
 * @param {string} textVal
 * @param {object} icon
 * @returns {?JSX.Element} JSX.Element | null
 */
export function empty(textVal, icon) {
  const placeholder = typeof textVal === 'string' ? textVal : i18next.t('commons.status.notEntered');
  if (!placeholder) {
    // 空文字の時にspanを出力しないようにする
    return null;
  }

  return (
    <span className='txt txt-disabled'>
      { !_.isNil(icon) && icon.position === 'left' ? <i className={ `fa fa-left ${icon.className}` } style={ icon.style }  /> : null }
      { placeholder }
      { !_.isNil(icon) && icon.position === 'right' ? <i className={ `fa fa-right ${icon.className}` } style={ icon.style }  /> : null }
    </span>
  );
}

/**
 * 各ステータスを表示形式にする
 * @param {string} code
 * @returns {string}
 */
export function status(code) {
  switch (code) {
    case 'default': return i18next.t('commons.status.unsubmitted');
    case 'applied': return i18next.t('commons.status.submitted');
    case 'waiting_for_worker': return i18next.t('commons.status.creating');
    case 'denied': return i18next.t('commons.status.failed');
    case 'approved': return i18next.t('commons.status.approved');
    default: return '';
  }
}

/**
 * 申請のステータスを表示形式にする
 * @param {?object} statusObj
 * @returns {?JSX.Element} JSX.Element | null
 */
export function requestStatus(statusObj) {
  if (_.isNil(statusObj)) {
    return null;
  }

  let iconClass = 'fa';
  let paidUserInfo = null;
  const getStatusStr = (code) => (i18next.t(`commons.status.${code}`));
  const applyingRegexp = new RegExp(`^(${i18next.t("commons.status.applying")}|${i18next.t("commons.status.reapplying")}).*`, 'g');
  if (applyingRegexp.test(statusObj.status)) { iconClass += ' fa-exclamation-circle'; }

  switch (statusObj.status) {
    case getStatusStr('unsubmitted'):
    case getStatusStr('creating'):
    case getStatusStr('failed'):
    case getStatusStr('recalled'):
    case getStatusStr('reapplying'):
    case getStatusStr('rejected'):
    case getStatusStr('potentially'):
    case getStatusStr('pendingTemporaryPayment'):
    case getStatusStr('pendingTemporaryPaymentRefund'):
    case getStatusStr('aborted'): {
      iconClass += ' fa-exclamation-circle';
      break;
    }
    case getStatusStr('approved'):
    case getStatusStr('closed'):
    case getStatusStr('archived'):
    case getStatusStr('temporaryPaid'):
    case getStatusStr('paid'): {
      iconClass += ' fa-check-circle';
      if (statusObj.paidUser) {
        paidUserInfo = member(statusObj.paidUser);
      }
      break;
    }
    case getStatusStr('unsettled'): {
      iconClass += ' fa-minus-circle';
      break;
    }
    default: break;
  }

  const finished = status === getStatusStr('approved')
    || status === getStatusStr('closed')
    || status === getStatusStr('archived')
    || status === getStatusStr('paid')
    || status === getStatusStr('temporaryPaid');

  return (
    <div className={ finished ? 'txt txt-desabled' : '' }>
      <i className={ iconClass } style={ { color: statusObj.color } }>
      </i>
      <span style={ { paddingLeft: '6px' } }>
        { statusObj.label }
        <div>
          { paidUserInfo }
        </div>
      </span>
    </div>
  );
}

/**
 * 為替レートを表示形式にする
 * @param {string} baseCurrencyName
 * @param {string} currencyCode
 * @param {number} rate
 * @param {string} locale
 * @returns {?JSX.Element} JSX.Element | null
 */
export function formatExchangeRate(baseCurrencyName, currencyCode, rate, locale = 'ja-JP') {
  const currencyConf = initIntlCurrencyObj(currencyCode, locale);
  return `${currencyConf.format(1)} = ${rate} ${baseCurrencyName}`;
}

/**
 * 部署の絶対パスを表示形式にする
 */
export function departmentWithAbsolutePath(departmentAbsolutePath) {
  const path = departmentAbsolutePath.split('/').slice(0, -1).join('/');
  const departmentName = departmentAbsolutePath.split('/').slice(-1);
  return (
    <>
      <small style={ { color:"#888888" } }>
        { path }
      </small>
      <div>
        { departmentName }
      </div>
    </>
  );
}

export default {
  date,
  datetime,
  datetimeToLocale,
  datetimeWithFormat,
  term,
  amount,
  distance,
  number,
  percent,
  role,
  text,
  empty,
  member,
  memberPull,
  project,
  expenseAccount,
  status,
  route,
  categoryName,
  requestStatus,
  projectBySearch,
  formatExchangeRate,
  departmentWithAbsolutePath,
};
