import * as TransactionActionTypes from "applications/transactions/actions";
import get from "lodash/get";
import * as ActionTypes from "../actions";

export const initialState = {
  // 経路検索の検索オプション
  // FareOptionsがpropとして使用する
  checked: {
    train: true,
    bus: true,
    plane: true,
    roundTrip: false,
  },

  searchCondition: {
    shinkansen: true,
    limitedExpress: true,
    liner: true,
    localBus: true,
    connectionBus: true,
    highwayBus: true,
    midnightBus: true,
    plane: true,
    ship: true,
    surcharge: "free",
    withIc: userPreferences.preference.icCardOption,
    jrReservation: undefined,
  },

  // FareSearchBoxの入力フォームの値
  inputs: {
    start: "",
    goal: "",
    destination: "",
  },

  // FareSearchBoxの補完候補の値
  routes: [], // 取得した経路候補

  // 選択中の駅
  station: {
    start: null, // 選択されている出発駅
    goal: null, // 選択されている到着駅
  },

  // 選択中の経路
  relays: [], // 経由駅。TODO: start, goalと同等の階層に置くように
  route: null, // 選択されている経路
  expiredCache: false, // 経路検索のキャッシュの有効状態

  // 計算済の運賃
  amount: null,
};

const checked = (state = initialState.checked, action) => ({
  ...state,
  [action.key]: !state[action.key],
});

const searchCondition = (state = initialState.searchConditions, action) => ({
  ...state,
  [action.key]: action.value,
});

const initSearchCondition = (state = initialState.searchCondition) => {
  const condition = {};

  [
    "shinkansen",
    "limitedExpress",
    "liner",
    "localBus",
    "connectionBus",
    "highwayBus",
    "midnightBus",
    "plane",
    "ship",
    "jrReservation",
  ].forEach((transport) => {
    condition[transport] = state[transport];
  });

  return {
    ...condition,
    withIc: get(state, "withIc", userPreferences.preference.icCardOption),
    surcharge: get(state, "surcharge", "free"),
  };
};

const inputs = (state = initialState.inputs, action) => {
  switch (action.type) {
    case ActionTypes.SET_STATION_INPUT:
      return {
        ...state,
        [action.key]: action.value,
      };
    case ActionTypes.SET_DESTINATION:
      return {
        ...state,
        destination: action.value,
      };
    default:
      return state;
  }
};

export const searchBox = (state = initialState, action) => {
  switch (action.type) {
    case TransactionActionTypes.RESET_TRANSACTION: {
      const data = action.formData;
      const route = data.route || {};

      return {
        ...state,
        checked: {
          ...state.checked,
          roundTrip: route.isRoundTrip || false,
        },
        searchCondition: initSearchCondition(route.searchCondition),
        inputs: {
          destination: data.shopName,
          start: (route.origin && route.origin.name) || "",
          goal: (route.destination && route.destination.name) || "",
        },
        station: {
          start: route.origin || null,
          goal: route.destination || null,
        },
        route,
        amount: null,
      };
    }
    case ActionTypes.RESET_SEARCH_BOX: {
      return {
        ...initialState,
      };
    }
    case ActionTypes.TOGGLE_EXPIRED_CACHE:
      return {
        ...state,
        expiredCache: action.value,
      };
    case ActionTypes.SET_STATION: {
      // 駅を選択する
      const nextInputs = action.value
        ? { ...state.inputs, [action.key]: action.value.name }
        : { ...state.inputs, [action.key]: "" };

      // 駅がnullのときは、駅をクリアすると同時に、経路もリセットする
      const nextRoute = action.value ? state.route : null;
      return {
        ...state,
        inputs: nextInputs,
        station: { ...state.station, [action.key]: action.value },
        route: nextRoute,
      };
    }
    case ActionTypes.SET_RELAYS: {
      return {
        ...state,
        relays: [...action.payload.data],
      };
    }
    case ActionTypes.UPDATE_RELAY: {
      return {
        ...state,
        relays: state.relays.map((x) => {
          if (x.id !== action.payload.id) {
            return x;
          }

          return { ...x, ...action.payload.diff };
        }),
      };
    }
    case ActionTypes.SET_BOTH_STATIONS: {
      // 出発駅と到着駅を同時にセットする
      // 処理内容はSET_STATIONと同様
      const nextInputs = {
        ...state.inputs,
        start: action.start ? action.start.name : "",
        goal: action.goal ? action.goal.name : "",
      };
      const nextRoute = action.start && action.goal ? state.route : null;
      return {
        ...state,
        inputs: nextInputs,
        station: { start: action.start, goal: action.goal },
        route: nextRoute,
      };
    }
    case ActionTypes.SET_ROUTE:
      return {
        ...state,
        route: action.value,
      };
    case ActionTypes.SET_STATION_INPUT:
    case ActionTypes.SET_DESTINATION:
      return {
        ...state,
        inputs: inputs(state.inputs, action),
      };
    case ActionTypes.SET_SEARCH_OPTIONS:
      return {
        ...state,
        checked: checked(state.checked, action),
      };
    case ActionTypes.SET_SEARCH_CONDITION:
      return {
        ...state,
        searchCondition: searchCondition(state.searchCondition, action),
      };
    case ActionTypes.SET_SEARCH_CONDITIONS:
      return {
        ...state,
        searchCondition: initSearchCondition(
          action.condition || initialState.searchCondition,
          action,
        ),
      };
    case ActionTypes.SET_ROUTES:
      return {
        ...state,
        routes: [...action.value],
      };
    case ActionTypes.SET_AMOUNT:
      return {
        ...state,
        amount: action.payload.amount,
      };
    default:
      return state;
  }
};

export default searchBox;
