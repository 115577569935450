import { ApiFunc } from "actions/AsyncAction";
import { useFetcher } from "hooks/useFetcher";
import { useMemo } from "react";
import Timer from "utilities/timer";
import { RequestObject } from "utilities/Utils";

const FETCH_INTERVAL = 3000;

interface ExportedCsvFetcher<Res> {
  readonly start: () => void;
  readonly stop: () => void;
  readonly data: Res | undefined;
}

export const useExportedCsvFetcher = <Res, Req extends RequestObject>(
  url: ApiFunc<Res, Req>,
  params: Req | undefined = undefined,
): ExportedCsvFetcher<Res> => {
  const { fetch, response } = useFetcher(url, { defaultParams: params });
  const timer: Timer = useMemo(() => new Timer(FETCH_INTERVAL, [fetch]), []);

  return {
    start: (): void => timer.start(),
    stop: (): void => timer.stop(),
    data: response,
  };
};
