import React from "react";
import styled from "styled-components";
import { Expense } from "utilities/api/models";
import { CommentIconWithOverlay } from "./CommentIconWithOverlay";
import { CompanionsIconWithOverlay } from "./CompanionsIconWithOverlay";
import { CurrencyIconWithOverlay } from "./CurrencyIconWithOverlay";
import { ElectronicReceiptImageIconWithOverlay } from "./ElectronicReceiptImageIconWithOverlay";
import { FromCardIconWithOverlay } from "./FromCardIconWithOverlay";
import { FromWorkerIconWithOverlay } from "./FromWorkerIconWithOverlay";
import { ImageIconWithOverlay } from "./ImageIconWithOverlay";
import { IsCorporateIconWithOverlay } from "./IsCorporateIconWithOverlay";

type Props = {
  expense: Expense;
};

const LabelIconsWithOverlayView = styled.div`
  display: flex;
  align-items: center;
  height: 100%;

  > i,
  > div {
    margin-left: 2px;
    margin-right: 2px;
  }

  > div {
    display: flex;
    align-items: center;
  }

  .material-icons {
    font-size: 14px;
  }
`;

/** 添付領収書画像アイコン */
export const LabelIconsWithOverlay: React.FC<Props> = ({ expense }) => {
  return (
    <LabelIconsWithOverlayView className="label-icons-with-overlay">
      <ImageIconWithOverlay
        receiptImages={expense.receiptImages}
        id={expense.id}
      />
      <ElectronicReceiptImageIconWithOverlay
        isElectronicReceiptImage={expense.isElectronicReceiptImage}
      />
      <CompanionsIconWithOverlay
        id={expense.id}
        companions={expense.companions}
      />
      <CommentIconWithOverlay comment={expense.comment} />
      <IsCorporateIconWithOverlay isCorporate={expense.isCorporate} />
      <FromWorkerIconWithOverlay inputBy={expense.inputBy} />
      <FromCardIconWithOverlay
        isCreatedByAggregation={expense.isCreatedByAggregation}
      />
      <CurrencyIconWithOverlay expense={expense} />
    </LabelIconsWithOverlayView>
  );
};
