import React, {
  useCallback, useEffect, useMemo, useState,
} from 'react';
import { FixedSizeList } from 'react-window';
import { Subject, from } from 'rxjs';
import { isNil } from 'lodash';
import {  mergeMap, throttleTime } from 'rxjs/operators';

type SparseArray<T> = Array<T | undefined>;
type Response<T> = {
  count: number;
  options: SparseArray<T>;
};

export type FetchItems<T> = (offset: number, limit: number) => Promise<Response<T>>;
export type ItemsChangeCallback<T> = (items: SparseArray<T>, count: number) => void;

interface RenderItemOptions {
  style: React.CSSProperties;
  index: number;
}

interface Props<T> {
  className?: string;
  innerRef?: React.Ref<HTMLDivElement>;
  outerRef?: React.Ref<HTMLDivElement>;
  totalCount?: number;
  batchSize?: number; // batchSize は表示されている件数 + overscanされる件数以上の数を指定すること
  throttleMs?: number;
  overscanCount?: number;
  items: SparseArray<T>;
  height: number;
  width?: number | string;
  itemHeight: number;
  style?: React.CSSProperties;
  fetchItems: FetchItems<T>;
  onItemsChange: ItemsChangeCallback<T>;
  renderItem: (item: T | undefined, options: RenderItemOptions) => React.ReactElement;
}

type Direction = 'forward' | 'backward';

interface FetchEvent {
  startIndex: number;
  stopIndex: number;
}

/**
 * 未取得の範囲を算出する
 * 指定された範囲のデータを全て取得済みの場合は、[-1, -1] を返す
 * その他の場合は、スクロール方向に向かって、batchSize以下の長さで最大の未取得の範囲を計算して返す
 *
 * @param items 取得済みのデータ
 * @param batchSize データの最大取得件数
 * @param scrollDirection
 * @param fetchEvent
 */

function useLazyLoad<T>(items: SparseArray<T>, fetchItems: FetchItems<T>, onItemsChange: ItemsChangeCallback<T>, batchSize: number, throttleMs: number): Subject<FetchEvent> {
  const subject = useMemo(() => (new Subject<FetchEvent>()), []);
  const fetch$ = useMemo(() => {
    return subject.pipe(
      throttleTime(throttleMs, void 0, { leading: true, trailing: true }),
      mergeMap(() => from(fetchItems(items.length, batchSize))),
    );
  }, [subject, items, fetchItems, batchSize, throttleMs]);

  useEffect(() => {
    return (): void => { subject.complete(); };
  }, [subject]);

  useEffect(() => {
    const subscription = fetch$.subscribe((response) => {
      const { options, count } = response;
      onItemsChange(options, count);
    });

    return (): void => {
      subscription.unsubscribe();
    };
  }, [fetch$, onItemsChange]);

  return subject;
}

const InfiniteScrollerGetConsecutiveData = <T extends { id: string }>(props: Props<T>): React.ReactElement<Props<T>> | null => {
  const {
    items, totalCount, batchSize = 20, throttleMs = 500, overscanCount = 8,
    innerRef, outerRef, style: listStyle, height, width = 'auto', itemHeight,
    fetchItems, onItemsChange, renderItem,
  } = props;

  const [scrollDirection, setScrollDirection] = useState<Direction>('forward');
  const getItemKey = useCallback((index) => {
    return items[index]?.id || index;
  }, [items]);
  const renderRow = useCallback(({ index, style }) => {
    return renderItem(items[index], { style, index });
  }, [renderItem, items]);
  const fetch$ = useLazyLoad(items, fetchItems, onItemsChange, batchSize, throttleMs);

  useEffect(() => {
    if (isNil(totalCount)) {
      fetch$.next({ startIndex: 0, stopIndex: batchSize - 1 });
    }
  }, [batchSize, fetch$, totalCount]);

  const handleItemsRendered = useCallback(({ overscanStopIndex }) => {
    if (
      items.length - 1 === overscanStopIndex
        && scrollDirection === 'forward'
        && totalCount
        && items.length < totalCount
    ) fetch$.next();
  }, [fetch$, items.length, scrollDirection, totalCount]);
  const handleScroll = useCallback((x) => {
    setScrollDirection(x.scrollDirection);
  }, []);

  return (
    <FixedSizeList
      innerRef={ innerRef }
      outerRef={ outerRef }
      style={ listStyle }
      itemKey={ getItemKey }
      itemCount={ items.length }
      overscanCount={ overscanCount }
      height={ height }
      width={ width }
      itemSize={ itemHeight }
      onItemsRendered={ handleItemsRendered }
      onScroll={ handleScroll }
        >
      { renderRow }
    </FixedSizeList>
  );
};

export default InfiniteScrollerGetConsecutiveData;
