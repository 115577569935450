import get from "lodash/get";
import { createSelector } from "reselect";
import { expenseTableColumns } from "./expenseTableColumns";

// TransactionTableCardで使用するselector
const getDefaultSearchConditions = (state, props) =>
  get(state, "defaultSearchConditions");
const getSearchConditions = (state, props) => get(state, "searchConditions");
const getTransactions = (state, props) => get(state, "transactions");
const getEntryForms = (state, props) => get(state, "entryForms");
const getCurrentPage = (state, props) => get(state, "currentPage");
const getSizePerPage = (state, props) => get(state, "sizePerPage");
const getInProcess = (state, props) => get(state, "inProcess");
const getIsEditing = (state, props) => get(state, "isEditing");
const getModalState = (state, props) => get(state, "modal");
const getCostAllocationModalState = (state, props) =>
  get(state, "costAllocationModal");
const getPairsSearchModal = (state, props) => get(state, "pairsSearch");
const getMergeSelecter = (state, props) => get(state, "mergeSelecter");
const getSearchBoxRef = (state, props) => get(state, "searchBoxRef");
const getShouldSelectSelfAsCompanion = (state, props) =>
  get(state, "shouldSelectSelfAsCompanion");
const getColumnVisibilities = (state, props) =>
  get(state, "columnVisibilities");
const getReactTableColumnParams = (state, props) =>
  get(state, "reactTableColumnParams");
const getIsSearchWithOnlyDeleted = (state, props) =>
  get(state, "isSearchWithOnlyDeleted");

const makeTransactionTableStateMapper = () =>
  createSelector(
    [
      getDefaultSearchConditions,
      getSearchConditions,
      getInProcess,
      getIsEditing,
      getTransactions,
      getEntryForms,
      getCurrentPage,
      getSizePerPage,
      getModalState,
      getCostAllocationModalState,
      getPairsSearchModal,
      getMergeSelecter,
      getSearchBoxRef,
      getShouldSelectSelfAsCompanion,
      getColumnVisibilities,
      getReactTableColumnParams,
      getIsSearchWithOnlyDeleted,
    ],
    (
      defaultSearchConditions,
      searchConditions,
      inProcess,
      isEditing,
      transactions,
      entryForms,
      currentPage,
      sizePerPage,
      modalState,
      costAllocationModalState,
      pairsSearch,
      mergeSelecter,
      searchBoxRef,
      shouldSelectSelfAsCompanion,
      columnVisibilities,
      reactTableColumnParams,
      isSearchWithOnlyDeleted,
    ) => {
      const isDefaultStatus =
        JSON.stringify(defaultSearchConditions) ===
        JSON.stringify(searchConditions);
      const transactionsForTable = isEditing
        ? transactions.inEdit
        : transactions.master;
      const isMultipleSelected =
        transactions.master.filter((t) => {
          if (typeof t !== "undefined") {
            return t.isSelected;
          }
          return false;
        }).length > 1;

      const authority = "approval"; // 経費ページでは常に承認をもらう立場(approval)
      const hasPreReport = false; // 経費ページでは常にfalse
      const reportRequiresWithholding = false; // 経費ページでは常にfalse

      return {
        authority,
        isDefaultStatus,
        transactions: transactionsForTable,
        entryForms,
        transactionsObj: transactions,
        currentPage,
        sizePerPage,
        columnVisibilities,
        reactTableColumnParams,
        ownerId: userPreferences.id,
        searchConditions,
        defaultSearchConditions,
        isAddable: true,
        isMultipleSelected,
        inProcess,
        isEditing,
        isSearchWithOnlyDeleted,
        modalTransaction: modalState.transaction,
        previousExpenseId: modalState.previousId,
        nextExpenseId: modalState.nextId,
        isTransactionModalOpen: modalState.isTransactionModalOpen,
        costAllocations: costAllocationModalState.costAllocations,
        isCostAllocationModalOpen: costAllocationModalState.show,
        costAllocationHovorRowIdx: costAllocationModalState.hovor,
        shouldSelectSelfAsCompanion,
        except: [],
        pairsSearch,
        mergeSelecter,
        manualMergeable: true,
        searchBoxRef,
        expenseTableColumns: expenseTableColumns(
          reactTableColumnParams,
          authority,
          reportRequiresWithholding,
          hasPreReport,
          searchConditions.scope,
        ),
        hasPreReport,
        reportRequiresWithholding,
      };
    },
  );

export default makeTransactionTableStateMapper;
