import ToolBarImage from "components/renewaled_ui/FileViewer/Viewer/Other/FileView/ToolBarImage";
import { useHooks } from "components/renewaled_ui/FileViewer/Viewer/Other/ImageViewer/hooksFile";
import { useHooksImage } from "components/renewaled_ui/FileViewer/Viewer/Other/ImageViewer/hooksToolBar";
import { ViewerFooter } from "components/renewaled_ui/FileViewer/ViewerFooter";
import {
  DeleteEventHandler,
  DropType,
  FileType,
  GetImageElement,
  RotateEventHandler,
  ScaleEventHandler,
} from "components/types/attachedFiles.types";
import React, { cloneElement } from "react";
import { DropzoneInputProps, DropzoneRootProps } from "react-dropzone";
import styled from "styled-components";

interface WrapperProps {
  readonly canClick?: boolean;
}
const Wrapper = styled.div<WrapperProps>`
  position: relative;
  overflow-y: auto;
  overflow-x: hidden;

  &:hover {
    cursor: ${(p): string => `${p.canClick ? "pointer" : "auto"}`};
  }
`;

interface ChildProps {
  readonly style?: React.CSSProperties;
  readonly page: number;
  readonly scale: number;
  readonly rotate: number;
}
interface Props {
  readonly rootProps: DropzoneRootProps;
  readonly className?: string;
  readonly url?: string;
  readonly isDeletable?: boolean;
  readonly isDroppable?: boolean;
  readonly isDropzoneVisible?: boolean;
  readonly dropType?: DropType;
  readonly isShowFileSelect: boolean;
  readonly isTransformable: boolean;
  readonly isClickable?: boolean;
  readonly downloadLink?: string;
  readonly fileName?: string;
  /** isDroppable = trueの時に、選択可能なファイルの種類を指定 */
  readonly selectableTypes?: FileType[];
  readonly getImageElement?: GetImageElement;
  /** 外部から回転、倍率を指定する時に使用する */
  readonly defaultRotation?: number;
  readonly defaultScale?: number;
  readonly isShowToolBar: boolean;
  /** ファイルinput */
  readonly getInputProps: (
    props?: DropzoneInputProps | undefined,
  ) => DropzoneInputProps;
  /** フッターに表示するテキスト */
  readonly footer?: string | JSX.Element;
  readonly onDelete?: DeleteEventHandler;
  readonly onRotate?: RotateEventHandler;
  readonly onScale?: ScaleEventHandler;
  readonly children: React.ReactElement<ChildProps>;
}

/**
 * childrenがFileViewの時に対応する `getImageElement` の実装
 */
const defaultGetImageElement: GetImageElement = (
  frameElement: HTMLElement | null,
) => {
  if (!frameElement) {
    return null;
  }
  return frameElement.querySelector("img");
};

/**
 * ファイルビューアーのツール
 * ツールバー, ファイルドラック&ドロップ, フッター
 */
const ImageViewer: React.FC<Props> = (p) => {
  const { isClickable, onClickUploadTool } = useHooks(
    p.rootProps,
    p.isDroppable || false,
    p.isClickable || false,
  );
  const {
    ref,
    imageStyle,
    onClickDeleteImage,
    onClickRotateImage,
    onClickScaleUpImage,
    onClickScaleDownImage,
    onClickExpandImage,
  } = useHooksImage(
    p.defaultScale,
    p.defaultRotation,
    p.onDelete,
    p.onScale,
    p.onRotate,
    p.getImageElement || defaultGetImageElement,
  );
  /**
   * ツールバーを表示するか
   */
  const isShowToolBar = (): boolean => {
    if (!p.isShowToolBar) return false;
    if (p.isDeletable) return true;
    if (p.isShowFileSelect) return true;
    if (p.isTransformable) return true;
    if (p.downloadLink) return true;
    return false;
  };

  return (
    <Wrapper
      className="image-container-view"
      {...p.rootProps}
      canClick={isClickable}
      onClick={isClickable ? p.rootProps.onClick : undefined}
    >
      {
        // ツールバー
        isShowToolBar() && (
          <ToolBarImage
            rootProps={p.rootProps}
            showFileSelectButton={p.isShowFileSelect}
            isTransformable={p.isTransformable}
            isDeletable={p.isDeletable || false}
            downloadLink={p.downloadLink || ""}
            fileName={p.fileName || ""}
            getInputProps={p.getInputProps}
            onClickUpload={onClickUploadTool}
            onClickDelete={onClickDeleteImage}
            onClickRotate={onClickRotateImage}
            onClickScaleUp={onClickScaleUpImage}
            onClickScaleDown={onClickScaleDownImage}
            onClickExpand={onClickExpandImage}
          />
        )
      }

      {cloneElement(p.children, {
        ref,
        imageStyle,
      })}

      {
        // フッター
        !!p.footer && (
          <ViewerFooter className="viewer-dropzone-view-footer">
            {p.footer}
          </ViewerFooter>
        )
      }
    </Wrapper>
  );
};

ImageViewer.propTypes = {
  children: (
    props: Partial<Props>,
    propName: string,
    componentName: string,
  ): Error | null => {
    const children = React.Children.toArray(props[propName as keyof Props]);

    if (children.length !== 1) {
      return new TypeError(`\`${componentName}\` must have just one child`);
    }

    return null;
  },
};

export default ImageViewer;
