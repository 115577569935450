import React, { Component } from 'react';
import i18next from 'i18n';

export default class AvailableFormatList extends Component {
  render() {
    return (
      <table className='string-placeholder'>
        <tbody>
          {
            this.props.placeholders.map((name, i) => (
              <tr key={ i }>
                <td>{ i18next.t(`preferences.format.placeholder.${name}`) }:</td>
                <td><span className='label label-info'>{ `{${name}}` }</span></td>
              </tr>
            ))
          }
        </tbody>
      </table>
    );
  }
}
