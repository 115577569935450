import AdvancedSearchConditionForm from './AdvancedSearchConditionForm';
import LazyElement from 'components/LazyElement';
import PickList from 'components/PickList';
import PropTypes from 'prop-types';
import React from 'react';
import TextField from 'components/fields/TextField';
import i18next from 'i18n';
import { Button, Modal } from 'react-bootstrap';

class SelectContactsModal extends React.Component {
  constructor(props) {
    super(props);
    this.state = { showAdvancedSearchCondition: false };
    this.toggleShowAdvancedSearchCondition = this.toggleShowAdvancedSearchCondition.bind(this);
  }

  toggleShowAdvancedSearchCondition() {
    this.setState({ showAdvancedSearchCondition: !this.state.showAdvancedSearchCondition });
  }

  renderRow(item) {
    return (
      <>
        <LazyElement height={ 19 } margin='4px 0'>{ item && <div>{ item.section }</div> }</LazyElement>
        <LazyElement height={ 13 } margin='2px 0'>{ item && <small>{ item.subSection }</small> }</LazyElement>
      </>
    );
  }

  render() {
    return (
      <Modal
        className = 'list-selector-modal'
        show={ this.props.show }
        onHide={ this.props.onHide }>

        <Modal.Header closeButton>
          <Modal.Title>
            { i18next.t('originalReceiptIdentificationRequests.titles.selectRecipients') }
          </Modal.Title>
        </Modal.Header>

        <Modal.Body>
          <div className='text-right'>
            <a onClick={ this.toggleShowAdvancedSearchCondition }>{ i18next.t('originalReceiptIdentificationRequests.actions.advancedSearch') }</a>
          </div>
          <div className = 'department-input-form'>
            <label>{ i18next.t('originalReceiptIdentificationRequests.properties.employeeName') }</label>
            <form className='list-selector-item-form' onSubmit={(e) => {
                e.preventDefault()
                this.props.onSearch();
              }
            }>
              <div className='form-group'>
                <TextField
                  className='form-control'
                  value={ this.props.searchText }
                  onValueChange={ this.props.onChangeSearchText }
                />
              </div>
            </form>
            {
              this.state.showAdvancedSearchCondition
                ? <AdvancedSearchConditionForm
                  { ...this.props }
                  className='advanced-search-condition'
                  />
                : null
            }
          </div>
          <div className='search-button'>
            <SearchButton inProcess={ this.props.inProcessSending } handleClick={ this.props.onSearch }/>
          </div>
          <div className='select-all-form' >
            <Button
              onClick={ () => this.props.onSelectAll(false) }
              bsStyle='default'>
              { i18next.t('originalReceiptIdentificationRequests.actions.clearAllRecipients') }
            </Button>
            <Button
              className='select-all-button'
              onClick={ () => this.props.onSelectAll(true) }
              bsStyle='success'>
              { i18next.t('originalReceiptIdentificationRequests.actions.allSelectRecipients') }
            </Button>
          </div>
          <PickList
            responseKey='members'
            fetchSourceItems={ this.props.fetchSourceItems }
            onOptionsChange={ this.props.onOptionsChange }
            onSelect={ this.props.onSelect }
            sourceTitle={ i18next.t('originalReceiptIdentificationRequests.titles.searchResult') }
            sourceSize={ this.props.sourceSize }
            sourceItems={ this.props.sourceItems }
            selectedTitle={ i18next.t('originalReceiptIdentificationRequests.titles.selectResult') }
            selectedItems={ this.props.selectedItems }
            selectedSize={ this.props.selectedItems.length }
            renderRow={ this.renderRow }
          />
        </Modal.Body>

        <Modal.Footer>
          <Button bsStyle='default' onClick={ this.props.onHide } >{ i18next.t('commons.actions.cancel') }</Button>
          <SendNotificationButton inProcess={ this.props.inProcessSending } handleClick={ this.props.sendNotify }/>
        </Modal.Footer>
      </Modal>
    );
  }
}

SelectContactsModal.defaultProps = {
  show: false,
};

SelectContactsModal.propTypes = {
  /**
   * モーダルに関するアクション
   */
  show: PropTypes.bool,                   // モーダルを表示するかどうか
  onHide: PropTypes.func.isRequired,      // モーダルを閉じる際に実行する
  sendNotify: PropTypes.func.isRequired,  // 連絡先への通知の送信時に発火するアクション
  inProcessSending: PropTypes.bool,

  /**
   * 検索窓に関するアクション
   */
  searchText: PropTypes.string,           // 検索する文字列
  onSearch: PropTypes.func.isRequired,    // 検索時に発火するアクション
  onChangeSearchText: PropTypes.func.isRequired,  // 検索文字列が変更されたときに発火するアクション
  departments: PropTypes.arrayOf(             // 部署検索ソースとなるアイテム
    PropTypes.shape({
      id: PropTypes.string.isRequired,        // id (一意に対象を特定するため)
      name: PropTypes.string.isRequired,      // 表示名
    }),
  ),
  selectedDepartment: PropTypes.shape({
    id: PropTypes.string.isRequired,        // id (一意に対象を特定するため)
    name: PropTypes.string.isRequired,      // 表示名
  }),
  onSelectDepartment: PropTypes.func.isRequired, // 部署を選んだ時に発火するアクション

  /**
   * PickList に関するアクション
   */
  onOptionsChange: PropTypes.func,            // フェッチ等によってソースが変更された場合に発火するアクション
  onSelect: PropTypes.func.isRequired,  // アイテムの選択状態が変更されたときに発火するアクション
  sourceSize: PropTypes.number,               // ソース全体の数
  sourceItems: PropTypes.arrayOf(             // ソースとなるアイテム
    PropTypes.shape({
      id: PropTypes.string.isRequired,        // id (一意に対象を特定するため)
      section: PropTypes.string.isRequired,   // 表示するセクション (名前などメインで表示したいもの)
      subSection: PropTypes.string,           // 表示するサブセクション (メールアドレスなど付随して表示したいもの)
    }),
  ),
  selectedItems: PropTypes.arrayOf(           // ソースから選択されたアイテム
    PropTypes.shape({
      id: PropTypes.string.isRequired,        // id (一意に対象を特定するため)
      section: PropTypes.string.isRequired,   // 表示するセクション (名前などメインで表示したいもの)
      subSection: PropTypes.string,           // 表示するサブセクション (メールアドレスなど付随して表示したいもの)
    }),
  ),
  fetchSourceItems: PropTypes.func.isRequired,      // アイテムをフェッチする際に発火するアクション
  onSelectAll: PropTypes.func.isRequired,     // 引数が true の場合は全件選択, false の場合は選択解除の処理を行う
};

export default SelectContactsModal;

const SendNotificationButton = (props) => {
  const className = `${props.inProcess ? 'disabled' : ''}`;

  return (
    <Button
      className={ className }
      onClick={ props.handleClick }
      bsStyle='success'>
      { props.inProcess
        ? <i className='fa fa-spinner fa-spin'></i>
        : i18next.t('originalReceiptIdentificationRequests.actions.sendNotification')
      }
    </Button>
  );
};

const SearchButton = (props) => {
  const className = `${props.inProcess ? 'disabled' : ''}`;

  return (
    <Button
      className={ className }
      onClick={ props.handleClick }
      bsStyle='success'>
      { props.inProcess
        ? <i className='fa fa-spinner fa-spin'></i>
        : i18next.t('commons.actions.search')
      }
    </Button>
  );
};
