import TaxCategorySelectionsTSModal from "applications/root_categories/components/TaxCategorySelectionsTSModal";
import { Button } from "components/renewaled_ui/buttons";
import { ListForm } from "components/renewaled_ui/form_views";
import i18next from "i18next";
import React, { useCallback, useEffect, useState } from "react";
import { TaxCategorySelection } from "utilities/api/models";
import Formatter from "utilities/formatter";
import { TaxCategory } from "../interface";

interface Props {
  /** 税区分一覧 */
  readonly taxCategories: TaxCategory[];
  /** 選択された税区分 */
  readonly selectedTaxCategories: TaxCategory[];
  /** 税区分選択時のコールバック */
  readonly onSelect: (selectedTaxCategories: TaxCategory[]) => void;
}

/**
 * 税区分表示・選択モーダル欄
 */
export const TaxCategorySelectionField: React.FC<Props> = ({
  taxCategories,
  selectedTaxCategories,
  onSelect,
}) => {
  const [showModal, setShowModal] = useState(false);
  const [taxCategorySelections, setTaxCategorySelections] = useState<
    TaxCategorySelection[]
  >([]);

  const depsTaxCategories = taxCategories.map((o) => o.id).join(",");
  const depsSelectedTaxCategories = selectedTaxCategories
    .map((o) => o.id)
    .join(",");

  useEffect(() => {
    setTaxCategorySelections(
      taxCategories.map((t) => ({
        ...t,
        categoryId: "",
        sort: 0,
        taxCategoryId: t.id,
        selected: selectedTaxCategories.some((s) => s.id === t.id),
      })),
    );
  }, [depsTaxCategories, depsSelectedTaxCategories]); // eslint-disable-line react-hooks/exhaustive-deps
  // 税区分一覧、選択された税区分は配列のためIDで比較するため

  const handleChangeSelections = useCallback(
    (selections: TaxCategorySelection[]) => {
      setTaxCategorySelections(selections);
    },
    [setTaxCategorySelections],
  );

  const handleClose = useCallback(() => {
    onSelect(taxCategorySelections.filter((o) => o.selected));
    setShowModal(false);
  }, [onSelect, taxCategorySelections, setShowModal]);

  const taxCategoryNames = selectedTaxCategories
    .map((o) => {
      return `${o.name} ${Formatter.percent(o.taxRate)}`;
    })
    .join("、");

  return (
    <>
      <ListForm label={i18next.t("transactions.properties.taxCategory")}>
        <div style={{ display: "flex" }}>
          <div className="" style={{ paddingRight: "2px", width: "100%" }}>
            <input
              type="text"
              readOnly
              className="form-control"
              value={taxCategoryNames}
            />
          </div>
          <div className="" style={{ paddingLeft: "2px" }}>
            <Button
              styleType="secondary"
              onClick={(): void => setShowModal(true)}
            >
              {i18next.t("commons.actions.select")}
            </Button>
          </div>
        </div>
      </ListForm>
      <TaxCategorySelectionsTSModal
        show={showModal}
        showSort={false}
        taxCategorySelections={taxCategorySelections}
        onHideModal={handleClose}
        onChangeSelections={handleChangeSelections}
      />
    </>
  );
};
