import React from 'react';
import i18next from 'i18n';
import { ListGroup, ListGroupItem } from 'react-bootstrap';

export type MenuKey = 'ip_restriction' | 'sso' | 'subdomain' | 'saml_setting' | 'saml_account';
type MenuItemSelected = 'selected' | '';
interface Props {
  currentUrl: MenuKey;
}

function isActive(menuKey: MenuKey, currentUrl: MenuKey): MenuItemSelected {
  return menuKey === currentUrl ? 'selected' : '';
}

function getHrefLocation(menuKey: MenuKey): string {
  return `/preferences/security/${menuKey}`;
}

const SecuritySubmenu: React.FC<Props> = ({
  currentUrl,
}) => {
  return (<ListGroup className='security-sub-menu'>
    <ListGroupItem
      className={ isActive('ip_restriction', currentUrl) }
      href={ getHrefLocation('ip_restriction') }>
      { i18next.t('preferences.security.subMenu.ipRestriction') }
    </ListGroupItem>
    <ListGroupItem
      className={ isActive('sso', currentUrl) }
      href={ getHrefLocation('sso') }>
      { i18next.t('preferences.security.subMenu.singleSignOn') }
    </ListGroupItem>
    <ListGroupItem
      className={ isActive('subdomain', currentUrl) }
      href={ getHrefLocation('subdomain') }>
      { i18next.t('preferences.security.subMenu.subdomain') }
    </ListGroupItem>
    <ListGroupItem
      className={ isActive('saml_setting', currentUrl) }
      href={ getHrefLocation('saml_setting') }>
      { i18next.t('preferences.security.subMenu.samlSetting') }
    </ListGroupItem>
    <ListGroupItem
      className={ isActive('saml_account', currentUrl) }
      href={ getHrefLocation('saml_account') }>
      { i18next.t('preferences.security.subMenu.samlAccount') }
    </ListGroupItem>
  </ListGroup>);
};

export default SecuritySubmenu;
