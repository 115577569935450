import React from 'react';

function renderTabElement(group, onClickTab, idx, prefix, tabFormatter) {
  const hrefPrefix = prefix && prefix.length > 0 ? `#${prefix}-group-` : 'group-';
  const hrefStr = `${hrefPrefix}-${idx}`;
  return  (
    <li className={ `${group.isActive ? 'active' : ''}` }
      key={ `key-${prefix}-${idx}` }
      style={ { cursor: 'pointer' } }
    >
      <a onClick={ (e) => {
        onClickTab(e, group, idx);
      } }
      >
        { tabFormatter(group) }
      </a>
    </li>
  );
}

export function renderTab(groups, onClickTab, onClickGenericFieldsTab, prefix = '', tabFormatter = (group) => (group.name)) {
  const tabElementsWithoutFG = groups.map((g, idx) => {
    if (g.type !== 'generic_fields/data_set') {
      return (renderTabElement(g, onClickTab, idx, prefix, tabFormatter));
    }
    return undefined;
  });

  const genericFiledsTabElements = groups.map((g, idx) => {
    if (g.type === 'generic_fields/data_set') {
      return (renderTabElement(g, onClickGenericFieldsTab, idx, prefix, tabFormatter));
    }
    return undefined;
  });

  const tabElements = tabElementsWithoutFG.concat(genericFiledsTabElements).filter((element) =>  element !== undefined);

  return (
    <ul className='nav nav-tabs'>
      { tabElements }
    </ul>
  );
}

function renderTabContent(group, idx, renderer, prefix = '') {
  const key = prefix.length > 0 ? `${prefix}-tab-content-${idx}` : `tab-content-${idx}`;
  return (
    <div className={ `tab-pane${group.isActive ? ' active' : ''}` } style={ { cursor: 'pointer' } } key={ key }>
      { renderer(group, idx) }
    </div>
  );
}

/**
 * @deprecated use renderComponentWithTabV2
 */
export function renderComponentWithTab(groups, renderer, onClickTab = () => null,
  prefix = '', tabFormatter = (group) => (group.name), { container, tab, contents } = {}, onClickGenericFieldsTab = () => null) {
  return (
    <div className={ container || 'tab-component-container' }>
      <div className={ tab || 'tab' }>
        { renderTab(groups, onClickTab, onClickGenericFieldsTab, prefix, tabFormatter) }
      </div>
      <div className={ contents || 'tab-content' }>
        { groups.map((group, idx) => (renderTabContent(group, idx, renderer, prefix))) }
      </div>
    </div>
  );
}

/**
 * タブ付きでComponentを描画する
 * タブがクリックされると、描画するComponentが切り替わる
 * Activeになっていないタブは、描画しない
 */
export function renderComponentWithTabV2(groups, renderer, onClickTab = () => null,
  prefix = '', tabFormatter = (group) => (group.name), { tab } = {}, onClickGenericFieldsTab = () => null) {
  const activeGroup = groups.find((x) => x.isActive);

  return (
    <div className='tab-component-container'>
      <div className={ tab || 'tab' }>
        { renderTab(groups, onClickTab, onClickGenericFieldsTab, prefix, tabFormatter) }
      </div>
      <div className='tab-content'>
        <div className='tab-pane-active'>
          { renderer(activeGroup) }
        </div>
      </div>
    </div>
  );
}

export default {
  renderTab,
  renderComponentWithTab,
  renderComponentWithTabV2,
};
