import FormItem from './FormItem';
import PropTypes from 'prop-types';
import React from 'react';
import formatter from 'utilities/formatter';

const Post = (props) => {
  const post = props.value;

  return (
    <>
      <FormItem label='postName'>
        { formatter.text(post.postName, '-') }
      </FormItem>
      <FormItem label='postAddress'>
        { formatter.text(post.postAddress, '-') }
      </FormItem>
    </>
  );
};

Post.defaultProps = {
  value: {},
};

Post.propTypes = {
  value: PropTypes.shape({
    postName: PropTypes.string,     // ポストの名前
    postAddress: PropTypes.string,  // ポストのアドレス
  }),
};

export default React.memo(Post);
