import i18next from "i18n";
import PropTypes from "prop-types";
import React, { Component } from "react";
import Api from "utilities/api";
import flash from "utilities/flash";
import { camelizeKeys } from "utilities/Utils";

export default class ExportFormatSelect extends Component {
  constructor(props) {
    super(props);
    this.state = {
      csvConverters: [],
      formatKey: this.props.defaultExportFormat || "",
    };
  }

  componentDidMount() {
    this.loadCsvConverters();
  }

  onFormatSelect() {
    const selectedConerter = this.state.csvConverters.find((c) => {
      return c.formatKey === this.refs.format.value;
    });
    this.props.onFormatSelect(selectedConerter);
  }

  loadCsvConverters() {
    const { type } = this.props;

    // NOTE API リクエストのパラメータのキーを snake_case 形式で指定しているため、
    //      明示的に quote していますが、 Prettier の自動整形がそれを削除してしまうため、
    //      当該コードは整形されないように ignore を指定しています。
    //
    // prettier-ignore
    Api.csvConverters
      .index({
        "only_accounting_services": true,
        "request_type_for_format": this.requestTypeForFormat(type),
      })
      .fail(this.onError.bind(this))
      .done((data) => {
        const receivedData = camelizeKeys(data);
        this.setState({
          csvConverters: receivedData.csvConverters,
        });
      });
  }

  requestTypeForFormat(type) {
    switch (type) {
      case "preTemporaryPaymentAnalysis": {
        // 事前申請(仮払)
        return "pre_report_request";
      }
      case "preAnalysis": {
        // 旅費申請
        return "pre_report_request";
      }
      case "analysis": {
        // 経費申請
        return "report_request";
      }
      case "report": {
        // 申請・承認一覧 経費申請
        return "report_request";
      }
      case "approvingRequest": {
        return "report_request";
      }
      case "preReport": {
        // 申請・承認一覧 事前申請
        return "pre_report_request";
      }
      default: {
        return null;
      }
    }
  }

  onError(xhr) {
    flash.error(i18next.t("commons.errors.communicationError"));
  }

  render() {
    return (
      <div className="export-format-select">
        <label className="col-sm-3 control-label">
          {i18next.t("exports.inputs.selectFormat")}
        </label>
        <div className="col-sm-6">{this.converterSelect()}</div>
      </div>
    );
  }

  converterSelect() {
    return (
      <select
        ref="format"
        className="form-control"
        onChange={this.onFormatSelect.bind(this)}
        value={this.props.defaultExportFormat}
      >
        {this.state.csvConverters.map((converter) => (
          <option key={converter.formatKey} value={converter.formatKey}>
            {converter.name}
          </option>
        ))}
      </select>
    );
  }
}

ExportFormatSelect.defaultProps = {
  defaultExportFormat: "normal",
  onFormatSelect: () => null,
  type: "",
};

ExportFormatSelect.propTypes = {
  defaultExportFormat: PropTypes.string,
  onFormatSelect: PropTypes.func,
  type: PropTypes.string,
};
