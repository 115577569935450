import i18next from 'i18n';
import isNaN from 'lodash/isNaN';
import { useMemo } from "react";

enum ReservationType {
  exYoyaku,
  exETokkyu,
  exHayatoku,
  exHayatoku21,
  exGreenHayatoku,
  smartEx,
  smartExHayatoku,
  smartExHayatoku21,
  smartExGreenHayatoku,
}

interface Option {
  label: string;
  value: string;
}

interface ReturnType {
  options: Option[],
  selectedValue?: Option
}

const getOptions = (): Array<Option> => {
  const keys = Object.keys(ReservationType).filter((v) => isNaN(Number(v)));
  return keys.map((key) => {
    return {
      value: key,
      label: i18next.t(`transactions.inputs.jrReservation.${key}`),
    };
  });
};

const JrReservationSelector = (discountType?: string): ReturnType => {
  const options: Array<Option> = useMemo(() => getOptions(), []);
  const selectedValue = options.find((x) => (x.value === discountType));

  return {
    options,
    selectedValue,
  };
};

export default JrReservationSelector;
