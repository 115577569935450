import i18next from "i18n";
import Formatter from "utilities/formatter";
import * as Types from "../types";

/** 分割先経費一覧のvalue object */
export class AmountBySeparators {
  private readonly _current: Types.AmountBySeparator[];

  constructor(current: Types.AmountBySeparator[]) {
    this._current = current;
  }

  /**
   * 現在の分割項目別金額一覧オブジェクトを返す
   * @return 現在の分割項目別金額一覧
   */
  get current(): Types.AmountBySeparator[] {
    return this._current;
  }

  /**
   * 分割項目別金額の合計金額を返す
   * @return 合計金額
   */
  get totalAmount(): number {
    return this._current.reduce((acc, cur) => acc + (cur.amount || 0), 0);
  }

  /**
   * 分割項目別金額を追加可能かを返す
   * @return 分割項目別金額を追加可能か
   */
  get canAddSeparator(): boolean {
    return this._current.length < 10;
  }

  /**
   * 分割項目別金額を削除可能かを返す
   * @return 分割項目別金額を削除可能か
   */
  get canRemoveSeparator(): boolean {
    return this._current.length > 2;
  }

  /**
   * 分割項目別金額を初期化する
   * @param separatorType 分割項目種別
   * @param expense 分割元経費
   * @return 分割項目別金額一覧
   */
  static buildAmountBySeparators(
    separatorType: Types.SeparatorType,
    expense: Types.Expense,
  ): AmountBySeparators {
    const id = separatorType && expense ? expense[separatorType].id : "";
    return new AmountBySeparators([
      {
        amount: 0,
        separator: {
          id,
          type: separatorType,
        },
      },
      {
        amount: 0,
        separator: {
          id: "",
          type: separatorType,
        },
      },
    ]);
  }

  /**
   * 分割項目別金額を追加する
   * @return 分割項目別金額一覧
   */
  addNewSeprator(): AmountBySeparators {
    // 金額0, 経費科目未選択の状態で追加
    return new AmountBySeparators(
      this._current.concat([
        {
          amount: 0,
          separator: {
            id: "",
            type: this._current[0].separator.type,
          },
        },
      ]),
    );
  }

  /**
   * 分割項目別金額を削除する
   * @param index 削除対象のインデックス
   * @return 分割項目別金額一覧
   */
  removeSeparator(index: number): AmountBySeparators {
    return new AmountBySeparators(
      this._current.filter((_, idx) => idx !== index),
    );
  }

  /**
   * 分割項目別金額の金額を更新する
   * @param index 更新対象のインデックス
   * @param amount 新しい金額
   * @return 分割項目別金額一覧
   */
  updateAmount(index: number, amount: number): AmountBySeparators {
    const updated = this._current.map((_, i) => {
      return i === index ? { ..._, amount } : _;
    });
    return new AmountBySeparators(updated);
  }

  /**
   * 分割項目別金額の分割項目を更新する
   * @param index 更新対象のインデックス
   * @param separator 新しい分割項目
   * @return 分割項目別金額一覧
   */
  updateSeparator(
    index: number,
    separator: Types.Separator,
  ): AmountBySeparators {
    const updated = this._current.map((_, i) => {
      return i === index ? { ..._, separator } : _;
    });
    return new AmountBySeparators(updated);
  }

  /**
   * 分割できるか判定する
   * @param originalExpenseAmount 分割元の経費の金額
   * @return 分割可能かどうか
   */
  isSplittableAmountSeparators(originalExpenseAmount: number): boolean {
    return this._current.length >= 2 && this.isValid(originalExpenseAmount);
  }

  /**
   * 分割先の合計金額と分割元金額の差額を伝えるメッセージ
   * @param originalExpenseAmount 分割元の経費の金額
   * @return 差額メッセージ。差額0ならnull
   */
  diffMessage(originalExpenseAmount: number): string | null {
    const diffAmount = this.totalAmount - originalExpenseAmount;
    if (diffAmount > 0)
      return i18next.t("expenses.split.diffAmount.overOriginalAmount", {
        diffAmount: Formatter.amount(diffAmount),
      });
    if (diffAmount < 0)
      return i18next.t("expenses.split.diffAmount.lessThanOriginalAmount", {
        diffAmount: Formatter.amount(-diffAmount),
      });
    return null;
  }

  /**
   * 分割先の合計金額と分割元の金額が一致し、すべての項目が入力されているか
   * @param originalExpenseAmount 分割元の経費の金額
   * @return 満たされているかどうか
   */
  private isValid(originalExpenseAmount: number): boolean {
    const isValidTotalAmount = this.totalAmount === originalExpenseAmount;
    const isValidRequiredParams = this._current.every((c) => {
      return c.amount >= 0 && c.separator.id;
    });
    return isValidTotalAmount && isValidRequiredParams;
  }
}
