import Api from 'utilities/api';
import makeAutoSuggestActions from 'actions/autoSuggest';
import {
  collectParams,
  initMembers,
  mergeMembers,
  setCurrentPage,
  setMembers,
} from './membersActions';
import { fetchAsync } from 'actions/AsyncAction';
import { initialState } from '../reducers/memberApprovalFlowSearch';

const prefix = 'member_approval_flow_search';

export function search() {
  return async (dispatch, getState) => {
    const { memberApprovalFlowSearch } = getState();
    dispatch(toggleLoadStatus(true));
    dispatch(mainSearchAction(memberApprovalFlowSearch));
    dispatch(setLastSearchedConditions(memberApprovalFlowSearch));
    dispatch(toggleLoadStatus(false));
  };
}

export function resetSearch() {
  return async (dispatch, getState) => {
    dispatch(mainSearchAction(initialState));
    dispatch(setInitialSearchParams());
  };
}

export function fetchIndividualFlowInfo(id) {
  return async (dispatch, getState) => {
    const data = await dispatch(fetchAsync(Api.approvalFlows.show, { id }));
    dispatch(setIndividualFlowInfo(data.data));
  };
}

export function mainSearchAction(state) {
  return async (dispatch, getState) => {
    const { members } = getState();
    const limit = members.sizePerPage;
    const offset = 0;
    const params = { ...collectParams(state), offset, limit };

    const data = await dispatch(fetchAsync(Api.members.indexForApprovalFlowAssignment, params));
    let currentMembers = initMembers([], data.count);
    currentMembers = mergeMembers(currentMembers, data.members, offset, limit);
    dispatch(setMembers(currentMembers));
    dispatch(setExpanding(state.openDepartmentsDetail ? currentMembers.filter((m) => m).filter((m) => m.departments.length > 1).map((m) => m.id) : [], state.expandingKey + 1));
    dispatch(setCurrentPage(1));
  };
}

export const SET_LAST_SEARCHED_CONDITIONS = `${prefix}/SET_LAST_SEARCHED_CONDITIONS`;
export function setLastSearchedConditions(state) {
  return {
    type: SET_LAST_SEARCHED_CONDITIONS,
    searchText: state.userNameOrEmail,
    departmentId: state.department.id || '',
    departmentScope: state.includeChildDepartment,
    onlyMainDepartment: state.onlyMainDepartment,
    approvalFlow: state.approvalFlow,
    isUnassignedApprovalFlow: state.isUnassignedApprovalFlow,
    requestTypeId: state.requestType.id || '',
    postIds: state.postIds || '',
    postIsUnassigned: state.postIsUnassigned,
    isSearched: true,
  };
}

export const SET_CONDITION_BOX_VALUE = `${prefix}/SET_CONDITION_BOX_VALUE`;
export function setConditionBoxValue(key, value) {
  return {
    type: SET_CONDITION_BOX_VALUE,
    key,
    value,
  };
}

export const SET_INITIAL_SEARCH_PARAMS = `${prefix}/SET_INITIAL_SEARCH_PARAMS`;
export function setInitialSearchParams() {
  return {
    type: SET_INITIAL_SEARCH_PARAMS,
  };
}

export const TOGGLE_SEARCH_BOX = `${prefix}/TOGGLE_SEARCH_BOX`;
export function toggleSearchBox() {
  return {
    type: TOGGLE_SEARCH_BOX,
  };
}

export const TOGGLE_LOAD_STATUS = `${prefix}/TOGGLE_LOAD_STATUS`;
export function toggleLoadStatus(isLoading) {
  return {
    type: TOGGLE_LOAD_STATUS,
    value: isLoading,
  };
}

export const SET_USER_NAME_OR_EMAIL = `${prefix}/SET_USER_NAME_OR_EMAIL`;
export function setUserNameOrEmail(userNameOrEmail) {
  return {
    type: SET_USER_NAME_OR_EMAIL,
    value: userNameOrEmail,
  };
}

export function fetchDepartments() {
  return async (dispatch) => {
    const data = await dispatch(fetchAsync(Api.departments.index, { excludeRoot: true }));
    dispatch(setDepartments(data.departments));
  };
}

export const INIT_DEPARTMENT_SUGGESTIONS = `${prefix}/INIT_DEPARTMENT_SUGGESTIONS`;
export const UPDATE_DEPARTMENT_SUGGESTIONS = `${prefix}/UPDATE_DEPARTMENT_SUGGESTIONS`;
export const SELECT_DEPARTMENT_SUGGESTION = `${prefix}/SELECT_DEPARTMENT_SUGGESTION`;
export const SET_DEPARTMENT_INPUT = `${prefix}/SET_DEPARTMENT_INPUT`;

const departmentSuggestActions = makeAutoSuggestActions({
  initType: INIT_DEPARTMENT_SUGGESTIONS,
  updateType: UPDATE_DEPARTMENT_SUGGESTIONS,
  selectType: SELECT_DEPARTMENT_SUGGESTION,
  setType: SET_DEPARTMENT_INPUT,
}, (x) => x.name);

export const selectDepartmentSuggestion = departmentSuggestActions.selectSuggestion;

export const SET_DEPARTMENT = `${prefix}/SET_DEPARTMENT`;
export function setDepartment(department) {
  return {
    type: SET_DEPARTMENT,
    data: department,
  };
}

export const SET_DEPARTMENTS = `${prefix}/SET_DEPARTMENTS`;
export function setDepartments(departments) {
  return {
    type: SET_DEPARTMENTS,
    data: departments,
  };
}

export const SET_POSTS_FORM = `${prefix}/SET_POSTS_FORM`;
export function setPostsForm(isOpened) {
  return {
    type: SET_POSTS_FORM,
    value: isOpened,
  };
}

export const SET_POST_IDS = `${prefix}/SET_POST_IDS`;
export function setPostIds(postIds) {
  return {
    type: SET_POST_IDS,
    payload: postIds,
  };
}

export const SET_POST_UNASSIGNED = `${prefix}/SET_POST_UNASSIGNED`;
export function setPostUnassigned(isSelected) {
  return {
    type: SET_POST_UNASSIGNED,
    payload: isSelected,
  };
}

export const SET_POSTS = `${prefix}/SET_POSTS`;
export function setPosts(posts) {
  return {
    type: SET_POSTS,
    payload: posts,
  };
}

export const SET_REQUEST_TYPE = `${prefix}/SET_REQUEST_TYPE`;
export function setRequestType(requestTypeId, requestTypeName) {
  return {
    type: SET_REQUEST_TYPE,
    id: requestTypeId,
    name: requestTypeName,
  };
}

export const SET_REQUEST_TYPES = `${prefix}/SET_REQUEST_TYPES`;
export function setRequestTypes(requestTypes) {
  return {
    type: SET_REQUEST_TYPES,
    data: requestTypes,
  };
}

export const SET_INDIVIDUAL_FLOW_INFO = `${prefix}/SET_INDIVIDUAL_FLOW_INFO`;
export function setIndividualFlowInfo(info) {
  return {
    type: SET_INDIVIDUAL_FLOW_INFO,
    data: info,
  };
}

export const SET_IS_FLOW_INFO_MODAL_OPEN = `${prefix}/SET_IS_FLOW_INFO_MODAL_OPEN`;
export function setIsFlowInfoModalOpen(state) {
  return {
    type: SET_IS_FLOW_INFO_MODAL_OPEN,
    data: state,
  };
}

export const SET_FLOW_INFO_MODAL = `${prefix}/SET_FLOW_INFO_MODAL`;
export function setFlowInfoModal(type, flow) {
  return {
    type: SET_FLOW_INFO_MODAL,
    data: {
      type,
      flow,
    },
  };
}

export const SET_EXPANDING = `${prefix}/SET_EXPANDING`;
export function setExpanding(ids, key) {
  return {
    type: SET_EXPANDING,
    expanding: ids,
    expandingKey: key,
  };
}

export const SELECT_APPROVAL_FLOW = `${prefix}/SELECT_APPROVAL_FLOW`;
export function selectApprovalFlow(approvalFlow) {
  return {
    type: SELECT_APPROVAL_FLOW,
    payload: {
      approvalFlow,
    },
  };
}
