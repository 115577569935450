import SuggestField from "components/fields/SuggestField";
import i18next from "i18n";
import isEmpty from "lodash/isEmpty";
import isNil from "lodash/isNil";
import PropTypes from "prop-types";
import React, { Component } from "react";
import { from } from "rxjs";
import Api from "utilities/api";
import { fetchAsync, handleError } from "utilities/async";
import StationListElement from "./StationListElement";

export default class StationSuggest extends Component {
  constructor(props) {
    super(props);

    this.fetchOptions = this.fetchOptions.bind(this);
    this.handleFetch = this.handleFetch.bind(this);
    this.getInputProps = this.getInputProps.bind(this);

    this.state = { recentStations: null };
  }

  componentDidMount() {
    this.fetchSubscription = from(this.fetchRecentStations()).subscribe(
      ({ data }) => {
        this.setState({ recentStations: data });
      },
    );
  }

  componentWillUnmount() {
    if (this.fetchSubscription) {
      this.fetchSubscription.unsubscribe();
    }
  }

  get isSelected() {
    return !isNil(this.props.value);
  }

  get availableTypes() {
    const types = [];

    if (this.props.includeTrain) {
      types.push("train");
    }

    if (this.props.includeBus) {
      types.push("bus");
    }

    if (this.props.includePlane) {
      types.push("plane");
    }

    return types;
  }

  handleFetch(params = {}) {
    if (!params.name) {
      return Promise.resolve({ stations: this.state.recentStations || [] });
    }

    if (isNil(params.types) || isEmpty(params.types)) {
      handleError(
        new Error(i18next.t("transactions.errors.noTransportFacilities")),
      );
      return Promise.resolve({ stations: this.state.recentStations || [] });
    }

    return Api.transits.suggest(params);
  }

  fetchRecentStations() {
    return fetchAsync(Api.transits.recentStations, {
      userId: this.props.ownerId,
    }).then((response) => ({ data: response.stations }));
  }

  fetchOptions(text, _offset, _limit) {
    const params = {
      name: text,
      types: this.availableTypes.join(":"),
    };

    return fetchAsync(this.handleFetch, params).then((response) => {
      return { count: response.stations.length, data: response.stations };
    });
  }

  getOptionText(option) {
    return option.name;
  }

  renderOption(option) {
    return <StationListElement datum={option} />;
  }

  getInputProps(props) {
    const colorClass = this.isSelected ? "input-success" : "input-warning";

    return {
      ...props,
      className: `form-control ${colorClass}`,
    };
  }

  render() {
    return (
      <SuggestField
        async={true}
        text={this.props.text}
        value={this.props.value}
        fetchOptions={this.fetchOptions}
        getOptionText={this.getOptionText}
        getInputProps={this.getInputProps}
        renderOption={this.renderOption}
        onSelect={this.props.onSelect}
        onTextChange={this.props.onTextChange}
      />
    );
  }
}

StationSuggest.defaultProps = {
  includeTrain: true,
  includeBus: false,
  includePlane: false,
};

StationSuggest.propTypes = {
  ownerId: PropTypes.string,
  text: PropTypes.string,
  value: PropTypes.shape({
    name: PropTypes.string.isRequired,
  }),
  includeTrain: PropTypes.bool,
  includeBus: PropTypes.bool,
  includePlane: PropTypes.bool,
  onTextChange: PropTypes.func,
  onSelect: PropTypes.func,
};
