import i18next from "i18n";
import indexOf from "lodash/indexOf";
import isNil from "lodash/isNil";
import set from "lodash/set";
import uniq from "lodash/uniq";
import PropTypes from "prop-types";
import React, { Component } from "react";
import { getUserLocale } from "utilities/Utils";

export default class FileDropAreaV2 extends Component {
  constructor(prop) {
    super(prop);

    this.handleDragOver = this.handleDragOver.bind(this);
    this.handleFileDrop = this.handleFileDrop.bind(this);
    this.handleFileSelect = this.handleFileSelect.bind(this);
    this.clearFiles = this.clearFiles.bind(this);
    this.formatContentTypes = this.formatContentTypes.bind(this);
  }

  // https://github.com/react-dropzone/react-dropzone/blob/master/src/utils/index.js
  getDataTransferItems(event) {
    let dataTransferItemsList = [];
    if (event.dataTransfer) {
      const dt = event.dataTransfer;
      if (dt.files && dt.files.length) {
        dataTransferItemsList = dt.files;
      } else if (dt.items && dt.items.length) {
        // During the drag even the dataTransfer.files is null
        // but Chrome implements some drag store, which is accesible via dataTransfer.items
        dataTransferItemsList = dt.items;
      }
    } else if (event.target && event.target.files) {
      dataTransferItemsList = event.target.files;
    }
    // Convert from DataTransferItemsList to the native Array
    return Array.prototype.slice.call(dataTransferItemsList);
  }

  isValidExtension(filename) {
    const { validContentTypes } = this.props;
    const ext = filename.substring(filename.lastIndexOf(".")).toLowerCase();

    for (let i = 0; i < validContentTypes.length; i++) {
      const contentType = validContentTypes[i];
      switch (contentType) {
        case "image/jpeg":
          if (ext === ".jpg" || ext === ".jpeg") {
            return true;
          }
          break;
        case "image/png":
          if (ext === ".png") {
            return true;
          }
          break;
        case "image/gif":
          if (ext === ".gif") {
            return true;
          }
          break;
        case "application/pdf":
          if (ext === ".pdf") {
            return true;
          }
          break;
        case "application/vnd.ms-excel":
          if (ext === ".xls") {
            return true;
          }
          break;
        case "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet":
          if (ext === ".xlsx") {
            return true;
          }
          break;
        case "text/csv":
          if (ext === ".csv") {
            return true;
          }
          break;
        default:
          break;
      }
    }

    return false;
  }

  acceptFiles(files) {
    const length = files.length;
    const { validContentTypes, multiple } = this.props;
    const acceptedFiles = [];

    for (let i = 0; i < length; i++) {
      const file = files[i];
      if (indexOf(validContentTypes, file.type) >= 0) {
        acceptedFiles.push(file);
      } else if (this.isValidExtension(file.name)) {
        // windowsではfile.typeが取得できない場合があるため、拡張子が合っていれば受け入れる
        acceptedFiles.push(file);
      }
    }

    if (multiple) {
      return acceptedFiles;
    }

    if (acceptedFiles.length > 0) {
      return acceptedFiles[0];
    }

    return null;
  }

  clearFiles() {
    $(this.fileInput).val("");
  }

  handleDragOver(e) {
    e.stopPropagation();
    e.preventDefault();
    set(e.dataTransfer, "dropEffect", "copy");
  }

  handleFileSelect(evt) {
    evt.stopPropagation();
    evt.preventDefault();

    const files = this.getDataTransferItems(evt);
    this.props.onSelect(this.acceptFiles(files));
    if (!this.props.appendOnSelect) this.clearFiles();
  }

  handleFileDrop(evt) {
    evt.stopPropagation();
    evt.preventDefault();

    const files = this.getDataTransferItems(evt);
    this.props.onSelect(this.acceptFiles(files));
    if (!this.props.appendOnSelect) this.clearFiles();
    // TODO: input[type=file]のtooltipを隠す
  }

  render() {
    return (
      <div
        className="droparea"
        onDragOver={this.handleDragOver}
        onDrop={this.handleFileDrop}
      >
        {this.renderMessage()}
        <input
          ref={(input) => {
            this.fileInput = input;
          }}
          type="file"
          multiple={this.props.multiple}
          accept={this.props.validContentTypes.join(",")}
          onChange={this.handleFileSelect}
        />
      </div>
    );
  }

  renderMessage() {
    const { fileNames, validContentTypes } = this.props;

    if (fileNames.length > 0) {
      // TODO: i18n対応
      let fileNameStr = fileNames[0];
      if (fileNames.length > 1) {
        fileNameStr += `など${fileNames.length}ファイル`;
      }

      return (
        <p
          // eslint-disable-next-line react/no-danger
          dangerouslySetInnerHTML={{
            __html: i18next.t("transactions.droparea.filename", {
              name: fileNameStr,
            }),
          }}
        ></p>
      );
    }

    // TODO: 複数ファイルを送信可能なことを表示
    return (
      <div>
        {i18next.t("transactions.droparea.click", {
          ext: this.formatContentTypes(validContentTypes),
        })}
        <br />
        <em>{i18next.t("transactions.droparea.or")}</em>
        <br />
        {i18next.t("transactions.droparea.dragAndDrop", {
          ext: this.formatContentTypes(validContentTypes),
        })}
        <br />
      </div>
    );
  }

  formatContentTypes(exts) {
    const locale = getUserLocale();

    const fileTypes = exts.map((ext) => {
      switch (ext) {
        case "image/jpeg":
        case "image/gif":
        case "image/png":
        case "image/x-png":
          return locale === "ja" ? "画像" : "Image";
        case "text/csv":
          return "csv";
        case "application/pdf":
          return "pdf";
        case "application/vnd.ms-excel":
          return "xls";
        case "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet":
          return "xlsx";
        default:
          return null;
      }
    });

    return uniq(fileTypes.filter((x) => !isNil(x))).join(", ");
  }
}

FileDropAreaV2.defaultProps = {
  multiple: true,
  fileNames: [],
  validContentTypes: ["image/jpeg", "image/png", "image/gif"],
  onSelect(files) {
    // files {Array|String} 選択されたファイルのリスト
  },
  appendOnSelect: true,
};

FileDropAreaV2.propTypes = {
  multiple: PropTypes.bool.isRequired,
  fileNames: PropTypes.arrayOf(PropTypes.string).isRequired,
  validContentTypes: PropTypes.arrayOf(PropTypes.string).isRequired,
  onSelect: PropTypes.func.isRequired,
  appendOnSelect: PropTypes.bool.isRequired,
};
