import SimpleModal from "components/SimpleModal";
import { useMountedRef } from "hooks";
import i18next from "i18n";
import React, { useCallback, useState } from "react";

interface Request {
  id: string;
}

interface Application {
  request: Request;
}

interface ApplicationRequest {
  showCancelApprovalModal: boolean;
  cancelApprovalComment: string;
}

interface ApplicationRequestActions {
  closeCancelApprovalModal: () => void;
  setCancelApprovalComment: (comment: string) => void;
}

interface ApplicationRequestAsyncActions {
  cancelApproval: (requestId: string, comment: string) => Promise<void>;
}

interface Props {
  /** 汎用申請 */
  application: Application;
  /** 汎用申請のRequest */
  applicationRequest: ApplicationRequest;
  /** 汎用申請のRequestのアクション */
  applicationRequestActions: ApplicationRequestActions;
  /** 汎用申請のRequestのユースケース */
  applicationRequestAsyncActions: ApplicationRequestAsyncActions;
}

export const CancelApprovalModal: React.FC<Props> = ({
  application,
  applicationRequest,
  applicationRequestActions,
  applicationRequestAsyncActions,
}): JSX.Element => {
  const isMountedRef = useMountedRef();
  const [disabled, setDisabled] = useState(false);

  const handleCancelApproval = useCallback(() => {
    setDisabled(true);
    applicationRequestAsyncActions
      .cancelApproval(
        application.request.id,
        applicationRequest.cancelApprovalComment,
      )
      .finally(() => {
        if (isMountedRef.current) {
          setDisabled(false);
        }
      });
  }, [
    application.request.id,
    applicationRequest.cancelApprovalComment,
    applicationRequestAsyncActions,
    isMountedRef,
  ]);

  return (
    <SimpleModal
      title={i18next.t("reports.requests.enterComment")}
      show={applicationRequest.showCancelApprovalModal}
      close={applicationRequestActions.closeCancelApprovalModal}
      buttons={[
        {
          className: "col-sm-offset-3 col-sm-6",
          content: (
            <span className="fa fa-left fa-undo">
              {" "}
              {i18next.t("commons.actions.withdrawApproval")}
            </span>
          ),
          color: "danger",
          disabled,
          onClick: (): void => {
            handleCancelApproval();
          },
        },
      ]}
    >
      <div className="form-horizontal">
        <div className="form-group">
          <div className="col-sm-6 col-sm-offset-3">
            <input
              className="form-control"
              type="text"
              value={applicationRequest.cancelApprovalComment}
              placeholder={i18next.t("commons.actions.enterComment")}
              onChange={(e): void => {
                applicationRequestActions.setCancelApprovalComment(
                  e.target.value,
                );
              }}
            />
          </div>
        </div>
      </div>
    </SimpleModal>
  );
};
