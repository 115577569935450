import React, { useMemo } from 'react';
import i18next from 'i18next';
import styled from 'styled-components';
import { AggregateSuccessItem } from './AggregateSuccessItem';
import { JobRequest } from 'utilities/api/models/accountingDataScheduledExports';

interface Props {
  jobRequest: null | JobRequest;
}

const Icon = styled.i`
  margin-right: 0.5rem;
`;

const TimesIcon = styled(Icon)`
  &::before {
    display: inline-block;
    width: 14px;
  }
`;

const aggregationFailureText = i18next.t('accountingDataScheduledExports.jobRequests.latestJobExecution.aggregationFailure');

export const AggregateResultsItem: React.FC<Props> = ({
  jobRequest,
}) => {
  const analysisId = useMemo(() => jobRequest?.jobExecution?.jobAggregation?.analysis?.id, [jobRequest]);

  if (!jobRequest?.jobExecution?.id) { return <></>; }

  return analysisId
    ? (
      <AggregateSuccessItem jobRequest={ jobRequest } />
    ) : (
      <div>
        <TimesIcon className='text-danger fa fa-times' />{ aggregationFailureText }
      </div>
    );
};
