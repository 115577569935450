/**
 * localStorageにアクセスする際に使用するラッパークラス
 *
 * @todo アクセス時に、ProxyやReflectを使う
 */

import _isArray from 'lodash/isArray';
import _isEmpty from 'lodash/isEmpty';

/**
 * ローカルストレージが使用可能かを返す
 */
function isLocalStorageAvailable() {
  try {
    window.localStorage.setItem('test', 'test');
    window.localStorage.removeItem('test');

    return true;
  } catch (e) {
    return false;
  }
}

/**
 * ローカルストレージを扱う
 */
export default class WebStorage {
  constructor(namespace = [], type = 'localStorage') {
    this.storage = type === 'localStorage' && isLocalStorageAvailable() ? window.localStorage : window.sessionStorage;
    this._namespace = namespace;
    // 現在のアプリのバージョン
    this._version = process.env.APP_VERSION || 'latest';

    try {
      this.storage.setItem('dk:version', this._version);
    } catch (e) {
      console.error(e);  // eslint-disable-line no-console
      // この段階で出来ることは何もないので、何もしない
    }
  }

  /**
   * アプリのバージョンを返す
   */
  get version() {
    return this._version;
  }

  /**
   * ローカルストレージのnamespaceを返す
   */
  get namespace() {
    return this._namespace;
  }

  /**
   * localStorageのnamespaceをセットする
   * getItemやsetItemをする時に、その名前空間以下の値を参照するようになる
   *
   * @param {string|string[]} namespace
   */
  set namespace(namespace) {
    if (typeof namespace === 'string') {
      this._namespace = [namespace];
    } else if (_isArray(namespace)) {
      this._namespace = namespace;
    } else if (process.env.NODE_ENV !== 'production') {
      console.warn('invalid namespace'); // eslint-disable-line no-console
    }
  }

  /**
   * ローカルストレージを全て削除する
   */
  clearAll() {
    Object.keys(this.storage).forEach((key) => {
      if (key.match(/dk:.*/)) {
        this.storage.removeItem(key);
      }
    });
  }

  /**
   * @param {string|string[]} key
   * @return {string}
   * @private
   */
  buildKey(key) {
    let keyString = 'dk';

    // 一部ページではuserPreferencesが定義されてなくエラーになるのでtry-catchで囲む
    try {
      if (!_isEmpty(userPreferences?.rootGroup?.id)) {
        keyString += `:${userPreferences.rootGroup.id}`;
      }
    } catch {} // eslint-disable-line no-empty

    if (!_isEmpty(this.namespace)) {
      keyString += `:${this.namespace.join('')}`;
    }

    if (_isArray(key)) {
      keyString += `:${key.join(':')}`;
    } else if (typeof key === 'string') {
      keyString += `:${key}`;
    }

    return keyString;
  }

  /**
   * localStorageに保存できる形式に値を変換する
   *
   * @return {string}
   * @private
   */
  buildValue(value) {
    return JSON.stringify(value);
  }

  /**
   * @private
   */
  parseValue(value) {
    return JSON.parse(value);
  }

  /**
   * Storage#getItemのラッパー
   *
   * @param {string|string[]} key
   * @return {*}
   */
  getItem(key) {
    const rawValue = this.storage.getItem(this.buildKey(key));
    if (rawValue !== null) {
      return this.parseValue(rawValue);
    }

    // 事業所idを含まない古い型のストレージを取得する。
    // 古い型のデータがあれば新しい型に書き換え、古い方は消去する。
    return this.getOldItem(key);
  }

  /**
   * Storage#setItemのラッパー
   *
   * @param {string|string[]} key
   * @param {*} value
   */
  setItem(key, value) {
    try {
      this.storage.setItem(this.buildKey(key), this.buildValue(value));
    } catch (e) {
      console.error(e);  // eslint-disable-line no-console
      // この段階で出来ることは何もないので、何もしない
    }
  }

  /**
   * Storage#removeItemのラッパー
   *
   * @param {string|string[]} key
   */
  removeItem(key) {
    this.storage.removeItem(this.buildKey(key));
  }

  /**
   * 事業所idを含まない古い型のストレージを取得する。
   * 古い型のデータがあれば新しい型に書き換え、古い方は消去する。
   *
   * @param {string|string[]} key
   * @return {*}
   */
  getOldItem(key) {
    const oldKey = this.buildOldKey(key);
    const rawOldValue = this.storage.getItem(oldKey);

    const resultValue = this.parseValue(rawOldValue);

    if (rawOldValue === null) {
      return resultValue;
    }

    // 新しい型にデータをコピー
    this.setItem(key, resultValue);
    // 古い型のデータを削除する
    this.storage.removeItem(oldKey);

    return resultValue;
  }

  /**
   * 事業所idを含まない古い型のストレージのkeyを構築する
   * @param {string|string[]} key
   * @return {string}
   * @private
   */
  buildOldKey(key) {
    let keyString = 'dk';

    if (!_isEmpty(this.namespace)) {
      keyString += `:${this.namespace.join('')}`;
    }

    if (_isArray(key)) {
      keyString += `:${key.join(':')}`;
    } else if (typeof key === 'string') {
      keyString += `:${key}`;
    }

    return keyString;
  }
}
