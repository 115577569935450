import React, { useCallback, useEffect, useState } from 'react';
import i18next from 'i18next';
import { Button } from 'components/renewaled_ui/buttons/Button';
import { CreateModal } from './CreateModal';
import { FileTransferLocationTable } from './FileTransferLocationTable';
import { NoLicensePage } from '../no_license/NoLicensePage';
import { Toolbar } from '../components/Toolbar';
import { useLicense as useExpenseLicense } from '../no_license/hooks/useLicense';
import { useFileTransferLocations } from './hooks/useFileTransferLocations';
import { useLicense as useInvoiceLicense } from 'applications/payment_requests/accounting_data_scheduled_exports/no_license/hooks/useLicense';

interface Props {
  id?: string;
}

export const IndexPage: React.FC<Props> = ({
  id,
}) => {
  const [showCreateModal, setShowCreateModal] = useState<boolean>(false);

  const { hasLicense: hasExpenseLicense } = useExpenseLicense();
  const { hasLicense: hasInvoiceLicense } = useInvoiceLicense();
  const { locations, fetchLocations, isLoading } = useFileTransferLocations();

  const handleCreated = useCallback(() => {
    fetchLocations();
    setShowCreateModal(false);
  }, [fetchLocations, setShowCreateModal]);

  useEffect(() => {
    if (hasExpenseLicense || hasInvoiceLicense) fetchLocations();
  }, [hasExpenseLicense, hasInvoiceLicense]);

  if (!hasExpenseLicense && !hasInvoiceLicense) {
    // ライセンスが無い場合
    return (
      <>
        <Toolbar fileTransferLocation={ true } />
        <NoLicensePage />
      </>
    );
  }

  return (
    <>
      <Toolbar fileTransferLocation={ true }>
        <Toolbar.Action>
          {
            userPreferences.isAdmin && <>
              <Button styleType='primary' onClick={ (): void => setShowCreateModal(true) }>{ i18next.t('accountingDataScheduledExports.fileTransferLocations.indexPage.new') }</Button>
            </>
          }
        </Toolbar.Action>
      </Toolbar>
      <FileTransferLocationTable
        fileTransferLocations={ locations }
        isLoading={ isLoading }
        onDeleted={ fetchLocations }
        onUpdated={ fetchLocations }
        defaultSelectedId={ id }
      />
      <CreateModal show={ showCreateModal } onClose={ (): void => setShowCreateModal(false) } onCreated={ handleCreated } />
    </>
  );
};
