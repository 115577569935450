import i18next from "i18n";
import React from "react";
import RequestStateButton from "./RequestStateButton";
import { Props } from "./Types";

/** 「精算する」ボタン */
const SettlementButton: React.FC<Props> = ({ requestState, onClick }) => {
  return (
    <RequestStateButton
      buttonJobType="settlement"
      buttonTitle={i18next.t("commons.actions.settlementRequest")}
      buttonClassName="btn btn-block btn-success"
      iconClassName="fa fa-left fa-paper-plane"
      requestState={requestState}
      onClick={onClick}
    />
  );
};

export default SettlementButton;
