import Api from 'utilities/api';
import LabelWithDateFormatPopover from './components/LabelWithDateFormatPopover';
import React, { useEffect, useMemo, useState } from 'react';
import i18next from 'i18next';
import styled from 'styled-components';
import { ExportFormat, TaskExportFormatAssignee } from 'utilities/api/models/accountingDataScheduledExports';
import { ExportFormatSelectField } from './fields/ExportFormatSelectField';
import { InputField } from 'components/renewaled_ui/forms/inputs';
import { RequiredIcon } from 'applications/generic_fields/components/icons/RequiredIcon';
import { useFieldArray, useFormContext, useWatch } from 'react-hook-form';

interface Props {
  initValue: TaskExportFormatAssignee,
  enableAccountingDataCreation: boolean
}

const ExportFormatStyled = styled.div`
  padding: 10px 15px 5px;
  border-radius: 8px;
  margin: 5px 0px;

  &:hover {
    background: #e2e7fd;
  }
`;

const ButtonAdd = styled.span`
  display: flex;
  justify-content: flex-end;
  span {
    cursor: pointer;
    color: #3264c4;
  }
`;

const ExportFormatAssigneesComponent = ({
  initValue,
  enableAccountingDataCreation,
}: Props): JSX.Element => {
  const { control } = useFormContext();

  const [processing, setProcessing] = useState<boolean>(false);
  const [exportFormats, setExportFormats] = useState<ExportFormat[]>([]);
  const exportFormatAssignees: TaskExportFormatAssignee[] = useWatch({
    control, name: 'exportFormatAssignees',
  });

  const { fields, append, remove } = useFieldArray({ control, name: "exportFormatAssignees" });

  const rules = useMemo(() => (enableAccountingDataCreation
    ? { required: i18next.t('accountingDataScheduledExports.tasks.forms.export.exportFileNameFormat.required') }
    : {}), [enableAccountingDataCreation]);

  const showCloseButton = useMemo(() => fields && Array.isArray(fields) && fields.length > 1, [fields]);

  const renderLabel = (): JSX.Element => {
    return (<>
      <LabelWithDateFormatPopover
        label={ <>
          {i18next.t('accountingDataScheduledExports.tasks.forms.export.exportFileNameFormat.label')} <RequiredIcon />
        </> }
      />
    </>);
  };

  useEffect(() => {
    const fetchExportFormats = async (): Promise<void> => {
      setProcessing(true);

      try {
        const res = await Api.accountingDataScheduledExports.exportFormats.index();
        setExportFormats(res.data);
      } catch (e) {
        // nothing to do
      } finally {
        setProcessing(false);
      }
    };
    fetchExportFormats();
  }, []);

  useEffect(() => {
    setExportFormats((values) => {
      return values.map((value) => {
        if (exportFormatAssignees.some((exportFormatAssigne) => exportFormatAssigne.exportFormat?.id === value.id)) {
          return {
            ...value,
            isDisabled: true,
          };
        }
        return {
          ...value,
          isDisabled: false,
        };
      });
    });
  }, [exportFormatAssignees]);

  return (
    <>
      {
        fields.map((item, index) => (
          <ExportFormatStyled key={ item.id }>
            {
              showCloseButton && <button type="button" className="close" onClick={ (): void => remove(index) } >
                <span>×</span>
              </button>
            }
            <ExportFormatSelectField
              name={ `exportFormatAssignees[${index}].exportFormat` }
              processing={ processing }
              exportFormats={ exportFormats }
            />
            <InputField
              name={ `exportFormatAssignees[${index}].exportFileNameFormat` }
              label={ renderLabel() }
              rules={ rules }
              control={ control }
              placeholder={ i18next.t('accountingDataScheduledExports.tasks.forms.export.exportFileNameFormat.placeholder') }
            />
          </ExportFormatStyled>
        ))
      }
      <ButtonAdd>
        <span className="" onClick={ (): void => append(initValue) }>+ 出力形式を追加</span>
      </ButtonAdd>
    </>
  );
};

export default ExportFormatAssigneesComponent;
