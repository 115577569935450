import Clearable from 'components/fields/Clearable';
import FieldWithInputModal from 'components/fields/FieldWithInputModal';
import GenericFieldsItemsSelector from 'components/generic_fields_selector/GenericFieldsItemsSelector';
import React, { FC, ReactNode, useCallback } from 'react';
import i18next from 'i18n';
import { Item } from 'utilities/api/models/genericFields';
import { text as formatText } from 'utilities/formatter';

interface DataSetWithItems {
  dataSetId: string;
  name: string;
  items?: Item[];
}

interface Props {
  dataSet?: DataSetWithItems;
  isMultipleSelectable: boolean;
  onSelectItems: (items: Item[]) => void;
}

const formatButton = (items: Item[], isMultipleSelectable: boolean): ReactNode => {
  if (isMultipleSelectable) {
    if (items.length) {
      return (
        formatText(items.map((i) => i.name).join(', '), i18next.t('commons.status.notEntered'))
      );
    }

    return (
      <span>{ i18next.t('commons.status.notEntered') }</span>
    );
  }

  if (items.length) {
    const displayName = (item): string => {
      if (item.isDeleted) return `${item.name} (${i18next.t('genericFields.items.labels.isDeleted')})`;

      return `${item.name}`;
    };

    return (
      <span>{ displayName(items[0]) }</span>
    );
  }

  return (
    <span>{ i18next.t('commons.status.notEntered') }</span>
  );
};

const ItemFieldWithModalSelector: FC<Props> = ({
  dataSet,
  isMultipleSelectable,
  onSelectItems,
}) => {
  const selectedItems = dataSet?.items || [];

  const renderField = useCallback(({ className, onClick }) => {
    // 複数選択時
    if (isMultipleSelectable) {
      return (
        <div
          onClick={ onClick }
          className={ `${className} form-control` }
          style={ selectedItems.length === 0 ? { color: '#bdc3c7' } : {} }
        >
          { formatButton(selectedItems, isMultipleSelectable) }
          <i className='caret' style={ { color: '#000' } } />
        </div>
      );
    }

    // 単一選択時
    return (
      <Clearable
        onClick={ onClick }
        className={ `${className} form-control` }
        onClear={ ():void => onSelectItems([]) }
        disabled={ false }
        style={ selectedItems.length === 0 ? { color: '#bdc3c7' } : {} }
      >
        { formatButton(selectedItems, isMultipleSelectable) }
        <i className='caret' style={ { color: '#000' } } />
      </Clearable>
    );
  }, [selectedItems, isMultipleSelectable, onSelectItems]);

  const renderTitle = `${dataSet?.name || ''} ${i18next.t('genericFields.items.titles.selectorTitle')}`;

  const renderModalBody = useCallback(() => {
    return (
      <GenericFieldsItemsSelector
        dataSet={ dataSet }
        isUserCreatableItem={ false }
        isMultipleSelectable={ isMultipleSelectable }
        onSelectItems={ onSelectItems }
        includeDeleted={ true }
      />
    );
  }, [dataSet, isMultipleSelectable, onSelectItems]);

  return (
    <FieldWithInputModal
      className={ 'generic-fields-input' }
      isStatic={ false }
      renderField={ renderField }
      renderModalBody={ renderModalBody }
      modalTitle={ renderTitle }
      modalSize='md'
      showCloseButton={ true }
    />
  );
};

export default ItemFieldWithModalSelector;
