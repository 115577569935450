import React, { useCallback, useEffect, useState } from 'react';
import Select from 'react-select';
import SmartCheckBox from 'components/SmartCheckBox';
import styled from 'styled-components';
import { Controller, useFormContext } from 'react-hook-form';
import { FormGroup } from 'react-bootstrap';

const FormGroupView = styled(FormGroup)`
  .form-control:focus {
    outline: none;
    box-shadow: none;
    border: 2px solid #004acc;
    background-color: #ffffff;
  }
  &.has-success div[class$="-control"] {
    border-color: #399d46;
    box-shadow: none;
  }
  &.has-success .control-label {
    color: #399d46;
  }
  &.has-error div[class$="-control"] {
    border-color: #d9534f;
    box-shadow: none;
  }
  &.has-error .control-label {
    color: #d9534f;
  }
  &.has-error .help-block {
    color: #d9534f;
  }
`;

interface Option {
  value: string | number | null;
  label: string;
}

const monthRanges: Option[] = [
  { value: null, label: '　' },
  { value: 'last_month_specified_date', label: '先月' },
  { value: 'this_month_specified_date', label: '今月' },
  { value: 'next_month_specified_date', label: '翌月' },
];

const dateOptions: Option[] = [...Array(31)].map((_, i) => ({ value: i + 1, label: `${i + 1}日` }));
const placeHolder: Option = { value: null, label: '　' };
dateOptions.unshift(placeHolder);

export const SpecifiedDateSelectField: React.FC = () => {
  const {
    control,
    watch,
    setValue,
    formState: {
      dirtyFields,
    },
  } = useFormContext();
  const [checked, setChecked] = useState(false);
  const specifiedDateOfMonthRange = watch('specifiedDateOfMonthRange');
  const specifiedDateOfLastDayOfMonth = watch('specifiedDateOfLastDayOfMonth');

  useEffect(() => {
    if (!dirtyFields?.specifiedDateOfMonthRange) return;

    switch (specifiedDateOfMonthRange) {
      case 'last_month_specified_date':
        setValue('specifiedDateOfDay', 1);
        setValue('specifiedDateOfLastDayOfMonth', false);
        break;
      case 'this_month_specified_date':
        setValue('specifiedDateOfDay', 1);
        setValue('specifiedDateOfLastDayOfMonth', false);
        break;
      case 'next_month_specified_date':
        setValue('specifiedDateOfDay', 1);
        setValue('specifiedDateOfLastDayOfMonth', false);
        break;
      case null:
        setValue('specifiedDateOfDay', null);
        setValue('specifiedDateOfLastDayOfMonth', false);
        break;
      default:
        break;
    }
  }, [specifiedDateOfMonthRange, dirtyFields, setValue]);

  useEffect(() => {
    if (!dirtyFields?.executionDayOfMonth) return;

    setValue('specifiedDateOfDay', specifiedDateOfLastDayOfMonth ? null : 1);
  }, [specifiedDateOfLastDayOfMonth, dirtyFields, setValue]);

  useEffect(() => {
    if (!specifiedDateOfMonthRange) return;

    setChecked(true);
  }, [specifiedDateOfMonthRange, setChecked]);

  const handleUsingClick = useCallback((newChecked) => {
    if (newChecked) {
      setValue('specifiedDateOfMonthRange', "this_month_specified_date");
      setValue('specifiedDateOfDay', 1);
      setValue('specifiedDateOfLastDayOfMonth', false);
    } else {
      setValue('specifiedDateOfMonthRange', null);
      setValue('specifiedDateOfDay', null);
      setValue('specifiedDateOfLastDayOfMonth', false);
    }

    setChecked(newChecked);
  }, [setChecked, setValue]);

  useEffect(() => {
    if (checked && dirtyFields?.specifiedDateOfLastDayOfMonth) {
      setValue('specifiedDateOfDay', specifiedDateOfLastDayOfMonth ? null : 1);
    }
  }, [specifiedDateOfLastDayOfMonth, dirtyFields, setValue, checked]);

  useEffect(() => {
    return (): void => {
      setValue('specifiedDateOfMonthRange', null);
      setValue('specifiedDateOfDay', null);
      setValue('specifiedDateOfLastDayOfMonth', false);
      setChecked(false);
    };
  }, [setValue, setChecked]);

  return (
    <>
      <FormGroupView>
        <SmartCheckBox onChange={ handleUsingClick } checked={ checked } label={ '一括取引指定日付' } />
        {
          checked && <>
            <div style={ { display: 'flex', gap: '12px', alignItems: 'center' } }>
              <Controller
                name='specifiedDateOfMonthRange'
                control={ control }
                shouldUnregister={ true }
                render={ ({ field: { onChange, value, ref: inputRef } }): React.ReactElement => (
                  <div style={ { width: '100px' } }>
                    <Select
                      inputRef={ inputRef }
                      options={ monthRanges }
                      value={ monthRanges?.find((c) => c.value === value) }
                      getOptionLabel={ (v: Option): string => v.label }
                      getOptionValue={ (v: Option): string | number | null => v.value }
                      onChange={ (v: Option): void => onChange(v?.value) }
                      isSearchable={ false }
                    />
                  </div>
                ) }
              />
              {
                !specifiedDateOfLastDayOfMonth && (
                  <Controller
                    name='specifiedDateOfDay'
                    control={ control }
                    render={ ({ field: { onChange, value, ref: inputRef } }): React.ReactElement => (
                      <div style={ { width: '100px' } }>
                        <Select
                          inputRef={ inputRef }
                          options={ dateOptions }
                          value={ dateOptions?.find((c) => c.value === value) }
                          getOptionLabel={ (v: Option): string => v.label }
                          getOptionValue={ (v: Option): string | number | null => v.value }
                          onChange={ (v: Option): void => onChange(v?.value) }
                          isSearchable={ false }
                        />
                      </div>
                    ) }
                  />
                )
              }
              <Controller
                name='specifiedDateOfLastDayOfMonth'
                control={ control }
                render={ ({ field: { onChange, value } }): React.ReactElement => (
                  <SmartCheckBox onChange={ onChange } checked={ value || false } label='月末日' />
                ) }
              />
            </div>
          </>
        }
      </FormGroupView>
    </>
  );
};
