import { displayMessage } from "actions/ActionCreators";
import i18next from "i18n";
import _get from "lodash/get";
import Api from "utilities/api";
import * as formActions from "../../request_types/actions/formActions";

const prefix = `REQUEST_TYPE`;

export function fetchRequestType(requestTypeId) {
  return async (dispatch) => {
    try {
      const requestType = await Api.requestTypes.show({ id: requestTypeId });
      const formItems = requestType.formItems.map((item) => ({
        ...item,
        value: item.defaultValue || [],
      }));
      dispatch(formActions.setForm(formItems));
      dispatch(setRequestType(requestType));
    } catch (e) {
      dispatch(
        displayMessage(
          "error",
          _get(e, "responseJSON.message") ||
            i18next.t("commons.errors.communicationError"),
        ),
      );
    }
  };
}

export const SET_REQUEST_TYPE = `${prefix}/SET_REQUEST_TYPE`;
export function setRequestType(requestType) {
  return {
    type: SET_REQUEST_TYPE,
    requestType,
  };
}
