import { combineReducers } from "redux";
import form from "../../request_types/reducers/form";
import application from "./application";
import applicationRequest from "./applicationRequest";
import requestType from "./requestType";

export default combineReducers({
  form,
  application,
  applicationRequest,
  requestType,
  forApprover: (state = false) => state,
  requestTypeId: (state = null) => state,
});
