import PropTypes from 'prop-types';
import React from 'react';
import isNil from 'lodash/isNil';

const BootstrapCard = (props) => {
  const {
    style, title, titleIcon, buttons, hasNoMargin, headerElement, children, footer, onClickHeader,
  } = props;
  const headerButtons = isNil(buttons) ? null : buttons.map(({
    color, disabled, icons, content, onClick, outlineDisabled,
  }, idx) => {
    const faLeftIcons = isNil(icons) ? null
      : icons.filter(({ position }) => (position === 'left'))
        .map(({ className, style: iconStyle = 'solid' }, i) => (
          <i key={ `icon-${i}` } className={ `${iconStyle === 'regular' ? 'far' : 'fas'} fa-left ${className}` } />
        ));
    const faRightIcons = isNil(icons) ? null
      : icons.filter(({ position }) => (position === 'right'))
        .map(({ className, style: iconStyle = 'solid' }, i) => (
          <i key={ `icon-${i}` } className={ `${iconStyle === 'regular' ? 'far' : 'fas'} fa-right ${className}` } />
        ));
    return (
      <button key={ `header-btn-${idx}` }
        type='button'
        className={ `btn btn-sm ${outlineDisabled ? '' : 'btn-outline'} btn-${color} ${disabled ? 'disabled' : ''}` }
        onClick={ onClick }
      >
        { faLeftIcons }
        { content }
        { faRightIcons }
      </button>
    );
  });

  return (
    <div className={ `card ${props.className || ''}` } style={ style }>
      <div className='card-header' onClick={ onClickHeader }>
        <div className='card-title inline'>
          { isNil(titleIcon) ? null : <i className={ `${titleIcon.style === 'regular' ? 'far' : 'fas'} fa-fw ${titleIcon.className}` } style={ { marginRight: '5px' } } /> }
          { title }
        </div>
        <div className='card-right'>
          { headerButtons }
          { headerElement }
        </div>
      </div>
      <div className={ `card-content${hasNoMargin ? '-no-margin' : ''}` }>
        { children }
      </div>
      { footer ? (<div className='card-footer'>{ footer }</div>) : null }
    </div>
  );
};

BootstrapCard.propTypes = {
  className: PropTypes.string,
  style: PropTypes.object,
  title: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.object,
  ]).isRequired,
  buttons: PropTypes.arrayOf(PropTypes.shape({
    color: PropTypes.oneOf([
      'default',
      'primary',
      'success',
      'accent',
      'info',
      'warning',
      'danger',
      'caution',
    ]),
    disabled: PropTypes.bool,
    icons: PropTypes.arrayOf(PropTypes.shape({
      position: PropTypes.oneOf(['right', 'left']).isRequired,
      className: PropTypes.string.isRequired,
      style: PropTypes.oneOf(['solid', 'regular']),
    })),
    content: PropTypes.any.isRequired,
    onClick: PropTypes.func,
  })),
  headerElement: PropTypes.element,
  footer: PropTypes.element,
  onClickHeader: PropTypes.func,
};

export default BootstrapCard;
