import React, { useState } from 'react';
import i18next from 'i18next';
import { Panel } from 'react-bootstrap';

/**
 * 電子領収書を添付するように促すアラート
 * @returns React.FC
 */
const ElectronicReceiptAlert: React.FC = () => {
  const [expanded, setExpanded] = useState(false);

  return (
    <Panel bsStyle='danger' expanded={ expanded } onToggle={ (): void => setExpanded(!expanded) }>
      <Panel.Heading>
        <Panel.Title style={ { fontSize: '14px' } }>
          <Panel.Toggle style={ { textDecoration: 'none' } }>
            <div style={ { display: 'flex', justifyContent: 'space-between', alignItems: 'center' } }>
              <div><i className='fa fa-exclamation-circle' /> { i18next.t('transactions.properties.electronicReceiptAlert.title') }</div>
              <i className={ expanded ? 'fa fa-angle-up' : 'fa fa-angle-down' } />
            </div>
          </Panel.Toggle>
        </Panel.Title>
      </Panel.Heading>
      <Panel.Collapse>
        <Panel.Body>
          { i18next.t('transactions.properties.electronicReceiptAlert.description1') }<br/>
          { i18next.t('transactions.properties.electronicReceiptAlert.description2') }
        </Panel.Body>
      </Panel.Collapse>
    </Panel>
  );
};

export default ElectronicReceiptAlert;
