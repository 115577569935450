import i18next from "i18n";
import React from "react";
import REPORT_STATUS from "../../../values/report_status";
import * as Buttons from "./buttons";
import { RequestState } from "./buttons/Types";

interface Props {
  locale: string;
  preReport: {
    status: string;
    report: {
      originalStatus: string;
    };
  };
  requestState: RequestState;
  onApplyButtonClick: () => void;
  onReapplyButtonClick: () => void;
  onChangeFlowButtonClick: () => void;
  onRecallButtonClick: () => void;
}

/** 承認後の事前申請（精算申請あり）の申請者用ボタン */
const ButtonsForRequesterAfterApprovedWithReport: React.FC<Props> = ({
  locale,
  preReport,
  requestState,
  onApplyButtonClick,
  onReapplyButtonClick,
  onChangeFlowButtonClick,
  onRecallButtonClick,
}) => {
  switch (preReport.report.originalStatus) {
    case REPORT_STATUS[locale].created:
      return (
        <Buttons.ApplyButton
          requestState={requestState}
          onClick={onApplyButtonClick}
        />
      );
    case REPORT_STATUS[locale].recalled:
    case REPORT_STATUS[locale].rejected:
      return (
        <Buttons.ReapplyButton
          requestState={requestState}
          onClick={onReapplyButtonClick}
        />
      );
    case REPORT_STATUS[locale].aborted:
      return (
        <Buttons.ChangeFlowButton
          requestState={requestState}
          onClick={onChangeFlowButtonClick}
        />
      );
    case REPORT_STATUS[locale].applying:
      return (
        <Buttons.RecallButton
          requestState={requestState}
          onClick={onRecallButtonClick}
        />
      );
    default: {
      if (
        preReport.status === REPORT_STATUS[locale].pendingTemporaryPaymentRefund
      ) {
        return (
          <div className="col-md-12">
            <span className="btn-block txt-danger">
              {i18next.t("commons.messages.refundTemporaryPayment")}
            </span>
          </div>
        );
      }
      return null;
    }
  }
};

export default ButtonsForRequesterAfterApprovedWithReport;
