import { RejectModal } from "components/RejectModal";
import RequestModal from "components/RequestModal";
import _pick from "lodash/pick";
import PropTypes from "prop-types";
import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import TimelineCard from "../../reports/timeline_card";
import * as _formActions from "../../request_types/actions/formActions";
import * as _applicationActions from "../actions/applicationActions";
import * as _applicationRequestActions from "../actions/applicationRequestActions";
import * as _applicationRequestAsyncActions from "../actions/ApplicationRequestAsyncActions";
import * as _requestTypeActions from "../actions/requestTypeActions";
import { ApplicationFormCard } from "../components/ApplicationFormCard";
import { CancelApprovalModal } from "../components/CancelApprovalModel";

export class ApplicationRequest extends Component {
  constructor(props) {
    super(props);

    this.rejectReport = this.rejectReport.bind(this);
  }

  componentDidMount() {
    if (this.props.requestTypeId) {
      this.props.requestTypeActions.fetchRequestType(this.props.requestTypeId);
    } else {
      this.refresh();
    }
  }

  refresh() {
    this.props.applicationActions.reload();
  }

  renderTimeline(request, classNameOfComponentCompare) {
    return request ? (
      <TimelineCard
        requestId={request.id}
        reportStatus={request.status}
        requestEvents={request.requestEvents}
        remainingApprovals={request.remainingApproval}
        onRefresh={this.refresh.bind(this)}
        isSetHeight
        classNameOfComponentCompare={classNameOfComponentCompare}
      />
    ) : null;
  }

  rejectReport(_id, comment, step) {
    this.props.applicationRequestActions.reject(
      this.props.application.request.id,
      comment,
      step?.id,
    );
  }

  render() {
    const {
      application,
      applicationActions,
      applicationRequest,
      applicationRequestActions,
      applicationRequestAsyncActions,
      forApprover,
      form,
      formActions,
      requestType,
    } = this.props;

    const classNameApplicationFormCard = "application-form-card";

    return (
      <div>
        <div className="row">
          <div className="col-md-6">
            <ApplicationFormCard
              actions={applicationRequestActions}
              application={application}
              cardTitle={requestType.name}
              editable={!forApprover && (!application || application.editable)}
              forApprover={forApprover}
              formItems={form}
              onChange={formActions.setValue}
              onTitleChange={applicationActions.setTitle}
              sequence={application.sequence}
              title={application.title}
              titleEditable={!application.id}
              className={classNameApplicationFormCard}
            />
          </div>
          <div className="col-sm-6">
            {this.renderTimeline(
              application.request,
              classNameApplicationFormCard,
            )}
          </div>
          <RequestModal
            approvalFlow={applicationRequest.approvalFlow}
            closeModal={applicationRequestActions.closeModal}
            comment={applicationRequest.comment}
            modalTitle="申請"
            onCommentChange={applicationRequestActions.setComment}
            onSubmit={applicationRequestActions.request}
            showModal={applicationRequest.showModal}
            title={applicationRequest.title}
          />
          {application.request && (
            <CancelApprovalModal
              application={application}
              applicationRequest={applicationRequest}
              applicationRequestActions={applicationRequestActions}
              applicationRequestAsyncActions={applicationRequestAsyncActions}
            />
          )}

          {
            /* 申請の差し戻しモーダル */
            application.canApprove && (
              <RejectModal
                id={application.remainingApprovals[0].id}
                showModal={applicationRequest.isRejectModalOpen}
                closeModal={applicationRequestActions.closeRejectModal}
                processing={applicationRequest.rejectingInProgress}
                rejectReport={this.rejectReport}
              />
            )
          }
        </div>
      </div>
    );
  }
}

function mapStateToProps(state, ownProps) {
  return _pick(state, [
    "form",
    "application",
    "applicationRequest",
    "requestType",
    "forApprover",
    "requestTypeId",
  ]);
}

function mapDispatchToProps(dispatch, ownProps) {
  return {
    applicationActions: bindActionCreators(_applicationActions, dispatch),
    applicationRequestActions: bindActionCreators(
      _applicationRequestActions,
      dispatch,
    ),
    applicationRequestAsyncActions: bindActionCreators(
      _applicationRequestAsyncActions,
      dispatch,
    ),
    requestTypeActions: bindActionCreators(_requestTypeActions, dispatch),
    formActions: bindActionCreators(_formActions, dispatch),
  };
}

ApplicationRequest.defaultProps = {
  forApprover: false,
};

ApplicationRequest.propTypes = {
  forApprover: PropTypes.bool.isRequired,
  requestTypeId: PropTypes.string,
};

export default connect(mapStateToProps, mapDispatchToProps)(ApplicationRequest);
