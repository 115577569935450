import TextField from "components/fields/TextField";
import SmartCheckBox from "components/SmartCheckBox";
import Decimal from "decimal.js";
import i18next from "i18n";
import isNil from "lodash/isNil";
import toString from "lodash/toString";
import PropTypes from "prop-types";
import React, { Component } from "react";
import { BootstrapTable, TableHeaderColumn } from "react-bootstrap-table";
import Formatter from "utilities/formatter";

/**
 * 税区分選択テーブル
 */
export default class TaxCategorySelectionsForm extends Component {
  /**
   * チェックボックスの欄
   */
  selectionFormatter(cell, row, enumObject, index) {
    return (
      <SmartCheckBox
        checked={cell}
        onChange={(checked) => {
          this.props.onChangeSelection(row.taxCategoryId, checked);
        }}
      />
    );
  }

  /**
   * 税率の欄
   */
  percentFormatter(cell, row, enumObject, index) {
    return <div>{`${new Decimal(cell).times(100)}`}%</div>;
  }

  /**
   * 有効期限の欄
   */
  termFormatter(cell, row, enumObject, index) {
    const startAt = toString(Formatter.date(row.startAt, { emptyText: "" }));
    const expireAt = toString(Formatter.date(row.expireAt, { emptyText: "" }));
    const hasTerm = startAt.length > 0 || expireAt.length > 0;
    const term = hasTerm ? `${startAt}〜${expireAt}` : "";
    return term;
  }

  /**
   * 並び順の欄
   */
  sortFormatter(cell, row, enumObject, index) {
    const defaultValue = row.selected && !isNil(cell) ? toString(cell) : "";
    const className = row.isValid ? "form-group" : "form-group has-error";

    return (
      <div className={className} style={{ marginBottom: 0 }}>
        <TextField
          className="form-control text-right"
          editable={row.selected}
          options={{ type: "number", min: 1 }}
          value={defaultValue}
          onValueChange={(value) => {
            this.props.onChangeSort(row.taxCategoryId, value);
          }}
        />
      </div>
    );
  }

  render() {
    const tableOption = {
      noDataText: i18next.t("commons.messages.noDataFound"),
    };
    return (
      <BootstrapTable
        data={this.props.taxCategorySelections}
        striped={true}
        options={tableOption}
      >
        <TableHeaderColumn
          dataField="taxCategoryId"
          hidden={true}
          isKey
        ></TableHeaderColumn>
        <TableHeaderColumn
          dataField="selected"
          dataFormat={this.selectionFormatter.bind(this)}
          width="40"
          dataAlign="center"
          tdStyle={{ verticalAlign: "middle" }}
        ></TableHeaderColumn>
        <TableHeaderColumn
          dataField="name"
          tdStyle={{ verticalAlign: "middle" }}
        >
          {i18next.t("preferences.taxCategorySelections.name")}
        </TableHeaderColumn>
        <TableHeaderColumn
          dataField="taxRate"
          dataFormat={this.percentFormatter}
          width="60"
          headerAlign="left"
          dataAlign="right"
          tdStyle={{ verticalAlign: "middle" }}
        >
          {i18next.t("preferences.taxCategorySelections.taxRate")}
        </TableHeaderColumn>
        <TableHeaderColumn
          dataFormat={this.termFormatter}
          tdStyle={{ verticalAlign: "middle" }}
        >
          {i18next.t("preferences.taxCategorySelections.validPeriod")}
        </TableHeaderColumn>
        {this.props.showSort ? (
          <TableHeaderColumn
            dataField="sort"
            dataFormat={this.sortFormatter.bind(this)}
            dataAlign="center"
            width="80"
          >
            {i18next.t("preferences.taxCategorySelections.sort")}
          </TableHeaderColumn>
        ) : null}
      </BootstrapTable>
    );
  }
}

TaxCategorySelectionsForm.defaultProps = {
  showSort: true,
  taxCategorySelections: [],
  onChangeSort: () => null,
  onChangeSelection: () => null,
};

TaxCategorySelectionsForm.propTypes = {
  showSort: PropTypes.bool,
  taxCategorySelections: PropTypes.arrayOf(
    PropTypes.shape({
      taxCategoryId: PropTypes.string,
      name: PropTypes.name,
      taxRate: PropTypes.number,
      startAt: PropTypes.string,
      expireAt: PropTypes.string,
      selected: PropTypes.bool,
      sort: PropTypes.number,
    }),
  ),
  onChangeSort: PropTypes.func,
  onChangeSelection: PropTypes.func,
};
