import React, { FC } from 'react';
import styled from 'styled-components';

export interface ContentsProps {
  /** @deprecated 代わりに children を使ってください */
  renderContent?: () => React.ReactElement;
}

export const Wrapper = styled.div`
  padding-right: 15px;
  padding-left: 15px;
`;

const Contents: FC<ContentsProps> = ({
  renderContent,
  children,
}) => {
  return (
    <Wrapper className='form-group'>
      { renderContent ? renderContent() : children }
    </Wrapper>
  );
};

export default Contents;
