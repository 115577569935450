import i18next from "i18n";
import React, { FC } from "react";
import { TableHeaderColumn } from "react-bootstrap-table";
import Formatter from "utilities/formatter";
import { ColumnProps } from "./types";
import { buildTableHeaderColumnOption } from "./utils";

const formatCategoryName = (cell: string): string | JSX.Element | null => {
  return Formatter.text(cell);
};

const renderCategoryNameColumn: FC<ColumnProps> = (props: ColumnProps) => {
  return (
    <TableHeaderColumn
      {...buildTableHeaderColumnOption(props)}
      dataField={"categoryName"}
      dataFormat={formatCategoryName}
    >
      {i18next.t("transactions.properties.category")}
    </TableHeaderColumn>
  );
};

export default renderCategoryNameColumn;
