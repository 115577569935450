import React from "react";
import styled from "styled-components";
import colors from "values/colors";
import fonts from "values/fonts";

interface Props {
  /** 表示ラベル */
  readonly label: string;
  /** 開くTabのindex番号 */
  readonly selectedIndex: number;
  /** 選択中のindex番号 */
  readonly value: number;
  /** 選択時のハンドラ */
  readonly onClick: (value: number) => void;
}

const TabView = styled.div<{ isSelected }>`
  transition: 150ms;
  cursor: ${({ isSelected }): string => (isSelected ? "auto" : "pointer")};
  margin-left: 4px;
  background: ${({ isSelected }): string =>
    isSelected ? "#fff" : colors.renewaled.whiteHover};
  color: ${({ isSelected }): string =>
    isSelected ? colors.renewaled.main : "inherit"};
  user-select: none;
  padding: 8px 12px;
  border-radius: 8px 8px 0 0;
  border-top: 1px solid
    ${({ isSelected }): string =>
      isSelected ? colors.renewaled.borderGrey : "transparent"};
  border-left: 1px solid
    ${({ isSelected }): string =>
      isSelected ? colors.renewaled.borderGrey : "transparent"};
  border-right: 1px solid
    ${({ isSelected }): string =>
      isSelected ? colors.renewaled.borderGrey : "transparent"};
  font-weight: ${fonts.weight.bold};
  position: relative;

  &::after {
    position: absolute;
    content: "";
    width: 100%;
    height: 1px;
    bottom: -1px;
    left: 0;
    background: ${({ isSelected }): string =>
      isSelected ? "#fff" : "transparent"};
  }
`;

/**
 * タブ: タイトル部分
 */
export const Tab: React.FC<Props> = ({
  selectedIndex,
  label,
  value,
  onClick,
}) => {
  return (
    <TabView
      className={`renewaled-tab ${
        value === selectedIndex && "renewaled-tab-selected"
      }`}
      onClick={(): void => onClick(selectedIndex)}
      isSelected={value === selectedIndex}
    >
      {label}
    </TabView>
  );
};
