import Api from 'utilities/api';
import PropTypes from 'prop-types';
import React from 'react';
import SelectContactsModal from 'components/SelectContactsModal/SelectContactsModal';
import _get from 'lodash/get';
import i18next from 'i18n';
import { fetchAsync, fetchAsyncAll } from 'utilities/async';
import { from } from 'rxjs';

/**
 * 通知先を探すフィールドを提供するコンポーネント
 */
class SelectContactsField extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      showSelectModal: false,
      memberSearch: {
        departmentSuggestions: [],
        userNameCondition: '',
        departmentCondition: null,
        members: [],
        membersCount: 0,
      },
    };

    // 従業員の検索に関する関数
    this.handleChangeSearchText = this.handleChangeSearchText.bind(this);
    this.handleSelectDepartment = this.handleSelectDepartment.bind(this);
    this.handleSearchMember = this.handleSearchMember.bind(this);

    // 従業員の選択に関する関数
    this.onSelect = this.onSelect.bind(this);
    this.selectAllMembers = this.selectAllMembers.bind(this);

    // モーダルに関する関数
    this.openSelectModal = this.openSelectModal.bind(this);
    this.closeSelectModal = this.closeSelectModal.bind(this);
  }

  componentDidMount() {
    this.initDepartmentSuggestionSubscription();
  }

  componentWillUnmount() {
    if (this.departmentSuggestionsSubscription) {
      this.departmentSuggestionsSubscription.unsubscribe();
    }
  }

  /**
   * @param {string} name 従業員名
   * @param {string} departmentId 部署id
   * @private
   */
  fetchAllMembers(name, departmentId) {
    return fetchAsyncAll(
      (params) => Api.members.index(params)
        .then((res) => { return { data: res.members, count: res.count }; }),
      {
        name,
        departmentId,
        isClient: false,
        includeBlockedUser: false,
        fields: ['department'],
      },
    );
  }

  // @todo このコンポーネントで取らないように修正すること
  initDepartmentSuggestionSubscription() {
    if (this.departmentSuggestionsSubscription) {
      // 実行されない
      this.departmentSuggestionsSubscription.unsubscribe();
    }

    this.departmentSuggestionsSubscription = from(this.fetchDepartmentSuggestions()).subscribe((data) => {
      this.setState({
        memberSearch: {
          ...this.state.memberSearch,
          departmentSuggestions: data,
        },
      });
    });
  }

  // @private
  fetchDepartmentSuggestions() {
    return fetchAsync(Api.departments.index, { excludeRoot: true }).then((data) => data.departments);
  }

  openSelectModal() {
    this.setState({
      showSelectModal: true,
    });
  }

  closeSelectModal() {
    this.setState({
      showSelectModal: false,
      memberSearch: {
        ...this.state.memberSearch,
        userNameCondition: '',
        departmentCondition: null,
      },
    });
  }

  handleChangeSearchText(text) {
    this.setState({
      memberSearch: {
        ...this.state.memberSearch,
        userNameCondition: text,
      },
    });
  }

  handleSelectDepartment(department) {
    this.setState({
      memberSearch: {
        ...this.state.memberSearch,
        departmentCondition: department,
      },
    });
  }

  buildSelectionChecker(selectedMembers) {
    const selectionMap = selectedMembers.reduce((acc, x) => {
      acc[x.id] = true;
      return acc;
    }, {});

    return (member) => (selectionMap[member.id] || false);
  }

  async handleSearchMember() {
    const name = this.state.memberSearch.userNameCondition;
    const departmentId = this.state.memberSearch.departmentCondition && this.state.memberSearch.departmentCondition.id;

    const result = await this.fetchAllMembers(name, departmentId);

    // 選択済みのアイテムと検索済みアイテムの整理
    const members = result.data;
    const checkSelection = this.buildSelectionChecker(this.selectedMembers);
    const mergedMembers = [...members, ...this.selectedMembers];
    const noDuplicateMembers = mergedMembers.filter((e, index, self) => {
      return self.findIndex((el) => el.id === e.id) === index;
    });
    const nextMembers = noDuplicateMembers.map((member) => { return { ...member, isSelected: checkSelection(member) }; });

    this.setState({
      memberSearch: {
        ...this.state.memberSearch,
        members: [...nextMembers],
        membersCount: result.count,
      },
    });
  }

  onSelect(_member, _nextState, selectedItems) {
    const checkSelection = this.buildSelectionChecker(selectedItems);
    const nextMembers = this.state.memberSearch.members.map((x) => {
      return { ...x, isSelected: checkSelection(x) };
    });

    this.props.onChange(selectedItems);
    this.setState({
      memberSearch: {
        ...this.state.memberSearch,
        members: nextMembers,
      },
    });
  }

  selectAllMembers(isSelected) {
    const nextMembers = this.state.memberSearch.members.map((x) => {
      return { ...x, isSelected };
    });

    this.props.onChange(nextMembers.filter((x) => x.isSelected));
    this.setState({
      memberSearch: {
        ...this.state.memberSearch,
        members: nextMembers,
      },
    });
  }

  get selectedMembers() {
    return this.state.memberSearch.members.filter((x) => x.isSelected);
  }

  get unselectedMembers() {
    return this.state.memberSearch.members.filter((x) => !x.isSelected);
  }

  get selectedOptionsSummary() {
    return this.selectedMembers.map((v) => v.email).join(', ');
  }

  // SelectContactsModal 用に整形しておく
  formatItem(member) {
    return {
      id: member.id,
      email: member.email,
      section: member.name,
      subSection: `email: ${member.email}, 所属部署: ${_get(member, 'departments[0].name', '-')}`,
      isSelected: member.isSelected,
    };
  }

  get sourceItems() {
    return this.state.memberSearch.members.map(this.formatItem);
  }

  get selectedItems() {
    return this.selectedMembers.map(this.formatItem);
  }

  get selectContactsModalProps() {
    return {
      show: this.state.showSelectModal,
      sourceItems: this.sourceItems,
      selectedItems: this.selectedItems,
      onHide: this.closeSelectModal,
      sendNotify: this.closeSelectModal, /* 通知はここから送らずモーダルを閉じる */
      searchText: this.state.memberSearch.userNameCondition,
      selectedDepartment: this.state.memberSearch.departmentCondition,
      departments: this.state.memberSearch.departmentSuggestions,
      sourceSize: this.state.memberSearch.membersCount,
      onSearch: this.handleSearchMember,
      onChangeSearchText: this.handleChangeSearchText,
      onSelectDepartment: this.handleSelectDepartment,
      onSelect: this.onSelect,
      onSelectAll: this.selectAllMembers,
      fetchSourceItems: () => { /* onSearchMember の中で全件取るため不要だが, 警告を回避するため空関数を定義 */ },
    };
  }

  render() {
    return (
      <>
        <input
          type='text'
          className='form-control'
          onClick={ this.openSelectModal }
          onChange={ () => { /* 文字列変更は許容しない */ } }
          value={ this.selectedOptionsSummary }
          placeholder={ i18next.t('exports.messages.emailFormat') } />
        <SelectContactsModal { ...this.selectContactsModalProps } />
      </>
    );
  }
}

SelectContactsField.defaultProps = {
  onChange: () => {},
};

SelectContactsField.propTypes = {
  onChange: PropTypes.func,
};


export default SelectContactsField;
