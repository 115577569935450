import * as ActionTypes from "../actions/expenseSelector";

const initialState = {
  matchedExpense: {},
  search: {
    results: [],
    error: {},
    status: "",
    conditions: {
      transactedAtFrom: "",
      transactedAtTo: "",
      amountFrom: "",
      amountTo: "",
      shopName: "",
      ownerName: "",
      department: null,
      includeChildDepartment: false,
      sort: [],
    },
  },
  createExpense: {
    entryForm: {
      items: [],
      error: {},
      status: "",
    },
  },
  expenseTable: {
    currentPage: 1,
    sizePerPage: 10,
  },
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case ActionTypes.FETCH_GETS_EXPENSE_REQUEST: {
      return {
        ...state,
        search: {
          ...state.search,
          status: "fetching",
        },
      };
    }
    case ActionTypes.FETCH_GETS_EXPENSE_SUCCESS: {
      // データサイズが異なる場合, リソースとなる配列をクリアする
      const collection =
        state.search.results.length !== action.payload.data.count
          ? [...new Array(action.payload.data.count)]
          : [...state.search.results];

      collection.splice(
        action.payload.data.offset,
        action.payload.data.transactions.length,
        ...action.payload.data.transactions,
      );

      return {
        ...state,
        search: {
          ...state.search,
          results: collection,
          error: {},
          status: "success",
        },
      };
    }
    case ActionTypes.FETCH_GETS_EXPENSE_FAILURE: {
      return {
        ...state,
        search: {
          ...state.search,
          results: [],
          error: action.payload.error,
          status: "error",
        },
      };
    }
    case ActionTypes.FETCH_GETS_ENTRY_FORM_REQUEST: {
      return {
        ...state,
        createExpense: {
          ...state.createExpense,
          entryForm: {
            ...state.createExpense.entryForm,
            status: "fetching",
          },
        },
      };
    }
    case ActionTypes.FETCH_GETS_ENTRY_FORM_SUCCESS: {
      return {
        ...state,
        createExpense: {
          ...state.createExpense,
          entryForm: {
            items: action.payload.data,
            error: {},
            status: "success",
          },
        },
      };
    }
    case ActionTypes.FETCH_GETS_ENTRY_FORM_FAILURE: {
      return {
        ...state,
        createExpense: {
          ...state.createExpense,
          entryForm: {
            items: [],
            error: action.payload.error,
            status: "error",
          },
        },
      };
    }
    case ActionTypes.SET_MATCHED_EXPENSE: {
      return {
        ...state,
        matchedExpense: action.payload.expense,
      };
    }
    case ActionTypes.SET_SEARCH_CONDITION: {
      return {
        ...state,
        search: {
          ...state.search,
          conditions: {
            ...state.search.conditions,
            [action.payload.key]: action.payload.data,
          },
        },
      };
    }
    case ActionTypes.SET_INITIAL_SEARCH_CONDITION: {
      return {
        ...state,
        search: {
          ...state.search,
          conditions: {
            ...state.search.conditions,
            transactedAtFrom: action.payload.transactedAt,
            transactedAtTo: action.payload.transactedAt,
            amountFrom: action.payload.amount,
            amountTo: action.payload.amount,
          },
        },
      };
    }
    case ActionTypes.CHANGE_CURRENT_PAGE: {
      return {
        ...state,
        expenseTable: {
          ...state.expenseTable,
          currentPage: action.payload.page,
        },
      };
    }
    case ActionTypes.SET_EXPENSE_IMAGE_URL: {
      return {
        ...state,
        search: {
          ...state.search,
          results: state.search.results.map((item, index) => {
            if (!item || item.id !== action.payload.expenseId) {
              return item;
            }
            return { ...item, imageS3Url: action.payload.imageUrl };
          }),
        },
      };
    }
    case ActionTypes.CLEAR_SEARCH_CONDITIONS: {
      return {
        ...state,
        search: {
          ...initialState.search,
        },
      };
    }
    case ActionTypes.CLEAR_EXPENSES: {
      return {
        ...state,
        search: {
          ...state.search,
          results: [],
        },
      };
    }
    default:
      return state;
  }
};

export default reducer;
