import get from "lodash/get";
import isNil from "lodash/isNil";
import PropTypes from "prop-types";
import React from "react";
import ExpenseTable from "./ExpenseTable";
import SearchAccordionForm from "./forms/SearchAccordionForm";

/**
 * 経費を検索し、任意の経費を選択するために検索フォームとテーブルを提供する
 */
class ExpenseSelector extends React.Component {
  constructor(props) {
    super(props);

    this.handleClickSearchButton = this.handleClickSearchButton.bind(this);
    this.handleFetchExpenses = this.handleFetchExpenses.bind(this);
    this.fetchExpenses = this.fetchExpenses.bind(this);
    this.onExpenseTableRowClick = (value) => {
      this.props.onSelectRow(value);
    };
    this.handleSortChange = this.handleSortChange.bind(this);
  }

  fetchExpenses(
    sort = this.props.sort,
    offset = (this.props.currentPage - 1) * this.props.sizePerPage,
    limit = this.props.sizePerPage,
    transactedAtFrom = this.props.transactedAtFrom,
    transactedAtTo = this.props.transactedAtTo,
    amountFrom = this.props.amountFrom,
    amountTo = this.props.amountTo,
    shopName = this.props.shopName,
    ownerName = this.props.ownerName,
    department = this.props.department,
    includeChildDepartment = this.props.includeChildDepartment,
  ) {
    return this.props.fetchExpenses({
      offset,
      limit,
      transactedAtFrom,
      transactedAtTo,
      shopName,
      amountFrom,
      amountTo,
      ownerName,
      department,
      includeChildDepartment,
      sort,
    });
  }

  handleClickSearchButton(_event) {
    this.props.clearExpenses();
    return this.fetchExpenses(this.props.sort);
  }

  handleFetchExpenses(offset, limit) {
    return this.fetchExpenses(this.props.sort, offset, limit);
  }

  handleSortChange(sortName, sortOrder) {
    this.props.clearExpenses();
    this.props.onSortChange(sortName, sortOrder);

    const sort = (this.props.sort || []).filter((x) => x.key !== sortName);
    sort.unshift({ key: sortName, order: sortOrder });

    return this.fetchExpenses(sort);
  }

  render() {
    return (
      <div>
        {/* 検索フィールド */}
        <SearchAccordionForm
          onClickSearchButton={this.handleClickSearchButton}
          setSearchConditions={this.props.setSearchConditions}
          transactedAtFrom={this.props.transactedAtFrom}
          transactedAtTo={this.props.transactedAtTo}
          amountFrom={this.props.amountFrom}
          amountTo={this.props.amountTo}
          shopName={this.props.shopName}
          ownerName={this.props.ownerName}
          department={this.props.department}
          includeChildDepartment={this.props.includeChildDepartment}
          isSearching={this.props.isSearching}
          forAdmin={this.props.forAdmin}
        />

        {/* 経費一覧 */}
        <ExpenseTable
          className="expense-matching-table"
          expenses={this.props.expenses}
          currentPage={this.props.currentPage}
          sizePerPage={this.props.sizePerPage}
          fetchExpense={this.handleFetchExpenses}
          onPageChange={this.props.onPageChange}
          isLoading={this.props.isSearching}
          selectRow={{
            mode: "radio",
            selected: isNil(get(this.props.selectedExpense, "id", null))
              ? []
              : [this.props.selectedExpense.id],
            onSelect: this.props.onSelectRow,
          }}
          onRowClick={this.onExpenseTableRowClick}
          setS3ImageUrl={this.props.setS3ImageUrl}
          forAdmin={this.props.forAdmin}
          onSortChange={this.handleSortChange}
        />
      </div>
    );
  }
}

ExpenseSelector.defaultProps = {
  fetchExpenses: () => Promise.resolve(),
  onSortChange: () => Promise.resolve(),
  isSearching: false,
  expenses: [],
  selectedExpense: {},
  currentPage: 1,
  sizePerPage: 10,
};

ExpenseSelector.propTypes = {
  // 共通で必要な props
  fetchExpenses: PropTypes.func.isRequired, // 経費を検索するAPI
  isSearching: PropTypes.bool, // 検索中かどうか
  forAdmin: PropTypes.bool, // 管理者向けであるか
  clearExpenses: PropTypes.func.isRequired,

  // 検索フォームが使用する props
  setSearchConditions: PropTypes.func.isRequired, // 検索条件の設定
  transactedAtFrom: PropTypes.string,
  transactedAtTo: PropTypes.string,
  amountFrom: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  amountTo: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  shopName: PropTypes.string,
  ownerName: PropTypes.string,
  department: PropTypes.object,
  includeChildDepartment: PropTypes.bool,
  sort: PropTypes.array,

  // テーブルが利用する props
  expenses: PropTypes.array, // 選択候補の経費
  selectedExpense: PropTypes.object, // 選択された経費
  currentPage: PropTypes.number, // 表示するページ
  sizePerPage: PropTypes.number, // 1ページあたりのサイズ
  onPageChange: PropTypes.func.isRequired, // 1ページあたりに表示する件数を変更
  onSelectRow: PropTypes.func.isRequired, // 行を選択した際に実行する関数
  setS3ImageUrl: PropTypes.func, // イメージIDからURLにコンバートされたものを保存する
  onSortChange: PropTypes.func, // ソート順変更
};

export default ExpenseSelector;
