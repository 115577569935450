import flash from 'utilities/flash';
import uniqueId from 'lodash/uniqueId';
import { RequestObject, getMessageFromResponse, snakecaseKeys } from 'utilities/Utils';
import { TDispatch } from 'tdispatch';
import { displayMessage, setFetchStatus } from './ActionCreators';
import { from, generate } from 'rxjs';
import { map, mergeMap } from 'rxjs/operators';

interface ErrorResponse extends XMLHttpRequest {
  responseJSON: {
    message: string;
  };
}

function handleError(e: ErrorResponse, message: string | null): void {
  flash.error(message || getMessageFromResponse(e, message));
}

/** 引数ありのAPIを実行する関数 */
export type ApiFuncWithRequest<Response, Request extends RequestObject> = (params: Request) => Promise<Response>;
/** 引数なしのAPIを実行する関数 */
export type ApiFuncWithoutRequest<Response> = () => Promise<Response>; // (params?: Request) => Promise<Response>にすると引数必須の関数を渡した場合にタイプエラーになるので関数定義を分けています
/** DK APIを実行する関数 */
export type ApiFunc<Response, Request extends RequestObject> = ApiFuncWithRequest<Response, Request> | ApiFuncWithoutRequest<Response>;

/** 非同期でDK APIを実行します。 */
export function fetchAsync<Response, Request extends RequestObject>(
  fetchApi: ApiFuncWithRequest<Response, Request>,
  params: Request,
  mute?: boolean,
): (dispatch: TDispatch) => Promise<Response>;

export function fetchAsync<Response>(
  fetchApi: ApiFuncWithoutRequest<Response>,
  mute?: boolean,
): (dispatch: TDispatch) => Promise<Response>;

export function fetchAsync<Response, Request extends RequestObject>(
  fetchApi: ApiFunc<Response, Request>,
  params?: Request,
  mute = false,
) {
  return async (dispatch: TDispatch): Promise<Response> => {
    const fetchId = uniqueId();

    try {
      dispatch(setFetchStatus(fetchId, 'start'));

      const callApi = (): Promise<Response> => {
        if (params) {
          const api = fetchApi as ApiFuncWithRequest<Response, Request>;
          return api(snakecaseKeys(params));
        }

        // 引数なしで実行する場合
        const api = fetchApi as ApiFuncWithoutRequest<Response>;
        return api();
      };

      const data = await callApi();
      dispatch(setFetchStatus(fetchId, 'done'));

      return data;
    } catch (e) {
      if (process.env.NODE_ENV !== 'production' && process.env.NODE_ENV !== 'test') {
        // 開発環境ではエラーを表示する
        console.error('fetchAsync: 通信エラー', e); // eslint-disable-line no-console
      }

      if (!mute) {
        dispatch(displayMessage('error', getMessageFromResponse(e)));
      }

      throw e;
    }
  };
}

export interface ApiResult<T> {
  count: number;
  data: T[];
}

interface FetchResult<T> {
  data: T[];
  offset: number;
}

/** 実行時オプション */
interface FetchAsyncAllOptions {
  /** APIの並列実行数 (429 Too Many Requests 回避のため) */
  concurrent: number;
  /** 1回のAPIリクエストで取得するデータ数 */
  limit: number;
  /**
   * 予め取得対象全体の要素数が分かっている場合に指定
   * 指定すると全体数を取得するための初回APIも並列実行に含まれるため, return が多少早くなる
   */
  maxFetchSize: number | null;
  /** エラー発生時に独自メッセーシを表示したい時に指定 */
  message: string | null;
}

/**
 * ページネーション対応のAPIに対して、全てのデータを取得する必要がある場合に実行する
 *
 * @note 使用する前に, 叩くAPIが適切かどうか, APIの修正も視野に入れて考えてください
 *       もし使用する場合, このメソッドはオプションによってはサーバに高負荷をかけてしまうので慎重に使用してください
 *
 * @param fetchApi 叩くAPI
 * @param params limit, offset を除くAPIへ渡すパラタメータ
 * @param options 実行時オプション
 * @return データのフェッチに関する promise を返す
 */
export async function fetchAsyncAll<T, Request extends RequestObject>(
  fetchApi: ApiFuncWithRequest<ApiResult<T>, Request>,
  params: Omit<Request, 'limit' | 'offset'>,
  options: Partial<FetchAsyncAllOptions>,
): Promise<ApiResult<T>> {
  const {
    concurrent = 5,
    limit = 50,
    maxFetchSize = null,
    message = null,
  } = options;
  let data: T[] = [];
  let count = 0;

  if (maxFetchSize) {
    count = maxFetchSize;
  } else {
    try {
      const response = await fetchApi(snakecaseKeys({ ...params, offset: 0, limit } as Request));
      data = response.data;
      count = response.count;
    } catch (e) {
      handleError(e, message);
      throw e;
    }
  }

  const results: T[][] = [data];
  const offset = limit;
  const apiResults: FetchResult<T>[] = [];

  const eventSource$ = generate(offset, (x) => x < count, (x) => x + limit)
    .pipe(
      map((x) => (snakecaseKeys({
        ...params,
        limit,
        noCount: true,
        offset: x,
      } as unknown as Request & { offset: number; limit: number }))),
      mergeMap(
        (apiParams) => from(fetchApi(apiParams)),
        (apiParams, response) => {
          return {
            data: response.data,
            offset: apiParams.offset, // offset順に取得結果を並び替えるために使用する
          };
        },
        concurrent,
      ),
    );

  const orderedResults = (): FetchResult<T>[] => {
    return apiResults.sort((a, b) => {
      if (a.offset < b.offset) {
        return -1;
      }
      if (a.offset > b.offset) {
        return 1;
      }
      return 0;
    });
  };

  const mergeApiResults = (): T[] => {
    orderedResults();

    apiResults.forEach((apiResult) => {
      results.push(apiResult.data);
    });

    return results.flat();
  };

  return new Promise((resolve, reject) => {
    eventSource$.subscribe(
      (result) => { apiResults.push(result); },
      (e) => { handleError(e, message); reject(e); },
      () => resolve({ count, data: mergeApiResults() }),
    );
  });
}
