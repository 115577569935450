import ExportJobModal from "applications/exports/components/ExportJobModal";
import i18next from "i18n";
import isFunction from "lodash/isFunction";
import PropTypes from "prop-types";
import qs from "qs";
import React from "react";
import { snakecaseKeys } from "utilities/Utils";

/**
 * ファイルエクスポートする際のオプションを表示するコンポーネント
 * デフォルトだと mail と file エクスポートを提供している.
 */
class ExportOptionButtons extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      showExportJobModal: false,
    };

    this.handleOpenExportJobModal = this.handleOpenExportJobModal.bind(this);
    this.handleCloseExportJobModal = this.handleCloseExportJobModal.bind(this);
  }

  handleOpenExportJobModal() {
    const { jobName, formatKey, type, targetKey, targetId, transactedAt } =
      this.props;

    // 集計の場合はモーダルを表示
    const types = [
      "analysis",
      "preAnalysis",
      "preTemporaryPaymentAnalysis",
      "approvingRequest",
    ];

    if (types.includes(type)) {
      this.setState({ showExportJobModal: true });
    } else {
      const param = qs.stringify(
        snakecaseKeys({
          formatKey,
          transactedAt,
          [targetKey]: targetId,
        }),
      );

      if (jobName === "journalEntriesForExpenses") {
        window.location.href = `/journal_entries_export/download_for_expenses?${param}`;
      } else {
        window.location.href = `/exports/csv?${param}`;
      }
    }
  }

  handleCloseExportJobModal() {
    this.setState({ showExportJobModal: false });
  }

  getFormName() {
    return this.props.type === "report" || this.props.type === "approvingRequest"
      ? "ReportExportForm"
      : "AccountingExportForm";
  }

  render() {
    return (
      <div className={this.props.className}>
        {["all", "mail"].includes(this.props.exportType) && (
          <button
            className="btn btn-success"
            onClick={this.props.onExportEmail}
          >
            <span className="fa fa-left fa-paper-plane" />
            {i18next.t("exports.inputs.sendEmail")}
          </button>
        )}
        {this.props.exportType === "all" && (
          <span style={{ marginLeft: "10px", marginRight: "10px" }}>
            {i18next.t("exports.inputs.or")}
          </span>
        )}
        {["all", "file"].includes(this.props.exportType) && (
          <>
            <button
              className="btn btn-outline btn-success"
              onClick={this.handleOpenExportJobModal}
            >
              {i18next.t("exports.inputs.download")}
            </button>
            <ExportJobModal
              title={i18next.t("exports.titles.fileExport")}
              show={this.state.showExportJobModal}
              closeModal={this.handleCloseExportJobModal}
              formName={this.getFormName()}
              formatKey={this.props.formatKey}
              jobName={this.props.jobName}
              transactedAt={this.props.transactedAt}
              targetId={this.props.targetId}
              targetKey={this.props.targetKey}
              onExportCsv={this.props.onExportCsv}
            />
          </>
        )}
      </div>
    );
  }
}

ExportOptionButtons.defaultProps = {
  exportType: "all",
  className: "",
};

ExportOptionButtons.propTypes = {
  targetId: PropTypes.string,
  targetKey: PropTypes.string,
  className: PropTypes.string,
  formatKey: PropTypes.string,
  jobName: PropTypes.oneOf([
    "accounting",
    "preAccounting",
    "journalEntriesForExpenses",
    "aggregationByUser",
    "aggregationByCategory",
    "analysisReportXls",
  ]),
  transactedAt: PropTypes.string,
  exportType: PropTypes.oneOf(["all", "mail", "file", null]),
  onExportEmail(props, propName, componentName) {
    if (props.exportType === "file") {
      return null;
    }

    if (
      !(
        ["all", "mail", null].includes(props.exportType) &&
        props.onExportEmail !== null
      )
    ) {
      return new TypeError(
        `Invalid prop '${propName}' supplied to ${componentName}: onExportEmail is required when 'exportType' is '${props.exportType}'`,
      );
    }

    if (!isFunction(props.onExportEmail)) {
      return new TypeError(
        `Invalid prop '${propName}' supplied to ${componentName}: onExportEmail is expected to be function type`,
      );
    }

    return null;
  },
};

export default ExportOptionButtons;
