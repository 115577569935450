import * as actions from '../actions/teikiRoutes';
import DeleteConfirmationModal from 'components/delete_confirmation_modal';
import Formatter from 'utilities/formatter';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import i18next from 'i18n';
import { BootstrapTable, TableHeaderColumn } from 'react-bootstrap-table';
import { connect } from 'react-redux';

export class TeikiRoutesTable extends Component {
  constructor(props) {
    super(props);

    this.onDelete = props.onDelete.bind(this);
    this.onCancelDelete = props.onCancelDelete.bind(this);
  }

  stationFormat(cell, row) {
    return (
      <div style={ { whiteSpace: 'pre-line', wordWrap: 'break-word' } }>
        { cell }
      </div>
    );
  }

  render() {
    const options = {
      noDataText: i18next.t('commons.messages.noDataFound'),
    };

    return (
      <div>
        <BootstrapTable data={ this.props.teikiRoutes } options={ options } striped={ true }>
          <TableHeaderColumn isKey={ true } hidden={ true } dataField='id'/>
          <TableHeaderColumn dataField='start' width='180' dataFormat={ this.stationFormat }>
            { i18next.t('transactions.properties.origin') }
          </TableHeaderColumn>
          <TableHeaderColumn dataField='goal' width='180' dataFormat={ this.stationFormat }>
            { i18next.t('transactions.properties.destination') }
          </TableHeaderColumn>
          <TableHeaderColumn width='90'
            dataFormat={ (column, route) => Formatter.date(route.startAt) }>
            { i18next.t('preferences.teiki.startAt') }
          </TableHeaderColumn>
          <TableHeaderColumn width='90'
            dataFormat={ (column, route) => Formatter.date(route.expireAt) }>
            { i18next.t('preferences.teiki.expireAt') }
          </TableHeaderColumn>
          <TableHeaderColumn width='100'
            dataFormat={ (column, route) => this.renderButtonGroup(route) }
            dataAlign='center'/>
        </BootstrapTable>
        <DeleteConfirmationModal
          show={ this.props.deleteConfirmationModal }
          onDelete={ this.onDelete }
          onCancel={ this.onCancelDelete } />
      </div>
    );
  }

  renderButtonGroup(route) {
    return (
      <div className='btn-group row'>
        <span style={ { paddingLeft: '10px', paddingRight: '5px' } } >
          <button className={ `btn btn-accent ${route.locked ? 'disabled' : ''}` }
            onClick={ this.props.onClickEditButton.bind(this, route) }>
            { i18next.t('commons.actions.update') }
          </button>
        </span>
        <span style={ { paddingLeft: '5px', paddingRight: '10px' } } >
          <button className={ `btn btn-outline btn-danger ${route.locked ? 'disabled' : ''}` }
            onClick={ this.props.onClickDeleteButton.bind(this, route) }>
            { i18next.t('commons.actions.delete') }
          </button>
        </span>
      </div>
    );
  }
}

function mapStateToProps(state, ownProps) {
  return {
    deleteConfirmationModal: state.teikiRoutesTable.deleteConfirmationModal,
    teikiRoutes: state.teikiRoutesTable.teikiRoutes,
  };
}

function mapDispatchToProps(dispatch, ownProps) {
  return {
    onClickEditButton(route) {
      dispatch(actions.onClickEditButton(route));
    },
    onClickDeleteButton(route) {
      dispatch(actions.onClickDeleteButton(route));
    },
    onDelete() {
      dispatch(actions.onDelete());
    },
    onCancelDelete() {
      dispatch(actions.closeDeleteConfirmationModal());
    },
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(TeikiRoutesTable);

TeikiRoutesTable.propTypes = {
  teikiRoutes:         PropTypes.array.isRequired,
  onClickEditButton:   PropTypes.func.isRequired,
  onClickDeleteButton: PropTypes.func.isRequired,
  onDelete:            PropTypes.func.isRequired,
  onCancelDelete:      PropTypes.func.isRequired,
};
