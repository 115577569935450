import SelectField from "components/fields/SelectField";
import React, { FC } from "react";
import useJrReservationSelector from "./hooks/JrReservationSelector";

interface Props {
  className?: string;
  discountType?: string;
  selectedItem?: string;
  onSelectItem: (item?: string) => void;
}

const JrReservationField: FC<Props> = ({ ...props }) => {
  const { className, discountType, selectedItem, onSelectItem } = props;

  const { options, selectedValue } = useJrReservationSelector(discountType);

  return (
    <SelectField
      value={selectedValue || null}
      className={className}
      options={options}
      onChange={onSelectItem}
      isClearable={true}
      isSearchable={false}
      defaultValue={selectedItem}
    />
  );
};

export default JrReservationField;
