import {
  CreateParams,
  ExpenseCreateResponse,
  ExpenseShowResponse,
  ExpenseUpdateResponse,
  IExpenseDriver,
  ShowParams,
  UpdateParams,
} from './IExpenseDriver';

export class ExpenseApi {
  constructor(private driver: IExpenseDriver) {
    this.driver = driver;
  }

  /** 複数経費を作成します */
  async createAll(apiParams: CreateParams): Promise<ExpenseCreateResponse> {
    const apiResponse = await this.driver.createAll(apiParams);
    return apiResponse;
  }

  /** 複数経費を更新します */
  async updateAll(apiParams: UpdateParams): Promise<ExpenseUpdateResponse> {
    const apiResponse = await this.driver.updateAll(apiParams);
    return apiResponse;
  }

  /** 経費を取得します */
  async show(apiParams: ShowParams): Promise<ExpenseShowResponse> {
    const apiResponse = await this.driver.show(apiParams);
    return apiResponse;
  }
}
