import uniqueId from "lodash/uniqueId";
import React, { FC, useEffect, useState } from "react";
import styled from "styled-components";

const ContainerView = styled.div`
  .checkbox {
    margin: 0px;
    input[type="checkbox"] {
      width: 15px;
      height: 15px;
    }
    label {
      min-height: 15px;
    }
  }

  // 複数のチェックボックスのある場合、間にあるボックスの左側のボーダーを消す
  :not(:first-child):not(:last-child) {
    border-left: 0;
  }
`;

interface Props {
  label: string;
  defaultChecked?: boolean;
  disabled?: boolean;
  onChange?: (checked: boolean) => void;
}

export const CheckBoxGroupAddon: FC<Props> = (props) => {
  const id = uniqueId("checkbox-");
  const [checked, setChecked] = useState(props.defaultChecked);

  useEffect(() => {
    setChecked(props.defaultChecked);
  }, [props.defaultChecked]);

  return (
    <ContainerView className="input-group-addon">
      <div className="checkbox">
        <label htmlFor={id}>
          <input
            type="checkbox"
            value="None"
            id={id}
            checked={checked}
            disabled={props.disabled}
            onChange={(e): void => {
              setChecked(e.target.checked);
              const handleOnChange = props.onChange || ((b): boolean => b);
              handleOnChange(e.target.checked);
            }}
          />
          {props.label}
        </label>
      </div>
    </ContainerView>
  );
};

CheckBoxGroupAddon.defaultProps = {
  defaultChecked: false,
  disabled: false,
};

export default CheckBoxGroupAddon;
