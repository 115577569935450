import i18next from "i18n";
import React from "react";
import { OverlayTrigger, Popover } from "react-bootstrap";
import { Expense } from "utilities/api/models";

type Props = {
  expense: Expense;
};

/** 外貨アイコン */
export const CurrencyIconWithOverlay: React.FC<Props> = ({ expense }) => {
  if (
    !expense.originalAmountCurrencyId ||
    expense.originalAmountCurrencyId === expense.baseCurrencyId
  ) {
    return null;
  }

  const companionsOverlay = (): JSX.Element => {
    return (
      <Popover id="currency-popup">
        {i18next.t("transactions.messages.foreignCurrency")}
      </Popover>
    );
  };

  return (
    <OverlayTrigger rootClose placement="left" overlay={companionsOverlay()}>
      <i className="material-icons">monetization_on</i>
    </OverlayTrigger>
  );
};
