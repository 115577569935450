import Conditions from './conditions';
import Result from './result';
import State from './state';
import { combineReducers } from 'redux';

const SearchReducer = combineReducers({
  Conditions,
  Result,
  State,
});
export default SearchReducer;
