import FormItem from './FormItem';
import React from 'react';
import formatter from 'utilities/formatter';

interface Props {
  value: string | null;
}

const OriginalFilename = (props: Props): JSX.Element => {
  return (
    <FormItem label='originalFilename'>
      { formatter.text(props.value, '-') }
    </FormItem>
  );
};

export default React.memo(OriginalFilename);
