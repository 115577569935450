import * as FetchActionTypes from '../../actions/search/fetch';

const initialState = {
  index: { inProcess: false },
  show:  { inProcess: false },
};

const searchState = (state = initialState, action) => {
  switch (action.type) {
    case FetchActionTypes.REQUEST_GET_INDEX: {
      return { ...state, index: { inProcess: true } };
    }
    case FetchActionTypes.FAILED_INDEX_REQUEST:
    case FetchActionTypes.SUCCESS_INDEX_REQUEST: {
      return { ...state, index: { inProcess: false } };
    }
    case FetchActionTypes.REQUEST_GET_SHOW: {
      return { ...state, show: { inProcess: true } };
    }
    case FetchActionTypes.FAILED_SHOW_REQUEST:
    case FetchActionTypes.SUCCESS_SHOW_REQUEST: {
      return { ...state, show: { inProcess: false } };
    }
    default: {
      return state;
    }
  }
};

export default searchState;
