import { CreatorsToActions } from "types/UtilityTypes";
import * as ActionCreators from "../actions/CancelTemporaryPayment/ActionCreators";
import { cancelTemporaryPaymentTypes as ActionTypes } from "../actions/CancelTemporaryPayment/ActionTypes";
import { initialState, State } from "../actions/CancelTemporaryPayment/Types";

type Actions = CreatorsToActions<typeof ActionCreators>;

export default function reducer(state = initialState, action: Actions): State {
  switch (action.type) {
    case ActionTypes.TOGGLE_MODAL: {
      return {
        ...state,
        show: action.payload.show,
      };
    }
    default:
      return state;
  }
}
