import DateInput from 'components/date_input';
import EditableBase from 'components/EditableBase';
import React from 'react';
import _isNil from 'lodash/isNil';
import formatter from 'utilities/formatter';

// 基本的には、EditableInputを経由して使用する
// EditableInputのinputTypeにdateを指定すると、
// このComponentが使用される
export default class EditableDate extends EditableBase {
  constructor(props) {
    super(props);

    this.handleBlur = this.handleBlur.bind(this);
  }

  // カレンダーを閉じると date_input の handleBlur が2回連続で流れるため、
  // 経費一覧の編集モードで 経費更新APIが2回実行される。
  // また2回目の e は undefined となり、不要である。
  // date_input 側の修正は影響範囲が大きいため、
  // EditableBase の handleBlur を オーバーライドし null チェックを追加した。
  handleBlur(e) {
    if (!_isNil(e)) {
      super.handleBlur(e);
    }
  }

  renderInEditMode() {
    let inputClass = this.props.input.className;
    if (this.props.sync) {
      inputClass += ' input-sync';
    } else if (this.props.sync === false) {
      inputClass += ' input-in-edit';
    }

    const { updateOnBlur } = this.props;
    const saveButton = (
      <span className='input-group-btn'>
        <button type='button'
          id={ this.buttonId }
          className={ this.props.button.className }
          onMouseDown={ this.onUpdate }
        >
          { this.props.button.text }
        </button>
      </span>
    );
    return (
      <form className='edit-date-form'>
        <div className={ `${updateOnBlur ? '' : 'input-group '}col-sm-12` }>
          <DateInput
            placeholder={ this.props.input.placeholder }
            inputClassName={ inputClass }
            value={ this.props.editingText }
            onChange={ this.props.onChange }
            onBlur={ this.handleBlur }
            onKeyPress={ this.handleKeyPress }
          />
          { updateOnBlur ? null : saveButton }
        </div>
      </form>
    );
  }

  renderInDisplayMode() {
    return (
      <span className={ this.props.className }>
        { formatter.date(this.props.text) }
        <i
          aria-hidden='true'
          className={ this.props.icon.className }
          style={ this.props.icon.style }
          onClick={ this.onClickIcon }
        />
      </span>
    );
  }
}
