import { changeUrl, displayMessage } from "actions/ActionCreators";
import { fetchAsync } from "actions/AsyncAction";
import get from "lodash/get";
import isNil from "lodash/isNil";
import last from "lodash/last";
import Api from "utilities/api";
import { getMessageFromResponse, snakecaseKeys } from "utilities/Utils";
import * as preReportActions from "./preReportActions";

const prefix = `pre_report_request`;

export function createAndFetchRequest(preReport) {
  return async (dispatch, getState) => {
    dispatch(startPreReportRequest("create"));

    if (!validateEmptyPreTransaction(preReport)) {
      dispatch(displayMessage("error", "事前申請項目がありません"));
      dispatch(closePreReportRequestJob());
      return;
    }
    const {
      formState: { fields },
    } = getState();
    try {
      await validatePreReport(preReport, fields);
    } catch (error) {
      dispatch(displayMessage("error", error.message));
      dispatch(closePreReportRequestJob());
      return;
    }

    try {
      const params = {
        ...preReport,
        type: preReport.type ? "PreTravelReport" : "PreReport", // booleanが入るようになっている一方、サーバーサイドが文字列を要求しているので、変換
        formFields: collectFormFields(fields, preReport.formValues),
      };
      const data = await Api.preReports.create(snakecaseKeys(params));
      dispatch(setShouldSave(false));
      dispatch(preReportActions.setProperty("id", data.id));
      dispatch(preReportActions.setProperty("title", data.title));
      // 申請モーダルで金額は使用するので、セットしておく
      // setPreReportすると、必要なプロパティが消えてしまうので、個別にセット
      dispatch(preReportActions.setProperty("amount", data.amount));
      dispatch(preReportActions.setProperty("preAmount", data.preAmount));
      dispatch(changeUrl(`/pre_reports/${data.id}`));
      await createFetchPromise(dispatch, data.id);
    } catch (error) {
      dispatch(displayMessage("error", getMessageFromResponse(error)));
    } finally {
      dispatch(closePreReportRequestJob());
    }
  };
}

export function updateAndFetchRequest(preReport) {
  return async (dispatch) => {
    dispatch(startPreReportRequest("update"));
    try {
      await dispatch(update(preReport));
      await createFetchPromise(dispatch, preReport.id);
    } catch (error) {
      dispatch(
        displayMessage("error", getMessageFromResponse(error, error.message)),
      );
    } finally {
      dispatch(closePreReportRequestJob());
    }
  };
}

export function updateAndReapply(preReport) {
  return async (dispatch) => {
    dispatch(startPreReportRequest("request"));
    try {
      if (preReport.editable) {
        await dispatch(update(preReport));
      }
      await dispatch(reapply(preReport));
    } catch (error) {
      dispatch(displayMessage("error", getMessageFromResponse(error)));
    } finally {
      dispatch(closePreReportRequestJob());
    }
  };
}

export function update(preReport, forApprover = false, onSuccess = () => null) {
  return async (dispatch, getState) => {
    const {
      formState: { fields },
    } = getState();

    if (!validateEmptyPreTransaction(preReport)) {
      const message = "事前申請項目がありません";
      dispatch(displayMessage("error", message));
      dispatch(closePreReportRequestJob());
      return Promise.reject(new Error(message));
    }

    try {
      await validatePreReport(preReport, fields);
    } catch (e) {
      return Promise.reject(e);
    }

    const params = {
      ...preReport,
      forApprover,
      formFields: collectFormFields(fields, preReport.formValues),
    };
    await Api.preReports.update(snakecaseKeys(params));
    const data = await dispatch(
      preReportActions.fetchPreReport(preReport.id, forApprover),
    );

    if (onSuccess) {
      dispatch(setShouldSave(false));
      onSuccess();
    }

    return data;
  };
}

export function approveRequest(preReport) {
  const id = last(preReport.requests).remainingApproval[0].id;
  return async (dispatch) => {
    dispatch(startPreReportRequest("approve"));

    try {
      const data = await Api.approvals.approve(
        snakecaseKeys({ id, async: true }),
      );
      await dispatch(preReportActions.fetchPreReport(preReport.id, true));

      dispatch(displayMessage("success", data.message));
    } catch (error) {
      dispatch(displayMessage("error", getMessageFromResponse(error)));
    } finally {
      dispatch(closePreReportRequestJob());
    }
  };
}

/**
 * 事前申請を差戻します。
 * @param {string} preReportId 事前申請ID
 * @param {string} approvalId 承認ID
 * @param {string} comment コメント
 * @param {string} stepId 差戻先のID（申請者の場合は null）
 */
export function rejectRequest(preReportId, approvalId, comment, stepId = null) {
  return async (dispatch) => {
    dispatch(startPreReportRequest("reject"));

    try {
      const data = await Api.approvals.reject(
        snakecaseKeys({
          id: approvalId,
          comment,
          stepId,
          async: true,
        }),
      );
      await dispatch(preReportActions.fetchPreReport(preReportId, true));

      dispatch(closeRejectModal());
      dispatch(displayMessage("success", data.message));
    } catch (error) {
      dispatch(displayMessage("error", getMessageFromResponse(error)));
    } finally {
      dispatch(closePreReportRequestJob());
    }
  };
}

export function recallRequest(preReport) {
  return async (dispatch) => {
    dispatch(startPreReportRequest("recall"));

    if (!validateEmptyPreTransaction(preReport)) {
      dispatch(displayMessage("error", "事前申請項目がありません"));
      dispatch(closePreReportRequestJob());
      return;
    }
    const requestId = last(preReport.requests).id;

    try {
      const data = await Api.requests.destroy(
        snakecaseKeys({ id: requestId, async: true }),
      );
      await dispatch(preReportActions.fetchPreReport(preReport.id));

      dispatch(displayMessage("success", data.message));
    } catch (error) {
      dispatch(displayMessage("error", getMessageFromResponse(error)));
    } finally {
      dispatch(closePreReportRequestJob());
    }
  };
}

export function recordTemporaryPayment(preReport, paymentDate, paymentComment) {
  return async (dispatch) => {
    try {
      dispatch(startPreReportRequest("recordTemporaryPayment"));
      dispatch(disablePaymentButton(true));
      const data = await dispatch(
        fetchAsync(Api.preReports.recordTemporaryPayment, {
          id: preReport.id,
          paymentDate,
          paymentComment,
        }),
      );
      await dispatch(preReportActions.fetchPreReport(preReport.id, true));

      dispatch(displayMessage("success", data.message));
      dispatch(showPaymentModal(false));
    } catch {
      dispatch(disablePaymentButton(false));
    } finally {
      dispatch(closePreReportRequestJob());
    }
  };
}

export function recordTemporaryPaymentRefund(preReport) {
  return async (dispatch) => {
    dispatch(startPreReportRequest("recordTemporaryPaymentRefund"));

    try {
      const data = await Api.preReports.recordTemporaryPaymentRefund({
        id: preReport.id,
      });
      await dispatch(preReportActions.fetchPreReport(preReport.id, true));

      dispatch(displayMessage("success", data.message));
    } catch (error) {
      dispatch(displayMessage("error", getMessageFromResponse(error)));
    } finally {
      dispatch(closePreReportRequestJob());
    }
  };
}

export const SHOW_PAYMENT_MODAL = `${prefix}/SHOW_PAYMENT_MODAL`;
export function showPaymentModal(shouldShow) {
  return {
    type: SHOW_PAYMENT_MODAL,
    payload: {
      shouldShow,
    },
  };
}

export const DISABLE_PAYMENT_BUTTON = `${prefix}/DISABLE_PAYMENT_BUTTON`;
export function disablePaymentButton(shouldDisable) {
  return {
    type: DISABLE_PAYMENT_BUTTON,
    payload: {
      shouldDisable,
    },
  };
}

export const SET_PAYMENT_COMMENT = `${prefix}/SET_PAYMENT_COMMENT`;
export function setPaymentComment(comment) {
  return {
    type: SET_PAYMENT_COMMENT,
    payload: {
      comment,
    },
  };
}

export const SET_PAYMENT_DATE = `${prefix}/SET_PAYMENT_DATE`;
export function setPaymentDate(date) {
  return {
    type: SET_PAYMENT_DATE,
    payload: {
      date,
    },
  };
}

export function fetchPreReportRequest(preReportId) {
  return function fetchPreReportRequestThunk(dispatch, getState) {
    return createFetchPromise(dispatch, preReportId);
  };
}

export function fetchRequest(preReportId, updateFlow = false) {
  return async (dispatch) => {
    dispatch(startPreReportRequest("settlement"));
    try {
      const data = await Api.reportRequests.split(
        snakecaseKeys({ preReportId }),
      );
      dispatch(setPreReportRequest(data.reports[0]));
      dispatch(showApprovalFlowsModal());
      dispatch(setUpdateFlow(updateFlow));
    } catch (error) {
      dispatch(displayMessage("error", getMessageFromResponse(error)));
    } finally {
      dispatch(closePreReportRequestJob());
    }
  };
}

function createFetchPromise(dispatch, preReportId) {
  return Promise.resolve(
    Api.preReportRequests.requests(snakecaseKeys({ preReportId })),
  )
    .then((data) => {
      dispatch(setPreReportRequest(data));
    })
    .then(() => {
      dispatch(showApprovalFlowsModal());
      dispatch(setUpdateFlow(false));
    })
    .catch((error) => {
      dispatch(displayMessage("error", getMessageFromResponse(error)));
    });
}

export const SET_PRE_REPORT_REQUEST = `${prefix}/SET_PRE_REPORT_REQUEST`;
export function setPreReportRequest(preReportRequest) {
  return {
    type: SET_PRE_REPORT_REQUEST,
    preReportRequest,
  };
}

// 事前申請および精算申請の再申請を行う
export function reapply(preReport) {
  return async (dispatch, getState) => {
    try {
      const reportId = get(preReport, "report.id");
      const preReportId = get(preReport, "id");
      dispatch(startPreReportRequest("request"));

      // NOTE: 精算申請と事前申請で叩く再申請APIが異なるので叩きかえる
      let data = null;
      if (reportId) {
        data = await Api.expenses.reports.request({
          id: reportId,
          async: true,
        });
      } else {
        data = await Api.preReportRequests.request(
          snakecaseKeys({ preReportId }),
        );
      }
      await dispatch(preReportActions.fetchPreReport(preReport.id));

      dispatch(closeApprovalFlowsModal());
      dispatch(displayMessage("success", data.message));
    } catch (error) {
      dispatch(displayMessage("error", getMessageFromResponse(error)));
    } finally {
      dispatch(closePreReportRequestJob());
    }
  };
}

export function request(
  preReportId,
  reportId,
  preReportRequest,
  approvals,
  onError,
) {
  return async (dispatch, getState) => {
    const { comment, approvalFlowId } = preReportRequest;
    const param = {
      reportId,
      reports: [
        {
          preReportId,
          comment,
          approvalFlowId,
          approvals,
        },
      ],
    };

    try {
      const data = await Api.reportRequests.request(snakecaseKeys(param));
      await dispatch(preReportActions.fetchPreReport(preReportId));

      dispatch(closeApprovalFlowsModal());
      dispatch(displayMessage("success", data.message));
    } catch (error) {
      onError(getMessageFromResponse(error));
    }
  };
}

export function requestPreReport(
  updateFlow,
  preReportId,
  comment,
  approvals,
  onError,
) {
  return async (dispatch) => {
    dispatch(startPreReportRequest("request"));

    try {
      const params = { comment, approvals };

      if (updateFlow) {
        params.originalId = preReportId;
      } else {
        params.preReportId = preReportId;
      }

      const data = await Api.preReportRequests.request(snakecaseKeys(params));
      await dispatch(preReportActions.fetchPreReport(preReportId));

      dispatch(closeApprovalFlowsModal());
      dispatch(displayMessage("success", data.message));
    } catch (error) {
      onError(getMessageFromResponse(error));
    } finally {
      dispatch(closePreReportRequestJob());
    }
  };
}

export function requestFlowChange(preReport) {
  return async (dispatch, getState) => {
    dispatch(startPreReportRequest("flowChange"));

    if (!validateEmptyPreTransaction(preReport)) {
      dispatch(displayMessage("error", "事前申請項目がありません"));
      dispatch(closePreReportRequestJob());
      return;
    }
    const {
      formState: { fields },
    } = getState();
    try {
      await validatePreReport(preReport, fields);
    } catch (e) {
      dispatch(displayMessage("error", e.message));
      dispatch(closePreReportRequestJob());
      return;
    }

    try {
      let data = null;
      if (preReport.report) {
        const requests = await Api.reportRequests.reports(
          snakecaseKeys({ preReportId: preReport.id }),
        );
        data = requests.reports[0];
      } else {
        const params = {
          ...preReport,
          forApprover: false,
          formFields: collectFormFields(fields, preReport.formValues),
        };
        await Api.preReports.update(snakecaseKeys(params));
        data = await Api.preReportRequests.requests(
          snakecaseKeys({ preReportId: preReport.id }),
        );
      }
      dispatch(setPreReportRequest(data));
      dispatch(showApprovalFlowsModal());
      dispatch(setUpdateFlow(true));
    } catch (e) {
      dispatch(displayMessage("error", getMessageFromResponse(e)));
    } finally {
      dispatch(closePreReportRequestJob());
    }
  };
}

export const SHOW_APPROVAL_FLOWS_MODAL = `${prefix}/SHOW_APPROVAL_FLOWS_MODAL`;
export function showApprovalFlowsModal() {
  return {
    type: SHOW_APPROVAL_FLOWS_MODAL,
  };
}

export const SET_UPDATE_FLOW = `${prefix}/SET_UPDATE_FLOW`;
export function setUpdateFlow(isUpdatingFlow) {
  return {
    type: SET_UPDATE_FLOW,
    payload: {
      isUpdatingFlow,
    },
  };
}

export const CLOSE_APPROVAL_FLOWS_MODAL = `${prefix}/CLOSE_APPROVAL_FLOWS_MODAL`;
export function closeApprovalFlowsModal() {
  return {
    type: CLOSE_APPROVAL_FLOWS_MODAL,
  };
}

export const TOGGLE_SHOW_REAPPLY_MODAL = `${prefix}/TOGGLE_SHOW_REAPPLY_MODAL`;
export function toggleShowReapplyModal() {
  return {
    type: TOGGLE_SHOW_REAPPLY_MODAL,
  };
}

export const SET_COMMENT = `${prefix}/SET_COMMENT`;
export function setComment(comment) {
  return {
    type: SET_COMMENT,
    comment,
  };
}

export const SHOW_REJECT_MODAL = `${prefix}/SHOW_REJECT_MODAL`;
export function showRejectModal() {
  return {
    type: SHOW_REJECT_MODAL,
  };
}

export const CLOSE_REJECT_MODAL = `${prefix}/CLOSE_REJECT_MODAL`;
export function closeRejectModal() {
  return {
    type: CLOSE_REJECT_MODAL,
  };
}

export const INPUT_REJECT_REASON = `${prefix}/INPUT_REJECT_REASON`;
export function inputRejectReason(value) {
  return { type: INPUT_REJECT_REASON, value };
}

export const SHOW_PRE_REPORT_REAPPLY_MODAL = `${prefix}/SHOW_PRE_REPORT_REAPPLY_MODAL`;
export function showPreReportReapplyModal() {
  return { type: SHOW_PRE_REPORT_REAPPLY_MODAL };
}

export const CLOSE_PRE_REPORT_REAPPLY_MODAL = `${prefix}/CLOSE_PRE_REPORT_REAPPLY_MODAL`;
export function closePreReportReapplyModal() {
  return { type: CLOSE_PRE_REPORT_REAPPLY_MODAL };
}

export const SET_SHOULD_SAVE = `${prefix}/SET_SHOULD_SAVE`;
export function setShouldSave(shouldSave) {
  return { type: SET_SHOULD_SAVE, shouldSave };
}

function collectFormFields(fields, formValues) {
  return snakecaseKeys(
    fields.reduce(
      (fieldArray, field) => buildFormField(field, formValues, fieldArray),
      [],
    ),
  );
}

function buildFormField(field, formValues, fieldArray) {
  // デフォルトフォームの値はtypeごとに1つだけ送信する
  const defaultFormTypes = fieldArray
    .filter((f) => isNil(f.id))
    .map((f) => f.type);
  if (isNil(field.id) && defaultFormTypes.includes(field.type)) {
    return fieldArray;
  }
  const key = field.id || field.type;

  const formValue = formValues[key];
  if (field.id) {
    return [...fieldArray, { id: field.id, type: field.type, formValue }];
  }

  switch (key) {
    case "pre_report_summary_input":
      return fieldArray;
    case "department_input":
      return [
        ...fieldArray,
        { id: null, type: key, formValue: isNil(formValue) ? {} : formValue },
      ];
    default:
      return [...fieldArray, { id: null, type: key, formValue }];
  }
}

// 本来はAPI側でバリデーションするべきだが、frontendで事前申請項目を削除しても
// DBに反映されていないので、ここでバリデーションする
function validateEmptyPreTransaction(preReport) {
  if (
    !userPreferences.preference.allowEmptyPreTransaction &&
    preReport.preTransactions.length === 0
  ) {
    return false;
  }
  return true;
}

export const START_PRE_REPORT_REQUEST_JOB = `${prefix}/START_PRE_REPORT_REQUEST_JOB`;
export function startPreReportRequest(jobType) {
  return { type: START_PRE_REPORT_REQUEST_JOB, jobType };
}
export const CLOSE_PRE_REPORT_REQUEST_JOB = `${prefix}/CLOSE_PRE_REPORT_REQUEST_JOB`;
export function closePreReportRequestJob() {
  return { type: CLOSE_PRE_REPORT_REQUEST_JOB };
}

// ここに書くべきかは謎
export function validatePreReport(preReport, formFields) {
  const validateEmpty = (reject, field, formValue) => {
    if (!isNil(field) && (isNil(formValue) || formValue.length === 0)) {
      // field(入力必須のkey)が存在しなければバリデーションしなくて良い。
      reject(new Error(`${field.label}を入力して下さい`));
    }
  };
  const validateDateOrder = (reject, day1, day2) => {
    if (!isNil(day1) && !isNil(day2) && day2 < day1) {
      reject(new Error(`開始日時が終了日時よりも後になっています`));
    }
  };
  const requiredFields = formFields
    .filter((f) => f.isRequired && f.show)
    .reduce((obj, f) => ({ ...obj, [f.id || f.type]: f }), {});

  return new Promise((resolve, reject) => {
    Object.keys(preReport.formValues).forEach((key) => {
      const field = requiredFields[key];
      const formValue = preReport.formValues[key];

      switch (key) {
        case "travel_destination_input":
        case "travel_start_at_input":
        case "travel_end_at_input": {
          if (preReport.formValues.travel_check_input) {
            validateEmpty(reject, field, formValue);
          }
          break;
        }
        case "temporary_payment_due_at_input":
        case "temporary_payment_reason_input": {
          if (preReport.formValues.temporary_payment_check_input) {
            validateEmpty(reject, field, formValue);
          }
          break;
        }
        case "pre_report_summary_input":
        case "temporary_payment_info_input":
          break;
        default: {
          validateEmpty(reject, field, formValue);
        }
      }
    });
    if (preReport.formValues.travel_check_input) {
      validateDateOrder(
        reject,
        preReport.formValues.travel_start_at_input,
        preReport.formValues.travel_end_at_input,
      );
    }

    resolve(preReport);
  });
}
