import { ReportViewData } from 'view_data/ReportViewData';
import { RequestApproval, RequestEvent } from 'utilities/api/models';
import { ShowResponse } from 'utilities/api/responses/reports';

export function toReportViewData(response: ShowResponse): ReportViewData {
  return {
    amount: response.amount,
    createdAt: getRequestedAt(response.requestEvents) || response.createdAt,
    editable: response.editable,
    id: response.id,
    progress: getProgress(response.finishedApproval, response.remainingApprovals),
    sequence: response.sequence,
    status: response.status,
    title: response.title,
    userId: response.userId,
    withholding: response.withholding,
  };
}

function getRequestedAt(events: RequestEvent[]): string | null {
  if (events.length === 0) {
    // パーソナルプランでは空になる
    return null;
  }

  const targetEvent = events.find((e) => e.type === 'applying');
  return targetEvent?.createdAt || null;
}

function getProgress(finishedApprovals: RequestApproval[], remainingApprovals: RequestApproval[]): number {
  if (!finishedApprovals || !remainingApprovals) {
    return 0;
  }

  const fin = finishedApprovals.length;
  const rem = remainingApprovals.length;

  switch (true) {
    case fin === 0: return 0;
    case rem === 0: return 100;
    default: return parseInt((fin / (fin + rem) * 100).toString(), 10);
  }
}
