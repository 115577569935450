import destination from './destination';
import station from './station';
import transaction from './transaction';

export const transactionType = transaction;
export const stationType = station;
export const destinationType = destination;

export default {
  transactionType,
  stationType,
  destinationType,
};
