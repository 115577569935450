export const exportPreferencInitialState = {
  /** @todo object型は使わないように */
  // eslint-disable-next-line @typescript-eslint/ban-types
  exportCategoryRules: {} as object,
  /** @todo object型は使わないように */
  // eslint-disable-next-line @typescript-eslint/ban-types
  exportCategoryColumns: {} as object,
  /** @todo object型は使わないように */
  // eslint-disable-next-line @typescript-eslint/ban-types
  exportTaxRules: {} as object,
  /** @todo object型は使わないように */
  // eslint-disable-next-line @typescript-eslint/ban-types
  exportTaxColumns: {} as object,
  formatKey: '' as string,
  aggregationColumns: {
    applicantName: false,
    reportCreatedAt: false,
    applicantEmail: false,
    shop: false,
    category: false,
    subCategory: false,
    displayCategory: false,
    projectName: false,
    projectId: false,
    comment: false,
    department: false,
    userDepartment: false,
  },
  accountingSummaryFormat: '' as string,
  usePaymentDate: userPreferences.preference.usePaymentDate,
  useTemporaryPaymentDate: userPreferences.preference.useTemporaryPaymentDate,
};

export type ExportPreferencState = typeof exportPreferencInitialState;

export const analysesPreferenceInitialState = {
  watchAccountingDataExport: userPreferences.preference.watchAccountingDataExport,
  paymentRequestsWatchAccountingDataExport: userPreferences.preference.paymentRequestsWatchAccountingDataExport,
};

export type AnalysesPreferenceState = typeof analysesPreferenceInitialState;

export type AlertPageFeature = 'expenseAlert' | 'invoiceReportAlert';
