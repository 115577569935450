import i18next from "i18n";
import PropTypes from "prop-types";
import React, { Component } from "react";
import { BootstrapTable, TableHeaderColumn } from "react-bootstrap-table";
import preTransactionType from "types/pre_transaction";
import Formatter from "utilities/formatter";

export default class PreTransactionTable extends Component {
  constructor(props) {
    super(props);

    this.handleClickConvertButton = this.handleClickConvertButton.bind(this);
    this.handleClickDeleteButton = this.handleClickDeleteButton.bind(this);
    this.formatButtonGroup = this.formatButtonGroup.bind(this);
  }

  handleClickConvertButton(row, formId, e) {
    e.stopPropagation();
    this.props.onConvertBtnClick(row, formId);
  }

  handleClickDeleteButton(row, e) {
    e.stopPropagation();
    this.props.onDeleteBtnClick(row.key);
  }

  // 経費追加の条件：
  //   事前申請が承認済みで、経費申請前、または申請後で編集可能な時
  // 事前申請項目の削除の条件：
  //   事前申請が編集可能な時
  formatButtonGroup(column, row) {
    return (
      <div className="col-sm-12">
        <button
          className="btn btn-primary col-sm-5"
          disabled={!this.props.transactionAddable}
          onClick={this.handleClickConvertButton.bind(null, row, "normal")}
        >
          {i18next.t("transactions.index.createTransaction")}
        </button>
        <button
          className="btn btn-outline btn-danger col-sm-offset-2 col-sm-5"
          disabled={!this.props.editable}
          onClick={this.handleClickDeleteButton.bind(null, row)}
        >
          {i18next.t("commons.actions.delete")}
        </button>
      </div>
    );
  }

  render() {
    const { editable, transactionAddable, preTransactions } = this.props;

    return (
      <BootstrapTable
        data={preTransactions}
        options={{ onRowClick: this.props.onRowClick }}
        tableBodyClass="multiline"
      >
        <TableHeaderColumn dataField="key" hidden={true} isKey={true} />
        <TableHeaderColumn
          dataField="transactedAt"
          dataFormat={(v) => Formatter.date(v, { withDayOfTheWeek: true })}
          editable={false}
          width="120"
        >
          {i18next.t("transactions.properties.date")}
        </TableHeaderColumn>
        <TableHeaderColumn
          dataField="comment"
          dataFormat={(v) => Formatter.text(v)}
          editable={true}
          width="150"
        >
          {i18next.t("transactions.properties.preTransactionContent")}
        </TableHeaderColumn>
        <TableHeaderColumn
          dataField="amount"
          width="120"
          dataFormat={(v) => Formatter.amount(v)}
        >
          {i18next.t("transactions.properties.amount")}
        </TableHeaderColumn>
        <TableHeaderColumn
          dataField="category"
          width="150"
          dataFormat={(v) => Formatter.text(v.name)}
        >
          {i18next.t("transactions.properties.category")}
        </TableHeaderColumn>
        <TableHeaderColumn
          dataField="companions"
          dataFormat={this.renderCompanions.bind(this)}
          dataAlign="center"
          width="150"
        >
          {i18next.t("transactions.properties.companion")}
        </TableHeaderColumn>
        <TableHeaderColumn
          dataAlign="center"
          width="280"
          hidden={!editable && !transactionAddable}
          dataFormat={this.formatButtonGroup}
        />
      </BootstrapTable>
    );
  }

  renderCompanions(companions) {
    const clientCompanions = companions.filter((it) => it.isClient);
    const houseCompanions = companions.filter((it) => !it.isClient);
    const houseCompanionNumber = houseCompanions
      .map((val) => val.number || 1)
      .reduce((acc, arg) => acc + arg, 0);
    const clientCompanionNumber = clientCompanions
      .map((val) => val.number || 1)
      .reduce((acc, arg) => acc + arg, 0);

    return (
      <div>
        {`自社: ${houseCompanionNumber}人  取引先 ${clientCompanionNumber}人`}
      </div>
    );
  }
}

PreTransactionTable.propTypes = {
  preTransactions: PropTypes.arrayOf(PropTypes.shape(preTransactionType)),
  hasApproved: PropTypes.bool.isRequired,
  editable: PropTypes.bool.isRequired,
  transactionAddable: PropTypes.bool.isRequired,
  onRowClick: PropTypes.func.isRequired,
  onDeleteBtnClick: PropTypes.func.isRequired,
  onConvertBtnClick: PropTypes.func.isRequired,
};
