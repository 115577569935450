import React, { useEffect, useRef, useState } from 'react';
import styled, { keyframes } from 'styled-components';
import { debounce } from 'lodash';

const ToolTipView = styled.div<{ block?: boolean }>`
  position: relative;
  display: ${({ block }): string => (block ? 'block' : 'inline-block')};
`;

const fadeIn = keyframes`
  0% {
    opacity: 0;
    transform: scale(0.9);
  }
  100% {
    opacity: 1;
    transform: scale(1);
  }
`;

const fadeInCenter = keyframes`
  0% {
    opacity: 0;
    transform: scale(0.9) translateX(50%);
  }
  100% {
    opacity: 1;
    transform: scale(1) translateX(50%);
  }
`;

const PopUpView = styled.div`
  z-index: 200;
  border-radius: 4px;
  font-size: 12px;
  white-space: pre;
  color: #FFFFFF;
  background: rgba(0, 1, 19, 0.7);
  backdrop-filter: saturate(180%) blur(10px);
  position: absolute;
  margin-top: 4px;
  .tool-tip-content {
    padding: 4px 8px;
  }
  &.center {
    right: 50%;
    transform: translateX(50%);
    transform-origin: 100% -100%;
    animation-name: ${fadeInCenter};
  }
  &.right {
    right: 0;
    transform: translateX(0%);
    transform-origin: 100% -100%;
    animation-name: ${fadeIn};
  }
  &.left {
    left: 0;
    transform: translateX(0%);
    transform-origin: 0 -100%;
    animation-name: ${fadeIn};
  }
  animation-duration: 100ms;
  animation-fill-mode: forwards;
`;

interface Props {
  overlay: JSX.Element | string | null;
  /**
   * ホバー時のポップアップを出す位置
   * canter: 中央合わせ, right: 右端合わせ, left: 左端合わせ
   * ※サイドバー付近に設置する場合は、折りたたんだ際に左に見切れる可能性が高いためleftを指定する。
   */
  align?: 'center' | 'right' | 'left';
  block?: boolean;
}

export const ToolTip: React.FC<Props> = ({
  overlay, align = 'center', children, block,
}) => {
  const [hovered, setHovered] = useState(false);
  const toolTipRef = useRef<HTMLDivElement>(null);

  // ホバー時の動作
  useEffect(() => {
    const handleMouseOver = (event): void => {
      if (toolTipRef.current?.contains(event.target)) setHovered(true);
    };

    // 200ms待ってから開く
    document.addEventListener('mouseover', debounce(handleMouseOver, 200));

    return (): void => document.removeEventListener('mouseover', handleMouseOver);
  }, []);

  // ホバー外した時の動作
  useEffect(() => {
    const handleMouseOut = (event): void => {
      if (!toolTipRef.current?.contains(event.target)) setHovered(false);
    };

    document.addEventListener('mouseover', handleMouseOut);

    return (): void => document.removeEventListener('mouseover', handleMouseOut);
  }, []);

  return (
    <ToolTipView ref={ toolTipRef } className='renewaled-tool-tip' block={ block }>
      { children }
      { hovered && (overlay !== null) && (
        <PopUpView className={ `tool-tip ${align}` }>
          <div className="tool-tip-content">
            { overlay }
          </div>
        </PopUpView>
      ) }
    </ToolTipView>
  );
};
