import React from 'react';
import styled from 'styled-components';

// Style
const FormSubmitButtonView = styled.button`
  width: 100%;
  height: 40px;
  border-radius: 10px;
  font-weight: bold;
  background-color: #5590e9;
  border: #548ee7;
  color: white;
  font-size: 1.4rem;
  transition: 0.3s;
  &:hover {
    background-color: #337ab7;
  }
`;

// type
interface Props {
  isSubmitting: boolean;
  onClick: (e: React.MouseEvent<HTMLButtonElement>) => void;
}

export const FormSubmitButton: React.FC<Props> = (props) => {
  return (
    <FormSubmitButtonView
      className='accept-button'
      type='submit'
      role='button'
      onClick={ props.onClick }
      disabled={ props.isSubmitting } >
      { props.isSubmitting
        ? <i className="fas fa-lg fa-spin fa-spinner" />
        : props.children
      }
    </FormSubmitButtonView>
  );
};
