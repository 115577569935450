import React, {
  FunctionComponent, useCallback, useEffect, useRef, useState,
} from 'react';
import { Waypoint } from 'react-waypoint';

interface ScrollerStatusProps {
  isLoading: boolean;
  isFailed: boolean;
  onReload: () => Promise<void>;
}

interface Props {
  className?: string,
  containerProps?: React.HtmlHTMLAttributes<HTMLDivElement>,
  isFinished?: boolean,
  showStatus?: boolean,
  style?: React.CSSProperties,
  onLoad: () => Promise<void>,
}

enum ActionStatus {
  stopped,
  loading,
  failed,
}

const ScrollerStatus: FunctionComponent<ScrollerStatusProps> = (props) => {
  const { isLoading, isFailed, onReload } = props;

  const baseClass = 'action-trigger__status';
  const className = isLoading ? `${baseClass}--loading` : `${baseClass}--failed`;

  return (
    <div className={ `${baseClass} ${className}` }>
      {
        isLoading && (
          <>
            <p className='txt'>Loading...</p>
            <span><i className='fa fa-fw fa-spinner fa-spin' /></span>
          </>
        )
      }

      {
        isFailed && (
          <span><i role='button' className='fas fa-fw fa-sync' onClick={ onReload } /></span>
        )
      }
    </div>
  );
};

const ActionTriggerByScroll: FunctionComponent<Props> = (props) => {
  const {
    children, className, containerProps = {}, isFinished = false, onLoad, showStatus = true, style = {},
  } = props;

  const isSubscribed = useRef(false);
  const [actionStatus, setActionStatus] = useState(ActionStatus.stopped);

  const handleAction = useCallback(async () => {
    try {
      setActionStatus(ActionStatus.loading);
      await onLoad();
      if (isSubscribed.current) {
        setActionStatus(ActionStatus.stopped);
      }
    } catch (e) {
      if (isSubscribed.current) {
        setActionStatus(ActionStatus.failed);
      }
    }
  }, [isSubscribed, onLoad, setActionStatus]);

  useEffect(() => {
    isSubscribed.current = true;
    return (): void => { isSubscribed.current = false; };
  }, []);

  const isScrollReady = actionStatus === ActionStatus.stopped && !isFinished;

  return (
    <div className={ className }
      style={ style }
      { ...containerProps }
    >
      { children }

      {
        !isFinished && (
          <div style={ { minHeight: '1px' } } role='application'>
            { isScrollReady && <Waypoint onEnter={ handleAction } /> }

            {
              showStatus && (
                <ScrollerStatus
                  isLoading={ actionStatus === ActionStatus.loading }
                  isFailed={ actionStatus === ActionStatus.failed }
                  onReload={ handleAction }
                />
              )
            }
          </div>
        )
      }
    </div>
  );
};

export default ActionTriggerByScroll;
