import React, { FC } from 'react';
import i18next from 'i18n';
import styled from 'styled-components';
import { Dropdown, MenuItem } from 'react-bootstrap';
import { Operator } from 'utilities/api/types/JournalEntries';

interface Props {
  options: Operator[];
  operator: Operator;
  conditionIndex: number;
  orIndex: number;
  onSelect: (operator: Operator) => void;
}

const StyledDropdown = styled(Dropdown)`
  flex: initial;
  align-self: stretch;
  max-width: 200px;
  height: auto;
  button {
    width: 100%;
    height: 100%;
  };
`;

const ConditionOperatorForm: FC<Props> = (props) => {
  const isSelected = (operator: Operator): boolean => { return operator === props.operator; };

  const operatorExpression = (operator: Operator): string => {
    return i18next.t(`journalEntries.labels.operators.${operator}`);
  };

  // Dropdown の制約で型を指定できない
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const onSelect = (operator: any): void => {
    props.onSelect(operator as Operator);
  };

  return (
    <StyledDropdown id={ `operator-${props.orIndex}-${props.conditionIndex}` }
      onSelect={ onSelect }
    >
      <StyledDropdown.Toggle>
        { operatorExpression(props.operator) }
      </StyledDropdown.Toggle>
      <StyledDropdown.Menu>
        {
          props.options.map((operator) => (
            <MenuItem
              key={ operator }
              eventKey={ operator }
              active={ isSelected(operator) }
            >
              { operatorExpression(operator) }
            </MenuItem>
          ))
        }
      </StyledDropdown.Menu>
    </StyledDropdown>
  );
};

export default ConditionOperatorForm;
