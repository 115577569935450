import React, { useMemo } from 'react';
import styled from 'styled-components';

type PxSize = number | string;

interface LoadingViewProps {
  margin?: string;
}

interface Props extends LoadingViewProps {
  height?: PxSize;
  minHeightPercentage?: number;
  maxHeightPercentage?: number;

  width?: PxSize;
  minWidthPercentage?: number;
  maxWidthPercentage?: number;

  children?: React.ReactNode;
}

function normalizeSize(size: PxSize | undefined): string {
  if (typeof size === 'number') {
    return `${size}px`;
  }

  return size || 'auto';
}

function getSize(size: PxSize | undefined, minPercentage = 10, maxPercentage = 100): string {
  if (typeof size !== 'undefined') {
    return normalizeSize(size);
  }

  return `calc(${Math.floor(Math.random() * (maxPercentage - minPercentage)) + minPercentage}% - 8px)`;
}

const LoadingView = styled.div<LoadingViewProps>`
  margin: ${(props): string => (props.margin ?? '0')};

  @keyframes loading {
    0% {
      background-position:100% 50%
    }

    100%{
      background-position:0 50%
    }
  }

  background: linear-gradient(90deg, #f2f2f2 25%, #e6e6e6 37%, #f2f2f2 63%);
  animation: loading 1.4s ease infinite;
  background-size: 400% 100%;
`;

const LazyElement: React.FunctionComponent<Props> = (props) => {
  const {
    height: h, minHeightPercentage: minH, maxHeightPercentage: maxH,
    width: w, minWidthPercentage: minW, maxWidthPercentage: maxW,
    margin, children,
  } = props;

  const height = useMemo(() => (getSize(h, minH, maxH)), [h, minH, maxH]);
  const width = useMemo(() => (getSize(w, minW, maxW)), [w, minW, maxW]);

  const style = { height, width };

  if (children) {
    return (<>{ children }</>);
  }

  return (<LoadingView style={ style } margin={ margin } />);
};

export default LazyElement;
