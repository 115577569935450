import * as ActionTypes from "containers/actions/customSuggest";
import autoSuggest from "containers/reducers/autoSuggest";
import get from "lodash/get";
import * as SuggestionActionTypes from "../actions/suggestions";

function updateTotal(state, arr) {
  return {
    total: [...arr],
    current: [...arr],
  };
}

function updateCurrent(state, arr) {
  return {
    ...state,
    current: [...arr],
  };
}

const initialState = {
  categories: { current: [], total: [] },
  currencies: { current: [], total: [] },
  groups: { current: [], total: [] },
  nestedCategories: [{ current: [], total: [] }],
  nestedCategoriesByParentId: {},
  project: [],
  preReports: [],
  reportTitles: { current: [], total: [] },
  superCategories: { current: [], total: [] },
  taxCategories: { current: [], total: [] },
  userDepartments: { current: [], total: [] },
};

const suggestions = (state = initialState, action) => {
  const key = get(action.field, "id", null);
  switch (action.type) {
    case SuggestionActionTypes.SET_PROJECTS:
      return {
        ...state,
        project: action.payload.projects,
      };
    /* ***
     * @note 以降のアクションについて、initialState による初期化後に全く呼ばれておらず値が更新されていないため、
     * 関連するストアの値についても全く参照されていない可能性がある.
     *** */
    case ActionTypes.SET_CUSTOM_INPUT_LIST:
      return {
        ...state,
        [key]: updateTotal(state[key], action.histories),
      };
    case ActionTypes.UPDATE_CUSTOM_INPUT_SUGGESTIONS:
      return {
        ...state,
        [key]: updateCurrent(state[key], action.suggestions),
      };
    case ActionTypes.RESET_CUSTOM_INPUT_SUGGESTIONS:
      return {
        ...state,
        [key]: updateCurrent(state[key], get(state[key], "total", [])),
      };
    default:
      return autoSuggest(state, action);
  }
};

export default suggestions;
