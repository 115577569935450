import InfiniteScroller from 'components/InfiniteScroller';
import InfiniteScrollerGetConsecutiveData from 'components/InfiniteScrollerGetConsecutiveData';
import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';
import isNil from 'lodash/isNil';
import { ListGroupItem } from 'react-bootstrap';

const ActionBox = (props) => {
  const {
    className, isMultiSelectable, isDeletable, isSelected, onDelete,
  } = props;

  let iconClass = null;

  if (isMultiSelectable) {
    iconClass = isSelected ? 'fa-check-square' : 'fa-square';
  } else {
    iconClass = isSelected ? 'fa-dot-circle' : 'fa-circle';
  }

  const onClick = (e) => { e.stopPropagation(); onDelete(e); };

  // TODO: SCSSでスタイル定義
  return (
    <div className={ className }>
      {
        isDeletable && (
          <i className='far fa-trash-alt'
            style={ { width: '30px' } }
            onClick={ onClick }
          />
        )
      }

      <i className={ `far fa-fw fa-lg ${iconClass}` }></i>
    </div>
  );
};

export default class InfiniteSelector extends PureComponent {
  constructor(props) {
    super(props);

    this.fetchOptions = this.fetchOptions.bind(this);
    this.handleOptionSelect = this.handleOptionSelect.bind(this);
    this.handleAllOptionSelect = this.handleAllOptionSelect.bind(this);
    this.handleOptionDelete = this.handleOptionDelete.bind(this);
    this.handleOptionsReset = this.handleOptionsReset.bind(this);
    this.renderOption = this.renderOption.bind(this);
  }

  get selection() {
    return this.normalizeSelection(this.props.selection, this.props.isMultiSelectable);
  }

  get callbacks() {
    return {
      onOptionsReset: this.handleOptionsReset,
      options: this.options,
      handleAllOptionSelect: this.handleAllOptionSelect,
    };
  }

  get options() {
    const selection = this.selection;
    // 選択済みの値であれば、trueを返す関数
    const checkSelection = (() => {
      const selectionMap = {};

      if (this.props.isMultiSelectable) {
        selection.forEach((value) => { selectionMap[value.id] = true; });
      } else if (!isNil(selection)) {
        selectionMap[selection.id] = true;
      }

      return (value) => { return !!selectionMap[value.id]; };
    })();

    return this.props.options.map((x) => ({ ...x, isSelected: checkSelection(x) }));
  }

  normalizeSelection(selection, isMultiSelectable) {
    return isMultiSelectable ? (selection || []) : selection;
  }

  buildSelection(value, isSelected) {
    if (this.props.isMultiSelectable) {
      if (isSelected) {
        return [...this.selection, value];
      }

      return this.selection.filter((x) => x.id !== value.id);
    }

    return isSelected ? value : null;
  }

  handleOptionSelect(item, e) {
    const isSelected = !item.isSelected;
    const nextSelection = this.buildSelection(item, isSelected);

    this.props.onSelect(item, isSelected, nextSelection);
  }

  handleAllOptionSelect(checked, options) {
    const targetOptions = options.filter((o) => o.isSelected === false);
    const displayedOptionIds = options.map((o) => o.id);

    this.props.onSelectAll(checked, targetOptions, displayedOptionIds);
  }

  handleOptionDelete(item, e) {
    this.props.onDelete(item);
  }

  async fetchOptions(offset, limit) {
    const response = await this.props.fetchOptions(offset, limit);
    const options = response[this.props.responseKey];

    const preOptions = Array.prototype.concat.call(
      this.props.options.slice(0, offset),
      new Array(Math.max(0, offset - this.props.options.length)), // preOptions.length === offsetとなるように
    );
    const postOptions = this.props.options.slice(offset + limit);
    const newOptions = Array.prototype.concat.call(preOptions, options, postOptions);

    return { options: newOptions, count: response.count };
  }

  handleOptionsReset() {
    this.props.onOptionsChange([], null);
  }

  buildStyle(option, style) {
    const disabled = option && option[this.props.itemDisabledAttributeName];
    if (disabled) {
      return {
        ...style,
        backgroundColor: 'lightgray',
      };
    }
    return style;
  }

  renderOption(option, { style }) {
    return (
      <ListGroupItem className='infinite-selector-item'
        style={ this.buildStyle(option, style) }
        onClick={ option ? this.handleOptionSelect.bind(null, option) : void 0 }
      >
        <div className='infinite-selector-item__label'>
          { this.props.formatOption(option) }
        </div>

        {
          option && (
            <ActionBox className='infinite-selector-item__btn-group'
              isMultiSelectable={ this.props.isMultiSelectable }
              isDeletable={ this.isOptionDeletable(option) }
              isSelected={ option.isSelected }
              onDelete={ this.handleOptionDelete }
            />
          )
        }
      </ListGroupItem>
    );
  }

  isOptionDeletable(item) {
    if (typeof this.props.isOptionDeletable !== 'function') {
      return false;
    }

    return this.props.isOptionDeletable(item);
  }

  render() {
    const options = this.options;

    return this.props.children(
      () => {
        if (this.props.totalCount === 0) {
          return null;
        }

        return (
          this.props.isGetConsecutiveData ? (
            <InfiniteScrollerGetConsecutiveData
              style={ this.props.style || {} }
              totalCount={ this.props.totalCount }
              height={ Math.min(this.props.maxHeight, this.props.rowHeight * options.length) }
              itemHeight={ this.props.rowHeight }
              items={ options }
              batchSize={ this.props.batchSize }
              fetchItems={ this.fetchOptions }
              onItemsChange={ this.props.onOptionsChange }
              renderItem={ this.renderOption }
            />
          ) : (
            <InfiniteScroller
              style={ this.props.style || {} }
              totalCount={ this.props.totalCount }
              height={ Math.min(this.props.maxHeight, this.props.rowHeight * options.length) }
              itemHeight={ this.props.rowHeight }
              items={ options }
              batchSize={ this.props.batchSize }
              fetchItems={ this.fetchOptions }
              onItemsChange={ this.props.onOptionsChange }
              renderItem={ this.renderOption }
            />
          )
        );
      },
      this.callbacks,
    );
  }
}

InfiniteSelector.defaultProps = {
  responseKey: 'data',
  options: [],
  isMultiSelectable: false,
  isGetConsecutiveData: false,
  formatOption(option) { return option; },
  fetchOptions(_offset, _limit) {
    window.console.warn('fetchOptions is not implemented: InfiniteSelector');
  },
  /**
   * @param {object} _value 選択状態が変更される値
   * @param {boolean} _isSelected trueの時、選択。falseの時は選択解除
   * @param {object|object[]} _selection 選択結果。props.selectionの更新後の値
   */
  onSelect(_value, _isSelected, _selection) {
    window.console.warn('onSelect is not implemented: InfiniteSelector');
  },
  /**
   * @param {*} _options 取得済みの選択肢のリスト
   * @param {*} _totalCount 全体の選択肢の数
   */
  onOptionsChange(_options, _totalCount) {
    window.console.warn('onOptionsChange is not implemented: InfiniteSelector');
  },
  maxHeight: 400,
  children: (renderSelector, _callbacks = {}) => {
    return renderSelector();
  },
};

InfiniteSelector.propTypes = {
  /**
   * データが入っているJSONのproperty
   * TODO: サーバー側でI/Fを統一して、propを削除する
   */
  responseKey: PropTypes.string.isRequired,
  isMultiSelectable: PropTypes.bool.isRequired,
  isGetConsecutiveData: PropTypes.bool, // @deprecated InfiniteSelectorと全選択は相性が悪いので、使わないでください。新しいコンポーネントを作ってください。
  isOptionDeletable: PropTypes.func,
  options: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.string.isRequired,
    isSelected: PropTypes.bool,
  })),
  selection: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.object),
    PropTypes.object,
  ]), // isMultiSelectable: trueの時はarray, その他の時はobject
  formatOption: PropTypes.func.isRequired,
  fetchOptions: PropTypes.func.isRequired,
  onDelete: PropTypes.func, // isOptionDeletable: trueの時は必須
  onSelect: PropTypes.func.isRequired,
  onSelectAll: PropTypes.func, // @deprecated InfiniteSelectorと全選択は相性が悪いので、使わないでください。新しいコンポーネントを作ってください。
  onOptionsChange: PropTypes.func.isRequired,
  totalCount: PropTypes.number,
  style: PropTypes.object,

  /** InfiniteScroller用のprops */
  maxHeight: PropTypes.number.isRequired,
  rowHeight: PropTypes.number.isRequired,
  batchSize: PropTypes.number,

  children: PropTypes.func.isRequired,
  itemDisabledAttributeName: PropTypes.string,
};
