import { ListForm } from "components/renewaled_ui/form_views";
import i18next from "i18next";
import React from "react";
import Formatter from "utilities/formatter";
import { TaxCategory } from "../interface";

interface Props {
  /** 選択された税区分 */
  readonly selectedTaxCategories: TaxCategory[];
}

/**
 * 税区分表示・選択モーダル欄(読み取り専用)
 */
export const ReadOnlyTaxCategorySelectionField: React.FC<Props> = ({
  selectedTaxCategories,
}) => {
  const taxCategoryNames = selectedTaxCategories
    .map((o) => {
      return `${o.name} ${Formatter.percent(o.taxRate)}`;
    })
    .join("、");

  return (
    <ListForm label={i18next.t("transactions.properties.taxCategory")}>
      <input
        type="text"
        readOnly
        className="form-control"
        value={taxCategoryNames}
      />
    </ListForm>
  );
};
