import React, { FC } from 'react';
import i18next from 'i18n';
import styled from 'styled-components';
import { ExportFormat } from 'utilities/api/models/JournalEntries';
import { OverlayTrigger, Popover } from 'react-bootstrap';

type Step = 1 | 2 | 3 | 4 | 5;

interface Props {
  currentStep: Step;
  exportFormat: ExportFormat;
  inEditing: boolean;
  onClick: (redirectUrl: string) => void;
}

export const NaviPanelView = styled.div`
  .step1,
  .step2,
  .step3,
  .step4,
  .step5 {
    margin: 4px;
  }

  .clickable:hover {
    font-weight: bold;
  }
`;

const NaviPanel: FC<Props> = (props) => {
  const exportFormatId = props.exportFormat.id;

  const redirectUrl = (step: Step): string => {
    const urls = {
      1: `/journal_entries/${exportFormatId}/preference/base`,
      2: `/journal_entries/${exportFormatId}/preference/export_target`,
      3: `/journal_entries/${exportFormatId}/preference/style`,
      4: `/journal_entries/${exportFormatId}/preference/voucher_division`,
      5: `/journal_entries/${exportFormatId}/preference/export_contents`,
    };
    return urls[step];
  };

  const stepTitle = (step: Step): string => {
    const titles = {
      1: `1. ${i18next.t('journalEntries.titles.setUpFileFormat')}`,
      2: `2. ${i18next.t('journalEntries.titles.setUpExportTarget')}`,
      3: `3. ${i18next.t('journalEntries.titles.setUpDebitCreditStyle')}`,
      4: `4. ${i18next.t('journalEntries.titles.setUpVoucherDivision')}`,
      5: `5. ${i18next.t('journalEntries.titles.setUpExportContent')}`,
    };
    return titles[step];
  };

  const title = (): string => {
    return `${i18next.t('journalEntries.titles.setUpProcedures')}${props.exportFormat.name ? ` (${i18next.t('journalEntries.labels.formatName')}: ${props.exportFormat.name})` : ''}`;
  };

  const isOnTheStep = (step: Step): boolean => {
    return props.currentStep === step;
  };

  const clickable = (step: Step): boolean => {
    return !props.inEditing && !isOnTheStep(step) && exportFormatId !== '';
  };

  const renderNaviText = (step: Step): JSX.Element => {
    const popover = <Popover id='disabled reason' style={ { maxWidth: '190px' } }>{ i18next.t('journalEntries.descriptions.naviTextUnclickableReason') }</Popover>;

    return (
      <OverlayTrigger placement='left' trigger={ clickable(step) || isOnTheStep(step) ? [] : ['hover'] } overlay={ popover }>
        <div>
          <span
            className={ `step${step} ${clickable(step) ? 'txt-pointer clickable' : ''}` }
            onClick={ (): void => (clickable(step) ? props.onClick(redirectUrl(step)) : undefined) }
          >
            { stepTitle(step) }
          </span>
        </div>
      </OverlayTrigger>
    );
  };

  return (
    <NaviPanelView>
      <div className='journal-entries-navi-panel'>
        <div className='title'>
          <span>{ title() }</span>
        </div>
        { renderNaviText(1) }
        { renderNaviText(2) }
        { renderNaviText(3) }
        { renderNaviText(4) }
        { renderNaviText(5) }
      </div>
    </NaviPanelView>
  );
};

export default NaviPanel;
