import ExportModal from './ExportModal';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import reducers from '../reducers';
import { Provider } from 'react-redux';
import { createEnhancer } from 'utilities/react_helper';
import { createStore } from 'redux';

export class ExportModalWrapper extends Component {
  constructor(props) {
    super(props);

    this.store = createStore(reducers, this.processProps(props), createEnhancer());
  }

  processProps(props) {
    return {
      exportData: {
        ...props,
        transactions: [],
        tabs: [],
        hasOauthToken: false,
        authUrl: '',
        exporting: false,
      },
    };
  }

  render() {
    const {
      show, reportId, preReportId, analysisId, closeModal, exportFormats,
    } = this.props;

    return (
      <Provider store={ this.store }>
        <ExportModal
          show={ show }
          reportId={ reportId }
          preReportId={ preReportId }
          analysisId={ analysisId }
          closeModal={ closeModal }
          exportFormats={ exportFormats }
        />
      </Provider>
    );
  }
}

ExportModalWrapper.propTypes = {
  show: PropTypes.bool.isRequired,
  reportId: PropTypes.string,
  preReportId: PropTypes.string,
  analysisId: PropTypes.string,
  closeModal: PropTypes.func.isRequired,
  exportFormats: PropTypes.array.isRequired,
};

export default ExportModalWrapper;
