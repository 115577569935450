import { fetchDirectProductTables } from "./fetch";
import { buildInput } from "./utils";

const prefix = "allowances";

export function resetDirectProductTables(
  all = false,
  transactionId,
  defaultPeriod,
  ownerId,
) {
  return (dispatch, getState) => {
    return dispatch(
      fetchDirectProductTables(
        all,
        true,
        true,
        transactionId,
        defaultPeriod,
        ownerId,
      ),
    ).then((data) => {
      dispatch(setDirectProductTables(data));
    });
  };
}

export const SET_DIRECT_PRODUCT_TABLES = `${prefix}/SET_DIRECT_PRODUCT_TABLES`;
export function setDirectProductTables(data) {
  return {
    type: SET_DIRECT_PRODUCT_TABLES,
    data,
  };
}

/**
 * ユーザが入力した情報を、Storeに保存する
 *
 * @param {string} tableId
 * @param {string} key 保存する情報の種類を指定
 * @param {object, string, number} value
 */
export const SET_INPUT_VALUE = `${prefix}/SET_INPUT_VALUE`;
export function setInputValue(table, key, value) {
  return {
    type: SET_INPUT_VALUE,
    table,
    key,
    value,
  };
}

/**
 * 手当表がそれぞれ持っている、現在の入力フォームの状態を初期化する
 *
 * @param {object} table Storeに保存されている、手当表の情報。サーバから直接取得したものでは動作しないので注意
 * @param {Array} inputs 経費のオブジェクトの中にある、calculationFormulaVariableInputs
 */
export const RESET_INPUT = `${prefix}/RESET_INPUT`;
export function resetInput(table, inputs, defaultPeriod) {
  return async (dispatch, getState) => {
    const inputFormData = buildInput(table, table.cells, inputs, defaultPeriod);

    dispatch({
      type: RESET_INPUT,
      table,
      inputData: inputFormData,
    });
  };
}
