import Api from 'utilities/api';
import React, { useEffect, useState } from 'react';
import Select from 'react-select';
import i18next from 'i18n';
import styled from 'styled-components';
import {
  Alert,
  ControlLabel,
  FormGroup,
  HelpBlock,
} from 'react-bootstrap';
import { Controller, FieldErrors, useFormContext } from 'react-hook-form';
import { ExecutionTimetable } from 'utilities/api/models/accountingDataScheduledExports';
import { RequiredIcon } from '../../../../components/icons/RequiredIcon';

interface Props {
  name: string;
}

const FormGroupView = styled(FormGroup)`
  .form-control:focus {
    outline: none;
    box-shadow: none;
    border: 2px solid #004acc;
    background-color: #ffffff;
  }
  &.has-error div[class$="-control"] {
    border-color: #d9534f;
    box-shadow: none;
  }
  &.has-error .control-label {
    color: #d9534f;
  }
  &.has-error .help-block {
    color: #d9534f;
  }
`;

type ValidationState = 'error' | 'warning' | null | undefined;

const validationState = (error: FieldErrors | undefined): ValidationState => {
  return error ? 'error' : null;
};

export const ExecutionTimetableSelectField: React.FC<Props> = ({
  name,
}) => {
  const { control } = useFormContext();
  const [processing, setProcessing] = useState<boolean>(false);
  const [executionTimetables, setExecutionTimetables] = useState<ExecutionTimetable[]>([]);

  useEffect(() => {
    const fetchExecutionTimetables = async (): Promise<void> => {
      setProcessing(true);

      try {
        const res = await Api.accountingDataScheduledExports.executionTimetables.index();
        setExecutionTimetables(res.data);
      } catch (e) {
        // nothing to do
      } finally {
        setProcessing(false);
      }
    };
    fetchExecutionTimetables();
  }, [setProcessing, setExecutionTimetables]);

  if (processing) {
    return (<div>{ i18next.t('accountingDataScheduledExports.tasks.forms.base.executionTimetable.loading') }</div>);
  }

  if (executionTimetables.length === 0) {
    return (<Alert bsStyle='danger'>{ i18next.t('accountingDataScheduledExports.tasks.forms.base.executionTimetable.notFound') }</Alert>);
  }

  return (
    <Controller
      name={ name }
      rules={ { required: i18next.t<string>('accountingDataScheduledExports.tasks.forms.base.executionTimetable.loading') } }
      control={ control }
      render={ ({ field: { onChange, value, ref: inputRef }, fieldState: { error } }): React.ReactElement => (
        <FormGroupView validationState={ validationState(error) }>
          <ControlLabel><>{ i18next.t('accountingDataScheduledExports.tasks.forms.base.executionTimetable.label') } <RequiredIcon /></></ControlLabel>
          <Select
            inputRef={ inputRef }
            options={ executionTimetables }
            value={ executionTimetables.find((e) => e.id === (value as ExecutionTimetable)?.id) }
            getOptionLabel={ (v: ExecutionTimetable): string => v.name }
            getOptionValue={ (v: ExecutionTimetable): string => v.id }
            onChange={ onChange }
            isClearable
          />
          { error && <HelpBlock>{ error.message }</HelpBlock> }
        </FormGroupView>
      ) }
    />
  );
};
