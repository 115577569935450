import AccordionButton from "components/accordion_button";
import Clearable from "components/fields/Clearable";
import DepartmentSuggestField from "components/fields/DepartmentSuggestField";
import MemberSuggestField from "components/fields/MemberSuggestField";
import NumberField from "components/fields/NumberField";
import RangeField from "components/fields/RangeField";
import LabeledComponent from "components/LabeledComponent";
import PeriodInput from "components/PeriodInput";
import SmartCheckBox from "components/SmartCheckBox";
import i18next from "i18n";
import get from "lodash/get";
import PropTypes from "prop-types";
import React from "react";
import { Button } from "react-bootstrap";
import { getUserLocale } from "utilities/Utils";

/**
 * アコーディオンが閉じているときに表示するコンポーネント
 */
const AccordionButtonOverview = () => {
  return (
    <span>
      <i className="fa fa-fw fa-search txt-accent" />
      <span className="button-text">
        {i18next.t("transactions.index.searchbox.openForm")}
      </span>
    </span>
  );
};

/**
 * 経費検索用のコンポーネント
 */
const SearchAccordionForm = (props) => {
  const renderInput = (_type, childProps) => {
    return <NumberField allowDecimal={true} {...childProps} />;
  };

  const handleRangeInput = (type, value) => {
    const inputNum = value === "" || value === undefined ? null : Number(value);
    switch (type) {
      case "from":
        props.setSearchConditions("amountFrom", inputNum);
        break;
      case "to":
        props.setSearchConditions("amountTo", inputNum);
        break;
      default: // do nothing
    }
  };

  return (
    <AccordionButton
      defaultOpen={false}
      renderButton={() => <AccordionButtonOverview />}
    >
      <div className="expense-searchbox">
        <div className="row form-group">
          <LabeledComponent
            className="col-sm-12"
            label={i18next.t("transactions.properties.date")}
          >
            <PeriodInput
              locale={props.locale}
              dateFrom={props.transactedAtFrom}
              dateTo={props.transactedAtTo}
              onChangeDateFrom={(v) =>
                props.setSearchConditions("transactedAtFrom", v)
              }
              onChangeDateTo={(v) =>
                props.setSearchConditions("transactedAtTo", v)
              }
            />
          </LabeledComponent>
        </div>
        <div className="row">
          <RangeField
            title={i18next.t("transactions.properties.amount")}
            className="col-sm-12"
            renderInput={renderInput}
            from={props.amountFrom}
            to={props.amountTo}
            onChange={handleRangeInput}
          />
        </div>
        <div className="row form-group">
          <LabeledComponent
            className="col-sm-6"
            label={i18next.t("transactions.properties.shop")}
          >
            <Clearable
              onClear={() => props.setSearchConditions("shopName", "")}
            >
              <input
                type="text"
                className="form-control validate[required, maxSize[255]]"
                value={props.shopName}
                onChange={(e) =>
                  props.setSearchConditions("shopName", e.target.value)
                }
              />
            </Clearable>
          </LabeledComponent>
          {props.forAdmin && (
            <LabeledComponent
              className="col-md-6"
              label={i18next.t("groups.properties.employee")}
            >
              <MemberSuggestField
                text={props.ownerName}
                value={props.ownerName ? { name: props.ownerName } : null}
                placeholder={i18next.t("transactions.index.searchbox.all")}
                onSelect={(o) =>
                  props.setSearchConditions("ownerName", o && o.name)
                }
                onTextChange={(name) =>
                  props.setSearchConditions("ownerName", name)
                }
              />
            </LabeledComponent>
          )}
        </div>
        {props.forAdmin && (
          <div className={"row form-group"}>
            <LabeledComponent
              className="col-sm-6"
              label={i18next.t("transactions.properties.userDepartment")}
            >
              <DepartmentSuggestField
                text={get(props.department, "name", "")}
                onSelect={(d) => props.setSearchConditions("department", d)}
                onTextChange={(text) =>
                  props.setSearchConditions("department", {
                    id: null,
                    name: text,
                  })
                }
              />
            </LabeledComponent>
            <LabeledComponent
              className="col-sm-6"
              label={i18next.t(
                "transactions.index.searchbox.departmentScope.label",
              )}
            >
              <div className="checkbox-group">
                <SmartCheckBox
                  label={i18next.t(
                    "transactions.index.searchbox.departmentScope.includeChildDepartment",
                  )}
                  checked={props.includeChildDepartment}
                  onChange={() =>
                    props.setSearchConditions(
                      "includeChildDepartment",
                      !props.includeChildDepartment,
                    )
                  }
                  color="accent"
                  type="squared"
                />
              </div>
            </LabeledComponent>
          </div>
        )}
        <div className="row">
          <div className="col-sm-offset-6 col-sm-6 form-group">
            <Button
              className={`col-sm-offset-7 col-sm-4 ${
                props.isSearching ? "disabled" : ""
              }`}
              onClick={props.onClickSearchButton}
              bsStyle="success"
            >
              {props.isSearching ? (
                <i className="fa fa-spinner fa-spin"></i>
              ) : (
                i18next.t("commons.actions.search")
              )}
            </Button>
          </div>
        </div>
      </div>
    </AccordionButton>
  );
};

SearchAccordionForm.defaultProps = {
  forAdmin: false,
  locale: getUserLocale(),
  isSearching: false,
  transactedAtFrom: "",
  transactedAtTo: "",
  amountFrom: null,
  amountTo: null,
  shopName: "",
  ownerName: "",
  department: null,
  includeChildDepartment: false,
};

SearchAccordionForm.propTypes = {
  forAdmin: PropTypes.bool,
  onClickSearchButton: PropTypes.func.isRequired,
  setSearchConditions: PropTypes.func.isRequired,
  locale: PropTypes.string,
  transactedAtFrom: PropTypes.string,
  transactedAtTo: PropTypes.string,
  amountFrom: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  amountTo: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  shopName: PropTypes.string,
  ownerName: PropTypes.string,
  department: PropTypes.object,
  includeChildDepartment: PropTypes.bool,
  isSearching: PropTypes.bool,
};

export default SearchAccordionForm;
