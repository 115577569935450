import React from 'react';
import i18next from 'i18n';
import { Button } from 'components/renewaled_ui/buttons/Button';

interface Props {
  onClick: () => void;
  processing: boolean;
  valid: boolean;
  invalidMessage?: string;
  show?: boolean;
}

export const SaveButton: React.FC<Props> = ({
  onClick,
  processing,
  valid,
  invalidMessage = i18next.t('commons.actions.save'),
  show = true,
}) => {
  return (
    <Button styleType='primary' onClick={ (): void => onClick() } disabled={ processing || !valid } loading={ processing } show={ show }>
      { valid ? i18next.t('commons.actions.save') : invalidMessage }
    </Button>
  );
};
