import Cookie from 'js-cookie';
import _ from 'lodash';
import axios from 'axios';

export default class ApiAuth {
  constructor({ rootPath = '/api/v1/auth', afterSignOutPath = '/' } = {}) {
    this._authRoot = rootPath;
    this._afterSignOutPath = afterSignOutPath;
  }

  get user() {
    return this.authUser;
  }

  set user(user) {
    this.authUser = user;
  }

  get authHeaders() {
    return this._authHeaders || this.buildAuthHeaders();
  }

  setAuthHeaders(authHeaders, updateCookie = false) {
    this._authHeaders = authHeaders;

    if (updateCookie) {
      Cookie.set('authHeaders', authHeaders);
    }
  }

  get authRoot() {
    return this._authRoot;
  }

  get afterSignOutPath() {
    return this._afterSignOutPath;
  }

  /**
   * @returns {Promise} - tokenのバリデーションを行うPromise
   */
  get validationPromise() {
    if (!this._validationPromise) {
      this._validationPromise = new Promise((resolve, reject) => {
        const authHeaders = this.authHeaders;

        this.request('validate_token', 'GET', null, { headers: authHeaders })
          .then(({ data }) => {
            resolve([data, authHeaders]);
          }).catch((error) => {
            reject(error);
          });
      });
    }

    return this._validationPromise;
  }

  /**
   * @returns {Promise} - サインアウトするPromise
   */
  get signOutPromise() {
    if (!this._signOutPromise) {
      if (!_.isEmpty(this.authHeaders)) {
        this._signOutPromise = this.request('sign_out', 'DELETE', null, { headers: this.authHeaders })
          .finally(() => {
            Cookie.remove('authHeaders');
            window.location.assign(this.afterSignOutPath);
          });
      } else {
        window.location.assign(this.afterSignOutPath);
      }
    }

    return this._signOutPromise;
  }

  /**
   * @returns {Promise} - テナント切り替えするPromise
   */
  changeTenantPromise(user) {
    return this.request('change_tenant', 'POST', { 'user_id': user.id }, { headers: this.authHeaders });
  }

  /**
   * @returns {Promise} - 代理人アカウントにログインするPromise
   */
  signInAsAgentPromise(principal) {
    if (!this._signInAsAgentPromise) {
      this._signInAsAgentPromise = this.request('sign_in', 'POST', { 'principal_id': principal.id }, { headers: this.authHeaders });
    }

    return this._signInAsAgentPromise;
  }

  /**
   * @returns {Promise} - 代理人アカウントから自分のアカウントに戻るPromise
   */
  get loginBackToUserPromise() {
    if (!this._loginBackToUserPromise) {
      this._loginBackToUserPromise = this.request('sign_out', 'DELETE', { 'login_back_to_user': true }, { headers: this.authHeaders });
    }

    return this._loginBackToUserPromise;
  }

  buildAuthHeaders() {
    try {
      return JSON.parse(Cookie.get('authHeaders'));
    } catch (e) {
      return {};
    }
  }

  bindEndpoint(apiClient, root) {
    ['updateAccount', 'signIn', 'signUp', 'changeTenant', 'signInAsAgent', 'destroy', 'signOut', 'validateToken', 'loginBackToUser'].forEach((path) => {
      _.set(apiClient, `${root}.${path}`, this[path].bind(this));
    });
  }

  /**
   * tokenのバリデーションを行う。複数回呼び出しても、処理は一回しか実行されない。
   */
  async validateToken() {
    return this.validationPromise;
  }

  async request(url, method, data, options = {}) {
    const defaultOptions = { withCredentials: true };
    const requestHeaders = {
      headers: {
        'Content-Type': 'application/json',
        'X-Requested-With': 'XMLHttpRequest',
        ...options.headers,
      },
    };

    return axios(
      `${this.authRoot}/${url}`,
      {
        ...defaultOptions, ...options, method, data, ...requestHeaders,
      },
    ).then((result) => {
      const headers = result.headers;
      const authHeaders = _.pick(headers, ['client', 'uid', 'access-token', 'expiry', 'token-type']);
      this.setAuthHeaders(authHeaders, true);

      return result.data;
    });
  }

  async updateAccount(data) {
    return this.request('', 'PUT', data, { headers: this.authHeaders });
  }

  async signIn(data) {
    return this.request('sign_in', 'POST', data);
  }

  async signUp(data) {
    return this.request('', 'POST', data);
  }

  async signInAsAgent(principal) {
    return this.signInAsAgentPromise(principal);
  }

  async destroy(data) {
    return this.request('', 'DELETE', data, { headers: this.authHeaders });
  }

  /**
   * テナント切り替えを行う。
   */
  async changeTenant(user, redirectTo = this.afterSignOutPath) {
    this._afterSignOutPath = redirectTo;
    return this.changeTenantPromise(user);
  }

  /**
   * サインアウトする。複数回呼び出しても、処理は一回しか実行されない。
   */
  async signOut(redirectTo = this.afterSignOutPath) {
    this._afterSignOutPath = redirectTo;
    return this.signOutPromise;
  }

  /**
   * 代理人アカウントから自分のアカウントに戻る
   */
  async loginBackToUser() {
    return this.loginBackToUserPromise;
  }

  invalidateToken() {}
}
