import Api from 'utilities/api';
import { IPreReportDriver } from 'apis/IPreReportDriver';
import { ShowResponse } from 'utilities/api/responses/preReports';
import { snakecaseKeys } from 'utilities/Utils';

export class PreReportDriver implements IPreReportDriver {
  /** 経費申請を取得します */
  async fetch(preReportId: string, forApprover: boolean): Promise<ShowResponse> {
    return await Api.preReports.show(snakecaseKeys({ id: preReportId, forApprover }));
  }
}
