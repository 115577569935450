import PropTypes from 'prop-types';

const stationType = {
  name:         PropTypes.string,
  yomi:         PropTypes.string,
  prefecture:   PropTypes.string,
  ekispertCode: PropTypes.number,
  type:         PropTypes.string,
};

const routeType = {
  id:           PropTypes.string,
  isRoundTrip:  PropTypes.string,
  onewayAmount: PropTypes.string,
  withIc:       PropTypes.string,
  lines:        PropTypes.arrayOf(PropTypes.shape({
    name:  PropTypes.string,
    color: PropTypes.string,
  })),
  origin:       PropTypes.shape(stationType),
  destination:  PropTypes.shape(stationType),
};

export default routeType;
