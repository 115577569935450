import ExtendedFileView, { FileViewerProps as ExtendedFileViewProps } from '../renewaled_ui/FileViewer';
import React from 'react';
import { useOriginalReceipt } from './useRemoteFile';

interface Props extends ExtendedFileViewProps {
  /** OriginalReceiptのID */
  readonly id?: string;
  readonly quality?: number;
}

/**
 * 原本画像のビューアー
 */
const OriginalReceiptView: React.FC<Props> = (props: Props) => {
  const {
    id,
    mimeType,
    quality,
    ...viewProps
  } = props;
  const [objectUrl, loadStatus] = useOriginalReceipt(id, mimeType, quality);

  return (
    <ExtendedFileView
      {...viewProps}
      url={objectUrl}
      mimeType={mimeType}
      loadStatus={loadStatus}
    />
  );
};

export default OriginalReceiptView;
