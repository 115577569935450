import AutoSuggestWrapper from 'components/auto_suggest_wrapper';
import EditableBase from 'components/EditableBase';
import PropTypes from 'prop-types';
import React from 'react';

// 基本的にはEditableInputを経由して使用する
// EditableInputのinputTypeにselectを指定すると、
// このComponentが使用される
export default class EditableSelect extends EditableBase {
  constructor(props) {
    super(props);

    this.handleFocus = this.handleFocus.bind(this);
    this.handleBlur = this.handleBlur.bind(this);
  }

  handleFocus(e) {
    this.props.onFocus(e);
  }

  handleBlur(e) {
    // AutoSuggestWrapperのクリアボタンが押された時は、blurイベントを発火させない
    if (e.relatedTarget && e.relatedTarget.className === 'react-autosuggest-clear-btn') {
      return;
    }

    this.props.onBlur(e);
    EditableBase.prototype.handleBlur.call(this, e);
  }

  renderInEditMode() {
    let inputClass = this.props.input.className;
    if (this.props.sync) {
      inputClass += ' input-sync';
    } else if (this.props.sync === false) {
      inputClass += ' input-in-edit';
    }

    const inputProps = this.props.input ? {
      ...this.props.input,
      onFocus: this.handleFocus,
      onBlur: this.handleBlur,
      onKeyPress: this.handleKeyPress,
      className: inputClass,
    } : {};
    const saveButton = (
      <span className='input-group-btn'>
        <button type='button'
          id={ this.buttonId }
          className={ this.props.button.className }
        >
          { this.props.button.text }
        </button>
      </span>
    );
    const { updateOnBlur } = this.props;

    return (
      <form className='edit-text-with-suggest-form'>
        <div className='col-sm-12' style={ this.props.style }>
          <div className={ updateOnBlur ? '' : 'input-group' }>
            <AutoSuggestWrapper isImmutable
              ref={ (ref) => { this._input = ref; } }
              inputProps={ inputProps }
              value={ this.props.editingText }
              suggestions={ this.props.suggestions }
              renderSuggestion={ this.props.renderSuggestion }
              getSuggestionValue={ this.props.getSuggestionValue }
              onSuggestionsUpdateRequested={ this.props.onSuggestionsUpdateRequested }
              onTextChanged={ this.props.onChange }
              onSuggestionSelected={ this.props.onSelect }
              clearValue={ this.props.clearValue || this.props.onChange.bind(null, '') }
            />
            { updateOnBlur ? null : saveButton }
          </div>
        </div>
      </form>
    );
  }
}

EditableSelect.defaultProps = {
  getSuggestionValue(v) { return v; },
  renderSuggestion(v) { return v; },
};

EditableSelect.propTypes = {
  style: PropTypes.object,
  suggestions: PropTypes.array.isRequired,
  renderSuggestion: PropTypes.func.isRequired,
  getSuggestionValue: PropTypes.func.isRequired,
  onSuggestionsUpdateRequested: PropTypes.func.isRequired,
  onChange: PropTypes.func.isRequired,
  onSelect: PropTypes.func.isRequired,
};
