import React from "react";
import { useTable } from "../hooks/useTable";
import { AmountPerTaxCategory, Authority } from "../interface";
import { ReadOnlyTable } from "./ReadOnlyTable";
import { ReadOnlyTableWithoutTaxAmount } from "./ReadOnlyTableWithoutTaxAmount";
import { ReadOnlyTaxCategorySelectionField } from "./ReadOnlyTaxCategorySelectionField";

interface Props {
  /** 税区分別金額 */
  readonly values: AmountPerTaxCategory[];
  /** 消費税額を表示するか */
  readonly isTaxAmountShow: boolean;
  /** ラベル */
  readonly label: string;
  /** 権限 */
  readonly authority: Authority;
  /** 税区分入力可能か */
  readonly isTaxCategoryEditable: boolean;
}

/**
 * 税区分別金額一覧入力欄(読み取り専用)
 */
export const ReadOnly: React.FC<Props> = ({
  isTaxAmountShow,
  values,
  label,
  authority,
  isTaxCategoryEditable,
}) => {
  const { isShowNameColumn, withTaxRows, withoutTaxRows } = useTable(
    authority,
    values,
    null,
  );

  return (
    <>
      {isTaxCategoryEditable && (
        <ReadOnlyTaxCategorySelectionField
          selectedTaxCategories={values.map((o) => o.taxCategory)}
        />
      )}
      {isTaxAmountShow && (
        <ReadOnlyTable
          values={withTaxRows}
          isShowNameColumn={isShowNameColumn}
        />
      )}
      {!isTaxAmountShow && values.length > 0 && (
        <ReadOnlyTableWithoutTaxAmount
          label={label}
          values={withoutTaxRows}
          isShowNameColumn={isShowNameColumn}
        />
      )}
    </>
  );
};
