import {
  DeleteEventHandler,
  GetImageElement,
  Rect,
  RotateEventHandler,
  ScaleEventHandler,
} from "components/types/attachedFiles.types";
import React, { useRef } from "react";
import { useRotation, useScale } from "./useImageViewer";

interface UseReturn {
  readonly ref: React.RefObject<HTMLDivElement>;
  readonly imageStyle: React.CSSProperties;
  readonly onClickRotateImage: (
    e: React.MouseEvent<HTMLElement, MouseEvent>,
  ) => void;
  readonly onClickDeleteImage: (
    e: React.MouseEvent<HTMLElement, MouseEvent>,
  ) => void;
  readonly onClickExpandImage: (
    e: React.MouseEvent<HTMLElement, MouseEvent>,
  ) => void;
  readonly onClickScaleUpImage: (
    e: React.MouseEvent<HTMLElement, MouseEvent>,
  ) => void;
  readonly onClickScaleDownImage: (
    e: React.MouseEvent<HTMLElement, MouseEvent>,
  ) => void;
}

/**
 * 画像ビューアーのツールのロジック
 */
export const useHooksImage = (
  defaultScale?: number,
  defaultRotation?: number,
  onDelete?: DeleteEventHandler,
  onScale?: ScaleEventHandler,
  onRotate?: RotateEventHandler,
  getImageElement?: GetImageElement,
): UseReturn => {
  const ref = useRef<HTMLDivElement>(null);

  // 回転
  const [rotation, rotationCallbacks] = useRotation({
    onChange: onRotate,
    defaultValue: defaultRotation,
  });

  /**
   * 倍率1の画像サイズ
   * ただし、縦横は表示上の値を正とするため、回転は考慮する
   */
  const getRawImageSize = (
    containerElement: HTMLElement | null,
    rotationValue: number,
    getElement?: GetImageElement,
  ): Rect => {
    if (!getElement) {
      return [0, 0];
    }

    const imageElement = getElement(containerElement);

    if (!imageElement) {
      return [0, 0];
    }

    const imageSize: Rect = [
      imageElement.offsetHeight,
      imageElement.offsetWidth,
    ];

    return rotationValue % 180 === 0
      ? imageSize
      : (imageSize.reverse() as Rect);
  };

  /**
   * ビューアーの縦横のサイズを返す
   */
  const getViewerSize = (containerElement: HTMLElement | null): Rect => {
    if (!containerElement) {
      return [0, 0];
    }

    return [containerElement.offsetHeight, containerElement.offsetWidth];
  };

  /**
   * 画像ポジション/向き/サイズなどのCSSを返す
   */
  const getImageStyle = (
    viewerSize: Rect,
    rawImageSize: Rect,
    rotationValue: number,
    scale: number,
  ): React.CSSProperties => {
    const [viewerHeight, viewerWidth] = viewerSize;
    const [imageHeight, imageWidth] = rawImageSize.map((x) => x * scale);
    const [overflowVertical, overflowHorizontal] = [
      imageHeight - viewerHeight,
      imageWidth - viewerWidth,
    ];

    const translateX =
      overflowHorizontal > 0 ? `${overflowHorizontal / 2}px` : "0px";
    const translateY =
      overflowVertical > 0 ? `${overflowVertical / 2}px` : "0px";

    return {
      transform: `translate(${translateX}, ${translateY}) rotate(${rotationValue}deg) scale(${scale})`,
      transformOrigin: "center",
    };
  };

  const viewerSize = getViewerSize(ref.current);
  const rawImageSize = getRawImageSize(ref.current, rotation, getImageElement);
  const scaleOption = {
    viewerSize,
    rawImageSize,
    defaultValue: defaultScale,
    onChange: onScale,
  };
  // 拡大・縮小
  const [scale, scaleCallbacks] = useScale(scaleOption);
  const imageStyle = getImageStyle(viewerSize, rawImageSize, rotation, scale);

  /**
   * ツールバー: 回転を押した
   */
  const onClickRotateImage = (
    e: React.MouseEvent<HTMLElement, MouseEvent>,
  ): void => {
    rotationCallbacks.onRotate(e);
  };

  /**
   * ツールバー: 削除を押した
   */
  const onClickDeleteImage = (
    e: React.MouseEvent<HTMLElement, MouseEvent>,
  ): void => {
    e.stopPropagation();
    if (!onDelete) return;

    onDelete(e);
  };

  /**
   * ツールバー: 縮小を押した
   */
  const onClickScaleDownImage = (
    e: React.MouseEvent<HTMLElement, MouseEvent>,
  ): void => {
    scaleCallbacks.onScaleDown(e);
  };

  /**
   * ツールバー: 拡大を押した
   */
  const onClickScaleUpImage = (
    e: React.MouseEvent<HTMLElement, MouseEvent>,
  ): void => {
    scaleCallbacks.onScaleUp(e);
  };

  /**
   * ツールバー: サイズを元に戻すを押した
   */
  const onClickExpandImage = (
    e: React.MouseEvent<HTMLElement, MouseEvent>,
  ): void => {
    scaleCallbacks.onFit(e);
  };

  return {
    ref,
    imageStyle,
    onClickDeleteImage,
    onClickRotateImage,
    onClickScaleUpImage,
    onClickScaleDownImage,
    onClickExpandImage,
  };
};
