import { fetchAsync, fetchAsyncAll } from "actions/AsyncAction";
import { ThunkDispatch } from "tdispatch";
import Api from "utilities/api";
import { snakecaseKeys } from "utilities/Utils";
import * as Actions from "./transactionTable";

/** 部署一覧を取得する */
export function fetchDepartments() {
  return async (dispatch: ThunkDispatch): Promise<void> => {
    const params = {
      excludeRoot: true,
    };
    try {
      const data = await dispatch(fetchAsync(Api.departments.index, params));
      const departmentNames = data.departments.map((g) => g.name);
      dispatch(Actions.initTotalSuggestions("groupName", departmentNames));
    } catch {
      /* エラー表示後なので何もしない */
    }
  };
}

/** 経費科目一覧を取得する */
export function fetchCategories(ownerId: string | null = null) {
  return async (dispatch: ThunkDispatch): Promise<void> => {
    const { data } = await fetchAsyncAll(
      (params) =>
        Api.personalCategories
          .index(
            snakecaseKeys({
              disableFilter: true,
              enable: true,
              ownerId,
              ...params,
            }),
          )
          .then((result) => {
            return { data: result.categories, count: result.count };
          }),
      {
        /* APIを投げる際の追加パラメータは特になし */
      },
      { limit: 200 },
    );
    const categoryNames = data.map((c) => c.name);
    dispatch(Actions.initTotalSuggestions("categoryName", categoryNames));
  };
}

/** 勘定科目一覧を取得する */
export function fetchSuperCategories() {
  return async (dispatch: ThunkDispatch): Promise<void> => {
    const param = {
      enable: true,
    };
    try {
      const data = await dispatch(
        fetchAsync(Api.kernels.superCategories.index, param),
      );
      const superCategoryNames = data.superCategories.map((c) => c.name);
      dispatch(
        Actions.initTotalSuggestions("creditCategoryName", superCategoryNames),
      );
    } catch {
      /* エラー表示後なので何もしない */
    }
  };
}

/** プロジェクト一覧を取得する */
export function fetchProjects(
  ownerId: string | null = null,
  offset = 0,
  limit = 20,
) {
  return async (dispatch: ThunkDispatch): Promise<void> => {
    const params = {
      limit,
      offset,
      ownerId,
    };
    try {
      const data = await dispatch(fetchAsync(Api.projects.index, params));
      dispatch(Actions.initTotalSuggestions("project", data.projects));
    } catch {
      /* エラー表示後なので何もしない */
    }
  };
}

/** 所属部署情報を取得する(userPreferencesを参照するだけ) */
export function fetchBelongingDepartments() {
  return (dispatch: ThunkDispatch): void => {
    dispatch(
      Actions.initTotalSuggestions("department", userPreferences.departments),
    );
  };
}

export function fetchSuggestionsForOwner(ownerId: string) {
  return (dispatch: ThunkDispatch): void => {
    dispatch(fetchCategories(ownerId));
    dispatch(fetchSuperCategories());
    dispatch(fetchProjects(ownerId));
    dispatch(fetchDepartments());
    dispatch(fetchBelongingDepartments());
  };
}
