import React from 'react';
import TokiumLogo from '../assets/TOKIUM-logo-black.png';
import styled from 'styled-components';

const TitleContent = styled.div`
  padding-top: 15px;
  margin-bottom: 22px;
  .title-img {
    width: 150px;
    margin: 0 auto 12px;
    img {
      display: block;
      width: 100%;
      height: auto;
    }
    span {
      font-size: 10px;
      margin: -50px;
    }
    text-align: center;
  }
  .title-text {
    text-align: center;
    font-weight: bold;
    font-size: 1.05rem;
  }
`;

interface Props {
  /** ページタイトルの補足文言 */
  readonly pageExplanation: string;
}

/**
 * ログインページ: タイトル
 */
export const SignInTitle: React.FC<Props> = ({ pageExplanation }) => {
  return (
    <TitleContent className='title-content'>
      <div className='title-img'>
        <img className='' src={ TokiumLogo } alt='TOKIUM LOGO' />
      </div>
      <div className='title-text' role='heading'>
        { pageExplanation }
      </div>
    </TitleContent>
  );
};
