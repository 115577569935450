import get from 'lodash/get';
import isNil from 'lodash/isNil';
import { createSelector } from 'reselect';

const getComponentFilter = (state, props) => get(state, 'importForm', null);

const getForm = (state, props) => state;

const getImportForm = () => createSelector(
  [getComponentFilter, getForm],
  (componentFilter, form) => (isNil(componentFilter) ? form : componentFilter),
);

export default getImportForm;
