import React, { useCallback, useEffect, useRef, useState } from "react";

/** 拡大縮小の間隔 */
const SCALE_STEP = 0.1;
/** 拡大縮小の間隔の桁数（JSの小数点計算はズレるため、整数で計算を行うため使用） */
const SCALE_DIGITS = 10;
/** 拡大縮小の間隔の桁数_ピンチインアウト */
const SCALE_PINCH_DIGITS = 100;
/** 拡大の最小値 */
const SCALE_MIN = 0.1;
/** 拡大の最大値 */
const SCALE_MAX = 3;
/** 拡大のデフォルト値 */
const SCALE_DEFAULT = 1;
/** ピンチアウトインの精度 */
const ACCURACY_SCALE = 100;
/** ピンチアウトインの感度 低いほど感度が高い */
const PINCH_SENSI = 600;

interface WheelEvent {
  readonly deltaX: number;
  readonly deltaY: number;
}
interface UseReturn {
  readonly scaleSize: number;
  readonly isExpandHeight: boolean;
  readonly onClickExpandTool: () => void;
  readonly onClickScaleDownTool: (
    e: React.MouseEvent<HTMLElement, MouseEvent>,
  ) => void;
  readonly onClickScaleUpTool: (
    e: React.MouseEvent<HTMLElement, MouseEvent>,
  ) => void;
}

/**
 * PDFビューアーのツールバー: 拡大率ロジック
 */
export const useHooksScale = (
  wrapperRef: React.RefObject<HTMLDivElement>,
): UseReturn => {
  const [isExpandHeight, setIsExpandHeight] = useState<boolean>(false);
  const [scaleSize, setScaleSize] = useState<number>(SCALE_DEFAULT);
  // 拡大率の数値。useStateは非同期なので連続的な更新に対応できない。
  const scaleRef = useRef<number | null>(null);
  scaleRef.current = scaleSize;

  /**
   * スケールを丸める
   */
  const roundScale = useCallback((scale: number): number => {
    if (scale <= SCALE_MIN) return SCALE_MIN;
    if (scale >= SCALE_MAX) return SCALE_MAX;
    return scale;
  }, []);

  /**
   * ツールバー: 縮小を押した
   */
  const onClickScaleDownTool = (
    e: React.MouseEvent<HTMLElement, MouseEvent>,
  ): void => {
    e.stopPropagation();
    // 最小値を超えないようにする
    if (scaleSize <= SCALE_MIN) return;

    // サイズの更新
    const size =
      Math.floor(scaleSize * SCALE_DIGITS - SCALE_STEP * SCALE_DIGITS) /
      SCALE_DIGITS;
    const roundedSize = roundScale(size);
    setScaleSize(roundedSize);
  };

  /**
   * ツールバー: 拡大を押した
   */
  const onClickScaleUpTool = (
    e: React.MouseEvent<HTMLElement, MouseEvent>,
  ): void => {
    e.stopPropagation();
    // 最大値を超えないようにする
    if (scaleSize >= SCALE_MAX) return;

    // サイズの更新
    const size =
      Math.floor(scaleSize * SCALE_DIGITS + SCALE_STEP * SCALE_DIGITS) /
      SCALE_DIGITS;
    const roundedSize = roundScale(size);
    setScaleSize(roundedSize);
  };

  /**
   * ツールバー: サイズを元に戻すを押した
   */
  const onClickExpandTool = (): void => {
    setIsExpandHeight(!isExpandHeight);
    setScaleSize(SCALE_DEFAULT);
  };

  /**
   * ピンチインアウト
   * 2つの挙動に対応
   * トラックパッド: ピンチインアウト(CTRLも自動でONになる)
   * マウス: CTRL+マウスホイール
   */
  const onWheel = useCallback(
    (e: globalThis.WheelEvent): void => {
      if (!scaleRef.current) return;
      const isPressCtrl = e.ctrlKey;
      // CTRLが押されていない場合はスクロールなので何もしない
      if (!isPressCtrl) return;
      // 画面全体の拡大縮小をできなくする
      e.preventDefault();

      // 拡大縮小とスクロールの向きを一般的なものにする
      // トラックパッドの場合は指を広げると拡大、マウスの場合は下にスクロールすると拡大
      const direction = -1;

      const ev = e as unknown as WheelEvent;
      // 精度として、少数第２位までの値を取得
      const accuracyValue = Math.floor(ev.deltaY * ACCURACY_SCALE);
      // 拡大縮小の感度を掛ける
      const delta = ACCURACY_SCALE * PINCH_SENSI;
      // 追加するサイズ
      const addedValue = (direction * accuracyValue) / delta;
      // 最小値と最大値で丸める
      const roundedScale = roundScale(scaleRef.current + addedValue);
      // 少数第２位までの値で切り上げ
      const floorScale =
        Math.floor(roundedScale * SCALE_PINCH_DIGITS) / SCALE_PINCH_DIGITS;
      setScaleSize(floorScale);
    },
    [roundScale],
  );

  /**
   * ピンチインアウトのイベント登録
   */
  useEffect(() => {
    if (!wrapperRef.current) return;
    wrapperRef.current.addEventListener("wheel", onWheel, { passive: false });
  }, [onWheel]);

  return {
    scaleSize,
    isExpandHeight,
    onClickExpandTool,
    onClickScaleDownTool,
    onClickScaleUpTool,
  };
};
