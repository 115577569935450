import React from "react";

/**
 * 右ドロワーのフッター
 * childrenに含めた要素は左から一定間隔で配置されます。
 * 要素を右側に配置すると、本番環境でサポートチャットのアイコンと被るので、左側に配置してください。
 */
export const RightDrawerFooter: React.FC = ({ children }) => {
  /**
   * childrenが個数によって型が変わるので出し分ける
   * 配列の場合は固定マージンを設け横に並べる
   */
  const renderFooterContents = (): JSX.Element => {
    if (!children) return <></>;

    if (!Array.isArray(children)) {
      return <>{children}</>;
    }

    return (
      <>
        {children.map((child, index) => {
          if (!child) return null;

          return (
            <div
              key={`right-drawer-footer-item-${index}`}
              style={{ marginRight: "8px" }}
            >
              {child}
            </div>
          );
        })}
      </>
    );
  };

  return (
    <footer className="right-drawer-footer">{renderFooterContents()}</footer>
  );
};
