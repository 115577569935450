import React from 'react';

interface Props {
  children: React.ReactElement;
  processing: boolean;
}

/** 処理中のボタンにスピナーを付ける */
export const ProcessingButtonContent: React.FC<Props> = React.memo(({ processing, children }) => {
  return (
    processing ? (
      <>
        <i className='fa fa-spinner fa-spin' />
        { children }
      </>
    ) : children
  );
});
