import i18next from "i18n";
import React from "react";
import { Table } from "react-bootstrap";
import * as Types from "../types";
import { AmountBySeparatorRow } from "./AmountBySeparatorRow";
import { AddRowButton } from "./buttons";

interface Props {
  /** 分割項目別金額 */
  amountBySeparators: Types.AmountBySeparator[];
  /** 分割項目種別 */
  separatorType: Types.SeparatorType;
  /** 分割前の経費の合計金額 */
  totalAmount: number;
  /** 分割後の経費の合計金額 */
  totalSeparatedAmount: number;
  /** テーブルレイアウト */
  tableStyle: Types.TableStyle;
  /** 分割項目別金額追加時 */
  /** 行を追加可能か */
  canAddRow: boolean;
  /** 行を削除可能か */
  canRemoveRow: boolean;
  onAdd: () => void;
  /**
   * 分割項目別金額削除時
   * @param index 削除した行番号
   */
  onRemove: (index: number) => void;
  /**
   * 分割項目別金額の金額変更時
   * @param index 変更した行番号
   * @param amount 変更後の金額
   */
  onChangeAmount: (index: number, amount: number) => void;
  /** 金額入力inputからフォーカスアウト時 */
  onBlurAmountInput: () => void;
  /**
   * 分割項目別金額の項目変更時
   * @param index 変更した行番号
   * @param separator 変更後の分割項目
   */
  onChangeSeparator: (index: number, separator: Types.Separator) => void;
}

/** 経費分割項目別金額編集画面 */
export const AmountsBySeparatorEditor: React.FC<Props> = ({
  amountBySeparators,
  separatorType,
  tableStyle,
  canAddRow,
  canRemoveRow,
  onAdd,
  onRemove,
  onChangeAmount,
  onBlurAmountInput,
  onChangeSeparator,
}) => {
  return (
    <>
      <Table striped bordered condensed hover>
        <thead>
          <tr>
            <th colSpan={tableStyle.firstColSpan}>
              {i18next.t(`transactions.properties.${separatorType}`)}
            </th>
            <th>{i18next.t("transactions.properties.amount")}</th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          {amountBySeparators.map((amountBySeparator, index) => {
            return (
              <AmountBySeparatorRow
                key={`amount-by-separator-row-${index}`}
                amountBySeparator={amountBySeparator}
                onChangeAmount={(amount): void => onChangeAmount(index, amount)}
                onBlurAmountInput={onBlurAmountInput}
                onChangeSeparator={(separator): void =>
                  onChangeSeparator(index, separator)
                }
                onRemove={(): void => onRemove(index)}
                canRemoveRow={canRemoveRow}
                separatorType={separatorType}
                tableStyle={tableStyle}
              />
            );
          })}
        </tbody>
      </Table>
      <div style={{ textAlign: "right" }}>
        <AddRowButton onClick={onAdd} disabled={!canAddRow} />
      </div>
    </>
  );
};
