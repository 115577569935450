import React, { useMemo } from 'react';
import i18next from 'i18next';
import styled from 'styled-components';
import { Popover as BsPopover, OverlayTrigger } from 'react-bootstrap';
import { JobRequestBase } from 'utilities/api/models/accountingDataScheduledExports';

interface Props {
  jobRequest: null | JobRequestBase;
}

const Icon = styled.i`
  margin-right: 0.5rem;
`;

export const ErrorDetailItem: React.FC<Props> = ({
  jobRequest,
}) => {
  const jobCompletion = jobRequest?.jobCompletion;
  const jobExecution = jobRequest?.jobExecution;

  const completed = useMemo(() => !!jobCompletion?.id, [jobCompletion]);

  const log = useMemo(() => {
    const criticalLog = jobExecution?.logs?.find((l) => l.level === 'critical');
    const errorLog = jobExecution?.logs?.find((l) => l.level === 'error');
    const infoWithExceptionLog = jobExecution?.logs?.find((l) => l.level === 'info' && l.exceptionClass);

    return criticalLog || errorLog || infoWithExceptionLog; // 必ずこの順番とする事。「criticalとinfoWithExceptionが混在する」といった場合にcriticalの方を優先的に見せるため
  }, [jobExecution]);


  const triggerText = useMemo(() => {
    if (!(completed && log)) return <></>;

    const infoWithExceptionDetailText = i18next.t('accountingDataScheduledExports.jobRequests.latestJobExecution.infoWithExceptionDetail');
    const errorDetailText = i18next.t('accountingDataScheduledExports.jobRequests.latestJobExecution.errorDetail');

    switch (log.level) {
      case 'info': {
        return <><Icon className='text-secondary fas fa-question-circle'></Icon>{ infoWithExceptionDetailText }</>;
      }
      case 'error':
      case 'critical':
        return <><Icon className='text-danger fas fa-question-circle'></Icon>{ errorDetailText }</>;
      default:
        return <></>;
    }
  }, [completed, log]);

  const popOverContent = useMemo(() => {
    if (!(completed && log)) return <></>;

    switch (log.level) {
      case 'info':
      case 'error':
        return log.message;
      case 'critical':
        return i18next.t('accountingDataScheduledExports.jobRequests.latestJobExecution.criticalMessage');
      default:
        return <></>;
    }
  }, [completed, log]);

  return (
    <OverlayTrigger
      trigger={ ['hover'] }
      placement={ 'bottom' }
      overlay={
        <BsPopover id='description' style={ { minWidth: '400px' } }>
          { popOverContent }
        </BsPopover>
      }>
      <div>
        { triggerText }
      </div>
    </OverlayTrigger>
  );
};
