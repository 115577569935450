import { ListForm } from "components/renewaled_ui/form_views";
import i18next from "i18n";
import isNil from "lodash/isNil";
import * as React from "react";
import { connect } from "react-redux";
import { TDispatch } from "tdispatch"; // eslint-disable-line import/no-unresolved
import * as actions from "../actions";
import CurrencyInput from "../components/CurrencyInput";
import RateInput from "../components/RateInput";
import RateReferenceTypeInput from "../components/RateReferenceTypeInput";
import { RateReferenceType } from "../types";
import { CurrencyIdType, CurrencyType } from "../types/currencyType";

interface State {
  formData: {
    originalAmountCurrencyId: CurrencyIdType;
    rateReferenceType: RateReferenceType;
  };
  formState: {
    exchangeRates: RateSettingType[];
  };
}

interface RateSettingType {
  id: CurrencyIdType;
  exchangeRate: number;
}

interface ExchangeRateType {
  currencyId: CurrencyIdType | null;
  currencyName: string; // 言語によって変わるため型定義なし
  rateSettings: RateSettingType[];
}

interface Props {
  currencies: CurrencyType[];
  currencyId: CurrencyIdType | null;
  exchangeRate: number;
  exchangeRates: ExchangeRateType[];
  rateReferenceType: RateReferenceType;
  editable: boolean;
  setCurrency: (currerncy: CurrencyType | null) => void;
  setExchangeRate: (rate: number) => void;
  setRateReferenceType: (referenceType: RateReferenceType) => void;
}

/**
 * 通貨の種類とレートを入力するフォーム
 */
const CurrencyAndRateInput: React.FunctionComponent<Props> = (
  props: Props,
): JSX.Element => {
  const showRateReferenceTypeInputForm =
    !isNil(props.currencyId) && props.currencyId !== "JPY";
  const showRateInputForm =
    props.rateReferenceType === "manual" && !isNil(props.currencyId);

  return (
    <>
      <ListForm label={i18next.t("transactions.properties.currency")}>
        {CurrencyInput({
          currencies: props.currencies,
          onChange: props.setCurrency,
          disabled: !props.editable,
        })}
      </ListForm>
      {showRateReferenceTypeInputForm && (
        <ListForm
          label={i18next.t("transactions.properties.rateReferenceType.label")}
        >
          {RateReferenceTypeInput({
            value: props.rateReferenceType || "manual",
            onChange: props.setRateReferenceType,
            disabled: !props.editable,
          })}
        </ListForm>
      )}
      {showRateInputForm && (
        <ListForm label={i18next.t("transactions.properties.rate")}>
          {RateInput({
            rate: props.exchangeRate,
            currencyId: props.currencyId,
            onChange: props.setExchangeRate,
            disabled: !showRateInputForm,
          })}
        </ListForm>
      )}
    </>
  );
};

/** @todo object型は使わないように */
// eslint-disable-next-line @typescript-eslint/ban-types
function mapStateToProps(state: State): object {
  const { formData, formState } = state;

  return {
    currencyId: formData.originalAmountCurrencyId,
    rateReferenceType: formData.rateReferenceType,
    exchangeRates: formState.exchangeRates,
  };
}

/** @todo object型は使わないように */
// eslint-disable-next-line @typescript-eslint/ban-types
function mapDispatchToProps(dispatch: TDispatch): object {
  return {
    setCurrency(currency: CurrencyType | null): void {
      dispatch(actions.setCurrency(currency, "currency_and_rate_input"));
      if (currency) {
        dispatch(actions.setRateReferenceTypeForMultipleEditing("setting")); // リセット時のデフォルト状態をセットする
      }
    },
    setExchangeRate(rate: number): void {
      dispatch(actions.setExchangeRate(rate, "currency_and_rate_input"));
    },
    setRateReferenceType(referenceType: RateReferenceType): void {
      dispatch(actions.setRateReferenceTypeForMultipleEditing(referenceType));
      dispatch(actions.setExchangeRate(null, "currency_and_rate_input"));
    },
  };
}

CurrencyAndRateInput.defaultProps = {
  rateReferenceType: "setting",
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(CurrencyAndRateInput);
