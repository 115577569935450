import PropTypes from 'prop-types';
import React, { Component } from 'react';

/*
 * 連打できないようにdisable化する機能を持っただけの汎用ボタン
 */
export default class ToggleButton extends Component {
  constructor(props) {
    super(props);
    this.state = {
      active: true,
    };
  }

  onClick(e) {
    this.disable();
    this.props.onClick(e);
  }

  activate() {
    this.setState({ active: true });
  }

  disable() {
    this.setState({ active: false });
  }

  render() {
    return (
      <button className={ this.className() } type={ this.props.type } onClick={ this.onClick.bind(this) } >
        { this.renderText() }
      </button>
    );
  }

  className() {
    return (this.state.active ? '' : 'disabled ') + this.props.className;
  }

  renderText() {
    return this.props.disabledText || this.props.children;
  }
}

ToggleButton.defaultProps = {
  className:    '',
  type: '',
  onClick: () => ({}),
  disabledText: '',
};

ToggleButton.propTypes = {
  className:    PropTypes.string,
  onClick:      PropTypes.func,
  disabledText: PropTypes.string,
};
