import Api from 'utilities/api';
import React, { useEffect, useState } from 'react';
import Select from 'react-select';
import { ControlLabel, FormGroup } from 'react-bootstrap';
import { Controller, useFormContext } from 'react-hook-form';
import { Post } from 'utilities/api/models/Post';

interface Props {
  name: string;
  label?: string | JSX.Element;
}

export const PostSelectField: React.FC<Props> = ({
  name,
  label,
}) => {
  const { control } = useFormContext();

  const [processing, setProcessing] = useState<boolean>(false);
  const [posts, setPosts] = useState<Post[]>([]);

  useEffect(() => {
    const fetchPosts = async (): Promise<void> => {
      setProcessing(true);

      try {
        const res = await Api.posts.index({ type: 'company' });
        setPosts(res.posts);
      } catch (e) {
        // nothing to do
      } finally {
        setProcessing(false);
      }
    };
    fetchPosts();
  }, [setProcessing, setPosts]);

  if (processing) {
    return (<div>役職を読込中...</div>);
  }

  return posts.length > 0 ? (
    <Controller
      name={ name }
      control={ control }
      render={ ({ field: { onChange, value, ref : inputRef } }): React.ReactElement => (
        <FormGroup>
          { label && <ControlLabel>{ label }</ControlLabel> }
          <Select
            inputRef={ inputRef }
            options={ posts }
            value={ posts?.find((c) => c.id === (value as Post)?.id) }
            getOptionLabel={ (v: Post): string => v.name }
            getOptionValue={ (v: Post): string => v.id }
            onChange={ onChange }
            isClearable
          />
        </FormGroup>
      ) }
    />
  ) : (<></>);
};
