import {
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
} from "components/renewaled_ui/single_views";
import React, { useEffect, useState } from "react";
import Api from "utilities/api";
import { Expense } from "utilities/api/models";
import { ExpenseHistory } from "../types";
import { CloseButton } from "./CloseButton";
import { ExpenseHistoryTable } from "./ExpenseHistoryTable";

export interface Props {
  /** モーダルを表示するか */
  show: boolean;
  /** 閉じる押したときのハンドラ */
  onClose: () => void;
  /** 経費ID */
  expense: Expense;
}

/**
 * 経費編集履歴モーダル
 */
export const ExpenseHistoryModal: React.FC<Props> = ({
  show,
  onClose,
  expense,
}) => {
  const [histories, setHistories] = useState<ExpenseHistory[]>([]);

  /**
   * 経費編集履歴を取得
   */
  useEffect(() => {
    if (!show) return;

    const fetchExpenseHistory = async (): Promise<void> => {
      const { data } = await Api.expenses.histories({ id: expense.id });
      setHistories(data);
    };
    fetchExpenseHistory();
  }, [expense, show]);

  return (
    <Modal show={show} onClose={onClose} width="xl">
      <ModalHeader title="経費編集履歴" onClose={onClose} />
      <ModalBody>
        <ExpenseHistoryTable histories={histories} expense={expense} />
      </ModalBody>
      <ModalFooter>
        <CloseButton onClick={onClose} />
      </ModalFooter>
    </Modal>
  );
};
