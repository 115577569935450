import uniqueId from 'lodash/uniqueId';

const value =  {
  id:             null,
  key:            null,
  amount:         null,
  comment:        null,
  transactedAt:   null,
  editable:       true,
  skipCompanions: true,
  category:       null,
  categoryId:     null,
  categoryName:   null,
  route:          null,
  companions:     [],
  project:        null,
};

export function initialPreTransaction() {
  return { ...value, key: uniqueId("pre_transaction-") };
}

export default {
  initialPreTransaction,
};
