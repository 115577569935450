import ApprovalStep from '../../approval_flows/components/ApprovalStep';
import React, { useCallback, useState } from 'react';
import SimpleModal, { ButtonProps } from 'components/SimpleModal';
import i18next from 'i18n';
import { ProcessingButtonContent } from 'components/ProcessingButtonContent';
import { useMountedRef } from 'hooks';

interface Approver {
  readonly name: string;
}

interface Approval {
  readonly approvers: Array<Approver>;
  readonly condition: 'any' | 'all';
}

interface Props {
  readonly closeModal: () => void;
  readonly currentApprovals?: Array<Approval>;
  readonly onClickUseCurrentApprovalFlow: () => Promise<void>;
  readonly onClickUseNewApprovalFlow: () => Promise<void>;
  readonly showModal: boolean;
}

/**
 * 経費申請・精算申請の再申請モーダル
 */
export const ReapplyingModal: React.FC<Props> = React.memo(({
  closeModal,
  currentApprovals = [],
  onClickUseCurrentApprovalFlow,
  onClickUseNewApprovalFlow,
  showModal,
}) => {
  const mountedRef = useMountedRef();
  const [currentApprovalFlowProcessing, setCurrentApprovalFlowProcessing] = useState(false);
  const [newApprovalFlowProcessing, setNewApprovalFlowProcessing] = useState(false);

  const handleClickCurrentApprovalFlow = useCallback(() => {
    setCurrentApprovalFlowProcessing(true);
    onClickUseCurrentApprovalFlow().finally(() => {
      if (mountedRef.current) {
        setCurrentApprovalFlowProcessing(false);
      }
    });
  }, [mountedRef, onClickUseCurrentApprovalFlow]);

  const handleClickNewApprovalFlow = useCallback(() => {
    setNewApprovalFlowProcessing(true);
    onClickUseNewApprovalFlow().finally(() => {
      if (mountedRef.current) {
        setNewApprovalFlowProcessing(false);
      }
    });
  }, [mountedRef, onClickUseNewApprovalFlow]);

  const inProcess = currentApprovalFlowProcessing || newApprovalFlowProcessing || !showModal;
  const buttons: ButtonProps[] = [
    {
      onClick: handleClickCurrentApprovalFlow,
      color: 'success',
      content: (
        <ProcessingButtonContent processing={ currentApprovalFlowProcessing }>
          { i18next.t('reports.requests.useCurrentApprovalFlow') }
        </ProcessingButtonContent>
      ),
      disabled: inProcess,
    },
    {
      onClick: handleClickNewApprovalFlow,
      color: 'primary',
      content: (
        <ProcessingButtonContent processing={ newApprovalFlowProcessing }>
          {i18next.t('reports.requests.applyNewApprovalFlow')}
        </ProcessingButtonContent>
      ),
      disabled: inProcess,
    },
  ];

  return (
    <SimpleModal
      title={ i18next.t('reports.titles.reapply') }
      show={ showModal }
      close={ closeModal }
      buttons={ buttons }
      dialogClassName="reapplying-modal">
      <label className={ 'col-sm-4 col-sm-offset-4 text-center' }>
        { i18next.t('reports.properties.currentApprovalFlow') }
      </label>
      <div>
        { currentApprovals.map((approver, idx) => {
          return (
            <div key={ `approver-${idx}` } className={ 'form-group' }>
              <ApprovalStep
                key={ `approver-step-${idx}` }
                approvalStepIndex={ idx }
                approverStep={ approver } />
              { (currentApprovals.length === idx + 1) ? null
                : (<div className={ 'form-group clear' }>
                  <i key={ `next-step-icon-${idx}` } className={ 'fa fa-chevron-down down-icon' }/>
                </div>)
              }
            </div>
          );
        })
      }
      </div>
    </SimpleModal>
  );
});
