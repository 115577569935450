import { displayMessage, redirectTo } from "actions/ActionCreators";
import { fetchAsync } from "actions/AsyncAction";
import * as transactionActions from "applications/transactions/actions/transactionTable";
import i18next from "i18n";
import _isNil from "lodash/isNil";
import _uniqueId from "lodash/uniqueId";
import Api from "utilities/api";

const prefix = "corporateReport";

/**
 * 申請可能な経費の一覧を取得する
 */
export function fetchTransactions(
  query = { open: true, requestable: true },
  offset = 0,
  limit = 30,
) {
  return async (dispatch, getState) => {
    dispatch(toggleInProcessStatus(true));
    const { searchConditions } = getState();
    const params = {
      sort: searchConditions.sort,
      ...query,
      offset,
      limit,
    };

    return dispatch(fetchAsync(Api.transactions.index, params)).then((data) => {
      const { transactions } = getState();
      dispatch(transactionActions.initTransactions(transactions.master, data));
      dispatch(toggleInProcessStatus(false));
    });
  };
}

export function sortRequestableExpenses(
  query = { open: true, requestable: true },
  offset = 0,
  limit = 30,
) {
  return async (dispatch, getState) => {
    const { searchConditions } = getState();
    dispatch(
      transactionActions.setSearchConditions({ ...searchConditions, ...query }),
    );

    const data = await dispatch(
      fetchAsync(Api.transactions.index, { ...query, offset, limit }),
    );
    dispatch(transactionActions.clearAllCacheTransactions());

    const { transactions } = getState();
    return dispatch(
      transactionActions.initTransactions(transactions.master, data),
    );
  };
}

/**
 * 申請に含まれる経費の情報を取得する
 */
export function fetchReportTransactions(
  reportId,
  transactionIds,
  offset = 0,
  limit = 30,
) {
  return (dispatch, getState) => {
    return dispatch(
      fetchAsync(Api.transactions.index, { transactionIds, offset, limit }),
    ).then((data) => {
      dispatch(setReportTransactions(reportId, data, offset));
    });
  };
}

export function selectThisMonths() {
  return (dispatch, getState) => {
    const date = new Date();
    const { transactions } = getState();
    const selected = transactions.master
      .filter((t) => {
        if (_isNil(t)) {
          return false;
        }

        const transactedAt = new Date(t.transactedAt);
        return (
          transactedAt.getMonth() === date.getMonth() &&
          transactedAt.getFullYear() === date.getFullYear()
        );
      })
      .map((x) => x.id);

    dispatch(transactionActions.selectTransactions(selected));
  };
}

export function selectLastMonths() {
  return (dispatch, getState) => {
    const date = new Date();

    // 日付は1日に指定しておく。現在の日付が、指定の月において存在しない日付の時、意図通りに動かないため。
    // 負の数が入った場合は、値に応じて年が自動的に変化する。
    date.setMonth(date.getMonth() - 1, 1);
    const { transactions } = getState();

    const selected = transactions.master
      .filter((t) => {
        if (_isNil(t)) {
          return false;
        }

        const transactedAt = new Date(t.transactedAt);
        return (
          transactedAt.getMonth() === date.getMonth() &&
          transactedAt.getFullYear() === date.getFullYear()
        );
      })
      .map((x) => x.id);

    dispatch(transactionActions.selectTransactions(selected));
  };
}

export function selectByProject(id) {
  return (dispatch, getState) => {
    const { transactions } = getState();
    const selected = transactions.master
      .filter((t) => {
        return t && t.project && t.project.id === id;
      })
      .map((x) => x.id);

    dispatch(transactionActions.selectTransactions(selected));
  };
}

export function selectByPayer(id) {
  return (dispatch, getState) => {
    const isCorporate = id === "corporatePayment";
    const { transactions } = getState();
    const selected = transactions.master
      .filter((t) => t && !!t.isCorporate === isCorporate)
      .map((x) => x.id);

    dispatch(transactionActions.selectTransactions(selected));
  };
}

export function fetchDefaultReportName(transactionIds, isDraft) {
  return async (dispatch, getState) => {
    const params = {
      transactionIds,
      isDraft,
    };

    return dispatch(fetchAsync(Api.preferences.defaultReportName, params)).then(
      (data) => {
        dispatch(setReportTitle(data.defaultReportName));
      },
    );
  };
}

/**
 * パーソナルプラン向け
 * パーソナルプランのユーザがレポートを作成する際に使用する（申請の代わり）
 */
export function createReportForPersonal(reportTitle, transactionIds) {
  return (dispatch, getState) => {
    const params = {
      title: reportTitle,
      transactionIds,
    };

    return dispatch(fetchAsync(Api.expenses.reports.create, params)).then(
      () => {
        dispatch(redirectTo("/requests"));
      },
    );
  };
}

/**
 * 申請する経費の情報を送り、申請フローの設定に基づき複数の分割された申請の情報を取得する
 *
 * @param {string} reportTitle
 * @param {string[]} transactionIds
 */
export function fetchSplittedReports(reportTitle, transactionIds) {
  return (dispatch, getState) => {
    dispatch(toggleLoadingStatus(true));

    return dispatch(
      fetchAsync(Api.reportRequests.split, { reportTitle, transactionIds }),
    )
      .then((data) => {
        const reports = data.reports.map((report) => {
          return {
            ...report,
            id: report.id || _uniqueId("report-"),
            editingApprovalIndex: -1,
            isAddApproverModalOpen: false,
            // 経費のリストは、詳細情報を未取得の状態で初期化
            transactions: {
              currentPage: 1,
              sizePerPage: 10,
              data: [...new Array(report.transactionIds.length)],
            },
          };
        });

        dispatch(setReports(reports));
        dispatch(toggleLoadingStatus(false));

        // 表示中のモーダルを閉じて、申請用のモーダルを開く
        dispatch(toggleReportSendModal(false));
        dispatch(toggleReportRequestModal(true));
      })
      .catch((e) => {
        dispatch(toggleLoadingStatus(false));
      });
  };
}

/**
 * 未申請ステータスの申請書を作成する
 *
 * @param {string} title
 * @param {string[]} transactionIds
 */
export function draftSaveReport(title, transactionIds) {
  return (dispatch, getState) => {
    dispatch(toggleLoadingStatus(true));

    return dispatch(
      fetchAsync(Api.expenses.reports.create, { title, transactionIds }),
    )
      .then((data) => {
        dispatch(displayMessage("success", data.message));
        window.location.href = `/reports/${data.id}`;
      })
      .catch((e) => {
        dispatch(toggleLoadingStatus(false));
      });
  };
}

/**
 * 申請書に作成済みの経費を追加する
 *
 * @param {string} id 申請書ID
 * @param {string[]} transactionIds 経費IDの配列
 */
export function attachExpenses(id, transactionIds) {
  return (dispatch, getState) => {
    dispatch(toggleLoadingStatus(true));

    return dispatch(
      fetchAsync(Api.expenses.reports.attach, { id, transactionIds }),
    )
      .then((data) => {
        dispatch(
          displayMessage(
            "success",
            i18next.t("reports.messages.addExpensesSuccess"),
          ),
        );
        window.location.reload();
      })
      .catch((e) => {
        dispatch(toggleLoadingStatus(false));
      });
  };
}

/**
 * 申請する
 * fetchSplittedReportsにより分割された申請の情報に、申請フローの情報を付加して送信する
 *
 * @todo 申請の分割自体は、サーバー側で行うように
 *   現状は任意の形に分割した申請を提出可能であるため、業務ロジックのバリデーションが働かない状態
 *
 * @param {object[]} reports
 * @param {string|null} originalReportId 申請のフローを再設定する際に、元の申請ID（UUID）を指定する
 *   フローを再設定しない場合は、指定しない
 */
export function requestReportsAndFetchTransactions(
  reports,
  originalReportId = null,
  sizePerPage = 30,
) {
  return async (dispatch, getState) => {
    const params = {
      async: true,
      reportId: originalReportId,
      reports: reports.map((x) => {
        return {
          name: x.name,
          transactionIds: x.transactionIds,
          comment: x.requestComment,
          approvals: [...x.approvals],
          approvalFlowId: x.approvalFlowId,
        };
      }),
    };
    // 承認者が１人もいない、スキップ不可のステップに承認者がいない時は送信を中止してメッセージを表示
    const isApprovalValid = reports.every((report) => {
      return report.approvals.every((cur) => {
        return cur.approvers.length > 0 || cur.skippable;
      });
    });
    const isApproverExist = reports.every((report) => {
      return report.approvals.some((cur) => {
        return cur.approvers.length > 0;
      });
    });
    if (!isApprovalValid || !isApproverExist) {
      throw new Error(i18next.t("reports.messages.selectApprovers"));
    }

    dispatch(toggleLoadingStatus(true));

    try {
      const data = await dispatch(
        fetchAsync(Api.reportRequests.request, params, true),
      );

      dispatch(transactionActions.clearAllCacheTransactions());
      await dispatch(
        fetchTransactions({ open: true, requestable: true }, 0, sizePerPage),
      );

      dispatch(toggleReportRequestModal(false));
      dispatch(displayMessage("success", data.message));

      // 申請書が1つの場合は申請書詳細ページに遷移。複数の場合は申請書一覧に遷移。
      const reportsLength = data.reports.length;
      if (reportsLength === 1) {
        window.location.href = `/reports/${data.reports[0].id}`;
      } else {
        window.location.href = "/requests";
      }
    } finally {
      dispatch(toggleLoadingStatus(false));
    }
  };
}

export const SET_REPORTS = `${prefix}/SET_REPORTS`;
export function setReports(reports) {
  return {
    type: SET_REPORTS,
    data: reports,
  };
}

export const SET_REPORT_TITLE = `${prefix}/SET_REPORT_TITLE`;
export function setReportTitle(title) {
  return {
    type: SET_REPORT_TITLE,
    value: title,
  };
}

export const SET_REPORT_FORM_VALUE = `${prefix}/SET_REPORT_FORM_VALUE`;
export function setReportFormValue(reportId, key, value) {
  return {
    type: SET_REPORT_FORM_VALUE,
    reportId,
    key,
    value,
  };
}

export const SET_REPORT_TRANSACTIONS = `${prefix}/SET_REPORT_TRANSACTIONS`;
export function setReportTransactions(reportId, data, offset) {
  return {
    type: SET_REPORT_TRANSACTIONS,
    data: data.transactions,
    reportId,
    offset,
  };
}

export const SET_REPORT_TRANSACTIONS_PAGE_VALUE = `${prefix}/SET_REPORT_TRANSACTIONS_PAGE_VALUE`;
/**
 * 申請に含まれる経費の一覧情報について、currentPage, sizePerPageを更新する
 */
export function setReportTransactionsPageValue(reportId, key, value) {
  return {
    type: SET_REPORT_TRANSACTIONS_PAGE_VALUE,
    reportId,
    key,
    value,
  };
}

export const TOGGLE_REPORT_SEND_MODAL = `${prefix}/TOGGLE_REPORT_SEND_MODAL`;
export function toggleReportSendModal(show, isDraft = false) {
  return {
    type: TOGGLE_REPORT_SEND_MODAL,
    show,
    isDraft,
  };
}

/**
 * デフォルトの申請名を取得し、申請用のモーダルを開く
 *
 * @param {string[]} transactionIds  申請対象の経費のIDのリスト。全ての申請可能経費を申請する際は、null
 * @param {boolean} isDraft  下書き保存かどうか(=申請書を作成だけして、申請には出さない)
 */
export function openReportSendModal(transactionIds = null, isDraft = false) {
  return (dispatch, getState) => {
    dispatch(toggleLoadingStatus(true));

    return dispatch(fetchDefaultReportName(transactionIds, isDraft))
      .then((data) => {
        dispatch(toggleLoadingStatus(false));
        dispatch(toggleReportSendModal(true, isDraft));
      })
      .catch((e) => {
        dispatch(toggleLoadingStatus(false));
        return Promise.reject(e);
      });
  };
}

export const TOGGLE_LOADING_STATUS = `${prefix}/TOGGLE_LOADING_STATUS`;
export function toggleLoadingStatus(isLoading) {
  return {
    type: TOGGLE_LOADING_STATUS,
    value: isLoading,
  };
}

export const TOGGLE_IN_PROCESS_STATUS = `${prefix}/TOGGLE_IN_PROCESS_STATUS`;
export function toggleInProcessStatus(inProcess) {
  return {
    type: TOGGLE_IN_PROCESS_STATUS,
    value: inProcess,
  };
}

export const TOGGLE_REPORT_REQUEST_MODAL = `${prefix}/TOGGLE_REPORT_REQUEST_MODAL`;
export function toggleReportRequestModal(show) {
  return {
    type: TOGGLE_REPORT_REQUEST_MODAL,
    show,
  };
}

export const TOGGLE_TRANSACTIONS_MODAL = `${prefix}/TOGGLE_TRANSACTIONS_MODAL`;
/**
 * @param {string} reportId 表示する経費を含む申請のID
 *   申請が分岐している場合、表示すべき経費の一覧を特定するために必要なため、指定する
 * @param {boolean} show trueの時、モーダルを表示する
 */
export function toggleTransactionsModal(reportId, show) {
  return {
    type: TOGGLE_TRANSACTIONS_MODAL,
    reportId,
    show,
  };
}

export const TOGGLE_COST_ALLOCATION_MODAL = `${prefix}/TOGGLE_COST_ALLOCATION_MODAL`;

export function toggleCostAllocationModal(show) {
  return {
    type: TOGGLE_COST_ALLOCATION_MODAL,
    show,
  };
}

export const SET_COST_ALLOCATIONS = `${prefix}/SET_COST_ALLOCATIONS`;

export function setCostAllocations(data) {
  return {
    type: SET_COST_ALLOCATIONS,
    data,
  };
}

export const SET_COST_ALLOCATION_HOVOR_ROW_IDX = `${prefix}/SET_COST_ALLOCATION_HOVOR_ROW_IDX`;
export function setCostAllocationHovorRowIdx(rowIdx) {
  return {
    type: SET_COST_ALLOCATION_HOVOR_ROW_IDX,
    rowIdx,
  };
}
