import SuggestField from "components/fields/SuggestField";
import i18next from "i18n";
import PropTypes from "prop-types";
import React, { PureComponent } from "react";
import { connect } from "react-redux";
import formatter from "utilities/formatter";
import colors from "values/colors";
import PREFERENCE_VALUES from "values/preference";
import * as actions from "../actions";

export class PayerDepartmentInput extends PureComponent {
  constructor(props) {
    super(props);

    this.onClickAppendInputButton = this.onClickAppendInputButton.bind(this);
    this.onClickRemoveInputButton = this.onClickRemoveInputButton.bind(this);
  }

  onClickAppendInputButton() {
    const inputNum = this.props.costAllocations.length;

    this.props.appendInput();

    if (inputNum === 1) {
      this.props.onChangeAllocation(0, 0);
    }
  }

  onClickRemoveInputButton(index) {
    const inputNum = this.props.costAllocations.length;

    if (inputNum > 1) {
      this.props.removeInput(index);

      if (inputNum === 2) {
        // 1つだけになるときには、負担率を100%にする
        this.props.devideCostAllocationsEquelly();
      }
    }
  }

  renderSuggestionField(costAllocation, idx) {
    const filteredSuggestions = (this.props.suggestions || []).filter(
      (x) => x !== undefined,
    );
    // costAllocation.payerName は undefined になることもあるので 空文字列をセット
    const filterOptions = (dept) =>
      dept.name.includes(costAllocation.payerName || "");

    return (
      <SuggestField
        text={costAllocation.payerName}
        initialOptions={filteredSuggestions}
        getOptionText={(dept) => dept.payerName}
        renderOption={(dept) =>
          formatter.departmentWithAbsolutePath(dept.absolutePath)
        }
        onSelect={(dept) => {
          this.props.onChangeDepartment(dept.id, idx);
        }}
        onTextChange={(txt) => {
          this.props.onChangeDepartmentName(txt, idx);
        }}
        filterOptions={filterOptions}
        disabled={this.props.disabled}
      />
    );
  }

  renderInputWithPercentage(costAllocation, index) {
    return (
      <div
        className="row"
        style={{ marginLeft: "0px", marginRight: "0px", marginBottom: "5px" }}
        key={index}
      >
        <div className="col-sm-6 col-md-6" style={{ padding: "0px" }}>
          {this.renderSuggestionField(costAllocation, index)}
        </div>
        <div className="col-sm-6 col-md-6">
          <div className="original-amount">
            <input
              type="number"
              min="0"
              max="100"
              step="1"
              className="form-control validate[required,custom[number],maxSize[15]]"
              style={{
                width: "50%",
                minWidth: "50px",
                paddingLeft: "5px",
                paddingRight: "5px",
                display: "inline-block",
              }}
              value={costAllocation.numerator.toString()}
              onChange={(e) => {
                this.props.onChangeAllocation(e.target.value, index);
              }}
            ></input>
            <span
              style={{
                verticalAlign: "middle",
                height: "34px",
                lineHeight: "34px",
                marginLeft: " 5px",
              }}
            >
              %
            </span>
            <span
              className="txt-danger txt-xlg txt-pointer"
              style={{
                position: "relative",
                top: "-2px",
                verticalAlign: "middle",
                height: "34px",
                lineHeight: "34px",
                marginLeft: " 15px",
              }}
              onClick={this.onClickRemoveInputButton.bind(void 0, index)}
            >
              ×
            </span>
          </div>
        </div>
      </div>
    );
  }

  render() {
    const { costAllocations } = this.props;
    const groupNum = this.props.totalGroups.length;
    const inputNum = this.props.costAllocations.length;

    return (
      <div>
        {costAllocations.length === 1
          ? this.renderSuggestionField(costAllocations[0], 0)
          : costAllocations.map((x, i) => this.renderInputWithPercentage(x, i))}
        {(costAllocations.length > 1 ||
          userPreferences.preference.costAllocationType ===
            PREFERENCE_VALUES.costAllocationType.group) &&
        groupNum > inputNum ? (
          <div
            className="txt-pointer"
            style={{
              paddingLeft: "0px",
              marginTop: "2px",
              color: colors.renewaled.main,
              fontSize: "12px",
            }}
            onClick={this.onClickAppendInputButton}
          >
            {i18next.t("transactions.inputs.addCostAllocation")}
          </div>
        ) : null}
      </div>
    );
  }
}

PayerDepartmentInput.propTypes = {
  suggestions: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string.isRequired,
      absolutePath: PropTypes.string.isRequired,
      id: PropTypes.string.isRequired,
      level: PropTypes.number.isRequired,
      parentId: PropTypes.string.isRequired,
    }),
  ),
  onChangeDepartmentName: PropTypes.func.isRequired,
  onChangeDepartment: PropTypes.func.isRequired,
  disabled: PropTypes.bool.isRequired,
  costAllocations: PropTypes.arrayOf(
    PropTypes.shape({
      payerId: PropTypes.string,
      payerType: PropTypes.string,
      numerator: PropTypes.integer,
      denominator: PropTypes.integer,
    }),
  ),
};

function mapStateToProps(state, ownProps) {
  const { suggestions } = state;

  return {
    totalGroups: suggestions.groups.total,
  };
}

function mapDispatchToProps(dispatch, ownProps) {
  return {
    appendInput() {
      dispatch(actions.appendCostAllocations());
    },
    removeInput(index) {
      dispatch(actions.removeCostAllocations(index));
    },
    devideCostAllocationsEquelly() {
      dispatch(actions.devideCostAllocationsEquelly());
    },
    onChangeAllocation(numerator, index) {
      dispatch(actions.setNumerator(numerator, index));
    },
    onFocus(e) {
      dispatch(actions.pruneGroupSuggestion());
    },
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(PayerDepartmentInput);
