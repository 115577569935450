import { ListForm } from "components/renewaled_ui/form_views";
import React from "react";
import { OperatorEntry } from "utilities/api_expenses/models/expenses/OperatorEntry";
import AmountPerTaxCategoryTable from "../../../AmountPerTaxCategoryTable";
import AmountWithTaxPerTaxCategoryTable from "../../../AmountWithTaxPerTaxCategoryTable";
import { useEditor } from "../hooks/useEditor";
import { useTable } from "../hooks/useTable";
import { AmountPerTaxCategory, Authority } from "../interface";
import { OperatorEntryInfo } from "./OperatorEntryInfo";

interface Props {
  /** 経費ID */
  readonly expenseId: string | null;
  /** 税区分別金額 */
  readonly values: AmountPerTaxCategory[];
  /** 利用日 */
  readonly dates: string[];
  /** 金額 */
  readonly amount: number | null;
  /** 経費科目ID */
  readonly categoryId: string | null;
  /** 適格請求書として扱うか */
  readonly asEligibleInvoice: boolean;
  /** 消費税額を表示するか */
  readonly isTaxAmountShow: boolean;
  /** ラベル */
  readonly label: string;
  /** 権限 */
  readonly authority: Authority;
  /**  オペレータ入力 */
  readonly operatorEntry: OperatorEntry | null;
  /** 税区分別金額変更時のコールバック */
  readonly onChange: (amountPerTaxCategories: AmountPerTaxCategory[]) => void;
}

/**
 * 税区分別金額一覧入力欄(税区分入力無効)
 */
export const Editor: React.FC<Props> = ({
  expenseId,
  amount,
  dates,
  categoryId,
  isTaxAmountShow,
  asEligibleInvoice,
  values,
  label,
  authority,
  operatorEntry,
  onChange,
}) => {
  const {
    isShowNameColumn,
    withTaxRows,
    withoutTaxRows,
    handleChangeAmount,
    handleChangeTaxAmount,
  } = useTable(authority, values, onChange);

  const { showOperatorEntry } = useEditor({
    expenseId,
    values,
    amount,
    dates,
    categoryId,
    asEligibleInvoice,
    isTaxCategoryEditable: false,
    operatorEntry,
    onChange,
  });

  return (
    <>
      {isTaxAmountShow && (
        <AmountWithTaxPerTaxCategoryTable
          values={withTaxRows}
          showName={isShowNameColumn}
          editable={true}
          onAmountChange={handleChangeAmount}
          onTaxAmountChange={handleChangeTaxAmount}
        />
      )}
      {!isTaxAmountShow && values.length > 0 && (
        <ListForm label={label}>
          <AmountPerTaxCategoryTable
            values={withoutTaxRows}
            showName={isShowNameColumn}
            editable={true}
            onAmountChange={handleChangeAmount}
          />
        </ListForm>
      )}
      {operatorEntry && showOperatorEntry && (
        <OperatorEntryInfo operatorEntry={operatorEntry} />
      )}
    </>
  );
};
