import Api from 'utilities/api';
import get from 'lodash/get';
import { fetchAsync } from 'actions/AsyncAction';

const prefix = `members`;

// 指定したページのデータを再取得して、他のページのデータをクリアする
export function resetMembers(resetPage) {
  return async (dispatch, getState) => {
    const { sizePerPage } = getState().members;
    const limit = sizePerPage;
    const offset = (resetPage - 1) * sizePerPage;
    const members = [];

    await dispatch(fetchMembers(offset, limit, members));
    if (resetPage) {
      dispatch(unselectAllPages());
      dispatch(setCurrentPage(resetPage));
    }
  };
}

export function collectParams(state, approvalFlows = []) {
  return {
    searchText: state.userNameOrEmail,
    departmentId: state.department.id,
    departmentScope: state.includeChildDepartment,
    onlyMainDepartment: state.onlyMainDepartment,
    approvalFlowId: state.approvalFlow?.id,
    isUnassignedApprovalFlow: state.isUnassignedApprovalFlow,
    requestTypeId: state.requestType.id,
    postIds: state.postIds,
    postIsUnassigned: state.postIsUnassigned,
  };
}

export function fetchMembers(offset, limit, members = null) {
  return async (dispatch, getState) => {
    const state = getState();
    const oldMembers = members === null ? get(state, 'members.members', []) : members;
    const params = { ...collectParams(get(state, 'memberApprovalFlowSearch', [])), offset, limit };

    return dispatch(fetchAsync(Api.members.indexForApprovalFlowAssignment, params))
      .then((data) => {
        // 新しいページのデータをロードした時に従業員数に差異がある場合、他のページのデータをクリアする
        const memberChanged = members === null && oldMembers.length !== data.count;
        if (memberChanged) {
          dispatch(resetMembers(1));
        } else {
          let currentMembers = initMembers(oldMembers, data.count);
          currentMembers = mergeMembers(currentMembers, data.members, offset, limit);
          dispatch(setMembers(currentMembers));
        }
      });
  };
}

export function initMembers(oldMembers, membersSize) {
  if (oldMembers.length === 0) {
    return [...new Array(membersSize)];
  }
  return oldMembers;
}

export function mergeMembers(oldMembers, newMembers, offset, limit) {
  const members = [];
  for (let i = 0; i < oldMembers.length; i++) {
    if ((i < offset) || (i >= offset + limit)) {
      members.push(oldMembers[i]);
    } else {
      members.push(newMembers[i - offset]);
    }
  }
  return members;
}

export const SET_MEMBERS = `${prefix}/SET_MEMBERS`;
export function setMembers(members) {
  return {
    type: SET_MEMBERS,
    members,
  };
}

export function fetchDepartments() {
  return async (dispatch) => {
    const data = await dispatch(fetchAsync(Api.departments.index, { excludeRoot: true }));
    dispatch(setDepartments(data.departments));
  };
}

export const SET_DEPARTMENTS = `${prefix}/SET_DEPARTMENTS`;
export function setDepartments(departments) {
  return {
    type: SET_DEPARTMENTS,
    departments,
  };
}

export const SELECT = `${prefix}/SELECT`;
export function select(id, isSelected) {
  return {
    type: SELECT,
    id,
    isSelected,
  };
}

export const SELECT_ALL = `${prefix}/SELECT_ALL`;
export function selectAll(isSelected) {
  return {
    type: SELECT_ALL,
    isSelected,
  };
}

export const UNSELECT_ALL_PAGES = `${prefix}/UNSELECT_ALL_PAGES`;
export function unselectAllPages() {
  return {
    type: UNSELECT_ALL_PAGES,
  };
}

export const SET_CURRENT_PAGE = `${prefix}/SET_CURRENT_PAGE`;
export function setCurrentPage(page) {
  return {
    type: SET_CURRENT_PAGE,
    page,
  };
}

export const SET_SIZE_PER_PAGE = `${prefix}/SET_SIZE_PER_PAGE`;
export function setSizePerPage(sizePerPage) {
  return {
    type: SET_SIZE_PER_PAGE,
    sizePerPage,
  };
}
