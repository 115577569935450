import i18next from "i18n";
import React from "react";
import styled from "styled-components";

const Wrapper = styled.div`
  color: #ffffffe0;
  background: #38373d;
  padding: 20px;
  border-radius: 4px;
  box-shadow: 0 0 8px #13163b94;
`;
const Title = styled.div`
  margin-bottom: 10px;
`;
const SubTitle = styled.div`
  margin-bottom: 10px;
  color: #ffffffa0;
`;
const Input = styled.input`
  margin-bottom: 10px;
  color: #38373d;
  padding: 5px;
  width: 100%;
  border-radius: 4px;

  &::placeholder {
    color: #b1b1b6;
  }
  &::-ms-input-placeholder {
    color: #b1b1b6;
  }
  &:-ms-input-placeholder {
    color: #b1b1b6;
  }
`;
const Button = styled.div`
  background: #294790;
  padding: 5px 10px;
  border-radius: 100px;
  text-align: center;
  cursor: pointer;
`;

interface Props {
  /** パスワード */
  readonly password: string;
  /** パスワード入力をした */
  readonly onChange?: React.ChangeEventHandler<HTMLInputElement>;
  /** キーダウンをした */
  readonly onKeyDownPassword: (
    e: React.KeyboardEvent<HTMLInputElement>,
  ) => void;
  /** 送信を押した */
  readonly onClick: () => void;
}

/**
 * PDFビューアーのパスワードフォーム
 */
const PDFPasswordForm: React.FC<Props> = (p) => {
  return (
    <Wrapper className="viewer-pdf-view-area-wrapper-password-form-wrapper">
      <Title className="viewer-pdf-view-area-wrapper-password-form-title">
        {i18next.t("viewer.passwordForm.title")}
      </Title>
      <SubTitle className="viewer-pdf-view-area-wrapper-password-form-subtitle">
        {i18next.t("viewer.passwordForm.subTitle")}
      </SubTitle>
      <Input
        className="viewer-pdf-view-area-wrapper-password-form-input"
        type="password"
        value={p.password}
        onChange={p.onChange}
        onKeyDown={p.onKeyDownPassword}
        placeholder={i18next.t("viewer.passwordForm.placeholder")}
        autoComplete="none"
      />
      <Button
        className="viewer-pdf-view-area-wrapper-password-form-button"
        onClick={p.onClick}
      >
        {i18next.t("commons.actions.send")}
      </Button>
    </Wrapper>
  );
};

export default PDFPasswordForm;
