import { css } from "styled-components";

const color = {
  text: "#191914",
  trBackground: "#fafafa",
  border: "#cccccc",
};

/**
 * 明細テーブルのデザイン
 */
export const reportItemTableStyle = css`
  .table-component {
    border-color: ${color.border};
    color: ${color.text};
  }
  .thead tr {
    background: ${color.trBackground};
  }
  .tbody tr {
    border-color: ${color.border};
    td {
      border-color: ${color.border};
    }
  }
  td:has(#report-item-action-group-button) {
    overflow: visible;
    position: relative;
    .dropdown {
      width: 100%;
      height: 100%;
    }
  }
  i.fa-recycle {
    color: #008d97;
  }
  .alert-border {
    margin-top: 0px;
    margin-bottom: 0px;
    border: 0;
    border-top: 1px solid #eeeeee;
  }
  ul.dropdown-menu {
    top: 0; !important;
    left: 45px; !important;
  }
  ul.pagination {
    margin: 15px 0;

    > li > span, > li > a {
      margin-right: 8px;
      border-radius: 4px;
      background-color: #AEAEAE;
      border: none;
      color: #FFFFFF;
      &:hover, &:focus, &:active {
        opacity: 0.7;
      }
    }

    > li:last-child > span, > li:last-child > a {
      margin-right: 0;
    }

    > .active > span, > .active > a {
      background-color: ${color.text};
      border: none;

      &:hover, &:focus, &:active {
        opacity: 1;
      }
    }
  }
`;
