import React, { PureComponent } from 'react';
import SimpleModal, { ButtonProps } from 'components/SimpleModal';
import i18next from 'i18n';

interface Props {
  show: boolean;
  close: () => void;
  onSendBack: () => void;
  onError?: (e: Error) => void;
}

export default class SendBackConfirmationModal extends PureComponent<Props> {
  constructor(props: Props) {
    super(props);

    this.handleSendBack = this.handleSendBack.bind(this);
  }

  private handleSendBack(): void {
    const { close, onSendBack, onError } = this.props;
    try {
      onSendBack();
      close();
    } catch (e) {
      if (onError) {
        onError(e as Error);
      }
    }
  }

  render(): JSX.Element {
    const { close, show, children } = this.props;
    const buttons: ButtonProps[] = [
      { color: 'default', content: i18next.t('commons.actions.cancel'), onClick: close },
      { color: 'danger', content: i18next.t('paperless.sendBackings.actions.sendBack'), onClick: this.handleSendBack },
    ];
    return (
      <SimpleModal
        show={ show }
        close={ close }
        title={ i18next.t('paperless.sendBackings.messages.confirmSendBack') }
        buttons={ buttons }
      >
        { children }
      </SimpleModal>
    );
  }
}
