import BootstrapCard from "components/BootstrapCard";
import PropTypes from "prop-types";
import React, { PureComponent } from "react";
import { connect } from "react-redux";
import * as actions from "../actions/reportHistories";
import ReportHistoryTable from "../components/ReportHistoryTable";
import mapStateToProps from "../selectors/reportHistory";

export class ReportHistoryCard extends PureComponent {
  render() {
    return (
      <BootstrapCard title="編集履歴">
        <ReportHistoryTable
          entryForms={this.props.entryForms}
          histories={this.props.histories.transactionHistories}
          transaction={this.props.transaction}
          selectTransaction={this.props.selectTransaction}
        />
      </BootstrapCard>
    );
  }
}

ReportHistoryCard.defaultProps = {
  entryForms: [],
  histories: {
    reportHistories: [],
    transactionHistories: [],
  },
};

ReportHistoryCard.propTypes = {
  entryForms: PropTypes.array.isRequired,
  histories: PropTypes.shape({
    reportHistories: PropTypes.array.isRequired,
    transactionHistories: PropTypes.array.isRequired,
  }).isRequired,
  transaction: PropTypes.object,
};

function mapDispatchToProps(dispatch, ownProps) {
  return {
    selectTransaction(transaction, entryForm) {
      return dispatch(actions.selectTransaction(transaction, entryForm));
    },
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(ReportHistoryCard);
