import React from 'react';

interface Props {
  disabled: boolean;
  value: string[];
  onChange: (currentValue: string[], selection: string, e: React.ChangeEvent<HTMLInputElement>) => void;
  label: string;
}

/**
 * 汎用フォームのチェックボックス
 */
export const FormItemCheckbox: React.FC<Props> = React.memo(({
  disabled, value, onChange, label,
}) => {
  return (
    <div className="checkbox">
      <label>
        <input
          disabled={ disabled }
          type='checkbox'
          checked={ value.length > 0 }
          onChange={ onChange.bind(null, value, label) }
        />
        { label }
      </label>
    </div>
  );
});
