import InfiniteScrollerWithPagination from "components/InfiniteScrollerWithPagination";
import { isNil } from "lodash";
import React, { useCallback, useState } from "react";
import { ListGroupItem } from "react-bootstrap";

interface ActionBoxProps {
  className: string;
  isMultiSelectable: boolean;
  isSelected: boolean;
  onSelectItem: () => void;
}

const ActionBox = (props: ActionBoxProps): React.ReactElement => {
  const { className, isMultiSelectable, isSelected, onSelectItem } = props;

  let iconClass = "";

  if (isMultiSelectable) {
    iconClass = isSelected ? "fa-check-square" : "fa-square";
  } else {
    iconClass = isSelected ? "fa-dot-circle" : "fa-circle";
  }

  return (
    <div className={className}>
      <i className={`far fa-fw fa-lg ${iconClass}`} onClick={onSelectItem}></i>
    </div>
  );
};

interface InfiniteSelectorWithPaginationProps<T, U> {
  responseKey: string;
  isMultiSelectable: boolean;
  options: T[];
  selection: T[] | T | null | undefined;
  formatOption: (option: T) => React.ReactElement;
  fetchOptions: (offset: number, limit: number) => Promise<U>;
  onSelect: (
    item: T,
    isSelected: boolean,
    nextSelection: T[] | T | null,
  ) => void;
  onOptionsChange: (options: T[], totalCount: number | null) => void;
  totalCount: number | null;
  style?: React.CSSProperties;
  maxHeight?: number;
  rowHeight: number;
  batchSize: number;
  children: (
    renderSelector: React.ReactElement,
    callbacks,
  ) => React.ReactElement;
  itemDisabledAttributeName?: string;
}

interface FetchItems<T> {
  resultOptions: T[];
  count: number;
}

const MAX_HEIGHT = 428; // 一覧の要素のmax-height

const InfiniteSelectorWithPagination = <
  T extends { id: string; isSelected?: boolean },
  U extends { count: number },
>({
  maxHeight = MAX_HEIGHT,
  ...props
}: InfiniteSelectorWithPaginationProps<T, U>): React.ReactElement => {
  const [disabledSearch, setDisabledSearch] = useState(false);

  const normalizeOptions = (): T[] => {
    const selection = props.selection || [];
    const selectionMap = {};

    if (props.isMultiSelectable) {
      (selection as T[]).forEach((value) => {
        selectionMap[value.id] = true;
      });
    } else if (!isNil(selection)) {
      selectionMap[(selection as T).id] = true;
    }

    return props.options.map((option) => ({
      ...option,
      isSelected: selectionMap[option.id] || false,
    }));
  };

  const options = normalizeOptions();

  const buildSelection = (value: T, isSelected: boolean): T[] | T | null => {
    if (props.isMultiSelectable) {
      const listSelected = (props.selection || []) as T[];

      if (isSelected) {
        return [...listSelected, value];
      }

      return listSelected.filter((x) => x.id !== value.id);
    }

    return isSelected ? value : null;
  };

  const handleOptionSelect = (item: T): void => {
    if (!item) return;

    const isSelected = !item.isSelected;
    const nextSelection = buildSelection(item, isSelected);

    props.onSelect(item, isSelected, nextSelection);
  };

  const fetchOptions = useCallback(
    async (
      offset: number,
      limit: number,
      isSearch = false,
    ): Promise<FetchItems<T>> => {
      const response = await props.fetchOptions(offset, limit);
      const responseOptions = response[props.responseKey];
      const optionsCount = response.count || responseOptions.length;
      const resultOptions = isSearch
        ? responseOptions
        : [...props.options, ...responseOptions];

      return { resultOptions, count: optionsCount };
    },
    [props.fetchOptions, props.responseKey, props.options],
  );

  const onFetchingOptions = useCallback(async () => {
    if (!disabledSearch) {
      setDisabledSearch(true);

      await fetchOptions(0, props.batchSize, true).then(
        ({ resultOptions, count }) => {
          props.onOptionsChange(resultOptions, count);
          setDisabledSearch(false);
        },
      );
    }
  }, [disabledSearch, fetchOptions, props]);

  const buildStyle = (
    option: T,
    style: React.CSSProperties,
  ): React.CSSProperties => {
    if (props.itemDisabledAttributeName === undefined) return style;

    const disabled = option && option[props.itemDisabledAttributeName];
    if (disabled) {
      return {
        ...style,
        backgroundColor: "lightgray",
      };
    }
    return style;
  };

  const renderOption = (option: T, { style }): React.ReactElement => {
    return (
      <ListGroupItem
        className="infinite-selector-item"
        style={buildStyle(option, style)}
        onClick={(): void => handleOptionSelect(option)}
      >
        <div className="infinite-selector-item__label">
          {props.formatOption(option)}
        </div>

        {option && (
          <ActionBox
            className="infinite-selector-item__btn-group"
            isMultiSelectable={props.isMultiSelectable}
            isSelected={option.isSelected ?? false}
            onSelectItem={(): void => handleOptionSelect(option)}
          />
        )}
      </ListGroupItem>
    );
  };

  return props.children(
    props.totalCount === 0 ? (
      <></>
    ) : (
      <InfiniteScrollerWithPagination<T>
        style={props.style || {}}
        totalCount={props.totalCount}
        height={Math.min(maxHeight, props.rowHeight * props.options.length)}
        itemHeight={props.rowHeight}
        items={options}
        batchSize={props.batchSize}
        fetchItems={fetchOptions}
        onItemsChange={props.onOptionsChange}
        renderItem={renderOption}
      />
    ),
    { onFetchingOptions },
  );
};

export default InfiniteSelectorWithPagination;
