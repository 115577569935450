import EmbeddedFlash from 'components/embedded_flash';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import i18next from 'i18n';

export default class XlsExportForm extends Component {
  constructor(props) {
    super(props);
    this.onExportXls = this.onExportXls.bind(this);
  }

  onExportXls() {
    if (this.props.reportId) {
      window.location.href = `/reports/${this.props.reportId}/xls`;
    } else if (this.props.preReportId) {
      window.location.href = `/pre_reports/${this.props.preReportId}/xls`;
    }
    this.onExport();
  }

  onExport() {
    this.props.onExport();
  }

  render() {
    return (
      <div className='row'>
        <EmbeddedFlash
          ref='embeddedFlash'
          style={ { padding: '0px 15px 15px' } }
        />
        { this.renderContent() }
      </div>
    );
  }

  renderContent() {
    return (
      <div className='form-horizontal xls-export'>
        <div className='form-group'>
          <div className='col-sm-6'>
            <button
              className='btn btn-outline btn-success export-btn'
              onClick={ this.onExportXls }>{ i18next.t('exports.inputs.downloadXls') }</button>
          </div>
        </div>
      </div>
    );
  }
}

XlsExportForm.defaultProps = {
  onExport: () => null,
};

XlsExportForm.propTypes = {
  reportId: PropTypes.string,
  preReportId: PropTypes.string,
  onExport: PropTypes.func,
};
