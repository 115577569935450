import React from 'react';
import Select from 'react-select';
import i18next from 'i18n';
import styled from 'styled-components';
import {
  Alert,
  ControlLabel,
  FormGroup,
  HelpBlock,
} from 'react-bootstrap';
import {
  Controller,
  FieldErrors,
  useFormContext,
  useWatch,
} from 'react-hook-form';
import { ExportFormat } from 'utilities/api/models/accountingDataScheduledExports';
import { RequiredIcon } from '../../../../components/icons/RequiredIcon';

interface Props {
  name: string;
  processing: boolean;
  exportFormats: ExportFormat[];
}

const FormGroupView = styled(FormGroup)`
  .form-control:focus {
    outline: none;
    box-shadow: none;
    border: 2px solid #004acc;
    background-color: #ffffff;
  }
  &.has-error div[class$="-control"] {
    border-color: #d9534f;
    box-shadow: none;
  }
  &.has-error .control-label {
    color: #d9534f;
  }
  &.has-error .help-block {
    color: #d9534f;
  }
`;

type ValidationState = 'error' | 'warning' | null | undefined;

const validationState = (error: FieldErrors | undefined): ValidationState => {
  return error ? 'error' : null;
};

export const ExportFormatSelectField: React.FC<Props> = ({
  name,
  processing,
  exportFormats,
}) => {
  const { control, getValues } = useFormContext();
  const watchedEnableAccountingDataCreation = useWatch({
    control,
    name: 'enableAccountingDataCreation',
    defaultValue: true,
  });

  if (processing) {
    return (<div>{ i18next.t('accountingDataScheduledExports.tasks.forms.export.exportFormat.loading') }</div>);
  }

  if (exportFormats.length === 0) {
    return (<Alert bsStyle='danger'>{ i18next.t('accountingDataScheduledExports.tasks.forms.export.exportFormat.notFound') }</Alert>);
  }

  const defaultTargetReportType = getValues('targetReportType');
  const filteredExportFormats = defaultTargetReportType === 'is_report'
    ? exportFormats.filter((format: ExportFormat) => format.jobName === 'accounting')
    : exportFormats.filter((format: ExportFormat) => format.jobName === 'preAccounting');

  return (
    <Controller
      name={ name }
      rules={ watchedEnableAccountingDataCreation ? { required: i18next.t<string>('accountingDataScheduledExports.tasks.forms.export.exportFormat.required') } : {} }
      control={ control }
      shouldUnregister={ true }
      render={ ({ field: { onChange, value, ref: inputRef }, fieldState: { error } }): React.ReactElement => {
        return (
          <FormGroupView validationState={ validationState(error) }>
            <ControlLabel>
              { i18next.t('accountingDataScheduledExports.tasks.forms.export.exportFormat.label') } <RequiredIcon />
            </ControlLabel>
            <Select
              inputRef={ inputRef }
              options={ filteredExportFormats }
              value={ exportFormats?.find((c) => c.id === value?.id) }
              getOptionLabel={ (v: ExportFormat): string => v.name }
              getOptionValue={ (v: ExportFormat): string => v.id }
              onChange={ onChange }
              isClearable
            />
            { error && <HelpBlock>{ error.message }</HelpBlock> }
          </FormGroupView>
        );
      } }
    />
  );
};
