import * as ActionTypes from '../actions/memberApprovalFlowSearchActions';

export const initialSearchParamState = {
  userNameOrEmail: '',
  department: {},
  includeChildDepartment: false,
  onlyMainDepartment: false,
  openDepartmentsDetail: false,
  expanding: [],
  expandingKey: 0,
  approvalFlow: null,
  isUnassignedApprovalFlow: false,
  requestType: {
    id: '',
    name: '',
  },
  postIds: [],
  postIsUnassigned: false,
  lastSearchedConditions: {
    approvalFlowId: '',
    departmentId: '',
    departmentScope: false,
    isSearched: false,
    isUnassignedApprovalFlow: false,
    name: '',
    onlyMainDepartment: false,
    postIds: [],
    postIsUnassigned: false,
    requestTypeId: '',
    searchText: '',
  },
};

export const initialState = {
  isSearchBoxOpen: true,
  isLoading: false,
  departments: [],
  approvalFlowNames: {
    total: [],
    current: [],
  },
  requestTypes: [],
  isPostFormOpen: false,
  posts: [{}],
  individualFlowInfo: {},
  isFlowInfoModalOpen: false,
  flowInfoModal: {
    type: {},
    flow: {},
  },
  ...initialSearchParamState,
};

export const memberApprovalFlowSearch = (state = initialState, action) => {
  switch (action.type) {
    case ActionTypes.TOGGLE_SEARCH_BOX: {
      return {
        ...state,
        isSearchBoxOpen: !state.isSearchBoxOpen,
      };
    }
    case ActionTypes.TOGGLE_LOAD_STATUS: {
      return {
        ...state,
        isLoading: action.value,
      };
    }
    case ActionTypes.SET_USER_NAME_OR_EMAIL: {
      return {
        ...state,
        userNameOrEmail: action.value,
      };
    }
    case ActionTypes.SELECT_DEPARTMENT_SUGGESTION: {
      return {
        ...state,
        department: { ...action.data },
      };
    }
    case ActionTypes.SET_DEPARTMENTS: {
      return {
        ...state,
        departments: [...action.data],
      };
    }
    case ActionTypes.SET_POSTS_FORM: {
      return {
        ...state,
        isPostFormOpen: action.value,
      };
    }
    case ActionTypes.SET_POST_IDS: {
      return {
        ...state,
        postIds: action.payload,
      };
    }
    case ActionTypes.SET_POST_UNASSIGNED: {
      return {
        ...state,
        postIsUnassigned: action.payload,
      };
    }
    case ActionTypes.SET_POSTS: {
      return {
        ...state,
        posts: [...action.payload],
      };
    }
    case ActionTypes.SET_REQUEST_TYPE: {
      return {
        ...state,
        requestType: {
          id: action.id,
          name: action.name,
        },
      };
    }
    case ActionTypes.SET_REQUEST_TYPES: {
      return {
        ...state,
        requestTypes: [...action.data],
      };
    }
    case ActionTypes.SELECT_APPROVAL_FLOW: {
      return {
        ...state,
        approvalFlow: action.payload.approvalFlow,
      };
    }
    case ActionTypes.SET_INDIVIDUAL_FLOW_INFO: {
      return {
        ...state,
        individualFlowInfo: action.data,
      };
    }
    case ActionTypes.SET_IS_FLOW_INFO_MODAL_OPEN: {
      return {
        ...state,
        isFlowInfoModalOpen: action.data,
      };
    }
    case ActionTypes.SET_FLOW_INFO_MODAL: {
      return {
        ...state,
        flowInfoModal: {
          type: action.data.type,
          flow: action.data.flow,
        },
      };
    }
    case ActionTypes.SET_CONDITION_BOX_VALUE: {
      return {
        ...state,
        [action.key]: action.value,
      };
    }
    case ActionTypes.SET_LAST_SEARCHED_CONDITIONS: {
      return {
        ...state,
        lastSearchedConditions: {
          approvalFlowId: action.approvalFlow?.id || '',
          departmentId: action.departmentId,
          departmentScope: action.departmentScope,
          isSearched: action.isSearched,
          isUnassignedApprovalFlow: action.isUnassignedApprovalFlow,
          name: action.name,
          onlyMainDepartment: action.onlyMainDepartment,
          postIds: action.postIds,
          postIsUnassigned: action.postIsUnassigned,
          requestTypeId: action.requestTypeId,
          searchText: action.searchText,
        },
      };
    }
    case ActionTypes.SET_INITIAL_SEARCH_PARAMS: {
      return {
        ...state,
        ...initialSearchParamState,
      };
    }
    case ActionTypes.SET_EXPANDING: {
      return {
        ...state,
        expanding: action.expanding,
        expandingKey: action.expandingKey,
      };
    }
    default:
      return state;
  }
};

export default memberApprovalFlowSearch;
