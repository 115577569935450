import OriginalReceiptCard from '../components/cards/OriginalReceiptCard';
import React from 'react';
import selector from '../selectors/originalReceiptDetailsSelector';
import { connect } from 'react-redux';

/**
 * 原本の詳細表示ページ用のコンテナ
 *
 * @overview
 *   現状, このコンテナはペーパーレス対応の会社の従業員のみが見ることを前提としている
 *   そのため isPaperlessWorker は強制的に false とする
 *   また, 企業名とストレージコードは意味のない値になるため伏せる
 */
const OriginalReceiptDetailsContainer = (props) => {
  return (
    <OriginalReceiptCard
      isPaperlessWorker={ false }
      originalReceipt={ props.originalReceipt }
      dataLoadStatus={ props.dataLoadStatus }
      hiddenElements={ ['companyName', 'storageCode', 'originalFilename'] } />
  );
};

export default connect(
  selector,
)(OriginalReceiptDetailsContainer);
