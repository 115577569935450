import i18next from "i18n";
import React from "react";
import RequestStateButton from "./RequestStateButton";
import { Props } from "./Types";

/** 「仮払差額返金済みにする」ボタン */
const TemporaryPaymentRefundButton: React.FC<Props> = ({
  requestState,
  onClick,
}) => {
  return (
    <RequestStateButton
      buttonJobType="recordTemporaryPaymentRefund"
      buttonTitle={i18next.t("commons.actions.recordTemporaryPaymentRefund")}
      buttonClassName="btn btn-block btn-accent"
      iconClassName="fa fa-left fa-check-square"
      requestState={requestState}
      onClick={onClick}
    />
  );
};

export default TemporaryPaymentRefundButton;
