import ApproverForm from 'applications/approval_flows/approver_form';
import EmbeddedFlash from 'components/embedded_flash';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import ToggleButton from 'components/toggle_button';
import i18next from 'i18n';
import { Modal } from 'react-bootstrap';

export default class RequestModal extends Component {
  onSubmit() {
    this.props.onSubmit(this.refs.approverForm.getApprovals(), this.onError.bind(this));
  }

  onError(error) {
    this.refs.embeddedFlash.clear(() => {
      this.refs.embeddedFlash.error(error);
    });
    this.refs.button.activate();
  }


  render() {
    const {
      approvalFlow, title, modalTitle, comment,
    } = this.props;
    return (
      <Modal
        show={ this.props.showModal }
        onHide={ this.props.closeModal }>
        <Modal.Header closeButton>
          <Modal.Title>{ modalTitle }</Modal.Title>
        </Modal.Header>
        <EmbeddedFlash
          ref='embeddedFlash'
          style={ { padding: '10px 12px 22px' } }
        />
        <Modal.Body bsClass="request-modal">
          <div className="card request-page">
            <table className="table table-bordered border-side">
              <tbody>
                <tr className="row">
                  <td className="col-sm-2">申請名</td>
                  <td className="col-sm-10">{ title }</td>
                </tr>
                <tr className="row">
                  <td className="col-sm-2">コメント</td>
                  <td className="col-sm-10">
                    <div className="form-group">
                      <input
                        type="text"
                        placeholder={ i18next.t('reports.requests.enterComment') }
                        value={ comment }
                        onChange={ (e) => this.props.onCommentChange(e.target.value) }
                        className="form-control"/>
                    </div>
                  </td>
                </tr>
                <tr className="row">
                  <td className="col-sm-2">承認フロー</td>
                  <td className="col-sm-10">
                    {
                      approvalFlow ? (
                        <ApproverForm
                          ref="approverForm"
                          approvalFlow={ approvalFlow }
                          isEdit={ false } />
                      ) : null
                    }
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <div className='text-left'>
            <ToggleButton
              ref='button'
              className='btn btn-accent col-sm-6 col-sm-offset-3'
              onClick={ this.onSubmit.bind(this) }>
              { i18next.t('commons.actions.sendReport') }
            </ToggleButton>
          </div>
        </Modal.Footer>
      </Modal>
    );
  }
}

RequestModal.propTypes = {
  showModal: PropTypes.bool.isRequired,
  approvalFlow: PropTypes.object,
  comment: PropTypes.string,
  title: PropTypes.string,
  modalTitle: PropTypes.string.isRequired,
  onCommentChange: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  closeModal: PropTypes.func.isRequired,
};
