import React from 'react';
import styled from 'styled-components';
import { isNull } from 'lodash';
import { useHooks } from './hooks';

interface Props {
  /** 現在の申請書ID */
  readonly currentId: string;
}

const NeighborLinkView = styled.div`
  display: flex;
  gap: 8px;
  align-items: center;
  color: rgb(78, 78, 78);
  justify-content: center;
`;

const LinkView = styled.a`
  font-size: 1.75rem;
  color: rgb(78, 78, 78);
`;

const ResultView = styled.div`
  white-space: nowrap;
`;

/**
 * 現在表示中の申請書の前後の申請書へのリンクを表示します。
 * URLから検索条件を取得してリンクを生成しています。
 */
export const NeighborLinks: React.FC<Props> = ({
  currentId,
}) => {
  const {
    count,
    current,
    prevLink,
    nextLink,
    processing,
    hasNeighbors,
  } = useHooks(currentId);

  if (!hasNeighbors) {
    return null;
  }
  if (processing) {
    return <NeighborLinkView><i className='fa fas fa-spinner' /></NeighborLinkView>;
  }

  return (
    isNull(current)
      ?  <></>
      : <NeighborLinkView>
        { prevLink && <LinkView href={ prevLink }><i className='fas fa-chevron-circle-left' /></LinkView> }
        <ResultView>検索結果 { current }/{ count }件</ResultView>
        { nextLink && <LinkView href={ nextLink }><i className='fas fa-chevron-circle-right' /></LinkView> }
      </NeighborLinkView>
  );
};
