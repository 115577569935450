
import flash from 'utilities/flash';
import i18n from "i18next";
import { ApproveJob } from "utilities/api_payment_requests/models/ApproveJob";
import { RequestsSearchApplicationResponse } from 'utilities/api/responses/requests/searchResults/application';
import { RequestsSearchPreReportResponse } from 'utilities/api/responses/requests/searchResults/preReport';
import { RequestsSearchReportResponse } from 'utilities/api/responses/requests/searchResults/report';
import { RequestsSearchResult } from 'utilities/api/responses/requests';
import { getMessageFromResponse } from "utilities/Utils";
import { useApproveJobRepository } from 'applications/requests/hooks//useApproveJobRepository';
import { useApproveJobs } from './useApproveJobs';
import {
  useCallback, useEffect, useMemo, useRef, useState,
} from 'react';
import { useIsWindowActive } from '../../payment_requests/requests/hooks/useIsWindowActive';
import { useReportStatus } from './useReportStatus';

interface IUseBulkApproval {
  readonly inProgressCreateApproveJobs: boolean;
  readonly selectedCount: number;
  readonly selectedIds: string[];
  readonly disabledBulkApprovalButton: boolean;
  readonly unapprovableReportIds: string[];
  readonly isEnableReportBulkApprove: boolean;
  readonly bulkApprove: () => Promise<void>;
  readonly onSelect: (row: RequestsSearchResult, isSelected: boolean) => void;
  readonly onSelectAll: (rows: RequestsSearchResult[], isSelected: boolean) => void;
}

interface BulkApprovalProps {
  readonly resetTable: () => void;
  readonly type: 'application' | 'report';
  readonly isApprovingPage: boolean;
  readonly requests: (RequestsSearchApplicationResponse | RequestsSearchReportResponse | RequestsSearchPreReportResponse)[];
}

/**
 * 一括承認のロジック
 */
export const useBulkApproval = ({
  resetTable,
  type,
  requests,
  isApprovingPage,
}: BulkApprovalProps): IUseBulkApproval => {
  const [inProgressCreateApproveJobs, setinProgressCreateApproveJobs] = useState<boolean>(false);
  const [selectedRows, setSelectedRows] = useState<string[]>([]);
  const [selectedIds, setSelectedIds] = useState<string[]>([]);
  const approveJobs = useRef<ApproveJob[]>([]);

  const {
    createApproveJobs,
    fetchApproveJobs,
  } = useApproveJobRepository();

  const isEnableReportBulkApprove: boolean = userPreferences.preference.enableReportBulkApprove && isApprovingPage;

  /**
   * テーブルを初期状態にする
   */
  const reset = useCallback(() => {
    resetTable();
    setSelectedIds([]);
    setSelectedRows([]);
  }, [resetTable]);

  const isWindowActive = useIsWindowActive();

  const subscribingRequestIds: string[] = useMemo<string[]>(() => {
    // 経費精算
    if (type === 'report') {
      return (requests as (RequestsSearchReportResponse | RequestsSearchPreReportResponse)[]).filter((request) => request?.statusForApprover === '承認待ち').map((request) => {
        if (request?.remainingApprovals && Array.isArray(request?.remainingApprovals)) {
          return request?.remainingApprovals[0]?.id;
        }
        return '';
      }).filter((request) => request !== '');
    }
    // 汎用申請
    if (type === 'application') {
      return (requests as RequestsSearchApplicationResponse[]).filter((request) => request?.statusForApprover === '承認待ち').map((request) => {
        if (request?.request?.remainingApproval && Array.isArray(request?.request?.remainingApproval)) {
          return request?.request?.remainingApproval[0]?.id;
        }
        return '';
      }).filter((request) => request !== '');
    }
    return [];
  }, [requests, type]);

  const { subscribe, unsubscribe } = useApproveJobs(subscribingRequestIds, fetchApproveJobs, isWindowActive);

  const ApproveJobsSubscriber = useCallback((newStateApproveJobs: ApproveJob[]): void => {
    const processingApproveJobIds = approveJobs.current.filter((approveJob) => !approveJob.completedAt).map((approveJob) => approveJob.id);
    const existUpdatedProcessingApproveJob = newStateApproveJobs.some((approveJob) => processingApproveJobIds.includes(approveJob.id) && !!approveJob.completedAt);
    if (existUpdatedProcessingApproveJob) reset();

    const jobIds = newStateApproveJobs.reduce((acc, job) => (acc.includes(job.id) ? acc : acc.concat(job.id)), approveJobs.current.map((job) => job.id));
    approveJobs.current = jobIds.map((id) => newStateApproveJobs.find((job) => job.id === id) || approveJobs.current.find((job) => job.id === id)).filter((job): job is NonNullable<ApproveJob> => !!job);
  }, [approveJobs, reset]);

  useEffect(() => {
    subscribe(ApproveJobsSubscriber);
    return (): void => unsubscribe(ApproveJobsSubscriber);
  }, [ApproveJobsSubscriber, subscribe, unsubscribe]);

  const bulkApprove = useCallback(async () => {
    setinProgressCreateApproveJobs(true);
    try {
      await createApproveJobs(selectedRows);
      flash.success(i18n.t('paymentRequests.approveJobs.message.bulkCreate'));
      reset();
      setinProgressCreateApproveJobs(false);
    } catch (e) {
      flash.error(getMessageFromResponse(e as TypeError));
      setinProgressCreateApproveJobs(false);
    }
  }, [createApproveJobs, reset, selectedRows]);

  /**
   * 選択: 行のチェックボックスを押した
   */
  const onSelect = useCallback((row: RequestsSearchResult, isSelected: boolean): void => {
    const selectedRowList = selectedRows;
    const selectedIdList = selectedIds;

    // 経費精算
    if (type === 'report') {
      if (isSelected) {
        selectedRowList.push((row as RequestsSearchReportResponse)?.remainingApprovals[0]?.id);
        selectedIdList.push(row?.id);
      } else {
        const index = selectedRowList.indexOf((row as RequestsSearchReportResponse)?.remainingApprovals[0]?.id);
        selectedRowList.splice(index, 1);

        const indexSelectedId = selectedIdList.indexOf(row?.id);
        selectedIdList.splice(indexSelectedId, 1);
      }
    }

    // 汎用申請
    if (type === 'application') {
      if (isSelected) {
        selectedRowList.push((row as RequestsSearchApplicationResponse)?.request?.remainingApproval[0]?.id || '');
        selectedIdList.push(row?.id);
      } else {
        const index = selectedRowList.indexOf((row as RequestsSearchApplicationResponse)?.request?.remainingApproval[0]?.id || '');
        selectedRowList.splice(index, 1);

        const indexSelectedId = selectedIdList.indexOf(row?.id);
        selectedIdList.splice(indexSelectedId, 1);
      }
    }

    setSelectedIds([...selectedIdList]);
    setSelectedRows([...selectedRowList]);
  }, [selectedIds, selectedRows, type]);

  /**
   * 全選択: ヘッダーのチェックボックスを押した
   */
  const onSelectAll = useCallback((rows: RequestsSearchResult[], isSelected: boolean): void => {
    if (isSelected) {
      // 経費精算
      if (type === 'report') {
        setSelectedRows((selectedRowList) => selectedRowList.concat((rows as RequestsSearchReportResponse[]).map((item) => item?.remainingApprovals[0]?.id)));
      }

      // 汎用申請
      if (type === 'application') {
        setSelectedRows((selectedRowList) => selectedRowList.concat((rows as RequestsSearchApplicationResponse[]).map((item) => item?.request?.remainingApproval[0]?.id || '')));
      }

      setSelectedIds((selectedIdList) => selectedIdList.concat(rows.map((item) => item?.id)));
    } else {
      // 経費精算
      if (type === 'report') {
        setSelectedRows((selectedRowList) => selectedRowList.filter((item) => !(rows as RequestsSearchReportResponse[]).some((row) => row?.remainingApprovals[0]?.id === item)));
      }

      // 汎用申請
      if (type === 'application') {
        setSelectedRows((selectedRowList) => selectedRowList.filter((item) => !(rows as RequestsSearchApplicationResponse[]).some((row) => row?.request?.remainingApproval[0]?.id === item)));
      }

      setSelectedIds((selectedIdList) => selectedIdList.filter((item) => !rows.some((row) => {
        return row?.id === item;
      })));
    }
  }, [type]);

  const selectedCount = useMemo((): number => selectedRows.length, [selectedRows]);

  const disabledBulkApprovalButton = useMemo((): boolean => selectedCount === 0, [selectedCount]);

  const { unapprovableReports } = useReportStatus();

  return {
    inProgressCreateApproveJobs,
    selectedCount,
    selectedIds,
    disabledBulkApprovalButton,
    isEnableReportBulkApprove,
    unapprovableReportIds: unapprovableReports(requests),
    bulkApprove,
    onSelect,
    onSelectAll,
  };
};
