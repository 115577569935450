import React from 'react';
import styled from 'styled-components';

const RequiredIconView = styled.span`
  color: red;
  font-weight: 400;
`;

export const RequiredIcon: React.FC = () => {
  return <RequiredIconView>*</RequiredIconView>;
};
