import React from 'react';
import { ApprovalStep } from 'utilities/api/models/ApprovalStepsForReject';
import { Popover as BsPopover,  OverlayTrigger } from 'react-bootstrap';

interface Props {
  ellipsis: string;
  style?: React.CSSProperties,
  selectedStep: ApprovalStep
}

const Ellipsis = ({ ellipsis, style = {}, selectedStep }: Props):JSX.Element => {
  return (<span>... <OverlayTrigger
    trigger={ ['hover'] }
    placement={ 'bottom' }
    overlay={
      <BsPopover id='description' style={ { minWidth: '400px' } }>
        {
          selectedStep.approvers.map((approver, aIndex) => (
            <span className='subject' key={ `approver-${aIndex}` }>
              { approver.type === 'group' && <i className="fas fa-user-friends"></i> }
              { approver.name }
              { aIndex < selectedStep.approvers.length - 1 && ', ' }
            </span>
          ))
        }
      </BsPopover>
      }
    >
    <span style={ style }>{ellipsis}</span>
  </OverlayTrigger></span>);
};

export default Ellipsis;
