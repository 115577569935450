import i18next from "i18n";
import React from "react";
import { Alert } from "react-bootstrap";
import styled from "styled-components";
import Formatter from "utilities/formatter";
import * as Types from "../types";

type StyleProps = {
  /** テーブルレイアウト */
  tableStyle: Types.TableStyle;
};

interface ComponentProps {
  /** 分割項目別金額 */
  amountBySeparators: Types.AmountBySeparator[];
  /** 分割前の経費の合計金額 */
  totalAmount: number;
  /** 分割先の経費の合計金額 */
  totalSeparatedAmount: number;
  /** 分割前後の合計金額の差額メッセージ */
  diffMessage: string | null;
}

export type Props = StyleProps & ComponentProps;

// AmountsBySeparatorEditorと整列するためtableでレイアウト
const TotalAmountDisplayView = styled.div<StyleProps>`
  table {
    width: ${(props): string => props.tableStyle.tableWidth};
  }
  .price-col {
    width: ${(props): string => props.tableStyle.secondColWidth};
    text-align: right;
  }
  .null-col {
    width: ${(props): string => props.tableStyle.thirdColWidth};
  }
  .original-expense-row {
    font-size: 16px;
    font-weight: bold;
    .title-col {
      padding: 0 5px;
      border-bottom: 1px solid gray;
    }
    .price-col {
      padding: 0 17px;
      border-bottom: 1px solid gray;
    }
  }
  .split-expense-row {
    font-size: 14px;
    color: gray;
    .title-col {
      padding: 8px 5px;
    }
    .price-col {
      padding: 8px 17px;
    }
    .error {
      color: red;
    }
  }
  .diff-message-area {
    padding-right: 62px;
    height: 30px;
    text-align: right;
    .alert {
      font-size: 12px;
      padding: 4px;
      display: inline;
    }
  }
`;

/** 分割前後の合計金額表示 */
export const TotalAmountDisplay: React.FC<Props> = ({
  amountBySeparators,
  totalAmount,
  totalSeparatedAmount,
  diffMessage,
  tableStyle,
}) => {
  return (
    <TotalAmountDisplayView
      tableStyle={tableStyle}
      className="split-modal-total-amount-display"
    >
      <table>
        <tbody>
          <tr className="original-expense-row">
            <td className="title-col" colSpan={tableStyle.firstColSpan}>
              {i18next.t(
                "expenses.split.totalAmountsDisplay.totalAmountBeforeSplit",
              )}
            </td>
            <td className="price-col">{Formatter.amount(totalAmount)}</td>
            <td className="null-col"></td>
          </tr>
          <tr className="split-expense-row">
            <td className="title-col" colSpan={tableStyle.firstColSpan}>
              {i18next.t(
                "expenses.split.totalAmountsDisplay.totalAmountAfterSplit",
                {
                  splitCount: amountBySeparators.length,
                },
              )}
            </td>
            <td className="price-col">
              {Formatter.amount(totalSeparatedAmount)}
            </td>
            <td className="null-col"></td>
          </tr>
        </tbody>
      </table>
      <div className="diff-message-area">
        {totalAmount !== totalSeparatedAmount && diffMessage && (
          <Alert bsStyle="danger" className="alert">
            {diffMessage}
          </Alert>
        )}
      </div>
    </TotalAmountDisplayView>
  );
};
