import Clearable from 'components/fields/Clearable';
import FieldWithInputModal from '../FieldWithInputModal';
import React, { FC, useCallback } from 'react';
import i18next from 'i18n';
import { Supplier as SupplierOption } from 'utilities/api/models/JournalEntries/Supplier';
import { SupplierSelector } from './SupplierSelector';
import { text as formatText } from 'utilities/formatter';

export interface Props {
  className: string;
  isMultiSelectable: boolean;
  isStatic: boolean;
  placeholder?: string;
  value: null | SupplierOption | SupplierOption[];
  onSelect: (value: SupplierOption | SupplierOption[]) => void;
}

function formatMultiSelectableText(value: SupplierOption[], emptyText: string | null): JSX.Element | null | string {
  if (value.length > 0) {
    return formatText(value.map((v) => v.name || '').join(', '), emptyText);
  }

  return formatText(null, emptyText);
}

function formatValue(
  value: null | SupplierOption | SupplierOption[],
  placeholder?: string,
): JSX.Element | null | string {
  const emptyText = placeholder || null;

  if (value == null) {
    return formatText(null, emptyText);
  }

  if (value instanceof Array) {
    return formatMultiSelectableText(value, emptyText);
  }

  return formatText(value.name || '', emptyText);
}

/** 従業員入力フィールド
 * モーダルを用いて従業員を選択する
 */
export const SupplierFieldWithInputModal: FC<Props> = (props) => {
  const {
    isMultiSelectable, isStatic, placeholder, value, onSelect,
  } = props;

  const handleClear = useCallback((): void => { onSelect([]); }, [onSelect]);

  const renderField = useCallback(({ className, onClick }): JSX.Element => {
    if (isStatic) {
      return (
        <span className={ `${className} form-control-static`  }>
          { formatValue(value, placeholder) }
        </span>);
    }

    return (
      <Clearable className={ `${className} form-control` }
        onClick={ onClick }
        onClear={ handleClear }
      >
        { formatValue(value, placeholder) }
        <i className='caret' />
      </Clearable>
    );
  }, [handleClear, isStatic, placeholder, value]);

  const renderModalBody = useCallback((): JSX.Element => {
    return (
      <SupplierSelector
        isMultiSelectable={ isMultiSelectable }
        onSelect={ onSelect }
        value={ value }
      />
    );
  }, [isMultiSelectable, onSelect, value]);

  return (
    <FieldWithInputModal
      className={ props.className }
      isStatic={ props.isStatic }
      renderField={ renderField }
      renderModalBody={ renderModalBody }
      modalTitle={ i18next.t('paymentRequests.suppliers.actions.selectSupplier') }
      modalSize='md'
    />
  );
};
