import PropTypes from 'prop-types';
import companionType from './companion';
import routeType from './route';

const preTransactionType = {
  id:             PropTypes.string,
  key:            PropTypes.string,
  amount:         PropTypes.number,
  comment:        PropTypes.string,
  transactedAt:   PropTypes.string,
  editable:       PropTypes.bool,
  skipCompanions: PropTypes.bool,
  categoryName:   PropTypes.string,
  route:          PropTypes.shape(PropTypes.shape(routeType)),
  companions:     PropTypes.arrayOf(PropTypes.shape(companionType)),
};

export default preTransactionType;
