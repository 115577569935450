import { DepartmentPostRelationshipSelectorDepartment, DepartmentPostRelationshipSelectorPost, DepartmentPostRelationshipSelectorSelectedDepartmentPost } from "./domain";
import { fetchDepartments } from "./fetch";
import { useCallback, useEffect, useState } from "react";

interface UseReturn {
  /** 部署一覧 */
  readonly departments: DepartmentPostRelationshipSelectorDepartment[];
  /** 検索テキスト */
  readonly searchText: string;
  /** 更に部署一覧を読み込むことができるか */
  readonly canReadMore: boolean;
  /** 検索する場合のコールバック */
  readonly onSearch: (text: string) => void;
  /** 更に部署一覧を読み込む場合のコールバック */
  readonly onReadMore: () => void;
  /** 検索テキスト変更時のコールバック */
  readonly onChangeSearchText: (text: string) => void;
  /** 検索テキストクリア時のコールバック */
  readonly onClearSearchText: () => void;
  /** 部署の展開状態を変更する場合のコールバック */
  readonly onToggleExpandDepartment: (department: DepartmentPostRelationshipSelectorDepartment) => void;
  /** 部署役職の選択状態を変更する場合のコールバック */
  readonly onSelectDepartmentPost: (department: DepartmentPostRelationshipSelectorDepartment, post: DepartmentPostRelationshipSelectorPost, checked: boolean) => void;
}

/**
 * 部署役職の選択状態を更新する
 * @param departments 部署一覧
 * @param department 部署
 * @param post 役職
 * @param checked 選択状態
 * @returns 部署一覧
 */
const updateDepartmentPostChecked = (
  departments: DepartmentPostRelationshipSelectorDepartment[],
  department: DepartmentPostRelationshipSelectorDepartment,
  post: DepartmentPostRelationshipSelectorPost,
  checked: boolean,
): DepartmentPostRelationshipSelectorDepartment[] => {
  return departments.map((d) => {
    if (d.id === department.id) {
      const newPosts = d.posts.map((p) => {
        if (p.postId === post.postId) {
          return { ...p, isChecked: checked };
        }
        return p;
      });
      return { ...d, posts: newPosts };
    }
    return d;
  });
};

/**
 * 部署役職選択コンポーネントのHooks
 * @param defaultPosts 部署に紐づく役職がない場合のデフォルトの役職一覧
 * @param selectedDepartmentPostRelationships 選択済みの部署役職一覧
 * @param onSelectDepartmentPostRelationship 部署役職選択時のコールバック
 * @param onDeselectDepartmentPostRelationship 部署役職選択解除時のコールバック
 * @returns 部署役職選択コンポーネントのHooks
 */
export const useHooks = (
  defaultPosts: DepartmentPostRelationshipSelectorPost[],
  selectedDepartmentPostRelationships: DepartmentPostRelationshipSelectorSelectedDepartmentPost[],
  onSelectDepartmentPostRelationship: (departmentPostRelationship: DepartmentPostRelationshipSelectorSelectedDepartmentPost) => void,
  onDeselectDepartmentPostRelationship: (departmentPostRelationship: DepartmentPostRelationshipSelectorSelectedDepartmentPost) => void,
): UseReturn => {
  /** 検索結果の部署一覧 */
  const [departments, setDepartments] = useState<DepartmentPostRelationshipSelectorDepartment[]>([]);
  /** 検索テキスト */
  const [searchText, setSearchText] = useState('');
  /** 最大取得件数 */
  const [limit, setLimit] = useState(100);
  /** オフセット */
  const [offset, setOffset] = useState(0);
  /** 部署の件数 */
  const [count, setCount] = useState(0);
  /** 更に読み込み可能か */
  const [canReadMore, setCanReadMore] = useState(false);

  /** 検索テキスト変更 */
  const onChangeSearchText = useCallback((text: string) => setSearchText(text), []);
  /** 検索テキストクリア */
  const onClearSearchText = useCallback(() => setSearchText(''), []);
  /** 検索 */
  const onSearch = useCallback((text: string) => {
    const fetch = async (): Promise<void> => {
      const { data, pagination } = await fetchDepartments(text, 100, 0, defaultPosts, selectedDepartmentPostRelationships);
      setLimit(pagination.limit);
      setOffset(pagination.offset);
      setCount(pagination.count);
      setDepartments(data);
    };

    fetch();
  }, [defaultPosts, selectedDepartmentPostRelationships]);

  /** 追加読み込み */
  const onReadMore = useCallback(() => {
    const fetch = async (): Promise<void> => {
      const { data, pagination } = await fetchDepartments(searchText, limit, offset + limit, defaultPosts, selectedDepartmentPostRelationships);
      setLimit(pagination.limit);
      setOffset(pagination.offset);
      setCount(pagination.count);
      setDepartments([...departments, ...data]);
    };
    fetch();
  }, [departments, limit, offset, searchText, defaultPosts, selectedDepartmentPostRelationships]);

  /** 部署の開閉 */
  const onToggleExpandDepartment = useCallback((selectedDepartment: DepartmentPostRelationshipSelectorDepartment) => {
    const newDepartments = departments.map((department) => {
      if (department.id === selectedDepartment.id) {
        return { ...department, isChecked: !department.isChecked };
      }
      return department;
    });
    setDepartments(newDepartments);
  }, [departments]);

  /** 部署役職の選択 */
  const onSelectDepartmentPost = useCallback((department: DepartmentPostRelationshipSelectorDepartment, post: DepartmentPostRelationshipSelectorPost, checked: boolean) => {
    const relationship = {
      id: department.id,
      name: `${department.name} (${post.name})`,
      postId: post.postId,
      type: 'department_post_relationship',
    };

    if (checked) {
      onSelectDepartmentPostRelationship(relationship);
    } else {
      onDeselectDepartmentPostRelationship(relationship);
    }

    setDepartments(updateDepartmentPostChecked(departments, department, post, checked));
  }, [departments, onSelectDepartmentPostRelationship, onDeselectDepartmentPostRelationship]);

  /** 更に読み込み可能かどうかを更新 */
  useEffect(() => {
    setCanReadMore(offset + limit < count);
  }, [defaultPosts, offset, limit, count]);

  /** 初期読み込み */
  useEffect(() => {
    const fetch = async (): Promise<void> => {
      const { data, pagination } = await fetchDepartments('', 100, 0, defaultPosts, selectedDepartmentPostRelationships);
      setLimit(pagination.limit);
      setOffset(pagination.offset);
      setCount(pagination.count);
      setDepartments(data);
    };

    fetch();

    return (): void => {
      setDepartments([]);
      setLimit(100);
      setOffset(0);
      setCount(0);
      setCanReadMore(false);
      setSearchText('');
    };
  }, [defaultPosts]);

  return {
    departments,
    searchText,
    canReadMore,
    onSearch,
    onReadMore,
    onChangeSearchText,
    onClearSearchText,
    onToggleExpandDepartment,
    onSelectDepartmentPost,
  };
};
