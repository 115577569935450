import i18next from "i18next";
import React, { FC } from "react";
import ExportForm from "../../components/ExportForm";
import { useHooks } from "./hooks";

interface Props {
  readonly onExportCsv: () => void;
}

export const ReportExportForm: FC<Props> = (props) => {
  const { formData } = useHooks();

  return (
    <ExportForm
      data={formData}
      onRequestExport={props.onExportCsv}
      requestButtonTitle={i18next.t("reports.modals.actions.createCsv")}
    />
  );
};
