import ApiBase from './api_base';

const api = new ApiBase('v1', { relativePath: 'security', authPath: '/api/v1/auth' })
  .resources('/subdomains', { only: ['index', 'create'] })
  .put('/subdomain', { to :  'subdomains.update' })
  .destroy('/subdomain', { to: 'subdomains.destroy' })
  .get('/allowed_authentication_methods', { to: 'allowedAuthenticationMethods.index' })
  .put('/allowed_authentication_methods', { to: 'allowedAuthenticationMethods.update' })
  .get('/subdomains/available', { to: 'subdomains.available' })
  .get('/saml/settings', { to: 'samlSettings.index' })
  .post('/saml/settings', { to: 'samlSettings.create' })
  .get('/saml/settings/{id}', { to: 'samlSettings.show' })
  .put('/saml/settings/{id}', { to: 'samlSettings.update' })
  .put('/saml/settings/{id}/enable', { to: 'samlSettings.enable' })
  .put('/saml/settings/{id}/disable', { to: 'samlSettings.disable' })
  .put('/saml/settings/{id}/skip_ip_restriction/enable', { to: 'skipIpRestriction.enable' })
  .put('/saml/settings/{id}/skip_ip_restriction/disable', { to: 'skipIpRestriction.disable' })
  .destroy('/saml/settings/{id}', { to: 'samlSettings.destroy' })
  .post('/saml/settings/{id}/identity_provider', { to: 'identityProvider.create' })
  .destroy('/saml/settings/{id}/identity_provider', { to: 'identityProvider.destroy' })
  .get('/login_options', { to: 'signInOptions.index' })
  .get('/saml/accounts', { to: 'samlAccounts.index' })
  .post('/saml/accounts', { to: 'samlAccounts.create' })
  .put('/saml/accounts/{id}', { to: 'samlAccounts.update' })
  .destroy('/saml/accounts/{id}', { to: 'samlAccounts.destroy' })
  .post('/saml/accounts/bulk_unlink', { to: 'samlAccounts.bulkUnlink' })
  .get('/saml/accounts/import_jobs/upload_url', { to: 'samlAccounts.importJobs.upload' })
  .post('/saml/accounts/import_jobs', { to: 'samlAccounts.importJobs.create' })
  .get('/saml/accounts/export_jobs', { to: 'samlAccounts.exportJobs.index' })
  .post('/saml/accounts/export_jobs', { to: 'samlAccounts.exportJobs.create' })
  .build();
export default api;
