import { useHooks } from "components/renewaled_ui/FileViewer/Viewer/PDF/hooks/hooks";
import { useHooksHand } from "components/renewaled_ui/FileViewer/Viewer/PDF/hooks/hooksHand";
import { usePDFHooks } from "components/renewaled_ui/FileViewer/Viewer/PDF/hooks/hooksPDF";
import { useHooksScale } from "components/renewaled_ui/FileViewer/Viewer/PDF/hooks/hooksScale";
import { useHooksToolBar } from "components/renewaled_ui/FileViewer/Viewer/PDF/hooks/hooksToolBar";
import PDFPasswordForm from "components/renewaled_ui/FileViewer/Viewer/PDF/parts/PasswordForm";
import { PDFTextLayerWrapperCSS } from "components/renewaled_ui/FileViewer/Viewer/PDF/parts/TextLayer";
import PDFToolBar from "components/renewaled_ui/FileViewer/Viewer/PDF/parts/ToolBar";
import ViewerMessage from "components/renewaled_ui/FileViewer/Viewer/PDF/parts/ViewerMessage";
import { ViewerFooter } from "components/renewaled_ui/FileViewer/ViewerFooter";
import {
  DeleteEventHandler,
  DropType,
  FileLoadStatus,
  FileType,
  LoadingStatus,
} from "components/types/attachedFiles.types";
import React from "react";
import { DropzoneInputProps, DropzoneRootProps } from "react-dropzone";
import styled from "styled-components";

/**
 * 縦横サイズのCSSの値を整形し返す
 */
function normalizeSize(isAuto: boolean, size?: number | string): string {
  if (typeof size === "number") return `${size}px`;
  if (size) return size;
  return isAuto ? "auto" : "100%";
}

const Wrapper = styled.div`
  position: relative;
  overflow: hidden;
  background: #69696c;
  display: flex;
  flex-flow: column;
  // PDFビューアーテキストレイヤー
  ${PDFTextLayerWrapperCSS}

  .textLayer span {
    cursor: unset;
  }
`;
const PDFWrapper = styled.div`
  height: 100%;
`;
const Content = styled.div`
  flex-grow: 1;
  overflow: scroll;
`;
const PasswordForm = styled.div`
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  width: 100%;
`;
const Loading = styled.div`
  position: absolute;
  display: flex;
  justify-content: center;
  width: 100%;
  height: 100%;
  align-items: center;
  z-index: 100;
`;
const LoadingIcon = styled.i`
  opacity: 0.4;
  font-size: 50px;
`;
const CanvasArea = styled.div`
  position: relative;
  margin: auto;
`;

interface Props {
  readonly fileName?: string;
  readonly rootProps: DropzoneRootProps;
  readonly className?: string;
  readonly isDroppable?: boolean;
  readonly isDropzoneVisible?: boolean;
  readonly selectableTypes?: FileType[];
  readonly dropType?: DropType;
  readonly isShowFileSelect: boolean;
  /** PDFファイルURL */
  readonly url: string;
  readonly downloadLink?: string;
  readonly isShowSubBar: boolean;
  readonly isDeletable: boolean;
  readonly isTransformable: boolean;
  readonly isShowToolBar: boolean;
  readonly width?: number | string;
  readonly height?: number | string;
  readonly loadStatus: FileLoadStatus;
  readonly showPlaceholderDescription?: boolean;
  /** ファイルinput */
  readonly getInputProps: (
    props?: DropzoneInputProps | undefined,
  ) => DropzoneInputProps;
  readonly onClickChangeViewer: (
    e: React.MouseEvent<HTMLElement, MouseEvent>,
  ) => void;
  /** フッター */
  readonly footer?: string | JSX.Element;
  /** 削除をする */
  readonly onDelete?: DeleteEventHandler;
  readonly onClick?: (e: React.SyntheticEvent, state: LoadingStatus) => void;
}

/**
 * PDFビューアー
 */
const PDFViewer: React.FC<Props> = (p) => {
  const {
    currentPage,
    rotate,
    isHand,
    pageNum,
    fileName,
    canvasAreaRef,
    wrapperRef,
    scrollAreaRef,
    onClickRotateTool,
    onClickDeleteTool,
    onClickHandTool,
    changePageNum,
    changeFileName,
    changeCurrentPage,
  } = useHooksToolBar(p.onDelete);
  const {
    scaleSize,
    isExpandHeight,
    onClickExpandTool,
    onClickScaleDownTool,
    onClickScaleUpTool,
  } = useHooksScale(wrapperRef);
  const {
    isLoading,
    needsPassword,
    password,
    onChangePassword,
    onClickPassword,
    onKeyDownPassword,
    onScroll,
  } = usePDFHooks(
    canvasAreaRef,
    wrapperRef,
    scrollAreaRef,
    p.url,
    scaleSize,
    rotate,
    isExpandHeight,
    currentPage,
    pageNum,
    changePageNum,
    changeFileName,
    changeCurrentPage,
    p.fileName,
  );
  const { onMouseDown, onMouseUp, onMouseMove, onMouseLeave } = useHooksHand(
    isHand,
    scrollAreaRef,
  );
  const {
    isLoaded,
    canRotate,
    canHand,
    canUpload,
    canTransform,
    canDownload,
    canDelete,
    canOnClickView,
    isShowToolBar,
    onClickView,
  } = useHooks(
    isLoading,
    needsPassword,
    p.isShowToolBar,
    p.isShowFileSelect,
    p.loadStatus,
    p.isDeletable,
    p.isTransformable,
    p.downloadLink,
    p.onClick,
  );

  /**
   * wrapperのクラス名
   */
  const wrapperClassName = `viewer-pdf-view-wrapper${
    p.className ? ` ${p.className}` : ""
  }`;

  /**
   * マウスカーソルのタイプ
   */
  const cursor = (): string => {
    if (canOnClickView) return "pointer";
    return isHand ? "grab" : "auto";
  };

  return (
    <Wrapper
      className={wrapperClassName}
      style={{
        height: normalizeSize(false, p.height),
        width: normalizeSize(true, p.width),
      }}
    >
      {(isLoading || p.loadStatus.state === "loading") && (
        <Loading className="viewer-pdf-view-wrapper-loading">
          <LoadingIcon className="fa fa-spinner fa-spin viewer-pdf-view-wrapper-loading-icon" />
        </Loading>
      )}

      {isShowToolBar() && (
        <PDFToolBar
          rootProps={p.rootProps}
          currentPage={currentPage}
          maxPage={pageNum}
          scaleSize={scaleSize}
          getInputProps={p.getInputProps}
          isHand={isHand}
          isExpandHeight={isExpandHeight}
          fileName={fileName || ""}
          downloadLink={p.downloadLink || ""}
          rotate={rotate}
          isShowSubBar={p.isShowSubBar}
          canRotate={canRotate}
          canHand={canHand}
          canUpload={canUpload}
          canTransform={canTransform}
          canDownload={canDownload}
          canDelete={canDelete}
          onClickRotate={onClickRotateTool}
          onClickDelete={onClickDeleteTool}
          onClickExpand={onClickExpandTool}
          onClickHand={onClickHandTool}
          onClickScaleDown={onClickScaleDownTool}
          onClickScaleUp={onClickScaleUpTool}
          onClickChangeViewer={p.onClickChangeViewer}
        />
      )}
      <Content
        className="viewer-pdf-view-wrapper-content"
        ref={scrollAreaRef}
        onScroll={onScroll}
      >
        {
          // ファイルを読み込めなかった場合
          !isLoaded && (
            <ViewerMessage
              loadStatus={p.loadStatus}
              showPlaceholderDescription={p.showPlaceholderDescription}
            />
          )
        }

        <PDFWrapper
          className="viewer-pdf-view-area-wrapper"
          ref={wrapperRef}
          onMouseDown={onMouseDown}
          onMouseUp={onMouseUp}
          onMouseMove={onMouseMove}
          onMouseLeave={onMouseLeave}
        >
          {/* パスワード付きPDFならフォームを表示 */}
          {needsPassword && (
            <PasswordForm className="viewer-pdf-view-area-wrapper-password">
              <PDFPasswordForm
                password={password}
                onChange={onChangePassword}
                onKeyDownPassword={onKeyDownPassword}
                onClick={onClickPassword}
              />
            </PasswordForm>
          )}
          {/* canvasAreaRefにpdfページ数分のcanvasが追加される */}
          <CanvasArea
            className="viewer-pdf-view-area-wrapper-canvas-area"
            ref={canvasAreaRef}
            onClick={onClickView}
            style={{
              cursor: cursor(),
              userSelect: isHand ? "none" : "auto",
            }}
          />
        </PDFWrapper>

        {!!p.footer && (
          <ViewerFooter className="viewer-dropzone-view-footer">
            {p.footer}
          </ViewerFooter>
        )}
      </Content>
    </Wrapper>
  );
};

export default PDFViewer;
