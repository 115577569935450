import { PDFDocumentProxy, PDFPageProxy } from "pdfjs-dist";
import * as pdfjs from "pdfjs-dist/legacy/build/pdf";
import pdfWorkerEntry from "pdfjs-dist/legacy/build/pdf.worker.entry";
import {
  getFilenameFromUrl,
  PageViewport,
} from "pdfjs-dist/lib/display/display_utils.js";
import { RefObject, useCallback, useEffect, useState } from "react";
import { usePDFdomHooks } from "./hooksPDFdom";
import { PDFPageProxys, WrapperSize } from "./interface";

/** 読み込むページ数 */
const PAGING_SIZE = 10;
/** ページングの読み込み領域px */
const PAGE_LOAD_AREA = 2200;

interface ScrollTarget {
  readonly scrollTop: number;
}
interface UseReturn {
  readonly isLoading: boolean;
  readonly needsPassword: boolean;
  readonly password: string;
  readonly onChangePassword: React.ChangeEventHandler<HTMLInputElement>;
  readonly onKeyDownPassword: (
    e: React.KeyboardEvent<HTMLInputElement>,
  ) => void;
  readonly onClickPassword: () => void;
  readonly onScroll: (e: React.UIEvent<HTMLDivElement, UIEvent>) => void;
}

/**
 * PDFビューアー: ロジック
 */
export const usePDFHooks = (
  canvasAreaRef: RefObject<HTMLDivElement>,
  wrapperRef: React.RefObject<HTMLDivElement>,
  scrollAreaRef: React.RefObject<HTMLDivElement>,
  pdfURL: string,
  scalePDF: number,
  rotatePDF: number,
  isExpandHeightPDF: boolean,
  currentPage: number | "",
  maxPagePDF: number,
  changePageNum: (v: number) => void,
  changeFileName: (v: string) => void,
  changeCurrentPage: (v: number | "") => void,
  defaultFileName?: string,
): UseReturn => {
  const { resetPDFArea, createPDFPage, getCanvasStyle, updateCanvasArea } =
    usePDFdomHooks();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [needsPassword, setNeedsPassword] = useState<boolean>(false);
  const [wrapperSize, setWrapperSize] = useState<WrapperSize | null>(null);
  const [passwordForm, setPasswordForm] = useState<string>("");
  const [pageProxys, setPageProxys] = useState<Array<PDFPageProxys>>([]);
  // レンダリング中であるか。二重にレンダリングイベントを発生させない。
  let renderIndexs: number[] = [];
  // 現在のPDFページング番号
  const [pdfPage, setPdfPage] = useState<number>(1);
  // PDF新規ページ読み込み中か
  const [isPDFLoading, setIsPDFLoading] = useState<boolean>(false);
  // PDF表示領域
  const [pdfArea, setPDFArea] = useState<HTMLDivElement | null>(null);
  // PDF情報
  const [pdfDoc, setPDFDoc] = useState<PDFDocumentProxy | null>(null);

  /**
   * 画面比率 画面解像度
   * [NOTE] +1しないとキャンバススケールの問題でPDFがぼやける
   */
  const dpRatio = window.devicePixelRatio + 1;

  /**
   * 読み込むページ数
   */
  const getLoadPageNum = (
    maxPage: number,
    current: number,
    size: number,
  ): number => {
    const remainingNumber = maxPage - current;
    if (remainingNumber <= size) return remainingNumber;
    return size;
  };

  /**
   * キャンバスの縦横サイズ取得
   */
  const getSize = (
    pdfWidth: number,
    pdfHeight: number,
    viewerWidth: number,
    viewerHeight: number,
    scale: number,
    isExpandHeight: boolean,
  ): WrapperSize => {
    // 縦を100%
    if (isExpandHeight) {
      const heightRatio = viewerHeight / (pdfHeight / dpRatio);
      // キャンバスのCSS縦横サイズを設定
      const width = ((pdfWidth * heightRatio) / dpRatio) * scale;
      const height = viewerHeight * scale;

      return {
        width,
        height,
      };
    }

    // 横を100%
    const widthRatio = viewerWidth / (pdfWidth / dpRatio);
    // キャンバスのCSS縦横サイズを設定
    const width = viewerWidth * scale;
    const height = ((pdfHeight * widthRatio) / dpRatio) * scale;

    return {
      width,
      height,
    };
  };

  /**
   * テキストレイヤーの取得
   */
  const getTextLayer = useCallback(
    async (
      pageProxy: PDFPageProxy,
      viewport: PageViewport,
      textLayerInner: HTMLDivElement,
    ): Promise<object> => {
      try {
        const textContent = await pageProxy.getTextContent();
        // テキストレイヤーの描画
        return pdfjs.renderTextLayer({
          textContent,
          container: textLayerInner,
          viewport,
        });
        // レンダリング重複のエラーは通知させない
        // eslint-disable-next-line no-empty
      } catch (e) {
        return {};
      }
    },
    [],
  );

  /**
   * キャンバスの描画
   */
  const setCanvas = useCallback(
    (pageProxyInfos: PDFPageProxys[], viewerSize: WrapperSize): void => {
      if (pageProxyInfos.length === 0) return;
      const renderExist = renderIndexs.length !== 0;
      if (renderExist) return;

      // 全てのPDFページをcanvsに描画
      pageProxyInfos.forEach(async (info, index) => {
        const pageProxy = info.pageProxy;

        // レンダリング開始フラグを立てる
        renderIndexs.push(index);

        const viewport = pageProxy.getViewport({
          scale: dpRatio,
        });
        const size = getSize(
          viewport.width,
          viewport.height,
          viewerSize.width,
          viewerSize.height,
          1,
          false,
        );
        // キャンバス情報の取得
        const canvas = getCanvasStyle(
          info,
          viewport.width,
          viewport.height,
          size,
        );
        const canvasContext = canvas.getContext("2d");
        if (!canvasContext) return;

        const textLayerInner = updateCanvasArea(
          info,
          viewport.width,
          viewport.height,
          canvasContext,
        );
        const textLayer = await getTextLayer(
          pageProxy,
          viewport,
          textLayerInner,
        );

        try {
          // PDFキャンバスに描画する
          const renderContext = {
            canvasContext,
            viewport,
            textLayer,
          };
          // キャンバスの描画
          const renderTask = pageProxy.render(renderContext);
          // eslint-disable-next-line no-underscore-dangle
          renderTask._internalRenderTask.callback = (): void => {
            // 同じcanvasならrenderingフラグを解除
            renderIndexs = renderIndexs.filter((v) => v !== index);
            // ローディング終了
            setIsLoading(false);
            setIsPDFLoading(false);
          };
          await renderTask.promise;
          // レンダリング重複のエラーは通知させない
          // eslint-disable-next-line no-empty
        } catch (e) {
          // ローディング終了
          setIsLoading(false);
          setIsPDFLoading(false);
        }
      });
    },
    [getCanvasStyle, updateCanvasArea, dpRatio, setIsLoading],
  );

  /**
   * 画面リサイズ時の処理
   */
  const resizeWindow = (ref: React.RefObject<HTMLDivElement>): void => {
    const width = ref.current?.clientWidth || 0;
    const height = ref.current?.clientHeight || 0;

    setWrapperSize({
      width,
      height,
    });
  };

  /**
   * ページ情報一覧の取得
   */
  const getPageProxyInfos = useCallback(
    async (
      pdf: PDFDocumentProxy,
      pageNums: number[],
      area: HTMLDivElement,
    ): Promise<PDFPageProxys[]> => {
      const pdfInfos: PDFPageProxys[] = [];
      // eslint-disable-next-line no-restricted-syntax
      for (const pageNum of pageNums) {
        const pageProxy = await pdf.getPage(pageNum);
        pdfInfos.push(createPDFPage(pageNum, area, pageProxy));
      }
      return pdfInfos;
    },
    [],
  );

  /**
   * cMapURLの取得
   */
  const getCMapUrl = useCallback(
    (version: string): string => `/pdf/cmaps-${version}/`,
    [],
  );

  /**
   * キャンバスのリセット
   */
  const resetCanvas = useCallback((): void => {
    // ページ情報のリセット
    setPageProxys([]);
    // 現在のPDFページング番号リセット
    setPdfPage(1);
  }, []);

  /**
   * PDFの読み込み
   */
  const loadPDF = useCallback(
    async (
      url: string,
      password: string,
      area: HTMLDivElement | null,
    ): Promise<void> => {
      if (!area) return;
      setIsLoading(true);
      setIsPDFLoading(true);
      // divの子要素を全て削除
      resetCanvas();

      const config = {
        url,
        cMapUrl: getCMapUrl(pdfjs.version),
        cMapPacked: true,
        password,
      };
      // PDFの読み込み
      const pageProxyInfos = await pdfjs
        .getDocument(config)
        .promise.then(
          async (pdf: PDFDocumentProxy): Promise<PDFPageProxys[]> => {
            const maxPage = pdf.numPages;
            // 読み込めたのでパスワード入力は不要
            setNeedsPassword(false);
            // PDFのページ数の取得
            changePageNum(maxPage);
            // ドキュメントの保持
            setPDFDoc(pdf);

            // PAGING_SIZEまでしか読み込まない
            const loadPageNums = getLoadPageNum(maxPage, 0, PAGING_SIZE);
            // ページ番号一覧の配列を作成
            const pageNums = [...Array(loadPageNums)].map((_, i) => i + 1);
            return await getPageProxyInfos(pdf, pageNums, area);
          },
        )
        .catch((error): [] => {
          setIsLoading(false);
          // パスワード付きPDFの場合
          if (error.name === "PasswordException") {
            setNeedsPassword(true);
            return [];
          }
          // 通知させないエラー
          if (error.name === "UnexpectedResponseException") return [];
          if (error.name === "AbortException") return [];
          if (error.name === "MissingPDFException") return [];
          throw error;
        });
      if (pageProxyInfos.length === 0) return;

      // ページ情報一覧を保持
      setPageProxys(pageProxyInfos);
      // ウィンドウの横幅を変えた時に再描画
      window.addEventListener("resize", () => resizeWindow(wrapperRef));
      // eslint-disable-next-line react-hooks/exhaustive-deps
    },
    [],
  );

  /**
   * 追加PDFページの読み込み
   */
  const addLoadPDF = async (
    page: number,
    area: HTMLDivElement | null,
  ): Promise<void> => {
    if (!area) return;
    if (isPDFLoading) return;
    if (!pdfDoc) return;

    // 現在のページ番号
    const currentPagingNum = PAGING_SIZE * page;
    if (maxPagePDF <= currentPagingNum) return;

    const getInfos = async (): Promise<PDFPageProxys[]> => {
      // ページングの更新
      setPdfPage(page + 1);
      // ページングで読み込み中か
      setIsPDFLoading(true);
      setIsLoading(true);

      // PAGING_SIZEまでしか読み込まない
      const loadPageNums = getLoadPageNum(
        maxPagePDF,
        currentPagingNum,
        PAGING_SIZE,
      );
      // ページ番号一覧の配列を作成
      const pageNums = [...Array(loadPageNums)].map(
        (_, i) => i + currentPagingNum + 1,
      );
      // ページデータ一覧の取得
      return await getPageProxyInfos(pdfDoc, pageNums, area);
    };
    const pageProxyInfos = await getInfos();
    if (pageProxyInfos.length === 0) return;

    // ページ情報一覧を保持
    setPageProxys([...pageProxys, ...pageProxyInfos]);
  };

  /**
   * パスワード入力欄: 入力された
   */
  const onChangePassword = (e: React.ChangeEvent<HTMLInputElement>): void => {
    setPasswordForm(e.target.value || "");
  };

  /**
   * パスワード入力欄: キーダウン
   */
  const onKeyDownPassword = (
    e: React.KeyboardEvent<HTMLInputElement>,
  ): void => {
    if (e.key !== "Enter") return;
    // ボタンがある場合にEnterキーを押すとsubmitされるのを防ぐ
    e.preventDefault();

    // 入力がなければ実行しない
    if (passwordForm === "") return;
    // PDFを再度読み込む
    loadPDF(pdfURL, passwordForm, pdfArea);
  };

  /**
   * パスワード入力欄: 送信を押した
   */
  const onClickPassword = (): void => {
    // PDFを再度読み込む
    loadPDF(pdfURL, passwordForm, pdfArea);
  };

  /**
   * スクロールされた: ツールバーのページ番号を更新する
   */
  const updatePaging = (
    scrollTop: number,
    pdfs: NodeListOf<ChildNode>,
  ): void => {
    // 現在表示されてるページ番号を取得
    const pdfDivs = Array.from(pdfs) as unknown as HTMLDivElement[];
    const currentScrollPage = pdfDivs.reduce((result, target, index) => {
      if (scrollTop >= target.offsetTop) return index + 1;
      return result;
    }, 1);

    // 現在のページを設定する
    if (currentPage !== currentScrollPage) changeCurrentPage(currentScrollPage);
  };

  /**
   * スクロールされた: ツールバーのページ番号を更新する
   */
  const onScroll = (e: React.UIEvent<HTMLDivElement, UIEvent>): void => {
    if (!canvasAreaRef) return;
    // pdfのdiv一覧を取得
    const pdfs = pdfArea?.childNodes;
    // キャンバスに何もなければ実行しない
    if (!pdfs || pdfs.length === 0) return;

    // 現在のスクロール量
    const scrollTop = (e.target as unknown as ScrollTarget)?.scrollTop || 0;

    // ツールバーのページ番号の更新をする
    updatePaging(scrollTop, pdfs);

    // ページの読み込み
    const height = canvasAreaRef.current?.clientHeight;
    if (!height) return;
    // 領域以内になったら新たなページを読み込む
    if (height - scrollTop < PAGE_LOAD_AREA) {
      addLoadPDF(pdfPage, pdfArea);
    }
  };

  /**
   * divの回転と拡大縮小を更新する
   */
  const updateTransform = useCallback(
    (
      info: PDFPageProxys,
      frameSize: WrapperSize,
      scale: number,
      isExpandHeight: boolean,
      rotate: number,
      isVertical: boolean,
    ): void => {
      const pageProxy = info.pageProxy;
      const canvasWrapper = info.canvasWrapper;
      const pageInner = info.pageInner;
      const pageInnerScale = info.pageInnerScale;

      const view = pageProxy.getViewport({
        scale: dpRatio,
      });
      const size = getSize(
        view.width,
        view.height,
        frameSize.width,
        frameSize.height,
        scale,
        isExpandHeight,
      );

      // スケールによってサイズを変更
      const isW = size.width > size.height;
      const ratio = size.width / frameSize.width;
      const top = ((isW ? 1 : -1) * size.width) / 5 / ratio;
      const left = ((isW ? -1 : 1) * size.height) / 7 / ratio;

      // 縦横幅の更新
      canvasWrapper.style.width = isVertical
        ? `${size.width}px`
        : `${size.height}px`;
      canvasWrapper.style.height = isVertical
        ? `${size.height}px`
        : `${size.width}px`;
      // 回転更新
      pageInner.style.transform = `rotate(${rotate}deg)`;
      pageInner.style.marginTop = isVertical ? "0" : `${top}px`;
      pageInner.style.marginLeft = isVertical ? "0" : `${left}px`;
      // スケール更新
      pageInnerScale.style.transform = `scale(${ratio})`;
    },
    [dpRatio],
  );

  /**
   * PDFJSの初期化
   */
  const setUpPDF = (): void => {
    pdfjs.GlobalWorkerOptions.workerSrc = pdfWorkerEntry;
  };

  /**
   * キャンバスの描画
   */
  useEffect(() => {
    if (!wrapperSize) return;
    setCanvas(pageProxys, wrapperSize);
  }, [pageProxys, wrapperSize, setCanvas]);

  /**
   * 回転と拡大縮小
   */
  useEffect(() => {
    if (pageProxys.length === 0) return;
    if (!wrapperSize) return;
    const isVertical = rotatePDF % 180 === 0;

    const oldHeight = scrollAreaRef.current?.scrollHeight || 0;

    // 全ページの回転と拡大縮小を更新
    pageProxys.forEach((info: PDFPageProxys): void => {
      updateTransform(
        info,
        wrapperSize,
        scalePDF,
        isExpandHeightPDF,
        rotatePDF,
        isVertical,
      );
    });

    const height = scrollAreaRef.current?.scrollHeight || 0;
    // 可変した倍率からスクロール位置を更新
    const ratio = height / oldHeight;

    // 現在のスクロールポジション
    const scrollX = scrollAreaRef.current?.scrollLeft || 0;
    const scrollY = scrollAreaRef.current?.scrollTop || 0;
    const x = ratio * scrollX;
    const y = ratio * scrollY;

    // スクロール位置の更新
    scrollAreaRef.current?.scrollTo(x, y);
  }, [
    pageProxys,
    wrapperSize,
    rotatePDF,
    scalePDF,
    isExpandHeightPDF,
    updateTransform,
  ]);

  /**
   * 表示領域の縦横幅の取得
   */
  useEffect(() => {
    const width = wrapperRef.current?.clientWidth || 0;
    const height = wrapperRef.current?.clientHeight || 0;
    setWrapperSize({
      width,
      height,
    });
  }, [
    wrapperRef,
    wrapperRef.current?.clientWidth,
    wrapperRef.current?.clientHeight,
  ]);

  /**
   * ファイル名の更新
   */
  useEffect(() => {
    if (!pdfURL) return;
    // ファイル名の指定がなければ、URLからファイル名を取得する
    if (!defaultFileName) {
      const fileName = getFilenameFromUrl(pdfURL) || "";
      changeFileName(fileName);
      return;
    }
    changeFileName(defaultFileName);
  }, [defaultFileName, pdfURL, changeFileName]);

  /**
   * PDFの読み込みと描画
   */
  useEffect(() => {
    if (!pdfURL || pdfURL === "") {
      setPageProxys([]);
      return;
    }
    // 描画させるDivの初期化
    const area = resetPDFArea(pdfArea, canvasAreaRef);
    setPDFArea(area);

    // PDFJSの初期化
    setUpPDF();
    // 入力したパスワードをリセット
    setPasswordForm("");
    // PDF読み込み
    loadPDF(pdfURL, "", area);
  }, [pdfURL, loadPDF]);

  return {
    isLoading,
    needsPassword,
    password: passwordForm,
    onChangePassword,
    onKeyDownPassword,
    onClickPassword,
    onScroll,
  };
};
