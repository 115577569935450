import NoStateCheckBox from 'components/NoStateCheckBox';
import React, { useCallback } from 'react';
import styled from 'styled-components';

export interface CheckBoxGroupItem<T> {
  className?: string;
  description?: string;
  disabled?: boolean;
  key: T;
  label: string;
  onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
}

interface Props<T> {
  values: T[];
  checkboxes: CheckBoxGroupItem<T>[];
  disabled?: boolean;
  onChange: (values: T[]) => void;
}

// TODO: 縦横両方指定できるようにしたい（現在横並びだけ）
const CheckBoxGroupView = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;

  margin-top: -6px;
  margin-left: -12px;

  > div {
    margin-top: 6px;
    margin-left: 12px;
  }
`;

function CheckBoxGroup<T extends string | number | boolean>({ onChange, ...props }: Props<T>): React.ReactElement {
  const handleChangeFactory = useCallback((checkbox: CheckBoxGroupItem<T>) => (
    (e: React.ChangeEvent<HTMLInputElement>): void => {
      onChange(
        props.checkboxes
          .map((c) => c.key)
          .filter((key) => (checkbox.key === key ? !!e.target.checked : props.values.indexOf(key) >= 0)),
      );

      if (checkbox.onChange) {
        checkbox.onChange(e);
      }
    }
  ), [onChange, props.checkboxes, props.values]);

  return (
    <CheckBoxGroupView className='checkbox-group'>
      {
        props.checkboxes.map((checkbox, i) => (
          <NoStateCheckBox
            checked={ props.values.indexOf(checkbox.key) >= 0 }
            className={ checkbox.className ?? 'squared checkbox-accent' }
            disabled={ props.disabled || checkbox.disabled }
            helpText={ checkbox.description }
            key={ i }
            label={ checkbox.label }
            onChange={ handleChangeFactory(checkbox) }
          />
        ))
      }
    </CheckBoxGroupView>
  );
}

export default CheckBoxGroup;
