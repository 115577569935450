import Api from 'utilities/api';
import React, { useCallback, useState } from 'react';
import flash from 'utilities/flash';
import i18next from 'i18next';
import styled from 'styled-components';
import { Button } from 'components/renewaled_ui/buttons/Button';
import { Modal } from 'react-bootstrap';
import { getMessageFromResponse } from 'utilities/Utils';
import { useMountedRef } from 'hooks';

interface Props {
  onClose: () => void;
  onDeleted: () => void;
  selectedId: string;
  show: boolean;
}

const ModalFooterView = styled(Modal.Footer)`
  display: flex;
  padding: 15px 6px;
  flex-direction: row;
  column-gap: 8px;
  align-items: center;
  justify-content: center;

  button:last-child {
    margin-left: auto;
  }
`;

export const DeleteConfirmModal: React.FC<Props> = ({
  show,
  selectedId,
  onClose,
  onDeleted,
}) => {
  const mountedRef = useMountedRef();
  const [processing, setProcessing] = useState(false);

  const handleSubmit = useCallback(() => {
    if (processing) return;

    setProcessing(true);
    flash.clear();

    const destroyApi = async (): Promise<void> => {
      try {
        const { message } = await Api.accountingDataScheduledExports.fileTransferLocations.destroy({ id: selectedId });
        flash.success(message);
        onDeleted();
      } catch (error) {
        flash.error(getMessageFromResponse(error as Record<string, unknown>));
      } finally {
        if (mountedRef.current) {
          setProcessing(false);
        }
      }
    };
    destroyApi();
  }, [processing, setProcessing, selectedId, onDeleted, mountedRef]);

  return (
    <Modal show={ show } onHide={ onClose }>
      <Modal.Header closeButton>{ i18next.t('accountingDataScheduledExports.fileTransferLocations.deleteConfirmModal.title') }</Modal.Header>
      <Modal.Body>
        { i18next.t('accountingDataScheduledExports.fileTransferLocations.deleteConfirmModal.message1') }<br/>
        { i18next.t('accountingDataScheduledExports.fileTransferLocations.deleteConfirmModal.message2') }
      </Modal.Body>
      <ModalFooterView>
        <Button styleType='danger' onClick={ handleSubmit } disabled={ processing }>
          { i18next.t('commons.actions.delete') }
        </Button>
        <Button styleType='link-secondary' onClick={ onClose } disabled={ processing }>
          { i18next.t('commons.actions.cancel') }
        </Button>
      </ModalFooterView>
    </Modal>
  );
};
