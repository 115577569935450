import React, { useCallback } from 'react';
import { ControlLabel, FormGroup } from 'react-bootstrap';

interface Props {
  dataSets: DataSet[];
  onChange: (genericFieldsValue: GenericFieldsValue[]) => void;
  value: GenericFieldsValue[];
}

type GenericFieldsValue = {
  id: string;
  type: string;
  value: string;
};

export const GenericFieldDataSetFields: React.FC<Props> = ({
  dataSets,
  onChange,
  value,
}) => {
  const handleChagenValue = useCallback((newValue: string, ds: DataSet, type: string) => {
    const genericFields = value ? [...value] : [];
    const dataSetIndex = genericFields.findIndex((gf) => (gf.id === ds.id && gf.type === type));

    if (dataSetIndex === -1) {
      const dataSet = {
        id: ds.id,
        value: newValue,
        type,
      };
      genericFields.push(dataSet);
    } else if (newValue === '') {
      genericFields.splice(dataSetIndex, 1);
    } else {
      genericFields[dataSetIndex].value = newValue;
    }

    onChange(genericFields);
  }, [onChange, value]);

  return (
    <>
      { dataSets.map((ds, i) => (
        <div key={ i }>
          <FormGroup>
            <ControlLabel>{ ds.name }（コード）</ControlLabel>
            <input
              className='form-control'
              value={ value?.find((v) => (v.id === ds.id && v.type === 'code'))?.value || '' }
              onChange={ (e): void => handleChagenValue((e.target as HTMLInputElement).value, ds, 'code') }
            />
          </FormGroup>
          <FormGroup>
            <ControlLabel>{ ds.name }（名称）</ControlLabel>
            <input
              className='form-control'
              value={ value?.find((v) => (v.id === ds.id && v.type === 'name'))?.value || '' }
              onChange={ (e): void => handleChagenValue((e.target as HTMLInputElement).value, ds, 'name') }
           />
          </FormGroup>
        </div>
      )) }
    </>
  );
};
