import { Button } from "components/renewaled_ui/buttons";
import i18next from "i18n";
import isEmpty from "lodash/isEmpty";
import isNil from "lodash/isNil";
import PropTypes from "prop-types";
import React, { PureComponent } from "react";

export default class ConfirmOriginalReceiptButton extends PureComponent {
  render() {
    const { foreside, backside, matchedOriginalReceipt } = this.props;

    return (
      <Button
        small
        styleType="link-primary"
        onClick={this.props.onClickOpenCancelReceiptMatchingModalButton}
        disabled={
          !(
            (!isEmpty(foreside) || !isEmpty(backside)) &&
            !isNil(matchedOriginalReceipt)
          )
        }
        minWidth={0}
      >
        {i18next.t("paperless.expense.confirmOriginalReceipt")}
      </Button>
    );
  }
}

ConfirmOriginalReceiptButton.defaultProps = {
  matchedOriginalReceipt: null,
};

ConfirmOriginalReceiptButton.propTypes = {
  foreside: PropTypes.string,
  backside: PropTypes.string,
  matchedOriginalReceipt: PropTypes.array.isRequired,
  onClickOpenCancelReceiptMatchingModalButton: PropTypes.func.isRequired,
  style: PropTypes.object,
};
