interface DepartmentResponse {
  id: string;
  parentId: string | null;
}

interface WithParent {
  parent?: DepartmentResponse;
}

/** 親部署がある部署にparentをセットして返します */
export function buildDepartmentsWithParent<T extends DepartmentResponse>(departments: T[]): Array<T & WithParent> {
  const departmentsById: { [key: string]: T } = {};

  // 親部署検索用のハッシュを作っておく
  departments.forEach((department) => {
    departmentsById[department.id] = department;
  });

  // 親部署があればparentをセットする
  return departments.map((department) => {
    if (department.parentId) {
      const parent = departmentsById[department.parentId];
      if (parent) {
        return { ...department, parent: { ...parent } };
      }
    }

    return { ...department };
  });
}
