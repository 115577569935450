import isNil from 'lodash/isNil';
import { getIncrementalMatcher } from 'utilities/Utils';

/* ReactAutoSuggestで使用するaction一式を作成する
 *
 * @param { String } initType 初期化時に発行されるactionのtype
 * @param { String } updateType update時に発行されるactionのtype
 * @param { String } setType 入力文字列の更新時に発行されるactionのtype
 * @param { String } selectType select時に発行されるactionのtype
 * @param { Object } methods 自前で用意する関数を定義
 * @returns { Object } updateSuggestions, requestSuggestionsUpdate, setInputValue, selectSuggestionを返す
 */
export default function makeAutoSuggestActions({
  initType, updateType, setType, selectType,
}, getter = (x) => x, methods = {}) {
  let {
    initializer, updater, selector, setter, requestSuggestionsUpdater,
  } = methods;

  /*
   * @param { Array } suggestions 使用する補完候補の全リスト。補完候補はこのリストからフィルタされる
   * @param { Object } params action拡張用のパラメータ。デフォルトのプロパティに追加する
   */
  initializer = initializer || ((suggestions = [], params = {}) => ({ type: initType, data: suggestions, ...params }));

  /*
   * @param { Array } suggestions フィルタ後の補完候補のリスト
   * @param { Object } params action拡張用のパラメータ。デフォルトのプロパティに追加する
   */
  updater = updater || ((suggestions = [], params = {}) => ({ type: updateType, data: suggestions, ...params }));

  /*
   * @param { Any } suggestion 補完候補のリストから選択された値。通常はObjectかString
   * @param { Object } params action拡張用のパラメータ。デフォルトのプロパティに追加する
   */
  selector = selector || ((suggestion, params = {}) => ({ type: selectType, data: suggestion, ...params }));

  /*
   * @param { Array } suggestions フィルタ前の補完候補のリスト
   * @param { String } value 入力文字列。totalをフィルタする際に使用する
   * @param { Object } params action拡張用のパラメータ。デフォルトのプロパティに追加する
   */
  setter = setter || ((suggestions, value = '', params = {}) => {
    return (dispatch, getState) => {
      const setAction = { type: setType, value, ...params };
      dispatch(setAction);

      /*
       * TODO: 該当する選択肢が1つになると、自動的に選択する
       * バックスペースで削除しようとしている時は発火しないようにしたい
       */
    };
  });

  /*
   * @param { Array } suggestions 補完候補全体のリスト
   * @param { String } value 補完候補の絞込に使われる、入力文字列
   * @param { String } reason requestSuggestionsUpdateイベントの発火原因。click, enterなど
   * @param { Object } params action拡張用のパラメータ。デフォルトのプロパティに追加する
   */
  requestSuggestionsUpdater = requestSuggestionsUpdater || ((suggestions, { value, reason }, params = {}) => {
    return (dispatch, getState) => {
      if (reason === 'click' || reason === 'enter' || isNil(value) || value.length === 0) {
        dispatch(updater(suggestions, params));
        return;
      }

      const reg = getIncrementalMatcher(value);
      const filteredSuggestions = suggestions.filter((x) => getter(x).match(reg));
      dispatch(updater(filteredSuggestions, params));
    };
  });

  return {
    /* 補完候補のリストを初期化する */
    initSuggestions: initializer,
    /* 補完候補をフィルタする */
    updateSuggestions: updater,
    /* AutoSuggestのrequestSuggestionsUpdateイベントを処理する */
    requestSuggestionsUpdate: requestSuggestionsUpdater,
    /* 入力文字列を更新する */
    setInputValue: setter,
    /* 補完候補を選択する */
    selectSuggestion: selector,
  };
}
