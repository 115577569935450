// @note 互いのクラスが参照しあっている場合、別ファイルに書くとビルド後の動作時にクラッシュしたため
/* eslint-disable max-classes-per-file */

import { RequestSearchSpecification } from './RequestSearchSpecification';

export abstract class CompositeSpecification implements RequestSearchSpecification {
  abstract isSatisfiedBy(candidate: string): boolean;

  or(other: RequestSearchSpecification): RequestSearchSpecification {
    return new OrSpecification(this, other);
  }
}

/**
 * Specification.or(OtherSpecification) が実行される際の Specification
 * コンストラクタには Specification と OtherSpecification を取る
 */
class OrSpecification extends CompositeSpecification {
  constructor(private leftCondition: RequestSearchSpecification, private rightCondition: RequestSearchSpecification) {
    super();
  }

  isSatisfiedBy(candidate: string): boolean {
    return this.leftCondition.isSatisfiedBy(candidate) || this.rightCondition.isSatisfiedBy(candidate);
  }
}
