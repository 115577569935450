import i18next from "i18n";
import React from "react";
import { OverlayTrigger, Popover } from "react-bootstrap";
import { Expense } from "utilities/api/models";

type Props = Pick<Expense, "isCreatedByAggregation">;

/** カード連携アイコン */
export const FromCardIconWithOverlay: React.FC<Props> = ({
  isCreatedByAggregation,
}) => {
  if (!isCreatedByAggregation) {
    return null;
  }

  const fromCardOverlay = (): JSX.Element => {
    return (
      <Popover id="from-card-popup">
        {i18next.t("transactions.messages.fromCard")}
      </Popover>
    );
  };

  return (
    <OverlayTrigger rootClose placement="left" overlay={fromCardOverlay()}>
      <i className="material-icons">credit_card</i>
    </OverlayTrigger>
  );
};
