/* eslint-disable @typescript-eslint/explicit-module-boundary-types, @typescript-eslint/explicit-function-return-type */

import get from "lodash/get";
import { useCallback, useEffect, useState } from "react";
import Api from "utilities/api";
import { snakecaseKeys } from "utilities/Utils";
import * as Types from "../types";
import { AmountBySeparators } from "../value_objects/AmountBySeparators";

/**
 * 経費分割モーダルを管理するためのhook
 * @param expense 経費情報
 * @param separatorType 分割項目
 * @param show モーダルを表示するか
 * @param close モーダルを閉じる処理
 * @param onSuccess 分割処理成功時に実行されるハンドラ
 * @returns
 * - amountBySeparators 分割先経費情報(value_objectクラスのインスタンス))
 * - totalSeparatedAmount 分割後の合計金額
 * - diffMessage 差額表示
 * - processing 処理中を示すフラグ
 * - errorMessage エラーメッセージ
 * - isSplittableAmountSeparators 経費分割可能かを示すフラグ
 * - showConfirmModal 確認モーダルを表示するか
 * - handleAdd 分割先を追加する処理
 * - handleRemove 分割先を削除する処理
 * - handleChangeAmount 分割先の金額を変更する処理
 * - handleUpdateDiffMessage 分割前後の合計金額の差額を返す処理
 * - handleChangeSeparator 分割先の項目を変更する処理
 * - handleClose モーダルを閉じる処理
 * - handleShowConfirmModal 確認モーダルを開く
 * - handleCloseConfirmModal 確認モーダルを閉じる
 * - handleSplit 分割を実行する処理
 */
export const useSplitModal = (
  expense: Types.Expense,
  separatorType: Types.SeparatorType,
  show: boolean,
  close: () => void,
  onSuccess: (message: string) => void,
) => {
  const [amountBySeparators, setAmountBySeparators] = useState(
    AmountBySeparators.buildAmountBySeparators(separatorType, expense),
  );
  const [totalSeparatedAmount, setTotalSeparatedAmount] = useState(0);
  const [processing, setProcessing] = useState(false);
  const [errorMessage, setErrorMessage] = useState<string | null>(null);
  const [diffMessage, setDiffMessage] = useState<string | null>(null);
  const [isSplittableAmountSeparators, setIsSplittableAmountSeparators] =
    useState(false);
  const [showConfirmModal, setShowConfirmModal] = useState(false);

  // 検証
  useEffect(() => {
    // モーダルが開きamountBySeparatorsが初期化された場合のみ実行
    if (!amountBySeparators) return;

    // 分割先合計金額
    setTotalSeparatedAmount(amountBySeparators.totalAmount);

    // 分割可能か
    setIsSplittableAmountSeparators(
      amountBySeparators.isSplittableAmountSeparators(expense.amount),
    );
  }, [amountBySeparators, expense, show]);

  // AmountBySeparators初期化
  useEffect(() => {
    setAmountBySeparators(
      AmountBySeparators.buildAmountBySeparators(separatorType, expense),
    );
    setDiffMessage(amountBySeparators.diffMessage(expense.amount));
    // setAmountBySeparatorsをdepsに含めると検証が無限ループするので外す。
    // モーダル開閉時のみ動けばよいので問題なし。
  }, [expense, separatorType, show]); // eslint-disable-line react-hooks/exhaustive-deps

  /** モーダルを閉じる */
  const handleClose = useCallback(() => {
    if (!processing) {
      close();
      setErrorMessage(null);
    }
  }, [close, processing, setErrorMessage]);

  /** 分割項目別金額を追加 */
  const handleAdd = useCallback(() => {
    setAmountBySeparators(amountBySeparators.addNewSeprator());
  }, [setAmountBySeparators, amountBySeparators]);

  /** 分割項目別金額を削除 */
  const handleRemove = useCallback(
    (index) => {
      setAmountBySeparators(amountBySeparators.removeSeparator(index));
    },
    [setAmountBySeparators, amountBySeparators],
  );

  /** 分割先の金額を変更 */
  const handleChangeAmount = useCallback(
    (index, amount) => {
      setAmountBySeparators(amountBySeparators.updateAmount(index, amount));
    },
    [setAmountBySeparators, amountBySeparators],
  );

  /** 分割先の項目を変更 */
  const handleChangeSeparator = useCallback(
    (index, separator) => {
      setAmountBySeparators(
        amountBySeparators.updateSeparator(index, separator),
      );
    },
    [setAmountBySeparators, amountBySeparators],
  );

  // 分割元-分割先の合計金額に差額があればメッセージ表示
  const handleUpdateDiffMessage = useCallback(() => {
    setDiffMessage(amountBySeparators.diffMessage(expense.amount));
  }, [setDiffMessage, amountBySeparators, expense]);

  // 分割先の経費の数が変更された場合、差額メッセージを更新する
  useEffect(() => {
    handleUpdateDiffMessage();
    // 通常の入力では差額メッセージを変更しない動作の為、handleUpdateDiffMessageは含めない。
  }, [amountBySeparators.current.length]); // eslint-disable-line react-hooks/exhaustive-deps

  /** 確認モーダルを開く */
  const handleShowConfirmModal = useCallback(() => {
    setShowConfirmModal(true);
  }, [setShowConfirmModal]);

  /** 確認モーダルを閉じる */
  const handleCloseConfirmModal = useCallback(() => {
    setShowConfirmModal(false);
  }, [setShowConfirmModal]);

  /** 分割を実行 */
  const handleSplit = useCallback(async () => {
    setProcessing(true);
    setErrorMessage(null);

    try {
      const input = snakecaseKeys({
        id: expense.id,
        amountsBySeparator: amountBySeparators.current,
      });
      const data = await Api.expenses.split(input);
      handleClose();
      onSuccess(data.message);
    } catch (error) {
      const message = get(error, "responseJSON.message");
      setErrorMessage(message);
    } finally {
      setProcessing(false);
      setShowConfirmModal(false);
    }
  }, [amountBySeparators, expense.id, handleClose, onSuccess]);

  return {
    amountBySeparators,
    totalSeparatedAmount,
    diffMessage,
    processing,
    errorMessage,
    isSplittableAmountSeparators,
    showConfirmModal,
    handleAdd,
    handleRemove,
    handleChangeAmount,
    handleUpdateDiffMessage,
    handleChangeSeparator,
    handleClose,
    handleShowConfirmModal,
    handleCloseConfirmModal,
    handleSplit,
  } as const;
};
