import React from 'react';
import fonts from 'values/fonts';
import i18next from 'i18n';
import styled from 'styled-components';
import { Dropdown, DropdownToggleButton } from 'components/renewaled_ui/Dropdown';

const Wrapper = styled.div`
  padding: 8px 16px;
  font-weight: ${Number(fonts.weight.bold)};
  font-size: 12px;
  color: #616161;
  user-select: none;
`;

interface Props {
  /** 選択してる数 */
  readonly selectedItemsLength: number;
  /** メニュー */
  readonly children: JSX.Element;
}

/**
 * ドロップダウン
 */
export const TableDropdown: React.FC<Props> = (props) => {
  return (
    <Dropdown
      id='size-per-page-drop-down'
      direction='up'
      align='left'
      toggleButton={ (
        <DropdownToggleButton bsRole='toggle' styleType='link-primary' small>
          <span>
            { props.selectedItemsLength } { i18next.t('commons.dataFetchTable.itemsAreSelected') }
          </span>
        </DropdownToggleButton>
      ) }
    >
      <Wrapper>
        { i18next.t('commons.dataFetchTable.selectedItemsMenu') }
      </Wrapper>

      { props.children }
    </Dropdown>
  );
};
