import OriginalReceipt from "applications/paperless/original_receipts/components/forms/OriginalReceipt";
import SimpleModal from "components/SimpleModal";
import i18next from "i18n";
import PropTypes from "prop-types";
import React, { PureComponent } from "react";
import { Modal } from "react-bootstrap";

export default class CancelReceiptMatchingModal extends PureComponent {
  constructor(props) {
    super(props);

    this.onCancel = this.onCancel.bind(this);
  }

  onCancel() {
    this.props.cancelMatching(this.props.originalReceipt.id);
  }

  render() {
    return (
      <div>
        <Modal
          className="original-receipt-modal"
          show={this.props.isCancelReceiptMatchingModalOpen}
          onHide={this.props.closeCancelReceiptMatchingModal}
          bsSize="lg"
        >
          <Modal.Header closeButton>
            <Modal.Title>
              {i18next.t("paperless.expense.cancelMatchingModal.title")}
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            {this.props.originalReceipt ? (
              <div className="row">
                <OriginalReceipt
                  originalReceipt={this.props.originalReceipt}
                  hiddenElements={[
                    "companyName",
                    "storageCode",
                    "post",
                    "report",
                    "matchingStatus",
                    "originalFilename",
                  ]}
                  dataLoadStatus={this.props.dataLoadStatus}
                />
              </div>
            ) : null}
          </Modal.Body>
          <Modal.Footer>
            <button
              className="btn btn-danger col-xs-offset-4 col-xs-4"
              onClick={this.props.openConfirmDetachModal}
            >
              {i18next.t("paperless.expense.cancelMatchingModal.footer.button")}
            </button>
          </Modal.Footer>
        </Modal>
        <SimpleModal
          show={this.props.isConfirmCancelMatchingModalOpen}
          close={this.props.closeConfirmCancelMatchingModal}
          title={i18next.t("paperless.expense.messages.cancelConfirm")}
          buttons={[
            {
              content: i18next.t("commons.actions.cancel"),
              color: "default",
              onClick: this.props.closeConfirmCancelMatchingModal,
            },
            {
              content: i18next.t(
                "paperless.expense.cancelMatchingModal.cancel",
              ),
              color: "danger",
              onClick: this.onCancel,
            },
          ]}
        />
      </div>
    );
  }
}

CancelReceiptMatchingModal.defaultProps = {
  isCancelReceiptMatchingModalOpen: false,
  isConfirmCancelMatchingModalOpen: false,
  originalReceipt: null,
};

CancelReceiptMatchingModal.propTypes = {
  isCancelReceiptMatchingModalOpen: PropTypes.bool.isRequired,
  closeCancelReceiptMatchingModal: PropTypes.func.isRequired,
  isConfirmCancelMatchingModalOpen: PropTypes.bool.isRequired,
  openConfirmDetachModal: PropTypes.func.isRequired,
  closeConfirmCancelMatchingModal: PropTypes.func.isRequired,
  cancelMatching: PropTypes.func.isRequired,
  originalReceipt: PropTypes.object, // 原本画像
  dataLoadStatus: PropTypes.string, // 原本のロード状態
};
