import React, { useMemo } from 'react';
import { IndexComponent as ExpenseIndexComponent } from './IndexComponent';
import { IndexComponent as InvoiceIndexComponent } from 'applications/payment_requests/accounting_data_scheduled_exports/tasks/IndexComponent';
import { Toolbar } from '../components/Toolbar';

interface Props {
  id?: string;
}

export const IndexPage: React.FC<Props> = ({ id }) => {
  const resource = useMemo(() => {
    return new URLSearchParams(window.location.search).get('resource');
  }, []);

  return (
    <div>
      <Toolbar tasks={ true } />
      { userPreferences.preference.useDkRequests && <ExpenseIndexComponent id={ (resource === 'expense' && id) || undefined } /> }
      { userPreferences.preference.usePaymentRequest && <InvoiceIndexComponent id={ (resource === 'invoice' && id) || undefined } /> }
    </div>
  );
};
