import Api from 'utilities/api';
import React, { useCallback, useEffect, useState } from 'react';
import flash from 'utilities/flash';
import styled from 'styled-components';
import {
  CancelButton,
  DeleteConfirmButton,
  EditButton,
  SaveButton,
} from '../components/buttons';
import { ConfirmConnectionButton } from './buttons';
import { DeleteConfirmModal } from './DeleteConfirmModal';
import { FileTransferLocation } from 'utilities/api/models/accountingDataScheduledExports';
import { Form } from './Form';
import { FormProvider, useForm } from 'react-hook-form';
import { LoadingIcon } from '../components/icons/LoadingIcon';
import { ReadOnlyForm } from './ReadOnlyForm';
import { RightDrawer, RightDrawerFooter, RightDrawerHeader } from 'components/renewaled_ui/single_views';
import { getMessageFromResponse, snakecaseKeys } from 'utilities/Utils';
import { useMountedRef } from 'hooks';

interface Props {
  show: boolean;
  selectedId: string;
  onClose: () => void;
  onDeleted: () => void;
  onUpdated: () => void;
}

const FooterToolbarView = styled.div`
  display: flex;
  flex-direction: row;
  width: 100vw;
  column-gap: 8px;

  button {
    align-self: flex-start;
  }
`;

export const DetailDrawer: React.FC<Props> = ({
  show,
  selectedId,
  onDeleted,
  onUpdated,
  onClose,
}) => {
  const mountedRef = useMountedRef();
  const methods = useForm({ mode: 'onChange' });
  const { isDirty, isValid } = methods.formState;

  const [processing, setProcessing] = useState(false);
  const [fileTransferLocation, setFileTransferLocation] = useState<FileTransferLocation | null>(null);
  const [showEditForm, setShowEditForm] = useState<boolean>(false);
  const [showDeleteConfirmModal, setShowDeleteConfirmModal] = useState<boolean>(false);

  const handleShow = useCallback(() => {
    if (!show) return;

    setProcessing(true);
    flash.clear();

    const showApi = async (): Promise<void> => {
      try {
        const data = await Api.accountingDataScheduledExports.fileTransferLocations.show({ id: selectedId });
        setFileTransferLocation(data);
      } catch (error) {
        flash.error(getMessageFromResponse(error as Record<string, unknown>));
        onClose();
      } finally {
        if (mountedRef.current) {
          setProcessing(false);
        }
      }
    };
    showApi();
  }, [show, selectedId, onClose, mountedRef]);

  useEffect(() => {
    if (show) {
      setShowEditForm(false);
      handleShow();
    }

    return (): void => {
      setFileTransferLocation(null);
    };
  }, [show, handleShow, selectedId]);


  const handleUpdate = useCallback((data) => {
    if (processing && !show) return;

    setProcessing(true);
    flash.clear();

    const updateApi = async (): Promise<void> => {
      try {
        const { message } = await Api.accountingDataScheduledExports.fileTransferLocations.update(snakecaseKeys(data));
        flash.success(message);
        setShowEditForm(false);
        handleShow();
        onUpdated();
      } catch (error) {
        flash.error(getMessageFromResponse(error as Record<string, unknown>));
      } finally {
        if (mountedRef.current) {
          setProcessing(false);
        }
      }
    };
    updateApi();
  }, [processing, show, handleShow, onUpdated, mountedRef]);

  const handleDeleted = useCallback(() => {
    setShowDeleteConfirmModal(false);
    onDeleted();
  }, [setShowDeleteConfirmModal, onDeleted]);

  // 接続確認
  const handleCheckConnection = useCallback(() => {
    if (processing && !show) return;

    setProcessing(true);
    flash.clear();

    const checkConnectionApi = async (): Promise<void> => {
      try {
        const data = await Api.accountingDataScheduledExports.fileTransferLocations.checkConnection({ id: selectedId });
        flash.success(data.message);
        handleShow();
      } catch (error) {
        flash.error(getMessageFromResponse(error as Record<string, unknown>));
      } finally {
        if (mountedRef.current) {
          setProcessing(false);
        }
      }
    };
    checkConnectionApi();
  }, [show, processing, setProcessing, selectedId, handleShow, mountedRef]);

  // 編集開始
  const handleStartEdit = useCallback(() => {
    if (!showEditForm) {
      methods.reset({ ...fileTransferLocation });
      setShowEditForm(true);
    }
  }, [methods, showEditForm, fileTransferLocation]);

  const handleCloseModal = useCallback(() => {
    setShowEditForm(false);
    onClose();
  }, [setShowEditForm, onClose]);

  // 編集・詳細表示キャンセル
  const handleCancelEdit = useCallback(() => {
    if (showEditForm) {
      setShowEditForm(false);
    } else {
      onClose();
    }
  }, [showEditForm, setShowEditForm, onClose]);

  const emptyAction = (): void => { /** nothing */ };

  return (
    <>
      <RightDrawer
        width={ 500 }
        show={ show }
        onClose={ onClose }
        onShow={ emptyAction }
        isLoading={ processing }
        overflowY='hidden'
        preventCloseSelector='#IndexPage .tbody .tr,div[role=tooltip]'
      >
        <RightDrawerHeader title={ fileTransferLocation?.name || <LoadingIcon /> } onClose={ handleCloseModal } />
        { !showEditForm && <ReadOnlyForm fileTransferLocation={ fileTransferLocation } /> }
        {
          showEditForm && (
            <FormProvider { ...methods }>
              <Form action='update' />
            </FormProvider>
          )
        }
        {
          userPreferences.isAdmin && <RightDrawerFooter>
            <FooterToolbarView>
              <EditButton onClick={ handleStartEdit } disabled={ processing } show={ !showEditForm }/>
              <ConfirmConnectionButton onClick={ handleCheckConnection } disabled={ processing } show={ !showEditForm }/>
              <DeleteConfirmButton onClick={ (): void => setShowDeleteConfirmModal(true) } disabled={ processing } show={ !showEditForm }/>
              <SaveButton onClick={ methods.handleSubmit(handleUpdate) } processing={ processing } valid={ isDirty && isValid } show={ showEditForm }/>
              <CancelButton onClick={ handleCancelEdit } disabled={ processing } show={ showEditForm }/>
            </FooterToolbarView>
          </RightDrawerFooter>
        }
      </RightDrawer>
      <DeleteConfirmModal
        show={ showDeleteConfirmModal }
        selectedId={ selectedId }
        onClose={ (): void => setShowDeleteConfirmModal(false) }
        onDeleted={ handleDeleted }
      />
    </>
  );
};
