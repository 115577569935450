import DataFetchingTable from "components/DataFetchingTable";
import i18next from "i18n";
import PropTypes from "prop-types";
import React from "react";
import { TableHeaderColumn } from "react-bootstrap-table";
import transactionType from "types/transaction";
import renderReadStatusColumn from "./ReadStatus";
import renderAmountColumn from "./renderAmountColumn";
import renderAuthorNameColumn from "./renderAuthorNameColumn";
import renderCategoryNameColumn from "./renderCategoryNameColumn";
import renderDepartmentNameColumn from "./renderDepartmentNameColumn";
import renderIconsColumn from "./renderIconsColumn";
import renderMemoColumn from "./renderMemoColumn";
import renderReportStatusColumn from "./renderReportStatusColumn";
import renderShopColumn from "./renderShopColumn";
import renderTransactedAtColumn from "./renderTransactedAtColumn";
import { isColumnSelectable, isColumnVisible } from "./utils";

class ExpenseTable extends React.Component {
  render() {
    const handleRowClick = (row) => {
      // 選択中の行は編集中であり、誤操作防止のために、行のクリックイベントを処理しない
      if (this.props.isEditing) {
        return;
      }
      this.props.onRowClick(row);
    };

    const options = {
      noDataText: i18next.t("commons.messages.noDataFound"),
      onRowClick: this.props.isStatic ? () => null : handleRowClick,
    };

    return (
      <DataFetchingTable
        ref="fetchingTable"
        className={this.props.className}
        data={this.props.expenses}
        isLoading={this.props.isLoading}
        options={options}
        sizePerPageList={this.props.sizePerPageList}
        currentPage={this.props.currentPage}
        sizePerPage={this.props.sizePerPage}
        columnSelectable={this.props.columnSelectable}
        fetchData={this.props.fetchExpense}
        onSortChange={this.props.onSortChange}
        onPageChange={this.props.onPageChange}
        onSizePerPageList={this.props.onSizePerPageList}
        selectRow={this.props.selectRow}
        striped={true}
        tableBodyClass="multiline"
      >
        <TableHeaderColumn dataField="id" hidden={true} isKey={true} />
        <TableHeaderColumn dataField="editable" hidden={true} />
        {renderIconsColumn({
          hidden: !isColumnVisible("misc", this.props),
          dataSort: false,
          width: "100",
          selectable: isColumnSelectable("misc", this.props),
          setS3ImageUrl: this.props.setS3ImageUrl,
        })}
        {this.props.forAdmin &&
          renderAuthorNameColumn({
            hidden: !isColumnVisible("authorName", this.props),
            dataSort: false,
            width: "150",
            selectable: isColumnSelectable("authorName", this.props),
          })}
        {renderTransactedAtColumn({
          hidden: !isColumnVisible("transactedAt", this.props),
          tbStyle: { overflow: "visible" },
          dataSort: true,
          width: "180",
          selectable: isColumnSelectable("transactedAt", this.props),
        })}
        {renderShopColumn({
          hidden: !isColumnVisible("shopName", this.props),
          dataSort: true,
          width: "280",
          selectable: isColumnSelectable("shopName", this.props),
        })}
        {renderAmountColumn({
          hidden: !isColumnVisible("amount", this.props),
          dataSort: true,
          width: "150",
          dataAlign: "right",
          selectable: isColumnSelectable("amount", this.props),
        })}
        {this.props.forAdmin &&
          renderCategoryNameColumn({
            hidden: !isColumnVisible("categoryName", this.props),
            dataSort: true,
            width: "180",
            selectable: isColumnSelectable("categoryName", this.props),
          })}
        {this.props.forAdmin &&
          renderMemoColumn({
            hidden: !isColumnVisible("comment", this.props),
            dataSort: false,
            width: "180",
            selectable: isColumnSelectable("comment", this.props),
          })}
        {this.props.forAdmin &&
          renderDepartmentNameColumn({
            hidden: !isColumnVisible("department", this.props),
            dataSort: true,
            width: "200",
            selectable: isColumnSelectable("department", this.props),
          })}
        {this.props.forAdmin &&
          renderReportStatusColumn({
            hidden: !isColumnVisible("status", this.props),
            dataSort: true,
            width: "110",
            selectable: isColumnSelectable("status", this.props),
          })}
      </DataFetchingTable>
    );
  }
}

ExpenseTable.defaultProps = {
  sizePerPageList: [],
  columnSelectable: false,
  currentPage: 1,
  sizePerPage: 10,
  isLoading: false,
  onRowClick: () => {},
  forAdmin: false,
};

ExpenseTable.propTypes = {
  className: PropTypes.string,
  expenses: PropTypes.arrayOf(PropTypes.shape(transactionType)).isRequired,
  sizePerPageList: PropTypes.arrayOf(PropTypes.number),
  currentPage: PropTypes.number,
  sizePerPage: PropTypes.number,
  columnSelectable: PropTypes.bool,
  fetchExpense: PropTypes.func.isRequired,
  onSortChange: PropTypes.func,
  onPageChange: PropTypes.func.isRequired,
  selectRow: PropTypes.object,
  isLoading: PropTypes.bool,
  onRowClick: PropTypes.func,
  setS3ImageUrl: PropTypes.func,
  forAdmin: PropTypes.bool,
};

export default ExpenseTable;

/**
 * NOTE: TransactionTable に書かれたカラムと互換性を持っている
 *
 * import {
 *   renderReadStatusColumn,
 *   renderTransactedAtColumn,
 *   ...,
 * } from '.../ExpenseTable/index';
 *
 */
export {
  renderReadStatusColumn,
  renderTransactedAtColumn,
  renderAmountColumn,
  renderShopColumn,
  renderIconsColumn,
  renderAuthorNameColumn,
  renderCategoryNameColumn,
  renderDepartmentNameColumn,
  renderMemoColumn,
  renderReportStatusColumn,
};
