import fareFormatter from "applications/fare_transactions/fare_formatter";
import {
  canUpdateAll,
  hasFailedReceiptImage,
  hasFailedToSignReceiptImage,
} from "applications/transactions/utilities/transactionFormPolicy";
import Thumbnail from "components/attached_files/Thumbnail";
import CompanionList from "components/CompanionList";
import DataFetchingTable from "components/DataFetchingTable";
import EditableInput from "components/EditableInput";
import SimpleModal from "components/SimpleModal";
import SimpleBalloon from "components/simple_balloon";
import i18next from "i18n";
import get from "lodash/get";
import groupBy from "lodash/groupBy";
import includes from "lodash/includes";
import isBoolean from "lodash/isBoolean";
import isFunction from "lodash/isFunction";
import isNil from "lodash/isNil";
import merge from "lodash/merge";
import set from "lodash/set";
import PropTypes from "prop-types";
import React, { Component } from "react";
import { OverlayTrigger, Popover } from "react-bootstrap";
import { TableHeaderColumn } from "react-bootstrap-table";
import transactionType from "types/transaction";
import Formatter from "utilities/formatter";
import { calcDistance, getReportTitleLabel } from "utilities/Utils";
import ExpenseHistoryTable from "./ExpenseHistoryTable";

/*
 * Transactionの一覧を表示する
 *
 */
export default class TransactionTable extends Component {
  /* TransactionTableを継承しているReportTransactionSelectTableはselectRowのオプションが違うため、オーバーライドで変更できるように */
  constructor(props) {
    super(props);

    this.handleRowClick = this.handleRowClick.bind(this);
    this.formatShopOrRoute = this.formatShopOrRoute.bind(this);
    this.handleHistoryIconClick = this.handleHistoryIconClick.bind(this);
    this.formatAlert = this.formatAlert.bind(this);
    this.formatTimeStamps = this.formatTimeStamps.bind(this);
    this.formatDepartment = this.formatDepartment.bind(this);
    this.costAllocationFormatter = this.costAllocationFormatter.bind(this);
    this.formatEligibleInvoice = this.formatEligibleInvoice.bind(this);
  }

  get transactions() {
    const historyMap = this.historyMap;

    // 詳細情報を取得していない経費レコードが存在していることに注意（その場合、undefined）
    return this.props.transactions.map((t) => {
      if (isNil(t)) {
        return t;
      }

      const error = {
        id: t.id,
        alerts: t.alerts,
        warnings: t.warnings,
      };

      // ソートするオブジェクトのキーを設定（文字列の場合は必要なし）
      const transaction = merge(t, {
        project: { sortKeys: ["displayId", "name"] },
      });

      return {
        ...transaction,
        error,
        histories: historyMap[t.id] || [],
      };
    });
  }

  get historyMap() {
    return this.props.histories.reduce((acc, history) => {
      const transactionId =
        history.transactionId || get(history, "transaction.id", null);

      if (acc[transactionId]) {
        acc[transactionId].push(history);
      } else {
        set(acc, transactionId, [history]);
      }

      return acc;
    }, {});
  }

  get selectedHistories() {
    if (this.props.selectedTransactionIdForHistory) {
      return this.props.histories.filter((x) => {
        const transactionId = x.transactionId || get(x, "transaction.id", null);

        return transactionId === this.props.selectedTransactionIdForHistory;
      });
    }

    return [];
  }

  get isHistoryModalOpen() {
    return !!this.props.selectedTransactionIdForHistory;
  }

  getSelectRowOptions() {
    const { currentPage, sizePerPage } = this.props;
    const selectRowProp = {
      mode: "checkbox",
      bgColor: "rgb(212, 238, 232)",
      onSelect: (obj, isSelected) => {
        if (isSelected) {
          this.props.onSelect(obj, true);
        } else {
          this.props.onSelect(obj, false);
        }
        return true;
      },
      // 実際は、テーブルに表示中のレコードのみが対象になる
      onSelectAll: (isSelected, arr) => {
        this.props.onSelectAll(isSelected);
        return true;
      },
      hideSelectColumn: this.props.hideSelectColumn,
      // テーブルに表示されているレコードの中から、選択中のレコードを絞り込む
      selected: this.props.transactions
        .slice((currentPage - 1) * sizePerPage, currentPage * sizePerPage)
        .filter((x) => x && x.isSelected)
        .map((x) => x.id),
    };
    return selectRowProp;
  }

  handleRowClick(row) {
    if (this.props.isEditing) {
      // 選択中の行は編集中であり、誤操作防止のために、行のクリックイベントを処理しない
      return;
    }
    this.props.onRowClick(row);
  }

  handleHistoryIconClick(transactionId, e) {
    e.stopPropagation();
    this.props.openHistoryModal(transactionId);
  }

  render() {
    const { isStatic } = this.props;

    const transactions = this.transactions;
    const options = {
      noDataText: i18next.t("commons.messages.noDataFound"),
      onRowClick: isStatic ? () => null : this.handleRowClick,
      sizePerPage: 30,
      sizePerPageList: [10, 30, 50],
    };

    const selectRowOptions = this.getSelectRowOptions();
    const count = transactions ? transactions.length : 0;

    const trClassName = (rowData, rIndex) => {
      if (this.props.authority === "approver") {
        return rowData.read ? "tr-status-read" : "tr-status-unread";
      }
      return null;
    };

    return (
      <div className="transaction-table">
        {isStatic || !this.props.showNumberOfResults ? null : (
          <div className="search-summary">
            <p className="txt txt-default">
              {`${i18next.t("transactions.index.results")}：`}
              <span style={{ fontWeight: "bold" }}> {count}</span>
              {i18next.t("commons.units.result", { count })}
            </p>
          </div>
        )}
        <DataFetchingTable
          data={transactions}
          options={options}
          currentPage={this.props.currentPage}
          sizePerPage={this.props.sizePerPage}
          sizePerPageList={[10, 30, 50]}
          columnSelectable={this.props.columnSelectable}
          fetchData={this.props.fetchTransactions}
          onSortChange={this.props.onSortChange}
          onPageChange={this.props.onPageChange}
          onSizePerPageList={this.props.onSizePerPageList}
          onColumnSelectionChange={this.props.onColumnSelectionChange}
          onRestoreDefaultColumnSelectionClick={
            this.props.onRestoreDefaultColumnSelectionClick
          }
          selectRow={
            isFunction(this.props.onSelect) && !isStatic
              ? selectRowOptions
              : void 0
          }
          striped={true}
          tableBodyClass="multiline"
          trClassName={trClassName}
          keyField="id"
        >
          {[
            <TableHeaderColumn dataField="id" hidden={true} key="id" />,
            <TableHeaderColumn
              dataField="editable"
              hidden={true}
              key="editable"
            />,
            <TableHeaderColumn
              dataField="read"
              hidden={!this.isColumnVisible("read")}
              dataFormat={(column, transaction) =>
                this.readStatus.bind(this)(column, transaction)
              }
              dataSort={true}
              dataAlign="center"
              width="80"
              key="read"
            >
              {i18next.t("transactions.properties.unread")}
            </TableHeaderColumn>,
            <TableHeaderColumn
              dataField="transactedAt"
              hidden={!this.isColumnVisible("transactedAt")}
              dataFormat={this.editableInputFormatter(
                "date",
                "transactedAt",
                (v) => Formatter.date(v, { withDayOfTheWeek: true }),
              )}
              dataSort={true}
              tdStyle={{ overflow: "visible" }}
              width="180"
              selectable={this.isColumnSelectable("transactedAt")}
              key="transactedAt"
            >
              {i18next.t("transactions.properties.date")}
            </TableHeaderColumn>,
            <TableHeaderColumn
              dataField="error"
              hidden={!this.isColumnVisible("error")}
              dataFormat={this.formatAlert}
              dataAlign="center"
              width="100"
              selectable={this.isColumnSelectable("error")}
              key="error"
            >
              {i18next.t("transactions.index.alert")}
            </TableHeaderColumn>,
            <TableHeaderColumn
              dataField="misc"
              hidden={!this.isColumnVisible("misc")}
              dataFormat={(column, transaction) =>
                this.formatInfoIcons.bind(this)(column, transaction)
              }
              dataSort={false}
              dataAlign="center"
              width="100"
              selectable={this.isColumnSelectable("misc")}
              key="misc"
            >
              {i18next.t("commons.misc")}
            </TableHeaderColumn>,
            <TableHeaderColumn
              dataField="hasValidatedReceiptMatching"
              hidden={!this.isColumnVisible("hasValidatedReceiptMatching")}
              dataFormat={(column, transaction) =>
                this.formatReceiptMatchingResultStatus.bind(this)(
                  column,
                  transaction,
                )
              }
              dataSort={false}
              dataAlign="center"
              width="90"
              selectable={this.isColumnSelectable(
                "hasValidatedReceiptMatching",
              )}
              key="hasValidatedReceiptMatching"
            >
              {i18next.t("transactions.properties.hasValidatedReceiptMatching")}
            </TableHeaderColumn>,
            <TableHeaderColumn
              dataField="isElectronicReceiptImage"
              hidden={!this.isColumnVisible("isElectronicReceiptImage")}
              dataFormat={(column, transaction) =>
                this.formatIsElectronicReceiptImageForCellData.bind(this)(
                  column,
                  transaction,
                )
              }
              dataSort={false}
              dataAlign="center"
              width="90"
              selectable={this.isColumnSelectable("isElectronicReceiptImage")}
              key="isElectronicReceiptImage"
            >
              {i18next.t("transactions.properties.isElectronicReceiptImage")}
            </TableHeaderColumn>,
            <TableHeaderColumn
              dataField="shopName"
              hidden={!this.isColumnVisible("shopName")}
              dataFormat={this.editableInputFormatter(
                "text",
                "shopName",
                this.formatShopOrRoute,
              )}
              dataSort={true}
              width="280"
              selectable={this.isColumnSelectable("shopName")}
              key="shopName"
            >
              {`${i18next.t("transactions.properties.shop")}/${i18next.t(
                "transactions.properties.visited",
              )}`}
            </TableHeaderColumn>,
            <TableHeaderColumn
              dataField="transitPayee"
              hidden={!this.isColumnVisible("transitPayee")}
              dataSort={true}
              width="280"
              selectable={this.isColumnSelectable("transitPayee")}
              key="transitPayee"
            >
              {i18next.t("transactions.properties.transitPayee")}
            </TableHeaderColumn>,
            <TableHeaderColumn
              dataField="amount"
              hidden={!this.isColumnVisible("amount")}
              dataFormat={this.editableInputFormatter(
                "number",
                "amount",
                this.formatStaticAmount,
              )}
              dataSort={true}
              dataAlign="right"
              width="150"
              selectable={this.isColumnSelectable("amount")}
              key="amount"
            >
              {i18next.t("transactions.properties.amount")}
            </TableHeaderColumn>,
            <TableHeaderColumn
              dataField="withholding"
              hidden={!this.isColumnSelectable("withholding")}
              dataFormat={(w) => this.formatStaticWithholding(w)}
              dataSort={false}
              dataAlign="right"
              width="150"
              key="withholding"
            >
              {i18next.t("transactions.properties.withholding")}
            </TableHeaderColumn>,
            <TableHeaderColumn
              dataField="ownerName"
              hidden={!this.isColumnVisible("ownerName")}
              dataFormat={(cell) => Formatter.text(cell, "所有者なし")}
              dataSort={true}
              width="150"
              selectable={this.isColumnSelectable("ownerName")}
              key="ownerName"
            >
              {i18next.t("transactions.properties.employeeName")}
            </TableHeaderColumn>,
            <TableHeaderColumn
              columnClassName="suggest-column"
              dataField="categoryName"
              hidden={!this.isColumnVisible("categoryName")}
              dataFormat={this.editableSelectFormatter(
                "categoryName",
                (value, row) =>
                  Formatter.categoryName(value, row.inputBy, row.status),
              )}
              dataSort={true}
              width="180"
              selectable={this.isColumnSelectable("categoryName")}
              key="categoryName"
            >
              {i18next.t("transactions.properties.category")}
            </TableHeaderColumn>,
            // ToDo:
            // isMultipleTaxをisMultipleTaxRatesに命名変更する
            // api/アプリの修正が必要
            <TableHeaderColumn
              dataField="isMultipleTax"
              hidden={!this.isColumnVisible("isMultipleTax")}
              dataFormat={this.formatIsMultipleTaxRates}
              className="long-text"
              dataAlign="center"
              width="100"
              selectable={this.isColumnSelectable("isMultipleTax")}
              key="isMultipleTax"
            >
              {this.isMultipleTaxRatesHeader()}
            </TableHeaderColumn>,
            <TableHeaderColumn
              dataField="expenseAmountPerTaxCategories"
              hidden={!this.isColumnVisible("expenseAmountPerTaxCategories")}
              dataFormat={this.formatTaxCategories}
              dataSort={true}
              width="120"
              selectable={this.isColumnSelectable(
                "expenseAmountPerTaxCategories",
              )}
              key="expenseAmountPerTaxCategories"
            >
              {i18next.t("transactions.properties.taxCategory")}
            </TableHeaderColumn>,
            <TableHeaderColumn
              dataField="comment"
              hidden={!this.isColumnVisible("comment")}
              width="180"
              selectable={this.isColumnSelectable("comment")}
              key="comment"
            >
              {i18next.t("transactions.properties.comment")}
            </TableHeaderColumn>,
            <TableHeaderColumn
              dataField="companions"
              hidden={!this.isColumnVisible("companions")}
              dataFormat={(cell, key) =>
                this.formatCompanionsAsLabel(cell, key)
              }
              dataSort={false}
              width="180"
              selectable={this.isColumnSelectable("companions")}
              key="companions"
            >
              {i18next.t("transactions.properties.companion")}
            </TableHeaderColumn>,
            <TableHeaderColumn
              dataField="originAndDestinationByCategory"
              hidden={!this.isColumnVisible("originAndDestinationByCategory")}
              dataFormat={this.formatOriginAndDestination}
              dataSort={false}
              width="180"
              selectable={this.isColumnSelectable(
                "originAndDestinationByCategory",
              )}
              key="originAndDestinationByCategory"
            >
              {i18next.t(
                "transactions.properties.originAndDestinationByCategory",
              )}
            </TableHeaderColumn>,
            <TableHeaderColumn
              dataField="visitByCategory"
              hidden={!this.isColumnVisible("visitByCategory")}
              dataSort={false}
              width="180"
              selectable={this.isColumnSelectable("visitByCategory")}
              key="visitByCategory"
            >
              {i18next.t("transactions.properties.visitByCategory")}
            </TableHeaderColumn>,
            <TableHeaderColumn
              dataField="purposeByCategory"
              hidden={!this.isColumnVisible("purposeByCategory")}
              dataSort={false}
              width="180"
              selectable={this.isColumnSelectable("purposeByCategory")}
              key="purposeByCategory"
            >
              {i18next.t("transactions.properties.purposeByCategory")}
            </TableHeaderColumn>,
            <TableHeaderColumn
              columnClassName="suggest-column"
              dataField="project"
              hidden={!this.isColumnVisible("project")}
              dataFormat={this.editableSelectFormatter(
                "project",
                (p, row) =>
                  Formatter.project(p, null, null, ["name", "displayId"], true),
                (p) =>
                  p &&
                  Formatter.project(p, null, null, ["name", "displayId"], true),
                (p) =>
                  p &&
                  Formatter.project(p, null, null, ["name", "displayId"], true),
              )}
              dataSort={true}
              width="200"
              selectable={this.isColumnSelectable("project")}
              key="project"
            >
              {i18next.t("transactions.properties.project")}
            </TableHeaderColumn>,
          ]
            .concat(
              userPreferences.preference?.genericFields &&
                Array.isArray(userPreferences.preference?.genericFields)
                ? userPreferences.preference?.genericFields.map(
                    (genericField) => {
                      return (
                        <TableHeaderColumn
                          key={genericField.id}
                          dataField={genericField.id}
                          dataFormat={(column, transaction) =>
                            this.formatGenericFields(
                              column,
                              transaction,
                              genericField.id,
                            )
                          }
                          hidden={!this.isColumnVisible(genericField.id)}
                          width="120"
                          selectable={this.isColumnSelectable(genericField.id)}
                        >
                          {genericField.name}
                        </TableHeaderColumn>
                      );
                    },
                  )
                : [],
            )
            .concat([
              <TableHeaderColumn
                columnClassName="suggest-column"
                dataField="department"
                hidden={!this.isColumnVisible("department")}
                dataFormat={this.formatDepartment}
                dataSort={true}
                width="200"
                selectable={this.isColumnSelectable("department")}
                key="department"
              >
                {i18next.t("transactions.properties.userDepartment")}
              </TableHeaderColumn>,
              <TableHeaderColumn
                dataField="costAllocations"
                hidden={!this.isColumnVisible("costAllocations")}
                dataFormat={this.costAllocationFormatter}
                width="200"
                selectable={this.isColumnSelectable("costAllocations")}
                tdStyle={{ height: 1 }}
                key="costAllocations"
              >
                {i18next.t("transactions.properties.department")}
              </TableHeaderColumn>,
              <TableHeaderColumn
                columnClassName="suggest-column"
                dataField="creditCategoryName"
                hidden={!this.isColumnVisible("creditCategoryName")}
                dataFormat={this.editableSelectFormatter(
                  "creditCategoryName",
                  (value, row) =>
                    Formatter.categoryName(value, row.inputBy, row.status),
                )}
                width="180"
                selectable={this.isColumnSelectable("creditCategoryName")}
                key="creditCategoryName"
              >
                {i18next.t("transactions.properties.creditCategory")}
              </TableHeaderColumn>,
              <TableHeaderColumn
                dataField="reportTitle"
                hidden={!this.isColumnVisible("reportTitle")}
                dataFormat={(cell) =>
                  Formatter.text(cell, i18next.t("commons.status.unsubmitted"))
                }
                dataSort={true}
                width="140"
                selectable={this.isColumnSelectable("reportTitle")}
                key="reportTitle"
              >
                {getReportTitleLabel()}
              </TableHeaderColumn>,
              <TableHeaderColumn
                dataField="isCorporate"
                hidden={!this.isColumnVisible("isCorporate")}
                dataFormat={this.formatCardAsLabel.bind(this)}
                dataSort={true}
                width="120"
                selectable={this.isColumnSelectable("isCorporate")}
                key="isCorporate"
              >
                {i18next.t("transactions.properties.noRefund")}
              </TableHeaderColumn>,
              <TableHeaderColumn
                dataField="status"
                hidden={!this.isColumnVisible("status")}
                dataFormat={(cell) => Formatter.status(cell)}
                dataSort={true}
                width="110"
                selectable={this.isColumnSelectable("status")}
                key="status"
              >
                {i18next.t("transactions.properties.status")}
              </TableHeaderColumn>,
              <TableHeaderColumn
                dataField="createdAt"
                hidden={!this.isColumnVisible("createdAt")}
                dataFormat={(v) => Formatter.date(v)}
                dataSort={true}
                width="100"
                selectable={this.isColumnSelectable("createdAt")}
                key="createdAt"
              >
                {i18next.t("transactions.properties.registrationDate")}
              </TableHeaderColumn>,
              <TableHeaderColumn
                dataField="reportSequenceNum"
                hidden={!this.isColumnVisible("reportSequenceNum")}
                dataAlign="center"
                width="120"
                selectable={this.isColumnSelectable("reportSequenceNum")}
                key="reportSequenceNum"
              >
                {i18next.t("transactions.properties.reportSequenceNum")}
              </TableHeaderColumn>,
              <TableHeaderColumn
                dataField="preReportSequenceNum"
                hidden={!this.isColumnVisible("preReportSequenceNum")}
                dataAlign="center"
                width="120"
                selectable={this.isColumnSelectable("preReportSequenceNum")}
                key="preReportSequenceNum"
              >
                {i18next.t("transactions.properties.preReportSequenceNum")}
              </TableHeaderColumn>,
              <TableHeaderColumn
                dataField="registratedNumber"
                hidden={!this.isColumnVisible("registratedNumber")}
                dataAlign="center"
                width="120"
                selectable={this.isColumnSelectable("registratedNumber")}
                key="registratedNumber"
                dataFormat={(number) => (number ? `T${number}` : "")}
              >
                {i18next.t("transactions.properties.registratedNumber")}
              </TableHeaderColumn>,
              <TableHeaderColumn
                dataField="asEligibleInvoice"
                hidden={!this.isColumnVisible("asEligibleInvoice")}
                dataAlign="center"
                width="120"
                selectable={this.isColumnSelectable("asEligibleInvoice")}
                key="asEligibleInvoice"
                dataFormat={(status) => this.formatEligibleInvoice(status)}
              >
                {i18next.t("transactions.properties.eligibleInvoiceTitle")}
              </TableHeaderColumn>,
            ])}
        </DataFetchingTable>
        <SimpleModal
          show={this.isHistoryModalOpen}
          close={this.props.closeHistoryModal}
          size={"lg"}
          title={"経費編集履歴"}
        >
          <ExpenseHistoryTable histories={this.selectedHistories} />
        </SimpleModal>
      </div>
    );
  }

  costAllocationFormatter(departments, _, __, rowIdx) {
    if (departments.length === 0) return <></>; // 費用負担部署が空の場合はなにも表示しない

    const detailIconStyle =
      this.props.costAllocationHovorRowIdx === rowIdx ? null : { opacity: 0 };
    return (
      <div
        onMouseEnter={() => {
          this.props.onMouseEnterCostAlocationDiv(rowIdx);
        }}
        onMouseLeave={() => {
          this.props.onMouseLeaveCostAlocationDiv();
        }}
        onClick={(e) => {
          e.stopPropagation();
          this.props.openCostAllocationModal(departments);
        }}
        style={{ height: "100%" }}
      >
        {departments.slice(0, 5).map((department) => (
          <div key={department.payerId}>{department.payerName}</div>
        ))}
        <div className="text-primary text-right" style={detailIconStyle}>
          <i className="fas fa-angle-double-up"></i>{" "}
          {i18next.t("transactions.costAllocationModal.detail")}
        </div>
      </div>
    );
  }

  isMultipleTaxRatesHeader() {
    const popover = (
      <Popover id="fromWorker">
        {i18next.t("transactions.messages.multipleTaxRates")}
      </Popover>
    );
    return (
      <span>
        {i18next.t("transactions.properties.multipleTaxRates")}
        <OverlayTrigger
          trigger={["hover", "focus", "click"]}
          placement="top"
          overlay={popover}
        >
          <i
            className="far fa-question-circle"
            style={{ color: "#AAA", marginLeft: "6px", cursor: "pointer" }}
          />
        </OverlayTrigger>
      </span>
    );
  }

  isColumnSelectable(column) {
    if (column === "project" && !userPreferences.preference.inputProject) {
      return false;
    }
    if (
      column === "costAllocations" &&
      !userPreferences.preference.inputGroup
    ) {
      return false;
    }
    if (column === "ownerName" && !this.props.isSearchScopeAll) {
      return false;
    }
    if (column === "read" && !(this.props.authority === "approver")) {
      return false;
    }
    if (column === "preReportSequenceNum" && !this.props.hasPreReport) {
      return false;
    }
    if (
      column === "withholding" &&
      !(
        userPreferences.preference.rootGroupRequiresWithholding &&
        this.props.reportRequiresWithholding
      )
    ) {
      return false;
    }
    if (
      column === "hasValidatedReceiptMatching" &&
      !userPreferences.isPaperlessPlan
    ) {
      return false;
    }
    if (
      column === "isElectronicReceiptImage" &&
      !userPreferences.preference.enableExpenseElectronicReceiptImageFlag
    ) {
      return false;
    }
    if (this.props.only) {
      return includes(this.props.only, column);
    }
    if (this.props.except) {
      return !includes(this.props.except, column);
    }
    return true;
  }

  isColumnVisible(dataField) {
    const { columnVisibilities } = this.props;
    const selectable = this.isColumnSelectable(dataField);
    if (columnVisibilities && isBoolean(columnVisibilities[dataField])) {
      return selectable && columnVisibilities[dataField];
    }
    return selectable;
  }

  editableInputFormatter(inputType, key, defaultFormatter) {
    const { isEditable, isEditing, onChangeTransaction, onUpdateTransaction } =
      this.props;

    if (!isEditable) {
      return defaultFormatter;
    }
    return (text, row) => {
      if (!isEditing || !row.editable || !canUpdateAll(row.permissions)) {
        return defaultFormatter(text, row);
      }
      const isRouteExpense = !isNil(row.routeId);
      const isAllowance =
        get(row.calculationFormulaVariableInputs, "length", 0) > 0;
      const hasExpenseAmountPerTaxCategories =
        get(row.expenseAmountPerTaxCategories, "length", 0) > 0;

      // 入力項目「金額」のとき、交通費、日当、または、税区分金額が存在する場合、編集不可とする。
      if (
        key === "amount" &&
        (isRouteExpense || isAllowance || hasExpenseAmountPerTaxCategories)
      ) {
        return defaultFormatter(text, row);
      }
      return (
        <EditableInput
          inputType={inputType}
          isEditing={true}
          sync={row.editorSync[key]}
          formatter={defaultFormatter}
          text={text}
          editingText={text}
          onChange={onChangeTransaction.bind(null, row.id, key)}
          onUpdate={onUpdateTransaction.bind(null, row.id, key)}
        />
      );
    };
  }

  editableSelectFormatter(
    key,
    defaultFormatter,
    getSuggestionValue = (v) => v,
    renderSuggestion = (v) => v,
  ) {
    const {
      isEditable,
      isEditing,
      onUpdateTransaction,
      onChangeTransaction,
      onChangeSelectInput,
      onSuggestionsUpdateRequested,
      onClearSelectInput,
      initSuggestions,
      clearSuggestions,
    } = this.props;

    if (!isEditable) {
      return defaultFormatter;
    }

    return (value, row) => {
      if (!isEditing || !row.editable || !canUpdateAll(row.permissions)) {
        return defaultFormatter(value, row);
      }
      const inputProps = {
        className: "react-autosuggest-input form-control",
      };

      return (
        <EditableInput
          inputType="select"
          input={inputProps}
          style={{ height: "34px" }}
          isEditing={true}
          sync={row.editorSync[key]}
          formatter={defaultFormatter}
          text={getSuggestionValue(value)}
          editingText={row.selectText[key]}
          suggestions={row.suggestions[key]}
          getSuggestionValue={getSuggestionValue}
          renderSuggestion={renderSuggestion}
          onFocus={() => {
            initSuggestions(row.id, key);
          }}
          onBlur={() => {
            clearSuggestions(row.id, key);
          }}
          onChange={onChangeSelectInput.bind(null, row.id, key)}
          onSelect={(e, { suggestion }) => {
            onChangeTransaction(row.id, key, suggestion);
          }}
          onUpdate={onUpdateTransaction.bind(null, row.id, key)}
          clearValue={onClearSelectInput.bind(null, row.id, key)}
          onSuggestionsUpdateRequested={onSuggestionsUpdateRequested.bind(
            null,
            row.id,
            key,
          )}
        />
      );
    };
  }

  formatStaticAmount(amount, row) {
    if (row.route) {
      return fareFormatter.renderFare(amount, row.route.withTeiki, "left");
    }

    return Formatter.amount(amount);
  }

  formatStaticWithholding(withholding) {
    return Formatter.amount(withholding);
  }

  formatShopOrRoute(__, row) {
    if (this.props.isEditing || isNil(row.route)) {
      return <span>{row.shopName}</span>;
    }

    const { route, shopName, id } = row;
    if (isNil(route.origin) || isNil(route.destination)) {
      return null;
    }

    const popover = this.detailsPopover(
      "route",
      void 0,
      route,
      `route-${id}`,
      this.formatRoute,
    );

    if (
      !userPreferences.preference.showDistance ||
      isNil(route.onewayDistance)
    ) {
      return (
        <OverlayTrigger rootClose placement="left" overlay={popover}>
          <span>
            {`${shopName || ""} (${route.origin.name} ${
              route.isRoundTrip ? "⇄" : "→"
            } ${route.destination.name})`}
          </span>
        </OverlayTrigger>
      );
    }
    return (
      <OverlayTrigger rootClose placement="left" overlay={popover}>
        <span>
          {`${shopName || ""} (${route.origin.name} ${
            route.isRoundTrip ? "⇄" : "→"
          } ${route.destination.name} ${Formatter.distance(
            calcDistance(route, route.isRoundTrip),
          )})`}
        </span>
      </OverlayTrigger>
    );
  }

  formatRoute(route) {
    return (
      <div>
        {fareFormatter.renderLabels(route.labels)}
        {fareFormatter.renderViaList(route.via || route.lines, "down")}
      </div>
    );
  }

  formatAlert(alert, trs) {
    return (
      <div className="icons">
        {this.formatError(alert)}
        {this.formatHistories(trs.histories, trs.id)}
        {this.formatTimeStamps(trs.receiptImages, trs.id)}
      </div>
    );
  }

  formatError({ id, alerts, warnings }) {
    const messages = alerts
      .concat(warnings)
      .map((a, i) => <div key={i}>{a.message}</div>);
    if (alerts.length > 0) {
      return (
        <SimpleBalloon
          popoverId={`alert-popup-${id}`}
          placement="top"
          icon="fa-exclamation-circle"
          color="danger"
          message={messages}
        ></SimpleBalloon>
      );
    }
    if (warnings.length > 0) {
      return (
        <SimpleBalloon
          popoverId={`warn-popup-${id}`}
          placement="top"
          icon="fa-exclamation-circle"
          color="warning"
          message={messages}
        ></SimpleBalloon>
      );
    }

    return null;
  }

  formatTimeStamps(receiptImages, id) {
    if (isNil(receiptImages)) {
      return null;
    }

    const timeStamps = Object.keys(receiptImages).reduce(
      (acc, side) => ({
        ...acc,
        [side]: receiptImages[side]
          .filter((x) => !isNil(x.timeStamp))
          .map((x) => ({ timeStamp: x.timeStamp, uploadedBy: x.uploadedBy })),
      }),
      {},
    );

    if (timeStamps.foreside.length === 0 && timeStamps.backside.length === 0) {
      return null;
    }

    return this.formatIconWithOverlay(
      this.detailsPopover(
        "time-stamps",
        i18next.t("timeStamps.timeStamp"),
        timeStamps,
        id,
        this.renderTimeStamps,
      ),
      "fas fa-certificate",
      id,
    );
  }

  renderTimeStamps(timeStamps) {
    let list = [];
    const labelStyle = { fontWeight: "bold" };
    const foresideLabel = (
      <div key={"foreside-label"} style={labelStyle}>
        {i18next.t("timeStamps.foreside")}
      </div>
    );
    const backsideLabel = (
      <div key={"backside-label"} style={labelStyle}>
        {i18next.t("timeStamps.backside")}
      </div>
    );

    const foresideLength = timeStamps.foreside.length;
    const foresides = timeStamps.foreside.map(
      ({ timeStamp, uploadedBy }, i) => (
        <div key={`foreside-${i}`}>
          <span className="time-stamp-signed-at">{`Ver.${
            foresideLength - i
          }`}</span>
          <span>{Formatter.datetime(timeStamp.stampedAt * 1000)}</span>
          {isNil(uploadedBy) ? null : (
            <span>{Formatter.text(`  by ${uploadedBy.name}`)}</span>
          )}
        </div>
      ),
    );
    const backsideLength = timeStamps.backside.length;
    const backsides = timeStamps.backside.map(
      ({ timeStamp, uploadedBy }, i) => (
        <div key={`backside-${i}`}>
          <span className="time-stamp-signed-at">{`Ver.${
            backsideLength - i
          }`}</span>
          <span>{Formatter.datetime(timeStamp.stampedAt * 1000)}</span>
          {isNil(uploadedBy) ? null : (
            <span>{Formatter.text(`  by ${uploadedBy.name}`)}</span>
          )}
        </div>
      ),
    );

    if (foresides.length > 0) {
      list = list.concat([foresideLabel]);
      list = list.concat(foresides);
    }
    if (backsides.length > 0) {
      list = list.concat([backsideLabel]);
      list = list.concat(backsides);
    }

    return list;
  }

  formatIconWithOverlay(overlay, icon, id, props = {}) {
    const iProps = { className: `${icon}`, ...props };
    return (
      <OverlayTrigger rootClose placement="left" overlay={overlay}>
        <i {...iProps}></i>
      </OverlayTrigger>
    );
  }

  detailsPopover(prefix, title, value, id, formatter = (v) => v) {
    return (
      <Popover id={`${prefix}-${id}`} placement="left" title={title}>
        <div style={{ wordWrap: "break-word" }}>{formatter(value)}</div>
      </Popover>
    );
  }

  formatImage(receiptImages, id) {
    if (receiptImages.foreside.length === 0) {
      return null;
    }

    if (hasFailedToSignReceiptImage(receiptImages)) {
      // 失敗している画像がある場合
      return this.formatIconWithOverlay(
        this.detailsPopover(
          "failedUpload",
          i18next.t("transactions.properties.image"),
          i18next.t("transactions.errors.failedToSignTimeStamp"),
          id,
          (c) =>
            c
              .split(/\r?\n|\\n/g)
              .map((string, index) => <div key={index}>{string}</div>),
        ),
        "fas fa-question",
        id,
      );
    }

    if (hasFailedReceiptImage(receiptImages)) {
      // 失敗している画像がある場合
      return this.formatIconWithOverlay(
        this.detailsPopover(
          "failedUpload",
          i18next.t("transactions.properties.image"),
          i18next.t("transactions.errors.failedToUploadImage"),
          id,
          (c) =>
            c
              .split(/\r?\n|\\n/g)
              .map((string, index) => <div key={index}>{string}</div>),
        ),
        "fas fa-question",
        id,
      );
    }

    const file = receiptImages.foreside[0];

    return this.formatIconWithOverlay(
      this.imagePopover(file, id),
      "fas fa-paperclip",
      id,
    );
  }

  imagePopover(image, id) {
    return (
      <Popover
        id={`image-popup-${id}`}
        placement="left"
        className="popup-image"
      >
        <Thumbnail
          id={image.id}
          mimeType={image.contentType}
          width={200}
          minHeight={200}
          maxHeight={300}
          rotation={image.rotation}
        />
      </Popover>
    );
  }

  formatComment(comment, id) {
    if (!comment) {
      return null;
    }

    return this.formatIconWithOverlay(
      this.detailsPopover(
        "comment",
        i18next.t("transactions.properties.comment"),
        comment,
        id,
        (c) =>
          c
            .split(/\r?\n|\\n/g)
            .map((string, index) => <div key={index}>{string}</div>),
      ),
      "far fa-comment-dots",
      id,
    );
  }

  formatCompanions(companions, id) {
    if (companions.length === 0) {
      return null;
    }
    return this.formatIconWithOverlay(
      this.companionsOverlay(companions, id),
      "fas fa-user",
      id,
    );
  }

  companionsOverlay(companions, id) {
    const colleagues = companions.filter((c) => !c.isClient);
    const clients = companions.filter((c) => c.isClient);
    let clientsByCompany = groupBy(clients, (c) => c.company);
    clientsByCompany = Object.keys(clientsByCompany).map(
      (company) => clientsByCompany[company],
    );

    return (
      <Popover
        id={`comment-${id}`}
        placement="left"
        title={i18next.t("companions.companion")}
      >
        <div style={{ wordWrap: "break-word" }}>
          <CompanionList
            companions={colleagues}
            showCompany={true}
            companyName={i18next.t("companions.ourCompany")}
          />
          <CompanionList companions={clients} showCompany={true} />
        </div>
      </Popover>
    );
  }

  formatCard(transaction, id) {
    if (!transaction.isCorporate) {
      return null;
    }
    return this.formatIconWithOverlay(
      this.detailsPopover(
        "card",
        void 0,
        i18next.t("transactions.messages.isCorporate"),
        id,
      ),
      "far fa-building",
      id,
    );
  }

  formatFromWorker(transaction, id) {
    if (transaction.inputBy !== "worker") {
      return null;
    }

    const materialIconStyle = {
      fontSize: 14,
      marginLeft: 2,
      marginRight: 2,
    };
    const popover = (
      <Popover id="fromWorker">
        {i18next.t("transactions.messages.fromWorker")}
      </Popover>
    );
    return (
      <OverlayTrigger
        trigger={["hover", "focus"]}
        placement="left"
        overlay={popover}
      >
        <span style={materialIconStyle}>
          <i className="material-icons" style={materialIconStyle}>
            cloud_done
          </i>
        </span>
      </OverlayTrigger>
    );
  }

  formatFromCard(transaction, id) {
    if (!transaction.isCreatedByAggregation) {
      return null;
    }

    const materialIconStyle = {
      fontSize: 14,
      marginLeft: 2,
      marginRight: 2,
    };

    const popover = (
      <Popover id="fromCard">
        {i18next.t("transactions.messages.fromCard")}
      </Popover>
    );
    return (
      <OverlayTrigger
        trigger={["hover", "focus"]}
        rootClose
        placement="left"
        overlay={popover}
      >
        <i className="material-icons" style={materialIconStyle}>
          credit_card
        </i>
      </OverlayTrigger>
    );
  }

  historyPopover(histories, id) {
    return (
      <Popover
        id={`history-popup-${id}`}
        placement="left"
        className="popup-image"
      >
        <span>
          {i18next.t("transactions.histories.messages.count", {
            count: histories.length,
          })}
        </span>
      </Popover>
    );
  }

  formatHistories(histories, id) {
    if (isNil(histories) || histories.length === 0) {
      return null;
    }
    return this.formatIconWithOverlay(
      this.historyPopover(histories, id),
      "fas fa-history primary txt-pointer",
      id,
      { onClick: this.handleHistoryIconClick.bind(null, id) },
    );
  }

  formatCurrency(transaction) {
    if (
      !transaction.originalAmountCurrencyId ||
      transaction.originalAmountCurrencyId === transaction.baseCurrencyId
    ) {
      return null;
    }
    const materialIconStyle = {
      fontSize: 14,
      marginLeft: 2,
      marginRight: 2,
    };
    const popover = (
      <Popover id="currency-popup">
        {i18next.t("transactions.messages.foreignCurrency")}
      </Popover>
    );
    return (
      <OverlayTrigger
        trigger={["hover", "focus"]}
        rootClose
        placement="left"
        overlay={popover}
      >
        <i className="material-icons" style={materialIconStyle}>
          monetization_on
        </i>
      </OverlayTrigger>
    );
  }

  formatIsElectronicReceiptImage(isElectronicReceiptImage, id) {
    if (!userPreferences.preference.enableExpenseElectronicReceiptImageFlag) {
      return null;
    }
    if (!isElectronicReceiptImage) {
      return null;
    }

    return this.formatIconWithOverlay(
      this.detailsPopover(
        "IsElectronicReceiptImage",
        void 0,
        i18next.t("transactions.messages.isElectronicReceiptImage"),
      ),
      "fas fa-bolt",
      id,
    );
  }

  formatInfoIcons(column, expense) {
    return (
      <div className="icons">
        {this.formatImage(expense.receiptImages, expense.id)}
        {this.formatCompanions(expense.companions, expense.id)}
        {this.formatComment(expense.comment, expense.id)}
        {this.formatCard(expense, expense.id)}
        {this.formatFromWorker(expense, expense.id)}
        {this.formatFromCard(expense, expense.id)}
        {this.formatCurrency(expense)}
        {this.formatIsElectronicReceiptImage(
          expense.isElectronicReceiptImage,
          expense.id,
        )}
      </div>
    );
  }

  formatIsMultipleTaxRates(isMultipleTaxRates, expense) {
    const mark = isMultipleTaxRates ? "●" : "";
    return <span>{mark}</span>;
  }

  formatTaxCategories(expenseAmountPerTaxCategories, expense) {
    const names = (expenseAmountPerTaxCategories || []).map((o) => {
      return o.taxCategory.name;
    });
    return Formatter.text(names.join("、"));
  }

  formatGenericFields(column, transaction, genericFieldId) {
    const genericField = transaction.genericFields?.find(
      (gf) => gf.dataSetId === genericFieldId,
    );

    if (genericField) {
      const itemNames = genericField.items.map((i) => i.name).join("、");
      return Formatter.text(itemNames, "");
    }

    return "";
  }

  readStatus(column, trs) {
    const readMark = trs.read ? "" : "●";
    return <span className="read-mark">{readMark}</span>;
  }

  formatReceiptMatchingResultStatus(column, expense) {
    const foreside = get(expense, "receiptImages.foreside[0]", null);
    const backside = get(expense, "receiptImages.backside[0]", null);
    const { receiptExpenseMatching, isElectronicReceiptImage } = expense;

    if (isElectronicReceiptImage) {
      const popover = (
        <Popover id="receiptExpenseMatchingComment">
          <span style={{ whiteSpace: "pre-line", wordWrap: "break-word" }}>
            原本確認不要（電子領収書）
          </span>
        </Popover>
      );

      return (
        <span>
          <OverlayTrigger
            overlay={popover}
            placement={"top"}
            trigger={["click", "hover"]}
          >
            <span
              className="fa-stack"
              style={{
                cursor: "pointer",
                fontSize: "0.666em",
                verticalAlign: "top",
              }}
            >
              <i className="fa fa-circle fa-stack-2x txt-warning" />
              <i
                className="fa fa-window-minimize fa-stack-1x"
                style={{ fontSize: "12px", lineHeight: "1" }}
              />
            </span>
          </OverlayTrigger>
        </span>
      );
    }

    if (isNil(foreside) && isNil(backside)) {
      return (
        <span>
          <i className="fa fa-window-minimize"></i>
        </span>
      );
    }

    if (receiptExpenseMatching?.originalReceipt) {
      return (
        <span>
          <i className="fa fa-check fa-lg"></i>
        </span>
      );
    }

    if (receiptExpenseMatching?.status === "confirmed") {
      const popover = (
        <Popover
          id="receiptExpenseMatchingComment"
          title={i18next.t(
            "paperless.expense.forms.markingAsMatchedForm.labels.comment",
          )}
        >
          <span style={{ whiteSpace: "pre-line", wordWrap: "break-word" }}>
            {receiptExpenseMatching.comment}
          </span>
        </Popover>
      );

      return (
        <span>
          <OverlayTrigger
            overlay={popover}
            placement={"top"}
            trigger={["click", "hover"]}
          >
            <span
              className="fa-stack"
              style={{
                cursor: "pointer",
                fontSize: "0.666em",
                verticalAlign: "top",
              }}
            >
              <i className="fa fa-circle fa-stack-2x txt-warning" />
              <i className="fa fa-check fa-stack-1x" />
            </span>
          </OverlayTrigger>
        </span>
      );
    }

    return <span />;
  }

  formatIsElectronicReceiptImageForCellData(column, expense) {
    const { isElectronicReceiptImage } = expense;

    if (!isElectronicReceiptImage) {
      return <span />;
    }

    return <i className="fa fa-check" />;
  }

  formatDepartment(value, row) {
    // 申請済みの場合は編集不可にする
    if (!isNil(row.reportId) || !isNil(row.preReportId)) {
      return Formatter.text(value && value.name, null, null, true);
    }

    const formatter = this.editableSelectFormatter(
      "department",
      (v) => Formatter.text(v && v.name, null, null, true),
      (v) => Formatter.text(v && v.name, null, null, true),
      (v) => Formatter.text(v && v.name, null, null, true),
    );
    return formatter(value, row);
  }

  formatCardAsLabel(isCorporate, transaction) {
    const isCorporateLabel = isCorporate
      ? i18next.t("transactions.labels.noRefund")
      : "";

    return <span>{isCorporateLabel}</span>;
  }

  formatCompanionsAsLabel(companions, transaction) {
    const colleagues = companions.filter((c) => !c.isClient);
    const clients = companions.filter((c) => c.isClient);

    return (
      <div style={{ wordWrap: "break-word" }}>
        <CompanionList
          companions={colleagues}
          showCompany={true}
          companyName={i18next.t("companions.ourCompany")}
        />
        <CompanionList companions={clients} showCompany={true} />
      </div>
    );
  }

  formatOriginAndDestination(_, expense) {
    const { originByCategory, destinationByCategory } = expense;
    if (!originByCategory || !destinationByCategory) {
      return <span />;
    }

    return (
      <span>
        {Formatter.text(
          `${expense.originByCategory} → ${expense.destinationByCategory}`,
        )}
      </span>
    );
  }

  formatEligibleInvoice(status) {
    if (status) return <i className="fa fa-check color-success"></i>;
    return "";
  }
}

TransactionTable.defaultProps = {
  members: [],
  columnVisibilities: {},
  columnSelectable: true,
  histories: [],
  selectedTransactionIdForHistory: null,
  onRowClick() {},
  isAddable: false,
  isEditable: false,
  isDeletable: false,
  isDetachable: false,
  isEditing: false,
  isStatic: false,
  closeHistoryModal() {
    /* TODO: 編集履歴の表示が必要ない時は、Modalを描画しない */
  },
  hasPreReport: false,
  reportRequiresWithholding: true,
  onMouseEnterCostAlocationDiv: () => {},
  onMouseLeaveCostAlocationDiv: () => {},
  hideSelectColumn: false,
};

TransactionTable.propTypes = {
  transactions: PropTypes.arrayOf(PropTypes.shape(transactionType)).isRequired,
  members: PropTypes.arrayOf(PropTypes.object).isRequired,
  currentPage: PropTypes.number.isRequired,
  sizePerPage: PropTypes.number.isRequired,
  columnVisibilities: PropTypes.object.isRequired,
  columnSelectable: PropTypes.bool.isRequired,
  histories: PropTypes.array.isRequired,
  selectedTransactionIdForHistory: PropTypes.string,
  ownerId: PropTypes.string,
  isEditable: PropTypes.bool.isRequired,
  isEditing: PropTypes.bool.isRequired,
  isStatic: PropTypes.bool.isRequired,
  isSearchScopeAll: PropTypes.bool.isRequired,
  formData: PropTypes.shape({
    transactedAt: PropTypes.string,
    shopName: PropTypes.string,
    amount: PropTypes.string,
  }),
  fetchTransactions: PropTypes.func.isRequired,
  onRowClick: PropTypes.func.isRequired,
  onSelect: PropTypes.func,
  onSelectAll: PropTypes.func,
  onPageChange: PropTypes.func.isRequired,
  onSizePerPageList: PropTypes.func,
  onUpdateTransaction: PropTypes.func,
  onChangeTransaction: PropTypes.func,
  onChangeSelectInput: PropTypes.func,
  onSuggestionsUpdateRequested: PropTypes.func,
  onColumnSelectionChange: PropTypes.func,
  onRestoreDefaultColumnSelectionClick: PropTypes.func,
  resetTransactions: PropTypes.func,
  onFetchPresignedUrl: PropTypes.func,
  only: PropTypes.array,
  except: PropTypes.array,
  selected: PropTypes.arrayOf(PropTypes.string),
  hasPreReport: PropTypes.bool.isRequired,
  reportRequiresWithholding: PropTypes.bool.isRequired,
  openCostAllocationModal: PropTypes.func,
  costAllocationHovorRowIdx: PropTypes.number,
  onMouseEnterCostAlocationDiv: PropTypes.func,
  onMouseLeaveCostAlocationDiv: PropTypes.func,
  hideSelectColumn: PropTypes.bool,
};
