import React from 'react';
import i18next from 'i18next';
import styled from 'styled-components';
import { Button } from 'components/renewaled_ui/buttons/Button';
import { Modal } from 'react-bootstrap';

interface Props {
  show: boolean;
  processing: boolean;
  onClose: () => void;
  onDelete: () => void;
}

const ModalFooterView = styled(Modal.Footer)`
  display: flex;
  padding: 15px 6px;
  flex-direction: row;
  column-gap: 8px;
  align-items: center;
  justify-content: center;

  button:last-child {
    margin-left: auto;
  }
`;

export const DeleteConfirmModal: React.FC<Props> = ({
  show,
  processing,
  onClose,
  onDelete,
}) => {
  return (
    <Modal show={ show } onHide={ onClose }>
      <Modal.Header closeButton>{ i18next.t('accountingDataScheduledExports.tasks.deleteConfirmModal.title') }</Modal.Header>
      <Modal.Body>
        { i18next.t('accountingDataScheduledExports.tasks.deleteConfirmModal.message1') }<br/>
        { i18next.t('accountingDataScheduledExports.tasks.deleteConfirmModal.message2') }
      </Modal.Body>
      <ModalFooterView>
        <Button styleType='danger' onClick={ onDelete } disabled={ processing }>
          { i18next.t('commons.actions.delete') }
        </Button>
        <Button styleType='link-secondary' onClick={ onClose } disabled={ processing }>
          { i18next.t('commons.actions.cancel') }
        </Button>
      </ModalFooterView>
    </Modal>
  );
};
