import i18next from "i18n";
import React, { useEffect, useState } from "react";
import { AsyncPaginate } from "react-select-async-paginate";
import { ColourOption } from "react-select/async";
import Api from "utilities/api";
import { Project } from "utilities/api/models/Project";
import flash from "utilities/flash";
import { getMessageFromResponse, snakecaseKeys } from "utilities/Utils";

interface Props {
  /** 分割元経費のプロジェクトID */
  projectId: string;
  /** 選択時のハンドラ */
  onSelect: (o: Project) => void;
}

interface ProjectOption {
  options: Project[];
  hasMore: boolean;
}

/** プロジェクト選択欄（非同期） */
export const ProjectAsyncSelect: React.FC<Props> = ({
  projectId,
  onSelect,
}) => {
  // デフォルトの選択候補
  const [defaultOptions, setDefaultOptions] = useState<Project[]>([]);
  // 選択されているプロジェクト
  const [selectedProject, setSelectedProject] = useState<Project>();
  const [totalProjects, setTotalProjects] = useState<number | null | undefined>(
    null,
  );

  /**
   * デフォルト値の初期化処理 (初回ロード時のみ実行)
   */
  useEffect(() => {
    Api.projects
      .index(snakecaseKeys({ limit: 30 }))
      .then((response) => {
        setTotalProjects(response.count);
        setDefaultOptions(response.projects);
      })
      .catch((e) => {
        flash.error(getMessageFromResponse(e));
      });
  }, []);

  /**
   * 対象中のプロジェクトについて、IDから必要な情報を埋め合わせる
   */
  useEffect(() => {
    if (projectId && projectId !== "") {
      Api.projects
        .show({ id: projectId })
        .then((response: Project) => {
          setSelectedProject({
            id: response.id,
            name: response.name,
            displayId: response.displayId,
          });
        })
        .catch((e) => {
          flash.error(getMessageFromResponse(e));
        });
    }
  }, [projectId]);

  /**
   * 文字入力時に非同期で候補取得
   * @param inputValue 入力欄に書き込まれている文字列
   * @see https://react-select.com/async
   */
  const getProjects = (
    inputValue: string,
    offset = 0,
  ): Promise<ColourOption[]> => {
    return Api.projects
      .index(snakecaseKeys({ searchText: inputValue, limit: 30, offset }))
      .then((response) => {
        setTotalProjects(response.count);
        return response.projects;
      })
      .catch((e) => {
        flash.error(getMessageFromResponse(e));
        return [];
      });
  };

  const loadOptions = async (
    inputValue: string,
    loadedOptions,
  ): Promise<ProjectOption> => {
    if (loadedOptions.length === 0) {
      const options = await getProjects(inputValue, loadedOptions.length);

      return {
        options: inputValue ? options : defaultOptions,
        hasMore:
          options.length > 0 &&
          (totalProjects ? totalProjects > defaultOptions.length : false),
      };
    }

    const options = await getProjects(inputValue, loadedOptions.length);
    const hasMore =
      options.length > 0 &&
      (totalProjects ? totalProjects > loadedOptions.length + 30 : false);

    return {
      options,
      hasMore,
    };
  };

  /**
   * 選択中となっているプロジェクトを更新する
   * @param project 選択されたプロジェクト
   */
  const onSelectProject = (project: Project): void => {
    setSelectedProject(project);
    onSelect(project);
  };

  return (
    <div
      title={
        selectedProject?.name ||
        i18next.t("expenses.split.amountBySeparatorsEditor.projectSelectTitle")
      }
    >
      <AsyncPaginate
        value={selectedProject}
        cacheOptions
        isClearable
        loadOptions={loadOptions}
        onChange={onSelectProject}
        getOptionLabel={(o: Project): string => o.name || ""}
        getOptionValue={(o: Project): string => o.id}
        placeholder={i18next.t("commons.status.notSelected")}
        additional={{ page: 1 }}
      />
    </div>
  );
};
