import React from 'react';
import { AcceptInvitationForm } from './components/AcceptInvitationForm';
import { BottomLogo } from './components/BottomLogo';
import { Notification } from './components/Notification';
import { NotificationMessage } from './types/index';
import { PageLinks } from './components/PageLinks';
import { SignInTitle } from './components/SignInTitle';

// type
interface Props {
  invitationToken: string;
  errors: string[] | null;
}

// ページリンク
export const pageLinks = [
  {
    name: 'ログイン画面に戻る',
    href: '/users/sign_in',
  },
  {
    name: '登録メールが届かない場合',
    href: '/users/confirmation/new',
  },
];

export const AcceptInvitationPage: React.FC<Props> = (props) => {
  // 基本フロントでバリデーションしているためprops.errorsが下りてくることは無いが、念のため。
  const errorMessage = props.errors ? props.errors[0] : '';
  const notification: NotificationMessage = { message: errorMessage, className: 'txt-caution' };

  const handleSubmit = (form: HTMLFormElement | null): void => {
    if (form) form.submit();
  };

  return (
    <div>
      <SignInTitle pageExplanation="招待の承認・登録" />
      <Notification notification={ notification } />
      <AcceptInvitationForm
        invitationToken={ props.invitationToken }
        // @note testing-libraryでonSubmitが実行されたことを検査するため、
        //       page階層にhandleSubmitを持たせる
        onSubmit={ handleSubmit } />
      <PageLinks pageLinks={ pageLinks } />
      <BottomLogo />
    </div>
  );
};
