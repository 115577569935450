import FormItem from './FormItem';
import PropTypes from 'prop-types';
import React from 'react';
import formatter from 'utilities/formatter';

const StorageCode = (props) => {
  return (
    <FormItem label='storageCode'>
      { formatter.text(props.value, '-') }
    </FormItem>
  );
};

StorageCode.propTypes = {
  value: PropTypes.string,
};

export default React.memo(StorageCode);
