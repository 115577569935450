import * as approvalFlowsActions from './approvalFlowsActions';
import * as memberApprovalFlowSearchActions from './memberApprovalFlowSearchActions';
import * as membersActions from './membersActions';
import Api from 'utilities/api';
import get from 'lodash/get';
import i18next from 'i18n';
import isArray from 'lodash/isArray';
import isEmpty from 'lodash/isEmpty';
import isNil from 'lodash/isNil';
import makeAutoSuggestActions from 'actions/autoSuggest';
import { displayMessage } from 'actions/ActionCreators';
import { fetchAsyncAll } from 'actions/AsyncAction';
import { getMessageFromResponse, snakecaseKeys, sortNestedArrayByFamily } from 'utilities/Utils';

const prefix = `flow_form`;

export function fetchCategories() {
  return async (dispatch) => {
    try {
      const { data } = await fetchAsyncAll(
        (params) => Api.rootCategories.index(snakecaseKeys({
          enable: true,
          ...params,
        })).then((result) => {
          return { data: result.categories, count: result.count };
        }),
        { /* APIを投げる際の追加パラメータは特になし */ },
        { limit: 200 },
      );

      const categories = sortNestedArrayByFamily(data)
        .filter((category) => category.selectable)
        .map((category) => ({
          id:category.id,
          name:category.absolutePathName,
          selectable: category.selectable,
        }));
      dispatch(initCategorySuggestions(categories));
    } catch (error) {
      dispatch(displayMessage('error', getMessageFromResponse(error)));
    }
  };
}

export function fetchApprovers() {
  return async (dispatch, getState) => {
    try {
      const data = await Api.approvers.index();
      dispatch(resetUsersForApprovers(data.users));
      dispatch(resetGroupsForApprovers(data.groups));
      dispatch(resetUserDepartmentPostsForApprovers(data.posts));
      dispatch(resetProjectPostsForApprovers(data.projectPosts));
      dispatch(resetPayerDepartmentPostsForApprovers(data.posts));
    } catch (e) {
      dispatch(displayMessage('error', getMessageFromResponse(e)));
    }
  };
}

export const RESET_APPROVERS = `${prefix}/RESET_APPROVERS`;
export function resetApprovers(approverType, approvers) {
  return {
    type: RESET_APPROVERS,
    data: approvers,
    approverType,
  };
}

export const resetUsersForApprovers = resetApprovers.bind(null, 'users');

export const resetGroupsForApprovers = resetApprovers.bind(null, 'groups');

export const resetUserDepartmentPostsForApprovers = resetApprovers.bind(null, 'userDepartmentPosts');

export const resetProjectPostsForApprovers = resetApprovers.bind(null, 'projectPosts');

export const resetPayerDepartmentPostsForApprovers = resetApprovers.bind(null, 'payerDepartmentPosts');

export const RESET_APPROVERS_CHECK = `${prefix}/RESET_APPROVERS_CHECK`;
export function resetApproversCheck(
  approvers,
  { isUpperLevelPostSelecting = false, relativeLevel = 0, nearestPostsApprover = false },
) {
  const checkedIds = approvers.reduce((acc, x) => {
    switch (x.type) {
      case 'user':
        acc.users.push(x.id);
        break;
      case 'group':
        acc.groups.push(x.id);
        break;
      case 'company_post':
        acc.userDepartmentPosts.push(x.id);
        break;
      case 'project_post':
        acc.projectPosts.push(x.id);
        break;
      case 'payer_department_post':
        acc.payerDepartmentPosts.push(x.id);
        break;
      case 'department_post_relationship':
        acc.departmentPostRelationships.push({ id: x.id, postId: x.postId });
        break;
      default:
        break;
    }

    return acc;
  }, {
    users: [],
    groups: [],
    userDepartmentPosts: [],
    projectPosts: [],
    payerDepartmentPosts: [],
    departmentPostRelationships: [],
  });

  const userDepartmentOptions = {
    isUpperLevelPostSelecting: !isEmpty(checkedIds.userDepartmentPosts) && isUpperLevelPostSelecting,
    relativeLevel: !isEmpty(checkedIds.userDepartmentPosts) ? relativeLevel : null,
    nearestPostsApprover: !isEmpty(checkedIds.userDepartmentPosts) && nearestPostsApprover,
  };
  const payerDepartmentOptions = {
    isUpperLevelPostSelecting: !isEmpty(checkedIds.payerDepartmentPosts) && isUpperLevelPostSelecting,
    relativeLevel: !isEmpty(checkedIds.payerDepartmentPosts) ? relativeLevel : null,
    nearestPostsApprover: !isEmpty(checkedIds.payerDepartmentPosts) && nearestPostsApprover,
  };

  return {
    type: RESET_APPROVERS_CHECK,
    userDepartmentOptions,
    payerDepartmentOptions,
    checkedIds,
  };
}

export function checkApprover(approverType, approvers, approverId, checked) {
  const nextApprovers = approvers.map((approver) => {
    if (approverId === approver.id) {
      return {
        ...approver,
        isChecked: checked,
      };
    }
    return approver;
  });
  return setCheckedApprovers(approverType, nextApprovers);
}

export const TOGGLE_UPPER_LEVEL_SELECTOR = `${prefix}/TOGGLE_UPPER_LEVEL_SELECTOR`;
export function toggleUpperLevelSelector(approverType, checked) {
  return {
    type: TOGGLE_UPPER_LEVEL_SELECTOR,
    approverType,
    show: checked,
  };
}

export const TOGGLE_NEAREST_POST_APPROVER = `${prefix}/TOGGLE_NEAREST_POST_APPROVER`;
export function toggleNearestPostApprover(approverType, checked) {
  return {
    type: TOGGLE_NEAREST_POST_APPROVER,
    approverType,
    value: checked,
  };
}

export const SET_RELATIVE_LEVEL = `${prefix}/SET_RELATIVE_LEVEL`;
export function setRelativeLevel(approverType, level) {
  return {
    type: SET_RELATIVE_LEVEL,
    approverType,
    value: level,
  };
}

export const SET_CHECKED_APPROVERS = `${prefix}/SET_CHECKED_APPROVERS`;
export function setCheckedApprovers(approverType, approvers) {
  return {
    type: SET_CHECKED_APPROVERS,
    approverType,
    data: approvers,
  };
}

export const SET_EDITING_APPROVAL_INDEX = `${prefix}/SET_EDITING_APPROVAL_INDEX`;
export function setEditingApprovalIndex(index) {
  return {
    type: SET_EDITING_APPROVAL_INDEX,
    value: index,
  };
}

export function openNew(currentLength) {
  return (dispatch) => {
    dispatch(setNewApprovalFlow(currentLength));
    dispatch(openEditModal());
  };
}

export function openNewAsApproverSelect(currentLength) {
  return (dispatch) => {
    dispatch(setNewApproverSelectApprovalFlow(currentLength));
    dispatch(openEditModal());
  };
}

export const SET_APPROVAL_FLOW = `${prefix}/SET_APPROVAL_FLOW`;
export function setApprovalFlow(approvalFlow) {
  return {
    type: SET_APPROVAL_FLOW,
    approvalFlow,
  };
}

export const INIT_APPROVAL_FLOW = `${prefix}/INIT_APPROVAL_FLOW`;
export function initApprovalFlow(categories, approvalFlow) {
  return (dispatch, getState) => {
    const { rule } = approvalFlow;
    if (!isNil(rule)) {
      const newRule = {
        expression: rule.expression,
        ast: rule.ast.map((xs) => {
          return xs.map((x) => {
            switch (x.left.key) {
              case 'category': {
                return {
                  ...x,
                  right: {
                    ...x.right,
                    text: x.right.value.name,
                    suggestions: [...categories],
                  },
                };
              }
              case 'project':
              case 'department':
              case 'payer_department':  {
                return {
                  ...x,
                  right: {
                    ...x.right,
                    suggestions: [],
                  },
                };
              }
              case 'amount':
              case 'total_amount': {
                return {
                  ...x,
                  right: {
                    ...x.right,
                    text: x.right.expression,
                  },
                };
              }
              default: {
                return x;
              }
            }
          });
        }),
      };

      dispatch(setApprovalFlow({ ...approvalFlow, rule: newRule }));
      return;
    }

    dispatch(setApprovalFlow(approvalFlow));
  };
}

export const SET_NEW_APPROVAL_FLOW = `${prefix}/SET_NEW_APPROVAL_FLOW`;
function setNewApprovalFlow(currentLength) {
  return {
    type: SET_NEW_APPROVAL_FLOW,
    currentLength,
  };
}

export const SET_NEW_APPROVER_SELECT_APPROVAL_FLOW = `${prefix}/SET_NEW_APPROVER_SELECT_APPROVAL_FLOW`;
function setNewApproverSelectApprovalFlow(currentLength) {
  return {
    type: SET_NEW_APPROVER_SELECT_APPROVAL_FLOW,
    currentLength,
  };
}

export const SET_SHOW_MODAL = `${prefix}/SET_SHOW_MODAL`;
export function openEditModal() {
  return {
    type: SET_SHOW_MODAL,
    showModal: true,
  };
}

export function closeEditModal() {
  return {
    type: SET_SHOW_MODAL,
    showModal: false,
  };
}

export const CHANGE_ASYNC_API_STATE = `${prefix}/CHANGE_ASYNC_API_STATE`;
export function callAsyncApi() {
  return {
    type: CHANGE_ASYNC_API_STATE,
    payload: true,
  };
}

export function doneAsyncApi() {
  return {
    type: CHANGE_ASYNC_API_STATE,
    payload: false,
  };
}

export function save(approvalFlow) {
  return async (dispatch, getState) => {
    const api = approvalFlow.id ? Api.approvalFlows.update : Api.approvalFlows.create;

    try {
      const data = await api(snakecaseKeys(approvalFlow));
      dispatch(displayMessage('success', data.message || i18next.t('commons.messages.saved')));
      dispatch(closeEditModal());
      dispatch(membersActions.resetMembers(1));
      dispatch(approvalFlowsActions.fetchApprovalFlows());
    } catch (e) {
      dispatch(displayMessage('error', getMessageFromResponse(e)));
    }
  };
}

export function copy(approvalFlow) {
  const params = {
    id: approvalFlow.id,
    approvals: approvalFlow.approvals,
    rule: collectApprovalFlowRuleParams(approvalFlow.rule),
  };
  return async (dispatch, _getState) => {
    try {
      const data = await Api.approvalFlows.copy(snakecaseKeys(params));
      dispatch(displayMessage('success', data.message || i18next.t('commons.messages.copied')));
      dispatch(membersActions.resetMembers(1));
      dispatch(approvalFlowsActions.fetchApprovalFlows());
    } catch (e) {
      dispatch(displayMessage('error', getMessageFromResponse(e)));
    }
  };
}

export function destroy(flow, rejectApproval = false) {
  return async (dispatch, getState) => {
    const params = { id: flow.id, rejectApproval };
    try {
      const data = await Api.approvalFlows.destroy(snakecaseKeys(params));
      dispatch(displayMessage('success', i18next.t('commons.messages.deleted')));
      await dispatch(approvalFlowsActions.fetchApprovalFlows());
      const { approvalFlows } = getState();
      if (!approvalFlows.some((a) => a.id === flow.id)) {
        dispatch(memberApprovalFlowSearchActions.selectApprovalFlow(null));
      }
      dispatch(membersActions.resetMembers(1));
      dispatch(setDeletingFlow(null));
    } catch (error) {
      dispatch(displayMessage('error', getMessageFromResponse(error)));
    }
  };
}

/** FIXME: 単なる値成形は Redux action ではないため、ここに実装すべきではありません */
export function collectApprovalFlowRuleParams(rule) {
  if (isNil(rule)) {
    return null;
  }

  return rule.ast.map((orRule) => {
    return orRule.map((andRule) => {
      const valueType = get(andRule, 'right.type');
      return {
        type: andRule.type,
        left: {
          type: get(andRule, 'left.type'),
          key: get(andRule, 'left.key'),
        },
        right: {
          type: valueType,
          value: valueType === 'reference' ? collectApprovalFlowRuleReferenceParams(andRule) : constantRightValue(andRule),
        },
      };
    });
  });
}

function constantRightValue(rule) {
  if (Object.prototype.hasOwnProperty.call(rule.right, 'text')) {
    return rule.right.text;
  }

  return get(rule, 'right.value');
}

/** FIXME: 単なる値成形は Redux action ではないため、ここに実装すべきではありません */
function collectApprovalFlowRuleReferenceParams(rule) {
  const val = get(rule, 'right.value');

  if (isNil(val)) {
    return '';
  }

  if (isArray(val)) {
    return val.map((v) => get(v, 'id'));
  }

  return get(val, 'id');
}

/** FIXME: 単なる値成形は Redux action ではないため、ここに実装すべきではありません */
export function collectApprovalFlowParams(id, approvalFlow, rejectApproval = false) {
  return {
    id,
    name: approvalFlow.name,
    rule: collectApprovalFlowRuleParams(approvalFlow.rule),
    priority: approvalFlow.priority,
    isApproverSelectFlow: approvalFlow.isApproverSelectFlow,
    rejectApproval,
    approvals: approvalFlow.approvals,
  };
}

export const SET_DELETING_FLOW = `${prefix}/SET_DELETING_FLOW`;
export function setDeletingFlow(flow) {
  return {
    type: SET_DELETING_FLOW,
    data: flow,
  };
}

/*
 * フロー情報編集用
 */
export const SET_APPROVAL_FLOW_NAME = `${prefix}/SET_APPROVAL_FLOW_NAME`;
export function setApprovalFlowName(name) {
  return {
    type: SET_APPROVAL_FLOW_NAME,
    value: name,
  };
}

export const SET_APPROVAL_FLOW_PRIORITY = `${prefix}/SET_APPROVAL_FLOW_PRIORITY`;
export function setApprovalFlowPriority(priority) {
  return {
    type: SET_APPROVAL_FLOW_PRIORITY,
    value: priority,
  };
}

/*
 * 条件設定用のaction
 */
export const SET_APPROVAL_RULE = `${prefix}/SET_APPROVAL_RULE`;
export function setApprovalRule(rules) {
  return {
    type: SET_APPROVAL_RULE,
    data: rules,
  };
}

export function insertRule(rules, rule, orIndex, andIndex) {
  return (dispatch, getState) => {
    const orRule = rules[orIndex] || [];
    const nextRules = [
      ...rules.slice(0, orIndex),
      [...orRule.slice(0, andIndex), rule, ...orRule.slice(andIndex)],
      ...rules.slice(orIndex + 1),
    ];

    dispatch(setApprovalRule(nextRules));
  };
}

export function removeRule(rules, orIndex, andIndex) {
  return (dispatch, getState) => {
    const orRule = [...rules[orIndex].slice(0, andIndex), ...rules[orIndex].slice(andIndex + 1)];

    // orRuleが空の時は、ルール毎削除する
    const nextRules = orRule.length > 0 ? [
      ...rules.slice(0, orIndex),
      [...rules[orIndex].slice(0, andIndex), ...rules[orIndex].slice(andIndex + 1)],
      ...rules.slice(orIndex + 1),
    ] : [
      ...rules.slice(0, orIndex),
      ...rules.slice(orIndex + 1),
    ];

    dispatch(setApprovalRule(nextRules));
  };
}

export function updateRule(rules, rule, orIndex, andIndex) {
  return (dispatch, getState) => {
    const nextRules = [
      ...rules.slice(0, orIndex),
      [...rules[orIndex].slice(0, andIndex), rule, ...rules[orIndex].slice(andIndex + 1)],
      ...rules.slice(orIndex + 1),
    ];

    dispatch(setApprovalRule(nextRules));
  };
}

export const SET_RULE_VALUE = `${prefix}/SET_RULE_VALUE`;
export function setRuleValue(orIndex, andIndex, value) {
  return {
    type: SET_RULE_VALUE,
    value,
    orIndex,
    andIndex,
  };
}

export const INIT_CATEGORY_SUGGESTIONS = `${prefix}/INIT_CATEGORY_SUGGESTIONS`;
export const UPDATE_CATEGORY_SUGGESTIONS = `${prefix}/UPDATE_CATEGORY_SUGGESTIONS`;

const categorySuggestActions = makeAutoSuggestActions({
  initType: INIT_CATEGORY_SUGGESTIONS,
  updateType: UPDATE_CATEGORY_SUGGESTIONS,
  selectType: null, // 使用しない
  setType: null, // 使用しない
}, (x) => x.name);

export const initCategorySuggestions = categorySuggestActions.initSuggestions;
export const updateCategorySuggestions = categorySuggestActions.updateSuggestions;
export const requestCategorySuggestionsUpdate = categorySuggestActions.requestSuggestionsUpdate;

/*
 * 承認フローの情報を更新する（削除、または作成）
 *
 * @param {object[]} approvals
 * @param {string} action  'add' or 'edit' or 'remove'
 * @param {object} param 'add', 'edit' の時は、indexとapprovalを指定。'remove'の時はindexを指定
 * @return {object[]}
 */
export function updateApprovals(approvals, action = 'add', param = {}) {
  switch (action) {
    case 'add': {
      return [
        ...approvals.slice(0, param.index),
        param.approval,
        ...approvals.slice(param.index),
      ];
    }
    case 'edit': {
      return [
        ...approvals.slice(0, param.index),
        param.approval,
        ...approvals.slice(param.index + 1),
      ];
    }
    case 'remove': {
      return [
        ...approvals.slice(0, param.index),
        ...approvals.slice(param.index + 1),
      ];
    }
    default: {
      return approvals;
    }
  }
}

export function insertNewApproval(index, approvals) {
  const approval = {
    condition: 'any',
    editable: true,
    skippable: true,
    selfApprovable: false,
    selfApprovalSkippable: false,
    nearestPostsApprover: false,
    approvers: [],
    label: null,
    created: true,
  };
  const param = { index, approval };
  return setApprovals(updateApprovals(approvals, 'add', param));
}

export function removeApproval(index, approvals) {
  const param = { index };
  return setApprovals(updateApprovals(approvals, 'remove', { index }));
}

export const SET_APPROVALS = `${prefix}/SET_APPROVALS`;
export function setApprovals(approvals) {
  return {
    type: SET_APPROVALS,
    data: approvals,
  };
}

export function setApprovalProp(prop, approvals, index, value) {
  const newApproval = {
    ...approvals[index],
    [prop]: value,
  };

  const param = { index, approval: newApproval };
  return setApprovals(updateApprovals(approvals, 'edit', param));
}

export function setApprovalSelfApprovable(approvals, index, value, isApproverSelectFlow) {
  const approval = approvals[index];
  // 「誰か1人」の場合は、自己承認可フラグのセットのタイミングで、自己承認スキップフラグもセットし直す
  // ただし、承認者選択フローの場合は自己承認スキップができないので無効にする
  let skippable;
  if (isApproverSelectFlow || value) {
    skippable = false;
  } else {
    skippable = approval.condition === 'any';
  }

  const newApproval = {
    ...approval,
    selfApprovable: value,
    selfApprovalSkippable: skippable,
  };

  const param = { index, approval: newApproval };
  return setApprovals(updateApprovals(approvals, 'edit', param));
}

export function setApprovalCondition(approvals, index, value) {
  // 自己承認不可の場合は自己承認スキップフラグもセットし直す
  let skippable = true;
  if (value === 'all') {
    skippable = false;
  } else {
    skippable = !approvals[index].selfApprovable;
  }

  const newApproval = {
    ...approvals[index],
    condition: value,
    selfApprovalSkippable: skippable,
  };

  const param = { index, approval: newApproval };
  return setApprovals(updateApprovals(approvals, 'edit', param));
}

export const setApprovalEditable = setApprovalProp.bind(null, 'editable');
export const setApprovalSkippable = setApprovalProp.bind(null, 'skippable');
export const setApprovalSelfApprovalSkippable = setApprovalProp.bind(null, 'selfApprovalSkippable');
export function setApprovers(
  approvals,
  index,
  approvers,
  { relativeLevel, nearestPostsApprover },
) {
  const newApprovers = approvers.map((approver) => {
    if (['company_post', 'payer_department_post'].includes(approver.type)) {
      // TODO: 上位部署が指定された場合に、`${relativeLevel}つ上の部署の役職`と表示されるようにする
      return {
        ...approver,
        relativeLevel,
      };
    }

    return {
      ...approver,
    };
  });
  const newApproval = {
    ...approvals[index],
    approvers: newApprovers,
    nearestPostsApprover,
  };

  const param = { index, approval: newApproval };
  return setApprovals(updateApprovals(approvals, 'edit', param));
}

export const TOGGLE_ADD_APPROVER_MODAL = `${prefix}/TOGGLE_ADD_APPROVER_MODAL`;
export function toggleAddApproverModal(show) {
  return {
    type: TOGGLE_ADD_APPROVER_MODAL,
    show,
  };
}

export const openAddApproverModal = toggleAddApproverModal.bind(null, true);
export const closeAddApproverModal = toggleAddApproverModal.bind(null, false);

export const TOGGLE_EDIT_CONFIRMATION_MODAL = `${prefix}/TOGGLE_EDIT_CONFIRMATION_MODAL`;
export function toggleEditConfirmationModal(show) {
  return {
    type: TOGGLE_EDIT_CONFIRMATION_MODAL,
    show,
  };
}

export const openEditConfirmationModal = toggleEditConfirmationModal.bind(null, true);
export const closeEditConfirmationModal = toggleEditConfirmationModal.bind(null, false);

export const TOGGLE_DELETE_CONFIRMATION_MODAL = `${prefix}/TOGGLE_DELETE_CONFIRMATION_MODAL`;
export function toggleDeleteConfirmationModal(show) {
  return {
    type: TOGGLE_DELETE_CONFIRMATION_MODAL,
    show,
  };
}

export const openDeleteConfirmationModal = toggleDeleteConfirmationModal.bind(null, true);
export const closeDeleteConfirmationModal = toggleDeleteConfirmationModal.bind(null, false);

export const UPDATE_APPROVERS = `${prefix}/UPDATE_APPROVERS`;
export function updateApprovers(approverType, target, value) {
  return {
    type: UPDATE_APPROVERS, approverType, target, value,
  };
}

export function mergeSearchApprovers() {
  return (dispatch, getState) => {
    const { result } = getState().approversSearch;
    const { approvers } = getState().flowForm;
    const approverKeyMap = [
      { currentApprovers: 'groups', searchResult: 'groups' },
      { currentApprovers: 'userDepartmentPosts', searchResult: 'posts' },
      { currentApprovers: 'projectPosts', searchResult: 'projectPosts' },
      { currentApprovers: 'users', searchResult: 'users' },
    ];
    const mergedSearchApprovers = {};

    approverKeyMap.forEach((keyMap) => {
      const checkedApprovers = approvers[keyMap.currentApprovers].current.filter((v) => v.isChecked);
      const mergedApprovers = checkedApprovers.concat(result[keyMap.searchResult]);

      // IDをベースとして重複値を解消
      mergedSearchApprovers[keyMap.currentApprovers] = mergedApprovers.filter((approver, idx, self) => self.findIndex((v) => v.id === approver.id) === idx);
      dispatch(updateApprovers(keyMap.currentApprovers, 'total', mergedSearchApprovers[keyMap.currentApprovers]));

      // 選択状態を付与
      mergedSearchApprovers[keyMap.currentApprovers].map((approver) => Object.assign(approver, { isChecked: Boolean(approver.isChecked) }));
      dispatch(updateApprovers(keyMap.currentApprovers, 'current', mergedSearchApprovers[keyMap.currentApprovers]));
    });
  };
}
