import i18next from 'i18n';

const usePreReportRequest = userPreferences.preference.requestTypes.some((v) => v.type === 'pre_report_request');

const formFields = [
  {
    id: null, type: 'date_input', sort: 0, label: i18next.t('transactions.properties.date'), conditions: [], conversionRules: [], defaultValue: null, editable: true, isRequired: false, validations: null,
  },
  {
    id: null, type: 'currency_and_rate_input', sort: 1, label: i18next.t('transactions.properties.currency'), conditions: [], conversionRules: [], defaultValue: null, editable: true, isRequired: false, validations: null,
  },
  {
    id: null, type: 'category_input', sort: 2, label: i18next.t('transactions.properties.category'), conditions: [], conversionRules: [], defaultValue: null, editable: true, isRequired: false, validations: null,
  },
  {
    id: null, type: 'super_category_input', sort: 3, label: i18next.t('transactions.properties.creditCategory'), conditions: [{ type: 'authority', operator: 'in', value: ['approver'] }], conversionRules: [], defaultValue: null, editable: true, isRequired: false, validations: null,
  },
  {
    id: null, type: 'tax_category_input', sort: 4, label: i18next.t('transactions.properties.taxCategory'), conditions: [], conversionRules: [], defaultValue: null, editable: true, isRequired: false, validations: null,
  },
  {
    id: null, type: 'assignable_report_input', sort: 5, label: usePreReportRequest ? i18next.t('reports.properties.preReportAndReport') : i18next.t('reports.types.report'), conditions: [], conversionRules: [], defaultValue: null, editable: true, isRequired: false, validations: null,
  },
  {
    id: null, type: 'project_input', sort: 6, label: i18next.t('transactions.properties.project'), conditions: [], conversionRules: [], defaultValue: null, editable: true, isRequired: false, validations: null,
  },
  {
    id: null, type: 'department_input', sort: 7, label: i18next.t('transactions.properties.userDepartment'), conditions: [], conversionRules: [], defaultValue: null, editable: true, isRequired: false, validations: null,
  },
  {
    id: null, type: 'cost_allocation_input', sort: 8, label: i18next.t('transactions.properties.department'), conditions: [], conversionRules: [], defaultValue: null, editable: true, isRequired: false, validations: null,
  },
  {
    id: null, type: 'refund_check_input', sort: 9, label: i18next.t('transactions.properties.noRefund'), conditions: [], conversionRules: [], defaultValue: null, editable: true, isRequired: false, validations: null,
  },
  {
    id: null, type: 'electronic_receipt_image_flag', sort: 10, label: i18next.t('transactions.properties.isElectronicReceiptImage'), conditions: [], conversionRules: [], defaultValue: null, editable: true, isRequired: false, validations: null,
  },
  {
    id: null, type: 'multi_line_text_input', sort: 11, label: i18next.t('transactions.properties.comment'), conditions: [], conversionRules: [], defaultValue: null, editable: true, isRequired: false, validations: null,
  },
];

export default function formFieldsForMultipleEdit(attached = false) {
  const preferences = userPreferences.preference;
  return formFields.reduce((array, field) => {
    switch (field.type) {
      case 'pre_report_input':
        return !attached && preferences.requestTypes.find((r) => r.type === 'pre_report_request') ? [...array, {
          ...field,
          editable: !attached,
        }] : array;
      case 'assignable_report_input':
        return !attached ? [...array, {
          ...field,
          editable: !attached,
        }] : array;
      case 'tax_category_input':
        return preferences.taxCategoryEditable ? [...array, field] : array;
      case 'cost_allocation_input':
        return preferences.inputGroup ? [...array, field] : array;
      case 'project_input':
        return preferences.inputProject ? [...array, field] : array;
      case 'refund_check_input':
        return preferences.noRefundEditable ? [...array, field] : array;
      default:
        return [...array, field];
    }
  }, []);
}
