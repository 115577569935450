import React, { FC } from 'react';
import i18next from 'i18n';
import styled from 'styled-components';
import {
  FilterOptionOption, OptionProps, components, createFilter,
} from 'react-select';
import { OverlayTrigger, Popover } from 'react-bootstrap';

import SelectField from 'components/fields/SelectField';
import { Condition,  ConditionOption } from 'utilities/api/models/JournalEntries';

interface Props {
  conditionOptions: ConditionOption[];
  condition: Condition;
  conditionIndex: number;
  orIndex: number;
  onSelect: (selectedOption: ConditionOption) => void;
}

const LeftHandSideFormView = styled.div`
  flex: initial;
  align-self: stretch;
  max-width: 250px;
  min-width: 200px;
  height: auto;
`;

const ConditionLeftHandSideForm: FC<Props> = (props) => {
  const getOptionValue = (option: ConditionOption): string => {
    // 付加情報や汎用マスタの場合、typeが同じoptionが存在するので、IDを後ろに付加して区別する
    if (option.left.metadataKeyId) {
      return `${option.left.type}-${option.left.metadataKeyId}`;
    } if (option.left.dataSetId) {
      return `${option.left.type}-${option.left.dataSetId}`;
    }
    return option.left.type;
  };

  const onChange = (e: ConditionOption): void => {
    const index = props.conditionOptions.findIndex(
      (option) => option.left.type === e.left.type
    && option.left.metadataKeyId === e.left.metadataKeyId
    && option.left.dataSetId === e.left.dataSetId,
    );

    props.onSelect(props.conditionOptions[index]);
  };

  const Option = (p: OptionProps<ConditionOption>): JSX.Element => {
    if (p.data.left.type === 'JournalEntries::CellValueConditions::LeftHands::Expenses::Constant::Aggregation') {
      return (
        <components.Option { ...p } children={
          <>
            { p.data.left.expression }
            <OverlayTrigger
              trigger={ ['hover'] }
              placement={ 'top' }
              overlay={ <Popover id='description'>{ i18next.t('journalEntries.descriptions.aggregationLeftHand') }</Popover> }>
              <i className='far fa-question-circle' style={ { color: '#AAA', marginLeft: '6px', cursor: 'pointer' } } />
            </OverlayTrigger>
          </>
        }/>
      );
    }
    return (
      <components.Option { ...p }/>
    );
  };

  return (
    <LeftHandSideFormView>
      <SelectField<ConditionOption>
        styles={ {
          // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
          menu: (baseStyles) => {
            return {
              ...baseStyles,
              width: '300px',
              zIndex: 1002,
            };
          },
        } }
        value={ props.condition.left.type === '' ? null : props.condition }
        placeholder={ i18next.t('journalEntries.placeholder.unselected') }
        options={ props.conditionOptions }
        getOptionLabel={ (x): ConditionOption => x.left.expression }
        getOptionValue={ getOptionValue }
        onChange={ (e): void => onChange(e) }
        isClearable={ false }
        filterOption={ createFilter({ stringify: (option: FilterOptionOption<ConditionOption>): string => {  return option.label; } }) }
        components={ { Option } }
    />
    </LeftHandSideFormView>
  );
};

export default ConditionLeftHandSideForm;
