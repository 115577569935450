import React from "react";
import { DropzoneInputProps, DropzoneRootProps } from "react-dropzone";
import styled from "styled-components";
import colors from "values/colors";

interface WraperProps {
  /** 高さ(px) */
  readonly h?: number;
}
const Wrapper = styled.div<WraperProps>`
  background-color: ${colors.main};
  position: absolute;
  top: 0;
  width: 100%;
  height: ${(p): string => `${p.h ?? 32}px`};
  width: 100%;
  padding: 0 8px;
  display: flex;
  align-items: center;
  font-size: ${(p): string => `${Math.floor((p.h ?? 32) * 0.7)}px`};
  color: ${colors.white};
  z-index: 99;

  > * {
    margin: 5px;
  }
  a {
    color: inherit;
  }
  // animation
  transition: 0.3s 1.2s transform;

  &.enter-active,
  &:hover {
    transition-delay: 0s;
    transform: translateY(0);
  }
  &:hover {
    cursor: auto; // 本体の画像のcursorがpointerの時、アイコンがない領域のcursorもpointerになるのを回避
  }
`;

interface Props {
  /** ファイル選択可能か */
  readonly showFileSelectButton: boolean;
  readonly rootProps: DropzoneRootProps;
  /** 回転可能か */
  readonly isTransformable: boolean;
  /** 削除可能か */
  readonly isDeletable: boolean;
  /** ダウンロードリンク */
  readonly downloadLink: string;
  /** ファイル名 */
  readonly fileName: string | undefined;
  /** ファイルinput */
  readonly getInputProps: (
    props?: DropzoneInputProps | undefined,
  ) => DropzoneInputProps;
  /** アップロードを押した */
  readonly onClickUpload: (
    e: React.MouseEvent<HTMLElement, MouseEvent>,
  ) => void;
  /** 削除を押した */
  readonly onClickDelete: (
    e: React.MouseEvent<HTMLDivElement, MouseEvent>,
  ) => void;
  /** 回転を押した */
  readonly onClickRotate: (
    e: React.MouseEvent<HTMLElement, MouseEvent>,
  ) => void;
  /** 拡大を押した */
  readonly onClickScaleUp: (
    e: React.MouseEvent<HTMLElement, MouseEvent>,
  ) => void;
  /** 縮小を押した */
  readonly onClickScaleDown: (
    e: React.MouseEvent<HTMLElement, MouseEvent>,
  ) => void;
  /** サイズを元に戻す押した */
  readonly onClickExpand: (
    e: React.MouseEvent<HTMLElement, MouseEvent>,
  ) => void;
}

/**
 * 画像ビューでのツールバー
 * NOTE: いずれToolBarPDFに置き換わる
 */
const ToolBarImage: React.FC<Props> = (p) => {
  const onClickToolbar = (e: React.MouseEvent<HTMLElement, MouseEvent>): void =>
    e.stopPropagation();
  return (
    <Wrapper onClick={onClickToolbar} className="image-toolbar-view">
      {
        // ファイルアップロードボタン
        p.showFileSelectButton && (
          <span role="button">
            <i {...p.rootProps} className="fas fa-exchange-alt" />
            <input {...p.getInputProps()} />
          </span>
        )
      }
      {
        /**
         * IE11対策（`justifi-content` と `margin: auto` を両立できない）.
         * IE11対応不要なら、 `justifi-content` を `flex-end` として、左揃えにしたい最後の要素の `margin-right` を `auto` にすれば良い.
         */
        <div style={{ marginRight: "auto" }} />
      }
      {
        // 回転・拡大・縮小・サイズ初期化ボタン
        p.isTransformable && (
          <>
            <i
              className="fas fa-redo"
              role="button"
              onClick={p.onClickRotate}
            />
            <i
              className="fa fa-search-plus"
              role="button"
              onClick={p.onClickScaleUp}
            />
            <i
              className="fa fa-search-minus"
              role="button"
              onClick={p.onClickScaleDown}
            />
            <i
              className="fas fa-expand-arrows-alt"
              role="button"
              onClick={p.onClickExpand}
            />
          </>
        )
      }
      {
        // ダウンロードリンク
        p.downloadLink && (
          <a href={p.downloadLink} download={p.fileName || true}>
            <i className="fa fa-download" role="button" />
          </a>
        )
      }
      {
        // 削除ボタン
        p.isDeletable && (
          <i
            className="fas fa-trash-alt text-danger"
            role="button"
            onClick={p.onClickDelete}
          />
        )
      }
    </Wrapper>
  );
};

export default ToolBarImage;
