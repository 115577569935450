import React, { FC, useCallback, useMemo } from 'react';
import Tag from 'applications/tags/components/Tag';
import TagSelector from 'applications/tags/components/TagSelector';
import i18next from 'i18n';
import styled from 'styled-components';
import { OverlayTrigger, Popover } from 'react-bootstrap';
import { PaymentMethodType } from 'utilities/api_payment_requests/models/PaymentMethod';
import { buildPaymentMethodOptions } from 'applications/payment_requests/values/RadioOptions';

const InputLikeView = styled.div`
  border: 1px solid #ccc;
  border-radius: 4px;
  box-shadow: inset 0 1px 1px rgb(0 0 0 / 8%);
  padding: 6px;
`;

const LabelContainerDiv = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: start;
  gap: 4px;
`;

type PaymentMethodFormProps = MultiSelectable | SingleSelectable;

type MultiSelectable = {
  multiSelectable: true;
  methods: PaymentMethodType[];
  onPaymentMethodsChange: (methods: PaymentMethodType[]) => void;
};

type SingleSelectable = {
  multiSelectable?: false;
  methods: PaymentMethodType | undefined;
  onPaymentMethodsChange: (methods: PaymentMethodType | undefined) => void;
};

type PaymentMethodTypeLabel = {
  id: PaymentMethodType;
  name: string;
  color: string;
  selected: boolean;
};

export const PaymentMethodField: FC<PaymentMethodFormProps> = (props) => {
  const paymentMethodOptions = useMemo(() => buildPaymentMethodOptions(), []);
  const paymentMethodLabels = useMemo<PaymentMethodTypeLabel[]>(() => {
    if (props.multiSelectable) {
      return (
        Object.entries(paymentMethodOptions).map(([key, name]) => ({
          id: key as PaymentMethodType, name, color: '#7F7F7F', selected: props.methods.includes(key as PaymentMethodType),
        }))
      );
    }

    return (
      Object.entries(paymentMethodOptions).map(([key, name]) => ({
        id: key as PaymentMethodType, name, color: '#7F7F7F', selected: props.methods === key as PaymentMethodType,
      }))
    );
  }, [paymentMethodOptions, props.methods, props.multiSelectable]);

  const handleSelectPaymentMethod = useCallback((method: PaymentMethodTypeLabel, selected: boolean) => {
    if (props.multiSelectable) {
      if (selected) props.onPaymentMethodsChange([...props.methods, method.id]);
      else props.onPaymentMethodsChange(props.methods.filter((m) => m !== method.id));

      return;
    }

    if (selected) props.onPaymentMethodsChange(method.id);
    else props.onPaymentMethodsChange(undefined);
  }, [props.methods, props.onPaymentMethodsChange]);

  const renderLabels = (methods: PaymentMethodType[]): JSX.Element => {
    if (methods.length === 0) {
      return (
        <span className='txt'>
          { i18next.t('commons.actions.unset') }
        </span>
      );
    }

    return (
      <LabelContainerDiv>
        {
          methods.map((method) => (
            <Tag
              key={ `label-${method}` }
              containerStyle={ { margin: '4px 0' } }
              name={ paymentMethodOptions[method] }
              color='#7F7F7F'
            />
          ))
        }
      </LabelContainerDiv>
    );
  };

  const renderLabel = (method: PaymentMethodType | undefined): JSX.Element => {
    if (!method) {
      return (
        <span className='txt'>
          { i18next.t('commons.actions.unset') }
        </span>
      );
    }

    return (
      <LabelContainerDiv>
        <Tag
          key={ `label-${method}` }
          containerStyle={ { margin: '4px 0' } }
          name={ paymentMethodOptions[method] }
          color='#7F7F7F'
        />
      </LabelContainerDiv>
    );
  };

  return (
    <OverlayTrigger
      trigger='click'
      placement='top'
      rootClose
      overlay={ (
        <Popover id='label-popup' title={ i18next.t('journalEntries.titles.selectPaymentMethodType') }>
          <TagSelector tags={ paymentMethodLabels } onClickTag={ handleSelectPaymentMethod } />
        </Popover>
      ) }>
      <InputLikeView className='txt-pointer'>
        { props.multiSelectable ? renderLabels(props.methods) : renderLabel(props.methods) }
      </InputLikeView>
    </OverlayTrigger>
  );
};
