import OriginalReceipt from './OriginalReceipt';
import PropTypes from 'prop-types';
import React from 'react';
import ReceiptFile from './ReceiptFile';
import camelCase from 'lodash/camelCase';
import fareFormatter from 'applications/fare_transactions/fare_formatter';
import formatter from 'utilities/formatter';
import get from 'lodash/get';
import i18next from 'i18n';
import isNil from 'lodash/isNil';

function formatAmountPerTaxCategories(logValues) {
  const amountPerTaxCategories = logValues.map((x) => x.value);
  const expr = amountPerTaxCategories.map(({ name, amount }) => {
    if (amount.includes('消費税額')) {
      const amountAndTaskAmount = amount.split(',');
      return `${name} ${formatter.amount(amountAndTaskAmount[0])} (${amountAndTaskAmount[1]}${formatter.amount(amountAndTaskAmount[2])})`;
    }

    return `${name} ${formatter.amount(amount)}`;
  }).join(', ');

  return formatter.text(expr);
}

function formatBoolean(bool) {
  if (bool) {
    return (<i className='far fa-circle' />);
  }

  return (<i className='fa fa-times' />);
}

function formatCostAllocation(logValues) {
  const costAllocations = logValues.map((x) => x.value);
  const expr = costAllocations.map((allocation) => {
    return `${allocation.groupName}(${allocation.percentage}%)`;
  }).join(', ');

  return formatter.text(expr);
}

function formatOption(option) {
  return `${option.name} (${option.value})`;
}

function formatPeriod(period) {
  return `${formatter.date(period.from)} 〜 ${formatter.date(period.to)}`;
}

function formatTableCell(tableCell) {
  return (
    <>
      { formatter.text(tableCell.value) }
      <span className='txt' style={ { marginLeft: '4px' } }>{ `${tableCell.coordinate}` }</span>
    </>
  );
}

function formatFile(fieldType, logValue) {
  const id = get(logValue, 'value.id');
  const mimeType = get(logValue, 'value.contentType');
  const fileName = get(logValue, 'value.fileName');
  const FileHistoryView = fieldType === 'receipt_file' ? ReceiptFile : OriginalReceipt;

  return (<FileHistoryView id={ id } mimeType={ mimeType } fileName={ fileName } />);
}

/**
 * ステータス変更による原本確認の履歴をフォーマット
 *
 * @param {string} fieldType
 * @param {{type: string, value: boolean}} logValue
 * @return {JSX.Element}
 */
function formatMatchingState(fieldType, logValue) {
  const field = camelCase(fieldType);

  return logValue.value
    ? formatter.text(i18next.t(`transactions.histories.properties.${field}.values.true`))
    : formatter.empty(i18next.t(`transactions.histories.properties.${field}.values.false`));
}

function formatValue(fieldType, propertyName, logValue) {
  // 未入力の表示形式も異なるので、別枠
  if (fieldType === 'receipt_file' || fieldType === 'original_receipt') {
    return formatFile(fieldType, logValue);
  }
  if (fieldType === 'mark_as_matched') {
    return formatMatchingState(fieldType, logValue);
  }

  if (isNil(logValue)) {
    return formatter.text(null);
  }

  // 以下、logValueが配列のケース
  if (fieldType === 'amount_per_tax_category') {
    return formatAmountPerTaxCategories(logValue);
  }
  if (fieldType === 'cost_allocation') {
    return formatCostAllocation(logValue);
  }

  const { type, value } = logValue;

  switch (type) {
    case 'boolean': return formatBoolean(value);
    case 'date':
    case 'datetime': return formatter.date(value); // TODO: datetimeは時間も表示するように
    case 'option': return formatOption(value);
    case 'period': return formatPeriod(value);
    case 'table_cell': return formatTableCell(value);
    case 'string': return formatter.text(value);
    default: break;
  }

  const normalizedProperty = camelCase(propertyName);

  // 入力フィールドごとの特殊フォーマット
  switch (fieldType) {
    case 'amount':
    case 'withholding': {
      // amount -> value, originalValue, currencyId, baseCurrencyId, exchangeRate
      // withholding -> amount, address, fullName
      // @todo 金額を整形して表示
      return formatter.text(value);
    }
    case 'route': {
      switch (normalizedProperty) {
        case 'onewayAmount': {
          return formatter.amount(value);
        }
        case 'lines':
        case 'via': {
          return fareFormatter.renderViaList(value);
        }
        case 'route': {
          return fareFormatter.renderRoute(value);
        }
        default: return formatter.text(value);
      }
    }
    default: break;
  }

  return formatter.text(value);
}

const InputChangeLog = (props) => {
  const baseClassName = 'input-change-log';
  const className = props.className ? `${baseClassName} ${props.className}` : baseClassName;

  return (
    <div className={ className }>
      { formatValue(props.fieldType, props.propertyName, props.from) }
      <i className='fas fa-long-arrow-alt-right' style={ { padding: '0 8px' } } />
      { formatValue(props.fieldType, props.propertyName, props.to) }
    </div>
  );
};

InputChangeLog.defaultProps = {
  labelColSize: 4,
};

InputChangeLog.propTypes = {
  clasName: PropTypes.string,
  from: PropTypes.any,
  to: PropTypes.any,
  fieldType: PropTypes.string.isRequired,
  propertyName: PropTypes.string,
};

export default InputChangeLog;
