import CompanionList from "components/CompanionList";
import i18next from "i18n";
import React from "react";
import { Popover } from "react-bootstrap";
import { Expense } from "utilities/api/models";
import { IconWithOverlay } from "./IconWithOverlay";

type Props = Pick<Expense, "id" | "companions">;

/** 参加者アイコン */
export const CompanionsIconWithOverlay: React.FC<Props> = ({
  id,
  companions,
}) => {
  const companionsOverlay = (): JSX.Element => {
    const colleagues = companions.filter((c) => !c.isClient);
    const clients = companions.filter((c) => c.isClient);

    return (
      <Popover
        id={`comment-${id}`}
        placement="left"
        title={i18next.t("companions.companion")}
      >
        <div style={{ wordWrap: "break-word" }}>
          <CompanionList
            companions={colleagues}
            showCompany={true}
            companyName={i18next.t("companions.ourCompany")}
          />
          <CompanionList companions={clients} showCompany={true} />
        </div>
      </Popover>
    );
  };

  if (companions.length === 0) {
    return null;
  }

  return <IconWithOverlay overlay={companionsOverlay()} icon={"fas fa-user"} />;
};
