import i18next from "i18n";
import isNil from "lodash/isNil";
import React from "react";
import formatter from "utilities/formatter";

// TODO: 交通費用のComponentを用意する
function renderFare(fare, withTeiki = false, position = "right") {
  const placeholder = i18next.t("transactions.inputs.noRouteSelected");
  const containerBaseStyle = { position: "relative", bottom: "2px" };

  return (
    <span>
      {withTeiki && position === "left" ? (
        <span style={{ ...containerBaseStyle, marginRight: "8px" }}>
          {renderTeikiLabel("left", {
            backgroundColor: "#909090",
            color: "white",
          })}
        </span>
      ) : null}
      {formatter.amount(fare, placeholder)}
      {withTeiki && position === "right" ? (
        <span style={{ ...containerBaseStyle, marginLeft: "8px" }}>
          {renderTeikiLabel("right", {
            backgroundColor: "#ff9c00",
            color: "white",
          })}
        </span>
      ) : null}
    </span>
  );
}

function renderTime(time) {
  const h = Math.floor(time / 60);
  const m = Math.ceil(time % 60);
  return `${h}${i18next.t("commons.units.hour")} ${m}${i18next.t(
    "commons.units.minute",
  )}`;
}

function renderStation({ type, name }) {
  return (
    <span>
      <i className={`fa fa-${type} prefix-icon`}></i>
      {name}
    </span>
  );
}

function renderRoute({ origin, destination }) {
  return (
    <div>
      {renderStation(origin)}
      <span className="fa fa-chevron-right prefix-icon suffix-icon"></span>
      {renderStation(destination)}
    </div>
  );
}

function renderViaList(viaList, direction = "down") {
  if (isNil(viaList) || viaList.length === 0) {
    return formatter.empty();
  }

  return viaList.map((via, key) => renderVia(via, key, direction));
}

function renderVia({ color, name, type }, key, direction) {
  if (type === "station") {
    return (
      <div key={`line-${key}`}>
        <span>{name}</span>
      </div>
    );
  }

  return (
    <div key={`line-${key}`}>
      <span>
        <i
          className={`fa fa-arrow-circle-${direction} prefix-icon`}
          style={{ color }}
        />
      </span>
      <span className="txt-sm">{name}</span>
    </div>
  );
}

function renderLinesSummary(lines) {
  if (isNil(lines) || lines.length === 0) {
    return null;
  }

  const routeElems = [];
  for (let i = 0; i < 2 && lines[i]; i++) {
    routeElems.push(renderVia(lines[i], `line-${i}`, "right"));
  }
  if (lines[2]) {
    routeElems.push(<span key="dots">...</span>);
  }

  return routeElems;
}

function labelClass(label) {
  switch (label) {
    case "楽":
      return "raku";
    case "早":
      return "haya";
    case "安":
      return "yasu";
    default:
      return "";
  }
}

function renderLabels(labels) {
  return (
    <div className="route-label-display">
      {(labels || []).map((label, i) => (
        <div className={`route-label ${labelClass(label)}`} key={`label-${i}`}>
          {label}
        </div>
      ))}
    </div>
  );
}

function renderTeikiLabel(position = "right", style = {}) {
  const labelStyle = {
    borderRadius: "4px",
    fontSize: "8px",
    padding: "2px 4px",
    ...style,
  };

  const label = position === "right" ? "+定期" : "定期控除";

  return <span style={labelStyle}>{label}</span>;
}

export default {
  renderFare,
  renderTime,
  renderStation,
  renderRoute,
  renderViaList,
  renderVia,
  renderLinesSummary,
  renderLabels,
};
