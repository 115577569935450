import GenericFieldsSelectedItemsList from '../GenericFieldsSelectedItemsList';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import styled from 'styled-components';
import { GenericFieldsItemsSelectModal } from './GenericFieldsItemsSelectModal';

const GenericFieldsSelectedItemsArea = styled.div`
  margin-top: 8px;

  ul {
    list-style: none;
    padding-left: 8px;
  }
`;

export default class GenericFieldsSelector extends Component {
  render() {
    const hasActiveGenericFieldsLicence = userPreferences.preference.useGenericFields;
    const editable = !this.props.disabled && hasActiveGenericFieldsLicence;

    return (
      <div>
        { editable && <GenericFieldsItemsSelectModal
          dataSet={ this.props.dataSet }
          label={ this.props.label }
          disabled={ !editable }
          isUserCreatableItem={ this.props.isUserCreatableItem }
          isMultipleSelectable={ this.props.isMultipleSelectable }
          onSelectItems={ this.props.onSelectItems }
          />
        }
        { (this.props.disabled // 経費の「詳細画面(=編集できない)」画面では必ず表示する
          || this.props.isMultipleSelectable) && <GenericFieldsSelectedItemsArea className={ 'generic-fields-items-list' }>
            <GenericFieldsSelectedItemsList
              selectedItems={ this.props.dataSet.items }
              onSelectItems={ this.props.onSelectItems }
              disabled={ !editable }
            />
          </GenericFieldsSelectedItemsArea>
        }
      </div>
    );
  }
}

GenericFieldsSelector.defaultProps = {
  dataSet: {},
  onSelectItems: (items) => null,
  disabled: true,
  isUserCreatableItem: false,
  isMultipleSelectable: false,
};

GenericFieldsSelector.propTypes = {
  dataSet: PropTypes.shape({
    dataSetId: PropTypes.string.isRequired,
    items: PropTypes.arrayOf(PropTypes.shape({
      id: PropTypes.string.isRequired,
      code: PropTypes.string,
      name: PropTypes.string.isRequired,
      sort: PropTypes.number,
      isDeleted: PropTypes.bool,
    })),
  }),
  label: PropTypes.string,
  onSelectItems: PropTypes.func,
  disabled: PropTypes.bool,
  isUserCreatableItem: PropTypes.bool,
  isMultipleSelectable: PropTypes.bool,
};
