import { AllowanceIcon } from "applications/expenses/collections/AllowanceIcon";
import {
  Dropdown,
  DropdownToggleButton,
  MenuItem,
} from "components/renewaled_ui/Dropdown";
import { EntryForm } from "components/types";
import i18next from "i18n";
import _get from "lodash/get";
import _sortBy from "lodash/sortBy";
import React from "react";
import styled from "styled-components";
import { Expense } from "utilities/api/models";
import colors from "values/colors";

const AddExpenseMenuView = styled.div`
  .drop-down-menu-item:hover {
    background: ${colors.renewaled.whiteHover};
  }
  .drop-down-menu-item > a {
    white-space: nowrap;
    text-decoration: none;
  }
  .create-expense-label {
    font-size: 12px;
    padding: 8px 18px 0;
    color: ${colors.renewaled.textGrey};
  }
`;

interface Props {
  entryForms: EntryForm[];
  defaultTransaction: Expense;
  isApprovingReport: boolean;
  openNewTransactionModal: (transaction: Expense) => void;
  onShowAddRequestableExpenseModal: () => void;
}

/** 経費を追加するドロップダウン */
export const AddExpenseDropdown: React.FC<Props> = ({
  entryForms,
  defaultTransaction,
  isApprovingReport,
  openNewTransactionModal,
  onShowAddRequestableExpenseModal,
}) => {
  if (!entryForms.length) return null;

  const renderCreateExpenseMenus = _sortBy(entryForms, ["sort"])
    .filter((x) => x.enabled)
    .map((entryForm, idx) => {
      let formValues = _get(defaultTransaction, "formValues", []);
      // TODO: 配列型になるはずだが、初期値(app/frontend/javascripts/applications/transactions/reducers/formData.js)が空オブジェクトになっているため配列に変換。配列のみで問題無さそうであることを確認し、初期値を空配列に変更し、以下コード削除。
      if (!(formValues instanceof Array)) {
        formValues = [];
      }

      const formedDefaultTransaction = {
        ...defaultTransaction,
        formId: entryForm.id,
        directProductTableId: entryForm.directProductTableId,
        formValues,
      };

      switch (entryForm.id) {
        // 手入力
        case "normal":
          return (
            <MenuItem
              key="normal"
              onClick={(): void =>
                openNewTransactionModal(formedDefaultTransaction)
              }
            >
              <i className="fas fa-pencil-alt fa-fw" />
              {entryForm.name}
            </MenuItem>
          );
        // 交通費入力
        case "transit":
          return (
            <MenuItem
              key="transit"
              onClick={(): void =>
                openNewTransactionModal(formedDefaultTransaction)
              }
            >
              <i className="fa fa-train fa-fw" />
              {entryForm.name}
            </MenuItem>
          );
        // 日当
        case "allowance":
          return (
            <MenuItem
              key={`allowance-${idx}`}
              onClick={(): void =>
                openNewTransactionModal(formedDefaultTransaction)
              }
            >
              <AllowanceIcon iconName={entryForm.icon || ""} />
              {entryForm.name}
            </MenuItem>
          );
        default:
          return null;
      }
    });

  return (
    <Dropdown
      id="add-drop-down"
      direction="down"
      align="right"
      menuMaxHeight="calc(100vh - 200px)"
      toggleButton={
        <DropdownToggleButton bsRole="toggle" styleType="secondary">
          {i18next.t("transactions.index.addTransactions")}
        </DropdownToggleButton>
      }
    >
      <AddExpenseMenuView>
        {!isApprovingReport && (
          <MenuItem onClick={onShowAddRequestableExpenseModal}>
            {i18next.t("transactions.index.selectFromExistingExpenses")}
          </MenuItem>
        )}
        <div className="">
          {!isApprovingReport && (
            <div className="create-expense-label">
              {i18next.t("transactions.index.createExpense")}
            </div>
          )}
          {renderCreateExpenseMenus}
        </div>
      </AddExpenseMenuView>
    </Dropdown>
  );
};
