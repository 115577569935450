import { displayMessage } from "actions/ActionCreators";
import { fetchAsync } from "actions/AsyncAction";
import Api from "utilities/api";
import * as transactionActions from "./transactionTable";

const prefix = `manualMerge`;

export const OPEN_MERGEABLE_TRANSACTIONS_PAIRS_SEARCH_MODAL = `${prefix}/OPEN_MERGEABLE_TRANSACTIONS_PAIRS_SEARCH_MODAL`;
export const CLOSE_MERGEABLE_TRANSACTIONS_PAIRS_SEARCH_MODAL = `${prefix}/CLOSE_MERGEABLE_TRANSACTIONS_PAIRS_SEARCH_MODAL`;
export const SEARCH_MERGEABLE_TRANSACTIONS_PAIRS = `${prefix}/SEARCH_MERGEABLE_TRANSACTIONS_PAIRS`;
export const EDIT_OPTION = `${prefix}/EDIT_OPTION`;

export function openMergeableTransactionsPairsSearchModal() {
  return { type: OPEN_MERGEABLE_TRANSACTIONS_PAIRS_SEARCH_MODAL };
}

export function closeMergeableTransactionsPairsSearchModal() {
  return { type: CLOSE_MERGEABLE_TRANSACTIONS_PAIRS_SEARCH_MODAL };
}

export function searchMergeableTransactionsPairs() {
  return async (dispatch, getState) => {
    const option = getState().pairsSearch.option;
    const target = getState().transactions.master.filter((t) => {
      if (typeof t !== "undefined") return t.isSelected;
      return false;
    });

    const transactionIds = target.map((t) => t.id);

    dispatch(fetchMutchingResults("fetching", []));

    try {
      const data = await dispatch(
        fetchAsync(Api.mergeableTransactions.index, {
          ids: transactionIds,
          option,
        }),
      );
      dispatch(fetchMutchingResults("success", data.payload));
      dispatch(openMergedSelecter());
    } catch (error) {
      dispatch(fetchMutchingResults("error", []));
    }
  };
}

function fetchMutchingResults(state, data) {
  return {
    type: SEARCH_MERGEABLE_TRANSACTIONS_PAIRS,
    state,
    data,
  };
}

export function editOption(key, value) {
  return {
    type: EDIT_OPTION,
    key,
    value,
  };
}

export const OPEN_MERGED_SELECTER = `${prefix}/OPEN_MERGED_SELECTER`;
export const CLOSE_MERGED_SELECTER = `${prefix}/CLOSE_MERGED_SELECTER`;
export const SELECT_MERGED_TRANSACTION = `${prefix}/SELECT_MERGED_TRANSACTION`;
export const MERGE_TRANSACTIONS = `${prefix}/MERGE_TRANSACTIONS`;

export function openMergedSelecter() {
  return { type: OPEN_MERGED_SELECTER };
}

export function closeMergedSelecter() {
  return { type: CLOSE_MERGED_SELECTER };
}

export function selectMergedTransaction(index) {
  return {
    type: SELECT_MERGED_TRANSACTION,
    index,
  };
}

export function mergeTransactions() {
  return (dispatch, getState) => {
    const option = getState().pairsSearch.option;
    const mergeTarget = getState().pairsSearch.pairs.filter((p) => {
      return p.selected || false;
    });

    if (mergeTarget.length < 1) {
      return dispatch(displayMessage("error", "統合対象を選択してください"));
    }

    const sendData = mergeTarget.map((pair) => {
      return {
        parent: pair.forWorker.id,
        sub: pair.forAggregation.map((t) => t.id),
      };
    });

    return dispatch(
      fetchAsync(Api.mergeableTransactions.merge, { pairs: sendData, option }),
    ).then(() => {
      dispatch(displayMessage("success", "統合が完了しました"));
      dispatch(transactionActions.fetchTransactions()).then(() =>
        dispatch(closeMergedSelecter()),
      );
    });
  };
}
