import ImportFormatSelect from 'components/import_format_select';
import React, { FC } from 'react';

export interface ExportTypeSelectorProps {
  format?: string;
  importType: string;
  onFileFormatSelect: (format: string) => void;
}

const ExportTypeSelector: FC<ExportTypeSelectorProps> = ({
  format,
  onFileFormatSelect,
  importType,
}) => {
  return (
    <div className='form-group'>
      <ImportFormatSelect
        defaultImportFormat={ format }
        onFormatSelect={ onFileFormatSelect }
        type={ importType }
      />
    </div>
  );
};

export default ExportTypeSelector;
