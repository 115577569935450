import CompanionCount from './CompanionCount';
import React, { useCallback } from 'react';
import groupBy from 'lodash/groupBy';
import i18next from 'i18n';
import { BootstrapTable, TableHeaderColumn } from 'react-bootstrap-table';
import {
  Button,
  ControlLabel,
} from 'react-bootstrap';

/** 選択された参加者一覧を表示するコンポーネント */
const SelectedCompanionlist = (props) => {
  const {
    /** 選択中の参加者 */
    selectedCompanions,
    /** 選択されたリストを更新するメソッド */
    onSelect,
  } = props;

  /** 選択されている参加者を取り除く */
  const deleteColumn = useCallback(
    (companion) => {
      let objIndex = null;
      if (companion.id) {
        objIndex = selectedCompanions.findIndex((c) => c.id === companion.id);
      } else {
        objIndex = selectedCompanions.findIndex((c) => (c.company === companion.company && c.isCompanionNumber));
      }

      const nextRows = Array.from(selectedCompanions);
      nextRows.splice(objIndex, 1);
      onSelect(nextRows);
    },
    [onSelect, selectedCompanions],
  );

  const options = {
    noDataText: i18next.t('commons.messages.noDataFound'),
    defaultSortName: 'id',
    defaultSortOrder: 'desc',
  };

  let companionsByCompany = groupBy(selectedCompanions, (c) => c.company);
  companionsByCompany = Object.keys(companionsByCompany).map((company) => companionsByCompany[company]);

  const companionLists = [];
  companionsByCompany.forEach((companions) => {
    const tableData = companions.map((companion) => {
      return {
        ...companion,
        // 人数で登録された要素については一番下に配置するため, id を 0 として上書く
        id: companion.isCompanionNumber ? 0 : companion.id,
        company: companion.company,
        name: companion.name,
        number: companion.number,
      };
    });
    const companyName = (tableData[0] === undefined) ? '' : tableData[0].company;
    companionLists.push(
      <div>
        <div className='col-sm-12'>
          <ControlLabel>
            { `${companyName} ${i18next.t('preferences.format.placeholder.companions')}`}
          </ControlLabel>
        </div>
        <div className='col-sm-12' style={ { marginBottom: '30px' } }>
          <BootstrapTable data={ tableData } options={ options }>
            <TableHeaderColumn dataField="id" hidden={ true } isKey={ true }/>
            <TableHeaderColumn dataField="name" width="150">
              { i18next.t('companions.companionName') }
            </TableHeaderColumn>
            <TableHeaderColumn dataField="number" width="150"
              dataFormat={ (cell, row) => {
                if (row.isCompanionNumber) {
                  return (
                    <CompanionCount
                      selectedCompanions={ selectedCompanions }
                      count={ cell }
                      company={ row.company }
                      onSelect={ onSelect }
                    />
                  );
                }
                return null;
              } }
            >
              { i18next.t('companions.detail') }
            </TableHeaderColumn>
            <TableHeaderColumn width="100" dataAlign='center'
              dataFormat={ (_, row) => (
                <Button bsStyle="danger" onClick={ () => deleteColumn(row) }>
                  { i18next.t('commons.actions.remove') }
                </Button>
              ) }
            />
          </BootstrapTable>
        </div>
      </div>,
    );
  });

  return (companionLists);
};

export default SelectedCompanionlist;
