import { Button } from "components/renewaled_ui/buttons";
import i18next from "i18n";
import React from "react";
import { Modal } from "react-bootstrap";
import styled from "styled-components";

const ModalBodyViwe = styled(Modal.Body)`
  display: flex;
  button {
    margin-right: 8px;
  }
`;

interface Props {
  readonly show: boolean;
  readonly onHide: () => void;
  readonly onConfirm: () => void;
}

/**
 * 選択した経費の削除を確認するモーダル
 */
export const DeleteConfirmModal: React.FC<Props> = ({
  show,
  onHide,
  onConfirm,
}) => {
  return (
    <Modal show={show} onHide={onHide} animation={false}>
      <Modal.Header closeButton>
        <Modal.Title>
          {i18next.t("transactions.messages.confirmDelete")}
        </Modal.Title>
      </Modal.Header>
      <ModalBodyViwe>
        <Button styleType="danger" onClick={onConfirm}>
          {i18next.t("commons.actions.delete")}
        </Button>
        <Button styleType="link-secondary" onClick={onHide}>
          {i18next.t("commons.actions.cancel")}
        </Button>
      </ModalBodyViwe>
    </Modal>
  );
};
