import { useMountedRef } from "hooks";
import React, { useCallback, useState } from "react";
import { ApproveButton } from "./ApproveButton";
import { OpenCancelApprovalModalButton } from "./OpenCancelApprovalModalButton";
import { RejectButton } from "./RejectButton";

interface ApplicationRequest {
  canApprove: boolean;
}

interface ApplicationPermission {
  canCancelApproval: boolean;
}

interface Application {
  permission?: ApplicationPermission;
  request?: ApplicationRequest;
}

interface AsyncActions {
  approve: () => Promise<void>;
  openCancelApprovalModal: () => void;
  reject: () => Promise<void>;
  showRejectModal: () => void;
}

interface Props {
  actions: AsyncActions;
  application: Application;
}

/** render結果が変わるものだけ比較しているので、actionsの変更は無視されます */
const propsAreEqual = (
  prevProps: Readonly<Props>,
  nextProps: Readonly<Props>,
): boolean =>
  prevProps.application.request?.canApprove ===
    nextProps.application.request?.canApprove &&
  prevProps.application.permission?.canCancelApproval ===
    nextProps.application.permission?.canCancelApproval;

/** 承認者用フォームのボタン */
export const ApproverButtons: React.FC<Props> = React.memo(
  ({ application, actions }) => {
    const isMountedRef = useMountedRef();
    const [disabled, setDisabled] = useState(false);

    const handleClickRejectButton = useCallback(() => {
      actions.showRejectModal();
    }, [actions]);

    const handleApprove = useCallback(() => {
      setDisabled(true);
      actions.approve().finally(() => {
        if (isMountedRef.current) {
          setDisabled(false);
        }
      });
    }, [actions, isMountedRef]);

    if (application.permission?.canCancelApproval) {
      return (
        <div className="row">
          <div className="col-md-12">
            <OpenCancelApprovalModalButton
              onClick={actions.openCancelApprovalModal}
            />
          </div>
        </div>
      );
    }

    if (!application.request?.canApprove) {
      return null;
    }

    return (
      <div className="row">
        <div className="col-md-6">
          <RejectButton disabled={disabled} onClick={handleClickRejectButton} />
        </div>
        <div className="col-md-6">
          <ApproveButton disabled={disabled} onClick={handleApprove} />
        </div>
      </div>
    );
  },
  propsAreEqual,
);
