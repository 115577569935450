
const prefixIndex = `REQUESTS`;
export const SET_REQUESTS = `${prefixIndex}/SET_REQUESTS`;
export const SET_SELECTED_TAB = `${prefixIndex}/SET_SELECTED_TAB`;
export const SET_CURRENT_PAGE = `${prefixIndex}/SET_CURRENT_PAGE`;
export const SET_SIZE_PER_PAGE = `${prefixIndex}/SET_SIZE_PER_PAGE`;

const prefixDeleteModal = 'requests/deleteModal';
export const TOGGLE_MODAL = `${prefixDeleteModal}/TOGGLE_MODAL`;
export const SET_DELETE_TARGET_ID = `${prefixDeleteModal}/SET_DELETE_TARGET_ID`;
