import ExpenseHistoryTable from "applications/transactions/components/ExpenseHistoryTable";
import TransactionModalWrapper from "applications/transactions/containers/TransactionModalWrapper";
import _get from "lodash/get";
import _isNil from "lodash/isNil";
import PropTypes from "prop-types";
import React, { PureComponent } from "react";

export default class ReportHistoryTable extends PureComponent {
  constructor(props) {
    super(props);

    this.handleHistoryClick = this.handleHistoryClick.bind(this);
    this.handleTransactionModalClose =
      this.handleTransactionModalClose.bind(this);
    this.getTransactionId = this.getTransactionId.bind(this);
    this.handleExpenseClick = this.handleExpenseClick.bind(this);
  }

  get tableOptions() {
    return { onRowClick: this.handleHistoryClick };
  }

  get isTransactionModalOpen() {
    return !_isNil(this.props.transaction);
  }

  get transactionFormFields() {
    const { transaction } = this.props;

    if (_isNil(transaction)) {
      return [];
    }

    // TransactionListEditorと処理を共通化
    const entryForm = this.props.entryForms.find((form) => {
      if (form.id === "allowance") {
        return form.directProductTableId === transaction.formId;
      }

      return form.id === transaction.formId;
    });

    return _get(entryForm, "formFields", []);
  }

  handleHistoryClick(history) {
    const transactionId = this.getTransactionId(history);

    // 紐付いている経費のIDが不明の場合の処理。実行されない想定
    if (_isNil(transactionId)) {
      return;
    }

    this.props.selectTransaction(transactionId);
  }

  handleExpenseClick(expenseId) {
    this.props.selectTransaction(expenseId);
  }

  handleTransactionModalClose() {
    this.props.selectTransaction(null);
  }

  getTransactionId(history) {
    return history.transactionId || _get(history, "transaction.id");
  }

  render() {
    return (
      <div className="report-history-table">
        <ExpenseHistoryTable
          histories={this.props.histories}
          options={this.tableOptions}
          onExpenseClick={this.handleExpenseClick}
        />

        <TransactionModalWrapper
          show={this.isTransactionModalOpen}
          closeModal={this.handleTransactionModalClose}
          formFields={this.transactionFormFields}
          transaction={this.props.transaction || {}}
          shouldSelectSelfAsCompanion={false}
        />
      </div>
    );
  }
}

ReportHistoryTable.defaultProps = {
  histories: [],
  entryForms: [],
  selectTransaction(transactionId) {},
};

ReportHistoryTable.propTypes = {
  entryForms: PropTypes.array.isRequired,
  histories: PropTypes.array.isRequired,
  selectTransaction: PropTypes.func.isRequired,
  transaction: PropTypes.object,
};
