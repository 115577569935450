import React from 'react';
import i18next from 'i18n';
import styled from 'styled-components';
import { OverlayTrigger, Popover } from 'react-bootstrap';

interface Props {
  label: JSX.Element;
}

const Spacer = styled.div`
  height: 5px;
`;

const formatDescription = i18next.t('accountingDataScheduledExports.tasks.descriptions.dateFormat').split(/(\n)/).map(
  (t, i) => (
    (t === '\n')
      ? <React.Fragment key={ i }><br /><Spacer /></React.Fragment>
      : t
  ),
);

const dateFormatNote = i18next.t('accountingDataScheduledExports.tasks.descriptions.dateFormatNote').split(/(\n)/).map(
  (t, i) => (
    (t === '\n')
      ? <React.Fragment key={ i }><br /><Spacer /></React.Fragment>
      : t
  ),
);


const descriptionPopover = (
  <Popover
    id='description'
    style={ { maxWidth: '500px' } }
  >
    {
      <>
        { i18next.t('accountingDataScheduledExports.tasks.descriptions.charsRestriction') }
        <br /><hr style={ { width: '100%', border: '1px solid #999' } }/>
        { formatDescription }
        <br /><hr style={ { width: '100%', border: '1px solid #999' } }/>
        { dateFormatNote }
      </>
    }
  </Popover>
);

const LabelWithDateFormatPopover: React.FC<Props> = ({
  label,
}) => {
  return (
    <label className={ 'control-label' }>
      { label }
      <OverlayTrigger
        overlay={ descriptionPopover }
        placement='left'
        trigger={ ['click'] }
        rootClose={ true }
      >
        <i className='far fa-question-circle' style={ { color: '#AAA', marginLeft: '6px', cursor: 'pointer' } } />
      </OverlayTrigger>
    </label>
  );
};

export default LabelWithDateFormatPopover;
