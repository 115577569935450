import ApprovingRequestsTableStorage, { ApprovingRequestsStorageHeaderDisplayParam, ApprovingRequestsStorageHeaderWidthApplication } from 'applications/approving_requests/utilities/ApprovingRequestsTableStorage';
import React, { useState } from 'react';
import RequestsTableStorage, { RequestsStorageHeaderDisplayParam, RequestsStorageHeaderWidthApplication } from 'applications/requests/utilities/RequestsTableStorage';
import _get from 'lodash/get';
import _includes from "lodash/includes";
import _isNil from 'lodash/isNil';
import formatter from 'utilities/formatter';
import i18next from 'i18n';
import { ApplicationTableRow, RequestTableColumn } from './interface';
import { RequestsSearchApplicationResponse } from 'utilities/api/responses/requests/searchResults/application';
import { TableColumn } from 'components/renewaled_ui/Table/interface/table';
import { TableColumnControlCacheParams, TableColumnControlParam, TableColumnControlParams } from 'components/renewaled_ui/ModalColumnControl/interface';
import { useBulkApproval } from 'applications/requests/hooks/useBulkApproval';

interface UseHooks {
  readonly isShowColumnModal: boolean;
  readonly isEnableReportBulkApprove: boolean;
  readonly inProgressCreateApproveJobs: boolean;
  readonly onBulkApprove: () => void;
  readonly disabledBulkApprovalButton: boolean;
  readonly selectedCount: number;
  readonly getSelectableColumns: () => TableColumnControlParam[];
  readonly getReactTableColumnParams: () => TableColumnControlParams;
  readonly getReactTableColumnParamsDefault: () => TableColumnControlParams;
  readonly onSetColumnInModal: (params: TableColumnControlCacheParams) => void;
  readonly getReports: () => ApplicationTableRow[];
  readonly getHeaderColumns: () => TableColumn[];
  readonly onClickShowModal: () => void;
  readonly onHideModal: () => void;
  readonly onFetchData: (startIndex: number, sizePerPage: number, background: boolean) => Promise<void>;
  readonly onSelectTable: (data: ApplicationTableRow, isSelected: boolean) => void;
  readonly onSelectAllTable: (isSelected: boolean) => void;
}

interface HooksProps {
  readonly applications: RequestsSearchApplicationResponse[];
  readonly isApprovingPage: boolean;
  readonly except: string[];
  readonly currentPage: number;
  readonly sizePerPage: number;
  readonly displayApplicant: boolean;
  readonly displayStatusForApprover: boolean;
  readonly displayButtons: boolean;
  readonly fetchData: (offset: number, limit: number, options?: { reset: boolean; }) => void;
  readonly onPageChange: (page: number) => void;
  readonly onDeleteClick: (application: RequestsSearchApplicationResponse) => void;
}

/**
 * 汎用申請テーブル: ロジック
 */
export const useHooks = (p: HooksProps): UseHooks => {
  /** モーダルを表示するか */
  const [isShowColumnModal, setIsShowColumnModal] = useState<boolean>(false);
  /**
   * キャッシュ: 表示するカラム
   */
  const getCacheDisplay = (): ApprovingRequestsStorageHeaderDisplayParam[] | RequestsStorageHeaderDisplayParam[] | null => {
    if (p.isApprovingPage) return ApprovingRequestsTableStorage.data.headerDisplay.applications;
    return RequestsTableStorage.data.headerDisplay.applications;
  };

  /**
   * キャッシュ: カラムの横幅
   */
  const getCacheWidth = (): ApprovingRequestsStorageHeaderWidthApplication | RequestsStorageHeaderWidthApplication => {
    if (p.isApprovingPage) return ApprovingRequestsTableStorage.data.headerWidth.application;
    return RequestsTableStorage.data.headerWidth.application;
  };

  const {
    inProgressCreateApproveJobs,
    bulkApprove,
    disabledBulkApprovalButton,
    selectedCount,
    selectedIds,
    isEnableReportBulkApprove,
    unapprovableReportIds,
    onSelect,
    onSelectAll,
  } = useBulkApproval({
    resetTable: (): void => {
      const options = {};
      const offset = 0;
      return p.fetchData(offset, p.sizePerPage, { ...options, reset: true });
    },
    requests: p.applications.filter((r) => r !== undefined),
    type: 'application',
    isApprovingPage: p.isApprovingPage,
  });

  /**
   * データ取得のハンドラ
   */
  const onFetchData = async (startIndex: number, sizePerPage: number, background: boolean): Promise<void> => {
    await p.fetchData(startIndex, sizePerPage, { reset: !background });
  };

  /**
   * 一括承認を押した
   */
  const onBulkApprove = (): void => {
    bulkApprove();
  };

  /**
   * 絞り込みフィルターを非表示を押した
   */
  const onHideModal = (): void => {
    setIsShowColumnModal(false);
  };

  /**
   * 絞り込みモーダルを表示を押した
   */
  const onClickShowModal = (): void => {
    setIsShowColumnModal(true);
  };

  /**
   * カラムを表示するか
   */
  const displayColumn = (column: string): boolean => {
    if (p.except) return !_includes(p.except, column);
    return true;
  };

  /**
   * ボタンのJSXを返す
   */
  const getButtonGroupJSX = (application: RequestsSearchApplicationResponse): JSX.Element => {
    const isDeletable: boolean = _get(application, 'permission.deletable', false);
    const onClickDelete = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>): void => {
      e.stopPropagation();
      p.onDeleteClick(application);
    };

    return (
      <div className='report-btn-group row'>
        <span>
          <button className={ `btn btn-danger${isDeletable ? '' : ' disabled'}` }
            onClick={ onClickDelete }>
            { i18next.t('commons.actions.delete') }
          </button>
        </span>
      </div>
    );
  };

  /**
   * テーブルの表示内容を整形する
   */
  const getReports = (): ApplicationTableRow[] => p.applications.map((a): ApplicationTableRow => {
    if (_isNil(a)) return a;

    const statusForApprover = formatter.requestStatus({
      status: a.statusForApprover,
      label: a.statusForApprover,
      color: a.statusColorForApprover,
    });
    const status = formatter.requestStatus({
      status: a.status,
      label: a.status,
      color: a.statusColor,
    });
    const isSelected = selectedIds.includes(a.id);
    const isUnapprovable = unapprovableReportIds.includes(a.id);

    return {
      id: a.id,
      title: a.title,
      sequence: a.sequence,
      approvalFlowName: a.approvalFlowName,
      applicant: formatter.text(a.user.name, ''),
      statusForApprover,
      status,
      buttons: getButtonGroupJSX(a),
      isSelected,
      isUnapprovable,
      response: a,
    };
  });

  /**
   * テーブルのヘッダーカラム
   */
  const headerColumns: RequestTableColumn[] = [
    {
      accessor: 'title',
      Header: i18next.t('reports.properties.applicationName'),
      width: getCacheWidth().title || 100,
      minWidth: 10,
      defaultCanSort: false,
      isShow: displayColumn('title'),
    },
    {
      accessor: 'sequence',
      Header: i18next.t('reports.properties.sequence'),
      width: getCacheWidth().sequence || 100,
      minWidth: 10,
      defaultCanSort: true,
      isShow: displayColumn('sequence'),
    },
    {
      accessor: 'approvalFlowName',
      Header: i18next.t('reports.properties.approvalFlowName'),
      width: getCacheWidth().approvalFlowName || 100,
      minWidth: 10,
      defaultCanSort: false,
      isShow: displayColumn('approvalFlowName'),
    },
    {
      accessor: 'applicant',
      Header: i18next.t('reports.properties.applicantName'),
      width: getCacheWidth().applicant || 100,
      minWidth: 10,
      defaultCanSort: false,
      isShow: p.displayApplicant && displayColumn('applicant'),
    },
    {
      accessor: 'statusForApprover',
      Header: i18next.t('reports.properties.statusForApprover'),
      width: getCacheWidth().statusForApprover || 100,
      minWidth: 10,
      defaultCanSort: false,
      isShow: p.displayStatusForApprover && displayColumn('statusForApprover'),
    },
    {
      accessor: 'status',
      Header: i18next.t('reports.properties.status'),
      width: getCacheWidth().status || 100,
      minWidth: 10,
      defaultCanSort: false,
      isShow: displayColumn('status'),
    },
  ];

  /**
   * テーブルのヘッダーカラム: 表示するカラムのみの配列
   */
  const getShowHeaderColumns = (): RequestTableColumn[] => headerColumns.filter((c) => c.isShow);

  /**
   * テーブルのヘッダーカラム: 末尾に追加され表示形式を変更できない
   */
  const headerColumnsAdded: RequestTableColumn[] = [
    {
      accessor: 'buttons',
      Header: '',
      width: getCacheWidth().buttons || 100,
      minWidth: 10,
      defaultCanSort: false,
      isShow: !_isNil(p.onDeleteClick) && p.displayButtons,
    },
  ];

  /**
   * 表示するカラムのデフォルト値
   */
  const displayAccessorsDefault = (): string[] => {
    const cacheDisplays = getCacheDisplay();
    const showColumns = getShowHeaderColumns();
    // キャッシュがなければそのまま表示する
    if (!cacheDisplays) return showColumns.map((h) => (h.accessor));

    // キャッシュがある場合
    // キャッシュしてる項目を後に表示しない権限に変わる可能性があるため、表示項目に一致した場合のみ反映する
    const showCacheColumns = showColumns.filter((c) => {
      const cacheColum = cacheDisplays.find((d) => d.accessor === c.accessor);
      if (!cacheColum) return true;
      return cacheColum.isShow;
    });
    const cacheShowDisplays = showCacheColumns.map((c) => {
      return cacheDisplays.find((d) => d.accessor === c.accessor) || {
        accessor: c.accessor,
        index: 0,
      };
    });
    const sorterdCacheColumns = cacheShowDisplays.sort((a, b) => a.index - b.index);
    return sorterdCacheColumns.map((c) => (c.accessor));
  };

  /**
   * 表示順序と表示するカラムの一覧
   */
  const [displayAccessors, setDisplayAccessors] = useState<string[]>(displayAccessorsDefault());

  /**
   * 表示非表示モーダル: テーブルの表示内容を整形する
   */
  const getSelectableColumns = (): TableColumnControlParam[] => {
    return getShowHeaderColumns().map((h, index) => ({
      accessor: h.accessor,
      selectable: true,
      show: h.isShow,
      index,
      width: h.width,
      Header: h.Header,
    }));
  };

  /**
   * モーダルで、カラムの表示非表示を取得する
   */
  const getReactTableColumnParams = (): TableColumnControlParams => {
    const cacheDisplays = getCacheDisplay();
    if (!cacheDisplays) {
      // キャッシュがなければそのままカラムを表示する
      const arr = getShowHeaderColumns().map((h, index) => ([
        h.accessor,
        {
          show: h.isShow,
          index,
        },
      ]));
      return Object.fromEntries(arr);
    }
    const arr = cacheDisplays.map((c) => ([
      c.accessor,
      {
        show: c.isShow,
        index: c.index,
      },
    ]));
    return Object.fromEntries(arr);
  };

  /**
   * モーダルで、カラムの表示非表示の初期値を取得する
   */
  const getReactTableColumnParamsDefault = (): TableColumnControlParams => {
    // キャッシュがなければそのままカラムを表示する
    const arr = getShowHeaderColumns().map((h, index) => ([
      h.accessor,
      {
        show: h.isShow,
        index,
      },
    ]));
    return Object.fromEntries(arr);
  };

  /**
   * モーダルで、表示順序と表示するものを変更した
   */
  const onSetColumnInModal = (params: TableColumnControlCacheParams): void => {
    const displayColumns = Object.keys(params).map((accessor) => ({
      accessor,
      index: params[accessor].index || 0,
      isShow: params[accessor].show,
    }));
    const sorterdColumns = displayColumns.sort((a, b) => a.index - b.index);

    // 現在の表示項目を更新する
    setDisplayAccessors(sorterdColumns.filter((c) => c.isShow).map((s) => s.accessor));

    // キャッシュに保存する
    if (p.isApprovingPage) {
      ApprovingRequestsTableStorage.set({
        sizePerPage: ApprovingRequestsTableStorage.data.sizePerPage,
        headerWidth: ApprovingRequestsTableStorage.data.headerWidth,
        headerDisplay: {
          ...ApprovingRequestsTableStorage.data.headerDisplay,
          applications: sorterdColumns,
        },
      });
    } else {
      RequestsTableStorage.set({
        sizePerPage: RequestsTableStorage.data.sizePerPage,
        headerWidth: RequestsTableStorage.data.headerWidth,
        headerDisplay: {
          ...RequestsTableStorage.data.headerDisplay,
          applications: sorterdColumns,
        },
      });
    }
  };

  /**
   * ヘッダーのカラムを返す
   */
  const getHeaderColumns = (): TableColumn[] => {
    const showColumns = getShowHeaderColumns();
    const filterColumns: RequestTableColumn[] = displayAccessors.map((accessor) => {
      return showColumns.find((s) => s.accessor === accessor) || {
        accessor,
        Header: '',
        width: 0,
        minWidth: 0,
        defaultCanSort: false,
        isShow: false,
        dataAlign: 'left',
      };
    });

    // ボタンなどの末尾固定のカラム
    const showAddedColumns = headerColumnsAdded.filter((c) => c.isShow);
    const mergedColumns = [...filterColumns, ...showAddedColumns];

    return mergedColumns.map((c): TableColumn => ({
      accessor: c.accessor,
      Header: c.Header,
      width: c.width,
      minWidth: c.minWidth,
      dataAlign: c.dataAlign,
      defaultCanSort: c.defaultCanSort,
    }));
  };

  /**
   * チェックボックスで全選択を押した
   */
  const onSelectAllTable = (isSelected: boolean): void => {
    const displayRows = p.applications.slice((p.currentPage - 1) * p.sizePerPage, p.currentPage * p.sizePerPage);
    const filterdRows = displayRows.filter((r) => {
      if (!r) return false;
      if (unapprovableReportIds.includes(r.id)) return false;
      return true;
    });
    // 表示中の行データのみを選択する
    onSelectAll(filterdRows, isSelected);
  };

  /**
   * チェックボックスを押した
   */
  const onSelectTable = (data: ApplicationTableRow, isSelected: boolean): void => {
    onSelect(data.response, isSelected);
  };

  return {
    isShowColumnModal,
    isEnableReportBulkApprove,
    inProgressCreateApproveJobs,
    onBulkApprove,
    disabledBulkApprovalButton,
    selectedCount,
    getSelectableColumns,
    getReactTableColumnParams,
    getReactTableColumnParamsDefault,
    onSetColumnInModal,
    getReports,
    getHeaderColumns,
    onClickShowModal,
    onHideModal,
    onFetchData,
    onSelectTable,
    onSelectAllTable,
  };
};
