import { fetchAsync } from "actions/AsyncAction";
import { ThunkDispatch } from "tdispatch";
import Api from "utilities/api";
import { CancelMarkedAsMatchedRequest } from "utilities/api/requests/expenseRequests/CancelMarkedAsMatchedRequest";
import { MarkAsMatchedRequest } from "utilities/api/requests/expenseRequests/MarkAsMatchedRequest";
import * as ActionCreators from "./ActionCreators"; // eslint-disable-line import/no-unresolved

// 原本経費確認済みのステータスに設定する
export function markAsMatched(
  payload: MarkAsMatchedRequest,
  onSubmitSuccess: () => void,
) {
  return async (dispatch: ThunkDispatch): Promise<void> => {
    dispatch(ActionCreators.setFetchingStatus(true));

    try {
      await fetchAsync(Api.transactions.markAsMatched, payload)(dispatch);
      dispatch(ActionCreators.closeModal());

      onSubmitSuccess();
    } finally {
      dispatch(ActionCreators.setFetchingStatus(false));
    }
  };
}

// 原本経費確認済みのステータスを取り消しする
export function cancelMarkedAsMatched(
  payload: CancelMarkedAsMatchedRequest,
  onCancelSuccess: () => void,
) {
  return async (dispatch: ThunkDispatch): Promise<void> => {
    dispatch(ActionCreators.setFetchingStatus(true));

    try {
      await fetchAsync(
        Api.transactions.cancelMarkedAsMatched,
        payload,
      )(dispatch);
      dispatch(ActionCreators.closeModal());

      onCancelSuccess();
    } finally {
      dispatch(ActionCreators.setFetchingStatus(false));
    }
  };
}
