import { displayMessage } from "actions/ActionCreators";
import { fetchAsync } from "actions/AsyncAction";
import _isNil from "lodash/isNil";
import _omit from "lodash/omit";
import _set from "lodash/set";
import Api from "utilities/api";

const prefix = "reports/histories";

export function fetchReportHistories(reportId) {
  return async (dispatch, getState) => {
    try {
      const { reportHistories, transactionHistories } =
        await Api.reports.histories({ reportId });

      const payload = {
        reportHistories: formatHistories(reportHistories),
        transactionHistories: formatHistories(transactionHistories),
      };

      dispatch(initReportHistories(payload));
    } catch (e) {
      dispatch(displayMessage("error", "編集履歴を取得できません"));
      throw e;
    }
  };
}

// 編集履歴が存在する経費について、経費の現在の情報を参照するために使用する
function fetchTransaction(transactionId) {
  return async (dispatch, getState) => {
    const {
      histories: { cache },
    } = getState();

    if (!_isNil(cache[transactionId])) {
      return Promise.resolve(cache[transactionId]);
    }

    const data = await dispatch(
      fetchAsync(Api.transactions.show, { id: transactionId }),
    );
    // 参照用なので、編集不可にしておく
    const transaction = {
      ...data,
      editable: false,
      permissions: { deletable: false, detachable: false },
    };

    dispatch(writeHistoryCache(transaction));

    return Promise.resolve(transaction);
  };
}

export function selectTransaction(transactionId) {
  return async (dispatch, getState) => {
    if (_isNil(transactionId)) {
      dispatch(setTransactionForReference(null));
      return;
    }

    const transaction = await dispatch(fetchTransaction(transactionId));

    dispatch(setTransactionForReference(transaction));
  };
}

function formatHistories(histories) {
  return histories.map((history) => {
    if (history.type !== "edit") {
      return history;
    }

    return { ...history, changes: groupChangesByField(history.changes) };
  });
}

function groupChangesByField(changes) {
  const cache = changes.reduce((acc, change) => {
    if (!acc[change.formField.type]) {
      _set(acc, change.formField.type, []);
    }

    acc[change.formField.type].push(change);

    return acc;
  }, {});

  return Object.keys(cache).map((fieldType) => {
    const formField = cache[fieldType][0].formField;

    return {
      formField,
      changes: cache[fieldType].map((x) => _omit(x, ["formField"])),
    };
  });
}

export const INIT_REPORT_HISTORIES = `${prefix}/INIT_REPORT_HISTORIES`;
function initReportHistories(histories) {
  return {
    type: INIT_REPORT_HISTORIES,
    payload: histories,
  };
}

export const SET_TRANSACTION_FOR_REFERENCE = `${prefix}/SET_TRANSACTION_FOR_REFERENCE`;
function setTransactionForReference(transaction) {
  return {
    type: SET_TRANSACTION_FOR_REFERENCE,
    payload: transaction,
  };
}

export const WRITE_HISTORY_CACHE = `${prefix}/WRITE_HISTORY_CACHE`;
function writeHistoryCache(data) {
  return {
    type: WRITE_HISTORY_CACHE,
    payload: data,
  };
}

export const TOGGLE_HISTORY_MODAL = `${prefix}/TOGGLE_HISTORY_MODAL`;
export function openHistoryModal(transactionId) {
  return {
    type: TOGGLE_HISTORY_MODAL,
    payload: { show: true, transactionId },
  };
}

export function closeHistoryModal() {
  return {
    type: TOGGLE_HISTORY_MODAL,
    payload: { show: false },
  };
}
