import RadioGroup from "components/fields/RadioGroup";
import i18next from "i18n";
import React, { FC } from "react";
import { RateReferenceType } from "../types";

interface Props {
  value: RateReferenceType;
  onChange: (value: RateReferenceType) => void;
  disabled: boolean;
}

const RateReferenceTypeInput: FC<Props> = (props) => {
  return (
    <RadioGroup
      isClearable={false}
      value={props.value}
      radios={[
        {
          value: "setting",
          label: i18next.t(
            "transactions.properties.rateReferenceType.setting.label",
          ),
          description: i18next.t(
            "transactions.properties.rateReferenceType.setting.description",
          ),
        },
        {
          value: "manual",
          label: i18next.t(
            "transactions.properties.rateReferenceType.manual.label",
          ),
        },
        {
          value: "unspecified",
          label: i18next.t(
            "transactions.properties.rateReferenceType.unspecified.label",
          ),
          description: i18next.t(
            "transactions.properties.rateReferenceType.unspecified.description",
          ),
        },
      ]}
      onChange={props.onChange}
      isDisabled={props.disabled}
    />
  );
};

RateReferenceTypeInput.defaultProps = {
  value: "setting",
};

export default RateReferenceTypeInput;
